import { LocalizationService } from 'root/services/localization/localization.service';
import { NoticebarService } from 'root/services/noticebar-service/noticebar.service';
import { Injectable, ElementRef } from '@angular/core';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { ScrollToService } from '../scroll-to/scroll-to.service';
import { LogService, MibpLogger } from '../logservice';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  private validationTitle: string;
  log: MibpLogger;

  constructor(private noticeBarService: NoticebarService, localizationService: LocalizationService,
    private scrollToService: ScrollToService, logger: LogService ) {
    this.log = logger.withPrefix('form-validation');

    localizationService.using(['Global_ValidationErrorsTitle'], values => {
      this.validationTitle = values[0];
    });

  }

  private findFirstErrorElement(form: UntypedFormGroup, formElement: ElementRef, sort = true): HTMLElement[] {
    const result: HTMLElement[] = [];
    for (const field of Object.keys(form.controls)) {
      const control = form.get(field);
      if (control instanceof UntypedFormControl) {
        if (form.controls[field].errors) {
          const elm = (formElement.nativeElement as HTMLDivElement).querySelector(`[formcontrolname=${field}]`);
          if (elm) {
            if (elm.getAttribute('id')) {
              const labelElement = (formElement.nativeElement as HTMLDivElement).querySelector(`label[for=${elm.getAttribute('id')}`);
              if (labelElement) {
                result.push(labelElement as HTMLElement);
                continue;
              }
            } else {
              this.log.warn(`FormControl "${field}" is missing ID attribute. Can not find control label.`);
            }
            result.push(elm as HTMLElement);
          } else {
            this.log.info(`Cold not find HTML Element for field ${field}`);
          }
        }
      } else if (control instanceof UntypedFormGroup) {
        this.findFirstErrorElement(control, formElement, false).forEach(e => result.push(e));
      }
    }

    if (sort) {
      result.sort((a, b) => {
        if (a === b) { return 0; }
        const aPos = a.getBoundingClientRect();
        const bPos = b.getBoundingClientRect();

        if (aPos.top === bPos.top) { return 0; }
        return aPos.top > bPos.top ? 1 : -1;
      });
    }

    return result;
  }

  scrollToFirstError(form: UntypedFormGroup, formElement: ElementRef ) {
    const sortedElementsWithErrors = this.findFirstErrorElement(form, formElement);
    this.log.warn('sortedElementsWithErrors', sortedElementsWithErrors);
    if (sortedElementsWithErrors.length > 0) {
      this.scrollToService.scrollToElement(sortedElementsWithErrors[0] as HTMLElement, null, -100);
    }
  }

  /**
   * Make sure all fields are touched so that validation status
   * is shown
   */
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
        control.updateValueAndValidity();
      } else if (control instanceof UntypedFormArray) {
        control.controls.forEach(childControl => this.validateAllFormFields(childControl as UntypedFormGroup));
      }
    });
  }

  private closestParentThatContains(sourceElement: HTMLElement, containsSelector: string ): HTMLElement {
    let i = 0;
    let currentElement = sourceElement;

    do {
      if (currentElement.querySelector(containsSelector)) {
        return currentElement;
      }
      currentElement = currentElement.parentElement;
      if (i > 50) {
        break;
      }
      i++;
    } while(currentElement);

  }

  /**
   * Will find mibp-validation-messages, show a notice with the errors and scroll to the first element
   * Pass in the form element
   */
  showErrors(elm: ElementRef, showMaxErrors = 10, extraErrorMessages?: { [key: string]: string}): void {
    setTimeout(() => {

      if (!showMaxErrors) {
        showMaxErrors = 10;
      }

      const errorSummary = {};
      let firstlabelElement = null;

      const query = 'mibp-validation-message div[data-validation-message-control].has-error, .validation-message-text--invalid';
      const validationMessages = Array.from(elm.nativeElement.querySelectorAll(query)) as HTMLElement[];


      // We have two type of validation implementations. We check them all to support combinations of them both for now

      validationMessages.filter(vm => vm.classList.contains('validation-message-text--invalid')).forEach(mibpValidationTextElement => {
        const controlName = mibpValidationTextElement.parentElement.getAttribute('controlname');


        const lookInElement = this.closestParentThatContains(mibpValidationTextElement, `*[formControlName='${controlName}']`) ?? elm.nativeElement;

        let formControl = lookInElement.querySelector(`*[formControlName='${controlName}']`) as HTMLElement;

        if (!formControl) {
          // Can't find [formControlName=...]. Let's try a form group!
          formControl = elm.nativeElement.querySelector(`*[formGroupName='${controlName}']`) as HTMLElement;
        }

        const formControlId = formControl ? formControl.getAttribute('id') : undefined;
        const formControlLabelledBy = formControl?.getAttribute('aria-labelledby');

        if (formControlId || formControlLabelledBy) {
          let labelElement = elm.nativeElement.querySelector( formControlLabelledBy ? `#${formControlLabelledBy} mibp-resource-string` :  `label[for='${formControlId}'] mibp-resource-string`) as HTMLLabelElement;
          if (!labelElement && formControlId) {
            labelElement = elm.nativeElement.querySelector(`label[for='${formControlId}']`) as HTMLLabelElement;
          }
          if (labelElement) {            
            const mibpResourceTextOnly = mibpValidationTextElement.querySelector("mibp-resource-string");

            if (mibpResourceTextOnly) {
              errorSummary[labelElement.textContent] = mibpResourceTextOnly.textContent;
            } else {
              const errorMessageElements = mibpValidationTextElement.querySelectorAll('.validation-message-text__error');

              if (errorMessageElements.length > 0) {              
                errorSummary[labelElement.textContent] = errorMessageElements[0].textContent;
              } else {
                errorSummary[labelElement.textContent] = '';
              }
            }            
          }
        }

      });



      validationMessages.filter(vm => !vm.classList.contains('validation-message-text--invalid')).forEach(valmsg => {
        const formControlName = valmsg.getAttribute('data-validation-message-control');
        if (formControlName) {
          const formControl = document.querySelector(`*[formControlName='${formControlName}']`);
          if (formControl) {
            const formControlId = formControl.getAttribute('id');
            if (formControlId) {
              const labelElement = document.querySelector(`label[for='${formControlId}']`);
              for (let i = 0; i < labelElement.childNodes.length; i++) {
                if (labelElement.childNodes[i].nodeName === 'MIBP-RESOURCE-STRING') {
                  firstlabelElement = firstlabelElement || labelElement;
                  if (!errorSummary[labelElement.childNodes[i].textContent]) {
                    errorSummary[labelElement.childNodes[i].textContent] = valmsg.textContent;
                  }
                }
              }
            } else {
              throw new Error(`[FormValidationService].showErrors ID missing on *[formControlName='${formControlName}']`);
            }
          } else {
            throw new Error(`[FormValidationService].showErrors Can't find *[formControlName='${formControlName}']`);
          }
        }
      });
      this.log.warn("validationMessages", validationMessages);

      if (extraErrorMessages) {
        Object.keys(extraErrorMessages).forEach(labelText => {
          errorSummary[labelText] = extraErrorMessages[labelText];
        });
      }


      if (Object.keys(errorSummary).length == 0) {
        return;
      }


      const messageKeys = Object.keys(errorSummary);
      let liElements = messageKeys.map(name => `<li><strong>${name}</strong> - ${errorSummary[name]}</li>`);

      let html = `<h2>${this.validationTitle}</h2>`;

      if (liElements.length > showMaxErrors && liElements.length  !== showMaxErrors + 1 ) {
        liElements = liElements.slice(0, showMaxErrors );
        liElements.push(`<li>and ${messageKeys.length - liElements.length} more...</li>`);
      }

      html += "<div class='text'><ul>";
      html += liElements.join('');
      html += "</ul></div>";

      this.noticeBarService.showText(html, NoticeType.Warning, true);
      if (firstlabelElement) {
        this.scrollToService.scrollToElement(firstlabelElement);
      }
    }, 50);

  }

 

}
