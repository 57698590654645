import { NotificationService } from './../../../../services/notification-service/notification.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'mibp-header-activity',
  templateUrl: './header-activity.component.html'
})
export class MibpHeaderActivityComponent implements OnInit, OnDestroy {

  notificationCountSub: Subscription;
  unreadUserActivityCountText: string;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {

    this.notificationCountSub = this.notificationService.notificationCounter$.subscribe(count => {
      if (!count) {
        this.unreadUserActivityCountText = null;
      } else {
        this.unreadUserActivityCountText = count > 100 ? '99+' : count.toString();
      }
    });


  }
  ngOnDestroy(): void {
    this.notificationCountSub?.unsubscribe();
  }
}
