import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { MibpHttpApi } from '../mibp-http-api/mibp-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class MibpHtmlHelperService {

  constructor(private auth: AuthService, private httpApi: MibpHttpApi)
  {}

  public removeJwtTokenFromHtmlImages(html: string): string {
    //TODO: Use regex and skip placeholder text
    if(!html){
      return html;
    }
    if(html.indexOf('?jwt') === -1){
      return html;
    }
    const startIndex = html.indexOf('?jwt=') + 5;
    const endIndex = html.indexOf('"', startIndex);
    const token = html.substring(startIndex, endIndex);

    return html.replace(new RegExp(token, 'g'),'__phJwtToken__');
  }

  public addJwtTokenToHtmlImages(html: string): string {
    const accessToken = this.auth.getB2CUser()?.accessToken;
    return html.replace(new RegExp('__phJwtToken__', 'g'), accessToken);
  }
}
