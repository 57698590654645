import { MibpGridOptions } from 'root/mibp-openapi-gen/models';
import { MibpHttpApi } from './mibp-http-api.service';


export class DownloadHttpService {

  constructor(private httpApi: MibpHttpApi) {}

  public async GetExcelBasedOnGrid(mibpGridOptions: MibpGridOptions): Promise<unknown> {
    return await this.httpApi.post(`/download/excel-grid`, mibpGridOptions).toPromise();
  }

  public async GetExcelBasedOnGridWithOptions(mibpGridOptions: MibpGridOptions, options: any): Promise<unknown> {
    return await this.httpApi.postWithOptions(`/download/excel-grid`, mibpGridOptions, options).toPromise();
  }

}
