import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GlobalConfigService } from 'root/services';
import { MibpPopoverComponent } from '../popover-component/popover.component';
import { MyfleetEquipmentStatusDescriptionService } from './equipment-status-desc.service';
import { EquipementStatuses } from './equipment-status-desc.types';
@Component({
  selector: 'mibp-equipment-status-desc',
  templateUrl: './equipment-status-desc.component.html',
  styleUrls: ['./equipment-status-desc.component.scss']
})
export class EquipmentStatusDescriptionComponent implements OnInit {

  @Input() status: string;
  @Input() canEdit: boolean;
  statusDescription: EquipementStatuses[];
  description:string;
  @ViewChild(MibpPopoverComponent) popover: MibpPopoverComponent;


  constructor(
    public globalConfig: GlobalConfigService,
    public fleetEquipementStatus : MyfleetEquipmentStatusDescriptionService
  ) {}

  ngOnInit(){
    this.statusDescription = this.fleetEquipementStatus.getDescriptionForStatusCode(this.status);
    this.description  = this.statusDescription[0]?.description;
  }
  showPopover(elm: HTMLElement): void {

    if (elm) {
      this.popover.open(elm);
    }
  }
}
