/* tslint:disable */
/* eslint-disable */
export enum UserStatus {
  New = 0,
  Active = 1,
  Inactivated = 2,
  Suspended = 3,
  Approved = 4,
  Rejected = 5,
  Deactivated = 6,
  Created = 7,
  None = 8
}
