import { OnInit, Renderer2, OnDestroy, Input } from '@angular/core';
import { FrontendContextService, ResourceStringEditorService, LocalizationService, SignalR_RoleAdministrationPermission } from 'root/services';
import { Directive, ElementRef } from '@angular/core';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MySandvikFeatures } from 'root/services/permission';

@Directive({
  selector: '[mibpResourceStringEditor]'
})
export class ResourceStringEditorDirective implements OnInit, OnDestroy {

  private isAuthorized = false;
  private mouseEnterUnlisten: () => void;
  private mouseLeaveUnlisten: () => void;
  private hoverElement: HTMLDivElement;
  private resourceStrings: string[] = [];
  private isListening: boolean;
  private stopUsingResources: () => void;

  constructor(private elm: ElementRef,
    private context: FrontendContextService,
    private sessionService: MibpSessionService,
    private renderer: Renderer2,
    private editorService: ResourceStringEditorService, private localizationService: LocalizationService) {
    this.isAuthorized = this.sessionService.current && !!(this.sessionService.hasFeature(MySandvikFeatures.AdminManageTranslations));
  }

  strPressToEdit: string;

  @Input('mibpResourceStringEditor')
  set setResourceStrings(values: string[]) {
    if (this.isAuthorized) {
      const filteredStrings = this.normalizeResourceStrings(values);
      if (this.isListening && filteredStrings.length === 0) {
        this.detachEditorEventListeners();
      }

      this.resourceStrings = filteredStrings.slice(0);
      if (this.resourceStrings.length > 0 && !this.isListening) {
        this.attachEditorEventListeners();
      }
    }
  }

  private normalizeResourceStrings(values: string[]) {
    if (typeof values === 'undefined' || !(values instanceof Array)) {
      return [];
    }
    const strings = values.filter(str => str);
    return strings;
  }

  private attachEditorEventListeners() {
    this.localizationService.addAvailableResourceString(this.resourceStrings);
    if (!this.mouseEnterUnlisten) {
      this.mouseEnterUnlisten = this.renderer.listen(this.elm.nativeElement, 'mouseenter', () => this.onMouseEnter());
    }
    if (!this.mouseLeaveUnlisten) {
      this.mouseLeaveUnlisten = this.renderer.listen(this.elm.nativeElement, 'mouseleave', () => this.onMouseLeave());
    }
    this.isListening = true;
  }

  private detachEditorEventListeners() {
    if (this.resourceStrings.length > 0) {
      this.localizationService.removeAvailableResourceString(this.resourceStrings);
    }
    if (this.mouseEnterUnlisten) {
      this.mouseEnterUnlisten();
    }

    if (this.mouseLeaveUnlisten) {
      this.mouseLeaveUnlisten();
    }
    this.isListening = false;
  }


  ngOnInit() {

    if (!this.isAuthorized) {
      return;
    }

    // Let the localization service know which resource strings we're using from code
    this.stopUsingResources = this.localizationService.using(['ResourceEditor_PressToEdit'], values => {
      this.strPressToEdit = this.localizationService.format(values[0], {key: 'E'});
    });


    const directive = this;
    this.editorService.EditKey$.subscribe(function() {
      if (directive.hoverElement) {
        directive.editorService.openEditor(directive.resourceStrings[0]).then(ok => {
          // Alright
        });
      }
    });

  }


  /**
   * Returns true if an input element currently has focus
   */
  private isInputFieldActive() {
    const activeElement = document.activeElement ? document.activeElement.tagName.toLowerCase() : '';
    return activeElement === 'input' || activeElement === 'select' || activeElement === 'textarea';
  }

  onMouseEnter() {

    if (!this.editorService.isEditMode()) {
      return;
    }

    if (!this.hoverElement && window.document.hasFocus() && !this.isInputFieldActive()) {
      const d = document.createElement('div');
      d.innerHTML = `<p>${this.strPressToEdit}</p>`;
      d.style.visibility = 'hidden';
      d.style.position = 'absolute';
      d.style.backgroundColor = '#fefefe';
      d.style.opacity = '0.85';
      d.style.padding = '10px';
      d.style.zIndex = '8000';
      d.style.border = '1px solid #ccc';
      d.style.pointerEvents = 'none';

      this.hoverElement = document.body.appendChild(d);

      const rect = this.getElementOffset(),
        hoverRect = this.hoverElement.getBoundingClientRect();


      let topPosition = rect.top - hoverRect.height;

      if (topPosition < 0) {
        // Position element below insead of above
        topPosition = rect.top + rect.height + 10;
      }

      this.hoverElement.style.left = `${rect.left}px`;
      this.hoverElement.style.top = `${topPosition}px`;
      this.hoverElement.style.visibility = 'visible';

    }
  }

  getElementOffset() {
    const rect = this.elm.nativeElement.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left, height: rect.height };
  }

  onMouseLeave() {
    if (this.hoverElement) {
      this.hoverElement.parentElement.removeChild(this.hoverElement);
      this.hoverElement = null;
    }
  }

  ngOnDestroy() {

    if (!this.isAuthorized) {
      return;
    }

    if (this.stopUsingResources) {
      this.stopUsingResources();
    }

    if (this.isListening) {
      this.detachEditorEventListeners();
      this.onMouseLeave();
    }
  }
}
