import { Injectable } from '@angular/core';

@Injectable()
export class ScrollToService {

  stopNextScroll: boolean = null;

  getScrollTop(): number {
    return window.scrollY;
  }
  scrollToElement(elm: HTMLElement | number, callback?: () => void, adjustment = 0, duration = 200): void {

    if (!elm) {
      return;
    }

    const position = (elm as HTMLElement).getBoundingClientRect ? this.offset(elm as HTMLElement).top + adjustment : (elm as number) + adjustment;
    const element = document.documentElement;
    this.scrollTo(element, position, duration);
  }

  scrollToTop(): void {
    if (this.stopNextScroll) {
      this.stopNextScroll = false;
      return;
    }
    const element = document.documentElement;
    this.scrollTo(element, 0, 200);
  }

  stopNextScrollToTop(): void {
    this.stopNextScroll = true;
  }


  public offset(el: HTMLElement): { top: number, left: number } {
    const rect = el.getBoundingClientRect(),
      // Edge wants body
      docElm = document.body.scrollTop > document.documentElement.scrollTop ? document.body : document.documentElement,
      scrollLeft = window.pageXOffset || docElm.scrollLeft,
      scrollTop = window.pageYOffset || docElm.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  private easeInOutQuad(currentTime, startValue, changeInValue, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
      return changeInValue / 2 * currentTime * currentTime + startValue;
    }
    currentTime--;
    return -changeInValue / 2 * (currentTime * (currentTime - 2) - 1) + startValue;
  }

  public scrollTo(element: HTMLElement, to: number, duration: number): void {
    const start = element.scrollTop,
      change = to - start,
      increment = 20,
      easeInOutQuad = this.easeInOutQuad;
    let currentTime = 0;

    const animateScroll = function () {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }
}
