import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActAsService {

  constructor() { }
  public disableActAsSubject : BehaviorSubject<boolean> = new BehaviorSubject(false);

  enableActAs(){
    this.disableActAsSubject.next(false);
  }
  disableActAs(){
    this.disableActAsSubject.next(true);
  }
}
