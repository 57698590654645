<mibp-popup-container
  (closed)="onPopupClosed($event)"
  [groupName]="'onboardingPopovers'"
  [attachToBody]="false"
  [closeOnClickOutside]="false"
  [closeOnResize]="false"
  [useArrow]="true"
  [anchorTo]="stepAnchorTo"
  [anchorPosition]="stepAnchorPosition"
  [closeOnScroll]="false">

  <div class="my-featuretour" [attr.data-step]="currentStep + 1">
    <div class="my-featuretour__step"><span>{{currentStep + 1}}</span></div>
    <div class="my-featuretour__contentwrapper">

      <div class="my-featuretour__title">
        <h3 class="my-header onboarding__title "><mibp-resource-string *ngIf="stepTitleResourceKey" [key]="stepTitleResourceKey"></mibp-resource-string></h3>
        <div class="my-featuretour__cornerclose"><a href="javascript:void(0)" (click)="close()">x</a></div>
      </div>

      <div class="my-featuretour__body my-body"><mibp-resource-string *ngIf="stepTitleResourceKey" [key]="stepBodyResourceKey" [isHtml]="true"></mibp-resource-string></div>
      <div class="my-featuretour__footer">
        <div class="my-featuretour__steps">
          <mibp-resource-string key="Global_StepXOfY" [macros]="{ step: currentStep + 1, count: currentSteps?.length}"></mibp-resource-string>
        </div>
        <div class="my-featuretour__actions">
          <mibp-button resourceStringKey="Global_Back" (click)="prevStep()" *ngIf="currentStep > 0"></mibp-button>
          <mibp-button resourceStringKey="Global_Next" #btn [style]="btn.Enum.Styles.Fill" (click)="nextStep()" *ngIf="currentStep < currentSteps?.length - 1"></mibp-button>
          <p class="skipTour" *ngIf="currentStep != currentSteps?.length - 1"><a href="javascript:void(0)" (click)="skipTour()"><mibp-resource-string key="NewFeature_SkipTour"></mibp-resource-string></a></p>
          <mibp-button resourceStringKey="Onboarding_Done" #btn [style]="btn.Enum.Styles.Fill" [color]="btn.Enum.Colors.Orange" (click)="done()" *ngIf="currentStep == currentSteps?.length - 1"></mibp-button>
        </div>


      </div>

    </div>
</div>

</mibp-popup-container>
