import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MibpLogger, LogService } from 'root/services';

@Component({
  template: `
 <div class="appcontainer">

  <div id="content content-primary" role="main">
      <h1 class="heading-type-3">404<span><mibp-resource-string key="Error_PageNotFound"></mibp-resource-string></span></h1>
      <div class="article text">
        <div class="article-inner">
          <div class="article-inner-inner">
            <p><mibp-resource-string key="Error_PageNotFoundText"></mibp-resource-string></p>
          </div>
        </div>
      </div>
    </div>
    </div>
  `
})
export class Error404Component implements OnInit {

  log: MibpLogger;

  constructor(logger: LogService, private route: ActivatedRoute,
    private changeRef: ChangeDetectorRef) {
    this.log = logger.withPrefix('error404');
  }

  ngOnInit() {
    this.log.debug('Route', window.location.href, this.route.snapshot.data);
  }
}
