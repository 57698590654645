import { SignalR_UserFile } from './../mibp-api-services/_mibp-api-generated.service.types';
import { Guid } from "guid-typescript";
import { SignalR_ApiResponseGeneric } from "../mibp-api-services";
import { MibpHttpApi } from "./mibp-http-api.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PromotionDto, PromotionIndexTarget, NewProduct, ResolvedScope, PagedOptions } from 'root/mibp-openapi-gen/models';
export class PromotionHttpService {
  constructor(private httpApi: MibpHttpApi) {}

  public GetPromotion(promotionId: number): Promise<PromotionDto> {
    return this.httpApi.get<SignalR_ApiResponseGeneric<PromotionDto>>(`promotions/${promotionId}`)
      .pipe(
        map((o) => {
          if (o.success) {
            return o.data;
          }
          return undefined;
        })
      )
      .toPromise();
  }

  public async SavePromotion(promotionForSaveVm: PromotionForSaveVm): Promise<SignalR_ApiResponseGeneric<number>> {
    return await this.httpApi.post<SignalR_ApiResponseGeneric<number>>(`/promotions/save`, promotionForSaveVm)
      .toPromise();
  }
  public async ReScope(): Promise<number> {
    return await this.httpApi.get<SignalR_ApiResponseGeneric<number>>(`/promotions/rescope`).pipe(
      map((o) => {
        if (o.success) {
          return o.data;
        }
        return undefined;
      })
    )
      .toPromise();
  }

  public async GetPromotionsForDashboard(): Promise<PromotionsForDashboardInterface> {
    return await this.httpApi.get<SignalR_ApiResponseGeneric<PromotionsForDashboardInterface>>(`/promotions/dashboard`).pipe(
      map(respons => respons.data)
    )
      .toPromise();
  }

}

export enum PromotionType {
  Unknown = 0,
  Internal = 1,
  External = 2
}

export enum PromotionPlacement {
    Unknown = 0,
    HomePage = 10,
}


export interface PagedPromotionRefinementOptions extends PagedOptions {
  query: string;
  sortBy: 'newest' | 'oldest' | 'priority';
  companyCode:string;
  erpCustomerId:string;
  types: string[];
  placements:string[];
}

export interface PromotionForListVm {

  id: number,
  promotionType: PromotionType,
  title: string,
  description: string,
  urlToExternalPromotion: string,
  userFileId: Guid

}

export interface PromotionIndex {
  id?: number;
  type: string;
  title: string;
  description?: string;
  urlToExternalPromotion?: string;
  userFileId: string;
  placement: string;
  priority: number;
  publishFromDate: Date;
  publishToDate: Date;
  targets: PromotionIndexTarget[];

}

export interface PromotionForSaveVm {
  id?: number;
  type: PromotionType;
  title: string;
  description?: string;
  urlToExternalPromotion?: string;
  userFileId: Guid; //Id fop the uploaded image
  placement: PromotionPlacement;
  priority: number;
  publishFromDate: Date;
  publishToDate: Date;
  promotionTargets: PromotionTargetForSaveVm[];
  promotionProducts?: PromotionProductVm[];
}

export interface PromotionTargetForSaveVm {
  companyId?: number;
  businessRelationId?: number;
  productModelId?: number;
  equipmentId?: number
}

export interface PromotionProductVm {
  productCode: string;
  maxOrderableQuantity: number;
}

export interface PromotionForEditToReturnVm {
  id?: number;
  type: PromotionType;
  title: string;
  description?: string;
  urlToExternalPromotion?: string;
  userFile: SignalR_UserFile;
  placement: PromotionPlacement;
  priority: number;
  publishFromDate: Date;
  publishToDate: Date;
  promotionProducts?: PromotionProductVm[];
  responsibilities?: ResolvedScope[];
}


export interface PromotionProductDto{
  productCode: string,
  recommendedQuantity: number,
  product: NewProduct
}

export interface PromotionForDashboardVm{
  id: string,
  promotionType: 'internal' | 'external',
  title: string,
  urlToExternalPromotion: string,
  userFileId: Guid
  priority: number;
}




export interface PromotionsForDashboardInterface {
  promotionsGroupedByPriority: [PromotionForDashboardVm[]]
}

export const PROMOTION_PRODUCT_NOT_FOUND_DB_EXCEPTION = 'PromotionProductNotFoundDbException';
export const ARGUMENT_EXCEPTION = 'ArgumentException';
export const DECIMAL_EXCEPTION = 'DecimalValidationException';
