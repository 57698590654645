
import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { MapDto } from 'root/mibp-openapi-gen/models/map-dto';
import { LogService, MibpLogger } from 'root/services';

@Component({
  selector: 'mibp-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, AfterViewInit {
  mapData: MapDto;
  protected errorLoadingGoogleScript = false;


  @Output() markerClicked: EventEmitter<string> = new EventEmitter();
  @Output() infoWindowCloseEvent: EventEmitter<any> = new EventEmitter();
  @Output() scrollToButtonClickedEvent: EventEmitter<string> = new EventEmitter();


  @Input() set location(value: MapDto) {
    if (!this.mapElement || !value) {
      return;
    }

    this.mapData = value;
    this.loadData();
  }

  @ViewChild('map') mapElement: any;
  map: google.maps.Map;

  hasMapData: boolean;
  private log: MibpLogger;

  constructor(log: LogService) {
    this.log = log.withPrefix('map-component');
  }


  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (typeof google != 'undefined') {
      this.map = new google.maps.Map(this.mapElement.nativeElement);
    } else {
      this.log.error(`Google was not defined`);
    }
  }

  loadData() {

    if (typeof google == 'undefined') {
      this.log.error(`Could not load data - google is not defined`);
      this.errorLoadingGoogleScript = true;
      return;
    }

    if (this.mapData?.mapLocation !== undefined) {
      this.hasMapData =this.mapData.markers.length > 0;

      const mapProperties = {
        center: new google.maps.LatLng(this.mapData?.mapLocation?.latitude ?? 0, this.mapData?.mapLocation?.longitude ?? 0),
        zoom: !this.mapData.zoom ? 5 : this.mapData.zoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };

      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties );
      const infowindow = new google.maps.InfoWindow();
      google.maps.event.addListener(infowindow, 'closeclick', () => {
        this.infoWindowCloseEvent.emit();

      });

      this.mapData.markers.forEach(marker => {
        const mark = new google.maps.Marker({
          position: { lat: marker.latitude, lng: marker.longitude },
          map: this.map,
          title: marker.label
        });

        if (marker.htmlContent) {

          mark.addListener("click", () => {
            infowindow.setContent(marker.htmlContent);
            infowindow.open(this.map, mark);
            this.markerClicked.emit(marker.id);

            setTimeout(() => {
              let scrollToButton = document.getElementById('js-scroll-to');
              if (!scrollToButton) {
                return;
              }

              scrollToButton.addEventListener('click', (e) => {
                this.scrollToButtonClickedEvent.emit();
              });

            }, 500);

          });

        }

      });

      const isMultipleMarkers = this.mapData.markers.length > 1;
      // If we have more then one marker on the map
      if (isMultipleMarkers) {
        //Try to set the center of the map based on all the markers that are placed out
        const bounds = new google.maps.LatLngBounds();
        this.mapData.markers.forEach(marker => {
          bounds.extend({ lat: marker.latitude, lng: marker.longitude });
        });

        this.map.fitBounds(bounds);
        this.map.setCenter(bounds.getCenter());
      }
    } else {
      this.hasMapData = false;
    }
  }
}
