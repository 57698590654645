import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MibpGridFrontendFilter } from '../..';
import { GlobalConfigService, MibpGridService } from 'root/services';
import { ExtendedMibpGridCheckboxFacet } from '../../mibp-grid.types';
import { MibpGridCheckboxFacet, MibpGridFacet } from 'root/mibp-openapi-gen/models';

@Component({
  selector: 'mibp-grid-filter-checkbox-facet',
  templateUrl: './mibp-grid-filter-checkbox-facet.component.html',
  styleUrls: ['./mibp-grid-filter-checkbox-facet.component.scss']
})
export class MibpGridFilterCheckboxFacetComponent implements OnInit {

  private currentFilter: string[] = [];
  filterForm: UntypedFormGroup;
  redIndicatorList: string[];

  @Input()
  set filter(value: MibpGridFrontendFilter) {
    if (value.checkboxFacets) {
      this.checkboxFacets = value.checkboxFacets;
      this.checkboxFacets.map(x=>{
        if(this.redIndicatorList.includes(x.text)){
          x.isHighlighted = true;
        }
        if(value.column.valueReourceStringPrefix){
          x.valueResourceKey = value.column.valueReourceStringPrefix+x.text;
        }
      });
    }
  }
  @Output() update = new EventEmitter<string[]>();
  checkboxFacets: ExtendedMibpGridCheckboxFacet[];
  initialCheckboxFacets: MibpGridCheckboxFacet[];
  @Input() disabled = false;
  @Input()
  set value(val: MibpGridFacet[]) {
    if(val){
      this.currentFilter = val.map(facet=>{
        if(typeof facet === 'string'){
          return this.gridService.mapStringToFacet(facet as string).text;
        }
      });
    }
    else{
      this.currentFilter = [];
    }
  }

  constructor(
    private gridService: MibpGridService,
    private globalConfig: GlobalConfigService) {
    this.redIndicatorList = this.globalConfig.checkboxFacetRedIndicator;
  }

  ngOnInit() {
    this.initialCheckboxFacets = this.checkboxFacets;
  }

  public updateCount(text: string, count: number): void {
    // This can be uncommented later if we want to update the numbers on the checkbox filters to the current result
    // this.checkboxFacets?.forEach(facet => {
    //   if (facet.text === text) {
    //     facet.value = count;
    //   }
    // });
  }

  checkboxeCheck(isChecked : boolean, checkboxFacetText : string) : void{
    if(isChecked){
      if(!this.currentFilter.includes(checkboxFacetText)){
        this.currentFilter.push(checkboxFacetText);
      }
    }
    else{
      this.currentFilter.splice(this.currentFilter.indexOf(checkboxFacetText),1);
    }
    this.update.emit(this.currentFilter.map(x => `${x}|(${x})`));
  }
  testChecked( checkboxFacetText : string) : boolean {
    return this.currentFilter.includes(checkboxFacetText);
  }
}
