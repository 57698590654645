<div #chart [showElementLoader]="loading" class="chart" [style.width]="width" [style.height]="height">

  <div *ngIf="(!chartData || chartData?.length === 0) && (!chartDataSets || chartDataSets.length === 0)" class="no-data">
    <div><mibp-resource-string key="Global_NoDataAvailable"></mibp-resource-string></div>
  </div>

  <canvas baseChart [height]="height" [width]="width"
    *ngIf="!loading && isReady && ( (chartData && chartData?.length > 0) || chartDataSets && chartDataSets?.length > 0 )"
    [datasets]="chartDataSets"
    [labels]="chartLabels"
    [type]="horizontal ? 'horizontalBar' : 'bar'"
    [options]="chartOptions"
    [colors]="chartColors">
  </canvas>
</div>

<mibp-popover #availabilityPopover>
  <mibp-resource-string [key]="currentTooltipResourceString" [isHtml]="true"></mibp-resource-string>
</mibp-popover>

<!-- <mibp-loader [showElementLoader]="loading" [coverElement]="chart"></mibp-loader> -->
