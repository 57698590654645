import { Subscription } from 'rxjs/internal/Subscription';
import { BroadcastService } from './../../services/broadcast-service/broadcast.service';
import { EventEmitter, Output, OnInit } from '@angular/core';
import { DialogButton } from './../dialog/dialog.types';
import { Component, ViewChild, OnDestroy, Input } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { MibpHttpApi } from 'root/services';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'mibp-pdf-render',
  templateUrl: './pdf-render.component.html',
  styleUrls: ['./pdf-render.component.scss']
})
export class PdfRenderComponent implements OnInit, OnDestroy {

  @Input() url : string;
  @Input() userFileId : Guid;
  @Input() dialogButtons : DialogButton[];
  @Output() buttonClicked = new EventEmitter<DialogButton>();
  dialogButtonTandC: DialogButton[];
  isLoading : boolean;
  @ViewChild('dialogTandC') dialogTandC: DialogComponent;
  responsiveSub: Subscription;
  useMobileLayout: boolean;

  constructor(private broadcast: BroadcastService, private httpApi: MibpHttpApi) {}
  ngOnDestroy(): void {
    this.responsiveSub?.unsubscribe();
  }
  ngOnInit(): void {
    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe((screen) => {
      this.useMobileLayout = screen.lteq('s');
    });
  }

  close() : void {
    this.url = null;
    this.dialogTandC.close();
  }

  open() : void {
    this.isLoading = true;
    this.dialogTandC.open();
  }



  buttonClick(event: DialogButton) : void{
    this.buttonClicked.emit(event);
  }

  callBackFn($event) : void {
    this.isLoading = false;
  }

  // Should not be here. What if we're showing a different PDF?
  downloadTermsandConditions():void{
    this.httpApi.UserFile.triggerDownload(this.userFileId);
  }
}
