import { IsActiveMatchOptions, NavigationExtras } from '@angular/router';
import { StringUtils } from '@azure/msal-browser';
import { PermissionPolicy, SignalR_RoleEcommercePermission, SignalR_RoleAdministrationPermission } from "root/services";

export type MibpTabRoute = {
  path: string[],
  extras?: NavigationExtras,
  isActiveMatchOptions?: IsActiveMatchOptions;
  routerLinkActiveOptions?: {
    exact: boolean;
    queryParams?: 'exact' | 'subset' | 'ignored';
  }
} | string[];

export interface MibpTab {
  tabIndex?: number;
  active?: boolean;
  resourceKey: string;
  text?: string;
  counterValue?: number;
  route?: MibpTabRoute;
  permissionPolicy?: PermissionPolicy;
  googleTagManagerDataAttributeValue?: string;
  hideTab?: boolean;
}

export enum RolePermissionOptions {
  All = 0,
  Any = 1
}

/**
 * Creates role permission settings used to check permissions in tabs component.
 * @param permissions Array of RolePermission.
 * @param settings RolePermissionOptions enum value. Determines if the user needs ALL or ANY of the permissions to view a tab. All = 0, Any = 1.
 */
export interface RoleEcommercePermissionSettings {
  ecommercePermissions: SignalR_RoleEcommercePermission[];
  adminPermissions: SignalR_RoleAdministrationPermission[];
  permissionMatchOption?: RolePermissionOptions;
}
