// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { MibpLogLevel } from './../app/services/logservice/loglevel.enum';

export const environment = {
  production: false,
  name: 'qa',
  defaultLanguage: 'en',
  availableLanguages: ['en', 'sv', 'cs', 'da', 'de', 'es', 'fi', 'fr', 'hu', 'it', 'ja', 'ko', 'nl', 'pl', 'pt', 'ru', 'tr', 'zh','id', 'rs'],
  clientSideCache: {
    prefix: 'mysandvik_'
  },
  mibpSessionLogPrefix: 'MibpSession',
  enableTestId: false,
  userFriendlyErrorMessages: true,
  enableErrorDialog: false,
  enableBugReport: true,
  logLevel: MibpLogLevel.Info,
  clientIdCacheKey: 'ClientID',
  backendLdeUrl: '',
  enableServiceWorker: false,
  documoto: {
    loginUrl: "https://mining.qa.ibp.sandvik.com/_layouts/15/sibp/usermanagement/Mibp2SigninProxyHandler.ashx?o=(mibp.organizationid)&u=(mibp.returnurl)&uh=(mibp.userhash)&iframetoken=8f8286a1-6237-4c5f-9db9-9fa9a2a3a572",
    signoutUrl: 'https://integration.digabit.com/api/auth/logout1?username=(userName)&tk=SANDVIKMINING',
    applicationUrl: "https://integration.digabit.com/Portal/saml/?tk=SANDVIKMINING&sso=true&rqid=(mibp.clientid)",
    applicationStartPageUrl: "https://integration.digabit.com/ui",
    mediaIdentifierUrl: "https://integration.digabit.com/ui/mediaIdentifierRedirect/(mibp.mediaidentifier)?rqid=(mibp.clientid)&tk=SANDVIKMINING&sso=true",
    mySandvik1LogoutUrl: "https://mining.qa.ibp.sandvik.com/_layouts/15/sibp/usermanagement/SignoutHandler.ashx?iframetoken=8f8286a1-6237-4c5f-9db9-9fa9a2a3a572",
    mysandvik1PingUrl: 'https://mining.qa.ibp.sandvik.com/en/my-sandvik/_ping?isDlg=1&iframetoken=8f8286a1-6237-4c5f-9db9-9fa9a2a3a572&c=(mibp.date)'
  },
  auth : {
    'b2c': {
      clientId: '1af6c2f2-774f-4011-aa53-1661d08cba61',
      authorityBaseUrl: 'https://mysandvikqa.b2clogin.com/tfp/mysandvikqa.onmicrosoft.com/',
      knownAuthorities: ['mysandvikqa.b2clogin.com'],
      policies: {
        /***
         * Standard SignIn user flow with options to signup and reset password as well
         */
        signUpSignIn: 'B2C_1_MSSiPo',

        /***
         * Stand alone User flow for password reset
         */
        passwordReset: 'B2C_1_MSRpPo',

        /***
         * Stand alone User Flow for signup
         */
        signUp: 'B2C_1_MSSuPo',

        /***
         * Special "migration reset password flow" for for users when sent from 1.0
         */
        passwordResetMigrationFlow: 'B2C_1_MuRp'
      },
      redirectUri: "/",
      silentRedirectUri: "/AuthSilentRenew",
      scope: ["https://mysandvikqa.onmicrosoft.com/portal/user_impersonation"]
    }
  },
  chart: {
    colors: ['#0099ff', '#ff6d00', '#0074ac', '#72c9e5', '#f2b685']
  },
  appInsights: {
    instrumentationKey: '6129b064-2434-4429-9c7a-10e1073c6fee'
  },
  pageTitle: {
    suffix: 'Global_MySandvik_CustomerPortal',
    delimiter: ' - '
  },
  userFiles: {
    maxUploadBytes: 26214400,
    expirationTime: {
      amount: 1,
      unit: 'hour'
    }
  },
  warrantyPortal: {
    otherCosts: {
      "20000001":  "Labour",
      "20000009":  "Accommodation",
      "20000008":  "Allowance",
      "20000007":  "Mileage",
      "20000019":  "VAT",
      "20000010":  "Tickets",
      "20000004":  "Travel hours",
      "20000011":  "Sub-contractor",
      "20000014":  "Parts Freight Costs",
      "20000012":  "Local Material",
      "20000015":  "Unit Transportation Costs",
      "20000013":  "Workshop Material",
      "20000018":  "Import Duty"
    }
  },
  googleTagManager: {
    containerId: 'GTM-WBRTCXF',
    auth: 'ZUTFHXei3MTdF6uhIzScHg',
    preview: 'env-14'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
