<div class="browser-notice" *ngIf="isVisible">
  <div class="group-inner">
    <p>
      <span translate="no" class="material-icon material-icon--red material-icon--fill">warning</span>
      <span [innerHTML]="messageHtml"></span>
    </p>
  </div>
</div>


<!-- DIALOG NOT IN USE -->
<mibp-dialog  [buttons]="dialogButtons"
              (opened)="onDialogOpen()"
              (closed)="onDialogClosed()"
              (buttonClick)="onDialogButton()"
              titleResourceStringKey="Global_Notice_UseNewerBrowser_Title">
  <p><span [innerHTML]="messageHtml"></span></p>
</mibp-dialog>
