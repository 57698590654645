import { MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core";
import { Inject, Injectable, Optional } from "@angular/core";
import { FormattingService } from "root/services";
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string, private formattingService: FormattingService) {
    super(dateLocale);
  }

  // set Monday as first day of week instead of Sunday
  getFirstDayOfWeek(): number {
    return 1;
  }

  format(date: Date): string {
    return this.formattingService.formatDate(date, null, true);
  }
}
