import { NavigationExtras, Router } from "@angular/router";
import { BroadcastService } from "../broadcast-service/broadcast.service";

export interface MibpRouteInfo {
  commands: any[];
  navigationExtras?: NavigationExtras;
}
export class NavigationContext {
  constructor(private router: Router, private broadcastService: BroadcastService) {}

  public navigateToProductDetailsPage(productCode: string): Promise<boolean> {
    const r = this.getRouteToProductDetailsPage(productCode);
    return this.router.navigate(r.commands, r.navigationExtras);
  }


  public getRouteToProductDetailsPage(productCode: string): MibpRouteInfo {
    return {
      commands: [
        this.broadcastService.snapshot.language,
        'shop',
        'products',
        productCode
      ]
    };
  }




  /**
   * Will make sure that the URL is pointing to the active language
   * @param url The URL to fix
   */
  public ensureUrlLanguage(url: string): string {
    const lang = this.broadcastService.snapshot.language;

    if (!lang) {
      return url;
    }

    if (url.indexOf('/') !== 0) {
      return url; // Only deal with absolute URL:s
    }

    const m = url.match(`(^/([a-z]{2}))(/|$)`);
    if (m) {
      if (m[2] !== lang) {
        url = `/${lang}/${url.substring(m[0].length)}`;
      }
    } else {
      url = `/${lang}/${url}`;
    }

    return this.cleanupUrl(url);
  }

  private cleanupUrl(url: string): string {
    return url ? url
      .replace('//', '/') // Remove duplicate slashes
      .replace(/\/+$/, '') // Trim ending slashes
      : url;
  }

}
