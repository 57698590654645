<h4 class="expandable-section-title" [class.is-modern]="design == 'modern'" [class.hidden]="isEditing" [class.help-title]="titleType === 1" [class.is-expanded]="isExpanded">
  <span class="edit-title__text" [innerHTML]="title"></span>
  <mibp-button *ngIf="allowEdit" #btn [icon]="btn.Enum.Icons.Edit" [isSmall]="true" (click)="startEditName($event)"></mibp-button>
</h4>
<form *ngIf="allowEdit" class="name-form" [formGroup]="nameForm" [ngClass]="{'is-editing': isEditing}" >
  <input class="name-input" autocomplete="off" formControlName="name"
  (keydown)="nameElementKeyDown($event)"
  (blur)="nameElementBlur()"
  (click)="clickedRenameInput($event)"
  maxlength="50"
  #cartNameElement  [class.hidden]="!isEditing" type="text">
</form>
