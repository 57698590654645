import { allPermissionPolicies } from "root/all-permission-policies";
import { MySandvikNavigationItem } from "../navigation-types";

export const NAVITEMS_NEWS: MySandvikNavigationItem =
{
  resourceKey: 'Global_News',
  uniqueId: 'news',
  sandvikIconName: 'newspaper',
  route: {path: ['news']},
  permissionPolicy: allPermissionPolicies.canSeeNewsArticles,
  children: [
    {
      resourceKey: 'NewsArticle_Details',
      route: {path: [':id']},
      uniqueId: 'news/details',
      permissionPolicy: allPermissionPolicies.canSeeNewsArticles
    },
  ]
};



