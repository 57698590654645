import {
  Injectable
} from '@angular/core';
import {
  ApplicationInsights,
  IExceptionTelemetry} from '@microsoft/applicationinsights-web';
import {
  Router,
  NavigationEnd
} from '@angular/router';
import {
  filter
} from 'rxjs/operators';
import { environment } from 'root/environment';
@Injectable({
  providedIn: 'root',
})

export class ApplicationInsightsService {
  private appInsights: ApplicationInsights;
  constructor(private router: Router) {
    return;
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking:true,
        cookieCfg: {

          //
          // Override setCookie function to make cookie secure + SameSite=None
          //
          setCookie: (name, value) => {
            if (!value.includes('Secure')) {
              value += '; Secure; SameSite=None';
            }
            document.cookie = `${encodeURIComponent(name)}=${value}`;
          }
        }
      }
    });
    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();
  }

  setUserId(userId: string): void {
    this.appInsights?.setAuthenticatedUserContext(userId);
  }

  clearUserId():void {
    this.appInsights?.clearAuthenticatedUserContext();
  }

  logPageView(name ? : string, uri ? : string):void {
    this.appInsights?.trackPageView({
      name,
      uri
    });
  }

  logException(error: Error) :void {
    const exception: IExceptionTelemetry = {
      exception: error
    };
    this.appInsights?.trackException(exception);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights?.addTelemetryInitializer(envelope => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties["ApplicationPlatform"] = "Web";
      item.properties["ApplicationName"] = "My Sandvik Portal";
    });
  }

  private createRouterSubscription() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.logPageView(null, event.urlAfterRedirects);
    });
  }
}
