/**
 * Guess language and redirect the user to that page
 *
 * Maybe this should be a guard...
 */
import { Component } from '@angular/core';

@Component({
  template: ``
})
export class VariationRootPageComponent {
  lang = '';
}
