/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addDocumotoSyncJob } from '../fn/operations/add-documoto-sync-job';
import { AddDocumotoSyncJob$Params } from '../fn/operations/add-documoto-sync-job';
import { addDownloadManualJob } from '../fn/operations/add-download-manual-job';
import { AddDownloadManualJob$Params } from '../fn/operations/add-download-manual-job';
import { addDownloadManualJob$Plain } from '../fn/operations/add-download-manual-job-plain';
import { AddDownloadManualJob$Plain$Params } from '../fn/operations/add-download-manual-job-plain';
import { AddDownloadManualsJobCommandResultVm } from '../models/add-download-manuals-job-command-result-vm';
import { AddPartsManualsJobFromDocumotoMdzJobResponseVm } from '../models/add-parts-manuals-job-from-documoto-mdz-job-response-vm';
import { BlobPagedResultViewModel } from '../models/blob-paged-result-view-model';
import { CachedMdzJobVm } from '../models/cached-mdz-job-vm';
import { createMissingDocumotoZipJobs } from '../fn/operations/create-missing-documoto-zip-jobs';
import { CreateMissingDocumotoZipJobs$Params } from '../fn/operations/create-missing-documoto-zip-jobs';
import { createMissingDocumotoZipJobs$Plain } from '../fn/operations/create-missing-documoto-zip-jobs-plain';
import { CreateMissingDocumotoZipJobs$Plain$Params } from '../fn/operations/create-missing-documoto-zip-jobs-plain';
import { deleteCachedKeys } from '../fn/operations/delete-cached-keys';
import { DeleteCachedKeys$Params } from '../fn/operations/delete-cached-keys';
import { deleteCachedKeys$Plain } from '../fn/operations/delete-cached-keys-plain';
import { DeleteCachedKeys$Plain$Params } from '../fn/operations/delete-cached-keys-plain';
import { deleteDocumotoMdzJobFromCache } from '../fn/operations/delete-documoto-mdz-job-from-cache';
import { DeleteDocumotoMdzJobFromCache$Params } from '../fn/operations/delete-documoto-mdz-job-from-cache';
import { deleteFromCache } from '../fn/operations/delete-from-cache';
import { DeleteFromCache$Params } from '../fn/operations/delete-from-cache';
import { deleteFromCache$Plain } from '../fn/operations/delete-from-cache-plain';
import { DeleteFromCache$Plain$Params } from '../fn/operations/delete-from-cache-plain';
import { DocumotoDownloadManualJobResultVm } from '../models/documoto-download-manual-job-result-vm';
import { DocumotoDownloadManualJobVm } from '../models/documoto-download-manual-job-vm';
import { DocumotoExtractZipFileJobVm } from '../models/documoto-extract-zip-file-job-vm';
import { DocumotoExtractZipFileJobVmJobListAndSummaryVm } from '../models/documoto-extract-zip-file-job-vm-job-list-and-summary-vm';
import { DocumotoReprocessZipFileResponseVm } from '../models/documoto-reprocess-zip-file-response-vm';
import { DocumotoSyncJobVm } from '../models/documoto-sync-job-vm';
import { DownloadDocumotoMdzJobResponseVm } from '../models/download-documoto-mdz-job-response-vm';
import { downloadDocumotoMdzJobStatus } from '../fn/operations/download-documoto-mdz-job-status';
import { DownloadDocumotoMdzJobStatus$Params } from '../fn/operations/download-documoto-mdz-job-status';
import { DownloadedManualsStatisticsVm } from '../models/downloaded-manuals-statistics-vm';
import { downloadJobZipFile } from '../fn/operations/download-job-zip-file';
import { DownloadJobZipFile$Params } from '../fn/operations/download-job-zip-file';
import { downloadManual } from '../fn/operations/download-manual';
import { DownloadManual$Params } from '../fn/operations/download-manual';
import { downloadManualOld } from '../fn/operations/download-manual-old';
import { DownloadManualOld$Params } from '../fn/operations/download-manual-old';
import { DurableJobDeployStatusVmForReturn } from '../models/durable-job-deploy-status-vm-for-return';
import { FullCrawlJobVm } from '../models/full-crawl-job-vm';
import { getCachedDocumotoJobs } from '../fn/operations/get-cached-documoto-jobs';
import { GetCachedDocumotoJobs$Params } from '../fn/operations/get-cached-documoto-jobs';
import { getCachedDocumotoJobs$Plain } from '../fn/operations/get-cached-documoto-jobs-plain';
import { GetCachedDocumotoJobs$Plain$Params } from '../fn/operations/get-cached-documoto-jobs-plain';
import { getCachedValue } from '../fn/operations/get-cached-value';
import { GetCachedValue$Params } from '../fn/operations/get-cached-value';
import { getCachedValue$Plain } from '../fn/operations/get-cached-value-plain';
import { GetCachedValue$Plain$Params } from '../fn/operations/get-cached-value-plain';
import { getDocumotoMdzJobStatus } from '../fn/operations/get-documoto-mdz-job-status';
import { GetDocumotoMdzJobStatus$Params } from '../fn/operations/get-documoto-mdz-job-status';
import { getDocumotoMdzJobStatus$Plain } from '../fn/operations/get-documoto-mdz-job-status-plain';
import { GetDocumotoMdzJobStatus$Plain$Params } from '../fn/operations/get-documoto-mdz-job-status-plain';
import { getDownloadedManualJobDetails } from '../fn/operations/get-downloaded-manual-job-details';
import { GetDownloadedManualJobDetails$Params } from '../fn/operations/get-downloaded-manual-job-details';
import { getDownloadedManualJobDetails$Plain } from '../fn/operations/get-downloaded-manual-job-details-plain';
import { GetDownloadedManualJobDetails$Plain$Params } from '../fn/operations/get-downloaded-manual-job-details-plain';
import { getDownloadedManualStatistics } from '../fn/operations/get-downloaded-manual-statistics';
import { GetDownloadedManualStatistics$Params } from '../fn/operations/get-downloaded-manual-statistics';
import { getDownloadedManualStatistics$Plain } from '../fn/operations/get-downloaded-manual-statistics-plain';
import { GetDownloadedManualStatistics$Plain$Params } from '../fn/operations/get-downloaded-manual-statistics-plain';
import { getGlobalConfigCreatedDate } from '../fn/operations/get-global-config-created-date';
import { GetGlobalConfigCreatedDate$Params } from '../fn/operations/get-global-config-created-date';
import { getGlobalConfigCreatedDate$Plain } from '../fn/operations/get-global-config-created-date-plain';
import { GetGlobalConfigCreatedDate$Plain$Params } from '../fn/operations/get-global-config-created-date-plain';
import { getGlobalConfigErrors } from '../fn/operations/get-global-config-errors';
import { GetGlobalConfigErrors$Params } from '../fn/operations/get-global-config-errors';
import { getGlobalConfigErrors$Plain } from '../fn/operations/get-global-config-errors-plain';
import { GetGlobalConfigErrors$Plain$Params } from '../fn/operations/get-global-config-errors-plain';
import { getIntegrationTestMessage } from '../fn/operations/get-integration-test-message';
import { GetIntegrationTestMessage$Params } from '../fn/operations/get-integration-test-message';
import { getIntegrationTestMessage$Plain } from '../fn/operations/get-integration-test-message-plain';
import { GetIntegrationTestMessage$Plain$Params } from '../fn/operations/get-integration-test-message-plain';
import { getJobDuraleFunctionStatus } from '../fn/operations/get-job-durale-function-status';
import { GetJobDuraleFunctionStatus$Params } from '../fn/operations/get-job-durale-function-status';
import { getJobDuraleFunctionStatus$Plain } from '../fn/operations/get-job-durale-function-status-plain';
import { GetJobDuraleFunctionStatus$Plain$Params } from '../fn/operations/get-job-durale-function-status-plain';
import { GetJobsForOperationsResponseVm } from '../models/get-jobs-for-operations-response-vm';
import { GetJobsMetadataForOperationsQueryResponseVm } from '../models/get-jobs-metadata-for-operations-query-response-vm';
import { getMissingPdfCount } from '../fn/operations/get-missing-pdf-count';
import { GetMissingPdfCount$Params } from '../fn/operations/get-missing-pdf-count';
import { getMissingPdfCount$Plain } from '../fn/operations/get-missing-pdf-count-plain';
import { GetMissingPdfCount$Plain$Params } from '../fn/operations/get-missing-pdf-count-plain';
import { getOrderNumbers } from '../fn/operations/get-order-numbers';
import { GetOrderNumbers$Params } from '../fn/operations/get-order-numbers';
import { getOrderNumbers$Plain } from '../fn/operations/get-order-numbers-plain';
import { GetOrderNumbers$Plain$Params } from '../fn/operations/get-order-numbers-plain';
import { getServicebusQueues } from '../fn/operations/get-servicebus-queues';
import { GetServicebusQueues$Params } from '../fn/operations/get-servicebus-queues';
import { getServicebusQueues$Plain } from '../fn/operations/get-servicebus-queues-plain';
import { GetServicebusQueues$Plain$Params } from '../fn/operations/get-servicebus-queues-plain';
import { getUnscheduledZipfiles } from '../fn/operations/get-unscheduled-zipfiles';
import { GetUnscheduledZipfiles$Params } from '../fn/operations/get-unscheduled-zipfiles';
import { getUnscheduledZipfiles$Plain } from '../fn/operations/get-unscheduled-zipfiles-plain';
import { GetUnscheduledZipfiles$Plain$Params } from '../fn/operations/get-unscheduled-zipfiles-plain';
import { GlobalConfigErrorViewModel } from '../models/global-config-error-view-model';
import { listCacheKeys } from '../fn/operations/list-cache-keys';
import { ListCacheKeys$Params } from '../fn/operations/list-cache-keys';
import { listCacheKeys$Plain } from '../fn/operations/list-cache-keys-plain';
import { ListCacheKeys$Plain$Params } from '../fn/operations/list-cache-keys-plain';
import { listDocumotoDownloadedManualsJob } from '../fn/operations/list-documoto-downloaded-manuals-job';
import { ListDocumotoDownloadedManualsJob$Params } from '../fn/operations/list-documoto-downloaded-manuals-job';
import { listDocumotoDownloadedManualsJob$Plain } from '../fn/operations/list-documoto-downloaded-manuals-job-plain';
import { ListDocumotoDownloadedManualsJob$Plain$Params } from '../fn/operations/list-documoto-downloaded-manuals-job-plain';
import { listDocumotoProcessedExtractZipJobs } from '../fn/operations/list-documoto-processed-extract-zip-jobs';
import { ListDocumotoProcessedExtractZipJobs$Params } from '../fn/operations/list-documoto-processed-extract-zip-jobs';
import { listDocumotoProcessedExtractZipJobs$Plain } from '../fn/operations/list-documoto-processed-extract-zip-jobs-plain';
import { ListDocumotoProcessedExtractZipJobs$Plain$Params } from '../fn/operations/list-documoto-processed-extract-zip-jobs-plain';
import { listDocumotoQueuedExtractZipJobs } from '../fn/operations/list-documoto-queued-extract-zip-jobs';
import { ListDocumotoQueuedExtractZipJobs$Params } from '../fn/operations/list-documoto-queued-extract-zip-jobs';
import { listDocumotoQueuedExtractZipJobs$Plain } from '../fn/operations/list-documoto-queued-extract-zip-jobs-plain';
import { ListDocumotoQueuedExtractZipJobs$Plain$Params } from '../fn/operations/list-documoto-queued-extract-zip-jobs-plain';
import { listDocumotoRunningExtractZipJobs } from '../fn/operations/list-documoto-running-extract-zip-jobs';
import { ListDocumotoRunningExtractZipJobs$Params } from '../fn/operations/list-documoto-running-extract-zip-jobs';
import { listDocumotoRunningExtractZipJobs$Plain } from '../fn/operations/list-documoto-running-extract-zip-jobs-plain';
import { ListDocumotoRunningExtractZipJobs$Plain$Params } from '../fn/operations/list-documoto-running-extract-zip-jobs-plain';
import { listDocumotoRunningSyncJobs } from '../fn/operations/list-documoto-running-sync-jobs';
import { ListDocumotoRunningSyncJobs$Params } from '../fn/operations/list-documoto-running-sync-jobs';
import { listDocumotoRunningSyncJobs$Plain } from '../fn/operations/list-documoto-running-sync-jobs-plain';
import { ListDocumotoRunningSyncJobs$Plain$Params } from '../fn/operations/list-documoto-running-sync-jobs-plain';
import { listJobMetadata } from '../fn/operations/list-job-metadata';
import { ListJobMetadata$Params } from '../fn/operations/list-job-metadata';
import { listJobMetadata$Plain } from '../fn/operations/list-job-metadata-plain';
import { ListJobMetadata$Plain$Params } from '../fn/operations/list-job-metadata-plain';
import { listJobs } from '../fn/operations/list-jobs';
import { ListJobs$Params } from '../fn/operations/list-jobs';
import { listJobs$Plain } from '../fn/operations/list-jobs-plain';
import { ListJobs$Plain$Params } from '../fn/operations/list-jobs-plain';
import { moveQueuedJobToTop } from '../fn/operations/move-queued-job-to-top';
import { MoveQueuedJobToTop$Params } from '../fn/operations/move-queued-job-to-top';
import { moveQueuedJobToTop$Plain } from '../fn/operations/move-queued-job-to-top-plain';
import { MoveQueuedJobToTop$Plain$Params } from '../fn/operations/move-queued-job-to-top-plain';
import { OperationsOrderNumbersVm } from '../models/operations-order-numbers-vm';
import { OperationsPdfNumbersVm } from '../models/operations-pdf-numbers-vm';
import { pauseDocumotoZipFileProcessing } from '../fn/operations/pause-documoto-zip-file-processing';
import { PauseDocumotoZipFileProcessing$Params } from '../fn/operations/pause-documoto-zip-file-processing';
import { pauseDocumotoZipFileProcessing$Plain } from '../fn/operations/pause-documoto-zip-file-processing-plain';
import { PauseDocumotoZipFileProcessing$Plain$Params } from '../fn/operations/pause-documoto-zip-file-processing-plain';
import { purgeRedisCache } from '../fn/operations/purge-redis-cache';
import { PurgeRedisCache$Params } from '../fn/operations/purge-redis-cache';
import { purgeRedisCache$Plain } from '../fn/operations/purge-redis-cache-plain';
import { PurgeRedisCache$Plain$Params } from '../fn/operations/purge-redis-cache-plain';
import { QueueCountResultApiResponse } from '../models/queue-count-result-api-response';
import { rebuildGlobalConfig } from '../fn/operations/rebuild-global-config';
import { RebuildGlobalConfig$Params } from '../fn/operations/rebuild-global-config';
import { rebuildGlobalConfig$Plain } from '../fn/operations/rebuild-global-config-plain';
import { RebuildGlobalConfig$Plain$Params } from '../fn/operations/rebuild-global-config-plain';
import { reImportDocumotoMedia } from '../fn/operations/re-import-documoto-media';
import { ReImportDocumotoMedia$Params } from '../fn/operations/re-import-documoto-media';
import { reprocessZipFiles } from '../fn/operations/reprocess-zip-files';
import { ReprocessZipFiles$Params } from '../fn/operations/reprocess-zip-files';
import { reprocessZipFiles$Plain } from '../fn/operations/reprocess-zip-files-plain';
import { ReprocessZipFiles$Plain$Params } from '../fn/operations/reprocess-zip-files-plain';
import { resumeDocumotoZipFileProcessing } from '../fn/operations/resume-documoto-zip-file-processing';
import { ResumeDocumotoZipFileProcessing$Params } from '../fn/operations/resume-documoto-zip-file-processing';
import { resumeDocumotoZipFileProcessing$Plain } from '../fn/operations/resume-documoto-zip-file-processing-plain';
import { ResumeDocumotoZipFileProcessing$Plain$Params } from '../fn/operations/resume-documoto-zip-file-processing-plain';
import { searchProcessedFiles } from '../fn/operations/search-processed-files';
import { SearchProcessedFiles$Params } from '../fn/operations/search-processed-files';
import { searchProcessedFiles$Plain } from '../fn/operations/search-processed-files-plain';
import { SearchProcessedFiles$Plain$Params } from '../fn/operations/search-processed-files-plain';
import { startDocumotoExtractZipFileJob } from '../fn/operations/start-documoto-extract-zip-file-job';
import { StartDocumotoExtractZipFileJob$Params } from '../fn/operations/start-documoto-extract-zip-file-job';
import { startDocumotoExtractZipFileJob$Plain } from '../fn/operations/start-documoto-extract-zip-file-job-plain';
import { StartDocumotoExtractZipFileJob$Plain$Params } from '../fn/operations/start-documoto-extract-zip-file-job-plain';
import { startDownloadDocumotoMdzJob } from '../fn/operations/start-download-documoto-mdz-job';
import { StartDownloadDocumotoMdzJob$Params } from '../fn/operations/start-download-documoto-mdz-job';
import { startDownloadDocumotoMdzJob$Plain } from '../fn/operations/start-download-documoto-mdz-job-plain';
import { StartDownloadDocumotoMdzJob$Plain$Params } from '../fn/operations/start-download-documoto-mdz-job-plain';
import { submitAcknowledgeSalesOrderMessage } from '../fn/operations/submit-acknowledge-sales-order-message';
import { SubmitAcknowledgeSalesOrderMessage$Params } from '../fn/operations/submit-acknowledge-sales-order-message';
import { submitIntegrationTestMessage } from '../fn/operations/submit-integration-test-message';
import { SubmitIntegrationTestMessage$Params } from '../fn/operations/submit-integration-test-message';
import { terminateDocumotoExtractZipJob } from '../fn/operations/terminate-documoto-extract-zip-job';
import { TerminateDocumotoExtractZipJob$Params } from '../fn/operations/terminate-documoto-extract-zip-job';
import { terminateDocumotoExtractZipJob$Plain } from '../fn/operations/terminate-documoto-extract-zip-job-plain';
import { TerminateDocumotoExtractZipJob$Plain$Params } from '../fn/operations/terminate-documoto-extract-zip-job-plain';
import { terminateOrDeleteDownloadManualJob } from '../fn/operations/terminate-or-delete-download-manual-job';
import { TerminateOrDeleteDownloadManualJob$Params } from '../fn/operations/terminate-or-delete-download-manual-job';
import { TerminateOrRemovedJobResponseVm } from '../models/terminate-or-removed-job-response-vm';
import { terminateRunningJob } from '../fn/operations/terminate-running-job';
import { TerminateRunningJob$Params } from '../fn/operations/terminate-running-job';
import { terminateRunningJob$Plain } from '../fn/operations/terminate-running-job-plain';
import { TerminateRunningJob$Plain$Params } from '../fn/operations/terminate-running-job-plain';
import { triggerExpirationOfDownloadedManual } from '../fn/operations/trigger-expiration-of-downloaded-manual';
import { TriggerExpirationOfDownloadedManual$Params } from '../fn/operations/trigger-expiration-of-downloaded-manual';
import { UnscheduledZipFilesResponseViewModel } from '../models/unscheduled-zip-files-response-view-model';
import { WebjobStatusResponseVm } from '../models/webjob-status-response-vm';

@Injectable({ providedIn: 'root' })
export class OperationsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `operationsControllerListJobsrequest()` */
  static readonly OperationsControllerListJobsrequestPath = '/operations/jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listJobs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listJobs$Plain$Response(params?: ListJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetJobsForOperationsResponseVm>> {
    return listJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listJobs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listJobs$Plain(params?: ListJobs$Plain$Params, context?: HttpContext): Observable<GetJobsForOperationsResponseVm> {
    return this.listJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetJobsForOperationsResponseVm>): GetJobsForOperationsResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listJobs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listJobs$Response(params?: ListJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<GetJobsForOperationsResponseVm>> {
    return listJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listJobs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listJobs(params?: ListJobs$Params, context?: HttpContext): Observable<GetJobsForOperationsResponseVm> {
    return this.listJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetJobsForOperationsResponseVm>): GetJobsForOperationsResponseVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerTerminateRunningJobdatabaseEntityTypeNameJobId()` */
  static readonly OperationsControllerTerminateRunningJobdatabaseEntityTypeNameJobIdPath = '/operations/jobs/running/terminate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `terminateRunningJob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateRunningJob$Plain$Response(params?: TerminateRunningJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TerminateOrRemovedJobResponseVm>> {
    return terminateRunningJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `terminateRunningJob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateRunningJob$Plain(params?: TerminateRunningJob$Plain$Params, context?: HttpContext): Observable<TerminateOrRemovedJobResponseVm> {
    return this.terminateRunningJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TerminateOrRemovedJobResponseVm>): TerminateOrRemovedJobResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `terminateRunningJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateRunningJob$Response(params?: TerminateRunningJob$Params, context?: HttpContext): Observable<StrictHttpResponse<TerminateOrRemovedJobResponseVm>> {
    return terminateRunningJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `terminateRunningJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateRunningJob(params?: TerminateRunningJob$Params, context?: HttpContext): Observable<TerminateOrRemovedJobResponseVm> {
    return this.terminateRunningJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<TerminateOrRemovedJobResponseVm>): TerminateOrRemovedJobResponseVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetJobDuraleFunctionStatusencryptedStatusUrl()` */
  static readonly OperationsControllerGetJobDuraleFunctionStatusencryptedStatusUrlPath = '/operations/jobs/running/status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJobDuraleFunctionStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJobDuraleFunctionStatus$Plain$Response(params?: GetJobDuraleFunctionStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WebjobStatusResponseVm>> {
    return getJobDuraleFunctionStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getJobDuraleFunctionStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJobDuraleFunctionStatus$Plain(params?: GetJobDuraleFunctionStatus$Plain$Params, context?: HttpContext): Observable<WebjobStatusResponseVm> {
    return this.getJobDuraleFunctionStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebjobStatusResponseVm>): WebjobStatusResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJobDuraleFunctionStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJobDuraleFunctionStatus$Response(params?: GetJobDuraleFunctionStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<WebjobStatusResponseVm>> {
    return getJobDuraleFunctionStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getJobDuraleFunctionStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJobDuraleFunctionStatus(params?: GetJobDuraleFunctionStatus$Params, context?: HttpContext): Observable<WebjobStatusResponseVm> {
    return this.getJobDuraleFunctionStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebjobStatusResponseVm>): WebjobStatusResponseVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerListJobMetadata()` */
  static readonly OperationsControllerListJobMetadataPath = '/operations/jobs/metadata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listJobMetadata$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobMetadata$Plain$Response(params?: ListJobMetadata$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetJobsMetadataForOperationsQueryResponseVm>> {
    return listJobMetadata$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listJobMetadata$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobMetadata$Plain(params?: ListJobMetadata$Plain$Params, context?: HttpContext): Observable<GetJobsMetadataForOperationsQueryResponseVm> {
    return this.listJobMetadata$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetJobsMetadataForOperationsQueryResponseVm>): GetJobsMetadataForOperationsQueryResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listJobMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobMetadata$Response(params?: ListJobMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<GetJobsMetadataForOperationsQueryResponseVm>> {
    return listJobMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listJobMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobMetadata(params?: ListJobMetadata$Params, context?: HttpContext): Observable<GetJobsMetadataForOperationsQueryResponseVm> {
    return this.listJobMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetJobsMetadataForOperationsQueryResponseVm>): GetJobsMetadataForOperationsQueryResponseVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerSubmitIntegrationTestMessagemessage()` */
  static readonly OperationsControllerSubmitIntegrationTestMessagemessagePath = '/operations/submit-integration-test-message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitIntegrationTestMessage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitIntegrationTestMessage$Response(params?: SubmitIntegrationTestMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return submitIntegrationTestMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitIntegrationTestMessage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitIntegrationTestMessage(params?: SubmitIntegrationTestMessage$Params, context?: HttpContext): Observable<void> {
    return this.submitIntegrationTestMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetIntegrationTestMessagefilename()` */
  static readonly OperationsControllerGetIntegrationTestMessagefilenamePath = '/operations/integration-test-message-tempate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationTestMessage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationTestMessage$Plain$Response(params?: GetIntegrationTestMessage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getIntegrationTestMessage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationTestMessage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationTestMessage$Plain(params?: GetIntegrationTestMessage$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getIntegrationTestMessage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationTestMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationTestMessage$Response(params?: GetIntegrationTestMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getIntegrationTestMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationTestMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationTestMessage(params?: GetIntegrationTestMessage$Params, context?: HttpContext): Observable<string> {
    return this.getIntegrationTestMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `operationsControllerSubmitAcknowledgeSalesOrderMessagemessage()` */
  static readonly OperationsControllerSubmitAcknowledgeSalesOrderMessagemessagePath = '/operations/submitmessage/mibp_acknowledge_sales_order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitAcknowledgeSalesOrderMessage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitAcknowledgeSalesOrderMessage$Response(params?: SubmitAcknowledgeSalesOrderMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return submitAcknowledgeSalesOrderMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitAcknowledgeSalesOrderMessage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitAcknowledgeSalesOrderMessage(params?: SubmitAcknowledgeSalesOrderMessage$Params, context?: HttpContext): Observable<void> {
    return this.submitAcknowledgeSalesOrderMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetServicebusQueues()` */
  static readonly OperationsControllerGetServicebusQueuesPath = '/operations/queues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServicebusQueues$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServicebusQueues$Plain$Response(params?: GetServicebusQueues$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<QueueCountResultApiResponse>> {
    return getServicebusQueues$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServicebusQueues$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServicebusQueues$Plain(params?: GetServicebusQueues$Plain$Params, context?: HttpContext): Observable<QueueCountResultApiResponse> {
    return this.getServicebusQueues$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueueCountResultApiResponse>): QueueCountResultApiResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServicebusQueues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServicebusQueues$Response(params?: GetServicebusQueues$Params, context?: HttpContext): Observable<StrictHttpResponse<QueueCountResultApiResponse>> {
    return getServicebusQueues(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getServicebusQueues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServicebusQueues(params?: GetServicebusQueues$Params, context?: HttpContext): Observable<QueueCountResultApiResponse> {
    return this.getServicebusQueues$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueueCountResultApiResponse>): QueueCountResultApiResponse => r.body)
    );
  }

  /** Path part for operation `operationsControllerCreateMissingDocumotoZipJobsrequest()` */
  static readonly OperationsControllerCreateMissingDocumotoZipJobsrequestPath = '/operations/documoto/jobs/extractzip/enqueue';

  /**
   * Will create jobs for files in documoto zip storage that do not already have
   * a pending job.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMissingDocumotoZipJobs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMissingDocumotoZipJobs$Plain$Response(params?: CreateMissingDocumotoZipJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return createMissingDocumotoZipJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Will create jobs for files in documoto zip storage that do not already have
   * a pending job.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMissingDocumotoZipJobs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMissingDocumotoZipJobs$Plain(params?: CreateMissingDocumotoZipJobs$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.createMissingDocumotoZipJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * Will create jobs for files in documoto zip storage that do not already have
   * a pending job.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMissingDocumotoZipJobs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMissingDocumotoZipJobs$Response(params?: CreateMissingDocumotoZipJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return createMissingDocumotoZipJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * Will create jobs for files in documoto zip storage that do not already have
   * a pending job.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMissingDocumotoZipJobs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMissingDocumotoZipJobs(params?: CreateMissingDocumotoZipJobs$Params, context?: HttpContext): Observable<Array<string>> {
    return this.createMissingDocumotoZipJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `operationsControllerSearchProcessedFilesfilenamePrefix()` */
  static readonly OperationsControllerSearchProcessedFilesfilenamePrefixPath = '/operations/documoto/jobs/extractzip/processedfiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchProcessedFiles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchProcessedFiles$Plain$Response(params?: SearchProcessedFiles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BlobPagedResultViewModel>> {
    return searchProcessedFiles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchProcessedFiles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchProcessedFiles$Plain(params?: SearchProcessedFiles$Plain$Params, context?: HttpContext): Observable<BlobPagedResultViewModel> {
    return this.searchProcessedFiles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlobPagedResultViewModel>): BlobPagedResultViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchProcessedFiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchProcessedFiles$Response(params?: SearchProcessedFiles$Params, context?: HttpContext): Observable<StrictHttpResponse<BlobPagedResultViewModel>> {
    return searchProcessedFiles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchProcessedFiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchProcessedFiles(params?: SearchProcessedFiles$Params, context?: HttpContext): Observable<BlobPagedResultViewModel> {
    return this.searchProcessedFiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlobPagedResultViewModel>): BlobPagedResultViewModel => r.body)
    );
  }

  /** Path part for operation `operationsControllerReprocessZipFilesrequest()` */
  static readonly OperationsControllerReprocessZipFilesrequestPath = '/operations/documoto/jobs/extractzip/reprocess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reprocessZipFiles$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reprocessZipFiles$Plain$Response(params?: ReprocessZipFiles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoReprocessZipFileResponseVm>> {
    return reprocessZipFiles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reprocessZipFiles$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reprocessZipFiles$Plain(params?: ReprocessZipFiles$Plain$Params, context?: HttpContext): Observable<DocumotoReprocessZipFileResponseVm> {
    return this.reprocessZipFiles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoReprocessZipFileResponseVm>): DocumotoReprocessZipFileResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reprocessZipFiles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reprocessZipFiles$Response(params?: ReprocessZipFiles$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoReprocessZipFileResponseVm>> {
    return reprocessZipFiles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reprocessZipFiles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reprocessZipFiles(params?: ReprocessZipFiles$Params, context?: HttpContext): Observable<DocumotoReprocessZipFileResponseVm> {
    return this.reprocessZipFiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoReprocessZipFileResponseVm>): DocumotoReprocessZipFileResponseVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetUnscheduledZipfiles()` */
  static readonly OperationsControllerGetUnscheduledZipfilesPath = '/operations/documoto/jobs/extractzip/zombiefiles';

  /**
   * Get a list of the documoto zipfiles in blobstorage that do not have a scheduled job.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnscheduledZipfiles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnscheduledZipfiles$Plain$Response(params?: GetUnscheduledZipfiles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UnscheduledZipFilesResponseViewModel>> {
    return getUnscheduledZipfiles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of the documoto zipfiles in blobstorage that do not have a scheduled job.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnscheduledZipfiles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnscheduledZipfiles$Plain(params?: GetUnscheduledZipfiles$Plain$Params, context?: HttpContext): Observable<UnscheduledZipFilesResponseViewModel> {
    return this.getUnscheduledZipfiles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnscheduledZipFilesResponseViewModel>): UnscheduledZipFilesResponseViewModel => r.body)
    );
  }

  /**
   * Get a list of the documoto zipfiles in blobstorage that do not have a scheduled job.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnscheduledZipfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnscheduledZipfiles$Response(params?: GetUnscheduledZipfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<UnscheduledZipFilesResponseViewModel>> {
    return getUnscheduledZipfiles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of the documoto zipfiles in blobstorage that do not have a scheduled job.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnscheduledZipfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnscheduledZipfiles(params?: GetUnscheduledZipfiles$Params, context?: HttpContext): Observable<UnscheduledZipFilesResponseViewModel> {
    return this.getUnscheduledZipfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnscheduledZipFilesResponseViewModel>): UnscheduledZipFilesResponseViewModel => r.body)
    );
  }

  /** Path part for operation `operationsControllerPauseDocumotoZipFileProcessing()` */
  static readonly OperationsControllerPauseDocumotoZipFileProcessingPath = '/operations/documoto/jobs/extractzip/hold';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pauseDocumotoZipFileProcessing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  pauseDocumotoZipFileProcessing$Plain$Response(params?: PauseDocumotoZipFileProcessing$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DurableJobDeployStatusVmForReturn>> {
    return pauseDocumotoZipFileProcessing$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pauseDocumotoZipFileProcessing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pauseDocumotoZipFileProcessing$Plain(params?: PauseDocumotoZipFileProcessing$Plain$Params, context?: HttpContext): Observable<DurableJobDeployStatusVmForReturn> {
    return this.pauseDocumotoZipFileProcessing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DurableJobDeployStatusVmForReturn>): DurableJobDeployStatusVmForReturn => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pauseDocumotoZipFileProcessing()` instead.
   *
   * This method doesn't expect any request body.
   */
  pauseDocumotoZipFileProcessing$Response(params?: PauseDocumotoZipFileProcessing$Params, context?: HttpContext): Observable<StrictHttpResponse<DurableJobDeployStatusVmForReturn>> {
    return pauseDocumotoZipFileProcessing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pauseDocumotoZipFileProcessing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pauseDocumotoZipFileProcessing(params?: PauseDocumotoZipFileProcessing$Params, context?: HttpContext): Observable<DurableJobDeployStatusVmForReturn> {
    return this.pauseDocumotoZipFileProcessing$Response(params, context).pipe(
      map((r: StrictHttpResponse<DurableJobDeployStatusVmForReturn>): DurableJobDeployStatusVmForReturn => r.body)
    );
  }

  /** Path part for operation `operationsControllerResumeDocumotoZipFileProcessing()` */
  static readonly OperationsControllerResumeDocumotoZipFileProcessingPath = '/operations/documoto/jobs/extractzip/resume';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resumeDocumotoZipFileProcessing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  resumeDocumotoZipFileProcessing$Plain$Response(params?: ResumeDocumotoZipFileProcessing$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return resumeDocumotoZipFileProcessing$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resumeDocumotoZipFileProcessing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resumeDocumotoZipFileProcessing$Plain(params?: ResumeDocumotoZipFileProcessing$Plain$Params, context?: HttpContext): Observable<number> {
    return this.resumeDocumotoZipFileProcessing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resumeDocumotoZipFileProcessing()` instead.
   *
   * This method doesn't expect any request body.
   */
  resumeDocumotoZipFileProcessing$Response(params?: ResumeDocumotoZipFileProcessing$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return resumeDocumotoZipFileProcessing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resumeDocumotoZipFileProcessing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resumeDocumotoZipFileProcessing(params?: ResumeDocumotoZipFileProcessing$Params, context?: HttpContext): Observable<number> {
    return this.resumeDocumotoZipFileProcessing$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `operationsControllerListDocumotoDownloadedManualsJobskipTakeQueryJobStatuses()` */
  static readonly OperationsControllerListDocumotoDownloadedManualsJobskipTakeQueryJobStatusesPath = '/operations/documoto/jobs/downloaded-manuals/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoDownloadedManualsJob$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoDownloadedManualsJob$Plain$Response(params?: ListDocumotoDownloadedManualsJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoDownloadManualJobResultVm>> {
    return listDocumotoDownloadedManualsJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoDownloadedManualsJob$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoDownloadedManualsJob$Plain(params?: ListDocumotoDownloadedManualsJob$Plain$Params, context?: HttpContext): Observable<DocumotoDownloadManualJobResultVm> {
    return this.listDocumotoDownloadedManualsJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoDownloadManualJobResultVm>): DocumotoDownloadManualJobResultVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoDownloadedManualsJob()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoDownloadedManualsJob$Response(params?: ListDocumotoDownloadedManualsJob$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoDownloadManualJobResultVm>> {
    return listDocumotoDownloadedManualsJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoDownloadedManualsJob$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoDownloadedManualsJob(params?: ListDocumotoDownloadedManualsJob$Params, context?: HttpContext): Observable<DocumotoDownloadManualJobResultVm> {
    return this.listDocumotoDownloadedManualsJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoDownloadManualJobResultVm>): DocumotoDownloadManualJobResultVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerListDocumotoRunningSyncJobsskipTakeDocumotoJobTypeOnlyRunningJobQuery()` */
  static readonly OperationsControllerListDocumotoRunningSyncJobsskipTakeDocumotoJobTypeOnlyRunningJobQueryPath = '/operations/documoto/jobs/sync/running';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoRunningSyncJobs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoRunningSyncJobs$Plain$Response(params?: ListDocumotoRunningSyncJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumotoSyncJobVm>>> {
    return listDocumotoRunningSyncJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoRunningSyncJobs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoRunningSyncJobs$Plain(params?: ListDocumotoRunningSyncJobs$Plain$Params, context?: HttpContext): Observable<Array<DocumotoSyncJobVm>> {
    return this.listDocumotoRunningSyncJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumotoSyncJobVm>>): Array<DocumotoSyncJobVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoRunningSyncJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoRunningSyncJobs$Response(params?: ListDocumotoRunningSyncJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumotoSyncJobVm>>> {
    return listDocumotoRunningSyncJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoRunningSyncJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoRunningSyncJobs(params?: ListDocumotoRunningSyncJobs$Params, context?: HttpContext): Observable<Array<DocumotoSyncJobVm>> {
    return this.listDocumotoRunningSyncJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumotoSyncJobVm>>): Array<DocumotoSyncJobVm> => r.body)
    );
  }

  /** Path part for operation `operationsControllerAddDocumotoSyncJobdocumotoJobType()` */
  static readonly OperationsControllerAddDocumotoSyncJobdocumotoJobTypePath = '/operations/documoto/jobs/sync/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDocumotoSyncJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDocumotoSyncJob$Response(params?: AddDocumotoSyncJob$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addDocumotoSyncJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDocumotoSyncJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDocumotoSyncJob(params?: AddDocumotoSyncJob$Params, context?: HttpContext): Observable<void> {
    return this.addDocumotoSyncJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerReImportDocumotoMediaidentifier()` */
  static readonly OperationsControllerReImportDocumotoMediaidentifierPath = '/operations/documoto/jobs/sync/reimport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reImportDocumotoMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  reImportDocumotoMedia$Response(params?: ReImportDocumotoMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reImportDocumotoMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reImportDocumotoMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reImportDocumotoMedia(params?: ReImportDocumotoMedia$Params, context?: HttpContext): Observable<void> {
    return this.reImportDocumotoMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerListDocumotoRunningExtractZipJobs()` */
  static readonly OperationsControllerListDocumotoRunningExtractZipJobsPath = '/operations/documoto/jobs/extractzip/running';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoRunningExtractZipJobs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoRunningExtractZipJobs$Plain$Response(params?: ListDocumotoRunningExtractZipJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumotoExtractZipFileJobVm>>> {
    return listDocumotoRunningExtractZipJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoRunningExtractZipJobs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoRunningExtractZipJobs$Plain(params?: ListDocumotoRunningExtractZipJobs$Plain$Params, context?: HttpContext): Observable<Array<DocumotoExtractZipFileJobVm>> {
    return this.listDocumotoRunningExtractZipJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumotoExtractZipFileJobVm>>): Array<DocumotoExtractZipFileJobVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoRunningExtractZipJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoRunningExtractZipJobs$Response(params?: ListDocumotoRunningExtractZipJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumotoExtractZipFileJobVm>>> {
    return listDocumotoRunningExtractZipJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoRunningExtractZipJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoRunningExtractZipJobs(params?: ListDocumotoRunningExtractZipJobs$Params, context?: HttpContext): Observable<Array<DocumotoExtractZipFileJobVm>> {
    return this.listDocumotoRunningExtractZipJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumotoExtractZipFileJobVm>>): Array<DocumotoExtractZipFileJobVm> => r.body)
    );
  }

  /** Path part for operation `operationsControllerListDocumotoQueuedExtractZipJobsquerySkipTake()` */
  static readonly OperationsControllerListDocumotoQueuedExtractZipJobsquerySkipTakePath = '/operations/documoto/jobs/extractzip/queued';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoQueuedExtractZipJobs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoQueuedExtractZipJobs$Plain$Response(params?: ListDocumotoQueuedExtractZipJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoExtractZipFileJobVmJobListAndSummaryVm>> {
    return listDocumotoQueuedExtractZipJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoQueuedExtractZipJobs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoQueuedExtractZipJobs$Plain(params?: ListDocumotoQueuedExtractZipJobs$Plain$Params, context?: HttpContext): Observable<DocumotoExtractZipFileJobVmJobListAndSummaryVm> {
    return this.listDocumotoQueuedExtractZipJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoExtractZipFileJobVmJobListAndSummaryVm>): DocumotoExtractZipFileJobVmJobListAndSummaryVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoQueuedExtractZipJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoQueuedExtractZipJobs$Response(params?: ListDocumotoQueuedExtractZipJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoExtractZipFileJobVmJobListAndSummaryVm>> {
    return listDocumotoQueuedExtractZipJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoQueuedExtractZipJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoQueuedExtractZipJobs(params?: ListDocumotoQueuedExtractZipJobs$Params, context?: HttpContext): Observable<DocumotoExtractZipFileJobVmJobListAndSummaryVm> {
    return this.listDocumotoQueuedExtractZipJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoExtractZipFileJobVmJobListAndSummaryVm>): DocumotoExtractZipFileJobVmJobListAndSummaryVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerListDocumotoProcessedExtractZipJobsquerySkipTake()` */
  static readonly OperationsControllerListDocumotoProcessedExtractZipJobsquerySkipTakePath = '/operations/documoto/jobs/extractzip/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoProcessedExtractZipJobs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoProcessedExtractZipJobs$Plain$Response(params?: ListDocumotoProcessedExtractZipJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoExtractZipFileJobVmJobListAndSummaryVm>> {
    return listDocumotoProcessedExtractZipJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoProcessedExtractZipJobs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoProcessedExtractZipJobs$Plain(params?: ListDocumotoProcessedExtractZipJobs$Plain$Params, context?: HttpContext): Observable<DocumotoExtractZipFileJobVmJobListAndSummaryVm> {
    return this.listDocumotoProcessedExtractZipJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoExtractZipFileJobVmJobListAndSummaryVm>): DocumotoExtractZipFileJobVmJobListAndSummaryVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoProcessedExtractZipJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoProcessedExtractZipJobs$Response(params?: ListDocumotoProcessedExtractZipJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoExtractZipFileJobVmJobListAndSummaryVm>> {
    return listDocumotoProcessedExtractZipJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoProcessedExtractZipJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listDocumotoProcessedExtractZipJobs(params?: ListDocumotoProcessedExtractZipJobs$Params, context?: HttpContext): Observable<DocumotoExtractZipFileJobVmJobListAndSummaryVm> {
    return this.listDocumotoProcessedExtractZipJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoExtractZipFileJobVmJobListAndSummaryVm>): DocumotoExtractZipFileJobVmJobListAndSummaryVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerTerminateDocumotoExtractZipJobjobId()` */
  static readonly OperationsControllerTerminateDocumotoExtractZipJobjobIdPath = '/operations/documoto/jobs/extractzip/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `terminateDocumotoExtractZipJob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateDocumotoExtractZipJob$Plain$Response(params: TerminateDocumotoExtractZipJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FullCrawlJobVm>>> {
    return terminateDocumotoExtractZipJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `terminateDocumotoExtractZipJob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateDocumotoExtractZipJob$Plain(params: TerminateDocumotoExtractZipJob$Plain$Params, context?: HttpContext): Observable<Array<FullCrawlJobVm>> {
    return this.terminateDocumotoExtractZipJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FullCrawlJobVm>>): Array<FullCrawlJobVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `terminateDocumotoExtractZipJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateDocumotoExtractZipJob$Response(params: TerminateDocumotoExtractZipJob$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FullCrawlJobVm>>> {
    return terminateDocumotoExtractZipJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `terminateDocumotoExtractZipJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateDocumotoExtractZipJob(params: TerminateDocumotoExtractZipJob$Params, context?: HttpContext): Observable<Array<FullCrawlJobVm>> {
    return this.terminateDocumotoExtractZipJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FullCrawlJobVm>>): Array<FullCrawlJobVm> => r.body)
    );
  }

  /** Path part for operation `operationsControllerMoveQueuedJobToTopjobId()` */
  static readonly OperationsControllerMoveQueuedJobToTopjobIdPath = '/operations/documoto/jobs/extractzip/{jobId}/movetotop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveQueuedJobToTop$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveQueuedJobToTop$Plain$Response(params: MoveQueuedJobToTop$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FullCrawlJobVm>>> {
    return moveQueuedJobToTop$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveQueuedJobToTop$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveQueuedJobToTop$Plain(params: MoveQueuedJobToTop$Plain$Params, context?: HttpContext): Observable<Array<FullCrawlJobVm>> {
    return this.moveQueuedJobToTop$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FullCrawlJobVm>>): Array<FullCrawlJobVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveQueuedJobToTop()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveQueuedJobToTop$Response(params: MoveQueuedJobToTop$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FullCrawlJobVm>>> {
    return moveQueuedJobToTop(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveQueuedJobToTop$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveQueuedJobToTop(params: MoveQueuedJobToTop$Params, context?: HttpContext): Observable<Array<FullCrawlJobVm>> {
    return this.moveQueuedJobToTop$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FullCrawlJobVm>>): Array<FullCrawlJobVm> => r.body)
    );
  }

  /** Path part for operation `operationsControllerRebuildGlobalConfig()` */
  static readonly OperationsControllerRebuildGlobalConfigPath = '/operations/cache/globalconfig/rebuild';

  /**
   * Will refresh GlobalConfig from _configuration/Secrets and replace the currently cached version.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rebuildGlobalConfig$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rebuildGlobalConfig$Plain$Response(params?: RebuildGlobalConfig$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return rebuildGlobalConfig$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Will refresh GlobalConfig from _configuration/Secrets and replace the currently cached version.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rebuildGlobalConfig$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rebuildGlobalConfig$Plain(params?: RebuildGlobalConfig$Plain$Params, context?: HttpContext): Observable<string> {
    return this.rebuildGlobalConfig$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Will refresh GlobalConfig from _configuration/Secrets and replace the currently cached version.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rebuildGlobalConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  rebuildGlobalConfig$Response(params?: RebuildGlobalConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return rebuildGlobalConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Will refresh GlobalConfig from _configuration/Secrets and replace the currently cached version.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rebuildGlobalConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rebuildGlobalConfig(params?: RebuildGlobalConfig$Params, context?: HttpContext): Observable<string> {
    return this.rebuildGlobalConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetGlobalConfigCreatedDate()` */
  static readonly OperationsControllerGetGlobalConfigCreatedDatePath = '/operations/cache/globalconfig/date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalConfigCreatedDate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfigCreatedDate$Plain$Response(params?: GetGlobalConfigCreatedDate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getGlobalConfigCreatedDate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalConfigCreatedDate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfigCreatedDate$Plain(params?: GetGlobalConfigCreatedDate$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getGlobalConfigCreatedDate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalConfigCreatedDate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfigCreatedDate$Response(params?: GetGlobalConfigCreatedDate$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getGlobalConfigCreatedDate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalConfigCreatedDate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfigCreatedDate(params?: GetGlobalConfigCreatedDate$Params, context?: HttpContext): Observable<string> {
    return this.getGlobalConfigCreatedDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetGlobalConfigErrors()` */
  static readonly OperationsControllerGetGlobalConfigErrorsPath = '/operations/cache/globalconfig/errors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalConfigErrors$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfigErrors$Plain$Response(params?: GetGlobalConfigErrors$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GlobalConfigErrorViewModel>>> {
    return getGlobalConfigErrors$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalConfigErrors$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfigErrors$Plain(params?: GetGlobalConfigErrors$Plain$Params, context?: HttpContext): Observable<Array<GlobalConfigErrorViewModel>> {
    return this.getGlobalConfigErrors$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GlobalConfigErrorViewModel>>): Array<GlobalConfigErrorViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalConfigErrors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfigErrors$Response(params?: GetGlobalConfigErrors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GlobalConfigErrorViewModel>>> {
    return getGlobalConfigErrors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalConfigErrors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfigErrors(params?: GetGlobalConfigErrors$Params, context?: HttpContext): Observable<Array<GlobalConfigErrorViewModel>> {
    return this.getGlobalConfigErrors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GlobalConfigErrorViewModel>>): Array<GlobalConfigErrorViewModel> => r.body)
    );
  }

  /** Path part for operation `operationsControllerDeleteFromCachekeyOrPrefixDeleteStartingWith()` */
  static readonly OperationsControllerDeleteFromCachekeyOrPrefixDeleteStartingWithPath = '/operations/cache/{keyOrPrefix}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFromCache$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFromCache$Plain$Response(params: DeleteFromCache$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return deleteFromCache$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFromCache$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFromCache$Plain(params: DeleteFromCache$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.deleteFromCache$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFromCache()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFromCache$Response(params: DeleteFromCache$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return deleteFromCache(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFromCache$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFromCache(params: DeleteFromCache$Params, context?: HttpContext): Observable<Array<string>> {
    return this.deleteFromCache$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `operationsControllerListCacheKeyskeyOrPrefix()` */
  static readonly OperationsControllerListCacheKeyskeyOrPrefixPath = '/operations/cache';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCacheKeys$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCacheKeys$Plain$Response(params?: ListCacheKeys$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return listCacheKeys$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listCacheKeys$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCacheKeys$Plain(params?: ListCacheKeys$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.listCacheKeys$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCacheKeys()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCacheKeys$Response(params?: ListCacheKeys$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return listCacheKeys(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listCacheKeys$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCacheKeys(params?: ListCacheKeys$Params, context?: HttpContext): Observable<Array<string>> {
    return this.listCacheKeys$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `operationsControllerDeleteCachedKeyskeys()` */
  static readonly OperationsControllerDeleteCachedKeyskeysPath = '/operations/cache';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCachedKeys$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteCachedKeys$Plain$Response(params?: DeleteCachedKeys$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return deleteCachedKeys$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCachedKeys$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteCachedKeys$Plain(params?: DeleteCachedKeys$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.deleteCachedKeys$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCachedKeys()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteCachedKeys$Response(params?: DeleteCachedKeys$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return deleteCachedKeys(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCachedKeys$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteCachedKeys(params?: DeleteCachedKeys$Params, context?: HttpContext): Observable<Array<string>> {
    return this.deleteCachedKeys$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `operationsControllerPurgeRedisCache()` */
  static readonly OperationsControllerPurgeRedisCachePath = '/operations/cache/purge';

  /**
   * Remove all cached values.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `purgeRedisCache$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  purgeRedisCache$Plain$Response(params?: PurgeRedisCache$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return purgeRedisCache$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove all cached values.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `purgeRedisCache$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  purgeRedisCache$Plain(params?: PurgeRedisCache$Plain$Params, context?: HttpContext): Observable<number> {
    return this.purgeRedisCache$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Remove all cached values.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `purgeRedisCache()` instead.
   *
   * This method doesn't expect any request body.
   */
  purgeRedisCache$Response(params?: PurgeRedisCache$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return purgeRedisCache(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove all cached values.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `purgeRedisCache$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  purgeRedisCache(params?: PurgeRedisCache$Params, context?: HttpContext): Observable<number> {
    return this.purgeRedisCache$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetCachedValuekey()` */
  static readonly OperationsControllerGetCachedValuekeyPath = '/operations/cache/value';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedValue$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedValue$Plain$Response(params?: GetCachedValue$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getCachedValue$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedValue$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedValue$Plain(params?: GetCachedValue$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getCachedValue$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedValue$Response(params?: GetCachedValue$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getCachedValue(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedValue(params?: GetCachedValue$Params, context?: HttpContext): Observable<string> {
    return this.getCachedValue$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetOrderNumbersorderType()` */
  static readonly OperationsControllerGetOrderNumbersorderTypePath = '/operations/orders/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderNumbers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderNumbers$Plain$Response(params?: GetOrderNumbers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationsOrderNumbersVm>> {
    return getOrderNumbers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderNumbers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderNumbers$Plain(params?: GetOrderNumbers$Plain$Params, context?: HttpContext): Observable<OperationsOrderNumbersVm> {
    return this.getOrderNumbers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationsOrderNumbersVm>): OperationsOrderNumbersVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderNumbers$Response(params?: GetOrderNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationsOrderNumbersVm>> {
    return getOrderNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderNumbers(params?: GetOrderNumbers$Params, context?: HttpContext): Observable<OperationsOrderNumbersVm> {
    return this.getOrderNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationsOrderNumbersVm>): OperationsOrderNumbersVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetMissingPdfCountdays()` */
  static readonly OperationsControllerGetMissingPdfCountdaysPath = '/operations/orders/missing-pdfs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMissingPdfCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMissingPdfCount$Plain$Response(params?: GetMissingPdfCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationsPdfNumbersVm>> {
    return getMissingPdfCount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMissingPdfCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMissingPdfCount$Plain(params?: GetMissingPdfCount$Plain$Params, context?: HttpContext): Observable<OperationsPdfNumbersVm> {
    return this.getMissingPdfCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationsPdfNumbersVm>): OperationsPdfNumbersVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMissingPdfCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMissingPdfCount$Response(params?: GetMissingPdfCount$Params, context?: HttpContext): Observable<StrictHttpResponse<OperationsPdfNumbersVm>> {
    return getMissingPdfCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMissingPdfCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMissingPdfCount(params?: GetMissingPdfCount$Params, context?: HttpContext): Observable<OperationsPdfNumbersVm> {
    return this.getMissingPdfCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<OperationsPdfNumbersVm>): OperationsPdfNumbersVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerDownloadManualmediaId()` */
  static readonly OperationsControllerDownloadManualmediaIdPath = '/operations/documoto/jobs/downloaded-manuals/pdf/{mediaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadManual()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadManual$Response(params: DownloadManual$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadManual(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadManual$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadManual(params: DownloadManual$Params, context?: HttpContext): Observable<void> {
    return this.downloadManual$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerDownloadJobZipFilejobIdGetFailedFile()` */
  static readonly OperationsControllerDownloadJobZipFilejobIdGetFailedFilePath = '/operations/documoto/jobs/downloaded-manuals/zip/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadJobZipFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadJobZipFile$Response(params: DownloadJobZipFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadJobZipFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadJobZipFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadJobZipFile(params: DownloadJobZipFile$Params, context?: HttpContext): Observable<void> {
    return this.downloadJobZipFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerAddDownloadManualJobmediaIdentifierAddUserDownloadAddJobEvenIfDownloadedEquipmentId()` */
  static readonly OperationsControllerAddDownloadManualJobmediaIdentifierAddUserDownloadAddJobEvenIfDownloadedEquipmentIdPath = '/operations/documoto/jobs/downloaded-manuals/process/add/{mediaIdentifier}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDownloadManualJob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDownloadManualJob$Plain$Response(params: AddDownloadManualJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AddDownloadManualsJobCommandResultVm>> {
    return addDownloadManualJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDownloadManualJob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDownloadManualJob$Plain(params: AddDownloadManualJob$Plain$Params, context?: HttpContext): Observable<AddDownloadManualsJobCommandResultVm> {
    return this.addDownloadManualJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddDownloadManualsJobCommandResultVm>): AddDownloadManualsJobCommandResultVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDownloadManualJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDownloadManualJob$Response(params: AddDownloadManualJob$Params, context?: HttpContext): Observable<StrictHttpResponse<AddDownloadManualsJobCommandResultVm>> {
    return addDownloadManualJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDownloadManualJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDownloadManualJob(params: AddDownloadManualJob$Params, context?: HttpContext): Observable<AddDownloadManualsJobCommandResultVm> {
    return this.addDownloadManualJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddDownloadManualsJobCommandResultVm>): AddDownloadManualsJobCommandResultVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetDownloadedManualJobDetailsjobId()` */
  static readonly OperationsControllerGetDownloadedManualJobDetailsjobIdPath = '/operations/documoto/jobs/downloaded-manuals/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDownloadedManualJobDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadedManualJobDetails$Plain$Response(params: GetDownloadedManualJobDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoDownloadManualJobVm>> {
    return getDownloadedManualJobDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDownloadedManualJobDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadedManualJobDetails$Plain(params: GetDownloadedManualJobDetails$Plain$Params, context?: HttpContext): Observable<DocumotoDownloadManualJobVm> {
    return this.getDownloadedManualJobDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoDownloadManualJobVm>): DocumotoDownloadManualJobVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDownloadedManualJobDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadedManualJobDetails$Response(params: GetDownloadedManualJobDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoDownloadManualJobVm>> {
    return getDownloadedManualJobDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDownloadedManualJobDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadedManualJobDetails(params: GetDownloadedManualJobDetails$Params, context?: HttpContext): Observable<DocumotoDownloadManualJobVm> {
    return this.getDownloadedManualJobDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoDownloadManualJobVm>): DocumotoDownloadManualJobVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerTerminateOrDeleteDownloadManualJobjobId()` */
  static readonly OperationsControllerTerminateOrDeleteDownloadManualJobjobIdPath = '/operations/documoto/jobs/downloaded-manuals/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `terminateOrDeleteDownloadManualJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateOrDeleteDownloadManualJob$Response(params: TerminateOrDeleteDownloadManualJob$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return terminateOrDeleteDownloadManualJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `terminateOrDeleteDownloadManualJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateOrDeleteDownloadManualJob(params: TerminateOrDeleteDownloadManualJob$Params, context?: HttpContext): Observable<void> {
    return this.terminateOrDeleteDownloadManualJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerDownloadManualOldmediaId()` */
  static readonly OperationsControllerDownloadManualOldmediaIdPath = '/operations/documoto/jobs/downloaded-manuals/pdf/{mediaId}/old';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadManualOld()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadManualOld$Response(params: DownloadManualOld$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadManualOld(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadManualOld$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadManualOld(params: DownloadManualOld$Params, context?: HttpContext): Observable<void> {
    return this.downloadManualOld$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetDownloadedManualStatistics()` */
  static readonly OperationsControllerGetDownloadedManualStatisticsPath = '/operations/documoto/jobs/downloaded-manuals/statistics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDownloadedManualStatistics$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadedManualStatistics$Plain$Response(params?: GetDownloadedManualStatistics$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadedManualsStatisticsVm>> {
    return getDownloadedManualStatistics$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDownloadedManualStatistics$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadedManualStatistics$Plain(params?: GetDownloadedManualStatistics$Plain$Params, context?: HttpContext): Observable<DownloadedManualsStatisticsVm> {
    return this.getDownloadedManualStatistics$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadedManualsStatisticsVm>): DownloadedManualsStatisticsVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDownloadedManualStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadedManualStatistics$Response(params?: GetDownloadedManualStatistics$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadedManualsStatisticsVm>> {
    return getDownloadedManualStatistics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDownloadedManualStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadedManualStatistics(params?: GetDownloadedManualStatistics$Params, context?: HttpContext): Observable<DownloadedManualsStatisticsVm> {
    return this.getDownloadedManualStatistics$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadedManualsStatisticsVm>): DownloadedManualsStatisticsVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerTriggerExpirationOfDownloadedManualidIsPackage()` */
  static readonly OperationsControllerTriggerExpirationOfDownloadedManualidIsPackagePath = '/operations/documoto/downloaded-manuals/{id}/expire';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerExpirationOfDownloadedManual()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerExpirationOfDownloadedManual$Response(params: TriggerExpirationOfDownloadedManual$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return triggerExpirationOfDownloadedManual(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `triggerExpirationOfDownloadedManual$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerExpirationOfDownloadedManual(params: TriggerExpirationOfDownloadedManual$Params, context?: HttpContext): Observable<void> {
    return this.triggerExpirationOfDownloadedManual$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetCachedDocumotoJobs()` */
  static readonly OperationsControllerGetCachedDocumotoJobsPath = '/operations/documoto/mdz-export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedDocumotoJobs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedDocumotoJobs$Plain$Response(params?: GetCachedDocumotoJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CachedMdzJobVm>>> {
    return getCachedDocumotoJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedDocumotoJobs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedDocumotoJobs$Plain(params?: GetCachedDocumotoJobs$Plain$Params, context?: HttpContext): Observable<Array<CachedMdzJobVm>> {
    return this.getCachedDocumotoJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CachedMdzJobVm>>): Array<CachedMdzJobVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCachedDocumotoJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedDocumotoJobs$Response(params?: GetCachedDocumotoJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CachedMdzJobVm>>> {
    return getCachedDocumotoJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCachedDocumotoJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCachedDocumotoJobs(params?: GetCachedDocumotoJobs$Params, context?: HttpContext): Observable<Array<CachedMdzJobVm>> {
    return this.getCachedDocumotoJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CachedMdzJobVm>>): Array<CachedMdzJobVm> => r.body)
    );
  }

  /** Path part for operation `operationsControllerStartDownloadDocumotoMdzJobrequest()` */
  static readonly OperationsControllerStartDownloadDocumotoMdzJobrequestPath = '/operations/documoto/mdz-export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startDownloadDocumotoMdzJob$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startDownloadDocumotoMdzJob$Plain$Response(params?: StartDownloadDocumotoMdzJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadDocumotoMdzJobResponseVm>> {
    return startDownloadDocumotoMdzJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startDownloadDocumotoMdzJob$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startDownloadDocumotoMdzJob$Plain(params?: StartDownloadDocumotoMdzJob$Plain$Params, context?: HttpContext): Observable<DownloadDocumotoMdzJobResponseVm> {
    return this.startDownloadDocumotoMdzJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadDocumotoMdzJobResponseVm>): DownloadDocumotoMdzJobResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startDownloadDocumotoMdzJob()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startDownloadDocumotoMdzJob$Response(params?: StartDownloadDocumotoMdzJob$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadDocumotoMdzJobResponseVm>> {
    return startDownloadDocumotoMdzJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startDownloadDocumotoMdzJob$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startDownloadDocumotoMdzJob(params?: StartDownloadDocumotoMdzJob$Params, context?: HttpContext): Observable<DownloadDocumotoMdzJobResponseVm> {
    return this.startDownloadDocumotoMdzJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadDocumotoMdzJobResponseVm>): DownloadDocumotoMdzJobResponseVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerGetDocumotoMdzJobStatusid()` */
  static readonly OperationsControllerGetDocumotoMdzJobStatusidPath = '/operations/documoto/mdz-export/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumotoMdzJobStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumotoMdzJobStatus$Plain$Response(params: GetDocumotoMdzJobStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadDocumotoMdzJobResponseVm>> {
    return getDocumotoMdzJobStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumotoMdzJobStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumotoMdzJobStatus$Plain(params: GetDocumotoMdzJobStatus$Plain$Params, context?: HttpContext): Observable<DownloadDocumotoMdzJobResponseVm> {
    return this.getDocumotoMdzJobStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadDocumotoMdzJobResponseVm>): DownloadDocumotoMdzJobResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumotoMdzJobStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumotoMdzJobStatus$Response(params: GetDocumotoMdzJobStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadDocumotoMdzJobResponseVm>> {
    return getDocumotoMdzJobStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumotoMdzJobStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumotoMdzJobStatus(params: GetDocumotoMdzJobStatus$Params, context?: HttpContext): Observable<DownloadDocumotoMdzJobResponseVm> {
    return this.getDocumotoMdzJobStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadDocumotoMdzJobResponseVm>): DownloadDocumotoMdzJobResponseVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerDeleteDocumotoMdzJobFromCacheid()` */
  static readonly OperationsControllerDeleteDocumotoMdzJobFromCacheidPath = '/operations/documoto/mdz-export/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocumotoMdzJobFromCache()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumotoMdzJobFromCache$Response(params: DeleteDocumotoMdzJobFromCache$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteDocumotoMdzJobFromCache(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDocumotoMdzJobFromCache$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumotoMdzJobFromCache(params: DeleteDocumotoMdzJobFromCache$Params, context?: HttpContext): Observable<void> {
    return this.deleteDocumotoMdzJobFromCache$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `operationsControllerStartDocumotoExtractZipFileJobidOverrideJobAddedTime()` */
  static readonly OperationsControllerStartDocumotoExtractZipFileJobidOverrideJobAddedTimePath = '/operations/documoto/mdz-export/{id}/documoto-extract-zip-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startDocumotoExtractZipFileJob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  startDocumotoExtractZipFileJob$Plain$Response(params: StartDocumotoExtractZipFileJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AddPartsManualsJobFromDocumotoMdzJobResponseVm>> {
    return startDocumotoExtractZipFileJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startDocumotoExtractZipFileJob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startDocumotoExtractZipFileJob$Plain(params: StartDocumotoExtractZipFileJob$Plain$Params, context?: HttpContext): Observable<AddPartsManualsJobFromDocumotoMdzJobResponseVm> {
    return this.startDocumotoExtractZipFileJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddPartsManualsJobFromDocumotoMdzJobResponseVm>): AddPartsManualsJobFromDocumotoMdzJobResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startDocumotoExtractZipFileJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  startDocumotoExtractZipFileJob$Response(params: StartDocumotoExtractZipFileJob$Params, context?: HttpContext): Observable<StrictHttpResponse<AddPartsManualsJobFromDocumotoMdzJobResponseVm>> {
    return startDocumotoExtractZipFileJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startDocumotoExtractZipFileJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startDocumotoExtractZipFileJob(params: StartDocumotoExtractZipFileJob$Params, context?: HttpContext): Observable<AddPartsManualsJobFromDocumotoMdzJobResponseVm> {
    return this.startDocumotoExtractZipFileJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddPartsManualsJobFromDocumotoMdzJobResponseVm>): AddPartsManualsJobFromDocumotoMdzJobResponseVm => r.body)
    );
  }

  /** Path part for operation `operationsControllerDownloadDocumotoMdzJobStatusid()` */
  static readonly OperationsControllerDownloadDocumotoMdzJobStatusidPath = '/operations/documoto/mdz-export/{id}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadDocumotoMdzJobStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocumotoMdzJobStatus$Response(params: DownloadDocumotoMdzJobStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadDocumotoMdzJobStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadDocumotoMdzJobStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocumotoMdzJobStatus(params: DownloadDocumotoMdzJobStatus$Params, context?: HttpContext): Observable<void> {
    return this.downloadDocumotoMdzJobStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
