import { Component, OnDestroy, OnInit } from "@angular/core";
import { BroadcastService } from 'root/services';
import { Subscription } from 'rxjs/internal/Subscription';
import { MibpCrumb } from "../breadcrumbs-v2.types";
import { NavigationService } from './../../../../services/navigation/navigation.service';


@Component({
  selector: 'mibp-breadrumbs-manual-control',
  template: ''
})
export class MibpBreadcrumbsV2ManualControlComponent implements OnInit, OnDestroy {

  originalCrumbs?: MibpCrumb[];

  constructor(private broadcast: BroadcastService, private navigationService: NavigationService) {}

  ngOnInit(): void {

    this.originalCrumbs = this.broadcast.snapshot.breadcrumbs;

    // Take control of breadcrumbs
    this.broadcast.setBreadcrumbConrolMode('manual');

  }

  public setBreadcrumbs(crumbs: MibpCrumb[], offset = 0): void {

    // Ok, clone initial crumbs
    let originals = this.originalCrumbs.map(crumb => Object.assign({}, crumb) );

    if (offset < 0) {
      offset = offset * -1;
      originals = originals.slice(0, originals.length - offset);
    }

    crumbs.forEach(newCrumb => originals.push(newCrumb) );

    this.navigationService.getBreadcrumbComponents().forEach(bc => {
      bc.update(originals);
    });

  }


  ngOnDestroy(): void {

    // Release breadcrumb control
    this.broadcast.setBreadcrumbConrolMode('auto');

  }


}
