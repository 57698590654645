import { ClientIdService } from './../clientid-service/clientid.service';
import { DialogService, LocalizationService } from 'root/services';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService } from 'root/services';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { Router } from '@angular/router';
import { ButtonColors } from 'root/components/button/button.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private clientIdService: ClientIdService, private localizationService: LocalizationService,
    private router: Router,
    private dialogService: DialogService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpEventType.Response>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {

    // TODO: Here we call enusure token every time. Could this be done better - by trying to refresh when we get 401 errors perhaps?
    const accessToken = await this.auth.ensureToken(req.url.endsWith('/session'));

    const headers = {};
    const clientId = this.clientIdService.getClientId();

    // "Allow anoymous" for globalConfig. Refactor to some sort of config if we need more of these
    if (!req.url.includes('globalConfig')) {
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    if (clientId) {
      headers['X-Client-Id'] = clientId;
    }

    headers['x-lang-code'] = this.localizationService.getLang();

    const authReq = req.clone({
      setHeaders: headers
    });

    return next.handle(authReq).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status == 403 && response.error?.errors[0]?.exceptionType == "MibpForbiddenException") {
          this.router.navigateByUrl('en/unauthorized',{skipLocationChange: true});
        }
        const errors = response.error?.errors;
        if (errors && response.status == 500 && errors[errors.length - 1]?.exceptionType == "MibpSessionRevokedException") {
          this.dialogService.prompt("Users_ConcurrentSession_LogOutMessage", "Global_Ok").finally(() => {
            this.auth.signout();
          });
        }
        return throwError(response);
      })).toPromise();

      // return next.handle(authReq).toPromise();
  }
}
