import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { MibpLogFilterItem, MibpLogSearchRequest } from "./operations.types";
import { BroadcastService, MibpHttpApi, PermissionService } from 'root/services';
import { UrlHelperService } from './../url-helper/url-helper.service';
import { QueryCountResult } from "../mibp-http-api/operations-http-service.class";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, take } from "rxjs/operators";

import { NavigationService } from './../navigation/navigation.service';
import { format, startOfDay } from "date-fns";
import { IntegrationMessageTypeVm, resubmittableIntegrationMessageTypes } from "root/modules/operations/pages/post-integration-messages/post-integration-message.types";
export interface QueueCountEvent {
  result: QueryCountResult;
  nextUpdate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class MibpOperationsService {

  queueCountSubject = new BehaviorSubject<QueueCountEvent>(null);
  queueFetchTime?: Date;
  queueRefreshTimeMs = 60000;

  constructor(private router: Router, private broadcast: BroadcastService, private urlHelper: UrlHelperService,
    private permissionService: PermissionService,
    private httpApi: MibpHttpApi, private navigationService: NavigationService) {
  }

  public get queueCount$(): Observable<QueueCountEvent> {
    return this.queueCountSubject.asObservable();
  }

  subscribeToQueueCount(): void {

    if (this.broadcast.snapshot.globalConfig.disableSupportQueueTile) {
      return;
    }

    this.broadcast.mibpSession.pipe(filter(f => f != null && !!f.user), take(1)).subscribe((x) => {
      this.updateQueues();
    });

  }


  public getResubmittableIntegrationMessageTypes(): IntegrationMessageTypeVm[]{
    return resubmittableIntegrationMessageTypes;
  }

  private updateQueues(): void {
    if (this.permissionService.test({isSupportTeamMember: true})) {
      this.httpApi.Operations.getQueuesAndTopics().then(result => {
        this.queueFetchTime = new Date();
        this.queueCountSubject.next({
          result: result,
          nextUpdate: new Date( (new Date()).getTime() + this.queueRefreshTimeMs )
        });

        const deadLetterCount = result.topics.reduce((count, topic) => count + topic.subscriptions.reduce((deadLetterCount, sub) => deadLetterCount + sub.deadletterCount, 0), 0);
        const activeLetterCount = result.topics.reduce((count, topic) => count + topic.subscriptions.reduce((activeLetterCount, sub) => activeLetterCount + sub.activeMessageCount, 0), 0);

        if (deadLetterCount >= 500) {
          // Show badge if we have more than 500 in deadletter
          this.navigationService.setBadge('#home', {value: this.abbreviateNumber(deadLetterCount), color: 'orange'});
        } else {
          // Show blue badge with active messages if it's more or equal to than 5000
          if (activeLetterCount >= 5000) {
            this.navigationService.setBadge('#home', {value: this.abbreviateNumber(activeLetterCount), color: 'blue'});
          } else {
            this.navigationService.setBadge('#home', null);
          }
        }

        setTimeout(() => this.updateQueues(), this.queueRefreshTimeMs);
      }, () => {
        // Just try again next time around...
        setTimeout(() => this.updateQueues(), this.queueRefreshTimeMs);
      });
    }

  }

  abbreviateNumber(value: number): string {
    let newValue = value.toString();
    if (value >= 1000) {
      const suffixes = ["", "k", "m", "b","t"];
      const suffixNum = Math.floor( (""+value).length/3 );
      let shortValue: number | string = 0;
      for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
        const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
        if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
  }


  navigateToLogSearch(type: 'useractions' | 'logrows' | 'integration', request?: MibpLogSearchRequest, navigateUsingBrowser = false): void {

    const nav = this.getLogSearchRoute(type, request);

    if (navigateUsingBrowser) {
      window.location.href = this.urlHelper.create(nav.route[0], nav.queryParams).toString();

    } else {
      this.router.navigate(nav.route, {queryParams: nav.queryParams});
    }

  }

  public getLogSearchRoute(type: 'useractions' | 'logrows' | 'integration', request?: MibpLogSearchRequest): { route: string[], queryParams: Params } {

    let url = `/${this.broadcast.snapshot.language}/operations/logs/${type}`;
    const param: Params = {
      q: JSON.stringify(request)
    };

    if (type === 'integration') {
      url = `/${this.broadcast.snapshot.language}/operations/logs/${type}/search`;
    }

    return {
      route: [url],
      queryParams: param
    };

  }

  public createStartOfDayFilter(): MibpLogFilterItem {
    const date = format(startOfDay(new Date()), `yyyy-MM-dd`);
    const dateString = `${date}T00:00:00Z`;

    return {
      field: 'LogDate',
      operator: '>=',
      value: dateString,
      dateTz: 'utc',
      type: 'condition'
    };
  }

  // SAve this a bit in case we need to use zipped filters in url

  // import { compress, decompress } from "lzutf8";
  // import { MibpLogSearchRequest } from "../../modules/operations/components/mibplog-filter-editor/mibplog-filter-editor.types";
  // import { Router } from '@angular/router';
  // import { BroadcastService } from 'root/services';
  // import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

  // @Injectable({
  //   providedIn: 'root'
  // })
  // export class MibpLogFilterService {


  //   public constructor(private router: Router,
  //                     private broadcast: BroadcastService,
  //                     private sanitizer: DomSanitizer) {}

  //   public toQuerystringValue(request: MibpLogSearchRequest): string {

  //     const compressedFilter = compress(JSON.stringify(request), {
  //       outputEncoding: 'BinaryString'
  //     });

  //     return `bstr${compressedFilter}`;
  //   }


}
