import { Guid } from 'guid-typescript';
import { Router } from '@angular/router';
import { LocalizationService, NoticebarService, FrontendContextService, ContactUsService, MibpLogger, MibpHttpApi, SimpleProductViewModelWithPrice, SignalR_CartAddProductErrorViewModel, PermissionService, BroadcastService } from 'root/services';
import { CartService } from 'root/services';
import { DialogComponent } from './../dialog/dialog.component';
import { DialogButton } from './../dialog/dialog.types';
import { ButtonColors, ButtonStyles } from './../button/button.enum';
import { LogService } from './../../services/logservice/log.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NoticeType } from '../noticebar/noticebar.enum';

import { ContactUsTopic } from '..';
import { PriceAndAvailabilityApiController } from './../../mibp-openapi-gen/services/price-and-availability-api-controller';
import { Item, PriceAndAvailabilitySource, ProductPrice } from 'root/mibp-openapi-gen/models';
import { firstValueFrom } from 'rxjs';
import { SimpleProductViewModel } from './../../mibp-openapi-gen/models/simple-product-view-model';
import { allPermissionPolicies } from 'root/all-permission-policies';



@Component({
  selector: 'mibp-addtocart-dialog',
  templateUrl: './addtocart-dialog.component.html',
  styleUrls: ['./addtocart-dialog.component.scss']
})
export class AddtocartDialogComponent implements OnInit {
  log: MibpLogger;
  errorProducts: Item[];
  hidden = false;
  hasDuplicates = false;
  hasUpsell = false;
  hasCrossSell = false;
  upsellProductId?: number;
  crossSellProductIds?: number[];
  additionalProducts: number;
  dialogButtons: DialogButton[];
  firstTwoProdcuts: SimpleProductViewModelWithPrice[];
  isLoading: boolean;
  errorText: string;
  itemCodesText: string;
  cartId: Guid;
  @Output() closeDialogBox = new EventEmitter<boolean>();
  @ViewChild(DialogComponent) dialog: DialogComponent;
  contactUsMessageRequestPrice: string;
  constructor(logService: LogService, private cartService: CartService,
    private priceAndAvailabilityApi: PriceAndAvailabilityApiController,
    private frontendContext: FrontendContextService,
    private router: Router,
    private localizationService: LocalizationService,
    private noticeBarService: NoticebarService,
    private contactUsService: ContactUsService,
    private httpApi: MibpHttpApi,
    private permissions: PermissionService
  ) {
    this.log = logService.withPrefix(`addtocart-dialog`);
  }

  ngOnInit(): void {
    this.cartService.registerDialog(this);
    this.dialogButtons = [

      {
        id: 'close',
        resourceKey: 'Global_Dialog_Close',
        color: ButtonColors.Orange,
        style: ButtonStyles.Fill,
        minWidth: 150,
        automatedTestId: 'button-addtocart-dialog'
      },
      {
        id: 'gotocart',
        resourceKey: 'CartsDialog_Go_To_Cart',
        color: ButtonColors.Blue,
        style: ButtonStyles.Fill,
        minWidth: 150,
        automatedTestId: 'button-addtocart-dialog-gotocart'
      },
    ];
    this.localizationService.getResourceString('Carts_PriceForProductMessage').then((resourceStringValue) => {
      this.contactUsMessageRequestPrice = resourceStringValue;
    });
  }

  closeDialog() {
    this.dialog.close();
  }

  showDialog(addedProducts: SimpleProductViewModel[], hasDuplicates: boolean, cartId: Guid, upsellProductId?: number, crossSellProductIds?: number[], productErrors?: SignalR_CartAddProductErrorViewModel[], upsellCrossellProductVm?: SimpleProductViewModel) {
    this.cartId = cartId;
    addedProducts = addedProducts || [];
    if (productErrors && productErrors.length > 0) {
      //Filter out the invalid items because they were never added to cart
      productErrors.forEach(item => {
        addedProducts = addedProducts.filter(addedProduct => {return addedProduct.code != item.productCode;});
      });
    }

    this.additionalProducts = addedProducts.length > 2 ? addedProducts.length - 2 : 0;
    this.hasDuplicates = hasDuplicates;
    this.hasUpsell = upsellProductId ? true : false;
    this.upsellProductId = upsellProductId;
    this.hasCrossSell = crossSellProductIds && crossSellProductIds.length > 0;
    this.crossSellProductIds = crossSellProductIds;

    this.firstTwoProdcuts = addedProducts.slice(0, 2).map(c => c as SimpleProductViewModelWithPrice);

    if (productErrors && productErrors.length > 0) {
      const productNumberText = this.localizationService.get('Global_ProductNumber');
      this.errorText = this.localizationService.get("Cart_ProductsCouldNotBeAdded") + ':';
      productErrors.forEach(item => { this.errorText += ` ${productNumberText}: ${item.productCode}, `; });
    } else {
      this.errorText = '';
    }

    this.dialog.open();
    if (this.permissions.test(allPermissionPolicies.canSeePrices)) {
      this.loadProductPrices();
    }
  }

  private createPriceError(productCode: string, errorCode: string, description: string): ProductPrice {
    return {
      errorCode: errorCode,
      errorDescription: description,
      productCode: productCode,
      hasNetPriceOnly: false,
      superseededByProduct:null,
      warning: null,
      taxCode: null,
    };
  }

  private async loadProductPrices() {

    try {

      const prices = await firstValueFrom(this.priceAndAvailabilityApi.getPrices({
        body: {
          deliverySequenceId : null,
          priceAndAvailabilitySource : PriceAndAvailabilitySource.ProductSearch,
          productCodes : this.firstTwoProdcuts.map(p => p.code),
          force : false
        }
      }));

      this.firstTwoProdcuts.forEach(product => {
        const priceInfo = prices.find(price => price.productCode === product.code || price.superseededByProduct === product.code);
        if (priceInfo) {
          // Map because of Mix of SIGNALR/REST models
          product.productPrice = {
            productCode: priceInfo.productCode,
            netPrice: priceInfo.netPrice,
            basePrice: priceInfo.basePrice,
            errorCode: priceInfo.errorCode,
            errorDescription: priceInfo.errorDescription,
            superseededByProduct: priceInfo.superseededByProduct,
            hasNetPriceOnly: priceInfo.hasNetPriceOnly,
            warning: null,
            taxCode: null
          };
        } else {
          product.productPrice = this.createPriceError(product.code, 'FRONTEND_PRODUCT_NOT_FOUND_IN_RESPONSE', 'The product code was not found in P&A response');
        }
      });

    } catch(e) {
      this.firstTwoProdcuts.forEach(product => {
        product.productPrice = this.createPriceError(product.code, 'BACKEND_UNHANDLED_ERROR', 'Backend threw an unhandled exception');
        this.log.error("Price error ", e);
      });
    }
  }


  dialogClick(e: DialogButton): void {
    if (e.id == "close") {
      this.dialog.close();

    } else {
      this.dialog.close();
      this.router.navigateByUrl(this.frontendContext.Navigation.ensureUrlLanguage('/home/shop/cart'));
    }
  }

  productHasBeenUpgradedByUpsell(): void {
    this.cartService.refreshCartItems();
    this.cartService.refreshCartWeightNPrice();
    this.closeDialog();
    this.noticeBarService.show('Upsell_SuccessUpgradeMessage', NoticeType.Success);
  }

  onCrossSellProductAddedToCart(): void {
    this.closeDialog();
  }
  onClickProductImage(productCode: string)
  {
    this.closeDialog();
    this.frontendContext.Navigation.navigateToProductDetailsPage(productCode);
  }
  onCloseCrossSellUpsellDialog(event:Event)
  {
    if(event)
    {
      this.closeDialog();
    }
  }
}

export interface AddToCartProductWithPrice {
  code: string;
  productPrice?: ProductPrice;
}
