
<div class="wrapper">
  <div class="refiner-sidebar">
    <div class="refiner-header" *ngIf = "useMobileLayout" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="isCollapsed ? 'true' : 'false'">
      <mibp-resource-string key="Filter_RefineYourSearch"></mibp-resource-string>

    </div>
    <div class="refiners" [class.collapsed]="isCollapsed">

      <ng-content select="[sidebar-top]"></ng-content>

      <div *ngIf="!configuration || configuration?.length === 0">
        <div class="refiner">
          <label><mibp-resource-string key="Global_Filters_NoneAvailable"></mibp-resource-string></label>
        </div>
      </div>

      <div *ngFor="let refiner of configuration">

        <div class="refiner" *ngIf="isDateRefiner(refiner) && !refiner.hidden">
          <label><mibp-resource-string [key]="refiner.label" [macros]="refiner.labelMacros ? refiner.labelMacros : dateFormatMacros"></mibp-resource-string></label>
           <mibp-datepicker placeholder="MM/DD/YYYY" [date]="refiner.dateValue" [displayBlock]="true" (change)="dateChanged(refiner, $event)" [minDate]="refiner.minDate"></mibp-datepicker>
        </div>

        <div class="refiner" *ngIf="(isDropdownRefiner(refiner) || isDropdownMultiSelectRefiner(refiner)) && !refiner.hidden">
          <label>
            <mibp-resource-string [key]="refiner.label"></mibp-resource-string>
          </label>
          <mibp-dropdown
          #dropdownRefiner
          (filter)="onFilterDropdown(refiner, $event)"
          [items]="refiner.items"
          placeholder="Component_ResponsibilityPicker_Any"
          [multiselect.tagPlaceholder]="true"
          [multiselect.hideOnClick]="true"
          [multiselect]="isDropdownMultiSelectRefiner(refiner)"
          [selectedOption]="refiner.selectedValues"
          (valueChange)="onDropdownValueChanged(refiner, $event)"
          ></mibp-dropdown>
        </div>

        <div class="refiner" *ngIf="isTextRefiner(refiner) && !refiner.hidden">
          <label>
            <mibp-resource-string [key]="refiner.label"></mibp-resource-string>
          </label>
          <input type="search" [(ngModel)]="refiner._model" (keyup)="textKeyUp(refiner, refiner._model)" />
        </div>

        <div class="refiner" *ngIf="isNumberRefiner(refiner) && !refiner.hidden">
          <label>
            <mibp-resource-string [key]="refiner.label"></mibp-resource-string>
          </label>
          <input type="number" [(ngModel)]="refiner._model" (input)="numberKeyUp(refiner, refiner._model)" />
        </div>

        <div class="refiner" *ngIf="isCheckboxListRefiner(refiner) && (!refiner.hideIfOnlyOne || (refiner.hideIfOnlyOne && refiner.items?.length > 1)) && !refiner.hidden">
          <label class="collapsable" (click)="expanded[refiner.name] = !expanded[refiner.name]">
            <mibp-resource-string [key]="refiner.label"></mibp-resource-string>
            <span translate="no" class="material-icon">{{expanded[refiner.name] ? 'minus' : 'plus'}} </span>
          </label>
          <div class="option-list checkboxes" [class.expanded]="expanded[refiner.name]">
            <label *ngFor="let item of refiner.items">
              <input (change)="chekboxCheck(refiner, cbx.checked, item.value);" #cbx [attr.checked]="testChecked(refiner, item) ? 'checked' : null" type="checkbox" />
              <mibp-resource-string *ngIf="item.resourceKey" [key]="item.resourceKey"></mibp-resource-string>
              <ng-container *ngIf="item.text">{{item.text}}</ng-container>
            </label>
          </div>
        </div>

        <div class="refiner" *ngIf="isRadiobuttonListRefiner(refiner) && (!refiner.hideIfOnlyOne || (refiner.hideIfOnlyOne && refiner.items?.length > 1)) && !refiner.hidden">
          <label class="collapsable" (click)="expanded[refiner.name] = !expanded[refiner.name]">
            <mibp-resource-string [key]="refiner.label"></mibp-resource-string>
            <span translate="no" class="material-icon">{{expanded[refiner.name] ? 'minus' : 'plus'}} </span>
          </label>
          <div class="option-list radiobuttons" [class.expanded]="expanded[refiner.name]">
            <label *ngFor="let item of refiner.items">
              <input (change)="radiobuttonCheck(refiner, item.value)" #cbx [attr.checked]="testSelected(refiner, item) ? 'checked' : null" type="radio" name="refiner.name" value="item.value" />
              <mibp-resource-string *ngIf="item.resourceKey" [key]="item.resourceKey"></mibp-resource-string>
              <ng-container *ngIf="item.text">{{item.text}}</ng-container>
            </label>
          </div>
        </div>

        <div class="refiner" *ngIf="isListRefiner(refiner) && !refiner.hidden">
          <label><mibp-resource-string [key]="refiner.label"></mibp-resource-string></label>
          <div class="filter">
            <ul>
              <li [class.selected]="refiner.value === listKey" *ngFor="let listKey of objectKeys(refiner.items)"><a href="javascript:void(0)" (click)="selectListItem(refiner, listKey)"><span class="name">{{refiner.items[listKey]}}</span></a></li>
            </ul>
          </div>
        </div>

      </div>

    </div>

    <ng-content select="[sidebar-below]" class="refiner-sidebar-below"></ng-content>
  </div>
  <div class="refiner-content" [style.opacity]="isPending && pendingOpacity ? pendingOpacity : null"
  [style.cursor]="isPending && pendingCursor ? pendingCursor : null">
    <ng-content></ng-content>
  </div>
</div>
