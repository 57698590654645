
<div class="group footer footer-polygon" role="contentinfo">
  <div class="group-inner">
    <h2 class="structural">Site footer</h2>
    <div class="footer-left">
      <img src="assets/images/logo_black.svg" alt="Sandvik">
      <p class="statement">
        <mibp-resource-string key="Footer_Paragraph" isHtml="true"></mibp-resource-string>
      </p>
      <p class="copyright">
        Copyright © Sandvik AB; (publ)<br>
        SE-811 81 Sandviken, Sweden <br>
        Phone: <a href="tel:+46026260000">+46 (0)26 260 000</a>
      </p>
    </div>

    <div class="footer-right">
      <div class="nav-supp" role="navigation">
        <ul>
          <li><a href="https://www.rocktechnology.sandvik/{{lang}}/contact-us"><mibp-resource-string key="Footer_LinkTexts_Contact"></mibp-resource-string></a></li>
          <li><a href="https://www.rocktechnology.sandvik/{{lang}}/about-this-site"><mibp-resource-string key="Footer_LinkTexts_AboutThisSite"></mibp-resource-string></a></li>
          <li><a href="https://www.rocktechnology.sandvik/{{lang}}/sitemap"><mibp-resource-string key="Footer_LinkTexts_Sitemap"></mibp-resource-string></a></li>
          <li><a href="https://www.rocktechnology.sandvik/{{lang}}/about-this-site/cookies/"><mibp-resource-string key="Footer_LinkTexts_Cookies"></mibp-resource-string></a></li>
          <li><a href="https://www.rocktechnology.sandvik/{{lang}}/about-this-site/data-privacy/"><mibp-resource-string key="Footer_LinkTexts_Privacy"></mibp-resource-string></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
