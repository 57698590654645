import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MibpPopupContainerComponent } from "../popup-container/popup-container.component";

@Component({
  selector: 'mibp-inline-help',
  templateUrl: './inline-help.component.html',
  styleUrls: ['./inline-help.component.scss']
})
export class MibpInlineHelpComponent  implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.popup?.close();
  }
  @Input() color: 'blue' | 'orange' | 'red' = 'blue';
  @Input() iconSize = 'normal';
  @Input() iconName = 'help';
  @Input() addLeftMargin = true;

  /// Will make sure content looks better - headers, paragraphs and list etc.
  @Input() applyContentStyles = false;

  minwidthForMobile?: number;
  widthForMobile?: number;

  @ViewChild(MibpPopupContainerComponent) popup: MibpPopupContainerComponent;

  public close(): void {
    this.popup.close();
  }

  public get isOpen(): boolean {
    return this.popup.isOpen;
  }

  ngOnInit(): void {
    this.minwidthForMobile = window.innerWidth - 10 < 600 ?  window.innerWidth - 10 : 600;
    this.widthForMobile = this.minwidthForMobile;
  }
}
