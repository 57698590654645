import { Injectable } from "@angular/core";
import { LogService, MibpLogger } from "../logservice/index";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private log: MibpLogger;
  private id = 0;
  private activeCoverLoaders: { id: string, element: HTMLElement }[] = [];
  private fullscreenSubject = new Subject<boolean>();
  private showTimer;


  constructor(logger: LogService) {
    this.log = logger.withPrefix('loader-service');
  }

  public get ShowFullscreen$(): Observable<boolean> {
    return this.fullscreenSubject.asObservable();
  }

  public HasFullscreenSubscribers(): boolean {
    return this.fullscreenSubject.observers.length > 0;
  }

  /**
   * Create a loader that covers the given element
   * Will return an ID that must be provided to destroyLoaderOverElement
   */
  createLoaderOverElement(element: HTMLElement): string {
    let id: string = null;
    if (element) {
      id = element.getAttribute('data-loader-ref');
      if (!id) {
        id = this.id.toString();
        element.setAttribute('data-loader-ref', id);
        const loaderElement = this.createCircleLoaderElement(element, id);
        this.activeCoverLoaders.push({id: id, element: loaderElement});
        this.id++;
        this.alignLoaderToElement(loaderElement, element);
        this.beginAlignmentOfElement(loaderElement, element);
        this.bindResizeEvent();
        element.style.position = 'relative';
      } else {
        return element.getAttribute('data-loader-ref');
      }
    }
    return id;
  }

  destroyLoaderOverElement(loaderRefId: string) {
    const targetElement = document.body.querySelector('[data-loader-ref="' + loaderRefId + '"]');
    const loaderElement = document.body.querySelector('[data-loader-for="' + loaderRefId + '"]');
    if (targetElement && loaderElement) {
      targetElement.removeAttribute('data-loader-ref');
      loaderElement.parentElement.removeChild(loaderElement);
      const index = this.activeCoverLoaders.findIndex(i => i.id === loaderRefId);
      if (index !== -1) {
        this.activeCoverLoaders.splice(index, 1);
      }
      if (this.activeCoverLoaders.length === 0) {
        this.unbindResizeEvent();
      }
    }
  }

  private bindResizeEvent() {
    this.log.debug("Binding resize event");
    window.addEventListener('resize', () => {
      this.alignCoverLoaders();
    });
  }

  private unbindResizeEvent() {
    this.log.debug("Unbinding resize event");
  }

  private alignCoverLoaders() {
    this.activeCoverLoaders.forEach(item => {
      const id = item.element.getAttribute('data-loader-for');
      const target = document.body.querySelector('[data-loader-ref="' + id + '"]') as HTMLElement;
      this.alignLoaderToElement(item.element, target);
    });
  }

  /**
   * Will watch an element for a while and adjust the loader size
   */
  private beginAlignmentOfElement(loaderElement: HTMLElement, targetElement: HTMLElement, counter = 0, iterations = 5, delay = 650) {
    setTimeout(() => {
      counter ++;
      if (counter <= iterations) {
        if (this.activeCoverLoaders.length > 0) {
          this.alignLoaderToElement(loaderElement, targetElement);
          this.beginAlignmentOfElement(loaderElement, targetElement, counter, iterations, delay);
        }
      }
    }, delay);
  }

  private alignLoaderToElement(loaderElement: HTMLElement, targetElement: HTMLElement) {
    if (loaderElement && targetElement) {
      let isMibpButton = false;


      if (targetElement.localName === 'mibp-button' || targetElement.classList.contains('my-button')) {
        const buttonElement = targetElement.querySelector('button');
        targetElement = buttonElement || targetElement;
        isMibpButton = true;
      }

      const targetSize = targetElement.getBoundingClientRect();

      if (targetSize.width > 0 && targetSize.height > 0) {
        if (targetSize.height < 70) {
          loaderElement.classList.remove('loading-new');
          loaderElement.classList.add('loading-small');
        } else {
          loaderElement.classList.add('loading-new');
          loaderElement.classList.remove('loading-small');
        }

        let width = targetSize.width;

        if (isMibpButton) {
          loaderElement.style.top = '-1px';
          loaderElement.style.left = '-1px';
          width += 1;
        }

        loaderElement.style.display = 'block';
        loaderElement.style.position = 'absolute';
        loaderElement.style.width = `${targetSize.width}px`;
        loaderElement.style.height = `${targetSize.height}px`;
        loaderElement.style.backgroundColor = 'rgba(255,255,255, 0.8)';
      }

    }
  }

  public showFullScreenLoader(delay = 0) {
    if (delay) {
      if (this.showTimer) {
        clearTimeout(this.showTimer);
        this.fullscreenSubject.next(false);
      }
      this.showTimer = setTimeout(() => {
        this.fullscreenSubject.next(true);
      }, delay);
    } else {
      this.fullscreenSubject.next(true);
    }
  }

  public hideFullScreenLoader() {
    if (this.showTimer) {
      clearTimeout(this.showTimer);
    }
    this.fullscreenSubject.next(false);
  }

  private createCircleLoaderElement(parent: HTMLElement, refId: string): HTMLElement {

    const wrapperDiv = document.createElement(`div`);
    wrapperDiv.setAttribute('data-loader-for', refId);
    wrapperDiv.style.display = 'none';
    wrapperDiv.style.cursor = 'progress';
    wrapperDiv.style.zIndex = '10';
    wrapperDiv.style.top = '0px';
    wrapperDiv.style.left = '0px';
    wrapperDiv.innerHTML = `<div class="loading-new">
    <div>
      <span class="circle">
        <span class="left"><span class="anim"></span></span>
      </span>
    </div>
  </div>
  <span class="appLoaderProgress"></span>
</div>`;

    if (parent.localName === 'mibp-button') {
      const buttonElement = parent.querySelector('button');
      if (buttonElement) {
        parent = buttonElement;
      }
    }

    if (parent.firstChild) {
      return parent.insertBefore(wrapperDiv, parent.firstChild);
    }
    return parent.appendChild(wrapperDiv);
  }
}
