<div>
  <h3 *ngIf="showHeader" class="my-header"><mibp-resource-string key="SupportCases_Type_GeneralQuestionOrRequest"></mibp-resource-string></h3>
  <form [formGroup]="contactUsGeneralForm" (ngSubmit)="submit()">
    <div class="columns">
      <div class="column is-half">
        <div class="forms__row">
          <label><mibp-resource-string key="ContactUs_Name"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="50"  formControlName="name" >
          <mibp-validation-text [formGroup]="contactUsGeneralForm" [alwaysVisible]="formSubmitted" controlName="name"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="columns" >
      <div class="column column--pr">
        <div class="forms__row" >
          <label><mibp-resource-string key="ContactUs_Email_Field"></mibp-resource-string><span *ngIf="isEmailSelected" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="50"  formControlName="email" >
          <mibp-validation-text [formGroup]="contactUsGeneralForm" [alwaysVisible]="formSubmitted" controlName="email"></mibp-validation-text>
        </div>
      </div>

      <div class="column">
        <div class="forms__row">
          <label><mibp-resource-string key="ContactUs_Phone_Field"></mibp-resource-string><span *ngIf="isPhoneNumberSelected" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="50"  formControlName="phonenumber" >
          <mibp-validation-text [formGroup]="contactUsGeneralForm" [alwaysVisible]="formSubmitted" controlName="phonenumber"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <div class="forms__row" formGroupName="contactPreferenceGroup">
          <label><mibp-resource-string key="ContactUs_PreferedContactMethod"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <div class="checkbox">
            <input formControlName="byEmail" id="byEmail" name="byEmail" type="checkbox">
            <label for="byEmail"><mibp-resource-string key="ContactUs_Email"></mibp-resource-string></label>
          </div>
          <div class="checkbox">
            <input formControlName="byPhone" id="byPhone" name="byPhone" type="checkbox">
            <label for="byPhone"><mibp-resource-string key="ContactUs_Phone"></mibp-resource-string></label>
          </div>
          <mibp-validation-text [formGroup]="contactUsGeneralForm" [alwaysVisible]="formSubmitted" controlName="contactPreferenceGroup"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="forms__row">
          <label><mibp-resource-string key="Global_Description"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <textarea style="width: 100%; height: 100px;" maxlength = "300" formControlName="description"></textarea>
          <mibp-validation-text [formGroup]="contactUsGeneralForm" [alwaysVisible]="formSubmitted" controlName="description"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div style="float: right;">
      <mibp-button type="submit" [resourceStringKey]="'Global_Submit'" [color]="buttonColors.Primary"></mibp-button>
    </div>
  </form>
</div>
