import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate (formGroup: UntypedFormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value && control.value === true) {
        checked ++;
      }
    });

    const isValid: boolean = checked < minRequired;

    return !isValid ? null : {
      requireCheckboxesToBeChecked: {
        valid: false
      }
    };
  };
}
