/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CartUpsellProductViewModel } from '../models/cart-upsell-product-view-model';
import { delete$ } from '../fn/up-sells/delete';
import { Delete$Params } from '../fn/up-sells/delete';
import { getForEditing } from '../fn/up-sells/get-for-editing';
import { GetForEditing$Params } from '../fn/up-sells/get-for-editing';
import { getForEditing$Plain } from '../fn/up-sells/get-for-editing-plain';
import { GetForEditing$Plain$Params } from '../fn/up-sells/get-for-editing-plain';
import { getLatestAddedUpsellProductFromActiveCart } from '../fn/up-sells/get-latest-added-upsell-product-from-active-cart';
import { GetLatestAddedUpsellProductFromActiveCart$Params } from '../fn/up-sells/get-latest-added-upsell-product-from-active-cart';
import { getLatestAddedUpsellProductFromActiveCart$Plain } from '../fn/up-sells/get-latest-added-upsell-product-from-active-cart-plain';
import { GetLatestAddedUpsellProductFromActiveCart$Plain$Params } from '../fn/up-sells/get-latest-added-upsell-product-from-active-cart-plain';
import { getUpsellProducts } from '../fn/up-sells/get-upsell-products';
import { GetUpsellProducts$Params } from '../fn/up-sells/get-upsell-products';
import { getUpsellProducts$Plain } from '../fn/up-sells/get-upsell-products-plain';
import { GetUpsellProducts$Plain$Params } from '../fn/up-sells/get-upsell-products-plain';
import { gridSearch } from '../fn/up-sells/grid-search';
import { GridSearch$Params } from '../fn/up-sells/grid-search';
import { gridSearch$Plain } from '../fn/up-sells/grid-search-plain';
import { GridSearch$Plain$Params } from '../fn/up-sells/grid-search-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { save } from '../fn/up-sells/save';
import { Save$Params } from '../fn/up-sells/save';
import { save$Plain } from '../fn/up-sells/save-plain';
import { Save$Plain$Params } from '../fn/up-sells/save-plain';
import { UpSellCrossSellProductRequestResponseDto } from '../models/up-sell-cross-sell-product-request-response-dto';
import { UpsellForEditingDto } from '../models/upsell-for-editing-dto';

@Injectable({ providedIn: 'root' })
export class UpSellsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `upSellsControllerGridSearchoptionsLanguageCode()` */
  static readonly UpSellsControllerGridSearchoptionsLanguageCodePath = '/upsells/searchgrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain$Response(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Response(params?: GridSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch(params?: GridSearch$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `upSellsControllerGetForEditingid()` */
  static readonly UpSellsControllerGetForEditingidPath = '/upsells/{id}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Plain$Response(params: GetForEditing$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpsellForEditingDto>> {
    return getForEditing$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Plain(params: GetForEditing$Plain$Params, context?: HttpContext): Observable<UpsellForEditingDto> {
    return this.getForEditing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpsellForEditingDto>): UpsellForEditingDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditing()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Response(params: GetForEditing$Params, context?: HttpContext): Observable<StrictHttpResponse<UpsellForEditingDto>> {
    return getForEditing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing(params: GetForEditing$Params, context?: HttpContext): Observable<UpsellForEditingDto> {
    return this.getForEditing$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpsellForEditingDto>): UpsellForEditingDto => r.body)
    );
  }

  /** Path part for operation `upSellsControllerDeleteid()` */
  static readonly UpSellsControllerDeleteidPath = '/upsells/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `upSellsControllerSaveupsell()` */
  static readonly UpSellsControllerSaveupsellPath = '/upsells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Plain$Response(params?: Save$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return save$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Plain(params?: Save$Plain$Params, context?: HttpContext): Observable<number> {
    return this.save$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<number> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `upSellsControllerGetUpsellProductsupsellCrossSellProductIdLanguageCode()` */
  static readonly UpSellsControllerGetUpsellProductsupsellCrossSellProductIdLanguageCodePath = '/upsells/products/{upsellCrossSellProductId}/upsellproducts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUpsellProducts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpsellProducts$Plain$Response(params: GetUpsellProducts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>> {
    return getUpsellProducts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUpsellProducts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpsellProducts$Plain(params: GetUpsellProducts$Plain$Params, context?: HttpContext): Observable<Array<UpSellCrossSellProductRequestResponseDto>> {
    return this.getUpsellProducts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>): Array<UpSellCrossSellProductRequestResponseDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUpsellProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpsellProducts$Response(params: GetUpsellProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>> {
    return getUpsellProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUpsellProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpsellProducts(params: GetUpsellProducts$Params, context?: HttpContext): Observable<Array<UpSellCrossSellProductRequestResponseDto>> {
    return this.getUpsellProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>): Array<UpSellCrossSellProductRequestResponseDto> => r.body)
    );
  }

  /** Path part for operation `upSellsControllerGetLatestAddedUpsellProductFromActiveCart()` */
  static readonly UpSellsControllerGetLatestAddedUpsellProductFromActiveCartPath = '/upsells/activecart/latestadded';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestAddedUpsellProductFromActiveCart$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestAddedUpsellProductFromActiveCart$Plain$Response(params?: GetLatestAddedUpsellProductFromActiveCart$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartUpsellProductViewModel>> {
    return getLatestAddedUpsellProductFromActiveCart$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestAddedUpsellProductFromActiveCart$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestAddedUpsellProductFromActiveCart$Plain(params?: GetLatestAddedUpsellProductFromActiveCart$Plain$Params, context?: HttpContext): Observable<CartUpsellProductViewModel> {
    return this.getLatestAddedUpsellProductFromActiveCart$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartUpsellProductViewModel>): CartUpsellProductViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestAddedUpsellProductFromActiveCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestAddedUpsellProductFromActiveCart$Response(params?: GetLatestAddedUpsellProductFromActiveCart$Params, context?: HttpContext): Observable<StrictHttpResponse<CartUpsellProductViewModel>> {
    return getLatestAddedUpsellProductFromActiveCart(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestAddedUpsellProductFromActiveCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestAddedUpsellProductFromActiveCart(params?: GetLatestAddedUpsellProductFromActiveCart$Params, context?: HttpContext): Observable<CartUpsellProductViewModel> {
    return this.getLatestAddedUpsellProductFromActiveCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartUpsellProductViewModel>): CartUpsellProductViewModel => r.body)
    );
  }

}
