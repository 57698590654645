/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { B2CCountryDto } from '../models/b-2-c-country-dto';
import { Country } from '../models/country';
import { countrySearch } from '../fn/countries/country-search';
import { CountrySearch$Params } from '../fn/countries/country-search';
import { countrySearch$Plain } from '../fn/countries/country-search-plain';
import { CountrySearch$Plain$Params } from '../fn/countries/country-search-plain';
import { CountrySearchResponse } from '../models/country-search-response';
import { getCountries } from '../fn/countries/get-countries';
import { GetCountries$Params } from '../fn/countries/get-countries';
import { getCountries$Plain } from '../fn/countries/get-countries-plain';
import { GetCountries$Plain$Params } from '../fn/countries/get-countries-plain';
import { getCountriesforB2C } from '../fn/countries/get-countriesfor-b-2-c';
import { GetCountriesforB2C$Params } from '../fn/countries/get-countriesfor-b-2-c';
import { getCountriesforB2C$Plain } from '../fn/countries/get-countriesfor-b-2-c-plain';
import { GetCountriesforB2C$Plain$Params } from '../fn/countries/get-countriesfor-b-2-c-plain';

@Injectable({ providedIn: 'root' })
export class CountriesApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `countriesControllerGetCountrieslang()` */
  static readonly CountriesControllerGetCountrieslangPath = '/countries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountries$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries$Plain$Response(params?: GetCountries$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Country>>> {
    return getCountries$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountries$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries$Plain(params?: GetCountries$Plain$Params, context?: HttpContext): Observable<Array<Country>> {
    return this.getCountries$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Country>>): Array<Country> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries$Response(params?: GetCountries$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Country>>> {
    return getCountries(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries(params?: GetCountries$Params, context?: HttpContext): Observable<Array<Country>> {
    return this.getCountries$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Country>>): Array<Country> => r.body)
    );
  }

  /** Path part for operation `countriesControllerGetCountriesforB2Clang()` */
  static readonly CountriesControllerGetCountriesforB2ClangPath = '/countries/b2c-countries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountriesforB2C$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountriesforB2C$Plain$Response(params?: GetCountriesforB2C$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<B2CCountryDto>>> {
    return getCountriesforB2C$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountriesforB2C$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountriesforB2C$Plain(params?: GetCountriesforB2C$Plain$Params, context?: HttpContext): Observable<Array<B2CCountryDto>> {
    return this.getCountriesforB2C$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<B2CCountryDto>>): Array<B2CCountryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountriesforB2C()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountriesforB2C$Response(params?: GetCountriesforB2C$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<B2CCountryDto>>> {
    return getCountriesforB2C(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountriesforB2C$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountriesforB2C(params?: GetCountriesforB2C$Params, context?: HttpContext): Observable<Array<B2CCountryDto>> {
    return this.getCountriesforB2C$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<B2CCountryDto>>): Array<B2CCountryDto> => r.body)
    );
  }

  /** Path part for operation `countriesControllerCountrySearchqueryIndexTake()` */
  static readonly CountriesControllerCountrySearchqueryIndexTakePath = '/countries/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countrySearch$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  countrySearch$Plain$Response(params?: CountrySearch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CountrySearchResponse>> {
    return countrySearch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countrySearch$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countrySearch$Plain(params?: CountrySearch$Plain$Params, context?: HttpContext): Observable<CountrySearchResponse> {
    return this.countrySearch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountrySearchResponse>): CountrySearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countrySearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  countrySearch$Response(params?: CountrySearch$Params, context?: HttpContext): Observable<StrictHttpResponse<CountrySearchResponse>> {
    return countrySearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countrySearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countrySearch(params?: CountrySearch$Params, context?: HttpContext): Observable<CountrySearchResponse> {
    return this.countrySearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountrySearchResponse>): CountrySearchResponse => r.body)
    );
  }

}
