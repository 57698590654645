import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { RouterLoadingComponent } from "root/components";

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  private appStartedSubject = new BehaviorSubject<boolean>(false);
  private component: RouterLoadingComponent;
  public hasStartupErrors = false;



  public get applicationStarted$(): Observable<boolean> {
    return this.appStartedSubject.asObservable();
  }

  public set applicationStarted(val: boolean) {
    if (val !== this.applicationStarted) {
      this.appStartedSubject.next(val);
    }
  }

  public get applicationStarted(): boolean {
    return this.appStartedSubject.value;
  }

  public registerLoaderComponent(component: RouterLoadingComponent): void {
    this.component = component;
  }

  /**
   * This method can be used before angular is loaded
   * You find this method in index.html
   */
  public showNativeMessage(msg: string | string[ ], isSticky?: boolean, isError?: boolean): void {
    window['showNativeMessage'](msg, isSticky, isError);
  }

  public startupBackendConnectionFailed(): void {
    this.component.showStartupBackendConnectionError();
  }

  /**
   * This method can be used before angular is loaded
   * You find this method in index.html
   */
  public hideNativeMessage(removeAtOnce?: boolean): void {
    window['hideNativeMessage'](removeAtOnce);
  }

}
