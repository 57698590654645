/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Int32SearchResponse } from '../../models/int-32-search-response';
import { SaveEquipmentCalendarViewModel } from '../../models/save-equipment-calendar-view-model';

export interface Save$Params {
      body?: SaveEquipmentCalendarViewModel
}

export function save(http: HttpClient, rootUrl: string, params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32SearchResponse>> {
  const rb = new RequestBuilder(rootUrl, save.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Int32SearchResponse>;
    })
  );
}

save.PATH = '/equipmentcalendars';
