  <h3 class="upsell-header my-header" *ngIf="upsellProducts?.length !== 0 " >
    <mibp-resource-string key="Upsell_UpgradeSaveMoney"></mibp-resource-string>
  </h3>

  <mibp-loader *ngIf="isLoading"></mibp-loader>

  <div  class="columns" [ngClass]="{'hidden': this.isLoading}" *ngIf="upsellProducts?.length !== 0 ">
    <div class="column" *ngFor="let upsellProduct of upsellProducts">
      <section class="upsell-card">
        <div class="image-container">
          <a   (click)="onClickProductImage(upsellProduct.code)">
          <mibp-image *ngIf="upsellProduct.partsPictureImageUrl" [disableLightbox]="'true'" [urlOfImage]="upsellProduct.partsPictureImageUrl"> </mibp-image>
          <img *ngIf="!upsellProduct.partsPictureImageUrl"  src="assets/images/NewDefaultImage.png" alt="image missing"></a>
        </div>

        <a  (click)="onClickProductImage(upsellProduct.code)">
          <h4 class="item-title" >{{upsellProduct.name}}</h4></a>
        <div class="upsell-card-info">
          <ul class="item-list">
            <li class="item-name"> <span class="item-label"><mibp-resource-string key="Global_ProductNumber"></mibp-resource-string>: </span> {{upsellProduct.code}}</li>
            <li *ngIf="canSeeAvailability" class="item-availability">
              <span class="item-label"><mibp-resource-string key="Global_ProductAvailability"></mibp-resource-string>: </span>
              <div class="item-availability__wrapper">
                <mibp-availability *ngIf="!useExpectedDate"
                  [productAvailability] = "upsellProduct.productAvailability"
                  [productCode]="upsellProduct.code"
                  [quantity]="upsellProduct.quantity">
                </mibp-availability>
                <mibp-expected-delivery-date-availability *ngIf="useExpectedDate"
                  [quantity]="upsellProduct.quantity"
                  [productExpectedDate] = "upsellProduct.productExpectedDate"
                  [productCode]="upsellProduct.code"
                ></mibp-expected-delivery-date-availability>
              </div>
            </li>
            <li><span class="item-label"><mibp-resource-string key="Upsell_UpgradeText"></mibp-resource-string>: </span>{{upsellProduct.upsellUpgradesProductCode}}</li>
            <li class="item-price">
              <mibp-product-price
              [productPrice]="upsellProduct.productPrice"
              [productCode]="upsellProduct.code"
              >
            </mibp-product-price>
            </li>
          </ul>
          <div class="upgrade-btn">
            <button type="button" data-gtm="upgrade-product-btn" class="my-button my-button--fill upsell-btn" (click)="onUpgradeProduct(upsellProduct.originatingUpsellProductId, upsellProduct.id)"><mibp-resource-string key="Upsell_UpgradeProductBtn"></mibp-resource-string></button>
          </div>
        </div>

      </section>
    </div>
  </div>
