import { FrontendContextService } from './../../../services/front-end-context/front-end-context.service';
import { GlobalConfigService, PermissionService } from 'root/services';
import { NavigationStart, Router } from '@angular/router';
import { MySandvikNavigationItem } from '../../../services/navigation/navigation-types';
import { BroadcastService } from '../../../services/broadcast-service/broadcast.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { AfterViewInit, Component, ElementRef, EmbeddedViewRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { Subscription, filter, fromEvent } from 'rxjs';
import { ActAsComponent } from 'root/components/act-as/act-as.component';
import { SidebarService } from 'root/services/sidebar-service/sidebar.service';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { environment } from 'root/environment';
import { MibpSessionViewModel, SessionDeliverySequenceViewModel, UserStatus, UserType } from 'root/mibp-openapi-gen/models';

@Component({
  selector: 'mibp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class MibpSidebarComponent implements OnInit, OnDestroy, AfterViewInit {

  items: MySandvikNavigationItem[];
  userSub: Subscription;
  dlvSeqSub: Subscription;
  userFullName?: string;
  userEmail?: string;
  isInternalUser: boolean;
  navSub: Subscription;
  actingAsText: string;
  canActAs = false;
  currentActAsText: string;
  @ViewChild(ActAsComponent) actAsComponent: ActAsComponent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('actAsDropdown') actAsdropdown: ElementRef<any>;
  isCollapes: boolean;
  isExpand: boolean;
  expandedRoute: MySandvikNavigationItem;
  hoverItem: MySandvikNavigationItem;
  @ViewChild('hoverMenuTemplate', {static: true}) hoverMenuTemplate: TemplateRef<any>;
  embeddedView: EmbeddedViewRef<any>;

  protected hoverMenuTop = 0;
  private hoverPopupDelay = 50;
  private hoverPopupCloseDelay = 50;
  private hoverPopupTimer?: number;
  private hoverCloseTimer?: number;
  private hoverExitSub?: Subscription;
  private navigatedSub?: Subscription;

  constructor(private permissionService: PermissionService, private viewContainerRef: ViewContainerRef, private navigation: NavigationService,
    protected globalConfig: GlobalConfigService,
    private broadcast: BroadcastService, private router: Router, private sidebarService: SidebarService,
    private context: FrontendContextService) {
  }
  ngAfterViewInit(): void {


    this.moveTemplateToBody();
  }

  private moveTemplateToBody(): void {
    const bodyElement = document.querySelector('body');
    this.embeddedView = this.viewContainerRef.createEmbeddedView(this.hoverMenuTemplate);
    this.embeddedView.rootNodes.forEach(rootNode => bodyElement.appendChild(rootNode));
  }


  showActAs(e: Event): void {
    e.preventDefault();
    this.actAsComponent.show(this.actAsdropdown.nativeElement, 'bottomleft');
  }

  closeActAs(scroll: boolean): void{
    if(scroll===true && this.actAsComponent) {
      this.actAsComponent.hide(100);
    }
  }

  onUserUpdated(u: MibpSessionViewModel): void {
    this.userEmail = u?.user?.email.endsWith('@punchout.int') ? null : u?.user?.email;
    this.userFullName = `${u?.user?.firstName ?? ''} ${u?.user?.lastName ?? ''}`;
    this.canActAs = this.permissionService.test(allPermissionPolicies.canActAs);

    if(u?.user){
      this.isInternalUser = u?.user?.type == UserType.Internal;

      // Don't start loading navigation if user is not active
      if (u?.user?.status == UserStatus.Active) {
        this.navigation.subscribeToNavigationChanges();
      }

    }
  }

  onDeliverySequenceChanged(d: SessionDeliverySequenceViewModel): void {
    if (d) {
      this.actingAsText = `${d?.erpCustomerId} - ${d.customerName} ${d.deliverySequenceNumber}`;
      this.currentActAsText = `${d?.companyCode} / ${d.erpCustomerId} / ${d.deliverySequenceNumber}`;
    } else {
      this.actingAsText = null;
      this.currentActAsText = null;
    }
  }

  onRootItemHover(e: Event, item: MySandvikNavigationItem): void {
    if (!this.globalConfig.sidebarHoverEnabled) {
      return;
    }
    // Do not hover on active item
    if (this.isRootNodeExpanded(item)) {
      this.hoverItem = null;
      this.hoverExitSub?.unsubscribe();
      return;
    }

    const hasAnyVisibleChildren = item.children?.filter(child => !child.calculated.hidden)?.length > 0;

    clearTimeout(this.hoverPopupTimer);

    const isSameItem = item == this.hoverItem;

    const timeoutFn = function(component: MibpSidebarComponent) {

      if (!hasAnyVisibleChildren) {
        component.hoverItem = null;
        component.hoverExitSub?.unsubscribe();
        return;
      }

      let hoverMenuTop = 0;
      const liElement = (e.target as HTMLElement).closest('li');
      if (liElement) {
        const rect = liElement.getBoundingClientRect();
        hoverMenuTop = rect.top;
      }

      // Todo - now we just assume the submenu item elements are 44px high
      const hoverMenuHeight = 44 * item.children.length;
      const hoverMenuBottom = hoverMenuHeight + hoverMenuTop;
      if (hoverMenuBottom > window.innerHeight) {

        const diff = (hoverMenuBottom - window.innerHeight) + 15;

        hoverMenuTop = hoverMenuTop -= diff;

       // hoverMenuTop = hoverMenuTop - hoverMenuHeight;
        if (hoverMenuTop < 0) {
          hoverMenuTop = 15;
        }
      }

      component.hoverMenuTop = hoverMenuTop;


      component.hoverItem = item;
      component.hoverExitSub?.unsubscribe();

      component.hoverExitSub = fromEvent(window, 'mousemove').subscribe(e => {

        clearTimeout(component.hoverCloseTimer);

        const target = e.target as HTMLElement;
        if (liElement.contains(target) || target.closest('.hovermenu')  ) {
          return;
        }

        // Close the hover menu
        component.hoverCloseTimer = window.setTimeout(() => {
          component.hoverItem = null;
          component.hoverExitSub?.unsubscribe();
        }, component.hoverPopupCloseDelay);

      });
    };

    if (!isSameItem) {
      if (this.hoverItem) {
        timeoutFn(this);
      } else {
        this.hoverPopupTimer = window.setTimeout(() => timeoutFn(this), this.hoverPopupDelay);
      }
    }

  }

  onNavigationUpdate(items: MySandvikNavigationItem[]): void {
    this.items = items;
  }

  trackById(_index: number, item: MySandvikNavigationItem): string {
    return item.uniqueId;
  }

  filteredItems(items: MySandvikNavigationItem[]): MySandvikNavigationItem[] {
    return items?.filter(i => i.calculated.hidden != true) || [];
  }

  ngOnInit(): void {

    this.userSub = this.broadcast.mibpSession.subscribe(e => this.onUserUpdated(e));
    this.dlvSeqSub = this.broadcast.deliverySequence.subscribe(e => this.onDeliverySequenceChanged(e));
    this.navSub = this.broadcast.navigation.subscribe(n => this.onNavigationUpdate(n));
    this.sidebarService.sidebarScroll.subscribe(scroll=>{
      this.closeActAs(scroll);
    });
    this.navigatedSub = this.router.events.pipe(filter(t => t instanceof NavigationStart)).subscribe(() => {
      this.hoverItem = null;
      clearTimeout(this.hoverPopupTimer);
      clearTimeout(this.hoverCloseTimer);
      this.hoverExitSub?.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe();
    this.dlvSeqSub?.unsubscribe();
    this.embeddedView?.destroy();
  }

  isRootNodeExpanded(item: MySandvikNavigationItem): boolean {

    if(this.expandedRoute === item){
      return false;
    }
    if (!item?.calculated?.fullPath) {
      return false;
    }

    return this.router.isActive(this.router.createUrlTree(item.calculated.fullPath), false);
  }


  isChildNodeVisible(item: MySandvikNavigationItem): boolean {

    return item.children?.filter(i => i.calculated.hidden !== true).length > 0;
  }

  collapseMenu(item: MySandvikNavigationItem): void {

    if(this.isRootNodeExpanded(item)){
      this.expandedRoute = item;
    }
    else{
      this.expandedRoute = null;
    }
  }

  public openInNewTab(url: string): void{
    window.open(url, "_blank");
  }
}
