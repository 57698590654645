import { ApplicationStateService } from 'root/services/application-state/application-state.service';
import { LogService, MibpLogger } from 'root/services/logservice';
import { Injectable } from "@angular/core";
import { UrlTree, Router } from "@angular/router";
import { ActivationArgs } from "root/guards";
import { LocalizationService } from "../localization/localization.service";
import { AuthService } from "../auth-service/auth.service";
import { ApplicationStates } from '../application-state/application-state.types';

/**
 * Use from startup guard.
 * Will make sure a language code is used
 */
@Injectable({
  providedIn: 'root'
})
export class StartupLanguageService {

  log: MibpLogger;

  constructor(private router:  Router, private localizationService: LocalizationService, private authService: AuthService, private logger: LogService, private appState: ApplicationStateService) {
    this.log = logger.withPrefix('startup-language.service');
  }

  public async testLanguage(args: ActivationArgs): Promise<boolean | UrlTree> {

    return  Promise.resolve(true);

    // Language is always ok if it's the style guide or a b2c auth callback
    // const isRedirectCallback = this.authService.urlContainsHash(args.state.url);
    // if (isRedirectCallback || this.isNonLanguagePage(args)) {
    //   return Promise.resolve(true);
    // }

    // Otherwise check that language code!
    const languageStatus = this.localizationService.verifyLanguageFromUrl(args.state.url, true);

    if (languageStatus !== true) {
      window.location.href = languageStatus as string;

      return new Promise( () => {
        // Block route processing in favor of above redirect
      });
    }

    if (!this.localizationService.Locale && languageStatus === true) {

      this.appState.setState({
        state: ApplicationStates.LoadingTranslations,
        resourceStringKey: 'AppLoading_Resources',
        textFallback: 'Loading texts and translations'
      });

      return new Promise((resolve) => {

        this.localizationService.getResourceStrings().toPromise().then(() => {
          resolve(true);
        }, (err) => {
          this.appState.setState({
            state: ApplicationStates.LoadingTranslations,
            resourceStringKey: 'AppLoading_Resources_Error',
            textFallback: 'Loading texts and translations',
            error: true,
            stack: 'STARTUPLANGSVC ' + err
          });
          this.log.error("COULD NOT LOAD RESOURCE STRINGS", err);
        });

      });

    }

    return languageStatus === true ? Promise.resolve(true) : this.router.parseUrl(languageStatus as string);
  }

  /**
   * Check if the root route have this named child
   */
  private isNonLanguagePage(args: ActivationArgs): boolean {
    // Perhaps this can be done in a more dynamic manner. It'll have to wait though, since it's just the style-guide for now
    const part = args.state.url.toString().replace(/^(.*?)[\?#].+$/, '$1').split('/').filter(segment => segment);
    if (part.length > 0 && part[0] === 'style-guide') {
      return true;
    }
    return false;
  }

}
