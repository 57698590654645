import { LocalizationService } from './../localization/localization.service';
import { ResourceStringEditorComponent } from 'root/components';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { NoticebarService } from '../noticebar-service/noticebar.service';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { ClientSideCacheService, CacheScope } from '../client-side-cache/client-side-cache.service';
import { FrontendContextService } from './../front-end-context/front-end-context.service';
import { SignalR_RoleAdministrationPermission } from '../mibp-api-services';
import { MibpSessionService } from '../mibp-session/mibp-session.service';
import { MySandvikFeatures } from '../permission';

@Injectable({
  providedIn: 'root'
})
export class ResourceStringEditorService {

  private component: ResourceStringEditorComponent;
  private keyDownListener: () => void;
  private renderer: Renderer2;
  private listenerTimer;
  private editKey = new Subject();
  private editMode = new BehaviorSubject<boolean>(false);

  constructor(rendererFactory: RendererFactory2,
    private localizationService: LocalizationService,
    private notifications: NoticebarService,
    private cacheService: ClientSideCacheService,
    private sessionService: MibpSessionService,
    private context: FrontendContextService) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.keyDownListener = this.renderer.listen('window', 'keyup', (e) => this.onKeyDown(e));
    this.editMode.next(this.isEditMode());
  }

  // public get AvailableResourceStrings$() {
  //   return this._availableResourceStrings.asObservable();
  // }

  public isEditMode(): boolean {
    if ( !this.sessionService.hasFeature(MySandvikFeatures.AdminManageTranslations)) {
      return false;
    }

    if (this.cacheService.get<boolean>('resourceStringEditMode') === true) {
      return true;
    }
    return false;
  }

  public setEditMode(enabled: boolean): void {
    const prevValue = this.isEditMode();
    if (enabled) {
      this.cacheService.add('resourceStringEditMode', true, null, CacheScope.UserSessionStorage);
    } else {
      this.cacheService.remove('resourceStringEditMode');
    }
    if (prevValue !== enabled) {
      this.editMode.next(enabled);
    }
  }

  public get EditKey$(): Observable<unknown> {
    return this.editKey.asObservable();
  }

  public get EditMode$(): Observable<boolean> {
    return this.editMode.asObservable();
  }

  public setComponentInstance(component: ResourceStringEditorComponent): void {
    this.component = component;
  }

  private onKeyDown(event) {
    if ((event.code === 'KeyE' || event.which === 69)) {

      if (this.listenerTimer) {
        clearTimeout(this.listenerTimer);
      }
      this.listenerTimer = setTimeout(() => {
        this.editKey.next(undefined);
      }, 200);
    }
  }


  public openEditor(key: string, languageCode: string = null, options?: {
    enableCurrentPageFilter?: boolean;
    enableNotTranslatedFilter?: boolean;
  }): Promise<void> {


    const showCurrentPage = options && options.enableCurrentPageFilter;
    const showNotTranslated = options && options.enableNotTranslatedFilter;

    if (languageCode === null) {
      languageCode = this.localizationService.getLang();
    }

    return new Promise((resolve) => {

      if (key !== null) {
        this.localizationService.getResourceString(key, languageCode).then(value => {
          const component = this.component;
          const notifications = this.notifications;
          if (value.match(/^[a-z]{2}:'/i)) {
            this.localizationService.getFromServer(key, languageCode)
              .then(ok => {
                done(!!ok);
              }).catch(() => done(false));
          } else {
            done(true);
          }

          function done(exists: boolean) {
            if (exists) {
              component.open(key, languageCode);
              resolve();
            } else {
              notifications.show('Resource string is not in database', NoticeType.Error);
              resolve();
            }
          }


        });
      } else {
        this.component.open(key, languageCode, showCurrentPage, showNotTranslated);
      }

    });
  }



}
