/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EquipmentCalendarViewModelSearchResponse } from '../../models/equipment-calendar-view-model-search-response';
import { SearchEquipmentCalendarViewModel } from '../../models/search-equipment-calendar-view-model';

export interface Search$Params {
      body?: SearchEquipmentCalendarViewModel
}

export function search(http: HttpClient, rootUrl: string, params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentCalendarViewModelSearchResponse>> {
  const rb = new RequestBuilder(rootUrl, search.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EquipmentCalendarViewModelSearchResponse>;
    })
  );
}

search.PATH = '/equipmentcalendars/search';
