<form name="quickAddUploadForm" class="ng-pristine ng-valid">
    <div class="quick-add__file">
      <label for="fileInputQuickAdd"><mibp-resource-string key="Quick-Add-Dashboard-Import-Title"></mibp-resource-string></label>
      <input type="file" #fileInputQuickAdd [id]="fileInputId" accept=".xls,.xlsx,.csv" class="inputfile inputfile-6" (click)="onSelectFile($event)" (change)="fileChangeEvent($event)">
      <label [for]="fileInputId" class="fileLabel">
        <span>{{ fileName }}</span>
        <span class="filebutton">
          <span translate="no" class="material-icon material-icon--white">upload</span>&nbsp;<mibp-resource-string key="Quick-Add-Dashboard-Import-ChooseFile"></mibp-resource-string>
        </span>
      </label>
    </div>
    <div class="quick-add__action">
        <mibp-button data-gtm="quick-add-import-btn" (click)="upload()" [disabled]="!fileName" class="importbtn" resourceStringKey="Quick-Add-Dashboard-Import-ImportButton" [style]="btn.Enum.Styles.Fill" #btn></mibp-button>
    </div>
    <div class="list-item-content-file">
        <a href="javascript:void(0)" (click)="dialogHelp.open()">
          <span translate="no" class="material-icon material-icon--fill">info</span>
          <mibp-resource-string key="Quick-Add-Dashboard-Import-FileInfo"></mibp-resource-string></a>
    </div>
</form>
<div>
  <mibp-dialog #dialogHelp [buttons]="dialogButtons" (buttonClick)="onDialogButton($event)">
    <h2 class="blue-text"><mibp-resource-string key="Quick-Add-Dashboard_ImportHelp_Header"></mibp-resource-string></h2>
    <div class="tiles">
      <div class="tiles__row">
          <div class="tile tiles--size2 tile--clean">
              <h3><mibp-resource-string key="Quick-Add-Dashboard_ImportHelp_FileTypesHeader"></mibp-resource-string></h3>
              <div class="tile__body">
                  <p>
                    <mibp-resource-string isHtml="true" key="Quick-Add-Dashboard_ImportHelp_Line1"></mibp-resource-string>
                  </p>
                  <p>
                    <mibp-resource-string isHtml="true" key="Quick-Add-Dashboard_ImportHelp_Line2"></mibp-resource-string>
                  </p>
                  <p>
                    <mibp-resource-string isHtml="true" key="Quick-Add-Dashboard_ImportHelp_Line3"></mibp-resource-string>
                  </p>
              </div>
          </div>
          <div class="tile tiles--size1 tile--clean plain-text">
              <h3 style="margin-left: 0;"><mibp-resource-string key="Quick-Add-Dashboard_ImportHelp_ExampleFilesHeader"></mibp-resource-string></h3>
              <ul style="font-size: 125%; margin-top: 1em;">
                  <li><a href="/assets/blob/CSV%20Template.csv" target="_blank"><span translate="no" class="material-icon material-icon--small">file_save</span> CSV Template.csv</a></li>
                  <li><a href="/assets/blob/Excel%20Template.xlsx" target="_blank"><span translate="no" class="material-icon material-icon--small">file_save</span> Excel Template.xslx</a></li>
              </ul>
          </div>
      </div>
    </div>
  </mibp-dialog>
</div>
