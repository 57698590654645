import { GlobalConfigService } from './../../services/global-config/global-config.service';
/* eslint-disable @angular-eslint/no-output-native */
import { Component, ViewChild, TemplateRef, ViewContainerRef, Output, EventEmitter, Input } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UserAction } from './context-menu.types';
import { MibpLogger, LogService, PermissionService } from 'root/services';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'mibp-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {

  @ViewChild('menuTemplate', { static: true }) dialogTemplate: TemplateRef<any>;


  overlayRef: OverlayRef | null;
  sub: Subscription;
  contextMenuItems: UserAction[];
  log: MibpLogger;
  scrollSub: Subscription;
  @Input() items: UserAction[] = [];
  @Input() showIcon = false;
  @Input() showIconIndicator = false;
  // TODO: Rename events to non-native events
  @Output() show = new EventEmitter();
  @Output() hide = new EventEmitter();
  @Output() select = new EventEmitter<UserAction>();

  constructor(private viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    logger: LogService,
    private permissionService: PermissionService,
    private globalconfig: GlobalConfigService) {
    this.log = logger.withPrefix('context-menu');
  }

  private watchCloseOnScroll(): void {
    const scrollContainer = document.getElementById('my-main-container')?.parentElement;
    this.scrollSub = fromEvent<MouseEvent>(scrollContainer, 'scroll')
      .subscribe(() => {
        this.close();
      });
  }

  selectAction(action: UserAction): void {
    if (!action.disabled) {
      this.log.debug('Action selected ', action.name);
      this.select.emit(action);
      this.close();
    }
  }

  open(e: MouseEvent, beforeShowContext?: any, beforeShow?: (items: UserAction[]) => UserAction[], ...extraParams: any[]): void {
    const x = e.clientX;
    const y = e.clientY;

    this.close();

    if (!this.items) {
      return;
    }

    // Make sure to clone default items and not copy so callback modifications are not saved
    const actionsClone = this.items.map(i => Object.assign({}, i));

    if (beforeShow) {
      let args: any[];
      args = [actionsClone].concat(extraParams);
      try {
        this.contextMenuItems = beforeShow.apply(beforeShowContext, args);
      } catch (exception) {
        this.log.error("An error occured in the beforeShow method of context-menu", exception);
      }
    } else {
      this.contextMenuItems = actionsClone;
    }

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    const contextMenuItems = this.contextMenuItems.filter(f => !f.permissionPolicy || this.permissionService.test(f.permissionPolicy));

    this.overlayRef.attach(new TemplatePortal(this.dialogTemplate, this.viewContainerRef, {
      $implicit: contextMenuItems
    }));

    this.show.emit();


    this.watchCloseOnScroll();


    // Hide menu when clicking outside
    setTimeout(() => {
      this.sub = fromEvent<MouseEvent>(document, 'click')
        .pipe(
          filter(event => {
            const clickTarget = event.target as HTMLElement;
            return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
          }),
          take(1)
        ).subscribe(() => this.close());
    }, 50);

  }

  close(): void {
    this.sub?.unsubscribe();
    this.scrollSub?.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.hide.emit();
    }
  }



}
