import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { BillOfMaterialApiController } from 'root/mibp-openapi-gen/controllers';
import { MediaFolderVm, MediaViewModel, PartsCatalogueFolderType } from "root/mibp-openapi-gen/models";
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService, BroadcastService, LocalizationService, MibpLogger, LogService, GlobalConfigService } from 'root/services';
import { ScrollToService } from './../../services/scroll-to/scroll-to.service';
import { GetEquipmentMediaResponseViewModel } from './../../mibp-openapi-gen/models/get-equipment-media-response-view-model';
import { EquipmentWithBillOfMaterialVm } from './../../mibp-openapi-gen/models/equipment-with-bill-of-material-vm';
import { DropdownInput } from 'root/components';
import { ApiErrorHandlerService } from 'root/services/api-error-handler/api-error-handler';
import { HttpStatusCode } from "@angular/common/http";
import { Subscription } from 'rxjs/internal/Subscription';
import { firstValueFrom, skip } from "rxjs";
import { MibpBreadcrumbsV2ManualControlComponent } from 'root/components/navigation/breadcrumbs/breadcrumb-manual-control/breadcrumb-manual-control.component';
import { MibpCrumb } from './../navigation/breadcrumbs/breadcrumbs-v2.types';
import { UrlHelperService } from './../../services/url-helper/url-helper.service';
import { UserAction } from './../context-menu/context-menu.types';
import { OperationsApiController } from './../../mibp-openapi-gen/services/operations-api-controller';
import { NoticebarService } from './../../services/noticebar-service/noticebar.service';
import { NoticeType } from "../noticebar/noticebar.enum";
import { MibpPartsManualTreeviewComponent } from "./parts-manual-treeview/parts-manual-treeview.component";
import { PartsManualReporterrorComponent } from "./parts-manual-reporterror/parts-manual-reporterror.component";
import { ReportErrorDto } from "./parts-manual-reporterror/report-error-dto";


@Component({
  selector: 'mibp-parts-manual',
  styleUrls: ['./parts-manual.component.scss'],
  templateUrl: './parts-manual.component.html'
})
export class MibpPartsManualComponent implements OnChanges, OnInit, OnDestroy {

  @Input() equipmentId?: number;
  @Input() mediaIdentifier?: string;
  @Input() initialFolderId?: number;

  @ViewChild(MibpBreadcrumbsV2ManualControlComponent) breadcrumbControl: MibpBreadcrumbsV2ManualControlComponent;
  @ViewChild(MibpPartsManualTreeviewComponent) treeview: MibpPartsManualTreeviewComponent;
  @ViewChild(PartsManualReporterrorComponent) reportErrorDialog: PartsManualReporterrorComponent;
  @Output() mediaLoaded = new EventEmitter<GetEquipmentMediaResponseViewModel>();

  equipment?: EquipmentWithBillOfMaterialVm;
  selectedFolderId?: number;
  actAsSub?: Subscription;
  isLoading = false;
  isUnauthorized = false;
  supportMenu: UserAction[];
  partsManual: MediaViewModel;
  hasError = false;
  mibpLogger: MibpLogger;
  reportErrorDto= <ReportErrorDto>{};
  enableReportPartsManualSupportCase: boolean;
  currentChapter: string;

  constructor(private bomApi: BillOfMaterialApiController,
    private scrollTo: ScrollToService,
    private router: Router, private route: ActivatedRoute, private broadcast: BroadcastService,
    private urlHelper: UrlHelperService,
    private noticebar: NoticebarService,
    private operationsApi: OperationsApiController,
    private localizationService: LocalizationService,
    private errorHandler: ApiErrorHandlerService,
    private logService: LogService,
    private globalConfig: GlobalConfigService) {}

  ngOnInit(): void {
    this.actAsSub = this.broadcast.deliverySequence.pipe(skip(1)).subscribe(() => this.loadEquipmentMedia());
    this.mibpLogger = this.logService.withPrefix('parts-manual');
    this.enableReportPartsManualSupportCase = this.globalConfig.enableReportPartsManualSupportCase;

  }
  ngOnDestroy(): void {
    this.actAsSub?.unsubscribe();
  }

  loadTree() {
    this.treeview.loadTree();
  }

  loadPage(folders: MediaFolderVm[]): void {

    this.scrollTo.stopNextScrollToTop();
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        folder: folders.length > 0 ? folders[folders.length - 1].id : null
      }});

    const crumbs: MibpCrumb[] = [];

    crumbs.push({
      text: this.localizationService.get('Global_Equipment') + ' '+ this.equipment.equipmentSerial +  (this.equipment?.equipmentAlias ? ` (${this.equipment.equipmentAlias})` : ''),
      route: [this.urlHelper.create(this.router.url, {}).path]
    });

    crumbs.push({
      text: this.mediaIdentifier,
      route: [this.urlHelper.create(this.router.url, {}).path]
    });

    if (folders.length > 0) {
      const lastFolder = folders[folders.length - 1];
      if (lastFolder) {
        crumbs.push({
          text: '... / ' + lastFolder.name
        });
      }
    }

    this.breadcrumbControl.setBreadcrumbs(crumbs, -1);
  }

  changeMedia(item: DropdownInput): void {
    if (item.value !== this.mediaIdentifier) {
      this.router.navigate(['../', item.value], {
        queryParamsHandling: 'merge',
        relativeTo: this.route,
        queryParams: {
          folder: null
        }});
    }

  }

  async onSupportMenuAction(action: UserAction): Promise<void> {

    if (action.name === 'clear-media-cache') {

      try {
        const deletedKeys = await firstValueFrom(this.operationsApi.deleteFromCache({
          keyOrPrefix: `bom/${this.mediaIdentifier}`,
          deleteStartingWith: true
        }));
        this.noticebar.showText(`${deletedKeys.length} key(s) were deleted`, NoticeType.Success, false);
      } catch(err) {
        this.errorHandler.parseError(err).showNotice();
      }


    }

  }

  ngOnChanges(changes: SimpleChanges): void {

    const equipmentIdChanged = changes.equipmentId && changes.equipmentId.currentValue != changes.equipmentId.previousValue;
    const mediaIdentifierChanged = changes.mediaIdentifier && changes.mediaIdentifier.currentValue != changes.mediaIdentifier.previousValue;

    if ( (equipmentIdChanged || mediaIdentifierChanged) &&  this.equipmentId && this.mediaIdentifier) {
      this.loadEquipmentMedia();
      this.supportMenu = this.mediaIdentifier ? [
        {
          name: 'clear-media-cache',
          textOverride: 'Clear cache for Media "' + this.mediaIdentifier + '"',
          danger: true,
          iconName: 'delete'
        }
      ] : null;
    }


  }
  loadEquipmentMedia(): void {
    this.isLoading = true;
    this.bomApi.getEquipmentMedia({
      equipmentId: this.equipmentId,
      mediaIdentifier: this.mediaIdentifier
    }).subscribe({
      next: eq => {
        this.isUnauthorized = false;
        this.equipment = eq.equipment;
        this.partsManual = eq.partsManual;
        this.mediaLoaded.emit(eq);
      },
      complete: () => {
        this.isLoading = false;

      },
      error: err => {
        this.errorHandler.parseError(err)
          .catchHttpStatusCode(HttpStatusCode.Unauthorized, e => {
            this.isUnauthorized = true;

          })
          .always(() => {
            this.isLoading = false;
            this.hasError = true;
            this.mibpLogger.error("Error fetching media", err);
          });
      }
    });
  }
  showReportErrorModal(){
    let chapter = '';
    if(this.treeview?.selectedPage?.parentFolderId == null){
      chapter = this.treeview.selectedPage?.name;
    }
    else{
      chapter = this.treeview.tree.find(t=> t.id== this.treeview.selectedPage?.parentFolderId)?.name;
    }

    this.reportErrorDto = <ReportErrorDto>{
      equipmentId: this.equipmentId,
      mediaName: this.partsManual.mediaName,
      url: this.router.url,
      chapter: chapter,
      page:  `${this.treeview.selectedPage?.name} ${this.treeview.selectedPage?.assemblyNumber?? ''} ${this.treeview.selectedPage?.revision ?? ''}`  ,
      mediaIdentifier: this.partsManual.mediaIdentifier
    };

    this.reportErrorDialog.openDialog();
  }
}
