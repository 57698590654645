import { query } from '@angular/animations';

import { DeliverySequenceIndexSearchResponse } from './../../mibp-openapi-gen/models/delivery-sequence-index-search-response';
import { DeliverySequencePickerApiController } from './../../mibp-openapi-gen/services/delivery-sequence-picker-api-controller';
import { DropdownComponent, DropdownData, DropdownInput, DropdownArgs } from 'root/components/dropdown';
import { ClientSideCacheService, MibpLogger, LogService } from 'root/services';
import { CacheScope } from 'root/services/client-side-cache/client-side-cache.service';
import { FrontendContextService } from './../../services/front-end-context/front-end-context.service';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, forwardRef, ViewEncapsulation } from "@angular/core";
import { Observable, of } from "rxjs";
import { UntypedFormBuilder, AbstractControl, Validators, UntypedFormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { tap, delay, map } from "rxjs/operators";
import { Guid } from 'guid-typescript';
import { CompanyIndexSearchResponse } from 'root/mibp-openapi-gen/models/company-index-search-response';
import { BusinessRelationIndexSearchResponse } from 'root/mibp-openapi-gen/models/business-relation-index-search-response';
import { BusinessRelation, Company, DeliverySequence, DeliverySequencePickerDto, SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

@Component({
  selector: 'mibp-deliverysequence-picker',
  templateUrl: './deliverysequence-picker.component.html',
  styleUrls: ['./deliverysequence-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DeliverySequencePickerComponent),
    }
  ]
})
export class DeliverySequencePickerComponent implements OnInit, ControlValueAccessor {

  @ViewChild('companyDropdown') companyDropdown: DropdownComponent;
  @Input() hideUnavailableElements: boolean;
  @Input() organizationId: Guid;
  @Input() disableCompanyDropdown: { isDisabled: boolean } = { isDisabled: false };
  @Input() disableAll = false;
  @Input() isRequired = false;
  @Input() displayNumberNameCityFormat = false;
  @Input() filterInactive = false;
  @Output() valueChange = new EventEmitter();
  @Output() dropdownShow = new EventEmitter();
  @Output() dropdownHide = new EventEmitter();

  isDisabled = false;
  actAsForm: UntypedFormGroup;
  log: MibpLogger;

  // Observables for the dropdown data
  companies$: Observable<DropdownData>;
  customers$: Observable<DropdownData>;
  deliverySequences$: Observable<DropdownData>;

  // Will contain the current list of delivery sequences
  deliverySequenceList: DeliverySequence[];

  hideCustomerDropdown = true;
  hideDeliverySequenceDropdown = true;
  isFirstValidation = false;
  customerChangeFromCompany = false;
  currentValue: DeliverySequence = null;

  currentBusinessRelation: BusinessRelation;
  currentCompany: Company;


  showDeliverySequenceLoader: boolean;
  showCompanyLoader: boolean;
  showCustomerLoader: boolean;
  writeValueError = false;
  disableAllValidation = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private frontendContext: FrontendContextService,
    private cache: ClientSideCacheService,
    logger: LogService,
    private sessionService: MibpSessionService,
    private apiRest: DeliverySequencePickerApiController) {
    this.log = logger.withPrefix('deliverysequence-picker');
    // this.currentValue = this.frontendContext.ActiveDeliverySequence;
  }

  ngOnInit(): void {

    let selectedCustomer = null;
    let selectedCompany = null;
    let selectedDeliverySequence = null;



    if (this.currentValue) {
      selectedCustomer = {
        text: `${this.currentValue.businessRelation.erpCustomerID} ${this.currentValue.businessRelation.customer.name}`,
        value: this.currentValue.businessRelation.erpCustomerID
      };
      selectedCompany = {
        text: `${this.currentValue.businessRelation.company.code}  -  ${this.currentValue.businessRelation.company.name}`,
        value: this.currentValue.businessRelation.company.code
      };

      const displayText = this.displayNumberNameCityFormat ? this.currentValue.number + " / " + this.currentValue.name + " / " + this.currentValue.displayName
        : this.currentValue.number + ' ' + this.currentValue.name;
      selectedDeliverySequence = {
        text: displayText,
        value: this.currentValue.id
      };
    }

    // Configure FormGroup
    this.actAsForm = this.formBuilder.group({
      companyDropdown: [
        selectedCompany,
        [Validators.required],
        [this.validateCompanyAsync.bind(this)]
      ],
      customerDropdown: [
        selectedCustomer,
        [Validators.required],
        [this.validateCustomerAsync.bind(this)]
      ],
      deliverySequenceDropdown: [
        selectedDeliverySequence,
        [Validators.required]
      ]
    });

    if (this.disableCompanyDropdown.isDisabled) {
      this.actAsForm.get('companyDropdown').disable();
    }
  }

  onDropdownShow(): void {
    this.dropdownShow.emit();
  }

  onDropdownHide(): void {
    this.dropdownHide.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {

    let deliverySequenceId = 0;
    this.writeValueError = false;
    this.showCompanyLoader = false;
    this.showCustomerLoader = false;
    this.showDeliverySequenceLoader = false;
    if (typeof obj === 'number') {
      deliverySequenceId = obj;
      this.deliverySequenceList = [];
    } else if (obj && obj.deliverySequenceId) { // maby enough to check if obj.businessRelation has a value
      this.deliverySequenceList = [obj];
      const dlvSessionVm = obj as SessionDeliverySequenceViewModel;
      deliverySequenceId = dlvSessionVm.deliverySequenceId;
    } else if (obj !== null && typeof obj !== 'undefined') {
      this.writeValueError = true;
    } else if (obj === null || typeof obj === 'undefined') {
      this.actAsForm.setValue({
        customerDropdown: null,
        companyDropdown: null,
        deliverySequenceDropdown: null
      });
    }

    if (deliverySequenceId !== 0) {


      this.showCompanyLoader = true;
      this.showCustomerLoader = true;
      this.showDeliverySequenceLoader = true;


      this.apiRest.validate(
        {
          companyCode: '',
          erpCustomerId: '',
          deliverySequenceId: obj,
        }).subscribe(validated => {

        if (validated.deliverySequence === null) {
          this.log.error('Could not retreive delivery sequence');
          this.writeValueError = true;
          this.showCompanyLoader = false;
          this.showCustomerLoader = false;
          this.showDeliverySequenceLoader = false;
          return;
        }

        this.deliverySequenceList = [validated.deliverySequence];
        this.currentCompany = validated.company;
        this.currentBusinessRelation = validated.businessRelation;

        this.disableAllValidation = true;
        this.actAsForm.setValue({
          customerDropdown: {
            text: `${validated.businessRelation.erpCustomerID} ${validated.businessRelation.customer.name}`,
            value: validated.businessRelation.erpCustomerID
          },
          companyDropdown: {
            text: `${validated.businessRelation.company.code}  -  ${validated.businessRelation.company.name}`,
            value: validated.company.code
          },
          deliverySequenceDropdown: {
            text: `${this.displayNumberNameCityFormat ? validated.deliverySequence.number + " / " + validated.deliverySequence.name + " / " + validated.deliverySequence.displayName
              : validated.deliverySequence.number + ' ' + validated.deliverySequence.displayName}`,
            value: validated.deliverySequence.id
          }
        });

        if (!this.hideUnavailableElements) {
          this.actAsForm.get('customerDropdown').enable();
          this.actAsForm.get('deliverySequenceDropdown').enable();
        }
        if (this.disableAll) {
          this.actAsForm.get('companyDropdown').disable();
          this.actAsForm.get('customerDropdown').disable();
          this.actAsForm.get('deliverySequenceDropdown').disable();
        }

        this.showCompanyLoader = false;
        this.showCustomerLoader = false;
        this.showDeliverySequenceLoader = false;
        this.disableAllValidation = false;

      }, error => {
        this.log.error('Could not validate delivery sequence', error);
      });

    }



    // this.activeListIndex = -1;
    // if (obj != null && typeof obj !== 'undefined') {
    //   if (this.items) {
    //     const itemIndexInList = this.items.findIndex(i => i.value === obj);
    //     if (itemIndexInList !== -1) {
    //       this.activeListIndex = itemIndexInList;
    //       this.selectedOption = this.items[itemIndexInList];
    //       this.triggerChange(this.items[itemIndexInList]);
    //     } else {
    //       this.selectedOption = <DropdownInput>obj;
    //       this.triggerChange(obj);
    //     }
    //   } else {
    //     this.selectedOption = <DropdownInput>obj;
    //     this.triggerChange(obj);
    //   }
    // } else {
    //   this.selectedOption = null;
    //   this.triggerChange(null);
    // }
  }

  triggerChange(event: DropdownInput): void {
    // Do things before triggering the change?
    if (event) {
      if (event.value) {
        const selectedDeliverySequenceId = parseInt(event.value, 10);
        const deliverySequence = this.deliverySequenceList.find(d => d.id === selectedDeliverySequenceId);
        if (deliverySequence) {
          if (this.currentValue !== deliverySequence) {
            this.currentValue = deliverySequence;

            const value = Object.assign({}, deliverySequence);
            value.businessRelation = this.currentBusinessRelation;
            value.businessRelation.company = this.currentCompany;

            this.onChange(value.id);
            this.valueChange.emit(value);
            this.writeValueError = false;
          }
        } else {
          // Tell Angular validation that we have no value if theres no delivery sequence
          this.onChange(null);
          this.valueChange.emit(null);
        }
      } else {
        this.onChange(null);
        this.valueChange.emit(null);
      }
    } else {
      if (this.currentValue) {
        this.currentValue = null;
        this.onChange(null);
        this.valueChange.emit(null);
      }
    }

  }

  triggerTouched(event: Event, selectedItem: any = null): void {
    this.onTouched();
    this.triggerChange(selectedItem ? selectedItem : null);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types,  @typescript-eslint/no-unused-vars
  onChange = (value: any): void => { };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = (): void => { };

  // ControlValueAccessor implementation
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // TODO: Fix disabled state
    this.isDisabled = isDisabled;
  }


  onFilterCustomers(args: DropdownArgs): void {
    const companyCode = this.getFieldValue(`companyDropdown`);
    let searchResponse$: Observable<BusinessRelationIndexSearchResponse>;
    if (this.organizationId) {
      searchResponse$ = this.apiRest.searchBusinessRelationsForOrganization({
        body:
        {
          query: args.query,
          skip: args.index,
          take: args.take,
          organizationId: this.organizationId.toString(),
          companyCode: companyCode.toString(),
          erpCustomerId: '',
          deliverySequenceId: null,
          fetchActiveOnly: this.filterInactive
        }
      });
    } else {
      searchResponse$ = this.apiRest.searchBusinessRelations(
        {
          body:
          {
            query: args.query,
            skip: args.index,
            take: args.take,
            organizationId: null,
            companyCode: companyCode.toString(),
            erpCustomerId: '',
            deliverySequenceId: null,
            fetchActiveOnly: this.filterInactive
          }
        });
    }

    this.customers$ = searchResponse$.pipe(
      map(companyListResult => {
        // Map result to an IDropdownData object
        return <DropdownData>{
          items: companyListResult.items.map(businessRelation => {
            return { text: `${businessRelation.erpCustomerId} ${businessRelation.customerName}`, value: businessRelation.erpCustomerId,
              disabled: !(businessRelation.isActive && businessRelation.hasActiveDeliverySequences) };
          }),
          totalCount: companyListResult.count,
          hasMoreResults: args.index + 10 < companyListResult.count
        };
      })
    );

  }

  onChangeCompany(event): void {
    this.customers$ = null;
    this.deliverySequences$ = null;
    this.triggerChange(null);

    if (!this.disableCompanyDropdown.isDisabled) {
      this.actAsForm.get('companyDropdown').enable();
    }

    if (event !== null) {
      this.hideCustomerDropdown = false;
      this.hideDeliverySequenceDropdown = false;
    } else {
      this.hideCustomerDropdown = true;
      this.hideDeliverySequenceDropdown = true;
      if (!this.hideUnavailableElements) {
        this.actAsForm.get('customerDropdown').disable();
        this.actAsForm.get('deliverySequenceDropdown').disable();
      }
    }
  }

  onChangeCustomer(): void {
    this.deliverySequences$ = null;
  }

  onChangeDeliverySequence(event: DropdownInput): void {
    this.triggerChange(event);
  }

  /**
   * Occurs when user starts searching for companies in the list
   */
  onFilterCompanies(args: DropdownArgs): void {
    let searchResponse$: Observable<CompanyIndexSearchResponse>;
    if (this.organizationId) {
      searchResponse$ = this.apiRest.searchCompaniesForOrganization(
        {
          body:
          {
            query: args.query,
            skip: args.index,
            take: args.take,
            organizationId: this.organizationId.toString(),
            companyCode: '',
            erpCustomerId: '',
            deliverySequenceId: null,
            fetchActiveOnly: this.filterInactive
          }
        });
    } else {
      searchResponse$ = this.apiRest.searchCompanies(
        {
          body:
          {
            query: args.query,
            skip: args.index,
            take: args.take,
            organizationId: null,
            companyCode: '',
            erpCustomerId: '',
            fetchActiveOnly: this.filterInactive
          }
        });
    }

    this.companies$ = searchResponse$
      .pipe(
        map(companyListResult => {
          // Map result to an IDropdownData object
          return <DropdownData>{
            items: companyListResult.items.map(company => {
              return { text: company.code + ' - ' + company.name, value: company.code.toString(),
                disabled: !(company.isActive && company.hasActiveBusinessRelations) };
            }),
            totalCount: companyListResult.count,
            hasMoreResults: args.index + 10 < companyListResult.count
          };
        })
      );
  }

  /**
   * Occurs when user starts searching for companies in the list
   */
  onFilterDeliverySequences(args: DropdownArgs): void {
    const customerNumber = this.getFieldValue(`customerDropdown`);
    const companyCode = this.getFieldValue('companyDropdown');

    let searchResponse$: Observable<DeliverySequenceIndexSearchResponse>;
    if (this.organizationId) {
      searchResponse$ = this.apiRest.searchDeliverySequencesForOrganization(
        {
          body:
          {
            query: args.query,
            skip: args.index,
            take: args.take,
            organizationId: this.organizationId.toString(),
            companyCode: companyCode.toString(),
            erpCustomerId: customerNumber,
            deliverySequenceId: null,
            fetchActiveOnly: this.filterInactive
          }
        });
    } else {
      searchResponse$ = this.apiRest.searchDeliverySequences(
        {
          body:
          {
            query: args.query,
            skip: args.index,
            take: args.take,
            organizationId: null,
            companyCode: companyCode.toString(),
            erpCustomerId: customerNumber,
            fetchActiveOnly: this.filterInactive
          }
        });
    }


    this.deliverySequences$ = searchResponse$.pipe(
      tap(deliverySequenceListResult => {
        const newItems = deliverySequenceListResult ? deliverySequenceListResult.items.map(ix => <DeliverySequence>{
          id: parseInt(ix.id, 10),
          number: ix.number,
          name: ix.name,
          currencyCode: null,
          postalCode: null,
          city: ix.city,
          country: null,
          masterDataStatus: null,
          businessRelation: null,
          content: null,
          state: ix.state,
          active: ix.isActive
        }) : [];
        this.deliverySequenceList = this.deliverySequenceList.concat(newItems);
      }),
      map(deliverySequenceListResult => {
        // Map result to an IDropdownData object
        return <DropdownData>{
          items: deliverySequenceListResult.items.map(deliverySequence => {
            const displayText = this.displayNumberNameCityFormat ? deliverySequence.number + " / " + deliverySequence.name + " / " + deliverySequence.displayName
              : deliverySequence.number + ' ' + deliverySequence.displayName;
            return { text: displayText, value: deliverySequence.id.toString(), disabled: !deliverySequence.isActive };
          }),
          totalCount: deliverySequenceListResult.count,
          hasMoreResults: args.index + 10 < deliverySequenceListResult.count
        };
      })
    );
  }

  setDropdownValue(dropboxName: string, text: string, value: string, enable = true): void {
    if (!this.actAsForm) {
      return;
    }

    if (dropboxName === 'deliverySequenceDropdown') {
      this.triggerChange({
        text: text,
        value: value
      });
    }

    this.actAsForm.get(dropboxName).setValue({
      text: text,
      value: value
    });
    if (enable) {
      this.actAsForm.get(dropboxName).enable();
    }
  }

  /**
   * Validate the company, customer and delivery sequence.
   * The result will be cached for 30 minutes during this session and user
   */
  cachedValidate(companyCode: string, customerNumber?: string, deliverySequenceId?: number): Observable<DeliverySequencePickerDto> {
    if (typeof deliverySequenceId === 'undefined') {
      deliverySequenceId = null;
    }

    const cacheKey = `actasvalidation_${companyCode}.${customerNumber || 0}.${deliverySequenceId || 0}`;
    const cachedValidation = this.cache.get<DeliverySequencePickerDto>(cacheKey);

    if (cachedValidation) {
      return of(cachedValidation).pipe(delay(250)); // Create an observable with the cached data
    } else {
      if (this.organizationId) {
        return this.apiRest.validateForOrganization(
          {
            organizationId: this.organizationId.toString(),
            companyCode: companyCode,
            customerNumber: customerNumber,
            deliverySequenceId: deliverySequenceId
          }).pipe(
          delay(250),
          tap(result => {
            this.cache.add(cacheKey, result, null, CacheScope.MemoryStorage);
          })
        );
      } else {
        return this.apiRest.validate(
          {
            companyCode: companyCode,
            erpCustomerId: customerNumber,
            deliverySequenceId: deliverySequenceId,
          }).pipe(
          delay(250),
          tap(result => {
            this.cache.add(cacheKey, result, null, CacheScope.MemoryStorage);
          })
        );
      }
    }
  }

  validateCompanyAsync(control: AbstractControl): Observable<DeliverySequencePickerDto | { invalidCompany: boolean }> {
    if (!control.value || this.disableAllValidation) {
      return of(null);
    }

    let erpCustomerID = null;
    let deliverySequenceId = null;
    if (this.isFirstValidation) {
      if (this.sessionService.activeDeliverySequence) {
        erpCustomerID = this.sessionService.activeDeliverySequence.erpCustomerId;
        deliverySequenceId = this.sessionService.activeDeliverySequence.deliverySequenceId;
      }
    }

    if (this.actAsForm) {
      this.deliverySequenceList = [];
      this.setDropdownValue('customerDropdown', '', '');
      this.setDropdownValue('deliverySequenceDropdown', '', '', false);
      this.actAsForm.get('customerDropdown').disable();
      this.actAsForm.get('deliverySequenceDropdown').disable();
    }


    const companyCode = control.value.value;
    return this.cachedValidate(companyCode.toString(), erpCustomerID, deliverySequenceId).pipe(
      map(response => {

        if (response.company == null) {
          return { invalidCompany: true };
        }
        this.isFirstValidation = false;

        this.currentCompany = response.company;

        if (response.businessRelation !== null) {
          this.currentBusinessRelation = response.businessRelation;
          this.customerChangeFromCompany = true;
          this.setDropdownValue('customerDropdown',
            `${response.businessRelation.erpCustomerID} ${response.businessRelation.customer.name}`,
            response.businessRelation.erpCustomerID.toString());
        }
        else{
          this.actAsForm.get('customerDropdown').enable();
        }

        if (response.deliverySequence !== null) {
          this.deliverySequenceList = [response.deliverySequence];
          this.setDropdownValue('deliverySequenceDropdown',
            `${this.displayNumberNameCityFormat ? response.deliverySequence.number + " / " + response.deliverySequence.name + " / " + response.deliverySequence.displayName
              : response.deliverySequence.number} ${response.deliverySequence.displayName}`,
            response.deliverySequence.id.toString());
        }

        return null;
      })
    );
  }

  getFieldValue(name: string): string {
    if (!this.actAsForm) {
      return null;
    }
    const field = this.actAsForm.get(name);
    if (!field) {
      return null;
    }
    return field.value ? field.value.value : null;
  }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateCustomerAsync(control: AbstractControl): Observable<any> {
    if (!control.value || this.customerChangeFromCompany || this.isFirstValidation || this.disableAllValidation) {
      if (this.customerChangeFromCompany) {
        this.customerChangeFromCompany = false;
      }
      return of(null);
    }

    this.setDropdownValue('deliverySequenceDropdown', '', '');
    this.actAsForm.get('deliverySequenceDropdown').disable();

    // Disable company dropdown using component and not Angular Form Component
    // This is to avoid triggering validation on the company control again
    this.companyDropdown.disable();

    const companyCode = this.getFieldValue('companyDropdown');
    const customerNumber = control.value.value;
    this.currentBusinessRelation = null;
    return this.cachedValidate(companyCode.toString(), customerNumber?.toString()).pipe(
      map(response => {
        if (response.company) {
          this.currentCompany = response.company;
        }
        if (response.businessRelation) {
          this.currentBusinessRelation = response.businessRelation;
        }
        if (response.deliverySequence !== null) {
          this.deliverySequenceList = [response.deliverySequence];
          this.setDropdownValue('deliverySequenceDropdown',
            `${response.deliverySequence.number} ${response.deliverySequence.displayName}`, response.deliverySequence.id.toString());
        }
        else if(this.currentBusinessRelation){
          this.actAsForm.get('deliverySequenceDropdown').enable();
        }

        // Enable company dropdown using component and not Angular Form Component
        // This is to avoid triggering validation on the company control again
        this.companyDropdown.enable();

        if (response.company == null) {
          return { invalidCompany: true };
        }
        return null;
      })
    );
  }

}
