<mibp-support-team-toolbar></mibp-support-team-toolbar>
<mibp-addtocart-dialog></mibp-addtocart-dialog>
<mibp-salesfore-embedded-service *ngIf="!isFocusContent"></mibp-salesfore-embedded-service>
<mibp-cart-quickview *ngIf="isLoggedIn && canSeeCart"></mibp-cart-quickview>
<mibp-google-maps-script></mibp-google-maps-script>
<mibp-errorevents-debug-dialog *ngIf="enableErrorDialog"></mibp-errorevents-debug-dialog>
<mibp-unhandled-exception-dialog></mibp-unhandled-exception-dialog>
<mibp-devops-feedback *ngIf="enableBugReport"></mibp-devops-feedback>
<mibp-resource-string-editor *ngIf="enableResourceStringEditor"></mibp-resource-string-editor>

<mibp-dialog title="ContactUs_Title" fullheight="false" size="narrow" (closed)="onContactUsFormClosed()" #contactDialog>
  <mibp-contact-dialog *ngIf="contactUsOpen" [formData]="contactUsFormData"></mibp-contact-dialog>
</mibp-dialog>

<mibp-dialog title="ContactUs_Title" fullheight="false" size="narrow" (closed)="onContactUsFormClosed()" #newContactDialog>
  <mibp-new-contact-us-form [formData]="newContactUsFormData" (successfullySubmitted)="closeForm($event)"></mibp-new-contact-us-form>
</mibp-dialog>

<mibp-system-message-dialog></mibp-system-message-dialog>
<mibp-feature-tour-component></mibp-feature-tour-component>
<!-- This dialog is used by the DialogService for more basic prompts etc -->
<!-- <mibp-noticebar></mibp-noticebar> -->

<mibp-browser-update-notice></mibp-browser-update-notice>


<section id="application-root" class="application-root" [class.mysandvik-menu-hidden]="isSidebarHidden">

  <header *ngIf="!isFocusContent" class="application-root__header" id="my-header"
    xstyle="transition: margin 0.25s ease-in-out;">
    <mibp-header></mibp-header>
  </header>

  <nav @myToggleTrigger class="application-root__sidebar mysandvik-sidebar media@s-and-larger"
    *ngIf="!isSidebarHidden && !isFocusContent">
    <mibp-sidebar></mibp-sidebar>
  </nav>

  <div class="application-root__content" [class.application-root__content--focus-content]="isFocusContent"
    xstyle="transition: margin 0.25s ease-in-out;">

    <mibp-terms-and-conditions>

      <section id="my-main-container">

        <div *ngIf="isTestingFeatureManagement" style="background-color: yellow; padding: 10px;">
          <strong style="font-weight: bold;">NOTICE!</strong> The new <strong>Feature Management</strong> is activated
          for testing in this environment. This means that Features are used instead of Permissions throughout the site.
        </div>

        <div *ngIf="isSupportTeamMember && hasConfigurationErrors">
          <mibp-system-message #msg [messageType]="msg.Types.Error">
            <h2>GlobalConfig error</h2>
            <ul>
              <li>This is shown because you are a SupportTeamMember</li>
              <li>Errors were detected in GlobalConfig</li>
              <li>See <a href="javascript:void(0)" [ecommerceRouterLink]="['/:lang/operations']">Operations</a> for
                details.</li>
            </ul>
          </mibp-system-message>
        </div>

          <main id="main">
            <mibp-breadcrumbs-v2 *ngIf="!isFocusContent"></mibp-breadcrumbs-v2>
            <router-outlet></router-outlet>
          </main>

      </section>

    </mibp-terms-and-conditions>

    <footer *ngIf="!isFocusContent" class="application-root__footer">
      <mibp-footer-v2></mibp-footer-v2>
    </footer>

  </div>

</section>
