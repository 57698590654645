import { Injectable } from '@angular/core';
import { MibpLogLevel } from './loglevel.enum';
import { MibpLogEntry } from './logentry.class';
import { environment } from 'root/environment';
import { MibpLogger } from './mibplogger.class';
import { BroadcastService } from '../broadcast-service/broadcast.service';

@Injectable({
  providedIn: 'root'
})
export class LogService extends MibpLogger {

  private logLevel: MibpLogLevel;

  public static getLogLevel(): MibpLogLevel {
    let logLevel = environment.logLevel;
    if (localStorage.getItem('MibpLogLevel')) {
      const fromLocalStorage = localStorage.getItem('MibpLogLevel');
      if (fromLocalStorage.match(/^\d$/)) {
        logLevel = parseInt(fromLocalStorage, 10);
      }
    }
    return logLevel;
  }

  public static setLogLevel(newLevel: MibpLogLevel) {
    localStorage.setItem('MibpLogLevel', newLevel.toString());
  }

  constructor(private broadcast: BroadcastService) {
    super('', LogService.getLogLevel(), broadcast);
    const logLevel = LogService.getLogLevel();
    this.logLevel = logLevel;
  }

  public withPrefix(prefix: string) {
    return new MibpLogger(prefix, this.logLevel, this.broadcast);
  }

}


