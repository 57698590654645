<div class="quick-add">
    <mibp-loader *ngIf="working || isLoadingTemplates"></mibp-loader>
    <form [formGroup]="form1" (ngSubmit)="onSubmit()" [class.submitted]="form1Submitted">
      <div class="my-discreet-error" *ngIf="hasTemplateLoadError">
        <p><mibp-resource-string key="Global_Error_DataCouldNotBeLoaded"></mibp-resource-string></p>
        <p><a href="javascript:void(0)" (click)="loadTemplates()"><mibp-resource-string key="Global_Action_TryAgain"></mibp-resource-string></a></p>
      </div>
      <div class="actions" *ngIf="!hasTemplateLoadError && !isLoadingTemplates">
        <div class="quick-add-template">
          <mibp-dropdown [items]="templates" formControlName="ddl"></mibp-dropdown>
        </div>
        <div class="quick-add-action">
            <mibp-button data-gtm="quick-add-from-template-btn" [icon]="btn.Enum.Icons.Plus" [style]="btn.Enum.Styles.Fill" resourceStringKey="Carts_Active_AddToCartButton" #btn></mibp-button>
        </div>
      </div>
    </form>
</div>
