/* eslint-disable no-console */
import { PriceAndAvailabilityHttpService } from './priceandavailability-http-service.class';
import { FeatureHttpService } from './features-http-service.class';
import { OrderHttpService } from './order-http-service.class';
import { Guid } from 'guid-typescript';
import { GlobalConfigService } from 'root/services/global-config/global-config.service';
import { BulletinsHttpService } from './bulletins-http-service.class';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlHelperService } from '../url-helper';
import { FrontendContextService } from '../front-end-context/front-end-context.service';
import { Observable } from 'rxjs';
import { UserFileHttpService } from './user-file-http-service.class';
import { KitHttpService } from './kits-http-service.class';
import { WarrantyHttpService } from './warranty-http-service.class';
import { AuthService } from '../auth-service/auth.service';
import { FormattingService } from '../formatting/formatting.service';
import { DownloadHttpService } from './download-http.service.class';
import { MibpLogHttpService } from './mibplog-http-service.class';
import { PromotionHttpService } from './promotion-http-service.class';
import { OperationsHttpService } from './operations-http-service.class';

/**
 * Wrapper class for API calls that do not user SignalR
 */
@Injectable({
  providedIn: 'root'
})
export class MibpHttpApi {

  private userFileChildService: UserFileHttpService;
  private kitService: KitHttpService;
  private bulletinService: BulletinsHttpService;
  private warrantyService: WarrantyHttpService;
  private orderService: OrderHttpService;
  private downloadService: DownloadHttpService;
  private mibpLogService: MibpLogHttpService;
  private promotionService: PromotionHttpService;
  private featureService: FeatureHttpService;
  private priceAvailabilityService: PriceAndAvailabilityHttpService;
  private operationsService: OperationsHttpService;

  constructor(private http: HttpClient,
    private url: UrlHelperService,
    private auth: AuthService,
    private context: FrontendContextService,
    private formattingService: FormattingService,
    private globalConfig: GlobalConfigService) {
    this.userFileChildService = new UserFileHttpService(this);
    this.kitService = new KitHttpService(this);
    this.bulletinService = new BulletinsHttpService(this);
    this.warrantyService = new WarrantyHttpService(this);
    this.orderService = new OrderHttpService(this);
    this.downloadService = new DownloadHttpService(this);
    this.mibpLogService = new MibpLogHttpService(this, formattingService);
    this.promotionService = new PromotionHttpService(this);
    this.featureService = new FeatureHttpService(this);
    this.priceAvailabilityService = new PriceAndAvailabilityHttpService(this);
    this.operationsService = new OperationsHttpService(this);
  }

  public localDateToUtcString(d: Date): string {
    return this.formattingService.toServerUTCString(d);
  }

  /**
   * Provides access to the Warranty HTTP Api
   */
  public get Warranty(): WarrantyHttpService {
    return this.warrantyService;
  }

  /**
   * Provides access to the UserFile HTTP Api
   */
  public get UserFile(): UserFileHttpService {
    return this.userFileChildService;
  }

  /**
   * Provides access to the Kit HTTP Api
   */
  public get Kit(): KitHttpService {
    return this.kitService;
  }

  public get Order(): OrderHttpService {
    return this.orderService;
  }

  /**
   * Provides access to the Bulletin HTTP Api
   */
  public get Bulletin(): BulletinsHttpService {
    return this.bulletinService;
  }

  public get Download(): DownloadHttpService {
    return this.downloadService;
  }

  public get MibpLog(): MibpLogHttpService {
    return this.mibpLogService;
  }

  public get Promotion(): PromotionHttpService {
    return this.promotionService;
  }

  public get PriceAndAvailability(): PriceAndAvailabilityHttpService {
    return this.priceAvailabilityService;
  }

  public get Operations(): OperationsHttpService {
    return this.operationsService;
  }

  /**
   * Provides access to the feature HTTP Api
   */
  public get Feature(): FeatureHttpService {
    return this.featureService;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  public post<T>(path: string, data: any): Observable<T> {
    return this.http.post<T>(this.resolveUrl(path), data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public delete(path: string): Observable<any> {
    return this.http.delete(this.resolveUrl(path));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  public postWithOptions(path: string, data: any, options: any): Observable<any> {
    return this.http.post(this.resolveUrl(path), data, options );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  public put<T>(path: string, data: any): Observable<T> {
    return this.http.put<T>(this.resolveUrl(path), data);
  }

  public get<T>(path: string): Observable<T> {
    return this.http.get<T>(this.resolveUrl(path));
  }

  /***
   * Will authoirize request using querystring token
   * Use for downloads/images when we can not user token in header
   * NOTE! The Backend API Endpoint must have the [AllowQuerystringJwtToken] attribute for this to be allowed
   */
  public resolveUriWithJwtToken(url: string): string {
    url = this.resolveUrl(url);
    const accessToken = this.auth.getB2CUser()?.accessToken;
    const querystringToken = btoa(`${this.context.ClientId || Guid.createEmpty()} ${accessToken}`);
    url = this.url.addQuerystring(url, 'jwt', accessToken);
    return url;
  }


  public addUriWithCidToken(url: string): string {
    const user = this.auth.getB2CUser();
    const accessToken = user ? user.accessToken : null;
    if(accessToken)
    {
      const querystringToken = btoa(`${this.context.ClientId || Guid.createEmpty()} ${accessToken}`);
      url = this.url.addQuerystring(url, 'jwt', accessToken);
    }
    return url;
  }

  /***
   * Get an url for an API Controller endpoint
   */
  public resolveUrl(url: string): string {
    url = url.replace(/^\//, '');
    url = `${this.globalConfig.backendUrl}${url}`;
    return url.replace(/([^:])\/{2}/g, '$1/');
  }

  public createQuerystring(values: { [key: string]: string }): string {

    const querystringArray = [];

    if (values) {
      Object.keys(values)
        .forEach(parameterName => {
          if (values[parameterName] !== null && typeof values[parameterName] !== 'undefined') {
            querystringArray.push(`${encodeURIComponent(parameterName)}=${encodeURIComponent(values[parameterName])}`);
          }
        });
    }

    return querystringArray.length > 0 ? `?${querystringArray.join('&')}` : '';

  }

}
