<div class="validation-message-text" [class.validation-message-text--invalid]="showErrors && errorMessages?.length > 0">
  <div *ngIf="showErrors">
    <div class="validation-message-text__error" *ngFor="let errorMessage of errorMessages">
      <span translate="no" class="material-icon material-icon--red material-icon--small">warning</span>
      <span *ngIf="errorMessage.plainTextOverride">{{errorMessage.plainTextOverride}}</span>
      <mibp-resource-string *ngIf="!errorMessage.plainTextOverride" [key]="errorMessage.resourceStringKey" [macros]="errorMessage.macros"></mibp-resource-string>

      <span class="additional-help" *ngIf="additionalInformationResourceKeys[errorMessage.errorKey]">
        (<mibp-resource-string [key]="additionalInformationResourceKeys[errorMessage.errorKey]"></mibp-resource-string>)
      </span>
    </div>
  </div>
</div>

