/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { delete$ } from '../fn/user-file/delete';
import { Delete$Params } from '../fn/user-file/delete';
import { downloadFile } from '../fn/user-file/download-file';
import { DownloadFile$Params } from '../fn/user-file/download-file';
import { fileExists } from '../fn/user-file/file-exists';
import { FileExists$Params } from '../fn/user-file/file-exists';
import { getBase64 } from '../fn/user-file/get-base-64';
import { GetBase64$Params } from '../fn/user-file/get-base-64';
import { getBase64$Plain } from '../fn/user-file/get-base-64-plain';
import { GetBase64$Plain$Params } from '../fn/user-file/get-base-64-plain';
import { getImageUserFile } from '../fn/user-file/get-image-user-file';
import { GetImageUserFile$Params } from '../fn/user-file/get-image-user-file';
import { getRendition } from '../fn/user-file/get-rendition';
import { GetRendition$Params } from '../fn/user-file/get-rendition';
import { getUserFile } from '../fn/user-file/get-user-file';
import { GetUserFile$Params } from '../fn/user-file/get-user-file';
import { ImageForReturnVm } from '../models/image-for-return-vm';
import { save } from '../fn/user-file/save';
import { Save$Params } from '../fn/user-file/save';
import { save$Plain } from '../fn/user-file/save-plain';
import { Save$Plain$Params } from '../fn/user-file/save-plain';

@Injectable({ providedIn: 'root' })
export class UserFileApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userFileControllerSavenewUserFile()` */
  static readonly UserFileControllerSavenewUserFilePath = '/userfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Plain$Response(params?: Save$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return save$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Plain(params?: Save$Plain$Params, context?: HttpContext): Observable<string> {
    return this.save$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<string> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `userFileControllerGetRenditionrenditionId()` */
  static readonly UserFileControllerGetRenditionrenditionIdPath = '/userfile/{id}/{rendition}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRendition()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRendition$Response(params: GetRendition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getRendition(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRendition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRendition(params: GetRendition$Params, context?: HttpContext): Observable<void> {
    return this.getRendition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userFileControllerGetImageUserFileblobNameWithExtension()` */
  static readonly UserFileControllerGetImageUserFileblobNameWithExtensionPath = '/userfile/image/{blobNameWithExtension}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImageUserFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageUserFile$Response(params: GetImageUserFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getImageUserFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImageUserFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageUserFile(params: GetImageUserFile$Params, context?: HttpContext): Observable<void> {
    return this.getImageUserFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userFileControllerGetUserFileid()` */
  static readonly UserFileControllerGetUserFileidPath = '/userfile/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFile$Response(params: GetUserFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getUserFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFile(params: GetUserFile$Params, context?: HttpContext): Observable<void> {
    return this.getUserFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userFileControllerFileExistsid()` */
  static readonly UserFileControllerFileExistsidPath = '/userfile/exists/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileExists()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileExists$Response(params: FileExists$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileExists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileExists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileExists(params: FileExists$Params, context?: HttpContext): Observable<void> {
    return this.fileExists$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userFileControllerDownloadFileid()` */
  static readonly UserFileControllerDownloadFileidPath = '/userfile/{id}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile$Response(params: DownloadFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile(params: DownloadFile$Params, context?: HttpContext): Observable<void> {
    return this.downloadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userFileControllerGetBase64UserFileIdRendition()` */
  static readonly UserFileControllerGetBase64UserFileIdRenditionPath = '/userfile/image/{userFileId}/{rendition}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBase64$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBase64$Plain$Response(params: GetBase64$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageForReturnVm>> {
    return getBase64$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBase64$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBase64$Plain(params: GetBase64$Plain$Params, context?: HttpContext): Observable<ImageForReturnVm> {
    return this.getBase64$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageForReturnVm>): ImageForReturnVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBase64()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBase64$Response(params: GetBase64$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageForReturnVm>> {
    return getBase64(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBase64$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBase64(params: GetBase64$Params, context?: HttpContext): Observable<ImageForReturnVm> {
    return this.getBase64$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageForReturnVm>): ImageForReturnVm => r.body)
    );
  }

  /** Path part for operation `userFileControllerDeleteid()` */
  static readonly UserFileControllerDeleteidPath = '/userfile/delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
