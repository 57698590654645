<mibp-dialog [size]="'auto'" #reportErrorDialog>
  <mibp-system-message *ngIf="attachmentError" #msg [messageType]="msg.Types.Information">
    <p><mibp-resource-string key="PartsManualReport_CreatedWithAttachmentError"></mibp-resource-string></p>
  </mibp-system-message>
  <form [formGroup]="reportErrorForm">
    <h2 class="my-header hide-print my-header--orange" *ngIf="true"><mibp-resource-string key="PartsManualReport_ReportError"></mibp-resource-string> {{request.mediaName}}</h2>
    <div class="forms__row">
      <!-- Issue category -->
      <label for="category"><mibp-resource-string key="PartsManualReport_Category"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
      <mibp-dropdown formControlName="category" id="category" name="category"
        placeholder="PartsManualReport_SelectCategory_Placeholder" [loading]="!categories"
        [items]="categories"></mibp-dropdown>

        <mibp-validation-text [formGroup]="reportErrorForm" [alwaysVisible]="formValidated" controlName="category"></mibp-validation-text>
    </div>
    <!-- Priority -->
    <div class="forms__row">
      <label for="priority"><mibp-resource-string key="PartsManualReport_Priority"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
      <mibp-dropdown formControlName="priority" id="priority" name="priority"
        placeholder="PartsManualReport_SelectPriority_Placeholder" [loading]="!priorities"
        [items]="priorities"></mibp-dropdown>

        <mibp-validation-text [formGroup]="reportErrorForm" [alwaysVisible]="formValidated" controlName="priority"></mibp-validation-text>
    </div>
    <div class="forms__row" *ngIf="showEquipmentList">
      <label for="equipmentId"><mibp-resource-string key="Global_MachineSerialNumber"></mibp-resource-string> <em>(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</em></label>
      <mibp-equipment-picker id="equipmentId" formControlName="equipmentId"  ></mibp-equipment-picker>
      <mibp-validation-text [formGroup]="reportErrorForm" [alwaysVisible]="formValidated" controlName="equipmentId"></mibp-validation-text>
    </div>
    <!-- Attachments -->
    <div class="forms__row">
      <label><mibp-resource-string key="PartsManualReport_UploadImage"></mibp-resource-string></label>
      <mibp-button type="button"  #btn  resourceStringKey="PartsManualReport_AddImages" (click)="addFiles()" [color]="buttonColors.Secondary"  [icon]="btn.Enum.Icons.FolderOpen"></mibp-button>
    <ul>
      <div *ngFor="let fileName of fileNames">
        <li>
          {{fileName}}
          <span translate="no" class="material-icon" (click)="deleteAttachment(fileName)">delete</span>
        </li>
      </div>
      <mibp-loader type="indeterminate-progressbar" *ngIf="isUploadingAttachments"></mibp-loader>



    </ul>

      <input *ngFor="let inputField of inputFields" class="hide" type="file" (change)="fileChange($event)" multiple="false" #uploadInputElement name="file"   >
<div *ngIf="attachmentError && fileNames.length > 0">
  <mibp-button type="button" #btn resourceStringKey="PartsManualReport_ReAddAttachments" (click)="uploadAttachments()"
    [color]="buttonColors.Secondary" [icon]="btn.Enum.Icons.FolderOpen"></mibp-button>
</div>
    </div>
    <!-- Comment -->
    <div class="forms__row">
      <label for="comment"><mibp-resource-string key="PartsManualReport_Comment"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
      <textarea style="width: 100%; height: 100px" formControlName="comment" name="comment" id="comment" ></textarea>
      <mibp-validation-text [formGroup]="reportErrorForm" [alwaysVisible]="formValidated" controlName="comment"></mibp-validation-text>
    </div>
    <div class="forms__row">
      <span translate="no" class="material-icon material-icon--fill">info</span>
    <mibp-resource-string key="PartsManualReport_InfoText"></mibp-resource-string>
    <hr>
    </div>
    <div class="buttons-class">
      <div>
        <mibp-button [resourceStringKey]="'Global_CancelButton'" [color]="buttonColors.Secondary" (click)="closeDialog()" *ngIf="!attachmentError"></mibp-button>
      </div>
      <div>
         <mibp-button [resourceStringKey]="'Global_Submit'" [color]="buttonColors.Primary" (click)="submitForm()" *ngIf="!attachmentError"></mibp-button>
         <mibp-button [resourceStringKey]="'Global_Dialog_Close'" [color]="buttonColors.Primary" (click)="closeDialog()" *ngIf="attachmentError"></mibp-button>
      </div>
    </div>

  </form>

</mibp-dialog>
