import { LocalizationService, MibpLogger, LogService } from 'root/services/index';
import { Component, ElementRef, Renderer2 } from "@angular/core";
import { ApiService } from "root/services";
import { Router } from '@angular/router';
import { ResourceApiController } from 'root/mibp-openapi-gen/controllers';

@Component({
  selector: 'mibp-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {

  showPopup = false;
  languageCodes: string[];
  log: MibpLogger;

  constructor(
    private api: ApiService,
    private localizationService: LocalizationService,
    private router: Router,
    private element: ElementRef,
    private renderer: Renderer2,
    private resourceApiController: ResourceApiController,
    logger: LogService) {
    this.log = logger.withPrefix('language-selector');
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.showPopup !== false) {
        if (!this.element.nativeElement.contains(e.target)) {
          this.closePopup();
        }
      }
    });
  }

  togglePopup():void {
    this.showPopup = (!this.showPopup);
    if (!this.languageCodes) {
      this.resourceApiController.allSelectableLanguagesResource().subscribe(
        languageCodes => this.languageCodes = languageCodes,
        err => this.log.error('Could not get languages', err)
      );
    }
  }

  closePopup():void {
    this.showPopup = false;
  }

  getFlagUrlFromCurrentLanguage(): string {
    return this.getFlagUrl(this.localizationService.getLang());
  }

  getFlagUrlFromIndex(index: number): string {
    return this.getFlagUrl(this.languageCodes[index]);
  }

  getFlagUrl(language: string): string {
    return "/assets/images/flags/24/" + language +  ".png";
  }

  getLanguageResoucenName(languageCode: string): string {
    return "Global_LanguageName_" + languageCode;
  }

  switchLanguage(toLanguage: string):void {
    this.closePopup();
    this.localizationService.switchLanguage(true);
    const currentUrl = this.router.url;
    const currentLanguage = this.localizationService.getLang();
    const currentLanguageUrl = "/" + currentLanguage + "/";

    let param: string;
    if (currentUrl.startsWith(currentLanguageUrl)) {
      param = currentUrl.slice(currentLanguageUrl.length);
    } else {
      param = "home";
    }
    this.localizationService.removeCurrentLanguageFromCache();
    const newLang = "/" + toLanguage + "/" + param;
    this.router.navigateByUrl(newLang).finally(()=>{
      this.localizationService.switchLanguage(false);
    });
  }
}
