import { Inject, Injectable, Injector } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { ActiveMibpToast, ToastConfig } from './toast.service.interface';
import { ToastType } from './toast.enum';
import { MibpToastComponent } from 'root/components';

@Injectable({
  providedIn: 'root'
})

export class ToastService  {

  constructor(@Inject(Injector) private injector: Injector) {}

  // May be some circular dependency issue so we use injector instead of DI in constructor
  // https://github.com/scttcper/ngx-toastr/issues/179
  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  show(messageResourceString: string, config?: Partial<ToastConfig>): ActiveMibpToast {
    return this.showToast(messageResourceString, true, null, config);
  }

  showWithTitle(messageResourceString: string, titleResourceString: string, config?: Partial<ToastConfig>): ActiveMibpToast {
    return this.showToast(messageResourceString, true, titleResourceString, config);
  }

  showText(message: string, config?: Partial<ToastConfig>): ActiveMibpToast {
    return this.showToast(message, false, null, config);
  }

  showTextWithTitle(message: string, title: string, config?: Partial<ToastConfig>): ActiveMibpToast {
    return this.showToast(message, false, title, config);
  }

  removeToast(toastId: number) : boolean {
    return this.toastr.remove(toastId);
  }

  removeAllToasts() {
    return this.toastr.clear();
  }

  private showToast(message: string, isResourceString: boolean, title?: string, config?: Partial<ToastConfig>) : ActiveMibpToast {
    const activeToast = this.createNgxToastrInstance(message, title, config);
    const buttons = config?.buttons;
    activeToast.toastRef.componentInstance.buttons = buttons;
    activeToast.toastRef.componentInstance.isResourceString = isResourceString;
    activeToast.toastRef.componentInstance.bodyResourceStringMacros = config?.bodyResourceMacros;
    activeToast.toastRef.componentInstance.titleResourceStringMacros = config?.titleResourceMacros;

    return {
      toastId: activeToast.toastId,
      onClose: activeToast.onTap,
      onHidden: activeToast.onHidden,
      onShown: activeToast.onShown,
      onButtonClick: buttons != null && buttons.length > 0 ? activeToast.onAction : null
    };
  }

  private createNgxToastrInstance(message: string, title?: string, config?: Partial<ToastConfig>) : ActiveToast<MibpToastComponent> {
    const configOverrides = config != null ? this.mapToNgxToastrConfig(config) : null;
    const toastType = config?.type;

    if (!toastType) {
      return this.toastr.info<MibpToastComponent>(message, title, configOverrides);
    }

    switch (toastType) {
      case ToastType.Warning : {
        return this.toastr.warning<MibpToastComponent>(message, title, configOverrides);
      }
      case ToastType.Error : {
        return this.toastr.error<MibpToastComponent>(message, title, configOverrides);
      }
      case ToastType.Success : {
        return this.toastr.success<MibpToastComponent>(message, title, configOverrides);
      }
      default : {
        return this.toastr.info<MibpToastComponent>(message, title, configOverrides);
      }
    }
  }

  private mapToNgxToastrConfig(config: Partial<ToastConfig>) : Partial<IndividualConfig<MibpToastComponent>> {
    return {
      enableHtml: config.isHtml,
      timeOut: config.timeOut ?? config?.type == ToastType.Success  ? 5000 : 8000,
      extendedTimeOut: config.extendedTimeOut ?? 8000,
      disableTimeOut: config.disableTimeOut,
    };
  }
}
