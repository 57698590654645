export enum SupportCaseItemEnquiryTypeResourceString {
  ShortSupply = 'SupportCasesItemEnquriy_Type_ShortSupply',
  OverSupply = "SupportCasesItemEnquriy_Type_OverSupply",
  IncorrectSupply = "SupportCasesItemEnquriy_Type_IncorrectSupply",
  Damage = "SupportCasesItemEnquriy_Type_Damage",
  ReturnItem = "SupportCasesItemEnquriy_Type_ReturnItem",
  CancelOrder = "SupportCasesItemEnquriy_Type_CancelOrder"
}
export enum SupportCaseItemEnquiryTypeValue {
  ShortSupply = 'I am reporting a short supply',
  OverSupply = "I am reporting an oversupply",
  IncorrectSupply = "I am reporting an incorrect supply",
  Damage = "The order has been received damaged",
  ReturnItem = "Request to return an item",
  CancelOrder = "Request to cancel an order/order line"
}
