<span  #langButton  (mouseenter)="languagePopupContainer.open(langButton);">
  <span class="my-site-header__language-icon" [style.backgroundImage]="flagImageUrl" [attr.class]="flagClassName"></span>
  <mibp-popup-container #languagePopupContainer [closeOnMouseLeave]="true">

    <mibp-loader *ngIf="isLoading && !loadLanguageError"></mibp-loader>
    <div class="my-discreet-error" *ngIf="loadLanguageError">
     <p><mibp-resource-string key="Global_Error_DataCouldNotBeLoaded"></mibp-resource-string></p>
     <p><a href="javascript:void(0)" (click)="loadLanguages()"><mibp-resource-string key="Global_Action_TryAgain"></mibp-resource-string></a></p>
   </div>

    <div class="my-languages">
     <div class="my-languages__item" [attr.data-language]="langCode" *ngFor="let langCode of languageCodes">
       <a href="#" (click)="changeLanguage($event, langCode)" [attr.hreflang]="langCode">
         <span><img [src]="'assets/images/flags/32/' + langCode + '.png'" [attr.alt]="langCode"></span>
         <span style="flex-grow: 1"><mibp-resource-string [key]="'Global_LanguageName_' + langCode"></mibp-resource-string></span>
     </a>
     </div>
   </div>
  </mibp-popup-container>
 </span>

 <div *ngIf="isInline">
   <mibp-loader *ngIf="isLoading && !loadLanguageError"></mibp-loader>
   <div class="my-discreet-error" *ngIf="loadLanguageError">
    <p><mibp-resource-string key="Global_Error_DataCouldNotBeLoaded"></mibp-resource-string></p>
    <p><a href="javascript:void(0)" (click)="loadLanguages()"><mibp-resource-string key="Global_Action_TryAgain"></mibp-resource-string></a></p>
  </div>
  <div class="my-languages" *ngIf="!isLoading && !loadLanguageError">
    <div class="my-languages__item" [attr.data-language]="langCode" *ngFor="let langCode of languageCodes">
      <a href="#" (click)="changeLanguage($event, langCode)" [attr.hreflang]="langCode">
        <span><img [src]="'assets/images/flags/32/' + langCode + '.png'" [attr.alt]="langCode"></span>
        <span style="flex-grow: 1"><mibp-resource-string [key]="'Global_LanguageName_' + langCode"></mibp-resource-string></span>
    </a>
    </div>
  </div>
 </div>


