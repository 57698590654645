<!-- Mobile status bar-->
<div class="my-mobile-menu-statusbar media@xs-and-smaller" #mobileStatusBar>

  <a class="my-mobile-menu-statusbar__cart" *ngIf="canSeeCart" (click)="onCartIconClick()">
    <span translate="no" class="material-icon">
      shopping_cart
    </span>
    <span *ngIf="itemsInCart" class="my-badge my-badge--blue">{{itemsInCart}}</span>
  </a>
  <a class="my-mobile-menu-statusbar__cart"  *ngIf="!canSeeCart">
  </a>

 
  <a class="my-mobile-menu-statusbar__notifications" *ngIf="isLoggedIn" [ecommerceRouterLink]="['/:lang/user/activity']">
    <span translate="no" class="material-icon">
      notifications
    </span>
    <span *ngIf="notificationCount" class="my-badge my-badge--blue">{{notificationCount}}</span>
  </a>
  <a class="my-mobile-menu-statusbar__notifications" *ngIf="!isLoggedIn">
  </a>
  <a class="my-mobile-menu-statusbar__systemmessage" *ngIf="systemMessageService.activeSystemMessageCount$ | async" >  
    <mibp-header-system-message iconsize="small"></mibp-header-system-message>
  </a>

  
  <span class="my-mobile-menu-statusbar__actingas" *ngIf="isLoggedIn" (click)="toggleActAs(mobileStatusBar, actAsToggle)" #actAsToggle>
    <!-- No customer selected -->
    <span class="mobile-acting-as-text" [class.mobile-acting-as-text--open]="isActAsOpen">
      <span class="short">{{ actingAsTextShort }}</span>
      <span class="long">{{ actingAsText }}</span>

      <mibp-resource-string *ngIf="!actingAsText" key="ActAs_None"></mibp-resource-string>
    </span>

    <span *ngIf="canActAs && isActAsOpen" translate="no" class="material-icon material-icon--xxxlarge">
      expand_less
    </span>
    <span *ngIf="canActAs && !isActAsOpen" translate="no" class="material-icon material-icon--xxxlarge">
      expand_more
    </span>

  </span>

  <a class="my-mobile-menu-statusbar__login" *ngIf="!isLoggedIn" (click)="auth.signinRedirect()"  href="javascript:void(0)">
      <mibp-resource-string key="Global_Login"></mibp-resource-string>
  </a>
</div>

<!-- Act as popup container -->
<mibp-popup-container #actAsPopupContainer [showAboveHeader]="true" (opened)="actAsOpened()" (closed)="actAsClosed()" anchorTo="bottom-left" anchorPosition="top-left" fitWidth="true" fitHeight="true" closeOnRootClick="true">
  <div class="my-mobile-menu-actas-popup" *ngIf="canActAs">
    <mibp-act-as [isVisible]="true" [alwaysVisible]="true" (actas)="actAsPopupContainer.close()"></mibp-act-as>
  </div>
</mibp-popup-container>

<!-- Mobile tool bar-->
<nav #mobileToolbar class="my-mobile-menu-toolbar media@xs-and-smaller">
  <!-- <mibp-sandvik-icon *ngIf="isActAsOpen" name="arrow-up" size="x-small"></mibp-sandvik-icon> -->
  <a [ecommerceRouterLink]="['/:lang']" class="my-mobile-menu-toolbar__logo"><img src="/assets/images/logo_black.svg" width="100" alt="Sandvik Logotype"></a>

  <div class="my-mobile-menu-toolbar__search" (click)="searchPopupContainer.toggle(mobileToolbar, searchToggle)" *ngIf="isLoggedIn && showHeaderSearch" #searchToggle>
    <span translate="no" class="material-icon material-icon--orange material-icon--xxlarge" *ngIf="searchPopupContainer.isVisible">
        search
    </span>
    <span translate="no" class="material-icon material-icon--xxlarge" *ngIf="!searchPopupContainer.isVisible">
      search
  </span>
  </div>

  <div class="my-mobile-menu-toolbar__hamburger" *ngIf="isLoggedIn" (click)="mobileMenuPopup.toggle(mobileToolbar, toggleElement2)" #toggleElement2 >
    <span *ngIf="!mobileMenuPopup.isVisible"  translate="no" class="material-icon material-icon--xxlarge">
      menu
    </span>
    <span *ngIf="mobileMenuPopup.isVisible"  translate="no" class="material-icon material-icon--orange material-icon--xxlarge">
      menu
    </span>
  </div>

</nav>

<!-- Search popup container -->
<mibp-popup-container  #searchPopupContainer (opened)="onMobileSearchVisible()" anchorTo="bottom-left" anchorPosition="top-left" fitWidth="true">
  <div  class="my-mobile-menu-search-popup">
    <input id="mobile-toolbar-search-input"
    (keydown.escape)="searchPopupContainer.close()"
    (keydown.enter)="runSearch($event); searchPopupContainer.close()"
     type="search" [mibpResourceStringAttributes]="{ placeholder: 'productsearch_placeholder' }">
  </div>
</mibp-popup-container>
<!-- Mobile menu popup container -->
<mibp-popup-container #mobileMenuPopup [scrollable]="true" [ignoreScroll]="true" (closed)="showLanguagePicker = false" anchorTo="bottom-left" anchorPosition="top-left" fitWidth="true" fitHeight="true">
  <div class="my-mobile-menu-container">

    <!-- Language and signout buttons -->
    <div class="my-mobile-menu-container__settings">
      <a class="my-mobile-menu-language-toggle" [class.my-mobile-menu-language-toggle--open]="showLanguagePicker" href="javascript:void(0)" (click)="showLanguagePicker = !showLanguagePicker">
        <span translate="no" class="material-icon" style="font-size: xx-large;">
          language
        </span>
        <span><mibp-resource-string key="Global_LanguageChange_Button"></mibp-resource-string></span>
      </a>

      <a class="my-mobile-menu-signout" (click)="auth.signout()" href="javascript:void(0)" >
         <span translate="no" class="material-icon" style="font-size: xx-large;">
          logout
        </span> 
        <mibp-resource-string key="Global_Logout"></mibp-resource-string>
      </a>
    </div>


    <!-- User details-->
    <div class="my-mobile-menu-container__user" *ngIf="!showLanguagePicker">
      <div class="my-mobile-menu-container__user--photo">
        <mibp-user-photo></mibp-user-photo>
      </div>
      <div  class="my-mobile-menu-container__user--email">
          <a [ecommerceRouterLink]="['/:lang/user/myprofile']" (click)="mobileMenuPopup.close()">{{ broadcast.snapshot?.userEvent?.user?.email }}</a>
      </div>
      <div class="my-mobile-menu-container__user--profile">
        <a [ecommerceRouterLink]="['/:lang/user/myprofile']" (click)="mobileMenuPopup.close()">
          <span translate="no" class="material-icon material-icon--xxlarge">settings</span>
        </a>
      </div>
    </div>

    <!--- Mobile menu -->
    <mibp-mobile-menu *ngIf="!showLanguagePicker" (navigated)="mobileMenuPopup.close()"></mibp-mobile-menu>

    <!--- Mobile Language picker -->
    <div class="my-mobile-menu-container__languagepicker" *ngIf="showLanguagePicker">
      <div>
        <a href="javascript:void(0)"  (click)="showLanguagePicker = !showLanguagePicker">
          <span translate="no" class="material-icon material-icon--xxxlarge">close</span>
        </a>
      </div>
      <mibp-header-language [isInline]="true" (changed)="mobileMenuPopup.close()"></mibp-header-language>
    </div>

  </div>
</mibp-popup-container>



<!-- Non-mobile header -->
<nav id="site-header" class="my-site-header media@s-and-larger">

  <ul *ngIf="isLoggedIn" [ngClass]="{'floatRight' : !isActiveUser}">

    <li *ngIf="isActiveUser" class="my-site-header__search">
      <div *ngIf="isToggleEnabled" [ngStyle]="{ 'visibility' : (sorgreater===true)? 'visible' : 'hidden' }"
        (click)="toggleSidebar()">
        <span *ngIf="!mobileMenuPopup.isVisible" translate="no" class="material-icon material-icon--xxlarge bold--icon">
          menu
        </span>
      </div>
      <mibp-header-search *ngIf="showHeaderSearch"></mibp-header-search>
    </li>

    <!-- <li>
      <mibp-sandvik-icon name="megaphone" color="orange"></mibp-sandvik-icon>
    </li> -->

    <li *ngIf="systemMessageService.activeSystemMessageCount$ | async" class="my-site-header__activity media@s-and-larger">
      <mibp-header-system-message iconsize="large"></mibp-header-system-message>
    </li>

    <li *ngIf="isActiveUser" class="my-site-header__activity media@s-and-larger">
      <mibp-header-activity></mibp-header-activity>
    </li>

    <li *ngIf="canSeeCart" class="my-site-header__cart media@s-and-larger">
      <mibp-header-cart></mibp-header-cart>
    </li>

    <li class="my-site-header__language media@s-and-larger">
      <mibp-header-language></mibp-header-language>
    </li>

    <li class="my-site-header__user media@s-and-larger">
      <mibp-header-user></mibp-header-user>
    </li>

  </ul>

  <ul *ngIf="!isLoggedIn">
    <li></li>
    <li>
      <span>
        <span (click)="login()"><mibp-resource-string key="Global_Login"></mibp-resource-string></span>
      </span>
    </li>

  </ul>
</nav>
