/***
 * Class to parse TimeSpan strings dotnet format
 */
export class TimeSpan {

  private dayValue: number;
  private hourValue: number;
  private minuteValue: number;
  private secondValue: number;
  private msValue: number;


  public get days(): number {
    return this.dayValue;
  }

  public get hours(): number {
    return this.hourValue;
  }

  public get minutes(): number {
    return this.minuteValue;
  }

  public get seconds(): number {
    return this.secondValue;
  }

  public get milliseconds(): number {
    return this.msValue;
  }

  constructor(days: number, hours: number, minutes: number, seconds: number, milliseconds: number) {

    this.dayValue = this.parseAndValidateDays(days);
    this.hourValue = this.parseAndValidateHours(hours);
    this.minuteValue = this.parseAndValidateMinuteOrSecond(minutes, 'minutes');
    this.secondValue = this.parseAndValidateMinuteOrSecond(seconds, 'seconds');
    this.msValue = this.parseAndValidateMs(milliseconds);
  }

  public getTotalMilliseconds(): number {
    const msDays = (((24 * this.dayValue) * 60) * 60) * 1000;
    const msHours = ((this.hourValue * 60) * 60) * 1000;
    const msMinutes = (this.minuteValue * 60) * 1000;
    const msSeconds = this.secondValue * 1000;
    return msDays + msHours + msMinutes + msSeconds + this.msValue;
  }

  public getTotalSeconds(): number {
    return this.getTotalMilliseconds() / 1000;
  }

  public getTotalMinutes(): number {
    return (this.getTotalMilliseconds() / 1000) / 60;
  }

  public getTotalHours(): number {
    return this.getTotalMinutes() / 60;
  }

  public getTotalDays(): number {
    return this.getTotalHours() / 24;
  }

  public toString(): string {

    let stringValue = [
      `${this.hourValue.toString().padStart(2, '0')}`,
      `${this.minuteValue.toString().padStart(2, '0')}`,
      `${this.secondValue.toString().padStart(2, '0')}`
    ].join(':');

    if (this.msValue > 0) {
      stringValue += `.${this.msValue.toString().padStart(3, '0')}`;
    }

    if (this.dayValue > 0) {
      stringValue = `${this.dayValue}.${stringValue}`;
    }

    return stringValue;
  }

  public static parse(timespanString: string): TimeSpan {
    const match = timespanString.match(/^(?:(?<days>\d+)\.|)(?<hours>\d{1,2}):(?<minutes>\d{1,2}):(?<seconds>\d{1,2}|)(?:\.(?<ms>\d{1,})|)$/);

    if (match) {
      const days =  parseInt(match.groups['days'], 10);
      const hours = parseInt(match.groups['hours'], 10);
      const minutes = parseInt(match.groups['minutes'], 10);
      const seconds = parseInt(match.groups['seconds'], 10);
      const milliseconds = parseInt(match.groups['ms']?.substring(0, 3), 10);
      return new TimeSpan(days, hours, minutes, seconds, milliseconds);
    } else {
      throw new Error(`Invalid TimeSpan format - use dd.HH:mm:ss.sss OR HH:mm:ss OR HH:mm:ss.sss`);
    }
  }

  private parseAndValidateDays(day: number): number | undefined {
    if (typeof day == 'undefined' || isNaN(day)) {
      return 0;
    }
    if (day < 0) {
      throw Error(`TimeSpan day value must be a numeric value equal to or larger than 0`);
    }
    return day;
  }

  private parseAndValidateHours(hours: number): number | undefined {
    if (isNaN(hours)) {
      throw Error(`TimeSpan 'hours' was not a number`);
    }

    if (hours < 0) {
      throw Error(`TimeSpan hour must be => 0`);
    }

    if (hours > 23) {
      throw Error(`TimeSpan hour must be <= 23`);
    }

    return hours;
  }

  private parseAndValidateMinuteOrSecond(value: number, what: 'minutes' | 'seconds'): number | undefined {
    if (isNaN(value)) {
      throw Error(`TimeSpan '${what}' was undefined`);
    }

    if (value < 0) {
      throw Error(`TimeSpan '${what}' must be => 0`);
    }

    if (value > 59) {
      throw Error(`TimeSpan '${what}' must be <= 59`);
    }

    return value;
  }

  private parseAndValidateMs(value: number): number | undefined {
    if (isNaN(value)) {
      return 0;
    }

    if (value < 0) {
      throw Error(`TimeSpan 'milliseconds' must be => 0`);
    }

    return value;
  }

  public static fromDays(days: number): TimeSpan {
    return this.fromHours(days * 24);
  }

  public static fromHours(hours: number): TimeSpan {
    return this.fromMinutes(hours * 60);
  }

  public static fromMinutes(minutes: number): TimeSpan {
    return this.fromSeconds( minutes * 60);
  }

  public static fromSeconds(seconds: number): TimeSpan {
    return this.fromMilliseconds( seconds * 1000);
  }

  public static fromMilliseconds(ms: number): TimeSpan {
    const parsedMs = this.convertMiliseconds(ms);
    return new TimeSpan(parsedMs.d, parsedMs.h, parsedMs.m, parsedMs.s, parsedMs.ms);
  }

  private static convertMiliseconds(miliseconds: number):{ d: number, h: number, m: number, s: number, ms: number } {
    const totalSeconds = Math.floor(miliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const days = Math.floor(totalHours / 24);

    const ms  = miliseconds % 1000;
    const seconds = totalSeconds % 60;
    const minutes = totalMinutes % 60;
    const hours = totalHours % 24;

    return { d: days, h: hours, m: minutes, s: seconds, ms: ms };
  }

}
