import Quill from 'quill';
const block = Quill.import('blots/block');

/**
 * Register the block blot for opening a collapsible section
 */
export function registerSectionHeaderBlot(): void {

  class SectionHeader extends block {
    static create(value) {
      const node = super.create(value);
      node.setAttribute('contenteditable', true);
      return node;
    }
  }
  SectionHeader.blotName = 'sectionheader';
  SectionHeader.tagName = 'DIV';
  SectionHeader.className = 'ql-mibp-section-separator';

  Quill.register('formats/sectionheader', SectionHeader);

}
