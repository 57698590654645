
export enum SupportCaseShipppingTrackingType {
  NoTrackingInfo = 'SupportCasesShippingTracking_Type_NoTrackingInfo',
  ExpectedDate = "SupportCasesShippingTracking_Type_ExpectedDate",
  ModifyOrder = "SupportCasesShippingTracking_Type_OrderModify",
  General = "SupportCasesShippingTracking_Type_General",
  NoWaybillInformation="SupportCasesShippingTracking_Type_NowaybillInfo",
  NoCarrierInformation = "SupportCasesShippingTracking_Type_NoCarrierInfo"
}

export enum SupportCaseShipppingTrackingTypeValue {
  NoTrackingInfo = 'There is no tracking information available online',
  ExpectedDate = "I need an update on the expected date of an order",
  ModifyOrder = "I need to modify an order",
  General = "General shipping/tracking question",
  NoWaybillInformation="No waybill information",
  NoCarrierInformation = "No carrier information"
}
