<mibp-loader *ngIf="working" type='fullscreen'></mibp-loader>

<div class="quick-add-max-height" [class.quick-add-max-height--compact]="compactLayout" [ngClass]="{'d-flex': !multiRowQuickAdd}">
  <div [ngClass]="{ 'quick-add-single quick-add': !multiRowQuickAdd, 'quick-add-multiple quick-add-table': multiRowQuickAdd }">
    <div *ngFor="let row of rows; let i = index; trackBy:trackByFn" [ngClass]="{ 'quick-add-row': multiRowQuickAdd }">
      <mibp-quick-add-row [isPromotion]="isPromotion" [skipFocus]="skipFocus" [zeroMinQuantity]="zeroMinQuantity" [singelRow]="!multiRowQuickAdd"
      [showDeleteButton]="rows?.length > 1" [showAddButton]="i === rows.length - 1" [hideQuantity]="hideQuantity" [index]='i'
      [rowItem]='row' (addNewRow)="addNewRow($event)" (removeRow)="removeRow($event)" (dataChanged)="validateData()"
      (addMultipleProducts)="addMultipleRows($event)" #cqr></mibp-quick-add-row>
    </div>
  </div>
  <div *ngIf="!hideAddToCartButton && !multiRowQuickAdd" class="quick-add-addtocart">
    <mibp-button automatedTestId="quickadd-addtocart" data-gtm="quick-add-to-cart-btn" [icon]="btn.Enum.Icons.Plus" [color]="btn.Enum.Colors.Primary" [disabled]="!isValid" tabindex="0" [resourceStringKey]="buttonTextKey" (click)="onSubmit()" #btn></mibp-button>
  </div>

</div>

<div class="quick-add-addtocart" *ngIf="!hideAddToCartButton && multiRowQuickAdd">
  <div class="quick-add-addtocart-label">
    <label><span translate="no" class="material-icon">info</span>&nbsp;&nbsp;<mibp-resource-string key="Global_ItemNumbers_Info_Label"></mibp-resource-string></label>
  </div>
  <div class="quick-add-addtocart-button">
    <mibp-button automatedTestId="quickadd-addtocart" data-gtm="quick-add-to-cart-btn" [icon]="btn.Enum.Icons.Plus" [color]="btn.Enum.Colors.Primary" [disabled]="(!isValid)||(addStart)" tabindex="0" [resourceStringKey]="buttonTextKey" (click)="onSubmit()" #btn></mibp-button>
  </div>
</div>
