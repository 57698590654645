import { FormattingService } from 'root/services';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'formatPrice'})
export class FormatPricePipe implements PipeTransform {

  constructor(private formattingService: FormattingService) {}

  transform(value: number): string {
    return this.formattingService.formatPrice(value);
  }
}
