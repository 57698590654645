import { BroadcastService } from 'root/services/broadcast-service/broadcast.service';
import { CacheScope, ClientSideCacheService} from 'root/services/client-side-cache/client-side-cache.service';
import { Injectable } from "@angular/core";
import { Guid } from 'guid-typescript';
import { environment } from 'root/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientIdService {

  private currentClientId: string;

  constructor(private broadcastService: BroadcastService, private cacheService: ClientSideCacheService) {
    this.getClientId(); // Just to make sure we have a client id from start
  }

  public newClientId(): string {
    this.currentClientId = Guid.create().toString();
    this.savetoCache();
    this.broadcast();
    return this.currentClientId;
  }

  private broadcast(): void {
    this.broadcastService.setClientId(this.currentClientId);
  }

  private savetoCache(): void {
    this.cacheService.add(environment.clientIdCacheKey, this.currentClientId, null, CacheScope.GlobalSessionStorage);
  }

  public getClientId(): string {

    let clientId = this.currentClientId;

    if (clientId) {
      return clientId;
    }

    clientId = this.cacheService.get<string>(environment.clientIdCacheKey);
    if (clientId) {
      this.currentClientId = clientId;
      this.broadcast(); // It was in cache but not in variable. Make sure to broadcast the first id
      return clientId;
    }

    // Not in variable, not in cache - return a new one
    return this.newClientId();
  }

}
