import { MySandvikNavigationItem } from './../../../services/navigation/navigation-types';
import { NavigationService, BroadcastService, LocalizationService } from 'root/services';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, Event } from "@angular/router";
import { Subscription, filter } from "rxjs";
import { MibpCrumb } from './breadcrumbs-v2.types';
import { PageTitleService } from 'root/services/page-title-service/page-title.service';
@Component({
  selector: 'mibp-breadcrumbs-v2',
  templateUrl: './breadcrumbs-v2.component.html',
  styleUrls: ['./breadcrumbs-v2.component.scss']
})

export class MibpBreadcrumbsV2Component implements OnInit, OnDestroy {

  navSub: Subscription;
  routerEventSub: Subscription;
  temp: string;
  crumbs: MibpCrumb[];
  hideBreadCrumb = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private titleService: PageTitleService,
              private localization: LocalizationService,
              private navigationService: NavigationService,
              private broadcast: BroadcastService) {}

  ngOnInit(): void {
    this.navSub = this.broadcast.navigation.subscribe(() => this.onNavigationUpdate());    
    this.routerEventSub = this.router.events
    .pipe(filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent))
    .subscribe((e: RouterEvent) => {
      this.onRouterEvent(e);
    }); 
    this.navigationService.registerBeadcrumbComponent(this);
    this.updateFromActiveRoute();
  }

  ngOnDestroy(): void {
    this.navSub?.unsubscribe();
    this.routerEventSub?.unsubscribe();
    this.navigationService.unregisterBeadcrumbComponent(this);
  }

  /**
   * Update the crumbs to a given value
   */
  public update(crumbs: MibpCrumb[]): void {
    this.crumbs = crumbs;
  }

  private onNavigationUpdate(): void {
    this.updateFromActiveRoute();
  }

  private onRouterEvent(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      this.updateFromActiveRoute();
    }
  }

  private updateFromActiveRoute(): void {

    if (this.broadcast.snapshot.breadcrumbControlMode == 'manual') {
      // Control has been overridden by breadcrumb-manual-control component
      // So no automatic updates should be done from navigation events
      return;
    }

    const navItem = this.navigationService.getByActivatedRoute(this.route);
    const crumbs: MibpCrumb[] = [];
    this.hideBreadCrumb = false;

    const home = this.navigationService.getById('home');
    const homeCrumb: MibpCrumb = {
      resourceString: home.resourceKey,
      text: this.localization.get(home.resourceKey),
      route: home.calculated.fullPath,
      navItem: home
    };


    if (navItem) {
      if(navItem.hideBreadCrumb){
        this.hideBreadCrumb = true;
      }

      let current: MySandvikNavigationItem = navItem;

      let i = 0;
      do {

        const text = this.localization.get(current.resourceKey, (current.text || '[resource/text missing ]'));

        const isAlreadyAdded = crumbs.find(c => c.text === text);

        if (!current.hideInBreadcrumbs && !isAlreadyAdded) {
          crumbs.push({
            resourceString: current.resourceKey,
            text: text,
            route: current.calculated.fullPath,
            navItem: current
          });
        }

        if (!current.calculated?.parentId) {
          break;
        }

        current = current.calculated.parentId ? this.navigationService.getById(current.calculated.parentId) : undefined;
        i++;
        if (i > 10) {
          break;
        }
      } while (current);

      crumbs.push(homeCrumb);
      crumbs.reverse();

      // In some cases (bulletins) we get double bulletin crumbs. Find duplicates and remove them
      let notDone = true;
      do {
        for (let i = 0; i < crumbs.length; i++) {
          const duplicateIndex = crumbs.findIndex((c, ix) => c.text?.toUpperCase() == crumbs[i].text?.toUpperCase() && i !== ix);
          if (duplicateIndex !== -1) {
            crumbs.splice(duplicateIndex, 1);
            break;
          } else {
            notDone = false;
          }
        }
      } while(notDone);

    }

    if (crumbs.length == 0) {
      crumbs.push(homeCrumb);
    }

    this.crumbs = crumbs;
    this.broadcast.setBreadcrumbs(crumbs);
    this.crumbs.forEach(x=>{
      this.localization.getResourceString(x.resourceString).then((resourceStringValue) => {
        this.titleService.append(x.text);
      });
    });
  }
}
