/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AccessGroup } from '../../models/access-group';
import { ResolvedScope } from '../../models/resolved-scope';

export interface OrganizationWithScopesExists$Plain$Params {
  ignoreId?: string;
      body?: Array<ResolvedScope>
}

export function organizationWithScopesExists$Plain(http: HttpClient, rootUrl: string, params?: OrganizationWithScopesExists$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccessGroup>> {
  const rb = new RequestBuilder(rootUrl, organizationWithScopesExists$Plain.PATH, 'post');
  if (params) {
    rb.query('ignoreId', params.ignoreId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AccessGroup>;
    })
  );
}

organizationWithScopesExists$Plain.PATH = '/accessgroups/exists/withscopes';
