import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonColors } from 'root/components/button/button.enum';
import { DropdownInput } from 'root/components/dropdown';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';
import { CaseType, ContactUsSupportCaseRequest } from 'root/mibp-openapi-gen/models';
import { FormValidationService, FormattingService, LoaderService, LocalizationService, NoticebarService } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MibpEmailValidator } from 'root/validators/email.validator';
import { requireCheckboxesToBeCheckedValidator } from 'root/validators/requireCheckboxesToBeChecked';
import { firstValueFrom } from 'rxjs';
import { SupportCaseWebsiteFeedbackType, SupportCaseWebsiteFeedbackTypeResourceString } from './contact-us-website-feedback-form.interface';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';

@Component({
  selector: 'mibp-contact-us-website-feedback-form',
  templateUrl: './contact-us-website-feedback-form.component.html',
  styleUrls: ['./contact-us-website-feedback-form.component.scss']
})
export class ContactUsWebsiteFeedbackFormComponent implements OnInit, OnDestroy {
  protected contactUsWebsiteFeedbackForm: UntypedFormGroup;
  protected websiteFeedbackTypes: DropdownInput[] = [];
  protected buttonColors = ButtonColors;
  protected formSubmitted: boolean;
  protected isEmailSelected: boolean;
  protected isPhoneNumberSelected: boolean;

  @Input() showHeader = true;
  @Output() successfullySubmitted = new EventEmitter<boolean>();

  websiteFeedbackTypeStringKeys = [
    SupportCaseWebsiteFeedbackTypeResourceString.General,
    SupportCaseWebsiteFeedbackTypeResourceString.SomethingNotWorking,
  ];

  websiteFeedbackTypeValues = [
    SupportCaseWebsiteFeedbackType.General,
    SupportCaseWebsiteFeedbackType.SomethingNotWorking
  ];


  private stopUsingResources: () => void;

  constructor(private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private mibpSession: MibpSessionService,
    private formattingService: FormattingService,
    private supportCaseController: SupportCaseApiController,
    private loader: LoaderService,
    private noticeService: NoticebarService,
    private localizationService: LocalizationService){

  }

  setResourceStringSubscription(resourceStringKeys: string[], onComplete: (translatedStrings: string[]) => void) {
    this.stopUsingResources = this.localizationService.using(
      resourceStringKeys,
      resourceStrings => {
        onComplete(resourceStrings);
      }
    );
  }

  ngOnDestroy(): void {
    if(this.stopUsingResources){
      this.stopUsingResources();
    }
  }

  ngOnInit(): void {
    this.contactUsWebsiteFeedbackForm = this.fb.group({
      name: [this.mibpSession.current.user.firstName + ' ' + this.mibpSession.current.user.lastName, [Validators.required, MibpAllowedCharactersValidator()]],
      email: [this.mibpSession.current.user.email, MibpEmailValidator(this.formattingService)],
      phonenumber: [this.mibpSession.current.user.phone, MibpAllowedCharactersValidator()],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false)
      }, { validators: requireCheckboxesToBeCheckedValidator(1)}),
      websiteFeedbackType: [null, Validators.required],
      description: ['', [Validators.required, MibpAllowedCharactersValidator()]]
    });

    this.setResourceStringSubscription(this.websiteFeedbackTypeStringKeys, resourceStrings => {
      const types = [];
      resourceStrings.forEach((rs, index) => {
        types.push(<DropdownInput>{ value: this.websiteFeedbackTypeValues[index], text: rs });
      });
      this.websiteFeedbackTypes = types;
    });

    this.contactUsWebsiteFeedbackForm.controls.contactPreferenceGroup.valueChanges.subscribe(() => {
      this.isEmailSelected = this.contactUsWebsiteFeedbackForm.controls.contactPreferenceGroup.get('byEmail').value;
      this.isPhoneNumberSelected = this.contactUsWebsiteFeedbackForm.controls.contactPreferenceGroup.get('byPhone').value;
      this.isEmailSelected ? this.addRequiredValidators('email') : this.removeRequiredValidators('email');
      this.isPhoneNumberSelected ? this.addRequiredValidators('phonenumber') : this.removeRequiredValidators('phonenumber');
    });
  }

  private addRequiredValidators(field: string){
    this.contactUsWebsiteFeedbackForm.get(field).addValidators([Validators.required]);
    this.contactUsWebsiteFeedbackForm.get(field).updateValueAndValidity();
  }

  private removeRequiredValidators(field: string){
    this.contactUsWebsiteFeedbackForm.get(field).removeValidators(Validators.required);
    this.contactUsWebsiteFeedbackForm.get(field).updateValueAndValidity();
  }

  async submit(){
    this.formSubmitted = true;
    if (this.contactUsWebsiteFeedbackForm.invalid) {
      this.contactUsWebsiteFeedbackForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactUsWebsiteFeedbackForm, this.element);
      return;
    }
    const contactUsSupportCaseRequest = <ContactUsSupportCaseRequest>{
      caseType: CaseType.WebsiteFeedBack,
      categoryWebsiteFeedback: this.contactUsWebsiteFeedbackForm.get('websiteFeedbackType').value.value,
      suppliedFullName: this.contactUsWebsiteFeedbackForm.get('name').value,
      suppliedEmail: this.contactUsWebsiteFeedbackForm.get('email').value,
      suppliedPhone: this.contactUsWebsiteFeedbackForm.get('phonenumber').value,
      prefferedEmailContact: this.contactUsWebsiteFeedbackForm.controls.contactPreferenceGroup.get('byEmail').value,
      prefferedPhoneContact: this.contactUsWebsiteFeedbackForm.controls.contactPreferenceGroup.get('byPhone').value,
      description: this.contactUsWebsiteFeedbackForm.get('description').value
    };

    try {
      this.loader.showFullScreenLoader();
      await firstValueFrom(this.supportCaseController.submitContactUsCase({body: contactUsSupportCaseRequest}));
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(true);
    }
    catch(error){
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(false);
    }
  }
}
