<div class="mibp-expandable-sections">

  <ng-container *ngIf="listItems && listItems.length > 0; else loadingList">

    <mibp-expandable-section *ngFor="let item of listItems; let i = index;" title="{{ item.title }}" compact="true" (expand)="loadBody(item)" [titleType]="1">
        <div data-content="true" class="header">

          <ng-container *ngIf="item.bodyLoaded; else loadingBody">
            <mibp-html-view *ngIf="isBodyHtml(item.body)" [html]="item.body" [config]="htmlViewConfig" (clickedLink)="htmlLinkClick($event)"></mibp-html-view>
            <div *ngIf="!isBodyHtml(item.body)">
              {{ item.body }}
            </div>
          </ng-container>

          <ng-template #loadingBody>
            <!-- <mibp-loader></mibp-loader> -->
          </ng-template>

        </div>
    </mibp-expandable-section>

  </ng-container>

  <ng-template #loadingList>
    <!-- <mibp-loader></mibp-loader> -->
  </ng-template>

</div>
