/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AvailabilityAndExpectedDateForOperationsViewModel } from '../models/availability-and-expected-date-for-operations-view-model';
import { CartTotalPriceDataVm } from '../models/cart-total-price-data-vm';
import { getAvailabilities } from '../fn/price-and-availability/get-availabilities';
import { GetAvailabilities$Params } from '../fn/price-and-availability/get-availabilities';
import { getAvailabilities$Plain } from '../fn/price-and-availability/get-availabilities-plain';
import { GetAvailabilities$Plain$Params } from '../fn/price-and-availability/get-availabilities-plain';
import { getExpectedDateForOperations } from '../fn/price-and-availability/get-expected-date-for-operations';
import { GetExpectedDateForOperations$Params } from '../fn/price-and-availability/get-expected-date-for-operations';
import { getExpectedDateForOperations$Plain } from '../fn/price-and-availability/get-expected-date-for-operations-plain';
import { GetExpectedDateForOperations$Plain$Params } from '../fn/price-and-availability/get-expected-date-for-operations-plain';
import { getPrices } from '../fn/price-and-availability/get-prices';
import { GetPrices$Params } from '../fn/price-and-availability/get-prices';
import { getPrices$Plain } from '../fn/price-and-availability/get-prices-plain';
import { GetPrices$Plain$Params } from '../fn/price-and-availability/get-prices-plain';
import { getProductExpectedDates } from '../fn/price-and-availability/get-product-expected-dates';
import { GetProductExpectedDates$Params } from '../fn/price-and-availability/get-product-expected-dates';
import { getProductExpectedDates$Plain } from '../fn/price-and-availability/get-product-expected-dates-plain';
import { GetProductExpectedDates$Plain$Params } from '../fn/price-and-availability/get-product-expected-dates-plain';
import { getProductExpectedDatesForQuotation } from '../fn/price-and-availability/get-product-expected-dates-for-quotation';
import { GetProductExpectedDatesForQuotation$Params } from '../fn/price-and-availability/get-product-expected-dates-for-quotation';
import { getProductExpectedDatesForQuotation$Plain } from '../fn/price-and-availability/get-product-expected-dates-for-quotation-plain';
import { GetProductExpectedDatesForQuotation$Plain$Params } from '../fn/price-and-availability/get-product-expected-dates-for-quotation-plain';
import { getProductPricesForOperations } from '../fn/price-and-availability/get-product-prices-for-operations';
import { GetProductPricesForOperations$Params } from '../fn/price-and-availability/get-product-prices-for-operations';
import { getProductPricesForOperations$Plain } from '../fn/price-and-availability/get-product-prices-for-operations-plain';
import { GetProductPricesForOperations$Plain$Params } from '../fn/price-and-availability/get-product-prices-for-operations-plain';
import { getTotalCartPriceData } from '../fn/price-and-availability/get-total-cart-price-data';
import { GetTotalCartPriceData$Params } from '../fn/price-and-availability/get-total-cart-price-data';
import { getTotalCartPriceData$Plain } from '../fn/price-and-availability/get-total-cart-price-data-plain';
import { GetTotalCartPriceData$Plain$Params } from '../fn/price-and-availability/get-total-cart-price-data-plain';
import { PriceAndAvailabilityForOperationsViewModel } from '../models/price-and-availability-for-operations-view-model';
import { ProductAvailability } from '../models/product-availability';
import { ProductExpectedDate } from '../models/product-expected-date';
import { ProductPrice } from '../models/product-price';

@Injectable({ providedIn: 'root' })
export class PriceAndAvailabilityApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getProductPrices()` */
  static readonly GetProductPricesPath = '/priceandavailability/price';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrices$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPrices$Plain$Response(params?: GetPrices$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductPrice>>> {
    return getPrices$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPrices$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPrices$Plain(params?: GetPrices$Plain$Params, context?: HttpContext): Observable<Array<ProductPrice>> {
    return this.getPrices$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductPrice>>): Array<ProductPrice> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrices()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPrices$Response(params?: GetPrices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductPrice>>> {
    return getPrices(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPrices$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPrices(params?: GetPrices$Params, context?: HttpContext): Observable<Array<ProductPrice>> {
    return this.getPrices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductPrice>>): Array<ProductPrice> => r.body)
    );
  }

  /** Path part for operation `priceAndAvailabilityControllerGetProductPricesForOperationscompanyCodeErpCustomerIdDeliverySequenceNumberProductCodes()` */
  static readonly PriceAndAvailabilityControllerGetProductPricesForOperationscompanyCodeErpCustomerIdDeliverySequenceNumberProductCodesPath = '/priceandavailability/price/raw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductPricesForOperations$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProductPricesForOperations$Plain$Response(params?: GetProductPricesForOperations$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceAndAvailabilityForOperationsViewModel>> {
    return getProductPricesForOperations$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductPricesForOperations$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProductPricesForOperations$Plain(params?: GetProductPricesForOperations$Plain$Params, context?: HttpContext): Observable<PriceAndAvailabilityForOperationsViewModel> {
    return this.getProductPricesForOperations$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceAndAvailabilityForOperationsViewModel>): PriceAndAvailabilityForOperationsViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductPricesForOperations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProductPricesForOperations$Response(params?: GetProductPricesForOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceAndAvailabilityForOperationsViewModel>> {
    return getProductPricesForOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductPricesForOperations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProductPricesForOperations(params?: GetProductPricesForOperations$Params, context?: HttpContext): Observable<PriceAndAvailabilityForOperationsViewModel> {
    return this.getProductPricesForOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceAndAvailabilityForOperationsViewModel>): PriceAndAvailabilityForOperationsViewModel => r.body)
    );
  }

  /** Path part for operation `priceAndAvailabilityControllerGetExpectedDateForOperationscompanyCodeErpCustomerIdDeliverySequenceNumberProductCodes()` */
  static readonly PriceAndAvailabilityControllerGetExpectedDateForOperationscompanyCodeErpCustomerIdDeliverySequenceNumberProductCodesPath = '/priceandavailability/expecteddate/raw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExpectedDateForOperations$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExpectedDateForOperations$Plain$Response(params?: GetExpectedDateForOperations$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AvailabilityAndExpectedDateForOperationsViewModel>> {
    return getExpectedDateForOperations$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExpectedDateForOperations$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExpectedDateForOperations$Plain(params?: GetExpectedDateForOperations$Plain$Params, context?: HttpContext): Observable<AvailabilityAndExpectedDateForOperationsViewModel> {
    return this.getExpectedDateForOperations$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvailabilityAndExpectedDateForOperationsViewModel>): AvailabilityAndExpectedDateForOperationsViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExpectedDateForOperations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExpectedDateForOperations$Response(params?: GetExpectedDateForOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<AvailabilityAndExpectedDateForOperationsViewModel>> {
    return getExpectedDateForOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExpectedDateForOperations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExpectedDateForOperations(params?: GetExpectedDateForOperations$Params, context?: HttpContext): Observable<AvailabilityAndExpectedDateForOperationsViewModel> {
    return this.getExpectedDateForOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvailabilityAndExpectedDateForOperationsViewModel>): AvailabilityAndExpectedDateForOperationsViewModel => r.body)
    );
  }

  /** Path part for operation `getProductAvailabilities()` */
  static readonly GetProductAvailabilitiesPath = '/priceandavailability/availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailabilities$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAvailabilities$Plain$Response(params?: GetAvailabilities$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAvailability>>> {
    return getAvailabilities$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailabilities$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAvailabilities$Plain(params?: GetAvailabilities$Plain$Params, context?: HttpContext): Observable<Array<ProductAvailability>> {
    return this.getAvailabilities$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAvailability>>): Array<ProductAvailability> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailabilities()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAvailabilities$Response(params?: GetAvailabilities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAvailability>>> {
    return getAvailabilities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailabilities$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAvailabilities(params?: GetAvailabilities$Params, context?: HttpContext): Observable<Array<ProductAvailability>> {
    return this.getAvailabilities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAvailability>>): Array<ProductAvailability> => r.body)
    );
  }

  /** Path part for operation `priceAndAvailabilityControllerGetTotalCartPriceDatacartId()` */
  static readonly PriceAndAvailabilityControllerGetTotalCartPriceDatacartIdPath = '/priceandavailability/{cartId}/price-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTotalCartPriceData$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCartPriceData$Plain$Response(params: GetTotalCartPriceData$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartTotalPriceDataVm>> {
    return getTotalCartPriceData$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTotalCartPriceData$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCartPriceData$Plain(params: GetTotalCartPriceData$Plain$Params, context?: HttpContext): Observable<CartTotalPriceDataVm> {
    return this.getTotalCartPriceData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartTotalPriceDataVm>): CartTotalPriceDataVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTotalCartPriceData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCartPriceData$Response(params: GetTotalCartPriceData$Params, context?: HttpContext): Observable<StrictHttpResponse<CartTotalPriceDataVm>> {
    return getTotalCartPriceData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTotalCartPriceData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCartPriceData(params: GetTotalCartPriceData$Params, context?: HttpContext): Observable<CartTotalPriceDataVm> {
    return this.getTotalCartPriceData$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartTotalPriceDataVm>): CartTotalPriceDataVm => r.body)
    );
  }

  /** Path part for operation `getProductExpectedDates()` */
  static readonly GetProductExpectedDatesPath = '/priceandavailability/expecteddates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductExpectedDates$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProductExpectedDates$Plain$Response(params?: GetProductExpectedDates$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductExpectedDate>>> {
    return getProductExpectedDates$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductExpectedDates$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProductExpectedDates$Plain(params?: GetProductExpectedDates$Plain$Params, context?: HttpContext): Observable<Array<ProductExpectedDate>> {
    return this.getProductExpectedDates$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductExpectedDate>>): Array<ProductExpectedDate> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductExpectedDates()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProductExpectedDates$Response(params?: GetProductExpectedDates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductExpectedDate>>> {
    return getProductExpectedDates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductExpectedDates$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProductExpectedDates(params?: GetProductExpectedDates$Params, context?: HttpContext): Observable<Array<ProductExpectedDate>> {
    return this.getProductExpectedDates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductExpectedDate>>): Array<ProductExpectedDate> => r.body)
    );
  }

  /** Path part for operation `getProductExpectedDatesForQuotation()` */
  static readonly GetProductExpectedDatesForQuotationPath = '/priceandavailability/{quotationId}/expecteddatesquotation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductExpectedDatesForQuotation$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductExpectedDatesForQuotation$Plain$Response(params: GetProductExpectedDatesForQuotation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductExpectedDate>>> {
    return getProductExpectedDatesForQuotation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductExpectedDatesForQuotation$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductExpectedDatesForQuotation$Plain(params: GetProductExpectedDatesForQuotation$Plain$Params, context?: HttpContext): Observable<Array<ProductExpectedDate>> {
    return this.getProductExpectedDatesForQuotation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductExpectedDate>>): Array<ProductExpectedDate> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductExpectedDatesForQuotation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductExpectedDatesForQuotation$Response(params: GetProductExpectedDatesForQuotation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductExpectedDate>>> {
    return getProductExpectedDatesForQuotation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductExpectedDatesForQuotation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductExpectedDatesForQuotation(params: GetProductExpectedDatesForQuotation$Params, context?: HttpContext): Observable<Array<ProductExpectedDate>> {
    return this.getProductExpectedDatesForQuotation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductExpectedDate>>): Array<ProductExpectedDate> => r.body)
    );
  }

}
