import { Directive, ElementRef, Input} from '@angular/core';
import { LocalizationService } from 'root/services';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mibpResourceStringAttributes]'
})
export class MibpResourceStringAttributesDirective {


  private stopUsingResources: () => void;
  private attributeValue: string;
  private currentResources: {attributeName: string, resourceStringKey: string}[];

  constructor(private elementRef: ElementRef, private localizationService: LocalizationService) {}

    @Input('mibpResourceStringAttributes')
  set titleResourceKey(value: { [attributeName: string]: string }) {

    this.currentResources = Object.keys(value).map(k => {
      return {
        attributeName: k,
        resourceStringKey: value[k]
      };
    });

    this.startUsingResources();

  }

    private startUsingResources() {
      if (this.stopUsingResources) {
        this.stopUsingResources();
      }

      const resourceKeys = this.currentResources.map(f => f.resourceStringKey);

      if (resourceKeys.length > 0) {
        this.stopUsingResources = this.localizationService.using(resourceKeys, values => {

          const attributeObject: { [key: string]: string} = {};

          this.currentResources.forEach((key, ix) => {
            attributeObject[key.attributeName] = values[ix];
          });

          this.updateAttributes(attributeObject);
        });
      }

    }

    private updateAttributes(attrs: { [key: string]: string}) {
      const attributeNames = Object.keys(attrs);
      const el = <HTMLElement>this.elementRef.nativeElement;

      attributeNames.forEach(attr => {
        el.setAttribute(attr, attrs[attr]);
      });

    }
}
