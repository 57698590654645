/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccessGroup } from '../models/access-group';
import { AccessGroupPagedResponse } from '../models/access-group-paged-response';
import { addOrganization } from '../fn/access-groups/add-organization';
import { AddOrganization$Params } from '../fn/access-groups/add-organization';
import { addOrganization$Plain } from '../fn/access-groups/add-organization-plain';
import { AddOrganization$Plain$Params } from '../fn/access-groups/add-organization-plain';
import { addOrganizationScopingJob } from '../fn/access-groups/add-organization-scoping-job';
import { AddOrganizationScopingJob$Params } from '../fn/access-groups/add-organization-scoping-job';
import { browseOrganizations } from '../fn/access-groups/browse-organizations';
import { BrowseOrganizations$Params } from '../fn/access-groups/browse-organizations';
import { browseOrganizations$Plain } from '../fn/access-groups/browse-organizations-plain';
import { BrowseOrganizations$Plain$Params } from '../fn/access-groups/browse-organizations-plain';
import { create } from '../fn/access-groups/create';
import { Create$Params } from '../fn/access-groups/create';
import { create$Plain } from '../fn/access-groups/create-plain';
import { Create$Plain$Params } from '../fn/access-groups/create-plain';
import { delete$ } from '../fn/access-groups/delete';
import { Delete$Params } from '../fn/access-groups/delete';
import { getOrganization } from '../fn/access-groups/get-organization';
import { GetOrganization$Params } from '../fn/access-groups/get-organization';
import { getOrganization$Plain } from '../fn/access-groups/get-organization-plain';
import { GetOrganization$Plain$Params } from '../fn/access-groups/get-organization-plain';
import { getResolvedScopes } from '../fn/access-groups/get-resolved-scopes';
import { GetResolvedScopes$Params } from '../fn/access-groups/get-resolved-scopes';
import { getResolvedScopes$Plain } from '../fn/access-groups/get-resolved-scopes-plain';
import { GetResolvedScopes$Plain$Params } from '../fn/access-groups/get-resolved-scopes-plain';
import { getScopingJobStatus } from '../fn/access-groups/get-scoping-job-status';
import { GetScopingJobStatus$Params } from '../fn/access-groups/get-scoping-job-status';
import { getScopingJobStatus$Plain } from '../fn/access-groups/get-scoping-job-status-plain';
import { GetScopingJobStatus$Plain$Params } from '../fn/access-groups/get-scoping-job-status-plain';
import { getUserCount } from '../fn/access-groups/get-user-count';
import { GetUserCount$Params } from '../fn/access-groups/get-user-count';
import { getUserCount$Plain } from '../fn/access-groups/get-user-count-plain';
import { GetUserCount$Plain$Params } from '../fn/access-groups/get-user-count-plain';
import { listOrganizations } from '../fn/access-groups/list-organizations';
import { ListOrganizations$Params } from '../fn/access-groups/list-organizations';
import { listOrganizations$Plain } from '../fn/access-groups/list-organizations-plain';
import { ListOrganizations$Plain$Params } from '../fn/access-groups/list-organizations-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { OrganizationScopingJobStatusDto } from '../models/organization-scoping-job-status-dto';
import { organizationSearch } from '../fn/access-groups/organization-search';
import { OrganizationSearch$Params } from '../fn/access-groups/organization-search';
import { organizationSearch$Plain } from '../fn/access-groups/organization-search-plain';
import { OrganizationSearch$Plain$Params } from '../fn/access-groups/organization-search-plain';
import { OrganizationVm } from '../models/organization-vm';
import { organizationWithNameExists } from '../fn/access-groups/organization-with-name-exists';
import { OrganizationWithNameExists$Params } from '../fn/access-groups/organization-with-name-exists';
import { organizationWithNameExists$Plain } from '../fn/access-groups/organization-with-name-exists-plain';
import { OrganizationWithNameExists$Plain$Params } from '../fn/access-groups/organization-with-name-exists-plain';
import { organizationWithScopesExists } from '../fn/access-groups/organization-with-scopes-exists';
import { OrganizationWithScopesExists$Params } from '../fn/access-groups/organization-with-scopes-exists';
import { organizationWithScopesExists$Plain } from '../fn/access-groups/organization-with-scopes-exists-plain';
import { OrganizationWithScopesExists$Plain$Params } from '../fn/access-groups/organization-with-scopes-exists-plain';
import { rescope } from '../fn/access-groups/rescope';
import { Rescope$Params } from '../fn/access-groups/rescope';
import { reScopeAll } from '../fn/access-groups/re-scope-all';
import { ReScopeAll$Params } from '../fn/access-groups/re-scope-all';
import { reScopeAll$Plain } from '../fn/access-groups/re-scope-all-plain';
import { ReScopeAll$Plain$Params } from '../fn/access-groups/re-scope-all-plain';
import { ResolvedScope } from '../models/resolved-scope';
import { resolveScopes } from '../fn/access-groups/resolve-scopes';
import { ResolveScopes$Params } from '../fn/access-groups/resolve-scopes';
import { resolveScopes$Plain } from '../fn/access-groups/resolve-scopes-plain';
import { ResolveScopes$Plain$Params } from '../fn/access-groups/resolve-scopes-plain';
import { resolveScopesFromFile } from '../fn/access-groups/resolve-scopes-from-file';
import { ResolveScopesFromFile$Params } from '../fn/access-groups/resolve-scopes-from-file';
import { resolveScopesFromFile$Plain } from '../fn/access-groups/resolve-scopes-from-file-plain';
import { ResolveScopesFromFile$Plain$Params } from '../fn/access-groups/resolve-scopes-from-file-plain';
import { saveOrganization } from '../fn/access-groups/save-organization';
import { SaveOrganization$Params } from '../fn/access-groups/save-organization';
import { saveOrganization$Plain } from '../fn/access-groups/save-organization-plain';
import { SaveOrganization$Plain$Params } from '../fn/access-groups/save-organization-plain';
import { searchTargetedUsers } from '../fn/access-groups/search-targeted-users';
import { SearchTargetedUsers$Params } from '../fn/access-groups/search-targeted-users';
import { searchTargetedUsers$Plain } from '../fn/access-groups/search-targeted-users-plain';
import { SearchTargetedUsers$Plain$Params } from '../fn/access-groups/search-targeted-users-plain';
import { updateIndex } from '../fn/access-groups/update-index';
import { UpdateIndex$Params } from '../fn/access-groups/update-index';
import { updateIndex$Plain } from '../fn/access-groups/update-index-plain';
import { UpdateIndex$Plain$Params } from '../fn/access-groups/update-index-plain';
import { UserIndexSearchResponse } from '../models/user-index-search-response';

@Injectable({ providedIn: 'root' })
export class AccessGroupsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accessGroupsControllerGetScopingJobStatusorganizationId()` */
  static readonly AccessGroupsControllerGetScopingJobStatusorganizationIdPath = '/accessgroups/jobs/organizationid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScopingJobStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScopingJobStatus$Plain$Response(params?: GetScopingJobStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationScopingJobStatusDto>>> {
    return getScopingJobStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScopingJobStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScopingJobStatus$Plain(params?: GetScopingJobStatus$Plain$Params, context?: HttpContext): Observable<Array<OrganizationScopingJobStatusDto>> {
    return this.getScopingJobStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationScopingJobStatusDto>>): Array<OrganizationScopingJobStatusDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScopingJobStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScopingJobStatus$Response(params?: GetScopingJobStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationScopingJobStatusDto>>> {
    return getScopingJobStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScopingJobStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScopingJobStatus(params?: GetScopingJobStatus$Params, context?: HttpContext): Observable<Array<OrganizationScopingJobStatusDto>> {
    return this.getScopingJobStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationScopingJobStatusDto>>): Array<OrganizationScopingJobStatusDto> => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerAddOrganizationScopingJoborganizationId()` */
  static readonly AccessGroupsControllerAddOrganizationScopingJoborganizationIdPath = '/accessgroups/jobs/organizationid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrganizationScopingJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  addOrganizationScopingJob$Response(params?: AddOrganizationScopingJob$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addOrganizationScopingJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOrganizationScopingJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addOrganizationScopingJob(params?: AddOrganizationScopingJob$Params, context?: HttpContext): Observable<void> {
    return this.addOrganizationScopingJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerListOrganizationsoptions()` */
  static readonly AccessGroupsControllerListOrganizationsoptionsPath = '/accessgroups/listorganizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrganizations$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listOrganizations$Plain$Response(params?: ListOrganizations$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccessGroupPagedResponse>> {
    return listOrganizations$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrganizations$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listOrganizations$Plain(params?: ListOrganizations$Plain$Params, context?: HttpContext): Observable<AccessGroupPagedResponse> {
    return this.listOrganizations$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccessGroupPagedResponse>): AccessGroupPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrganizations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listOrganizations$Response(params?: ListOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<AccessGroupPagedResponse>> {
    return listOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrganizations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listOrganizations(params?: ListOrganizations$Params, context?: HttpContext): Observable<AccessGroupPagedResponse> {
    return this.listOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccessGroupPagedResponse>): AccessGroupPagedResponse => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerCreatescopes()` */
  static readonly AccessGroupsControllerCreatescopesPath = '/accessgroups/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain$Response(params?: Create$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationScopingJobStatusDto>>> {
    return create$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Plain(params?: Create$Plain$Params, context?: HttpContext): Observable<Array<OrganizationScopingJobStatusDto>> {
    return this.create$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationScopingJobStatusDto>>): Array<OrganizationScopingJobStatusDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationScopingJobStatusDto>>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<Array<OrganizationScopingJobStatusDto>> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationScopingJobStatusDto>>): Array<OrganizationScopingJobStatusDto> => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerResolveScopesselection()` */
  static readonly AccessGroupsControllerResolveScopesselectionPath = '/accessgroups/resolvescopes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveScopes$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resolveScopes$Plain$Response(params?: ResolveScopes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResolvedScope>>> {
    return resolveScopes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveScopes$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resolveScopes$Plain(params?: ResolveScopes$Plain$Params, context?: HttpContext): Observable<Array<ResolvedScope>> {
    return this.resolveScopes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResolvedScope>>): Array<ResolvedScope> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveScopes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resolveScopes$Response(params?: ResolveScopes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResolvedScope>>> {
    return resolveScopes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveScopes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resolveScopes(params?: ResolveScopes$Params, context?: HttpContext): Observable<Array<ResolvedScope>> {
    return this.resolveScopes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResolvedScope>>): Array<ResolvedScope> => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerResolveScopesFromFilefilereq()` */
  static readonly AccessGroupsControllerResolveScopesFromFilefilereqPath = '/accessgroups/resolvescopesfromfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveScopesFromFile$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  resolveScopesFromFile$Plain$Response(params?: ResolveScopesFromFile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResolvedScope>>> {
    return resolveScopesFromFile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveScopesFromFile$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  resolveScopesFromFile$Plain(params?: ResolveScopesFromFile$Plain$Params, context?: HttpContext): Observable<Array<ResolvedScope>> {
    return this.resolveScopesFromFile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResolvedScope>>): Array<ResolvedScope> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveScopesFromFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  resolveScopesFromFile$Response(params?: ResolveScopesFromFile$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResolvedScope>>> {
    return resolveScopesFromFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveScopesFromFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  resolveScopesFromFile(params?: ResolveScopesFromFile$Params, context?: HttpContext): Observable<Array<ResolvedScope>> {
    return this.resolveScopesFromFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResolvedScope>>): Array<ResolvedScope> => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerGetResolvedScopesaccessGroupId()` */
  static readonly AccessGroupsControllerGetResolvedScopesaccessGroupIdPath = '/accessgroups/{accessGroupId}/resolvedscopes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResolvedScopes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResolvedScopes$Plain$Response(params: GetResolvedScopes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResolvedScope>>> {
    return getResolvedScopes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResolvedScopes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResolvedScopes$Plain(params: GetResolvedScopes$Plain$Params, context?: HttpContext): Observable<Array<ResolvedScope>> {
    return this.getResolvedScopes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResolvedScope>>): Array<ResolvedScope> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResolvedScopes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResolvedScopes$Response(params: GetResolvedScopes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResolvedScope>>> {
    return getResolvedScopes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResolvedScopes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResolvedScopes(params: GetResolvedScopes$Params, context?: HttpContext): Observable<Array<ResolvedScope>> {
    return this.getResolvedScopes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResolvedScope>>): Array<ResolvedScope> => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerReScopeAllname()` */
  static readonly AccessGroupsControllerReScopeAllnamePath = '/accessgroups/rescopeall';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAll$Plain$Response(params?: ReScopeAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeAll$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAll$Plain(params?: ReScopeAll$Plain$Params, context?: HttpContext): Observable<number> {
    return this.reScopeAll$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAll$Response(params?: ReScopeAll$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAll(params?: ReScopeAll$Params, context?: HttpContext): Observable<number> {
    return this.reScopeAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerSearchTargetedUsersrequest()` */
  static readonly AccessGroupsControllerSearchTargetedUsersrequestPath = '/accessgroups/searchtargetedusers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTargetedUsers$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTargetedUsers$Plain$Response(params?: SearchTargetedUsers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserIndexSearchResponse>> {
    return searchTargetedUsers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchTargetedUsers$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTargetedUsers$Plain(params?: SearchTargetedUsers$Plain$Params, context?: HttpContext): Observable<UserIndexSearchResponse> {
    return this.searchTargetedUsers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserIndexSearchResponse>): UserIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTargetedUsers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTargetedUsers$Response(params?: SearchTargetedUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserIndexSearchResponse>> {
    return searchTargetedUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchTargetedUsers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTargetedUsers(params?: SearchTargetedUsers$Params, context?: HttpContext): Observable<UserIndexSearchResponse> {
    return this.searchTargetedUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserIndexSearchResponse>): UserIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerOrganizationSearchoptions()` */
  static readonly AccessGroupsControllerOrganizationSearchoptionsPath = '/accessgroups/searchorganizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationSearch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationSearch$Plain$Response(params?: OrganizationSearch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return organizationSearch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationSearch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationSearch$Plain(params?: OrganizationSearch$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.organizationSearch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationSearch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationSearch$Response(params?: OrganizationSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return organizationSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationSearch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationSearch(params?: OrganizationSearch$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.organizationSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerRescopeaccessGroupId()` */
  static readonly AccessGroupsControllerRescopeaccessGroupIdPath = '/accessgroups/{accessGroupId}/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Response(params: Rescope$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rescope(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope(params: Rescope$Params, context?: HttpContext): Observable<void> {
    return this.rescope$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerUpdateIndexaccessGroupIdConfirmDelete()` */
  static readonly AccessGroupsControllerUpdateIndexaccessGroupIdConfirmDeletePath = '/accessgroups/{accessGroupId}/updateindex';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIndex$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIndex$Plain$Response(params: UpdateIndex$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateIndex$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIndex$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIndex$Plain(params: UpdateIndex$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.updateIndex$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIndex$Response(params: UpdateIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIndex(params: UpdateIndex$Params, context?: HttpContext): Observable<boolean> {
    return this.updateIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerOrganizationWithScopesExistsresolvedScopesIgnoreId()` */
  static readonly AccessGroupsControllerOrganizationWithScopesExistsresolvedScopesIgnoreIdPath = '/accessgroups/exists/withscopes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationWithScopesExists$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationWithScopesExists$Plain$Response(params?: OrganizationWithScopesExists$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccessGroup>> {
    return organizationWithScopesExists$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationWithScopesExists$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationWithScopesExists$Plain(params?: OrganizationWithScopesExists$Plain$Params, context?: HttpContext): Observable<AccessGroup> {
    return this.organizationWithScopesExists$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccessGroup>): AccessGroup => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationWithScopesExists()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationWithScopesExists$Response(params?: OrganizationWithScopesExists$Params, context?: HttpContext): Observable<StrictHttpResponse<AccessGroup>> {
    return organizationWithScopesExists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationWithScopesExists$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  organizationWithScopesExists(params?: OrganizationWithScopesExists$Params, context?: HttpContext): Observable<AccessGroup> {
    return this.organizationWithScopesExists$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccessGroup>): AccessGroup => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerGetOrganizationaccessgroupId()` */
  static readonly AccessGroupsControllerGetOrganizationaccessgroupIdPath = '/accessgroups/{accessgroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganization$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization$Plain$Response(params: GetOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationVm>> {
    return getOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganization$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization$Plain(params: GetOrganization$Plain$Params, context?: HttpContext): Observable<OrganizationVm> {
    return this.getOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationVm>): OrganizationVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization$Response(params: GetOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationVm>> {
    return getOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganization(params: GetOrganization$Params, context?: HttpContext): Observable<OrganizationVm> {
    return this.getOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationVm>): OrganizationVm => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerGetUserCountaccessgroupId()` */
  static readonly AccessGroupsControllerGetUserCountaccessgroupIdPath = '/accessgroups/{accessgroupId}/usercount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount$Plain$Response(params: GetUserCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getUserCount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount$Plain(params: GetUserCount$Plain$Params, context?: HttpContext): Observable<number> {
    return this.getUserCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount$Response(params: GetUserCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getUserCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount(params: GetUserCount$Params, context?: HttpContext): Observable<number> {
    return this.getUserCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerOrganizationWithNameExistsnameIgnoreId()` */
  static readonly AccessGroupsControllerOrganizationWithNameExistsnameIgnoreIdPath = '/accessgroups/exists/organization-with-name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationWithNameExists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationWithNameExists$Plain$Response(params?: OrganizationWithNameExists$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return organizationWithNameExists$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationWithNameExists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationWithNameExists$Plain(params?: OrganizationWithNameExists$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.organizationWithNameExists$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationWithNameExists()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationWithNameExists$Response(params?: OrganizationWithNameExists$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return organizationWithNameExists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationWithNameExists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationWithNameExists(params?: OrganizationWithNameExists$Params, context?: HttpContext): Observable<boolean> {
    return this.organizationWithNameExists$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerSaveOrganizationorganization()` */
  static readonly AccessGroupsControllerSaveOrganizationorganizationPath = '/accessgroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveOrganization$Plain$Response(params?: SaveOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationVm>> {
    return saveOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveOrganization$Plain(params?: SaveOrganization$Plain$Params, context?: HttpContext): Observable<OrganizationVm> {
    return this.saveOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationVm>): OrganizationVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveOrganization$Response(params?: SaveOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationVm>> {
    return saveOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveOrganization(params?: SaveOrganization$Params, context?: HttpContext): Observable<OrganizationVm> {
    return this.saveOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationVm>): OrganizationVm => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerAddOrganizationorganization()` */
  static readonly AccessGroupsControllerAddOrganizationorganizationPath = '/accessgroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOrganization$Plain$Response(params?: AddOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationVm>> {
    return addOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOrganization$Plain(params?: AddOrganization$Plain$Params, context?: HttpContext): Observable<OrganizationVm> {
    return this.addOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationVm>): OrganizationVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOrganization$Response(params?: AddOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationVm>> {
    return addOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOrganization(params?: AddOrganization$Params, context?: HttpContext): Observable<OrganizationVm> {
    return this.addOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationVm>): OrganizationVm => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerDeleteaccessGroupId()` */
  static readonly AccessGroupsControllerDeleteaccessGroupIdPath = '/accessgroups/{accessGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accessGroupsControllerBrowseOrganizationsrequest()` */
  static readonly AccessGroupsControllerBrowseOrganizationsrequestPath = '/accessgroups/browseorganizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseOrganizations$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  browseOrganizations$Plain$Response(params?: BrowseOrganizations$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AccessGroupPagedResponse>> {
    return browseOrganizations$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseOrganizations$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  browseOrganizations$Plain(params?: BrowseOrganizations$Plain$Params, context?: HttpContext): Observable<AccessGroupPagedResponse> {
    return this.browseOrganizations$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccessGroupPagedResponse>): AccessGroupPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseOrganizations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  browseOrganizations$Response(params?: BrowseOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<AccessGroupPagedResponse>> {
    return browseOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseOrganizations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  browseOrganizations(params?: BrowseOrganizations$Params, context?: HttpContext): Observable<AccessGroupPagedResponse> {
    return this.browseOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccessGroupPagedResponse>): AccessGroupPagedResponse => r.body)
    );
  }

}
