import { DropdownInput } from 'root/components';

export interface Refiner {
  name: string;
  label: string;
  _model?: any;
  hidden?: boolean;
}

export class ListRefiner implements Refiner {
  constructor(name: string, label: string, items: {[key: string]: string}, active: string, hidden?: boolean) {
    this.name = name;
    this.label = label;
    this.hidden = hidden;
    this.items = items;
    this.value = active;
  }
  items: {[key: string]: string};
  value: string;
  name: string;
  label: string;
  hidden?: boolean;
  refinerModel?: any;
}

export class DateRefiner implements Refiner {
  constructor(name: string, resourceString: string, value?: Date, minDate?: Date, maxDate?: Date, hidden?: boolean) {
    this.name = name;
    this.label = resourceString;
    this.hidden = hidden;
    this.dateValue = value;
    this.minDate = minDate;
    this.maxDate = maxDate;
  }
  minDate?: Date;
  maxDate?: Date;
  dateValue: Date;
  name: string;
  label: string;
  labelMacros?: { [key: string]: string};
  hidden?: boolean;
  refinerModel?: any;
}

export class CheckboxListRefiner implements Refiner {
  constructor(name: string, label: string, items: CheckboxListItem[], checkedValues?: string[], hidden?: boolean) {
    this.name = name;
    this.label = label;
    this.hidden = hidden;
    this.items = items;
    this.checkedValues = checkedValues || [];
  }
  items: CheckboxListItem[];
  checkedValues: string[];
  label: string;
  name: string;
  hidden?: boolean;
  refinerModel?: any;
  hideIfOnlyOne?: boolean;
}

export interface CheckboxListItem {
  text?: string;
  resourceKey?: string;
  value: string;
}

export class RadiobuttonListRefiner implements Refiner {
  constructor(name: string, label: string, items: RadiobuttonListItem[], selectedValue?: string, hidden?: boolean) {
    this.name = name;
    this.label = label;
    this.hidden = hidden;
    this.items = items;
    this.selectedValue = selectedValue;
  }
  items: RadiobuttonListItem[];
  selectedValue: string;
  label: string;
  name: string;
  hidden?: boolean;
  refinerModel?: any;
  hideIfOnlyOne?: boolean;
}

export interface RadiobuttonListItem {
  text?: string;
  resourceKey?: string;
  value: string;
}

export class DropdownRefiner implements Refiner {
  constructor(name: string, label: string, items: string[], selectedValues?: string, hidden?: boolean) {
    this.name = name;
    this.label = label;
    this.items = this.buildDropdownItems(items);
    this.selectedValues = selectedValues ? <DropdownInput>{ text: selectedValues, value: selectedValues } : null;
    this.hidden = hidden;
  }
  items: DropdownInput[];
  selectedValues: DropdownInput;
  label: string;
  name: string;
  refinerModel?: any;
  hidden?: boolean;
  hideIfOnlyOne?: boolean;
  buildDropdownItems(strings: string[]): DropdownInput[] {
    if (strings && strings.length > 0) {
      const dropdownInput = <DropdownInput[]>[];
      strings.forEach(s => {
        dropdownInput.push({text: s, value: s});
      });
      return dropdownInput;
    }
    return null;
  }
}

export class DropdownMultiSelectRefiner implements Refiner {
  constructor(name: string, label: string, items: string[], selectedValues?: string[], hidden?: boolean) {
    this.name = name;
    this.label = label;
    this.hidden = hidden;
    this.items = this.buildDropdownItems(items);
    this.selectedValues = this.buildDropdownItems(selectedValues);
  }
  items: DropdownInput[];
  selectedValues: DropdownInput[];
  label: string;
  name: string;
  hidden?: boolean;
  refinerModel?: any;
  hideIfOnlyOne?: boolean;
  buildDropdownItems(strings: string[]): DropdownInput[] {
    if (strings && strings.length > 0) {
      const dropdownInput = <DropdownInput[]>[];
      strings.forEach(s => {
        dropdownInput.push({text: s, value: s});
      });
      return dropdownInput;
    }
    return null;
  }
}


// export interface RefinerOptionItem {
//   text?: string;
//   resourceKey?: string;
//   value: string;
// }

export class TextRefiner implements Refiner {
  constructor(name: string, label: string, value?: string, hidden?: boolean) {
    this.name = name;
    this.label = label;
    this.textValue = value;
    this.hidden = hidden;
  }
  name: string;
  textValue: string;
  label: string;
  hidden?: boolean;
  refinerModel?: any;
}

export class NumberRefiner implements Refiner {
  constructor(name: string, label: string, value?: number, hidden?: boolean) {
    this.name = name;
    this.label = label;
    this.numberValue = value;
    this.hidden = hidden;
  }
  name: string;
  numberValue: number;
  label: string;
  hidden?: boolean;
  refinerModel?: any;
}

export interface RefinerChangeEvent {
  before: RefinerValue[];
  after: RefinerValue[];
  filters: any;
}

export interface RefinerValue {
  refinerType: string;
  name: string;
  value: any;
}
