import { Subject, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { PopupOpenEvent } from './popup-container.types';

@Injectable({
  providedIn: 'root'
})
export class PopupContainerInternalService {

  private openSubject = new Subject<PopupOpenEvent>();
  private incrementalId = 0;

  public get popupGroupOpened(): Observable<PopupOpenEvent> {
    return this.openSubject.asObservable();
  }

  public emitOpen(e: PopupOpenEvent): void {
    this.openSubject.next(e);
  }

  public newId(): string {
    this.incrementalId++;
    return `popcon-${this.incrementalId}`;
  }

}

