import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export function MibpAllowedCharactersValidator(extraAllowedChars: string = null): ValidatorFn { 
    return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    let illegalChars = '<>\'"'; //TODO: Maybe keep in settings?

    if (extraAllowedChars && extraAllowedChars.length > 0) {
      for (let index = 0; index < extraAllowedChars.length; index++) {
        illegalChars = illegalChars.replace(extraAllowedChars[index], '');
      }

      if (illegalChars.length < 1) {
        return null;
      }
    }

    if (value.match(new RegExp(`[${illegalChars}]`, "g"))) {      
      //This return object structure is needed due to resource macro
      return {
        illegalCharacters: {
          illegalChars: illegalChars
        }
      };
    }
  };
}