import { CartsApiController, UpSellsApiController } from 'root/mibp-openapi-gen/controllers';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { ContactUsService, GlobalConfigService, LocalizationService, LogService, MibpHttpApi, MibpLogger, NoticebarService, FrontendContextService, PermissionService } from 'root/services';
import { ContactUsTopic } from '../contact-dialog/contact-us-form/contact-us-form.types';
import { NoticeType } from '../noticebar/noticebar.enum';
import { firstValueFrom } from 'rxjs';
import { PriceAndAvailabilityRequest, PriceAndAvailabilitySource, ProductAvailability, ProductExpectedDate, ProductExpectedDateRequestDto, ProductPrice, UpSellCrossSellProductRequestResponseDto } from 'root/mibp-openapi-gen/models';
import { allPermissionPolicies } from 'root/all-permission-policies';

export interface UpSellProductWithPriceAndAvailability extends UpSellCrossSellProductRequestResponseDto {
  productPrice : ProductPrice;
  productAvailability : ProductAvailability;
  productExpectedDate : ProductExpectedDate;
}

@Component({
  selector: 'mibp-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss']
})
export class UpsellComponent implements OnInit {

  isLoading = false;
  productPrice: ProductPrice;
  upsellProducts: UpSellProductWithPriceAndAvailability[];
  log: MibpLogger;
  contactUsMessageRequestPrice: string;
  currentUpsellCrossSellProductId: number;
  canSeeAvailability: boolean;
  @Output() closeDialogBox = new EventEmitter<boolean>();
  @Input()
  set setUpsellCrossSellProductId(upsellCrossSellProductId: number) {
    if (!upsellCrossSellProductId) {
      this.upsellProducts = [];
      return;
    }

    this.getUpsellProducts(upsellCrossSellProductId);
  }

  @Output() productHasBeenUpgradedEvent = new EventEmitter();

  constructor(
    private localizationService: LocalizationService,
    private upsellsApi: UpSellsApiController,
    private contactUsService: ContactUsService,
    private noticeBarService: NoticebarService,
    private logger: LogService,
    private frontendContext: FrontendContextService,
    private httpApi: MibpHttpApi,
    private router: Router,
    private globalConfigService : GlobalConfigService,
    private cartsController: CartsApiController,
    private permissions: PermissionService
  ) {
    this.log = logger.withPrefix('upsell-component');
  }

  get useExpectedDate(): boolean {
    return this.frontendContext.isExpectedDeliveryDateEnabled();
  }


  ngOnInit(): void {
    this.localizationService.getResourceString('Carts_PriceForProductMessage').then((resourceStringValue) => {
      this.contactUsMessageRequestPrice = resourceStringValue;
    });
    this.canSeeAvailability = this.permissions.test(allPermissionPolicies.canSeeAvailability);
  }
  getUpsellProducts(upsellCrossSellProductId: number): void {
    this.currentUpsellCrossSellProductId = upsellCrossSellProductId;
    this.log.debug('getUpsellProducts upsellCrossSellProductId', upsellCrossSellProductId);

    this.isLoading = true;

    this.upsellProducts = [];
    const languageCode = this.localizationService.getLang();

    firstValueFrom(this.upsellsApi.getUpsellProducts({
      upsellCrossSellProductId: upsellCrossSellProductId,
      languageCode: languageCode})).then((upsellProducts) => {
      this.log.debug('response upsellProducts', upsellProducts);
      this.upsellProducts = upsellProducts as UpSellProductWithPriceAndAvailability[];
      this.isLoading = false;

      this.getPriceAndAvailabilitiesOfProducts(this.upsellProducts.map(u => { return {quantity:1, productCode:u.code } as ProductExpectedDateRequestDto;}));

    }).catch(error => {
      this.isLoading = false;
      this.log.error('Error when fetching upsell products', error);
      this.noticeBarService.show('Upsell_ErrorFetchingUpsellProducts', NoticeType.Error);
    });
  }

  getPriceAndAvailabilitiesOfProducts(productCodes: ProductExpectedDateRequestDto[]) {
    if(this.permissions.test(allPermissionPolicies.canSeePrices)){
      this.httpApi.PriceAndAvailability.GetPriceofProducts({
        deliverySequenceId : null,
        priceAndAvailabilitySource : PriceAndAvailabilitySource.ActiveCart,
        productCodes : productCodes.map(p => p.productCode),
        force : false
      } as PriceAndAvailabilityRequest).then(x => {
        this.upsellProducts.forEach(c => {
          c.productPrice = x.find(x => x.productCode === c.code);
        });
      }).catch(e => {
        // Update all the affected products with an error
        this.upsellProducts.forEach(c => {
          c.productPrice = {
            errorCode: 'BACKEND_UNHANDLED_ERROR',
            errorDescription: 'Backend threw an unhandled exception',
            productCode: c.code,
            hasNetPriceOnly: false,
            superseededByProduct:null,
            warning: null,
            taxCode: null
          };
        });
        this.log.error(`Price error`, e);
      });
    }
    if(!this.useExpectedDate){
      if (this.canSeeAvailability) {
        this.httpApi.PriceAndAvailability.GetAvailabilityofProducts({
          deliverySequenceId : null,
          priceAndAvailabilitySource : PriceAndAvailabilitySource.ActiveCart,
          productCodes : productCodes.map(p => p.productCode),
          force : false
        }).then(x => {
          this.upsellProducts.forEach(c => {
            c.productAvailability = x.find(x => x.productCode === c.code);
          });
        });
      }
    }
    else{
      const todayDate = new Date().toLocaleDateString('en-ca');
      this.httpApi.PriceAndAvailability.GetExpectedDateofProducts({
        products : productCodes,
        priceAndAvailabilitySource : PriceAndAvailabilitySource.ActiveCart,
        deliverySequenceId : null,
        userBrowserDate:todayDate,
        onlyGetFromCache: false
      }).then(x => {
        this.upsellProducts.forEach(c => {
          c.productExpectedDate = x.find(x => x.productCode === c.code);
        });
      });
    }
  }

  onUpgradeProduct(originatingUpsellProductId: Guid, upsellCrossSellProductId: number): void {
    this.isLoading = true;
    firstValueFrom(this.cartsController.replaceProductsWithUpsellProduct({productIdToReplace : originatingUpsellProductId.toString(), upsellCrossSellProductId: upsellCrossSellProductId})).then((response) => {
      this.productHasBeenUpgradedEvent.emit();
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);

    }, error => {
      this.isLoading = false;
      this.log.error('Error when replacing with upsell product', error);
      this.noticeBarService.show('Upsell_ErrorUpgradeMessage', NoticeType.Error);
    });
  }
  onClickProductImage(productCode:string)
  {
    this.closeDialogBox.emit(true);
    this.frontendContext.Navigation.navigateToProductDetailsPage(productCode);
  }
  // onCloseCrossSellUpsellDialog($event)
  // {
  //   this.closeDialogBox.emit();
  // }
}
