import { NoticebarService } from './../../services/noticebar-service/noticebar.service';
import { DialogButton, DialogComponent } from 'root/components';
import { UnhandledExceptionInfo } from './../../services/broadcast-service/broadcast.service.types';
import { BroadcastService } from './../../services/broadcast-service/broadcast.service';
import { Component, EmbeddedViewRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ButtonColors } from '../button/button.enum';
import { NoticeType } from '../noticebar/noticebar.enum';
import { Subscription } from 'rxjs';
import { GlobalConfigService } from './../../services/global-config/global-config.service';
import { PermissionService } from 'root/services/permission';
import { FormattingService } from 'root/services';
import { MibpOperationsService } from './../../services/operations/operations.service';
import { addMinutes } from 'date-fns';
import { allPermissionPolicies } from 'root/all-permission-policies';

@Component({
  selector: 'mibp-unhandled-exception-dialog',
  templateUrl: './unhandled-exception-dialog.component.html',
  styleUrls: ['./unhandled-exception-dialog.scss'],
  styles: [`
    textarea {
      width: 100%;
      height: 10em;
      font-size: 10pt;
      font-family: monospace;
      line-height: 1em;
    }
  `]
})
export class UnhandledExceptionDialogComponent implements OnInit, OnDestroy {
  exception: UnhandledExceptionInfo;
  @ViewChild(DialogComponent) dialog: DialogComponent;
  errorDetails: string;
  exceptionSub: Subscription;
  embeddedView: EmbeddedViewRef<any>;
  clientId: string;
  isSupportTeamMember = false;
  exceptionDate: Date;

  buttons: DialogButton[];
  @ViewChild('mibpLogExceptionDialog', {static: true}) mibpLogExceptionDialogTemplate: TemplateRef<any>;

  constructor(private global: GlobalConfigService,
    private broadcastService: BroadcastService,
    private noticeBar: NoticebarService,
    private viewContainerRef: ViewContainerRef,
    private permission: PermissionService,
    private operationsService: MibpOperationsService,
    private formatting: FormattingService) {}

  ngOnInit(): void {

    this.clientId = this.broadcastService.snapshot.clientId;
    this.isSupportTeamMember = this.permission.test(allPermissionPolicies.isSupportTeamMember);

    if (this.global.useNewExceptionDialog) {
      this.exceptionSub = this.broadcastService?.unhandledException.subscribe(ex => {
        if (ex != null) {
          this.exceptionDate = new Date();
          this.moveTemplateToBody();
        }
      });



    } else {
      this.buttons = [
        {
          id: 'copy-details',
          resourceKey: 'Global_CopyToClipboard',
          color: ButtonColors.Blue
        },
        {
          id: 'reload',
          resourceKey: 'AppLoading_ReloadPage_Action',
          color: ButtonColors.Orange
        }
      ];

      this.exceptionSub = this.broadcastService.unhandledException.subscribe(ex => {
        if (ex != null) {
          this.errorDetails = `==== START OF MY SANDVIK ERROR ===== \n`;
          this.errorDetails += `URL: ${window.location.href}` + '\n';
          this.errorDetails += `User Agent: ${navigator.userAgent}` + '\n';
          this.errorDetails += `Platform: ${navigator.platform}` + '\n';
          this.errorDetails += `Window size: ${window.innerWidth}x${window.innerHeight}` + '\n';
          this.errorDetails += `Screen size: ${screen.width}x${screen.height}` + '\n\n';
          if (ex instanceof Error) {
            this.errorDetails += 'Error Message:' + ex.message + '\n';
            this.errorDetails += 'Error Name:' + ex.name + '\n';
            this.errorDetails += 'Error Stack:' + ex.stack + '\n';
          } else {
            this.errorDetails += 'Exception:\n' + JSON.stringify(ex, null, 2) + '\n';
          }

          this.errorDetails += `==== END OF MY SANDVIK ERROR ===== \n`;

          this.dialog?.open();
        }
      });
    }



  }

  findCorrelationId(correlationid: string): void {
    this.operationsService.navigateToLogSearch('logrows', {
      logTable: this.formatting.toServerUTCString(addMinutes(this.exceptionDate, -5)).substring(10),
      filters: [
        {
          field: 'CorrelationId',
          operator: '=',
          type: 'condition',
          value: correlationid
        },
        {
          field: 'LogLevel',
          operator: '=',
          type: 'condition',
          value: 'Error'
        },
        {
          field: 'LogDate',
          operator: '>',
          type: 'condition',
          dateTz: 'utc',
          value: this.formatting.toServerUTCString( addMinutes(this.exceptionDate, -5) )
        }
      ]
    }, true);
  }

  /**
   * Move the 'dialogTemplate' element (see template) to the body
   * This is done by creating an EmbeddedView and moving its elements to the body element
   * Using the embeddedView, all angular bindings will remain and work as usual even if the
   * elements are moved somewhere else
   * https://angular.io/api/core/ViewContainerRef#createembeddedview
   */
  private moveTemplateToBody(): void {
    const bodyElement = document.querySelector('body');
    this.embeddedView = this.viewContainerRef.createEmbeddedView(this.mibpLogExceptionDialogTemplate);
    this.embeddedView.rootNodes.forEach(rootNode => bodyElement.appendChild(rootNode));
  }

  ngOnDestroy(): void {
    this.embeddedView?.destroy();
    this.exceptionSub?.unsubscribe();
  }

  onButtonClick(e: DialogButton): void {
    if (e.id === 'copy-details') {
      this.copy();
    } else {
      window.location.reload();
    }
  }

  copy(): void {

    try {
      const input = document.createElement('textarea');
      input.style.opacity = '0';
      input.style.position = 'absolute';
      input.style.zIndex = '0';
      input.value = this.errorDetails;
      document.body.appendChild(input);

      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      document.body.removeChild(input);

      this.noticeBar.showText('Copied to Clipboard', NoticeType.Success, false);
    } catch(e) {
      this.noticeBar.showText('Error copying to Clipboard', NoticeType.Error, false);
    }
  }

}

