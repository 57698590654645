<mibp-contact-us-item-enquiry-form
  *ngIf="newContactUsTopic.ItemEnquiry == selectedTopic || (formData && newContactUsTopic.ItemEnquiry == formData.newContactUsTopic)"
  [itemEnquiryData]="formData?.itemEnquiryData"
  (successfullySubmitted)="formSuccessfullySubmitted($event)"
  [showHeader]="showHeader">
</mibp-contact-us-item-enquiry-form>

<mibp-contact-us-shipping-tracking-form
  *ngIf="newContactUsTopic.ShippingAndTracking == selectedTopic
     || (formData && newContactUsTopic.ShippingAndTracking == formData.newContactUsTopic)"
  [shipingTrackingData]="formData?.shipingTrackingData"
  (successfullySubmitted)="formSuccessfullySubmitted($event)"
  [showHeader]="showHeader">
</mibp-contact-us-shipping-tracking-form>

<mibp-contact-us-returns-and-orders-form  *ngIf="newContactUsTopic.ReturnsAndOrderDiscrepancies == selectedTopic" (successfullySubmitted)="formSuccessfullySubmitted($event)"></mibp-contact-us-returns-and-orders-form>
<mibp-contact-us-website-feedback-form *ngIf="newContactUsTopic.WebsiteFeedback == selectedTopic" (successfullySubmitted)="formSuccessfullySubmitted($event)"></mibp-contact-us-website-feedback-form>
<mibp-contact-us-trading-account-form *ngIf="newContactUsTopic.TradingAccountQueryOrIssue == selectedTopic" (successfullySubmitted)="formSuccessfullySubmitted($event)"></mibp-contact-us-trading-account-form>
<mibp-technical-request-form *ngIf="newContactUsTopic.TechnicalRequest == selectedTopic" (successfullySubmitted)="formSuccessfullySubmitted($event)"></mibp-technical-request-form>
<mibp-contact-us-general-form *ngIf="newContactUsTopic.GeneralQuestionOrRequest == selectedTopic || (formData && newContactUsTopic.GeneralQuestionOrRequest == formData.newContactUsTopic)" (successfullySubmitted)="formSuccessfullySubmitted($event)"></mibp-contact-us-general-form>
<mibp-contact-us-request-quote-form [requestQuoteData]="formData.upgradeQuoteData" *ngIf="newContactUsTopic.RequestUpgradeQuote == selectedTopic || (formData && newContactUsTopic.RequestUpgradeQuote == formData.newContactUsTopic)" 
(successfullySubmitted)="formSuccessfullySubmitted($event)" [contactUsRequestQuoteForm]="formData?.upgradeQuoteData" ></mibp-contact-us-request-quote-form>
