import { EventEmitter } from '@angular/core';
import { Component, OnInit, Input, Output, ViewChild, OnDestroy } from '@angular/core';
import { ChartOptions, ChartData, Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { environment } from 'root/environment';
import { MibpPopoverComponent } from 'root/components/popover-component/popover.component';
import { LocalizationService } from '../../../services/localization/localization.service';
import { MibpLogger, LogService } from '../../../services/logservice/index';
import { BasicChartData } from '../chart.types';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'mibp-chart-pie',
  templateUrl: './mibp-chart-pie.component.html',
  styleUrls: ['./mibp-chart-pie.component.scss']
})
export class MibpChartPieComponent implements OnInit, OnDestroy {

  private releaseResourceStrings: () => void;
  private currentLegendValue: 'top' | 'right' | 'bottom' | 'left' | 'hidden';
  private log: MibpLogger;
  private chartJsData: ChartData;
  private plugins: any;
  private cutout = "70%";
  private chartCenterLabels: string[];

  @Input() width = '400px';
  @Input() height = '300px';
  @Input() aspectRatio = 2;
  @Input() doughnut = false;
  @Input() allowSelection = false;
  @Input() loading = false;
  @Input() legendHtmlResourceStrings: string[] = [];
  @Input() useResourceStringLabels = true;
  @Input() showLegend = false;
  @Input()
  set colors(value: string[]) {
    if (value !== null && value.length > 0) {
      this.chartColors = value;
    }
  }

  @Input()
  set total(value: string[]) {
    if (value !== null && value.length > 0) {
      this.chartCenterLabels = value;
    }
  }

  @Input()
  set cutoutPercentage(value: number) {
    if (value !== null && value > 0) {
      this.cutout = value.toString() + '%';
    }
  }

  @Input()
  set legend(value: 'top' | 'right' | 'bottom' | 'left' | 'hidden' | null) {
    this.currentLegendValue = value ? value : 'hidden';
    if (this.currentLegendValue === 'hidden') {
      this.showLegend = false;
    } else {
      this.showLegend = true;
      let clickCallback = null;
      const self = this;
      if (this.chartTooltipHtmlResourceStrings && this.chartTooltipHtmlResourceStrings.findIndex(str => !!str) !== -1) {
        clickCallback = function (e: MouseEvent, legend: any) {
          if (self.chartTooltipHtmlResourceStrings[legend.index]) {
            self.currentTooltipResourceString = self.chartTooltipHtmlResourceStrings[legend.index];
            self.popoverComponent.openAt(e.clientX, e.clientY + window.scrollY);
          }
        };
      }

      this.chartOptions.plugins.legend = {
        display: true,
        position: this.currentLegendValue,
        labels: {
          usePointStyle: true,
          font: {
            size: 12
          }
        }
      };

      if (clickCallback) {
        this.chartOptions.plugins.legend['onClick'] = clickCallback;
      }
    }
  }

  @Output() chartClicked: EventEmitter<any> = new EventEmitter;

  @ViewChild(MibpPopoverComponent)
  private popoverComponent: MibpPopoverComponent;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  isReady = false;
  public chartOptions: ChartOptions = {};
  public chartLabels: string[] = [];
  public chartData: number[] = [];
  public chartTooltipHtmlResourceStrings: string[] = [];
  public currentTooltipResourceString: string = null;
  public chartTotal: string = null;
  public chartColors = environment.chart.colors;

  @Input()
  set data(value: BasicChartData[]) {
    const resourceStrings = [];
    const values = [];
    let labels = [];
    this.chartTooltipHtmlResourceStrings = [];
    this.isReady = false;

    if (!value) {
      this.isReady = true;
      return;
    }

    value.forEach(val => {
      values.push(val.value);
      labels.push(val.labelText || val.labelResourceString);
      this.chartTooltipHtmlResourceStrings.push(val.tooltipHtmlResourceString);
      if (val.labelResourceString) { resourceStrings.push(val.labelResourceString); }
    });

    if (this.releaseResourceStrings) {
      this.releaseResourceStrings();
    }

    if (this.useResourceStringLabels) {
      this.releaseResourceStrings = this.localizationService.using(resourceStrings, (resourceStringValues) => {
        this.log.debug('rsv', resourceStrings, resourceStringValues);
        resourceStrings.forEach((rs, ix) => {
          labels = labels.map(v => rs === v ? resourceStringValues[ix] : v);
        });

        this.chartData = values;
        this.chartLabels = labels;
        this.setChartJsData();
        this.isReady = true;
      });
    } else {
      this.chartData = values;
      this.chartLabels = labels;
      this.setChartJsData();
      this.isReady = true;
    }
  }

  constructor(private localizationService: LocalizationService, logger: LogService) {
    this.log = logger.withPrefix('mibp-chart-pie');
    const self = this;
  }

  ngOnInit() {
    this.chartOptions.responsive = true;
    this.chartOptions.aspectRatio = this.aspectRatio;
    this.chartOptions.maintainAspectRatio = true;

    this.chartOptions.plugins = {
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: true,
        callbacks: {
          title: () => null,
          label: (context) => {    
            let total = 0;
            let tooltipPercentage = 0;
            this.chartData.forEach(n => total += n);

            if (total !== 0) {
              tooltipPercentage = Math.round(context.parsed * 100 / total);
            }

            return " " + context.label + ": " + context.parsed + " (" + tooltipPercentage + "%" + ")";
          }
        }
      }
    };

    if (this.chartOptions.plugins.legend === undefined) {
      this.chartOptions.plugins.legend = {
        display: false,
        labels: {
          usePointStyle: true
        }
      };
    }

    // We have a dougnut chart, then we set cutout and dougnut center label plugin
    if (this.doughnut) {
      // If cutout is set as a number it´s interpreted as number of pixels, if it´s a string
      // ending with % it´s interpreted as percentage
      this.chartOptions['cutout'] = this.cutout;

      const doughnutLabel = {
        id: 'doughnutLabel',
        beforeDatasetsDraw: (chart, args, pluginOptions) => {
          const { ctx, data, config } = chart;
          ctx.save();

          if (!this.chartCenterLabels || this.chartCenterLabels.length < 1) {
            return;
          }

          const x = chart.getDatasetMeta(0).data[0].x;
          const y = chart.getDatasetMeta(0).data[0].y;

          ctx.font = 'bold 36px Sandvik Sans, Arial';
          ctx.textAlign = 'center';
          ctx.Baseline = 'middle';
          ctx.fillStyle = "#101010"; //TODO: Define as constant or read from global service

          if (this.chartCenterLabels.length == 1) {
            ctx.fillText(this.chartCenterLabels[0], x, y + 12); //adjusted y for text height to center text
          } else {
            ctx.fillText(this.chartCenterLabels[0], x, y - 5); //adjusted upwards to make room for other label

            ctx.restore();
            ctx.font = "normal 21px Sandvik Sans, Arial";
            ctx.textAlign = 'center';
            ctx.Baseline = 'middle';
            ctx.fillStyle = "#aca9a9"; //TODO: Define as constant or read from global service
            ctx.fillText(this.chartCenterLabels[1], x, y + 24);
          }
        }
      };
      this.plugins = [doughnutLabel];
    }
  }

  ngOnDestroy() {
    if (this.releaseResourceStrings) {
      this.releaseResourceStrings();
    }
  }

  setChartJsData() {
    this.chartJsData = {
      labels: this.chartLabels,
      datasets: [
        {
          data: this.chartData,
          backgroundColor: this.chartColors
        }
      ],
    };
  }

  // events
  public onChartClicked({ event, active }: { event: MouseEvent, active: any[] }): void {
    this.log.debug("Allow selection", this.allowSelection);
    const e = {
      event: event,
      active: active
    };
    if (this.allowSelection && active[0] && active[0]._model) {
      this.chart.update();
      active[0]._model.outerRadius += 5;
    }
    this.chartClicked.emit(e);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: any[] }): void {
    this.log.debug("chartHovered", event, active);
  }
}
