import { FrontendContextService, LoaderService } from 'root/services';
import { AuthService } from './../../../../services/auth-service/auth.service';
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BroadcastService } from './../../../../services/broadcast-service/broadcast.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { MibpPopupContainerComponent } from 'root/components/popup-container/popup-container.component';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

@Component({
  selector: 'mibp-header-user',
  templateUrl: './header-user.component.html'
})
export class MibpHeaderUserComponent implements OnInit, OnDestroy {
  isLogoutVisible = true;
  userEventIsNull = false;
  userEventSub?: Subscription;
  isSupportUser;
  isActiveUser = false;
  @ViewChild('userPopupContainer') userPopupContainer: MibpPopupContainerComponent;



  constructor(private auth: AuthService, private loader: LoaderService, private context: FrontendContextService, private broadcast: BroadcastService, private sessionService: MibpSessionService) {}

  ngOnInit(): void {
    this.userEventSub = this.broadcast.mibpSession.subscribe(userApiEvent => {
      this.userEventIsNull = userApiEvent === null;

      this.isSupportUser = this.context.testPermission(allPermissionPolicies.isSupportTeamMember);
      this.isActiveUser = this.sessionService.hasActiveUser();
      if (window !== window.top) {
        this.isLogoutVisible = false;
      }
    });

  }

  ngOnDestroy(): void {
    this.userEventSub?.unsubscribe();
  }

  public logout(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.loader.showFullScreenLoader();
    this.userPopupContainer.close();
    this.auth.signout();
  }

}
