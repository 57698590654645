import { MibpHttpApi } from './mibp-http-api.service';
import { AvailabilityType, PriceAndAvailabilitySource, ProductAvailability, ProductExpectedDate, ProductPrice, ResponseExpectedDeliveryDate } from 'root/mibp-openapi-gen/models';

/**
 * Provides an API And for fetching large kits that are too big for SignalR
 */
export class PriceAndAvailabilityHttpService {
  constructor(private httpApi: MibpHttpApi) { }
  public async GetPriceofProducts(request: PriceAndAvailabilityRequest): Promise<ProductPrice[]> {
    return await this.httpApi.post<ProductPrice[]>(`priceandavailability/price`, request).toPromise();
  }
  public async GetAvailabilityofProducts(request: PriceAndAvailabilityRequest): Promise<ProductAvailability[]> {
    return await this.httpApi.post<ProductAvailability[]>(`priceandavailability/availability`, request).toPromise();
  }
  public async GetExpectedDateofProducts(request: ProductExpectedDatesRequest): Promise<ProductExpectedDate[]> {
    return await this.httpApi.post<ProductExpectedDate[]>(`priceandavailability/expectedDates`, request).toPromise();
  }
}

export interface CartTotalPriceWeight {
  totalPrice?: number;
  totalWeight?: number;
}

export interface PriceAndAvailabilityRequest {
  cartId?: null | string;
  deliverySequenceId?: null | number;
  force?: boolean;
  priceAndAvailabilitySource?: PriceAndAvailabilitySource;
  productCodes?: null | Array<string>;
}



export interface ProductExpectedDatesRequest {
  deliverySequenceId?: null | number;
  products?: null | Array<ProductExpectedDateRequestDto>;
  priceAndAvailabilitySource?: PriceAndAvailabilitySource;
  userBrowserDate: string;
  onlyGetFromCache: boolean;
}

export interface ProductExpectedDateRequestDto {
  productCode?: null | string;
  quantity?: number;
}
