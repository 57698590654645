import { Component, OnInit, OnDestroy, Input, ViewChild, EventEmitter, OnChanges, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { MibpLogger, LogService } from 'root/services';

@Component({


  selector: 'mibp-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {

  @Input() files: any[];
  @Input() maxNrOfFiles: any;
  @ViewChild('fileInput') fileInput;
  @Output() filesChanged = new EventEmitter();
  @Input() fileTypes = ".doc,.docx,.pdf";

  fileList = [];
  log: MibpLogger;
  onTouched = () => {};
  onChange = (val?: any[]) => {};

  constructor(logger: LogService) {
    this.log = logger.withPrefix('file-upload');
  }

  get value(): any {
    return this.fileList;
  }

  writeValue(filesFromForm: any[]): void {
    this.log.debug('Files writeValue:', filesFromForm);
    this.fileList = filesFromForm;
    // this.onChange(this.value)
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  async fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      if (fileInput.target.files && fileInput.target.files.length > 0 ) {
        const file: File = fileInput.target.files[0];
        const fileContents = await this.readfile(file);
        this.fileList.push({
          blobId: fileInput.target.files[0].blobId,
          title: fileInput.target.files[0].name,
          size: fileInput.target.files[0].size,
          data: fileContents.split(',')[1],
          displayName: fileInput.target.files[0].name
        });
        this.onChange(this.fileList);
      }
    }
    this.filesChanged.emit(this.fileList);
  }

  private async readfile(file: File): Promise<string> {
    const reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  public displayNameChangeEvent(event: any, index) {
    if (index > -1) {
      this.fileList[index].displayname = event.target.value;
      this.onChange(this.fileList);
    }
  }

  public addFile() {
    const fileInputElement: HTMLElement = this.fileInput.nativeElement;
    fileInputElement.click();
  }

  public removeFile = function (index) {
    if (index > -1) {
      this.fileList.splice(index, 1);
    }
    this.filesChanged.emit(this.fileList);
  };

}
