import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownInput } from 'root/components';
import { AccessGroupsApiController } from 'root/mibp-openapi-gen/controllers';
import { AccessGroup, PagedBrowseOrganizationRequest, ResolvedScope } from 'root/mibp-openapi-gen/models';
import { SignalR_AccessGroup, ApiService, LogService, MibpLogger } from 'root/services';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'mibp-organization-list-selector',
  templateUrl: './organization-list-selector.component.html',
  styleUrls: ['./organization-list-selector.component.scss']
})

export class OrganizationListSelectorComponent implements OnInit  {
  organizations: any[];
  log: MibpLogger;
  accessgroups: AccessGroup[];

  selectedScopes: ResolvedScope[];
  skip = 0;
  pageSize = 10;
  totalItems: number;
  isLoading = false;
  cachedResolvedScopesAsJson: string;
  cachedSkip: number;
  cachedPageSize: number;
  @Output() orgSelected = new EventEmitter<DropdownInput>();

  @Input() set scopes(value: ResolvedScope[]) {
    if (this.accessgroups?.length === 0 && value === undefined) {
      return;
    }
    this.selectedScopes = value;
    this.loadData();
  }

  constructor(private api: ApiService,
     logger: LogService,
    private accessGroupApi: AccessGroupsApiController) {
    this.log = logger.withPrefix('organization-list-selector');
  }

  pageChanged(page): void {
    if (page.isUserEvent) {
      this.skip = (page.currentPage - 1) * this.pageSize;
      this.loadData();
    }
  }


  ngOnInit(): void {
    this.log.info("load organizations");
  }

  selectedOrg (orgid: string, name: string): void {
    this.orgSelected.emit(<DropdownInput>{text: name, value: orgid});
  }

  loadData(): void {
    this.isLoading = true;
    let scopes: ResolvedScope[] = [<ResolvedScope>{  }];

    if (this.selectedScopes) {
      scopes = this.selectedScopes;
    }

    //If argument is the same as last call to API we can reuse the fetched data.
    if (this.cachedSkip === this.skip && this.cachedPageSize === this.pageSize && this.cachedResolvedScopesAsJson === JSON.stringify(scopes)) {
      this.isLoading = false;
      return;
    }

    this.cachedResolvedScopesAsJson = JSON.stringify(scopes);
    this.cachedSkip = this.skip;
    this.cachedPageSize = this.pageSize;

    const searchFor: PagedBrowseOrganizationRequest = <PagedBrowseOrganizationRequest>{ resolvedScopes: scopes, skip: this.skip, take: this.pageSize };

    firstValueFrom(this.accessGroupApi.browseOrganizations({body: searchFor})).then(result => {
      this.accessgroups = result.items;
      this.totalItems = result.totalCount;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    }).catch(error => {
      this.log.error('error browsning organizations', error);
    });
  }

}
