
<div class="product-availability" *ngIf="userCanSeeAvailability && isAuroraCompany">
  <mibp-loader type="spinner" *ngIf="isLoading"></mibp-loader>
  <div class="error-block" *ngIf="!isLoading && availabilityProduct?.errorCode">
    <div>
      <p *ngIf="availabilityProduct?.errorCode === 'ERRDAUR009'">
        <mibp-resource-string key="Aurora_Error_ERRDAUR009"></mibp-resource-string>
      </p>
      <p *ngIf="availabilityProduct?.errorCode != 'ERRDAUR009'"><mibp-resource-string key="Carts_ErrorGettingAvailability"></mibp-resource-string></p>
    </div>
  </div>

  <div *ngIf="!isLoading" #ava>
    <span *ngIf="availabilityProduct.expectedDeliveryDate">
      <mibp-expecteddeliverydate [date]="availabilityProduct.expectedDeliveryDate" [productExpectedDate]="availabilityProduct" [isRockToolItem]="isRockToolItem" [isQuotation]="isQuotation" [stockroomEnabled]="stockroomEnabled"></mibp-expecteddeliverydate>
    </span>
    <span *ngIf="!availabilityProduct?.errorCode && availabilityProduct && availabilityProduct.expectedDeliveryDate == null" class="hide-print">
      <a href="javascript:void(0)" (click)="clickedRequestExpectedDateLink()" ><mibp-resource-string key="Global_Carts_RequestDeliveryDate"></mibp-resource-string></a>
    </span>
    <span class="value" *ngIf="!availabilityProduct">
      <mibp-loader type="spinner"></mibp-loader>
    </span>
  </div>
  <mibp-support-menu (action)="onSupportMenuAction($event)" [items]="[{textOverride: 'Show Quote Tool data (productExpectedDate)', name: 'quote-tool-data'}]"></mibp-support-menu>
</div>
<div *ngIf="userCanSeeAvailability && !isAuroraCompany">
  <div><mibp-button type="button" #btn [style]="btn.Enum.Styles.Fill" (click)="clickedRequestExpectedDateLink()"><mibp-resource-string key="Global_ContactUs"></mibp-resource-string></mibp-button></div>
</div>
