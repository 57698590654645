import { MibpValidationMessagesComponent } from "./validation-messages.component";
import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, NgForm } from "@angular/forms";

@Component({
  selector: 'mibp-validation-message',
  styles: [`
      .validation-message {display: none; }
    .has-error {
      display: block;
    }
  `],
  template: `
    <div class="validation-message" [class.has-error]="(isFormSubmitted || touchedOrDirty) && hasThisError && formControl?.invalid"
    [attr.data-validation-message-control]="formControlName">
       <ng-content></ng-content>
    </div>
  `
})
export class MibpValidationMessageComponent implements OnInit {

  @Input() forError: string;

  formControl: UntypedFormControl;
  hasThisError = false;
  isFormSubmitted: boolean;
  formControlName: string;
  form: NgForm;
  touchedOrDirty: boolean;

  constructor(private parent: MibpValidationMessagesComponent) {}

  ngOnInit() {
    this.parent.addMessage(this);
  }

  public updateControlErrors(form: NgForm, controlName: string, errors: any, isFormSubmitted: boolean) {
    this.isFormSubmitted = isFormSubmitted;
    this.formControlName = controlName;
    this.form = form;

    this.hasThisError = errors && errors[this.forError];
    this.formControl = <any>(form && form.controls[controlName] ? form.controls[controlName] : null);
    this.touchedOrDirty = this.formControl && (this.formControl.dirty || this.formControl.touched);


  }

}
