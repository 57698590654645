
<div style="padding: 16px;">

  <nav class="my-menu-mobile__navigation" role="navigation" data-fetch-children-url="/api/navigation/">
    <ng-container>
      <ul>
        <li
          [attr.data-has-children]="true"
          *ngFor="let item of filteredItems(items)"
          [attr.data-expanded]="item.expanded ? '' : null"
          class="my-menu-mobile__nav-item">
          <a class=" my-menu-mobile__nav-item-link" [class.my-menu-mobile__nav-item-link--foldout]="anyVisibleChildren(item) && item.calculated.fullPath.indexOf('bulletins') == -1" href="javascript:void(0)" (click)="mobileMenuClick(item, 0)"  >
            <mibp-resource-string [key]="item.resourceKey"></mibp-resource-string>
            <span *ngIf="item.text">{{item.text}}</span>
          </a>
          <ul *ngIf="item.expanded">
            <li *ngFor="let child of filteredItems(item.children)" class="noindex my-menu-mobile__nav-item">
              <a class="noindex my-menu-mobile__nav-item-link" href="javascript:void(0)" (click)="mobileMenuClick(child, 1)">
                <mibp-resource-string *ngIf="child.resourceKey" [key]="child.resourceKey"></mibp-resource-string>
                <span *ngIf="child.text">{{ child.text }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </ng-container>
  </nav>

</div>

