import { Injectable } from '@angular/core';
import { ApiService, SignalR_SelectProfileEvent } from 'root/services/mibp-api-services';
import { Subject, Observable, Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { MibpLogger, LogService } from '../logservice';

@Injectable({
  providedIn: 'root'
})
export class ProfilePickerService {

  private showProfilePickerSubject = new Subject<SignalR_SelectProfileEvent>();
  private selectProfileSubscription: Subscription;
  private log: MibpLogger;

  constructor(private api: ApiService, logger: LogService) {
    this.log = logger.withPrefix('profile-picker-serice');
    this.log.debug("ProfilePickerService constructor()");
  }

  initService() {
    this.selectProfileSubscription = this.api.EventHub.SelectProfile.subscribe((selectProfile: SignalR_SelectProfileEvent) => {
      this.showProfilePickerSubject.next(selectProfile);
    });
  }

  get showProfilePicker$(): Observable<SignalR_SelectProfileEvent> {
    return this.showProfilePickerSubject.asObservable();
  }

  changeUser(userId: Guid): Promise<boolean>  {
    return this.api.Connection.ChangeUser(userId).toPromise();
  }
}
