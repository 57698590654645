/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrderViewModelSearchResponse } from '../../models/order-view-model-search-response';
import { PagedUserRefinementOptions } from '../../models/paged-user-refinement-options';

export interface ListMachineOrders$Params {
  sortOrder?: string;
      body?: PagedUserRefinementOptions
}

export function listMachineOrders(http: HttpClient, rootUrl: string, params?: ListMachineOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderViewModelSearchResponse>> {
  const rb = new RequestBuilder(rootUrl, listMachineOrders.PATH, 'post');
  if (params) {
    rb.query('sortOrder', params.sortOrder, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OrderViewModelSearchResponse>;
    })
  );
}

listMachineOrders.PATH = '/orders/listmachineorders';
