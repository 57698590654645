import { DropdownInput, DropdownArgs, DropdownData } from "./dropdown.interface";
import { Observable, of } from "rxjs";

/**
 *
 */
export class DropDownDataSource {

  private dropdownItems: DropdownInput[];

  constructor(items: DropdownInput[]) {
    this.dropdownItems = items;
  }

  public get items() {
    return this.dropdownItems;
  }

  public filter(args: DropdownArgs): Observable<DropdownData> {

    let matchingItems: DropdownInput[];

    if (args.query) {
      matchingItems = this.dropdownItems.filter(
        item => item.text?.toLowerCase().indexOf(args.query.toLowerCase()) !== -1
      );
    } else {
      matchingItems = this.dropdownItems;
    }



    return of({
      hasMoreResults: args.index + args.take < this.dropdownItems.length,
      items: matchingItems.slice(args.index, args.index + args.take),
      totalCount: this.dropdownItems.length
    } as DropdownData);

  }

}
