import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonColors } from 'root/components/button/button.enum';
import { DropdownInput } from 'root/components/dropdown';
import { SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';
import { CaseType, ContactUsSupportCaseRequest } from 'root/mibp-openapi-gen/models';
import { FormValidationService, FormattingService, LoaderService, LocalizationService } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MibpEmailValidator } from 'root/validators/email.validator';
import { requireCheckboxesToBeCheckedValidator } from 'root/validators/requireCheckboxesToBeChecked';
import { Subscription, firstValueFrom } from 'rxjs';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';
import { Guid } from 'guid-typescript';



export interface ProductUpgradeRequestQuoteData{
  topic: string;
  upgradeName: string;
  productArea: string;
  productModel: string;
  productSerial: string;
  mainImageId: Guid;
  shortDescription: string;
  longDescription: string;
}

@Component({
  selector: 'mibp-contact-us-request-quote-form',
  templateUrl: './contact-us-request-quote-form.component.html',
  styleUrls: ['./contact-us-request-quote-form.component.scss']
})
export class ContactUsRequestQuoteFormComponent implements OnInit, OnDestroy {
  protected contactUsRequestQuoteForm: UntypedFormGroup;
  buttonColors = ButtonColors;
  protected formSubmitted: boolean;
  protected isEmailSelected: boolean;
  protected isPhoneNumberSelected: boolean;
  private formValueChangeSub: Subscription;
  private description: string;

  @Input() showHeader = true;
  @Input() requestQuoteData : ProductUpgradeRequestQuoteData = null;
  @Output() successfullySubmitted = new EventEmitter<boolean>();

  private stopUsingResources: () => void;

  constructor(private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private mibpSession: MibpSessionService,
    private formattingService: FormattingService,
    private supportCaseController: SupportCaseApiController,
    private loader: LoaderService,
    private localizationService: LocalizationService){

  }
  ngOnDestroy(): void {
    if(this.stopUsingResources){
      this.stopUsingResources();
    }
    this.formValueChangeSub?.unsubscribe();
  }

  ngOnInit(): void {

    this.contactUsRequestQuoteForm = this.fb.group({
      topicName: [{value : this.requestQuoteData?.topic, disabled: true }, [Validators.required, MibpAllowedCharactersValidator()]],
      name: [{value : this.mibpSession.current.user.firstName + ' ' + this.mibpSession.current.user.lastName, disabled: true }, [Validators.required, MibpAllowedCharactersValidator()]],
      email: [{value : this.mibpSession.current.user.email, disabled: true }, MibpEmailValidator(this.formattingService)],
      phonenumber: [{value : this.mibpSession.current.user.phone, disabled: true }, MibpAllowedCharactersValidator()],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false)
      }, { validators: requireCheckboxesToBeCheckedValidator(1)}),
      upgradeName: [{value : this.requestQuoteData?.upgradeName, disabled: true }, [Validators.required, MibpAllowedCharactersValidator()]],
      productArea: [{value : this.requestQuoteData?.productArea, disabled: true }, [Validators.required, MibpAllowedCharactersValidator()]],
      productModel: [{value : this.requestQuoteData?.productModel, disabled: true }, [Validators.required, MibpAllowedCharactersValidator()]],
      productSerial: [{value : this.requestQuoteData?.productSerial, disabled: true }, [Validators.required, MibpAllowedCharactersValidator()]],
      shortDescription: [{value : this.requestQuoteData?.shortDescription, disabled: true }, MibpAllowedCharactersValidator()],
      longDescription: [{value : this.requestQuoteData?.longDescription, disabled: true }, MibpAllowedCharactersValidator()],
      mainImageId: [this.requestQuoteData?.mainImageId],
      comments: [null, [MibpAllowedCharactersValidator()]]
    });

    this.initForm();

   
  }

  initForm(){
    this.formValueChangeSub = this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.valueChanges.subscribe(() => {
      this.isEmailSelected = this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.get('byEmail').value;
      this.isPhoneNumberSelected = this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.get('byPhone').value;
      this.isEmailSelected ? this.addRequiredValidators('email') : this.removeRequiredValidators('email');
      this.isPhoneNumberSelected ? this.addRequiredValidators('phonenumber') : this.removeRequiredValidators('phonenumber');
    });

  }

  private addRequiredValidators(field: string){
    this.contactUsRequestQuoteForm.get(field).addValidators([Validators.required]);
    this.contactUsRequestQuoteForm.get(field).updateValueAndValidity();
  }

  private removeRequiredValidators(field: string){
    this.contactUsRequestQuoteForm.get(field).removeValidators(Validators.required);
    this.contactUsRequestQuoteForm.get(field).updateValueAndValidity();
  }

  async submit(){
    this.formSubmitted = true;
    if (this.contactUsRequestQuoteForm.invalid) {
      this.contactUsRequestQuoteForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactUsRequestQuoteForm, this.element);
      return;
    }
    // const contactUsSupportCaseRequest = <ContactUsSupportCaseRequest>{
    //   caseType: CaseType.RequestQuote,
    //   categoryRequestQuote: this.contactUsRequestQuoteForm.get('topicName').value.text,
    //   suppliedFullName: this.contactUsRequestQuoteForm.get('name').value,
    //   suppliedEmail: this.contactUsRequestQuoteForm.get('email').value,
    //   suppliedPhone: this.contactUsRequestQuoteForm.get('phonenumber').value,
    //   prefferedEmailContact: this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.get('byEmail').value,
    //   prefferedPhoneContact: this.contactUsRequestQuoteForm.controls.contactPreferenceGroup.get('byPhone').value,
    //   description: this.contactUsRequestQuoteForm.get('comments').value
    // };

    // try {
    //   this.loader.showFullScreenLoader();
    //   await firstValueFrom(this.supportCaseController.submitContactUsCase({body: contactUsSupportCaseRequest}));
    //   this.loader.hideFullScreenLoader();
    //   this.successfullySubmitted.emit(true);
    // }
    // catch(error){
    //   this.loader.hideFullScreenLoader();
    //   this.successfullySubmitted.emit(false);
    // }
  }
}
