/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getResourceValueForEdit } from '../fn/help/get-resource-value-for-edit';
import { GetResourceValueForEdit$Params } from '../fn/help/get-resource-value-for-edit';
import { getResourceValueForEdit$Plain } from '../fn/help/get-resource-value-for-edit-plain';
import { GetResourceValueForEdit$Plain$Params } from '../fn/help/get-resource-value-for-edit-plain';
import { getResourceValueForView } from '../fn/help/get-resource-value-for-view';
import { GetResourceValueForView$Params } from '../fn/help/get-resource-value-for-view';
import { getResourceValueForView$Plain } from '../fn/help/get-resource-value-for-view-plain';
import { GetResourceValueForView$Plain$Params } from '../fn/help/get-resource-value-for-view-plain';
import { ResourceValue } from '../models/resource-value';
import { saveHelpPage } from '../fn/help/save-help-page';
import { SaveHelpPage$Params } from '../fn/help/save-help-page';

@Injectable({ providedIn: 'root' })
export class HelpApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `helpControllerSaveHelpPagerequest()` */
  static readonly HelpControllerSaveHelpPagerequestPath = '/help/savehelppage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveHelpPage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveHelpPage$Response(params?: SaveHelpPage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveHelpPage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveHelpPage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveHelpPage(params?: SaveHelpPage$Params, context?: HttpContext): Observable<void> {
    return this.saveHelpPage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `helpControllerGetResourceValueForEditlanguageCodeKey()` */
  static readonly HelpControllerGetResourceValueForEditlanguageCodeKeyPath = '/help/{languageCode}/{key}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceValueForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceValueForEdit$Plain$Response(params: GetResourceValueForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceValue>> {
    return getResourceValueForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResourceValueForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceValueForEdit$Plain(params: GetResourceValueForEdit$Plain$Params, context?: HttpContext): Observable<ResourceValue> {
    return this.getResourceValueForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceValue>): ResourceValue => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceValueForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceValueForEdit$Response(params: GetResourceValueForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceValue>> {
    return getResourceValueForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResourceValueForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceValueForEdit(params: GetResourceValueForEdit$Params, context?: HttpContext): Observable<ResourceValue> {
    return this.getResourceValueForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceValue>): ResourceValue => r.body)
    );
  }

  /** Path part for operation `helpControllerGetResourceValueForViewlanguageCodeKeySkipCache()` */
  static readonly HelpControllerGetResourceValueForViewlanguageCodeKeySkipCachePath = '/help/{languageCode}/{key}/view';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceValueForView$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceValueForView$Plain$Response(params: GetResourceValueForView$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getResourceValueForView$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResourceValueForView$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceValueForView$Plain(params: GetResourceValueForView$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getResourceValueForView$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceValueForView()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceValueForView$Response(params: GetResourceValueForView$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getResourceValueForView(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResourceValueForView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceValueForView(params: GetResourceValueForView$Params, context?: HttpContext): Observable<string> {
    return this.getResourceValueForView$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
