
/* This file was generated from build/update_features.js
 *
 * DO NOT EDIT MANUALLY
 *
 */
export enum MySandvikFeatures {
  GeneralActAs = 'general_act_as',

  GeneralMyFeaturesTile = 'general_my_features_tile',

  GeneralSmartmate = 'general_smartmate',

  GeneralMyrocktools = 'general_myrocktools',

  GeneralRemotesupport = 'general_remotesupport',

  GeneralViewNewsarticles = 'general_view_newsarticles',

  ShopProductAvailability = 'shop_product_availability',

  ShopProductAvailabilityStockroomdetail = 'shop_product_availability_stockroomdetail',

  ShopProductExpecteddeliverydate = 'shop_product_expecteddeliverydate',

  ShopProductExpecteddeliverydateOndemand = 'shop_product_expecteddeliverydate_ondemand',

  ShopCart = 'shop_cart',

  ShopPlaceOrderMibppayment = 'shop_place_order_mibppayment',

  ShopPlaceOrder = 'shop_place_order',

  ShopRequestQuotation = 'shop_request_quotation',

  ShopExportCart = 'shop_export_cart',

  ShopPersistentCart = 'shop_persistent_cart',

  ShopShowEquipmentpickerForCriticalOrder = 'shop_show_equipmentpicker_for_critical_order',

  ShopShowProductPrices = 'shop_show_product_prices',

  ShopShowBasePrice = 'shop_show_base_price',

  ShopViewCreditLimit = 'shop_view_credit_limit',

  ShopOrdersAndQuotations = 'shop_orders_and_quotations',

  ShopViewInvoices = 'shop_view_invoices',

  ShopTemplates = 'shop_templates',

  ShopShareUnshareTemplates = 'shop_share_unshare_templates',

  MyfleetElectronicManuals = 'myfleet_electronic_manuals',

  ShopPartsManuals = 'shop_parts_manuals',

  ShopPromotions = 'shop_promotions',

  ShopAddkitproducts = 'shop_addKitProducts',

  ShopShowkitproductsdiscount = 'shop_showKitProductsDiscount',

  MyfleetElectronicManualsNew = 'myfleet_electronic_manuals_new',

  AdminManageInternalUsers = 'admin_manage_internal_users',

  AdminManageExternalUsers = 'admin_manage_external_users',

  AdminManageRoles = 'admin_manage_roles',

  AdminManageOrganizations = 'admin_manage_organizations',

  AdminManageCustomers = 'admin_manage_customers',

  AdminManageRestrictedItems = 'admin_manage_restricted_items',

  AdminManageUpsell = 'admin_manage_upsell',

  AdminManageCrossSell = 'admin_manage_cross_sell',

  AdminManagePromotions = 'admin_manage_promotions',

  AdminManageMessageCenter = 'admin_manage_message_center',

  AdminApproveNewUsers = 'admin_approve_new_users',

  AdminPunchout = 'admin_punchout',

  AdminManageTranslations = 'admin_manage_translations',

  AdminManageChangelog = 'admin_manage_changelog',

  AdminViewInternalReporting = 'admin_view_internal_reporting',

  AdminManageB2c = 'admin_manage_b2c',

  AdminManageB2cSica = 'admin_manage_b2c_sica',

  AdminManageB2cDigitalservices = 'admin_manage_b2c_digitalservices',

  BulletinsView = 'bulletins_view',

  BulletinsManage = 'bulletins_manage',

  BulletinsViewReports = 'bulletins_view_reports',

  MyfleetViewMachineOrders = 'myfleet_view_machine_orders',

  MyfleetViewMachines = 'myfleet_view_machines',

  MyfleetInspectionReports = 'myfleet_inspection_reports',

  MyfleetExtendedWarrantyServiceRecords = 'myfleet_extended_warranty_service_records',

  MyfleetUpdateMachine = 'myfleet_update_machine',

  MyfleetManageInspectionReports = 'myfleet_manage_inspection_reports',

  MyfleetManageExtendedWarrantyServiceRecords = 'myfleet_manage_extended_warranty_service_records',

  MyfleetMaintenancePlanner = 'myfleet_maintenance_planner',

  MyfleetMaintenancePlannerManage = 'myfleet_maintenance_planner_manage',

  MyfleetDownloadManuals = 'myfleet_download_manuals',

  MyfleetOperationalReadinessReports = 'myfleet_operational_readiness_reports',

  MyfleetManageOperationalReadinessReports = 'myfleet_manage_operational_readiness_reports',

  Myfleet3dmodels = 'myfleet_3dmodels',

  WarrantyportalView = 'warrantyportal_view',

  WarrantyportalWarrantyapplication = 'warrantyportal_warrantyapplication',

  WarrantyportalWarrantyregistration = 'warrantyportal_warrantyregistration',

  AdminManageHelpPage = 'admin_manage_help_page',

  AdminManageTermsAndConditionsPage = 'admin_manage_terms_and_conditions_page',

  AdminManageProductEnrichment = 'admin_manage_product_enrichment',

  GeneralSupportLiveChat = 'general_support_live_chat',

  AdminManageNewsarticles = 'admin_manage_newsarticles',

  GeneralViewSupportcases = 'general_view_supportcases',

  ContactUsForm = 'contact_us_form',

  ContactUsFormItemEnquiry = 'contact_us_form_item_enquiry',

  ContactUsFormReturnsAndOrder = 'contact_us_form_returns_and_order',

  ContactUsFormShippingAndTracking = 'contact_us_form_shipping_and_tracking',

  ContactUsFormTechnicalRequest = 'contact_us_form_technical_request',

  ContactUsFormTradingAccountQuery = 'contact_us_form_trading_account_query',

  ContactUsFormWebsiteFeedback = 'contact_us_form_website_feedback',

  AdminManageMachineUpgrades = 'admin_manage_machine_upgrades',

  MyfleetViewMachineUpgrades = 'myfleet_view_machine_upgrades',

  AdminManageProductNotifications = 'admin_manage_product_notifications'

}
