import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonColors } from 'root/components/button/button.enum';
import { DropdownInput } from 'root/components/dropdown';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';
import { SupportCaseAttachmentDto, CaseType, ContactUsSupportCaseRequest} from 'root/mibp-openapi-gen/models';
import { FormValidationService, FormattingService, LoaderService, LocalizationService, NoticebarService, GlobalConfigService, ToastService } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MibpEmailValidator } from 'root/validators/email.validator';
import { requireCheckboxesToBeCheckedValidator } from 'root/validators/requireCheckboxesToBeChecked';
import { Subscription, firstValueFrom } from 'rxjs';
import { SupportCaseItemEnquiryTypeResourceString, SupportCaseItemEnquiryTypeValue } from './contact-us-returns-and-orders-form.interface';

import { Configuration } from '@azure/msal-browser';
import { ToastType } from 'root/services/toast-service/toast.enum';
import { maxQuantityLimitValidator } from 'root/validators/MaxQuantityLimit.validator';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';

@Component({
  selector: 'mibp-contact-us-returns-and-orders-form',
  templateUrl: './contact-us-returns-and-orders-form.component.html',
  styleUrls: ['./contact-us-returns-and-orders-form.component.scss']
})
export class ContactUsReturnsAndOrderFormComponent implements OnInit, OnDestroy {

  @Output() successfullySubmitted = new EventEmitter<boolean>();
  protected formSubmitted = false;
  private enquiryResourceStringKeys = [ SupportCaseItemEnquiryTypeResourceString.ShortSupply,
    SupportCaseItemEnquiryTypeResourceString.OverSupply,
    SupportCaseItemEnquiryTypeResourceString.IncorrectSupply,
    SupportCaseItemEnquiryTypeResourceString.Damage,
    SupportCaseItemEnquiryTypeResourceString.ReturnItem,
    SupportCaseItemEnquiryTypeResourceString.CancelOrder];

  private enquiryValues = [ SupportCaseItemEnquiryTypeValue.ShortSupply,
    SupportCaseItemEnquiryTypeValue.OverSupply,
    SupportCaseItemEnquiryTypeValue.IncorrectSupply,
    SupportCaseItemEnquiryTypeValue.Damage,
    SupportCaseItemEnquiryTypeValue.ReturnItem,
    SupportCaseItemEnquiryTypeValue.CancelOrder];

  protected contactUsReturnAndOrderForm: UntypedFormGroup;
  protected enquiryTypes: DropdownInput[] = [];
  protected buttonColors = ButtonColors;


  protected isQuantityRequired = true;
  protected isPartNumberRequired = true;
  protected isEmailSelected: boolean;
  protected isPhoneNumberSelected: boolean;

  @Input() showHeader = true;
  private stopUsingResources: () => void;
  private formValueSubscription: Subscription;

  // Attachements
  protected fileNames: string[] = [];
  protected attachmentError = false;
  protected isUploadingAttachments = false;
  protected inputFields: number[] = [0];
  private attachedImages: SupportCaseAttachmentDto[] = [];
  private caseId: string;
  @ViewChildren('uploadInputElement') uploadInputElements: QueryList<ElementRef>;


  constructor(private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private mibpSession: MibpSessionService,
    private formattingService: FormattingService,
    private supportCaseController: SupportCaseApiController,
    private loader: LoaderService,
    private noticeService: NoticebarService,
    private localizationService: LocalizationService,
    private globalConfigService: GlobalConfigService,
    private toastService: ToastService){

  }
  ngOnDestroy(): void {
    if(this.stopUsingResources){
      this.stopUsingResources();
    }
    this.formValueSubscription?.unsubscribe();
  }

  setResourceStringSubscription(resourceStringKeys: string[], onComplete: (translatedStrings: string[]) => void) {
    this.stopUsingResources = this.localizationService.using(
      resourceStringKeys,
      resourceStrings => {
        onComplete(resourceStrings);
      }
    );
  }


  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.contactUsReturnAndOrderForm = this.fb.group({
      name: [this.mibpSession.current.user.firstName + ' ' + this.mibpSession.current.user.lastName, [Validators.required, MibpAllowedCharactersValidator()]],
      email: [this.mibpSession.current.user.email, MibpEmailValidator(this.formattingService)],
      phonenumber: [this.mibpSession.current.user.phone, MibpAllowedCharactersValidator()],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false)
      }, { validators: requireCheckboxesToBeCheckedValidator(1)}),
      enquiryType: [null, Validators.required],
      purchaseOrderNumber: [null, [Validators.required, MibpAllowedCharactersValidator()]],
      partNumber: [null, [Validators.required, MibpAllowedCharactersValidator()]],
      quantity: [null, {validators: [Validators.required, maxQuantityLimitValidator(99)]}],
      description: ['', MibpAllowedCharactersValidator()]
    });

    this.setResourceStringSubscription(this.enquiryResourceStringKeys, resourceStrings => {
      const types = [];
      resourceStrings.forEach((rs, index) => {
        types.push(<DropdownInput>{ value: this.enquiryValues[index], text: rs });
      });
      this.enquiryTypes = types;
    });

    this.formValueSubscription =  this.contactUsReturnAndOrderForm.controls.contactPreferenceGroup.valueChanges.subscribe(() => {
      this.isEmailSelected = this.contactUsReturnAndOrderForm.controls.contactPreferenceGroup.get('byEmail').value;
      this.isPhoneNumberSelected = this.contactUsReturnAndOrderForm.controls.contactPreferenceGroup.get('byPhone').value;
      this.isEmailSelected ? this.addRequiredValidators('email') : this.removeRequiredValidators('email');
      this.isPhoneNumberSelected ? this.addRequiredValidators('phonenumber') : this.removeRequiredValidators('phonenumber');
    });
  }
  enquiryTypeChanged(value: DropdownInput){
    if(value){
      this.enquiryTypeChangedHandleValidators(value.value);
    }
  }

  enquiryTypeChangedHandleValidators(enquiryType: SupportCaseItemEnquiryTypeValue | string){
    if(enquiryType === SupportCaseItemEnquiryTypeValue.CancelOrder){
      this.removeRequiredValidators('quantity');
      this.removeRequiredValidators('partNumber');

      this.isQuantityRequired = false;
      this.isPartNumberRequired = false;
    }
    else{
      this.addRequiredValidators('quantity');
      this.addRequiredValidators('partNumber');

      this.isQuantityRequired = true;
      this.isPartNumberRequired = true;
    }
  }

  async submit(){
    this.formSubmitted = true;
    if (this.contactUsReturnAndOrderForm.invalid) {
      this.contactUsReturnAndOrderForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactUsReturnAndOrderForm, this.element);
      return;
    }
    const contactUsSupportCaseRequest = <ContactUsSupportCaseRequest>{
      caseType: CaseType.ReturnsAndOrder,
      categoryReturnsAndOrder: this.contactUsReturnAndOrderForm.get('enquiryType').value.value,
      suppliedFullName: this.contactUsReturnAndOrderForm.get('name').value,
      suppliedEmail: this.contactUsReturnAndOrderForm.get('email').value,
      suppliedPhone: this.contactUsReturnAndOrderForm.get('phonenumber').value,
      orderNumber: this.contactUsReturnAndOrderForm.get('purchaseOrderNumber').value,
      productCode: this.contactUsReturnAndOrderForm.get('partNumber').value,
      quantity: this.contactUsReturnAndOrderForm.get('quantity').value,
      prefferedEmailContact: this.contactUsReturnAndOrderForm.controls.contactPreferenceGroup.get('byEmail').value,
      prefferedPhoneContact: this.contactUsReturnAndOrderForm.controls.contactPreferenceGroup.get('byPhone').value,
      description: this.contactUsReturnAndOrderForm.get('description').value
    };

    try {
      this.loader.showFullScreenLoader();
      this.caseId = await firstValueFrom(this.supportCaseController.submitContactUsCase({ body: contactUsSupportCaseRequest }));
      if (this.caseId) {

        if(this.attachedImages.length>0)
        {
          await this.uploadAttachments();
          if (this.attachmentError) {
            this.noticeService.show('Contact_Us_CreatedWithAttachmentError', NoticeType.Warning);
            return;
          }
        }
        this.successfullySubmitted.emit(true);

      }
      this.loader.hideFullScreenLoader();

    }
    catch (error) {
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(false);
    }
  }


  private addRequiredValidators(field: string){
    this.contactUsReturnAndOrderForm.get(field).addValidators([Validators.required]);
    this.contactUsReturnAndOrderForm.get(field).updateValueAndValidity();
  }

  private removeRequiredValidators(field: string){
    this.contactUsReturnAndOrderForm.get(field).removeValidators(Validators.required);
    this.contactUsReturnAndOrderForm.get(field).updateValueAndValidity();
  }



  // Handle file upload
  private async readfile(file: File): Promise<string> {
    const reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.onload = (e: ProgressEvent<FileReader>) => {
        resolve(e.target.result as string);
      };
      reader.readAsDataURL(file);
    });
  }

  addFiles(): void {
    this.uploadInputElements.last.nativeElement.click();
    this.addFileInput();
  }
  addFileInput(): void {
    this.inputFields.push(0);

  }
  fileChange(e: Event): void {
    const input = e.target as HTMLInputElement;
    const validFileExtensions = this.globalConfigService.supportedImageFileExtensions.toLowerCase();
    let file;
    let invalidFileSelected = false;
    for (let i = 0; i < input.files.length; i++) {
      file = input.files.item(i);
      const fileExtension = file.name.split('.')[file.name.split('.').length - 1];
      if(validFileExtensions.includes(fileExtension.toLowerCase())){
        this.addSingleFile(file);
      }
      else if(!invalidFileSelected){
        invalidFileSelected = true;
      }
    }
    if(invalidFileSelected){
      this.toastService.showWithTitle("Global_Invalid_ImageFileType_Message", "Global_Invalid_FileType_Title",{
        type: ToastType.Error
      });
    }

  }

  private async addSingleFile(file: File): Promise<void> {
    const fileContents = await this.readfile(file);
      this.fileNames.push(file.name);

    this.attachedImages.push(<SupportCaseAttachmentDto>{
      title: file.name,
      pathOnClient: file.name,
      description: "",
      fileCategory: "Pictures of Defect",
      body: fileContents

    });
  }

  async uploadAttachments() {
    try {
      this.isUploadingAttachments = true;
      await firstValueFrom(this.supportCaseController.uploadAttachments({ caseId: this.caseId, body: this.attachedImages }));
      this.loader.hideFullScreenLoader();
      this.attachmentError = false;
      this.isUploadingAttachments = false;
    } catch (error) {
      this.attachmentError = true;
      this.isUploadingAttachments = false;
      this.loader.hideFullScreenLoader();
    }
  }
  async retryUploadAttachments() {
    try {
      this.isUploadingAttachments = true;
      await firstValueFrom(this.supportCaseController.uploadAttachments({ caseId: this.caseId, body: this.attachedImages }));
      this.isUploadingAttachments = false;
      this.attachmentError = false;
    } catch (error) {
      this.attachmentError = true;
      this.isUploadingAttachments = false;
      this.noticeService.show('ContactUs_UploadAttachmentFailed', NoticeType.Error);

    }
  }

  deleteAttachment(filename: string) {
    this.fileNames.splice(this.fileNames.indexOf(filename),1);
    this.attachedImages.splice(this.fileNames.indexOf(filename),1);
  }

}
