import { Component, OnInit } from "@angular/core";
import { ContactUsService, LocalizationService, AuthService, PermissionPolicy, PermissionService } from 'root/services';
import { ActivatedRoute, Router } from "@angular/router";
import { MibpSessionService } from "root/services/mibp-session/mibp-session.service";

@Component({
  styles: [`
    .url {
      color: #ff6a00;
    }
  `],
  template: `
  <h1 class="my-header my-header--nomargin">401</h1>
  <h2 class="my-header"><mibp-resource-string key="Error_UnauthorizedPage_Subtitle"></mibp-resource-string></h2>
  <div class="my-body">
   <p *ngIf="url" class="url">{{ url }}</p>
   <p><mibp-resource-string key="Error_UnauthorizedPage_Message"></mibp-resource-string></p>
  </div>

  <mibp-button *ngIf="loggedIn && actingAs" resourceStringKey="Global_ContactUs" (click)="contactUs()"></mibp-button>

  `})
export class ErrorUnauthorizedPageComponent implements OnInit {

  url: string;
  loggedIn: boolean;
  actingAs: boolean;

  constructor(private contactUsService: ContactUsService,
    private localizationService: LocalizationService,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private router: Router,
    private sessionService: MibpSessionService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    if (!this.authService.getAccessToken()) {
      this.url = "";
    } else {
      this.url = this.route.snapshot.queryParams.url.toString();
    }

    const failedPermission = this.route.snapshot.queryParams.p;
    const failedUrl = this.route.snapshot.queryParams.url;
    this.loggedIn = this.sessionService.isLoggedIn();
    this.actingAs = !!this.sessionService.current?.activeDeliverySequence;

    // If user is logged in and we have permission policy in url, then redirect user to
    // original page if permissions are ok
    if (this.loggedIn && failedPermission) {
      try {
        const permissionPolicy = JSON.parse(atob(failedPermission)) as PermissionPolicy;
        if (this.permissionService.test(permissionPolicy)) {
          this.router.navigateByUrl(failedUrl);
        }
      } catch (e) {
        // Ignore errors
      }
    }


  }
  contactUs(): void {

    let message = this.localizationService.get('Error_UnauthorizedPage_Subtitle');
    if (this.url) {
      message += " - " + this.url;
    }

    this.contactUsService.openContactUs(null, message);
  }
}

