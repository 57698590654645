
import Quill from 'quill';
// eslint-disable-next-line @typescript-eslint/naming-convention
const Parchment = Quill.import('parchment');


/**
 * Makes sure that our elements with custom classes are not removed when editor loads
 */
export function registerQuillCustomMibpClass() {
  // attribute name 'class' - prefix 'ql-mibp'
  const customClass = new Parchment.Attributor.Class('class', 'ql-mibp', {
    scope: Parchment.Scope.BLOCK,
    tag: 'div',
    whitelist: [
      'separator',
      'exit-section',     // Will allow for <prefix>-exit-section class
      'section',
      'section-separator' // Will allow for <prefix>-section-separator class
    ]
  });
  Quill.register( 'attributors/class/custom', customClass, true);
  Quill.register( 'formats/custom', customClass, true);
}
