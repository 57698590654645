<div>
  <h3 *ngIf="showHeader" class="my-header"><mibp-resource-string key="SupportCases_Type_TechnicalRequest"></mibp-resource-string></h3>
   <form [formGroup]="contactUsTechnicalRequestForm" (ngSubmit)="submit()">
    <div class="columns">
      <div class="column is-half">
        <div class="forms__row">
          <label><mibp-resource-string key="ContactUs_Name"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="50"  formControlName="name" >
          <mibp-validation-text [formGroup]="contactUsTechnicalRequestForm" [alwaysVisible]="formSubmitted" controlName="name"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column column--pr">
        <div class="forms__row">
          <label><mibp-resource-string key="ContactUs_Email_Field"></mibp-resource-string><span *ngIf="isEmailSelected" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="50"  formControlName="email" >
          <mibp-validation-text [formGroup]="contactUsTechnicalRequestForm" [alwaysVisible]="formSubmitted" controlName="email"></mibp-validation-text>
        </div>
      </div>
      <div class="column">
        <div class="forms__row">
          <label><mibp-resource-string key="ContactUs_Phone_Field"></mibp-resource-string><span *ngIf="isPhoneNumberSelected" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="50"  formControlName="phonenumber" >
          <mibp-validation-text [formGroup]="contactUsTechnicalRequestForm" [alwaysVisible]="formSubmitted" controlName="phonenumber"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <div class="forms__row" formGroupName="contactPreferenceGroup">
          <label><mibp-resource-string key="ContactUs_PreferedContactMethod"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <div class="checkbox">
            <input formControlName="byEmail" id="byEmail" name="byEmail" type="checkbox">
            <label for="byEmail"><mibp-resource-string key="ContactUs_Email"></mibp-resource-string></label>
          </div>
          <div class="checkbox">
            <input formControlName="byPhone" id="byPhone" name="byPhone" type="checkbox">
            <label for="byPhone"><mibp-resource-string key="ContactUs_Phone"></mibp-resource-string></label>
          </div>
          <mibp-validation-text [formGroup]="contactUsTechnicalRequestForm" [alwaysVisible]="formSubmitted" controlName="contactPreferenceGroup"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div  class="forms__row">
          <label><mibp-resource-string key="SupportCasesTechnicalRequest_TechnicalRequestTypesLabel"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <mibp-dropdown [items]="enquiryTypes" formControlName="enquiryType" (valueChange)="enquiryTypeChanged($event)"></mibp-dropdown>
          <mibp-validation-text [formGroup]="contactUsTechnicalRequestForm" [alwaysVisible]="formSubmitted" controlName="enquiryType"></mibp-validation-text>
        </div>
        <div class="forms__row">
          <label><mibp-resource-string key="SupportCasesTechnicalRequest_SerialNumber"></mibp-resource-string><span *ngIf="isSerialNumberRequired"  class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="20"  formControlName="serialNumber" >
          <mibp-validation-text [formGroup]="contactUsTechnicalRequestForm" [alwaysVisible]="formSubmitted" controlName="serialNumber"></mibp-validation-text>
        </div>
        <div class="forms__row">
          <label><mibp-resource-string key="WarrantyPortal_Field_PartNumber"></mibp-resource-string><span  *ngIf="isPartNumberRequired" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="20"  formControlName="partNumber" >
          <mibp-validation-text [formGroup]="contactUsTechnicalRequestForm" [alwaysVisible]="formSubmitted" controlName="partNumber"></mibp-validation-text>
        </div>
        <div class="forms__row">
          <label><mibp-resource-string key="Contact_Us_AddAttachment"></mibp-resource-string></label>
          <mibp-button type="button"  #btn  resourceStringKey="PartsManualReport_AddImages" (click)="addFiles()" [color]="buttonColors.Secondary"  [icon]="btn.Enum.Icons.FolderOpen"></mibp-button>
          <ul>
            <div *ngFor="let fileName of fileNames">
              <li>
                {{fileName}} <span translate="no" class="material-icon material-icon--red" (click)="deleteAttachment(fileName)">delete</span>
              </li>
            </div>
            <mibp-loader type="indeterminate-progressbar" *ngIf="isUploadingAttachments"></mibp-loader>
          </ul>
          <input *ngFor="let inputField of inputFields" class="hide" type="file" accept="image/*" (change)="fileChange($event)" multiple="false" #uploadInputElement name="file">
          <div *ngIf="attachmentError && fileNames.length > 0">
            <mibp-button type="button" #btn resourceStringKey="PartsManualReport_ReAddAttachments" (click)="retryUploadAttachments()"
              [color]="buttonColors.Secondary" [icon]="btn.Enum.Icons.FolderOpen"></mibp-button>

          </div>
        </div>
        <div class="forms__row">
          <label><mibp-resource-string key="Global_Description"></mibp-resource-string><span *ngIf="isDescriptionRequired"  class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <textarea class="textarea" maxlength = "300" formControlName="description"></textarea>
          <mibp-validation-text [formGroup]="contactUsTechnicalRequestForm" [alwaysVisible]="formSubmitted" controlName="description"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="submitButton">
      <mibp-button type="submit" [resourceStringKey]="'UserReg_Submit'" [color]="buttonColors.Primary"></mibp-button>
    </div>
  </form>
</div>
