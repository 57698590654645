import { MibpHttpApi } from './../../../../../../services/mibp-http-api/mibp-http-api.service';
import { ProductListItemMachineLink, PromotionProductQuantityDetails } from './../../components/product-list/product-list-item/product-list-item.type';
import { Injectable } from "@angular/core";
import { LocalizationService, MibpLogger, LogService, PermissionService, FrontendContextService, AuthService, ClientIdService } from "root/services";
import { ProductListItem, ProductListItemProduct } from "../../components/product-list/product-list-item/product-list-item.type";
import { Guid } from 'guid-typescript';
import { AvailabilityType, CartItemViewModel, CartItemVm, CartProductVm, ItemStatus, KitProductViewModel, KitSearchItem, NewProduct, NewProductSearchItem, OrderLine, PriceAndAvailabilityRequest, PriceAndAvailabilitySource, ProductAvailability, ProductDetailViewModel, ProductExpectedDate, ProductExpectedDateRequestDto, ProductPrice, ProductType } from 'root/mibp-openapi-gen/models';
import { allPermissionPolicies } from './../../../../../../all-permission-policies';
import { Subscription, firstValueFrom } from 'rxjs';
import { PriceAndAvailabilityApiController } from 'root/mibp-openapi-gen/controllers';
import { MySandvikFeatures } from 'root/services/permission';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

@Injectable({
  providedIn: 'root'
})
export class ProductListService {
  log: MibpLogger;
  productPriceSubscription: Subscription;
  availabilitySubscription: Subscription;
  expectedDateSubscription: Subscription;
  priceAbortController: AbortController;
  availabilityAbortController: AbortController;
  expectedDeliveryDateAbortController: AbortController;

  constructor(log: LogService,
    private context: FrontendContextService,
    private localizationService: LocalizationService,
    private permissions: PermissionService,
    private httpApi : MibpHttpApi,
    private priceAndAvailabilityController: PriceAndAvailabilityApiController,
    private auth : AuthService,
    private clientIdService: ClientIdService,
    private frontendContext: FrontendContextService,
    private sessionService: MibpSessionService) {
    this.log = log.withPrefix('product-list-service');
  }

  get useExpectedDate(): boolean {
    return this.context.isExpectedDeliveryDateEnabled();
  }

  private getTranslatedName(name: string, nameTranslations: string[]): string {
    if (nameTranslations && Array.isArray(nameTranslations)) {
      const lang = this.localizationService.getLang();
      const translatedName = nameTranslations.find(t => t.indexOf(`${lang}:`) === 0);
      return translatedName ? translatedName.substr(lang.length + 1) : name;
    }
    return name;
  }

  public mapFromProductIndex(productIndexItems: NewProductSearchItem[]): ProductListItem[] {
    if (productIndexItems && productIndexItems.length > 0) {
      return productIndexItems.map(pii => <ProductListItem>{
        quantity: 1,
        isKitItem: pii.type === 'Kit' ? true : false,
        product: {
          id: Guid.parse(pii.id),
          name: pii.name,
          brandCode: pii.code,
          brandDescription: pii.itemClassName,
          type:pii.type,
          code: pii.code,
          aliases: [],
          unitOfMeasure: pii.unitOfMeasure,
          weight: pii.weight ?? 0,
          priceCommodityCode:pii.priceCommodityCode,
          PartsPictureImageUrl:pii.partsPictureImageUrl ? pii.partsPictureImageUrl:'assets/images/NewDefaultImage.png',
        },
      });
    }
    return [];
  }

  public mapFromNewProductIndex(productIndexItems: NewProductSearchItem[]): ProductListItem[] {
    const lang = this.localizationService.getLang();
    if (productIndexItems && productIndexItems.length > 0) {
      return productIndexItems.map(pii => <ProductListItem>{
        quantity: 1,
        isKitItem: pii.type === 'Kit' ? true : false,
        productEquipment: pii.equipment,
        score: pii.score,
        companyCode: pii.companyCode,
        product: {
          id: Guid.parse(pii.id),
          name: pii.translations[lang] ? pii.translations[lang] : pii.name,
          brandCode: pii.itemClassIdentifier,
          brandDescription: pii.itemClassName,
          type:pii.type,
          code: pii.code,
          aliases: [],
          unitOfMeasure: pii.unitOfMeasure,
          weight: pii.weight ?? 0,
          priceCommodityCode:pii.priceCommodityCode,
          PartsPictureImageUrl:pii.partsPictureImageUrl ? pii.partsPictureImageUrl:'assets/images/NewDefaultImage.png',
          productAlias: pii.productAlias,
          isRockToolItem: pii.isRockToolItem,
          productNotificationId: pii.productNotificationId,
          productNotificationBusinessRelationIds: pii.productNotificationBusinessRelationIds
        },
      });
    }
    return [];
  }
  public mapFromKitProductViewModel(items: KitProductViewModel[]): ProductListItem[] {
    const lang = this.localizationService.getLang();
    if (items && items.length > 0) {
      return items.map(item => <ProductListItem>{
        quantity: item.quantity,
        product: {
          name: item.name,
          code: item.code,
          unitOfMeasure: item.unitOfMeasure,
          weight: 0,
        },
      });
    }
    return [];
  }



  public mapFromNewProduct(productIndexItems: NewProduct[]): ProductListItem[] {
    if (productIndexItems && productIndexItems.length > 0) {
      return productIndexItems.map(pii => <ProductListItem>{
        quantity: 1,
        isKitItem: pii.type === ProductType.Kit ? true : false,
        product: <ProductListItemProduct>{
          id: Guid.parse(pii.id),
          brandCode: pii.productItemClass?.identifier,
          brandDescription: pii.productItemClass?.name,
          weight: pii.weight ?? 0,
          code: pii.code,
          name: pii.name,
          unitOfMeasure: (pii.unitOfMeasure) || '',
          PartsPictureImageUrl:(pii.productImage?.value) ? (pii.productImage?.value):'assets/images/NewDefaultImage.png',
          aliases: [],
          majorProductItemGroupName: pii.majorProductItemGroup?.name,
          productAlias: pii.productAlias
        },
      });
    }
    return [];
  }

  public mapFromNewProductDetail(productDetails: ProductDetailViewModel) : ProductListItem {
    if (!productDetails) {
      return;
    }
    return <ProductListItem>{
      quantity: 1,
      isKitItem: productDetails.itemType === ProductType.Kit ? true : false,
      product: <ProductListItemProduct>{
        id: Guid.parse(productDetails.id),
        brandDescription: productDetails.brandDescription,
        weight: productDetails.weight,
        code: productDetails.code,
        name: productDetails.name,
        unitOfMeasure: productDetails.unitOfMeasure,
        PartsPictureImageUrl: productDetails.imageUrl ? productDetails.imageUrl :'assets/images/NewDefaultImage.png',
        productAlias: productDetails.productAlias,
        isRockToolItem: productDetails.isRockToolItem
      }
    };
  }

  public mapFromKitIndex(productIndexItems: KitSearchItem[]): ProductListItem[] {
    const lang = this.localizationService.getLang();
    if (productIndexItems && productIndexItems.length > 0) {
      return productIndexItems.map(pii => <ProductListItem>{
        quantity: 1,
        isKitItem: true,
        product: {
          id: Guid.parse(pii.id),
          name: pii.translations[lang] ? pii.translations[lang] : pii.name,
          brandCode: pii.productBrandCode,
          brandDescription: pii.productBrandDescription,
          code: pii.productCode,
          aliases: [],
          unitOfMeasure: pii.productUnitOfMeasure,
          weight: pii.productWeight ?? 0,
          PartsPictureImageUrl:pii.partsPictureImageUrl ? pii.partsPictureImageUrl:'assets/images/NewDefaultImage.png',
        },
      });
    }
    return [];
  }

  public mapFromCartItemViewModel(cartItemList:  CartItemVm[]): ProductListItem[] {
    let result: ProductListItem[] = [];

    if (cartItemList && cartItemList.length > 0) {
      result = cartItemList.map(apiCartItem =>
        <ProductListItem>{
          cartItemProductStatus: apiCartItem.status.productStatus,
          isDuplicate: apiCartItem.hasDuplicates,

          //upsellCrossSellProductId: apiCartItem.upsellCrossSellProductId,
          //crossSellProductIds: apiCartItem.crossSellProductIds,
          itemId: Guid.parse(apiCartItem.itemId),
          documotoDeepUrl: apiCartItem.documotoDeepUrl,
          supersededFromDb: apiCartItem.replacementProduct,
          //isApproved: apiCartItem.item.status === ItemStatus.Approved,
          //isPending: apiCartItem.item.status === ItemStatus.Pending,
          //isKitItem: apiCartItem.item.newProduct.type === ProductType.Kit,
          //isPurchased: apiCartItem.item.status === ItemStatus.Purchased,
          //isRejected: apiCartItem.item.status === ItemStatus.Rejected,
          //isSparePart: apiCartItem.item.newProduct.type === ProductType.SparePart,
          //isSubscription: apiCartItem.item.newProduct.type === ProductType.Subscription,
          isJustAdded: false,
          isSuperseeded: apiCartItem.replacementProduct != null,
          product: <ProductListItemProduct>{
            // id: Guid.parse(apiCartItem.product..id),
            brandCode: apiCartItem.product.itemClassCode,
            brandDescription: apiCartItem.product.itemClassName,
            productAlias: apiCartItem.addedUsingAlias,
            weight: (apiCartItem.replacementProduct ? apiCartItem.replacementProduct.weight : apiCartItem.product.weight || 0),
            code: apiCartItem.product.productCode,
            name: apiCartItem.replacementProduct?.name || apiCartItem.product.name,
            unitOfMeasure: (apiCartItem.product.unitOfMeasure?.unit) || '',
            PartsPictureImageUrl:
            apiCartItem.replacementProduct?.productImageUrl || apiCartItem.product.productImageUrl || 'assets/images/NewDefaultImage.png',
            aliases: [],
            majorProductItemGroupName: apiCartItem.product.majorGroupName,
            // isRockToolItem: apiCartItem.item.newProduct.isRockToolItem
          },
          quantity: apiCartItem.quantity,
          superseededByProductCode: apiCartItem.replacementProduct?.productCode,
          superseededByProductName: apiCartItem.replacementProduct?.name,
          // machineLink: <ProductListItemMachineLink>{
          //   equipmentId: apiCartItem.item.equipment !== null ? apiCartItem.item.equipment.id : null,
          //   equipmentName: apiCartItem.item.equipment !== null ? apiCartItem.item.equipment.name : null
          // },
          //productPrice: apiCartItem.item.productPrice,
          //productAvailability: apiCartItem.item.productAvailability,


          // TODO: MAYBE THIS MUST BE IMPLEMENTED
          // promotionProductQuantity: apiCartItem.promotionProductDto ? <PromotionProductQuantityDetails>{
          //   maxOrderableQuantity: apiCartItem.promotionProductDto.maxOrderableQuantity ?? 0,
          //   orderedQuantity: apiCartItem.promotionProductDto.orderedQuantity ?? 0,
          //   cumulativeDuplicateProductQuantity: apiCartItem.promotionProductDto.cumulativeDuplicateProductQuantity ?? 0
          // } : null,
          showPromotionRestrictionNotice : false
        }
      );
    }
    return result;
  }

  /**
   * Transform a CartItem page from api results to a list of ProductListItems to use
   * with the ProductList component
   */
  public mapFromCartItems(cartItemList:  CartItemViewModel[]): ProductListItem[] {
    let result: ProductListItem[] = [];

    if (cartItemList && cartItemList.length > 0) {
      result = cartItemList.map(apiCartItem =>
        <ProductListItem>{
          superseededLoadedFromDatabase: apiCartItem.superseededLoadedFromDatabase,
          cartItemProductStatus: apiCartItem.cartItemProductStatus,
          isDuplicate: apiCartItem.isDuplicate,
          upsellCrossSellProductId: apiCartItem.upsellCrossSellProductId,
          crossSellProductIds: apiCartItem.crossSellProductIds,
          itemId: Guid.parse(apiCartItem.item.id),
          documotoDeepUrl: apiCartItem.item.documotoDeepUrl,
          isApproved: apiCartItem.item.status === ItemStatus.Approved,
          isPending: apiCartItem.item.status === ItemStatus.Pending,
          isKitItem: apiCartItem.item.newProduct.type === ProductType.Kit,
          isPurchased: apiCartItem.item.status === ItemStatus.Purchased,
          isRejected: apiCartItem.item.status === ItemStatus.Rejected,
          isSparePart: apiCartItem.item.newProduct.type === ProductType.SparePart,
          isSubscription: apiCartItem.item.newProduct.type === ProductType.Subscription,
          isJustAdded: false,
          isSuperseeded: apiCartItem.item.superseededByNewProduct !== null,
          product: <ProductListItemProduct>{
            id: Guid.parse(apiCartItem.item.newProduct.id),
            brandCode: apiCartItem.item.newProduct.productItemClass.identifier,
            brandDescription: apiCartItem.item.newProduct.productItemClass.name,
            productAlias:apiCartItem.item.newProduct.productAlias,
            weight: (apiCartItem.item.superseededByNewProduct ? apiCartItem.item.superseededByNewProduct.weight : apiCartItem.item.newProduct.weight) ?? 0,
            code: apiCartItem.item.newProduct.code,
            name:  apiCartItem.item.superseededByNewProduct?.name || apiCartItem.item.newProduct.name || '',
            unitOfMeasure: (apiCartItem.item.newProduct.unitOfMeasure) || '',
            PartsPictureImageUrl:
              apiCartItem.item.superseededByNewProduct?.productImage?.value || apiCartItem.item.newProduct.productImage.value || 'assets/images/NewDefaultImage.png',

            aliases: [],
            majorProductItemGroupName: apiCartItem.item.newProduct.majorProductItemGroup?.name,
            isRockToolItem: apiCartItem.item.newProduct.isRockToolItem,
            productNotificationId: apiCartItem.item.newProduct.productNotificationProducts?.length > 0 ?
              apiCartItem.item.newProduct.productNotificationProducts[0].productNotificationId : null
          },
          quantity: apiCartItem.item.quantity,
          superseededByProductCode: apiCartItem.item.superseededByNewProduct?.code,
          superseededByProductName: apiCartItem.item.superseededByNewProduct?.name,
          machineLink: <ProductListItemMachineLink>{
            equipmentId: apiCartItem.item.equipment !== null ? apiCartItem.item.equipment.id : null,
            equipmentName: apiCartItem.item.equipment !== null ? apiCartItem.item.equipment.name : null
          },
          productPrice: apiCartItem.item.productPrice,
          productAvailability: apiCartItem.item.productAvailability,
          promotionProductQuantity: apiCartItem.promotionProductDto ? <PromotionProductQuantityDetails>{
            maxOrderableQuantity: apiCartItem.promotionProductDto.maxOrderableQuantity ?? 0,
            orderedQuantity: apiCartItem.promotionProductDto.orderedQuantity ?? 0,
            cumulativeDuplicateProductQuantity: apiCartItem.promotionProductDto.cumulativeDuplicateProductQuantity ?? 0
          } : null,
          showPromotionRestrictionNotice : false
        }
      );
    }
    return result;
  }

  public mapToConfirmationPageFromQuotationItems(list: OrderLine[]): ProductListItem[] {
    let result: ProductListItem[] = [];

    if (list && list.length > 0) {
      result = list.map(orderItem =>
        <ProductListItem>{
          productPrice: orderItem.unitPrice !== null ? {
            netPrice: orderItem.unitPrice,
          } : null,
          product: <ProductListItemProduct>{
            weight: orderItem.newProduct.weight,
            code: orderItem.newProduct.code,
            name: orderItem.newProduct.name,
          },
        }
      );
    }

    return result;
  }

  public mapPricesToProductItems(products : ProductListItem[], prices : ProductPrice[]) : ProductListItem[]{

    products?.forEach(productListItem => {
      const priceInfo = prices?.find(price => price.productCode == productListItem.product.code || price.productCode == productListItem.superseededByProductCode); // x.isSuperseeded === true ? prices?.find(price => price.superseededByProduct && price.productCode === x.product.code) : prices?.find(p => (p.productCode === x.product.code && !p.superseededByProduct) || (p.superseededByProduct === x.product.code));

      if (!priceInfo) {
        productListItem.productPrice = {
          productCode: productListItem.product.code,
          errorCode: 'BACKEND_UNHANDLED_ERROR',
          errorDescription: 'Backend threw an unhandled exception',
          superseededByProduct: null,
          hasNetPriceOnly: false,
          warning: null
        };
      }

      productListItem.productPrice = priceInfo;
      if (priceInfo?.superseededByProduct && !this.sessionService.hasFeature(MySandvikFeatures.ShopPersistentCart)) {
        productListItem.superseededByProductCode = priceInfo.superseededByProduct;
        productListItem.superseededByProductCodeDbValue = priceInfo.superseededByProductDbValue;
        productListItem.isSuperseeded = true;
      }
    });
    return products;
  }

  public mapAvailabilitiesToProductItems(products : ProductListItem[], availabilities : ProductAvailability[]) : ProductListItem[]{
    products?.forEach(productListItem => {
      const info = availabilities?.find(price => price.productCode == productListItem.product.code || price.productCode == productListItem.superseededByProductCode);
      productListItem.productAvailability = info;
    });
    return products;
  }

  public mapExpectedDatesToProductItems(products : ProductListItem[], expectedDates : ProductExpectedDate[]) : ProductListItem[]{
    products?.forEach(productListItem => {
      productListItem.productExpectedDate = productListItem.isSuperseeded === true ? expectedDates?.find(p => p.productCode === productListItem.superseededByProductCode && p.quantity == productListItem.quantity) : expectedDates?.find(p => p.productCode === productListItem.product.code && p.quantity === productListItem.quantity);
      productListItem.expectedDate = productListItem.productExpectedDate?.expectedDeliveryDate;
    });
    return products;
  }

  loadExpectedDates(productListItems: ProductListItem[], source: PriceAndAvailabilitySource, onlyGetFromCache = false): void {

    if (!this.useExpectedDate || !productListItems || productListItems.length == 0) {
      return;
    }
    const todayDate = new Date().toLocaleDateString('en-ca');
    this.expectedDateSubscription = this.priceAndAvailabilityController.getProductExpectedDates({body:{
      products : productListItems.map<ProductExpectedDateRequestDto>(p => {
        return {
          productCode: p.superseededByProductCode || p.product.code,
          quantity: p.quantity
        };
      }),
      priceAndAvailabilitySource: source,
      deliverySequenceId: null,
      userBrowserDate: todayDate,
      onlyGetFromCache: onlyGetFromCache
    }}).subscribe({
      next: (productExpectedDates) =>{
        this.mapExpectedDatesToProductItems(productListItems, productExpectedDates);
      },
      error: (e) => {
        this.log.error(`Error fetching expected dates`, e);
        productListItems.forEach(pi => {
          pi.expectedDate = null;
          pi.productExpectedDate = {
            errorCode: 'UNHANDLED',
            errorDescription: 'Error when fetching expected dates',
            productCode: pi.product.code,
            quantity: pi.quantity,
            expectedDeliveryDate: null,
            availabilityType: AvailabilityType.NotAvailable
          };
        });
      }
    });
  }

  loadAvailabilities(products: ProductExpectedDateRequestDto[], productListItems: ProductListItem[], source: PriceAndAvailabilitySource, force: boolean, cartId: string) {

    if (!this.permissions.test(allPermissionPolicies.canSeeAvailability)) {
      return;
    }
    this.availabilitySubscription = this.priceAndAvailabilityController.getAvailabilities({body:{
      deliverySequenceId : null,
      priceAndAvailabilitySource : source,
      productCodes : products.map(p => p.productCode),
      force : force,
      cartId : cartId
    }}).subscribe({
      next: (productAvailabilities) => {
        this.mapAvailabilitiesToProductItems(productListItems, productAvailabilities);
      },
      error: (e) => {
        this.log.error("availability error", e);
      }
    });
  }

  private async loadPrices(products: ProductExpectedDateRequestDto[], productListItems: ProductListItem[], source: PriceAndAvailabilitySource, force: boolean, cartId: string) {
    try {
      const productPrices = await firstValueFrom(this.priceAndAvailabilityController.getPrices({
        body: {
          deliverySequenceId: null,
          priceAndAvailabilitySource: source,
          productCodes: products.map(p => p.productCode),
          force: force,
          cartId: cartId
        }
      }));

      this.mapPricesToProductItems(productListItems, productPrices);
      // Invoke expected date request when we know the product code for sure (superseeded etc)
    } catch (error) {
      productListItems?.filter(product => !product.productPrice || !product.productPrice?.basePrice).forEach(product => {
        product.productPrice = {
          superseededByProduct: null,
          errorCode: 'BACKEND_UNHANDLED_ERROR',
          errorDescription: 'Backend threw an unhandled exception',
          productCode: product.product.code,
          hasNetPriceOnly: false,
          warning: null
        };
      });
      this.log.error("product-list price error", error);
    }
  }

  async getPriceAndAvailabilitiesOfProducts(products: ProductExpectedDateRequestDto[], productListItems: ProductListItem[], source: PriceAndAvailabilitySource, cartId: string, force : boolean) : Promise<void> {

    const requestExpectedDeliveryDatesOnDemand = this.sessionService.hasFeature(MySandvikFeatures.ShopProductExpecteddeliverydateOndemand);
    const onlyGetFromCache = (source != PriceAndAvailabilitySource.ActiveCart && requestExpectedDeliveryDatesOnDemand && !force);

    this.loadExpectedDates(productListItems, source, onlyGetFromCache);
    this.loadAvailabilities(products, productListItems, source, force, cartId);

    if (this.permissions.test(allPermissionPolicies.canSeePrices)) {
      await this.loadPrices(products, productListItems, source, force, cartId);
    }
  }

  public unsbscribeToSubscriptions(){
    this.productPriceSubscription?.unsubscribe();
    this.availabilitySubscription?.unsubscribe();
    this.expectedDateSubscription?.unsubscribe();
  }

  private loadExpectedDatesWithAbortSignal(productListItems: ProductListItem[], headers = {}, abortSignal: AbortSignal, source: PriceAndAvailabilitySource, onlyGetFromCache = false): void {

    if (!this.useExpectedDate || !productListItems || productListItems.length == 0) {
      return;
    }

    const url = this.httpApi.resolveUrl('priceandavailability/expectedDates');

    const todayDate = new Date().toLocaleDateString('en-ca');
    const requestBody = {
      products : productListItems.map<ProductExpectedDateRequestDto>(p => {
        return {
          productCode: p.superseededByProductCode || p.product.code,
          quantity: p.quantity
        };
      }),
      deliverySequenceId: null,
      priceAndAvailabilitySource: source,
      userBrowserDate: todayDate,
      onlyGetFromCache: onlyGetFromCache
    };

    fetch(url, { method: 'POST', body: JSON.stringify(requestBody), signal: abortSignal,headers: headers })
      .then((productPrices) => productPrices.json())
      .then((productExpectedDates) =>{
        this.mapExpectedDatesToProductItems(productListItems, productExpectedDates);
      })
      .catch((e) => {
        if(e instanceof DOMException && e.name === 'AbortError'){
          this.log.info("user aborted availability request");
        }
        else{
          this.log.error(`Error fetching expected dates`, e);
          productListItems.forEach(pi => {
            pi.expectedDate = null;
            pi.productExpectedDate = {
              errorCode: 'UNHANDLED',
              errorDescription: 'Error when fetching expected dates',
              productCode: pi.product.code,
              quantity: pi.quantity,
              expectedDeliveryDate: null,
              availabilityType: AvailabilityType.NotAvailable
            };
          });
        }
      });
  }

  private loadAvailabilitiesWithAbortSignal(products: ProductExpectedDateRequestDto[], productListItems: ProductListItem[], source: PriceAndAvailabilitySource, force: boolean, cartId: string, headers = {}, abortSignal: AbortSignal) {

    if (!this.permissions.test(allPermissionPolicies.canSeeAvailability)) {
      return;
    }

    const url = this.httpApi.resolveUrl('priceandavailability/availability');

    const requestBody = {
      deliverySequenceId : null,
      priceAndAvailabilitySource : source,
      productCodes : products.map(p => p.productCode),
      force : force,
      cartId : cartId
    } as PriceAndAvailabilityRequest;

    fetch(url, { method: 'POST', body: JSON.stringify(requestBody), signal: abortSignal, headers: headers})
      .then((productPrices) => productPrices.json())
      .then(productAvailabilities => {
        this.mapAvailabilitiesToProductItems(productListItems, productAvailabilities);
      })
      .catch((e) => {
        if(e instanceof DOMException && e.name === 'AbortError'){
          this.log.info("user aborted availability request");
        }
        else{
          this.log.error("availability error", e);
        }
      });
  }

  async getPriceAndAvailabilitiesOfProductsWithAbortSignal(products: ProductExpectedDateRequestDto[], productListItems: ProductListItem[], source: PriceAndAvailabilitySource, cartId: string, force : boolean) {
    const url = this.httpApi.resolveUrl('priceandavailability/price');

    this.priceAbortController = new AbortController();
    this.availabilityAbortController = new AbortController();
    this.expectedDeliveryDateAbortController = new AbortController();

    const headers = await this.getRequestHeaders();

    const requestExpectedDeliveryDatesOnDemand = this.sessionService.hasFeature(MySandvikFeatures.ShopProductExpecteddeliverydateOndemand);
    const onlyGetFromCached = (source != PriceAndAvailabilitySource.ActiveCart && requestExpectedDeliveryDatesOnDemand && !force);

    if (!this.permissions.test(allPermissionPolicies.canSeePrices)) {
      this.loadExpectedDatesWithAbortSignal(productListItems, headers, this.expectedDeliveryDateAbortController.signal, source, onlyGetFromCached);
      this.loadAvailabilitiesWithAbortSignal(products, productListItems, source, force, cartId, headers, this.availabilityAbortController.signal);
      return;
    }

    const requestBody = {
      deliverySequenceId : null,
      priceAndAvailabilitySource : source,
      productCodes : products.map(p => p.productCode),
      force : force,
      cartId : cartId
    } as PriceAndAvailabilityRequest;

    fetch(url, { method: 'POST', body: JSON.stringify(requestBody), signal: this.priceAbortController.signal, headers: headers})
      .then((productPrices) => productPrices.json())
      .then(productPrices => {
        this.mapPricesToProductItems(productListItems, productPrices);

        // Invoke expected date request when we know the product code for sure (superseeded etc)
        this.loadExpectedDatesWithAbortSignal(productListItems, headers, this.expectedDeliveryDateAbortController.signal, source, onlyGetFromCached);
      })
      .catch((e) => {
        if(e instanceof DOMException && e.name === 'AbortError'){
          this.log.info("user aborted price request");
        }
        else{
          // Request failed so let's update the affected products with error state
          productListItems?.filter(product => !product.productPrice || !product.productPrice?.basePrice).forEach(product => {
            product.productPrice = {
              superseededByProduct: null,
              errorCode: 'BACKEND_UNHANDLED_ERROR',
              errorDescription: 'Backend threw an unhandled exception',
              productCode: product.product.code,
              hasNetPriceOnly: false,
              warning: null
            };
          });
          this.log.error("product-list price error", e);
        }
      });

    this.loadAvailabilitiesWithAbortSignal(products, productListItems, source, force, cartId, headers, this.availabilityAbortController.signal);
  }

  async getRequestHeaders(){
    const accessToken = await this.auth.ensureToken();
    const headers = {};
    const clientId = this.clientIdService.getClientId();

    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    if (clientId) {
      headers['X-Client-Id'] = clientId;
    }

    headers['x-lang-code'] = this.localizationService.getLang();
    headers['Content-Type'] = 'application/json';

    return headers;
  }

  abortExisitingRequests(){
    this.priceAbortController?.abort();
    this.availabilityAbortController?.abort();
    this.expectedDeliveryDateAbortController?.abort();
  }
}
