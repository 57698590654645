<span class="my-inline-help" [class.my-inline-help--no-left-margin]="!addLeftMargin" #helpIconElementRef>
  <span
    [class.material-icon--blue]="color =='blue'"
    [class.material-icon--orange]="color =='orange'"
    [class.material-icon--red]="color =='red'"
    [class.material-icon--small]="iconSize.includes('small')"
    [class.material-icon--large]="iconSize.includes('large')"
    translate="no" class="material-icon" (click)="helpPopup.open(helpIconElementRef)">{{iconName}}</span>
    <!-- <mibp-sandvik-icon (click)="helpPopup.open(helpIconElementRef)" class="my-feature-editor__helpicon" name="help-circle" [color]="color" [size]="iconSize"></mibp-sandvik-icon> -->
</span>

<mibp-popup-container
  [useArrow]="true"
  [anchorTo]="null"
  [minWidth]="minwidthForMobile"
  [maxWidth]="600"
  [widthForMobile]="widthForMobile"
  [anchorPosition]="null" #helpPopup>
  <div class="my-inline-help-content" [class.my-inline-help-content--content-styles]="applyContentStyles" >
    <ng-content></ng-content>
  </div>
</mibp-popup-container>
