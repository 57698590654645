/* tslint:disable */
/* eslint-disable */
export enum JobStatus {
  Unknown = 0,
  Waiting = 10,
  Running = 20,
  Completed = 30,
  PartiallyCompleted = 35,
  Terminated = 40,
  Failed = 60,
  Hold = 70
}
