export enum SupportCaseItemEnquiryType {
  ProductAvailability = 'SupportCasesItemEnquriy_Type_Availability',
  ItemPrice = "SupportCasesItemEnquriy_Type_Price",
  ExpectedDeliveryDate = "SupportCasesItemEnquriy_Type_ExpectedDeliveryDate",
  OutPhasedItem = "SupportCasesItemEnquriy_Type_OutPhased"
}

export enum SupportCaseItemEnquiryTypeValue {
  ProductAvailability = 'Product availability enquiry',
  ItemPrice = "Item price enquiry",
  ExpectedDeliveryDate = "Expected delivery date enquiry",
  OutPhasedItem = "Outphased Item"
}
