import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { ApiService } from 'root/services//mibp-api-services';
import { MyFleetListComponent } from '../../pages/myfleet/myfleet-list/myfleet-list-page.component';
@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  private myFleetListComponent: MyFleetListComponent;


  constructor(private api: ApiService) {}

  public setmyFleetListComponent(component: MyFleetListComponent) {
    this.myFleetListComponent = component;
  }
}
