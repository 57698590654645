import format from "date-fns/format";
import { MibpLogLevel } from "./loglevel.enum";

export class MibpLogEntry {
  message: string;
  level: MibpLogLevel;
  extraInfo: any[];
  logWithDate: boolean;
  prefix: string;

  buildLogString(): string {
    let ret  = "", levelstring = "", date = "";

    if (this.prefix) {
      ret += `[${this.prefix}]`;
    }

    switch (this.level) {
    case MibpLogLevel.Debug: levelstring = 'DEBUG'; break;
    case MibpLogLevel.Info: levelstring = 'INFO '; break;
    case MibpLogLevel.Error: levelstring = 'ERROR'; break;
    case MibpLogLevel.Warn: levelstring = 'WARN '; break;
    }

    if (this.logWithDate) {
      date = ` ${format(new Date(), 'HH:mm:ss.SS')} `;
    }
    ret += ' ' + this.message;
    return `[mysandvik][${levelstring}]${date}${ret}`;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(",");

    // Is there at least one object in the array?
    if (params.some(p => typeof p === "object")) {
      ret = "";
      // Build comma-delimited string
      for (const item of params) {
        ret += JSON.stringify(item) + ",";
      }
    }

    return ret;
  }

}

