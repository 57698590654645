import { MibpPopoverComponent } from 'root/components/popover-component/popover.component';
import { LogService } from '../../services/logservice/log.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MibpLogger } from '../../services/logservice';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { LocalizationService } from 'root/services';
import { Subscription, firstValueFrom, skip } from 'rxjs';
import { ProductNotificationsApiController } from 'root/mibp-openapi-gen/controllers';

@Component({
  selector: 'mibp-product-notification',
  templateUrl: './product-notification.component.html',
  styleUrls: ['./product-notification.component.scss']
})
export class MibpProductNotificationComponent implements OnInit, OnDestroy {  
  isLoading = true;
  log: MibpLogger;
  message: string;
  currentLanguage: string;
  localeSub: Subscription;

  @Input() productNotificationId: number;    
  @ViewChild(MibpPopoverComponent) popover: MibpPopoverComponent;

  constructor(logger: LogService,
    private localizationService: LocalizationService,
    private productNotificationApi: ProductNotificationsApiController) {
    this.log = logger.withPrefix('notification-component');
  }

  ngOnInit(): void {
    this.currentLanguage = this.localizationService.getLang();

    this.localeSub = this.localizationService.Locale$.pipe(skip(1)).subscribe(() => {
      if (this.currentLanguage != this.localizationService.getLang()) {
        this.currentLanguage = this.localizationService.getLang();
      }
    });
  }

  async showPopover(elm: HTMLElement) {
    this.popover.open(elm);

    if (this.productNotificationId) {
      try {
        const productNotification = await firstValueFrom(this.productNotificationApi.getProductNotification( { id: this.productNotificationId, languageCode: this.currentLanguage } ));
        this.message = productNotification.message;        
      } catch (error) {        
        this.log.error('Error when loading product notification', error);
      } finally {
        this.isLoading = false;
      }   
    }   
  } 

  ngOnDestroy(): void {
    this.localeSub?.unsubscribe();   
  }
}
