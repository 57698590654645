
import { SignalR_RoleEcommercePermission } from "root/services/mibp-api-services";
import { MySandvikNavigationItem } from "../navigation-types";
import { MySandvikFeatures } from 'root/services/permission';
import { allPermissionPolicies } from "root/all-permission-policies";

export const NAVITEMS_SHOP: MySandvikNavigationItem = {
  resourceKey: 'Shop_Title',
  sandvikIconName: 'inventory_2',
  showIfAnyChildren: true,
  automatedTestId: 'sidebar-shop',
  route: {
    path: ['shop'],
    exactMatch: true
  },
  children: [
    {
      uniqueId: 'product-search',
      automatedTestId: 'sidebar-product-search',
      resourceKey: 'SearchProducts_Title',
      permissionPolicy: allPermissionPolicies.search,
      route: {
        path: ['products']
      }
    },    
    {
      resourceKey: 'PartsManuals_Title',
      permissionPolicy: allPermissionPolicies.canSeePartsManuals,
      automatedTestId: 'sidebar-parts-manuals',
      route: {
        path: ['parts-manuals']
      },
      children: [
        {
          resourceKey: 'Global_Equipment',
          uniqueId: 'shop/parts-manuals/equipment',
          route: {
            path: ['equipment/:id']
          }
        }
      ]
    },
    {
      resourceKey: 'KitFinder_Title',
      route: {path: ['kitfinder']},
      permissionPolicy: allPermissionPolicies.canViewKitFinder,
      children: [
        {
          resourceKey: 'Global_Kit',
          uniqueId: 'shop/kitfinder/product',
          route: {
            path: ['shop/kitfinder/product']
          }
        }
      ]
    },
    {
      resourceKey: 'Templates_Title',
      permissionPolicy: allPermissionPolicies.canUseTemplates,
      automatedTestId: 'sidebar-templates',
      route: {
        path: ['templates']
      },
      children: [
        // These children are not visible in navigation, but
        // configuration is used by breadcrumbs and permissions
        {
          resourceKey: 'Templates_Private_Tab_Title',
          route: { path: ['private'] }
        },
        {
          resourceKey: 'Templates_Shared_Tab_Title',
          route: { path: ['shared'] },
        }
      ]
    },

    {
      resourceKey: 'Global_Promotions',
      permissionPolicy: allPermissionPolicies.promotions,
      automatedTestId: 'sidebar-promotions',
      route: {
        path: ['promotions']
      },
      children:[{
        resourceKey: 'Promotion_Details',
        uniqueId:'shop/promotions/details',
        permissionPolicy: allPermissionPolicies.promotions,
        route: { path:['promotions/:id']}
      }
      ]
    },

    {
      resourceKey: 'Cart_Title',
      automatedTestId: 'sidebar-active-cart',
      uniqueId: 'cart',
      permissionPolicy: allPermissionPolicies.canSeeCart,
      route: {
        path: ['cart']
      },
      children: [
        // These children are not visible in navigation, but
        // configuration is used by breadcrumbs and permissions
        {
          uniqueId: 'cart/active',
          resourceKey: 'Carts_Active_Title',
          permissionPolicy: allPermissionPolicies.canSeeCart,
          route: { path: [''] },
          children: [
            {
              uniqueId: 'cart/checkout',
              resourceKey: 'Carts_Active_Checkout_Title',
              permissionPolicy: allPermissionPolicies.checkout,
              route: { path: ['checkout'] },
              children: [
                {
                  uniqueId: 'shop/checkout/payment',
                  resourceKey: 'Global_Payment',
                  permissionPolicy: allPermissionPolicies.canViewPaymentPage,
                  route: { path: ['payment'] },
                }
              ],
            },
          ]
        },
        {
          resourceKey: 'Carts_Saved_Tab',
          permissionPolicy: allPermissionPolicies.canSeeCart,
          route: { path: ['saved'] },
        },
        {
          resourceKey: 'Carts_Sent_Title',
          route: { path: ['sent'] },
        }
      ]
    },
    {
      resourceKey: 'Navigation_Shop_Orders',
      permissionPolicy: allPermissionPolicies.viewOrdersAndQuotations,
      automatedTestId: 'sidebar-orders',
      route: {
        path: ['orders']
      },
      children: [
        // These children are not visible in navigation, but
        // configuration is used by breadcrumbs and permissions
        {
          resourceKey: 'Global_Order_Details',
          route: { path: [':id'] },
        }
      ]
    },
    {
      resourceKey: 'Carts_Quotations_Title',
      permissionPolicy: allPermissionPolicies.viewOrdersAndQuotations,
      automatedTestId: 'sidebar-quotations',
      route: {
        path: ['quotations']
      },
      children: [
        // These children are not visible in navigation, but
        // configuration is used by breadcrumbs and permissions
        {
          resourceKey: 'Global_Quotation_Details',
          route: { path: [':id'] },
          uniqueId: 'shop/quotations/:id'
        }
      ]
    },
    {
      resourceKey: 'Carts_PunchedOut_Title',
      permissionPolicy: allPermissionPolicies.canPunchOut,
      automatedTestId: 'sidebar-punchedout',
      route: {
        path: ['punchedout']
      }
    },
  ]
};
