import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'root/environment';
import { ClientSideCacheMemoryStorageService } from './memoryStorage';
import { format } from 'date-fns';

export interface MibpCookie {
  name: string;
  value: string;
  expires?: Date;
  path?: string;
  sameSite?: 'Lax' | 'Strict';
  secure?: boolean;
  httpOnly?: boolean;
}

/**
 * Enum representing how an item is stored in the Client side cache
 */
export enum CacheScope {

  /** All users. Stored in localStorage. Persistent between tabs and sessions. */
  GlobalStorage,

  /**
  * All users.
  * Stored in sessionStorage.
  * Only for active tab/window.
  */
  GlobalSessionStorage,

  /**
  * User specific.
  * Stored in localStorage.
  * Persistent between tabs and sessions
  */
  UserStorage,

  /**
  * User specific.
  * Stored in sessionStorage.
  * Only for active tab/window
  */
  UserSessionStorage,

  /**
  * Stored in a javascript variable
  */
  MemoryStorage
}

/**
 * Represents an item stored in the client side cache
 *
 * This could be in localStorage, sessionStorage or memory storage.

 * It could also be for all, or for just the current user
 *
 * @see MemoryStorage
 */
interface CachedItem {
  /**
   * The value of this item in the cache
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val: any;

  /**
   * The timestamp for when this item expires
   */
  e?: number;

  /**
   * The timestamp for when this item was added to the cache
   */
  _ts: number;
}

/**
 * The ClientSideCache service will save, delete and fetch values from a cache in the client's web browser.
 *
 * Depending on the Scope, the value will be stored in different storages:
 *
 * - localStorage (GlobalStorage, UserStorage)
 * - sessionStorage (GlobalSessionStorage, UserSessionStorage)
 * - memoryStorage (MemoryStorage)
 *
 * The memory storage is cleared as soon as the page is reloaded, while
 * the others are more persistant.
 *
 * When caching data for the "User", the cached values will only be retreivable by the same user that saves the data
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ClientSideCacheService {

  /**
   * Changing the cache key will invalidate any cache used with that key
   * The cache key is usually generated per build.
   * It will be fetched from the meta tag named 'my-sandvik' and the attribute 'data-cache-hash'
   */
  cacheKey: string;

  /**
   * A key unique for the current user
   */
  private userKey = 'anonymous';

  /**
   * The prefix for all cached items so they are easily identified in the storages
   */
  readonly PREFIX: string = environment.clientSideCache.prefix;

  private localStorage: Storage;

  private sessionStorage: Storage;

  /**
   * Create a new instance of the ClientSideCacheService
   * @param document The window document is used to retreive the cacheKey from the HTML meta data
   */
  constructor(@Inject(DOCUMENT) document: Document,
  @Inject("windowObject") window: Window,
  private memoryStorage: ClientSideCacheMemoryStorageService) {

    this.localStorage = window.localStorage;
    this.sessionStorage = window.sessionStorage;

    const mySandvikMeta = document.querySelector(`meta[name='my-sandvik']`);

    if (mySandvikMeta != null) {
      this.cacheKey = mySandvikMeta.getAttribute('data-cache-hash');
    }

    if (!this.cacheKey) {
      const today = format(new Date(), 'yyMMdd');
      this.cacheKey = `$${today}`;
    }
    this.clearOldCache();
    setTimeout(() => this.clearExpiredCache());
  }

  /**
   * Set the user key. This will be a part of the cache to and is used to separate user specific items from each other
   * @param newKey The key uniqe for the user
   */
  public setUserKey(newKey: string): void {
    this.userKey = newKey;
  }

  public getAllKeys() {
    const storages = [this.memoryStorage, this.localStorage, this.sessionStorage];
    const keys: string[] = [];
    storages.forEach(storage =>
      Object.keys(storage)
        .filter(key => key.indexOf(this.PREFIX +  this.cacheKey) === 0 )
        .forEach(key => {
          keys.push(key.substring((this.PREFIX +  this.cacheKey).length + 1));
        }));
    return keys;
  }

  /**
   * Delete all cached items that have the correct PREFIX, but the wrong cache key
   */
  private clearOldCache() {
    const storages = [this.memoryStorage, this.localStorage, this.sessionStorage];
    storages.forEach(storage =>
      Object.keys(storage)
        .filter(key => key.indexOf(this.PREFIX) === 0 && key.indexOf(this.PREFIX +  this.cacheKey) === -1 )
        .forEach(key => {
          storage.removeItem(key);
        }));
  }

  /**
   * This will clear EVERYTHING (Not just My Sandvik cache items) from local and session storage
   */
  public clearEverything(): void {
    const storages = [this.memoryStorage, this.localStorage, this.sessionStorage];
    storages.forEach(storage =>
      Object.keys(storage)
        .forEach(key => {
          storage.removeItem(key);
        }));
  }

  /**
   * Go through all items and "get" them. If expired, they will be deleted
   */
  private clearExpiredCache() {
    const storages = [this.memoryStorage, this.localStorage, this.sessionStorage];
    storages.forEach(storage =>
      Object.keys(storage)
        .filter(key => key.indexOf(this.PREFIX) === 0 && key.indexOf(this.PREFIX +  this.cacheKey) === -1 )
        .forEach(key => {
          this.get(key);
        }));
  }

  /**
   * Remove the named item from the cache
   *
   * This will remove the item from all types of storage (user, global etc.)
   * @param key The key if the cached item to delete
   */
  public remove(key: string): void {
    const storages = [this.memoryStorage, this.localStorage, this.sessionStorage];

    storages.forEach(storage => {
      const userKey = `${this.PREFIX}${this.cacheKey}_${this.userKey}_${key}`;
      const globalKey = `${this.PREFIX}${this.cacheKey}_${key}`;
      storage.removeItem(userKey);
      storage.removeItem(globalKey);
    });

  }

  /**
  * Add an item to the cache
  * @param key The key to cache the data with
  * @param value The data to cache
  * @param expiration A string (15 minutes, 4 hours) or a Date
  * @param scope Specify if this is user specific or global cache
  */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  add(key: string, value: any, expiration: string | Date, scope: CacheScope = CacheScope.GlobalStorage): void {

    let storage;

    if (scope === CacheScope.GlobalSessionStorage || scope === CacheScope.UserSessionStorage) {
      storage = this.sessionStorage;
    } else if (scope === CacheScope.GlobalStorage || scope === CacheScope.UserStorage) {
      storage = this.localStorage;
    } else if (scope === CacheScope.MemoryStorage ) {
      storage = this.memoryStorage;
    }

    let finalKey;
    if (scope === CacheScope.UserSessionStorage || scope === CacheScope.UserStorage) {
      finalKey = `${this.PREFIX}${this.cacheKey}_${this.userKey}_${key}`;
    } else {
      finalKey = `${this.PREFIX}${this.cacheKey}_${key}`;
    }

    let expires: number;

    if (typeof expiration === 'string') {
      expires = this.durationToTime(expiration);
    } else if (expiration instanceof Date) {
      expires = (<Date>expiration).getTime();
    }

    const data = <CachedItem>{
      _ts: (new Date()).getTime(),
      e: expires,
      val: value
    };

    storage.setItem(finalKey, JSON.stringify(data));
  }

  /**
   * Get the cached item with the specified key
   *
   * The key will be fetch from storages in this order:
   *
   * - memoryStorage
   * - localStorage
   * - sessionStorage
   *
   * @param key The key to retreive
   */
  get<T>(key: string): T {
    const storages = [this.memoryStorage, this.localStorage, this.sessionStorage];
    const globalKey = `${this.PREFIX}${this.cacheKey}_${key}`;
    const userKey = `${this.PREFIX}${this.cacheKey}_${this.userKey}_${key}`;

    const flatten = list => list.reduce(
      (a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: any[] = flatten(storages.map(storage =>
      [
        {storage: storage, key: userKey, json: storage.getItem(userKey)},
        {storage: storage, key: globalKey, json: storage.getItem(globalKey)}
      ]))
      .filter(value => value.json !== null);

    if (values.length > 0) {
      let obj: CachedItem;
      try { obj = JSON.parse(values[0].json); } catch (e) {
        // Do nothing
      }
      if (obj) {
        if (obj.e) {
          if (obj.e < (new Date()).getTime()) {
            values[0].storage.removeItem(values[0].key);
            return null;
          }
        }
        return obj.val;
      }
    }
    return null;
  }


  // getKeys(pattern: string) {

  // }

  /**
   * Convert a duration string to milliseconds
   * @param durationString A duration string in the format `number unit` where unit can be month, day, hour, minute, second or millisecond
   * @example
   * durationToTime('2 months')
   * durationToTime('1 day')
   * durationToTime('10 minutes')
   */
  private durationToTime(durationString: string): number {
    let msToAdd = 0,
      match,
      seconds;
    durationString = durationString.toLocaleLowerCase();

    if (durationString.match(/^\d+$/)) {
      // Number only. Default to minutes
      msToAdd = ((parseInt(durationString, 10) * 60) * 1000);
    } else if ((match = durationString.match(/^(\d+) (month|day|hour|minute|second|ms|millisecond)s?$/))) {
      const intval = parseInt(durationString, 10);
      switch (match[2]) {
      case 'month': seconds = (((24 * 60) * 60) * 30) * intval; break;
      case 'day': seconds = (((intval * 24) * 60) * 60); break;
      case 'hour': seconds = ((intval * 60) * 60); break;
      case 'minute': seconds = (intval * 60); break;
      case 'second': seconds = intval; break;
      case 'ms': case 'millisecond': seconds = intval / 1000; break;
      }
      if (seconds) {
        msToAdd = seconds * 1000;
      }
    }

    if (msToAdd > 0) {
      return new Date((new Date()).getTime() + msToAdd).getTime();
    }
    return null;
  }

  getCookie(name: string): string {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  getCookieNames(): string[] {
    const cookieString = document.cookie;
    if (cookieString) {
      const cookies = cookieString.split(';');
      return cookies.map(cookie => cookie.indexOf('=') !== -1 ? cookie.substr(0, cookie.indexOf('=')).trim() : null).filter(c => c);
    }
    return [];
  }

  deleteCookie(name: string): void {
    this.setCookie(name, '', new Date('Thu, 01 Jan 1970 00:00:00 GMT'));
  }


  setCookie(name: string, value: string, expires?: Date): void {
    const expiresString = expires ? expires.toString() : '';
    document.cookie = `${name}=${value}; path=/; expires=${expiresString};`;
  }

  writeCookie(cookie: MibpCookie): void {

    const cookieArray: string[] = [];

    if (typeof cookie.secure == 'undefined') {
      cookie.secure = true;
    }

    cookieArray.push(`${encodeURIComponent(cookie.name)}=${encodeURIComponent(cookie.value)}`);

    if (cookie.expires) {
      cookieArray.push(`expires=${cookie.expires.toUTCString()}`);
    }

    if (cookie.secure == true) {
      cookieArray.push(`Secure`);
    }

    if (cookie.httpOnly == true) {
      cookieArray.push(`HttpOnly`);
    }

    if (cookie.path) {
      cookieArray.push(`path=${cookie.path}`);
    } else {
      cookieArray.push(`path=/`);
    }

    if (cookie.sameSite) {
      cookieArray.push(`SameSite=${cookie.sameSite}`);
    }

    document.cookie = cookieArray.join('; ');
  }

}
