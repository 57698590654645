<mibp-system-message *ngIf="!isDeliverySequenceOk && hasRequiredPermissions" class="m-4">
  <p><mibp-resource-string key="Global_ActAsCustomerForFeature"></mibp-resource-string></p>
</mibp-system-message>

<mibp-system-message *ngIf="!hasRequiredPermissions" class="m-4">
  <p><mibp-resource-string key="Global_NoPermissionMessage"></mibp-resource-string></p>
</mibp-system-message>



<mibp-refiner querystringParameter="q" [config]="refinerConfig" (filter)="onRefinerFilterChanged($event)" *ngIf="isDeliverySequenceOk && hasRequiredPermissions">

  <mibp-loader *ngIf="isLoading && !errorFetchingItems"></mibp-loader>

  <mibp-system-message *ngIf="!isLoading && errorFetchingItems" [messageType]="msg.Types.Error" #msg>
    <h2><mibp-resource-string key="Global_Error_OperationFailed_message"></mibp-resource-string></h2>
  </mibp-system-message>


  <ng-template [ngIf]="!isLoading && cartList?.length === 0 && !errorFetchingItems">
    <h1 class="article-heading-h1"><mibp-resource-string key="Global_NoHits"></mibp-resource-string></h1>
    <p style="font-size: 18px" *ngIf="hasFilters"><mibp-resource-string key="Global_FilterYieldedNoResults"></mibp-resource-string></p>
    <p style="font-size: 18px" *ngIf="!hasFilters"><mibp-resource-string key="No_Saved_Cart_Available"></mibp-resource-string></p>
  </ng-template>


  <div class="mibp-expandable-sections">
    <mibp-expandable-section *ngFor="let cart of cartList; trackBy: trackByCartId; let i = index" [canExpand]="canExpand(cart)" [asideClass]="type === 'carts' ? 'is-3' : 'is-2'"   [showAside]="showAside(cart.status)" (expand)="expandOrVisitItem(cart, i)" (collapse)="collapseCart(cart)" (finishedEditingTitle)="onFinishedEditingCartTitle(cart, $event)"
      [title]="cart.title" [allowEditTitle]="allowEditTitle" disableContentPadding="true">
      <div data-header="true" class="header">
        <br>
        <div class="cart-info">
          <span *ngFor="let prop of cart.properties; let ix = index;">
            <span *ngIf="prop.key"><mibp-resource-string [key]="prop.key"></mibp-resource-string>:&nbsp;</span>

            <span class="cart-info__value" [class.cart-info__value--bold]="prop.valueIsBold">
              <span *ngIf="prop.valueResourceKey"><mibp-resource-string [key]="prop.valueResourceKey"></mibp-resource-string>&nbsp;</span>
              <span *ngIf="prop.value" [attr.title]="prop.tooltip" [class.info]="prop.tooltip">{{ prop.value }}</span>
            </span>
            <span class="cart-info__value-suffix" [class.cart-info__value-suffix--uppercase]="prop.valueSuffixResourceKeyIsUpperCase" *ngIf="prop.valueSuffixResourceKey" >
              <mibp-resource-string [key]="prop.valueSuffixResourceKey"></mibp-resource-string>
            </span>
            {{ ix &lt; cart.properties?.length - 1  ? ' | ' : '' }}
          </span>
        </div>

        <div class="aside template-actions" *ngIf="type === 'privateTemplate' || type === 'sharedTemplate'">
          <ng-template [ngIf]="cart.status === cartStatusEnum.Saved">
            <mibp-button isSmall="true" resourceStringKey="Templates_ShareTemplate" *ngIf="hasShareTemplatePermission && type === 'privateTemplate'" (click)="shareCart(cart)"></mibp-button>
            <mibp-button isSmall="true" resourceStringKey="Templates_UnshareTemplate" *ngIf="hasUnShareTemplatePermission && type === 'sharedTemplate' && currentUserId.equals(cart.ownerId)" (click)="UnShareCart(cart)"></mibp-button>
            <mibp-button isSmall="true" resourceStringKey="Templates_RemoveTemplate" *ngIf="(type === 'privateTemplate' || type === 'sharedTemplate') && currentUserId.equals(cart.ownerId)" (click)="removeSavedCart(cart)"></mibp-button>
            <mibp-button *ngIf="hasCreateCartPermission" isSmall="true" resourceStringKey="Templates_AddItemsToCart" (click)="addItemsToActiveCart(cart)" data-gtm="Template_AddItemsToCart"></mibp-button>
          </ng-template>
        </div>

      </div>

      <div data-aside="true" class="aside" *ngIf="type === 'carts'">
        <ng-template [ngIf]="cart.status === cartStatusEnum.Saved">
          <mibp-button isSmall="true" resourceStringKey="Carts_SavedCartRemove" #btn [color]="btn.Enum.Colors.Orange" (click)="removeSavedCart(cart)"></mibp-button>
          <mibp-button isSmall="true" resourceStringKey="Carts_SavedCartActivate" (click)="activateSavedCart(cart)"></mibp-button>
        </ng-template>
        <ng-template [ngIf]="cart.status === cartStatusEnum.OrderSent">
          Sent yes?
        </ng-template>
      </div>


    <div data-aside="true" class="aside" *ngIf="type === 'sharedTemplate'">
      <ng-template [ngIf]="type === 'sharedTemplate'">
        <mibp-button isSmall="true" resourceStringKey="Templates_UnshareTemplate" (click)="UnShareCart(cart)"></mibp-button>
        <mibp-button *ngIf="hasCreateCartPermission" isSmall="true" resourceStringKey="Templates_AddItemsToCart" (click)="addItemsToActiveCart(cart)" data-gtm="Template_AddItemsToCart"></mibp-button>
      </ng-template>
    </div>

      <div data-aside="true" class="aside" *ngIf="type === 'orders' || type === 'quotations'">
        <mibp-button isSmall="true" resourceStringKey="Global_View" (click)="expandOrVisitItem(cart, i)"></mibp-button>
      </div>

      <div data-content="true" class="content">
        <mibp-product-list-paginated
        [cart]="cart"
        [hidePrice]="hidePrice"
        [hideTotalPrice]="hideTotalPrice"
        [hideAvailability]="hideAvailability"
        [hideAddToCart]="hideAddToCart"
        [hideDelete]="hideDelete"
        [hideRelatedProducts]="hideRelatedProducts"
        [hideRelatedButtons]="hideRelatedButtons"
        [quickAddButtonTextKey]="quickAddButtonTextKey"
        [hideQuickAdd]="hideQuickAdd"
        [priceAndAvailabilitySource]= "priceAndAvailabilitySource"
        [addItemToCartSource]="addItemToCartSource"

        (removedItem)="itemremoved($event)"
        (addedItem)="itemAdded($event)"
        [disableDuplicate]="disableDuplicate"
        ></mibp-product-list-paginated>
        <!-- <mibp-product-list [cartId]="cart.id" [ecomItems]="ecomItems" class="d-block"
        [equipmentCount]="equipmentCount"
        [hidePrice]="hidePrice"
        [hideTotalPrice]="hideTotalPrice"
        [hideAvailability]="hideAvailability"
        [hideAddToCart]="hideAddToCart"
        [hideDelete]="hideDelete"
        [hideMachineLinks]="hideMachineLinks"
        [hideRelatedProducts]="hideRelatedProducts"
        [hideRelatedButtons]="hideRelatedButtons"
        (itemRemoved)="onCartRemoved(cart, $event)"
        #listItem></mibp-product-list>
        <div *ngIf="!hideQuickAdd"><mibp-quick-add [skipFocus]="skipFocusOnQuickAdd" [cartId]="cart.id" [buttonTextKey]="quickAddButtonTextKey" (addedToCart)="onCartAdded($event, cart, i)" #quickadd></mibp-quick-add></div> -->
         <!-- <mibp-paginator [startIndex]="0" [totalItems]="totalItems" [pageSize]="pageSize" (changePage)="onChangePage($event)"> </mibp-paginator> -->
      </div>
    </mibp-expandable-section>
  </div>

  <mibp-paginator *ngIf="!isLoading && totalItems > pageSize" [startIndex]="skip" [totalItems]="totalItems" [pageSize]="pageSize" (changePage)="changePage($event)"> </mibp-paginator>


    <!-- <div class="buttonrow">

      <div *ngIf="cart.status === cartStatus.OrderSent">
        sent
      </div>

      <div *ngIf="cart.status === cartStatus.Saved">
          saved
          <mibp-button resourceStringKey="Carts_SavedCartRemove" ></mibp-button>
          <mibp-button resourceStringKey="Carts_SavedCartActivate" ></mibp-button>

        </div>

    </div>
    <div *ngIf="cart.showItems" class="cart">
      ja
      <mibp-product-list [hideDelete]="true" [cartId]="cart.id" [ecomItems]="cart.productListItems" class="d-block"></mibp-product-list>
    </div> -->

</mibp-refiner>
