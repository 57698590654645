/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createWarrantyRegistration } from '../fn/warranty-registration/create-warranty-registration';
import { CreateWarrantyRegistration$Params } from '../fn/warranty-registration/create-warranty-registration';
import { downloadFile } from '../fn/warranty-registration/download-file';
import { DownloadFile$Params } from '../fn/warranty-registration/download-file';
import { getForEdit } from '../fn/warranty-registration/get-for-edit';
import { GetForEdit$Params } from '../fn/warranty-registration/get-for-edit';
import { getForEdit$Plain } from '../fn/warranty-registration/get-for-edit-plain';
import { GetForEdit$Plain$Params } from '../fn/warranty-registration/get-for-edit-plain';
import { getForView } from '../fn/warranty-registration/get-for-view';
import { GetForView$Params } from '../fn/warranty-registration/get-for-view';
import { getForView$Plain } from '../fn/warranty-registration/get-for-view-plain';
import { GetForView$Plain$Params } from '../fn/warranty-registration/get-for-view-plain';
import { getWarrantiesRegistrationsListGrid } from '../fn/warranty-registration/get-warranties-registrations-list-grid';
import { GetWarrantiesRegistrationsListGrid$Params } from '../fn/warranty-registration/get-warranties-registrations-list-grid';
import { getWarrantiesRegistrationsListGrid$Plain } from '../fn/warranty-registration/get-warranties-registrations-list-grid-plain';
import { GetWarrantiesRegistrationsListGrid$Plain$Params } from '../fn/warranty-registration/get-warranties-registrations-list-grid-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { rescope } from '../fn/warranty-registration/rescope';
import { Rescope$Params } from '../fn/warranty-registration/rescope';
import { rescope$Plain } from '../fn/warranty-registration/rescope-plain';
import { Rescope$Plain$Params } from '../fn/warranty-registration/rescope-plain';
import { updateWarrantyRegistration } from '../fn/warranty-registration/update-warranty-registration';
import { UpdateWarrantyRegistration$Params } from '../fn/warranty-registration/update-warranty-registration';
import { WarrantyRegistrationForEditViewModel } from '../models/warranty-registration-for-edit-view-model';
import { WarrantyRegistrationForViewDto } from '../models/warranty-registration-for-view-dto';

@Injectable({ providedIn: 'root' })
export class WarrantyRegistrationApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `warrantyRegistrationControllerGetWarrantiesRegistrationsListGridoptionsDeliveryPointId()` */
  static readonly WarrantyRegistrationControllerGetWarrantiesRegistrationsListGridoptionsDeliveryPointIdPath = '/warrantyregistration/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesRegistrationsListGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesRegistrationsListGrid$Plain$Response(params?: GetWarrantiesRegistrationsListGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return getWarrantiesRegistrationsListGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesRegistrationsListGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesRegistrationsListGrid$Plain(params?: GetWarrantiesRegistrationsListGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.getWarrantiesRegistrationsListGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesRegistrationsListGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesRegistrationsListGrid$Response(params?: GetWarrantiesRegistrationsListGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return getWarrantiesRegistrationsListGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesRegistrationsListGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesRegistrationsListGrid(params?: GetWarrantiesRegistrationsListGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.getWarrantiesRegistrationsListGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `warrantyRegistrationControllerCreateWarrantyRegistrationsaveWarrantyRegistrationDto()` */
  static readonly WarrantyRegistrationControllerCreateWarrantyRegistrationsaveWarrantyRegistrationDtoPath = '/warrantyregistration/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWarrantyRegistration()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWarrantyRegistration$Response(params?: CreateWarrantyRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createWarrantyRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWarrantyRegistration$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWarrantyRegistration(params?: CreateWarrantyRegistration$Params, context?: HttpContext): Observable<void> {
    return this.createWarrantyRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `warrantyRegistrationControllerUpdateWarrantyRegistrationsaveWarrantyRegistrationDto()` */
  static readonly WarrantyRegistrationControllerUpdateWarrantyRegistrationsaveWarrantyRegistrationDtoPath = '/warrantyregistration/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWarrantyRegistration()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateWarrantyRegistration$Response(params?: UpdateWarrantyRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateWarrantyRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWarrantyRegistration$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateWarrantyRegistration(params?: UpdateWarrantyRegistration$Params, context?: HttpContext): Observable<void> {
    return this.updateWarrantyRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `warrantyRegistrationControllerGetForEditid()` */
  static readonly WarrantyRegistrationControllerGetForEditidPath = '/warrantyregistration/{id}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Plain$Response(params: GetForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyRegistrationForEditViewModel>> {
    return getForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Plain(params: GetForEdit$Plain$Params, context?: HttpContext): Observable<WarrantyRegistrationForEditViewModel> {
    return this.getForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyRegistrationForEditViewModel>): WarrantyRegistrationForEditViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Response(params: GetForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyRegistrationForEditViewModel>> {
    return getForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit(params: GetForEdit$Params, context?: HttpContext): Observable<WarrantyRegistrationForEditViewModel> {
    return this.getForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyRegistrationForEditViewModel>): WarrantyRegistrationForEditViewModel => r.body)
    );
  }

  /** Path part for operation `warrantyRegistrationControllerGetForViewid()` */
  static readonly WarrantyRegistrationControllerGetForViewidPath = '/warrantyregistration/{id}/view';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForView$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForView$Plain$Response(params: GetForView$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyRegistrationForViewDto>> {
    return getForView$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForView$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForView$Plain(params: GetForView$Plain$Params, context?: HttpContext): Observable<WarrantyRegistrationForViewDto> {
    return this.getForView$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyRegistrationForViewDto>): WarrantyRegistrationForViewDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForView()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForView$Response(params: GetForView$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyRegistrationForViewDto>> {
    return getForView(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForView(params: GetForView$Params, context?: HttpContext): Observable<WarrantyRegistrationForViewDto> {
    return this.getForView$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyRegistrationForViewDto>): WarrantyRegistrationForViewDto => r.body)
    );
  }

  /** Path part for operation `warrantyRegistrationControllerRescope()` */
  static readonly WarrantyRegistrationControllerRescopePath = '/warrantyregistration/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain$Response(params?: Rescope$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain(params?: Rescope$Plain$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Response(params?: Rescope$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope(params?: Rescope$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `warrantyRegistrationControllerDownloadFileidUserfileid()` */
  static readonly WarrantyRegistrationControllerDownloadFileidUserfileidPath = '/warrantyregistration/{id}/file/{userfileid}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile$Response(params: DownloadFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile(params: DownloadFile$Params, context?: HttpContext): Observable<void> {
    return this.downloadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
