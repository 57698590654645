import { Injectable } from "@angular/core";
import { MibpFeatureTourComponent } from '../feature-tour/feature-tour.component';
import { PopupPositionArgs, PopupPositionResult } from "../popup-container/popup-container.types";
import { FeatureTourGuideStep } from "./feature-tour.types";
@Injectable({
  providedIn: 'root'
})
export class MibpFeatureTourService {

  private globalComponent: MibpFeatureTourComponent;
  tourId = 'sprint-25';
  steps: FeatureTourGuideStep[] = [
    {
      elementSelector: '.tiles-grid #dashboard-tileType-5',
      bodyResourceString: 'FeatureTour_TileChange_Description',
      titleResourceString: 'Onboarding_SidebarToggle_Title',
      anchorTo: 'bottom-center',
      anchorPosition: 'top-center',
    },
  ];
  public registerGlobalComponent(component: MibpFeatureTourComponent): void {
    this.globalComponent = component;
  }

  public testNewFeatureTour(): void {
    this.globalComponent.testNewFeatureTour();
  }

}
