/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { delete$ } from '../fn/product-notifications/delete';
import { Delete$Params } from '../fn/product-notifications/delete';
import { getProductNotification } from '../fn/product-notifications/get-product-notification';
import { GetProductNotification$Params } from '../fn/product-notifications/get-product-notification';
import { getProductNotification$Plain } from '../fn/product-notifications/get-product-notification-plain';
import { GetProductNotification$Plain$Params } from '../fn/product-notifications/get-product-notification-plain';
import { getProductNotificationForEdit } from '../fn/product-notifications/get-product-notification-for-edit';
import { GetProductNotificationForEdit$Params } from '../fn/product-notifications/get-product-notification-for-edit';
import { getProductNotificationForEdit$Plain } from '../fn/product-notifications/get-product-notification-for-edit-plain';
import { GetProductNotificationForEdit$Plain$Params } from '../fn/product-notifications/get-product-notification-for-edit-plain';
import { gridSearch } from '../fn/product-notifications/grid-search';
import { GridSearch$Params } from '../fn/product-notifications/grid-search';
import { gridSearch$Plain } from '../fn/product-notifications/grid-search-plain';
import { GridSearch$Plain$Params } from '../fn/product-notifications/grid-search-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { ProductNotificationForReadViewModel } from '../models/product-notification-for-read-view-model';
import { ProductNotificationViewModel } from '../models/product-notification-view-model';
import { saveProductNotification } from '../fn/product-notifications/save-product-notification';
import { SaveProductNotification$Params } from '../fn/product-notifications/save-product-notification';

@Injectable({ providedIn: 'root' })
export class ProductNotificationsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `productNotificationsControllerSaveProductNotificationpn()` */
  static readonly ProductNotificationsControllerSaveProductNotificationpnPath = '/productnotifications/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProductNotification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductNotification$Response(params?: SaveProductNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveProductNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveProductNotification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductNotification(params?: SaveProductNotification$Params, context?: HttpContext): Observable<void> {
    return this.saveProductNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `productNotificationsControllerGetProductNotificationForEditid()` */
  static readonly ProductNotificationsControllerGetProductNotificationForEditidPath = '/productnotifications/edit/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductNotificationForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductNotificationForEdit$Plain$Response(params: GetProductNotificationForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductNotificationViewModel>> {
    return getProductNotificationForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductNotificationForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductNotificationForEdit$Plain(params: GetProductNotificationForEdit$Plain$Params, context?: HttpContext): Observable<ProductNotificationViewModel> {
    return this.getProductNotificationForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductNotificationViewModel>): ProductNotificationViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductNotificationForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductNotificationForEdit$Response(params: GetProductNotificationForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductNotificationViewModel>> {
    return getProductNotificationForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductNotificationForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductNotificationForEdit(params: GetProductNotificationForEdit$Params, context?: HttpContext): Observable<ProductNotificationViewModel> {
    return this.getProductNotificationForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductNotificationViewModel>): ProductNotificationViewModel => r.body)
    );
  }

  /** Path part for operation `productNotificationsControllerGetProductNotificationidLanguageCode()` */
  static readonly ProductNotificationsControllerGetProductNotificationidLanguageCodePath = '/productnotifications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductNotification$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductNotification$Plain$Response(params: GetProductNotification$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductNotificationForReadViewModel>> {
    return getProductNotification$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductNotification$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductNotification$Plain(params: GetProductNotification$Plain$Params, context?: HttpContext): Observable<ProductNotificationForReadViewModel> {
    return this.getProductNotification$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductNotificationForReadViewModel>): ProductNotificationForReadViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductNotification$Response(params: GetProductNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductNotificationForReadViewModel>> {
    return getProductNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductNotification(params: GetProductNotification$Params, context?: HttpContext): Observable<ProductNotificationForReadViewModel> {
    return this.getProductNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductNotificationForReadViewModel>): ProductNotificationForReadViewModel => r.body)
    );
  }

  /** Path part for operation `productNotificationsControllerDeleteid()` */
  static readonly ProductNotificationsControllerDeleteidPath = '/productnotifications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `productNotificationsControllerGridSearchoptions()` */
  static readonly ProductNotificationsControllerGridSearchoptionsPath = '/productnotifications/searchgrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain$Response(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Response(params?: GridSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch(params?: GridSearch$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

}
