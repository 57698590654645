/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BusinessRelation } from '../models/business-relation';
import { BusinessRelationIndexSearchResponse } from '../models/business-relation-index-search-response';
import { CreditLimitCountResponse } from '../models/credit-limit-count-response';
import { getCreditLimitCount } from '../fn/business-relations/get-credit-limit-count';
import { GetCreditLimitCount$Params } from '../fn/business-relations/get-credit-limit-count';
import { getCreditLimitCount$Plain } from '../fn/business-relations/get-credit-limit-count-plain';
import { GetCreditLimitCount$Plain$Params } from '../fn/business-relations/get-credit-limit-count-plain';
import { getForEdit } from '../fn/business-relations/get-for-edit';
import { GetForEdit$Params } from '../fn/business-relations/get-for-edit';
import { getForEdit$Plain } from '../fn/business-relations/get-for-edit-plain';
import { GetForEdit$Plain$Params } from '../fn/business-relations/get-for-edit-plain';
import { getSubsciptionPermissionList } from '../fn/business-relations/get-subsciption-permission-list';
import { GetSubsciptionPermissionList$Params } from '../fn/business-relations/get-subsciption-permission-list';
import { getSubsciptionPermissionList$Plain } from '../fn/business-relations/get-subsciption-permission-list-plain';
import { GetSubsciptionPermissionList$Plain$Params } from '../fn/business-relations/get-subsciption-permission-list-plain';
import { getUserCount } from '../fn/business-relations/get-user-count';
import { GetUserCount$Params } from '../fn/business-relations/get-user-count';
import { getUserCount$Plain } from '../fn/business-relations/get-user-count-plain';
import { GetUserCount$Plain$Params } from '../fn/business-relations/get-user-count-plain';
import { gridSearch } from '../fn/business-relations/grid-search';
import { GridSearch$Params } from '../fn/business-relations/grid-search';
import { gridSearch$Plain } from '../fn/business-relations/grid-search-plain';
import { GridSearch$Plain$Params } from '../fn/business-relations/grid-search-plain';
import { gridSearchForOrganization } from '../fn/business-relations/grid-search-for-organization';
import { GridSearchForOrganization$Params } from '../fn/business-relations/grid-search-for-organization';
import { gridSearchForOrganization$Plain } from '../fn/business-relations/grid-search-for-organization-plain';
import { GridSearchForOrganization$Plain$Params } from '../fn/business-relations/grid-search-for-organization-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { PermissionDto } from '../models/permission-dto';
import { rescopeAll } from '../fn/business-relations/rescope-all';
import { RescopeAll$Params } from '../fn/business-relations/rescope-all';
import { rescopeAll$Plain } from '../fn/business-relations/rescope-all-plain';
import { RescopeAll$Plain$Params } from '../fn/business-relations/rescope-all-plain';
import { search } from '../fn/business-relations/search';
import { Search$Params } from '../fn/business-relations/search';
import { search$Plain } from '../fn/business-relations/search-plain';
import { Search$Plain$Params } from '../fn/business-relations/search-plain';
import { update } from '../fn/business-relations/update';
import { Update$Params } from '../fn/business-relations/update';
import { update$Plain } from '../fn/business-relations/update-plain';
import { Update$Plain$Params } from '../fn/business-relations/update-plain';

@Injectable({ providedIn: 'root' })
export class BusinessRelationsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `businessRelationsControllerSearchrequest()` */
  static readonly BusinessRelationsControllerSearchrequestPath = '/businessrelations/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain$Response(params?: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRelationIndexSearchResponse>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain(params?: Search$Plain$Params, context?: HttpContext): Observable<BusinessRelationIndexSearchResponse> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessRelationIndexSearchResponse>): BusinessRelationIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRelationIndexSearchResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<BusinessRelationIndexSearchResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessRelationIndexSearchResponse>): BusinessRelationIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `businessRelationsControllerGetSubsciptionPermissionListbusinessrelationid()` */
  static readonly BusinessRelationsControllerGetSubsciptionPermissionListbusinessrelationidPath = '/businessrelations/{businessrelationid}/subscriptionpermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubsciptionPermissionList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubsciptionPermissionList$Plain$Response(params: GetSubsciptionPermissionList$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PermissionDto>>> {
    return getSubsciptionPermissionList$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubsciptionPermissionList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubsciptionPermissionList$Plain(params: GetSubsciptionPermissionList$Plain$Params, context?: HttpContext): Observable<Array<PermissionDto>> {
    return this.getSubsciptionPermissionList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PermissionDto>>): Array<PermissionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubsciptionPermissionList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubsciptionPermissionList$Response(params: GetSubsciptionPermissionList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PermissionDto>>> {
    return getSubsciptionPermissionList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubsciptionPermissionList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubsciptionPermissionList(params: GetSubsciptionPermissionList$Params, context?: HttpContext): Observable<Array<PermissionDto>> {
    return this.getSubsciptionPermissionList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PermissionDto>>): Array<PermissionDto> => r.body)
    );
  }

  /** Path part for operation `businessRelationsControllerGetUserCountbusinessrelationid()` */
  static readonly BusinessRelationsControllerGetUserCountbusinessrelationidPath = '/businessrelations/{businessrelationid}/usercount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount$Plain$Response(params: GetUserCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getUserCount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount$Plain(params: GetUserCount$Plain$Params, context?: HttpContext): Observable<number> {
    return this.getUserCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount$Response(params: GetUserCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getUserCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount(params: GetUserCount$Params, context?: HttpContext): Observable<number> {
    return this.getUserCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `businessRelationsControllerUpdatesaveBusinessRelationFromAdmin()` */
  static readonly BusinessRelationsControllerUpdatesaveBusinessRelationFromAdminPath = '/businessrelations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Plain$Response(params?: Update$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return update$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Plain(params?: Update$Plain$Params, context?: HttpContext): Observable<number> {
    return this.update$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Response(params?: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update(params?: Update$Params, context?: HttpContext): Observable<number> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `businessRelationsControllerRescopeAllcompanyCodeErpCustomerId()` */
  static readonly BusinessRelationsControllerRescopeAllcompanyCodeErpCustomerIdPath = '/businessrelations/rescopeall';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeAll$Plain$Response(params?: RescopeAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescopeAll$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeAll$Plain(params?: RescopeAll$Plain$Params, context?: HttpContext): Observable<number> {
    return this.rescopeAll$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeAll$Response(params?: RescopeAll$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescopeAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeAll(params?: RescopeAll$Params, context?: HttpContext): Observable<number> {
    return this.rescopeAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `businessRelationsControllerGetForEditid()` */
  static readonly BusinessRelationsControllerGetForEditidPath = '/businessrelations/{id}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Plain$Response(params: GetForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRelation>> {
    return getForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Plain(params: GetForEdit$Plain$Params, context?: HttpContext): Observable<BusinessRelation> {
    return this.getForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessRelation>): BusinessRelation => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Response(params: GetForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRelation>> {
    return getForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit(params: GetForEdit$Params, context?: HttpContext): Observable<BusinessRelation> {
    return this.getForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessRelation>): BusinessRelation => r.body)
    );
  }

  /** Path part for operation `businessRelationsControllerGetCreditLimitCount()` */
  static readonly BusinessRelationsControllerGetCreditLimitCountPath = '/businessrelations/active/creditlimitcount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCreditLimitCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCreditLimitCount$Plain$Response(params?: GetCreditLimitCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreditLimitCountResponse>> {
    return getCreditLimitCount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCreditLimitCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCreditLimitCount$Plain(params?: GetCreditLimitCount$Plain$Params, context?: HttpContext): Observable<CreditLimitCountResponse> {
    return this.getCreditLimitCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreditLimitCountResponse>): CreditLimitCountResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCreditLimitCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCreditLimitCount$Response(params?: GetCreditLimitCount$Params, context?: HttpContext): Observable<StrictHttpResponse<CreditLimitCountResponse>> {
    return getCreditLimitCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCreditLimitCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCreditLimitCount(params?: GetCreditLimitCount$Params, context?: HttpContext): Observable<CreditLimitCountResponse> {
    return this.getCreditLimitCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreditLimitCountResponse>): CreditLimitCountResponse => r.body)
    );
  }

  /** Path part for operation `businessRelationsControllerGridSearchForOrganizationoptionsOrganizationId()` */
  static readonly BusinessRelationsControllerGridSearchForOrganizationoptionsOrganizationIdPath = '/businessrelations/searchgrid/organization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain$Response(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Response(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `businessRelationsControllerGridSearchoptions()` */
  static readonly BusinessRelationsControllerGridSearchoptionsPath = '/businessrelations/searchgrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain$Response(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Response(params?: GridSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch(params?: GridSearch$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

}
