/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { allAdminVisibleLanguagesResource } from '../fn/resource/all-admin-visible-languages-resource';
import { AllAdminVisibleLanguagesResource$Params } from '../fn/resource/all-admin-visible-languages-resource';
import { allAdminVisibleLanguagesResource$Plain } from '../fn/resource/all-admin-visible-languages-resource-plain';
import { AllAdminVisibleLanguagesResource$Plain$Params } from '../fn/resource/all-admin-visible-languages-resource-plain';
import { allLanguagesResource } from '../fn/resource/all-languages-resource';
import { AllLanguagesResource$Params } from '../fn/resource/all-languages-resource';
import { allLanguagesResource$Plain } from '../fn/resource/all-languages-resource-plain';
import { AllLanguagesResource$Plain$Params } from '../fn/resource/all-languages-resource-plain';
import { allSelectableLanguagesResource } from '../fn/resource/all-selectable-languages-resource';
import { AllSelectableLanguagesResource$Params } from '../fn/resource/all-selectable-languages-resource';
import { allSelectableLanguagesResource$Plain } from '../fn/resource/all-selectable-languages-resource-plain';
import { AllSelectableLanguagesResource$Plain$Params } from '../fn/resource/all-selectable-languages-resource-plain';
import { allVisibleLanguagesResource } from '../fn/resource/all-visible-languages-resource';
import { AllVisibleLanguagesResource$Params } from '../fn/resource/all-visible-languages-resource';
import { allVisibleLanguagesResource$Plain } from '../fn/resource/all-visible-languages-resource-plain';
import { AllVisibleLanguagesResource$Plain$Params } from '../fn/resource/all-visible-languages-resource-plain';
import { changeLanguage } from '../fn/resource/change-language';
import { ChangeLanguage$Params } from '../fn/resource/change-language';
import { findResource } from '../fn/resource/find-resource';
import { FindResource$Params } from '../fn/resource/find-resource';
import { findResource$Plain } from '../fn/resource/find-resource-plain';
import { FindResource$Plain$Params } from '../fn/resource/find-resource-plain';
import { getTranslation } from '../fn/resource/get-translation';
import { GetTranslation$Params } from '../fn/resource/get-translation';
import { getTranslation$Plain } from '../fn/resource/get-translation-plain';
import { GetTranslation$Plain$Params } from '../fn/resource/get-translation-plain';
import { import$ } from '../fn/resource/import';
import { Import$Params } from '../fn/resource/import';
import { Language } from '../models/language';
import { LanguageViewModel } from '../models/language-view-model';
import { listResource } from '../fn/resource/list-resource';
import { ListResource$Params } from '../fn/resource/list-resource';
import { listResource$Plain } from '../fn/resource/list-resource-plain';
import { ListResource$Plain$Params } from '../fn/resource/list-resource-plain';
import { resource } from '../fn/resource/resource';
import { Resource$Params } from '../fn/resource/resource';
import { resource$Plain } from '../fn/resource/resource-plain';
import { Resource$Plain$Params } from '../fn/resource/resource-plain';
import { ResourceKeyVm } from '../models/resource-key-vm';
import { ResourceTranslationLanguageOverviewViewModel } from '../models/resource-translation-language-overview-view-model';
import { ResourceTranslationVm } from '../models/resource-translation-vm';
import { ResourceUploadExcelResult } from '../models/resource-upload-excel-result';
import { ResourceValue } from '../models/resource-value';
import { ResourceViewModel } from '../models/resource-view-model';
import { saveResource } from '../fn/resource/save-resource';
import { SaveResource$Params } from '../fn/resource/save-resource';
import { translationOverviewResource } from '../fn/resource/translation-overview-resource';
import { TranslationOverviewResource$Params } from '../fn/resource/translation-overview-resource';
import { translationOverviewResource$Plain } from '../fn/resource/translation-overview-resource-plain';
import { TranslationOverviewResource$Plain$Params } from '../fn/resource/translation-overview-resource-plain';
import { uploadExcelResource } from '../fn/resource/upload-excel-resource';
import { UploadExcelResource$Params } from '../fn/resource/upload-excel-resource';
import { uploadExcelResource$Plain } from '../fn/resource/upload-excel-resource-plain';
import { UploadExcelResource$Plain$Params } from '../fn/resource/upload-excel-resource-plain';

@Injectable({ providedIn: 'root' })
export class ResourceApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `resourceControllerGetTranslationlanguageCodeKey()` */
  static readonly ResourceControllerGetTranslationlanguageCodeKeyPath = '/resource/{languageCode}/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTranslation$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTranslation$Plain$Response(params: GetTranslation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceTranslationVm>> {
    return getTranslation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTranslation$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTranslation$Plain(params: GetTranslation$Plain$Params, context?: HttpContext): Observable<ResourceTranslationVm> {
    return this.getTranslation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceTranslationVm>): ResourceTranslationVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTranslation$Response(params: GetTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceTranslationVm>> {
    return getTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTranslation(params: GetTranslation$Params, context?: HttpContext): Observable<ResourceTranslationVm> {
    return this.getTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceTranslationVm>): ResourceTranslationVm => r.body)
    );
  }

  /** Path part for operation `resourceControllerListResourcelanguageCodeIfModifiedAfter()` */
  static readonly ResourceControllerListResourcelanguageCodeIfModifiedAfterPath = '/resource/listresource/{languageCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listResource$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listResource$Plain$Response(params: ListResource$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceViewModel>> {
    return listResource$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listResource$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listResource$Plain(params: ListResource$Plain$Params, context?: HttpContext): Observable<ResourceViewModel> {
    return this.listResource$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceViewModel>): ResourceViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  listResource$Response(params: ListResource$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceViewModel>> {
    return listResource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listResource(params: ListResource$Params, context?: HttpContext): Observable<ResourceViewModel> {
    return this.listResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceViewModel>): ResourceViewModel => r.body)
    );
  }

  /** Path part for operation `resourceControllerChangeLanguagelanguageCode()` */
  static readonly ResourceControllerChangeLanguagelanguageCodePath = '/resource/change/{languageCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeLanguage()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeLanguage$Response(params: ChangeLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeLanguage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeLanguage(params: ChangeLanguage$Params, context?: HttpContext): Observable<void> {
    return this.changeLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resourceControllerImportlanguageCode()` */
  static readonly ResourceControllerImportlanguageCodePath = '/resource/import/{languageCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `import()` instead.
   *
   * This method doesn't expect any request body.
   */
  import$Response(params: Import$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return import$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `import$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  import(params: Import$Params, context?: HttpContext): Observable<void> {
    return this.import$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resourceControllerSaveResourcerequest()` */
  static readonly ResourceControllerSaveResourcerequestPath = '/resource/saveresource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveResource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveResource$Response(params?: SaveResource$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveResource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveResource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveResource(params?: SaveResource$Params, context?: HttpContext): Observable<void> {
    return this.saveResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resourceControllerFindResourcelanguageCodeQueryKeysOnlyUntranslated()` */
  static readonly ResourceControllerFindResourcelanguageCodeQueryKeysOnlyUntranslatedPath = '/resource/findresource/{languageCode}/{query}/{keys}/{onlyUntranslated}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findResource$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findResource$Plain$Response(params: FindResource$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResourceKeyVm>>> {
    return findResource$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findResource$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findResource$Plain(params: FindResource$Plain$Params, context?: HttpContext): Observable<Array<ResourceKeyVm>> {
    return this.findResource$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResourceKeyVm>>): Array<ResourceKeyVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findResource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findResource$Response(params: FindResource$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResourceKeyVm>>> {
    return findResource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findResource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findResource(params: FindResource$Params, context?: HttpContext): Observable<Array<ResourceKeyVm>> {
    return this.findResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResourceKeyVm>>): Array<ResourceKeyVm> => r.body)
    );
  }

  /** Path part for operation `resourceControllerAllLanguagesResource()` */
  static readonly ResourceControllerAllLanguagesResourcePath = '/resource/alllanguagesresource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allLanguagesResource$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  allLanguagesResource$Plain$Response(params?: AllLanguagesResource$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LanguageViewModel>>> {
    return allLanguagesResource$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allLanguagesResource$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allLanguagesResource$Plain(params?: AllLanguagesResource$Plain$Params, context?: HttpContext): Observable<Array<LanguageViewModel>> {
    return this.allLanguagesResource$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LanguageViewModel>>): Array<LanguageViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allLanguagesResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  allLanguagesResource$Response(params?: AllLanguagesResource$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LanguageViewModel>>> {
    return allLanguagesResource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allLanguagesResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allLanguagesResource(params?: AllLanguagesResource$Params, context?: HttpContext): Observable<Array<LanguageViewModel>> {
    return this.allLanguagesResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LanguageViewModel>>): Array<LanguageViewModel> => r.body)
    );
  }

  /** Path part for operation `resourceControllerResourcelanguageCodeKey()` */
  static readonly ResourceControllerResourcelanguageCodeKeyPath = '/resource/resource/{languageCode}/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resource$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  resource$Plain$Response(params: Resource$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceValue>> {
    return resource$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resource$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resource$Plain(params: Resource$Plain$Params, context?: HttpContext): Observable<ResourceValue> {
    return this.resource$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceValue>): ResourceValue => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resource()` instead.
   *
   * This method doesn't expect any request body.
   */
  resource$Response(params: Resource$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceValue>> {
    return resource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resource(params: Resource$Params, context?: HttpContext): Observable<ResourceValue> {
    return this.resource$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceValue>): ResourceValue => r.body)
    );
  }

  /** Path part for operation `resourceControllerAllSelectableLanguagesResource()` */
  static readonly ResourceControllerAllSelectableLanguagesResourcePath = '/resource/allselectablelanguagesresource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allSelectableLanguagesResource$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  allSelectableLanguagesResource$Plain$Response(params?: AllSelectableLanguagesResource$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return allSelectableLanguagesResource$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allSelectableLanguagesResource$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allSelectableLanguagesResource$Plain(params?: AllSelectableLanguagesResource$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.allSelectableLanguagesResource$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allSelectableLanguagesResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  allSelectableLanguagesResource$Response(params?: AllSelectableLanguagesResource$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return allSelectableLanguagesResource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allSelectableLanguagesResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allSelectableLanguagesResource(params?: AllSelectableLanguagesResource$Params, context?: HttpContext): Observable<Array<string>> {
    return this.allSelectableLanguagesResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `resourceControllerAllAdminVisibleLanguagesResource()` */
  static readonly ResourceControllerAllAdminVisibleLanguagesResourcePath = '/resource/alladminvisiblelanguagesresource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allAdminVisibleLanguagesResource$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  allAdminVisibleLanguagesResource$Plain$Response(params?: AllAdminVisibleLanguagesResource$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return allAdminVisibleLanguagesResource$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allAdminVisibleLanguagesResource$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allAdminVisibleLanguagesResource$Plain(params?: AllAdminVisibleLanguagesResource$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.allAdminVisibleLanguagesResource$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allAdminVisibleLanguagesResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  allAdminVisibleLanguagesResource$Response(params?: AllAdminVisibleLanguagesResource$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return allAdminVisibleLanguagesResource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allAdminVisibleLanguagesResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allAdminVisibleLanguagesResource(params?: AllAdminVisibleLanguagesResource$Params, context?: HttpContext): Observable<Array<string>> {
    return this.allAdminVisibleLanguagesResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `resourceControllerAllVisibleLanguagesResource()` */
  static readonly ResourceControllerAllVisibleLanguagesResourcePath = '/resource/allvisiblelanguagesresource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allVisibleLanguagesResource$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  allVisibleLanguagesResource$Plain$Response(params?: AllVisibleLanguagesResource$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Language>>> {
    return allVisibleLanguagesResource$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allVisibleLanguagesResource$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allVisibleLanguagesResource$Plain(params?: AllVisibleLanguagesResource$Plain$Params, context?: HttpContext): Observable<Array<Language>> {
    return this.allVisibleLanguagesResource$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Language>>): Array<Language> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allVisibleLanguagesResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  allVisibleLanguagesResource$Response(params?: AllVisibleLanguagesResource$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Language>>> {
    return allVisibleLanguagesResource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allVisibleLanguagesResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allVisibleLanguagesResource(params?: AllVisibleLanguagesResource$Params, context?: HttpContext): Observable<Array<Language>> {
    return this.allVisibleLanguagesResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Language>>): Array<Language> => r.body)
    );
  }

  /** Path part for operation `resourceControllerUploadExcelResourcedataFilenameLanguageCode()` */
  static readonly ResourceControllerUploadExcelResourcedataFilenameLanguageCodePath = '/resource/uploadexcelresource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadExcelResource$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadExcelResource$Plain$Response(params?: UploadExcelResource$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResourceUploadExcelResult>>> {
    return uploadExcelResource$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadExcelResource$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadExcelResource$Plain(params?: UploadExcelResource$Plain$Params, context?: HttpContext): Observable<Array<ResourceUploadExcelResult>> {
    return this.uploadExcelResource$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResourceUploadExcelResult>>): Array<ResourceUploadExcelResult> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadExcelResource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadExcelResource$Response(params?: UploadExcelResource$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResourceUploadExcelResult>>> {
    return uploadExcelResource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadExcelResource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadExcelResource(params?: UploadExcelResource$Params, context?: HttpContext): Observable<Array<ResourceUploadExcelResult>> {
    return this.uploadExcelResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResourceUploadExcelResult>>): Array<ResourceUploadExcelResult> => r.body)
    );
  }

  /** Path part for operation `resourceControllerTranslationOverviewResource()` */
  static readonly ResourceControllerTranslationOverviewResourcePath = '/resource/translationoverviewresource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `translationOverviewResource$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  translationOverviewResource$Plain$Response(params?: TranslationOverviewResource$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResourceTranslationLanguageOverviewViewModel>>> {
    return translationOverviewResource$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `translationOverviewResource$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  translationOverviewResource$Plain(params?: TranslationOverviewResource$Plain$Params, context?: HttpContext): Observable<Array<ResourceTranslationLanguageOverviewViewModel>> {
    return this.translationOverviewResource$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResourceTranslationLanguageOverviewViewModel>>): Array<ResourceTranslationLanguageOverviewViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `translationOverviewResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  translationOverviewResource$Response(params?: TranslationOverviewResource$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResourceTranslationLanguageOverviewViewModel>>> {
    return translationOverviewResource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `translationOverviewResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  translationOverviewResource(params?: TranslationOverviewResource$Params, context?: HttpContext): Observable<Array<ResourceTranslationLanguageOverviewViewModel>> {
    return this.translationOverviewResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResourceTranslationLanguageOverviewViewModel>>): Array<ResourceTranslationLanguageOverviewViewModel> => r.body)
    );
  }

}
