/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acceptTermsAndConditions } from '../fn/users/accept-terms-and-conditions';
import { AcceptTermsAndConditions$Params } from '../fn/users/accept-terms-and-conditions';
import { addPunchoutProfile } from '../fn/users/add-punchout-profile';
import { AddPunchoutProfile$Params } from '../fn/users/add-punchout-profile';
import { addPunchoutProfile$Plain } from '../fn/users/add-punchout-profile-plain';
import { AddPunchoutProfile$Plain$Params } from '../fn/users/add-punchout-profile-plain';
import { BooleanApiResponse } from '../models/boolean-api-response';
import { completeExternalUserSignup } from '../fn/users/complete-external-user-signup';
import { CompleteExternalUserSignup$Params } from '../fn/users/complete-external-user-signup';
import { completeExternalUserSignup$Plain } from '../fn/users/complete-external-user-signup-plain';
import { CompleteExternalUserSignup$Plain$Params } from '../fn/users/complete-external-user-signup-plain';
import { createExternalUser } from '../fn/users/create-external-user';
import { CreateExternalUser$Params } from '../fn/users/create-external-user';
import { createExternalUser$Plain } from '../fn/users/create-external-user-plain';
import { CreateExternalUser$Plain$Params } from '../fn/users/create-external-user-plain';
import { createInternalUser } from '../fn/users/create-internal-user';
import { CreateInternalUser$Params } from '../fn/users/create-internal-user';
import { createInternalUser$Plain } from '../fn/users/create-internal-user-plain';
import { CreateInternalUser$Plain$Params } from '../fn/users/create-internal-user-plain';
import { deletePunchoutProfile } from '../fn/users/delete-punchout-profile';
import { DeletePunchoutProfile$Params } from '../fn/users/delete-punchout-profile';
import { detectConcurrentUserSessions } from '../fn/users/detect-concurrent-user-sessions';
import { DetectConcurrentUserSessions$Params } from '../fn/users/detect-concurrent-user-sessions';
import { detectConcurrentUserSessions$Plain } from '../fn/users/detect-concurrent-user-sessions-plain';
import { DetectConcurrentUserSessions$Plain$Params } from '../fn/users/detect-concurrent-user-sessions-plain';
import { disconnectUser } from '../fn/users/disconnect-user';
import { DisconnectUser$Params } from '../fn/users/disconnect-user';
import { disconnectUserConnections } from '../fn/users/disconnect-user-connections';
import { DisconnectUserConnections$Params } from '../fn/users/disconnect-user-connections';
import { ensureActiveDeliverySequence } from '../fn/users/ensure-active-delivery-sequence';
import { EnsureActiveDeliverySequence$Params } from '../fn/users/ensure-active-delivery-sequence';
import { ensureActiveDeliverySequence$Plain } from '../fn/users/ensure-active-delivery-sequence-plain';
import { EnsureActiveDeliverySequence$Plain$Params } from '../fn/users/ensure-active-delivery-sequence-plain';
import { exists } from '../fn/users/exists';
import { Exists$Params } from '../fn/users/exists';
import { exists$Plain } from '../fn/users/exists-plain';
import { Exists$Plain$Params } from '../fn/users/exists-plain';
import { getCompletedNewFeatureTour } from '../fn/users/get-completed-new-feature-tour';
import { GetCompletedNewFeatureTour$Params } from '../fn/users/get-completed-new-feature-tour';
import { getCompletedNewFeatureTour$Plain } from '../fn/users/get-completed-new-feature-tour-plain';
import { GetCompletedNewFeatureTour$Plain$Params } from '../fn/users/get-completed-new-feature-tour-plain';
import { getCompletedOnboardings } from '../fn/users/get-completed-onboardings';
import { GetCompletedOnboardings$Params } from '../fn/users/get-completed-onboardings';
import { getCompletedOnboardings$Plain } from '../fn/users/get-completed-onboardings-plain';
import { GetCompletedOnboardings$Plain$Params } from '../fn/users/get-completed-onboardings-plain';
import { getCurrentUserDashboardTileSettings } from '../fn/users/get-current-user-dashboard-tile-settings';
import { GetCurrentUserDashboardTileSettings$Params } from '../fn/users/get-current-user-dashboard-tile-settings';
import { getCurrentUserDashboardTileSettings$Plain } from '../fn/users/get-current-user-dashboard-tile-settings-plain';
import { GetCurrentUserDashboardTileSettings$Plain$Params } from '../fn/users/get-current-user-dashboard-tile-settings-plain';
import { getCurrentUserHash } from '../fn/users/get-current-user-hash';
import { GetCurrentUserHash$Params } from '../fn/users/get-current-user-hash';
import { getCurrentUserHash$Plain } from '../fn/users/get-current-user-hash-plain';
import { GetCurrentUserHash$Plain$Params } from '../fn/users/get-current-user-hash-plain';
import { getCurrentUserNotificationSettings } from '../fn/users/get-current-user-notification-settings';
import { GetCurrentUserNotificationSettings$Params } from '../fn/users/get-current-user-notification-settings';
import { getCurrentUserNotificationSettings$Plain } from '../fn/users/get-current-user-notification-settings-plain';
import { GetCurrentUserNotificationSettings$Plain$Params } from '../fn/users/get-current-user-notification-settings-plain';
import { getCurrentUserPermissions } from '../fn/users/get-current-user-permissions';
import { GetCurrentUserPermissions$Params } from '../fn/users/get-current-user-permissions';
import { getCurrentUserPermissions$Plain } from '../fn/users/get-current-user-permissions-plain';
import { GetCurrentUserPermissions$Plain$Params } from '../fn/users/get-current-user-permissions-plain';
import { getCurrentUserRole } from '../fn/users/get-current-user-role';
import { GetCurrentUserRole$Params } from '../fn/users/get-current-user-role';
import { getCurrentUserRole$Plain } from '../fn/users/get-current-user-role-plain';
import { GetCurrentUserRole$Plain$Params } from '../fn/users/get-current-user-role-plain';
import { getForEditing } from '../fn/users/get-for-editing';
import { GetForEditing$Params } from '../fn/users/get-for-editing';
import { getForEditing$Plain } from '../fn/users/get-for-editing-plain';
import { GetForEditing$Plain$Params } from '../fn/users/get-for-editing-plain';
import { getOrganizationScopes } from '../fn/users/get-organization-scopes';
import { GetOrganizationScopes$Params } from '../fn/users/get-organization-scopes';
import { getOrganizationScopes$Plain } from '../fn/users/get-organization-scopes-plain';
import { GetOrganizationScopes$Plain$Params } from '../fn/users/get-organization-scopes-plain';
import { getPermissionsForUser } from '../fn/users/get-permissions-for-user';
import { GetPermissionsForUser$Params } from '../fn/users/get-permissions-for-user';
import { getPermissionsForUser$Plain } from '../fn/users/get-permissions-for-user-plain';
import { GetPermissionsForUser$Plain$Params } from '../fn/users/get-permissions-for-user-plain';
import { getProfile } from '../fn/users/get-profile';
import { GetProfile$Params } from '../fn/users/get-profile';
import { getProfile$Plain } from '../fn/users/get-profile-plain';
import { GetProfile$Plain$Params } from '../fn/users/get-profile-plain';
import { getProfileBase64 } from '../fn/users/get-profile-base-64';
import { GetProfileBase64$Params } from '../fn/users/get-profile-base-64';
import { getProfileBase64$Plain } from '../fn/users/get-profile-base-64-plain';
import { GetProfileBase64$Plain$Params } from '../fn/users/get-profile-base-64-plain';
import { getPunchoutProfiles } from '../fn/users/get-punchout-profiles';
import { GetPunchoutProfiles$Params } from '../fn/users/get-punchout-profiles';
import { getPunchoutProfiles$Plain } from '../fn/users/get-punchout-profiles-plain';
import { GetPunchoutProfiles$Plain$Params } from '../fn/users/get-punchout-profiles-plain';
import { getRoleForUser } from '../fn/users/get-role-for-user';
import { GetRoleForUser$Params } from '../fn/users/get-role-for-user';
import { getRoleForUser$Plain } from '../fn/users/get-role-for-user-plain';
import { GetRoleForUser$Plain$Params } from '../fn/users/get-role-for-user-plain';
import { getUserFromAD } from '../fn/users/get-user-from-ad';
import { GetUserFromAD$Params } from '../fn/users/get-user-from-ad';
import { getUserFromAD$Plain } from '../fn/users/get-user-from-ad-plain';
import { GetUserFromAD$Plain$Params } from '../fn/users/get-user-from-ad-plain';
import { isEmailDomainValid } from '../fn/users/is-email-domain-valid';
import { IsEmailDomainValid$Params } from '../fn/users/is-email-domain-valid';
import { isEmailDomainValid$Plain } from '../fn/users/is-email-domain-valid-plain';
import { IsEmailDomainValid$Plain$Params } from '../fn/users/is-email-domain-valid-plain';
import { isMainPunchoutProfileUserByUserId } from '../fn/users/is-main-punchout-profile-user-by-user-id';
import { IsMainPunchoutProfileUserByUserId$Params } from '../fn/users/is-main-punchout-profile-user-by-user-id';
import { isMainPunchoutProfileUserByUserId$Plain } from '../fn/users/is-main-punchout-profile-user-by-user-id-plain';
import { IsMainPunchoutProfileUserByUserId$Plain$Params } from '../fn/users/is-main-punchout-profile-user-by-user-id-plain';
import { isPunchoutProfileUserByUserId } from '../fn/users/is-punchout-profile-user-by-user-id';
import { IsPunchoutProfileUserByUserId$Params } from '../fn/users/is-punchout-profile-user-by-user-id';
import { isPunchoutProfileUserByUserId$Plain } from '../fn/users/is-punchout-profile-user-by-user-id-plain';
import { IsPunchoutProfileUserByUserId$Plain$Params } from '../fn/users/is-punchout-profile-user-by-user-id-plain';
import { loginAsPunchoutProfile } from '../fn/users/login-as-punchout-profile';
import { LoginAsPunchoutProfile$Params } from '../fn/users/login-as-punchout-profile';
import { loginAsPunchoutProfile$Plain } from '../fn/users/login-as-punchout-profile-plain';
import { LoginAsPunchoutProfile$Plain$Params } from '../fn/users/login-as-punchout-profile-plain';
import { MibpGetUserSessionResponseViewModel } from '../models/mibp-get-user-session-response-view-model';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { PermissionDto } from '../models/permission-dto';
import { PunchoutProfileViewModel } from '../models/punchout-profile-view-model';
import { renamePunchoutProfile } from '../fn/users/rename-punchout-profile';
import { RenamePunchoutProfile$Params } from '../fn/users/rename-punchout-profile';
import { renamePunchoutProfile$Plain } from '../fn/users/rename-punchout-profile-plain';
import { RenamePunchoutProfile$Plain$Params } from '../fn/users/rename-punchout-profile-plain';
import { rescopeAllUsers } from '../fn/users/rescope-all-users';
import { RescopeAllUsers$Params } from '../fn/users/rescope-all-users';
import { rescopeAllUsers$Plain } from '../fn/users/rescope-all-users-plain';
import { RescopeAllUsers$Plain$Params } from '../fn/users/rescope-all-users-plain';
import { rescopeUser } from '../fn/users/rescope-user';
import { RescopeUser$Params } from '../fn/users/rescope-user';
import { rescopeUser$Plain } from '../fn/users/rescope-user-plain';
import { RescopeUser$Plain$Params } from '../fn/users/rescope-user-plain';
import { RoleDto } from '../models/role-dto';
import { SandvikAdUserViewModel } from '../models/sandvik-ad-user-view-model';
import { saveCurrentUserDashboardTileSettings } from '../fn/users/save-current-user-dashboard-tile-settings';
import { SaveCurrentUserDashboardTileSettings$Params } from '../fn/users/save-current-user-dashboard-tile-settings';
import { saveCurrentUserNotificationSettings } from '../fn/users/save-current-user-notification-settings';
import { SaveCurrentUserNotificationSettings$Params } from '../fn/users/save-current-user-notification-settings';
import { saveProfile } from '../fn/users/save-profile';
import { SaveProfile$Params } from '../fn/users/save-profile';
import { Scope } from '../models/scope';
import { search } from '../fn/users/search';
import { Search$Params } from '../fn/users/search';
import { search$Plain } from '../fn/users/search-plain';
import { Search$Plain$Params } from '../fn/users/search-plain';
import { searchInOrganization } from '../fn/users/search-in-organization';
import { SearchInOrganization$Params } from '../fn/users/search-in-organization';
import { searchInOrganization$Plain } from '../fn/users/search-in-organization-plain';
import { SearchInOrganization$Plain$Params } from '../fn/users/search-in-organization-plain';
import { SelectProfileEvent } from '../models/select-profile-event';
import { setCompletedNewFeatureTour } from '../fn/users/set-completed-new-feature-tour';
import { SetCompletedNewFeatureTour$Params } from '../fn/users/set-completed-new-feature-tour';
import { setCompletedNewFeatureTour$Plain } from '../fn/users/set-completed-new-feature-tour-plain';
import { SetCompletedNewFeatureTour$Plain$Params } from '../fn/users/set-completed-new-feature-tour-plain';
import { setCompletedOnboarding } from '../fn/users/set-completed-onboarding';
import { SetCompletedOnboarding$Params } from '../fn/users/set-completed-onboarding';
import { setCompletedOnboarding$Plain } from '../fn/users/set-completed-onboarding-plain';
import { SetCompletedOnboarding$Plain$Params } from '../fn/users/set-completed-onboarding-plain';
import { updateDefaultDeliverySequence } from '../fn/users/update-default-delivery-sequence';
import { UpdateDefaultDeliverySequence$Params } from '../fn/users/update-default-delivery-sequence';
import { updateDefaultDeliverySequenceForBusinessRelation } from '../fn/users/update-default-delivery-sequence-for-business-relation';
import { UpdateDefaultDeliverySequenceForBusinessRelation$Params } from '../fn/users/update-default-delivery-sequence-for-business-relation';
import { updateExternalUser } from '../fn/users/update-external-user';
import { UpdateExternalUser$Params } from '../fn/users/update-external-user';
import { updateExternalUser$Plain } from '../fn/users/update-external-user-plain';
import { UpdateExternalUser$Plain$Params } from '../fn/users/update-external-user-plain';
import { updateIndex } from '../fn/users/update-index';
import { UpdateIndex$Params } from '../fn/users/update-index';
import { updateIndex$Plain } from '../fn/users/update-index-plain';
import { UpdateIndex$Plain$Params } from '../fn/users/update-index-plain';
import { updateInternalUser } from '../fn/users/update-internal-user';
import { UpdateInternalUser$Params } from '../fn/users/update-internal-user';
import { updateInternalUser$Plain } from '../fn/users/update-internal-user-plain';
import { UpdateInternalUser$Plain$Params } from '../fn/users/update-internal-user-plain';
import { UserDeliverySequenceResponse } from '../models/user-delivery-sequence-response';
import { UserForEditingViewModel } from '../models/user-for-editing-view-model';
import { UserNotificationSetingsDto } from '../models/user-notification-setings-dto';
import { UserProfileViewModel } from '../models/user-profile-view-model';
import { UserPropertyType } from '../models/user-property-type';
import { UserTileSetting } from '../models/user-tile-setting';

@Injectable({ providedIn: 'root' })
export class UsersApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usersControllerGetForEditingid()` */
  static readonly UsersControllerGetForEditingidPath = '/users/{id}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Plain$Response(params: GetForEditing$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserForEditingViewModel>> {
    return getForEditing$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Plain(params: GetForEditing$Plain$Params, context?: HttpContext): Observable<UserForEditingViewModel> {
    return this.getForEditing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserForEditingViewModel>): UserForEditingViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditing()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Response(params: GetForEditing$Params, context?: HttpContext): Observable<StrictHttpResponse<UserForEditingViewModel>> {
    return getForEditing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing(params: GetForEditing$Params, context?: HttpContext): Observable<UserForEditingViewModel> {
    return this.getForEditing$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserForEditingViewModel>): UserForEditingViewModel => r.body)
    );
  }

  /** Path part for operation `usersControllerCompleteExternalUserSignupuser()` */
  static readonly UsersControllerCompleteExternalUserSignupuserPath = '/users/signup/external';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeExternalUserSignup$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  completeExternalUserSignup$Plain$Response(params?: CompleteExternalUserSignup$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return completeExternalUserSignup$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeExternalUserSignup$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  completeExternalUserSignup$Plain(params?: CompleteExternalUserSignup$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.completeExternalUserSignup$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeExternalUserSignup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  completeExternalUserSignup$Response(params?: CompleteExternalUserSignup$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return completeExternalUserSignup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeExternalUserSignup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  completeExternalUserSignup(params?: CompleteExternalUserSignup$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.completeExternalUserSignup$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /** Path part for operation `usersControllerAcceptTermsAndConditions()` */
  static readonly UsersControllerAcceptTermsAndConditionsPath = '/users/me/acceptterms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptTermsAndConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptTermsAndConditions$Response(params?: AcceptTermsAndConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return acceptTermsAndConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptTermsAndConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptTermsAndConditions(params?: AcceptTermsAndConditions$Params, context?: HttpContext): Observable<void> {
    return this.acceptTermsAndConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerExistsemailUserType()` */
  static readonly UsersControllerExistsemailUserTypePath = '/users/exists/{userType}';

  /**
   * Check if the user exists with the given email and user type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists$Plain$Response(params: Exists$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return exists$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if the user exists with the given email and user type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists$Plain(params: Exists$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.exists$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * Check if the user exists with the given email and user type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exists()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists$Response(params: Exists$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return exists(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if the user exists with the given email and user type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists(params: Exists$Params, context?: HttpContext): Observable<boolean> {
    return this.exists$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usersControllerIsEmailDomainValiddomainName()` */
  static readonly UsersControllerIsEmailDomainValiddomainNamePath = '/users/validate/domain/{domainName}';

  /**
   * Check if a domain is marked as valid in the database.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isEmailDomainValid$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isEmailDomainValid$Plain$Response(params: IsEmailDomainValid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isEmailDomainValid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if a domain is marked as valid in the database.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isEmailDomainValid$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isEmailDomainValid$Plain(params: IsEmailDomainValid$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.isEmailDomainValid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * Check if a domain is marked as valid in the database.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isEmailDomainValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  isEmailDomainValid$Response(params: IsEmailDomainValid$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isEmailDomainValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if a domain is marked as valid in the database.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isEmailDomainValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isEmailDomainValid(params: IsEmailDomainValid$Params, context?: HttpContext): Observable<boolean> {
    return this.isEmailDomainValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usersControllerSearchInOrganizationgridOptionsOrganizationId()` */
  static readonly UsersControllerSearchInOrganizationgridOptionsOrganizationIdPath = '/users/search/inorganization/{organizationId}';

  /**
   * Search for users visible by the specified organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchInOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchInOrganization$Plain$Response(params: SearchInOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchInOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for users visible by the specified organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchInOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchInOrganization$Plain(params: SearchInOrganization$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchInOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * Search for users visible by the specified organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchInOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchInOrganization$Response(params: SearchInOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchInOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for users visible by the specified organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchInOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchInOrganization(params: SearchInOrganization$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchInOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `usersControllerGetProfile()` */
  static readonly UsersControllerGetProfilePath = '/users/me/profile';

  /**
   * Get the profile details for the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile$Plain$Response(params?: GetProfile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserProfileViewModel>> {
    return getProfile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the profile details for the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile$Plain(params?: GetProfile$Plain$Params, context?: HttpContext): Observable<UserProfileViewModel> {
    return this.getProfile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfileViewModel>): UserProfileViewModel => r.body)
    );
  }

  /**
   * Get the profile details for the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile$Response(params?: GetProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<UserProfileViewModel>> {
    return getProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the profile details for the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile(params?: GetProfile$Params, context?: HttpContext): Observable<UserProfileViewModel> {
    return this.getProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfileViewModel>): UserProfileViewModel => r.body)
    );
  }

  /** Path part for operation `usersControllerSaveProfilerequest()` */
  static readonly UsersControllerSaveProfilerequestPath = '/users/me/profile';

  /**
   * Save the changes that a user has done on their profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProfile$Response(params?: SaveProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Save the changes that a user has done on their profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProfile(params?: SaveProfile$Params, context?: HttpContext): Observable<void> {
    return this.saveProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerGetOrganizationScopes()` */
  static readonly UsersControllerGetOrganizationScopesPath = '/users/me/organization/scopes';

  /**
   * Save the changes that a user has done on their profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationScopes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationScopes$Plain$Response(params?: GetOrganizationScopes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Scope>>> {
    return getOrganizationScopes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Save the changes that a user has done on their profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationScopes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationScopes$Plain(params?: GetOrganizationScopes$Plain$Params, context?: HttpContext): Observable<Array<Scope>> {
    return this.getOrganizationScopes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Scope>>): Array<Scope> => r.body)
    );
  }

  /**
   * Save the changes that a user has done on their profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationScopes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationScopes$Response(params?: GetOrganizationScopes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Scope>>> {
    return getOrganizationScopes(this.http, this.rootUrl, params, context);
  }

  /**
   * Save the changes that a user has done on their profile.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationScopes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationScopes(params?: GetOrganizationScopes$Params, context?: HttpContext): Observable<Array<Scope>> {
    return this.getOrganizationScopes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Scope>>): Array<Scope> => r.body)
    );
  }

  /** Path part for operation `usersControllerGetCurrentUserPermissions()` */
  static readonly UsersControllerGetCurrentUserPermissionsPath = '/users/me/permissions';

  /**
   * Get permissions for the currently logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserPermissions$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserPermissions$Plain$Response(params?: GetCurrentUserPermissions$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PermissionDto>>> {
    return getCurrentUserPermissions$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get permissions for the currently logged in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserPermissions$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserPermissions$Plain(params?: GetCurrentUserPermissions$Plain$Params, context?: HttpContext): Observable<Array<PermissionDto>> {
    return this.getCurrentUserPermissions$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PermissionDto>>): Array<PermissionDto> => r.body)
    );
  }

  /**
   * Get permissions for the currently logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserPermissions$Response(params?: GetCurrentUserPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PermissionDto>>> {
    return getCurrentUserPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get permissions for the currently logged in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserPermissions(params?: GetCurrentUserPermissions$Params, context?: HttpContext): Observable<Array<PermissionDto>> {
    return this.getCurrentUserPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PermissionDto>>): Array<PermissionDto> => r.body)
    );
  }

  /** Path part for operation `usersControllerGetCurrentUserRole()` */
  static readonly UsersControllerGetCurrentUserRolePath = '/users/me/role';

  /**
   * Get the role for the currently logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserRole$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserRole$Plain$Response(params?: GetCurrentUserRole$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return getCurrentUserRole$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the role for the currently logged in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserRole$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserRole$Plain(params?: GetCurrentUserRole$Plain$Params, context?: HttpContext): Observable<RoleDto> {
    return this.getCurrentUserRole$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /**
   * Get the role for the currently logged in user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserRole$Response(params?: GetCurrentUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return getCurrentUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the role for the currently logged in user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserRole(params?: GetCurrentUserRole$Params, context?: HttpContext): Observable<RoleDto> {
    return this.getCurrentUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /** Path part for operation `usersControllerGetProfileBase64()` */
  static readonly UsersControllerGetProfileBase64Path = '/users/me/photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileBase64$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileBase64$Plain$Response(params?: GetProfileBase64$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getProfileBase64$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfileBase64$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileBase64$Plain(params?: GetProfileBase64$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getProfileBase64$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileBase64()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileBase64$Response(params?: GetProfileBase64$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getProfileBase64(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfileBase64$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileBase64(params?: GetProfileBase64$Params, context?: HttpContext): Observable<string> {
    return this.getProfileBase64$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersControllerGetPermissionsForUseruserId()` */
  static readonly UsersControllerGetPermissionsForUseruserIdPath = '/users/{userId}/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermissionsForUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsForUser$Plain$Response(params: GetPermissionsForUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PermissionDto>>> {
    return getPermissionsForUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPermissionsForUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsForUser$Plain(params: GetPermissionsForUser$Plain$Params, context?: HttpContext): Observable<Array<PermissionDto>> {
    return this.getPermissionsForUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PermissionDto>>): Array<PermissionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermissionsForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsForUser$Response(params: GetPermissionsForUser$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PermissionDto>>> {
    return getPermissionsForUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPermissionsForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsForUser(params: GetPermissionsForUser$Params, context?: HttpContext): Observable<Array<PermissionDto>> {
    return this.getPermissionsForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PermissionDto>>): Array<PermissionDto> => r.body)
    );
  }

  /** Path part for operation `usersControllerGetRoleForUseruserId()` */
  static readonly UsersControllerGetRoleForUseruserIdPath = '/users/{userId}/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoleForUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleForUser$Plain$Response(params: GetRoleForUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return getRoleForUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoleForUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleForUser$Plain(params: GetRoleForUser$Plain$Params, context?: HttpContext): Observable<RoleDto> {
    return this.getRoleForUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoleForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleForUser$Response(params: GetRoleForUser$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDto>> {
    return getRoleForUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoleForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoleForUser(params: GetRoleForUser$Params, context?: HttpContext): Observable<RoleDto> {
    return this.getRoleForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDto>): RoleDto => r.body)
    );
  }

  /** Path part for operation `usersControllerGetCompletedOnboardings()` */
  static readonly UsersControllerGetCompletedOnboardingsPath = '/users/me/properties/onboarding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompletedOnboardings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedOnboardings$Plain$Response(params?: GetCompletedOnboardings$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserPropertyType>>> {
    return getCompletedOnboardings$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompletedOnboardings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedOnboardings$Plain(params?: GetCompletedOnboardings$Plain$Params, context?: HttpContext): Observable<Array<UserPropertyType>> {
    return this.getCompletedOnboardings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserPropertyType>>): Array<UserPropertyType> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompletedOnboardings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedOnboardings$Response(params?: GetCompletedOnboardings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserPropertyType>>> {
    return getCompletedOnboardings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompletedOnboardings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedOnboardings(params?: GetCompletedOnboardings$Params, context?: HttpContext): Observable<Array<UserPropertyType>> {
    return this.getCompletedOnboardings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserPropertyType>>): Array<UserPropertyType> => r.body)
    );
  }

  /** Path part for operation `usersControllerSetCompletedOnboarding()` */
  static readonly UsersControllerSetCompletedOnboardingPath = '/users/me/properties/onboarding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCompletedOnboarding$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  setCompletedOnboarding$Plain$Response(params?: SetCompletedOnboarding$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return setCompletedOnboarding$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCompletedOnboarding$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setCompletedOnboarding$Plain(params?: SetCompletedOnboarding$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.setCompletedOnboarding$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCompletedOnboarding()` instead.
   *
   * This method doesn't expect any request body.
   */
  setCompletedOnboarding$Response(params?: SetCompletedOnboarding$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return setCompletedOnboarding(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCompletedOnboarding$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setCompletedOnboarding(params?: SetCompletedOnboarding$Params, context?: HttpContext): Observable<boolean> {
    return this.setCompletedOnboarding$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usersControllerRescopeAllUsers()` */
  static readonly UsersControllerRescopeAllUsersPath = '/users/rescope/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeAllUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeAllUsers$Plain$Response(params?: RescopeAllUsers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return rescopeAllUsers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeAllUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeAllUsers$Plain(params?: RescopeAllUsers$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.rescopeAllUsers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeAllUsers$Response(params?: RescopeAllUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return rescopeAllUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeAllUsers(params?: RescopeAllUsers$Params, context?: HttpContext): Observable<boolean> {
    return this.rescopeAllUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usersControllerSearchoptionsUserType()` */
  static readonly UsersControllerSearchoptionsUserTypePath = '/users/search/{userType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain$Response(params: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain(params: Search$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params: Search$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `usersControllerRescopeUseruserId()` */
  static readonly UsersControllerRescopeUseruserIdPath = '/users/rescope/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeUser$Plain$Response(params: RescopeUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return rescopeUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeUser$Plain(params: RescopeUser$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.rescopeUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeUser$Response(params: RescopeUser$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return rescopeUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeUser(params: RescopeUser$Params, context?: HttpContext): Observable<boolean> {
    return this.rescopeUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usersControllerUpdateExternalUserexternalUser()` */
  static readonly UsersControllerUpdateExternalUserexternalUserPath = '/users/external';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExternalUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExternalUser$Plain$Response(params?: UpdateExternalUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return updateExternalUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExternalUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExternalUser$Plain(params?: UpdateExternalUser$Plain$Params, context?: HttpContext): Observable<string> {
    return this.updateExternalUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExternalUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExternalUser$Response(params?: UpdateExternalUser$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return updateExternalUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExternalUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateExternalUser(params?: UpdateExternalUser$Params, context?: HttpContext): Observable<string> {
    return this.updateExternalUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersControllerCreateExternalUserexternalUser()` */
  static readonly UsersControllerCreateExternalUserexternalUserPath = '/users/external';

  /**
   * Creates a new external (Non SANDVIK) user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExternalUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExternalUser$Plain$Response(params?: CreateExternalUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createExternalUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new external (Non SANDVIK) user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExternalUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExternalUser$Plain(params?: CreateExternalUser$Plain$Params, context?: HttpContext): Observable<string> {
    return this.createExternalUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Creates a new external (Non SANDVIK) user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExternalUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExternalUser$Response(params?: CreateExternalUser$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createExternalUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new external (Non SANDVIK) user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExternalUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExternalUser(params?: CreateExternalUser$Params, context?: HttpContext): Observable<string> {
    return this.createExternalUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersControllerUpdateInternalUserinternalUser()` */
  static readonly UsersControllerUpdateInternalUserinternalUserPath = '/users/internal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInternalUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInternalUser$Plain$Response(params?: UpdateInternalUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return updateInternalUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInternalUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInternalUser$Plain(params?: UpdateInternalUser$Plain$Params, context?: HttpContext): Observable<string> {
    return this.updateInternalUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInternalUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInternalUser$Response(params?: UpdateInternalUser$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return updateInternalUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInternalUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInternalUser(params?: UpdateInternalUser$Params, context?: HttpContext): Observable<string> {
    return this.updateInternalUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersControllerCreateInternalUserinternalUser()` */
  static readonly UsersControllerCreateInternalUserinternalUserPath = '/users/internal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInternalUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createInternalUser$Plain$Response(params?: CreateInternalUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createInternalUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createInternalUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createInternalUser$Plain(params?: CreateInternalUser$Plain$Params, context?: HttpContext): Observable<string> {
    return this.createInternalUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInternalUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createInternalUser$Response(params?: CreateInternalUser$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createInternalUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createInternalUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createInternalUser(params?: CreateInternalUser$Params, context?: HttpContext): Observable<string> {
    return this.createInternalUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersControllerGetUserFromADemail()` */
  static readonly UsersControllerGetUserFromADemailPath = '/users/activedirectory/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFromAD$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFromAD$Plain$Response(params: GetUserFromAD$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SandvikAdUserViewModel>> {
    return getUserFromAD$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserFromAD$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFromAD$Plain(params: GetUserFromAD$Plain$Params, context?: HttpContext): Observable<SandvikAdUserViewModel> {
    return this.getUserFromAD$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SandvikAdUserViewModel>): SandvikAdUserViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFromAD()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFromAD$Response(params: GetUserFromAD$Params, context?: HttpContext): Observable<StrictHttpResponse<SandvikAdUserViewModel>> {
    return getUserFromAD(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserFromAD$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFromAD(params: GetUserFromAD$Params, context?: HttpContext): Observable<SandvikAdUserViewModel> {
    return this.getUserFromAD$Response(params, context).pipe(
      map((r: StrictHttpResponse<SandvikAdUserViewModel>): SandvikAdUserViewModel => r.body)
    );
  }

  /** Path part for operation `usersControllerUpdateIndexuserIdConfirmDelete()` */
  static readonly UsersControllerUpdateIndexuserIdConfirmDeletePath = '/users/{userId}/index';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIndex$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIndex$Plain$Response(params: UpdateIndex$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateIndex$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIndex$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIndex$Plain(params: UpdateIndex$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.updateIndex$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIndex$Response(params: UpdateIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIndex(params: UpdateIndex$Params, context?: HttpContext): Observable<boolean> {
    return this.updateIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usersControllerGetCurrentUserHash()` */
  static readonly UsersControllerGetCurrentUserHashPath = '/users/me/hash';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserHash$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserHash$Plain$Response(params?: GetCurrentUserHash$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getCurrentUserHash$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserHash$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserHash$Plain(params?: GetCurrentUserHash$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getCurrentUserHash$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserHash()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserHash$Response(params?: GetCurrentUserHash$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getCurrentUserHash(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserHash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserHash(params?: GetCurrentUserHash$Params, context?: HttpContext): Observable<string> {
    return this.getCurrentUserHash$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersControllerUpdateDefaultDeliverySequenceForBusinessRelationbusinessRelationIdDeliverySequenceIdHideInvoiceAddress()` */
  static readonly UsersControllerUpdateDefaultDeliverySequenceForBusinessRelationbusinessRelationIdDeliverySequenceIdHideInvoiceAddressPath = '/users/me/deliverysequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDefaultDeliverySequenceForBusinessRelation()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDefaultDeliverySequenceForBusinessRelation$Response(params?: UpdateDefaultDeliverySequenceForBusinessRelation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateDefaultDeliverySequenceForBusinessRelation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDefaultDeliverySequenceForBusinessRelation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDefaultDeliverySequenceForBusinessRelation(params?: UpdateDefaultDeliverySequenceForBusinessRelation$Params, context?: HttpContext): Observable<void> {
    return this.updateDefaultDeliverySequenceForBusinessRelation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerGetCurrentUserNotificationSettings()` */
  static readonly UsersControllerGetCurrentUserNotificationSettingsPath = '/users/me/settings/notifications';

  /**
   * Will push UserApIEvent using SignalR for the current user - and also return it as a response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserNotificationSettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserNotificationSettings$Plain$Response(params?: GetCurrentUserNotificationSettings$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserNotificationSetingsDto>>> {
    return getCurrentUserNotificationSettings$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Will push UserApIEvent using SignalR for the current user - and also return it as a response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserNotificationSettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserNotificationSettings$Plain(params?: GetCurrentUserNotificationSettings$Plain$Params, context?: HttpContext): Observable<Array<UserNotificationSetingsDto>> {
    return this.getCurrentUserNotificationSettings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserNotificationSetingsDto>>): Array<UserNotificationSetingsDto> => r.body)
    );
  }

  /**
   * Will push UserApIEvent using SignalR for the current user - and also return it as a response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserNotificationSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserNotificationSettings$Response(params?: GetCurrentUserNotificationSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserNotificationSetingsDto>>> {
    return getCurrentUserNotificationSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Will push UserApIEvent using SignalR for the current user - and also return it as a response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserNotificationSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserNotificationSettings(params?: GetCurrentUserNotificationSettings$Params, context?: HttpContext): Observable<Array<UserNotificationSetingsDto>> {
    return this.getCurrentUserNotificationSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserNotificationSetingsDto>>): Array<UserNotificationSetingsDto> => r.body)
    );
  }

  /** Path part for operation `usersControllerSaveCurrentUserNotificationSettingsuserNotificationSetingsDto()` */
  static readonly UsersControllerSaveCurrentUserNotificationSettingsuserNotificationSetingsDtoPath = '/users/me/settings/notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCurrentUserNotificationSettings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCurrentUserNotificationSettings$Response(params?: SaveCurrentUserNotificationSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveCurrentUserNotificationSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveCurrentUserNotificationSettings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCurrentUserNotificationSettings(params?: SaveCurrentUserNotificationSettings$Params, context?: HttpContext): Observable<void> {
    return this.saveCurrentUserNotificationSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerGetCurrentUserDashboardTileSettings()` */
  static readonly UsersControllerGetCurrentUserDashboardTileSettingsPath = '/users/me/settings/dashboardtiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserDashboardTileSettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserDashboardTileSettings$Plain$Response(params?: GetCurrentUserDashboardTileSettings$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserTileSetting>>> {
    return getCurrentUserDashboardTileSettings$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserDashboardTileSettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserDashboardTileSettings$Plain(params?: GetCurrentUserDashboardTileSettings$Plain$Params, context?: HttpContext): Observable<Array<UserTileSetting>> {
    return this.getCurrentUserDashboardTileSettings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserTileSetting>>): Array<UserTileSetting> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserDashboardTileSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserDashboardTileSettings$Response(params?: GetCurrentUserDashboardTileSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserTileSetting>>> {
    return getCurrentUserDashboardTileSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserDashboardTileSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserDashboardTileSettings(params?: GetCurrentUserDashboardTileSettings$Params, context?: HttpContext): Observable<Array<UserTileSetting>> {
    return this.getCurrentUserDashboardTileSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserTileSetting>>): Array<UserTileSetting> => r.body)
    );
  }

  /** Path part for operation `usersControllerSaveCurrentUserDashboardTileSettingsrequest()` */
  static readonly UsersControllerSaveCurrentUserDashboardTileSettingsrequestPath = '/users/me/settings/dashboardtiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCurrentUserDashboardTileSettings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCurrentUserDashboardTileSettings$Response(params?: SaveCurrentUserDashboardTileSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveCurrentUserDashboardTileSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveCurrentUserDashboardTileSettings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCurrentUserDashboardTileSettings(params?: SaveCurrentUserDashboardTileSettings$Params, context?: HttpContext): Observable<void> {
    return this.saveCurrentUserDashboardTileSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerGetCompletedNewFeatureTourtourId()` */
  static readonly UsersControllerGetCompletedNewFeatureTourtourIdPath = '/users/me/properties/featuretour/{tourId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompletedNewFeatureTour$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedNewFeatureTour$Plain$Response(params: GetCompletedNewFeatureTour$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return getCompletedNewFeatureTour$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompletedNewFeatureTour$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedNewFeatureTour$Plain(params: GetCompletedNewFeatureTour$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.getCompletedNewFeatureTour$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompletedNewFeatureTour()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedNewFeatureTour$Response(params: GetCompletedNewFeatureTour$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return getCompletedNewFeatureTour(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompletedNewFeatureTour$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedNewFeatureTour(params: GetCompletedNewFeatureTour$Params, context?: HttpContext): Observable<boolean> {
    return this.getCompletedNewFeatureTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usersControllerSetCompletedNewFeatureTourtourId()` */
  static readonly UsersControllerSetCompletedNewFeatureTourtourIdPath = '/users/me/properties/featuretour/{tourId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCompletedNewFeatureTour$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  setCompletedNewFeatureTour$Plain$Response(params: SetCompletedNewFeatureTour$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return setCompletedNewFeatureTour$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCompletedNewFeatureTour$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setCompletedNewFeatureTour$Plain(params: SetCompletedNewFeatureTour$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.setCompletedNewFeatureTour$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCompletedNewFeatureTour()` instead.
   *
   * This method doesn't expect any request body.
   */
  setCompletedNewFeatureTour$Response(params: SetCompletedNewFeatureTour$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return setCompletedNewFeatureTour(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCompletedNewFeatureTour$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setCompletedNewFeatureTour(params: SetCompletedNewFeatureTour$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.setCompletedNewFeatureTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /** Path part for operation `usersControllerDisconnectUser()` */
  static readonly UsersControllerDisconnectUserPath = '/users/disconnectuser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disconnectUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  disconnectUser$Response(params?: DisconnectUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return disconnectUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disconnectUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disconnectUser(params?: DisconnectUser$Params, context?: HttpContext): Observable<void> {
    return this.disconnectUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerDisconnectUserConnections()` */
  static readonly UsersControllerDisconnectUserConnectionsPath = '/users/disconnectuserconnections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disconnectUserConnections()` instead.
   *
   * This method doesn't expect any request body.
   */
  disconnectUserConnections$Response(params?: DisconnectUserConnections$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return disconnectUserConnections(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disconnectUserConnections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disconnectUserConnections(params?: DisconnectUserConnections$Params, context?: HttpContext): Observable<void> {
    return this.disconnectUserConnections$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerDetectConcurrentUserSessions()` */
  static readonly UsersControllerDetectConcurrentUserSessionsPath = '/users/detectconcurrentusersessions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detectConcurrentUserSessions$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  detectConcurrentUserSessions$Plain$Response(params?: DetectConcurrentUserSessions$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return detectConcurrentUserSessions$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `detectConcurrentUserSessions$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  detectConcurrentUserSessions$Plain(params?: DetectConcurrentUserSessions$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.detectConcurrentUserSessions$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detectConcurrentUserSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  detectConcurrentUserSessions$Response(params?: DetectConcurrentUserSessions$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return detectConcurrentUserSessions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `detectConcurrentUserSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  detectConcurrentUserSessions(params?: DetectConcurrentUserSessions$Params, context?: HttpContext): Observable<boolean> {
    return this.detectConcurrentUserSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usersControllerRenamePunchoutProfileprofileUserIdNewName()` */
  static readonly UsersControllerRenamePunchoutProfileprofileUserIdNewNamePath = '/users/punchoutprofiles/{profileUserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renamePunchoutProfile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  renamePunchoutProfile$Plain$Response(params: RenamePunchoutProfile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SelectProfileEvent>> {
    return renamePunchoutProfile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renamePunchoutProfile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  renamePunchoutProfile$Plain(params: RenamePunchoutProfile$Plain$Params, context?: HttpContext): Observable<SelectProfileEvent> {
    return this.renamePunchoutProfile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelectProfileEvent>): SelectProfileEvent => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renamePunchoutProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  renamePunchoutProfile$Response(params: RenamePunchoutProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<SelectProfileEvent>> {
    return renamePunchoutProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renamePunchoutProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  renamePunchoutProfile(params: RenamePunchoutProfile$Params, context?: HttpContext): Observable<SelectProfileEvent> {
    return this.renamePunchoutProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelectProfileEvent>): SelectProfileEvent => r.body)
    );
  }

  /** Path part for operation `usersControllerDeletePunchoutProfileprofileUserId()` */
  static readonly UsersControllerDeletePunchoutProfileprofileUserIdPath = '/users/punchoutprofiles/{profileUserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePunchoutProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePunchoutProfile$Response(params: DeletePunchoutProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePunchoutProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePunchoutProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePunchoutProfile(params: DeletePunchoutProfile$Params, context?: HttpContext): Observable<void> {
    return this.deletePunchoutProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerLoginAsPunchoutProfileprofileUserId()` */
  static readonly UsersControllerLoginAsPunchoutProfileprofileUserIdPath = '/users/punchoutprofiles/{profileUserId}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginAsPunchoutProfile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  loginAsPunchoutProfile$Plain$Response(params: LoginAsPunchoutProfile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return loginAsPunchoutProfile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loginAsPunchoutProfile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loginAsPunchoutProfile$Plain(params: LoginAsPunchoutProfile$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.loginAsPunchoutProfile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginAsPunchoutProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  loginAsPunchoutProfile$Response(params: LoginAsPunchoutProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return loginAsPunchoutProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loginAsPunchoutProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loginAsPunchoutProfile(params: LoginAsPunchoutProfile$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.loginAsPunchoutProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /** Path part for operation `usersControllerAddPunchoutProfilename()` */
  static readonly UsersControllerAddPunchoutProfilenamePath = '/users/punchoutprofiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPunchoutProfile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addPunchoutProfile$Plain$Response(params?: AddPunchoutProfile$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SelectProfileEvent>> {
    return addPunchoutProfile$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPunchoutProfile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addPunchoutProfile$Plain(params?: AddPunchoutProfile$Plain$Params, context?: HttpContext): Observable<SelectProfileEvent> {
    return this.addPunchoutProfile$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelectProfileEvent>): SelectProfileEvent => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPunchoutProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  addPunchoutProfile$Response(params?: AddPunchoutProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<SelectProfileEvent>> {
    return addPunchoutProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPunchoutProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addPunchoutProfile(params?: AddPunchoutProfile$Params, context?: HttpContext): Observable<SelectProfileEvent> {
    return this.addPunchoutProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelectProfileEvent>): SelectProfileEvent => r.body)
    );
  }

  /** Path part for operation `usersControllerEnsureActiveDeliverySequence()` */
  static readonly UsersControllerEnsureActiveDeliverySequencePath = '/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ensureActiveDeliverySequence$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  ensureActiveDeliverySequence$Plain$Response(params?: EnsureActiveDeliverySequence$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDeliverySequenceResponse>> {
    return ensureActiveDeliverySequence$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ensureActiveDeliverySequence$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ensureActiveDeliverySequence$Plain(params?: EnsureActiveDeliverySequence$Plain$Params, context?: HttpContext): Observable<UserDeliverySequenceResponse> {
    return this.ensureActiveDeliverySequence$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDeliverySequenceResponse>): UserDeliverySequenceResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ensureActiveDeliverySequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  ensureActiveDeliverySequence$Response(params?: EnsureActiveDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDeliverySequenceResponse>> {
    return ensureActiveDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ensureActiveDeliverySequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ensureActiveDeliverySequence(params?: EnsureActiveDeliverySequence$Params, context?: HttpContext): Observable<UserDeliverySequenceResponse> {
    return this.ensureActiveDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDeliverySequenceResponse>): UserDeliverySequenceResponse => r.body)
    );
  }

  /** Path part for operation `usersControllerUpdateDefaultDeliverySequencedeliverySequenceId()` */
  static readonly UsersControllerUpdateDefaultDeliverySequencedeliverySequenceIdPath = '/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDefaultDeliverySequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDefaultDeliverySequence$Response(params?: UpdateDefaultDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateDefaultDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDefaultDeliverySequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDefaultDeliverySequence(params?: UpdateDefaultDeliverySequence$Params, context?: HttpContext): Observable<void> {
    return this.updateDefaultDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersControllerGetPunchoutProfilesuserId()` */
  static readonly UsersControllerGetPunchoutProfilesuserIdPath = '/users/getpunchoutprofiles/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPunchoutProfiles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles$Plain$Response(params: GetPunchoutProfiles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PunchoutProfileViewModel>>> {
    return getPunchoutProfiles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPunchoutProfiles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles$Plain(params: GetPunchoutProfiles$Plain$Params, context?: HttpContext): Observable<Array<PunchoutProfileViewModel>> {
    return this.getPunchoutProfiles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PunchoutProfileViewModel>>): Array<PunchoutProfileViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPunchoutProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles$Response(params: GetPunchoutProfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PunchoutProfileViewModel>>> {
    return getPunchoutProfiles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPunchoutProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles(params: GetPunchoutProfiles$Params, context?: HttpContext): Observable<Array<PunchoutProfileViewModel>> {
    return this.getPunchoutProfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PunchoutProfileViewModel>>): Array<PunchoutProfileViewModel> => r.body)
    );
  }

  /** Path part for operation `usersControllerIsPunchoutProfileUserByUserIduserId()` */
  static readonly UsersControllerIsPunchoutProfileUserByUserIduserIdPath = '/users/ispunchoutprofileuserbyuserid/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isPunchoutProfileUserByUserId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPunchoutProfileUserByUserId$Plain$Response(params: IsPunchoutProfileUserByUserId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isPunchoutProfileUserByUserId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isPunchoutProfileUserByUserId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPunchoutProfileUserByUserId$Plain(params: IsPunchoutProfileUserByUserId$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.isPunchoutProfileUserByUserId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isPunchoutProfileUserByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPunchoutProfileUserByUserId$Response(params: IsPunchoutProfileUserByUserId$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isPunchoutProfileUserByUserId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isPunchoutProfileUserByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPunchoutProfileUserByUserId(params: IsPunchoutProfileUserByUserId$Params, context?: HttpContext): Observable<boolean> {
    return this.isPunchoutProfileUserByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `usersControllerIsMainPunchoutProfileUserByUserIduserId()` */
  static readonly UsersControllerIsMainPunchoutProfileUserByUserIduserIdPath = '/users/{userId}/is-main-punchout-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isMainPunchoutProfileUserByUserId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isMainPunchoutProfileUserByUserId$Plain$Response(params: IsMainPunchoutProfileUserByUserId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isMainPunchoutProfileUserByUserId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isMainPunchoutProfileUserByUserId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isMainPunchoutProfileUserByUserId$Plain(params: IsMainPunchoutProfileUserByUserId$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.isMainPunchoutProfileUserByUserId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isMainPunchoutProfileUserByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  isMainPunchoutProfileUserByUserId$Response(params: IsMainPunchoutProfileUserByUserId$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isMainPunchoutProfileUserByUserId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isMainPunchoutProfileUserByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isMainPunchoutProfileUserByUserId(params: IsMainPunchoutProfileUserByUserId$Params, context?: HttpContext): Observable<boolean> {
    return this.isMainPunchoutProfileUserByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
