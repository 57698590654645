import { AuthenticationStatus, B2CAccount, B2CEventMessages, B2CIDTokenClaims, B2CLoginSuccessPayload, B2CServerError, MibpAuthSetupResult, MySandvikAppB2cData, SigninOptions } from './auth.types';
import { LogService } from './../logservice/log.service';
import { CacheScope, ClientSideCacheService } from 'root/services/client-side-cache/client-side-cache.service';
import { environment } from 'root/environment';

import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from '@angular/router';
import { RouteAuthConfig } from './route-auth-config';
import { PermissionService } from '../permission/permission.service';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { filter, first, take } from 'rxjs/operators';
import { AccountInfo, AuthenticationResult, BrowserAuthError, EndSessionRequest, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { BroadcastService } from '../broadcast-service/broadcast.service';
import { UrlHelperService } from 'root/services/url-helper';
import { MibpLogger } from '../logservice/mibplogger.class';
import { ApplicationStateService } from '../application-state/application-state.service';
import { ApplicationStates } from '../application-state/application-state.types';
import { neverEndingPromise } from '../neverEndingPromise.function';
import { BehaviorSubject, Observable, firstValueFrom, from } from 'rxjs';
import { ApplicationInsightsService } from '../application-insights/application-insights.service';
import { SessionApiController, UsersApiController } from 'root/mibp-openapi-gen/controllers';
import { ClientIdService } from '../clientid-service/clientid.service';
import { differenceInSeconds } from 'date-fns';
import { DocumotoAuthService } from './documoto-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  loginInProgress = false;
  private authenticationResult?: AuthenticationResult;
  log: MibpLogger;

  /**
   * Logger with common prefix for logging MibpSession related things
   *  - Signing in, user events etc
   */
  private mibpSessionLog: MibpLogger;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isRefreshingToken = false;

  b2cEvents: B2CEventMessages = {};
  public isLoggedInSubject = new BehaviorSubject<boolean>(false);
  private interactionSubject = new BehaviorSubject<InteractionStatus>(InteractionStatus.Startup);



  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private appState: ApplicationStateService,
    logger: LogService,
    private documotoAuth: DocumotoAuthService,
    private sessionApiController: SessionApiController,
    private broadcastService: BroadcastService,
    private cacheService: ClientSideCacheService,
    private clientIdService: ClientIdService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private permissionService: PermissionService,
    private urlHelper: UrlHelperService,
    private usersApiController: UsersApiController,
    private appInsightService: ApplicationInsightsService) {
    this.log = logger.withPrefix('auth.service');

    this.mibpSessionLog = logger.withPrefix(environment.mibpSessionLogPrefix + ':auth.service');

    const accounts = this.msalService.instance.getAllAccounts();

    if (accounts.length > 1) {
      this.log.warn(`Multiple accounts`, accounts);
    }

    // Set active account, if any is already available
    this.setActiveAccount(this.msalService.instance.getAllAccounts());

    this.subscribeToB2CEvents();
  }



  // Subscribe to B2C events that will be processed in processB2CEvents function
  private subscribeToB2CEvents(): void {


    this.msalBroadcastService.inProgress$.subscribe({
      next: e => {
        this.interactionSubject.next(e);
        this.log.debug(`MSAL InteractionStatus`, e);
      },
      error: e => {
        this.log.warn(`MSAL InteractionStatus`, e);
      }
    });

    this.msalBroadcastService.msalSubject$
      .subscribe((result: EventMessage) => {
        // result.error = null;
        this.log.debug("msalevent", result?.eventType, result);

        if (result.error) {
          this.log.error('msal event error', JSON.stringify(result.error));

          this.clearCacheDueToError()
            .then(() => {
              this.log.warn(`msal cache cleared. Reloading page.`);
              window.location.reload();
            }).catch(e => {
              this.log.error(`Could not forcefully clear msal cache`, e);

              // Will be picked up by application state service - at this point the angular application is not loaded
              this.appState.setState({
                state: ApplicationStates.UnhandledException,
                error: true,
                internalStatus: 'b2c.msal.error',
                stopStartupGuardProcessing: true,
                exception: result.error
              });

            });

        }
        this.b2cEvents[result.eventType] = result;
        if (result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          this.onB2CAccessTokenSuccess(result);
        }

      });
  }

  /**
   * Set the active account from the list of accounts available
   */
  private setActiveAccount(accountInfo: AccountInfo[]): void {
    if (accountInfo?.length > 0) {
      document.body.classList.add('is-logged-in');
      this.msalService.instance.setActiveAccount(accountInfo[0]);
      this.isLoggedInSubject.next(true);
      this.appInsightService.setUserId(this.getB2CUser().identityObjectId);
    } else {
      this.isLoggedInSubject.next(false);
    }
  }

  /***
   * This will attempt to fetch the accessToken either from
   * msal cache or it will try to refresh it using a silent refresh
   */
  public async ensureToken(firstLoad = false): Promise<string> {

    if (!this.isLoggedIn()) {
      return Promise.resolve(null);
    }

    // Token is already being refeshed - wait for that to complete
    if (this.isRefreshingToken) {
      return firstValueFrom(
        this.refreshTokenSubject.pipe(
          filter(token => token != null),
          take(1))
      );
    }

    // We have a valid token from B2C. Let's check it's expiration date.
    // We do not have to call B2C to silently refresh it for every request
    // This also spammed the logs and made it really hard to follow
    const expires = this.msalService.instance?.getActiveAccount()?.idTokenClaims['exp'];
    if (expires) {
      const now = (new Date()).valueOf() / 1000;
      if (expires - now >= 120 && this.authenticationResult?.accessToken) {
        // If it's more than 2 minutes remaining of the token. Then let's just accept that it's an ok token.
        this.isRefreshingToken = false;
        this.refreshTokenSubject.next(this.authenticationResult.accessToken);
        return Promise.resolve(this.authenticationResult.accessToken);
      }
    }

    return new Promise((resolve, reject) => {

      this.isRefreshingToken = true;
      this.refreshTokenSubject.next(null);

      this.msalService.instance.acquireTokenSilent({
        scopes: environment.auth.b2c.scope,
        authority: `${environment.auth.b2c.authorityBaseUrl}${environment.auth.b2c.policies.signUpSignIn}`,
        redirectUri: this.urlHelper.addHostnameToPath(environment.auth.b2c.silentRedirectUri),
      }).then(async authenticationResult => {
        this.authenticationResult = authenticationResult;
        if (!authenticationResult.fromCache) {
          this.mibpSessionLog.debug(`acquireTokenSilent result`, JSON.stringify(authenticationResult));
          this.mibpSessionLog.info(`Token Silently Updated. Nonce ${this.getB2CUser().nonce}. New token expires ${authenticationResult.expiresOn}`, );

          this.isRefreshingToken = false;
          this.refreshTokenSubject.next(authenticationResult.accessToken);

          if (!firstLoad) {
            try {
              await firstValueFrom(this.sessionApiController.refreshSessionToken());
            } catch (e) {
              // We don't have to succeed here as long as the token is valid. This will only update expiration date in database
              // It's of course not good if it fails very often!
              this.mibpSessionLog.error(`Error refreshing Connection expiration date from token.`);
            }
          }
        } else {
          this.isRefreshingToken = false;
          this.refreshTokenSubject.next(authenticationResult.accessToken);
        }

        resolve(authenticationResult.accessToken);
      }, err => {
        this.isRefreshingToken = false;
        this.mibpSessionLog.error(`Error when trying to silently update token`, err);
        this.log.error("Error fetching token", err);
        reject(err);
      });
    });

  }

  private getActiveAccount(): AccountInfo {
    return this.msalService.instance.getActiveAccount();
  }

  public getB2CUser(): B2CAccount {

    const accountInfo = this.getActiveAccount();

    if (!accountInfo) {
      return;
    }

    const claims = accountInfo.idTokenClaims as B2CIDTokenClaims;

    return {
      email: claims.emails?.length > 0 ? claims.emails[0] : null,
      identityObjectId: claims.sub,
      family_name: claims.family_name,
      given_name: claims.given_name,
      type: claims.idp ? 'internal' : 'external',
      tfp: claims.tfp,
      exp: this.claimTimestampToDate(claims.exp),
      iat: this.claimTimestampToDate(claims.iat),
      auth_time: this.claimTimestampToDate(claims.auth_time),
      country: claims.country,
      accessToken: this.authenticationResult?.accessToken,
      nonce: claims.nonce
    };

  }

  private claimTimestampToDate(timestamp?: number): Date {
    if (timestamp) {
      const d = new Date(0);
      d.setUTCSeconds(timestamp);
      return d;
    }
    return null;
  }

  public async signoutPopup(): Promise<void> {
    const endSessionRequest: EndSessionRequest = {
      authority: `${environment.auth.b2c.authorityBaseUrl}${environment.auth.b2c.policies.signUpSignIn}`
    };
    return this.msalService.instance.logoutPopup(endSessionRequest);
  }

  public async signout(postLogoutRedirectUri?: string, logoutReason: 'idle' | 'app-password-reset' = null): Promise<void> {
    if (logoutReason != 'app-password-reset') {
      await this.documotoAuth.signout();
      await this.disconnectUser();
    }
    await this.b2cSignout(postLogoutRedirectUri, logoutReason);
  }

  public async signoutAllSignIns(postLogoutRedirectUri?: string, logoutReason: 'idle' | 'app-password-reset' = null): Promise<void> {
    await this.documotoAuth.signout();
    await this.disconnectUserConnections();
    await this.b2cSignout(postLogoutRedirectUri, logoutReason);
  }

  public async detectConcurrentUserSessions(): Promise<boolean> {
    try {
      return await firstValueFrom(this.usersApiController.detectConcurrentUserSessions());
    } catch {
      this.log.warn(`Error fetching user's concurrent connection details`);
    }
  }

  private b2cSignout(postLogoutRedirectUri?: string, logoutReason: 'idle' | 'app-password-reset' = null): Promise<any>
  {
    const postLogoutUrl = this.urlHelper.addHostnameToPath('/user/notloggedin') + (logoutReason ? `?logoutreason=${encodeURIComponent(logoutReason)}` : ``);
    const endSessionRequest: EndSessionRequest = {
      authority: `${environment.auth.b2c.authorityBaseUrl}${environment.auth.b2c.policies.signUpSignIn}`,
      postLogoutRedirectUri: postLogoutUrl
    };

    if (postLogoutRedirectUri) {
      endSessionRequest.postLogoutRedirectUri = postLogoutRedirectUri;
    }

    this.clearAll();

    this.appInsightService.clearUserId();
    if (this.isInIframe) {
      this.mibpSessionLog.info(`LogoutPopup`, JSON.stringify(endSessionRequest));
      return this.msalService.instance.logoutPopup(endSessionRequest)
        .then(() => {
          document.location.href = postLogoutUrl;
        }).catch(() => {
          document.location.href = postLogoutUrl;
        });
    } else {
      this.mibpSessionLog.info(`LogoutRedirect`, JSON.stringify(endSessionRequest));
      return this.msalService.instance.logoutRedirect(endSessionRequest);
    }
  }

  public async resolveAuthStatus(): Promise<MibpAuthSetupResult> {
    if (this.getActiveAccount() === null) {
      return Promise.resolve({
        status: AuthenticationStatus.NotLoggedIn
      } as MibpAuthSetupResult);
    } else {
      return Promise.resolve({
        status: AuthenticationStatus.LoggedIn
      } as MibpAuthSetupResult);
    }
  }

  public isLoggedIn(): boolean {
    return !!this.getActiveAccount();
  }

  private async disconnectUser(): Promise<void> {

    try {
      await firstValueFrom(this.usersApiController.disconnectUser());
    } catch {
      this.log.warn(`Could not disconnect user. Session will expire with token`);
    }
  }

  private async disconnectUserConnections(): Promise<void> {

    try {
      await firstValueFrom(this.usersApiController.disconnectUserConnections());
    } catch {
      this.log.warn(`Could not disconnect user from all devices/browsers. Session will expire with token`);
    }
  }

  /**
   * Given SigninOptions, ensure configuration values and return a normalized options object
   */
  private prepareSigninOptions(options?: SigninOptions): SigninOptions {
    options = options || {};
    const defaultQueryParams = this.getB2CDefaultQueryParameters();
    const defaultStateParams = this.getB2CDefaultStateParameters();

    if (options.languageCode) {
      defaultQueryParams.ui_locales = options.languageCode;
      defaultStateParams.lang = options.languageCode;
    }


    if (!options.returnUrl) {
      const returnUrlPath = /\/\/.*?(\/.*)$/.exec(window.location.href);
      if (returnUrlPath && returnUrlPath[1].indexOf('notloggedin') === -1) {
        options.returnUrl = returnUrlPath[1];
      } else {
        options.returnUrl = this.urlHelper.addHostnameToPath('/');
      }
    }

    options.scopes = options.scopes || environment.auth.b2c.scope;
    options.b2cPolicy = options.b2cPolicy || environment.auth.b2c.policies.signUpSignIn;
    options.b2cRedirectUri = options.b2cRedirectUri || environment.auth.b2c.redirectUri;
    options.stateParams = options.stateParams || {};
    options.queryParams = options.queryParams || {};

    if (!options.stateParams.returnUrl) {
      options.stateParams.returnUrl = options.returnUrl;
    }

    options.queryParams = {
      ...defaultQueryParams,
      ...options.queryParams
    };

    options.stateParams = {
      ...defaultStateParams,
      ...options.stateParams
    };

    return options;
  }

  public signinPopup(options?: SigninOptions): Promise<AuthenticationResult> {

    const authr = this.msalGuardConfig.authRequest;
    options = this.prepareSigninOptions(options);


    const popupRequest: PopupRequest = {
      ...authr,
      authority: `${environment.auth.b2c.authorityBaseUrl}${options.b2cPolicy}`,
      state: this.createB2CStateString(options.stateParams),
      scopes: options.scopes,
      nonce: this.clientIdService.getClientId(),
      extraQueryParameters: options.queryParams,
      redirectUri: options.b2cRedirectUri
    };
    this.mibpSessionLog.info(`signinPopup`, JSON.stringify(popupRequest));

    return this.msalService.loginPopup(popupRequest).toPromise();
  }

  public signupRedirect(): void {
    this.signinRedirect({
      b2cPolicy: environment.auth.b2c.policies.signUp
    });
  }

  /**
   *
   * @param email
   * @param app If this is a link from the B2C admin pages then the app parameter can contain metadata that shows the reset password page a bit differently
   */
  public resetpasswordRedirect(email?: string, app?: MySandvikAppB2cData): void {

    const redirectUrl = app ? this.urlHelper
      .parseUrl()
      .setPath(this.getLanguage() + '/user/reset-password/' + app.id + '/done')
      .toString() : null;

    this.signinRedirect({
      b2cPolicy: environment.auth.b2c.policies.passwordReset,
      returnUrl: redirectUrl,
      stateParams: {
        // After password reset user will be logged in.
        // We do not want this since we do not want to create the My Sandvik user
        // So, with this flag we will automatically sign out when coming to my sandvik
        'mibpB2cSignoutAfterRedirect': app ? 'true' : null
      },
      queryParams: {
        reset_password_hint: email,
        mysapp: app ? btoa(JSON.stringify(app)) : null
      }
    });
  }

  public get b2cInteraction$(): Observable<InteractionStatus> {
    return this.interactionSubject.asObservable();
  }

  /**
   * Initiate b2c signin redirect
   * Default options will trigger default login/register flow
   */
  public signinRedirect(options?: SigninOptions): void {

    const authr = this.msalGuardConfig.authRequest;
    options = this.prepareSigninOptions(options);


    const redirectRequest: RedirectRequest = {
      ...authr,
      authority: `${environment.auth.b2c.authorityBaseUrl}${options.b2cPolicy}`,
      state: this.createB2CStateString(options.stateParams),
      scopes: options.scopes,
      nonce: this.clientIdService.getClientId(),
      extraQueryParameters: options.queryParams,
      redirectUri: options.b2cRedirectUri
    };

    // https://stackoverflow.com/questions/66405214/browserautherror-interaction-in-progress-interaction-is-currently-in-progress

    this.mibpSessionLog.info(`signinRedirect`, JSON.stringify(redirectRequest));

    // Wait for any b2c interactions to be completed before redirecting to signin
    this.b2cInteraction$.pipe(filter(s => s == InteractionStatus.None), take(1)).subscribe(() => {
      this.msalService.loginRedirect(redirectRequest).subscribe({
        next: () => {
          window.sessionStorage.removeItem('mibp.auth.clear.msal');
          this.log.info(`Login redirect successful`);
        },
        error: e => {
          // TODO: See if we get the error here
          this.log.error('Login redirect error (HANDLE!?)', e);

          if (e instanceof BrowserAuthError) {

            if (e.errorCode === 'interaction_in_progress') {
              this.clearCacheDueToError()
                .then(() => {
//                  this.signinRedirect(options);
                  window.location.reload();
                }).catch(e => {
                  this.log.error(`Could not forcefully clear msal cache`, e);
                });
            } else {
              this.appState.setState({
                state: ApplicationStates.UnhandledException,
                error: true,
                internalStatus: 'b2c.msal.error',
                stopStartupGuardProcessing: true,
                exception: e
              });
            }


            // Try again..
            throw e;
          }

        }
      });
    });

  }

  private async clearCacheDueToError(): Promise<void> {

    // TODO: How do we avoid loops here?

    let attempts = parseInt(window.sessionStorage['mibp.auth.clear.msal'], 10) || 0;
    attempts ++;

    if (attempts > 2) {
      throw "x";
    }

    if (this.msalService.instance["browserStorage"]) {
      this.log.warn("Clearing msal cache");
      this.msalService.instance["browserStorage"].clear();
    }

    window.sessionStorage['mibp.auth.clear.msal'] = attempts;

  }

  private get isInIframe(): boolean {
    return window.parent !== window;
  }

  /**
   * Will attempt to use the provided "esi" parameter to trigger a single sign on flow
   * The esi contains the encoded username and password
   * This will be sent to b2c, and our custom b2c template will fill the form out and submit it
   */
  public async signinSSO(encodedUsernameAndPassword?: string, redirectUrlWithoutEsiParameter?: string): Promise<AuthenticationResult> {

    encodedUsernameAndPassword = encodedUsernameAndPassword || this.urlHelper.getQuerystringValue('esi');
    this.cacheService.add(`iscXMLAddressPunchout`, false, null, CacheScope.UserSessionStorage);
    // This method is invoked when queryparameter "esi" is in the URL
    // We must make sure the redirect URL does not contain the esi parameter
    // or this will trigger again after sso login and we will be stuck in a loop
    redirectUrlWithoutEsiParameter = redirectUrlWithoutEsiParameter || this.urlHelper
      .parseUrl()
      .removeQueryString('esi')
      .toString();

    const signinOptions: SigninOptions = {
      queryParams: {
        esi: encodedUsernameAndPassword
        //,debugSSOFlow: `true` // Set to true to disable automatic login flow in B2C template. Useful for debugging
      },
      stateParams: {
        ssoInitiatedLogin: 'true',
        ds: this.urlHelper.getQuerystringValue('ds') || null
      },
      returnUrl: redirectUrlWithoutEsiParameter
    };

    this.log.debug(`signinSSO`, JSON.stringify({
      options: signinOptions,
      inIframe: this.isInIframe
    }, null, 2));
    if (this.isInIframe) {

      return new Promise(() => {

        this.signinPopup(signinOptions).then(() => {
          window.location.href = redirectUrlWithoutEsiParameter;
          this.cacheService.add('sso-disable-browser-notice', 'true', null, CacheScope.GlobalSessionStorage);
        },
        loginError => {
          this.log.error(`SSO Login Error`, loginError);

          if (loginError?.errorCode === 'popup_window_error') {
            // Unable to open popup. It maybe be blocked by browser.
            // Redirect to /user/login page with esi parameters to let user click-open the popup

            const loginUrl = this.urlHelper.create('/en/user/login', {
              esipopup: encodedUsernameAndPassword,
              esiurl: redirectUrlWithoutEsiParameter
            });

            this.log.warn(`Popup was blocked. Redirecting to ${loginUrl.toString()}`);
            window.location.href = loginUrl.toString();
          } else {
            // Show some error details from b2c instead of just a never-ending loader
            document.querySelector('.my-app-state__info').innerHTML = `<h3 class="my-header">A B2C error occured</h3><p class="message"></p>`;
            document.querySelector('.my-app-state__info .message').textContent = loginError.message;
            (document.querySelector('.my-app-state__icon') as HTMLDivElement).style.display = 'none';

            if (loginError?.errorCode === 'redirect_uri_mismatch') {
              const configError = document.createElement('p');
              configError.innerHTML = `<hr><p><strong>This is a configuration error that must be updated by developers.</strong></p>`;
              document.querySelector('.my-app-state__info').appendChild(configError);
            }
          }
        });

      });
    } else {
      this.signinRedirect(signinOptions);
    }

  }

  public getAccessToken(): string {
    return null; // this.authenticationResult?.accessToken;
  }

  /**
   * Define the default query parameters to use if not overridden by user
   */
  private getB2CDefaultQueryParameters(): { [key: string]: string } {
    return {
      ui_locales: this.getLanguage()
    };
  }

  /**
   * Get language from snapshot or browser
   */
  private getLanguage(): string {
    return this.broadcastService.snapshot.language || environment.defaultLanguage;
  }

  /**
   * Define the default state parameters to use if not overridden by user
   */
  private getB2CDefaultStateParameters(): { [key: string]: string } {
    return {
      lang: this.getLanguage(),
      returnUrl: window.location.href
    };
  }

  /**
   * Map the key/value object into a querystring to use as state parameter to b2c
   */
  private createB2CStateString(params?: { [key: string]: string }): string {
    return params ? btoa(Object
      .keys(params)
      .map(parameterName => `${encodeURIComponent(parameterName)}=${encodeURIComponent(params[parameterName])}`)
      .join('&')) : '';
  }

  /**
   * Invoked before load of global config to quickly resolve and handle any B2C events (callbacks/redirects)
   * This will wait for InteractionStatus.None - when B2C is done processing
   * Then we can start handling the events we received
   */
  public async processB2CEvents(): Promise<void> {

    // If there's an observable avaiable then MSAL is working on something that we should wait for
    if (this.msalService.handleRedirectObservable() != null) {

      this.log.debug('processB2CEvents - Waiting for msal to finish activities');
      return new Promise((resolve) => {
        this.msalBroadcastService.inProgress$
          .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            first()
          )
          .subscribe(async () => {
            this.log.debug('processB2CEvents - msal activities completed');
            this.log.debug(`events`, JSON.stringify(this.b2cEvents, null, 2));

            // B2C is finished with all processing. Below, we must handle the results
            if (this.b2cEvents[EventType.LOGIN_SUCCESS]) {
              await this.onB2CLoginSuccess(this.b2cEvents[EventType.LOGIN_SUCCESS]);
            } else if (this.b2cEvents[EventType.LOGOUT_SUCCESS]) {
              await neverEndingPromise();
            } else if (this.b2cEvents[EventType.LOGIN_FAILURE]) {
              await this.onB2CLoginFailure(this.b2cEvents[EventType.LOGIN_FAILURE]);
            }

            resolve();

          });
      });
    }
    return Promise.resolve();
  }

  private parseState(value: string): { [key: string]: string } {
    if (value) {
      try {
        const decoded = atob(value);
        if( decoded) {
          const parsed = this.urlHelper.parseUrl(`?${decoded}`);
          return parsed?.query || {};
        }
      } catch (e) {
        return {};
      }
    }
  }

  private async onB2CLoginSuccess(event: EventMessage): Promise<void> {

    this.log.debug(`onB2CLoginSuccess`, event);
    const payload = event?.payload as B2CLoginSuccessPayload;
    const parsedState = this.parseState(payload.state);

    this.setActiveAccount(this.msalService.instance.getAllAccounts());

    return new Promise(resolve => {

      if (parsedState.ssoInitiatedLogin) {
        this.cacheService.add('sso-disable-browser-notice', 'true', null, CacheScope.GlobalSessionStorage);
      }

      if (parsedState.mibpB2cSignoutAfterRedirect === 'true') {
        this.signout(parsedState.returnUrl, 'app-password-reset');
        return;
      }

      // We have just logged in. Check the "returnUrl" in the state parameter (returnUrl)
      if (parsedState.returnUrl) {
        this.log.debug(`Found returnUrl in state parameters. Redirecting to ${parsedState.returnUrl}`);
        window.location.href = parsedState.returnUrl;
      } else {
        this.log.warn(`ReturnUrl is not valid, redirecting to host instead ${parsedState.returnUrl}`);
        window.location.href = '/';
        return; // Never resolve since we're redirecting
      }

      resolve();
    });
  }

  private onB2CAccessTokenSuccess(event: EventMessage): void {
    window.sessionStorage.removeItem('mibp.auth.clear.msal');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = (event.payload as {expiresOn: Date, fromCache: boolean, accessToken: string});
    this.broadcastService.setAccessToken({
      expiresOn: payload?.expiresOn,
      accessToken: payload.accessToken,
      fromCache: payload.fromCache
    });
  }

  private async onB2CLoginFailure(event: EventMessage): Promise<void> {

    return new Promise(() => {
      // See https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes

      const serverError = event?.error as B2CServerError;
      const errorCodeMatch = serverError?.errorMessage?.match(/AADB2C\d+/);
      const errorCode = errorCodeMatch ? errorCodeMatch[0] : null;

      if (errorCode === 'AADB2C90118') {
        this.triggerPasswordResetRedirect();
      } else {

        // Unhandled error. Show message and stop application
        this.appState.setState({
          state: ApplicationStates.UnhandledException,
          error: true,
          internalStatus: 'error-handler.service',
          stopStartupGuardProcessing: true,
          exception: {
            message: serverError?.errorMessage
          }
        });
      }
    });
  }

  private triggerPasswordResetRedirect(): void {
    this.appState.setState({
      state: ApplicationStates.StartResetPasswordRedirect,
      stopStartupGuardProcessing: true
    });

    this.log.debug(`Redirecting to password reset`);

    this.signinRedirect({
      b2cPolicy: environment.auth.b2c.policies.passwordReset
    });

  }

  /**
   * Check if the url path is / or /<lang>
   * Then we do not need to check router permissions but can redirect user to signin a bit faster
   */
  public testForImmediateSignin(): Promise<void> {

    if (this.isInIframe) {
      // We never want to sign in immediatly when in an iframe because then loginredirect is not allowed
      this.log.info(`[SKIP] testForImmediateSignin (we're inside an iframe)`);
      return Promise.resolve();
    }

    if (window?.location?.pathname.match(/^\/([a-z]{2}\/?|)$/i) && !this.isLoggedIn()) {
      this.appState.setState({ state: ApplicationStates.SSO, resourceStringKey: 'AppLoading_Authorization_RedirectingToLogin', textFallback: 'Redirecting to login page...'  });
      this.mibpSessionLog.info(`No language in URL - redirect to B2C login page immediately`);

      this.signinRedirect();
      return neverEndingPromise();
    } else {
      return Promise.resolve();
    }
  }


  /**
   * Invoked before load of global config to quickly resolve and handle any SSO attempts
   */
  public async processSingleSignOnEvents(): Promise<void> {
    const esiQuerystringValue = this.urlHelper.getQuerystringValue('esi');

    if (esiQuerystringValue) {
      this.log.debug(`processSingleSignOnEvents`, esiQuerystringValue);

      // Force a new client id before we login using sso
      this.clientIdService.newClientId();

      return new Promise((resolve) => {

        this.appState.setState({ state: ApplicationStates.SSO, resourceStringKey: 'AppLoading_Authorization_SSO', textFallback: 'Signing you in...'  });

        // We want to initiate SSO login but we are already loggedin. Trigger a logout redirect
        if (this.isLoggedIn()) {
          if (!this.isInIframe) {
            this.log.debug(`processSingleSignOnEvents - already loggedin. Signing out first`);
            this.signout(window.location.href);
            return;
          } else {
            this.log.warn(`processSingleSignOnEvents - already loggedin.`);
            this.signoutPopup().then(() => {
              this.log.warn(`processSingleSignOnEvents - popup signout worked`);
              resolve();
            }).catch(() => {
              this.log.warn(`processSingleSignOnEvents - popup signout did not work. Continuing anyway`);
              resolve();
            });

            return;

          }
        }
        this.signinSSO(esiQuerystringValue);
      });
    } else {
      this.log.debug(`processSingleSignOnEvents - no events detected`);
    }
    return Promise.resolve();
  }

  /**
   * Get auth configuration from the route and its parents
   *
   * For example - if root has "allowAnonymous" then it should be inherited down until a value is specifically set
   */
  public getRouteConfig(snapshot: ActivatedRouteSnapshot): RouteAuthConfig {

    let routeConfig: RouteAuthConfig = {
      allowAnonymous: false,
      permissionPolicy: null,
      postponeSignalrConnection: false
    };

    let current = snapshot;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hierarcyData: any[] = [];

    do {
      if (current.routeConfig) {
        if (current.routeConfig.data?.mibpGuard || current.routeConfig.data?.navItemId) {
          if (!current.routeConfig.data) {
            current.routeConfig.data = {};
          }
          if (!current.routeConfig.data?.mibpGuard) {
            current.routeConfig.data.mibpGuard = {};
          }
          if (typeof current.routeConfig.data?.mibpGuard?.postponeSignalrConnection === 'undefined' || current.routeConfig.data?.mibpGuard?.postponeSignalrConnection === null) {
            current.routeConfig.data.mibpGuard.postponeSignalrConnection = false;
          }
          if(typeof current.routeConfig.data?.mibpGuard?.allowAnonymous === 'undefined' || current.routeConfig.data?.mibpGuard?.allowAnonymous === null){
            current.routeConfig.data.mibpGuard.allowAnonymous = false;
          }
          hierarcyData.push(Object.assign({}, { navItemId: current.routeConfig?.data?.navItemId }, current.routeConfig.data.mibpGuard));
        }
      }
      current = current.parent;
    } while (current !== null);

    hierarcyData.reverse().forEach(authConfig => {
      routeConfig = Object.assign({}, routeConfig, authConfig);
    });

    const policyHasPermissionChecks = this.permissionService.policyHasPermissionChecks(routeConfig.permissionPolicy);

    // If permissions are required, then we can't allow anonymous
    if (policyHasPermissionChecks) {
      if (routeConfig.allowAnonymous) {
        routeConfig.allowAnonymous = false;
      }
    }

    return routeConfig;
  }

  /**
   * Trigger signout if the user is logged in (Will trigger logout redirect and return a never resolving promise)
   */
  public async signoutIfNeeded(): Promise<void> {
    if (this.isLoggedIn()) {
      this.signout();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise(() => {});
    }
    return Promise.resolve();
  }

  /**
   * Clear ClientID and other cached values
   */
  public clearAll(clearClientId = true): void {
    this.cacheService.remove('browser_dialog_seen');
    this.cacheService.remove('sso-disable-browser-notice');
    if (clearClientId) {
      this.cacheService.remove(environment.clientIdCacheKey);
    }
  }


  public startResetPasswordRedirect(): void {
    this.signinRedirect({
      b2cPolicy: environment.auth.b2c.policies.passwordReset,
      b2cRedirectUri: this.urlHelper.addHostnameToPath('/'),
      returnUrl: this.urlHelper.addHostnameToPath('/')
    });
  }

}
