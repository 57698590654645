import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonColors } from 'root/components/button/button.enum';
import { DropdownInput } from 'root/components/dropdown';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';
import { CaseType, ContactUsSupportCaseRequest } from 'root/mibp-openapi-gen/models';
import { FormValidationService, FormattingService, LoaderService, LocalizationService, NoticebarService } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MibpEmailValidator } from 'root/validators/email.validator';
import { requireCheckboxesToBeCheckedValidator } from 'root/validators/requireCheckboxesToBeChecked';
import { Subscription, firstValueFrom } from 'rxjs';
import { SupportCaseItemEnquiryType, SupportCaseItemEnquiryTypeValue } from './contact-us-item-enquiry-form.interface';
import { maxQuantityLimitValidator } from 'root/validators/MaxQuantityLimit.validator';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';


export interface SupportCaseItemEnquiryData {
  productCode: string,
  quantity?: number,
  enquiryType?: SupportCaseItemEnquiryType
}

@Component({
  selector: 'mibp-contact-us-item-enquiry-form',
  templateUrl: './contact-us-item-enquiry-form.component.html',
  styleUrls: ['./contact-us-item-enquiry-form.component.scss']
})
export class ContactUsItemEnquiryFormComponent implements OnInit, OnDestroy {
  protected contactUsItemEnquiryForm: UntypedFormGroup;
  protected enquiryTypes: DropdownInput[] = [];
  protected buttonColors = ButtonColors;
  protected formSubmitted: boolean;
  protected showEnquiryDropdown = false;
  protected isQuantityRequired: boolean;
  protected isEmailSelected: boolean;
  protected isPhoneNumberSelected: boolean;
  private formValueChangeSub: Subscription;
  private description: string;
  private partNumber: string;
  private quantity: string;

  @Input() showHeader = true;
  @Input() itemEnquiryData : SupportCaseItemEnquiryData = null;
  @Output() successfullySubmitted = new EventEmitter<boolean>();

  private stopUsingResources: () => void;

  constructor(private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private mibpSession: MibpSessionService,
    private formattingService: FormattingService,
    private supportCaseController: SupportCaseApiController,
    private loader: LoaderService,
    private noticeService: NoticebarService,
    private localizationService: LocalizationService){

  }
  ngOnDestroy(): void {
    if(this.stopUsingResources){
      this.stopUsingResources();
    }
    this.formValueChangeSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.description = (this.itemEnquiryData?.quantity == null ? '' : this.itemEnquiryData?.quantity) + '  ' + (this.itemEnquiryData?.productCode == null ? '' : this.itemEnquiryData?.productCode);

    this.contactUsItemEnquiryForm = this.fb.group({
      name: [this.mibpSession.current.user.firstName + ' ' + this.mibpSession.current.user.lastName, [Validators.required, MibpAllowedCharactersValidator()]],
      email: [this.mibpSession.current.user.email, MibpEmailValidator(this.formattingService)],
      phonenumber: [this.mibpSession.current.user.phone, MibpAllowedCharactersValidator()],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false)
      }, { validators: requireCheckboxesToBeCheckedValidator(1)}),
      enquiryType: [null, Validators.required],
      partNumber: [this.itemEnquiryData?.productCode, [Validators.required, MibpAllowedCharactersValidator()]],
      quantity: [this.itemEnquiryData?.quantity, {validators:maxQuantityLimitValidator(99)}],
      description: [this.description, MibpAllowedCharactersValidator()]
    });

    this.stopUsingResources = this.localizationService.using([SupportCaseItemEnquiryType.ProductAvailability, SupportCaseItemEnquiryType.ItemPrice, SupportCaseItemEnquiryType.ExpectedDeliveryDate, SupportCaseItemEnquiryType.OutPhasedItem], resourceStrings => {
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[0], value: SupportCaseItemEnquiryTypeValue.ProductAvailability});
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[1], value: SupportCaseItemEnquiryTypeValue.ItemPrice});
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[2], value: SupportCaseItemEnquiryTypeValue.ExpectedDeliveryDate});
      this.enquiryTypes.push(<DropdownInput>{text: resourceStrings[3], value: SupportCaseItemEnquiryTypeValue.OutPhasedItem});
      this.showEnquiryDropdown = true;
    });

    this.initForm();

    this.formValueChangeSub = this.contactUsItemEnquiryForm.controls.contactPreferenceGroup.valueChanges.subscribe(() => {
      this.isEmailSelected = this.contactUsItemEnquiryForm.controls.contactPreferenceGroup.get('byEmail').value;
      this.isPhoneNumberSelected = this.contactUsItemEnquiryForm.controls.contactPreferenceGroup.get('byPhone').value;
      this.isEmailSelected ? this.addRequiredValidators('email') : this.removeRequiredValidators('email');
      this.isPhoneNumberSelected ? this.addRequiredValidators('phonenumber') : this.removeRequiredValidators('phonenumber');
    });
  }

  initForm(){
    if(this.itemEnquiryData){
      // this.contactUsItemEnquiryForm.get('partNumber').patchValue(this.itemEnquiryData.productCode);
      // this.contactUsItemEnquiryForm.get('quantity').patchValue(this.itemEnquiryData.quantity);
      if(this.itemEnquiryData.enquiryType){
        const enquiryTypeText = this.localizationService.get(this.itemEnquiryData.enquiryType);
        this.contactUsItemEnquiryForm.get('enquiryType').patchValue(<DropdownInput>{text: enquiryTypeText, value: enquiryTypeText});
        this.contactUsItemEnquiryForm.get('enquiryType').disable();
        this.enquiryTypeChangedHandleValidators(this.itemEnquiryData.enquiryType);
      }
    }
  }

  partOnChange(){
    this.partNumber = this.contactUsItemEnquiryForm.get('partNumber')?.value == null ? '' : this.contactUsItemEnquiryForm.get('partNumber').value;
    this.quantity = this.contactUsItemEnquiryForm.get('quantity')?.value == null ? '' : this.contactUsItemEnquiryForm.get('quantity').value;

    this.description = this.quantity + '  ' + this.partNumber;
    this.contactUsItemEnquiryForm.get('description').patchValue(this.description);
  }

  quantityOnChange(){
    this.partNumber = this.contactUsItemEnquiryForm.get('partNumber')?.value == null ? '' : this.contactUsItemEnquiryForm.get('partNumber').value;
    this.quantity = this.contactUsItemEnquiryForm.get('quantity')?.value == null ? '' : this.contactUsItemEnquiryForm.get('quantity').value;

    this.description = this.quantity + '  ' + this.partNumber;
    this.contactUsItemEnquiryForm.get('description').patchValue(this.description);
  }

  enquiryTypeChanged(value: DropdownInput){
    if(value){
      this.enquiryTypeChangedHandleValidators(value.value);
    }
  }

  enquiryTypeChangedHandleValidators(enquiryType: SupportCaseItemEnquiryType | string){
    if(enquiryType === SupportCaseItemEnquiryType.ExpectedDeliveryDate){
      this.addRequiredValidators('quantity');
      this.isQuantityRequired = true;
    }
    else{
      this.removeRequiredValidators('quantity');
      this.isQuantityRequired = false;
    }
  }

  private addRequiredValidators(field: string){
    this.contactUsItemEnquiryForm.get(field).addValidators([Validators.required]);
    this.contactUsItemEnquiryForm.get(field).updateValueAndValidity();
  }

  private removeRequiredValidators(field: string){
    this.contactUsItemEnquiryForm.get(field).removeValidators(Validators.required);
    this.contactUsItemEnquiryForm.get(field).updateValueAndValidity();
  }

  async submit(){
    this.formSubmitted = true;
    if (this.contactUsItemEnquiryForm.invalid) {
      this.contactUsItemEnquiryForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactUsItemEnquiryForm, this.element);
      return;
    }
    const contactUsSupportCaseRequest = <ContactUsSupportCaseRequest>{
      caseType: CaseType.ItemEnquriy,
      categoryItemEnquiry: this.contactUsItemEnquiryForm.get('enquiryType').value.text,
      suppliedFullName: this.contactUsItemEnquiryForm.get('name').value,
      suppliedEmail: this.contactUsItemEnquiryForm.get('email').value,
      suppliedPhone: this.contactUsItemEnquiryForm.get('phonenumber').value,
      productCode: this.contactUsItemEnquiryForm.get('partNumber').value,
      quantity: this.contactUsItemEnquiryForm.get('quantity').value,
      prefferedEmailContact: this.contactUsItemEnquiryForm.controls.contactPreferenceGroup.get('byEmail').value,
      prefferedPhoneContact: this.contactUsItemEnquiryForm.controls.contactPreferenceGroup.get('byPhone').value,
      description: this.contactUsItemEnquiryForm.get('description').value
    };

    try {
      this.loader.showFullScreenLoader();
      await firstValueFrom(this.supportCaseController.submitContactUsCase({body: contactUsSupportCaseRequest}));
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(true);
    }
    catch(error){
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(false);
    }
  }
}
