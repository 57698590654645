
<div [style.opacity]="loadingSelectedFolder ? 0.5 : 1">
  <mibp-loader *ngIf="isLoadingTree"></mibp-loader>

<ng-container [ngTemplateOutlet]="treeTemplate" [ngTemplateOutletContext]="{folders: tree, depth: 0}"></ng-container>
</div>

<ng-template let-folders="folders" let-depth="depth" #treeTemplate>
  <ul class="folders" [style.marginLeft]="depth > 0 ? '33px' : null ">
    <li *ngFor="let folder of folders"
      class="folder"
      [class.folder--selected]="selectedPage?.id == folder.id"
      [attr.aria-expanded]="folder.expanded ? true : false"
      [attr.data-folder]="folder.id"
      [ngClass]="{ 'folder--page': folder.type == folderTypes.Page || folder.childCount == 0, 'folder--chapter': folder.type == folderTypes.Chapter && folder.childCount > 0 }">
      <a href="javascript:void(0)" (click)="toggleFolder(folder)"><span class="plus-minus" *ngIf="folder.type == folderTypes.Chapter && folder.childCount > 0">{{ folder.isExpanded ? '-' : '+' }}</span></a>
      <a href="javascript:void(0)" (click)="selectFolder(folder)">

        {{folder.name}}
        {{folder.assemblyNumber}}
        {{folder.revision}}
        <!-- {{folder.type == folderTypes.Chapter ? '(' + folder.childCount + ')' : ''}} -->
      </a>
        <mibp-loader *ngIf="folder.childrenLoading" [type]="'spinner'"></mibp-loader>

        <ng-template [ngIf]="folder.isExpanded && folder.children?.length > 0">
          <ng-container [ngTemplateOutlet]="treeTemplate" [ngTemplateOutletContext]="{folders: folder.children, depth: depth +1}"></ng-container>
        </ng-template>
    </li>
  </ul>
</ng-template>