/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetRelatedMediaRequestVm } from '../../models/get-related-media-request-vm';
import { GetRelatedMediaResponseVmPagedResponse } from '../../models/get-related-media-response-vm-paged-response';

export interface GetRelatedMedia$Params {
      body?: GetRelatedMediaRequestVm
}

export function getRelatedMedia(http: HttpClient, rootUrl: string, params?: GetRelatedMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<GetRelatedMediaResponseVmPagedResponse>> {
  const rb = new RequestBuilder(rootUrl, getRelatedMedia.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetRelatedMediaResponseVmPagedResponse>;
    })
  );
}

getRelatedMedia.PATH = '/media/relatedmedia';
