<div *ngIf="showFilter === true" class="mibp-table-filter">
  <label for="filter"><mibp-resource-string key="Global_SearchCriteria"></mibp-resource-string></label>
  <input name="filter" type="text" (input)="onFilterChanged($event.target.value)" [ngModel]='query' [disabled]="totalItems === 0 && query?.length === 0" />
  <mibp-button resourceStringKey="Global_ClearFilter" (click)="onFilterChanged('')" [disabled]="(totalItems === 0 && query?.length === 0) || query?.length === 0"></mibp-button>
</div>
<div class="mibp-table-responsive" *ngIf="rows">
  <table [ngClass]="{'table': true, 'compact': compact, 'no-max-width': noMaxWidth}">
    <thead>
      <tr>
        <th *ngFor="let column of columnDefs; let i = index" scope="col"><mibp-resource-string [key]="column.title"></mibp-resource-string></th>
        <th *ngIf="editable === 'singlerow'"></th>
        <th *ngIf="actionbutton || actionContent"></th>
      </tr>
    </thead>
    <tbody *ngIf="!isLoading && rows?.length > 0">
      <tr *ngFor="let row of rows; let i = index" [style.opacity]="isRowPending(i) ? 0.5 : 1" [attr.data-rowindex]="i">
        <td *ngFor="let column of columnDefs; let j = index" [ngClass]="column.dataCellClassList" >
          <mibp-loader type='spinner' *ngIf="column.isLoading === true"></mibp-loader>
          <span *ngIf="!isEditingRow(i) || column.readOnly">
            <span *ngIf="!column.dataType || column.dataType === 0" title="{{rows[i][j]}}">
              {{ rows[i][j] }}
            </span>
            <span *ngIf="column.dataType === 2">
              <mibp-resource-string required [key]="rows[i][j]" titleAttr="true"></mibp-resource-string>
            </span>
            <span class="mibp-table-editablecolumn" *ngIf="column.dataType === 3" title="{{rows[i][j]}}">
              <input type="text" [value]="rows[i][j]" (input)="rows[i][j] = $event.target.value" >
            </span>
            <span *ngIf="column.dataType === 4" [innerHTML]="getHtml(rows[i][j])"></span>
            <span *ngIf="column.dataType === 5">
              <mibp-datetime [date]=rows[i][j] format="datetime"></mibp-datetime>
            </span>
          </span>
          <span *ngIf="isEditingRow(i) && !column.readOnly">
            <mibp-datepicker
              [ngClass]="{ 'ng-dirty ng-invalid': !isFieldValid(i, column) }"
              [minDate]="column.minDate"
            (change)="editDate($event, i, column)" [date]="rows[i][j]"></mibp-datepicker>
          </span>
         </td>
         <td *ngIf="editable == 'singlerow'" style="width: 120px">
            <mibp-button *ngIf="!isEditingRow(i)" (click)="beginEditRow(i)" #btn [icon]="btn.Enum.Icons.Edit" [isSmall]="true"></mibp-button>
            <div *ngIf="isEditingRow(i)" >
              <mibp-button *ngIf="isEditingRow(i)" (click)="cancelEditRow(i)" #btn [icon]="btn.Enum.Icons.Undo" [isSmall]="true"></mibp-button>
              &nbsp;
              <mibp-button #btn (click)="saveRowChanges(i)" [icon]="btn.Enum.Icons.Save" [disabled]="!isRowValid(i)" [color]="btn.Enum.Colors.Orange" [isSmall]="true"></mibp-button>
            </div>
         </td>
         <td *ngIf="actionbutton">
            <mibp-button (click)="actionButton(i)"
              [disabled]="isDisabled && !isDisabledLoading"
              [icon]="actionButtonIcon"
              [showElementLoader]="isDisabled && (isDisabledLoading || loadingRows[i] === true)"
               #btn [isSmall]="false"><mibp-resource-string *ngIf="actionButtonText" [key]="actionButtonText"></mibp-resource-string></mibp-button>
         </td>
         <td *ngIf="actionContent">
          <ng-content select="[slot=actions]"></ng-content>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!isLoading && (!rows || rows?.length === 0)">
      <tr>
        <td [attr.colspan]="columnDefs.length">
          <mibp-resource-string *ngIf="query?.length > 0" key="Global_FilterYieldedNoResults"></mibp-resource-string>
          <mibp-resource-string *ngIf="!query || query?.length === 0" key="Global_NoItemsAvailable"></mibp-resource-string>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="isLoading">
      <tr class="is-loading">
        <td [attr.colspan]="columnDefs.length">
          <mibp-loader *ngIf="isLoading"></mibp-loader>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<mibp-paginator class="mibp-table-pagination" [startIndex]="startIndex" [totalItems]="totalItems" [pageSize]="pageSize" (changePage)="onChangePage($event)"> </mibp-paginator>
