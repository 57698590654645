import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'root/services';
import { AppInjector } from 'root/services';

@Component({
  template: ''
})
export class LocalizedComponent implements OnInit {

  public languageCode;
  private localizationSvc: LocalizationService;
  private resourceStringValues = {};

  constructor() {
    const injector = AppInjector.getInjector();
    this.localizationSvc = injector.get(LocalizationService);
  }

  /**
    * Return the translated text by key
    * @param resourceKey Return
    */
  getString(resourceKey: string) {
    if (typeof this.resourceStringValues && this.resourceStringValues[resourceKey]) {
      return this.resourceStringValues[resourceKey];
    }
    return `${this.languageCode}:'${resourceKey}'`;
  }

  ngOnInit() {
    this.localizationSvc.currentLocale.forEach(locale => {
      this.resourceStringValues = locale.translations;
      this.languageCode = locale.code;
    });
  }


}
