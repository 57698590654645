import { MibpValidationMessageComponent } from './validation-message.component';
import { Component, Input } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'mibp-validation-messages',
  styleUrls: ['./validation-messages.component.scss'],
  templateUrl: './validation-messages.component.html'
})
export class MibpValidationMessagesComponent {
  private messages: MibpValidationMessageComponent[] = [];
  private formControlErrors: any;
  private formControlName: string;
  private isFormSubmitted: boolean;
  private form: NgForm;

  @Input()
  set controlName(value: string) {
    this.formControlName = value;
    this.updateChildControls();
  }

  @Input()
  set ngForm(value: NgForm) {
    this.form = value;
    this.updateChildControls();
  }

  @Input()
  set errors(value: any) {
    this.formControlErrors = value;
    this.updateChildControls();
  }

  @Input()
  set formSubmitted(value: boolean) {
    this.isFormSubmitted = !!value;
    this.updateChildControls();
  }

  private updateChildControls() {
    this.messages.forEach(childControl => {
      childControl.updateControlErrors(this.form, this.formControlName, this.formControlErrors, this.isFormSubmitted);
    });
  }

  addMessage(mibpMessage: MibpValidationMessageComponent): void {
    mibpMessage.updateControlErrors(this.form, this.formControlName, this.formControlErrors, this.isFormSubmitted);
    this.messages.push(mibpMessage);
  }

}
