/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { allRestrictedItem } from '../fn/restricted-items/all-restricted-item';
import { AllRestrictedItem$Params } from '../fn/restricted-items/all-restricted-item';
import { allRestrictedItem$Plain } from '../fn/restricted-items/all-restricted-item-plain';
import { AllRestrictedItem$Plain$Params } from '../fn/restricted-items/all-restricted-item-plain';
import { deleteRestrictedItem } from '../fn/restricted-items/delete-restricted-item';
import { DeleteRestrictedItem$Params } from '../fn/restricted-items/delete-restricted-item';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { RestrictedItem } from '../models/restricted-item';
import { restrictedItemById } from '../fn/restricted-items/restricted-item-by-id';
import { RestrictedItemById$Params } from '../fn/restricted-items/restricted-item-by-id';
import { restrictedItemById$Plain } from '../fn/restricted-items/restricted-item-by-id-plain';
import { RestrictedItemById$Plain$Params } from '../fn/restricted-items/restricted-item-by-id-plain';
import { RestrictedItemEditingViewModel } from '../models/restricted-item-editing-view-model';
import { restrictedItemForEdit } from '../fn/restricted-items/restricted-item-for-edit';
import { RestrictedItemForEdit$Params } from '../fn/restricted-items/restricted-item-for-edit';
import { restrictedItemForEdit$Plain } from '../fn/restricted-items/restricted-item-for-edit-plain';
import { RestrictedItemForEdit$Plain$Params } from '../fn/restricted-items/restricted-item-for-edit-plain';
import { saveRestrictedItem } from '../fn/restricted-items/save-restricted-item';
import { SaveRestrictedItem$Params } from '../fn/restricted-items/save-restricted-item';
import { searchGridRestrictedItem } from '../fn/restricted-items/search-grid-restricted-item';
import { SearchGridRestrictedItem$Params } from '../fn/restricted-items/search-grid-restricted-item';
import { searchGridRestrictedItem$Plain } from '../fn/restricted-items/search-grid-restricted-item-plain';
import { SearchGridRestrictedItem$Plain$Params } from '../fn/restricted-items/search-grid-restricted-item-plain';

@Injectable({ providedIn: 'root' })
export class RestrictedItemsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `restrictedItemsControllerAllRestrictedItem()` */
  static readonly RestrictedItemsControllerAllRestrictedItemPath = '/restricteditems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allRestrictedItem$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  allRestrictedItem$Plain$Response(params?: AllRestrictedItem$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RestrictedItem>>> {
    return allRestrictedItem$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allRestrictedItem$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allRestrictedItem$Plain(params?: AllRestrictedItem$Plain$Params, context?: HttpContext): Observable<Array<RestrictedItem>> {
    return this.allRestrictedItem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RestrictedItem>>): Array<RestrictedItem> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allRestrictedItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  allRestrictedItem$Response(params?: AllRestrictedItem$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RestrictedItem>>> {
    return allRestrictedItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `allRestrictedItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allRestrictedItem(params?: AllRestrictedItem$Params, context?: HttpContext): Observable<Array<RestrictedItem>> {
    return this.allRestrictedItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RestrictedItem>>): Array<RestrictedItem> => r.body)
    );
  }

  /** Path part for operation `restrictedItemsControllerRestrictedItemByIdrestrictedItemId()` */
  static readonly RestrictedItemsControllerRestrictedItemByIdrestrictedItemIdPath = '/restricteditems/{restrictedItemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restrictedItemById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  restrictedItemById$Plain$Response(params: RestrictedItemById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RestrictedItem>> {
    return restrictedItemById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restrictedItemById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restrictedItemById$Plain(params: RestrictedItemById$Plain$Params, context?: HttpContext): Observable<RestrictedItem> {
    return this.restrictedItemById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestrictedItem>): RestrictedItem => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restrictedItemById()` instead.
   *
   * This method doesn't expect any request body.
   */
  restrictedItemById$Response(params: RestrictedItemById$Params, context?: HttpContext): Observable<StrictHttpResponse<RestrictedItem>> {
    return restrictedItemById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restrictedItemById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restrictedItemById(params: RestrictedItemById$Params, context?: HttpContext): Observable<RestrictedItem> {
    return this.restrictedItemById$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestrictedItem>): RestrictedItem => r.body)
    );
  }

  /** Path part for operation `restrictedItemsControllerDeleteRestrictedItemrestrictedItemId()` */
  static readonly RestrictedItemsControllerDeleteRestrictedItemrestrictedItemIdPath = '/restricteditems/{restrictedItemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRestrictedItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRestrictedItem$Response(params: DeleteRestrictedItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteRestrictedItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRestrictedItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRestrictedItem(params: DeleteRestrictedItem$Params, context?: HttpContext): Observable<void> {
    return this.deleteRestrictedItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `restrictedItemsControllerSaveRestrictedItemrestrictedItem()` */
  static readonly RestrictedItemsControllerSaveRestrictedItemrestrictedItemPath = '/restricteditems/saverestricteditem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveRestrictedItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveRestrictedItem$Response(params?: SaveRestrictedItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveRestrictedItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveRestrictedItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveRestrictedItem(params?: SaveRestrictedItem$Params, context?: HttpContext): Observable<void> {
    return this.saveRestrictedItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `restrictedItemsControllerSearchGridRestrictedItemoptions()` */
  static readonly RestrictedItemsControllerSearchGridRestrictedItemoptionsPath = '/restricteditems/searchgrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGridRestrictedItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGridRestrictedItem$Plain$Response(params?: SearchGridRestrictedItem$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGridRestrictedItem$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGridRestrictedItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGridRestrictedItem$Plain(params?: SearchGridRestrictedItem$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGridRestrictedItem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGridRestrictedItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGridRestrictedItem$Response(params?: SearchGridRestrictedItem$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGridRestrictedItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGridRestrictedItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGridRestrictedItem(params?: SearchGridRestrictedItem$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGridRestrictedItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `restrictedItemsControllerRestrictedItemForEditrestrictedItemId()` */
  static readonly RestrictedItemsControllerRestrictedItemForEditrestrictedItemIdPath = '/restricteditems/{restrictedItemId}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restrictedItemForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  restrictedItemForEdit$Plain$Response(params: RestrictedItemForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RestrictedItemEditingViewModel>> {
    return restrictedItemForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restrictedItemForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restrictedItemForEdit$Plain(params: RestrictedItemForEdit$Plain$Params, context?: HttpContext): Observable<RestrictedItemEditingViewModel> {
    return this.restrictedItemForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestrictedItemEditingViewModel>): RestrictedItemEditingViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restrictedItemForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  restrictedItemForEdit$Response(params: RestrictedItemForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<RestrictedItemEditingViewModel>> {
    return restrictedItemForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restrictedItemForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restrictedItemForEdit(params: RestrictedItemForEdit$Params, context?: HttpContext): Observable<RestrictedItemEditingViewModel> {
    return this.restrictedItemForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestrictedItemEditingViewModel>): RestrictedItemEditingViewModel => r.body)
    );
  }

}
