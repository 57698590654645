import { Injectable } from '@angular/core';
import { environment } from 'root/environment';
import { BroadcastService } from '..';
import { UserType } from 'root/mibp-openapi-gen/models';

let windowAsAny: any;
@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  private googleTagManagerIsInitalized = false;

  constructor(
    private broadcastService: BroadcastService,
    private window: Window

  ) {
    windowAsAny = window;
  }

  setupGoogleTagManager() {

    if (environment.name === 'lde' || !environment.googleTagManager?.containerId || !environment.googleTagManager?.auth || !environment.googleTagManager?.preview) {
      return;
    }

    this.broadcastService.mibpSession.subscribe(sessionVm => {
      if (sessionVm && sessionVm.user && !this.googleTagManagerIsInitalized) {
        this.initGoogleScriptAndDataLayer(sessionVm.user.type);
      }
    });
  }

  private initGoogleScriptAndDataLayer(userType: UserType): void{
    this.googleTagManagerIsInitalized = true;
    const userTypeAsString = userType == UserType.Internal ? 'internal' : 'external';
    const googleTagManagerContainerId = environment.googleTagManager.containerId;
    const googleTagManagerAuth = environment.googleTagManager.auth;
    const googleTagManagerPreview = environment.googleTagManager.preview;

    const googleTagManagerScript = document.createElement('script');
    googleTagManagerScript.innerHTML = `
    dataLayer = [{
      'userType': '${userTypeAsString}'
    }];

    // Make sure cookies are secure and limited to the Environment domain (for example dev-ef21b9ad.portal.my.sandvik instead of .my.sandvik)
    function gtag(){dataLayer.push(arguments);}
    gtag('set', {cookie_flags: 'SameSite=None;Secure;domain=${location.host}'});

    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${googleTagManagerAuth}&gtm_preview=${googleTagManagerPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagManagerContainerId}')

    `;

    const firstElementInHead = document.head.firstElementChild;
    document.head.insertBefore(googleTagManagerScript, firstElementInHead);
  }

  //data should be an object with key value pairs.
  pushCustomEvent(eventName: string, data: { [key: string]: string } = {}) {
    data.event = eventName;
    windowAsAny?.dataLayer?.push(data);
  }

}

