/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DayStatisticsViewModel } from '../models/day-statistics-view-model';
import { getAttachements } from '../fn/message-logs/get-attachements';
import { GetAttachements$Params } from '../fn/message-logs/get-attachements';
import { getAttachements$Plain } from '../fn/message-logs/get-attachements-plain';
import { GetAttachements$Plain$Params } from '../fn/message-logs/get-attachements-plain';
import { getMessageTypes } from '../fn/message-logs/get-message-types';
import { GetMessageTypes$Params } from '../fn/message-logs/get-message-types';
import { getMessageTypes$Plain } from '../fn/message-logs/get-message-types-plain';
import { GetMessageTypes$Plain$Params } from '../fn/message-logs/get-message-types-plain';
import { getStatistics } from '../fn/message-logs/get-statistics';
import { GetStatistics$Params } from '../fn/message-logs/get-statistics';
import { getStatistics$Plain } from '../fn/message-logs/get-statistics-plain';
import { GetStatistics$Plain$Params } from '../fn/message-logs/get-statistics-plain';
import { getTableChartData } from '../fn/message-logs/get-table-chart-data';
import { GetTableChartData$Params } from '../fn/message-logs/get-table-chart-data';
import { getTableChartData$Plain } from '../fn/message-logs/get-table-chart-data-plain';
import { GetTableChartData$Plain$Params } from '../fn/message-logs/get-table-chart-data-plain';
import { getTables } from '../fn/message-logs/get-tables';
import { GetTables$Params } from '../fn/message-logs/get-tables';
import { getTables$Plain } from '../fn/message-logs/get-tables-plain';
import { GetTables$Plain$Params } from '../fn/message-logs/get-tables-plain';
import { getTableTopErrors } from '../fn/message-logs/get-table-top-errors';
import { GetTableTopErrors$Params } from '../fn/message-logs/get-table-top-errors';
import { getTableTopErrors$Plain } from '../fn/message-logs/get-table-top-errors-plain';
import { GetTableTopErrors$Plain$Params } from '../fn/message-logs/get-table-top-errors-plain';
import { list } from '../fn/message-logs/list';
import { List$Params } from '../fn/message-logs/list';
import { list$Plain } from '../fn/message-logs/list-plain';
import { List$Plain$Params } from '../fn/message-logs/list-plain';
import { MessageLogFileViewModel } from '../models/message-log-file-view-model';
import { MessageLogLogTableChartDataViewModel } from '../models/message-log-log-table-chart-data-view-model';
import { MessageLogRowViewModelTableStorageResult } from '../models/message-log-row-view-model-table-storage-result';
import { MessageLogTableErrorsViewModel } from '../models/message-log-table-errors-view-model';
import { MessageLogTableViewModel } from '../models/message-log-table-view-model';
import { MessageLogTypeDescriptionViewModel } from '../models/message-log-type-description-view-model';

@Injectable({ providedIn: 'root' })
export class MessageLogsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messageLogsControllerGetMessageTypes()` */
  static readonly MessageLogsControllerGetMessageTypesPath = '/messagelogs/messagetypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessageTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageTypes$Plain$Response(params?: GetMessageTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageLogTypeDescriptionViewModel>>> {
    return getMessageTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMessageTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageTypes$Plain(params?: GetMessageTypes$Plain$Params, context?: HttpContext): Observable<Array<MessageLogTypeDescriptionViewModel>> {
    return this.getMessageTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageLogTypeDescriptionViewModel>>): Array<MessageLogTypeDescriptionViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessageTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageTypes$Response(params?: GetMessageTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageLogTypeDescriptionViewModel>>> {
    return getMessageTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMessageTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageTypes(params?: GetMessageTypes$Params, context?: HttpContext): Observable<Array<MessageLogTypeDescriptionViewModel>> {
    return this.getMessageTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageLogTypeDescriptionViewModel>>): Array<MessageLogTypeDescriptionViewModel> => r.body)
    );
  }

  /** Path part for operation `messageLogsControllerListfilter()` */
  static readonly MessageLogsControllerListfilterPath = '/messagelogs/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  list$Plain$Response(params?: List$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageLogRowViewModelTableStorageResult>> {
    return list$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  list$Plain(params?: List$Plain$Params, context?: HttpContext): Observable<MessageLogRowViewModelTableStorageResult> {
    return this.list$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageLogRowViewModelTableStorageResult>): MessageLogRowViewModelTableStorageResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  list$Response(params?: List$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageLogRowViewModelTableStorageResult>> {
    return list(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  list(params?: List$Params, context?: HttpContext): Observable<MessageLogRowViewModelTableStorageResult> {
    return this.list$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageLogRowViewModelTableStorageResult>): MessageLogRowViewModelTableStorageResult => r.body)
    );
  }

  /** Path part for operation `messageLogsControllerGetAttachementsrowKey()` */
  static readonly MessageLogsControllerGetAttachementsrowKeyPath = '/messagelogs/{rowKey}/getattachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachements$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachements$Plain$Response(params: GetAttachements$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageLogFileViewModel>>> {
    return getAttachements$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachements$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachements$Plain(params: GetAttachements$Plain$Params, context?: HttpContext): Observable<Array<MessageLogFileViewModel>> {
    return this.getAttachements$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageLogFileViewModel>>): Array<MessageLogFileViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachements$Response(params: GetAttachements$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageLogFileViewModel>>> {
    return getAttachements(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachements(params: GetAttachements$Params, context?: HttpContext): Observable<Array<MessageLogFileViewModel>> {
    return this.getAttachements$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageLogFileViewModel>>): Array<MessageLogFileViewModel> => r.body)
    );
  }

  /** Path part for operation `messageLogsControllerGetTablesfromTo()` */
  static readonly MessageLogsControllerGetTablesfromToPath = '/messagelogs/tables';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTables$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTables$Plain$Response(params?: GetTables$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageLogTableViewModel>>> {
    return getTables$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTables$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTables$Plain(params?: GetTables$Plain$Params, context?: HttpContext): Observable<Array<MessageLogTableViewModel>> {
    return this.getTables$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageLogTableViewModel>>): Array<MessageLogTableViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTables()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTables$Response(params?: GetTables$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageLogTableViewModel>>> {
    return getTables(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTables$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTables(params?: GetTables$Params, context?: HttpContext): Observable<Array<MessageLogTableViewModel>> {
    return this.getTables$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageLogTableViewModel>>): Array<MessageLogTableViewModel> => r.body)
    );
  }

  /** Path part for operation `messageLogsControllerGetTableTopErrorstableName()` */
  static readonly MessageLogsControllerGetTableTopErrorstableNamePath = '/messagelogs/table/{tableName}/errors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTableTopErrors$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableTopErrors$Plain$Response(params: GetTableTopErrors$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageLogTableErrorsViewModel>>> {
    return getTableTopErrors$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTableTopErrors$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableTopErrors$Plain(params: GetTableTopErrors$Plain$Params, context?: HttpContext): Observable<Array<MessageLogTableErrorsViewModel>> {
    return this.getTableTopErrors$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageLogTableErrorsViewModel>>): Array<MessageLogTableErrorsViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTableTopErrors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableTopErrors$Response(params: GetTableTopErrors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageLogTableErrorsViewModel>>> {
    return getTableTopErrors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTableTopErrors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableTopErrors(params: GetTableTopErrors$Params, context?: HttpContext): Observable<Array<MessageLogTableErrorsViewModel>> {
    return this.getTableTopErrors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageLogTableErrorsViewModel>>): Array<MessageLogTableErrorsViewModel> => r.body)
    );
  }

  /** Path part for operation `messageLogsControllerGetTableChartDatatableName()` */
  static readonly MessageLogsControllerGetTableChartDatatableNamePath = '/messagelogs/table/{tableName}/chartdata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTableChartData$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableChartData$Plain$Response(params: GetTableChartData$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageLogLogTableChartDataViewModel>> {
    return getTableChartData$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTableChartData$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableChartData$Plain(params: GetTableChartData$Plain$Params, context?: HttpContext): Observable<MessageLogLogTableChartDataViewModel> {
    return this.getTableChartData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageLogLogTableChartDataViewModel>): MessageLogLogTableChartDataViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTableChartData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableChartData$Response(params: GetTableChartData$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageLogLogTableChartDataViewModel>> {
    return getTableChartData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTableChartData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableChartData(params: GetTableChartData$Params, context?: HttpContext): Observable<MessageLogLogTableChartDataViewModel> {
    return this.getTableChartData$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageLogLogTableChartDataViewModel>): MessageLogLogTableChartDataViewModel => r.body)
    );
  }

  /** Path part for operation `messageLogsControllerGetStatisticsfromTo()` */
  static readonly MessageLogsControllerGetStatisticsfromToPath = '/messagelogs/statistics/{from}/{to}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatistics$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistics$Plain$Response(params: GetStatistics$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DayStatisticsViewModel>>> {
    return getStatistics$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStatistics$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistics$Plain(params: GetStatistics$Plain$Params, context?: HttpContext): Observable<Array<DayStatisticsViewModel>> {
    return this.getStatistics$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DayStatisticsViewModel>>): Array<DayStatisticsViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistics$Response(params: GetStatistics$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DayStatisticsViewModel>>> {
    return getStatistics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistics(params: GetStatistics$Params, context?: HttpContext): Observable<Array<DayStatisticsViewModel>> {
    return this.getStatistics$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DayStatisticsViewModel>>): Array<DayStatisticsViewModel> => r.body)
    );
  }

}
