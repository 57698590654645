import { Component, Input } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

export interface ToastButton {
  id: string;
  textResourceString: string;
}

@Component({
  selector: '[mibp-toast]',
  styleUrls: ['./toast.component.scss'],
  templateUrl: './toast.component.html',
  preserveWhitespaces: false
})
export class MibpToastComponent extends Toast {

  @Input() isResourceString = false;
  @Input() buttons: ToastButton[];
  @Input() titleResourceStringMacros: { [key: string]: string};
  @Input() bodyResourceStringMacros: { [key: string]: string};

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  override tapToast() {
    // We just want to prevent default here, so we do not need to do anything
  }

  close() {
    super.tapToast();
  }

  action(btn: ToastButton) {
    this.toastPackage.triggerAction(btn);
    return false;
  }
}
