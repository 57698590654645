import { DropdownInput } from 'root/components';
import { SupportCaseItemEnquiryData } from '../new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.component';
export interface ContactUsDialogInit {
  open: boolean;
  formData?: ContactUsFormData;
}

export interface ContactUsFormData {
  topic?: ContactUsTopic;
  name?: string;
  organization?: string;
  country?: DropdownInput;
  email?: string;
  phone?: string;
  message?: string;
  orderNumber?:string
  partNumber?:string,
  orderType?:string
}

export enum ContactUsTopic {
  BackOrder = 'ContactUs_TopicOption_BackOrder',
  Feedback = 'ContactUs_TopicOption_Feedback',
  Order = 'ContactUs_TopicOption_Order',
  Quote = 'ContactUs_TopicOption_Quote',
  Returns = 'ContactUs_TopicOption_Returns',
  Trash = 'ContactUs_TopicOption_Trash',
  Enquiry = 'ContactUs_TopicOption_Enquiry',
  TechnicalSupport = 'ContactUs_TopicOption_TechnicalSupport',
  RequestPrice = 'ContactUs_TopicOption_RequestPrice',
  MySandvikInsightEnquiry = 'ContactUs_TopicOption_MySandvikInsightEnquiry',
  MySandvikProductivityEnquiry = 'ContactUs_TopicOption_MySandvikProductivityEnquiry',
  RequestOutPhasedItemReplacement = "ContactUs_TopicOption_RequestItemReplacement"
}

