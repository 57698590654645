import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Guid } from 'guid-typescript';
import { AddItemToCartSource, PriceAndAvailabilitySource, SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';
import { BroadcastService } from 'root/services/broadcast-service/broadcast.service';
import { CartService, QuickOrderLine } from 'root/services/cart-service/cart.service';
import { FrontendContextService } from 'root/services/front-end-context/front-end-context.service';
import { LogService, MibpLogger } from 'root/services/logservice';
import { ApiService, SignalR_DeliverySequence} from 'root/services/mibp-api-services';
import { Subscription } from 'rxjs';
import { ProductListItem, ProductListItemComponent } from '..';

export interface ProductListConfig {
  hidePrice: boolean;
  hideTotalPrice: boolean;
  hideRelatedProducts: boolean;
  hideMachineLinks: boolean;
  hideAddToCart: boolean;
  hideDelete: boolean;
  hideAction: boolean;
  isKitConfigurator: boolean;

}

@Component({
  selector: 'mibp-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['product-list-shared.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductListComponent implements OnInit, OnDestroy {

  @Input() cartId: Guid;
  @Input() hidePrice: boolean;
  @Input() hideTotalPrice: boolean;
  @Input() hideAvailability: boolean;
  @Input() hideRelatedProducts: boolean;
  @Input() hideAction: boolean;
  @Input() hideAddToCart: boolean;
  @Input() hideAddToCartBig = true;
  @Input() hideDelete: boolean;
  @Input() hideRelatedButtons: boolean;
  @Input() showRecommendedQuantity: boolean;
  @Input() listConfig: ProductListConfig;
  @Output() itemRemoved = new EventEmitter<Guid>();
  @Input() priceAndAvailabilitySource=  PriceAndAvailabilitySource.ActiveCart;
  @Input() addItemToCartSource =  AddItemToCartSource.QuickAdd;
  @Input() isNarrowLayout = false;
  @Input() isActiveCart = false;
  @Input() disableDuplicate = false;
  @Input() showAvailableQuantity = false;
  @Output() forwardValidDecimalChange: EventEmitter<any> = new EventEmitter();
  @Input() set ecomItems(value: ProductListItem[]) {
    this.items = value;
  }
  activeDeliverySequence: SessionDeliverySequenceViewModel;
  isInCart = false;
  isAddingSubscription: Subscription;
  actAsSubscription: Subscription;
  itemsAreBeingAdded = false;
  items: ProductListItem[];
  log: MibpLogger;
  isValidDecimalProducts = true;

  @ViewChildren('cartItem') cartItemComponents: QueryList<ProductListItemComponent>;

  constructor(private cartService: CartService,
              private api: ApiService,
              private broadcast: BroadcastService,
              private frontEndContext: FrontendContextService,
              logger: LogService) {
    this.log = logger.withPrefix(`product-list.component`);
  }

  onvalidDecimalQuantity(isValid?: boolean): void{
    this.forwardValidDecimalChange.emit(isValid);
  }

  public removeByIndex(index: number): void {
    const component: ProductListItemComponent = this.cartItemComponents.toArray()[index];
    if (component) {
      component.leaveTheStage().then(() => {
        this.items.splice(index, 1);
      });
    }
  }

  public removeById(id: Guid): void {
    const components = this.cartItemComponents.toArray();
    const index: number = components.findIndex(f => f.productListitem.itemId.toString() === id.toString());
    if (index !== -1) {
      components[index].leaveTheStage().then(() => {
        this.items.splice(index, 1);
      });
    }
  }

  onItemRemoved(_itemIndex: number, itemId: Guid): void {

    const itemIndex = this.items.findIndex(i => i.itemId === itemId);

    if (itemIndex !== -1) {
      this.items.splice(itemIndex, 1);
    }

    this.itemRemoved.emit(itemId);



  }

  ngOnInit(): void {
    this.isAddingSubscription = this.cartService.IsAddingToCart$
      .subscribe(isAddingArgs => this.itemsAreBeingAdded = isAddingArgs && isAddingArgs.isAdding);

    this.broadcast.deliverySequence.subscribe(activeDeliverySequence => this.activeDeliverySequence = activeDeliverySequence);
  }

  ngOnDestroy(): void {
    this.isAddingSubscription?.unsubscribe();
    this.actAsSubscription?.unsubscribe();
  }

  get isActingAs(): boolean {
    return !!this.activeDeliverySequence;
  }

  trackByItemId(_index: number, item: ProductListItem): Guid {
    return item.itemId;
  }
}
export enum ProductListView {
  ProductList = 0,
  ActiveCart = 1
}
