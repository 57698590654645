export class MibpParsedUrl {

  private originalQueryString: string;
  private originalHashString: string;

  public schema?: string;
  public port?: number;
  public host?: string;
  public path?: string;
  public query: { [key: string]: string };
  public hash: { [key: string]: string };

  public get hashString(): string {
    return this.originalHashString;
  }

  public get queryString(): string {
    return this.originalQueryString;
  }

  constructor(private url: string) {
    this.parseUrl();
  }

  public setPath(path: string): MibpParsedUrl {
    this.path = path;
    return this;
  }

  public clearQuerystring(): MibpParsedUrl {
    this.query = {};
    return this;
  }

  public setQuerystring(name: string, value: string): MibpParsedUrl {
    this.query[name] = value;
    return this;
  }

  public removeQueryString(name: string): MibpParsedUrl {
    delete this.query[name];
    return this;
  }

  private parseUrl() {
    let url = this.url;
    if (url) {

      let parts = url.split('#', 2);
      if (parts.length === 2) {
        url = parts[0];
        this.originalHashString = parts[1];
      }

      parts = url.split('?', 2);
      if (parts.length === 2) {
        url = parts[0];
        this.originalQueryString = parts[1];
      }

      const hostMatch = url.match(/^(http[s]?):\/\/(.*?)(?:$|\?|#|\/)(.*?)(?:$|\?|#)/i);
      if (hostMatch) {
        this.path = hostMatch[3];
        this.host = hostMatch[2];
        this.schema = hostMatch[1];
      } else {
        this.path = url;
      }

      url = url.replace(/\#$/, '');



      this.query = this.extractQuerystringValues(this.queryString);

      this.hash = this.extractQuerystringValues(this.hashString);
      if (Object.keys(this.hash).length === 0) {
        this.hash = null;
      }
    }
  }

  public getQuerystring(): string {
    return this.objectToQuerystring(this.query);
  }

  /**
   * Get querystring parameter
   * Will get from "hash-string querystring" if not found in standard querystring
   */
  public param(key: string, fallbackToHashString = true): string {
    let value = this.getParam(this.query, key);
    if (value === null && this.hash && fallbackToHashString) {
      value = this.getParam(this.hash, key);
    }
    return value;
  }

  private getParam(obj: { [key: string]: string }, key: string) {
    if (key) {
      if (obj) {
        if (obj[key]) {
          return obj[key];
        }
        const caseInsensitiveSearch = Object.keys(obj).filter(objectKey => objectKey.toLowerCase() === key.toLowerCase());
        if (caseInsensitiveSearch.length > 0) {
          return obj[caseInsensitiveSearch[0]];
        }
      }
    }
    return null;
  }



  private extractQuerystringValues(querystring: string): { [key: string]: string } {
    if (!querystring || querystring.indexOf('=') === -1) {
      return {};
    }

    const keyValueCollection = querystring.split('&');
    const queryStringObject: { [key: string]: string } = {};
    keyValueCollection.forEach(keyValueString => {
      const keyAndValue = keyValueString.split('=', 2);
      if (keyAndValue.length > 1) {
        queryStringObject[keyAndValue[0]] = keyAndValue.length > 1 ? decodeURIComponent(keyAndValue[1]) : null;
      }
    });
    return queryStringObject;
  }

  public toString(): string {
    let url = '';

    if (this.schema) {
      url += `${this.schema}://`;
    }

    if (this.host) {
      url += `${this.host}`;
    }

    if (this.path) {
      url += `/${this.path.replace(/^\/+/, '')}`;
    }

    const querystring = this.objectToQuerystring(this.query, '?');
    const hashString = this.hash ? this.objectToQuerystring(this.hash, '#') : (this.hashString ? `#${this.hashString}` : '');

    return `${url}${querystring}${hashString}`;
  }

  private objectToQuerystring(obj: { [key: string]: string }, separator = '?'): string {
    const keys = Object.keys(obj);
    const keyValueArray: string[] = keys.filter(key => obj[key] !== null).map( key => `${key}=${encodeURIComponent(obj[key])}` );
    return keyValueArray.length > 0 ? `${separator}${keyValueArray.join('&')}` : '';
  }

}

