import { CartsApiController } from 'root/mibp-openapi-gen/controllers';
import { CartListItem } from './../cart-list/cart-list.d';
import { ApiService, MibpLogger } from 'root/services';
import { Input, OnInit, ElementRef, ViewChild, Component, OnChanges, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'mibp-cart-name',
  templateUrl: './cart-name.component.html',
  styleUrls: ['./cart-name.component.scss']
})

export class CartNameComponent implements OnInit {

  @Input() cart: CartListItem;
  @Input() allowEdit: boolean;
  @Output() editState: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("cartNameElement", { read: ElementRef }) cartNameElement: ElementRef;
  nameForm: UntypedFormGroup;
  isEditingValue: boolean;
  log: MibpLogger;
  get isEditing() {
    return this.isEditingValue;
  }
  set isEditing(value: boolean) {
    this.isEditingValue = value;
    this.editState.emit(this.isEditingValue);
  }
  unchangedCartName: string = null;

  constructor(private api: ApiService, private fb: UntypedFormBuilder, private cartsController: CartsApiController) { }

  ngOnInit() {
    this.nameForm = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  // ngOnChanges(changes) {
  //   if (changes.cart.currentValue) {
  //     this.unchangedCartName = changes.cart.currentValue.name;
  //     console.log('Unchanged name set to', this.unchangedCartName);
  //     if (this.nameForm != null) {
  //       this.nameForm.setValue({
  //         name: changes.cart.currentValue.name
  //       });
  //     }
  //   }
  // }

  startEditName(event: Event) {
    event.stopPropagation();
    if (!this.allowEdit) {
      return;
    }

    this.unchangedCartName = this.cleanString(this.cart.title);
    this.nameForm.setValue({name: this.unchangedCartName});
    this.isEditing = true;
    setTimeout( () => {
      this.cartNameElement.nativeElement.focus();
      this.cartNameElement.nativeElement.select();
    }, 10);
  }

  nameElementBlur() {

    if (this.nameForm.controls['name'].value === this.unchangedCartName || this.nameForm.controls['name'].invalid) {
      this.unchangedCartName = null;
      this.isEditing = false;
      return;
    }

    const newName = this.nameForm.controls['name'].value !== '' ? this.cleanString(this.nameForm.controls['name'].value) : this.unchangedCartName;
    firstValueFrom(this.cartsController.rename({cartId:this.cart.id,name: newName}))
      .then(
        () => {
          this.cart.title = newName;
          this.unchangedCartName = null;
          this.isEditing = false;
        }).catch(errorResponse => {
        this.nameForm.controls.name.setValue(this.unchangedCartName);
        this.isEditing = false;
        this.cart.title = this.unchangedCartName;
        this.unchangedCartName = null;
        if (errorResponse.stack.response.error === "The cart name is not unique") {
          this.nameForm.controls.name.enable();
          this.nameForm.controls.name.setErrors({
            ERRBCRT002 : true
          });
        }
        if (typeof errorResponse === 'string' && errorResponse.indexOf('ERRBCRT000') !== -1) {
          this.nameForm.controls.name.setErrors({
            ERRBCRT000 : true
          });
        }
        if (typeof errorResponse === 'string' && errorResponse.indexOf('ERRBCRT002') !== -1) {
          this.nameForm.controls.name.enable();
          this.nameForm.controls.name.setErrors({
            ERRBCRT002 : true
          });
        }
        this.log.error('Error renaming cart', errorResponse);
      });

  }

  nameElementKeyDown(e: KeyboardEvent) {
    if (e.key === 'Tab' || e.key === 'Enter') {
      // This is to prevent the page from scrolling down
      // since we currently do not control tabIndex for elements
      e.preventDefault();
      e.stopPropagation();
      (e.target as HTMLInputElement).blur();
    } else if (e.key === "Escape") {
      this.nameForm.setValue({
        name: this.unchangedCartName
      });
      (e.target as HTMLInputElement).blur();
    } else {
      e.stopPropagation();
    }
  }

  clickedRenameInput(event: Event) {
    event.stopPropagation();
  }

  // Replace tabs, multiple spaces and new lines with single space.
  // Remove space characters at the beginning and end of the string
  cleanString(input: string): string {
    return input.replace(/\s\s+/g, ' ').trim();
  }

}
