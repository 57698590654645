/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createB2cUser } from '../fn/b-2-c-users/create-b-2-c-user';
import { CreateB2cUser$Params } from '../fn/b-2-c-users/create-b-2-c-user';
import { createB2cUser$Plain } from '../fn/b-2-c-users/create-b-2-c-user-plain';
import { CreateB2cUser$Plain$Params } from '../fn/b-2-c-users/create-b-2-c-user-plain';
import { CreateB2CUserResponseVm } from '../models/create-b-2-c-user-response-vm';
import { FindB2CUserResponseVm } from '../models/find-b-2-c-user-response-vm';
import { findByEmail } from '../fn/b-2-c-users/find-by-email';
import { FindByEmail$Params } from '../fn/b-2-c-users/find-by-email';
import { findByEmail$Plain } from '../fn/b-2-c-users/find-by-email-plain';
import { FindByEmail$Plain$Params } from '../fn/b-2-c-users/find-by-email-plain';

@Injectable({ providedIn: 'root' })
export class B2CUsersApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `b2CUsersControllerFindByEmailemail()` */
  static readonly B2CUsersControllerFindByEmailemailPath = '/b2cusers/find-by-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByEmail$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByEmail$Plain$Response(params?: FindByEmail$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FindB2CUserResponseVm>> {
    return findByEmail$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findByEmail$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByEmail$Plain(params?: FindByEmail$Plain$Params, context?: HttpContext): Observable<FindB2CUserResponseVm> {
    return this.findByEmail$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FindB2CUserResponseVm>): FindB2CUserResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByEmail$Response(params?: FindByEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<FindB2CUserResponseVm>> {
    return findByEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findByEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByEmail(params?: FindByEmail$Params, context?: HttpContext): Observable<FindB2CUserResponseVm> {
    return this.findByEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<FindB2CUserResponseVm>): FindB2CUserResponseVm => r.body)
    );
  }

  /** Path part for operation `b2CUsersControllerCreateB2CUserrequest()` */
  static readonly B2CUsersControllerCreateB2CUserrequestPath = '/b2cusers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createB2cUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createB2cUser$Plain$Response(params?: CreateB2cUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateB2CUserResponseVm>> {
    return createB2cUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createB2cUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createB2cUser$Plain(params?: CreateB2cUser$Plain$Params, context?: HttpContext): Observable<CreateB2CUserResponseVm> {
    return this.createB2cUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateB2CUserResponseVm>): CreateB2CUserResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createB2cUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createB2cUser$Response(params?: CreateB2cUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateB2CUserResponseVm>> {
    return createB2cUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createB2cUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createB2cUser(params?: CreateB2cUser$Params, context?: HttpContext): Observable<CreateB2CUserResponseVm> {
    return this.createB2cUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateB2CUserResponseVm>): CreateB2CUserResponseVm => r.body)
    );
  }

}
