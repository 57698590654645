<mibp-dialog #dialog [buttons]="dialogButtons" [titleResourceStringKey]="dialogTitle" (buttonClick)="dialogClick($event)"  size = "narrow"  [id]="'system-message-dialog'">
  <div class="dialog-container">
    <div class="row">
      <div class="messageColumn" *ngIf="currentMessage?.imageUserFileId" >
        <mibp-image [userFileId]="currentMessage?.imageUserFileId" [rendition]="rendition.Original" [adjustToContainer]="true" [loaderAspectRatio]="[1,1]" class="center-image"></mibp-image>
      </div>
      <div class="messageColumn" >
        <div class="message-content" [innerHTML]="currentMessage?.description"></div>
      </div>
    </div>

    <div *ngIf="currentMessage?.disruptionFromDate || currentMessage?.disruptionToDate ">
      <div class="columnFullWidth" >
         <hr class="solid">
          <h1 class="bold" >
            <mibp-datetime [date]="currentMessage?.disruptionFromDate" format="datetime"></mibp-datetime>
            -
            <mibp-datetime [date]="currentMessage?.disruptionToDate" format="datetime"></mibp-datetime>
          </h1>
          <span class="disruptionMsgText"> <mibp-resource-string  key="SystemMessage_DisruptionMessageText"></mibp-resource-string></span>
         <hr class="solid">
      </div>
    </div>
  </div>
</mibp-dialog>
