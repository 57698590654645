import { FormattingService } from 'root/services';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'formatWeight'})
export class FormatWeightPipe implements PipeTransform {

  constructor(private formattingService: FormattingService) {}

  transform(value: number): string {
    return this.formattingService.formatWeight(value);
  }

}
