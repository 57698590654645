import { Subject, Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class InlineEditingOverlayService {

  visible = false;
  private visibilitySubject = new Subject<boolean>();

  public get Visibility(): Observable<boolean> {
    return this.visibilitySubject.asObservable();
  }

  public show(): void {
    this.visible  = true;
    this.visibilitySubject.next(this.visible);
    document.body.classList.add('inline-editing');
  }

  public hide(): void {
    this.visible  = false;
    this.visibilitySubject.next(this.visible);
    document.body.classList.remove('inline-editing');
  }

}
