import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Page } from 'root/components/paginator/page';
import { TableColumnDef, SaveRowArgs } from './table.types';
import { MibpLogger, LogService } from 'root/services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'mibp-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {

  private _dataSource: any[];
  private searchTimer: any;
  public rows: string[][] = [];
  public query = '';
  private log: MibpLogger;

  private editingRows: number[] = [];
  private pendingRows: number[] = [];
  private editRowData: { [rowIndex: number]: any } = {};

  @Input() columnDefs: TableColumnDef[];
  @Input()
  get dataSource() { return this._dataSource; }
  set dataSource(value: any[]) {
    if (value) {
      this._dataSource = value;
    }
  }
  @Input() editable: 'no' | 'singlerow' = 'no';
  @Input() totalItems = 0;
  @Input() pageSize = 10;
  @Input() startIndex = 0;
  @Input() showFilter = true;
  @Input() compact = false;
  @Input() isLoading = true;
  @Input() actionbutton = false;
  @Input() actionButtonText = null;
  @Input() actionButtonIcon = null;
  @Output() filterChanged = new EventEmitter();
  @Output() changePage = new EventEmitter();
  @Output() saveRow = new EventEmitter<SaveRowArgs>();
  @Output() actionClicked = new EventEmitter();
  @Output() dataBound = new EventEmitter();
  @Input() returnRowindex = false;
  @Input() noMaxWidth = false;

  private identifierIndex: { [key: string]: number} = {};
  loadingRows: { [rowIndex: number]: boolean } = {};
  isDisabled = false;
  isDisabledLoading = false;


  constructor(logger: LogService, private sanitizer: DomSanitizer) {
    this.log = logger.withPrefix('table');
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dataSource && changes.dataSource.currentValue)
    {
      this.setDataSource();
    }
  }


  ngOnInit() {
    // this.setDataSource();
  }

  /**
   * Disable
   * @param isLoading Specify ID of a specific row to disable other rows and set that row as loading
   */
  disable(isLoading: boolean | string = false) {
    this.isDisabled = true;
    if ( typeof(isLoading) === 'boolean') {
      this.isDisabledLoading = !!isLoading;
    } else {
      const rowIndex = this.identifierIndex[isLoading];
      if (!isNaN(rowIndex)) {
        this.loadingRows[rowIndex] = true;
      }
    }
  }

  enable() {
    this.isDisabled = false;
    this.isDisabledLoading = false;
    this.loadingRows = {};
  }

  beginEditRow(rowIndex: number) {
    this.editingRows.push(rowIndex);
    this.editRowData[rowIndex] = Object.assign({}, this.rows[rowIndex]);
  }

  editDate(e: Date, rowIndex: number, col: TableColumnDef) {

    if (!col.readOnly) {
      let newDate: string = null;

      if (e) {
        newDate = e.toISOString();
      } else {
        e = null;
      }

      const colIndex = this.columnDefs.findIndex(co => co.dataKey === col.dataKey);
      const fn = col.dataFormattingFunction;
      this.editRowData[rowIndex][colIndex] = newDate ? (fn ? fn(newDate) : newDate) : newDate;
    }

  }

  saveRowChanges(rowIndex: number) {
    const oldObject = {};
    const newObject = {};

    this.columnDefs.forEach((col, ix) => {
      newObject[col.dataKey] = this.editRowData[rowIndex][ix];
      oldObject[col.dataKey] = this.rows[rowIndex][ix];
    });

    const args = <SaveRowArgs>{
      rowIndex: rowIndex,
      old: oldObject,
      new: newObject,
      resolve: () => {
        this.rows[rowIndex] = this.editRowData[rowIndex];
        this.cancelEditRow(rowIndex);
      },
      reject: () => {
        const ix = this.pendingRows.findIndex(p => p === rowIndex);
        if (ix !== -1) {
          this.pendingRows.splice(ix, 1);
        }
      }
    };
    this.pendingRows.push(rowIndex);
    this.saveRow.emit(args);

  }

  cancelEditRow(rowIndex: number) {
    let ix = this.editingRows.indexOf(rowIndex);
    if (ix !== -1) {
      this.editingRows.splice(ix, 1);
    }
    ix = this.pendingRows.indexOf(rowIndex);
    if (ix !== -1) {
      this.pendingRows.splice(ix, 1);
    }
  }

  setDataSource(): void {
    if (this.dataSource && this.columnDefs) {
      this.rows = this.convertToTableData(this.dataSource, this.columnDefs);
      this.dataBound.emit();
    } else {
      this.log.error('failed to set rows');
    }
  }

  isRowValid(rowIndex: number) {
    let isValid = true;
    this.columnDefs.forEach(col => {
      if (!this.isFieldValid(rowIndex, col)) {
        isValid = false;
      }
    });
    return isValid;
  }

  isRowPending(rowIndex: number) {
    return this.pendingRows.indexOf(rowIndex) !== -1;
  }

  isFieldValid(rowIndex: number, col: TableColumnDef): boolean {

    if (this.isEditingRow(rowIndex) && !col.readOnly) {
      if (col.notNull === true) {
        const colIndex = this.columnDefs.findIndex(cd => cd.dataKey === col.dataKey);
        if (this.editRowData[rowIndex][colIndex] === null || this.editRowData[rowIndex][colIndex] === '') {
          return false;
        }
      }
    }

    return true;
  }

  isEditingRow(rowIndex: number) {
    return this.editingRows.indexOf(rowIndex) !== -1;
  }

  onFilterChanged(value: string) {
    this.query = value.trim();

    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.filterChanged.emit(this.query);
    }, 300);
  }

  tryParseDate() {

  }

  actionButton(rowIndex: number) {
    if (this.returnRowindex) {
      this.actionClicked.emit(rowIndex);
    } else {
      this.actionClicked.emit(this.rows[rowIndex]);
    }

  }

  onChangePage(page: Page) {
    if (page.isUserEvent) {
      const newStartIndex = (page.currentPage - 1) * this.pageSize;
      this.changePage.emit(newStartIndex);
    }
  }

  update(id: string, dataKey: string, val: string) {
    const rowIndex = this.identifierIndex[id];
    const colIndex = this.columnDefs.findIndex(f => f.dataKey === dataKey);
    this.log.warn('update', id, dataKey, val, this.identifierIndex, rowIndex, colIndex, this.columnDefs);
    if (colIndex !== -1 && rowIndex !== -1) {
      this.rows[rowIndex][colIndex] = val;
      this.columnDefs[colIndex].isLoading = false;
    }
  }

  convertToTableData(
    sourceData: any[],
    columnDefs: TableColumnDef[]
  ): string[][] {
    const result: string[][] = [];
    if (sourceData) {
      for (let row = 0; row < sourceData.length; row++) {
        const newRow: string[] = [];
        for (let col = 0; col < columnDefs.length; col++) {
          let value: string;
          const fn = columnDefs[col].dataFormattingFunction;
          if (columnDefs[col].isIdentifier === true) {
            this.identifierIndex[sourceData[row][columnDefs[col].dataKey]] = row;
          }
          if (fn) {
            value = fn(sourceData[row][columnDefs[col].dataKey]);
          } else {
            value = sourceData[row][columnDefs[col].dataKey];
          }
          newRow.push(value);
        }
        result.push(newRow);
      }
    }
    return result;
  }

  getHtml(data: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(data);
  }
}
