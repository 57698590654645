export enum ToastType {
  Info,
  Error,
  Success,
  Warning
}

export enum ToastSize {
  Default,
  Narrow,
  Wide
}