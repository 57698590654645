<div #chart [showElementLoader]="loading" class="chart" [style.max-width]="width" [style.min-height]="height">

  <div class="chart-container" >
    <canvas baseChart
      *ngIf="!loading && isReady && chartData && chartData?.length > 0"
      [legend]="showLegend"
      [data]="chartJsData"
      [labels]="chartLabels"
      [type]="doughnut ? 'doughnut' : 'pie'"
      [options]="chartOptions"
      [plugins]="plugins"
      [total]="chartTotal"
      (chartClick)="onChartClicked($event)"
      >
    </canvas>
  </div>
</div>

<mibp-popover #availabilityPopover>
  <mibp-resource-string [key]="currentTooltipResourceString" [isHtml]="true"></mibp-resource-string>
</mibp-popover>
