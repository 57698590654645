import { FormattingService, NoticebarService } from 'root/services';
import { Pipe, PipeTransform } from '@angular/core';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';

@Pipe({name: 'formatDate'})
export class FormatDatePipe implements PipeTransform {

  constructor(private formattingService: FormattingService) {}

  transform(value: any): string {


    return this.formattingService.formatDate(value);
  }

}
