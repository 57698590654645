import Quill from 'quill';

/**
 * Register the block blot for existing an open section
 */
export function registerBreakSectionBlot(): void {

  const block = Quill.import('blots/block');

  class BreakSectionBlot extends block {
    static create(value) {
      const node = super.create(value);
      node.setAttribute('class', 'ql-mibp-exit-section');
      node.setAttribute('contenteditable', false);
      return node;
    }
  }
  BreakSectionBlot.blotName = 'breaksection';
  BreakSectionBlot.tagName = 'DIV';
  BreakSectionBlot.className = 'ql-mibp-exit-section';
  Quill.register('formats/breaksection', BreakSectionBlot);
}
