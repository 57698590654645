/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressLinesViewModel } from '../models/address-lines-view-model';
import { DespatchNoteVm } from '../models/despatch-note-vm';
import { get } from '../fn/orders/get';
import { Get$Params } from '../fn/orders/get';
import { get$Plain } from '../fn/orders/get-plain';
import { Get$Plain$Params } from '../fn/orders/get-plain';
import { getAddressLinesForDeliverySequence } from '../fn/orders/get-address-lines-for-delivery-sequence';
import { GetAddressLinesForDeliverySequence$Params } from '../fn/orders/get-address-lines-for-delivery-sequence';
import { getAddressLinesForDeliverySequence$Plain } from '../fn/orders/get-address-lines-for-delivery-sequence-plain';
import { GetAddressLinesForDeliverySequence$Plain$Params } from '../fn/orders/get-address-lines-for-delivery-sequence-plain';
import { getAddressLinesForOrder } from '../fn/orders/get-address-lines-for-order';
import { GetAddressLinesForOrder$Params } from '../fn/orders/get-address-lines-for-order';
import { getAddressLinesForOrder$Plain } from '../fn/orders/get-address-lines-for-order-plain';
import { GetAddressLinesForOrder$Plain$Params } from '../fn/orders/get-address-lines-for-order-plain';
import { getAuroraSalesOrder } from '../fn/orders/get-aurora-sales-order';
import { GetAuroraSalesOrder$Params } from '../fn/orders/get-aurora-sales-order';
import { getAuroraSalesOrder$Plain } from '../fn/orders/get-aurora-sales-order-plain';
import { GetAuroraSalesOrder$Plain$Params } from '../fn/orders/get-aurora-sales-order-plain';
import { getDespatches } from '../fn/orders/get-despatches';
import { GetDespatches$Params } from '../fn/orders/get-despatches';
import { getDespatches$Plain } from '../fn/orders/get-despatches-plain';
import { GetDespatches$Plain$Params } from '../fn/orders/get-despatches-plain';
import { getOrderDeliveryPointInfo } from '../fn/orders/get-order-delivery-point-info';
import { GetOrderDeliveryPointInfo$Params } from '../fn/orders/get-order-delivery-point-info';
import { getOrderDeliveryPointInfo$Plain } from '../fn/orders/get-order-delivery-point-info-plain';
import { GetOrderDeliveryPointInfo$Plain$Params } from '../fn/orders/get-order-delivery-point-info-plain';
import { getOrderLines } from '../fn/orders/get-order-lines';
import { GetOrderLines$Params } from '../fn/orders/get-order-lines';
import { getOrderLines$Plain } from '../fn/orders/get-order-lines-plain';
import { GetOrderLines$Plain$Params } from '../fn/orders/get-order-lines-plain';
import { getOrderSummaryDetails } from '../fn/orders/get-order-summary-details';
import { GetOrderSummaryDetails$Params } from '../fn/orders/get-order-summary-details';
import { getOrderSummaryDetails$Plain } from '../fn/orders/get-order-summary-details-plain';
import { GetOrderSummaryDetails$Plain$Params } from '../fn/orders/get-order-summary-details-plain';
import { listMachineOrders } from '../fn/orders/list-machine-orders';
import { ListMachineOrders$Params } from '../fn/orders/list-machine-orders';
import { listMachineOrders$Plain } from '../fn/orders/list-machine-orders-plain';
import { ListMachineOrders$Plain$Params } from '../fn/orders/list-machine-orders-plain';
import { listOrders } from '../fn/orders/list-orders';
import { ListOrders$Params } from '../fn/orders/list-orders';
import { listOrders$Plain } from '../fn/orders/list-orders-plain';
import { ListOrders$Plain$Params } from '../fn/orders/list-orders-plain';
import { Order } from '../models/order';
import { OrderLineViewModel } from '../models/order-line-view-model';
import { OrderQuotationDeliveryPointInfoViewModel } from '../models/order-quotation-delivery-point-info-view-model';
import { OrderResponseDto } from '../models/order-response-dto';
import { OrderSummaryDto } from '../models/order-summary-dto';
import { OrderViewModelSearchResponse } from '../models/order-view-model-search-response';
import { rescope } from '../fn/orders/rescope';
import { Rescope$Params } from '../fn/orders/rescope';
import { rescope$Plain } from '../fn/orders/rescope-plain';
import { Rescope$Plain$Params } from '../fn/orders/rescope-plain';
import { rescopeById } from '../fn/orders/rescope-by-id';
import { RescopeById$Params } from '../fn/orders/rescope-by-id';
import { rescopeUnscopedOrders } from '../fn/orders/rescope-unscoped-orders';
import { RescopeUnscopedOrders$Params } from '../fn/orders/rescope-unscoped-orders';
import { syncOrderWithAurora } from '../fn/orders/sync-order-with-aurora';
import { SyncOrderWithAurora$Params } from '../fn/orders/sync-order-with-aurora';
import { syncOrderWithAurora$Plain } from '../fn/orders/sync-order-with-aurora-plain';
import { SyncOrderWithAurora$Plain$Params } from '../fn/orders/sync-order-with-aurora-plain';

@Injectable({ providedIn: 'root' })
export class OrdersApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ordersControllerGetid()` */
  static readonly OrdersControllerGetidPath = '/orders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain$Response(params: Get$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return get$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain(params: Get$Plain$Params, context?: HttpContext): Observable<Order> {
    return this.get$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: Get$Params, context?: HttpContext): Observable<Order> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /** Path part for operation `ordersControllerGetAddressLinesForDeliverySequencedeliverysequenceid()` */
  static readonly OrdersControllerGetAddressLinesForDeliverySequencedeliverysequenceidPath = '/orders/deliverysequence/{deliverysequenceid}/addresslines';

  /**
   * Used to show address lines when there's no order data.
   * For example at checkout.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressLinesForDeliverySequence$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForDeliverySequence$Plain$Response(params: GetAddressLinesForDeliverySequence$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressLinesViewModel>> {
    return getAddressLinesForDeliverySequence$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Used to show address lines when there's no order data.
   * For example at checkout.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddressLinesForDeliverySequence$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForDeliverySequence$Plain(params: GetAddressLinesForDeliverySequence$Plain$Params, context?: HttpContext): Observable<AddressLinesViewModel> {
    return this.getAddressLinesForDeliverySequence$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressLinesViewModel>): AddressLinesViewModel => r.body)
    );
  }

  /**
   * Used to show address lines when there's no order data.
   * For example at checkout.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressLinesForDeliverySequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForDeliverySequence$Response(params: GetAddressLinesForDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressLinesViewModel>> {
    return getAddressLinesForDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * Used to show address lines when there's no order data.
   * For example at checkout.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddressLinesForDeliverySequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForDeliverySequence(params: GetAddressLinesForDeliverySequence$Params, context?: HttpContext): Observable<AddressLinesViewModel> {
    return this.getAddressLinesForDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressLinesViewModel>): AddressLinesViewModel => r.body)
    );
  }

  /** Path part for operation `ordersControllerGetAddressLinesForOrderorderId()` */
  static readonly OrdersControllerGetAddressLinesForOrderorderIdPath = '/orders/{orderId}/addresslines';

  /**
   * Fetch orderlines for an order.
   * Will fallback to deliverysequence invoice address if that is missing in the extended order data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressLinesForOrder$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForOrder$Plain$Response(params: GetAddressLinesForOrder$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressLinesViewModel>> {
    return getAddressLinesForOrder$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch orderlines for an order.
   * Will fallback to deliverysequence invoice address if that is missing in the extended order data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddressLinesForOrder$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForOrder$Plain(params: GetAddressLinesForOrder$Plain$Params, context?: HttpContext): Observable<AddressLinesViewModel> {
    return this.getAddressLinesForOrder$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressLinesViewModel>): AddressLinesViewModel => r.body)
    );
  }

  /**
   * Fetch orderlines for an order.
   * Will fallback to deliverysequence invoice address if that is missing in the extended order data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressLinesForOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForOrder$Response(params: GetAddressLinesForOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressLinesViewModel>> {
    return getAddressLinesForOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch orderlines for an order.
   * Will fallback to deliverysequence invoice address if that is missing in the extended order data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddressLinesForOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForOrder(params: GetAddressLinesForOrder$Params, context?: HttpContext): Observable<AddressLinesViewModel> {
    return this.getAddressLinesForOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressLinesViewModel>): AddressLinesViewModel => r.body)
    );
  }

  /** Path part for operation `ordersControllerRescopecompanyCodeCustomerNumber()` */
  static readonly OrdersControllerRescopecompanyCodeCustomerNumberPath = '/orders/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain$Response(params?: Rescope$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain(params?: Rescope$Plain$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Response(params?: Rescope$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope(params?: Rescope$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `ordersControllerRescopeUnscopedOrders()` */
  static readonly OrdersControllerRescopeUnscopedOrdersPath = '/orders/rescopeunscopedorders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeUnscopedOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeUnscopedOrders$Response(params?: RescopeUnscopedOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rescopeUnscopedOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeUnscopedOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeUnscopedOrders(params?: RescopeUnscopedOrders$Params, context?: HttpContext): Observable<void> {
    return this.rescopeUnscopedOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ordersControllerRescopeByIdid()` */
  static readonly OrdersControllerRescopeByIdidPath = '/orders/{id}/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeById$Response(params: RescopeById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rescopeById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescopeById(params: RescopeById$Params, context?: HttpContext): Observable<void> {
    return this.rescopeById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ordersControllerListOrdersrequestGetQuotationsSortOrder()` */
  static readonly OrdersControllerListOrdersrequestGetQuotationsSortOrderPath = '/orders/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrders$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listOrders$Plain$Response(params?: ListOrders$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderViewModelSearchResponse>> {
    return listOrders$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrders$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listOrders$Plain(params?: ListOrders$Plain$Params, context?: HttpContext): Observable<OrderViewModelSearchResponse> {
    return this.listOrders$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderViewModelSearchResponse>): OrderViewModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrders()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listOrders$Response(params?: ListOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderViewModelSearchResponse>> {
    return listOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrders$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listOrders(params?: ListOrders$Params, context?: HttpContext): Observable<OrderViewModelSearchResponse> {
    return this.listOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderViewModelSearchResponse>): OrderViewModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `ordersControllerListMachineOrdersrequestSortOrder()` */
  static readonly OrdersControllerListMachineOrdersrequestSortOrderPath = '/orders/listmachineorders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMachineOrders$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listMachineOrders$Plain$Response(params?: ListMachineOrders$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderViewModelSearchResponse>> {
    return listMachineOrders$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listMachineOrders$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listMachineOrders$Plain(params?: ListMachineOrders$Plain$Params, context?: HttpContext): Observable<OrderViewModelSearchResponse> {
    return this.listMachineOrders$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderViewModelSearchResponse>): OrderViewModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMachineOrders()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listMachineOrders$Response(params?: ListMachineOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderViewModelSearchResponse>> {
    return listMachineOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listMachineOrders$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listMachineOrders(params?: ListMachineOrders$Params, context?: HttpContext): Observable<OrderViewModelSearchResponse> {
    return this.listMachineOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderViewModelSearchResponse>): OrderViewModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `ordersControllerGetOrderDeliveryPointInfoid()` */
  static readonly OrdersControllerGetOrderDeliveryPointInfoidPath = '/orders/{id}/deliverysequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderDeliveryPointInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderDeliveryPointInfo$Plain$Response(params: GetOrderDeliveryPointInfo$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderQuotationDeliveryPointInfoViewModel>> {
    return getOrderDeliveryPointInfo$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderDeliveryPointInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderDeliveryPointInfo$Plain(params: GetOrderDeliveryPointInfo$Plain$Params, context?: HttpContext): Observable<OrderQuotationDeliveryPointInfoViewModel> {
    return this.getOrderDeliveryPointInfo$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderQuotationDeliveryPointInfoViewModel>): OrderQuotationDeliveryPointInfoViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderDeliveryPointInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderDeliveryPointInfo$Response(params: GetOrderDeliveryPointInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderQuotationDeliveryPointInfoViewModel>> {
    return getOrderDeliveryPointInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderDeliveryPointInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderDeliveryPointInfo(params: GetOrderDeliveryPointInfo$Params, context?: HttpContext): Observable<OrderQuotationDeliveryPointInfoViewModel> {
    return this.getOrderDeliveryPointInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderQuotationDeliveryPointInfoViewModel>): OrderQuotationDeliveryPointInfoViewModel => r.body)
    );
  }

  /** Path part for operation `ordersControllerGetOrderSummaryDetailsid()` */
  static readonly OrdersControllerGetOrderSummaryDetailsidPath = '/orders/{id}/summarydetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderSummaryDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderSummaryDetails$Plain$Response(params: GetOrderSummaryDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderSummaryDto>> {
    return getOrderSummaryDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderSummaryDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderSummaryDetails$Plain(params: GetOrderSummaryDetails$Plain$Params, context?: HttpContext): Observable<OrderSummaryDto> {
    return this.getOrderSummaryDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderSummaryDto>): OrderSummaryDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderSummaryDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderSummaryDetails$Response(params: GetOrderSummaryDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderSummaryDto>> {
    return getOrderSummaryDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderSummaryDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderSummaryDetails(params: GetOrderSummaryDetails$Params, context?: HttpContext): Observable<OrderSummaryDto> {
    return this.getOrderSummaryDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderSummaryDto>): OrderSummaryDto => r.body)
    );
  }

  /** Path part for operation `ordersControllerGetOrderLinesid()` */
  static readonly OrdersControllerGetOrderLinesidPath = '/orders/{id}/orderlines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderLines$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderLines$Plain$Response(params: GetOrderLines$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderLineViewModel>>> {
    return getOrderLines$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderLines$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderLines$Plain(params: GetOrderLines$Plain$Params, context?: HttpContext): Observable<Array<OrderLineViewModel>> {
    return this.getOrderLines$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderLineViewModel>>): Array<OrderLineViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderLines()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderLines$Response(params: GetOrderLines$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderLineViewModel>>> {
    return getOrderLines(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderLines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderLines(params: GetOrderLines$Params, context?: HttpContext): Observable<Array<OrderLineViewModel>> {
    return this.getOrderLines$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderLineViewModel>>): Array<OrderLineViewModel> => r.body)
    );
  }

  /** Path part for operation `ordersControllerGetDespatchesidLanguageCode()` */
  static readonly OrdersControllerGetDespatchesidLanguageCodePath = '/orders/{id}/despatches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDespatches$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDespatches$Plain$Response(params: GetDespatches$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DespatchNoteVm>>> {
    return getDespatches$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDespatches$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDespatches$Plain(params: GetDespatches$Plain$Params, context?: HttpContext): Observable<Array<DespatchNoteVm>> {
    return this.getDespatches$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DespatchNoteVm>>): Array<DespatchNoteVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDespatches()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDespatches$Response(params: GetDespatches$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DespatchNoteVm>>> {
    return getDespatches(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDespatches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDespatches(params: GetDespatches$Params, context?: HttpContext): Observable<Array<DespatchNoteVm>> {
    return this.getDespatches$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DespatchNoteVm>>): Array<DespatchNoteVm> => r.body)
    );
  }

  /** Path part for operation `ordersControllerSyncOrderWithAuroraorderNumberEntityCode()` */
  static readonly OrdersControllerSyncOrderWithAuroraorderNumberEntityCodePath = '/orders/summarydetails/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncOrderWithAurora$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  syncOrderWithAurora$Plain$Response(params?: SyncOrderWithAurora$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderSummaryDto>> {
    return syncOrderWithAurora$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `syncOrderWithAurora$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  syncOrderWithAurora$Plain(params?: SyncOrderWithAurora$Plain$Params, context?: HttpContext): Observable<OrderSummaryDto> {
    return this.syncOrderWithAurora$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderSummaryDto>): OrderSummaryDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncOrderWithAurora()` instead.
   *
   * This method doesn't expect any request body.
   */
  syncOrderWithAurora$Response(params?: SyncOrderWithAurora$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderSummaryDto>> {
    return syncOrderWithAurora(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `syncOrderWithAurora$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  syncOrderWithAurora(params?: SyncOrderWithAurora$Params, context?: HttpContext): Observable<OrderSummaryDto> {
    return this.syncOrderWithAurora$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderSummaryDto>): OrderSummaryDto => r.body)
    );
  }

  /** Path part for operation `ordersControllerGetAuroraSalesOrderorderNumberCompanyCode()` */
  static readonly OrdersControllerGetAuroraSalesOrderorderNumberCompanyCodePath = '/orders/sales-order-api';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuroraSalesOrder$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuroraSalesOrder$Plain$Response(params?: GetAuroraSalesOrder$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderResponseDto>> {
    return getAuroraSalesOrder$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuroraSalesOrder$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuroraSalesOrder$Plain(params?: GetAuroraSalesOrder$Plain$Params, context?: HttpContext): Observable<OrderResponseDto> {
    return this.getAuroraSalesOrder$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderResponseDto>): OrderResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuroraSalesOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuroraSalesOrder$Response(params?: GetAuroraSalesOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderResponseDto>> {
    return getAuroraSalesOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuroraSalesOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuroraSalesOrder(params?: GetAuroraSalesOrder$Params, context?: HttpContext): Observable<OrderResponseDto> {
    return this.getAuroraSalesOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderResponseDto>): OrderResponseDto => r.body)
    );
  }

}
