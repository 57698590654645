import { ResponseAvailabilityExpectedDate } from './../../../mibp-openapi-gen/models/response-availability-expected-date';
import { Component, EventEmitter, Input, OnInit,Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'root/components/paginator/page';
import { BillOfMaterialApiController } from 'root/mibp-openapi-gen/controllers';
import { FrontendContextService, LocalizationService } from 'root/services';
import { firstValueFrom} from 'rxjs';
import { WhereUsedFolderVmExtended, WhereUsedMediaResponseVmExtended } from './parts-manual-whereused.types';

@Component({
  selector: 'mibp-parts-manual-whereused',
  styleUrls: ['./parts-manual-whereused.component.scss'],
  templateUrl: './parts-manual-whereused.component.html'

})
export class PartsManualWhereUsedComponent implements OnInit {
  @Input() folderId: number;
  @Output() whereUsedSelected = new EventEmitter();
  protected isLoading = false;
  protected whereUsedItems: WhereUsedMediaResponseVmExtended[];
  protected total = 0;
  protected skip = 0;
  protected take = 10;
  protected hasLoadingError: boolean;

constructor(
  private bomApi: BillOfMaterialApiController,
  private localizationService: LocalizationService,
  private frontEndContext: FrontendContextService,
  private router: Router,
  private route: ActivatedRoute,){

}

  ngOnInit(): void {
    this.loadWhereUsed();
  }

  async loadWhereUsed() {
    try {
      this.isLoading = true;
      const request = {
        skip: this.skip,
        take: this.take,
        folderId: this.folderId
      };

      const response = await firstValueFrom(this.bomApi.getWhereUsedMedia({
        body: request
      }));

      this.total = response.totalCount;
      this.whereUsedItems = response.items.map<WhereUsedMediaResponseVmExtended>(whereUsedMedia => {
        return {
          ...whereUsedMedia,
          language: this.localizationService.get('Global_LanguageName_'+ whereUsedMedia.languageCode),
          extendedFolders: whereUsedMedia.folders.map<WhereUsedFolderVmExtended>(folder => {
            return {
              ...folder,
              navigationQueryParams: {
                folder: folder.folderId
              },
              navigationRoute: this.getRouteToManual(folder.mediaIdentifier, whereUsedMedia.equipmentId),
            };
          })
        };

      });

      this.isLoading = false;
    } catch (e) {
      this.hasLoadingError = true;
      this.isLoading = false;
    }
  }

  getRouteToManual(identifier: string, equipmentId: number): string[] {
    event.preventDefault();

    let commands: string[] = [];

    // This component can be used in Parts Manuals page and in Electronic Manuals
    // We must link to different places depending on where we are
    if (this.route.snapshot.data?.navItemId?.includes('parts-manuals')) {
      commands = [
        '/',
        this.localizationService.getLang(),
        'shop',
        'parts-manuals',
        'equipment', equipmentId.toString(),
        'media', identifier
      ];
    } else {
      commands = [
        '/',
        this.localizationService.getLang(),
        'myfleet',
        'electronic-manuals',
        equipmentId.toString(),
        identifier
      ];
    }
    return commands;
  }

  public emitSelectedEvent(): void {
    this.whereUsedSelected.emit();
  }

  protected onPageChange(page: Page): void {
    if (page.currentPage >= 0) {
      if (this.skip != page.startIndex) {
        this.skip = page.startIndex;
        this.router.navigate(['.'], {
          queryParams: {
            skip: this.skip
          },
          queryParamsHandling: 'merge',
          relativeTo: this.route
        });
        this.loadWhereUsed();
      }
    }
  }

}
