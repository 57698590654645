<ng-template #toolbarTemplate>
  <div id="support-toolbar"
    [class.support-toolbar--is-dragging]="isDragging"
    [class.support-toolbar--expanded]="isExpanded"
    [class.support-toolbar--collapsed]="!isExpanded">

    <div class="drag" *ngIf="isExpanded" (mousedown)="dragStart($event)">
      <span  translate="no" class="material-icon">drag_pan</span>
    </div>

    <div class="items" *ngIf="isExpanded">
      <div class="button button--link">
        <a href="javascript:void(0)" (click)="sessionInfoDialog.open($event)">Session</a>
      </div>
      <div class="button button--link" [class.button--disabled]="!pageMenu?.length">
        <a href="javascript:void(0)" (click)="ctxPageMenu.open($event)">Page</a>
      </div>
      <!-- <div class="button button--link">
        <a href="javascript:void(0)" (click)="toolsMenu.open($event)">Tools</a>
      </div> -->
      <div *ngIf="actasMenu?.length > 0" class="button button--link" [class.button--disabled]="!actasMenu?.length">
        <a href="javascript:void(0)" title="Recent 'Acted As' delivery sequences" (click)="ctxActasMenu.open($event)">Acted As</a>
      </div>
      <!-- <div class="button button--link">
        <a href="javascript:void(0)" title="Go to Operations page"><mibp-sandvik-icon name="tools" size="x-small" color="blue"></mibp-sandvik-icon></a>
      </div> -->
    </div>

    <div *ngIf="isExpanded" (click)="collapse()" class="toggle toggle--expanded" title="Click to collapse">
      <span translate="no" class="material-icon material-icon--xxlarge">navigate_before</span>
    </div>

    <div *ngIf="!isExpanded" (click)="expand()" class="toggle toggle--collapsed" title="Click to expand Support Tools">

      <div class="page-menu-indicator" *ngIf="pageMenu?.length > 0"></div>

      <span translate="no" class="material-icon material-icon--white material-icon--xxlarge">navigate_next</span>

    </div>

    <div *ngIf="isExpanded" class="statusbar">
      <span><strong>SignalR:</strong> {{signalrStatusText}}</span>
      <span title="ServiceBus (active/deadletter)" *ngIf="activeQueueMessages || deadletterMessages">
        <br><strong>ServiceBus:</strong> {{activeQueueMessages}}/<span class="deadletter">{{deadletterMessages}}</span>
      </span>


    </div>


  </div>

</ng-template>

<mibp-context-menu (select)="pageMenuSelect($event)" #ctxPageMenu [items]="pageMenu"></mibp-context-menu>
<mibp-context-menu (select)="actAsMenuSelect($event)" #ctxActasMenu [items]="actasMenu"></mibp-context-menu>
<mibp-context-menu  #toolsMenu [items]="toolsMenuItems"></mibp-context-menu>

<mibp-dialog [fullheight]="true" #sessionInfoDialog (buttonClick)="sessionInfoDialog.close()" [buttons]="sessionInfoButtons">
  <div *ngIf="isExpanded">

  <p>ClientID: <mibp-copy-to-clipboard [value]="clientId"></mibp-copy-to-clipboard></p>
  <p>Access Token: <mibp-copy-to-clipboard [value]="auth.getB2CUser()?.accessToken"></mibp-copy-to-clipboard></p>
  <p>IdentityObjectId: <mibp-copy-to-clipboard [value]="auth.getB2CUser()?.identityObjectId"></mibp-copy-to-clipboard></p>
  <p>User ID: <mibp-copy-to-clipboard [value]="mibpSessionService.current?.user?.userId"></mibp-copy-to-clipboard></p>

  <div class="mibp-expandable-sections">
    <mibp-expandable-section [title]="'B2C Account'" design="modern">
      <div data-content>
        <p>This data comes from the token received from B2C</p>
        <pre>{{auth.getB2CUser() | json}}</pre>
      </div>
    </mibp-expandable-section>

    <mibp-expandable-section [title]="'Active Delivery Sequence'" design="modern">
      <div data-content>
        <p>This data comes from the Active Delivery Sequence in the current <code>[Connection]/Session</code></p>
        <pre>{{mibpSessionService.current?.activeDeliverySequence | json}}</pre>
      </div>
    </mibp-expandable-section>

    <mibp-expandable-section [title]="'User'" design="modern">
      <div data-content>
          <p>This data comes from the User in the current <code>[Connection]/Session</code></p>
          <pre>{{mibpSessionService.current?.user | json}}</pre>
        </div>
    </mibp-expandable-section>
  </div>
</div>

</mibp-dialog>
