import { Injectable } from '@angular/core';
import { DynatraceUserService } from 'root/services/dynatrace/dynatrace-user.service';
import { ApiService } from 'root/services/mibp-api-services';
import { BehaviorSubject, Observable, Subject, count, delay, filter, firstValueFrom } from 'rxjs';
import { SystemMessageDialogComponent } from './system-message-dialog.component';
import { GetActiveSystemMessagesQueryResponseVm, UserType } from 'root/mibp-openapi-gen/models';
import { SystemMessagesApiController } from 'root/mibp-openapi-gen/controllers';
import { BroadcastService, CacheScope, ClientSideCacheService, GlobalConfigService, LocalizationService, LogService, MibpLogger } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

export interface ActiveSystemMessageVm extends GetActiveSystemMessagesQueryResponseVm {
  hasSeen?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SystemMessageService {
  private systemMessageDialogComponent: SystemMessageDialogComponent;
  private lastRequestHash: string;
  private allSystemMessages: ActiveSystemMessageVm[] = [];
  private activeSystemMessagesSubject = new BehaviorSubject<ActiveSystemMessageVm[]>([]);
  private activeSystemMessagesCountSubject = new BehaviorSubject<string>(null);
  private timerNextMessage: number;
  private timerNextRefresh: number;

  private log: MibpLogger;


  constructor(
    private dynatraceUserService: DynatraceUserService,
    private broadcastService: BroadcastService,
    private sessionService: MibpSessionService,
    logService: LogService,
    private signalrApi: ApiService,
    private cacheService: ClientSideCacheService,
    private localizationService: LocalizationService,
    private globalConfig: GlobalConfigService,
    private systemMessageApi: SystemMessagesApiController,
  ) {
    this.log = logService.withPrefix(`system-message-service`);

    // These will trigger an initial update
    this.broadcastService.mibpSession.subscribe(async () => await this.refreshFromServer());
    this.localizationService.Locale$.subscribe(async () => await this.refreshFromServer());

    // If we get a push event from signalr to refresh system messages. Occurs when someone adds/updates a system message
    this.signalrApi.EventHub.RefreshSystemMessages.pipe(filter(e => e != null), delay(5000)).subscribe(async (e) => await this.onRefreshSignalrEvent(e));
  }

  private async onRefreshSignalrEvent(e): Promise<void> {
    this.log.debug(`Received SignalR event to refresh system messages from server`);
    this.lastRequestHash = null;
    this.refreshFromServer();
  }

  // Two public observables that should be used by other places

  public get activeSystemMessages$(): Observable<ActiveSystemMessageVm[]> {
    return this.activeSystemMessagesSubject.asObservable();
  }

  public get activeSystemMessageCount$(): Observable<string> {
    return this.activeSystemMessagesCountSubject.asObservable();
  }



  private getSystemMessageCacheKey(message: ActiveSystemMessageVm): string {
    // We use id + publish date as a key. So we can change the publish date on an existing message to re-use it
    return `systemmessage_${message.id}_${message.publishFromDate}`;
  }

  private hasUserSeenMessage(message: ActiveSystemMessageVm) {
    const key = this.getSystemMessageCacheKey(message);
    return this.cacheService.get(key) ? true : false;
  }

  public markMessageAsRead(message: ActiveSystemMessageVm): void {
    const key = this.getSystemMessageCacheKey(message);
    this.cacheService.add(key, true, null, CacheScope.UserStorage);
  }

  /***
   * Get the list of messages that should be shown right now
   */
  private getActiveMessages(currentTime: Date): ActiveSystemMessageVm[] {
    return this.allSystemMessages
      .filter(msg => currentTime >= new Date(msg.publishFromDate)  && currentTime <= new Date(msg.publishToDate))
      .map(msg => {
        return {
          ...msg,
          hasSeen: this.hasUserSeenMessage(msg)
        };
      });
  }

  private getFutureMessages(currentTime: Date): ActiveSystemMessageVm[] {
    return this.allSystemMessages
      .filter(msg => new Date(msg.publishFromDate) > currentTime);
  }

  /**
   * We have a message that should be shown later.
   * Setup a timer to call 'pushAnyActiveMessages' when it's time to show the message
   */
  private startTimerForFutureMessage(currentTime: Date, futureMessage: ActiveSystemMessageVm): void {
    const nextDate = new Date(futureMessage.publishFromDate);
    const msToNextMessage = nextDate.getTime() - currentTime.getTime() + 5000;
    this.timerNextMessage = window.setTimeout(() => {
      this.pushAnyActiveMessages();
    }, msToNextMessage);
  }

  /**
   * Will check the list of system messages that we have loaded
   * and make sure the correct ones are pushed into the subjects
   */
  private pushAnyActiveMessages(): void {

    clearInterval(this.timerNextMessage);

    const currentTime = new Date();

    // Filter out active messages
    const activeMessages = this.getActiveMessages(currentTime);
    const futureMessages = this.getFutureMessages(currentTime);

    if (futureMessages.length > 0) {
      this.startTimerForFutureMessage(currentTime, futureMessages[0]);
    }

    // If message list has changed from the current one - then we push the next subject value
    const visibleMessages = this.activeSystemMessagesSubject.value.map(m => JSON.stringify(m)).join(',');
    const newCurrentMessages = activeMessages.map(m => JSON.stringify(m)).join(',');

    if( visibleMessages != newCurrentMessages) {
      this.activeSystemMessagesSubject.next(activeMessages);

      // Let's also push the change for the message count
      let countValue: string = activeMessages.length.toString();
      if (activeMessages.length > 99) {
        countValue = `99+`;
      }
      this.activeSystemMessagesCountSubject.next(countValue);

    }

  }

  private startRefreshTimer(): void {

    const pollIntervalMs = (this.globalConfig.systemMessagePollIntervalMinutes * 60) * 1000;

    this.timerNextRefresh = window.setTimeout(() => {
      this.lastRequestHash = null;
      this.refreshFromServer();
    }, pollIntervalMs);
  }

  /**
   * Load system messages from backend
   */
  private async refreshFromServer(): Promise<void> {

    // Dynatrace user should never be bothered with system messages
    if (this.dynatraceUserService.isDynatraceUser) {
      return;
    }

    clearInterval(this.timerNextRefresh);

    const requestHash = `${this.sessionService?.activeDeliverySequence?.deliverySequenceId}_${this.localizationService.getLang()}`;
    if (this.lastRequestHash == requestHash) {
      return;
    }
    this.lastRequestHash = requestHash;

    if (this.sessionService.hasActiveUser()) {
      try {
        const messagesFromServer = await firstValueFrom(this.systemMessageApi.getActiveSystemMessages({
          languageCode: this.localizationService.getLang()
        }));

        this.allSystemMessages = messagesFromServer.sort((a,b) => {
          if (a.publishFromDate == b.publishFromDate) {
            return 0;
          }
          return a.publishFromDate < b.publishFromDate ? -1 : 1;
        });

        this.pushAnyActiveMessages();
        this.startRefreshTimer();
        return;
      } catch (e) {
        this.startRefreshTimer();
        this.log.warn(`Error occured when fetching system messages`);
      }
    }

    if (this.activeSystemMessagesSubject.value.length > 0) {
      this.activeSystemMessagesSubject.next([]);
      this.activeSystemMessagesCountSubject.next(null);
    }

  }

  public showDialogIfMessagesExist(): void {
    this.systemMessageDialogComponent.showDialogIfMessagesExist();
  }

  public registerSystemMessageDialogComponent(systemMessageDialogComponent: SystemMessageDialogComponent): void {
    this.systemMessageDialogComponent = systemMessageDialogComponent;
  }

}
