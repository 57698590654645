/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { delete$ } from '../fn/notification/delete';
import { Delete$Params } from '../fn/notification/delete';
import { deleteAll } from '../fn/notification/delete-all';
import { DeleteAll$Params } from '../fn/notification/delete-all';
import { getAllNotificationsForUser } from '../fn/notification/get-all-notifications-for-user';
import { GetAllNotificationsForUser$Params } from '../fn/notification/get-all-notifications-for-user';
import { getAllNotificationsForUser$Plain } from '../fn/notification/get-all-notifications-for-user-plain';
import { GetAllNotificationsForUser$Plain$Params } from '../fn/notification/get-all-notifications-for-user-plain';
import { markAllAsRead } from '../fn/notification/mark-all-as-read';
import { MarkAllAsRead$Params } from '../fn/notification/mark-all-as-read';
import { markAsRead } from '../fn/notification/mark-as-read';
import { MarkAsRead$Params } from '../fn/notification/mark-as-read';
import { NotificationPagedResponse } from '../models/notification-paged-response';

@Injectable({ providedIn: 'root' })
export class NotificationApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notificationControllerGetAllNotificationsForUseroptions()` */
  static readonly NotificationControllerGetAllNotificationsForUseroptionsPath = '/notification/getallnotificationsforuser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllNotificationsForUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllNotificationsForUser$Plain$Response(params?: GetAllNotificationsForUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationPagedResponse>> {
    return getAllNotificationsForUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllNotificationsForUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllNotificationsForUser$Plain(params?: GetAllNotificationsForUser$Plain$Params, context?: HttpContext): Observable<NotificationPagedResponse> {
    return this.getAllNotificationsForUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationPagedResponse>): NotificationPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllNotificationsForUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllNotificationsForUser$Response(params?: GetAllNotificationsForUser$Params, context?: HttpContext): Observable<StrictHttpResponse<NotificationPagedResponse>> {
    return getAllNotificationsForUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllNotificationsForUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllNotificationsForUser(params?: GetAllNotificationsForUser$Params, context?: HttpContext): Observable<NotificationPagedResponse> {
    return this.getAllNotificationsForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<NotificationPagedResponse>): NotificationPagedResponse => r.body)
    );
  }

  /** Path part for operation `notificationControllerDeleteAll()` */
  static readonly NotificationControllerDeleteAllPath = '/notification/deleteall';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAll$Response(params?: DeleteAll$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAll(params?: DeleteAll$Params, context?: HttpContext): Observable<void> {
    return this.deleteAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationControllerMarkAllAsRead()` */
  static readonly NotificationControllerMarkAllAsReadPath = '/notification/markallasread';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAllAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAllAsRead$Response(params?: MarkAllAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markAllAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAllAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAllAsRead(params?: MarkAllAsRead$Params, context?: HttpContext): Observable<void> {
    return this.markAllAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationControllerMarkAsReadid()` */
  static readonly NotificationControllerMarkAsReadidPath = '/notification/marasread/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAsRead$Response(params: MarkAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAsRead(params: MarkAsRead$Params, context?: HttpContext): Observable<void> {
    return this.markAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationControllerDeleteid()` */
  static readonly NotificationControllerDeleteidPath = '/notification/delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
