<mibp-system-message *ngIf="!isActingAs" class="m-4">
  <mibp-resource-string key="Global_ActAsCustomerForFeature"></mibp-resource-string>
</mibp-system-message>

<section class="my-contact-us-page" *ngIf="isActingAs">
  <aside>
    <div class="my-card">
      <div class="row">
        <h2 class="my-header"><mibp-resource-string key="Global_ContactUs"></mibp-resource-string></h2>
        <div>
          <p><mibp-resource-string key="ContactUs_Info"></mibp-resource-string></p>
        </div>
        <label><mibp-resource-string key="ContactUs_HowCanWeHelp"></mibp-resource-string><span
            class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
        <mibp-dropdown #topicDropdown name="topic" id="topic" placeholder="ContactUs_SelectCategory_Placeholder" [items]="categories"
          (valueChange)="onTopicChange($event)"></mibp-dropdown>
      </div>
      <div *ngIf="selectedTopic" class="row">
        <mibp-new-contact-us-form [selectedTopic]="selectedTopic" [showHeader]="true"
          (successfullySubmitted)="resetForm($event)"></mibp-new-contact-us-form>
      </div>
      <label><mibp-resource-string key="ContactUs_HelpsectionInfoPart1"></mibp-resource-string>&nbsp;
        <a href="#" (click)="gotoGeneral($event)"><mibp-resource-string
            key="ContactUs_HelpSection"></mibp-resource-string></a>
        &nbsp;<mibp-resource-string key="Carts_Active_Checkout_Checkout_and"></mibp-resource-string>&nbsp;
        <a href="#" (click)="gotoFAQ($event)"><mibp-resource-string key="ContactUs_FAQ"></mibp-resource-string></a>
        ? <mibp-resource-string key="ContactUs_HelpsectionInfoPart2"></mibp-resource-string></label>
    </div>
  </aside>

  <article *ngIf="showLiveChat">
    <div class="my-card">
      <h3 class="my-header hide-print"><mibp-resource-string key="LiveChat_Header"></mibp-resource-string></h3>
      <div>
        <p><mibp-resource-string key="LiveChat_Info" isHtml="true"></mibp-resource-string></p>
      </div>
      <div><mibp-button resourceStringKey="LiveChat_StartLiveChat" (click)="startLiveChat()"></mibp-button></div>

    </div>
  </article>
</section>

