
<span #userButton (mouseenter)="userPopupContainer.open(userButton)">
  <!-- <span class="my-header__icon"><mibp-sandvik-icon name="user" color="black"></mibp-sandvik-icon></span>

  -->
  <mibp-user-photo data-aut="header-user-photo"></mibp-user-photo>
</span>

<mibp-popup-container #userPopupContainer [closeOnMouseLeave]="true">
  <div class="my-userinfo">
    <a data-aut="header-usermenu-profile" *ngIf="!userEventIsNull && isActiveUser" [ecommerceRouterLink]="['/:lang/user/myprofile']" (click)="userPopupContainer.close()">
      <span><span translate="no" class="material-icon material-icon--xxlarge">person</span></span>
      <span><mibp-resource-string key="Users_MyProfile_Tab_Title"></mibp-resource-string></span>
    </a>

    <a data-aut="header-usermenu-activity" *ngIf="!userEventIsNull && isActiveUser" [ecommerceRouterLink]="['/:lang/user/activity']" (click)="userPopupContainer.close()">
      <span><span translate="no" class="material-icon material-icon--xxlarge">notifications</span></span>
      <span><mibp-resource-string key="Users_Notifications_Tab_Title"></mibp-resource-string></span>
    </a>

    <a *ngIf="isSupportUser && isActiveUser"  [ecommerceRouterLink]="['/:lang/operations']" class="my-mobile-menu-container__user--operations"  (click)="userPopupContainer.close()">
      <span><span translate="no" class="material-icon material-icon--xxlarge">construction</span></span>
      <span>Operations</span>
    </a>

    <a data-aut="header-usermenu-signout" *ngIf = "isLogoutVisible" (click)="logout($event)">
      <span><span translate="no" class="material-icon material-icon--xxlarge">logout</span></span>
      <span><mibp-resource-string key="Global_Logout"></mibp-resource-string></span>
    </a>

  </div>
</mibp-popup-container>
