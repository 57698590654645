<div>
  <h3 *ngIf="showHeader" class="my-header"><mibp-resource-string key="SupportCases_Type_TradingAccountQuery"></mibp-resource-string></h3>
   <form [formGroup]="contactUsTradingAccountForm" (ngSubmit)="submit()">
    <div class="columns">
      <div class="column is-half">
        <div class="forms__row">
          <label><mibp-resource-string key="ContactUs_Name"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="50"  formControlName="name" >
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="name"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column column--pr">
        <div class="forms__row">
          <label><mibp-resource-string key="ContactUs_Email_Field"></mibp-resource-string><span *ngIf="isEmailSelected" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="50"  formControlName="email" >
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="email"></mibp-validation-text>
        </div>
      </div>
      <div class="column">
        <div class="forms__row">
          <label><mibp-resource-string key="ContactUs_Phone_Field"></mibp-resource-string><span *ngIf="isPhoneNumberSelected" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="50"  formControlName="phonenumber" >
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="phonenumber"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <div class="forms__row" formGroupName="contactPreferenceGroup">
          <label><mibp-resource-string key="ContactUs_PreferedContactMethod"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <div class="checkbox">
            <input formControlName="byEmail" id="byEmail" name="byEmail" type="checkbox">
            <label for="byEmail"><mibp-resource-string key="ContactUs_Email"></mibp-resource-string></label>
          </div>
          <div class="checkbox">
            <input formControlName="byPhone" id="byPhone" name="byPhone" type="checkbox">
            <label for="byPhone"><mibp-resource-string key="ContactUs_Phone"></mibp-resource-string></label>
          </div>
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="contactPreferenceGroup"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div  class="forms__row">
          <label><mibp-resource-string key="SupportCasesTradingAccount_Type_Label"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <mibp-dropdown [items]="tradingAccountTypes" formControlName="tradingAccountType" (valueChange)="tradingAccountTypeChanged($event)"></mibp-dropdown>
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="tradingAccountType"></mibp-validation-text>
        </div>
          <div class="forms__row" *ngIf="showPurchaseOrderNumber">
          <label><mibp-resource-string key="Contact_Us_PurchaseOrderNumber"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="20"  formControlName="purchaseOrderNumber" >
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="purchaseOrderNumber"></mibp-validation-text>
        </div>
        <div class="forms__row" *ngIf="showInvoiceNumber">
          <label><mibp-resource-string key="Global_InvoiceNumber"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <input type="text" maxlength="20"  formControlName="invoiceNumber" >
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="invoiceNumber"></mibp-validation-text>
        </div>
        <div class="forms__row" *ngIf="showCurrentAddress">
          <label><mibp-resource-string key="SupportCasesTradingAccount_CurrentAddress"></mibp-resource-string><span  class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <textarea class="textarea" maxlength = "300" formControlName="currentAddress"></textarea>
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="currentAddress"></mibp-validation-text>
        </div>
        <div class="forms__row" *ngIf="showNewAddress">
          <label><mibp-resource-string key="SupportCasesTradingAccount_NewAddress"></mibp-resource-string><span  class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <textarea class="textarea" maxlength = "300" formControlName="newAddress"></textarea>
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="newAddress"></mibp-validation-text>
        </div>
        <div class="forms__row">
          <label><mibp-resource-string key="Global_Description"></mibp-resource-string><span *ngIf="isDescriptionRequired" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
          <textarea class="textarea" maxlength = "300" formControlName="description"></textarea>
          <mibp-validation-text [formGroup]="contactUsTradingAccountForm" [alwaysVisible]="formSubmitted" controlName="description"></mibp-validation-text>
        </div>
      </div>
    </div>
    <div class="submitButton">
      <mibp-button type="submit" [resourceStringKey]="'UserReg_Submit'" [color]="buttonColors.Primary"></mibp-button>
    </div>
  </form>
</div>
