import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ButtonColors } from 'root/components/button/button.enum';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { ToastButton } from 'root/components/toast/toast.component';
import { SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';
import { CaseType, ContactUsSupportCaseRequest } from 'root/mibp-openapi-gen/models';
import { FormValidationService, FormattingService, LoaderService, NoticebarService, ToastService } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { ToastType } from 'root/services/toast-service/toast.enum';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';
import { MibpEmailValidator } from 'root/validators/email.validator';
import { requireCheckboxesToBeCheckedValidator } from 'root/validators/requireCheckboxesToBeChecked';
import { Subscription, firstValueFrom } from 'rxjs';

export enum SupportCaseGeneralType {
  QuestionOrRequest = 'General question or request'
}

export interface SupportCaseGeneralData {
  orderNumber?: string,
  description?: string,
  enquiryType?: SupportCaseGeneralType
}
@Component({
  selector: 'mibp-contact-us-general-form',
  templateUrl: './contact-us-general-form.component.html',
  styleUrls: ['./contact-us-general-form.component.scss']
})
export class ContactUsGeneralFormComponent implements OnInit, OnDestroy {
  protected contactUsGeneralForm: UntypedFormGroup;
  protected buttonColors = ButtonColors;
  protected formSubmitted: boolean;
  protected isEmailSelected: boolean;
  protected isPhoneNumberSelected: boolean;
  private formValueChangesSub: Subscription;

  @Input() showHeader = true;
  @Output() successfullySubmitted = new EventEmitter<boolean>();


  constructor(private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private mibpSession: MibpSessionService,
    private formattingService: FormattingService,
    private supportCaseController: SupportCaseApiController,
    private loader: LoaderService,
    private toastService: ToastService){
  }

  ngOnDestroy(): void {
    this.formValueChangesSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.contactUsGeneralForm = this.fb.group({
      name: [this.mibpSession.current.user.firstName + ' ' + this.mibpSession.current.user.lastName, [Validators.required, MibpAllowedCharactersValidator()]],
      email: [this.mibpSession.current.user.email, MibpEmailValidator(this.formattingService)],
      phonenumber: [this.mibpSession.current.user.phone, MibpAllowedCharactersValidator()],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false)
      }, { validators: requireCheckboxesToBeCheckedValidator(1)}),
      description: ['', [Validators.required, MibpAllowedCharactersValidator()]]
    });

    this.formValueChangesSub = this.contactUsGeneralForm.controls.contactPreferenceGroup.valueChanges.subscribe(() => {
      this.isEmailSelected = this.contactUsGeneralForm.controls.contactPreferenceGroup.get('byEmail').value;
      this.isPhoneNumberSelected = this.contactUsGeneralForm.controls.contactPreferenceGroup.get('byPhone').value;
      this.isEmailSelected ? this.addRequiredValidators('email') : this.removeRequiredValidators('email');
      this.isPhoneNumberSelected ? this.addRequiredValidators('phonenumber') : this.removeRequiredValidators('phonenumber');
    });
  }

  private addRequiredValidators(field: string){
    this.contactUsGeneralForm.get(field).addValidators([Validators.required]);
    this.contactUsGeneralForm.get(field).updateValueAndValidity();
  }

  private removeRequiredValidators(field: string){
    this.contactUsGeneralForm.get(field).removeValidators(Validators.required);
    this.contactUsGeneralForm.get(field).updateValueAndValidity();
  }

  async submit(){
    this.formSubmitted = true;
    if (this.contactUsGeneralForm.invalid) {
      this.contactUsGeneralForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactUsGeneralForm, this.element);
      return;
    }
    const contactUsSupportCaseRequest = <ContactUsSupportCaseRequest>{
      caseType: CaseType.GeneralQuestionOrRequest,
      categoryGeneralQuestionOrRequest: SupportCaseGeneralType.QuestionOrRequest,
      suppliedFullName: this.contactUsGeneralForm.get('name').value,
      suppliedEmail: this.contactUsGeneralForm.get('email').value,
      suppliedPhone: this.contactUsGeneralForm.get('phonenumber').value,
      prefferedEmailContact: this.contactUsGeneralForm.controls.contactPreferenceGroup.get('byEmail').value,
      prefferedPhoneContact: this.contactUsGeneralForm.controls.contactPreferenceGroup.get('byPhone').value,
      description: this.contactUsGeneralForm.get('description').value
      };

    try {
      this.loader.showFullScreenLoader();
      await firstValueFrom(this.supportCaseController.submitContactUsCase({body: contactUsSupportCaseRequest}));
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(true);
    }
    catch(error){
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(false);
    }
  }
}
