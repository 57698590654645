import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SignalR_Rendition } from 'root/services';
import { ButtonColors, ButtonStyles } from './../button/button.enum';
import { DialogButton, DialogComponent } from '..';
import { ActiveSystemMessageVm, SystemMessageService } from './system-message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mibp-system-message-dialog',
  templateUrl: './system-message-dialog.component.html',
  styleUrls: ['./system-message-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SystemMessageDialogComponent implements OnInit, OnDestroy {

  @Input() openDialog:boolean;
  @ViewChild(DialogComponent) dialog: DialogComponent;

  protected dialogButtons: DialogButton[];
  protected dialogTitle = 'Global_SystemMessage';
  protected rendition = SignalR_Rendition;
  protected activeMessages: ActiveSystemMessageVm[];
  protected currentMessage: ActiveSystemMessageVm;
  protected currentIndex = 0;
  protected messageSubscription: Subscription;

  constructor(private systemMessageService: SystemMessageService) {}

  ngOnInit(): void {

    // Tell the service that we're here
    this.systemMessageService.registerSystemMessageDialogComponent(this);

    // Subscribe to active system messages
    this.messageSubscription = this.systemMessageService.activeSystemMessages$.subscribe(activeMessages => {
      this.activeMessages = activeMessages;
      this.showFirstUnseenMessage();
    });

  }

  private showFirstUnseenMessage(): void {
    const firstUnseenMessageIndex = this.activeMessages.findIndex(msg => !msg.hasSeen);
    if (firstUnseenMessageIndex != -1) {
      this.showMessage(firstUnseenMessageIndex);
    }
  }

  private updateDialogButtons(): void {

    this.dialogButtons = [
      {
        id: 'close',
        resourceKey: 'Global_Dialog_Close',
        color: ButtonColors.Secondary,
        minWidth: 150
      }
    ];

    // Can be added later if we want it
    // if (this.currentIndex > 0) {
    //   this.dialogButtons.unshift({
    //     id: 'prev',
    //     resourceKey: 'Global_Dialog_Previous',
    //     color: ButtonColors.Primary,
    //     minWidth: 150
    //   });
    // }

    if (this.currentIndex < this.activeMessages.length - 1) {
      this.dialogButtons.push({
        id: 'next',
        resourceKey: 'Global_Dialog_Next',
        color: ButtonColors.Primary,
        style: ButtonStyles.Fill,
        minWidth: 150
      });
    }

  }

  private showMessage(index: number): void {
    this.currentIndex = index;
    this.currentMessage = {...this.activeMessages[index]}; // Copy message so it's not closed if active messages are updated
    this.updateDialogButtons();
    this.dialog.open();
    this.systemMessageService.markMessageAsRead(this.currentMessage);
  }

  showDialogIfMessagesExist(): void {
    if (this.activeMessages?.length > 0) {
      this.currentIndex = 0;
      this.showMessage(this.currentIndex);
    }
  }

  closeDialog(){
    this.currentIndex = 0;
    this.dialog.close();
  }

  protected dialogClick(e: DialogButton): void {
    if (e.id=="next") {
      this.currentIndex += 1;
      this.showMessage(this.currentIndex);
    } else if (e.id=="prev") {
      this.currentIndex -= 1;
      this.showMessage(this.currentIndex);
    } else {
      this.closeDialog();
    }
  }

  ngOnDestroy(): void {
    this.messageSubscription?.unsubscribe();

  }

}
