import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { InlineEditingOverlayService } from 'root/services';

@Component({
  selector: 'mibp-inline-editing-overlay',
  styleUrls: ['./inline-editing-overlay.component.scss'],
  templateUrl: './inline-editing-overlay.component.html'
})
export class InlineEditingOverlayComponent implements OnInit, OnDestroy {
  visible = false;
  visibilitySub: Subscription;

  constructor(private inlineEditingOverlayService: InlineEditingOverlayService) {}

  ngOnInit(): void {
    this.visibilitySub = this.inlineEditingOverlayService.Visibility.subscribe(visibility => {
      this.visible = visibility;
    });
  }

  ngOnDestroy(): void {
    if (this.visibilitySub) {
      this.visibilitySub.unsubscribe();
    }
  }

}
