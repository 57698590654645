/* tslint:disable */
/* eslint-disable */
export enum CartItemProductStatus {
  NotSet = 0,
  CompanyProductExists = 1,
  CompanyProductRestricted = 2,
  CompanyProductMissing = 3,
  GlobalProductExists = 4,
  GlobalProductMissing = 5
}
