import { EventMessage } from "@azure/msal-browser";


export enum AuthenticationStatus {
  NotSet,
  LoggedIn,
  NotLoggedIn,
  ForgottenPassword,
  Usercancelled,
  Error
}

export enum AuthenticationPopupStatus {
  NotSet,
  Open,
  Loggedin,
  PopupBlocked
}

export interface MibpAuthSetupResult {
  status: AuthenticationStatus;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorObject?: any;
}

export interface MySandvikAppB2cData {
  id: string;
  appName: string;
  hideCancelButton?: boolean;
}

export interface B2CIDTokenClaims {
  family_name?: string;
  given_name?: string;
  iat?: number;
  exp?: number;
  auth_time?: number;
  tfp?: string;
  emails?: string[];
  sub?: string;
  idp?: string;
  country?: string;

  /**
   * Contains the ClientID that is sent to B2C when signing in
   */
  nonce?: string;
}

export interface B2CAccount {
  identityObjectId: string;
  family_name: string;
  given_name: string;
  type: 'internal' | 'external';
  country: string;
  email: string;

  accessToken?: string;

  /**
   * Trust Framework Policy that issued the token
   */
  tfp: string;

  /**
   * Expires
   */
   exp: Date;

  /**
   * Issued at
   */
  iat: Date;

 auth_time: Date;

  /**
   * The ClientID - this is generated in ClientIdService and sent to B2C during signin redirect
   */
  nonce: string;
}

export interface B2CLoginSuccessPayload {
  state?: string;
  account: {
    idTokenClaims: B2CIDTokenClaims
  }
}


export interface SigninOptions {
  /**
   * Extra querystring parameters that will be sent to B2C page
   */
  queryParams?: { [key: string]: string},

  /**
   * State parameters that will be send to b2c page and then available when b2c redirects back to My Sandvik
   */
  stateParams?: { [key: string]: string},

  /**
   * Language code that will be used for localization in b2c (Will attempt to resolve it automatically if not specified)
   * Will be sent both as querystring parameter (ui_locales) and state (lang) unless it  is specifically overridden in those settings
   */
  languageCode?: string;

  /**
   * The B2C Redirect URI
   * This URI must be registered in B2C application
   * NOTE! Use option "returnUrl" to specify where to end up after b2c work is completed
   */
  b2cRedirectUri?: string;

  /***
   * Where to end up after login
   */
  returnUrl?: string;

  /**
   * Scopes to login with (will use environment.auth.b2c.policies.scope if not specified)
   */
  scopes?: string[];

  /**
   * The b2c policy (authority/user flow) that will be used (will use environment.auth.b2c.policies.signUpSignIn if not specified)
   */
  b2cPolicy?: string;
}

export type B2CEventMessages = { [key: string]: EventMessage};

export interface B2CServerError {
  errorCode?: string;
  errorMessage?: string;
  message?: string;
  originalStack?: string;
  stack?: string;
}
