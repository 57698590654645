import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { Subject } from "rxjs";
import { filter, first } from "rxjs/operators";
import { AuthService } from "../auth-service/auth.service";
import { BroadcastService } from "../broadcast-service/broadcast.service";
import { SignalrConnectionStatusEvent } from "../broadcast-service/broadcast.service.types";
import { MibpException } from "../error-handler/error-handler.service";
import { LogService } from "../logservice/log.service";
import { MibpLogger } from "../logservice/mibplogger.class";
import { MibpSessionService } from "../mibp-session/mibp-session.service";

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private log: MibpLogger;
  private connectionAttempt = 1;
  private fullHubUrl?: string;
  private connectedWithAccessToken: string;
  private isUpdatingAccessToken = false;
  private updatingAccessTokenSubject: Subject<void>;
  private reconnectTimer?: number;


  private pendingListOfSignalrEvents: { name: string, callback: () => void }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private connection: signalR.HubConnection;

  constructor( private broadcastService: BroadcastService, private sessionService: MibpSessionService, private auth: AuthService, logger: LogService) {
    this.log = logger.withPrefix('signalr.service');

    //console.warn("SIGNALRRRRR constructor", this.auth, this.sessionService);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.connection = <any>{
      on: (name: string, callback: () => void) => {
        this.pendingListOfSignalrEvents.push({ name, callback });
      }
    };

    // this.broadcastService.globalConfig.subscribe(gc => {
    //   if (gc && this.broadcastService.snapshot.mibpSession?.user) {
    //     setTimeout(() => {
    //       try {
    //         this.connect();
    //       } catch (e) {
    //         console.error("NEEEJ SIGNALR",e);
    //       }
    //     }, 5500);
    //   }
    // });



  }

  public async connect(updatedToken?: string): Promise<SignalrConnectionStatusEvent> {
    this.fullHubUrl = `${this.broadcastService.snapshot.globalConfig?.backendUrl}${this.broadcastService.snapshot.globalConfig.signalR.hubName}`;
    this.log.debug(`Full URL`, this.fullHubUrl);

    const event: SignalrConnectionStatusEvent = {
      status: 'connecting',
      connecting: {
        reason: 'first',
        connectionAttempt: 1
      }
    };

    if (this.broadcastService.snapshot.signalR === null) {
      this.log.debug('Connection - (first)');
    } else if (updatedToken) {
      this.isUpdatingAccessToken = true;
      this.log.warn('Got an updated token. Disconnecting...');
      await this.connection.stop();
      await this.broadcastService.signalR.pipe(filter(c => c?.status === 'closed-for-token-refresh'), first()).toPromise();
      event.connecting.reason = 'newAccessToken';
      this.log.debug('Connection - (new access token)');
    } else {
      event.connecting.reason = 'disconnected';
      this.log.debug('Connection - (disconnected)');
    }
    this.broadcastService.setSignalrStatus(event);

    let accessToken: string = updatedToken;

    if (!accessToken && this.sessionService.isLoggedIn()) {
      try {
        accessToken = await this.auth.ensureToken();
      } catch (e) {
        this.log.error('Exception occured when fetching access token', e);
        throw e;
      }
    }

    if (!accessToken) {
      this.log.info(`Not connecting to SignalR for anonymous users`);
      return;
    }

    const languageCode = this.broadcastService.snapshot.language;
    const tokenParam = accessToken ? `&token=${encodeURIComponent(accessToken)}` : '';

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.fullHubUrl}?lang=${languageCode}${tokenParam}`, {
        skipNegotiation: this.broadcastService.snapshot.globalConfig.signalR.skipNegotiation,
        transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.ServerSentEvents | signalR.HttpTransportType.LongPolling,
      })
      .withAutomaticReconnect([0, 2000, 5000, 10000, 10000, 10000, 10000])
      .configureLogging(signalR.LogLevel.Information)
      .build();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.pendingListOfSignalrEvents.forEach(e => this.connection.on(e.name, <any>e.callback));

    this.connection.onreconnecting(e => {
      this.log.debug('Connection - Reconnecting', e);
      this.broadcastService.setSignalrStatus({
        status: 'connecting',
        connecting: {
          reason: 'disconnected',
          connectionAttempt: this.connectionAttempt
        },
        accessToken: accessToken,
        clientId: this.broadcastService.snapshot.clientId
      });
    });

    this.connection.onreconnected(e => {
      this.log.debug('Connection - Reconnected', e);
      this.connectionAttempt = 1;
      this.broadcastService.setSignalrStatus({
        status: 'connected',
        accessToken: accessToken,
        clientId: this.broadcastService.snapshot.clientId
      });
    });

    this.connection.on('error', (err) => {
      this.log.error('Connection - Error', err);
      if (this.updatingAccessTokenSubject || this.isUpdatingAccessToken) {
        this.log.warn('Error occured while reconnecting with a new access token', err);
        return;
      }


      // If already trying to reconnect, try again
      if (this.reconnectTimer) {
        this.reconnectTimer = undefined;
        this.tryReconnect(5000);
        return;
      }

      this.broadcastService.setSignalrStatus({
        status: 'error',
        accessToken: accessToken,
        clientId: this.broadcastService.snapshot.clientId
      });
    });

    this.connection.onclose(err => {
      // if (this.isUpdatingAccessToken || this.updatingAccessTokenSubject ) {
      //   this.log.info('Connection closed in order to update access token');
      // } else {

      this.log.warn('Connection - Closed', err);

      if (!this.isUpdatingAccessToken) {
        this.reconnectTimer = undefined;
        this.tryReconnect(10000);
        return;
      }


      this.broadcastService.setSignalrStatus({
        status: this.isUpdatingAccessToken ? 'closed-for-token-refresh' : 'closed',
        accessToken: accessToken,
        clientId: this.broadcastService.snapshot.clientId
      });
      // }
    });

    this.connection.start().then(() => {
      this.log.debug('Connection - Connected');
      this.connectedWithAccessToken = accessToken;
      this.connectionAttempt = 1;
      this.broadcastService.setSignalrStatus({
        status: 'connected',
        accessToken: accessToken,
        clientId: this.broadcastService.snapshot.clientId
      });

    }, err => {
      this.log.warn('Connection - Error (start)', err);

      if (this.reconnectTimer) {
        this.reconnectTimer = undefined;
        this.tryReconnect(5000);
        return;
      }

      this.broadcastService.setSignalrStatus({
        status: 'error',
        accessToken: accessToken,
        clientId: this.broadcastService.snapshot.clientId
      });
    });

    return this.broadcastService.signalR.pipe(filter(c => c?.status === 'connected'), first()).toPromise();
  }

  private tryReconnect(waitMs = 1500): void {
    if (!this.reconnectTimer) {
      this.log.debug(`Trying to reconnect in ${waitMs}ms (Attempt ${this.connectionAttempt})`);
      this.connectionAttempt ++;
      this.reconnectTimer = window.setTimeout(() => this.connect(), waitMs);
    } else {
      this.log.debug(`Already trying to reconnect... (Attempt ${this.connectionAttempt})`);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public stream<T = any>(methodName: string, args: any[]): Promise<T> {

    // Not connected? Return error at once instead of trying to wait for connection
    if (this.connection?.state !== signalR.HubConnectionState.Connected && !this.updatingAccessTokenSubject) {
      this.log.info(`SignalR is not connected. Rejecting request ${methodName}`);
      return Promise.reject(new MibpException({
        isFatal: false,
        type: 'backend',
        message: `Disconnected`,
        stack: Object.assign({
          request: {
            methodName: methodName,
            args: args
          }
        })
      }));
    }

    // Methodname must be the first paramete
    args.unshift(methodName);

    this.log.debug(`Request`, methodName, args);

    return new Promise<T>((resolve, reject) => {

      this.ensureSignalRAccessToken().then(() => {
        // eslint-disable-next-line prefer-spread
        this.connection.stream.apply(this.connection, args).subscribe({
          next: result => {
            if (result.success === false) {
              let errorType = 'an error';
              if (result.error && result.error.toString) {
                if (result.error.toString().indexOf('Mibp error ') !== -1) {
                  errorType = `: ${result.error.toString()}`;
                }
              }

              this.log.warn("Error received from backend. Reject with an MibpException");
              reject(new MibpException({
                isFatal: false,
                type: 'backend',
                message: `Method '${methodName}' returned ${errorType}`,
                stack: Object.assign({
                  request: {
                    methodName: methodName,
                    args: args
                  },
                  response: result
                }, result)
              }));
            } else {
              this.log.debug(`Response: ${methodName}`, result);
              resolve(result);
            }
          },
          error: err => {
            this.log.warn(`${methodName} Error`, err);
            reject(err);
          }
        });
      }, e => reject(e));

    });
  }

  private async ensureSignalRAccessToken(): Promise<void> {

    if (this.updatingAccessTokenSubject) {
      this.log.debug("SignalR access token check is already pending");
      return this.updatingAccessTokenSubject.asObservable().toPromise();
    }

    let accessToken: string;

    this.updatingAccessTokenSubject = new Subject<void>();

    try {
      accessToken = await this.auth.ensureToken();
    } catch (e) {
      this.auth.signinRedirect();
      throw e;
    }

    if (this.connectedWithAccessToken !== accessToken) {
      this.log.debug(`New access token. Recreating connection`);
      await this.connect(accessToken);
      this.log.debug(`New connection was established`);
    }

    this.updatingAccessTokenSubject.next(undefined);
    this.updatingAccessTokenSubject.complete();
    this.updatingAccessTokenSubject = null;

  }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoke<T = any>(methodName: string, args: any[]): Promise<T> {
    args.unshift(methodName);
    return new Promise<T>((resolve, reject) => {
      // eslint-disable-next-line prefer-spread
      this.connection.invoke.apply(this.connection, args).then(resultat => {
          resolve(resultat);
      }).catch(reject);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on(methodName: string, newMethod: (...args: any[]) => void): void {
    this.connection.on(methodName, newMethod);
  }

}
