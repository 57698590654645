import { Component, OnInit, Input, ElementRef, SimpleChanges } from '@angular/core';
import { SignalR_SelectProfileUserViewModel, ApiService, NotificationService, NoticebarService, MibpLogger, LogService, FrontendContextService, DialogService } from 'root/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { ButtonColors, ButtonStyles } from 'root/components/button/button.enum';
import { UsersApiController } from './../../../../mibp-openapi-gen/services/users-api-controller';
import { firstValueFrom } from 'rxjs';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { Router } from '@angular/router';
import { PunchoutProfileViewModel } from 'root/mibp-openapi-gen/models';

@Component({
  selector: 'mibp-profile-picker-card',
  templateUrl: './profile-picker-card.component.html',
  styleUrls: ['./profile-picker-card.component.scss']
})
export class ProfilePickerCardComponent implements OnInit {

  @Input() deleteDisabled = false;
  @Input() loginDisabled = false;

  currentProfile: PunchoutProfileViewModel;
  profileForm: UntypedFormGroup;
  isEditing = false;
  isLoading = false;
  log: MibpLogger;
  buttonColors = ButtonColors;
  buttonStyles = ButtonStyles;

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private router: Router,
    private sessionService: MibpSessionService,
    private elm: ElementRef,
    private usersApi: UsersApiController,
    private dialogService: DialogService,
    private notification: NoticebarService, logger: LogService,
    private frontendContextService: FrontendContextService) {
    this.log = logger.withPrefix('profile-picker-card');
  }

  @Input()
  set profile(profile: PunchoutProfileViewModel) {
    this.currentProfile = profile;
    if (profile) {
      if (this.profileForm) {

        if (!this.isEditing && profile && this.currentProfile && profile.id.toString() === this.currentProfile.id.toString()) {
          this.profileForm.patchValue({
            name: profile.name
          });
        }
      }
    }
  }

  get profile(): PunchoutProfileViewModel {
    return this.currentProfile;
  }

  ngOnInit() {
    this.profileForm = this.fb.group({
      id: [this.profile ? this.profile.id.toString() : null],
      name: [this.profile ? this.profile.name : null, [this.removeSpaces, Validators.required]]
    });
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }


  /* User actions */
  editStart() {
    this.isEditing = true;
    setTimeout( () => this.focusVisibleInput(), 80);
  }

  editSave() {
    this.isLoading = true;
    const newName = this.profileForm.value.name;



    this.usersApi.renamePunchoutProfile({profileUserId: this.profile.id.toString(), newName: newName}).subscribe({
      next: () => {
        this.profile.name = newName;
        this.isEditing = false;
        this.isLoading = false;
      },
      error: () => {
        this.notification.show('Global_GeneralErrorMessage', NoticeType.Error);
        this.isLoading = false;
      }
    });
  }

  editCancel() {
    this.profileForm.patchValue({
      name: this.profile.name
    });
    this.isEditing = false;
  }

  createNewProfile(e?: KeyboardEvent) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.profileForm.invalid) {
      return false;
    }

    this.isLoading = true;

    this.usersApi.addPunchoutProfile({name: this.profileForm.value.name}).subscribe({
      next: () => {
        this.blurActiveInput();
        this.profileForm.patchValue({name: null});
        this.profileForm.reset();
        this.isLoading = false;
      },
      error: () => {
        this.notification.show('Global_GeneralErrorMessage', NoticeType.Error);
        this.isLoading = false;
      }
    });

  }

  blurActiveInput() {
    const activeInput = this.elm.nativeElement.querySelector('input:focus');
    if (activeInput) {
      (activeInput as HTMLInputElement).blur();
    }
  }

  focusVisibleInput() {
    const visibleInput = this.elm.nativeElement.querySelector('input') as HTMLInputElement;
    if (visibleInput) {
      visibleInput.focus();
      visibleInput.select();
    }
  }


  deleteProfile() {
    const name = this.profile.name;
    this.dialogService.promptWithMacros('Global_DeletePrompt', { name: name}, 'delete#Global_Delete', 'cancel#Global_CancelButton').then(chosenAction => {

      if (chosenAction === 'delete') {
        this.isLoading = true;
        this.usersApi.deletePunchoutProfile({profileUserId: this.profile.id.toString()}).subscribe({
          next: () => {
            this.profileForm.patchValue({name: null});
            this.profileForm.reset();
          },
          error: () => {
            this.notification.show('Global_GeneralErrorMessage', NoticeType.Error);
            this.isLoading = false;
          }
        });
      }
    });
  }

  async loginAsProfile(): Promise<void> {
    this.isLoading = true;

    const updatedSessionVm = await firstValueFrom(this.usersApi.loginAsPunchoutProfile({profileUserId: this.profile.id.toString()}));
    this.sessionService.updateAndBroadcastCurrentSession(updatedSessionVm);

    this.isLoading = false;

    this.router.navigate([this.frontendContextService.getLang()]).then(() => {
      window.location.reload();
    });

  }

  /* Internal dynamic properties */
  get profileIcon(): string {
    if (this.profile) {
      if (this.profile.isLoggedIn) {
        return 'lock';
      }
      if (this.profile.isOriginalPunchoutUser) {
        return 'supervisor_account';
      }
      return 'person';
    }
    return 'person_add';
  }

  get classNames(): string[] {
    const blockName = 'my-profile-picker-card';
    const classes = [blockName];

    if (this.profile) {

      if (this.profile.isLoggedIn) {
        classes.push(` ${blockName}--loggedin`);
      }

      // if (this.profile.isPunchoutProfile === false) {
      //   classes.push(` ${blockName}--non-managable`);
      // } else {
      //   classes.push(` ${blockName}--managable`);
      // }

    } else {
      classes.push(` ${blockName}--new`);
    }

    return classes;
  }

}
