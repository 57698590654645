<mibp-loader type='loader' *ngIf="isLoading"></mibp-loader>
<div class="my-profilepicker" *ngIf="!isLoading">

  <h1 class="my-header my-header--nomargin"><mibp-resource-string key="Global_WelcomeToPrefix"></mibp-resource-string></h1>
  <h1 class="my-header"><mibp-resource-string key="Global_MySandvik_CustomerPortal"></mibp-resource-string></h1>

  <div class="my-body" *ngIf="profileManagagementEnabled">
    <p><mibp-resource-string key="ProfilePicker_InformationTextLine1"></mibp-resource-string></p>
    <p><mibp-resource-string key="ProfilePicker_InformationTextLine2"></mibp-resource-string></p>
    <p *ngIf="!acceptedTermsAndConditions" class="information"><mibp-resource-string key="ProfilePicker_InformationTextLine3"></mibp-resource-string></p>
  </div>

  <mibp-system-message *ngIf="maxAmountExceeded" #m [messageType]="m.Types.Warning">
    <h2><mibp-resource-string key="General_Error_InlineTitle"></mibp-resource-string></h2>
    <p><mibp-resource-string key="ProfilePicker_TooManyProfiles"></mibp-resource-string></p>
  </mibp-system-message>

  <div class="my-profilepicker__profile-list">
    <ng-container *ngFor="let profile of profileList; trackBy: trackByProfileId">
      <mibp-profile-picker-card [loginDisabled]="maxAmountExceeded || (!profile?.isOriginalPunchoutUser && !acceptedTermsAndConditions)" [deleteDisabled]="profileCount === 1" [profile]="profile"></mibp-profile-picker-card>
    </ng-container>
  </div>

</div>
