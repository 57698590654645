import { Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import { MibpLogger, LogService, LoaderService } from 'root/services';
import { environment } from 'root/environment';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[testid]'
})
export class TestIdDirective implements OnDestroy {
  log: MibpLogger;
  id: string;

  constructor(logger: LogService, private loaderService: LoaderService, private elementRef: ElementRef) {
    this.log = logger.withPrefix('loader.directive');
  }

  // TODO: Would it be safe to remove this Input name? Maybe?
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('testid')
  set testid(value: string) {
    if (environment.enableTestId) {
      this.id = value;
      this.element.setAttribute('data-testid', value);
    }
  }

  private get element(): HTMLElement {
    return (this.elementRef.nativeElement as HTMLElement);
  }

  ngOnDestroy() {
    if (environment.enableTestId) {
      this.element.removeAttribute('data-testid');
    }
  }

}
