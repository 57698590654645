/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulletinReportNew } from '../fn/download/bulletin-report-new';
import { BulletinReportNew$Params } from '../fn/download/bulletin-report-new';
import { cart } from '../fn/download/cart';
import { Cart$Params } from '../fn/download/cart';
import { excelBasedOnGrid } from '../fn/download/excel-based-on-grid';
import { ExcelBasedOnGrid$Params } from '../fn/download/excel-based-on-grid';
import { fileExists } from '../fn/download/file-exists';
import { FileExists$Params } from '../fn/download/file-exists';
import { getMessageLogFile } from '../fn/download/get-message-log-file';
import { GetMessageLogFile$Params } from '../fn/download/get-message-log-file';
import { invoice } from '../fn/download/invoice';
import { Invoice$Params } from '../fn/download/invoice';
import { maintenanceCalanderSummary } from '../fn/download/maintenance-calander-summary';
import { MaintenanceCalanderSummary$Params } from '../fn/download/maintenance-calander-summary';
import { newsArticleList } from '../fn/download/news-article-list';
import { NewsArticleList$Params } from '../fn/download/news-article-list';
import { order } from '../fn/download/order';
import { Order$Params } from '../fn/download/order';
import { productEnrichmentList } from '../fn/download/product-enrichment-list';
import { ProductEnrichmentList$Params } from '../fn/download/product-enrichment-list';
import { productNotificationItemlist } from '../fn/download/product-notification-itemlist';
import { ProductNotificationItemlist$Params } from '../fn/download/product-notification-itemlist';
import { promotionList } from '../fn/download/promotion-list';
import { PromotionList$Params } from '../fn/download/promotion-list';
import { quotation } from '../fn/download/quotation';
import { Quotation$Params } from '../fn/download/quotation';
import { restrictedItemList } from '../fn/download/restricted-item-list';
import { RestrictedItemList$Params } from '../fn/download/restricted-item-list';
import { translation } from '../fn/download/translation';
import { Translation$Params } from '../fn/download/translation';
import { userList } from '../fn/download/user-list';
import { UserList$Params } from '../fn/download/user-list';

@Injectable({ providedIn: 'root' })
export class DownloadApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `downloadControllerCartcartIdUserBrowserDate()` */
  static readonly DownloadControllerCartcartIdUserBrowserDatePath = '/download/cart/{cartId}/{userBrowserDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cart()` instead.
   *
   * This method doesn't expect any request body.
   */
  cart$Response(params: Cart$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cart(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cart(params: Cart$Params, context?: HttpContext): Observable<void> {
    return this.cart$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerUserListrequest()` */
  static readonly DownloadControllerUserListrequestPath = '/download/userlist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userList$Response(params?: UserList$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userList(params?: UserList$Params, context?: HttpContext): Observable<void> {
    return this.userList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerPromotionListrequest()` */
  static readonly DownloadControllerPromotionListrequestPath = '/download/promotionlist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotionList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotionList$Response(params?: PromotionList$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return promotionList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promotionList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotionList(params?: PromotionList$Params, context?: HttpContext): Observable<void> {
    return this.promotionList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerNewsArticleListrequest()` */
  static readonly DownloadControllerNewsArticleListrequestPath = '/download/newsarticlelist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsArticleList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  newsArticleList$Response(params?: NewsArticleList$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return newsArticleList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsArticleList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  newsArticleList(params?: NewsArticleList$Params, context?: HttpContext): Observable<void> {
    return this.newsArticleList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerProductEnrichmentListrequest()` */
  static readonly DownloadControllerProductEnrichmentListrequestPath = '/download/productenrichmentlist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productEnrichmentList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  productEnrichmentList$Response(params?: ProductEnrichmentList$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return productEnrichmentList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productEnrichmentList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  productEnrichmentList(params?: ProductEnrichmentList$Params, context?: HttpContext): Observable<void> {
    return this.productEnrichmentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerRestrictedItemListrequest()` */
  static readonly DownloadControllerRestrictedItemListrequestPath = '/download/restricteditemlist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restrictedItemList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  restrictedItemList$Response(params?: RestrictedItemList$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return restrictedItemList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restrictedItemList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  restrictedItemList(params?: RestrictedItemList$Params, context?: HttpContext): Observable<void> {
    return this.restrictedItemList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerMaintenanceCalanderSummaryrequest()` */
  static readonly DownloadControllerMaintenanceCalanderSummaryrequestPath = '/download/maintenancecalandersummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `maintenanceCalanderSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  maintenanceCalanderSummary$Response(params?: MaintenanceCalanderSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return maintenanceCalanderSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `maintenanceCalanderSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  maintenanceCalanderSummary(params?: MaintenanceCalanderSummary$Params, context?: HttpContext): Observable<void> {
    return this.maintenanceCalanderSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerProductNotificationItemlistrequest()` */
  static readonly DownloadControllerProductNotificationItemlistrequestPath = '/download/productnotificationitemlist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productNotificationItemlist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  productNotificationItemlist$Response(params?: ProductNotificationItemlist$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return productNotificationItemlist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productNotificationItemlist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  productNotificationItemlist(params?: ProductNotificationItemlist$Params, context?: HttpContext): Observable<void> {
    return this.productNotificationItemlist$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerExcelBasedOnGridmibpGridOptions()` */
  static readonly DownloadControllerExcelBasedOnGridmibpGridOptionsPath = '/download/excel-grid';

  /**
   * This is a general export grid endpoint that will export the grid to an excel.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `excelBasedOnGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  excelBasedOnGrid$Response(params?: ExcelBasedOnGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return excelBasedOnGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * This is a general export grid endpoint that will export the grid to an excel.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `excelBasedOnGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  excelBasedOnGrid(params?: ExcelBasedOnGrid$Params, context?: HttpContext): Observable<void> {
    return this.excelBasedOnGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerTranslationlanguageCodeOnlyMissingTranslations()` */
  static readonly DownloadControllerTranslationlanguageCodeOnlyMissingTranslationsPath = '/download/translation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `translation()` instead.
   *
   * This method doesn't expect any request body.
   */
  translation$Response(params?: Translation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return translation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `translation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  translation(params?: Translation$Params, context?: HttpContext): Observable<void> {
    return this.translation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerInvoicedespatchNoteId()` */
  static readonly DownloadControllerInvoicedespatchNoteIdPath = '/download/invoice/{despatchNoteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoice()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoice$Response(params: Invoice$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return invoice(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoice(params: Invoice$Params, context?: HttpContext): Observable<void> {
    return this.invoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerQuotationquotationId()` */
  static readonly DownloadControllerQuotationquotationIdPath = '/download/quotation/{quotationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quotation()` instead.
   *
   * This method doesn't expect any request body.
   */
  quotation$Response(params: Quotation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return quotation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `quotation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  quotation(params: Quotation$Params, context?: HttpContext): Observable<void> {
    return this.quotation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerOrderorderId()` */
  static readonly DownloadControllerOrderorderIdPath = '/download/order/{orderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `order()` instead.
   *
   * This method doesn't expect any request body.
   */
  order$Response(params: Order$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return order(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `order$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  order(params: Order$Params, context?: HttpContext): Observable<void> {
    return this.order$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerFileExistsnameBlobContentType()` */
  static readonly DownloadControllerFileExistsnameBlobContentTypePath = '/download/fileexists/{name}/{blobContentType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileExists()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileExists$Response(params: FileExists$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileExists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileExists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileExists(params: FileExists$Params, context?: HttpContext): Observable<void> {
    return this.fileExists$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerBulletinReportNewbulletinId()` */
  static readonly DownloadControllerBulletinReportNewbulletinIdPath = '/download/bulletinreportnew/{bulletinId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulletinReportNew()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulletinReportNew$Response(params: BulletinReportNew$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulletinReportNew(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulletinReportNew$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulletinReportNew(params: BulletinReportNew$Params, context?: HttpContext): Observable<void> {
    return this.bulletinReportNew$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadControllerGetMessageLogFilefilename()` */
  static readonly DownloadControllerGetMessageLogFilefilenamePath = '/download/messagelogfile/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessageLogFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageLogFile$Response(params: GetMessageLogFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getMessageLogFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMessageLogFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageLogFile(params: GetMessageLogFile$Params, context?: HttpContext): Observable<void> {
    return this.getMessageLogFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
