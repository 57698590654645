import { Injectable } from "@angular/core";
import { ApiService } from "../mibp-api-services";
import { Subscription, filter } from "rxjs";
import { ToastService } from "../toast-service/toast.service";
import { ToastType } from "../toast-service/toast.enum";
import { ActiveMibpToast } from "../toast-service/toast.service.interface";
import { DownloadedManualStatus } from "root/mibp-openapi-gen/models";
import { Route, Router } from "@angular/router";
import { BroadcastService, LogService, MibpLogger } from "..";

@Injectable({
  providedIn: 'root'
})
export class DownloadedManualsService {

  private dowloadReadyActionSub: Subscription;
  private downloadReadyToast: ActiveMibpToast;

  private exportStartedActionSub: Subscription;
  private exportStartedToast: ActiveMibpToast;

  private dowloadAddedActionSub: Subscription;
  private downloadAddedToast: ActiveMibpToast;

  private log: MibpLogger;

  constructor(private signalrApi: ApiService, private toastService: ToastService, private router: Router, private broadcast: BroadcastService, logService: LogService) {
    this.log = logService.withPrefix('downloaded-manual-service');
  }


  public showErrorStartingExport(): void {
    this.toastService.showWithTitle('DownloadedManuals_ErrorStartingExport', 'DownloadedManuals_ErrorStartingExport_Title', {
      type: ToastType.Error
    });
  }

  public showExportStartedToast(mediaName: string): void {
    const resourceString = 'DownloadedManuals_ExportQueuedNotification_SingleItem';

    if (this.exportStartedToast) {
      this.toastService.removeToast(this.exportStartedToast.toastId);
    }

    this.exportStartedToast = this.toastService.show(resourceString, {
      type: ToastType.Info,
      bodyResourceMacros: {
        name: mediaName
      },
      isHtml: true,
      buttons: [
        {
          id: 'downloads',
          textResourceString: 'DownloadedManuals_VisitDownloadsPageButton'
        }
      ]
    });

    this.exportStartedToast.onHidden.subscribe(() => {
      this.exportStartedActionSub?.unsubscribe();
      this.exportStartedToast = null;
    });

    this.exportStartedActionSub = this.exportStartedToast.onButtonClick.subscribe(() => {
      this.navigateToMyDownloads();
      if (this.exportStartedToast.toastId) {
        this.toastService.removeToast(this.exportStartedToast.toastId);
      }
    });
  }

  public hideAllToasts(): void {
    if (this.downloadAddedToast) {
      this.toastService.removeToast(this.downloadAddedToast.toastId);
    }
    if (this.downloadReadyToast) {
      this.toastService.removeToast(this.downloadReadyToast.toastId);
    }
    if (this.exportStartedToast) {
      this.toastService.removeToast(this.exportStartedToast.toastId);
    }
  }

  public showManualAddedToDownloadsToast(mediaName: string): void {
    const resourceString = 'DownloadedManuals_DownloadAdded';

    this.downloadAddedToast = this.toastService.show(resourceString, {
      type: ToastType.Info,
      bodyResourceMacros: {
        name: mediaName
      },
      isHtml: true,
      buttons: [
        {
          id: 'downloads',
          textResourceString: 'DownloadedManuals_VisitDownloadsPageButton'
        }
      ]
    });

    this.downloadAddedToast.onHidden.subscribe(() => {
      this.dowloadAddedActionSub?.unsubscribe();
      this.downloadAddedToast = null;
    });

    this.dowloadAddedActionSub = this.downloadAddedToast.onButtonClick.subscribe(() => {
      this.navigateToMyDownloads();
      if (this.downloadAddedToast.toastId) {
        this.toastService.removeToast(this.downloadAddedToast.toastId);
      }
    });

  }

  public showDownloadReadyToast(mediaName: string, status: DownloadedManualStatus = DownloadedManualStatus.Downloaded, manualCount = 1): void {
    let resourceString = 'DownloadedManuals_ExportQueuedNotification_SingleItemSuccess';

    if (status == DownloadedManualStatus.Error) {
      resourceString = 'DownloadedManuals_ExportQueuedNotification_SingleItemError';
    }

    if (this.downloadReadyToast) {
      this.toastService.removeToast(this.downloadReadyToast.toastId);
      resourceString = 'DownloadedManuals_ExportQueuedNotification_MultipleItemSuccess';


      if (status == DownloadedManualStatus.Error) {
        resourceString = 'DownloadedManuals_ExportQueuedNotification_CompletedWithFailure';
      }

    }


    this.downloadReadyToast = this.toastService.show(resourceString, {
      type: status == DownloadedManualStatus.Downloaded ? ToastType.Success : ToastType.Warning,
      bodyResourceMacros: {
        name: mediaName
      },
      isHtml: true,
      buttons: [
        {
          id: 'downloads',
          textResourceString: 'DownloadedManuals_VisitDownloadsPageButton'
        }
      ]
    });

    this.downloadReadyToast.onHidden.subscribe(() => {
      this.dowloadReadyActionSub?.unsubscribe();
      this.downloadReadyToast = null;
    });

    this.dowloadReadyActionSub = this.downloadReadyToast.onButtonClick.subscribe((b) => {
      this.navigateToMyDownloads();
      if (this.downloadReadyToast.toastId) {
        this.toastService.removeToast(this.downloadReadyToast.toastId);
      }
    });
  }


  public startListeningToEvents(): void {
    this.log.info('ahahaha');
    // User targeted events
    this.signalrApi.EventHub.ManualDownloadCompletedUser
      .pipe(filter(e=> e != null))
      .subscribe(e => {
        this.log.info('ManualDownloadCompletedUser received', e);
        this.showDownloadReadyToast(e.mediaName, (<any>e.status as DownloadedManualStatus));
      });

    this.signalrApi.EventHub.ManualPackageCompletedUser
      .pipe(filter(e=> e != null))
      .subscribe(e => {
        this.log.info('ManualPackageCompletedUser received', e);
        this.showDownloadReadyToast(e.customName, (<any>e.status as DownloadedManualStatus));
      });

    // General events sent to all users with this feature enabled
    this.signalrApi.EventHub.ManualDownloadStatusUpdate
      .pipe(filter(e=> e != null))
      .subscribe(e => {
        this.log.info('ManualDownloadStatusUpdate received', e);
      });

    this.signalrApi.EventHub.ManualDownloadedPackageStatus
      .pipe(filter(e=> e != null))
      .subscribe(e => {
        this.log.info('ManualDownloadStatusUpdate received', e);
      });

  }

  public navigateToMyDownloads(): void {

    this.router.navigate([`/${this.broadcast.snapshot.language}/myfleet/downloaded-manuals`]);

  }

}
