import { OnDestroy, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FrontendContextService } from '../../services/front-end-context/front-end-context.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { ButtonIcons, ButtonColors, ButtonStyles } from './button.enum';

@Component({
  selector: 'mibp-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnDestroy, OnChanges {
  isDisabled: boolean;
  styleInternal: ButtonStyles = ButtonStyles.Normal;
  iconInternal: ButtonIcons = ButtonIcons.None;
  protected iconName: string;
  protected faIconSize: string;
  protected buttonClass: string;

  isConnectedSubscription: Subscription;
  isConnected = false;
  hyperlinkUrl: string = null;
  isHyperlink = false;
  colorValue: ButtonColors = ButtonColors.Blue;

  // tslint:disable-next-line:variable-name
  public Enum = {
    Icons: ButtonIcons,
    Styles: ButtonStyles,
    Colors: ButtonColors
  };

  @Input() automatedTestId?: string;
  @Input() resourceStringKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() resourceStringMacros: { [ key: string ]: any };
  @Input() tabindex?: number;
  @Input() fallbackText: string;
  @Input()
  set color(color: ButtonColors) {
    this.colorValue = color;
    this.refreshButton();
  }

  @Input()
  set displayBlock(value: boolean) {
    this.displayBlockValue = value;

    if (this.elm.nativeElement.parentElement) {
      this.elm.nativeElement.parentElement.style.display =  value === true ? 'block' : null;
    }

    this.refreshButton();
  }
  get displayBlock(): boolean {
    return this.displayBlockValue;
  }

  @Input()
  set style(value: ButtonStyles) {
    this.styleInternal = value;
    this.refreshButton();
  }
  get style(): ButtonStyles {
    return this.styleInternal;
  }
  @Input()
  set icon(value: ButtonIcons) {
    this.iconInternal = value;
    this.refreshButton();
  }
  get icon(): ButtonIcons {
    return this.iconInternal;
  }
  @Input() ignoreDisconnectedStatus = true;
  @Input() isBig: boolean;
  @Input() isSmall: boolean;
  displayBlockValue: boolean;
  @Input() minWidth: number;
  @Input() type: "button";
  @Input()
  set href(value: string) {
    if (typeof value !== 'undefined') {
      this.hyperlinkUrl = value;
      this.isHyperlink = true;
    } else {
      this.isHyperlink = false;
    }
  }

  @Input()
  set disabled(disabled: boolean) {
    this.isDisabled = disabled;
    this.refreshButton();
  }

  get disabled(): boolean {
    return this.isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.automatedTestId) {
      if (this.elm?.nativeElement) {
        if (changes.automatedTestId) {
          (this.elm?.nativeElement as HTMLElement).setAttribute('data-aut', changes.automatedTestId.currentValue);
        } else {
          (this.elm?.nativeElement as HTMLElement).removeAttribute('data-aut');
        }
      }
    }
  }

  onClick(event: Event): boolean {
    if (this.isDisabled) {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }

  constructor(private frontEndContext: FrontendContextService, private elm: ElementRef) {}


  generateButtonClass(): string {
    const classNames = ['button'];
    this.faIconSize = 'xs';

    if (this.icon === ButtonIcons.None) {
      classNames.push('button--noicon');
    } else {
      classNames.push('button--icon');
      if(this.icon === ButtonIcons.Eye || this.icon === ButtonIcons.EyeSlash){
        classNames.push('button--toggleicon');
      }
    }

    if (!this.resourceStringKey) {
      classNames.push('button--icon-only');
    }

    if (this.displayBlock) {
      classNames.push('button--block');
    }

    if (this.disabled) {
      classNames.push('disabled');
    }

    switch (this.colorValue) {
    case ButtonColors.Primary:
      classNames.push('primary');
      break;
    case ButtonColors.Secondary:
      classNames.push('secondary');
      break;
    case ButtonColors.Red:
      classNames.push('negative');
      break;
    case ButtonColors.Success:
      classNames.push('success');
      break;
    default:
      if (this.icon === ButtonIcons.None) {
        classNames.push('primary');
      }
      break;
    }

    if (this.isBig) {
      classNames.push('button-big');
      this.faIconSize = '1x';
    } else if (this.isSmall) {
      classNames.push('button-small');
    }

    switch (this.style) {
    case ButtonStyles.Normal:
      classNames.push('button-style-2');
      break;
    case ButtonStyles.Fill:
      classNames.push('button-style-3');
      break;
    case ButtonStyles.BeforeInput:
      classNames.push('before-input button-style-2');
      break;
    case ButtonStyles.AfterInput:
      classNames.push('after-input button-style-2');
      break;
    }

    return classNames.join(' ');
  }


  getMaterialIconName(): string | null {

    switch (this.icon) {
    case ButtonIcons.ChevronCircleRight:
      return 'expand-circle-right';
    case ButtonIcons.Plus:
      return 'add';
    case ButtonIcons.Minus:
      return 'remove';
    case ButtonIcons.Trash:
      return 'delete';
    case ButtonIcons.FolderOpen:
      return 'folder_open';
    case ButtonIcons.Print:
      return 'print';
    case ButtonIcons.CheckCircle:
      return 'task_alt';
    case ButtonIcons.Edit:
      return 'edit';
    case ButtonIcons.AddToCart:
      return 'expand-circle-right';
    case ButtonIcons.Undo:
      return 'undo';
    case ButtonIcons.Download:
      return 'download';
    case ButtonIcons.Copy:
      return 'content_copy';
    case ButtonIcons.ScopePreview:
      return 'preview';
    case ButtonIcons.Save:
      return 'save';
    case ButtonIcons.ChevronDown:
      return 'expand_more';
    case ButtonIcons.Eye:
      return 'visibility';
    case ButtonIcons.EyeSlash:
      return 'visibility_off';
    default:
      return null;
    }
  }

  ngOnInit(): void {
    this.isConnectedSubscription = this.frontEndContext.IsConnected$.subscribe(isConnected => this.isConnected = this.ignoreDisconnectedStatus ? true : isConnected);
    this.refreshButton();
    (this.elm.nativeElement as HTMLElement).setAttribute('tabindex', '-1');
  }

  ngOnDestroy(): void {
    if (this.isConnectedSubscription) {
      this.isConnectedSubscription.unsubscribe();
    }
  }

  refreshButton(): void {
    this.buttonClass = this.generateButtonClass();
    this.iconName = this.getMaterialIconName();
  }

}
