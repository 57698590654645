import { FrontendContextService } from '../front-end-context/front-end-context.service';
import { MibpLogger, LogService } from '../logservice';

import { Injectable } from "@angular/core";

export interface ImageThumbnailSizeOptions {
  fitWidth: number;
}

@Injectable({
  providedIn: 'root'
})
export class ImageThumbailService {

  log: MibpLogger;

  constructor(private context: FrontendContextService, logger: LogService) {
    this.log = logger.withPrefix('image-thumbnail-service');
  }

  public async previewFromFile(file: File, options?: ImageThumbnailSizeOptions): Promise<string> {
    const self = this;
    this.log.debug(`${file.name} Creating thumbnail image from file (${file.size} bytes)`);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();



      reader.onload = function() {
        self.previewFromBase64(reader.result as string, options).then(result => resolve(result), err => reject(err));
      };

      reader.onerror = err => { this.log.debug(`${file.name} Error when reading file)`); reject(err); };
      reader.onabort = err => { this.log.debug(`${file.name} File read was aborted)`); reject(err); };

      reader.readAsDataURL(file);

    });

  }


  public async readFileAsBase64(file: File): Promise<string> {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function() {
        resolve(reader.result as string);
      };

      reader.onerror = err => { this.log.debug(`${file.name} Error when reading file)`); reject(err); };
      reader.onabort = err => { this.log.debug(`${file.name} File read was aborted)`); reject(err); };

      reader.readAsDataURL(file);
    });
  }

  public async previewFromBase64(base64String: string, options?: ImageThumbnailSizeOptions  ): Promise<string> {
    const self = this;
    let maxWidth = 300;
    this.log.debug(`Creating thumbnail from base64 string (fit width ${options?.fitWidth || maxWidth})`);

    return new Promise((resolve, reject) => {
      if (!base64String.startsWith('data:')) {
        //let imageBase64String= atob(base64String);
        this.log.debug(`Adding missing data url prefix`);
        base64String = `data:image/jpeg;base64,` + base64String;
      }

      const id = this.context.newRandomIdString();
      const image = new Image();

      image.onload = function (imageEvent) {
        self.log.debug(`Base64 Image url was loaded`);
        if (options?.fitWidth) {
          maxWidth = options.fitWidth;
        }

        self.log.debug(`Creating temporary canvas element`);
        const canvas = document.createElement('canvas');
        canvas.setAttribute('id', id);
        canvas.style.position = 'absolute';
        canvas.style.top = '150px';
        document.body.appendChild(canvas);
        const maxSize = maxWidth;
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const resizedImage = canvas.toDataURL('image/jpeg');
        resolve(resizedImage);
        self.log.debug(`Removing temporary canvas element`);
        document.body.removeChild(document.getElementById(id));

      };
      image.onerror = (e: any) => { reject(e); };
      image.src = base64String;
    });

  }

}
