/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CrossSellForEditingDto } from '../models/cross-sell-for-editing-dto';
import { delete$ } from '../fn/cross-sells/delete';
import { Delete$Params } from '../fn/cross-sells/delete';
import { getCrossSellProductDetails } from '../fn/cross-sells/get-cross-sell-product-details';
import { GetCrossSellProductDetails$Params } from '../fn/cross-sells/get-cross-sell-product-details';
import { getCrossSellProductDetails$Plain } from '../fn/cross-sells/get-cross-sell-product-details-plain';
import { GetCrossSellProductDetails$Plain$Params } from '../fn/cross-sells/get-cross-sell-product-details-plain';
import { getCrossSellProductIdsFromActiveCart } from '../fn/cross-sells/get-cross-sell-product-ids-from-active-cart';
import { GetCrossSellProductIdsFromActiveCart$Params } from '../fn/cross-sells/get-cross-sell-product-ids-from-active-cart';
import { getCrossSellProductIdsFromActiveCart$Plain } from '../fn/cross-sells/get-cross-sell-product-ids-from-active-cart-plain';
import { GetCrossSellProductIdsFromActiveCart$Plain$Params } from '../fn/cross-sells/get-cross-sell-product-ids-from-active-cart-plain';
import { getForEditing } from '../fn/cross-sells/get-for-editing';
import { GetForEditing$Params } from '../fn/cross-sells/get-for-editing';
import { getForEditing$Plain } from '../fn/cross-sells/get-for-editing-plain';
import { GetForEditing$Plain$Params } from '../fn/cross-sells/get-for-editing-plain';
import { getGlobalProductIdsForRelatedProducts } from '../fn/cross-sells/get-global-product-ids-for-related-products';
import { GetGlobalProductIdsForRelatedProducts$Params } from '../fn/cross-sells/get-global-product-ids-for-related-products';
import { getGlobalProductIdsForRelatedProducts$Plain } from '../fn/cross-sells/get-global-product-ids-for-related-products-plain';
import { GetGlobalProductIdsForRelatedProducts$Plain$Params } from '../fn/cross-sells/get-global-product-ids-for-related-products-plain';
import { getGlobalProductIdsForTargetProducts } from '../fn/cross-sells/get-global-product-ids-for-target-products';
import { GetGlobalProductIdsForTargetProducts$Params } from '../fn/cross-sells/get-global-product-ids-for-target-products';
import { getGlobalProductIdsForTargetProducts$Plain } from '../fn/cross-sells/get-global-product-ids-for-target-products-plain';
import { GetGlobalProductIdsForTargetProducts$Plain$Params } from '../fn/cross-sells/get-global-product-ids-for-target-products-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { save } from '../fn/cross-sells/save';
import { Save$Params } from '../fn/cross-sells/save';
import { save$Plain } from '../fn/cross-sells/save-plain';
import { Save$Plain$Params } from '../fn/cross-sells/save-plain';
import { searchGrid } from '../fn/cross-sells/search-grid';
import { SearchGrid$Params } from '../fn/cross-sells/search-grid';
import { searchGrid$Plain } from '../fn/cross-sells/search-grid-plain';
import { SearchGrid$Plain$Params } from '../fn/cross-sells/search-grid-plain';
import { UpSellCrossSellProductRequestResponseDto } from '../models/up-sell-cross-sell-product-request-response-dto';

@Injectable({ providedIn: 'root' })
export class CrossSellsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `crossSellsControllerSavecrossSellSaveDto()` */
  static readonly CrossSellsControllerSavecrossSellSaveDtoPath = '/crosssells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Plain$Response(params?: Save$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return save$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Plain(params?: Save$Plain$Params, context?: HttpContext): Observable<number> {
    return this.save$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<number> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `crossSellsControllerGetForEditingid()` */
  static readonly CrossSellsControllerGetForEditingidPath = '/crosssells/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Plain$Response(params: GetForEditing$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossSellForEditingDto>> {
    return getForEditing$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Plain(params: GetForEditing$Plain$Params, context?: HttpContext): Observable<CrossSellForEditingDto> {
    return this.getForEditing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossSellForEditingDto>): CrossSellForEditingDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditing()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Response(params: GetForEditing$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossSellForEditingDto>> {
    return getForEditing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing(params: GetForEditing$Params, context?: HttpContext): Observable<CrossSellForEditingDto> {
    return this.getForEditing$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossSellForEditingDto>): CrossSellForEditingDto => r.body)
    );
  }

  /** Path part for operation `crossSellsControllerDeleteid()` */
  static readonly CrossSellsControllerDeleteidPath = '/crosssells/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `crossSellsControllerGetCrossSellProductDetailscrossSellProductDetailsRequestDto()` */
  static readonly CrossSellsControllerGetCrossSellProductDetailscrossSellProductDetailsRequestDtoPath = '/crosssells/productdetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCrossSellProductDetails$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCrossSellProductDetails$Plain$Response(params?: GetCrossSellProductDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>> {
    return getCrossSellProductDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCrossSellProductDetails$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCrossSellProductDetails$Plain(params?: GetCrossSellProductDetails$Plain$Params, context?: HttpContext): Observable<Array<UpSellCrossSellProductRequestResponseDto>> {
    return this.getCrossSellProductDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>): Array<UpSellCrossSellProductRequestResponseDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCrossSellProductDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCrossSellProductDetails$Response(params?: GetCrossSellProductDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>> {
    return getCrossSellProductDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCrossSellProductDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCrossSellProductDetails(params?: GetCrossSellProductDetails$Params, context?: HttpContext): Observable<Array<UpSellCrossSellProductRequestResponseDto>> {
    return this.getCrossSellProductDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>): Array<UpSellCrossSellProductRequestResponseDto> => r.body)
    );
  }

  /** Path part for operation `crossSellsControllerGetCrossSellProductIdsFromActiveCart()` */
  static readonly CrossSellsControllerGetCrossSellProductIdsFromActiveCartPath = '/crosssells/productids/by-active-cart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCrossSellProductIdsFromActiveCart$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCrossSellProductIdsFromActiveCart$Plain$Response(params?: GetCrossSellProductIdsFromActiveCart$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return getCrossSellProductIdsFromActiveCart$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCrossSellProductIdsFromActiveCart$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCrossSellProductIdsFromActiveCart$Plain(params?: GetCrossSellProductIdsFromActiveCart$Plain$Params, context?: HttpContext): Observable<Array<number>> {
    return this.getCrossSellProductIdsFromActiveCart$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCrossSellProductIdsFromActiveCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCrossSellProductIdsFromActiveCart$Response(params?: GetCrossSellProductIdsFromActiveCart$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return getCrossSellProductIdsFromActiveCart(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCrossSellProductIdsFromActiveCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCrossSellProductIdsFromActiveCart(params?: GetCrossSellProductIdsFromActiveCart$Params, context?: HttpContext): Observable<Array<number>> {
    return this.getCrossSellProductIdsFromActiveCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `crossSellsControllerGetGlobalProductIdsForTargetProductstargetProductCodes()` */
  static readonly CrossSellsControllerGetGlobalProductIdsForTargetProductstargetProductCodesPath = '/crosssells/productids/by-target-product-codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalProductIdsForTargetProducts$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGlobalProductIdsForTargetProducts$Plain$Response(params?: GetGlobalProductIdsForTargetProducts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getGlobalProductIdsForTargetProducts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalProductIdsForTargetProducts$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGlobalProductIdsForTargetProducts$Plain(params?: GetGlobalProductIdsForTargetProducts$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getGlobalProductIdsForTargetProducts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalProductIdsForTargetProducts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGlobalProductIdsForTargetProducts$Response(params?: GetGlobalProductIdsForTargetProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getGlobalProductIdsForTargetProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalProductIdsForTargetProducts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGlobalProductIdsForTargetProducts(params?: GetGlobalProductIdsForTargetProducts$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getGlobalProductIdsForTargetProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `crossSellsControllerGetGlobalProductIdsForRelatedProductsrelatedProductCodes()` */
  static readonly CrossSellsControllerGetGlobalProductIdsForRelatedProductsrelatedProductCodesPath = '/crosssells/productids/by-related-product-codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalProductIdsForRelatedProducts$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGlobalProductIdsForRelatedProducts$Plain$Response(params?: GetGlobalProductIdsForRelatedProducts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getGlobalProductIdsForRelatedProducts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalProductIdsForRelatedProducts$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGlobalProductIdsForRelatedProducts$Plain(params?: GetGlobalProductIdsForRelatedProducts$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getGlobalProductIdsForRelatedProducts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalProductIdsForRelatedProducts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGlobalProductIdsForRelatedProducts$Response(params?: GetGlobalProductIdsForRelatedProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getGlobalProductIdsForRelatedProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalProductIdsForRelatedProducts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGlobalProductIdsForRelatedProducts(params?: GetGlobalProductIdsForRelatedProducts$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getGlobalProductIdsForRelatedProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `crossSellsControllerSearchGridoptionsLanguageCode()` */
  static readonly CrossSellsControllerSearchGridoptionsLanguageCodePath = '/crosssells/searchgrid';

  /**
   * List cross sell in admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain$Response(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * List cross sell in admin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * List cross sell in admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Response(params?: SearchGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * List cross sell in admin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid(params?: SearchGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

}
