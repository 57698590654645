import { Component, Input } from "@angular/core";
import { NoticebarService } from "root/services";
import { NoticeType } from "../noticebar/noticebar.enum";
/**
 * Simple component with a textbox value and a copy-to-clipboard button
 * so user can easily copy the value to the clipboard
 */
@Component({
  selector: 'mibp-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss']
})
export class MibpCopyToClipboardComponent {

  constructor(private noticeBar: NoticebarService) {}

  @Input() value: string;

  protected copyToClipboard(): void {

    try {
      const input = document.createElement('textarea');
      input.style.opacity = '0';
      input.style.position = 'absolute';
      input.style.zIndex = '0';
      input.value = this.value;
      document.body.appendChild(input);

      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      document.body.removeChild(input);

      this.noticeBar.showText('Copied to Clipboard', NoticeType.Success, false);
    } catch(e) {
      this.noticeBar.showText('Error copying to Clipboard', NoticeType.Error, false);
    }
  }

}
