import { Component, Input, OnInit } from '@angular/core';
import { TabsComponent } from './tabs.component';

@Component({
  selector: 'mibp-tab',
  template: `
  <div [attr.id]="'tab-' + tabIndex" class="tab-content" style="padding-top: 12px;"
  [class.hidden-tab]="!active" role="tabpanel" aria-labelledby="tabnav-2" [attr.aria-hidden]="active ? 'false' : 'true'">
    <h2 class="tab-heading" style="display: none;">Tab {{tabIndex}} / {{tabCount}}</h2>
    <ng-content></ng-content>
  </div>
  `
})
export class TabComponent implements OnInit {
  @Input() active = false;
  @Input() resourceKey: string;
  @Input() text: string;
  @Input() counterValue: number;
  @Input() hideTab = false;
  public tabIndex: number;
  public tabCount: number;

  constructor(private tabsComponent: TabsComponent) {
    this.tabsComponent.addTab(this);
  }


  ngOnInit(): void {
    this.tabsComponent.updateTabVisibility(this, this.hideTab);
  }

}
