import { EmbeddedViewRef } from '@angular/core';
import { Component, Input, ViewChild, ChangeDetectorRef, TemplateRef, Injector, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { LogService, MibpLogger } from 'root/services';
import { LoaderService } from 'root/services/loader/loader.service';
import {  Subscription } from 'rxjs';

@Component({
  selector: 'mibp-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})


export class LoaderComponent implements OnInit, OnDestroy {

  showLoaderValue: boolean;
  embeddedViewFull: EmbeddedViewRef<any>;
  embeddedViewCover: EmbeddedViewRef<any>;
  log: MibpLogger;
  isFullscreenComponent = false;
  isFullscreenLoaderVisible = false;
  visibleFullscreenLoaders = 0;
  fullScreenSubscription: Subscription;

  @Input() type: 'loader' | 'spinner' | 'progressbar' | 'indeterminate-progressbar' | 'fullscreen' | 'cover' = 'loader';
  @Input() isFullscreenGlobal = false;
  @Input() resourceStringKey: string;
  @Input() percent: number;
  @Input() disabledBackgroundColor = false;
  @Input() destoryOnHide = true; /* if ya wanna keep fullscreen/cover template when hide to reuse */
  @ViewChild('fullscreenTemplate', {static: true}) fullscreenTemplate: TemplateRef<any>;

  constructor(logger: LogService,
    private loaderService: LoaderService, private injector: Injector, private viewContainerRef: ViewContainerRef, private changeDetector: ChangeDetectorRef) {
    this.log = logger.withPrefix('loader.component');
  }

  ngOnInit() {
    if (this.isFullscreenGlobal || this.type === 'fullscreen') {
      this.type = 'fullscreen';
      this.isFullscreenComponent = !this.loaderService.HasFullscreenSubscribers();
      if (!this.isFullscreenComponent) {
        this.loaderService.showFullScreenLoader();
      } else {
        this.moveTemplateToBody();
        // Listen for fullscreen events from the service
        this.fullScreenSubscription = this.loaderService.ShowFullscreen$.subscribe(show => {
          this.log.debug("fullscreenevent", show);
          if (show) {
            this.showFullScreenLoader();
          } else {
            this.hideFullScreenLoader();
          }
        });
        if (!this.isFullscreenGlobal) {
          this.showFullScreenLoader();
        }
      }
    }
  }

  private showFullScreenLoader() {
    if (this.isFullscreenComponent) {
      setTimeout(() => {
        this.visibleFullscreenLoaders++;
        this.isFullscreenLoaderVisible = true;
      });
    } else {
      this.loaderService.showFullScreenLoader();
    }
  }

  private hideFullScreenLoader() {
    if (this.isFullscreenComponent) {
      setTimeout(() => {
        this.visibleFullscreenLoaders--;
        if (this.visibleFullscreenLoaders < 0) {
          this.visibleFullscreenLoaders = 0;
        }
        if (this.visibleFullscreenLoaders === 0) {
          this.isFullscreenLoaderVisible = false;
        }
      });
    } else {
      this.loaderService.hideFullScreenLoader();
    }
  }

  ngOnDestroy() {
    if (this.fullScreenSubscription) {
      this.fullScreenSubscription.unsubscribe();
    }
    if (this.type === 'fullscreen') {
      if (!this.isFullscreenComponent) {
        // The component did not register as the full global full screen loader component
        this.log.warn("OK, this is the secondary fullscreen loader. Invoking service to hide loader...");
        this.loaderService.hideFullScreenLoader();
      } else {
        if (this.embeddedViewFull != null) {
          this.embeddedViewFull.destroy();
        }
      }
    }

  }
  // /**
  //  * Move the 'screenLoaderTemplate' element (see template) to the body
  //  * This is done by creating an EmbeddedView and moving its elements to the body element
  //  * Using the embeddedView, all angular bindings will remain and work as usual even if the
  //  * elements are moved somewhere else
  //  * https://angular.io/api/core/ViewContainerRef#createembeddedview
  //  */
  private moveTemplateToBody(): void {
    const bodyElement = document.querySelector('body');
    this.embeddedViewFull = this.viewContainerRef.createEmbeddedView(this.fullscreenTemplate);
    this.embeddedViewFull.rootNodes.forEach(rootNode => bodyElement.appendChild(rootNode));
  }
}
