import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mibp-footer',
  templateUrl: './footer.component.html',
  styleUrls: [`./footer.component.scss`]
})
export class FooterComponent implements OnInit, OnDestroy {

  public lang = 'en';
  private langParamSubscription: Subscription;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.langParamSubscription = this.route.paramMap.subscribe( paramMap => this.setLangugage(paramMap.get('lang')));
  }

  private setLangugage(language: string) {
    switch (language) {
    case 'es': {
      this.lang = 'es-la';
      break;
    }
    case 'pt': {
      this.lang = 'pt-br';
      break;
    }
    case 'zh': {
      this.lang = 'zh-cn';
      break;
    }
    default: {
      this.lang = language;
      break;
    }
    }
  }

  ngOnDestroy() {
    if (this.langParamSubscription) {
      this.langParamSubscription.unsubscribe();
    }
  }

}
