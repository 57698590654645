// import { ExtendedGlobalConfig } from './../global-config/global-config.types';
import { Injectable } from "@angular/core";
import { BroadcastService } from "../broadcast-service/broadcast.service";
import { ApplicationState, ApplicationStates } from "./application-state.types";
import { fromEvent, take } from "rxjs";
import { AuthService } from "../auth-service";
import { LoaderService } from "../loader/loader.service";
import { ClientSideCacheService } from "../client-side-cache/client-side-cache.service";
import { MsalService } from "@azure/msal-angular";

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {

  stateElement: HTMLDivElement;

  timestamps: Date[] = [];
  isLoaded = false;

  protected translations = {
    'general-error-title': { value: null,  resourceKey: 'General_Error_InlineTitle', fallbackText: 'Whoops! Something has gone wrong' },
    'load-session': { value: null,  resourceKey: 'x', fallbackText: 'Could not load your account information' },
    'load-resources': { value: null, resourceKey: 'x', fallbackText: 'Could not load translations' },
    'what-to-do-now': { value: null, resourceKey: 'Global_Error_WhatToDoNow', fallbackText: 'Try the following to see if the problem resolves itself'},
    'reload-page': {value: null, resourceKey: 'Global_Error_ReloadSuggestion', fallbackText: 'Reload the page to try again'},
    'try-later': { value: null, resourceKey: 'Global_Error_TryLaterSuggestion', fallbackText: 'Wait and try later'},
    'login-again': { value: null, resourceKey: 'AppLoad_ClearCacheAndReload', fallbackText: '[Clear cache] and reload' },
    'contact-support': { value: null, resourceKey: 'Global_Error_ContactSupport', fallbackText: 'Contact support if the problem remains'},
    'login-failed-title': { value: null, resourceKey: 'AppLoad_LoginError', fallbackText: 'Could not log you in'},
    'config-load-error-title': { value: null, resourceKey: 'AppLoad_LoadConfigError', fallbackText: 'Could not load configuration'}
  };

  public getTranslationsForApplicationLoad(): any {
    Object.keys(this.translations).forEach(key => {
      this.translations[key].value = this.tryGetTranslatedText(this.translations[key].resourceKey, this.translations[key].fallbackText);
    });

    if (this.translations['login-again']) {
      this.translations['login-again'].value = this.translations['login-again'].value.replace(/(.*)\[(.*?)\](.*)/, '$1<a href="javascript:void(0)" class="clear-cache-reload">$2</a>$3');
    }
    return this.translations;
  }

  constructor(private broadcastService: BroadcastService, private msalService: MsalService, private cacheService: ClientSideCacheService) {}

  private get element(): HTMLDivElement {

    if (this.stateElement) {
      return this.stateElement;
    }

    this.stateElement = document.getElementById('application-state') as HTMLDivElement;

    if (!this.stateElement) {

      this.stateElement = document.createElement('div') as HTMLDivElement;
      this.stateElement.style.position = 'fixed';
      this.stateElement.style.top = '10px';
      this.stateElement.style.zIndex = '10000';
      this.stateElement.style.left = '20px';
      this.stateElement.style.backgroundColor = '#fff';
      this.stateElement.style.border = '1px solid black';

      return document.body.appendChild(this.stateElement);

    }

    return this.stateElement;

  }

  public setGlobalConfig(config: any): void {
    this.broadcastService.setGlobalConfig(config);
  }

  public setState(state: ApplicationState): void {

    if (state.state === ApplicationStates.UnhandledException) {
      this.showErrorIfAppIsNotLoadedYet(state);
    }
    else if(state.state === ApplicationStates.Offline) {
      this.showErrorIfAppOffline(state);
    }else {
      this.showProgressIfAppIsNotLoadedYet(state);
    }

    // if (state.state === ApplicationStates.Loaded) {
    //   this.isLoaded = true;
    // }

    if (this.isLoaded && state.state !== ApplicationStates.UnhandledException) {
      return;
    }

    this.broadcastService.setApplicationState(state);
  }

  public get currentState(): ApplicationState {
    return this.broadcastService.snapshot.applicationState;
  }

  private get nativeLoaderElement(): HTMLElement {
    return document.querySelector('ecommerce-app .native-loader');
  }

   /**
   * index.html contains a helper method to get any loaded resources outside of angular
   * Here we try to use that function to load translations before we've loaded resource strings
   * @param resourceString
   * @param fallback
   * @returns
   */
   tryGetTranslatedText(resourceString: string, fallback: string): string {
    return window['tryGetResourceString'](resourceString) || fallback;
  }

  private showProgressIfAppIsNotLoadedYet(state: ApplicationState): void {
    this.setProgressText(state);
  }

  private setProgressText(state: ApplicationState): void {
    const elm = this.nativeLoaderElement?.querySelector('.my-app-state__info h3');
    if (elm) {
      elm.textContent = window['tryGetResourceString'](state.resourceStringKey) || state.textFallback;
    }
  }

  /***
   * Most errors are shown using router-loading component
   * But if the angular app has not started, and we get a critical error
   * then this function will make sure to show a message to the user
   */
  private showErrorIfAppIsNotLoadedYet(state: ApplicationState): void {
    const nativeLoader = document.querySelector('ecommerce-app .native-loader') as HTMLElement;
    const toolbar = document.querySelector('.psuedo-toolbar');
    const loader = nativeLoader?.querySelector('.loader') as HTMLElement;
    if (nativeLoader) {
      nativeLoader.style.background = 'none';
      if (toolbar) {
        toolbar.parentElement.removeChild(toolbar);
      }
      // if (loader) {
      //   loader.style.marginTop = '100px';
      // }



      // alert("fel");
      nativeLoader.querySelector('.my-app-state__icon img')?.setAttribute('src', 'assets/images/error.svg');
      nativeLoader.querySelector('.my-app-state__info h2')?.classList?.add('has-error');
      const h3 = nativeLoader.querySelector('.my-app-state__info h3') as HTMLElement;
      if (h3) {
        if (state?.exception?.message?.includes('AADB')) {
          h3.innerText = 'My Sandvik B2C returned an error';
        } else {

          h3.innerText = window['tryGetResourceString'](state.resourceStringKey) || state.textFallback || 'An unhandled error occured';
        }
      }

      if (state?.internalStatus == 'appload.globalconfig') {
        this.createNewNativeErrorMessage(state);
      } else if (state?.internalStatus === 'b2c.msal.error') {
        this.createNewNativeErrorMessage(state);


/*
  <p>{{translations['what-to-do-now'].value}}</p>
  <ul class="my-list">
    <li>{{translations['reload-page'].value}}</li>
    <li>{{translations['try-later'].value}}</li>
    <li (click)="clearCacheClick($event)" [innerHTML]="translations['login-again']"></li>
    <li>{{translations['contact-support'].value}}</li>
  </ul>
*/



      } else if (state.exception?.message) {
        const stack = document.createElement('div');
        stack.classList.add('.my-app-state__trace');
        stack.innerText = state.exception?.message;

        if (state.exception?.rejection) {
          stack.innerText = state?.exception?.rejection.errorMessage;
        }
        nativeLoader.querySelector('.my-app-state__info').appendChild(stack);
      }
    }
  }

  private createNewNativeErrorMessage(state: ApplicationState) {
    const translations = this.getTranslationsForApplicationLoad();

    (document.querySelector('.my-app-state__icon') as HTMLHeadingElement).innerHTML = '<img src="assets/images/error.svg" width="48" alt="[Error]">';
    (document.querySelector('.my-app-state__info') as HTMLHeadingElement).innerHTML = '';


    if (state?.internalStatus === 'b2c.msal.error') {
      const h2 = document.createElement('h2');
      h2.classList.add('has-error');
      h2.innerText = translations['login-failed-title'].value;
      document.querySelector('.my-app-state__info').appendChild(h2);

      const pErrCode = document.createElement('p');
      pErrCode.innerText =  state.exception?.message ? `Login error code: ` + state.exception?.message : 'An unexpected error occured';
      document.querySelector('.my-app-state__info').appendChild(pErrCode);
    } else if (state?.internalStatus == 'appload.globalconfig') {
      const h2 = document.createElement('h2');
      h2.classList.add('has-error');
      h2.innerText = translations['config-load-error-title'].value;
      document.querySelector('.my-app-state__info').appendChild(h2);

      // const pErrCode = document.createElement('p');
      // pErrCode.innerText =  state.exception?.message ? `Login error code: ` + state.exception?.message : 'An unexpected error occured';
      // document.querySelector('.my-app-state__info').appendChild(pErrCode);
    }

    const p = document.createElement('p');
    p.innerText = translations['what-to-do-now'].value;
    document.querySelector('.my-app-state__info').appendChild(p);

    const ul = document.createElement('ul');
    ul.classList.add('my-list');

    const liReloadSuggestion = document.createElement('li');
    const liTryLater = document.createElement('li');
    const liClearCache = document.createElement('li');
    const liContactSupport = document.createElement('li');

    liReloadSuggestion.innerText = translations['reload-page'].value;
    liTryLater.innerText = translations['try-later'].value;
    liClearCache.innerHTML = translations['login-again'].value;
    liContactSupport.innerText = translations['contact-support'].value;

    ul.appendChild(liReloadSuggestion);
    ul.appendChild(liTryLater);
    ul.appendChild(liClearCache);
    ul.appendChild(liContactSupport);

    document.querySelector('.my-app-state__info').appendChild(ul);


    const pClientid = document.createElement('p');
    pClientid.classList.add('appload-error-client-id');
    pClientid.innerText = this.broadcastService.snapshot.clientId;
    document.querySelector('.my-app-state__info').appendChild(pClientid);

    fromEvent(liClearCache, 'click').pipe(take(1)).subscribe(e => {
      if ((e.target as HTMLElement).nodeName === 'A') {

        // Create a simple fullscreen loader...
        const loader = document.createElement('div');
        loader.style.backgroundColor = 'rgba(255,255,255, 0.5)';
        loader.style.position = 'fixed';
        loader.style.top = '0';
        loader.style.left = '0';
        loader.style.width = '100%';
        loader.style.height = '100%';
        loader.style.zIndex = '60000';
        loader.style.cursor = 'wait';
        document.body.appendChild(loader);
        this.clearAllCacheAndReload();
      }
    });

  }

  public clearAllCacheAndReload(): void {
    this.cacheService.clearEverything();
    if (this.msalService.instance["browserStorage"]) {
      this.msalService.instance["browserStorage"].clear();
    }
    window.location.reload();
  }

  private showErrorIfAppOffline(state: ApplicationState): void {
    const nativeLoader = document.querySelector('ecommerce-app .native-loader') as HTMLElement;
    const toolbar = document.querySelector('.psuedo-toolbar');
    const loader = nativeLoader?.querySelector('.loader') as HTMLElement;
    if (nativeLoader) {
      nativeLoader.style.background = 'none';
      if (toolbar) {
        toolbar.parentElement.removeChild(toolbar);
      }
      // if (loader) {
      //   loader.style.marginTop = '100px';
      // }



      // alert("fel");
      nativeLoader.querySelector('.my-app-state__icon img')?.setAttribute('src', 'assets/images/logo_black.svg');
      nativeLoader.querySelector('.my-app-state__icon')?.classList?.add('offline');
      nativeLoader.querySelector('.my-app-state__icon img')?.classList?.add('offline');
      nativeLoader.querySelector('.my-app-state__content')?.classList?.add('offline');
      nativeLoader.querySelector('.my-app-state__info h2')?.remove();
      const button = document.createElement("button");
      button.innerHTML = "TRY AGAIN";
      button.style.marginTop = '50px';
      button.classList.add('my-button','my-button--fill','btn-min-widht');
      button.addEventListener ("click", function() {
        window.location.reload();
      });
      const h3 = nativeLoader.querySelector('.my-app-state__info h3') as HTMLElement;
      if (h3) {
        if (state?.exception?.message?.includes('AADB')) {
          h3.innerText = 'My Sandvik B2C returned an error';
        } else {

          h3.innerText = window['tryGetResourceString'](state.resourceStringKey) || state.textFallback || 'An unhandled error occured';
        }
      }
      nativeLoader.querySelector('.my-app-state__info h3')?.classList.add('offline');
      if (state.exception?.message) {
        const stack = document.createElement('div');
        stack.classList.add('.my-app-state__trace');
        stack.innerText = state.exception?.message;

        if (state.exception?.rejection) {
          stack.innerText = state?.exception?.rejection.errorMessage;
        }
        nativeLoader.querySelector('.my-app-state__info').appendChild(stack);
      }
      else{
        const stack = document.createElement('p');
        stack.innerText = state.exception;
        nativeLoader.querySelector('.my-app-state__info').appendChild(stack);
      }
      nativeLoader.querySelector('.my-app-state__info').appendChild(button);
    }
  }

  private getCookie(name){
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }
  private getLang(){
    const lang = window.location.href.match(/\:\/\/.*?\/([a-z]{2})($|\/)/);
    if(lang){
      return lang[1];
    }
    else{
      const langFromCookie = this.getCookie('mibp_lang');
      if(langFromCookie){
        return langFromCookie;
      }
      else{
        let langCode = navigator.languages && navigator.languages[0] || navigator.language || navigator['userLanguage'];
        if(langCode){
          langCode = langCode.split('-')[0].toLocaleLowerCase();
          if(langCode){
            return langCode;
          }
        }
      }
    }
    return 'en';
  }

  private showErrorIfUnableToLoadGlobalConfig(): void {
    // window.location.href = "/assets/application-down.html?lang="+this.getLang();
  }

}

