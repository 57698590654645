import { BroadcastService, ScrollToService } from 'root/services';
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { AfterContentInit, Component, EmbeddedViewRef, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import { PopupContainerInternalService } from "./popup-container-internal.service";
import { PopupAnchorPosition, PopupAnchorTo, PopupClosedEvent, PopupOpenEvent, PopupPositionArgs, PopupPositionResult } from "./popup-container.types";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mibp-popup-container',
  styleUrls: ['./popup-container.component.scss'],
  templateUrl: './popup-container.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MibpPopupContainerComponent implements AfterContentInit, OnDestroy {

  @Input() closeOnMouseLeave = false;

  id: string;
  isOpen = false;
  public isVisible = false;
  overlayRef: OverlayRef | null;
  openSub: Subscription;
  mousedownsub: Subscription;
  mouseupsub: Subscription;
  mouseoutsub: Subscription;
  mouseentersub: Subscription;
  mousemovesub: Subscription;
  scrollSub: Subscription;
  resizeSub: Subscription;
  rootClickSub: Subscription;
  clickOutsideSub: Subscription;
  screenSizeSub: Subscription;
  embeddedView: EmbeddedViewRef<any>;
  windowWidthOnOpen?: number;
  repositionTimer: number;
  maxHeight?: string;
  positionCallbackValue?: { x: number, y: number, originalX: number, originalY: number };

  arrowPosition: 'top-center' | 'top-left' | 'right-center' | 'left-center' | 'bottom-center';

  relativeToElement: HTMLElement;
  toggleElement: HTMLElement;
  initialDesktopWidth: number;


  positionCallback?: (args: PopupPositionArgs) => PopupPositionResult;

  @Input() fitWidth = false;
  @Input() fitHeight = false;
  @Input() closeOnRootClick = false;
  @Input() closeOnClickOutside = true;
  @Input() closeOnScroll = true;
  @Input() closeOnResize = true;
  @Input() groupName: string;      // If popup opens, other popups in the same group will close
  @Input() useArrow = false;
  @Input() attachToBody = true; // Attach to body if true, if false - will attach to parent of the element
  @Input() anchorTo: PopupAnchorTo = 'bottom-right';
  @Input() anchorPosition: PopupAnchorPosition = 'top-right';
  @Input() scrollable = false;
  @Input() minWidth: number;
  @Input() maxWidth: number;
  @Input() widthForMobile?: number;
  @Input() ignoreScroll = false;

  /// Set to true if this element show have a z-index above the header. By default it would be above sidebar but below header
  @Input() showAboveHeader = false;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter<PopupClosedEvent>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('popupContainerTemplate', {static: true}) popupContainerTemplate: TemplateRef<any>;

  constructor(
    public overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private popupContainerService: PopupContainerInternalService,
    private scrollTo: ScrollToService,
    private broadcastService: BroadcastService
  ) {
    this.id = popupContainerService.newId();
  }


  ngAfterContentInit(): void {

    const popupContainerElement = this.embeddedView?.rootNodes?.find(n => n instanceof HTMLDivElement) as HTMLDivElement;
    if (popupContainerElement) {
      const position = this.calculatePopupPosition(popupContainerElement);
      popupContainerElement.style.left = `${position.x}px`;
      popupContainerElement.style.top = `${position.y}px`;
    }

    this.initialDesktopWidth = this.minWidth;
    this.screenSizeSub = this.broadcastService.screenSize
      .pipe(debounceTime(1000))
      .subscribe(() => this.onScreenChange());

  }

  onScreenChange(): void {
    if (!this.widthForMobile) {
      return;
    }

    if (window.innerWidth < 700) {
      this.minWidth = this.widthForMobile;
      this.maxWidth = this.widthForMobile;
    } else if (window.innerWidth > 700 ) {
      this.minWidth = this.initialDesktopWidth;
      this.maxWidth = this.initialDesktopWidth;
      //On mobile we always display popup on top. Reset these values on desktop
      this.anchorTo = null;
      this.anchorPosition = null;
    }

    this.minWidth = window.innerWidth <= 700 ? window.innerWidth -20 : 600;
    this.maxWidth = 600;
  }

  ngOnDestroy(): void {
    this.screenSizeSub?.unsubscribe();
  }

  toggle(element: HTMLElement, toggleElement?: HTMLElement): void {
    this.toggleElement = toggleElement;
    if (this.isOpen) {
      this.close();
    } else {
      this.open(element);
    }
  }

  private isElementInContentArea(element: HTMLElement): boolean {
    return this.contentAreaElement.contains(element);
  }

  public get contentAreaElement(): HTMLElement {
    return (document.body as HTMLElement);
  }

  open(element: HTMLElement, positionCallback?: (args: PopupPositionArgs) => PopupPositionResult): void {

    this.attachToBody = true;
    if (!this.isOpen) {
      this.positionCallback = positionCallback;
      this.isOpen = true;
      this.relativeToElement = element;
      this.openSub = this.popupContainerService.popupGroupOpened.subscribe(e => this.onOpenEvent(e));
      this.popupContainerService.emitOpen({dialogId: this.id, group: this.groupName});

      if (this.contentAreaElement.contains(this.relativeToElement)) {
        this.attachToBody = false;
      } else {
        this.attachToBody = true;
      }

      this.moveTemplateToBody();
      this.opened.emit();
    }
  }

  /**
   * When any popup is open - not just this
   */
  onOpenEvent(e: PopupOpenEvent): void {

    if (e.group === this.groupName && e.dialogId !== this.id) {
      this.close({
        reason: 'group'
      });
    }
    this.broadcastService.setCartQuickviewOpen(false);

    // if (e.dialogId !== this.id) {
    //   this.close();
    // }
  }


  close(e?: PopupClosedEvent): void {
    e = e || { reason : 'code' };
    if (this.isOpen) {
      const popupContainerElement = this.embeddedView.rootNodes.find(n => n instanceof HTMLDivElement) as HTMLDivElement;
      if (popupContainerElement) {
        popupContainerElement.style.transition = this.useArrow ? 'opacity 1s' : null;
        popupContainerElement.style.left = '0';

      }
      clearInterval(this.repositionTimer);
      this.isOpen = false;
      this.isVisible = false;
      this.openSub?.unsubscribe();
      this.mousemovesub?.unsubscribe();
      this.rootClickSub?.unsubscribe();
      this.clickOutsideSub?.unsubscribe();
      this.removeTemplateFromBody();
      this.closed.emit(e);
    }
  }

  private get scrollTop(): number {
    return document.getElementById('my-main-container').parentElement.scrollTop;
  }

  private isElementHidden(elm: HTMLElement): boolean {

    if (window.getComputedStyle(elm).visibility === 'hidden' || window.getComputedStyle(elm).display === 'none') {
      return true;
    }
    return false;

  }

  private calculatePopupPositionForMobile(): {x: number, y: number} {
    this.anchorTo = 'top-center';
    this.anchorPosition = 'bottom-center';
    this.arrowPosition = 'bottom-center';

    return ({x: 0, y: 0});
  }

  private calculatePopupPosition(containerElement: HTMLElement): {x: number, y: number} {

    if (this.isElementHidden(this.relativeToElement)) {
      this.close({reason: 'element-gone'});
      return;
    }

    const relativeToElementSize = this.relativeToElement?.getBoundingClientRect();
    const popupSize = containerElement?.querySelector('.my-popup-content')?.getBoundingClientRect();

    let anchorToX: number;
    let anchorToY: number;
    let containerX: number;
    let containerY: number;

    let relativeToTop = relativeToElementSize.top;
    let relativeToLeft = relativeToElementSize.left;

    let anchorTo = this.anchorTo;
    let anchorPosition = this.anchorPosition;


    if (this.useArrow && !anchorTo && !anchorPosition) {

      // Determine where to anchor to

      if (relativeToElementSize.width >  window.innerWidth / 2) {
        // For very wide elements, anchor to top/bottom
        anchorTo = 'bottom-center';
        anchorPosition = 'top-center';
        this.arrowPosition = 'top-center';
      } else if (relativeToLeft > (window.innerWidth / 2) && popupSize.width != (window.innerWidth - 10)) {
        // If element is to the left side of the screeen, show popup on its right side
        anchorTo = 'left-center'; // Anchor to the left center of the element
        anchorPosition = 'right-center';
        this.arrowPosition = 'right-center'; // Arrow should be to the right

      } else {
        anchorTo = 'right-center';
        anchorPosition = 'left-center';
        this.arrowPosition = 'left-center';

        if ( relativeToLeft + relativeToElementSize.width + popupSize.width > (window.innerWidth - 20)) {
          anchorTo = 'top-center';
          anchorPosition = 'bottom-center';
          this.arrowPosition = 'bottom-center';
        }

      }


      // anchorTo = 'right-center'; anchorPosition = 'left-center'; this.arrowPosition = 'left-center';
      // // anchorTo = 'left-center';anchorPosition = 'right-center';this.arrowPosition = 'right-center';
      // anchorTo = 'bottom-center'; anchorPosition = 'top-center'; this.arrowPosition = 'top-center';
    //  anchorTo = 'top-center'; anchorPosition = 'bottom-center'; this.arrowPosition = 'bottom-center';
    } else {
      if (this.useArrow) {
        if (this.anchorTo === 'top-center') {
          this.arrowPosition = 'bottom-center';
        } else if (this.anchorTo === 'bottom-center') {
          this.arrowPosition = 'top-center';
        }
      }
    }

    // if (!this.attachToBody) {
    //   // Attached to parent element so we must calculate relative position, not from body
    //   // relativeToTop = this.relativeToElement.clientTop;

    //   relativeToLeft = relativeToLeft - this.attachedToElement.offsetLeft;
    //   relativeToTop = (relativeToTop - this.attachedToElement.offsetTop) + (this.scrollTop);
    // }

    if (anchorTo === 'bottom-right') {
      anchorToX = relativeToLeft + relativeToElementSize.width;
      anchorToY = relativeToTop + relativeToElementSize.height;
    } else if (anchorTo === 'bottom-left') {
      anchorToX = relativeToLeft;
      anchorToY = relativeToTop + relativeToElementSize.height;
    } else if (anchorTo === 'left-center') {
      anchorToX = relativeToLeft;
      anchorToY = relativeToTop + (relativeToElementSize.height / 2);
    } else if (anchorTo === 'bottom-center') {
      anchorToX = relativeToLeft + (relativeToElementSize.width / 2);
      anchorToY = relativeToTop + relativeToElementSize.height;
    } else if (anchorTo === 'right-center') {
      anchorToX = relativeToLeft + relativeToElementSize.width;
      anchorToY = relativeToTop + (relativeToElementSize.height / 2);
    } else if (anchorTo === 'top-center') {
      anchorToX = relativeToLeft + relativeToElementSize.width / 2;
      anchorToY = relativeToTop;
    }

    // let d = document.getElementById('anchorx');
    // if(!d) {
    //   d = document.createElement('div');
    //   d.setAttribute('id', 'anchorx');
    //   d.style.position = 'absolute';
    //   d.style.borderRadius = '5px';
    //   d.style.width = '10px';
    //   d.style.height = '10px';
    //   d.style.zIndex = '1000000';
    //   d.style.backgroundColor = 'lime';
    //   d.style.border = '1px solid black';
    //   d = this.attachedToElement.appendChild(d);
    // }

    // d.style.left = anchorToX + 'px';
    // d.style.top = anchorToY + 'px';

    if (anchorPosition === 'top-right') {
      containerX = anchorToX - popupSize.width;
      containerY = anchorToY;
    } else if (anchorPosition === 'top-left') {
      containerX = anchorToX;
      containerY = anchorToY;
    } else if (anchorPosition === 'right-center') {
      //popupSize = containerElement?.querySelector('.my-popup-content')?.getBoundingClientRect();
      containerX = anchorToX - (popupSize.width + 15);
      containerY = anchorToY - (popupSize.height / 2) - 7;
    } else if (anchorPosition === 'bottom-center') {
      containerX = anchorToX - (popupSize.width / 2);
      containerY = anchorToY - popupSize.height - 15;
    } else if (anchorPosition === 'left-center') {
      //popupSize = containerElement?.querySelector('.my-popup-content')?.getBoundingClientRect();
      containerX = anchorToX + 15;
      containerY = anchorToY - (popupSize.height / 2) - 7;
    } else if (anchorPosition === 'top-center') {
      //popupSize = containerElement?.querySelector('.my-popup-content')?.getBoundingClientRect();
      containerX = anchorToX - (popupSize.width / 2);
      containerY = anchorToY + 15;
    }


    // Don't add scrollY if we're in header
    if (!this.relativeToElement?.closest('#site-header') && !this.ignoreScroll) {
      containerY += window.scrollY;
    }

    if (this.positionCallbackValue) {
      if (this.positionCallbackValue.originalX === containerX && this.positionCallbackValue.originalY === containerY) {
        return { x: this.positionCallbackValue.x, y: this.positionCallbackValue.y };
      }
    }

    this.positionCallbackValue = {
      originalX: containerX,
      originalY: containerY,
      x: containerX,
      y: containerY
    };

    if (this.positionCallback) {
      const result = this.positionCallback({
        x: containerX,
        y: containerY,
        anchorPosition: anchorPosition,
        anchorTo: anchorTo
      });
      if (result) {
        containerY = result.y;
        containerX = result.x;
        this.positionCallbackValue.x = result.x;
        this.positionCallbackValue.y = result.y;
      }
    }

    return { x: containerX, y: containerY };

  }

  private watchCloseOnMouseLeave(popupContainerElement: HTMLElement): void {
    let closeTimer: number;
    this.mousemovesub = fromEvent<MouseEvent>(document, 'mousemove')
      .subscribe(event => {
        // If mouse is not over trigger element (relative to ) or container - then close..
        const mouseOverTarget = event.target as HTMLElement;
        let startClosing = false;

        if (!popupContainerElement.contains(mouseOverTarget)) {

          if (!this.relativeToElement.contains(mouseOverTarget)) {
            startClosing = true;
          } else if (closeTimer) {
            clearTimeout(closeTimer);
            startClosing = false;
          }
        } else if (closeTimer) {
          clearTimeout(closeTimer);
          startClosing = false;
        }

        if (startClosing && !closeTimer) {
          closeTimer = window.setTimeout(() => {
            this.close({reason: 'mouse-leave'});
          }, 500);
        }

      });
  }

  private watchCloseOnScroll(): void {

    if (!this.closeOnScroll) {
      return;
    }

    const scrollContainer = document.getElementById('my-main-container')?.parentElement;
    if (!scrollContainer) {
      return;
    }

    this.scrollSub = fromEvent<MouseEvent>(scrollContainer, 'scroll')
      .subscribe(() => {
        this.close({reason: 'scroll'});
        this.scrollSub?.unsubscribe();
      });
  }

  private watchCloseOnClickOutside(): void {
    if (this.closeOnClickOutside) {
      this.clickOutsideSub = fromEvent<MouseEvent>(window, 'mousedown')
        .subscribe((e) => {
          const popupContainerElement = this.embeddedView.rootNodes.find(n => n instanceof HTMLDivElement) as HTMLDivElement;

          if (!popupContainerElement.contains(e.target as HTMLElement)) {
            if (this.toggleElement) {
              // If we have a toggle element and it's clicked, then it would close then open the popup container
              // If if toggleElement is clicked, let that handle the closing
              if (e.target as HTMLElement == this.toggleElement || this.toggleElement.contains(e.target as HTMLElement) ) {
                return;
              }
            }
            this.close({reason: 'click-outside'});
            this.resizeSub?.unsubscribe();
          }
        });
    }
  }

  private watchCloseOnResize(): void {
    if (this.closeOnResize) {
      this.resizeSub = fromEvent<MouseEvent>(window, 'resize')
        .subscribe(() => {
          // Only hide popup container if width changes.
          // Otherwise on-screen-keyboard resize will hide the popup container
          if (this.windowWidthOnOpen !== window.innerWidth) {
            this.close({reason: 'resize'});
            this.resizeSub?.unsubscribe();
          }
        });
    }
  }

  private watchCloseOnRootClick(): void {
    if (this.closeOnRootClick) {
      const popupContainerElement = this.embeddedView.rootNodes.find(n => n instanceof HTMLDivElement) as HTMLDivElement;
      this.rootClickSub = fromEvent<MouseEvent>(popupContainerElement, 'click')
        .subscribe((e) => {
          if (e.target === popupContainerElement) {
            this.close({reason: 'root-click'});
          }
        });
    }
  }

  private get attachedToElement(): HTMLElement {
    return document.querySelector('body');
  }

  private moveTemplateToBody(): void {

   // document.body.style.overflow = 'hidden';

    const bodyElement = this.attachedToElement;
    this.embeddedView = this.viewContainerRef.createEmbeddedView(this.popupContainerTemplate);
    this.embeddedView.rootNodes.forEach(rootNode => bodyElement.appendChild(rootNode));

    // if (!this.attachToBody) {
    //   bodyElement.style.position = 'relative';
    // }

    setTimeout(() => {

      const popupContainerElement = this.embeddedView.rootNodes.find(n => n instanceof HTMLDivElement) as HTMLDivElement;

      if (popupContainerElement) {
        let position: {x: number, y: number};
        if (!this.widthForMobile || (this.widthForMobile && window.innerWidth > 700)) {
          position = this.calculatePopupPosition(popupContainerElement);
        } else if (this.widthForMobile && window.innerWidth <= 700) {
          position = this.calculatePopupPositionForMobile();
        }

        if (!position) {
          this.close({ reason: 'element-gone'});
          return;
        }
        popupContainerElement.style.transition = this.useArrow ? 'opacity 0.5s' : null;
        popupContainerElement.style.left = `${position.x}px`;
        popupContainerElement.style.top = `${position.y}px`;

        if (this.attachToBody) {
          if (this.fitHeight) {
            popupContainerElement.style.height = `calc(100% - ${position.y}px)`;
          }
          popupContainerElement.style.maxHeight = `calc(100% - ${position.y}px)`;
        } else {
          if (this.fitHeight) {
            popupContainerElement.style.height = `calc(100% - ${position.y}px)`;
            popupContainerElement.style.maxHeight = `calc(100% - ${position.y}px)`;
          } else {
            popupContainerElement.style.maxHeight = `none`;
          }
        }

        this.windowWidthOnOpen = window.innerWidth;
        this.watchCloseOnScroll();
        this.watchCloseOnResize();
        this.watchCloseOnRootClick();
        this.watchCloseOnClickOutside();

        if (this.closeOnMouseLeave) {
          this.watchCloseOnMouseLeave(popupContainerElement);
        }

        if (this.fitHeight) {
          this.isVisible = true;
        } else {

          // Is element visible or do we need to scroll?
          this.scrollToElement(this.relativeToElement, position.y).then(() => {
            setTimeout(() => {
              this.isVisible = true;
              setTimeout(() => {popupContainerElement.style.transition = this.useArrow ? 'left 1s, top 1s, opacity 1s' : null;}, 500);
            }, 150);
          });
        }

        //Make sure popup sticks to its element
        // this.repositionTimer = window.setInterval(() => {
        //   const position = this.calculatePopupPosition(popupContainerElement);
        //   if (window.innerWidth <= 700 || position.x < 10) {
        //     position.x = 0;
        //     const leftPoint = this.relativeToElement.getBoundingClientRect().left + 'px';
        //     document.styleSheets[0].addRule('.my-popup-content--arrow-bottom-center:before', 'left: '+leftPoint+' !important;');
        //     document.styleSheets[0].addRule('.my-popup-content--arrow-bottom-center:after', 'left: '+leftPoint+' !important;');

        //     if(window.innerWidth < popupContainerElement.clientWidth){
        //       document.styleSheets[0].addRule('.my-popup-container', 'width : '+`${ window.innerWidth - 10}px !important`);
        //     }
        //     document.styleSheets[0].addRule('.my-featuretour__contentwrapper', 'width: 0 px !important');
        //   }
        //   if (position) {
        //     popupContainerElement.style.left = `${position.x < 10 ? 10 : position.x}px`;
        //     popupContainerElement.style.top = `${position.y}px`;
        //   }
        // }, 250);

      }

    });
  }

  private scrollToElement(element: HTMLElement, top: number): Promise<void> {

    if (!this.isElementInContentArea(element)) {
      return Promise.resolve();
    }

    const containerElement = this.embeddedView.rootNodes.find(n => n instanceof HTMLDivElement) as HTMLDivElement;
    const popupSize = containerElement?.querySelector('.my-popup-content')?.getBoundingClientRect();

    if (top + popupSize.height + this.contentAreaElement.getBoundingClientRect().top < this.contentAreaElement.scrollTop + this.contentAreaElement.getBoundingClientRect().height) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      // this.scrollTo.scrollTo(this.contentAreaElement, top + popupSize.height - document.getElementById('main-wrapper').getBoundingClientRect().height + this.contentAreaElement.getBoundingClientRect().top, 500);
      setTimeout(() => {
        resolve();
      }, 500);
    });

  }

  removeTemplateFromBody(): void {
    //document.body.style.overflow = null;
    this.embeddedView?.destroy();
    this.mousemovesub?.unsubscribe();
    this.resizeSub?.unsubscribe();
    this.scrollSub?.unsubscribe();
    this.rootClickSub?.unsubscribe();
  }

}
