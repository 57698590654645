/**
 * The AppInjector can be used to inject providers into
 * scripts without forcing the user to send them in the constructor
 * See: LocalizedComponent
 */
import { Injector } from '@angular/core';

export class AppInjector {
  private static injector: Injector;

  static setInjector(injector: Injector) {
    AppInjector.injector = injector;
  }

  static getInjector(): Injector {
    return AppInjector.injector;
  }

}
