/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UpSellCrossSellProductRequestResponseDto } from '../../models/up-sell-cross-sell-product-request-response-dto';

export interface GetUpsellProducts$Plain$Params {
  upsellCrossSellProductId: number;
  languageCode?: string;
}

export function getUpsellProducts$Plain(http: HttpClient, rootUrl: string, params: GetUpsellProducts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>> {
  const rb = new RequestBuilder(rootUrl, getUpsellProducts$Plain.PATH, 'get');
  if (params) {
    rb.path('upsellCrossSellProductId', params.upsellCrossSellProductId, {});
    rb.query('languageCode', params.languageCode, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<UpSellCrossSellProductRequestResponseDto>>;
    })
  );
}

getUpsellProducts$Plain.PATH = '/upsells/products/{upsellCrossSellProductId}/upsellproducts';
