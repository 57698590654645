import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalizationService } from 'root/services';
import { environment } from 'root/environment';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService implements OnDestroy {

  private pageTitleSuffix = '';
  private releaseResourceStrings: () => void;
  private macroValues: { [key: string]: string } = {};
  private previousTitle: string;

  constructor(
    private localizationService: LocalizationService,
    private titleService: Title,
  ) {}

  public ngOnDestroy(): void {
    this.releaseResourceStrings();
  }

  public setMacroValue(key: string, value: string) {
    this.macroValues[key] = value;
  }

  public getMacroValue(key: string) {
    return this.macroValues[key];
  }

  public append(textValue: string): void {
    this.localizationService.getResourceString('Global_MySandvik_CustomerPortal').then((resourceStringValue) => {
      let currentTitle = resourceStringValue;
      currentTitle = textValue + environment.pageTitle.delimiter + currentTitle;
      this.titleService.setTitle(currentTitle);
    });
  }

  /**
   * Insert a part of the page title.
   * Will use environment.pageTitle.delimieter to split title and insert at right location
   */
  public insert(index: number, textValue: string): void {
    let currentTitle = this.previousTitle;
    const parts = currentTitle ? currentTitle.split(environment.pageTitle.delimiter) : [];

    if (index <= parts.length) {
      parts.splice(index, 0, textValue);
    } else {
      parts.push(textValue);
    }

    currentTitle = parts.join(environment.pageTitle.delimiter);
    this.titleService.setTitle(currentTitle);
  }

  /**
   Sets the html <title> tag of the page.
   * @param title The title of the page
   * @param convertFromResourceString Set to true if you want to convert from a resource string. Defaults to true.
   */

  public setTitle(title: string, convertFromResourceString = true): void {
    let newTitle = title;
    if (title) {
      const sameAsSuffix: boolean = (title === environment.pageTitle.suffix);

      if (convertFromResourceString) {

        const resourceStrings: string[] = [];

        resourceStrings.push(title);
        resourceStrings.push(environment.pageTitle.suffix);

        if (this.releaseResourceStrings) { this.releaseResourceStrings(); }

        this.releaseResourceStrings = this.localizationService.using(resourceStrings, values => {
          this.pageTitleSuffix = environment.pageTitle.delimiter + values[1];
          newTitle = sameAsSuffix ? values[0] : values[0] + this.pageTitleSuffix;
          this.titleService.setTitle(newTitle);
          this.previousTitle = newTitle;
        });
      } else {
        this.titleService.setTitle(newTitle);
        this.previousTitle = newTitle;
      }
    }
  }
}
