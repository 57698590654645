import { ValidatorFn, AbstractControl } from "@angular/forms";
export function maxQuantityLimitValidator(maxAllowed): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value;
    if (value > maxAllowed) {
      return {
        maxQuanityLimitValidation: {
          valid: false,
          'maxValue': maxAllowed
        }
      };
    }
  };
}
