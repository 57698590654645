import { LoaderService } from './../../../../services/loader/loader.service';
import { LocalizationService, LogService, MibpLogger, NoticebarService } from 'root/services';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MibpPopupContainerComponent } from 'root/components';
import { ResourceApiController, SessionApiController, UsersApiController } from 'root/mibp-openapi-gen/controllers';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'mibp-header-language',
  templateUrl: './header-language.component.html'
})
export class MibpHeaderLanguageComponent implements OnInit {

  flagImageUrl: string;
  languageCodes: string[];
  currentLanguageCode: string;
  flagClassName: string;
  loadLanguageError = false;
  isLoading = false;
  log: MibpLogger;
  @Input() isInline = false;
  @Output() changed = new EventEmitter();
  @ViewChild('languagePopupContainer') languagePopupContainer: MibpPopupContainerComponent;


  constructor(private loader: LoaderService,
    private localizationService: LocalizationService,
    private sessionApiController: SessionApiController,
    private logService: LogService,
    private noticebarService : NoticebarService
  ) {
    this.log = logService.withPrefix('header-language.component');
  }

  ngOnInit(): void {
    this.updateFlagImageUrl();
    this.loadLanguages();
  }

  public async changeLanguage(e: Event, code: string): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    this.languagePopupContainer.close();

    this.loader.showFullScreenLoader();
    this.updateFlagImageUrl(code);

    try {
      await this.localizationService.userChangeLanguage(code);
      this.loader.hideFullScreenLoader();
      await firstValueFrom(this.sessionApiController.changeConnectionAndUserLanguage({languageCode: code}));
      this.changed.emit();
    } catch (e) {
      this.log.error('user langauge could not be updated', e);
      this.loader.hideFullScreenLoader();
    }
  }

  public loadLanguages(): void {
    this.loadLanguageError = false;
    this.isLoading = true;
    this.localizationService.getAvailableLanguages()
      .then(languageCodes => {
        this.isLoading = false;
        this.languageCodes = languageCodes;
      })
      .catch(() => this.loadLanguageError = true);
  }

  private updateFlagImageUrl(langCode?: string): void {
    this.currentLanguageCode = langCode || this.localizationService.getLang();
    this.flagClassName = 'my-site-header__language-icon my-site-header__language-icon--' + this.currentLanguageCode;
    this.flagImageUrl = `url(/assets/images/flags/64/${this.currentLanguageCode}.png)`;
  }


}
