/* tslint:disable */
/* eslint-disable */
export enum FdmReportAccessStatus {
  Unknown = 0,
  OK = 1,
  FdmCustomerNotSetup = 2,
  FdmLoginFailed = 3,
  FdmAuthorizationError = 4,
  FdmLoginError = 5,
  MySandvikCompanyMissing = 6,
  InvalidReportSource = 7
}
