import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: "mibp-numeric-input",
  templateUrl: "./numeric-input.component.html",
  styleUrls: ["./numeric-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumericInputComponent
    }
  ]
})
export class NumericInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  step = 1;
  isTouched: boolean;
  isDisabled: boolean;
  valueUpdate: Subject<number>;

  @Input() minValue = 0.05; //Min and max values are currently hard coded in backend and frontend but should probably be settings
  @Input() maxValue = 99999999.999;
  @Input() debounceTime = 400;
  @Input() value = 1;

  @Output() inputChange = new EventEmitter<number>();
  @Output() inputBlur = new EventEmitter<any>();

  onChange = (value) => { };
  onTouch = () => { };

  constructor() {
    if (this.debounceTime < 0) {
      this.debounceTime = 0;
    }
  }

  ngOnInit(): void {
    this.valueUpdate = new Subject<number>();

    this.valueUpdate.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged())
      .subscribe(value => {
        this.value = value;
        this.onChange(this.value);
        this.inputChange.emit(this.value);
      });
  }

  writeValue(newValue: number): void {
    this.value = newValue;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouch = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onModelChanged(newValue: number) {
    this.markAsTouched();

    if (!this.isDisabled) {
      this.valueUpdate.next(newValue);
    }
  }

  increaseValue() {
    this.markAsTouched();

    if (!this.isDisabled && (this.value + this.step) <= this.maxValue) {
      this.value++;
      this.valueUpdate.next(this.value);
    }
  }

  decreaseValue() {
    this.markAsTouched();

    const newValue = this.value - this.step;

    if (!this.isDisabled && newValue >= this.minValue && newValue >= 1) {
      this.value--;
      this.valueUpdate.next(this.value);
    }
  }

  onBlur($event: any) {
    this.inputBlur.emit($event);
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.onTouch();
      this.isTouched = true;
    }
  }

  ngOnDestroy(): void {
    this.valueUpdate?.unsubscribe();
  }
}
