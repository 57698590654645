import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AvailabilityType, ProductExpectedDate, SplitSupplyExpectedDeliveryDate } from '../../mibp-openapi-gen/models';
import { MibpPopoverComponent } from '../popover-component/popover.component';

@Component({
  selector: 'mibp-expecteddeliverydate',
  templateUrl: './expecteddeliverydate.component.html',
  styleUrls: ['./expecteddeliverydate.component.scss']
})
export class ExpectedDeliveryDateComponent implements OnInit {

  @Input() date: string;
  @Input() isRockToolItem: boolean;
  @Input() isQuotation: boolean;
  @Input() expectedDeliveryDates: SplitSupplyExpectedDeliveryDate[];
  @Input() productExpectedDate: ProductExpectedDate;
  @Input() stockroomEnabled: boolean;

  @ViewChild('expectedDeliveryDatePopover') expectedDeliveryDatePopover: MibpPopoverComponent;
  @ViewChild('availabilityPopover') availabilityPopover: MibpPopoverComponent;

  availabilityType =  AvailabilityType;
  ispopoverClosedValue = true;

  ngOnInit() {

  }

  showPopover(elm: HTMLElement): void {
    if (elm) {
      this.isPopOverClosed = false;
      if(this.productExpectedDate){
        this.availabilityPopover.open(elm);
      }
      else{
        this.expectedDeliveryDatePopover.open(elm);
      }
    }
  }

  closePopover(isPopoverClosed : boolean): void{
    this.isPopOverClosed = isPopoverClosed;
  }

  get isPopOverClosed(){
    return this.ispopoverClosedValue;
  }
  set isPopOverClosed(isPopoverClosed : boolean){
    this.ispopoverClosedValue = isPopoverClosed;
  }
}
