import { OrdersApiController } from './../../mibp-openapi-gen/services/orders-api-controller';
import { AddProductDto } from './../../mibp-openapi-gen/models/add-product-dto';
import { GoogleTagManagerService } from 'root/services/google-tag-manager-service/google-tag-manager.service';
import { KitSuggestionDto } from './../../mibp-openapi-gen/models/kit-suggestion-dto';
import { CartsApiController } from './../../mibp-openapi-gen/services/carts-api-controller';
import { LocalizationService } from './../localization/localization.service';
import { AddtocartDialogComponent } from './../../components/addtocart-dialog/addtocart-dialog.component';
import { NoticebarService } from 'root/services/noticebar-service/noticebar.service';
import { GlobalConfigService } from './../global-config/global-config.service';
import {
  SignalR_CartUpdatedEvent,
  SignalR_CartAddProductsResponseViewModel
} from './../mibp-api-services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { FrontendContextService } from '../front-end-context/front-end-context.service';
import {
  ApiService,
} from '../mibp-api-services';
import { Guid } from 'guid-typescript';
import { Router } from '@angular/router';
import { LogService, MibpLogger } from '../logservice';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { UpsellCrossSellModalComponent } from 'root/modules/home/modules/cart/components/upsell-cross-sell-modal/upsell-cross-sell-modal.component';
import { firstValueFrom } from 'rxjs';
import { ProductsApiController } from './../../mibp-openapi-gen/services/products-api-controller';

import { TemplatesApiController } from './../../mibp-openapi-gen/services/templates-api-controller';
import { CartAddProductsResponseViewModel } from './../../mibp-openapi-gen/models/cart-add-products-response-view-model';
import { SimpleProductViewModel } from 'root/mibp-openapi-gen/models/simple-product-view-model';
import { BroadcastService, DialogService, FormattingService, MibpHttpApi } from '..';
import { ButtonColors, ButtonStyles } from 'root/components/button/button.enum';
import { AddItemToCartSource, Cart, CartAddProductErrorReason, CartAddProductErrorViewModel, OrderQuotationDeliveryPointInfoViewModel, ProductCodeandQuantityDto, ProductPrice } from 'root/mibp-openapi-gen/models';
import { MibpSessionService } from '../mibp-session/mibp-session.service';
import { MySandvikFeatures } from '../permission';

export interface CartUpdatedEvent {
  cartId?: Guid;
  productsAdded?: string[];
  isActiveCart?: boolean;
  count?: number;
  refreshActiveCartCount?:boolean;
  updatedItems?:Guid[];
}

export interface DuplicatePromotionProductQuantityEvent {
  productCode: string;
  cumulativeDuplicateProductQuantity: number;
}

@Injectable({
  providedIn: 'root'
})

export class CartService {

  addtocartDialog: AddtocartDialogComponent;
  upsellCrossSellModalComponent: UpsellCrossSellModalComponent;
  currentCartIdForPriceAndAvailability: Guid;
  log: MibpLogger;
  private refreshCartItemsSubject = new Subject();
  private refreshCartWeightNPriceSubject = new BehaviorSubject<boolean>(false);
  private refreshCartPrices = new BehaviorSubject<boolean>(false);
  itemsInCart: number;
  languageCode: string;
  private addedMessage = "";
  private productsCouldNotbeAddedmessage = "";
  private invalidProductCodeMessage = "";
  suggestedKits : string[];
  /**
   * Will be true while something is being added to the cart
   */
  private isAddingToCartSubject = new BehaviorSubject<AddingToCartArgs>(null);
  suggestedKitsSubject = new BehaviorSubject<KitSuggestionDto>(null);
  duplicatePromotionProductSubject = new BehaviorSubject<DuplicatePromotionProductQuantityEvent>(null);
  decimalValueNotSupported: string;
  // cartEventSubject = new BehaviorSubject<CartUpdatedEvent>(null);
  constructor(private api: ApiService,
              private frontendContext: FrontendContextService,
              private formattingService: FormattingService,
              private templatesApi: TemplatesApiController,
              private router: Router,
              private session: MibpSessionService,
              private productApi: ProductsApiController,
              private globalConfig: GlobalConfigService,
              private noticeBarService: NoticebarService,
              private localizationService: LocalizationService,
              logger: LogService,
              private cartsController: CartsApiController,
              private dialogService: DialogService,
              private context: FrontendContextService,
              private googleTagManagerService : GoogleTagManagerService,
              private broadcast: BroadcastService,
              private ordersController: OrdersApiController
  ) {

    this.log = logger.withPrefix('cart.service');

    this.localizationService.using(['Cart_ProductsSuccessfullyAdded', 'Cart_ProductsCouldNotBeAdded', 'Global_InvalidProductCode', 'Global_ValidationText_DecimalValueNotSupported'], resourceStrings => {
      this.addedMessage = resourceStrings[0];
      this.productsCouldNotbeAddedmessage = resourceStrings[1];
      this.invalidProductCodeMessage = resourceStrings[2];
      this.decimalValueNotSupported = resourceStrings[3];
    });
    this.languageCode = localizationService.getLang();
  }

  calculateSuggestedKits(){

    if (!this.session.hasFeature(MySandvikFeatures.ShopPersistentCart) && !this.session.activeDeliverySequence) {
      return;
    }

    if(!this.globalConfig.disableKitSuggestions){
      firstValueFrom(this.cartsController.getSuggestedKitsForActiveCart()).then(suggestedKitDto => {
        this.suggestedKitsSubject.next(suggestedKitDto);
        if(suggestedKitDto && suggestedKitDto.hasBeenSuggested !== true && suggestedKitDto.suggestedKits && suggestedKitDto.suggestedKits.length>0){
          firstValueFrom(this.cartsController.updatedKitSuggestedStatusForActiveCart());

          this.googleTagManagerService.pushCustomEvent('Cart_SuggestedKits_Dialog');

          this.dialogService.promptWithButtons('Cart_SuggestedKitsDialog', [
            { resourceKey: 'Global_Dialog_Close', id: 'close', color: ButtonColors.Orange, style: ButtonStyles.Fill},
            { resourceKey: 'Button_GoToKitConfigurator', id: 'gotokits', style: ButtonStyles.Fill},
          ],true).then(button => {
            if (button === 'gotokits') {
              this.googleTagManagerService.pushCustomEvent('Cart_SuggestedKits_Dialog_GoToKitConfigurator');

              const kitsString = suggestedKitDto.suggestedKits.join(',');
              const params = { kits: kitsString};
              this.router.navigate([`/${this.context.getLang()}/shop/kitfinder`], { queryParams: params});
            }
          });
        }
      }).catch(e => {
        this.log.error(`Error when loading suggested kits for active cart`, e);
      });
    }
  }
  private updateItemsInCart(cartEvent: SignalR_CartUpdatedEvent): void {
    if (this.broadcast.snapshot.mibpSession?.activeDeliverySequence && cartEvent?.count !== null && cartEvent?.isActiveCart == true) {
      this.itemsInCart = cartEvent.count;
    } else {
      this.itemsInCart = 0;
    }
  }

  /**
   * If errorous products are added but has no upsell/crossell then we should show a notice
   * otherwise we want to show a dialog
   */
  private getAddToCartDialogOrNotice(addProductsResponse: CartAddProductsResponseViewModel | SignalR_CartAddProductsResponseViewModel): 'noticebar' | 'dialog' {

    const hasUpsell = !this.globalConfig.disableUpSell && addProductsResponse?.crossSellProductIds?.length > 0;
    const hasCrossell = !this.globalConfig.disableUpSell && addProductsResponse?.upsellCrossSellProductId > 0;

    return addProductsResponse?.cartContainsAddedProduct || hasUpsell || hasCrossell
      ? 'dialog' : 'noticebar';
  }

  private showCartNotificationNew(result: CartAddProductsResponseViewModel) : void {
    if (!this.broadcast.snapshot.mibpSession?.activeDeliverySequence) {
      return;
    }

    let noticeType = null;
    let htmlNotice = '';

    const tooManyitemsError = this.localizationService.format(this.localizationService.get('Carts_TooManyItems_Error'), {
      // TODO: Max cart size should be moved to global config, not be a part of user event
      max: this.globalConfig.maxCartSize.toString()
    });

    const productCodesAdded = result ? result.productsAdded : [];
    const productAddErrors = result ? result.productErrors : [];

    this.log.debug("Product codes added", productCodesAdded);
    if (productCodesAdded.length > 0) {
      htmlNotice += `<div class="text"><p class="text-success"><strong>${this.addedMessage.replace('{count}', productCodesAdded.length.toString())}</strong></p></div>`;
      noticeType = NoticeType.Success;
    }

    // If any errors, show top errorous products
    // If products were also added, change to warning - else error
    if (productAddErrors.length > 0) {

      noticeType = noticeType === null ? NoticeType.Error : NoticeType.Warning;
      const textclass = noticeType === NoticeType.Error ? 'text-error' : 'text-warning';

      const exceedSizeErrorCount = productAddErrors.filter(f => f.reason === CartAddProductErrorReason.CartSizeExceeded).length;
      const invalidProductErrorCount = productAddErrors.filter(f => f.reason === CartAddProductErrorReason.Missing).length;

      if (exceedSizeErrorCount > 0 && invalidProductErrorCount > 0) {

        htmlNotice += `<h2 class="${textclass}">${this.productsCouldNotbeAddedmessage}:</h2><div class="text"><ul>`;

        htmlNotice += '<li>' + tooManyitemsError + ' (' + this.localizationService.format(this.localizationService.get('Global_RowsAffected'), { count: exceedSizeErrorCount.toString() }) + ')</li>';
        htmlNotice += '<li>' + this.invalidProductCodeMessage + ' (' + this.localizationService.format(this.localizationService.get('Global_RowsAffected'), { count: invalidProductErrorCount.toString() }) + ')</li>';

        htmlNotice += '</ul>';

      } else {

        const max = 7;
        let take = productAddErrors.length;
        let andMoreMessage = '';
        if (productAddErrors.length >= (max + 3)) {
          take = max;
          andMoreMessage = 'and ' + (productAddErrors.length - max) + ' more...';
        }

        htmlNotice += `<h2 class="${textclass}">${this.productsCouldNotbeAddedmessage}:</h2><div class="text"><ul>` + productAddErrors
          .map(code => `<li>${code.reason === CartAddProductErrorReason.CartSizeExceeded ? tooManyitemsError : code.reason === CartAddProductErrorReason.DecimalNotSupported? this.decimalValueNotSupported:  this.invalidProductCodeMessage}: ${code.productCode}</li>`)
          .slice(0, take).join('') + andMoreMessage + '</ul></div>';

      }

    }

    if (htmlNotice) {
      if (result.cartContainsAddedProduct === false && !result.upsellCrossSellProductId && (!result.crossSellProductIds || result.crossSellProductIds.length === 0)) {
        this.noticeBarService.showText(htmlNotice, noticeType, true);
      }
    }
  }

  /**
   * Observable to indicate if items are currently being added to the cart
   */
  public get IsAddingToCart$(): Observable<AddingToCartArgs> {
    return this.isAddingToCartSubject.asObservable();
  }

  /**
   * Current adding to cart value
   * Use observable to watch
   */
  public get IsAddingToCart(): boolean {
    return this.isAddingToCartSubject && this.isAddingToCartSubject.value.isAdding;
  }

  public refreshCartItems$(): Observable<any> {
    return this.refreshCartItemsSubject.asObservable();
  }

  public refreshCartItems() {
    this.refreshCartItemsSubject.next(undefined);
  }

  public refreshCartWeightNPrice(){
    this.refreshCartWeightNPriceSubject.next(true);
  }

  public get refreshCartWeightAndPrice$(): Observable<boolean> {
    return this.refreshCartWeightNPriceSubject.asObservable();
  }

  public refreshCartProductPrices(){
    this.refreshCartPrices.next(true);
  }

  public get refreshCartProductPrices$(): Observable<boolean> {
    return this.refreshCartPrices.asObservable();
  }

  public getOrderDeliveryPointInfo(orderId: Guid): Promise<OrderQuotationDeliveryPointInfoViewModel> {
    return firstValueFrom(this.ordersController.getOrderDeliveryPointInfo({id: orderId.toString()}));
  }

  async uploadFile(fileList: HTMLInputElement): Promise<ProductCodeandQuantityDto[]> {
    const file: File = fileList.files[0];
    const formData = new FormData();
    formData.append('file', file, file.name);
    return firstValueFrom(this.cartsController.uploadExcel({body:{filereq: file as Blob} }));
  }

  private async readfile(file: File): Promise<ArrayBuffer> {
    const reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  public get CartUpdate(): CartUpdatedEvent {
    return this.broadcast.cartEventValue;
  }

  public registerDialog(addtocartDialog: AddtocartDialogComponent): void {
    this.addtocartDialog = addtocartDialog;
  }

  public registerUpsellCrossSellModal(upsellCrossSellModalComponent: UpsellCrossSellModalComponent): void {
    this.upsellCrossSellModalComponent = upsellCrossSellModalComponent;
  }

  async addToCart(products: QuickOrderLine[],  source : AddItemToCartSource, cartId?: Guid): Promise<CartAddProductsResponseViewModel> {
    const productsToAdd: AddProductDto[] = new Array<AddProductDto>();
    const productCodesAddedToCart: string[] = new Array<string>();

    if (this.itemsInCart + products.length > this.globalConfig.maxCartSize) {
      this.noticeBarService.show('Cart_Error_TooManyRowsInCart', NoticeType.Warning, { maxCartItems: this.globalConfig.maxCartSize?.toString() });
      return Promise.reject();
    } else {
      this.isAddingToCartSubject.next({ isAdding: true });

      products.forEach(product => {
        const modal: AddProductDto = {
          productCode: product.productCode,
          quantity: product.quantity
        };
        productsToAdd.push(modal);
        productCodesAddedToCart.push(product.productCode);
      });
    }

    const promise = firstValueFrom(this.cartsController.addProducts({
      cartId:cartId?.toString(),
      source: source,
      body:productsToAdd,
      currentDatePattern: this.formattingService.getDateFormatPlaceholder(false),
      dateTimeOffsetMinutes: new Date().getTimezoneOffset()
    })
      .pipe(
        map(x => <unknown>x as CartAddProductsResponseViewModel), // Let's always use CartAddProductsResponseViewModel
        tap({
          next: r => this.isAddingToCartSubject.next({ isAdding: false, result: r}),
          error: () => this.isAddingToCartSubject.next({ isAdding: false })
        })
      )
    );

    promise.then(result => {
      this.handleAddToCart(result);
    }).catch(err => {
      // Without this catch, the caller of addToCart function will never get rejected promise. The whole site will simply crash.
      this.noticeBarService.show('GeneralError_AddToCart',NoticeType.Error);
      this.log.error(`Error when adding to cart`, err);
    });

    return promise;
  }

  handleAddToCart(result: CartAddProductsResponseViewModel) : void {
    if(result && result.isActiveCart){
      const event : CartUpdatedEvent = {
        cartId: Guid.parse(result.cartId),
        productsAdded :  result.productsAdded,
        isActiveCart: result.isActiveCart,
        count: result.count
      };
      this.broadcast.setCartEvent(event);
    }
    if (this.getAddToCartDialogOrNotice(result) == 'dialog') {
      this.showAddToCartDialogIfNeeded(result);
    }
    else{
      this.showCartNotificationNew(result);
    }
    if(result && result.isActiveCart && result.productsAdded?.length > 0){
      this.calculateSuggestedKits();
    }
  }

  mapToCartAddProductsResponseViewModel(result : SignalR_CartAddProductsResponseViewModel){
    if(result){
      return <CartAddProductsResponseViewModel>{
        cartContainsAddedProduct: result.cartContainsAddedProduct,
        cartHasDuplicates: result.cartHasDuplicates,
        cartId: result.cartId?.toString(),
        count: result.count,
        crossSellProductIds: result.crossSellProductIds,
        isActiveCart: result.isActiveCart,
        isSavedCart: result.isSavedCart,
        productErrors: result.productErrors?.map(p => <CartAddProductErrorViewModel>{
          index: p.index,
          productCode: p.productCode,
          reason: CartAddProductErrorReason[p.reason.toString()]
        }),
        productsAdded: result.productsAdded,
        upsellCrossSellProductId: result.upsellCrossSellProductId
      };
    }
  }

  showAddToCartDialogIfNeeded(addProductsResponse: CartAddProductsResponseViewModel | SignalR_CartAddProductsResponseViewModel): void {
    firstValueFrom(this.productApi.getSimpleProductListByProductCodes({
      body: addProductsResponse.productsAdded
    })).then(simpleProductList => {
      this.addtocartDialog.showDialog(simpleProductList, addProductsResponse.cartHasDuplicates, this.currentCartIdForPriceAndAvailability, addProductsResponse.upsellCrossSellProductId, addProductsResponse.crossSellProductIds, (<unknown>addProductsResponse as SignalR_CartAddProductsResponseViewModel).productErrors);
    }).catch(e => {
      this.log.error(`Error occured when fetching Upsell/Crossell product details`, e);
    });
  }

  addTemplateToCart(cartId: Guid): Promise<CartAddProductsResponseViewModel> {
    return firstValueFrom(this.templatesApi.addTemplateToCart({
      templateCartId: cartId.toString()
    }));
  }

  showUpsellModalComponent(upsellProductId: number) {
    this.upsellCrossSellModalComponent.showUpsellCrossSellModal(upsellProductId);
  }

  showCrossSellModalComponent(crossSellProductIds?: number[]) {
    this.upsellCrossSellModalComponent.showUpsellCrossSellModal(null, crossSellProductIds);
  }
}
export interface QuickOrderLine {
  productCode: string;
  quantity: number;
}

export interface AddingToCartArgs {
  result?: CartAddProductsResponseViewModel;
  isAdding: boolean;
}

export interface CartForCheckout {
  cart: Cart;
  invoiceAddressLines: string[];
  deliveryAddressLines: string[];
}

export interface SimpleProductViewModelWithPrice extends SimpleProductViewModel {
  productPrice?: ProductPrice;
}
