import { ContactUsFormData } from './contact-us-form/contact-us-form.types';
import { Component, OnInit, Input } from '@angular/core';
import { LocalizationService } from 'root/services';

@Component({
  selector: 'mibp-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {

  @Input() formData: ContactUsFormData;
  @Input() showLogoutButton: boolean;
  public language: string;
  constructor(private localizationService: LocalizationService) { }

  ngOnInit() {
    this.language = this.localizationService.getLang();
    if (this.language === 'es') {
      this.language = 'es-la';
    }
    if (this.language === 'pt') {
      this.language = 'pt-br';
    }
    if (this.language === 'zh') {
      this.language = 'zh-cn';
    }
  }
}
