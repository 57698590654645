/* tslint:disable */
/* eslint-disable */
export enum DownloadedManualStatus {
  Unknown = 0,
  Queued = 1,
  InProgress = 2,
  Downloaded = 3,
  Error = 4,
  Expired = 5
}
