<div class="numeric-input">
  <button class="numeric-input__btn numeric-input__btn--no-right-border" (click)="decreaseValue()" [disabled]="isDisabled">-</button>
  <input
    type="number"
    class="numeric-input__input"
    [disabled]="isDisabled"
    [min]="minValue"
    [max]="maxValue"
    [step]="step"
    placeholder="quantity"
    [ngModel]="value"
    (ngModelChange)="onModelChanged($event)"
    (blur)="onBlur($event)" />
  <button class="numeric-input__btn numeric-input__btn--no-left-border" (click)="increaseValue()" [disabled]="isDisabled">+</button>
</div>
