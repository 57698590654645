import { MySandvikNavigationItem } from 'root/services/navigation/navigation-types';
import { BroadcastService } from 'root/services';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'mibp-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MibpMobileMenuComponent implements OnInit, OnDestroy {

  items: MySandvikNavigationItem[];

  constructor(private broadcast: BroadcastService,
              private router: Router) {}

  navSub: Subscription;
  @Output() navigated = new EventEmitter();

  ngOnInit(): void {
    this.navSub = this.broadcast.navigation.subscribe(items => this.onNavigationUpdate(items));
  }

  onNavigationUpdate(items: MySandvikNavigationItem[]): void {
    this.items = items;
  }

  mobileMenuClick(item: MySandvikNavigationItem, depth: number): void {
    if (this.anyVisibleChildren(item) && (depth == 0 && item.calculated.fullPath.indexOf('bulletins') == -1)) {
      item['expanded'] = !item['expanded'];
    } else {
      this.navigated.emit();
      this.router.navigate(item.calculated.fullPath);
    }
  }

  ngOnDestroy(): void {
    this.navSub?.unsubscribe();
  }

  filteredItems(items: MySandvikNavigationItem[]): MySandvikNavigationItem[] {
    return items?.filter(i => i.calculated.hidden !== true) || [];
  }

  anyVisibleChildren(item: MySandvikNavigationItem): boolean {
    return item?.children?.filter(i => i.hidden !== true).length > 0;
  }

}