/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getIndexHtmlWithUpdatedBaseUrl } from '../fn/tdm-host/get-index-html-with-updated-base-url';
import { GetIndexHtmlWithUpdatedBaseUrl$Params } from '../fn/tdm-host/get-index-html-with-updated-base-url';
import { getModelList } from '../fn/tdm-host/get-model-list';
import { GetModelList$Params } from '../fn/tdm-host/get-model-list';
import { getModelList$Plain } from '../fn/tdm-host/get-model-list-plain';
import { GetModelList$Plain$Params } from '../fn/tdm-host/get-model-list-plain';
import { MediaViewModelPagedResponse } from '../models/media-view-model-paged-response';
import { serveFileFromBlob } from '../fn/tdm-host/serve-file-from-blob';
import { ServeFileFromBlob$Params } from '../fn/tdm-host/serve-file-from-blob';
import { serveFileFromBlob$Plain } from '../fn/tdm-host/serve-file-from-blob-plain';
import { ServeFileFromBlob$Plain$Params } from '../fn/tdm-host/serve-file-from-blob-plain';
import { TdModelVm } from '../models/td-model-vm';

@Injectable({ providedIn: 'root' })
export class TdmHostApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tdmHostControllerGetModelList()` */
  static readonly TdmHostControllerGetModelListPath = '/tdmhost';

  /**
   * Get a list of available 3d model names. This is a list of blob storage root folders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getModelList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getModelList$Plain$Response(params?: GetModelList$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TdModelVm>>> {
    return getModelList$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of available 3d model names. This is a list of blob storage root folders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getModelList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getModelList$Plain(params?: GetModelList$Plain$Params, context?: HttpContext): Observable<Array<TdModelVm>> {
    return this.getModelList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TdModelVm>>): Array<TdModelVm> => r.body)
    );
  }

  /**
   * Get a list of available 3d model names. This is a list of blob storage root folders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getModelList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getModelList$Response(params?: GetModelList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TdModelVm>>> {
    return getModelList(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of available 3d model names. This is a list of blob storage root folders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getModelList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getModelList(params?: GetModelList$Params, context?: HttpContext): Observable<Array<TdModelVm>> {
    return this.getModelList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TdModelVm>>): Array<TdModelVm> => r.body)
    );
  }

  /** Path part for operation `tdmHostControllerGetIndexHtmlWithUpdatedBaseUrlname()` */
  static readonly TdmHostControllerGetIndexHtmlWithUpdatedBaseUrlnamePath = '/tdmhost/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIndexHtmlWithUpdatedBaseUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndexHtmlWithUpdatedBaseUrl$Response(params: GetIndexHtmlWithUpdatedBaseUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getIndexHtmlWithUpdatedBaseUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIndexHtmlWithUpdatedBaseUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndexHtmlWithUpdatedBaseUrl(params: GetIndexHtmlWithUpdatedBaseUrl$Params, context?: HttpContext): Observable<void> {
    return this.getIndexHtmlWithUpdatedBaseUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `tdmHostControllerServeFileFromBlobnameClientIdPath()` */
  static readonly TdmHostControllerServeFileFromBlobnameClientIdPathPath = '/tdmhost/{name}/{clientId}/{path}';

  /**
   * This returns the file from the blob for the requested file.
   * These requests are called as the index.htm file is loaded.
   * The ClientID will be used to verify that the session is still valid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serveFileFromBlob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serveFileFromBlob$Plain$Response(params: ServeFileFromBlob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return serveFileFromBlob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This returns the file from the blob for the requested file.
   * These requests are called as the index.htm file is loaded.
   * The ClientID will be used to verify that the session is still valid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serveFileFromBlob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serveFileFromBlob$Plain(params: ServeFileFromBlob$Plain$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.serveFileFromBlob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /**
   * This returns the file from the blob for the requested file.
   * These requests are called as the index.htm file is loaded.
   * The ClientID will be used to verify that the session is still valid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serveFileFromBlob()` instead.
   *
   * This method doesn't expect any request body.
   */
  serveFileFromBlob$Response(params: ServeFileFromBlob$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return serveFileFromBlob(this.http, this.rootUrl, params, context);
  }

  /**
   * This returns the file from the blob for the requested file.
   * These requests are called as the index.htm file is loaded.
   * The ClientID will be used to verify that the session is still valid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serveFileFromBlob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serveFileFromBlob(params: ServeFileFromBlob$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.serveFileFromBlob$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

}
