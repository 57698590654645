/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SystemMessageForEditingVm } from '../../models/system-message-for-editing-vm';

export interface GetForEditing$Params {
  id: number;
}

export function getForEditing(http: HttpClient, rootUrl: string, params: GetForEditing$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemMessageForEditingVm>> {
  const rb = new RequestBuilder(rootUrl, getForEditing.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SystemMessageForEditingVm>;
    })
  );
}

getForEditing.PATH = '/systemmessages/{id}/edit';
