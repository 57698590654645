import { Component, Input, OnChanges, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { TabComponent } from './tab.component';
import { FrontendContextService, PermissionService, ScrollToService } from 'root/services';
import { MibpTab, MibpTabRoute } from './tabs.types';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
@Component({
  selector: 'mibp-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activeTabIndex = 0;
  @Input() stopScrollToTopOnClick = false;
  @Input() noMargin: boolean;
  @Input() compactLayout = false;
  @Input() tabs: MibpTab[] = [];
  @Input() bordered = false;
  @Input() tabStyle: 'boxed' | 'plain' | 'subnavigation' = 'boxed';
  @Input() tabBgColor: 'primary' | 'secondary' = 'primary';
  @Output() activeTabIndexChange: EventEmitter<number> = new EventEmitter<number>();
  /**
   * Set to TRUE to automatically redirect to the first tab if no child route is activated
   * Only works when using routed tabs
   */
  @Input() automaticRedirect = false;

  visibleTabs: MibpTab[] = [];
  hasChildTabComponents = false;
  deliverySequenceSubscription: Subscription;
  routerSubscription: Subscription;

  constructor(private frontendContext: FrontendContextService, private router: Router, private sessionService: MibpSessionService, private route: ActivatedRoute,
     private permissionService: PermissionService, private scrollService: ScrollToService) {}

  ngOnInit(): void {
    this.deliverySequenceSubscription = this.sessionService.activeDeliverySequence$.subscribe(() => this.ngOnChanges());
    this.setVisibleTabs();

    // Make sure a tab is selected
    if (this.automaticRedirect) {
      this.routerSubscription = this.router.events.subscribe(f => {
        if (f instanceof NavigationEnd) {
          this.navigateToFirstRoute();
        }
      });
      this.navigateToFirstRoute();
    }
  }

  clickTab(): void {
    if (this.stopScrollToTopOnClick === true) {
      this.scrollService.stopNextScrollToTop();
    }
  }

  ngOnChanges(): void {
    this.setVisibleTabs();
  }

  ngOnDestroy(): void {
    if (this.deliverySequenceSubscription) {
      this.deliverySequenceSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  setVisibleTabs(): void {
    if (this.tabs) {
      this.visibleTabs = this.tabs.map(tab => this.normalizeTab(tab)).filter(tab => this.isAllowedToSee(tab));
    } else {
      this.visibleTabs = [];
    }
  }

  normalizeTab(tab: MibpTab): MibpTab {

    if (tab.route) {
      if (Array.isArray(tab.route)) {
        if (tab.route.length > 0) {
          tab.route = {
            path: tab.route
          } as MibpTabRoute;
        } else {
          tab.route = null;
        }
      } else if (tab.route.path) {
        tab.route.routerLinkActiveOptions = tab.route.routerLinkActiveOptions || { exact: false };
      }
    }
    return tab;
  }

  addTab(t: TabComponent): void {
    // Child tabs will invoke this when constructed so we can keep track of the tabs
    this.hasChildTabComponents = true;
    this.tabs.push(t);
    this.tabs.forEach((tab, index) => {
      tab.tabIndex = index;
      tab.active = index === this.activeTabIndex;
    });
  }

  isAllowedToSee(tab: MibpTab): boolean {
    if (tab.hideTab) {
      return false;
    }
    if (tab.permissionPolicy) {
      return this.permissionService.test(tab.permissionPolicy);
    }
    return true;
  }

  updateTabVisibility(t: TabComponent, isHidden: boolean): void {
    const tab = this.tabs.find(f => f == t);
    if (tab != null) {
      tab.hideTab = isHidden;
      this.setVisibleTabs();
    }
  }


  // Tries to navigate to the first available tab when using routing
  navigateToFirstRoute(): void {
    const yesGoToTab = this.route.snapshot.firstChild == null ||
      !!(this.route.snapshot.firstChild && this.route.snapshot.firstChild.url && this.route.snapshot.firstChild.url.length === 0);

    if (yesGoToTab ) {
      if (this.visibleTabs.length > 0) {

        if ( !Array.isArray(this.visibleTabs[0].route)) {
          const route = this.visibleTabs[0].route.path;
          if (route) {
            const options: NavigationExtras = {  };
            if (!route[0].startsWith('/')) {
              options.relativeTo = this.route;
              this.router.navigate(route, { relativeTo: this.route, replaceUrl: true  });
            }
          }
        }
      }
    }
  }

  setActiveTab(index: number): void {
    this.activeTabIndex = index;
    this.visibleTabs.forEach(x => x.active = index === x.tabIndex);
  }

  selectTab(event, index: number): void {
    this.clickTab();
    event.preventDefault();
    this.activeTabIndex = index;
    this.activeTabIndexChange.emit(this.activeTabIndex);
    this.visibleTabs.forEach(x => x.active = index === x.tabIndex);
  }
}
