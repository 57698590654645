<div class="forms__row" *ngIf="fileList && fileList.length > 0">
  <label><mibp-resource-string key="Global_DisplayName"></mibp-resource-string></label>
  <div class="quick-add quick-add-multiple quick-add-multifile" *ngFor='let file of fileList; let i = index'>
    <div class="quick-add-row">
      <div class="quick-add-parts-number"><input class="form-control" type="text" name="displayname" id="displayname" maxlength="50" [value]="file.displayName" (change)="displayNameChangeEvent($event,i)"></div>
      <div class="quick-add-item-action"><mibp-button [icon]="btn.Enum.Icons.Trash" [color]="btn.Enum.Colors.Orange" #btn (click)="removeFile(i)"></mibp-button></div>
      <p>{{file.title}}</p>
    </div>
  </div>
</div>

<div class="forms__row">
  <mibp-button resourceStringKey="Global_Add_File" (click)="addFile()" [disabled]="fileList.length == maxNrOfFiles"></mibp-button>
  <input type="file" #fileInput id="fileInput" [accept]="fileTypes" style="display:none;" class="inputfile inputfile-6" (change)="fileChangeEvent($event)">
</div>
