/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CustomersGraphViewModel } from '../../models/customers-graph-view-model';
import { InternalReportOptions } from '../../models/internal-report-options';

export interface GetCustomerReportData$Params {
      body?: InternalReportOptions
}

export function getCustomerReportData(http: HttpClient, rootUrl: string, params?: GetCustomerReportData$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomersGraphViewModel>> {
  const rb = new RequestBuilder(rootUrl, getCustomerReportData.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CustomersGraphViewModel>;
    })
  );
}

getCustomerReportData.PATH = '/internalreports/customerreportdata';
