import { MibpException } from './../../../services/error-handler/error-handler.service';
import { SignalR_ApiResponse } from 'root/services/mibp-api-services';
import { MibpGridError, MibpGridErrorInternal, MibpGridFormatContext } from './../mibp-grid.types';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Subject } from "rxjs";
import { MibpGridDataSource, MibpGridDataSourceConfig, MibpGridFieldFormatOptions, MibpGridOptionBuilder } from "../mibp-grid.types";
import { UrlHelperService } from "root/services/url-helper/url-helper.service";
import { FrontendContextService } from "root/services/front-end-context/front-end-context.service";
import { LogService, MibpLogger } from "root/services/logservice";
import { Router } from "@angular/router";
import { LocalizationService } from 'root/services/localization/localization.service';
import { MibpGridOptions } from './../../../mibp-openapi-gen/models/mibp-grid-options';
import { MibpGridFacet, MibpGridResponse } from 'root/mibp-openapi-gen/models';


@Injectable()
export class MibpGridService {

  private incrementalId = 0;
  private log: MibpLogger;
  public loadFilters: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private urlHelper: UrlHelperService,
    private router: Router, private frontendContext: FrontendContextService, logger: LogService,
    private localizationService: LocalizationService) {
    this.log = logger.withPrefix('mibp-grid-service');
  }

  private bindings: {
    [id: string]: {
      apiMethod: (options: MibpGridOptions) => Observable<MibpGridResponse | MibpGridError>,
      dataSource: MibpGridDataSource,
      subject: Subject<MibpGridResponse | MibpGridErrorInternal>
  }} = {};

  public mapStringToFacet(value: string): MibpGridFacet {

    if (!value) {
      return {
        text: this.getEmptyFacetText(),
        value: null
      };
    }


    if (typeof value === 'string') {
      const m = value.match(/^(.*?)\|\((.+)\)$/);
      if (m) {
        return {
          text: m[1],
          value: m[2]
        };
      }
    }
    return {
      text: value,
      value: value
    };
  }

  public getEmptyFacetText(): string {
    return this.localizationService.get('Grid_Facet_Empty');
  }


  public bind(apiMethod: (options: MibpGridOptions) => Observable<MibpGridResponse>, setup?: (builder: MibpGridOptionBuilder) => void ): MibpGridDataSource {
    const sub = new Subject<MibpGridResponse>();
    const id = `grid${this.incrementalId}`;
    this.log.debug('DataSource bound', id);
    this.incrementalId++;

    const config: MibpGridDataSourceConfig = {
      customFormatters: {},
      errorHandler: undefined
    };
    if (setup) {
      const bld: MibpGridOptionBuilder = {
        setFieldFormatter(fieldId: string, cb: (value: string, context: MibpGridFormatContext) => string | MibpGridFieldFormatOptions): MibpGridOptionBuilder {
          config.customFormatters[fieldId] = cb;
          return bld;
        },
        setErrorHandler(callback: (error: any) => MibpGridError): MibpGridOptionBuilder {
          config.errorHandler = callback;
          return bld;
        }
      };
      setup(bld);
    }


    this.bindings[id] = {
      subject: sub,
      apiMethod: apiMethod,
      dataSource: {
        _cfg: config,
        refresh: options => {
          this.log.debug('DataSource refresh', id);
          this.refreshData(options, id, config);
        },
        data$: sub.asObservable(),
        destroy: () => {
          if (this.bindings[id].subject) {
            this.bindings[id].subject.complete();
          }
          this.log.debug('DataSource destroyed', id);
        }
      } as MibpGridDataSource
    };
    return this.bindings[id].dataSource;
  }

  navigateWithGridConfig(url: string, queryParams?: { [key: string]: string }) {
    const parsed = this.urlHelper.parseUrl(this.router.url.toString() );
    const editUrl = `${parsed.path}/${url}`;
    this.router.navigate([editUrl], {
      queryParamsHandling: 'merge',
      queryParams: queryParams
    });
  }

  /**
   * Extract grid filters form the current url
   * and add them to the specified route
   * This can be used to go back to a grid filter page if you've
   * passed along the filters
   */
  public createBackUrl(gridName: string, route: string) {
    const parsed = this.urlHelper.parseUrl();
    const matches = Object.keys(parsed.query)
      .filter(querystringParameter => querystringParameter.indexOf(`${gridName}.`) === 0);

    const newUrl = this.urlHelper.parseUrl(route);
    matches.forEach(m => { newUrl.query[m] = parsed.query[m]; });

    return this.frontendContext.Navigation.ensureUrlLanguage(newUrl.toString());
  }

  private refreshData(options: MibpGridOptions, id: string, config: MibpGridDataSourceConfig): void {
    if (this.bindings[id]) {
      this.bindings[id].apiMethod(options).subscribe(gridresponse => {
        this.bindings[id].subject.next(gridresponse as MibpGridResponse);
      },
      err => {
        let handlerInvoked = false;
        let gridError = {
          isGridError: true,
          titleResourceStringKey: 'General_Error_InlineTitle',
          messageIsHtml: false
        } as MibpGridErrorInternal;

        if (err instanceof MibpException) {
          if (err.stack.errorCode) {
            if (config.errorHandler) {
              delete err.stack.response;
              const userErrorMessage = config.errorHandler(err.stack as SignalR_ApiResponse);
              if (userErrorMessage) {
                gridError = Object.assign(gridError, userErrorMessage, {isGridError: true});
              }
              handlerInvoked = true;
            }
          }
        }

        if (!handlerInvoked) {
          if (config.errorHandler) {
            const userErrorMessage = config.errorHandler({
              success: false,
              error: err.message || 'Unhandled exception',
              errorCode: 'UNHANDLED'
            });
            if (userErrorMessage) {
              gridError = Object.assign(gridError, userErrorMessage, {isGridError: true});
            }
          }
        }

        this.log.error('Error fetching grid data', err);

        this.bindings[id].subject.next(gridError);
      });
    }
  }
  public loadFilterSeparatly() : void {
    setTimeout(() => {
      this.loadFilters.next(true);
    });
  }

}
