<div *ngIf="!oldLook" class="progress" [class.progress--hide-top-border]="hideTopBorder">
    <ol>
      <li *ngFor="let resourceKey of steps; let i = index"
        [class.current]="i === step"
        [class.done]="i < step">
        <strong *ngIf="i === step">
          <mibp-resource-string [key]="resourceKey"></mibp-resource-string>
        </strong>
        <span *ngIf="i != step">
          <mibp-resource-string [key]="resourceKey"></mibp-resource-string>
        </span>
      </li>
    </ol>
  </div>


  <div *ngIf="oldLook" class="order-flow">
    <div [class.active]="i === step" *ngFor="let resourceKey of steps; let i = index">
      <span translate="no" class="material-icon material-icon--small" *ngIf="i === steps.length - 1">double_arrow</span>
      <span>{{ i + 1 }}</span>
      <mibp-resource-string [key]="resourceKey"></mibp-resource-string>
    </div>
  </div>
