import { Refiner, ListRefiner, TextRefiner } from 'root/components/refiner/refiner.types';
import { NoticeType } from './../noticebar/noticebar.enum';
import { DialogService } from './../../services/dialog/dialog.service';
import { Component,  ElementRef, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { ResourceStringEditorService, ApiService,
       LocalizationService,NoticebarService } from 'root/services';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogButton } from '../dialog/dialog.types';
import { ButtonColors } from '../button/button.enum';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { RefinerComponent } from '../refiner/refiner.component';
import { ResourceType,ResourceTranslationVm } from 'root/mibp-openapi-gen/models';
import { ResourceApiController } from 'root/mibp-openapi-gen/controllers';
import { firstValueFrom } from 'rxjs';
import { LogService, MibpLogger } from 'root/services/logservice';

@Component({
  selector: 'mibp-resource-string-editor',
  templateUrl: './resource-string-editor.component.html',
  styleUrls: ['./resource-string-editor.component.scss']
})
export class ResourceStringEditorComponent implements AfterViewInit  {

  @ViewChild('dialog') dialog: DialogComponent;
  editMode = false;
  translation: ResourceTranslationVm;
  translatedValue: string;
  languageCode: string;
  isOpen = false;
  dialogButtons: DialogButton[];
  isHtml = false;
  resourceStringForm: UntypedFormGroup;
  isWorking = false;
  availableResourceStrings: any[];
  isLoadingResourceEdit: boolean;
  editorModeEnabled: boolean;
  optionsForm: UntypedFormGroup;
  resourceStringResult;
  private editFromCurrentPage = false;
  key: string;
  log: MibpLogger;

  @ViewChild("textElement", { read: ElementRef }) textElement: ElementRef;
  @ViewChild("textareaElement", { read: ElementRef }) textareaElement: ElementRef;
  @ViewChild(RefinerComponent) refiner: RefinerComponent;

  refinerConfig = <Refiner[]>[
    new TextRefiner('query', 'ResourceString_Search' ),
    new ListRefiner('scope', 'ResourceString', {all: 'All', currentPage: 'Current page'}, 'currentPage'),
    new ListRefiner('translation', 'ResourceString_TransStatus', {all: 'All', missing: 'Not translated'}, 'all')

  ];

  constructor(private noticebarService: NoticebarService,
    private cd: ChangeDetectorRef, private fb: UntypedFormBuilder,
    private api: ApiService, private resourceStringEditorService: ResourceStringEditorService,
    private localization: LocalizationService, private resourceApiController: ResourceApiController,
    private logger: LogService) {
    resourceStringEditorService.setComponentInstance(this);
    this.log = logger.withPrefix('resource-string-editor');
  }

  previousFilter;

  filterResourceStrings(e) {
    if (!e) {
      e = this.previousFilter;
    }

    const scopeValue = e.after.find(i => i.name === 'scope').value;
    const queryValue = e.after.find(i => i.name === 'query') ? e.after.find(i => i.name === 'query').value : null;
    const untranslatedOnly = e.after.find(i => i.name === 'translation').value === 'missing';

    let keys = null;


    if (scopeValue === 'currentPage') {
      keys = this.localization.getAvailableResourceStrings();
    }

    this.resourceStringResult =
   this.resourceApiController.findResource({languageCode : this.languageCode,
                                           query : queryValue, keys:keys,
                                           onlyUntranslated : untranslatedOnly});
    this.previousFilter = e;
  }

  ngAfterViewInit() {
    this.optionsForm = this.fb.group({
      editorEnabled: [this.resourceStringEditorService.isEditMode()]
    });

  }

  private updateButtons(disableSave = false) {
    this.dialogButtons = [{
      resourceKey: 'dialog_SaveButton',
      id: 'ok',
      color: ButtonColors.Blue,
      disabled: disableSave || this.isWorking
    },
    {
      resourceKey: 'dialog_CancelButton',
      id: 'cancel',
      color: ButtonColors.Orange,
      disabled : this.isWorking
    }];
  }

  ngonInit(){
    this.updateButtons();
  }
  editorModeChange(enabled) {
    this.resourceStringEditorService.setEditMode(enabled);
  }


  onDialogClosed() {
    this.isOpen = false;
  }

  formChanged(e) {
    this.updateButtons(!this.resourceStringForm.valid);
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.resourceStringForm.valid) {
      this.cd.detectChanges();
      this.save();
    }
  }

  onDialogButton(btn: DialogButton) {
    if (btn.id === 'close') {
      this.dialog.close();
    } else {
      this.save();
    }
  }


  cancelEdit() {
    if (this.editFromCurrentPage) {
      this.dialog.close();
    } else {
      this.showResourceFinder(null);
    }
  }



  private save() {
    this.isWorking = true;
    this.updateButtons();

    firstValueFrom(this.resourceApiController.saveResource({body:{key:this.translation.resourceKey.toString(),
                   languageCode: this.languageCode.toString(), value :this.resourceStringForm.value.value.toString()}
      })).then(ok => {
      this.localization.getResourceStrings(true);
      this.resourceStringForm = null;
      this.translation = null;
      this.isWorking = false;
      this.updateButtons();
      this.resourceStringResult = null;
      if (this.editFromCurrentPage) {
        this.dialog.close();
      } else {
        this.showResourceFinder(null);
      }
    }).catch(err => {
      this.noticebarService.show('Error_SavingResourceString', NoticeType.Error);
      this.isWorking = false;
      this.updateButtons();
      if (this.resourceStringForm) {
        this.resourceStringForm.controls.value.disable();
      }
    });
  }

  public showEditor(key: string) {
    this.editMode = true;
    this.isLoadingResourceEdit = true;
    this.updateButtons();
    this.resourceApiController.getTranslation({languageCode: this.languageCode, key: this.key}).subscribe(oki => {
        this.resourceStringForm = this.fb.group({
        value: [oki.value, [Validators.required]]
      });

      this.cd.detectChanges();
      this.resourceStringForm.markAsUntouched();
      this.resourceStringForm.markAsPristine();
      this.translation = oki;
      this.isHtml = oki.type === ResourceType.Html;
      this.isLoadingResourceEdit = false;
      setTimeout(() => {
        if (this.isHtml) {
          this.textareaElement.nativeElement.focus();
        } else {
          this.textElement.nativeElement.focus();
        }
      }, 100);
    }, error => {
      this.noticebarService.showText(error.stack.error, NoticeType.Error, false);
      this.log.error("Could not find translation", error);
    });
  }

  private showResourceFinder(refreshList: boolean) {
    this.editMode = false;

    if (refreshList) {
      this.filterResourceStrings(null);
    }

    this.dialogButtons = [{
      resourceKey: 'Global_Dialog_Close',
      id: 'close',
      color: ButtonColors.Orange,
      disabled : this.isWorking
    }];
  }



  public open(key: string, lang: string, enableCurrentPageFilter = false, enableUntranslatedFilter = false) {

    if (this.isOpen) {
      if (!confirm('This will close the current Resource String Editor\n\nAre you sure?')) {
        return;
      }
    }

    this.languageCode = lang;
    this.editFromCurrentPage = false;

    if (key === null) {

      this.refinerConfig = <Refiner[]>[
        new TextRefiner('query', 'ResourceString_Search' ),
        new ListRefiner('scope', 'ResourceString',
          {all: 'All', currentPage: 'Current page'}, enableCurrentPageFilter ? 'currentPage' : 'all'),
        new ListRefiner('translation', 'ResourceString_TransStatus',
          {all: 'All', missing: 'Not translated'}, enableUntranslatedFilter ? 'missing' : 'all')
      ];

      this.showResourceFinder(null);
      this.dialog.open();
      this.isOpen = true;

    } else {
      this.editFromCurrentPage = true;
      this.key = key;
      this.showEditor(key);
      this.dialog.open();
      this.isOpen = true;
    }
  }




}
