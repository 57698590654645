import { Directive, ElementRef, Input} from '@angular/core';
import { LocalizationService } from 'root/services';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mibpResourceString.title]'
})
export class MibpResourceStringTitleDirective {


  private stopUsingResource: () => void;
  private attributeValue: string;
  private currentTitleResourceKey: string;

  constructor(private elementRef: ElementRef, private localizationService: LocalizationService) {}

    @Input('mibpResourceString.title')
  set titleResourceKey(value: string) {
    this.currentTitleResourceKey = value;
    this.startUsingResource();
  }

    private startUsingResource() {
      if (this.stopUsingResource) {
        this.stopUsingResource();
      }

      const resourceKeys = [
        this.currentTitleResourceKey
      ].filter(key => key);

      if (resourceKeys.length > 0) {
        this.stopUsingResource = this.localizationService.using([this.currentTitleResourceKey], values => {
          this.attributeValue = values[0];
          this.updateAttribute();
        });
      }

    }

    private getAttributeName(): string {
      if (this.currentTitleResourceKey) {
        return `title`;
      }
      return null;
    }

    private updateAttribute() {
      const attrName = this.getAttributeName();
      if (!attrName) {
        return;
      }

      const el = <HTMLElement>this.elementRef.nativeElement;
      if (el) {
        if (this.attributeValue) {
          el.setAttribute(attrName, this.attributeValue);
        } else {
          el.removeAttribute(attrName);
        }
      }
    }
}
