<ng-template #dialogTemplate let-name>

  <div [class]="modalClassName" *ngIf="active" [style.zIndex]="zIndex" [id]="id">
    <div class="modal-background"></div>
    <div class="modal-card" [class.fullheight]="fullheight">
      <header class="modal-card-head" >
        <p *ngIf="rawTitle" class="modal-card-title">
          <span>{{rawTitle}}</span>
        </p>
        <mibp-button *ngIf="!hideCloseButton" aria-label="close" class="modal-close" (click)="close()"></mibp-button>
      </header>
      <section class="modal-card-body body" [style.minHeight]="minHeight ? minHeight + 'px' : null">
        <div class="my-body" *ngIf="rawHtml" [innerHtml]="rawHtml"></div>
        <div class="simplebody" *ngIf="resourceStringKey || rawText">
          <p>
            <span *ngIf="rawText">{{ rawText }}</span>
          </p>

        </div>

        <ng-content></ng-content>
      </section>


    </div>
  </div>

</ng-template>
