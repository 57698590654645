/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { completeMaintenance } from '../fn/equipment-calendars/complete-maintenance';
import { CompleteMaintenance$Params } from '../fn/equipment-calendars/complete-maintenance';
import { deleteEquipmentCalendar } from '../fn/equipment-calendars/delete-equipment-calendar';
import { DeleteEquipmentCalendar$Params } from '../fn/equipment-calendars/delete-equipment-calendar';
import { deleteMaintenance } from '../fn/equipment-calendars/delete-maintenance';
import { DeleteMaintenance$Params } from '../fn/equipment-calendars/delete-maintenance';
import { EquipmentCalendarForEditingViewModel } from '../models/equipment-calendar-for-editing-view-model';
import { EquipmentCalendarViewModelSearchResponse } from '../models/equipment-calendar-view-model-search-response';
import { EquipmentMaintenancePlanSummaryViewModel } from '../models/equipment-maintenance-plan-summary-view-model';
import { GetEquipmentMaintenancePlanQueryResponseViewModel } from '../models/get-equipment-maintenance-plan-query-response-view-model';
import { getEquipmentMaintenancePlanSummary } from '../fn/equipment-calendars/get-equipment-maintenance-plan-summary';
import { GetEquipmentMaintenancePlanSummary$Params } from '../fn/equipment-calendars/get-equipment-maintenance-plan-summary';
import { getEquipmentMaintenancePlanSummary$Plain } from '../fn/equipment-calendars/get-equipment-maintenance-plan-summary-plain';
import { GetEquipmentMaintenancePlanSummary$Plain$Params } from '../fn/equipment-calendars/get-equipment-maintenance-plan-summary-plain';
import { getForEditingByEquipmentId } from '../fn/equipment-calendars/get-for-editing-by-equipment-id';
import { GetForEditingByEquipmentId$Params } from '../fn/equipment-calendars/get-for-editing-by-equipment-id';
import { getForEditingByEquipmentId$Plain } from '../fn/equipment-calendars/get-for-editing-by-equipment-id-plain';
import { GetForEditingByEquipmentId$Plain$Params } from '../fn/equipment-calendars/get-for-editing-by-equipment-id-plain';
import { getMultipleEquipmentMaintenancePlan } from '../fn/equipment-calendars/get-multiple-equipment-maintenance-plan';
import { GetMultipleEquipmentMaintenancePlan$Params } from '../fn/equipment-calendars/get-multiple-equipment-maintenance-plan';
import { getMultipleEquipmentMaintenancePlan$Plain } from '../fn/equipment-calendars/get-multiple-equipment-maintenance-plan-plain';
import { GetMultipleEquipmentMaintenancePlan$Plain$Params } from '../fn/equipment-calendars/get-multiple-equipment-maintenance-plan-plain';
import { Int32SearchResponse } from '../models/int-32-search-response';
import { save } from '../fn/equipment-calendars/save';
import { Save$Params } from '../fn/equipment-calendars/save';
import { save$Plain } from '../fn/equipment-calendars/save-plain';
import { Save$Plain$Params } from '../fn/equipment-calendars/save-plain';
import { search } from '../fn/equipment-calendars/search';
import { Search$Params } from '../fn/equipment-calendars/search';
import { search$Plain } from '../fn/equipment-calendars/search-plain';
import { Search$Plain$Params } from '../fn/equipment-calendars/search-plain';

@Injectable({ providedIn: 'root' })
export class EquipmentCalendarsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `equipmentCalendarsControllerSearchrequest()` */
  static readonly EquipmentCalendarsControllerSearchrequestPath = '/equipmentcalendars/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain$Response(params?: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentCalendarViewModelSearchResponse>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain(params?: Search$Plain$Params, context?: HttpContext): Observable<EquipmentCalendarViewModelSearchResponse> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentCalendarViewModelSearchResponse>): EquipmentCalendarViewModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentCalendarViewModelSearchResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<EquipmentCalendarViewModelSearchResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentCalendarViewModelSearchResponse>): EquipmentCalendarViewModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentCalendarsControllerSaverequest()` */
  static readonly EquipmentCalendarsControllerSaverequestPath = '/equipmentcalendars';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Plain$Response(params?: Save$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32SearchResponse>> {
    return save$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Plain(params?: Save$Plain$Params, context?: HttpContext): Observable<Int32SearchResponse> {
    return this.save$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32SearchResponse>): Int32SearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32SearchResponse>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<Int32SearchResponse> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32SearchResponse>): Int32SearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentCalendarsControllerGetForEditingByEquipmentIdequipmentId()` */
  static readonly EquipmentCalendarsControllerGetForEditingByEquipmentIdequipmentIdPath = '/equipmentcalendars/edit/equipment/{equipmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditingByEquipmentId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditingByEquipmentId$Plain$Response(params: GetForEditingByEquipmentId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentCalendarForEditingViewModel>> {
    return getForEditingByEquipmentId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditingByEquipmentId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditingByEquipmentId$Plain(params: GetForEditingByEquipmentId$Plain$Params, context?: HttpContext): Observable<EquipmentCalendarForEditingViewModel> {
    return this.getForEditingByEquipmentId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentCalendarForEditingViewModel>): EquipmentCalendarForEditingViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditingByEquipmentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditingByEquipmentId$Response(params: GetForEditingByEquipmentId$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentCalendarForEditingViewModel>> {
    return getForEditingByEquipmentId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditingByEquipmentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditingByEquipmentId(params: GetForEditingByEquipmentId$Params, context?: HttpContext): Observable<EquipmentCalendarForEditingViewModel> {
    return this.getForEditingByEquipmentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentCalendarForEditingViewModel>): EquipmentCalendarForEditingViewModel => r.body)
    );
  }

  /** Path part for operation `equipmentCalendarsControllerGetEquipmentMaintenancePlanSummaryrequest()` */
  static readonly EquipmentCalendarsControllerGetEquipmentMaintenancePlanSummaryrequestPath = '/equipmentcalendars/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentMaintenancePlanSummary$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentMaintenancePlanSummary$Plain$Response(params?: GetEquipmentMaintenancePlanSummary$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentMaintenancePlanSummaryViewModel>> {
    return getEquipmentMaintenancePlanSummary$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentMaintenancePlanSummary$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentMaintenancePlanSummary$Plain(params?: GetEquipmentMaintenancePlanSummary$Plain$Params, context?: HttpContext): Observable<EquipmentMaintenancePlanSummaryViewModel> {
    return this.getEquipmentMaintenancePlanSummary$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentMaintenancePlanSummaryViewModel>): EquipmentMaintenancePlanSummaryViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentMaintenancePlanSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentMaintenancePlanSummary$Response(params?: GetEquipmentMaintenancePlanSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentMaintenancePlanSummaryViewModel>> {
    return getEquipmentMaintenancePlanSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentMaintenancePlanSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentMaintenancePlanSummary(params?: GetEquipmentMaintenancePlanSummary$Params, context?: HttpContext): Observable<EquipmentMaintenancePlanSummaryViewModel> {
    return this.getEquipmentMaintenancePlanSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentMaintenancePlanSummaryViewModel>): EquipmentMaintenancePlanSummaryViewModel => r.body)
    );
  }

  /** Path part for operation `equipmentCalendarsControllerGetMultipleEquipmentMaintenancePlanequipmentIdsTimezoneOffsetYearMonthNumberOfMonths()` */
  static readonly EquipmentCalendarsControllerGetMultipleEquipmentMaintenancePlanequipmentIdsTimezoneOffsetYearMonthNumberOfMonthsPath = '/equipmentcalendars/equipment/mutliple/calendar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMultipleEquipmentMaintenancePlan$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMultipleEquipmentMaintenancePlan$Plain$Response(params?: GetMultipleEquipmentMaintenancePlan$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetEquipmentMaintenancePlanQueryResponseViewModel>> {
    return getMultipleEquipmentMaintenancePlan$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMultipleEquipmentMaintenancePlan$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMultipleEquipmentMaintenancePlan$Plain(params?: GetMultipleEquipmentMaintenancePlan$Plain$Params, context?: HttpContext): Observable<GetEquipmentMaintenancePlanQueryResponseViewModel> {
    return this.getMultipleEquipmentMaintenancePlan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEquipmentMaintenancePlanQueryResponseViewModel>): GetEquipmentMaintenancePlanQueryResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMultipleEquipmentMaintenancePlan()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMultipleEquipmentMaintenancePlan$Response(params?: GetMultipleEquipmentMaintenancePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<GetEquipmentMaintenancePlanQueryResponseViewModel>> {
    return getMultipleEquipmentMaintenancePlan(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMultipleEquipmentMaintenancePlan$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMultipleEquipmentMaintenancePlan(params?: GetMultipleEquipmentMaintenancePlan$Params, context?: HttpContext): Observable<GetEquipmentMaintenancePlanQueryResponseViewModel> {
    return this.getMultipleEquipmentMaintenancePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetEquipmentMaintenancePlanQueryResponseViewModel>): GetEquipmentMaintenancePlanQueryResponseViewModel => r.body)
    );
  }

  /** Path part for operation `equipmentCalendarsControllerDeleteEquipmentCalendarequipmentId()` */
  static readonly EquipmentCalendarsControllerDeleteEquipmentCalendarequipmentIdPath = '/equipmentcalendars/equipment/{equipmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEquipmentCalendar()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEquipmentCalendar$Response(params: DeleteEquipmentCalendar$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteEquipmentCalendar(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEquipmentCalendar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEquipmentCalendar(params: DeleteEquipmentCalendar$Params, context?: HttpContext): Observable<void> {
    return this.deleteEquipmentCalendar$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `equipmentCalendarsControllerCompleteMaintenanceequipmentIdEquipmentHoursKitIntervalHoursIsoYearIsoWeek()` */
  static readonly EquipmentCalendarsControllerCompleteMaintenanceequipmentIdEquipmentHoursKitIntervalHoursIsoYearIsoWeekPath = '/equipmentcalendars/equipment/{equipmentId}/{equipmentHours}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeMaintenance()` instead.
   *
   * This method doesn't expect any request body.
   */
  completeMaintenance$Response(params: CompleteMaintenance$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return completeMaintenance(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeMaintenance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  completeMaintenance(params: CompleteMaintenance$Params, context?: HttpContext): Observable<void> {
    return this.completeMaintenance$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `equipmentCalendarsControllerDeleteMaintenanceequipmentIdEquipmentHours()` */
  static readonly EquipmentCalendarsControllerDeleteMaintenanceequipmentIdEquipmentHoursPath = '/equipmentcalendars/equipment/{equipmentId}/{equipmentHours}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMaintenance()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMaintenance$Response(params: DeleteMaintenance$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMaintenance(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMaintenance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMaintenance(params: DeleteMaintenance$Params, context?: HttpContext): Observable<void> {
    return this.deleteMaintenance$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
