import { environment } from "root/environment";
import { MySandvikNavigationItem } from "../navigation-types";
import { allPermissionPolicies } from 'root/all-permission-policies';

export const NAVITEMS_MYROCKTOOLS: MySandvikNavigationItem = {
  resourceKey: 'MyRockTools_Title',
  sandvikIconName: 'construction',
  uniqueId: 'myrocktools',
  permissionPolicy: allPermissionPolicies.viewMyRockTools,
  externalUrl: null
};


