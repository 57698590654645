import { MibpLogger } from './../../services/logservice/mibplogger.class';
import { firstValueFrom } from 'rxjs';
import { UserFileApiController } from './../../mibp-openapi-gen/services/user-file-api-controller';
import { Component, Input } from '@angular/core';
import { SignalR_UserFile, MibpHttpApi, NoticebarService, LogService } from 'root/services';
import { Guid } from 'guid-typescript';
import { HttpErrorResponse } from '@angular/common/http';
import { NoticeType } from '../noticebar/noticebar.enum';
import { Logger } from '@azure/msal-browser';

@Component({
  selector: 'mibp-userfile-list',
  templateUrl: './userfile-list.component.html',
  styleUrls: ['./userfile-list.component.scss']
})
export class UserfileListComponent {

  @Input() userFiles: SignalR_UserFile[];
  @Input() bulletinData: any;
  @Input() onFileDownload: (args: any) => void;
  @Input() isOpenPdfViewer = false;
  log: MibpLogger;


  constructor(private httpApi: MibpHttpApi,
     private userFileApi:  UserFileApiController,
      private noticebarService: NoticebarService,
      private logger: LogService) {
    this.log = logger.withPrefix('userfile-list');
  }

  handleClickEvent(blobId: Guid){
    if(this.isOpenPdfViewer){
      this.navigateToPDFViewerInNewTab(blobId);
    }
    else{
      this.triggerFileDownload(blobId);
    }
  }

  triggerFileDownload(blobId: Guid) {
    if (this.onFileDownload !== undefined) {
      this.onFileDownload(this.bulletinData);
    }
    firstValueFrom(this.userFileApi.fileExists({ id: blobId.toString() })).then(()=> {
      this.httpApi.UserFile.triggerDownload(blobId);
    })
      .catch(e => {
        if (e instanceof HttpErrorResponse && e.status == 404) {
          this.noticebarService.show('DownloadFile_Error_NotFound', NoticeType.Error);
          this.log.error('File was not found', e.error);
        } else {
          this.noticebarService.show('DownloadFile_Error_Unhandled', NoticeType.Error);
          this.log.error('Unexpected error when downloading file', e.error);
        }
      });
  }

  navigateToPDFViewerInNewTab(blobId: Guid){
    firstValueFrom(this.userFileApi.fileExists({ id: blobId.toString() })).then(()=> {
      const url = this.httpApi.resolveUriWithJwtToken(`userfile/${blobId.toString()}`);
      window.open(url, '_blank');
    })
      .catch(e => {
        if (e instanceof HttpErrorResponse && e.status == 404) {
          this.noticebarService.show('DownloadFile_Error_NotFound', NoticeType.Error);
          this.log.error('File was not found', e.error);
        } else {
          this.noticebarService.show('DownloadFile_Error_Unhandled', NoticeType.Error);
          this.log.error('Unexpected error when downloading file', e.error);
        }
      });
  }

  trackByUserfileId(file: SignalR_UserFile) {
    return file.id;
  }

  getFileIcon(file: SignalR_UserFile): string {
    if (file.fileName) {
      if (file.fileName.match(/\.docx?$/i)) {
        return 'draft';
      } else if (file.fileName.match(/\.pdf$/i)) {
        return 'picture_as_pdf';
      } else if (file.fileName.match(/\.(jpe?g|gif|tiff|png)$/i)) {
        return 'image';
      } else {
        return 'draft';
      }
    }
    return 'picture_as_pdf';
  }

}
