import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { FrontendContextService, MibpLogger, LogService, ApiService, SignalR_SelectProfileUserViewModel, NoticebarService } from 'root/services';
import { Subscription, firstValueFrom } from 'rxjs';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { PunchoutProfileViewModel } from 'root/mibp-openapi-gen/models';
import { NoticeType } from '../noticebar/noticebar.enum';

@Component({
  selector: 'mibp-profile-picker',
  templateUrl: './profile-picker.component.html',
  styleUrls: ['./profile-picker.component.scss']
})
export class ProfilePickerComponent implements OnInit, OnDestroy {
  @ViewChild('dialog', {static: true}) dialog: DialogComponent;
  isLoading = false;
  log: MibpLogger;
  profileList: PunchoutProfileViewModel[] = [];
  selectProfileSubscription: Subscription;
  loggedInSubscription: Subscription;
  maxNumberOfProfiles = 8;
  profileCount = 0;
  maxAmountExceeded = false;

  timerRefreshProfiles?: number;
  msReloadProfiles = (1 * 60) * 1000;

  // If you come here, user is always a punchout profile user
  profileManagagementEnabled = true;
  acceptedTermsAndConditions: boolean;

  constructor(public context: FrontendContextService,
    private noticebar: NoticebarService,
    private sessionService: MibpSessionService, private api: ApiService, logger: LogService) {
    this.log = logger.withPrefix('profile-picker');
  }

  async ngOnInit(): Promise<void> {

    if (!this.sessionService.isLoggedIn()) {
      return;
    }

    await this.reloadProfileList();

    // Refresh if we get update-events though signalr
    this.api.EventHub.PunchoutProfileUpdated.subscribe(async () => {
      await this.reloadProfileList();
    });

    // Continously update profile list to make sure logged in status is up to date
    this.timerRefreshProfiles = window.setTimeout(async () => await this.reloadProfileList(), this.msReloadProfiles);

    // Subscribe to login events for the users with the same identityObjectId
    this.loggedInSubscription = this.api.EventHub.ProfileLoggedIn.subscribe(event => {
      if (event && event.loggedInProfiles) {
        this.profileList.filter(isNotFalsy => isNotFalsy).forEach(profile => {
          const ix = event.loggedInProfiles.findIndex(userId => userId.toString() === profile.id.toString());
          profile.isLoggedIn = ix !== -1;
        });
      }
    });

  }

  private async reloadProfileList(): Promise<void> {
    try {
      const profiles = await firstValueFrom(await this.sessionService.getPunchoutProfiles());
      this.profileList = profiles;
      this.profileCount = profiles.length;
      this.ensureAddProfileCard();

      this.acceptedTermsAndConditions = this.profileList.find(p=> p.isOriginalPunchoutUser).acceptedTermsAndConditions;

    }  catch (e) {
      this.noticebar.show(`Global_Error_DataCouldNotBeLoaded`, NoticeType.Error);
    }
    this.timerRefreshProfiles = window.setTimeout(async () => await this.reloadProfileList(), this.msReloadProfiles);
  }

  ngOnDestroy() {

    clearTimeout(this.timerRefreshProfiles);

    if (this.selectProfileSubscription) {
      this.selectProfileSubscription.unsubscribe();
    }
    if (this.loggedInSubscription) {
      this.loggedInSubscription.unsubscribe();
    }
  }

  /**
   * Remove or add the "new profile" card
   * Visible if number of profiles is less than max
   * AND the users are PunchoutProfiles
   */
  private ensureAddProfileCard() {

    if (this.profileManagagementEnabled) {
      const newProfileIndex = this.profileList.findIndex(profile => profile === null);
      const maxAmountReached = this.profileCount >= this.maxNumberOfProfiles;
      this.maxAmountExceeded = this.profileCount > this.maxNumberOfProfiles;

      if (maxAmountReached && newProfileIndex !== -1) {
        this.profileList.splice(newProfileIndex, 1);
      } else if (!maxAmountReached && newProfileIndex === -1) {
        this.profileList.push(null);
      }

    }

  }

  /**
   * Important to have trackBy here.
   * Without it the card components will be re-created every time we update an item in the array
   * since Angular can not know how to identify items properly.
   */
  trackByProfileId(index: number, item: SignalR_SelectProfileUserViewModel) {
    return item ? item.id.toString() : null;
  }

}


