import { Router, NavigationExtras } from '@angular/router';
import { Injectable } from "@angular/core";
import { FrontendContextService } from "../front-end-context/front-end-context.service";
import { environment } from "root/environment";
import { MibpLogger, LogService } from "../logservice";
import { CacheScope, ClientSideCacheService } from "../client-side-cache/client-side-cache.service";
import { ApiService } from '../mibp-api-services';
import { BroadcastService } from '../broadcast-service/broadcast.service';
import { GlobalConfigService } from '../global-config/global-config.service';
import { UsersApiController } from './../../mibp-openapi-gen/services/users-api-controller';
import { firstValueFrom } from 'rxjs';
import { MySandvikFeatures, PermissionService } from '../permission';

export interface PartsCatalogueOptions {
  mediaIdentifier?: string;
  partsNumber?: string;
  equipmentId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PartsCatalogueService {
  private log: MibpLogger;
  private userHash: string;
  private isLoggedInValue = false;
  private mysandvik1ping;
  private readonly MYSANDVIK1_PING_MS = 15000;
  private UseB2CDocumoto = false;
  private previewCacheKey = 'disable-new-electronic-manuals-page';
  constructor(private broadcastService: BroadcastService,
    private cache: ClientSideCacheService,
    private usersApi: UsersApiController,
    logger: LogService,
    private router: Router,
    private permission: PermissionService,
    private api: ApiService,
    private globalConfig: GlobalConfigService) {
    this.log = logger.withPrefix('parts-catalogue-service');
    this.UseB2CDocumoto = this.globalConfig.useB2CDocumoto;
    this.broadcastService.documotoSignout.subscribe(isDone => {
      if (isDone === false) {
        this.signout().then(() => {
          this.broadcastService.setDocumotoSignout(true);
        }, () => {
          this.broadcastService.setDocumotoSignout(true);
        });
      }
    });

  }

  public get isLoggedIn(): boolean {
    return this.isLoggedInValue;
  }

  private get mySandvik1PingUrl(): string {
    return environment.documoto.mysandvik1PingUrl.replace('(mibp.date)', (new Date()).getTime().toString());
  }

  public canMediaWithCategoriesBeDownloaded(categories?: string[]): boolean {

    if (!categories || categories.length == 0) {
      return false;
    }

    if (categories.includes('Parts Manuals')) {
      return this.globalConfig.enablePartsManualDownload;
    }

    return true;
  }


  /**
   * Ping My Sandvik 1.0 so that the ADFS login will stay alive
   */
  private startSharePointPing(): void {

    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'mysandvik1-ping');
    iframe.setAttribute('src', this.mySandvik1PingUrl);
    iframe.style.visibility = 'hidden';
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    document.body.appendChild(iframe);

    iframe.onload = () => {
      clearTimeout(this.mysandvik1ping);
      this.mysandvik1ping = setTimeout(() => {
        const iframeElement = document.getElementById('mysandvik1-ping') as HTMLIFrameElement;
        if (iframeElement) {
          iframeElement.src = this.mySandvik1PingUrl;
        }
      }, this.MYSANDVIK1_PING_MS);
    };

  }

  private stopSharePointPing(): void {
    clearTimeout(this.mysandvik1ping);
    const iframe = document.getElementById('mysandvik1-ping');
    if (iframe) {
      iframe.parentElement.removeChild(iframe);
    }
  }



  public signout(): Promise<void> {
    if (!this.cache.get('ecatalogue')) {
      this.log.debug('No need to sign out from parts catalogue');
      return Promise.resolve();
    }

    this.stopSharePointPing();

    return new Promise(resolve => {
      this.log.debug('Attempting to sign out from parts catalogue');
      Promise.all( [
        this.signOutFromMySandvik1(),
        this.signOutFromDocumoto()
      ]).then(() => resolve(), () => resolve());
    });
  }

  /**
   * Attempt to sign out from My Sandvik 1.0 and ADFS
   */
  private signOutFromMySandvik1(): Promise<void> {
    return new Promise(resolve => {
      const logoutIframe = document.createElement('iframe');
      logoutIframe.setAttribute('id', 'partscatalogue-logout');
      logoutIframe.style.visibility = 'hidden';
      logoutIframe.setAttribute('src', environment.documoto.mySandvik1LogoutUrl);
      this.cache.remove('ecatalogue');
      document.body.appendChild(logoutIframe);
      logoutIframe.onload = function() {
        setTimeout(() => {
          resolve();
          const iframe = document.getElementById('partscatalogue-logout');
          if (iframe) {
            iframe.parentElement.removeChild(iframe);
          }
        }, 1500);
      };
    });
  }

  /**
   * Attempt to sign out Documoto
   */
  private signOutFromDocumoto(): Promise<void> {
    return new Promise(resolve => {

      // Create an iframe
      let logoutIframe = document.createElement('iframe');
      logoutIframe.setAttribute('id', 'documoto-signout');
      logoutIframe.setAttribute('style', 'width: 0; height: 0; position: absolute; visibility: hidden');
      logoutIframe = document.body.appendChild(logoutIframe);

      // Setup event receiver to wait for message from iframe when signout has been posted
      const waitForMessage = (e: MessageEvent) => {
        if (e.data === 'DocumotoIframeSignOut') {
          this.log.debug('Received message from iframe that we have submitted a logout from documoto');
          window.removeEventListener('message', waitForMessage);
          setTimeout( () => {
            this.log.debug('Removing documoto signout iframe');
            logoutIframe.parentElement.removeChild(logoutIframe);
            resolve();
          }, 2000);
        }
      };

      window.addEventListener('message', waitForMessage);

      // Write HTML and script to iframe
      const html = `
        <form method="POST" onsubmit="onFormSubmit()" action="${environment.documoto.signoutUrl}">

          <button id="signoutform"  type="submit">SIGN OUT</button></form>

          <script type="text/javascript">function onFormSubmit() {
            window.parent.postMessage("DocumotoIframeSignOut", "*");
          };

          document.getElementById("signoutform").click();
        </script>';
      `;

      logoutIframe.contentWindow.document.open();
      logoutIframe.contentWindow.document.write(html);
      logoutIframe.contentWindow.document.close();
    });

  }

  private getUserHash(): Promise<string> {

    if (this.userHash) {
      this.log.debug(`User Hash: ${this.userHash}`);
      return Promise.resolve(this.userHash);
    }

    const cached: string = this.cache.get('uhash');
    if (cached) {
      this.userHash = cached;
      this.log.debug(`User Hash: ${this.userHash} (From cache)`);
      return Promise.resolve(this.userHash);
    }

    return new Promise((resolve, reject) => {
      this.usersApi.getCurrentUserHash().subscribe({
        next: response => {
          if (response) {
            this.userHash = response;
            this.cache.add('uhash', response, null, CacheScope.MemoryStorage);
            this.log.debug(`User Hash: ${this.userHash} (From API)`);
            resolve(this.userHash);
          } else {
            this.log.error(`GetUserHash did not return any data`);
            reject();
          }
        },
        error: err => {
          this.log.error('An error occured fetching user hash');
          reject(err);
        }
      });
    });
  }


  public getUrl(options?: PartsCatalogueOptions): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      if (!this.userHash) {
        try {
          this.userHash = await this.getUserHash();
        } catch (e) {
          reject(e);
          return;
        }
      }

      if (this.isLoggedInValue) {
        this.log.debug(`User has previously logged in. We assume he/she is still logged in!`);
        resolve(this.createDocumotoUrl(options));
        return;
      }

      resolve(this.createDocumotoLoginUrl(options));
    });
  }

  public getDocumotoDirectUrl(options?: PartsCatalogueOptions): string {
    return this.createDocumotoUrl( options );
  }

  public getDocumotoOrganizationID(): string {
    return this.broadcastService.snapshot.mibpSession.user?.accessGroupId?.toString();
  }

  /**
  * Create the URL the will login to Documoto via My Sandvik 1.0
  */
  private createDocumotoLoginUrl(options?: PartsCatalogueOptions): string {

    if (!this.userHash) {
      throw new Error('User hash is not available');
    }
    let loginUrl = this.loginUrl;
    const documotoOrganizationId = this.getDocumotoOrganizationID();
    //change
    if (documotoOrganizationId) {
      const documotoUrl = this.createDocumotoUrl(options);
      if (this.UseB2CDocumoto) {
        return documotoUrl;
      } else {
        loginUrl = loginUrl
          .replace('(mibp.organizationid)', encodeURIComponent(documotoOrganizationId))
          .replace('(mibp.returnurl)', encodeURIComponent(documotoUrl))
          .replace('(mibp.userhash)', encodeURIComponent(this.userHash));
        return loginUrl;
      }
    } else {
      this.log.warn(`User does not have DocumotoOrganizationId`);
    }

    return undefined;
  }

  public setLoggedIn(loggedIn: boolean): void {
    this.isLoggedInValue = loggedIn;
    if (this.isLoggedInValue && !this.UseB2CDocumoto) {
      this.startSharePointPing();
    }
  }


  public mapDocumotoDeepUrlToRoute(deepUrl: string): { commands: string[], extras?: NavigationExtras } {
    const match = /(.*?)\?q=(.*?)$/.exec(deepUrl);
    if (match) {
      return this.generateRoute({
        mediaIdentifier: match[1],
        partsNumber: match[2]
      });
    }
    return undefined;
  }

  public generateRoute(options?: PartsCatalogueOptions): { commands: string[], extras?: NavigationExtras } {

    const mediaIdentifier = options && options.mediaIdentifier ? options.mediaIdentifier : null;
    const partNumber = mediaIdentifier && options.partsNumber ? options.partsNumber : null;
    const equipmentId = mediaIdentifier && options.equipmentId ? options.equipmentId : null;

    const commands = ['/', this.broadcastService.snapshot.language, 'myfleet', 'electronic-manuals'];
    let extras: NavigationExtras;
    if(equipmentId){
      commands.push(encodeURIComponent(equipmentId));
    }
    if (mediaIdentifier) {
      commands.push(encodeURIComponent(mediaIdentifier));
      if (partNumber) {
        extras = {
          queryParams: {
            q: partNumber
          }
        };
      }
    }

    return {
      commands,
      extras
    };
  }

  public async navigate(options?: PartsCatalogueOptions) {
    const route = this.generateRoute(options);
    this.router.navigate( route.commands, route.extras);
  }

  /**
  * Create the URL for a specific media and/or part
  * Will include the current ClientID that will be used to correlate AddToCart from documoto
  */
  private createDocumotoUrl(options?: PartsCatalogueOptions) {
    let documotoUrl: string;
    const clientId = this.broadcastService.snapshot.clientId;
    const mediaIdentifier = options && options.mediaIdentifier ? options.mediaIdentifier : null;
    const partNumber = mediaIdentifier && options.partsNumber ? options.partsNumber : null;

    this.log.debug(`mediaIdentifer=${mediaIdentifier}, partnumber=${partNumber}, clientid=${clientId}`);

    if (mediaIdentifier) {
      documotoUrl = environment.documoto.mediaIdentifierUrl;
    } else {
      documotoUrl = environment.documoto.applicationUrl;
    }

    documotoUrl = documotoUrl.replace('(mibp.clientid)', encodeURIComponent(clientId));
    documotoUrl = documotoUrl.replace('(mibp.returnurl)', encodeURIComponent(documotoUrl));
    if (mediaIdentifier) {
      documotoUrl = documotoUrl.replace('(mibp.mediaidentifier)', encodeURIComponent(mediaIdentifier));
      if (partNumber) {
        documotoUrl += `&q=${encodeURIComponent(partNumber)}`;
      }
    }
    return documotoUrl;
  }

  private get loginUrl(): string {
    if (environment.documoto && environment.documoto.loginUrl) {
      return environment.documoto.loginUrl;
    }
    return null;
  }

  public get electronicManualsVersionToShow(): 'documoto' | 'my-sandvik' {
    if (this.isPreviewEnabledForUser()) {
      if (this.cache.get(this.previewCacheKey) == 'disable-preview') {
        return 'documoto';
      }
      return 'my-sandvik';
    }
    return 'documoto';
  }

  public isPreviewEnabledForUser(): boolean {
    return this.permission.test({ features: [ MySandvikFeatures.MyfleetElectronicManualsNew ] });
  }

  public toggleElectronicManualsPreview(): 'documoto' | 'my-sandvik' {
    const currentValue = this.cache.get(this.previewCacheKey);
    if (currentValue === 'disable-preview') {
      this.cache.remove(this.previewCacheKey);
      return 'documoto';
    } else {
      this.cache.add(this.previewCacheKey, 'disable-preview', null, CacheScope.UserSessionStorage);
      return 'my-sandvik';
    }
  }

}
