import { DialogComponent } from './../../components/dialog/dialog.component';
import { Injectable } from '@angular/core';
import { DialogButton } from 'root/components/dialog/dialog.types';
import { ButtonColors } from 'root/components/button/button.enum';
import { LocalizationService } from '../localization/localization.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private globalComponent: DialogComponent;
  private globalComponentInUse: boolean;

  constructor(private localizationService: LocalizationService) {}

  public registerGlobalComponent(component: DialogComponent) {
    this.globalComponent = component;
  }

  addDialogClass() {
    document.body.classList.add('dialog-open');
  }

  removeDialogClass() {
    document.body.classList.remove('dialog-open');
  }

  /**
   * Map a string array to Dialog Buttons
   *
   * @example
   * Using plain resource string keys
   * mapStringsToButtons(['Resource_StringKey1', 'Resource_StringKey2'])
   * @example
   * Setting ID of buttons using ID# prefix
   * mapStringsToButtons(['ok#Resource_StringKey1', 'cancel#Resource_StringKey2'])
   * @example
   * Setting color of buttons using orange prefix
   * mapStringsToButtons(['Resource_StringKey1', 'orange Resource_StringKey2'])
   * @example
   * Setting color and id of buttons
   * mapStringsToButtons(['ok#Resource_StringKey1', 'orange cancel#Resource_StringKey2'])
   */
  public mapStringsToButtons(buttonResourceStrings: string[]): DialogButton[] {
    return buttonResourceStrings.map((key, index) => {

      let color = ButtonColors.Primary;
      let id = index.toString();

      if (key.toLocaleLowerCase().indexOf('secondary ') === 0) {
        color = ButtonColors.Secondary;
        key = key.substr( key.indexOf(' ') + 1 );
      }

      const idMatch = key.match(/^(.*?)#(.*)$/);
      if (idMatch) {
        id = idMatch[1];
        key = idMatch[2];
      }

      return <DialogButton>{
        id: id,
        resourceKey: key,
        color: color
      };
    });
  }

  public promptWithMacros(resourceStringkey: string, macros: any, ... buttonResourceStrings: string[]): Promise<any> {
    this.globalComponent.showWarningText = false;
    return new Promise((resolve, reject) => {
      const stopUsingResource = this.localizationService.using([resourceStringkey], values => {
        this.prompt.apply(this, ['text://' + this.localizationService.format(values[0], macros)].concat(buttonResourceStrings))
          .then(result => {
            stopUsingResource();
            resolve(result);
          });
      });
    });
  }

  public promptWithHtml(resourceStringkey: string, ... buttonResourceStrings: string[]): Promise<any> {
    this.globalComponent.showWarningText = false;
    return new Promise((resolve, reject) => {
      const stopUsingResource = this.localizationService.using([resourceStringkey], values => {
        this.prompt.apply(this, ['html://' + values[0]].concat(buttonResourceStrings))
          .then(result => {
            stopUsingResource();
            resolve(result);
          });
      });
    });
  }

  public async promptWithButtons(resourceStringKey: string, buttons: DialogButton[], resourceStringKeyIsHtml?: boolean, spreadButtonsEvenly?: boolean, macros?: any): Promise<string> {

    return new Promise((resolve, reject) => {
      if (this.globalComponentInUse) {
        return;
      }

      this.globalComponent.hideCloseButton = true;
      this.globalComponent.rawText= null;
      this.globalComponent.resourceStringKey= null;
      this.globalComponent.rawHtml='';
      this.globalComponent.speadButtonsEvenly = (spreadButtonsEvenly ?? true) == true;

      if(resourceStringKeyIsHtml !== null){
        this.globalComponent.resourceStringKeyIsHtml = resourceStringKeyIsHtml;
      }
      this.globalComponent.showWarningText = false;
      this.globalComponent.buttons = buttons;
      this.globalComponentInUse = true;

      if (resourceStringKey.indexOf('text://') === 0) {
        this.globalComponent.resourceStringKey = null;
        this.globalComponent.rawText = resourceStringKey.substr(7);
      } else if (resourceStringKey.indexOf('html://') === 0) {
        this.globalComponent.resourceStringKey = null;
        this.globalComponent.rawText = null;
        this.globalComponent.rawHtml = resourceStringKey.substr(7);
      } else {
        if (macros) {

          if (resourceStringKeyIsHtml) {
            this.globalComponent.rawHtml = this.localizationService.format(this.localizationService.get(resourceStringKey), macros);
          } else {
            this.globalComponent.rawText = this.localizationService.format(this.localizationService.get(resourceStringKey), macros);
          }

          this.globalComponent.resourceStringKey = null;
        } else {
          this.globalComponent.resourceStringKey = resourceStringKey;
        }
      }
      const prevSize = this.globalComponent.size;
      this.globalComponent.size = 'auto';
      this.globalComponent.open().then(button => {
        this.globalComponent.close();
        this.globalComponent.size = prevSize;
        this.globalComponentInUse = false;
        setTimeout(() => {
          resolve(button ? button.id : null);
        });
      });

    });

  }

  public promptWithText(messageText: string, ... buttons: DialogButton[]): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.globalComponentInUse) {
        return;
      }

      this.globalComponent.hideCloseButton = true;
      this.globalComponent.rawText= messageText;
      this.globalComponent.resourceStringKey= null;
      this.globalComponent.rawHtml='';

      this.globalComponent.buttons =buttons;
      this.globalComponentInUse = true;


      const prevSize = this.globalComponent.size;
      this.globalComponent.size = 'auto';
      this.globalComponent.open().then(button => {
        this.globalComponent.close();
        this.globalComponent.size = prevSize;
        this.globalComponentInUse = false;
        setTimeout(() => {
          resolve(button ? button.id : null);
        });
      });

    });
  }

  public prompt(resourceStringKey: string, ... buttonResourceStrings: string[]): Promise<string> {

    if (this.globalComponentInUse) {
      return;
    }

    this.globalComponent.hideCloseButton = true;
    this.globalComponent.rawText= null;
    this.globalComponent.resourceStringKey= null;
    this.globalComponent.rawHtml='';
    this.globalComponent.showWarningText = false;

    let buttons: DialogButton[] = [];

    if (buttonResourceStrings === null || buttonResourceStrings.length === 0) {
      buttonResourceStrings = ['yes#Global_Dialog_Yes', 'secondary no#Global_Dialog_No'];
    }

    buttons = this.mapStringsToButtons(buttonResourceStrings);

    return this.promptWithButtons(resourceStringKey, buttons);

    // this.globalComponent.showWarningText = false;
    // this.globalComponent.buttons = this.mapStringsToButtons(buttonResourceStrings);
    // this.globalComponentInUse = true;

    // if (resourceStringKey.indexOf('text://') === 0) {
    //   this.globalComponent.resourceStringKey = null;
    //   this.globalComponent.rawText = resourceStringKey.substr(7);
    // } else if (resourceStringKey.indexOf('html://') === 0) {
    //   this.globalComponent.resourceStringKey = null;
    //   this.globalComponent.rawText = null;
    //   this.globalComponent.rawHtml = resourceStringKey.substr(7);
    // } else {
    //   this.globalComponent.resourceStringKey = resourceStringKey;
    // }
    // const prevSize = this.globalComponent.size;
    // this.globalComponent.size = 'auto';
    // this.globalComponent.open().then(button => {
    //   this.globalComponent.close();
    //   this.globalComponent.size = prevSize;
    //   this.globalComponentInUse = false;
    //   setTimeout(() => {
    //     resolve(button ? button.id : null);
    //   });
    // });

  }

  public promptWithWarning(resourceStringKey: string, warningResourceStringKey: string, ... buttonResourceStrings: string[]): Promise<string> {

    return new Promise((resolve, reject) => {
      if (this.globalComponentInUse) {
        return;
      }
      this.globalComponent.rawText= null;
      this.globalComponent.resourceStringKey= null;
      this.globalComponent.rawHtml= '';
      this.globalComponent.hideCloseButton = true;
      this.globalComponent.showWarningText = false;

      if (buttonResourceStrings === null || buttonResourceStrings.length === 0) {
        buttonResourceStrings = ['yes#Global_Dialog_Yes', 'no#Global_Dialog_No'];
      }

      this.globalComponent.buttons = this.mapStringsToButtons(buttonResourceStrings);
      this.globalComponentInUse = true;
      if (resourceStringKey.indexOf('text://') === 0) {
        this.globalComponent.resourceStringKey = null;
        this.globalComponent.rawText = resourceStringKey.substr(7);
      } else {
        this.globalComponent.resourceStringKey = resourceStringKey;
      }
      this.globalComponent.showWarningText = true;
      this.globalComponent.warningResourceStringKey = warningResourceStringKey;

      const prevSize = this.globalComponent.size;
      this.globalComponent.size = 'auto';
      this.globalComponent.open().then(button => {
        this.globalComponent.close();
        this.globalComponent.size = prevSize;
        this.globalComponentInUse = false;
        setTimeout(() => {
          resolve(button ? button.id : null);
        });
      });

    });
  }


}
