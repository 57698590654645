/*
 * A pipe that will truncate a text and will avoid to do so within a word
 * String length, including '...' should not exceed the maxLength value
 *
 **/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'truncateString'})
export class TruncateStringPipe implements PipeTransform {
  transform(value: string, maxLength = 100): string {

    if (value.length > maxLength) {
      const characters = [' ', '.', ','];
      let truncated = value;
      do {
        truncated = this.findLastCharacterOf(truncated, characters);
      } while (truncated.length > (maxLength - 3));
      return truncated + '...';
    }

    return value;
  }

  private findLastCharacterOf(value: string, characters: string[]) {
    let lastIndex = -1;
    characters.forEach(c => {
      const i = value.lastIndexOf(c);
      if (i !== -1 && i > lastIndex) {
        lastIndex = i;
      }
    });
    return value.substr(0, lastIndex);
  }

}
