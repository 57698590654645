import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  public async sha256(stringTohash: string): Promise<string> {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(stringTohash);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
    return hashHex;
  }


}
