import { CartService } from './../../services/cart-service/cart.service';
import { Component, OnInit } from '@angular/core';
import { ApiService, LoaderService, MibpLogger } from 'root/services';
import { DropdownInput } from '..';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { CartsApiController } from 'root/mibp-openapi-gen/controllers';
import { CartType } from 'root/mibp-openapi-gen/models';
import { TemplatesApiController } from 'root/mibp-openapi-gen/controllers';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'mibp-quick-add-template',
  templateUrl: './quick-add-template.component.html',
  styleUrls: ['./quick-add-template.component.scss']
})
export class QuickAddTemplateComponent implements OnInit {

  constructor(private api: ApiService,
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private cartService: CartService,
    private cartsController: CartsApiController,
    private templateApiController : TemplatesApiController) { }

  templates: DropdownInput[] = [];
  form1: UntypedFormGroup;
  form1Submitted: boolean;
  hasTemplateLoadError = false;
  isLoadingTemplates = false;
  log: MibpLogger;
  public working = false;

  ngOnInit(): void {
    this.form1 = this.formBuilder.group({
      ddl: [null, Validators.required]
    });

    this.loadTemplates();
  }

  loadTemplates(): void {
    this.isLoadingTemplates = true;
    this.hasTemplateLoadError = false;

    this.cartsController.getQuickAddTemplates({body:[CartType.PrivateTemplate, CartType.SharedTemplate]}).toPromise().then(o => {
      this.templates = o.items.map((name) => <DropdownInput>{ text: name.name, value: name.id.toString() });
      this.isLoadingTemplates = false;
    }).catch(error => {
      this.log.error('error getting templates', error);
      this.hasTemplateLoadError = true;
      this.isLoadingTemplates = false;
    });

  }

  onSubmit(): void {
    this.working = true;
    const selected = <DropdownInput>this.form1.controls.ddl.value;

    if (this.form1.controls.ddl.status !== "VALID") {
      this.working = false;
      return;
    }

    this.loaderService.showFullScreenLoader();

    const templateId: Guid = Guid.parse(selected.value);
    // Results are shown via topbar component
      firstValueFrom(this.templateApiController.addTemplateToCart({templateCartId: templateId.toString()})).then(result=>{
      this.cartService.handleAddToCart(result);
    })
      .finally(
        () => {
          this.loaderService.hideFullScreenLoader();
          this.working = false;
        }
      );
  }
}
