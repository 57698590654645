import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RouterEvent, Event } from "@angular/router";
import { ScrollToService } from "../scroll-to/scroll-to.service";
import { NavigationLogService } from "../navigation-log-service/navigation-log-service";
import { filter } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RouterEventService {
  private isSubscribingToChanges = false;

  constructor(
    private router: Router,
    private scrollToService: ScrollToService,
    private navigationLogService: NavigationLogService
  ) {}

  public subscribeToRouterEvents(): void {
    if (this.isSubscribingToChanges) {
      return;
    }

    this.isSubscribingToChanges = true;
    this.router.events
      .pipe(filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent))
      .subscribe((e: RouterEvent) => {
           this.scrollToTop(e);
           this.logPageView(e);
      });
  }

  private scrollToTop(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      this.scrollToService.scrollToTop();
    }
  }

  private logPageView(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      this.navigationLogService.logPageView(event.url);
    }
  }
}
