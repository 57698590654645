import { UserFileApiController } from './../../mibp-openapi-gen/services/user-file-api-controller';
import { Component, OnInit, Input, ViewChild, EventEmitter, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { MibpLogger, LogService, MibpHttpApi, ApiService, FormattingService, DialogService, SignalR_Rendition} from 'root/services';
import { environment } from 'root/environment';
import { UserFileReference } from 'root/mibp-openapi-gen/models';
import { UserFileReferenceAction } from 'root/mibp-openapi-gen/models';
import { Guid } from 'guid-typescript';
import { addHours } from 'date-fns';

export interface SingleFile {
  fileUploadId: number;
  userFileId?: Guid;
  dataToUpload: string;
  fileName: string;
  displayName: string;
  originalDisplayName?: string;
  size: number;
  removed: boolean;
  status: 'added' | 'reading' | 'uploading' | 'deleting' | 'idle' | 'error';
  error?: 'upload' | 'delete' | 'filetoobig' | 'badfiletype';
  addedByUser: boolean;
  previewUrl: string;
}

export interface UploadedFile {
  id: Guid;
  displayName: string;
  size: number;
  rendition?: SignalR_Rendition;
}

@Component({
  selector: 'mibp-userfile-upload',
  templateUrl: './userfile-upload.component.html',
  styleUrls: ['./userfile-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFileUploadComponent),
      multi: true
    }
  ]
})
export class UserFileUploadComponent implements OnInit, ControlValueAccessor {

  @Input() files: any;
  @Input() fileTypes = "doc,docx,png,jpg,jpeg,pdf,xlsx";
  @Input() fileSource:any;
  @Input() maxFileSize = environment.userFiles.maxUploadBytes;
  @Input() maxNrOfFiles: any;
  @Input() previewImages = false;
  @Input() editable = true;
  @Input() disableDelete = false;
  @Input() hideAddFileBUtton = false;
  @Output() loadingChanged = new EventEmitter<boolean>();
  @Output() filesChanged = new EventEmitter();
  @ViewChild('fileInput') fileInput;
  fileSizeExceededMacros = {
    maxSize: ''
  };
  badFileTypeMacros = {
    fileTypes: ''
  };
  incrementalFileUploadId = 0;
  log: MibpLogger;
  newFileList: SingleFile[] = [];
  uploadsInProgress = 0;
  fileTypeArray = [];

  onTouched = () => {};
  onChange = (val?: any[]) => {};

  constructor(logger: LogService,
    private httpApi: MibpHttpApi,
    private userFileApi:UserFileApiController,
    private formatting: FormattingService,
    private dialog: DialogService) {
    this.log = logger.withPrefix('file-upload');
  }

  ngOnInit() {
    this.fileSizeExceededMacros.maxSize = this.formatting.formatBytes(this.maxFileSize);
    this.fileTypeArray = this.getFileTypeArray();
    this.badFileTypeMacros.fileTypes = this.fileTypeArray.join(', ');
  }

  isImage(file: SingleFile) {
    if (file.fileName.toLowerCase().match(/\.(png|jpg|jpeg|tiff|svg)$/)) {
      return true;
    }
    return false;
  }

  private getFileTypeArray() {
    if (this.fileTypes && this.fileTypes !== '*') {
      const fixedString = this.fileTypes.replace(' ', ',').replace(';', ',');
      return fixedString.split(',').map( str => {
        str = str.replace(/^\s+|\s+$/g, '').toLowerCase();
        if (str.length > 0 && str[0] !== '.') {
          str = `.${str}`;
        }
        return str;
      }).filter(nonEmptyString => nonEmptyString);
    }
    return [];
  }

  /**
   *ControlValueAccessor things for Reactive forms
   */
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  triggerOnChange() {
    this.onChange(this.value);
  }

  /**
   * Returns true if the specified file is in a loading state
   */
  isLoading(file: SingleFile): boolean {
    return file.status === 'deleting' || file.status === 'reading' || file.status === 'uploading';
  }

  /**
   * Map the current files into the UserFileReference type and the appropriate action
   */
  get value(): UserFileReference[] {
    return this.newFileList.filter(f => f.status === 'idle' || f.status === 'error').map(nfl => {
      let fileAction = UserFileReferenceAction.NoAction;

      if (nfl.addedByUser) {
        fileAction = UserFileReferenceAction.Add;
      } else if (nfl.userFileId) {
        if (nfl.removed) {
          fileAction = UserFileReferenceAction.Delete;
        } else if (nfl.originalDisplayName && nfl.displayName && nfl.originalDisplayName !== nfl.displayName) {
          fileAction = UserFileReferenceAction.Rename;
        }
      }

      return <UserFileReference>{
        displayName: nfl.displayName || nfl.fileName,
        id: nfl.userFileId?.toString(),
        size: nfl.size,
        action: fileAction,
        error: nfl.status === 'error'
      };
    });
  }

  /**
   * User changed the displayName of a file. Trigger a change of the form field.
   */
  updateDisplayName() {
    this.triggerOnChange();
  }

  /**
   * Form value is set from the form
   * Map into the internal SingleFile array
   */
  writeValue(filesFromForm: UploadedFile[]): void {
    if (filesFromForm) {
      this.newFileList = filesFromForm.map<SingleFile>(f => {
        this.incrementalFileUploadId++;
        return {
          size: f.size,
          displayName: f.displayName,
          userFileId: f.id,
          fileName: f.displayName,
          originalDisplayName: f.displayName,
          removed: false,
          dataToUpload: null,
          fileUploadId: this.incrementalFileUploadId,
          status: 'idle',
          error: null,
          addedByUser: false,
          previewUrl: this.isImage(<any>{ fileName: f.displayName }) ? this.httpApi.UserFile.getFileRenditionUrl(f.id, f.rendition ?? SignalR_Rendition.Medium) : null
        };
      });
    }

    this.triggerOnChange();
  }

  /**
   * Read data from a selected file
   */
  private async readfile(file: File): Promise<string> {
    const reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  private checkFileType() {

  }

  private async beginUpload(index: number, file: File, userFile: SingleFile) {
    const extensionWithDot = userFile.fileName.toLowerCase().replace(/^.*(\.[a-z0-9]+)$/i, '$1');

    this.log.debug('Starting upload of ' + userFile.fileName);

    // Make sure host component knows we're busy
    this.loadingChanged.emit(true);

    // Display error if file is too large
    if (userFile.size > this.maxFileSize) {
      userFile.error = 'filetoobig';
    } else if (this.fileTypeArray.length > 0 && this.fileTypeArray.indexOf(extensionWithDot) === -1) {
      userFile.error = 'badfiletype';
    }

    if (userFile.error) {
      userFile.dataToUpload = null;
      userFile.status = 'error';
      this.completeFileUpload();
      this.triggerOnChange();
      return;
    }

    // Read file
    userFile.status = 'reading';
    const fileContents = await this.readfile(file);
    const [fileMetadata, base64Data] = fileContents.split(',');
    const mimeType = fileMetadata.replace(/^data:(.*?);.*$/, '$1');

    if (this.isImage(userFile)) {
      userFile.previewUrl = fileContents;
    }

    userFile.dataToUpload = base64Data;
    userFile.status = 'uploading';

    try {
      const newId = await this.httpApi.UserFile.Save({
        Data: userFile.dataToUpload,
        DisplayName: userFile.displayName,
        FileName: userFile.fileName,
        MimeType: mimeType,
        FileSource:this.fileSource,
        ExpireDate: addHours(new Date(), environment.userFiles.expirationTime.amount)

      });

      userFile.status = 'idle';
      userFile.dataToUpload = null;
      userFile.userFileId = Guid.parse(newId);
      this.triggerOnChange();
      this.log.debug(`File was uploaded`, newId);

    } catch (err) {
      userFile.error = 'upload';
      userFile.status = 'error';
      this.log.error(`Error uploading file`, userFile.fileName, err);
    }

    this.completeFileUpload();
  }

  /**
   * Keeps track of current uploads and will reset fileInput
   * when all uploads are completed. Otherwise there can be some issues
   * if you want to upload the same file again
   */
  private completeFileUpload() {
    this.uploadsInProgress--;
    if (this.uploadsInProgress === 0) {
      this.loadingChanged.emit(false);
      this.log.debug("Resetting input value");
      this.fileInput.nativeElement.value = '';
    }
  }

  /**
   * Contains the current number of files that count toward maxFileCount
   */
  get currentNumberOfFiles(): number {
    return this.newFileList.filter(f => !f.removed).length;
  }

  /**
   * Occurs when user has selected files.
   * This will add files and queue them for upload
   */
  async fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      if (fileInput.target.files && fileInput.target.files.length > 0 ) {
        const extraFileCount = (fileInput.target.files.length + this.currentNumberOfFiles) - this.maxNrOfFiles;

        if (extraFileCount > 0) {
          this.dialog.promptWithMacros('UserFile_FileCountExceededWarning', { maxCount: this.maxNrOfFiles }, 'Global_OK');
          return;
        }

        this.uploadsInProgress = fileInput.target.files.length;

        for (let i = 0; i < fileInput.target.files.length; i++ ) {
          const file = fileInput.target.files[i];

          this.incrementalFileUploadId++;

          this.newFileList.push({
            fileUploadId: this.incrementalFileUploadId,
            dataToUpload: null,
            size: file.size,
            displayName: file.name,
            fileName: file.name,
            removed: false,
            status: 'added',
            addedByUser: true,
            previewUrl: null
          });

          this.beginUpload(i, file, this.newFileList[this.newFileList.length - 1]);
        }
      }
    }
  }

  /**
   * Will trigger a click on the hidden file input
   */
  public addFile() {
    const fileInputElement: HTMLElement = this.fileInput.nativeElement;
    fileInputElement.click();
  }

  /**
   * Öet the user undo removing a file if it was previously saved
   */
  undoRemove(file: SingleFile) {
    if (this.currentNumberOfFiles + 1 > this.maxNrOfFiles) {
      this.dialog.promptWithMacros('UserFile_FileCountExceededWarning', { maxCount: this.maxNrOfFiles }, 'Global_OK');
      return;
    }
    file.removed = false;
    this.triggerOnChange();
  }

  /**
   * Remove a file from the list
   */
  removeFile(file: SingleFile) {
    if (!file.userFileId) {
      // File does not have an ID yet. We can simply remove it
      const positionInArray = this.newFileList.findIndex(i => i.fileUploadId === file.fileUploadId);
      this.newFileList.splice(positionInArray, 1);
      this.triggerOnChange();
    } else {
      if (!file.addedByUser) {
        // File was added from form. So we just mark it as removed so it can be deleted on page save action
        file.removed = true;
        this.triggerOnChange();
        return;
      }

      // File is uploaded but not saved to an entity. We can delete it from the database and blob right now
      file.status = 'deleting';
      this.loadingChanged.emit(true);
      this.userFileApi.delete({id: file.userFileId.toString()}).subscribe(() => {
        const positionInArray = this.newFileList.findIndex(i => i.fileUploadId === file.fileUploadId);
        this.newFileList.splice(positionInArray, 1);
        this.triggerOnChange();
        this.loadingChanged.emit(false);

      }, err => {
        this.log.error('Error deleting userfile', err);
      });
    }
  }

}
