<mibp-dialog #dialogTandC [buttons]="dialogButtons"  (buttonClick)="buttonClick($event)" [hideCloseButton]="false">
  <p *ngIf="useMobileLayout">
    <mibp-resource-string key="Terms_And_Condition_Download_Text"></mibp-resource-string>
  </p>
  <p *ngIf="url"><a href="javascript:void(0)" (click)="downloadTermsandConditions()"><mibp-resource-string key="Global_Download"></mibp-resource-string></a></p>
  <mibp-loader class="m-4" type="loader" *ngIf="isLoading && !useMobileLayout"></mibp-loader>
  <pdf-viewer *ngIf="!useMobileLayout" [src]="url"
  [render-text]="true"
  [original-size]="false"
  style="height: 600px"
  (after-load-complete)="callBackFn($event)"
  ></pdf-viewer>
</mibp-dialog>
