/* tslint:disable */
/* eslint-disable */
export enum DasboardTileTypes {
  Dashboard_Bulletins = 0,
  Dashboard_MyFleet = 1,
  Dashboard_Orders = 2,
  Dashboard_CreditLimit = 3,
  Dashboard_QuickAdd = 4,
  Dashboard_Partscatalog = 5,
  Dashboard_PrmototionCarousel = 6,
  Dashboard_MyFeatures = 7,
  Dashboard_ServiceBusQueues = 8,
  Dashboard_MachineEngineHours = 9,
  Dashboard_MachineEngineHoursStatus = 10,
  Dashboard_News = 11
}
