import { Injectable } from "@angular/core";
import { addMinutes, format } from "date-fns";
import { TimezoneDateTimePickerResult } from "root/components/timezone-datetimepicker/timezone-datetimepicker.service";
import { FormattingService } from "../formatting/formatting.service";
import { LogService, MibpLogger } from "../logservice";

export interface TimezoneViewModel {
  name: string;
  offset: number;
  offsetString: string;
}

export interface MibpParsedDate {
  timzone: string;
  utcDateString: string;
  timezoneDateString: string;
  offsetMinutes: number;
  offsetString: string;
}

export interface MibpParsedTime {
  hour: number;
  minute: number;
  second: number;
  ms: number;
}

@Injectable({
  providedIn: 'root'
})
export class MibpDateTimeService {

  protected browserTimezone: string;
  protected timezones: TimezoneViewModel[];
  private log: MibpLogger;
  private timezonesAreSupported = false;

  constructor(private formattingService: FormattingService, logService: LogService) {
    this.log = logService.withPrefix(`datetime-service`);

  }

  /***
   * Should be called on application start so we prepare timezone list and see if browser supports it
   */
  public testTimezoneSupport(): void {
    setTimeout(() => this.collectTimezoneInformation(), 10);
  }


  public getBrowserTimezone(): string {
    try {
      const datetimeFormat = (Intl as any).DateTimeFormat();
      return datetimeFormat.resolvedOptions()?.timeZone;
    } catch (e) {
      this.log.error(`Could not get browser timezone`, e);
    }
  }

  public get canUseTimezones(): boolean {
    return this.timezonesAreSupported;
  }

  public getAvailableTimezones(): TimezoneViewModel[] {
    return this.timezones.slice(0);
  }


  private collectTimezoneInformation(): void {

    try {

      const datetimeFormat = (Intl as any).DateTimeFormat();
      this.browserTimezone = datetimeFormat.resolvedOptions()?.timeZone;

      this.timezones = ((Intl as any).supportedValuesOf('timeZone') as string[]).map(t => {
        return { name: t, offset: 0, offsetString: ''};
      });

      ///this.timezones.unshift('GMT/UTC');

      // Ok... Let's iterate timezones...
      const groups = {};
      for (const tzName of this.timezones) {
        const parsedIsoDate = this.parseUtcISOString({ utcDateString: this.formattingService.toServerUTCString(new Date()), timezone: tzName.name });
        const x = tzName.name.split('/', 2);
        if (!groups[parsedIsoDate.offsetString]) {
          groups[parsedIsoDate.offsetString] = [];
        }
        tzName.offset = parseInt(parsedIsoDate.offsetString, 10);
        tzName.offsetString = (tzName.offset < 0 ? '-' : '+') + tzName.offset.toString().replace('-', '').padStart(4, '0');
        tzName.offsetString = tzName.offsetString.substring(0, 3) + ':' +  tzName.offsetString.substring(3, 5);
        groups[parsedIsoDate.offsetString].push( x.length == 1 ? x[0] : x[1]);
      }

      this.timezonesAreSupported = true;

      this.log.info(`${this.timezones.length} time zones found in browser`);

    } catch (e) {
      this.log.error(`Could not get browser time zone list`, e);
    }

  }

  public parseStringAsTime(timeString: string): MibpParsedTime {
    const m = timeString.match(/^(\d{1,2}):(\d{1,2})(:\d{1,2}|)(\.\d{1,4}|)$/);

    if (m) {
      const hours = parseInt(m[1], 10);
      const minutes = parseInt(m[2], 10);
      const seconds = parseInt(m[3], 10) || 0;
      const ms = parseInt(m[4], 10) || 0;

      if (hours < 0 || hours > 24) {
        throw `Hour must be a value between 0-24`;
      }

      if (minutes < 0 || minutes > 59) {
        throw `Minute must be a value between 0-59`;
      }

      if (seconds < 0 || seconds > 59) {
        throw `Second must be a value between 0-59`;
      }

      if (ms < 0 || ms > 9999) {
        throw `Second must be a value between 0-9999`;
      }

      return {
        hour: hours,
        minute: minutes,
        second: seconds,
        ms: ms
      };
    }
    throw `Invalid time pattern - use pattern HH:mm[:ss][.SSSS]`;
  }
  public parseUtcISOString(options: { utcDateString: string, timezone?: string }): MibpParsedDate {
    const parsed = this.mapISODateStringIntoDatepickerResult(options.utcDateString, options.timezone);
    return {
      utcDateString: parsed.utcValue,
      timezoneDateString: parsed.tzValue,
      timzone: parsed.tzName,
      offsetMinutes: parsed.utcOffsetMinutes,
      offsetString: parsed.utcOffsetString
    };
  }


  /**
   * Parse an ISO UTC date string into information useful for the timezone datetime picker
   */
  public mapISODateStringIntoDatepickerResult(date: string, timezone: string): TimezoneDateTimePickerResult {
    const isoDateMatch = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{1,3}|)(Z)$/.exec(date);

    timezone = timezone || this.browserTimezone;

    if (isoDateMatch) {
      const d = addMinutes(new Date(date), 0);
      const utcTimezoneDateString = new Date( d.toLocaleString('sv-SE', { timeZone: 'UTC' }) + 'Z');
      const seletedTimezoneDateString = new Date(d.toLocaleString('sv-SE', { timeZone: timezone }));
      const offset = (seletedTimezoneDateString.getTime() - addMinutes(utcTimezoneDateString, utcTimezoneDateString.getTimezoneOffset()).getTime() ) / 6e4;

      const selectedDateTimeWithOffset = format(seletedTimezoneDateString, `yyyy-MM-dd'T'HH:mm:ss.SSS`) + this.timezoneOffsetToString(offset);
      const selectedTimezoneConvertIntoUTCISOString = this.formattingService.toServerUTCString(new Date(selectedDateTimeWithOffset));

      return {
        inputType: 'string',
        parsedInputValue: format(utcTimezoneDateString, `yyyy-MM-dd'T'HH:mm:ss.SSS`) + ' Z',
        rawInputValue: date,
        utcValue: selectedTimezoneConvertIntoUTCISOString,
        tzValue: format(seletedTimezoneDateString, `yyyy-MM-dd'T'HH:mm:ss.SSS`) + this.timezoneOffsetToString(offset),
        utcOffsetMinutes: offset,
        utcOffsetString: this.timezoneOffsetToString(offset),
        tzName: timezone
      };

    }

    return null;
  }

  public timezoneOffsetToString(minutes: number): string {
    const isNegative = minutes < 0;
    const hrs = (minutes * (isNegative ? -1 : 1) ) / 60;
    const additional = hrs - parseInt(hrs.toString(), 10);
    const evenHours = hrs - additional;
    return `${isNegative ? '-' : '+'}${evenHours.toString().padStart(2, '0')}${(additional * 60).toString().padStart(2, '0')}`;
  }

}
