import { inject } from "@angular/core";
import { UrlTree, Router, ActivatedRouteSnapshot, CanDeactivateFn } from "@angular/router";
import { Observable } from "rxjs";
import { LogService } from "root/services/logservice";
import { CanDeactivateService } from "root/services/can-deactivate/can-deactivate.service";
import { Location } from '@angular/common';

/**
 * General guard to be used with CanDeactivateService
 * to block navigation or prompt before navigating
 */

export const MibpCanDeactivateFn : CanDeactivateFn<any> = (component: any, currentRoute: ActivatedRouteSnapshot) : Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree => {
  const canDeactivate = !inject(CanDeactivateService).isBlocked;
  let log = inject(LogService).withPrefix('can-deactivate-guard');
    if (!canDeactivate) {
      log.debug("Navigation was blocked by Guard");

      // Due to issue https://github.com/angular/angular/issues/13586
      // a workaround is implemented below as per  https://stackoverflow.com/questions/46448133/candeactivate-changing-the-window-history
      const currentUrlTree = inject(Router).createUrlTree([], currentRoute);
      const currentUrl = currentUrlTree.toString();
      inject(Location).go(currentUrl);
    }

    return canDeactivate;
};
