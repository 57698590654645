import { GlobalConfigService } from './../../services/global-config/global-config.service';
import { LogService } from './../../services/logservice/log.service';
import { MibpLogger } from './../../services/logservice/mibplogger.class';
import { FrontendContextService } from 'root/services';
import { Component, ViewChild, TemplateRef, ViewContainerRef, OnInit, OnDestroy, HostListener, EmbeddedViewRef, Input, ViewEncapsulation} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'mibp-full-screen-iframe',
  styleUrls: ['./fullscreen-iframe.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
   <ng-template #template>
    <div class="fullscreen-iframe-wrapper" [style.top]="navigationHeight + 'px'" [style.left]="newUxLeft" [style.width]="newUxWidth" [style.height]="newUxHeight || 'calc(100% - ' + (navigationHeight + (showBelowBreadcrumb ? breadcrumbHeight : 0) ) + 'px)'">
      <div class="fullscreen-content">
        <ng-template [ngIf]="loading">
          <div class="loader-container">
            <div>
              <div>
                <mibp-loader showLoader="true"></mibp-loader>
              </div>
              <div class="loading-text"><mibp-resource-string key="Global_WaitMessage"></mibp-resource-string></div>
            </div>
          </div>
        </ng-template>
        <iframe
        allow="fullscreen"
        *ngIf="iframeUrl"
        [style.visibility]="iframeUrl && loading ? 'hidden' : 'visible'"
        [src]="iframeUrl"
        [attr.id]="enableCacheBusting ? iframeName : null"
        [attr.name]="enableCacheBusting ? iframeName : null"
        width="100%"></iframe>

      </div>
    </div>
  </ng-template>`
})
export class MibpFullScreenIframeComponent implements OnInit, OnDestroy {

  @ViewChild('template', {static: true}) template: TemplateRef<any>;

  /**
   * Set to true to generate a random name for the iframe
   * This can avoid the frame src being cached when the page reloads
   */
  @Input() enableCacheBusting = false;
  @Input() showBelowBreadcrumb = false;
  @Input() allowFullscreen = false;
  private breadcrumbHeight = 75; // Uncomplicated for now since it's only used in 3dmodel poc pages
  embeddedView: EmbeddedViewRef<any>;
  previousOverflowY: string;
  newUxWidth: string;
  newUxHeight: string;
  newUxLeft: string;
  topPositionTimer;
  iframeUrl: SafeResourceUrl = null;
  loading = true;
  navigationHeight = 91;
  i = 0;
  iframeName: string;
  log: MibpLogger;

  @Input() waitForMessage: { timeoutMs: number; name: string; };
  @Input() showLoaderMs: number;
  sidebarHidden: boolean;
  savedMySandvikSidebarSize: DOMRect;
  sidebarSize: number;

  @Input()
  set src(value: string) {
    if (value) {
      if (this.waitForMessage || this.showLoaderMs > 0) {
        this.loading = true;
        setTimeout( () => {
          this.loading = false;
        }, this.showLoaderMs);
      } else {
        this.loading = false;
      }
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(value);
    } else if (this.iframeUrl) {
      this.iframeUrl = null;
    }
  }

  constructor(private viewContainerRef: ViewContainerRef, private sanitizer: DomSanitizer, private context: FrontendContextService, logger: LogService, globalConfig: GlobalConfigService) {
    this.log = logger.withPrefix('iframe-fullscreen');
  }


  @HostListener('window:message', ['$event'])
  handleKeyDown(event: MessageEvent): void {
    if (this.waitForMessage && this.loading) {
      if (this.waitForMessage.name === event.data) {
        this.loading = false;
      }
    }
  }

  @HostListener('window:resize')
  handleWindowResize(): void {
    this.sidebarHidden = null;
    this.updateTopPosition();
  }

  ngOnInit(): void {
    const self = this;
    this.context.isSidebarHiddenSubject.subscribe(x=>{
      if(x !== null){
        this.sidebarHidden = x.isHidden;
        this.sidebarSize = x.number;
        this.updateTopPosition();
      }
    });
    this.moveTemplateToBody();
    this.enableBodyScroll(false);
    this.topPositionTimer = setInterval(function() {
      this.sidebarHidden = null;
      self.updateTopPosition();
    }, 150);

    if (this.showBelowBreadcrumb) {
      this.navigationHeight += this.breadcrumbHeight;
    }

    const newMainContainer = document.getElementById('my-main-container');
    if (newMainContainer) {
      this.newUxWidth = `calc(100% - 290x)`;
      this.newUxLeft = `290px`;
    }

    this.iframeName = `frame-${this.context.newRandomIdString()}`;
    this.log.debug(`Created iframe with name ${this.iframeName}`);
    this.updateTopPosition();
  }


  updateTopPosition(): void {

    const newMainContainer = document.getElementById('my-main-container');
    if (newMainContainer) {
      // TODO: Fix this iframe for new ux as well

      const navbarHeight = (document.querySelector('#site-header') as HTMLDivElement).offsetHeight;
      if (navbarHeight && this.navigationHeight !== navbarHeight) {
        this.navigationHeight = navbarHeight;
        this.log.debug('updateTopPosition', navbarHeight);
      }

      if (this.showBelowBreadcrumb) {
        this.navigationHeight += this.breadcrumbHeight;
      }


      const mySadvikSidebarSize = document.querySelector('.mysandvik-sidebar')?.getBoundingClientRect();
      if(this.sidebarHidden === true){
        this.newUxWidth = `100%`;
        this.newUxLeft = `0%`;
      }
      else if(this.sidebarHidden === false){
        if(this.sidebarSize){
          this.newUxWidth = `calc(100% - ${this.sidebarSize}px)`;
          this.newUxLeft = `${this.sidebarSize+10}px`;
        }
        else{
          this.newUxWidth = `100%`;
          this.newUxLeft = `0%`;
        }
      }
      else{
        if(mySadvikSidebarSize && mySadvikSidebarSize.width){
          this.newUxWidth = `calc(100% - ${mySadvikSidebarSize.width}px)`;
          this.newUxLeft = `${mySadvikSidebarSize.width}px`;
        }
        else{
          this.newUxWidth = `100%`;
          this.newUxLeft = `0%`;
        }
      }


      this.newUxHeight = `calc(100% - ${navbarHeight + (this.showBelowBreadcrumb ? this.breadcrumbHeight : 0)}px)`;

    } else {

      const navbarHeight = (document.querySelector('.navbar') as HTMLDivElement)?.offsetHeight;
      if (navbarHeight && this.navigationHeight !== navbarHeight) {
        this.navigationHeight = navbarHeight;
        this.log.debug('updateTopPosition', navbarHeight);
      }

    }

    if (this.i > 50) {
      clearInterval(this.topPositionTimer);
    }
    this.i ++;
  }

  ngOnDestroy(): void {
    this.enableBodyScroll(true);
    if (this.embeddedView) {
      this.embeddedView.destroy();
    }
  }

  @HostListener('window:resize', ['$event']) onResizeHandler(): void {
    this.sidebarHidden = null;
    this.updateTopPosition();
  }

  enableBodyScroll(enableScroll: boolean): void {
    this.log.debug('EnableBodyScroll', enableScroll);
    const body = document.querySelector('body');
    if (body) {
      if (enableScroll) {
        body.style.overflowY = this.previousOverflowY;
        this.previousOverflowY = null;
      } else {
        let style: CSSStyleDeclaration = null;
        if (getComputedStyle) {
          style  = getComputedStyle(body);
        } else {
          style = body.style;
        }
        this.previousOverflowY = body.style.overflowY ? body.style.overflowY : 'auto';
        body.style.overflowY = 'hidden';
      }
    } else {
      this.log.debug('Body element was not found');
    }
  }

  /**
   * Move the 'dialogTemplate' element (see template) to the body
   * This is done by creating an EmbeddedView and moving its elements to the body element
   * Using the embeddedView, all angular bindings will remain and work as usual even if the
   * elements are moved somewhere else
   * https://angular.io/api/core/ViewContainerRef#createembeddedview
   */
  private moveTemplateToBody(): void {
    this.log.debug('Move template to body');
    const bodyElement = document.querySelector('body');
    this.embeddedView = this.viewContainerRef.createEmbeddedView(this.template);
    this.embeddedView.rootNodes.forEach(rootNode => bodyElement.insertBefore(rootNode, bodyElement.firstElementChild));
    this.log.debug('Template was moved');
  }

}
