import { firstValueFrom } from 'rxjs';
import { CrossSellsApiController } from './../../mibp-openapi-gen/services/cross-sells-api-controller';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CartService, ContactUsService, LocalizationService, LogService, MibpHttpApi, MibpLogger, NoticebarService, QuickOrderLine, FrontendContextService, DialogService, PermissionService } from 'root/services';
import { ApiService } from 'root/services/mibp-api-services';
import { ContactUsTopic } from '../contact-dialog/contact-us-form/contact-us-form.types';
import { NoticeType } from '../noticebar/noticebar.enum';
import { AddItemToCartSource, CrossSellProductDetailsRequestDto, PriceAndAvailabilityRequest, PriceAndAvailabilitySource, ProductAvailability, ProductExpectedDate, ProductExpectedDateRequestDto, ProductPrice, UpSellCrossSellProductRequestResponseDto } from 'root/mibp-openapi-gen/models';
import { allPermissionPolicies } from 'root/all-permission-policies';

export interface CrossSellProductWithPriceAndAvailability extends UpSellCrossSellProductRequestResponseDto {
  productPrice : ProductPrice;
  productAvailability : ProductAvailability;
  productExpectedDate : ProductExpectedDate;
}
@Component({
  selector: 'mibp-cross-sell',
  templateUrl: './cross-sell.component.html',
  styleUrls: ['./cross-sell.component.scss']
})
export class CrossSellComponent implements OnInit {

  isLoading = false;
  crossSellProducts: CrossSellProductWithPriceAndAvailability[];
  log: MibpLogger;
  contactUsMessageRequestPrice: string;
  canSeeAvailability: boolean;
  useexpectedDeliveryDateAndAvailability: boolean;
  @Input()
  set setCrossSellProductIds(crossSellProductIds: number[]) {
    if(!crossSellProductIds || crossSellProductIds.length < 1) {
      this.crossSellProducts = [];
      return;

    }

    this.getCrossSellProducts(crossSellProductIds);
  }

  @Output() crossSellProductAddedToCartEvent = new EventEmitter();

  @Output() closeDialogBox = new EventEmitter<boolean>();
  constructor(
    private router: Router,
    private localizationService: LocalizationService,
    private api: ApiService,
    private contactUsService: ContactUsService,
    private noticeBarService: NoticebarService,
    private cartService: CartService,
    private logger: LogService,
    private frontendContext: FrontendContextService,
    private httpApi: MibpHttpApi,
    private crossSellApiController: CrossSellsApiController,
    private dialogService: DialogService,
    private permissions: PermissionService
  ) {
    this.log = logger.withPrefix('crossSell-component');
  }

  ngOnInit(): void {
    this.useexpectedDeliveryDateAndAvailability = this.frontendContext.isExpectedDeliveryDateEnabled();
    this.localizationService.getResourceString('Carts_PriceForProductMessage').then((resourceStringValue) => {
      this.contactUsMessageRequestPrice = resourceStringValue;
    });
    this.canSeeAvailability = this.permissions.test(allPermissionPolicies.canSeeAvailability);
  }

  get useExpectedDate(): boolean {
    return this.frontendContext.isExpectedDeliveryDateEnabled();
  }

  getCrossSellProducts(crossSellProductIds: number[]): void {
    const languageCode = this.localizationService.getLang();
    this.isLoading = true;
    this.crossSellProducts = [];
    const request =  {
      crossSellProductIds: crossSellProductIds,
      languageCode : languageCode
    } as CrossSellProductDetailsRequestDto;

    firstValueFrom(this.crossSellApiController.getCrossSellProductDetails({body: request })).then((crossSellProducts) => {
      this.log.debug('response crossSellProducts ', crossSellProducts);
      this.crossSellProducts = crossSellProducts as CrossSellProductWithPriceAndAvailability[];

      this.getPriceAndAvailabilitiesOfProducts(this.crossSellProducts.map(u => { return {quantity:1, productCode:u.code } as ProductExpectedDateRequestDto;}));

      setTimeout(() => {
        this.isLoading = false;
      }, 1000);

    }).catch(error => {
      this.isLoading = false;
      this.log.error('Error when fetching cross sell products', error);
      this.noticeBarService.show('Cross-sell_ErrorFetchingCrossSellProducts', NoticeType.Error);
    });
  }

  getPriceAndAvailabilitiesOfProducts(productCodes: ProductExpectedDateRequestDto[]) {
    if(this.permissions.test(allPermissionPolicies.canSeePrices)){
      this.httpApi.PriceAndAvailability.GetPriceofProducts({
        deliverySequenceId : null,
        priceAndAvailabilitySource : PriceAndAvailabilitySource.ActiveCart,
        productCodes : productCodes.map(p => p.productCode),
        force : false
      } as PriceAndAvailabilityRequest).then(x => {
        this.crossSellProducts.forEach(c => {
          c.productPrice = x.find(x => x.productCode === c.code);
        });
      }).catch(e => {
        this.crossSellProducts.forEach(c => {
          c.productPrice = {
            errorCode: 'BACKEND_UNHANDLED_ERROR',
            errorDescription: 'Backend threw an unhandled exception',
            productCode: c.code,
            hasNetPriceOnly: false,
            superseededByProduct:null,
            warning: null,
            taxCode: null
          };
        });
        this.log.error("Price error", e);
      });
    }
    if(!this.useExpectedDate){
      if (this.canSeeAvailability) {
        this.httpApi.PriceAndAvailability.GetAvailabilityofProducts({
          deliverySequenceId : null,
          priceAndAvailabilitySource : PriceAndAvailabilitySource.ActiveCart,
          productCodes : productCodes.map(p => p.productCode),
          force : false
        }).then(x => {
          this.crossSellProducts.forEach(c => {
            c.productAvailability = x.find(x => x.productCode === c.code);
          });
        });
      }
    }
    else{
      const todayDate = new Date().toLocaleDateString('en-ca');
      this.httpApi.PriceAndAvailability.GetExpectedDateofProducts({
        products : productCodes,
        priceAndAvailabilitySource : PriceAndAvailabilitySource.ActiveCart,
        deliverySequenceId : null,
        userBrowserDate:todayDate,
        onlyGetFromCache: false
      }).then(x => {
        this.crossSellProducts.forEach(c => {
          c.productExpectedDate = x.find(x => x.productCode === c.code);
        });
      });
    }
  }

  onAddToActiveCart (productCode: string) :void {
    this.isLoading = true;

    const products = [<QuickOrderLine>{
      productCode: productCode,
      quantity: 1 //Always qty 1 when adding cross sell products
    }];

    this.cartService.addToCart(products, AddItemToCartSource.CrossSell).then(ok => {
      this.crossSellProductAddedToCartEvent.emit();
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    }).catch(err => {
      this.log.error("Could not add to active cart", err);
      this.isLoading = false;
      //
    });
  }
  onClickProductImage(productCode: string)
  {
    this.closeDialogBox.emit(true);
    this.frontendContext.Navigation.navigateToProductDetailsPage(productCode);
  }
  // onCloseCrossSellUpsellDialog($event)
  // {
  //   this.closeDialogBox.emit();
  // }
}
