/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EquipmentIndexDtoSearchResponse } from '../models/equipment-index-dto-search-response';
import { getEquipmentsForFilters } from '../fn/operation-sites/get-equipments-for-filters';
import { GetEquipmentsForFilters$Params } from '../fn/operation-sites/get-equipments-for-filters';
import { getEquipmentsForFilters$Plain } from '../fn/operation-sites/get-equipments-for-filters-plain';
import { GetEquipmentsForFilters$Plain$Params } from '../fn/operation-sites/get-equipments-for-filters-plain';
import { getEquipmentsForOperationSite } from '../fn/operation-sites/get-equipments-for-operation-site';
import { GetEquipmentsForOperationSite$Params } from '../fn/operation-sites/get-equipments-for-operation-site';
import { getEquipmentsForOperationSite$Plain } from '../fn/operation-sites/get-equipments-for-operation-site-plain';
import { GetEquipmentsForOperationSite$Plain$Params } from '../fn/operation-sites/get-equipments-for-operation-site-plain';
import { hasAnyOperationSites } from '../fn/operation-sites/has-any-operation-sites';
import { HasAnyOperationSites$Params } from '../fn/operation-sites/has-any-operation-sites';
import { hasAnyOperationSites$Plain } from '../fn/operation-sites/has-any-operation-sites-plain';
import { HasAnyOperationSites$Plain$Params } from '../fn/operation-sites/has-any-operation-sites-plain';
import { MapDtoSearchResponse } from '../models/map-dto-search-response';
import { searchOperationSites } from '../fn/operation-sites/search-operation-sites';
import { SearchOperationSites$Params } from '../fn/operation-sites/search-operation-sites';
import { searchOperationSites$Plain } from '../fn/operation-sites/search-operation-sites-plain';
import { SearchOperationSites$Plain$Params } from '../fn/operation-sites/search-operation-sites-plain';

@Injectable({ providedIn: 'root' })
export class OperationSitesApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `operationSitesControllerSearchOperationSitesrefinementOptions()` */
  static readonly OperationSitesControllerSearchOperationSitesrefinementOptionsPath = '/sites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchOperationSites$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites$Plain$Response(params?: SearchOperationSites$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MapDtoSearchResponse>> {
    return searchOperationSites$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchOperationSites$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites$Plain(params?: SearchOperationSites$Plain$Params, context?: HttpContext): Observable<MapDtoSearchResponse> {
    return this.searchOperationSites$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MapDtoSearchResponse>): MapDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchOperationSites()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites$Response(params?: SearchOperationSites$Params, context?: HttpContext): Observable<StrictHttpResponse<MapDtoSearchResponse>> {
    return searchOperationSites(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchOperationSites$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites(params?: SearchOperationSites$Params, context?: HttpContext): Observable<MapDtoSearchResponse> {
    return this.searchOperationSites$Response(params, context).pipe(
      map((r: StrictHttpResponse<MapDtoSearchResponse>): MapDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `operationSitesControllerGetEquipmentsForOperationSiteoperationSiteIdPagedOptionsRefinementOptions()` */
  static readonly OperationSitesControllerGetEquipmentsForOperationSiteoperationSiteIdPagedOptionsRefinementOptionsPath = '/sites/{operationSiteId}/equipments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsForOperationSite$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForOperationSite$Plain$Response(params: GetEquipmentsForOperationSite$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return getEquipmentsForOperationSite$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsForOperationSite$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForOperationSite$Plain(params: GetEquipmentsForOperationSite$Plain$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.getEquipmentsForOperationSite$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsForOperationSite()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForOperationSite$Response(params: GetEquipmentsForOperationSite$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return getEquipmentsForOperationSite(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsForOperationSite$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForOperationSite(params: GetEquipmentsForOperationSite$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.getEquipmentsForOperationSite$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `operationSitesControllerGetEquipmentsForFiltersrefinementOptions()` */
  static readonly OperationSitesControllerGetEquipmentsForFiltersrefinementOptionsPath = '/sites/equipments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsForFilters$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForFilters$Plain$Response(params?: GetEquipmentsForFilters$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return getEquipmentsForFilters$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsForFilters$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForFilters$Plain(params?: GetEquipmentsForFilters$Plain$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.getEquipmentsForFilters$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsForFilters()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForFilters$Response(params?: GetEquipmentsForFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return getEquipmentsForFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsForFilters$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsForFilters(params?: GetEquipmentsForFilters$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.getEquipmentsForFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `operationSitesControllerHasAnyOperationSites()` */
  static readonly OperationSitesControllerHasAnyOperationSitesPath = '/sites/any';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasAnyOperationSites$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAnyOperationSites$Plain$Response(params?: HasAnyOperationSites$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return hasAnyOperationSites$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hasAnyOperationSites$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAnyOperationSites$Plain(params?: HasAnyOperationSites$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.hasAnyOperationSites$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasAnyOperationSites()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAnyOperationSites$Response(params?: HasAnyOperationSites$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return hasAnyOperationSites(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hasAnyOperationSites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAnyOperationSites(params?: HasAnyOperationSites$Params, context?: HttpContext): Observable<boolean> {
    return this.hasAnyOperationSites$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
