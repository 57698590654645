import { Injectable } from "@angular/core";
import { MibpParsedUrl } from "./url-helper.types";

/**
 * Utilities for parsing and creating URL:s
 */
@Injectable({
  providedIn: 'root'
})
export class UrlHelperService {

  public parseUrl(url?: string): MibpParsedUrl {
    return new MibpParsedUrl(url || window.location.href);
  }

  public getQuerystringValue(key: string, url: string = null): string {
    const parsed = this.parseUrl(url);
    return parsed.param(key);
  }

  /**
   * Given an URL, add or replace the specified querystring
   */
  public addQuerystring(url: string, key: string, value: string): string {
    const parsed = this.parseUrl(url);
    parsed.query[key] = value;
    return parsed.toString();
  }

  /**
   * Will parse the current querystring parameters form the url
   * and merge them with the ones in the provided object
   * @returns The new Querystring key/value object
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public mergeQuerystringParameters(url: string, params: { [key: string]: any}): { [key: string]: string} {
    const parsed = this.parseUrl(url);
    return Object.assign({}, parsed.query, params);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public create(url: string, queryStringParams: { [key: string]: any}): MibpParsedUrl {
    const parsed = this.parseUrl(url);
    parsed.query = Object.assign({}, parsed.query, queryStringParams);
    return parsed;
  }

  public areQuerystringsEqual(first: MibpParsedUrl, second: MibpParsedUrl): boolean {


    const firstQuery = first.query ? Object.assign({}, first.query) : {};
    const secondQuery = second.query ? Object.assign({}, second.query) : {};

    Object.keys(firstQuery).forEach(k => {
      if (firstQuery[k] === null || typeof firstQuery[k] === 'undefined') {
        delete firstQuery[k];
      }
    });

    Object.keys(secondQuery).forEach(k => {
      if (secondQuery[k] === null || typeof secondQuery[k] === 'undefined') {
        delete secondQuery[k];
      }
    });

    if (!firstQuery && secondQuery) {
      return false;
    }

    if (!secondQuery && firstQuery) {
      return false;
    }

    if (Object.keys(firstQuery).length !== Object.keys(secondQuery).length) {
      return false;
    }

    let anyDifference = false;

    Object.keys(firstQuery).forEach(parameterName => {
      if (firstQuery[parameterName]?.toString() !== secondQuery[parameterName]?.toString()) {
        anyDifference = true;
      }
    });

    return !anyDifference;
  }

  public addHostnameToPath(path: string, location?: Location): string {

    location = location || window.location;

    if (path && path.indexOf('/') === 0) {
      path = path.substring(1);
    }

    let address = `${location.protocol}//${location.hostname}`;
    if (location.port) {
      address += `:${location.port}`;
    }
    if (path) {
      address += `/${path}`;
    }

    return address;

  }


}
