/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createWarrantyApplication } from '../fn/warranty-application/create-warranty-application';
import { CreateWarrantyApplication$Params } from '../fn/warranty-application/create-warranty-application';
import { downloadFile } from '../fn/warranty-application/download-file';
import { DownloadFile$Params } from '../fn/warranty-application/download-file';
import { getForEdit } from '../fn/warranty-application/get-for-edit';
import { GetForEdit$Params } from '../fn/warranty-application/get-for-edit';
import { getForEdit$Plain } from '../fn/warranty-application/get-for-edit-plain';
import { GetForEdit$Plain$Params } from '../fn/warranty-application/get-for-edit-plain';
import { getWarrantiesApplicationsListGrid } from '../fn/warranty-application/get-warranties-applications-list-grid';
import { GetWarrantiesApplicationsListGrid$Params } from '../fn/warranty-application/get-warranties-applications-list-grid';
import { getWarrantiesApplicationsListGrid$Plain } from '../fn/warranty-application/get-warranties-applications-list-grid-plain';
import { GetWarrantiesApplicationsListGrid$Plain$Params } from '../fn/warranty-application/get-warranties-applications-list-grid-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { updateWarrantyApplication } from '../fn/warranty-application/update-warranty-application';
import { UpdateWarrantyApplication$Params } from '../fn/warranty-application/update-warranty-application';
import { WarrantyApplicationForEditDto } from '../models/warranty-application-for-edit-dto';

@Injectable({ providedIn: 'root' })
export class WarrantyApplicationApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `warrantyApplicationControllerCreateWarrantyApplicationsaveWarrantyApplicationDto()` */
  static readonly WarrantyApplicationControllerCreateWarrantyApplicationsaveWarrantyApplicationDtoPath = '/warrantyapplication/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWarrantyApplication()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWarrantyApplication$Response(params?: CreateWarrantyApplication$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createWarrantyApplication(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWarrantyApplication$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWarrantyApplication(params?: CreateWarrantyApplication$Params, context?: HttpContext): Observable<void> {
    return this.createWarrantyApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `warrantyApplicationControllerUpdateWarrantyApplicationsaveWarrantyApplicationDto()` */
  static readonly WarrantyApplicationControllerUpdateWarrantyApplicationsaveWarrantyApplicationDtoPath = '/warrantyapplication/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWarrantyApplication()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateWarrantyApplication$Response(params?: UpdateWarrantyApplication$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateWarrantyApplication(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWarrantyApplication$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateWarrantyApplication(params?: UpdateWarrantyApplication$Params, context?: HttpContext): Observable<void> {
    return this.updateWarrantyApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `warrantyApplicationControllerGetWarrantiesApplicationsListGridoptionsDeliveryPointId()` */
  static readonly WarrantyApplicationControllerGetWarrantiesApplicationsListGridoptionsDeliveryPointIdPath = '/warrantyapplication/search/warrantyapplication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesApplicationsListGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesApplicationsListGrid$Plain$Response(params?: GetWarrantiesApplicationsListGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return getWarrantiesApplicationsListGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesApplicationsListGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesApplicationsListGrid$Plain(params?: GetWarrantiesApplicationsListGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.getWarrantiesApplicationsListGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesApplicationsListGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesApplicationsListGrid$Response(params?: GetWarrantiesApplicationsListGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return getWarrantiesApplicationsListGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesApplicationsListGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesApplicationsListGrid(params?: GetWarrantiesApplicationsListGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.getWarrantiesApplicationsListGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `warrantyApplicationControllerGetForEditid()` */
  static readonly WarrantyApplicationControllerGetForEditidPath = '/warrantyapplication/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Plain$Response(params: GetForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyApplicationForEditDto>> {
    return getForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Plain(params: GetForEdit$Plain$Params, context?: HttpContext): Observable<WarrantyApplicationForEditDto> {
    return this.getForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyApplicationForEditDto>): WarrantyApplicationForEditDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Response(params: GetForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyApplicationForEditDto>> {
    return getForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit(params: GetForEdit$Params, context?: HttpContext): Observable<WarrantyApplicationForEditDto> {
    return this.getForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyApplicationForEditDto>): WarrantyApplicationForEditDto => r.body)
    );
  }

  /** Path part for operation `warrantyApplicationControllerDownloadFileidUserfileid()` */
  static readonly WarrantyApplicationControllerDownloadFileidUserfileidPath = '/warrantyapplication/{id}/file/{userfileid}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile$Response(params: DownloadFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadFile(params: DownloadFile$Params, context?: HttpContext): Observable<void> {
    return this.downloadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
