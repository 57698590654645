<mibp-page-support-menu [items]="supportMenu" (action)="onSupportMenuAction($event)"></mibp-page-support-menu>

<mibp-page-load-error *ngIf="hasError && !isUnauthorized"></mibp-page-load-error>

<div *ngIf="isUnauthorized">
  <mibp-system-message #type [messageType]="type.Types.Error">
    <h2>
      <mibp-resource-string *ngIf="isUnauthorized"  key="Global_Unauthorized"></mibp-resource-string>
    </h2>
  </mibp-system-message>
</div>



<mibp-breadrumbs-manual-control #breadcrumbControl></mibp-breadrumbs-manual-control>


<div *ngIf="equipmentId && !hasError">


  <mibp-loader *ngIf="isLoading"></mibp-loader>

  <section *ngIf="!isLoading && !hasError">
    <aside>
      <div class="my-card">
        <h3 class="my-header"><mibp-resource-string key="Global_Information_Header"></mibp-resource-string></h3>
        <table>
          <tr>
            <th>
              <mibp-resource-string key="PartsManual_DocumentName"></mibp-resource-string>:
            </th>
            <td>
              {{ partsManual.mediaName }}
            </td>
          </tr>
          <tr *ngIf="equipment.equipmentAlias">
            <th>
              <mibp-resource-string key="MyFleet_Alias"></mibp-resource-string>:
            </th>
            <td>
              {{ equipment.equipmentAlias }}
            </td>
          </tr>
          <tr>
            <th>
              <mibp-resource-string key="MyFleet_Machine"></mibp-resource-string>:
            </th>
            <td>
              {{ equipment.equipmentSerial }}
            </td>
          </tr>
        </table>

        <h3 class="my-header"><mibp-resource-string key="Global_Navigaton_Header"></mibp-resource-string></h3>
        <mibp-parts-manual-treeview (pageSelected)="loadPage($event)" [mediaIdentifier]="mediaIdentifier" [initialFolderId]="initialFolderId"></mibp-parts-manual-treeview>
        <div class="report-error" *ngIf="enableReportPartsManualSupportCase">
          <h3 class="my-header"><mibp-resource-string key="PartsManualReport_Report"></mibp-resource-string></h3>
          <div> <mibp-resource-string key="PartsManualReport_ReportErrorDescription"></mibp-resource-string></div>
          <button class="my-button my-button--fill crossSell-btn" (click)="showReportErrorModal()"><mibp-resource-string
              key="PartsManualReport_Report"></mibp-resource-string></button>
        </div>
      </div>
    </aside>

    <main>
      <mibp-parts-manual-folder-details (itemSelected)="loadTree()" [mediaFolderId]="initialFolderId" [mediaIdentifier]="mediaIdentifier"></mibp-parts-manual-folder-details>
      <div *ngIf="!initialFolderId" class="my-card">
        <!-- placeholder - here we can put some text for the start page of a parts manual-->
      </div>
    </main>
  </section>

</div>
<mibp-parts-manual-report-error-dialog #reportErrorDialog [request]="reportErrorDto"></mibp-parts-manual-report-error-dialog>
