import { MessageType } from './system-message.enum';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mibp-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SystemMessageComponent implements OnInit {

  public messageTypeClass: string;
  @Input() messageType: MessageType  = MessageType.Neutral;
  @Input() marginSize: number;

  // tslint:disable-next-line:variable-name
  public Types = MessageType;

  generateMessageTypeClass() {

    const classNames = ['system-message'];
    switch (this.messageType) {

    case MessageType.Error:
      classNames.push('error-message');
      break;

    case MessageType.Success:
      classNames.push('confirmation-message');
      break;

    case MessageType.Warning:
      classNames.push('warning-message');
      break;

    case MessageType.Neutral:
      classNames.push('neutral-message');
      break;
    }

    if (this.marginSize) {
      classNames.push(`system-message--nomargin`);
      classNames.push(`m-${this.marginSize}`);
    }

    classNames.push('text');
    return classNames.join(' ');
  }

  ngOnInit() {
    this.messageTypeClass =  this.generateMessageTypeClass();
  }
}
