import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonColors } from 'root/components/button/button.enum';
import { DropdownInput } from 'root/components/dropdown';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';
import { SupportCaseAttachmentDto, CaseType, ContactUsSupportCaseRequest } from 'root/mibp-openapi-gen/models';
import { FormValidationService, FormattingService, LoaderService, LocalizationService, NoticebarService, ToastService, GlobalConfigService } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MibpEmailValidator } from 'root/validators/email.validator';
import { requireCheckboxesToBeCheckedValidator } from 'root/validators/requireCheckboxesToBeChecked';
import { Subscription, firstValueFrom } from 'rxjs';

import { SupportCaseTechnicalRequestTypeResourceString, SupportCaseTechnicalRequestValue } from './contact-us-technical-request-form.interface';
import { ToastType } from 'root/services/toast-service/toast.enum';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';

@Component({
  selector: 'mibp-technical-request-form',
  templateUrl: './contact-us-technical-request-form.component.html',
  styleUrls: ['./contact-us-technical-request-form.component.scss']
})
export class ContactUsTechnicalRequestFormComponent implements OnInit, OnDestroy {

  @Output() successfullySubmitted = new EventEmitter<boolean>();
  protected formSubmitted = false;
  private enquiryResourceStringKeys = [
    SupportCaseTechnicalRequestTypeResourceString.FindItemInPartsManual,
    SupportCaseTechnicalRequestTypeResourceString.FindPart,
    SupportCaseTechnicalRequestTypeResourceString.RequestForPartsBreakDown,
    SupportCaseTechnicalRequestTypeResourceString.Replacement,
    SupportCaseTechnicalRequestTypeResourceString.GeneralTechnicalRequest
  ];

  private enquiryValues = [
    SupportCaseTechnicalRequestValue.FindItemInPartsManual,
    SupportCaseTechnicalRequestValue.FindPart,
    SupportCaseTechnicalRequestValue.RequestForPartsBreakDown,
    SupportCaseTechnicalRequestValue.Replacement,
    SupportCaseTechnicalRequestValue.GeneralTechnicalRequest];

  protected contactUsTechnicalRequestForm: UntypedFormGroup;
  protected enquiryTypes: DropdownInput[] = [];
  protected buttonColors = ButtonColors;

  protected isSerialNumberRequired = true;
  protected isDescriptionRequired = false;
  protected isPartNumberRequired = true;
  protected isEmailSelected: boolean;
  protected isPhoneNumberSelected: boolean;
  @Input() showHeader = true;
  private stopUsingResources: () => void;
  private formValueSubscription: Subscription;

  // Attachements
  protected fileNames: string[] = [];
  protected attachmentError = false;
  protected isUploadingAttachments = false;
  protected inputFields: number[] = [0];
  private attachedImages: SupportCaseAttachmentDto[] = [];
  private caseId: string;
  @ViewChildren('uploadInputElement') uploadInputElements: QueryList<ElementRef>;


  constructor(private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private mibpSession: MibpSessionService,
    private formattingService: FormattingService,
    private supportCaseController: SupportCaseApiController,
    private loader: LoaderService,
    private noticeService: NoticebarService,
    private localizationService: LocalizationService,
    private globalConfigService: GlobalConfigService,
    private toastService: ToastService){

  }
  ngOnDestroy(): void {
    if(this.stopUsingResources){
      this.stopUsingResources();
    }
    this.formValueSubscription?.unsubscribe();
  }

  setResourceStringSubscription(resourceStringKeys: string[], onComplete: (translatedStrings: string[]) => void) {
    this.stopUsingResources = this.localizationService.using(
      resourceStringKeys,
      resourceStrings => {
        onComplete(resourceStrings);
      }
    );
  }


  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.contactUsTechnicalRequestForm = this.fb.group({
      name: [this.mibpSession.current.user.firstName + ' ' + this.mibpSession.current.user.lastName, [Validators.required, MibpAllowedCharactersValidator()]],
      email: [this.mibpSession.current.user.email, MibpEmailValidator(this.formattingService)],
      phonenumber: [this.mibpSession.current.user.phone, MibpAllowedCharactersValidator()],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false)
      }, { validators: requireCheckboxesToBeCheckedValidator(1)}),
      enquiryType: [null,  Validators.required],
      serialNumber: [null, [Validators.required, MibpAllowedCharactersValidator()]],
      partNumber: [null, [Validators.required, MibpAllowedCharactersValidator()]],
      description: ['', MibpAllowedCharactersValidator()]
    });

    this.setResourceStringSubscription(this.enquiryResourceStringKeys, resourceStrings => {
      const types = [];
      resourceStrings.forEach((rs, index) => {
        types.push(<DropdownInput>{ value: this.enquiryValues[index], text: rs });
      });
      this.enquiryTypes = types;
    });

    this.formValueSubscription = this.contactUsTechnicalRequestForm.controls.contactPreferenceGroup.valueChanges.subscribe(() => {
      this.isEmailSelected = this.contactUsTechnicalRequestForm.controls.contactPreferenceGroup.get('byEmail').value;
      this.isPhoneNumberSelected = this.contactUsTechnicalRequestForm.controls.contactPreferenceGroup.get('byPhone').value;
      this.isEmailSelected ? this.addRequiredValidators('email') : this.removeRequiredValidators('email');
      this.isPhoneNumberSelected ? this.addRequiredValidators('phonenumber') : this.removeRequiredValidators('phonenumber');
    });

  }

  enquiryTypeChanged(value: DropdownInput){
    if(value){
      this.enquiryTypeChangedHandleValidators(value.value);
    }
  }

  enquiryTypeChangedHandleValidators(enquiryType: SupportCaseTechnicalRequestValue | string){
    if(enquiryType === SupportCaseTechnicalRequestValue.FindPart){
      this.removeRequiredValidators('serialNumber');
      this.isSerialNumberRequired = false;
    }
    else if(enquiryType === SupportCaseTechnicalRequestValue.GeneralTechnicalRequest){
      this.addRequiredValidators('description');
      this.removeRequiredValidators('serialNumber');
      this.removeRequiredValidators('partNumber');
      this.isDescriptionRequired = true;
      this.isSerialNumberRequired = false;
      this.isPartNumberRequired = false;
    }
    else{
      this.addRequiredValidators('serialNumber');
      this.isSerialNumberRequired = true;
      this.addRequiredValidators('partNumber');
      this.isPartNumberRequired = true;
      this.removeRequiredValidators('description');
      this.isDescriptionRequired = false;
    }
  }

  async submit() {
    this.formSubmitted = true;
    if (this.contactUsTechnicalRequestForm.invalid) {
      this.contactUsTechnicalRequestForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactUsTechnicalRequestForm, this.element);
      return;
    }
    const contactUsSupportCaseRequest = <ContactUsSupportCaseRequest>{
      caseType: CaseType.TechnicalRequest,
      categoryTechnicalRequest: this.contactUsTechnicalRequestForm.get('enquiryType').value.value,
      suppliedFullName: this.contactUsTechnicalRequestForm.get('name').value,
      suppliedEmail: this.contactUsTechnicalRequestForm.get('email').value,
      suppliedPhone: this.contactUsTechnicalRequestForm.get('phonenumber').value,
      serialNumber: this.contactUsTechnicalRequestForm.get('serialNumber').value,
      productCode: this.contactUsTechnicalRequestForm.get('partNumber').value,
      prefferedEmailContact: this.contactUsTechnicalRequestForm.controls.contactPreferenceGroup.get('byEmail').value,
      prefferedPhoneContact: this.contactUsTechnicalRequestForm.controls.contactPreferenceGroup.get('byPhone').value,
      description: this.contactUsTechnicalRequestForm.get('description').value
    };

    try {
      this.loader.showFullScreenLoader();
      this.caseId = await firstValueFrom(this.supportCaseController.submitContactUsCase({ body: contactUsSupportCaseRequest }));
      if (this.caseId) {

        if(this.attachedImages.length>0)
        {
          await this.uploadAttachments();
          if (this.attachmentError) {
            this.noticeService.show('Contact_Us_CreatedWithAttachmentError', NoticeType.Warning);
            return;
          }
        }
        this.successfullySubmitted.emit(true);

      }
      this.loader.hideFullScreenLoader();

    }
    catch (error) {
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(false);
    }
  }

  private addRequiredValidators(field: string){
    this.contactUsTechnicalRequestForm.get(field).addValidators([Validators.required]);
    this.contactUsTechnicalRequestForm.get(field).updateValueAndValidity();
  }

  private removeRequiredValidators(field: string){
    this.contactUsTechnicalRequestForm.get(field).removeValidators(Validators.required);
    this.contactUsTechnicalRequestForm.get(field).updateValueAndValidity();
  }



  // Handle file upload
  private async readfile(file: File): Promise<string> {
    const reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.onload = (e: ProgressEvent<FileReader>) => {
        resolve(e.target.result as string);
      };
      reader.readAsDataURL(file);
    });
  }

  addFiles(): void {
    this.uploadInputElements.last.nativeElement.click();
    this.addFileInput();
  }
  addFileInput(): void {
    this.inputFields.push(0);

  }
  fileChange(e: Event): void {

    const input = e.target as HTMLInputElement;
    const validFileExtensions = this.globalConfigService.supportedImageFileExtensions;
    let file;
    let invalidFileSelected = false;
    for (let i = 0; i < input.files.length; i++) {
      file = input.files.item(i);
      const fileExtension = file.name.split('.')[file.name.split('.').length - 1];
      if(validFileExtensions.includes(fileExtension)){
        this.addSingleFile(file);
      }
      else if(!invalidFileSelected){
        invalidFileSelected = true;
      }
    }
    if(invalidFileSelected){
      this.toastService.showWithTitle("Global_Invalid_ImageFileType_Message", "Global_Invalid_FileType_Title",{
        type: ToastType.Error
      });
    }
  }
  private async addSingleFile(file: File): Promise<void> {

    const fileContents = await this.readfile(file);
    this.fileNames.push(file.name);

    this.attachedImages.push(<SupportCaseAttachmentDto>{
      title: file.name,
      pathOnClient: file.name,
      description: "",
      fileCategory: "Pictures of Defect",
      body: fileContents
     });
  }

  async uploadAttachments() {
    try {
      this.isUploadingAttachments = true;
      await firstValueFrom(this.supportCaseController.uploadAttachments({ caseId: this.caseId, body: this.attachedImages }));
      this.loader.hideFullScreenLoader();
      this.attachmentError = false;
      this.isUploadingAttachments = false;
    } catch (error) {
      this.attachmentError = true;
      this.isUploadingAttachments = false;
      this.loader.hideFullScreenLoader();
    }
  }
  async retryUploadAttachments() {
    try {
      this.isUploadingAttachments = true;
      await firstValueFrom(this.supportCaseController.uploadAttachments({ caseId: this.caseId, body: this.attachedImages }));
      this.isUploadingAttachments = false;
      this.attachmentError = false;
      this.successfullySubmitted.emit(true);
    } catch (error) {
      this.attachmentError = true;
      this.isUploadingAttachments = false;
      this.noticeService.show('ContactUs_UploadAttachmentFailed', NoticeType.Error);

    }
  }

  deleteAttachment(filename: string) {
    this.fileNames.splice(this.fileNames.indexOf(filename),1);
    this.attachedImages.splice(this.fileNames.indexOf(filename),1);
  }

}
