import { TermsAndConditionsEvent, TermsAndConditionsTypes } from './../../components/terms-and-conditions/terms-and-conditions.enum';
import { TermsAndConditionsComponent } from './../../components/terms-and-conditions/terms-and-conditions.component';
import { Injectable } from '@angular/core';
import { ApiService } from 'root/services/mibp-api-services';
import { Subject, Observable, firstValueFrom, first } from 'rxjs';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ClientSideCacheService, CacheScope } from '../client-side-cache/client-side-cache.service';
import { MibpHttpApi } from '../mibp-http-api';
import { ResourceApiController } from './../../mibp-openapi-gen/services/resource-api-controller';
import { UsersApiController } from './../../mibp-openapi-gen/services/users-api-controller';
import { MibpSessionService } from '../mibp-session/mibp-session.service';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  showTermsAndConditionsSubject = new Subject<TermsAndConditionsEvent>();

  public generalTermsAndConditionsPublishDate?: Date;

  constructor(
    private api: ApiService,
    private cache: ClientSideCacheService,
    private sanitizer: DomSanitizer,
    private userApi: UsersApiController,
    private sessionService: MibpSessionService,
    private resourceController: ResourceApiController) {}

  get showTerms$() {
    return this.showTermsAndConditionsSubject.asObservable();
  }



  async acceptTermsAndConditions(): Promise<boolean> {
    await firstValueFrom(this.userApi.acceptTermsAndConditions());
    await this.sessionService.reloadSessionFromBackend();
    return true;
  }

  showTermsAndConditions(type: TermsAndConditionsTypes, isReadOnly = false): any {
    this.showTermsAndConditionsSubject.next({
      type: type,
      isReadOnly: isReadOnly
    });
  }

  /**
   * Load terms and condition text for given language and cache in memory
   */
  getTermsAndConditionsText(resourceStringKey: string, langCode: string): Promise<SafeHtml> {
    resourceStringKey = resourceStringKey.toLowerCase();
    const cacheKey = `${resourceStringKey}_${langCode}`;
    return new Promise((resolve, reject) => {
      let resourceValue = this.cache.get<string>(cacheKey);
      if (resourceValue) {
        resolve(this.sanitizer.bypassSecurityTrustHtml(resourceValue));
      } else {
        this.resourceController.getTranslation({
          languageCode: langCode,
          key: resourceStringKey
        }).subscribe({
          next: (translation) => {
            if (translation) {
              resourceValue = translation.value;
              this.cache.add(cacheKey, resourceValue, null, CacheScope.MemoryStorage);
              resolve(this.sanitizer.bypassSecurityTrustHtml(resourceValue));
            } else {
              reject(new Error(`Terms and conditions returned empty result: ${resourceStringKey} (${langCode})`));
            }
          },
          error: err => reject(err)
        });
      }
    });
  }

}
