/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getActiveSystemMessages } from '../fn/system-messages/get-active-system-messages';
import { GetActiveSystemMessages$Params } from '../fn/system-messages/get-active-system-messages';
import { getActiveSystemMessages$Plain } from '../fn/system-messages/get-active-system-messages-plain';
import { GetActiveSystemMessages$Plain$Params } from '../fn/system-messages/get-active-system-messages-plain';
import { GetActiveSystemMessagesQueryResponseVm } from '../models/get-active-system-messages-query-response-vm';
import { getForEditing } from '../fn/system-messages/get-for-editing';
import { GetForEditing$Params } from '../fn/system-messages/get-for-editing';
import { getForEditing$Plain } from '../fn/system-messages/get-for-editing-plain';
import { GetForEditing$Plain$Params } from '../fn/system-messages/get-for-editing-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { save } from '../fn/system-messages/save';
import { Save$Params } from '../fn/system-messages/save';
import { searchGrid } from '../fn/system-messages/search-grid';
import { SearchGrid$Params } from '../fn/system-messages/search-grid';
import { searchGrid$Plain } from '../fn/system-messages/search-grid-plain';
import { SearchGrid$Plain$Params } from '../fn/system-messages/search-grid-plain';
import { SystemMessageForEditingVm } from '../models/system-message-for-editing-vm';

@Injectable({ providedIn: 'root' })
export class SystemMessagesApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `systemMessagesControllerSearchGridoptionsLanguageCode()` */
  static readonly SystemMessagesControllerSearchGridoptionsLanguageCodePath = '/systemmessages/searchgrid';

  /**
   * List system message for admin pages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain$Response(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * List system message for admin pages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * List system message for admin pages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Response(params?: SearchGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * List system message for admin pages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid(params?: SearchGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `systemMessagesControllerSavecommand()` */
  static readonly SystemMessagesControllerSavecommandPath = '/systemmessages/save';

  /**
   * Create or update a system message.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * Create or update a system message.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<void> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemMessagesControllerGetForEditingid()` */
  static readonly SystemMessagesControllerGetForEditingidPath = '/systemmessages/{id}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Plain$Response(params: GetForEditing$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemMessageForEditingVm>> {
    return getForEditing$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Plain(params: GetForEditing$Plain$Params, context?: HttpContext): Observable<SystemMessageForEditingVm> {
    return this.getForEditing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemMessageForEditingVm>): SystemMessageForEditingVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEditing()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing$Response(params: GetForEditing$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemMessageForEditingVm>> {
    return getForEditing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEditing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEditing(params: GetForEditing$Params, context?: HttpContext): Observable<SystemMessageForEditingVm> {
    return this.getForEditing$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemMessageForEditingVm>): SystemMessageForEditingVm => r.body)
    );
  }

  /** Path part for operation `systemMessagesControllerGetActiveSystemMessageslanguageCode()` */
  static readonly SystemMessagesControllerGetActiveSystemMessageslanguageCodePath = '/systemmessages';

  /**
   * Get a list of active and soon upcoming system messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveSystemMessages$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveSystemMessages$Plain$Response(params?: GetActiveSystemMessages$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetActiveSystemMessagesQueryResponseVm>>> {
    return getActiveSystemMessages$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of active and soon upcoming system messages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveSystemMessages$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveSystemMessages$Plain(params?: GetActiveSystemMessages$Plain$Params, context?: HttpContext): Observable<Array<GetActiveSystemMessagesQueryResponseVm>> {
    return this.getActiveSystemMessages$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetActiveSystemMessagesQueryResponseVm>>): Array<GetActiveSystemMessagesQueryResponseVm> => r.body)
    );
  }

  /**
   * Get a list of active and soon upcoming system messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveSystemMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveSystemMessages$Response(params?: GetActiveSystemMessages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GetActiveSystemMessagesQueryResponseVm>>> {
    return getActiveSystemMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of active and soon upcoming system messages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveSystemMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveSystemMessages(params?: GetActiveSystemMessages$Params, context?: HttpContext): Observable<Array<GetActiveSystemMessagesQueryResponseVm>> {
    return this.getActiveSystemMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetActiveSystemMessagesQueryResponseVm>>): Array<GetActiveSystemMessagesQueryResponseVm> => r.body)
    );
  }

}
