<mibp-dialog #dialog [buttons]="dialogButtons" hideCloseButton="true" width="1100" (buttonClick)="onDialogButton($event)" (closed)="onDialogClosed()">

<div>
    <h1>Resource String Manager</h1>

  <mibp-refiner #refiner *ngIf="isOpen" [config]="refinerConfig" (filter)="filterResourceStrings($event)">
      <div  *ngIf="editMode" style="
      /* margin: 20px; */
      position: absolute;
      left: 30px;
      top: 30px;
      width: 95%;
      height: 92%;
      box-shadow: aqua;
      box-shadow: 3px 3px 3px rgba(0,0,0,0.25);
      padding-bottom: 60px;
      background-color: #fdfdfd;
      z-index: 9000;
      border: 1px solid #eee;
      padding:20px;
  ">

<div>
    <form *ngIf="resourceStringForm" [formGroup]="resourceStringForm" >
      <mibp-loader *ngIf="isLoadingResourceEdit"></mibp-loader>
        <div *ngIf="translation" [hidden]="isLoadingResourceEdit">
          <div class="forms__row">
            <label>Resource key</label>
            <div style="display: flex">
              <input style="flex: 1" type="text" disabled readonly value="{{ translation.resource.key }}" />
            </div>
          </div>
          <div class="forms__row">
            <label>Description</label>
            <p class="description">{{translation.resource.description}}</p>
          </div>
          <div class="forms__row">
            <label>Language</label>
            <mibp-resource-string [key]="'Global_LanguageName_' + languageCode"></mibp-resource-string>
          </div>
          <div *ngIf="!isHtml" class="forms__row">
            <label>Value <mibp-loader *ngIf="isWorking"></mibp-loader></label>
            <input type="text" #textElement formControlName="value" />
          </div>
          <div *ngIf="isHtml" class="forms__row">
              <label>Value <mibp-loader type='spinner' *ngIf="isWorking"></mibp-loader></label>
            <textarea #textareaElement  formControlName="value" style="height: 8em;width: 100%;"></textarea>
          </div>
          <mibp-system-message [messageType]="msg.Types.Information" #msg *ngIf="translation.resource.key.indexOf('ProductName_') === 0">
            <p><strong>NOTE!</strong> The Product translations requires the page to be reloaded for the change to be seen</p>
          </mibp-system-message>
          <button type="submit" [hidden]="true" (click)="onSubmit($event)"></button>
          <mibp-button (click)="onSubmit($event)" resourceStringKey="Global_Save" [color]="btn.Enum.Colors.Blue" #btn ></mibp-button>
          &nbsp;
          <mibp-button (click)="cancelEdit()" [color]="btn.Enum.Colors.Orange" resourceStringKey="Global_CancelButton"></mibp-button>
          </div>
      </form>
    </div>

</div>

      <div style="max-height: 400px; min-height: 400px; overflow-y: scroll;">
        <mibp-data-view *ngIf="resourceStringResult" [bind]="resourceStringResult" #result>
          <div isSuccess>
          <table class="resource-strings">
            <thead>
              <tr>
                <th>Key</th>
                <th>Description</th>
                <th colspan="2">Value</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let resource of result.data" [class.not-translated]="!resource.isTranslated" [class.is-translated]="resource.isTranslated">
                  <td><a href="javascript:void(0)" (click)="showEditor(resource.key)">{{resource.key}}</a></td>
                  <td>{{resource.description}}</td>
                  <td class="icon" [attr.title]="!resource.isTranslated ? 'Resource is not translated' : ''">
                    <span translate="no" class="material-icon">{{resource.isTranslated ? 'done' : 'close'}}</span>
                  </td>
                  <td [attr.title]="resource.value">{{resource.value | truncateString:25}}</td>
                </tr>
            </tbody>

          </table>
        </div>
        </mibp-data-view>
      </div>
      <form  [formGroup]="optionsForm">
        <div class="checkbox">
          <input type="checkbox" (change)="editorModeChange(cbx.checked)" #cbx id="editorEnabled" formControlName="editorEnabled" name="editorEnabled" />
          <label for="editorEnabled">
            Enable page editor tools
          </label>
        </div>
    </form>


  </mibp-refiner>

</div>



</mibp-dialog>
