/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MediaFolderVm } from '../../models/media-folder-vm';

export interface GetFolderPath$Params {
  mediaIdentifier: string;
  folderId: number;
}

export function getFolderPath(http: HttpClient, rootUrl: string, params: GetFolderPath$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MediaFolderVm>>> {
  const rb = new RequestBuilder(rootUrl, getFolderPath.PATH, 'get');
  if (params) {
    rb.path('mediaIdentifier', params.mediaIdentifier, {});
    rb.path('folderId', params.folderId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<MediaFolderVm>>;
    })
  );
}

getFolderPath.PATH = '/billofmaterial/media/{mediaIdentifier}/folders/{folderId}/path';
