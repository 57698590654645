

<div  *ngIf="isLoadingPage" class="my-card mb-2">
  <mibp-loader></mibp-loader>
</div>

<div *ngIf="mediaFolderId && justListChildren && !isLoadingPage" class=" my-card">
  <div  class="child-list">
    <div *ngFor="let child of childList" class="child" (click)="goToFolder(child)">
      <div *ngIf="child.thumbnailImage && !child.hasPdf" class="thumbnail">
        <img [src]="child.thumbnailImage">
      </div>
      <div *ngIf="child.hasPdf" class="icon">
        <span  translate="no" class="material-icon material-icon--xxxlarge">picture_as_pdf</span>
      </div>
      <div *ngIf="!child.hasPdf && !child.thumbnailImage" class="icon">
        <span  translate="no" class="material-icon material-icon--xxxlarge">image</span>
      </div>

      {{child.name}}
    </div>
  </div>
</div>

<div *ngIf="mediaFolderId && !justListChildren && !isLoadingPage">

  <div class="my-card mb-2" *ngIf="pdfUrl">
    <div class="my-toolbar">
      <div class="my-toolbar__left">
        <h3 class="my-header">{{ pdfName }}</h3>
      </div>
      <div class="my-toolbar__right">
        <button (click)="downloadPdf()" class="my-button my-button--blue my-button--fill">
          <mibp-resource-string key="Global_Download"></mibp-resource-string>
          ({{ pdfSize | formatBytes }})
        </button>
      </div>
    </div>

    <pdf-viewer [url]="pdfUrl"  [src]="pdfUrl" [render-text]="true" [original-size]="false" style="height: calc(100vh - 400px);"></pdf-viewer>
  </div>
<mibp-tabs class="illustration-tabs">
  <mibp-tab [resourceKey]="'PartsManual_Illustration_Header'">
   <div class="my-card mb-5" *ngIf="illustrations && illustrations.length > 0">
    <mibp-parts-manual-illustration [illustrations]="illustrations" [image]="selectedImage" [imageIsMissing]="imageIsMissing"></mibp-parts-manual-illustration>

  </div>
  <div class="my-card" *ngIf="isLoadingParts || parts?.length > 0">
    <h3 class="my-header"><mibp-resource-string key="PartsManual_BillOfMaterial_Header"></mibp-resource-string></h3>

    <mibp-loader *ngIf="isLoadingParts"></mibp-loader>
    <mibp-horizontal-scroll-container>
      <table class="my-table my-table--compact my-table--hover" *ngIf="!isLoadingParts">
        <thead>
          <tr>
            <th>#</th>
            <th><mibp-resource-string key="Global_ProductNumber"></mibp-resource-string></th>
            <th><mibp-resource-string key="Global_Name"></mibp-resource-string></th>
            <th><mibp-resource-string key="Global_RecommendedQuantity"></mibp-resource-string></th>
            <th *ngIf="canAddToCart"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let part of parts" [id]="'node_'+part.item" [class.is-loading]="part.addingToCart" [class.isSelected]="part.item === selectedNode">
            <td>{{part.item}}</td>
            <td>{{part.partNumber}}</td>
            <td>{{part.name}}&nbsp;{{part.description}}</td>
            <td>

              {{part.quantity || 1}}

              <span class="my-sandvik-uom" *ngIf="part.mySandvikUnitOfMeasure">
                <mibp-resource-string [key]="'Global_UoM_' + part.mySandvikUnitOfMeasure" [defaultValue]="part.mySandvikUnitOfMeasure"></mibp-resource-string>
              </span>
              <span class="bom-uom" *ngIf="!part.mySandvikUnitOfMeasure">
                {{ part.unitOfMeasure}}
              </span>

            </td>
            <td *ngIf="canAddToCart">
              <button type="button" *ngIf="part.existsInMySandvik" [disabled]="part.addingToCart" (click)="addToCart(part)" class="my-button my-button--small"><mibp-resource-string key="Carts_Active_AddToCartButton"></mibp-resource-string></button>
              <a href="javascript:void(0)" *ngIf="!part.existsInMySandvik" class="contact-us" (click)="openProductEnquiryDialog(part.partNumber, part.quantity || 1)">
                <mibp-resource-string key="Global_ContactUs"></mibp-resource-string>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </mibp-horizontal-scroll-container>
  </div>
</mibp-tab>
<mibp-tab [resourceKey]="'PartsManual_WhereUsed_Header'" [text]="whereUsedCount">
  <div class="my-card mb-5" *ngIf="mediaFolderId">
    <mibp-parts-manual-whereused [folderId]="mediaFolderId" (whereUsedSelected)="whereUsedSelected()"></mibp-parts-manual-whereused>
  </div>
</mibp-tab>
</mibp-tabs>


</div>
