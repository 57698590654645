/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deliveryAddressExists } from '../fn/maps/delivery-address-exists';
import { DeliveryAddressExists$Params } from '../fn/maps/delivery-address-exists';
import { deliveryAddressExists$Plain } from '../fn/maps/delivery-address-exists-plain';
import { DeliveryAddressExists$Plain$Params } from '../fn/maps/delivery-address-exists-plain';
import { OrderDeliveryAddressLookupResultVm } from '../models/order-delivery-address-lookup-result-vm';

@Injectable({ providedIn: 'root' })
export class MapsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mapsControllerDeliveryAddressExistscountryCodeCityNamePostalCode()` */
  static readonly MapsControllerDeliveryAddressExistscountryCodeCityNamePostalCodePath = '/maps/lookup/order-delivery-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryAddressExists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryAddressExists$Plain$Response(params?: DeliveryAddressExists$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderDeliveryAddressLookupResultVm>> {
    return deliveryAddressExists$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryAddressExists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryAddressExists$Plain(params?: DeliveryAddressExists$Plain$Params, context?: HttpContext): Observable<OrderDeliveryAddressLookupResultVm> {
    return this.deliveryAddressExists$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderDeliveryAddressLookupResultVm>): OrderDeliveryAddressLookupResultVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryAddressExists()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryAddressExists$Response(params?: DeliveryAddressExists$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderDeliveryAddressLookupResultVm>> {
    return deliveryAddressExists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryAddressExists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryAddressExists(params?: DeliveryAddressExists$Params, context?: HttpContext): Observable<OrderDeliveryAddressLookupResultVm> {
    return this.deliveryAddressExists$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderDeliveryAddressLookupResultVm>): OrderDeliveryAddressLookupResultVm => r.body)
    );
  }

}
