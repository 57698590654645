import { Component, EmbeddedViewRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { BroadcastService } from './../../services/broadcast-service/broadcast.service';
import { PermissionService } from 'root/services/permission';
import { UserAction } from "../context-menu";
import { filter, firstValueFrom, fromEvent, Subscription, take } from "rxjs";
import { ApiService, AuthService } from "root/services";
import { LoaderService } from 'root/services/loader/loader.service';
import { NoticebarService } from './../../services/noticebar-service/noticebar.service';
import { NoticeType } from "../noticebar/noticebar.enum";
import { MibpSessionService } from "root/services/mibp-session/mibp-session.service";
import { MibpOperationsService } from "root/services/operations/operations.service";
import { DialogButton } from "../dialog/dialog.types";

interface ActAsHistoryItem {
  deliverySequenceId: number;
  name: string;
}

@Component({
  selector: 'mibp-support-team-toolbar',
  templateUrl: './support-team-toolbar.component.html',
  styleUrls: ['./support-team-toolbar.component.scss']
})
export class MibpSupportTeamToolbarComponent implements OnInit, OnDestroy {

  @ViewChild('toolbarTemplate', {static: true}) dialogTemplate: TemplateRef<any>;
  embeddedView: EmbeddedViewRef<any>;
  isSupportTeamMember = false;
  isExpanded = false;

  sessionInfoButtons: DialogButton[] = [
    {
      id: 'close',
      textOverride: 'Close',
      resourceKey: ''
    }
  ];
  pageMenu: UserAction[] = [];
  actasMenu: UserAction[] = [];
  toolsMenuItems: UserAction[] = [
    {textOverride: 'Force refresh resources (translations)', name: 'refresh-resources'}
  ];
  mouseMoveSub?: Subscription;
  screenSizeSub?: Subscription;
  dragStartPosition: DOMRect;
  isDragging = false;
  actAsHistory: ActAsHistoryItem[];
  savePositionTimer?: number;
  clientId: string;
  sessionSub: Subscription;
  sessionInfo: string;


  protected signalrStatusText = 'idle';
  protected activeQueueMessages = 0;
  protected deadletterMessages = 0;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private signalRApi: ApiService,
    private loader: LoaderService,
    protected mibpSessionService: MibpSessionService,
    private operationsService: MibpOperationsService,
    private noticebar: NoticebarService,
    protected auth: AuthService,
    private permissions: PermissionService,
    private broadcast: BroadcastService) {}
  ngOnInit(): void {
    this.clientId = this.broadcast.snapshot.clientId;

    if (this.permissions.test({isSupportTeamMember: true})) {
      this.moveTemplateToBody();

      this.sessionSub = this.mibpSessionService.current$.subscribe(session => {
        this.sessionInfo = JSON.stringify(session, null, 2);
      });

      this.broadcast.supportPageMenuItems.subscribe(items => {
        this.pageMenu = items;
      });

      this.broadcast.signalR.pipe(filter(status => status != null)).subscribe((x) => {
        this.signalrStatusText = x.status;
      });

      this.operationsService.subscribeToQueueCount();

      this.operationsService.queueCount$.subscribe(q => {
        if (q) {
          let activeMessages = 0;
          let deadletterMessages = 0;
          q.result.topics.forEach(topic => {
            topic.subscriptions.forEach(sub => {
              activeMessages+= sub.activeMessageCount;
              deadletterMessages += sub.deadletterCount;
            });
          });
          this.activeQueueMessages = activeMessages;
          this.deadletterMessages = deadletterMessages;
        }
      });

    }


    this.actAsHistory = this.readActAsFavorites();
    this.updateActAsMenu();

    try {
      const toolbarPositionJson = localStorage.getItem('mibp.supporttoolbar.pos');
      const pos = JSON.parse(toolbarPositionJson) as {x: number; y: number};
      const supportToolbar = document.getElementById('support-toolbar');
      supportToolbar.style.left = `${pos.x}px`;
      supportToolbar.style.top = `${pos.y}px`;
      this.toolbarPositionSanityCheck();
    } catch {
      // do nothing
    }

    this.broadcast.deliverySequence.subscribe(ds => {


      if (!this.broadcast.snapshot.mibpSession?.user) {
        return;
      }

      if (ds) {
        const key = `${ds.companyCode} / ${ds.erpCustomerId} / ${ds.deliverySequenceNumber}`;
        const id = ds.deliverySequenceId;

        const existingIndex = this.actAsHistory.findIndex(i => i.deliverySequenceId == id);
        if (existingIndex != -1) {
          this.actAsHistory.splice(existingIndex, 1);
        }
        this.actAsHistory.splice(0, 0, {
          name: key,
          deliverySequenceId: id
        });

        this.actAsHistory = this.actAsHistory.splice(0, 10);
        localStorage.setItem(`mibp.${this.broadcast.snapshot.mibpSession.user.userId}_actas.history`, JSON.stringify(this.actAsHistory));
        this.updateActAsMenu();
      }

    });

    if (localStorage.getItem('mibp.supporttoolbar.expanded') === 'true') {
      this.expand();
    }

  }

  updateActAsMenu(): void {
    this.actasMenu = this.actAsHistory.map<UserAction>(ds => {
      return {
        textOverride: ds.name,
        name: `${ds.deliverySequenceId}`
      };
    });
  }



  private readActAsFavorites(): ActAsHistoryItem[] {

    if (!this.broadcast.snapshot.mibpSession?.user) {
      return [];
    }

    const jsonValue = localStorage.getItem(`mibp.${this.broadcast.snapshot.mibpSession?.user.userId}_actas.history`);
    if (jsonValue) {
      try {
        const o = JSON.parse(jsonValue);
        return o as ActAsHistoryItem[];
      } catch {}
    }
    return [];
  }


  ngOnDestroy(): void {
    this.embeddedView?.destroy();
    this.sessionSub?.unsubscribe();
  }


  dragStart(e: MouseEvent): void {
    document.body.style.userSelect = `none`;
    const supportToolbar = document.getElementById('support-toolbar');
    this.isDragging = true;

    if (!supportToolbar) {
      return;
    }

    this.dragStartPosition = supportToolbar.getBoundingClientRect();
    fromEvent(document, 'mouseup').pipe(take(1)).subscribe(c => {
      this.mouseMoveSub?.unsubscribe();
      document.body.style.userSelect = null;
      this.isDragging = false;
    });

    this.mouseMoveSub = fromEvent(document, 'mousemove').subscribe((c: MouseEvent) => {
      const newX = c.clientX - (e.clientX- this.dragStartPosition.x);
      const newY = c.clientY - (e.clientY- this.dragStartPosition.y);

      if (newX >= 0) {
        supportToolbar.style.left = `${newX}px`;
      }
      if (newY >= 0) {
        supportToolbar.style.top = `${newY}px`;
      }

      clearTimeout(this.savePositionTimer);
      this.savePositionTimer = window.setTimeout(() => {
        this.saveToolbarPosition();
      }, 100);


    });

  }

  private saveToolbarPosition(): void {

    if (this.isExpanded) {
      const pos = document.getElementById('support-toolbar')?.getBoundingClientRect();
      if (pos) {
        localStorage.setItem('mibp.supporttoolbar.pos', JSON.stringify({ x: pos.x, y: pos.y  }));
      }
    }
  }

  toolbarPositionSanityCheck(): void {
    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;

    const toolbar = document.getElementById('support-toolbar');

    if (!toolbar) {
      return;
    }


    const pos = toolbar.getBoundingClientRect();
    const toolbarRight = pos.left + pos.width;
    const toolbarBottom = pos.top + pos.height;
    const toolbarLeft = pos.top + pos.height;

    if (toolbarRight > winWidth ) {
      toolbar.style.left = `${winWidth - pos.width - 10 }px`;
    }
    if (toolbarBottom > winHeight ) {
      toolbar.style.top = `${winHeight - pos.height - 10 }px`;
    }

    if (toolbarLeft < 0) {
      toolbar.style.left = `10px`;
    }

    this.saveToolbarPosition();

  }

  expand(): void {
    this.isExpanded = true;

    this.screenSizeSub = this.broadcast.screenSize.subscribe(x => {
      if (x) {
        const toolbar = document.getElementById('support-toolbar');

        if (!toolbar) {
          return;
        }

        const pos = toolbar.getBoundingClientRect();
        const toolbarRight = pos.left + pos.width;
        const toolbarBottom = pos.top + pos.height;

        if (toolbarRight > x.width ) {
          toolbar.style.left = `${x.width - pos.width - 10 }px`;
        }
        if (toolbarBottom > x.height ) {
          toolbar.style.top = `${x.height - pos.height - 10 }px`;
        }
      }
    });

    localStorage.setItem('mibp.supporttoolbar.expanded', 'true');
    this.toolbarPositionSanityCheck();
    this.broadcast.setSupportToolbarOpen(true);

  }

  collapse(): void {
    this.isExpanded = false;
    this.screenSizeSub?.unsubscribe();
    localStorage.removeItem('mibp.supporttoolbar.expanded');
    this.broadcast.setSupportToolbarOpen(false);
  }

  pageMenuSelect(action: UserAction): void {
    this.broadcast.triggerSupportMenuAction(action);
  }

  async actAsMenuSelect(action: UserAction): Promise<void> {
    await this.mibpSessionService.actAs(parseInt(action.name, 10));
  }

  private moveTemplateToBody(): void {
    const bodyElement = document.querySelector('body');
    this.embeddedView = this.viewContainerRef.createEmbeddedView(this.dialogTemplate);
    this.embeddedView.rootNodes.forEach(rootNode => bodyElement.appendChild(rootNode));
  }

}

