import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonColors } from 'root/components/button/button.enum';
import { DropdownInput } from 'root/components/dropdown';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { DeliverySequenceApiController, SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';
import { AddressVm, CaseType, ContactUsSupportCaseRequest } from 'root/mibp-openapi-gen/models';
import { FormValidationService, FormattingService, LoaderService, LocalizationService, LogService, MibpLogger, NoticebarService } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MibpEmailValidator } from 'root/validators/email.validator';
import { requireCheckboxesToBeCheckedValidator } from 'root/validators/requireCheckboxesToBeChecked';
import { Subscription, firstValueFrom } from 'rxjs';
import { SupportCaseTradingAccountTypeResourceString, SupportCaseTradingAccountTypeValue } from './contact-us-trading-account-form.interface';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';
@Component({
  selector: 'mibp-contact-us-trading-account-form',
  templateUrl: './contact-us-trading-account-form.component.html',
  styleUrls: ['./contact-us-trading-account-form.component.scss']
})
export class ContactUsTradingAccountFormComponent implements OnInit, OnDestroy {

  @Input() showHeader = true;
  @Output() successfullySubmitted = new EventEmitter<boolean>();

  protected formSubmitted = false;
  private tradingAccountResourceStringKeys = [
    SupportCaseTradingAccountTypeResourceString.QueryAboutInvoice,
    SupportCaseTradingAccountTypeResourceString.QuestionAboutAccount,
    SupportCaseTradingAccountTypeResourceString.FollowUpOnCredit,
    SupportCaseTradingAccountTypeResourceString.AddNewAdress,
    SupportCaseTradingAccountTypeResourceString.EditAddress
  ];

  private tradingAccountValues = [
    SupportCaseTradingAccountTypeValue.QueryAboutInvoice,
    SupportCaseTradingAccountTypeValue.QuestionAboutAccount,
    SupportCaseTradingAccountTypeValue.FollowUpOnCredit,
    SupportCaseTradingAccountTypeValue.AddNewAdress,
    SupportCaseTradingAccountTypeValue.EditAddress
  ];
  protected log: MibpLogger;
  protected contactUsTradingAccountForm: UntypedFormGroup;
  protected tradingAccountTypes: DropdownInput[] = [];
  protected buttonColors = ButtonColors;


  protected showCurrentAddress = false;
  protected showNewAddress  = false;
  protected showPurchaseOrderNumber = true;
  protected showInvoiceNumber = true;
  protected isDescriptionRequired = false;
  protected isEmailSelected: boolean;
  protected isPhoneNumberSelected: boolean;


  private stopUsingResources: () => void;
  private formValueSubscription: Subscription;

  private caseId: string;
  private addressVm: AddressVm;
  private addressString: string;




  constructor(private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private mibpSession: MibpSessionService,
    private formattingService: FormattingService,
    private supportCaseController: SupportCaseApiController,
    private loader: LoaderService,
    private noticeService: NoticebarService,
    private localizationService: LocalizationService,
    private deliverySequenceController: DeliverySequenceApiController,
    private logService: LogService){

    this.log = this.logService.withPrefix("contact-us-trading-account-form.component");
  }
  ngOnDestroy(): void {
    if(this.stopUsingResources){
      this.stopUsingResources();
    }
    this.formValueSubscription?.unsubscribe();
  }

  setResourceStringSubscription(resourceStringKeys: string[], onComplete: (translatedStrings: string[]) => void) {
    this.stopUsingResources = this.localizationService.using(
      resourceStringKeys,
      resourceStrings => {
        onComplete(resourceStrings);
      }
    );
  }


  async ngOnInit(){
    this.initForm();
  }

  async initForm(){

    this.contactUsTradingAccountForm = this.fb.group({
      name: [this.mibpSession.current.user.firstName + ' ' + this.mibpSession.current.user.lastName, [Validators.required, MibpAllowedCharactersValidator()]],
      email: [this.mibpSession.current.user.email, MibpEmailValidator(this.formattingService)],
      phonenumber: [this.mibpSession.current.user.phone, MibpAllowedCharactersValidator()],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false)
      }, { validators: requireCheckboxesToBeCheckedValidator(1)}),
      tradingAccountType: [null, Validators.required],
      purchaseOrderNumber: [null, [Validators.required, MibpAllowedCharactersValidator()]],
      invoiceNumber: [null, [Validators.required, MibpAllowedCharactersValidator()]],
      currentAddress: ['', MibpAllowedCharactersValidator()],
      newAddress: ['', MibpAllowedCharactersValidator()],
      description: ['', MibpAllowedCharactersValidator()]
    });



    this.setResourceStringSubscription(this.tradingAccountResourceStringKeys, resourceStrings => {
      const types = [];
      resourceStrings.forEach((rs, index) => {
        types.push(<DropdownInput>{ value: this.tradingAccountValues[index], text: rs });
      });
      this.tradingAccountTypes = types;
    });

    this.formValueSubscription = this.contactUsTradingAccountForm.controls.contactPreferenceGroup.valueChanges.subscribe(() => {
      this.isEmailSelected = this.contactUsTradingAccountForm.controls.contactPreferenceGroup.get('byEmail').value;
      this.isPhoneNumberSelected = this.contactUsTradingAccountForm.controls.contactPreferenceGroup.get('byPhone').value;
      this.isEmailSelected ? this.addRequiredValidators('email') : this.removeRequiredValidators('email');
      this.isPhoneNumberSelected ? this.addRequiredValidators('phonenumber') : this.removeRequiredValidators('phonenumber');
    });
  }

  tradingAccountTypeChanged(value: DropdownInput){
    if(value){
      this.enquiryTypeChangedHandleValidators(value.value);
    }
  }

  async enquiryTypeChangedHandleValidators(enquiryType: SupportCaseTradingAccountTypeValue | string): Promise<void> {
    this.showCurrentAddress = false;
    this.showNewAddress  = false;
    this.showPurchaseOrderNumber= true;
    this.showInvoiceNumber = true;
    this.isDescriptionRequired = false;
    this.addRequiredValidators('invoiceNumber');
    this.addRequiredValidators('purchaseOrderNumber');
    this.removeRequiredValidators('currentAddress');
    this.removeRequiredValidators('newAddress');
    this.removeRequiredValidators('description');

    if (enquiryType === SupportCaseTradingAccountTypeValue.EditAddress) {
      this.contactUsTradingAccountForm.patchValue({ newAddress: '' });
      await this.getAddress();
      this.removeRequiredValidators('purchaseOrderNumber');
      this.removeRequiredValidators('invoiceNumber');
      this.removeRequiredValidators('newAddress');
      this.showPurchaseOrderNumber = false;
      this.showInvoiceNumber = false;
      this.showNewAddress = false;

      this.addRequiredValidators('currentAddress');
      this.showCurrentAddress = true;

    }
    else if (enquiryType === SupportCaseTradingAccountTypeValue.AddNewAdress) {
      this.contactUsTradingAccountForm.patchValue({ currentAddress: '' });
      this.addRequiredValidators('newAddress');
      this.showNewAddress = true;

      this.removeRequiredValidators('purchaseOrderNumber');
      this.removeRequiredValidators('invoiceNumber');
      this.removeRequiredValidators('currentAddress');
      this.showPurchaseOrderNumber = false;
      this.showInvoiceNumber = false;


    }
    else if (enquiryType === SupportCaseTradingAccountTypeValue.QuestionAboutAccount) {
      this.showPurchaseOrderNumber = false;
      this.removeRequiredValidators('purchaseOrderNumber');
      this.showInvoiceNumber = false;
      this.removeRequiredValidators('invoiceNumber');

      this.isDescriptionRequired = true;
      this.addRequiredValidators('description');
    }
  }

  async submit(){
    this.formSubmitted = true;
    if (this.contactUsTradingAccountForm.invalid) {
      this.contactUsTradingAccountForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactUsTradingAccountForm, this.element);
      return;
    }
    const contactUsSupportCaseRequest = <ContactUsSupportCaseRequest>{
      caseType: CaseType.TradingAccountQuery,
      categoryTradingAccountQuery: this.contactUsTradingAccountForm.get('tradingAccountType').value.value,
      suppliedFullName: this.contactUsTradingAccountForm.get('name').value,
      suppliedEmail: this.contactUsTradingAccountForm.get('email').value,
      suppliedPhone: this.contactUsTradingAccountForm.get('phonenumber').value,
      orderNumber: this.contactUsTradingAccountForm.get('purchaseOrderNumber').value,
      invoiceNumber: this.contactUsTradingAccountForm.get('invoiceNumber').value,
      currentAddress: this.contactUsTradingAccountForm.get('currentAddress').value,
      newAddress: this.contactUsTradingAccountForm.get('newAddress').value,
      prefferedEmailContact: this.contactUsTradingAccountForm.controls.contactPreferenceGroup.get('byEmail').value,
      prefferedPhoneContact: this.contactUsTradingAccountForm.controls.contactPreferenceGroup.get('byPhone').value,
      description: this.contactUsTradingAccountForm.get('description').value
    };

    try {
      this.loader.showFullScreenLoader();
      this.caseId = await firstValueFrom(this.supportCaseController.submitContactUsCase({ body: contactUsSupportCaseRequest }));
      if (this.caseId) {


        this.successfullySubmitted.emit(true);

      }
      this.loader.hideFullScreenLoader();

    }
    catch (error) {
      this.loader.hideFullScreenLoader();
      this.successfullySubmitted.emit(false);
    }
  }


  private addRequiredValidators(field: string){
    this.contactUsTradingAccountForm.get(field).addValidators([Validators.required]);
    this.contactUsTradingAccountForm.get(field).updateValueAndValidity();
  }

  private removeRequiredValidators(field: string){
    this.contactUsTradingAccountForm.get(field).removeValidators(Validators.required);
    this.contactUsTradingAccountForm.get(field).updateValueAndValidity();
  }


  private async getAddress() {
    try {

      this.addressVm = await firstValueFrom(this.deliverySequenceController.getAddressLinesForActiveDeliverySequence());

      const phoneNumbers = this.addressVm?.phoneNumbers !== null ? this.addressVm?.phoneNumbers?.forEach((p) => {
        p !== null ? p : '';
      }) : '';

      this.addressString = this.addressVm?.name
        + '\n' + this.addressVm?.addressLine1
        + '\n' + this.addressVm?.addressLine2
        + '\n' + this.addressVm?.addressLine3
        + '\n' + this.addressVm?.postalCode + this.addressVm?.city
        + '\n' + this.addressVm?.country
        + '\n' + phoneNumbers;

      this.contactUsTradingAccountForm.patchValue({ currentAddress: this.addressString });
    }
    catch (error) {
      this.log.error('Error when loading addresslines for deliverysequence', error);

    }
    finally{
      this.addressString = '';
    }
  }

}
