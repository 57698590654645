
export enum TermsAndConditionsTypes {
  General,
  Company,
  Information,
  Updated,
  Privacy,
  DigitalServiceTerms
}

export interface TermsAndConditionsEvent {
  type: TermsAndConditionsTypes;
  isReadOnly?: boolean;
}
