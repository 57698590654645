import { ApiService } from 'root/services';
import { Input, OnInit, ElementRef, ViewChild, Component, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { MibpNoWhitespaceValidator } from 'root/validators/no-whitespace.validator';

export enum ExpandableTitleType {
  Default = 0,
  HelpList = 1,
}

@Component({
  selector: 'mibp-expandable-section-title',
  templateUrl: './expandable-section-title.component.html',
  styleUrls: ['./expandable-section-title.component.scss']
})
export class ExpandableSectionTitleComponent implements OnInit {

  @Input() title: string;
  @Input() titleType: ExpandableTitleType = ExpandableTitleType.Default;
  @Input() isExpanded = false;
  @Input() allowEdit: boolean;
  @Input() design: 'default' | 'modern' = 'default';
  @Output() editState: EventEmitter<boolean> = new EventEmitter();
  @Output() doneEditing: EventEmitter<string> = new EventEmitter();
  @ViewChild("cartNameElement", { read: ElementRef }) cartNameElement: ElementRef;
  nameForm: UntypedFormGroup;
  isEditingValue: boolean;
  get isEditing() {
    return this.isEditingValue;
  }
  set isEditing(value: boolean) {
    this.isEditingValue = value;
    this.editState.emit(this.isEditingValue);
  }
  unchangedCartName: string = null;

  constructor(private api: ApiService, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.nameForm = this.fb.group({
      name: ['', [Validators.required, MibpNoWhitespaceValidator()]]
    });
  }

  startEditName(event: Event) {
    event.stopPropagation();
    if (!this.allowEdit) {
      return;
    }

    this.unchangedCartName = this.cleanString(this.title);
    this.nameForm.setValue({name: this.unchangedCartName});
    this.isEditing = true;
    setTimeout( () => {
      this.cartNameElement.nativeElement.focus();
      this.cartNameElement.nativeElement.select();
    }, 10);
  }

  nameElementBlur() {
    if (this.nameForm.controls['name'].value === this.unchangedCartName || this.nameForm.controls['name'].invalid) {
      this.unchangedCartName = null;
      this.isEditing = false;
      return;
    }

    const newName = this.nameForm.controls['name'].value !== '' ? this.cleanString(this.nameForm.controls['name'].value) : this.unchangedCartName;

    this.title = newName;
    this.isEditing = false;
    this.unchangedCartName = null;
    this.doneEditing.emit(this.title);
  }

  nameElementKeyDown(e: KeyboardEvent) {
    if (e.key === 'Tab' || e.key === 'Enter') {
      // This is to prevent the page from scrolling down
      // since we currently do not control tabIndex for elements
      e.preventDefault();
      e.stopPropagation();
      (e.target as HTMLInputElement).blur();
    } else if (e.key === "Escape") {
      this.nameForm.setValue({
        name: this.unchangedCartName
      });
      (e.target as HTMLInputElement).blur();
    } else {
      e.stopPropagation();
    }
  }

  clickedRenameInput(event: Event) {
    event.stopPropagation();
  }

  // Replace tabs, multiple spaces and new lines with single space.
  // Remove space characters at the beginning and end of the string
  cleanString(input: string): string {
    return input.replace(/\s\s+/g, ' ').trim();
  }

}

