import { ResolvedScope } from 'root/mibp-openapi-gen/models';
import { SignalR_ApiResponseGeneric } from './../mibp-api-services/_mibp-api-generated.service.types';
import { MibpHttpApi } from './mibp-http-api.service';

/**
 * Provides an API And for fetching large resource strings that are too big for SignalR
 */
export class FeatureHttpService {

  constructor(private httpApi: MibpHttpApi) {}

  public async AddFeatureResponsibilityUsingResolvedScopes(identifier: string, resolvedScopes: ResolvedScope[]): Promise<unknown> {
    return await this.httpApi.post(`/feature/save/${encodeURIComponent(identifier)}`, resolvedScopes).toPromise();
  }

  public async GetFeatureScopes(identifier : string): Promise<SignalR_ApiResponseGeneric<ResolvedScope[]>> {
    return await this.httpApi.get<SignalR_ApiResponseGeneric<ResolvedScope[]>>(`/feature/scopes/${encodeURIComponent(identifier)}`).toPromise();
  }
}
