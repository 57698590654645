import { MibpHttpApi } from './mibp-http-api.service';
import { Guid } from 'guid-typescript';
import { FormattingService } from '..';
import { MibpLogSearchRequest } from '../operations/operations.types';
import { Observable } from 'rxjs';

/**
 * Provides an API And for fetching large resource strings that are too big for SignalR
 */
export class MibpLogHttpService {

  constructor(private httpApi: MibpHttpApi, private formatting: FormattingService) {}

  private errorsLogged = 0;
  private maxErrorsLogged = 5; // Let's not log too much if it gets crazy

  public async getErrorSummary(from: Date, to: Date): Promise<MibpLogErrorSummaryItemVm[]> {
    const querystring = this.httpApi.createQuerystring({
      from: from ? this.formatting.toServerUTCString(from) : null,
      to: to ? this.formatting.toServerUTCString(to) : null,
    });
    return await this.httpApi.get<MibpLogErrorSummaryItemVm[]>(`/mibplog/logs/errors${querystring}`).toPromise();
  }

  public async getLoggedTags(type: MibpLogType, from?: Date, to?: Date, query?: string, skip?: number, take?: number): Promise<LookupResponseVm> {
    const querystring = this.httpApi.createQuerystring({
      from: from ? this.formatting.toServerUTCString(from) : null,
      to: to ? this.formatting.toServerUTCString(to) : null,
      take: take ? `${take}` : null,
      skip: skip ? `${skip}` : null,
      query: query ? `${query}` : null,
    });
    return await this.httpApi.get<LookupResponseVm>(`/mibplog/tags/${type}${querystring}`).toPromise();
  }

  public async getLoggedFieldValues(type: MibpLogType, fieldName: string, from?: Date, to?: Date, query?: string, skip?: number, take?: number): Promise<LookupResponseVm> {
    const querystring = this.httpApi.createQuerystring({
      from: from ? this.formatting.toServerUTCString(from) : null,
      to: to ? this.formatting.toServerUTCString(to) : null,
      fieldName,
      take: take ? `${take}` : null,
      skip: skip ? `${skip}` : null,
      query: query ? `${query}` : null,
    });
    return await this.httpApi.get<LookupResponseVm>(`/mibplog/fieldvalues/${type}${querystring}`).toPromise();
  }

  public async getLoggedProperties(type: MibpLogType, from?: Date, to?: Date, query?: string, skip?: number, take?: number): Promise<LookupResponseVm> {

    const querystring = this.httpApi.createQuerystring({
      from: from ? this.formatting.toServerUTCString(from) : null,
      to: to ? this.formatting.toServerUTCString(to) : null,
      take: take ? `${take}` : null,
      skip: skip ? `${skip}` : null,
      query: query ? `${query}` : null,
    });

    return await this.httpApi.get<LookupResponseVm>(`/mibplog/properties/${type}${querystring}`).toPromise();
  }


  public search(type: MibpLogType, request: MibpLogSearchRequest): Observable<MibpLogSearchResultVm> {
    return this.httpApi.post<MibpLogSearchResultVm>(`/mibplog/search/${type}`, request);
  }

  public async GetAttachmentContent(blobFilename: string): Promise<MibpLogAttachmentContent> {
    return await this.httpApi.get<MibpLogAttachmentStringContent>(`/mibplog/attachments/${blobFilename}/content`).toPromise();
  }

  public async prettifyMessage(content: string): Promise<PrettifyResult> {
    return await this.httpApi.post<PrettifyResult>(`/mibplog/attachments/prettify`, content).toPromise();
  }

  public async lookupUsers(userIds: Guid[]): Promise<{ [key: string]: string}> {
    return await this.httpApi.post<{ [key: string]: string}>(`/mibplog/users/lookup`, userIds).toPromise();
  }


  private getHeapMb(): number {
    if (performance['memory'] && performance['memory'].usedJSHeapSize) {
      return Math.round(performance['memory'].usedJSHeapSize / 1048576);
    }
    return null;
  }

  private getUsedHeapPercentage(): number {
    if (performance['memory'] && performance['memory'].usedJSHeapSize && performance['memory'].jsHeapSizeLimit) {
      return Math.round(performance['memory'].usedJSHeapSize / performance['memory'].jsHeapSizeLimit * 100);
    }
    return null;
  }


  public async logFrontendError(e: any): Promise<void> {

    if (this.errorsLogged > this.maxErrorsLogged) {
      return Promise.resolve();
    }

    this.errorsLogged++;

    // Try to serialize error
    // If this fails without try/catch the error will be hard to see in console
    let stack: string = null;
    try {
      stack = JSON.stringify(e);
    } catch (stringifyException) {
      stack = `ERROR: Could not serialize exception to JSON`;
    }

    // Log as much as we possibly can regarding the javascript error
    const err: MibpLogFrontendErrorVm = {
      url: window.location.href,
      referrer: document.referrer,
      cookie: navigator.cookieEnabled ? document.cookie : 'disabled',
      language: navigator.language,
      connection: navigator && navigator['connection'] && navigator['connection']['effectiveType'] ? navigator['connection']['effectiveType'] : null,
      hasFocus: document.hasFocus(),
      readyState: document.readyState,
      pageAgeSeconds: Math.round(performance.now() / 1000), // page age in seconds
      heapMb: this.getHeapMb(),
      heapPercent: this.getUsedHeapPercentage(),
      browserLanguage: navigator.language,
      userAgent: window.navigator.userAgent,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      inFrame: window.parent !== window,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      message: e?.message,
      stack: stack
    };

    return this.httpApi.post<void>('/mibplog/error', err).toPromise();
  }

}

export interface MibpLogAttachmentStringContent {
  content: string;
}

export interface MibpLogFrontendErrorVm {
  url: string;
  referrer: string;
  cookie: string;
  language: string;
  connection: string;
  hasFocus: boolean;
  readyState: string;
  pageAgeSeconds?: number;
  heapMb?: number;
  heapPercent?: number;
  browserLanguage: string;
  userAgent: string;
  screenWidth: number;
  screenHeight: number;
  inFrame: boolean,
  windowWidth: number;
  windowHeight: number;
  message: string;
  stack: string;
}

export interface MibpLogAttachmentContent {
  content: string;
}

export interface ListUserActionsQueryResultVm {
  totalCount: number;

  items: ListUserActionQueryRowVm[];
}

export interface ListUserActionQueryRowVm {
  userId: Guid;
  message: string;
  correlationId: string;
  userActionType: string;
  durationMs?: number;
  date: string;
  origin: string;
  attachments: AttachmentItemVm[];
  properties: { [key: string]: string};
}

export interface AttachmentItemVm {
  title: string;
  id: number;
  bytes: number;
}

export interface PrettifyResult {
  type: string;
  result: string;
  message: string;
}

export interface LogRowSnapshotResponseVm
{
    debugCount: number;
    informationCount: number;
    warningCount: number;
    errorCount: number;
    topErrors: LogRowSnapshotTopErrorVm[];
    logRowsByOrigin: { [key: string]: LogRowSnapshotLogTypesVm};

}

export interface LogRowSnapshotTopErrorVm
{
    exceptionType: string;
    Message: string;
    count: number;
    lastSeen: Date;
}


export interface LogRowSnapshotLogTypesVm
{
    errorCount: number;
    informationCount: number;
    warningCount: number;
    debugCount: number;
}

export interface LogRowMetadataVm
{
    origins: string[];
}



export enum LogRowSortingOrder
{
    Default,
    LogDate,
    LogLevel,
    Origin,
    UserId,
    CorrelationId,
    ExceptionType,
    Location,
    Duration
}

export enum LogRowSortingOrderDirection
{
    Ascending,
    Descending
}

export interface LogRowListRequestVm
{
    take: number;
    skip: number;

    query?: string;

    orderBy?: LogRowSortingOrder;

    orderByDirection?: LogRowSortingOrderDirection;

    origin?: string[];

    logLevel?: string[];

    from?: string;
    to?: string;

    exceptionType?: StringFilterParameter;
    location?: StringFilterParameter;

    userId?: GuidFilterParameter;
    correlationId?: GuidFilterParameter;

    duration?: IntRangeFilterParameter;

}

export enum BasicFilterComparisonType
{
    EqualTo,
    NotEmpty,
    Empty
}

export interface GuidFilterParameter
{
    type: BasicFilterComparisonType;
    value?: Guid;
}

export interface IntRangeFilterParameter
{
    min?: number;
    max?: number;
}

export interface StringFilterParameter
{
    type: StringFilterComparisonType;
    Value?: string;

}

export enum StringFilterComparisonType
{
    EqualTo,
    NotEmpty,
    Empty,
    Contains
}


export interface MibpLogSearchResultVm {
  columns: string[];
  items: { [key: string]: any }[];
  continuationToken?: string;
  query: string;
}

export interface MibpLogSearchResultAttachmentVm {
  blobFilename: string;
  bytes: number;
  title: string;
}

export interface MibpLogErrorSummaryItemVm {
  exceptionType: string;
  regionName: string;
  websiteSiteName: string;
  dateTime: Date;
  origin: string;
  count: number;
  isWarning: boolean;
}

export interface LookupValueVm
{
  value: string;
  count: number;
}

export interface LookupResponseVm {
  count: number;
  items: LookupValueVm[];
}

export type MibpLogType = 'logrow' | 'useraction' | 'integrationlog';
