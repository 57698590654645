import { CartNameComponent } from './../../modules/home/modules/cart/components/cart-name/cart-name.component';
import { Input, Component, Output, OnInit, ViewChild, ElementRef } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { LogService, MibpLogger } from 'root/services';
import { ExpandableTitleType } from './expandable-section-title/expandable-section-title.component';


@Component({
  selector: 'mibp-expandable-section',
  templateUrl: './expandable-section.component.html',
  styleUrls: ['./expandable-section.component.scss']
})
export class ExpandableSectionComponent implements OnInit {

  log: MibpLogger;
  isExpanded = false;
  editTitle = false;

  @Input() title: string;
  @Input() allowEditTitle = false;
  @Input() titleType: ExpandableTitleType = ExpandableTitleType.Default;
  @Input() canExpand = true;
  @Input() asideClass: 'is-3' | 'is-1' = 'is-3';
  @Input() compact = false;
  @Input() design: 'default' | 'modern' = 'default';
  @Output() expand = new EventEmitter();
  @Output() collapse = new EventEmitter();
  @Output() finishedEditingTitle = new EventEmitter();
  // @ViewChild("expandBtn", {read: ElementRef, static: false}) expandButton: ElementRef;

  asideClassName = 'column is-vcentered';

  /**
   * Show the right side section of the header
   */
  @Input() showAside: boolean;
  @Input() disableContentPadding = false;

  constructor (logger: LogService) {
    this.log = logger.withPrefix('ExpandableSectionComponent');
  }

  ngOnInit() {
    this.asideClassName += ` ${this.asideClass}`;
  }

  toggleExpanded() {
    if (this.canExpand) {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.expand.emit(null);
      } else {
        this.collapse.emit(null);
      }
    } else {
      this.expand.emit(null);
    }


  }

  toggleEditTitle(isEditing: boolean) {
    this.editTitle = isEditing;

    // Disable button while editing to prevent toggling the button when pressing spacebar
    // this.expandButton.nativeElement.disabled = this.editTitle;
  }

  onFinishedEditingTitle(newName: any) {
    this.finishedEditingTitle.emit(newName);
  }

}
