import { Injectable } from '@angular/core';
import { GlobalConfigService } from 'src/app/services/';
import { MibpSessionService } from '../mibp-session/mibp-session.service';

@Injectable({
  providedIn: 'root'
})
export class DynatraceUserService {

  private _isDynatraceUser: boolean;
  private _isDynatraceUserChecked: boolean;

  constructor(
    private globalConfig: GlobalConfigService,
    private session: MibpSessionService


  ) { }

  get isDynatraceUser(): boolean {
    if (this._isDynatraceUserChecked) {
      return this._isDynatraceUser;
    }
    else {
      this._isDynatraceUser = this.isCurrentUserDynatraceUser();
      this._isDynatraceUserChecked = true;
      return this._isDynatraceUser;
    }
  }

  private isCurrentUserDynatraceUser() {
    const dynatraceEmails = this.globalConfig.dynatraceUserEmails;
    const isDynatraceEmail = dynatraceEmails.some(dynatraceEmail => dynatraceEmail == this.session?.current?.user?.email?.toLocaleLowerCase());

    return isDynatraceEmail;


  }
}
