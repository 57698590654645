/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { delete$ } from '../fn/fleet-documents/delete';
import { Delete$Params } from '../fn/fleet-documents/delete';
import { FleetDocument } from '../models/fleet-document';
import { FleetDocumentType } from '../models/fleet-document-type';
import { FleetDocumentViewModel } from '../models/fleet-document-view-model';
import { getById } from '../fn/fleet-documents/get-by-id';
import { GetById$Params } from '../fn/fleet-documents/get-by-id';
import { getById$Plain } from '../fn/fleet-documents/get-by-id-plain';
import { GetById$Plain$Params } from '../fn/fleet-documents/get-by-id-plain';
import { getDocumentType } from '../fn/fleet-documents/get-document-type';
import { GetDocumentType$Params } from '../fn/fleet-documents/get-document-type';
import { getDocumentType$Plain } from '../fn/fleet-documents/get-document-type-plain';
import { GetDocumentType$Plain$Params } from '../fn/fleet-documents/get-document-type-plain';
import { getUserFileId } from '../fn/fleet-documents/get-user-file-id';
import { GetUserFileId$Params } from '../fn/fleet-documents/get-user-file-id';
import { getUserFileId$Plain } from '../fn/fleet-documents/get-user-file-id-plain';
import { GetUserFileId$Plain$Params } from '../fn/fleet-documents/get-user-file-id-plain';
import { save } from '../fn/fleet-documents/save';
import { Save$Params } from '../fn/fleet-documents/save';
import { search } from '../fn/fleet-documents/search';
import { Search$Params } from '../fn/fleet-documents/search';
import { search$Plain } from '../fn/fleet-documents/search-plain';
import { Search$Plain$Params } from '../fn/fleet-documents/search-plain';

@Injectable({ providedIn: 'root' })
export class FleetDocumentsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fleetDocumentsControllerSearchrequest()` */
  static readonly FleetDocumentsControllerSearchrequestPath = '/fleetdocuments/search';

  /**
   * Search the fleetdocuments based on search options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain$Response(params?: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FleetDocumentViewModel>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search the fleetdocuments based on search options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain(params?: Search$Plain$Params, context?: HttpContext): Observable<FleetDocumentViewModel> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FleetDocumentViewModel>): FleetDocumentViewModel => r.body)
    );
  }

  /**
   * Search the fleetdocuments based on search options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<FleetDocumentViewModel>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * Search the fleetdocuments based on search options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<FleetDocumentViewModel> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<FleetDocumentViewModel>): FleetDocumentViewModel => r.body)
    );
  }

  /** Path part for operation `fleetDocumentsControllerDeleteid()` */
  static readonly FleetDocumentsControllerDeleteidPath = '/fleetdocuments/delete/{id}';

  /**
   * delete the fleetdocuments based on id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * delete the fleetdocuments based on id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fleetDocumentsControllerGetByIdid()` */
  static readonly FleetDocumentsControllerGetByIdidPath = '/fleetdocuments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain$Response(params: GetById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FleetDocument>> {
    return getById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain(params: GetById$Plain$Params, context?: HttpContext): Observable<FleetDocument> {
    return this.getById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FleetDocument>): FleetDocument => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Response(params: GetById$Params, context?: HttpContext): Observable<StrictHttpResponse<FleetDocument>> {
    return getById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById(params: GetById$Params, context?: HttpContext): Observable<FleetDocument> {
    return this.getById$Response(params, context).pipe(
      map((r: StrictHttpResponse<FleetDocument>): FleetDocument => r.body)
    );
  }

  /** Path part for operation `fleetDocumentsControllerGetDocumentTypeid()` */
  static readonly FleetDocumentsControllerGetDocumentTypeidPath = '/fleetdocuments/{id}/documenttype';

  /**
   * get the fleetdocument type .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentType$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentType$Plain$Response(params: GetDocumentType$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FleetDocumentType>> {
    return getDocumentType$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get the fleetdocument type .
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentType$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentType$Plain(params: GetDocumentType$Plain$Params, context?: HttpContext): Observable<FleetDocumentType> {
    return this.getDocumentType$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FleetDocumentType>): FleetDocumentType => r.body)
    );
  }

  /**
   * get the fleetdocument type .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentType$Response(params: GetDocumentType$Params, context?: HttpContext): Observable<StrictHttpResponse<FleetDocumentType>> {
    return getDocumentType(this.http, this.rootUrl, params, context);
  }

  /**
   * get the fleetdocument type .
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentType(params: GetDocumentType$Params, context?: HttpContext): Observable<FleetDocumentType> {
    return this.getDocumentType$Response(params, context).pipe(
      map((r: StrictHttpResponse<FleetDocumentType>): FleetDocumentType => r.body)
    );
  }

  /** Path part for operation `fleetDocumentsControllerGetUserFileIdid()` */
  static readonly FleetDocumentsControllerGetUserFileIdidPath = '/fleetdocuments/{id}/userfile';

  /**
   * get the user file .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFileId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFileId$Plain$Response(params: GetUserFileId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getUserFileId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get the user file .
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserFileId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFileId$Plain(params: GetUserFileId$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getUserFileId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * get the user file .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFileId$Response(params: GetUserFileId$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getUserFileId(this.http, this.rootUrl, params, context);
  }

  /**
   * get the user file .
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserFileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFileId(params: GetUserFileId$Params, context?: HttpContext): Observable<string> {
    return this.getUserFileId$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `fleetDocumentsControllerSavefleetDocumentViewModel()` */
  static readonly FleetDocumentsControllerSavefleetDocumentViewModelPath = '/fleetdocuments/save';

  /**
   * save the fleet documnets.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * save the fleet documnets.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<void> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
