import { from } from 'rxjs';
import { Injectable } from '@angular/core';
import { addMinutes, differenceInMilliseconds, differenceInSeconds, format, formatISO, isValid, parseISO } from 'date-fns';
import { BroadcastService } from '../broadcast-service/broadcast.service';
import { UserDateFormat } from 'root/mibp-openapi-gen/models';

export type AllowedDateTypes = Date | string | number;

@Injectable({
  providedIn: 'root'
})
export class FormattingService {

  private showLocalTime = false;

  private shortYearResourceStringValue= 'YYYY';
  private shortMonthResourceStringValue= 'MM';
  private shortDateResourceStringValue= 'DD';
  private timezoneStringValue = 'Timezone';
  private userDateFormat: UserDateFormat = UserDateFormat.BrowserDefault;
  private datePattern: string;

  private locale: string;

  constructor(broadcastService: BroadcastService) {

    broadcastService.mibpSession.subscribe(sessionVm => {

      if (sessionVm) {
        if (sessionVm.user?.preferredDateFormat) {
          this.userDateFormat  = sessionVm.user?.preferredDateFormat;
        } else {
          this.userDateFormat  = UserDateFormat.BrowserDefault;
        }
        this.datePattern = this.mapDateFormatToDatePattern(this.userDateFormat);
      } else {
        this.datePattern = this.mapDateFormatToDatePattern(UserDateFormat.BrowserDefault);
      }
    });

    broadcastService.dateFormatResourceStrings.subscribe(e => {
      if (e) {
        this.shortYearResourceStringValue = e.shortYearPlaceholder;
        this.shortMonthResourceStringValue = e.shortMonthPlaceholder;
        this.shortDateResourceStringValue = e.shortDatePlaceholder;
        this.timezoneStringValue = e.timezone;
      }
    });

    this.locale = Intl.DateTimeFormat(this.locale).resolvedOptions().locale;

  }

  public setLocale(newLocale: string): void {
    this.locale = newLocale;
  }

  mapDateFormatToDatePattern(dateFormat: UserDateFormat): string {

    switch(dateFormat) {
    case UserDateFormat.YYMMDDDash:
      return 'yyyy-MM-dd';
    case UserDateFormat.YYMMDDSlash:
      return 'yyyy/MM/dd';
    case UserDateFormat.MMDDYYSlash:
      return 'MM/dd/yyyy';
    case UserDateFormat.MMDDYYDash:
      return 'MM-dd-yyyy';
    case UserDateFormat.DDMMYYSlash:
      return 'dd/MM/yyyy';
    case UserDateFormat.DDMMYYDash:
      return 'dd-MM-yyyy';
    default:
      return null;
    }
  }

  formatDate(date: string | Date, pattern?: UserDateFormat, showLocalTime?: boolean): string {
    const resultDate = this.toDate(date);

    if (!resultDate) {
      return null;
    }
    const datePattern = this.mapDateFormatToDatePattern(pattern) || this.datePattern;
    if (!datePattern && isValid(resultDate)) {
      return Intl.DateTimeFormat(this.locale).format(resultDate);
    }

    const minuteDiff = showLocalTime || this.showLocalTime ? 0 : resultDate.getTimezoneOffset();
    return format(addMinutes(resultDate, minuteDiff), datePattern);
  }

  toDate(value: AllowedDateTypes): Date {

    if (!value) {
      return null;
    }

    let resultDate: Date;

    if (typeof value === 'string') {
      if (value === 'now') {
        resultDate = new Date();
      } else {
        resultDate = parseISO(value);
        if (isNaN(resultDate.getTime())) {
          resultDate = null;
        }
      }
    } else if (typeof value === 'number') {
      resultDate = new Date(value);
    } else {
      resultDate = value as Date;
    }

    return resultDate;

  }


  formatDateTime(date: AllowedDateTypes, pattern?: UserDateFormat, useDetailedTime?: boolean): string {

    const resultDate = this.toDate(date);

    if (resultDate == null || typeof resultDate ===  'undefined') {
      return null;
    }

    const datePattern = this.mapDateFormatToDatePattern(pattern) || this.datePattern;

    if (!datePattern && isValid(resultDate)) {
      return Intl.DateTimeFormat(this.locale).format(resultDate) + ' ' + this.formatTime(resultDate, useDetailedTime);
    }

    const minuteDiff = this.showLocalTime ? 0 : resultDate.getTimezoneOffset();
    return isValid(resultDate)  ? format(addMinutes(resultDate, minuteDiff), datePattern) + ' ' + this.formatTime(resultDate, useDetailedTime) : '';
  }

  getTimezoneOffset(date: Date): string {
    const ts = Intl.DateTimeFormat(this.locale, {timeZoneName: 'short'}).format(date);
    return ts.indexOf(' ') > -1 ? ts.substr(ts.indexOf(' ') + 1) : date.getTimezoneOffset().toString();
  }

  offsetToHHmmss(seconds: number): string {
    const isNegative = seconds > 0;
    return (isNegative ? '-' : '+') + (new Date((seconds * (isNegative ? 1 : -1) ) * 1000).toISOString().substr(11, 8));
  }

  getTooltipString(date: AllowedDateTypes): string {
    const parsedDate = this.toDate(date);
    let tooltip = "";

    if (Intl.DateTimeFormat(this.locale).resolvedOptions().timeZone) {
      tooltip = `${Intl.DateTimeFormat(this.locale).resolvedOptions().timeZone }`;
    } else {
      tooltip = (parsedDate ? this.getTimezoneOffset(parsedDate) : '') + ' ' + this.timezoneStringValue + ' '
        + this.offsetToHHmmss((parsedDate ? parsedDate : new Date()).getTimezoneOffset());
    }



    if (parsedDate) {
      tooltip += ` (${this.toServerUTCString(parsedDate)})`;
    }
    return tooltip;
  }


  formatTime(date: AllowedDateTypes, usedetailedTime?: boolean): string {
    const parsedDate = this.toDate(date);
    if (parsedDate) {
      let options = {hour: 'numeric', minute: 'numeric'} as Intl.DateTimeFormatOptions;
      if (usedetailedTime) {
        options.second = 'numeric';
        options.fractionalSecondDigits = 3;
      }
      return Intl.DateTimeFormat(this.locale, options).format(parsedDate );
    }
  }

  formatDuration(start: Date, finish: Date): string {
    const seconds = differenceInSeconds(finish, start);
    if (seconds < 1) {
      return differenceInMilliseconds(finish, start) + 'ms';
    }
    return this.formatMilliseconds(seconds * 1000);
  }

  public formatMilliseconds(ms: number): string {

    const parsed = this.convertMiliseconds(ms);
    const days = parsed.d > 0 ? `${parsed.d.toString()}.` : '';
    return `${days}${parsed.h.toString().padStart(2, '0')}:${parsed.m.toString().padStart(2, '0')}:${parsed.s.toString().padStart(2, '0')}`;
  }

  translateDatePatternValue(val: string): string {
    val = val.replace('YY', this.shortYearResourceStringValue);
    val = val.replace('MM', this.shortMonthResourceStringValue);
    val = val.replace('dd', this.shortDateResourceStringValue);
    return val;
  }

  private convertMiliseconds(miliseconds: number) {

    const totalSeconds = Math.floor(miliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const days = Math.floor(totalHours / 24);

    const seconds = totalSeconds % 60;
    const minutes = totalMinutes % 60;
    const hours = totalHours % 24;

    return { d: days, h: hours, m: minutes, s: seconds };
  }

  /**
   * Get the date formatting pattern for the current user for use as placeholder in date fields
   * @param useResourceStrings Set to false to use hard coded yyyy for year, MM for month and dd for day instead of translated strings
   * @returns
   */
  getDateFormatPlaceholder(useResourceStrings = true): string {


    if (this.datePattern) {
      return this.translateDatePatternValue(this.datePattern);
    }


    if (Intl.DateTimeFormat(this.locale).formatToParts) {
      const parts = Intl.DateTimeFormat(this.locale).formatToParts();
      let resultString = '';

      parts.forEach(p => {
        if (p.type === 'year') {
          resultString += (useResourceStrings ? this.shortYearResourceStringValue : 'yyyy');
        } else if (p.type === 'month') {
          resultString += (useResourceStrings ? this.shortMonthResourceStringValue : 'MM');
        } else if (p.type === 'day') {
          resultString += (useResourceStrings ? this.shortDateResourceStringValue : 'dd');
        } else if (p.type === 'literal') {
          resultString += p.value;
        }
      });
      return resultString;
    }

  }

  toUtcISOString(date: AllowedDateTypes): string {
    const parsedDate = this.toDate(date);
    if (parsedDate) {
      return formatISO(addMinutes(parsedDate, parsedDate.getTimezoneOffset()));
    }
  }

  toISOString(date: AllowedDateTypes): string {
    const parsedDate = this.toDate(date);
    if (parsedDate) {
      return formatISO(parsedDate);
    }
  }

  toServerUTCString(theDate: Date): string {
    const parsedDate = this.toDate(theDate);
    if (parsedDate) {
      return format(addMinutes(parsedDate, parsedDate.getTimezoneOffset()), `yyyy-MM-dd'T'HH:mm:ss'Z'`);
    }
  }

  toServerUTCStringWithMs(theDate: Date): string {
    const parsedDate = this.toDate(theDate);
    if (parsedDate) {
      return format(addMinutes(parsedDate, parsedDate.getTimezoneOffset()), `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`);
    }
  }

  formatPrice(price: number): string {

    if (price === null || isNaN(price)) {
      return '';
    }

    const decimals = 2;
    price = Math.round(price * Math.pow(10, decimals)) / Math.pow(10, decimals);

    const priceString = price.toFixed(decimals).toString();
    const p = priceString.split('.');

    let newString = "";
    for (let i = p[0].length; i--; i >= 0) {
      newString = `${p[0][i]}${newString}`;
      if ((p[0].length - i) % 3 === 0 && i !== 0) {
        newString = `,${newString}`;
      }
    }
    return newString + '.' + p[1];
  }

  formatWeight(weight: number): string {

    if (weight === null || isNaN(weight)) {
      return '';
    }

    const decimals = 2;
    weight = Math.round(weight * Math.pow(10, decimals)) / Math.pow(10, decimals);

    return weight.toFixed(decimals);
  }

  validateEmailFormat(email: string, domainName : string = null): boolean {
    let atIndex: number;
    let isValid = true;
    let domain: string;
    let local: string;
    let localLen: number;
    let domainLen: number;

    if (email.length > 0) {
      atIndex = email.indexOf('@');
      if (atIndex === -1) {
        isValid = false;
      } else {
        domain = email.substr(atIndex + 1);
        local = email.substr(0, atIndex);
        localLen = local.length;
        domainLen = domain.length;
        isValid = this.validateDomainFormat(domain, domainLen, local, localLen);
        // Comment the following check if you want to allow domain names without dots
        if (!/\.[a-z]{2,63}$/.test(email)) {
          isValid = false;
        }
        if(domainName != null && domain.substring(0, domain.indexOf('.')) != domainName){
          isValid = false;
        }
      }
      // return false to show the default msg
      return isValid ? true : false;
    }
    return false;
  }

  private validateDomainFormat(domain: string, domainLen: number, local: string, localLen: number): boolean {

    if (localLen < 1 || localLen > 64) {
      // local part length exceeded
      return false;
    }
    if (domainLen < 1 || domainLen > 255) {
      // domain part length exceeded
      return false;
    }
    if (local[0] === '.' || local[localLen - 1] === '.' || local[0] === '-' || local[localLen - 1] === '-') {
      // local part starts or ends with '.' or '-'
      return false;
    }
    if (domain[0] === '-' || domain[domainLen - 1] === '-' || domain[0] === '.' || domain[domainLen - 1] === '.') {
      // domain part starts or ends with '-'
      return false;
    }
    if (/\.\./.test(local)) {
      // local part has two consecutive dots
      return false;
    }
    if (!/^[åäöA-Za-z0-9\-\\.]+$/.test(domain)) {
      // character not valid in domain part
      return false;
    }
    if (/\.\./.test(domain)) {
      // domain part has two consecutive dots
      return false;
    }
    if (!/^(\.|[A-Za-z0-9!#%&`_=/$'*+?^{}|~.-])+$/.test(local.replace("\\\\", ""))) {
      // character not valid in local part unless
      // local part is quoted
      if (!/^"(\\\\"|[^"])+"$/.test(local.replace("\\\\", ""))) {
        return false;
      }
    }
    return true;

  }

  /**
   * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
   * NOTE! We use 1024 for MB etc, not 1000 as used in MiB
   */
  formatBytes(bytes: number): string {
    const i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <any>(bytes / (Math.pow(1024, i))).toFixed(2) * 1 + ' ' + sizes[i];
  }

  abbreviateNumber(value: number): string {
    if (!value) {
      return null;
    }
    let newValue = value.toString();
    if (value >= 1000) {
      const suffixes = ["", "k", "m", "b","t"];
      const suffixNum = Math.floor( (""+value).length/3 );
      let shortValue: number | string = 0;
      for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
        const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
        if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
  }

}
