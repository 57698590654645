
import { allPermissionPolicies } from "root/all-permission-policies";
import { MySandvikNavigationItem } from "../navigation-types";

export const NAVITEMS_OPERATIONS: MySandvikNavigationItem =
{
  text: 'Operations',
  sandvikIconName: 'construction',
  route: {path: ['operations']},
  permissionPolicy: allPermissionPolicies.isSupportTeamMember,
  children: [
    {
      text: 'Logs',
      route: {path: ['logs']},
      children: [
        {
          text: 'LogRows',
          route: {path: ['logrows']}
        },
        {
          text: 'UserActions',
          route: {path: ['useractions']}
        }
      ]
    },
    {
      text: 'Search',
      route: {path: ['search']}
    },
    {
      text: 'Rescope',
      route: {path: ['rescope']}
    },
    {
      text: 'Jobs',
      route: {path: ['jobs']}
    },
    {
      text: 'Cache',
      route: {path: ['cache']}
    },
    {
      text: 'Price and Availability',
      route: {path: ['price-and-availability']}
    },
    {
      text: 'Availability And ExpectedDate',
      route: {path: ['availability-and-expecteddate']}
    }
    ,
    {
      text: 'Sales Order API',
      route: {path: ['sales-order-api']}
    },
    {
      text: 'Documoto',
      route: {path: ['documoto']}
    },
    {
      text: 'Post integration messages',
      route: {path: ['post-integration-messages']},
      children: [
        {
          route: {path: [':messageid']},
          text: 'Message'
        }
      ]
    }
  ]
};



