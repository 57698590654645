import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { LocalizationService } from "../localization/localization.service";
import { environment } from "root/environment";
import { ApplicationStateService } from "../application-state/application-state.service";

/**
 * Use from startup guard.
 * Will load resource strings for current language
 * Will load english if no language is set
 */
@Injectable({
  providedIn: 'root'
})
export class StartupLocalizationService {

  constructor(private localizationService: LocalizationService, private appState: ApplicationStateService) {}

  public async testLocalization(): Promise<boolean | UrlTree> {

    return new Promise(resolve => {

      this.localizationService.getResourceStrings().subscribe(() => {
        resolve(true);
      });
    });
  }

}
