import { SafeHtml } from '@angular/platform-browser';
import { Component, Input, AfterViewInit, ElementRef, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';


export interface HtmlViewLinkClick {
  nativeElement: any;
  event: MouseEvent;
}

export interface HtmlViewConfig {
  linkOptions: HtmlViewLinkOptions;
}

export interface HtmlViewLinkOptions {
  openInNewTab: boolean;
  openInNewTabExclude?: string [];
}

@Component({
  selector: 'mibp-html-view',
  templateUrl: './html-view.component.html',
  styleUrls: ['./html-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HtmlViewComponent implements AfterViewInit {

  @Input() html: SafeHtml;
  @Input() config: HtmlViewConfig;
  @ViewChild("htmlContainer", {static: true}) htmlContainer: ElementRef;
  @Output() clickedLink = new EventEmitter<HtmlViewLinkClick>();

  constructor() {}

  ngAfterViewInit() {
    const linkTags = <any[]>this.htmlContainer.nativeElement.getElementsByTagName('a');
    const linkArray = Array.prototype.slice.call( linkTags, 0 );
    linkArray.forEach(link => {
      if (this.config.linkOptions.openInNewTab) {
        if ((this.config.linkOptions.openInNewTabExclude && !this.config.linkOptions.openInNewTabExclude.includes(link.href)) || !this.config.linkOptions.openInNewTabExclude) {
          link.setAttribute('target', '_blank');
        }
      }
      link.addEventListener('click', e => {
        const event = <HtmlViewLinkClick> { nativeElement: link, event: e };
        this.clickedLink.emit(event);
      });
    });
  }

}
