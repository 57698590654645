import { NotificationApiController } from './../../mibp-openapi-gen/services/notification-api-controller';

import { NoticeType } from './../../components/noticebar/noticebar.enum';
import { Params, Router } from '@angular/router';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { ApiService, SignalR_NotificationEvent, SignalR_UserMessageEvent, SignalR_UserMessageEventType } from '../mibp-api-services';
import { LocalizationService } from '../localization/localization.service';
import { FrontendContextService } from '../front-end-context/front-end-context.service';
import { NoticebarService } from '../noticebar-service/noticebar.service';
import { LogService, MibpLogger } from '../logservice';

@Injectable({
  providedIn: 'root'
})

export class NotificationService  {
  private notificationItemSubject = new BehaviorSubject<NotificationItem[]>(new Array(0));
  private notificationCounterSubject = new BehaviorSubject<number>(0);
  private notifications: NotificationItem[] = [];
  private currentNotificationEvent: SignalR_NotificationEvent;
  private log: MibpLogger;

  constructor(private api: ApiService,
              private notificationApi: NotificationApiController,
              private router: Router,
              private context: FrontendContextService,
              private localizationService: LocalizationService,
              private noticeBar: NoticebarService,
              logger: LogService,) {

    this.api.EventHub.UserMessage.subscribe(e => this.onUserMessageEvent(e));
    this.api.EventHub.Notification.subscribe(event => {
      if (event) {
        this.currentNotificationEvent = event;
        this.notificationCounterSubject.next(this.currentNotificationEvent.unreadNotifications || 0);
        this.triggerNotificationNext();
      }
    });
    this.localizationService.Locale$.subscribe(() => {
      this.triggerNotificationNext();
    });
    this.log = logger.withPrefix('notification-service');
  }

  onUserMessageEvent(event: SignalR_UserMessageEvent) {

    if (event) {
      let eventType = NoticeType.Info;
      switch(event.type) {
      case SignalR_UserMessageEventType.Error: eventType = NoticeType.Error; break;
      case SignalR_UserMessageEventType.Success: eventType = NoticeType.Success; break;
      case SignalR_UserMessageEventType.Warning: eventType = NoticeType.Warning; break;
      }
      if (event.resourceStringKey) {
        this.noticeBar.show(event.resourceStringKey, eventType, event.macros);
      } else if (event.textOverride) {
        this.noticeBar.showText(event.textOverride, eventType, false);
      }
    }
  }


  private triggerNotificationNext() {
    if (this.currentNotificationEvent) {
      this.notifications.length = 0;
      this.currentNotificationEvent.notifications?.forEach(n => {
        this.notifications.push(<NotificationItem>{
          titleResourceStringKey: n.resourceTitle.key,
          descriptionResourceStringKey: n.resourceMessage.key,
          args: n.args,
          date: n.createdDate,
          url: this.context.Navigation.ensureUrlLanguage(n.url),
          id: n.id
        });
      });
      this.notificationItemSubject.next(this.notifications);
    }
  }

  insertArgumentsIntoText(text: string, values: string): string {
    if (values) {
      const args = values.split("|");
      let i: number;
      for (i = 0; i < args.length; i++) {
        text = text.replace("{" + i + "}", args[i]);
      }
    }
    return text;
  }

  view(notification: NotificationItem): Promise<void> {
    return new Promise((resolve) => {
      const url = notification.url;
      firstValueFrom(this.notificationApi.markAsRead({id: notification.id.toString()})).then(() => {
        this.router.navigateByUrl(url);
        resolve();
      }).catch(error=> {
        this.log.error("Error marking notification as read", error);
      });
    });
  }

  get notificationItems$(): Observable<NotificationItem[]> {
    return this.notificationItemSubject.asObservable();
  }

  get notificationCounter$(): Observable<number> {
    return this.notificationCounterSubject.asObservable();
  }
}

export interface NotificationItem {
  id: Guid;
  date: any;
  titleResourceStringKey: string;
  descriptionResourceStringKey: string;
  args: string;
  url: string;
  isread: boolean;
  descriptionIsHtml: boolean;
  route?: string[];
  routeQueryParam?: Params;
}
