/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EquipmentIndexDtoSearchResponse } from '../../models/equipment-index-dto-search-response';
import { OperationSiteRefinementOptions } from '../../models/operation-site-refinement-options';

export interface GetEquipmentsForOperationSite$Plain$Params {
  operationSiteId: string;
  Skip?: number;
  Take?: number;
  FetchRecent?: boolean;
      body?: OperationSiteRefinementOptions
}

export function getEquipmentsForOperationSite$Plain(http: HttpClient, rootUrl: string, params: GetEquipmentsForOperationSite$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
  const rb = new RequestBuilder(rootUrl, getEquipmentsForOperationSite$Plain.PATH, 'post');
  if (params) {
    rb.path('operationSiteId', params.operationSiteId, {});
    rb.query('Skip', params.Skip, {});
    rb.query('Take', params.Take, {});
    rb.query('FetchRecent', params.FetchRecent, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EquipmentIndexDtoSearchResponse>;
    })
  );
}

getEquipmentsForOperationSite$Plain.PATH = '/sites/{operationSiteId}/equipments';
