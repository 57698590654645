import { MySandvikNavigationItem } from "../navigation-types";

export const NAVITEMS_MYACCOUNT: MySandvikNavigationItem = {
  resourceKey: 'MyAccount_Title',
  sandvikIconName: 'person',
  permissionPolicy: { shouldBeActive: true },
  route: {
    path: ['user'],
    exactMatch: true
  },
  children: [
    {
      resourceKey: 'Users_Notifications_Tab_Title',
      route: {
        path: ['activity']
      }
    },
    {
      resourceKey: 'Users_MyProfile_Tab_Title',
      route: {
        path: ['myprofile']
      }
    },
    {
      resourceKey: 'Users_EmailNotifications_Tab_Title',
      route: {
        path: ['notificationSettings']
      }
    },
    {
      resourceKey: 'Global_ResetPassword',
      uniqueId: 'reset-password',
      route: {

        path: ['reset-password'],

      }
      ,hideBreadCrumb: true
    },

  ]
};
