import { LogService } from '../logservice/index';

import { Injectable } from "@angular/core";
import { UrlTree, Router } from "@angular/router";
import { ActivationArgs } from "root/guards";
import { FrontendContextService } from "../front-end-context/front-end-context.service";
import { StartupService } from "./startup.service";
import { PermissionService } from "../permission/permission.service";
import { NavigationService } from "../navigation/navigation.service";
import { MibpLogger } from "../logservice";
import { MibpSessionService } from '../mibp-session/mibp-session.service';
import { BroadcastService } from '..';

/**
 * Use from startup guard.
 * Will check so the user have the appropriate permissions for the page
 */
@Injectable({
  providedIn: 'root'
})
export class StartupUserPermissionsService {

  private log: MibpLogger;

  constructor(
    private frontendContext: FrontendContextService,
    private appStartService: StartupService,
    private navigationService: NavigationService,
    private permissionService: PermissionService,
    private sessionService: MibpSessionService,
    private broadcast: BroadcastService,
    logService: LogService,
    private router: Router) {
    this.log = logService.withPrefix('route-permissions');
  }

  public async testUserPermissions(args: ActivationArgs): Promise<boolean | UrlTree> {
    if (!args.routeConfig.permissionPolicy && !args.routeConfig.navItemId) {
      return Promise.resolve(true);
    }

    return new Promise(resolve => {

      let hasPermissions = true;

      let permissionPolicy = args.routeConfig.permissionPolicy;

      if (args.routeConfig.navItemId) {
        const navitem = this.navigationService.getById(args.routeConfig.navItemId);
        if (navitem) {
          permissionPolicy = navitem.permissionPolicy;
        }
      }


      if (permissionPolicy) {
        hasPermissions = this.permissionService.test(permissionPolicy);
      }
      //  else if (args.routeConfig.navItemId) {
      //   console.warn("AHA! USE NAVIGATION PERMISSION SETTINGS");
      // }

      if (!hasPermissions) {
        this.appStartService.hideNativeMessage(true);

        // TODO when fixed. Stay on same route but show unauthorized page
        // Does not currently work properly. Would be nicer to stay on the same route
        // this.router.navigate(['/en/unauthorized'], {skipLocationChange: true}).then(() => {
        // //  this.location.replaceState(args.state.url); Bad workaround.
        // });

        let policy = "";
        try {
          policy = `&p=${btoa(JSON.stringify(permissionPolicy))}`;
        } catch(e) {
          // This can be empty, it's ok
        }

        const url = this.frontendContext.Navigation.ensureUrlLanguage('/unauthorized?url=' + encodeURIComponent(args.state.url) + policy);

        resolve(this.router.parseUrl(url));
      } else {
        resolve(true);
      }
    });
  }

  public async testRouteForAnyPermissionRequirements(args: ActivationArgs): Promise<boolean | UrlTree> {

    // console.warn("LOGGED IN?", this.frontendContext.isLoggedIn(), args.routeConfig);

    // No permissions on route. Proceed with startup chain
    if (args.routeConfig.allowAnonymous === true) {
      // console.warn("Anonymoys ok");
      return true;
    }

    // Any permissions required, but user is lot logged in. redirect to login and block more processing
    if (!this.sessionService.isLoggedIn()) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise(() => {
        //   console.warn("BLOCK further navigation and redirect to login", args.routeConfig);
      });
    }

    //  console.warn("PERMISSIONS REQUIRED BUT WE ARE LOGGED IN TO B2C. PROCEED", args.routeConfig);
    return true;

  }


}
