import { Component, Input, } from "@angular/core";
import { SandvikIconSize } from "root/components/sandvik-icon/sandvik-icon.component";
import { SystemMessageService } from 'root/components/system-message-dialog/system-message.service';

@Component({
  selector: 'mibp-header-system-message',
  templateUrl: './header-system-message.component.html'
})
export class MibpHeaderSystemMessageComponent {
  @Input() iconsize :SandvikIconSize = 'large';
  constructor(protected systemMessageService: SystemMessageService) {}
}
