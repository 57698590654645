import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot, UrlTree } from "@angular/router";
import { NavigationService } from "root/services";

export const MibpNewUXRedirectCanActivateFn : CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<boolean | UrlTree> => {
  return inject(NavigationService).tryRedirectOldRoute(route, state);
};

export const MibpNewUXRedirectCanActivateChildFn : CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<boolean | UrlTree> => {
  return inject(NavigationService).tryRedirectOldRoute(route, state);
};
