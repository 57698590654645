import { WarrantyCardViewModel, WarrantyClaimAttachmentViewModel } from 'root/mibp-openapi-gen/models';
import { SignalR_ApiResponseGeneric } from './../mibp-api-services/_mibp-api-generated.service.types';
import { MibpHttpApi } from './mibp-http-api.service';

/**
 * Provides an API And for fetching large resource strings that are too big for SignalR
 */
export class WarrantyHttpService {

  constructor(private httpApi: MibpHttpApi) {}

  public async AddAttachment(claimId: string, attachments: WarrantyClaimAttachmentViewModel[]): Promise<unknown> {
    return await this.httpApi.post(`/warranty/claims/${encodeURIComponent(claimId)}/attachments`, attachments).toPromise();
  }

  public async GetAttachmentData(claimId: string, attachmentId: string): Promise<string> {
    return await this.httpApi.get<string>(`/warranty/claims/${encodeURIComponent(claimId)}/attachments/${attachmentId}/base64`).toPromise();
  }

  public async GetWarrantyRegistrations(mdgEquipmentId: WarrantyCardViewModel[]): Promise<WarrantyCardViewModel[]> {
    return await this.httpApi.get<WarrantyCardViewModel[]>(`/warranty/registrations?equipmentIds=${mdgEquipmentId}`).toPromise();
  }

  public async PrepareDeliverySequenceSync(deliverySequenceId: number): Promise<SignalR_ApiResponseGeneric<PrepareSyncJobResponseViewModel>> {
    return await this.httpApi.get<SignalR_ApiResponseGeneric<PrepareSyncJobResponseViewModel>>(`/warranty/sync/prepare/deliverysequence/${encodeURIComponent(deliverySequenceId)}`).toPromise();
  }

  public async StartDeliverySequenceSync(mdgDeliveryPointid: string): Promise<SignalR_ApiResponseGeneric<string>> {
    return await this.httpApi.post<SignalR_ApiResponseGeneric<string>>(`/warranty/jobs/deliverysequence/${encodeURIComponent(mdgDeliveryPointid)}`, null).toPromise();
  }

  public async StartBusinessRelationSync(id: number): Promise<SignalR_ApiResponseGeneric<string>> {
    return await this.httpApi.post<SignalR_ApiResponseGeneric<string>>(`/warranty/jobs/businessrelation/${encodeURIComponent(id)}`, null).toPromise();
  }

  public async StartCompanySync(companyCode: string): Promise<SignalR_ApiResponseGeneric<string>> {
    return await this.httpApi.post<SignalR_ApiResponseGeneric<string>>(`/warranty/jobs/company/${encodeURIComponent(companyCode)}`, null).toPromise();
  }

  public async GetJobStatus(jobId: string): Promise<SignalR_ApiResponseGeneric<WarrantyClaimJobLog>> {
    return await this.httpApi.get<SignalR_ApiResponseGeneric<WarrantyClaimJobLog>>(`/warranty/jobs/${encodeURIComponent(jobId)}`).toPromise();
  }

  public async ReScope(data: RescopeWarrantyClaimViewModel): Promise<SignalR_ApiResponseGeneric<WarrantyClaimJobLog>> {
    return await this.httpApi.post<SignalR_ApiResponseGeneric<WarrantyClaimJobLog>>(`/warranty/rescope`, data).toPromise();
  }

}

export interface WarrantyClaimJobLog {
  completedAt?: Date;
  hasErrors: boolean;
  logs: string[];
}

export interface PrepareSyncJobResponseViewModel {
  companyCode: string;
  businessRelationId: number;
  mdgDeliveryPointId: string;
}

export interface RescopeWarrantyClaimViewModel {
  companyCode?: string;
  customerNumber?: string;
  deliverySequenceNumber?: string;
  mdgDeliveryPointId?: string;
  claimId?: string;
}
