import { FormattingService, NoticebarService } from 'root/services';
import { Pipe, PipeTransform } from '@angular/core';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';

@Pipe({name: 'formatDateTime'})
export class FormatDateTimePipe implements PipeTransform {

  constructor(private formattingService: FormattingService) {}

  transform(value: any, detailedTime = false): string {
    return this.formattingService.formatDateTime(value, undefined, detailedTime);
  }

}
