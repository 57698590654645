import { FormattingService } from 'root/services';
import { ValidatorFn, AbstractControl } from "@angular/forms";

export function MibpEmailValidator(formattingService: FormattingService): ValidatorFn {

  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value;

    if (value && typeof value === 'string') {
      const invalid = !formattingService.validateEmailFormat(value.trim());
      if (invalid) {
        return {
          email: true
        };
      }
    }
  };


}

