/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetJobsForOperationsQueryRequestVm } from '../../models/get-jobs-for-operations-query-request-vm';
import { GetJobsForOperationsResponseVm } from '../../models/get-jobs-for-operations-response-vm';

export interface ListJobs$Plain$Params {
      body?: GetJobsForOperationsQueryRequestVm
}

export function listJobs$Plain(http: HttpClient, rootUrl: string, params?: ListJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetJobsForOperationsResponseVm>> {
  const rb = new RequestBuilder(rootUrl, listJobs$Plain.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetJobsForOperationsResponseVm>;
    })
  );
}

listJobs$Plain.PATH = '/operations/jobs';
