import { SignalR_RoleEcommercePermission } from './services/mibp-api-services';
import { ApplicationRootPageComponent, ErrorUnauthorizedPageComponent } from './pages';
import { Routes } from '@angular/router';
import { Error404Component } from 'root/components';
import { PermissionPolicy } from 'root/services';
import { VariationRootPageComponent } from './pages';
import { StartupCanActivateChildFn, StartupCanActivateFn } from './guards';
import { MibpNewUXRedirectCanActivateFn } from './guards/new-ux-redirect/new-ux-redirect.guard';
import { allPermissionPolicies } from './all-permission-policies';
import { Component } from '@angular/core';

@Component({template: 'https://dev.azure.com/sandvik/MIBP/_workitems/edit/297854'})
export class Bug297854DummyComponent {}


export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [StartupCanActivateFn],
    canActivateChild: [StartupCanActivateChildFn],
    data: {
      isRoot: true,
      mibpGuard: { allowAnonymous: true, postponeSignalrConnection: true }
    },
    children: [
      {
        path: '',
        component: VariationRootPageComponent,
        data: {
          mibpGuard: {
            skipGuardForRoute: true,
            allowAnonymous: true
          }
        },
      },
      {
        path: ':lang',
        component: ApplicationRootPageComponent,
        data: {
          setLang: true,
          mibpGuard: {
            postponeSignalrConnection: true,
            allowAnonymous: true
          }
        },
        children:  [
          {
            path: 'unauthorized',
            component: ErrorUnauthorizedPageComponent,
            data: {
              mibpGuard: { allowAnonymous: true, postponeSignalrConnection: false }
            }
          },
          {
            path: 'user',
            children: [
              {
                path: '',
                loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
              }
            ]
          },
          {
            path: '',
            loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
            data: {
              mibpGuard: {
                allowAnonymous: false
              }
            }
          },
          {
            path: 'home',
            redirectTo: ''
          },
          {
            path: 'AuthIEPunchout'
          },

          // START:  Can not remove this or we get weird error in prod build
          // https://dev.azure.com/sandvik/MIBP/_workitems/edit/297854
          {
            path: 'electronic-manuals',
            canActivate: [MibpNewUXRedirectCanActivateFn],
            component: Bug297854DummyComponent,
            // When the data section is not here, then the error occurs
            data: {
              mibpGuard: {
                permissionPolicy: <PermissionPolicy>{ roleEcommercePermissions: [SignalR_RoleEcommercePermission.ECatalogue] }
              }
            },
          },
          // END:  Can not remove this or we get weird error in prod build


          {
            path: 'settings',
            children: [
              {
                path: '',
                loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
                data: {
                  mibpGuard: {
                    allowAnonymous: false
                  }
                }
              }
            ]
          },
          {
            path: 'operations',
            children: [
              {
                path: '',
                loadChildren: () => import('./modules/operations/operations.module').then(m => m.OperationsModule),
                data: {
                  mibpGuard: {
                    permissionPolicy: allPermissionPolicies.isSupportTeamMember
                  }
                }
              }
            ]
          },
          {
            path: 'warranty-portal',
            children: [
              {
                path: '',
                loadChildren: () => import('./modules/warranty-portal/warranty-portal.module').then(m => m.WarrantyPortalModule),
                data: {
                  mibpGuard: {
                    allowAnonymous: true
                  },
                  navItemId: 'warranty-portal'
                }
              }
            ]
          },
          {
            path: '**',
            component: Error404Component,
            data: {
              error: { code: 404, path: ':lang/**' },
              mibpGuard: {
                skipGuardForRoute: true,
                allowAnonymous: true
              }
            }
          }
        ]
      }
    ]
  }
];
