import { Injectable } from "@angular/core";
import { OperationSitesApiController } from "root/mibp-openapi-gen/controllers";
import { firstValueFrom } from "rxjs";
import { BroadcastService } from "../broadcast-service/broadcast.service";
import { PermissionService } from 'root/services';
import { allPermissionPolicies } from './../../all-permission-policies';

@Injectable({
  providedIn: 'root'
})
export class OperationSiteService {

  private hasOperationSites: boolean | null = null;

  constructor(private broadcast: BroadcastService,
              private operationSitesApiController: OperationSitesApiController,
              private permissionService: PermissionService) {}

  async hasAnySites(): Promise<boolean> {

    if (!this.broadcast.snapshot.mibpSession) {
      return false;
    }

    if(this.hasOperationSites !== null) {
      return this.hasOperationSites;
    }

    if (!this.permissionService.test(allPermissionPolicies.myfleet)) {
      this.hasOperationSites = false;
      return this.hasOperationSites;
    }

    this.hasOperationSites = await firstValueFrom(this.operationSitesApiController.hasAnyOperationSites());
    return this.hasOperationSites;
  }


}
