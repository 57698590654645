import { environment } from "root/environment";
import { MySandvikNavigationItem } from "../navigation-types";
import { allPermissionPolicies } from 'root/all-permission-policies';




export const NAVITEMS_SMARTMATE: MySandvikNavigationItem = {
  resourceKey: 'SmartMate_Title',
  sandvikIconName: 'monitoring',
  uniqueId: 'smartmate',
  permissionPolicy: allPermissionPolicies.viewSmartMate,
  externalUrl: null
};


