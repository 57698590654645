import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { BillOfMaterialApiController } from "root/mibp-openapi-gen/controllers";
import { BroadcastService, MibpHttpApi, ScrollToService } from 'root/services';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { PartsManualIllustrationImage } from "../parts-manual.types";
import { Subscription } from 'rxjs/internal/Subscription';
import { BomAttachmentVm } from './../../../mibp-openapi-gen/models/bom-attachment-vm';
import { Hotpoint, MediaFolderFileVersion } from 'root/mibp-openapi-gen/models';
import { ActivatedRoute, Router } from "@angular/router";


export interface PartManualsIllustration {
  name: string;
  images: BomAttachmentVm[];
  hotPoints?: HotPointExtended[];
}

export interface HotPointExtended extends Hotpoint{
  isSelected: boolean;
}

@Component({
  selector: 'mibp-parts-manual-illustration',
  styleUrls: ['./parts-manual-illustration.component.scss'],
  templateUrl: './parts-manual-illustration.component.html'
})
export class MibpPartsManualIllustrationComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {

  @Input() image?: PartsManualIllustrationImage;
  @Input() imageIsMissing = false;
  @ViewChild('imageContainer') imageContainer: ElementRef<HTMLDivElement>;
  @ViewChild('canvasHotpoints') canvasRef: ElementRef<HTMLCanvasElement>;
  zoom=0;
  currentAttachemnt: BomAttachmentVm;
  private ctx: CanvasRenderingContext2D;
  canvasHeight: number;
  canvasWidth: number;
  showImage = false;
  zoomValue: number = null;
  readonly zoomStep = 100;

  isLoadingImage = false;
  screenSizeSubscription?: Subscription;

  @Input() illustrations: PartManualsIllustration[];
  currentIllustrationIndex = 0;


  /**
   * When zooming we load the original image.
   * Then this is set to true
   */
  isOriginalLoaded = false;
  heightFactor: number;

  constructor(private bomApi: BillOfMaterialApiController,
    private httpApi: MibpHttpApi,
    private broadcast: BroadcastService,
    private sanitizier: DomSanitizer,
    private route: ActivatedRoute,
    private scrollToService: ScrollToService,
    private router: Router) {}
  ngOnInit(): void {
    this.screenSizeSubscription = this.broadcast.screenSize.subscribe(x => this.calculateImageContainerMaxWidth());
  }

  ngOnDestroy(): void {
    this.screenSizeSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.illustrations && changes.illustrations.currentValue && !changes.illustrations.firstChange) {

      if (this.illustrations?.length > 0) {
        this.loadImage(0);
        this.calculateImageContainerMaxWidth();
      }
    }

    if (this.route.snapshot.queryParams.node) {
      const initiallySelectedHotpointItem = this.route.snapshot.queryParams.node;
      this.illustrations?.filter(f => f.hotPoints?.filter(f => f.item == initiallySelectedHotpointItem).forEach(h => h.isSelected = true));
    }

    if (!this.illustrations) {
      this.showImage = false;
    }
  }
  ngAfterViewInit() {

    this.loadImage(0);
    this.calculateImageContainerMaxWidth();


  }

  nextImage(): void {

    if (this.currentIllustrationIndex < this.illustrations.length - 1) {
      this.clearAllSelectedNodes();
      this.router.navigate([], {queryParams: {node: null}, queryParamsHandling: 'merge',  });
      this.loadImage(this.currentIllustrationIndex + 1);
    }
  }

  prevImage(): void {
    if (this.currentIllustrationIndex > 0) {
      this.clearAllSelectedNodes();
      this.router.navigate([], {queryParams: {node: null}, queryParamsHandling: 'merge',  });
      this.loadImage(this.currentIllustrationIndex - 1);
    }
  }

  private clearAllSelectedNodes(): void {
    this.illustrations.forEach(ill => ill.hotPoints?.forEach(hp => hp.isSelected = false));
  }

  private calculateImageContainerMaxWidth(): void {
    if (this.imageContainer) {
      const rect = this.imageContainer.nativeElement.getBoundingClientRect();
      const maxWidth = window.innerWidth - rect.left - 104;
      this.imageContainer.nativeElement.style.maxWidth = `${maxWidth}px`;
    }
  }

  onImageLoad(): void {
    this.isLoadingImage = false;
    if (!this.isOriginalLoaded) {
      setTimeout(() => {
        this.calculateImageContainerMaxWidth();
        this.showImage = true;
      }, 150);
    }
  }

  zoomIn(): void {
    this.loadOriginalImage();
    this.zoom += this.zoomStep;
    this.drawCircle(null);
  }

  /**
   * When zooming - we want to load the original sized image so the quality is good
   */
  loadOriginalImage(): void {
    if (!this.isOriginalLoaded) {
      this.isOriginalLoaded = true;
      this.loadImage(this.currentIllustrationIndex, MediaFolderFileVersion.OriginalFile);
    }
  }

  zoomOut(): void {
    this.loadOriginalImage();
    this.zoom -= this.zoomStep;
    this.drawCircle(null);
  }

  loadImage(index: number, version = MediaFolderFileVersion.WebImage): void {

    this.currentIllustrationIndex = index;
    this.isLoadingImage = true;
    this.zoomValue = null;
    this.showImage = false;
    this.isOriginalLoaded = false;
    this.currentAttachemnt = this.illustrations[index].images.find(i => i.version == version);


    const originalAttachment = this.illustrations[index].images.find(i => i.version == MediaFolderFileVersion.OriginalFile);
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const baseImage = new Image();
    baseImage.src = this.httpApi.resolveUriWithJwtToken(`/billofmaterial/images/${this.currentAttachemnt.id}/raw`);
    baseImage.onload = () => {
      this.ctx.imageSmoothingQuality = "high";
      canvas.height = this.imageContainer?.nativeElement.getBoundingClientRect().height+this.zoom;
      canvas.width = (canvas.height * originalAttachment.width)/originalAttachment.height;
      this.canvasHeight = canvas.height;
      this.canvasWidth = canvas.width;
      this.heightFactor = (this.canvasHeight/originalAttachment.height);
      this.ctx.drawImage(baseImage, 0, 0,canvas.width, canvas.height);
      this.drawCircle(null);
      this.onImageLoad();
    };
    canvas.removeAllListeners('click');
    canvas.removeAllListeners('mousemove');
    canvas.addEventListener('click', this.handleClick.bind(this));
    canvas.addEventListener('mousemove', this.handleMouseMove.bind(this));
  }

  drawCircle(highlightedItem: string | null) {
    this.illustrations[this.currentIllustrationIndex]?.hotPoints?.forEach((circle, index) => {
      this.ctx.beginPath();
      this.ctx.arc(circle.x*this.heightFactor, circle.y*this.heightFactor, circle.radius*this.heightFactor, 0, 2 * Math.PI);
      this.ctx.fillStyle = (highlightedItem === circle.item || circle.isSelected) ? '#1441F5' : '#101010';
      this.ctx.fill();
      this.ctx.strokeStyle = (highlightedItem === circle.item || circle.isSelected) ? '#1441F5' : '#101010';
      this.ctx.stroke();
      this.ctx.fillStyle = '#FFFFFF';
      this.ctx.font = `${circle.radius*this.heightFactor}px Arial`;
      this.ctx.textAlign = 'center'; // Center text horizontally
      this.ctx.textBaseline = 'middle'; // Center text vertically
      this.ctx.fillText(circle.item.toString(), circle.x*this.heightFactor, circle.y*this.heightFactor);
      this.ctx.beginPath();
    });
  }

  private handleClick(event: MouseEvent) {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    this.scrollToService.stopNextScrollToTop();

    // Find the item for the clicked item
    const selectedHotpointItemNumber = this.illustrations[this.currentIllustrationIndex]?.hotPoints?.find(circle =>
      (Math.pow(x - circle.x*this.heightFactor, 2) + Math.pow(y - circle.y*this.heightFactor, 2) <=
        Math.pow(circle.radius*this.heightFactor, 2)))?.item;

    const isCurrentlySelected = this.illustrations[this.currentIllustrationIndex]?.hotPoints?.filter(circle => circle.item == selectedHotpointItemNumber && circle.isSelected).length > 0;


    this.illustrations[this.currentIllustrationIndex].hotPoints.filter(hp => hp.item != selectedHotpointItemNumber).forEach(hp => hp.isSelected = false);
    this.illustrations[this.currentIllustrationIndex].hotPoints.filter(hp => hp.item == selectedHotpointItemNumber).forEach(hp => hp.isSelected = true);

    if (!isCurrentlySelected) {
      this.drawCircle(selectedHotpointItemNumber);
      this.router.navigate([], {queryParams: {node: selectedHotpointItemNumber}, queryParamsHandling: 'merge'});
    } else {
      this.router.navigate([], {queryParams: {node: null}, queryParamsHandling: 'merge'});
      this.drawCircle(null);
    }
  }

  private handleMouseMove(event: MouseEvent) {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    let hoverIndex = -1;

    let activeCircle: HotPointExtended;

    this.illustrations[this.currentIllustrationIndex]?.hotPoints?.forEach((circle, index) => {
      if (Math.pow(x - circle.x*this.heightFactor, 2) + Math.pow(y - circle.y*this.heightFactor, 2) <=
      Math.pow(circle.radius*this.heightFactor, 2)) {
        hoverIndex = index;
        activeCircle = circle;
      }
    });

    const canvas = this.canvasRef.nativeElement;
    if (hoverIndex >= 0) {
      canvas.style.cursor = 'pointer';
    } else {
      canvas.style.cursor = 'default';
    }
    this.drawCircle(activeCircle?.item);
  }

}
