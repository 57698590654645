/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addAttachments } from '../fn/warranty/add-attachments';
import { AddAttachments$Params } from '../fn/warranty/add-attachments';
import { BinaryData } from '../models/binary-data';
import { createThumbnailForImage } from '../fn/warranty/create-thumbnail-for-image';
import { CreateThumbnailForImage$Params } from '../fn/warranty/create-thumbnail-for-image';
import { createThumbnailForImage$Plain } from '../fn/warranty/create-thumbnail-for-image-plain';
import { CreateThumbnailForImage$Plain$Params } from '../fn/warranty/create-thumbnail-for-image-plain';
import { createThumbnailForPDF } from '../fn/warranty/create-thumbnail-for-pdf';
import { CreateThumbnailForPDF$Params } from '../fn/warranty/create-thumbnail-for-pdf';
import { createThumbnailForPDF$Plain } from '../fn/warranty/create-thumbnail-for-pdf-plain';
import { CreateThumbnailForPDF$Plain$Params } from '../fn/warranty/create-thumbnail-for-pdf-plain';
import { deleteDraftWarrantyClaim } from '../fn/warranty/delete-draft-warranty-claim';
import { DeleteDraftWarrantyClaim$Params } from '../fn/warranty/delete-draft-warranty-claim';
import { deleteDraftWarrantyClaim$Plain } from '../fn/warranty/delete-draft-warranty-claim-plain';
import { DeleteDraftWarrantyClaim$Plain$Params } from '../fn/warranty/delete-draft-warranty-claim-plain';
import { deleteWarrantiesClaimAttachment } from '../fn/warranty/delete-warranties-claim-attachment';
import { DeleteWarrantiesClaimAttachment$Params } from '../fn/warranty/delete-warranties-claim-attachment';
import { deleteWarrantiesUnAvailable } from '../fn/warranty/delete-warranties-un-available';
import { DeleteWarrantiesUnAvailable$Params } from '../fn/warranty/delete-warranties-un-available';
import { deleteWarrantiesUnAvailable$Plain } from '../fn/warranty/delete-warranties-un-available-plain';
import { DeleteWarrantiesUnAvailable$Plain$Params } from '../fn/warranty/delete-warranties-un-available-plain';
import { DeliveryPointInfoViewModel } from '../models/delivery-point-info-view-model';
import { downloadAttachments } from '../fn/warranty/download-attachments';
import { DownloadAttachments$Params } from '../fn/warranty/download-attachments';
import { downloadAttachments$Plain } from '../fn/warranty/download-attachments-plain';
import { DownloadAttachments$Plain$Params } from '../fn/warranty/download-attachments-plain';
import { getAttachment } from '../fn/warranty/get-attachment';
import { GetAttachment$Params } from '../fn/warranty/get-attachment';
import { getAttachment$Plain } from '../fn/warranty/get-attachment-plain';
import { GetAttachment$Plain$Params } from '../fn/warranty/get-attachment-plain';
import { getAttachmentContent } from '../fn/warranty/get-attachment-content';
import { GetAttachmentContent$Params } from '../fn/warranty/get-attachment-content';
import { getAttachmentContent$Plain } from '../fn/warranty/get-attachment-content-plain';
import { GetAttachmentContent$Plain$Params } from '../fn/warranty/get-attachment-content-plain';
import { getImportJobLog } from '../fn/warranty/get-import-job-log';
import { GetImportJobLog$Params } from '../fn/warranty/get-import-job-log';
import { getImportJobLog$Plain } from '../fn/warranty/get-import-job-log-plain';
import { GetImportJobLog$Plain$Params } from '../fn/warranty/get-import-job-log-plain';
import { getMandatoryClaimInfoRequirementsPdf } from '../fn/warranty/get-mandatory-claim-info-requirements-pdf';
import { GetMandatoryClaimInfoRequirementsPdf$Params } from '../fn/warranty/get-mandatory-claim-info-requirements-pdf';
import { getThumbnailImage } from '../fn/warranty/get-thumbnail-image';
import { GetThumbnailImage$Params } from '../fn/warranty/get-thumbnail-image';
import { getThumbnailImage$Plain } from '../fn/warranty/get-thumbnail-image-plain';
import { GetThumbnailImage$Plain$Params } from '../fn/warranty/get-thumbnail-image-plain';
import { getWarrantiesByEquipmentId } from '../fn/warranty/get-warranties-by-equipment-id';
import { GetWarrantiesByEquipmentId$Params } from '../fn/warranty/get-warranties-by-equipment-id';
import { getWarrantiesByEquipmentId$Plain } from '../fn/warranty/get-warranties-by-equipment-id-plain';
import { GetWarrantiesByEquipmentId$Plain$Params } from '../fn/warranty/get-warranties-by-equipment-id-plain';
import { getWarrantiesClaim } from '../fn/warranty/get-warranties-claim';
import { GetWarrantiesClaim$Params } from '../fn/warranty/get-warranties-claim';
import { getWarrantiesClaim$Plain } from '../fn/warranty/get-warranties-claim-plain';
import { GetWarrantiesClaim$Plain$Params } from '../fn/warranty/get-warranties-claim-plain';
import { getWarrantiesClaimAttachments } from '../fn/warranty/get-warranties-claim-attachments';
import { GetWarrantiesClaimAttachments$Params } from '../fn/warranty/get-warranties-claim-attachments';
import { getWarrantiesClaimAttachments$Plain } from '../fn/warranty/get-warranties-claim-attachments-plain';
import { GetWarrantiesClaimAttachments$Plain$Params } from '../fn/warranty/get-warranties-claim-attachments-plain';
import { getWarrantiesClaimDeliverySequence } from '../fn/warranty/get-warranties-claim-delivery-sequence';
import { GetWarrantiesClaimDeliverySequence$Params } from '../fn/warranty/get-warranties-claim-delivery-sequence';
import { getWarrantiesClaimDeliverySequence$Plain } from '../fn/warranty/get-warranties-claim-delivery-sequence-plain';
import { GetWarrantiesClaimDeliverySequence$Plain$Params } from '../fn/warranty/get-warranties-claim-delivery-sequence-plain';
import { getWarrantiesClaimsListGrid } from '../fn/warranty/get-warranties-claims-list-grid';
import { GetWarrantiesClaimsListGrid$Params } from '../fn/warranty/get-warranties-claims-list-grid';
import { getWarrantiesClaimsListGrid$Plain } from '../fn/warranty/get-warranties-claims-list-grid-plain';
import { GetWarrantiesClaimsListGrid$Plain$Params } from '../fn/warranty/get-warranties-claims-list-grid-plain';
import { getWarrantiesWarrantyCards } from '../fn/warranty/get-warranties-warranty-cards';
import { GetWarrantiesWarrantyCards$Params } from '../fn/warranty/get-warranties-warranty-cards';
import { getWarrantiesWarrantyCards$Plain } from '../fn/warranty/get-warranties-warranty-cards-plain';
import { GetWarrantiesWarrantyCards$Plain$Params } from '../fn/warranty/get-warranties-warranty-cards-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { prepareSyncJob } from '../fn/warranty/prepare-sync-job';
import { PrepareSyncJob$Params } from '../fn/warranty/prepare-sync-job';
import { prepareSyncJob$Plain } from '../fn/warranty/prepare-sync-job-plain';
import { PrepareSyncJob$Plain$Params } from '../fn/warranty/prepare-sync-job-plain';
import { PrepareSyncJobResponseViewModel } from '../models/prepare-sync-job-response-view-model';
import { rescopeClaims } from '../fn/warranty/rescope-claims';
import { RescopeClaims$Params } from '../fn/warranty/rescope-claims';
import { rescopeClaims$Plain } from '../fn/warranty/rescope-claims-plain';
import { RescopeClaims$Plain$Params } from '../fn/warranty/rescope-claims-plain';
import { saveWarrantiesClaim } from '../fn/warranty/save-warranties-claim';
import { SaveWarrantiesClaim$Params } from '../fn/warranty/save-warranties-claim';
import { saveWarrantiesClaim$Plain } from '../fn/warranty/save-warranties-claim-plain';
import { SaveWarrantiesClaim$Plain$Params } from '../fn/warranty/save-warranties-claim-plain';
import { saveWarrantiesClaimLineItems } from '../fn/warranty/save-warranties-claim-line-items';
import { SaveWarrantiesClaimLineItems$Params } from '../fn/warranty/save-warranties-claim-line-items';
import { saveWarrantiesClaimLineItems$Plain } from '../fn/warranty/save-warranties-claim-line-items-plain';
import { SaveWarrantiesClaimLineItems$Plain$Params } from '../fn/warranty/save-warranties-claim-line-items-plain';
import { startBusinessRelationJob } from '../fn/warranty/start-business-relation-job';
import { StartBusinessRelationJob$Params } from '../fn/warranty/start-business-relation-job';
import { startBusinessRelationJob$Plain } from '../fn/warranty/start-business-relation-job-plain';
import { StartBusinessRelationJob$Plain$Params } from '../fn/warranty/start-business-relation-job-plain';
import { startCompanyJob } from '../fn/warranty/start-company-job';
import { StartCompanyJob$Params } from '../fn/warranty/start-company-job';
import { startCompanyJob$Plain } from '../fn/warranty/start-company-job-plain';
import { StartCompanyJob$Plain$Params } from '../fn/warranty/start-company-job-plain';
import { startDeliverySequenceJob } from '../fn/warranty/start-delivery-sequence-job';
import { StartDeliverySequenceJob$Params } from '../fn/warranty/start-delivery-sequence-job';
import { startDeliverySequenceJob$Plain } from '../fn/warranty/start-delivery-sequence-job-plain';
import { StartDeliverySequenceJob$Plain$Params } from '../fn/warranty/start-delivery-sequence-job-plain';
import { submitWarrantiesClaim } from '../fn/warranty/submit-warranties-claim';
import { SubmitWarrantiesClaim$Params } from '../fn/warranty/submit-warranties-claim';
import { WarrantyCardViewModel } from '../models/warranty-card-view-model';
import { WarrantyClaimAttachmentViewModel } from '../models/warranty-claim-attachment-view-model';
import { WarrantyClaimJobLog } from '../models/warranty-claim-job-log';
import { WarrantyClaimViewModel } from '../models/warranty-claim-view-model';

@Injectable({ providedIn: 'root' })
export class WarrantyApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `warrantyControllerPrepareSyncJobid()` */
  static readonly WarrantyControllerPrepareSyncJobidPath = '/warranty/sync/prepare/deliverysequence/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prepareSyncJob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareSyncJob$Plain$Response(params: PrepareSyncJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PrepareSyncJobResponseViewModel>> {
    return prepareSyncJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `prepareSyncJob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareSyncJob$Plain(params: PrepareSyncJob$Plain$Params, context?: HttpContext): Observable<PrepareSyncJobResponseViewModel> {
    return this.prepareSyncJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrepareSyncJobResponseViewModel>): PrepareSyncJobResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prepareSyncJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareSyncJob$Response(params: PrepareSyncJob$Params, context?: HttpContext): Observable<StrictHttpResponse<PrepareSyncJobResponseViewModel>> {
    return prepareSyncJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `prepareSyncJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prepareSyncJob(params: PrepareSyncJob$Params, context?: HttpContext): Observable<PrepareSyncJobResponseViewModel> {
    return this.prepareSyncJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrepareSyncJobResponseViewModel>): PrepareSyncJobResponseViewModel => r.body)
    );
  }

  /** Path part for operation `warrantyControllerStartDeliverySequenceJobid()` */
  static readonly WarrantyControllerStartDeliverySequenceJobidPath = '/warranty/jobs/deliverysequence/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startDeliverySequenceJob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  startDeliverySequenceJob$Plain$Response(params: StartDeliverySequenceJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return startDeliverySequenceJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startDeliverySequenceJob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startDeliverySequenceJob$Plain(params: StartDeliverySequenceJob$Plain$Params, context?: HttpContext): Observable<string> {
    return this.startDeliverySequenceJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startDeliverySequenceJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  startDeliverySequenceJob$Response(params: StartDeliverySequenceJob$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return startDeliverySequenceJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startDeliverySequenceJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startDeliverySequenceJob(params: StartDeliverySequenceJob$Params, context?: HttpContext): Observable<string> {
    return this.startDeliverySequenceJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerStartBusinessRelationJobid()` */
  static readonly WarrantyControllerStartBusinessRelationJobidPath = '/warranty/jobs/businessrelation/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startBusinessRelationJob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  startBusinessRelationJob$Plain$Response(params: StartBusinessRelationJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return startBusinessRelationJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startBusinessRelationJob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startBusinessRelationJob$Plain(params: StartBusinessRelationJob$Plain$Params, context?: HttpContext): Observable<string> {
    return this.startBusinessRelationJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startBusinessRelationJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  startBusinessRelationJob$Response(params: StartBusinessRelationJob$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return startBusinessRelationJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startBusinessRelationJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startBusinessRelationJob(params: StartBusinessRelationJob$Params, context?: HttpContext): Observable<string> {
    return this.startBusinessRelationJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerStartCompanyJobcode()` */
  static readonly WarrantyControllerStartCompanyJobcodePath = '/warranty/jobs/company/{code}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startCompanyJob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  startCompanyJob$Plain$Response(params: StartCompanyJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return startCompanyJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startCompanyJob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startCompanyJob$Plain(params: StartCompanyJob$Plain$Params, context?: HttpContext): Observable<string> {
    return this.startCompanyJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startCompanyJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  startCompanyJob$Response(params: StartCompanyJob$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return startCompanyJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startCompanyJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startCompanyJob(params: StartCompanyJob$Params, context?: HttpContext): Observable<string> {
    return this.startCompanyJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetImportJobLogid()` */
  static readonly WarrantyControllerGetImportJobLogidPath = '/warranty/jobs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImportJobLog$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImportJobLog$Plain$Response(params: GetImportJobLog$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyClaimJobLog>> {
    return getImportJobLog$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImportJobLog$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImportJobLog$Plain(params: GetImportJobLog$Plain$Params, context?: HttpContext): Observable<WarrantyClaimJobLog> {
    return this.getImportJobLog$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyClaimJobLog>): WarrantyClaimJobLog => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImportJobLog()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImportJobLog$Response(params: GetImportJobLog$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyClaimJobLog>> {
    return getImportJobLog(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImportJobLog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImportJobLog(params: GetImportJobLog$Params, context?: HttpContext): Observable<WarrantyClaimJobLog> {
    return this.getImportJobLog$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyClaimJobLog>): WarrantyClaimJobLog => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetWarrantiesClaimAttachmentsclaimId()` */
  static readonly WarrantyControllerGetWarrantiesClaimAttachmentsclaimIdPath = '/warranty/claims/{claimId}/attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesClaimAttachments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaimAttachments$Plain$Response(params: GetWarrantiesClaimAttachments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WarrantyClaimAttachmentViewModel>>> {
    return getWarrantiesClaimAttachments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesClaimAttachments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaimAttachments$Plain(params: GetWarrantiesClaimAttachments$Plain$Params, context?: HttpContext): Observable<Array<WarrantyClaimAttachmentViewModel>> {
    return this.getWarrantiesClaimAttachments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WarrantyClaimAttachmentViewModel>>): Array<WarrantyClaimAttachmentViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesClaimAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaimAttachments$Response(params: GetWarrantiesClaimAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WarrantyClaimAttachmentViewModel>>> {
    return getWarrantiesClaimAttachments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesClaimAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaimAttachments(params: GetWarrantiesClaimAttachments$Params, context?: HttpContext): Observable<Array<WarrantyClaimAttachmentViewModel>> {
    return this.getWarrantiesClaimAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WarrantyClaimAttachmentViewModel>>): Array<WarrantyClaimAttachmentViewModel> => r.body)
    );
  }

  /** Path part for operation `warrantyControllerAddAttachmentsclaimIdNewFiles()` */
  static readonly WarrantyControllerAddAttachmentsclaimIdNewFilesPath = '/warranty/claims/{claimId}/attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAttachments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAttachments$Response(params: AddAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addAttachments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAttachments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAttachments(params: AddAttachments$Params, context?: HttpContext): Observable<void> {
    return this.addAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAttachmentBase64()` */
  static readonly GetAttachmentBase64Path = '/warranty/claims/{claimId}/attachments/{attachmentId}/base64';

  /**
   * tjenare.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Plain$Response(params: GetAttachment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getAttachment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * tjenare.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Plain(params: GetAttachment$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getAttachment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * tjenare.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment$Response(params: GetAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * tjenare.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachment(params: GetAttachment$Params, context?: HttpContext): Observable<string> {
    return this.getAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetThumbnailImageclaimIdAttachmentId()` */
  static readonly WarrantyControllerGetThumbnailImageclaimIdAttachmentIdPath = '/warranty/claims/{claimId}/attachments/{attachmentId}/thumbnail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getThumbnailImage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThumbnailImage$Plain$Response(params: GetThumbnailImage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryData>> {
    return getThumbnailImage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getThumbnailImage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThumbnailImage$Plain(params: GetThumbnailImage$Plain$Params, context?: HttpContext): Observable<BinaryData> {
    return this.getThumbnailImage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryData>): BinaryData => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getThumbnailImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThumbnailImage$Response(params: GetThumbnailImage$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryData>> {
    return getThumbnailImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getThumbnailImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThumbnailImage(params: GetThumbnailImage$Params, context?: HttpContext): Observable<BinaryData> {
    return this.getThumbnailImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryData>): BinaryData => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetAttachmentContentclaimIdAttachmentIdClaimSalesforceIdFilename()` */
  static readonly WarrantyControllerGetAttachmentContentclaimIdAttachmentIdClaimSalesforceIdFilenamePath = '/warranty/claims/{claimId}/attachments/{attachmentId}/{claimSalesforceId}/content';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachmentContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachmentContent$Plain$Response(params: GetAttachmentContent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getAttachmentContent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachmentContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachmentContent$Plain(params: GetAttachmentContent$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getAttachmentContent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachmentContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachmentContent$Response(params: GetAttachmentContent$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getAttachmentContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachmentContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachmentContent(params: GetAttachmentContent$Params, context?: HttpContext): Observable<string> {
    return this.getAttachmentContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerCreateThumbnailForImageclaimIdAttachmentIdClaimSalesforceId()` */
  static readonly WarrantyControllerCreateThumbnailForImageclaimIdAttachmentIdClaimSalesforceIdPath = '/warranty/claims/{claimId}/attachments/{attachmentId}/thumbnail/{claimSalesforceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createThumbnailForImage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  createThumbnailForImage$Plain$Response(params: CreateThumbnailForImage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createThumbnailForImage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createThumbnailForImage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createThumbnailForImage$Plain(params: CreateThumbnailForImage$Plain$Params, context?: HttpContext): Observable<string> {
    return this.createThumbnailForImage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createThumbnailForImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  createThumbnailForImage$Response(params: CreateThumbnailForImage$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createThumbnailForImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createThumbnailForImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createThumbnailForImage(params: CreateThumbnailForImage$Params, context?: HttpContext): Observable<string> {
    return this.createThumbnailForImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerCreateThumbnailForPdFclaimIdAttachmentIdClaimSalesforceIdRequest()` */
  static readonly WarrantyControllerCreateThumbnailForPdFclaimIdAttachmentIdClaimSalesforceIdRequestPath = '/warranty/claims/{claimId}/attachments/{attachmentId}/thumbnail/{claimSalesforceId}/pdf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createThumbnailForPDF$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createThumbnailForPDF$Plain$Response(params: CreateThumbnailForPDF$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createThumbnailForPDF$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createThumbnailForPDF$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createThumbnailForPDF$Plain(params: CreateThumbnailForPDF$Plain$Params, context?: HttpContext): Observable<string> {
    return this.createThumbnailForPDF$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createThumbnailForPDF()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createThumbnailForPDF$Response(params: CreateThumbnailForPDF$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createThumbnailForPDF(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createThumbnailForPDF$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createThumbnailForPDF(params: CreateThumbnailForPDF$Params, context?: HttpContext): Observable<string> {
    return this.createThumbnailForPDF$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerDownloadAttachmentsclaimIdAttachmentId()` */
  static readonly WarrantyControllerDownloadAttachmentsclaimIdAttachmentIdPath = '/warranty/claims/{claimId}/attachments/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachments$Plain$Response(params: DownloadAttachments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return downloadAttachments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachments$Plain(params: DownloadAttachments$Plain$Params, context?: HttpContext): Observable<string> {
    return this.downloadAttachments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachments$Response(params: DownloadAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return downloadAttachments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachments(params: DownloadAttachments$Params, context?: HttpContext): Observable<string> {
    return this.downloadAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerRescopeClaimsrequest()` */
  static readonly WarrantyControllerRescopeClaimsrequestPath = '/warranty/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeClaims$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rescopeClaims$Plain$Response(params?: RescopeClaims$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescopeClaims$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeClaims$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rescopeClaims$Plain(params?: RescopeClaims$Plain$Params, context?: HttpContext): Observable<number> {
    return this.rescopeClaims$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescopeClaims()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rescopeClaims$Response(params?: RescopeClaims$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescopeClaims(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescopeClaims$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rescopeClaims(params?: RescopeClaims$Params, context?: HttpContext): Observable<number> {
    return this.rescopeClaims$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetWarrantiesByEquipmentIdequipmentIds()` */
  static readonly WarrantyControllerGetWarrantiesByEquipmentIdequipmentIdsPath = '/warranty/registrations/{equipmentIds}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesByEquipmentId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesByEquipmentId$Plain$Response(params: GetWarrantiesByEquipmentId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WarrantyCardViewModel>>> {
    return getWarrantiesByEquipmentId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesByEquipmentId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesByEquipmentId$Plain(params: GetWarrantiesByEquipmentId$Plain$Params, context?: HttpContext): Observable<Array<WarrantyCardViewModel>> {
    return this.getWarrantiesByEquipmentId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WarrantyCardViewModel>>): Array<WarrantyCardViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesByEquipmentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesByEquipmentId$Response(params: GetWarrantiesByEquipmentId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WarrantyCardViewModel>>> {
    return getWarrantiesByEquipmentId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesByEquipmentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesByEquipmentId(params: GetWarrantiesByEquipmentId$Params, context?: HttpContext): Observable<Array<WarrantyCardViewModel>> {
    return this.getWarrantiesByEquipmentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WarrantyCardViewModel>>): Array<WarrantyCardViewModel> => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetWarrantiesClaimsListGridoptions()` */
  static readonly WarrantyControllerGetWarrantiesClaimsListGridoptionsPath = '/warranty/searchgrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesClaimsListGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesClaimsListGrid$Plain$Response(params?: GetWarrantiesClaimsListGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return getWarrantiesClaimsListGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesClaimsListGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesClaimsListGrid$Plain(params?: GetWarrantiesClaimsListGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.getWarrantiesClaimsListGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesClaimsListGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesClaimsListGrid$Response(params?: GetWarrantiesClaimsListGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return getWarrantiesClaimsListGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesClaimsListGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWarrantiesClaimsListGrid(params?: GetWarrantiesClaimsListGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.getWarrantiesClaimsListGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `warrantyControllerSaveWarrantiesClaimrequest()` */
  static readonly WarrantyControllerSaveWarrantiesClaimrequestPath = '/warranty/saveclaim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWarrantiesClaim$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveWarrantiesClaim$Plain$Response(params?: SaveWarrantiesClaim$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return saveWarrantiesClaim$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveWarrantiesClaim$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveWarrantiesClaim$Plain(params?: SaveWarrantiesClaim$Plain$Params, context?: HttpContext): Observable<string> {
    return this.saveWarrantiesClaim$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWarrantiesClaim()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveWarrantiesClaim$Response(params?: SaveWarrantiesClaim$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return saveWarrantiesClaim(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveWarrantiesClaim$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveWarrantiesClaim(params?: SaveWarrantiesClaim$Params, context?: HttpContext): Observable<string> {
    return this.saveWarrantiesClaim$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerDeleteWarrantiesUnAvailableauroraClaimId()` */
  static readonly WarrantyControllerDeleteWarrantiesUnAvailableauroraClaimIdPath = '/warranty/delete/{auroraClaimId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWarrantiesUnAvailable$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWarrantiesUnAvailable$Plain$Response(params: DeleteWarrantiesUnAvailable$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deleteWarrantiesUnAvailable$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWarrantiesUnAvailable$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWarrantiesUnAvailable$Plain(params: DeleteWarrantiesUnAvailable$Plain$Params, context?: HttpContext): Observable<string> {
    return this.deleteWarrantiesUnAvailable$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWarrantiesUnAvailable()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWarrantiesUnAvailable$Response(params: DeleteWarrantiesUnAvailable$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deleteWarrantiesUnAvailable(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWarrantiesUnAvailable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWarrantiesUnAvailable(params: DeleteWarrantiesUnAvailable$Params, context?: HttpContext): Observable<string> {
    return this.deleteWarrantiesUnAvailable$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerDeleteDraftWarrantyClaimclaimId()` */
  static readonly WarrantyControllerDeleteDraftWarrantyClaimclaimIdPath = '/warranty/{claimId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDraftWarrantyClaim$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDraftWarrantyClaim$Plain$Response(params: DeleteDraftWarrantyClaim$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deleteDraftWarrantyClaim$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDraftWarrantyClaim$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDraftWarrantyClaim$Plain(params: DeleteDraftWarrantyClaim$Plain$Params, context?: HttpContext): Observable<string> {
    return this.deleteDraftWarrantyClaim$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDraftWarrantyClaim()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDraftWarrantyClaim$Response(params: DeleteDraftWarrantyClaim$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deleteDraftWarrantyClaim(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDraftWarrantyClaim$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDraftWarrantyClaim(params: DeleteDraftWarrantyClaim$Params, context?: HttpContext): Observable<string> {
    return this.deleteDraftWarrantyClaim$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetWarrantiesClaimDeliverySequenceid()` */
  static readonly WarrantyControllerGetWarrantiesClaimDeliverySequenceidPath = '/warranty/deliverypointinfo/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesClaimDeliverySequence$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaimDeliverySequence$Plain$Response(params: GetWarrantiesClaimDeliverySequence$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryPointInfoViewModel>> {
    return getWarrantiesClaimDeliverySequence$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesClaimDeliverySequence$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaimDeliverySequence$Plain(params: GetWarrantiesClaimDeliverySequence$Plain$Params, context?: HttpContext): Observable<DeliveryPointInfoViewModel> {
    return this.getWarrantiesClaimDeliverySequence$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryPointInfoViewModel>): DeliveryPointInfoViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesClaimDeliverySequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaimDeliverySequence$Response(params: GetWarrantiesClaimDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryPointInfoViewModel>> {
    return getWarrantiesClaimDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesClaimDeliverySequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaimDeliverySequence(params: GetWarrantiesClaimDeliverySequence$Params, context?: HttpContext): Observable<DeliveryPointInfoViewModel> {
    return this.getWarrantiesClaimDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryPointInfoViewModel>): DeliveryPointInfoViewModel => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetWarrantiesClaimid()` */
  static readonly WarrantyControllerGetWarrantiesClaimidPath = '/warranty/warrantiesclaim/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesClaim$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaim$Plain$Response(params: GetWarrantiesClaim$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyClaimViewModel>> {
    return getWarrantiesClaim$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesClaim$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaim$Plain(params: GetWarrantiesClaim$Plain$Params, context?: HttpContext): Observable<WarrantyClaimViewModel> {
    return this.getWarrantiesClaim$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyClaimViewModel>): WarrantyClaimViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesClaim()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaim$Response(params: GetWarrantiesClaim$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyClaimViewModel>> {
    return getWarrantiesClaim(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesClaim$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesClaim(params: GetWarrantiesClaim$Params, context?: HttpContext): Observable<WarrantyClaimViewModel> {
    return this.getWarrantiesClaim$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyClaimViewModel>): WarrantyClaimViewModel => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetWarrantiesWarrantyCardsmdgEquipmentId()` */
  static readonly WarrantyControllerGetWarrantiesWarrantyCardsmdgEquipmentIdPath = '/warranty/warrantieswarrantycards/{mdgEquipmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesWarrantyCards$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesWarrantyCards$Plain$Response(params: GetWarrantiesWarrantyCards$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyClaimViewModel>> {
    return getWarrantiesWarrantyCards$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesWarrantyCards$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesWarrantyCards$Plain(params: GetWarrantiesWarrantyCards$Plain$Params, context?: HttpContext): Observable<WarrantyClaimViewModel> {
    return this.getWarrantiesWarrantyCards$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyClaimViewModel>): WarrantyClaimViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWarrantiesWarrantyCards()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesWarrantyCards$Response(params: GetWarrantiesWarrantyCards$Params, context?: HttpContext): Observable<StrictHttpResponse<WarrantyClaimViewModel>> {
    return getWarrantiesWarrantyCards(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWarrantiesWarrantyCards$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWarrantiesWarrantyCards(params: GetWarrantiesWarrantyCards$Params, context?: HttpContext): Observable<WarrantyClaimViewModel> {
    return this.getWarrantiesWarrantyCards$Response(params, context).pipe(
      map((r: StrictHttpResponse<WarrantyClaimViewModel>): WarrantyClaimViewModel => r.body)
    );
  }

  /** Path part for operation `warrantyControllerSaveWarrantiesClaimLineItemsrequest()` */
  static readonly WarrantyControllerSaveWarrantiesClaimLineItemsrequestPath = '/warranty/saveclaimline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWarrantiesClaimLineItems$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveWarrantiesClaimLineItems$Plain$Response(params?: SaveWarrantiesClaimLineItems$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return saveWarrantiesClaimLineItems$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveWarrantiesClaimLineItems$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveWarrantiesClaimLineItems$Plain(params?: SaveWarrantiesClaimLineItems$Plain$Params, context?: HttpContext): Observable<string> {
    return this.saveWarrantiesClaimLineItems$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWarrantiesClaimLineItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveWarrantiesClaimLineItems$Response(params?: SaveWarrantiesClaimLineItems$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return saveWarrantiesClaimLineItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveWarrantiesClaimLineItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveWarrantiesClaimLineItems(params?: SaveWarrantiesClaimLineItems$Params, context?: HttpContext): Observable<string> {
    return this.saveWarrantiesClaimLineItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `warrantyControllerDeleteWarrantiesClaimAttachmentclaimIdAttachmentId()` */
  static readonly WarrantyControllerDeleteWarrantiesClaimAttachmentclaimIdAttachmentIdPath = '/warranty/deleteclaimattachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWarrantiesClaimAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWarrantiesClaimAttachment$Response(params?: DeleteWarrantiesClaimAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteWarrantiesClaimAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWarrantiesClaimAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWarrantiesClaimAttachment(params?: DeleteWarrantiesClaimAttachment$Params, context?: HttpContext): Observable<void> {
    return this.deleteWarrantiesClaimAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `warrantyControllerSubmitWarrantiesClaimid()` */
  static readonly WarrantyControllerSubmitWarrantiesClaimidPath = '/warranty/submitclaim/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitWarrantiesClaim()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitWarrantiesClaim$Response(params: SubmitWarrantiesClaim$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return submitWarrantiesClaim(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitWarrantiesClaim$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  submitWarrantiesClaim(params: SubmitWarrantiesClaim$Params, context?: HttpContext): Observable<void> {
    return this.submitWarrantiesClaim$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `warrantyControllerGetMandatoryClaimInfoRequirementsPdf()` */
  static readonly WarrantyControllerGetMandatoryClaimInfoRequirementsPdfPath = '/warranty/mandatoryclaiminformationrequirements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMandatoryClaimInfoRequirementsPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMandatoryClaimInfoRequirementsPdf$Response(params?: GetMandatoryClaimInfoRequirementsPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getMandatoryClaimInfoRequirementsPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMandatoryClaimInfoRequirementsPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMandatoryClaimInfoRequirementsPdf(params?: GetMandatoryClaimInfoRequirementsPdf$Params, context?: HttpContext): Observable<void> {
    return this.getMandatoryClaimInfoRequirementsPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
