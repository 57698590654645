/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MessageLogLogTableChartDataViewModel } from '../../models/message-log-log-table-chart-data-view-model';

export interface GetTableChartData$Params {
  tableName: string;
}

export function getTableChartData(http: HttpClient, rootUrl: string, params: GetTableChartData$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageLogLogTableChartDataViewModel>> {
  const rb = new RequestBuilder(rootUrl, getTableChartData.PATH, 'get');
  if (params) {
    rb.path('tableName', params.tableName, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MessageLogLogTableChartDataViewModel>;
    })
  );
}

getTableChartData.PATH = '/messagelogs/table/{tableName}/chartdata';
