<mibp-dropdown
  [class.ng-pending]="showLoader"
  #serialDropdown
  [data]="equipment$"
  (filter)="filterEquipment($event)"
  [multiselect.hideOnClick]="true"
  [multiselect.tagPlaceholder]="true"
  [enableClearSingleSelect]="canBeCleared"
  (valueChange)="onDropdownValueChange($event)"
  [take]="dropdownPageSize"
  [disabled]="isDisabled"></mibp-dropdown>

<!-- placeholder="Component_ResponsibilityPicker_Any" -->
