
<div *ngIf="isOpen && isActingAs" class="cart-quickview" [ngClass]="(showMobileLayout ? 'cart-quickview-mobile' : '')" #cartQuickviewElement (mouseleave)="hideCartQuickView()">
  <div #cartQuickView class="cart-quickview__content">
    <h3 class="my-header"><mibp-resource-string key="Global_Carts_RecentlyAdded"></mibp-resource-string></h3>
    <ul class="cart-quickview__productlist light-scroll">
      <mibp-resource-string *ngIf="!isError && currentPageItems.length <= 0" key="Carts_Active_NoItemsInCart"></mibp-resource-string>
      <mibp-resource-string *ngIf="isError" key="Carts_QuickView_Error"></mibp-resource-string>
      <li *ngFor="let productListitem of currentPageItems; let i=index">
        <mibp-product-list-item [ecomItem]="productListitem"></mibp-product-list-item>
        <div class="cart-quickview__productlist__item">
          <div class="cart-quickview__productlist__item--imagecontainer">
            <a (click)="navigateToProductDetails(productListitem.product.code)">
              <mibp-image [urlOfImage]="productListitem.product.PartsPictureImageUrl" ></mibp-image></a>
          </div>
          <div  class="cart-quickview__productlist__item--info">
            <div class="cart-quickview__productlist__item--info--name">
              <a (click)="navigateToProductDetails(productListitem.product.code)">
                <b>{{ (productListitem.product.name.length > 30) ? (productListitem.product.name | slice:0:30)+'...':(productListitem.product.name)}}</b></a>
            </div>
            <div class="itemproperties">
              <div>
                <div class="itemproperties__label">
                  <mibp-resource-string key="Global_ProductNumber"></mibp-resource-string>:
                </div>
                <div class="itemproperties__value">{{ productListitem.superseededByProductCode || productListitem.product.code }}</div>
              </div>
              <div>
                <div class="itemproperties__label">
                  <mibp-resource-string key="Global_Quantity"></mibp-resource-string>:
                </div>
                <div class="itemproperties__value">{{productListitem.quantity}}</div>
              </div>
              <div>
                <div class="itemproperties__label itemproperties__label--availability"><mibp-resource-string key="Global_ProductAvailability"></mibp-resource-string>: </div>
                <div class="itemproperties__value">
                  <mibp-availability
                    [productCode]= "productListitem.product.code"
                    [quantity]= "productListitem.quantity"
                    [isSubscription] = "productListitem.isSubscription"
                    [productAvailability] = "productListitem.productAvailability"
                    ></mibp-availability>
                </div>
              </div>
              <div>
                <div class="itemproperties__label itemproperties__label--price itemproperties__label--price">
                  <mibp-resource-string key="Global_TotalPrice"></mibp-resource-string>:
                </div>
                <div class="itemproperties__value"
                  [class.itemproperties__value--error]="!productListitem.productPrice || productListitem.productPrice?.errorCode"
                  [class.itemproperties__value--price]="productListitem.productPrice?.netPrice != null && !productListitem.productPrice?.errorCode">

                  <mibp-loader type="spinner" *ngIf="!productListitem.productPrice"></mibp-loader>
                  <div *ngIf="productListitem.productPrice?.netPrice > 0;" class="cart-quickview__productlist__item--info--price">{{((productListitem.quantity * productListitem.productPrice.netPrice) | number) + ' ' + currencyCode}}</div>
                  <mibp-resource-string class="text--error" *ngIf="productListitem.productPrice?.errorCode" key="Carts_ErrorGettingPrice"></mibp-resource-string>
                  <a (click)="openPriceContactUs(productListitem.superseededByProductCode || productListitem.product.code, productListitem.quantity)" *ngIf="productListitem.productPrice && productListitem.productPrice.netPrice <= 0 && !productListitem.productPrice?.errorCode" >
                    <mibp-resource-string key="Global_Carts_RequestPrice"></mibp-resource-string>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="cart-quickview__productlist__item--deleteicon">
            <a (click)="onDeleteCartItem(event, i)" href="javascript:void(0)">
              <span translate="no" class="material-icon material-icon--red bold--icon">
              close
            </span>
            </a>
          </div>
        </div>
      </li>
    </ul>

    <div *ngIf="currentPageItems.length > 0" class="cart-quickview__subTotal">
      <span><mibp-resource-string key="Carts_SubTotal"></mibp-resource-string></span>
      <span *ngIf="subtotal > 0" class="cart-quickview__subTotal--value"> {{((subtotal | number) + ' ' + currencyCode)}} </span>
    </div>

    <div class="cart-quickview__button-container">
      <button class="my-button my-button--secondary" (click)="hideCartQuickView()">Close</button>
      <button class="my-button my-button--primary"  (click)="hideCartQuickView()" [ecommerceRouterLink]="['/:lang/shop/cart']">View cart</button>
    </div>
  </div>
</div>
