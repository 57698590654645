import { allPermissionPolicies } from "root/all-permission-policies";
import { MySandvikNavigationItem } from "../navigation-types";


export const NAVITEMS_BULLETINS: MySandvikNavigationItem = {
  resourceKey: 'Navigation_Bulletins',
  sandvikIconName: 'newspaper',
  permissionPolicy: allPermissionPolicies.bulletins,
  route: {
    path: ['bulletins']
  },
  children: [
    // Children on this level are never visible in menu, but
    // we can still use navigation service to fetch permissions etc
    // or to easily navigate here
    {
      uniqueId: 'bulletins-unread',
      resourceKey: 'Bulletin_Unread_Title',
      route: {
        path: ['unread']
      },
      permissionPolicy: allPermissionPolicies.bulletins,
    },
    {
      uniqueId: 'bulletins-archived',
      resourceKey: 'Bulletin_Archived_Title',
      route: {
        path: ['archived']
      },
      permissionPolicy: allPermissionPolicies.bulletins,
    },
    {
      resourceKey: 'Bulletin_AddNew',
      uniqueId: 'bulletins-add',
      permissionPolicy: allPermissionPolicies.manageBulletins,
      route: {
        path: ['add']
      }
    },
    {
      uniqueId: 'bulletins-edit',
      resourceKey: 'Bulletin_Edit_Title',
      route: {
        path: ['edit']
      },
      permissionPolicy: allPermissionPolicies.manageBulletins,
    },
    {
      uniqueId: 'bulletins-details',
      resourceKey: 'Bulletin_Details_title',
      route: {
        path: ['details'],
      },
      permissionPolicy: allPermissionPolicies.bulletins,
    },
    {
      resourceKey: 'Bulletin_EmailStatus_Title',
      uniqueId: '#bulletins-emailstatus',
      route: {
        path: ['emailstatus']
      },
      permissionPolicy: allPermissionPolicies.bulletins,
    }
  ]
};

