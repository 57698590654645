<div class=" is-initiated exp-section-small" [class.is-modern]="design == 'modern'"  [ngClass]=" canExpand ? 'exp-section': 'exp-section-no-kit'" [class.can-expand]="canExpand" [class.collapsed]="!isExpanded" [class.no-padding]="disableContentPadding" [class.exp-section-compact]="compact">

  <div class="columns">
    <div class="column">
      <div class="expand-title" [ngClass]="{'edit-title': editTitle}">
        <button *ngIf="!editTitle" class="expand-title-button" type="button" [attr.aria-expanded]="!isExpanded" (click)="toggleExpanded()" [ngClass]="{'edit-title': editTitle, 'allow-edit': allowEditTitle }" #expandBtn></button>
        <!-- <mibp-cart-name [cart]="title" [allowEdit]="allowEditTitle" (editState)="toggleEditTitle($event)"></mibp-cart-name> -->
        <mibp-expandable-section-title [design]="design" [title]="title" [allowEdit]="allowEditTitle" [isExpanded]="isExpanded" (editState)="toggleEditTitle($event)" (doneEditing)="onFinishedEditingTitle($event)" [titleType]="titleType"></mibp-expandable-section-title>
        <ng-content select="div[data-title]"></ng-content>
      </div>
      <ng-content select="div[data-header]"></ng-content>
    </div>
    <div [class]="asideClassName" *ngIf="showAside">
      <ng-content select="div[data-aside]"></ng-content>
    </div>
  </div>


  <div class="exp-section-content" id="exp-section-01" aria-hidden="false">
      <ng-content select="div[data-content]"></ng-content>
  </div>
</div>
