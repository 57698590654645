import { Injectable } from '@angular/core';
import { CartsApiController, MibpPaymentApiController } from 'root/mibp-openapi-gen/controllers';
import { LogService, MibpLogger } from '../logservice';
import { firstValueFrom } from 'rxjs';
import { AdyenConfigurationVm } from 'root/mibp-openapi-gen/models';
import { FrontendContextService } from '../front-end-context/front-end-context.service';
import { MySandvikFeatures } from '../permission';

@Injectable({
  providedIn: 'root'
})
export class MibpPaymentService {
  log: MibpLogger;

  constructor(
    private mibpPaymentApiController: MibpPaymentApiController,
    private frontendContext: FrontendContextService,
    private mibpPaymentController: MibpPaymentApiController,
    private cartsController: CartsApiController,
    logger: LogService,
  ) {
    this.log = logger.withPrefix('mibp-payment.service');
  }



  public getAdyenConfiguration(): Promise<AdyenConfigurationVm>{
    return firstValueFrom(this.mibpPaymentApiController.getMibpPaymentConfiguration());
  }

  async isUserAllowedForCardPayment(): Promise<boolean> {
    const hasFeatureMibpPayment = this.frontendContext.testPermission({ features: [MySandvikFeatures.ShopPlaceOrderMibppayment] });
    const hasFeaturePlaceOrder = this.frontendContext.testPermission({ features: [MySandvikFeatures.ShopPlaceOrder] });

    if (!hasFeatureMibpPayment || !hasFeaturePlaceOrder) {
      return false;
    }

    try {
      const isCurrencyValid = await firstValueFrom(this.mibpPaymentController.isCompanyAndCurrencyValidForMibpPayment());
      if (!isCurrencyValid) {
        return false;
      }

    } catch (error) {
      this.log.error('Check failed, isCurrencyValidForMibpPayment', error);
    }


    return true;
  }

  async isUserAllowedForInvoicePayment(): Promise<boolean> {
    const hasFeaturePlaceOrder = this.frontendContext.testPermission({features: [MySandvikFeatures.ShopPlaceOrder]});

    if(!hasFeaturePlaceOrder) {
      return false;
    }

    try {
      return await firstValueFrom(this.cartsController.isInvoicePaymentAllowed());
    } catch (error) {
      this.log.error('Check failed, isInvoicePaymentAllowed', error);
    }
  }

}
