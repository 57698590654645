import { Component, OnInit } from "@angular/core";
import { GlobalConfigService } from './../../services/global-config/global-config.service';

@Component({
  selector: 'mibp-google-maps-script',
   template: ``,
})
export class MibpGoogleMapsScriptComponent implements OnInit {

  constructor(private globalConfig: GlobalConfigService) {}

  ngOnInit(): void {
    const value = this.globalConfig.googleMapsScriptKey;
    if(value?.length)
    {
      const scriptElm = document.createElement('script');
      scriptElm.setAttribute('type', 'text/javascript');
      scriptElm.src = 'https://maps.googleapis.com/maps/api/js?key='+value.toString();
      document.head.appendChild(scriptElm);
    }
  }

}




