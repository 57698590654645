<div></div>
<p *ngIf="(mapData !== undefined &&  mapData?.mapLocation === null) || errorLoadingGoogleScript">
  <mibp-resource-string key="MyFleet_Location_NotAvailable"></mibp-resource-string>
</p>

  <div [ngClass]="{'hidden': !hasMapData}">
    <!-- <div *ngIf="mapData?.mapLocation !==undefined" #map class="map"></div> -->
    <div  #map class="map"></div>

  </div>

