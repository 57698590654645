/* tslint:disable */
/* eslint-disable */
export enum AddItemToCartSource {
  QuickAdd = 1,
  ProductSearch = 2,
  Kits = 3,
  Documoto = 4,
  Upsell = 5,
  Promotions = 6,
  Template = 7,
  CrossSell = 8,
  Order = 9,
  Quotation = 10,
  PartsManual = 20,
  MaintenancePlanner = 30,
  KitChild = 40,
  DocumotoWidget = 50
}
