<h3 *ngIf="title && !isResourceString" class="toast-title">{{title}}</h3>
<h3 *ngIf="title && isResourceString" class="toast-title"><mibp-resource-string [key]="title" [macros]="titleResourceStringMacros"></mibp-resource-string></h3>
<button type="button" aria-label="Close" class="toast-close-button" [ngStyle]="{'top': title == null ? '-0.7em':'-1.6em'}" (click)="close()"><span>×</span></button>
<div *ngIf="isResourceString" class="toast-message">
    <mibp-resource-string [key]="message" [isHtml]="options.enableHtml" [macros]="bodyResourceStringMacros"></mibp-resource-string>
</div>
<ng-container *ngIf="!isResourceString">
    <div *ngIf="!options.enableHtml" class="toast-message">{{message}}</div>
    <div *ngIf="options.enableHtml" class="toast-message" [innerHtml]="message"></div>
</ng-container>
<div *ngIf="buttons && buttons.length > 0" class="mt-2">
    <button *ngFor="let btn of buttons" (click)="action(btn)" class="my-button my-button--secondary">
        <mibp-resource-string [key]="btn.textResourceString"></mibp-resource-string>
    </button>
</div>
