<div class="my-productlist">

<!-- Header  -->

  <div class="my-productlist__head media@xs-and-larger">
    <div class="my-productlist__row">
      <div class="my-productlist__cell my-productlist__cell--image">
        <mibp-resource-string key="MyFleet_Image" [titleAttr]="true" [truncate]="true"></mibp-resource-string>
      </div>

      <div class="my-productlist__cell my-productlist__cell--product"><mibp-resource-string key="Global_Product" [titleAttr]="true" [truncate]="true"></mibp-resource-string></div>

      <div class="my-productlist__cell my-productlist__cell--availableqty" *ngIf="showAvailableQuantity"><mibp-resource-string key="Global_Available" [titleAttr]="true" [truncate]="true"></mibp-resource-string>&nbsp;<mibp-resource-string key="Global_Quantity" [titleAttr]="true" [truncate]="true"></mibp-resource-string></div>

      <div class="my-productlist__cell my-productlist__cell--actas" *ngIf="!isActingAs">&nbsp;</div>

      <div class="my-productlist__cell my-productlist__cell--quantity"><mibp-resource-string *ngIf="isActingAs" key="Global_Quantity"></mibp-resource-string></div>

      <div class="my-productlist__cell my-productlist__cell--totalprice"><mibp-resource-string *ngIf="isActingAs && !hideTotalPrice" key="Global_TotalPrice" [titleAttr]="true" [truncate]="true"></mibp-resource-string></div>

    </div>
  </div>

  <div *ngIf="!items && items?.length !== 0">
    <mibp-loader></mibp-loader>
  </div>
  <div data-aut="product-list-item" class="my-productlist__rowgroup my-productlist__rowgroup--item"
  [mibp-product-list-item] #cartItem
  *ngFor='let ecomItem of items; trackBy: trackByItemId; let i = index'
  data-product-index="{{index}}"
  (itemRemoved)="onItemRemoved(i, $event)"
  (validDecimalQuantity)="onvalidDecimalQuantity($event)"
  [hidePrice]="hidePrice"
  [hideTotalPrice]="hideTotalPrice"
  [hideAvailability]="hideAvailability"
  [hideRelatedButtons]="hideRelatedButtons"
  [hideRelatedProducts]="hideRelatedProducts"
  [hideAddToCart]="hideAddToCart"
  [hideAddToCartBig]="hideAddToCartBig"
  [hideDelete]="hideDelete"
  [hideAction]="hideAction"
  [showRecommendedQuantity]="showRecommendedQuantity"
  [index]='i'
  [cartId]="cartId"
  [ecomItem]='ecomItem'
  [isActiveCart]="isActiveCart"
  [priceAndAvailabilitySource]="priceAndAvailabilitySource"
  [addItemToCartSource]="addItemToCartSource"
  [disableDuplicate]="disableDuplicate"
  [newProductCode]="ecomItem.productPrice?.productCode"
  [showAvailableQuantity]="showAvailableQuantity"
  [isDecimalUnit]="ecomItem.product.unitOfMeasure.isDecimal"
  [newProductCode]="ecomItem.superseededByProductCode">
</div>
</div>

