<mibp-dialog #confirmDeleteDialog (buttonClick)="deleteDialogButtonClick(confirmDeleteDialog, $event)" [buttons]="confirmDeleteButtons"
size="auto" [speadButtonsEvenly]="true">
  <mibp-resource-string key="Carts_ConfirmRemoveItem_Text"></mibp-resource-string>
</mibp-dialog>

<div  class="my-productlist__row my-productlist__row--cart-item"
    [@addedAnimation]="addedState" [@deletedAnimation]="currentState"
    [attr.data-product-code]="productListitem.product.code"
    [attr.data-row-index]="index"
    [style.opacity]="isLoading ? 0.2 : 1">

<div class="my-productlist__cell my-productlist__cell--image">
  <div class="my-productlist__image">
    <a   (click)="onClickProductImage(productListitem.product)">
    <mibp-image [urlOfImage]="productListitem.product.PartsPictureImageUrl" ></mibp-image></a>
  </div>
</div>
<div  class="my-productlist__cell my-productlist__cell--product">
  <div>
  <a   (click)="onClickProductImage(productListitem.product)">
    <h4 class="my-productlist__cell--name">
      <span>{{ productListitem.product.name }}</span>
      <!-- Duplicate icon warning -->
      <div class="tooltip" *ngIf="disableDuplicate===false && productListitem.isDuplicate">
        <span translate="no" class="material-icon material-icon--red material-icon--fill">error</span>
        <span class="tooltiptext"><mibp-resource-string key="Cart_Duplicate_Product"></mibp-resource-string></span>
      </div>
    </h4>
  </a>
  <ng-template [ngIf]="productListitem.product.productNotificationId">
    <mibp-product-notification [productNotificationId]="productListitem.product.productNotificationId"></mibp-product-notification>
  </ng-template>
 </div> 
  <div *ngIf="productListitem.isSuperseeded" class="my-status-label my-status-label--information superseded-product">
    <mibp-resource-string key="Carts_ItemSuperseededStatus"></mibp-resource-string>
     {{productListitem.product.code}}
  </div>

  @if (isProductMissingOrRestricted) {
    <p style="color:red">This product is not available for your active company</p>
  } @else if (isGlobalProductUnavailable) {
    <p style="color:red">This product is not globally available</p>

  }

  <!-- We can't use CSS GRID because of IE11, so we use a table for this-->
  <table class="properties" [class.properties--collapsable]="isActiveCart">
    <tbody>
      <tr class="prop" *ngIf="productListitem.documotoDeepUrl">
        <th><mibp-resource-string key="PartsCatalogue_Title"></mibp-resource-string>:</th>
        <td class="pointer">
          <a (click)="redirectToDocumoto()"><mibp-resource-string key="Global_View"></mibp-resource-string></a>
        </td>
      </tr>
      <tr class="prop prop--code">
        <th><mibp-resource-string key="Global_ProductNumber"></mibp-resource-string>:</th>
        <td>{{ productListitem.superseededByProductCode || productListitem.product.code }}</td>
      </tr>

      <tr class="prop prop--alias" *ngIf="isActingAs && productListitem?.product?.productAlias">
        <th><mibp-resource-string key="Global_Alias"></mibp-resource-string>:</th>
        <td>{{ productListitem.product.productAlias}}</td>
      </tr>

      <tr class="prop prop--weight">
        <th><mibp-resource-string key="Global_Weight"></mibp-resource-string>:</th>
        <td>
          <mibp-product-weight [weight]="productListitem.product.weight"></mibp-product-weight>
        </td>
      </tr>

      @if (!isProductMissingOrRestricted) {

      <tr class="prop prop--expecteddelivery" *ngIf="useexpectedDeliveryDateAndAvailability && isActingAs && isAuroraCompany">
        <th><mibp-resource-string key="Global_ExpectedDeliveryDate"></mibp-resource-string>:</th>
        <td>
          <mibp-expected-delivery-date-availability
          [quantity]="productListitem.quantity"
          [productExpectedDate]="productListitem.productExpectedDate"
          [productCode]="productListitem.product.code"
          [isRockToolItem] = "productListitem.product?.isRockToolItem"
          ></mibp-expected-delivery-date-availability>
        </td>
      </tr>
      <tr class="prop prop--availability" *ngIf="isActingAs && userCanSeeAvailability">
        <th><mibp-resource-string key="Global_ProductAvailability"></mibp-resource-string>:</th>
        <td>
              <mibp-availability
              [productCode]= "productListitem.product.code"
              [quantity]= "productListitem.quantity"
              [isSubscription] = "productListitem.isSubscription"
              [productAvailability] = "productListitem.productAvailability"
              ></mibp-availability>
        </td>
      </tr>
      <tr class="availableqty" *ngIf="showAvailableQuantity">
        <th>
          <mibp-resource-string key="Global_Available"></mibp-resource-string>&nbsp;<mibp-resource-string key="Global_Quantity"></mibp-resource-string>:
        </th>
        <td>
          {{productListitem.availablePromotionProductQuantity}}
        </td>
      </tr>
      <tr class="prop prop--price" *ngIf="isActingAs && !hidePrice && isAllowedToSeePrice && !isProductMissingOrRestricted">
        <th><mibp-resource-string key="Global_UnitPrice"></mibp-resource-string>:</th>
        <td>
          <div>
            <mibp-product-price
            [displayBlock]="true"
            [productPrice]="productListitem.productPrice"
            displayStyle="cartitem"
            [priceUnitOfMeasure]="productListitem.product.unitOfMeasure.uom"
            [productCode]="productListitem.product.code"
            [quantityForContactUs]="productListitem.quantity"
            ></mibp-product-price>
          </div>
        </td>
      </tr>
    }

    </tbody>
  </table>

</div>
<div class="my-productlist__cell my-productlist__cell--availableqty" *ngIf="showAvailableQuantity">
  <span>{{productListitem.availablePromotionProductQuantity}}</span>
</div>
<div class="my-productlist__cell my-productlist__cell--quantity" *ngIf="isActingAs || !isDeliverySequenceRequired">

  <!-- <span class="my-productlist__mobileheader media@xs-and-smaller"><mibp-resource-string key="Global_Quantity"></mibp-resource-string></span> -->

  <form [formGroup]="quantityForm" *ngIf="isActingAs || !isDeliverySequenceRequired">
    <mibp-numeric-input formControlName="quantity" (inputChange)="updateQuantity($event)" (inputBlur)="onblurevent()"></mibp-numeric-input>
    <mibp-validation-text [formGroup]="quantityForm" [alwaysVisible]="true" controlName="quantity"></mibp-validation-text>
  </form>
  <div class="promotion-quantity-restriction" *ngIf="productListitem.showPromotionRestrictionNotice">
    <span translate="no" class="material-icon material-icon--red material-icon--fill">error</span>
    <mibp-resource-string *ngIf="availablePromotionQuantity > 0" key="PromotionProduct_Quantity_Exceeded_Message" [macros]="promotionNoticeMacro" [isHtml]="'true'"></mibp-resource-string>
    <mibp-resource-string *ngIf="availablePromotionQuantity < 0 || availablePromotionQuantity == 0" key="Promotion_Quantity_Exhasted"></mibp-resource-string>
  </div>
</div>

<div class="my-productlist__cell my-productlist__cell--actas" *ngIf="!isActingAs && isDeliverySequenceRequired">
  <mibp-resource-string key="Carts_ActForMoreInfo" *ngIf="!isActingAs"></mibp-resource-string>
</div>

<div class = "my-productlist__cell my-productlist__cell--totalprice"
*ngIf="isActingAs" [attr.data-has-price]="!!(productListitem.productPrice && productListitem.productPrice?.netPrice)">

  <span class="my-productlist__mobileheader media@xxs-only"><mibp-resource-string key="Global_TotalPrice"></mibp-resource-string></span>

  <div>
    <mibp-product-price
      *ngIf="!hideTotalPrice && isAllowedToSeePrice  && !isProductMissingOrRestricted"
      [displayBlock]="mediaIsSmallOrLarger"
      [productPrice]="productListitem.productPrice"
      displayStyle="cartitem"
      (priceError)="priceError()"
      (priceReceived)="priceReceived()"
      [quantity]="productListitem.quantity"
      [productCode]="productListitem.product.code"
      ></mibp-product-price>
  </div>
</div>


<div class="my-productlist__action-buttons" *ngIf=" !hideAddToCart && isActingAs && !isOutPhased">

  <div size="large" class="my-productlist__row--fullactions">
    <mibp-button data-gtm="productList_AddToCart" resourceStringKey="Carts_Active_AddToCartButton" (click)="addToActiveCart()"  [isSmall]="true" #btn [style]="btn.Enum.Styles.Fill" [ngStyle]="{'margin-left':'10px'}" [disabled]="!this.quantityForm.valid"></mibp-button>
  </div>

</div>
<div *ngIf="isOutPhased" class="my-productlist__action-buttons">
  <a href="javascript:void(0)"  class="contact-us" (click)="clickedContactUsLinkForOutPhasedItem()">
     <mibp-resource-string key="Global_ContactUs"></mibp-resource-string>
  </a>
</div>


<div class="upsell-crossSell-container" *ngIf="productListitem.upsellCrossSellProductId || productListitem.crossSellProductIds?.length > 0">
    <button *ngIf="productListitem.upsellCrossSellProductId" class="my-button my-button--fill upsell-btn" (click)="showUpsellModal(productListitem.upsellCrossSellProductId)"><mibp-resource-string key="Cart_CartItem_UpsellUpgradeButton"></mibp-resource-string></button>
    <button *ngIf="productListitem.crossSellProductIds?.length > 0" class="my-button my-button--fill crossSell-btn" (click)="showCrossSellModal(productListitem.crossSellProductIds)"><mibp-resource-string key="Cart_CartItem_CrossSellButton"></mibp-resource-string></button>
  </div>

<div class="my-productlist__remove-from-cart" *ngIf="(isActingAs || !isDeliverySequenceRequired) && hasCreateCartPermission && productListitem.itemId && !hideDelete">
  <a (click)="confirmDeleteDialog.open()"
    href="javascript:void(0)">
    <span translate="no" class="material-icon material-icon--red material-icon--xxlarge">close</span>
  </a>
</div>

</div>

<div>

  <div class="my-productlist__row my-productlist__row--mobileactions media@xxs-only" *ngIf="!hideAddToCart && isActingAs && !isOutPhased">
    <mibp-button data-gtm="productList_AddToCart" *ngIf="!hideAddToCart && hasCreateCartPermission" isSmall="true" resourceStringKey="Carts_Active_AddToCartButton" (click)="addToActiveCart()" [style]="btn.Enum.Styles.Fill" #btn></mibp-button>
  </div>

</div>
