<div class="my-grid" [class.my-grid--topborder]="!hideTopBorder" [class.my-grid--striped]="striped" [class.my-grid--disabled]="isDisabled()">
  <div>
    <mibp-grid-paging *ngIf="pagingAbove" [disabled]="isDisabled()" [currentIndex]="currentIndex" [pageSize]="pageSize" (goto)="pageGoto($event)" [totalCount]="totalCount" [visiblePages]="pagerVisibleCount"></mibp-grid-paging>
    <mibp-grid-toolbar
      *ngIf="(filters || search)"
      (search)="triggerSearch($event)"
      (filter)="triggerFilter($event)"
      (reload)="triggerReload()"
      [disabled]="disabled || loading"
      [filters]="filterList"
      [query]="currentSearchQuery"
      [searchPlaceholder]="searchPlaceholder"
      [showFilters]="filters"
      [showSearch]="search"
      [tabName]="name"
      [showHelpIcon]="showHelpIcon"
      (showUserFlow)="triggerUserFlow()"
      ></mibp-grid-toolbar>

    <div class="grid-selection-tools">
      <div class="grid-selection-tools__pagesize">
          <label><mibp-resource-string key="Global_PageSizeDropdownLabel"></mibp-resource-string>:</label>
          <div class="page-size__dropdown">
              <mibp-dropdown  [items]="pageSizes" [selectedOption]="{text: pageSize ,value: pageSize}" (valueChange)="pageSizeChanged($event)" #selectPageSize></mibp-dropdown>
          </div>
      </div>

      <div class="grid-selection-tools__actions">
        <ng-content select="[extraMibpGridButtons]"></ng-content>
        <mibp-button *ngIf="isExportGridToExcelEnabled" resourceStringKey="Settings_Users_DownloadUserExcel" [color]="buttonColors.Secondary" (click)="onDownloadExcel()"></mibp-button>
        <mibp-button resourceStringKey="Grid_EditColumns"  [disabled]="disabled || loading" (click)="onToggleEditColumns(true)" [color]="buttonColors.Secondary"></mibp-button>
        <mibp-button resourceStringKey="Grid_Reload"  [disabled]="disabled || loading" (click)="triggerReload()" [color]="buttonColors.Secondary"></mibp-button>
      </div>
    </div>

    <mibp-grid-edit-columns [gridComponent]="this" [isOpen]="editColumnsOpen" (closed)="onToggleEditColumns(false)" [allColumns]="currentGridResponse?.columns"></mibp-grid-edit-columns>

    <div class="my-grid__count">
      <span *ngIf="!totalCount">0 <mibp-resource-string key="Global_Items"></mibp-resource-string></span>
      <span *ngIf="totalCount"><mibp-resource-string key="Global_Page_FirstLast_Index" [macros]="{ firstIndex: firstIndexOnPage, lastIndex: lastIndexOnPage, totalCount: totalCount }"></mibp-resource-string></span>
    </div>
    <div class="my-grid__tablearea" #mygrid [style.height]="calcMinHeight()">


      <div  class="m-4" *ngIf="systemMessage">
        <mibp-system-message [messageType]="systemMessage.messageType" [marginSize]="5">
          <h2 *ngIf="systemMessage.titleResourceString"><mibp-resource-string [key]="systemMessage.titleResourceString"></mibp-resource-string></h2>
          <p *ngIf="systemMessage.textResourceString && !systemMessage.isTextHtml"><mibp-resource-string [key]="systemMessage.textResourceString"></mibp-resource-string></p>
          <div class="my-body" *ngIf="systemMessage.textResourceString && systemMessage.isTextHtml"><mibp-resource-string [key]="systemMessage.textResourceString" [isHtml]="true"></mibp-resource-string></div>
        </mibp-system-message>
      </div>

      <div><mibp-loader *ngIf="loading && !totalCount && !currentGridResponse"></mibp-loader></div>
      <mibp-horizontal-scroll-container>
      <table *ngIf="currentGridResponse"  class="my-table my-table--nowrap">
          <thead>
              <tr>
                <th *ngIf="actions?.length > 0"></th>
                  <ng-container *ngFor="let column of visibleColumns; let ix = index; trackBy: trackColumn">

                    <th title="{{ column.text }}">
                      <div>
                        <span [class.my-grid__colname--sortable]="column.sortable" (click)="sortBy(column)">
                          <mibp-resource-string [key]="column.resourceStringKey"></mibp-resource-string>
                        </span>
                      </div>
                    </th>
                  </ng-container>
              </tr>
          </thead>
          <tbody *ngIf="rows?.length === 0" class="no-data">
            <tr>
              <td colspan="10"><mibp-resource-string key="Grid_StatusNoData"></mibp-resource-string></td>
            </tr>
          </tbody>
          <tbody *ngIf="rows?.length > 0">
            <ng-container *ngFor="let row of rows; let rowIndex = index;">
                <tr>
                  <td *ngIf="actions?.length > 0" class="my-grid__rowactionmenu" (click)="openRowActionMenu(mnu, $event, rowIndex)">
                      <mibp-context-menu #mnu [items]="actions" (select)="triggerRowActionEvent($event, rowIndex)"></mibp-context-menu>
                      <span translate="no" class="material-icon">menu</span>
                      <!-- <mibp-button #b [icon]="b.Enum.Icons.ChevronCircleRight" [isSmall]="true"></mibp-button> -->
                  </td>
                  <ng-container *ngFor="let column of visibleColumns; let ix = index; trackBy: trackColumn">
                      <td title="{{ !column.valueReourceStringPrefix ? row[column.colIndex].tooltipText || row[column.colIndex].text : ''}}">
                      <ng-template [ngIf]="!row[column.colIndex].route && !row[column.colIndex].url">
                        <span *ngIf="(ix !== 0 || !defaultActionName)&&row[column.colIndex].isVisible!==false&&!column.valueReourceStringPrefix">{{ row[column.colIndex].text }}</span>
                        <span [mibpResourceStringAttributes]="{ title: column.valueReourceStringPrefix+row[column.colIndex].text }" *ngIf="column.valueReourceStringPrefix">
                          <mibp-resource-string [key]="column.valueReourceStringPrefix+row[column.colIndex].text"></mibp-resource-string></span>
                        <span *ngIf="row[column.colIndex].isVisible===false">***********</span>
                        <a href="#" (click)="onRowDefaultAction($event, rowIndex)" *ngIf="ix === 0 && defaultActionName">{{ row[column.colIndex].text }}</a>
                        </ng-template>
                      <ng-template [ngIf]="row[column.colIndex].route || row[column.colIndex].url">

                        <a *ngIf="row[column.colIndex].route"  [ecommerceRouterLink]="row[column.colIndex].route">{{ row[column.colIndex].text }}</a>

                      </ng-template>
                      <ng-template [ngIf]="column.toggleVisibility">
                        <div class="float-left">
                          <mibp-button (click)="toggleVisibilityofField(ix,rowIndex)" [displayBlock] = "false" [icon] = "row[column.colIndex].isVisible ? icons.eye : icons.eyeSlash"></mibp-button>
                        </div>
                      </ng-template>
                      </td>
                  </ng-container>
              </tr>
            </ng-container>
          </tbody>
      </table>
    </mibp-horizontal-scroll-container>
    </div>
    <!-- <div *ngIf="statusbar && !hasError" class="my-grid__statusbar">
      <span *ngIf="totalCount">
        <mibp-resource-string key="Grid_StatusPageNumbers" [macros]="{ firstItemIndex: currentIndex + 1, lastItemIndex: pageLastIndex, totalCount: totalCount  }"></mibp-resource-string>
      </span>
      <span *ngIf="!totalCount">
        <mibp-resource-string key="Grid_StatusNoData"></mibp-resource-string>
      </span>
    </div> -->

    <mibp-grid-paging *ngIf="pagingBelow && pageSize > 0 && !hasError" [disabled]="isDisabled()" [currentIndex]="currentIndex" [pageSize]="pageSize" (goto)="pageGoto($event)" [totalCount]="totalCount" [visiblePages]="pagerVisibleCount"></mibp-grid-paging>


  </div>
</div>
