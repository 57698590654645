/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { delete$ } from '../fn/product-enrichments/delete';
import { Delete$Params } from '../fn/product-enrichments/delete';
import { getProductEnrichmentDetail } from '../fn/product-enrichments/get-product-enrichment-detail';
import { GetProductEnrichmentDetail$Params } from '../fn/product-enrichments/get-product-enrichment-detail';
import { getProductEnrichmentDetail$Plain } from '../fn/product-enrichments/get-product-enrichment-detail-plain';
import { GetProductEnrichmentDetail$Plain$Params } from '../fn/product-enrichments/get-product-enrichment-detail-plain';
import { getProductEnrichmentForEdit } from '../fn/product-enrichments/get-product-enrichment-for-edit';
import { GetProductEnrichmentForEdit$Params } from '../fn/product-enrichments/get-product-enrichment-for-edit';
import { getProductEnrichmentForEdit$Plain } from '../fn/product-enrichments/get-product-enrichment-for-edit-plain';
import { GetProductEnrichmentForEdit$Plain$Params } from '../fn/product-enrichments/get-product-enrichment-for-edit-plain';
import { gridSearch } from '../fn/product-enrichments/grid-search';
import { GridSearch$Params } from '../fn/product-enrichments/grid-search';
import { gridSearch$Plain } from '../fn/product-enrichments/grid-search-plain';
import { GridSearch$Plain$Params } from '../fn/product-enrichments/grid-search-plain';
import { Int32ApiResponse } from '../models/int-32-api-response';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { ProductEnrichmentDescriptionDto } from '../models/product-enrichment-description-dto';
import { ProductEnrichmentForEditDto } from '../models/product-enrichment-for-edit-dto';
import { saveProductEnrichmentResponse } from '../fn/product-enrichments/save-product-enrichment-response';
import { SaveProductEnrichmentResponse$Params } from '../fn/product-enrichments/save-product-enrichment-response';
import { saveProductEnrichmentResponse$Plain } from '../fn/product-enrichments/save-product-enrichment-response-plain';
import { SaveProductEnrichmentResponse$Plain$Params } from '../fn/product-enrichments/save-product-enrichment-response-plain';

@Injectable({ providedIn: 'root' })
export class ProductEnrichmentsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `productEnrichmentsControllerSaveProductEnrichmentResponseproductEnrichmentForSaveDto()` */
  static readonly ProductEnrichmentsControllerSaveProductEnrichmentResponseproductEnrichmentForSaveDtoPath = '/productenrichments/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProductEnrichmentResponse$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductEnrichmentResponse$Plain$Response(params?: SaveProductEnrichmentResponse$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return saveProductEnrichmentResponse$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveProductEnrichmentResponse$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductEnrichmentResponse$Plain(params?: SaveProductEnrichmentResponse$Plain$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.saveProductEnrichmentResponse$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProductEnrichmentResponse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductEnrichmentResponse$Response(params?: SaveProductEnrichmentResponse$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return saveProductEnrichmentResponse(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveProductEnrichmentResponse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductEnrichmentResponse(params?: SaveProductEnrichmentResponse$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.saveProductEnrichmentResponse$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /** Path part for operation `productEnrichmentsControllerGetProductEnrichmentForEditproductEnrichmentId()` */
  static readonly ProductEnrichmentsControllerGetProductEnrichmentForEditproductEnrichmentIdPath = '/productenrichments/edit/{productEnrichmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductEnrichmentForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductEnrichmentForEdit$Plain$Response(params: GetProductEnrichmentForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductEnrichmentForEditDto>> {
    return getProductEnrichmentForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductEnrichmentForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductEnrichmentForEdit$Plain(params: GetProductEnrichmentForEdit$Plain$Params, context?: HttpContext): Observable<ProductEnrichmentForEditDto> {
    return this.getProductEnrichmentForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductEnrichmentForEditDto>): ProductEnrichmentForEditDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductEnrichmentForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductEnrichmentForEdit$Response(params: GetProductEnrichmentForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductEnrichmentForEditDto>> {
    return getProductEnrichmentForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductEnrichmentForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductEnrichmentForEdit(params: GetProductEnrichmentForEdit$Params, context?: HttpContext): Observable<ProductEnrichmentForEditDto> {
    return this.getProductEnrichmentForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductEnrichmentForEditDto>): ProductEnrichmentForEditDto => r.body)
    );
  }

  /** Path part for operation `productEnrichmentsControllerGetProductEnrichmentDetailnewProductId()` */
  static readonly ProductEnrichmentsControllerGetProductEnrichmentDetailnewProductIdPath = '/productenrichments/{newProductId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductEnrichmentDetail$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductEnrichmentDetail$Plain$Response(params: GetProductEnrichmentDetail$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductEnrichmentDescriptionDto>> {
    return getProductEnrichmentDetail$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductEnrichmentDetail$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductEnrichmentDetail$Plain(params: GetProductEnrichmentDetail$Plain$Params, context?: HttpContext): Observable<ProductEnrichmentDescriptionDto> {
    return this.getProductEnrichmentDetail$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductEnrichmentDescriptionDto>): ProductEnrichmentDescriptionDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductEnrichmentDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductEnrichmentDetail$Response(params: GetProductEnrichmentDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductEnrichmentDescriptionDto>> {
    return getProductEnrichmentDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductEnrichmentDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductEnrichmentDetail(params: GetProductEnrichmentDetail$Params, context?: HttpContext): Observable<ProductEnrichmentDescriptionDto> {
    return this.getProductEnrichmentDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductEnrichmentDescriptionDto>): ProductEnrichmentDescriptionDto => r.body)
    );
  }

  /** Path part for operation `productEnrichmentsControllerDeleteid()` */
  static readonly ProductEnrichmentsControllerDeleteidPath = '/productenrichments/delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `productEnrichmentsControllerGridSearchoptions()` */
  static readonly ProductEnrichmentsControllerGridSearchoptionsPath = '/productenrichments/searchgrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain$Response(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Response(params?: GridSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch(params?: GridSearch$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

}
