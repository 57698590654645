/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { checkGeneralTermsandWarrantyRegistrationTermsExist } from '../fn/terms-and-conditions/check-general-termsand-warranty-registration-terms-exist';
import { CheckGeneralTermsandWarrantyRegistrationTermsExist$Params } from '../fn/terms-and-conditions/check-general-termsand-warranty-registration-terms-exist';
import { checkGeneralTermsandWarrantyRegistrationTermsExist$Plain } from '../fn/terms-and-conditions/check-general-termsand-warranty-registration-terms-exist-plain';
import { CheckGeneralTermsandWarrantyRegistrationTermsExist$Plain$Params } from '../fn/terms-and-conditions/check-general-termsand-warranty-registration-terms-exist-plain';
import { CompanySearchResponse } from '../models/company-search-response';
import { ExisitingTermsAndConditionsViewModel } from '../models/exisiting-terms-and-conditions-view-model';
import { get } from '../fn/terms-and-conditions/get';
import { Get$Params } from '../fn/terms-and-conditions/get';
import { get$Plain } from '../fn/terms-and-conditions/get-plain';
import { Get$Plain$Params } from '../fn/terms-and-conditions/get-plain';
import { getCompaniesWithoutTandC } from '../fn/terms-and-conditions/get-companies-without-tand-c';
import { GetCompaniesWithoutTandC$Params } from '../fn/terms-and-conditions/get-companies-without-tand-c';
import { getCompaniesWithoutTandC$Plain } from '../fn/terms-and-conditions/get-companies-without-tand-c-plain';
import { GetCompaniesWithoutTandC$Plain$Params } from '../fn/terms-and-conditions/get-companies-without-tand-c-plain';
import { getGeneralTermsPublishDate } from '../fn/terms-and-conditions/get-general-terms-publish-date';
import { GetGeneralTermsPublishDate$Params } from '../fn/terms-and-conditions/get-general-terms-publish-date';
import { getGeneralTermsPublishDate$Plain } from '../fn/terms-and-conditions/get-general-terms-publish-date-plain';
import { GetGeneralTermsPublishDate$Plain$Params } from '../fn/terms-and-conditions/get-general-terms-publish-date-plain';
import { getUserFileId } from '../fn/terms-and-conditions/get-user-file-id';
import { GetUserFileId$Params } from '../fn/terms-and-conditions/get-user-file-id';
import { getUserFileId$Plain } from '../fn/terms-and-conditions/get-user-file-id-plain';
import { GetUserFileId$Plain$Params } from '../fn/terms-and-conditions/get-user-file-id-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { save } from '../fn/terms-and-conditions/save';
import { Save$Params } from '../fn/terms-and-conditions/save';
import { searchGrid } from '../fn/terms-and-conditions/search-grid';
import { SearchGrid$Params } from '../fn/terms-and-conditions/search-grid';
import { searchGrid$Plain } from '../fn/terms-and-conditions/search-grid-plain';
import { SearchGrid$Plain$Params } from '../fn/terms-and-conditions/search-grid-plain';
import { TermsAndConditions } from '../models/terms-and-conditions';

@Injectable({ providedIn: 'root' })
export class TermsAndConditionsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `termsAndConditionsControllerSearchGridoptions()` */
  static readonly TermsAndConditionsControllerSearchGridoptionsPath = '/termsandconditions/searchgrid';

  /**
   * SearchGrid for getting List of Terms and Conditions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain$Response(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * SearchGrid for getting List of Terms and Conditions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * SearchGrid for getting List of Terms and Conditions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Response(params?: SearchGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * SearchGrid for getting List of Terms and Conditions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid(params?: SearchGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `termsAndConditionsControllerGetid()` */
  static readonly TermsAndConditionsControllerGetidPath = '/termsandconditions/{id}';

  /**
   * Get Terms and Condtions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain$Response(params: Get$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TermsAndConditions>> {
    return get$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Terms and Condtions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain(params: Get$Plain$Params, context?: HttpContext): Observable<TermsAndConditions> {
    return this.get$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TermsAndConditions>): TermsAndConditions => r.body)
    );
  }

  /**
   * Get Terms and Condtions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<TermsAndConditions>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Terms and Condtions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: Get$Params, context?: HttpContext): Observable<TermsAndConditions> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<TermsAndConditions>): TermsAndConditions => r.body)
    );
  }

  /** Path part for operation `termsAndConditionsControllerGetCompaniesWithoutTandCoptions()` */
  static readonly TermsAndConditionsControllerGetCompaniesWithoutTandCoptionsPath = '/termsandconditions/companieswithouttandc';

  /**
   * Get list of Companies with out Terms and Conditiond.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompaniesWithoutTandC$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompaniesWithoutTandC$Plain$Response(params?: GetCompaniesWithoutTandC$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanySearchResponse>> {
    return getCompaniesWithoutTandC$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of Companies with out Terms and Conditiond.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompaniesWithoutTandC$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompaniesWithoutTandC$Plain(params?: GetCompaniesWithoutTandC$Plain$Params, context?: HttpContext): Observable<CompanySearchResponse> {
    return this.getCompaniesWithoutTandC$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanySearchResponse>): CompanySearchResponse => r.body)
    );
  }

  /**
   * Get list of Companies with out Terms and Conditiond.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompaniesWithoutTandC()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompaniesWithoutTandC$Response(params?: GetCompaniesWithoutTandC$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanySearchResponse>> {
    return getCompaniesWithoutTandC(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of Companies with out Terms and Conditiond.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompaniesWithoutTandC$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompaniesWithoutTandC(params?: GetCompaniesWithoutTandC$Params, context?: HttpContext): Observable<CompanySearchResponse> {
    return this.getCompaniesWithoutTandC$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanySearchResponse>): CompanySearchResponse => r.body)
    );
  }

  /** Path part for operation `termsAndConditionsControllerSavesaveTermsAndConditionsViewModel()` */
  static readonly TermsAndConditionsControllerSavesaveTermsAndConditionsViewModelPath = '/termsandconditions/save';

  /**
   * Save Terms and Condtions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * Save Terms and Condtions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<void> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `termsAndConditionsControllerGetUserFileIdtermsAndConditionType()` */
  static readonly TermsAndConditionsControllerGetUserFileIdtermsAndConditionTypePath = '/termsandconditions/getuserfileid';

  /**
   * Get user file id of terms and condition of language.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFileId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFileId$Plain$Response(params?: GetUserFileId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getUserFileId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user file id of terms and condition of language.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserFileId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFileId$Plain(params?: GetUserFileId$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getUserFileId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Get user file id of terms and condition of language.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFileId$Response(params?: GetUserFileId$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getUserFileId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user file id of terms and condition of language.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserFileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFileId(params?: GetUserFileId$Params, context?: HttpContext): Observable<string> {
    return this.getUserFileId$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `termsAndConditionsControllerCheckGeneralTermsandWarrantyRegistrationTermsExist()` */
  static readonly TermsAndConditionsControllerCheckGeneralTermsandWarrantyRegistrationTermsExistPath = '/termsandconditions/checkgeneraltermsandwarrantyregistrationtermsexist';

  /**
   * Get if general terms exist or warranty registration exist.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkGeneralTermsandWarrantyRegistrationTermsExist$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkGeneralTermsandWarrantyRegistrationTermsExist$Plain$Response(params?: CheckGeneralTermsandWarrantyRegistrationTermsExist$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExisitingTermsAndConditionsViewModel>> {
    return checkGeneralTermsandWarrantyRegistrationTermsExist$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get if general terms exist or warranty registration exist.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkGeneralTermsandWarrantyRegistrationTermsExist$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkGeneralTermsandWarrantyRegistrationTermsExist$Plain(params?: CheckGeneralTermsandWarrantyRegistrationTermsExist$Plain$Params, context?: HttpContext): Observable<ExisitingTermsAndConditionsViewModel> {
    return this.checkGeneralTermsandWarrantyRegistrationTermsExist$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExisitingTermsAndConditionsViewModel>): ExisitingTermsAndConditionsViewModel => r.body)
    );
  }

  /**
   * Get if general terms exist or warranty registration exist.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkGeneralTermsandWarrantyRegistrationTermsExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkGeneralTermsandWarrantyRegistrationTermsExist$Response(params?: CheckGeneralTermsandWarrantyRegistrationTermsExist$Params, context?: HttpContext): Observable<StrictHttpResponse<ExisitingTermsAndConditionsViewModel>> {
    return checkGeneralTermsandWarrantyRegistrationTermsExist(this.http, this.rootUrl, params, context);
  }

  /**
   * Get if general terms exist or warranty registration exist.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkGeneralTermsandWarrantyRegistrationTermsExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkGeneralTermsandWarrantyRegistrationTermsExist(params?: CheckGeneralTermsandWarrantyRegistrationTermsExist$Params, context?: HttpContext): Observable<ExisitingTermsAndConditionsViewModel> {
    return this.checkGeneralTermsandWarrantyRegistrationTermsExist$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExisitingTermsAndConditionsViewModel>): ExisitingTermsAndConditionsViewModel => r.body)
    );
  }

  /** Path part for operation `termsAndConditionsControllerGetGeneralTermsPublishDate()` */
  static readonly TermsAndConditionsControllerGetGeneralTermsPublishDatePath = '/termsandconditions/general/publishdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGeneralTermsPublishDate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralTermsPublishDate$Plain$Response(params?: GetGeneralTermsPublishDate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getGeneralTermsPublishDate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGeneralTermsPublishDate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralTermsPublishDate$Plain(params?: GetGeneralTermsPublishDate$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getGeneralTermsPublishDate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGeneralTermsPublishDate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralTermsPublishDate$Response(params?: GetGeneralTermsPublishDate$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getGeneralTermsPublishDate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGeneralTermsPublishDate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralTermsPublishDate(params?: GetGeneralTermsPublishDate$Params, context?: HttpContext): Observable<string> {
    return this.getGeneralTermsPublishDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
