/* tslint:disable */
/* eslint-disable */
export enum CartStatus {
  Active = 0,
  Deleted = 1,
  Published = 2,
  Archived = 3,
  Pending = 4,
  Purchased = 5,
  Rejected = 6,
  Approved = 7,
  PunchedOut = 9,
  Saved = 10,
  PreApproved = 11,
  OrderSent = 12,
  OrderCreated = 13,
  MibpPaymentPending = 20,
  MibpPaymentFailed = 30
}
