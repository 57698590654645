import { MySandvikNavigationItem } from "../navigation-types";

export const NAVITEMS_HELP: MySandvikNavigationItem =
{
  resourceKey: 'Header_SupportMenu_Help',
  uniqueId: 'help',
  sandvikIconName: 'help',
  route: {path: ['help']},
  permissionPolicy: {
    shouldBeRegistered: true
  },
  children: [
    {
      resourceKey: 'Header_SupportMenu_Help_General',
      route: {path: ['topics'], exactMatch: true }
    },
    // {
    //   resourceKey: 'Header_SupportMenu_Introduction',
    //   route: {path: ['introduction']}
    // },
    {
      resourceKey: 'Header_SupportMenu_FAQ',
      route: {path: ['faq']}
    },
    {
      resourceKey: 'ContactUs_Title',
      route: {path: ['contact']}
    },
    {
      resourceKey: 'InteroperabilityPolicy_Title',
      route: { path: ['interoperability-policy'] }
    },
    {
      uniqueId: 'changelog',
      resourceKey: 'General_Changelog_Title',
      route: { path: ['changelog'] }
    },
  ]
};



