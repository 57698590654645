  <div class="status status--active" *ngIf="globalConfig.activeEquipmentStatuses.includes(status)">
    <span translate="no" class="material-icon material-icon--fill">check_circle</span>
    <mibp-resource-string key="MyFleet_Active"></mibp-resource-string>
  </div>

  <div class="status status--onorder" *ngIf="globalConfig.onOrderEquipmentStatuses.includes(status)">
    <span translate="no" class="material-icon material-icon--fill">check_circle</span>
    <mibp-resource-string key="MyFleet_OnOrder"></mibp-resource-string>
  </div>

  <div class="status status--inactive" *ngIf="globalConfig.inActiveEquipmentStatuses.includes(status)">
    <span translate="no" class="material-icon material-icon--fill">error</span>
    <mibp-resource-string key="MyFleet_InActive"></mibp-resource-string>
  </div>

  <div class="status status--decommisioned" *ngIf="globalConfig.decommisionedEquipmentStatuses.includes(status)">
    <span translate="no" class="material-icon material-icon--fill">error</span>
    <mibp-resource-string key="MyFleet_Decommissioned"></mibp-resource-string>
  </div>



