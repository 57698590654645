/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AddDownloadManualsJobCommandResultVm } from '../../models/add-download-manuals-job-command-result-vm';

export interface AddDownloadManualJob$Params {
  mediaIdentifier: string;
  addUserDownload?: boolean;
  addJobEvenIfDownloaded?: boolean;
  equipmentId?: number;
}

export function addDownloadManualJob(http: HttpClient, rootUrl: string, params: AddDownloadManualJob$Params, context?: HttpContext): Observable<StrictHttpResponse<AddDownloadManualsJobCommandResultVm>> {
  const rb = new RequestBuilder(rootUrl, addDownloadManualJob.PATH, 'post');
  if (params) {
    rb.path('mediaIdentifier', params.mediaIdentifier, {});
    rb.query('addUserDownload', params.addUserDownload, {});
    rb.query('addJobEvenIfDownloaded', params.addJobEvenIfDownloaded, {});
    rb.query('equipmentId', params.equipmentId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AddDownloadManualsJobCommandResultVm>;
    })
  );
}

addDownloadManualJob.PATH = '/operations/documoto/jobs/downloaded-manuals/process/add/{mediaIdentifier}';
