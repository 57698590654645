<div class="tabbed-module active"
[class.tabbed-module--compact]="compactLayout"
[class.tabbed-module--plain]="tabStyle === 'plain'"
[class.tabbed-module--subnavigation]="tabStyle === 'subnavigation'"
[class.tabbed-module-bordered]="bordered" [style.marginTop]="noMargin ? '0px' : null" [style.marginBottom]="noMargin ? '0px' : null">
  <div class="tab-navigation">
    <ul class="tabs cf" role="tablist">
      <li *ngFor="let tab of visibleTabs; let ix = index;"
        [class.tab-selected]="(hasChildTabComponents && activeTabIndex === ix) || rlax.isActive || tab.active" [class.first]="ix === 0"
        [class.last]="ix === visibleTabs.length -1"
        role="presentation" #rlax="routerLinkActive" routerLinkActive [routerLinkActiveOptions]="tab.route?.isActiveMatchOptions || tab.route?.routerLinkActiveOptions || {}">
        <a *ngIf="tab.route" [routerLink]="tab.route.path" (click)="clickTab()" role="tab" routerLinkActive="activeyo" #rlax="routerLinkActive"
        [attr.data-gtm]="tab.googleTagManagerDataAttributeValue" [class.bg-secondary]="tabBgColor === 'secondary'">
          <mibp-resource-string *ngIf="tab.resourceKey" [key]="tab.resourceKey"></mibp-resource-string>
          <span *ngIf="tab.text">{{ tab.text }}</span>
          <span *ngIf="tab.counterValue != null"> ({{ tab.counterValue }})</span>
        </a>
        <a *ngIf="!tab.route" href="#" role="tab" (click)="selectTab($event, ix)" id="tabnav-0" tabindex="0" [class.bg-secondary]="tabBgColor === 'secondary'">
          <mibp-resource-string *ngIf="tab.resourceKey" [key]="tab.resourceKey"></mibp-resource-string>
          <span *ngIf="tab.text">{{ tab.text }}</span>
          <span *ngIf="tab.counterValue != null"> ({{ tab.counterValue }})</span>
        </a>
      </li>
    </ul>
  </div>
  <div [class.tab-content]="bordered">
    <ng-content></ng-content>
  </div>
</div>
