<mibp-dialog [buttons]="buttons" [hideCloseButton]="true" (buttonClick)="onButtonClick($event)" [orangeTitle]="true" titleResourceStringKey="General_Error_InlineTitle">

  <div class="my-body">
    <mibp-resource-string key="Global_Error_UnhandledError_Message" [isHtml]="true"></mibp-resource-string>

    <textarea readonly aria-readonly="true">{{ errorDetails }}</textarea>
  </div>

</mibp-dialog>

<ng-template #mibpLogExceptionDialog>
  <div class="my-unhandled-exception-dialog">
    <div>
      <img style="margin-bottom: 30px;" src="assets/images/logo_black.svg" width="130" alt="[Sandvik Logotype]">
      <h3 class="my-header"><mibp-resource-string key="General_Error_InlineTitle"></mibp-resource-string></h3>
      <p class="newdescription"><mibp-resource-string key="Global_Error_UnhandledError_NewDescription"></mibp-resource-string></p>
      <p class="correlationmessage"><mibp-resource-string key="Global_Error_UnhandledError_CorrelationIdMessage"></mibp-resource-string></p>
      <p class="clientid" *ngIf="!isSupportTeamMember">{{clientId}}</p>
      <p class="clientid" *ngIf="isSupportTeamMember"><a title="Find this error in the logs" (click)="findCorrelationId(clientId)" href="javascript:void(0)">{{clientId}}</a></p>

      <button style="margin-top: 50px;" class="my-button my-button--fill btn-min-widht" (click)="onButtonClick(this)"><mibp-resource-string key="AppLoading_ReloadPage_Action"></mibp-resource-string></button>
    </div>
  </div>
</ng-template>
