/* tslint:disable */
/* eslint-disable */
export enum BusinessRelationPermission {
  None = 0,
  ViewUndergroundReports = 1,
  ViewSurfaceReports = 2,
  ViewCrushingAndScreeningReports = 4,
  ViewProductivityReportsForTrucks = 16,
  ViewProductivityReportsForLoaders = 32,
  ViewProductivityReportForUgDrills = 64,
  ViewTrackReports = 128,
  WarrantyPortal = 256
}
