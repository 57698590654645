import { allPermissionPolicies } from "root/all-permission-policies";
import { MySandvikNavigationItem } from "../navigation-types";

export const NAVITEMS_REPORTS: MySandvikNavigationItem = {
  resourceKey: 'Reports_Title',
  sandvikIconName: 'contract_edit',
  showIfAnyChildren: true,
  route: {
    exactMatch: true,
    path: ['reports']
  },
  children: [
    {
      resourceKey: 'Reports_Crushing_And_Screening',
      permissionPolicy: allPermissionPolicies.canSeeCrushingAndScreeningReports,
      route: { path: ['crushingandscreening'] },
      hideBreadCrumb: true
    },
    {
      resourceKey: 'Reports_Track',
      permissionPolicy: allPermissionPolicies.canSeeTrackReports,
      route: { path: ['rtms'] },
      hideBreadCrumb: true
    }
  ]
};
