import { ValidatorFn, AbstractControl } from "@angular/forms";

/**
 * Will make sure the field value is not just whitespace
 */
export function MibpNoWhitespaceValidator(showAsRequired = true): ValidatorFn {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value;
    if (value && typeof value === 'string' && value.length > 0) {
      if (value.match(/^\s+$/)) {

        if (!showAsRequired) {
          return {
            // Different validation code if field is not required, but we don't want to allow only white-space characters
            noWhitespace: true
          };
        } else {
          return {
            required: true
          };
        }
      }
    }
  };

}

