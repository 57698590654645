import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";

import { DialogComponent } from '../dialog/dialog.component';
import { DialogButton } from '../dialog/dialog.types';
import { ButtonColors } from '../button/button.enum';
import { ClientSideCacheService, CacheScope } from '../../services/client-side-cache/client-side-cache.service';
import { LocalizationService } from '../../services/localization/localization.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'mibp-browser-update-notice',
  templateUrl: './browser-update-notice.component.html',
  styleUrls: ['./browser-update-notice.component.global.scss']
})
export class MibpBrowserUpdateNoticeComponent implements OnInit, AfterViewInit, OnDestroy {
  stopUsingResource: () => void;
  messageHtml: string;
  isVisible = false;
  @ViewChild(DialogComponent) dialog: DialogComponent;
  dialogButtons: DialogButton[];

  constructor(private localizationService: LocalizationService, private cache: ClientSideCacheService) {}

  ngOnInit(): void {

    this.stopUsingResource = this.localizationService.using(['Global_Notice_UseNewerBrowser_Text'], resourceStrings => {
      this.messageHtml = resourceStrings[0];
    });

    this.dialogButtons = [
      {id: 'close', resourceKey: 'Global_Dialog_Close', color: ButtonColors.Orange}
    ];

  }

  onDialogClosed(): void {
    document.body.classList.remove('browser-notice-dialog');
    this.cache.add('browser_dialog_seen', true, null, CacheScope.UserSessionStorage);
  }

  onDialogButton(): void {
    this.dialog.close();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      if (this.isOldBrowser()) {
        // const hasClosedNoticeBefore = this.cache.get<boolean>('browser_dialog_seen') === true;
        // document.body.classList.add('browser-notice-visible');
        this.isVisible = true;
        // if (!hasClosedNoticeBefore) {
        //   this.dialog.open();
        // }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.stopUsingResource) {
      this.stopUsingResource();
    }
  }

  onDialogOpen(): void {
    document.body.classList.add('browser-notice-dialog');
  }

  isOldBrowser(): boolean {
    const ieVersion = this.getCurrentIEVersion();
    if (ieVersion !== false) {

      // Do not show if we've logged in using SSO (Punchout)
      // Since they do not have control over the browser used in their ERP systems
      if (this.cache.get('sso-disable-browser-notice') === 'true') {
        return false;
      }
      return true;
    }
  }

  getCurrentIEVersion(): number | boolean {
    const userAgent = window.navigator.userAgent;

    const msie = userAgent.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10);
    }

    const trident = userAgent.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = userAgent.indexOf('rv:');
      return parseInt(userAgent.substring(rv + 3, userAgent.indexOf('.', rv)), 10);
    }

    const ieBasedEdge = userAgent.indexOf('Edge/');
    if (ieBasedEdge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(userAgent.substring(ieBasedEdge + 5, userAgent.indexOf('.', ieBasedEdge)), 10);
    }

    // other browser
    return false;
  }



}
