<footer class="my-footer">

  <section class="my-footer__basics">

    <div class="logotype">
      <img src="./assets/images/logo_black.svg" alt="Sandvik Logotype">
    </div>

    <div class="paragraph">
      <mibp-resource-string key="Footer_Paragraph" isHtml="true"></mibp-resource-string>
    </div>

    <div class="copyright">
      <mibp-resource-string key="Footer_Copyright" isHtml="true"></mibp-resource-string>
    </div>

  </section>

  <section  class="my-footer__spring"></section>

  <section class="my-footer__information">
    <h2>
      <span translate="no" class="material-icon material-icon--xlarge footericon--margin">
        newsstand
      </span>
      <mibp-resource-string key="Footer_Information"></mibp-resource-string>
    </h2>
    <ul>
      <li><a *ngIf="isLoggedIn" [ecommerceRouterLink]="['/:lang/help/interoperability-policy']"><mibp-resource-string key="InteroperabilityPolicy_Title"></mibp-resource-string></a></li>
      <li><a href="https://www.rocktechnology.sandvik/{{lang}}/about-this-site/data-privacy/"><mibp-resource-string key="Footer_LinkTexts_Privacy"></mibp-resource-string></a></li>
      <li><a href="https://www.rocktechnology.sandvik/{{lang}}/about-this-site/cookies/"><mibp-resource-string key="Footer_LinkTexts_Cookies"></mibp-resource-string></a></li>
      <li><a *ngIf="isLoggedIn" href="javascript:void(0)" (click)="showTermsAndConditions()"><mibp-resource-string key="Footer_LinkTexts_TermsAndConditions"></mibp-resource-string></a></li>
    </ul>

  </section>

  <section class="my-footer__account" *ngIf="isLoggedIn">
    <h2>
      <span translate="no" class="material-icon material-icon--xlarge footericon--margin">
        person_outline
      </span>
      <mibp-resource-string key="MyAccount_Title"></mibp-resource-string>
    </h2>
    <ul>
      <li><a [ecommerceRouterLink]="['/:lang/user/myprofile']"><mibp-resource-string key="Users_MyProfile_Tab_Title"></mibp-resource-string></a></li>
      <li><a href="javascript:void(0)" (click)="logout()"><mibp-resource-string key="Global_Logout"></mibp-resource-string></a></li>
    </ul>
  </section>

  <section class="my-footer__followus">
    <h2>
      <span translate="no" class="material-icon material-icon--xlarge  footericon--margin">
        share
      </span>
      <mibp-resource-string key="Footer_FollowUs"></mibp-resource-string>
    </h2>
    <ul>
      <li><a href="https://www.linkedin.com/showcase/sandvik-mining-and-rock-technology/">Linkedin</a></li>
      <li><a href="https://www.youtube.com/channel/UCHlMm2mDjS20mFQNWF_vWTw">Youtube</a></li>
      <li><a href="https://www.instagram.com/sandvikminingandrocktechnology/">Instagram</a></li>
      <li><a href="https://twitter.com/sandvik_mining">Twitter</a></li>
      <li><a href="https://www.facebook.com/SandvikMining">Facebook</a></li>
    </ul>
  </section>

  <section class="my-footer__support">
    <h2>
       <span translate="no" class="material-icon material-icon--xlarge footericon--margin">
        chat_bubble_outline
      </span>
      <mibp-resource-string key="Footer_Support"></mibp-resource-string>
    </h2>
    <ul>
      <li><a href="#" (click)="gotoContactUs($event)"><mibp-resource-string key="ContactUs_Title"></mibp-resource-string></a></li>
      <li><a href="https://www.rocktechnology.sandvik/{{lang}}/sitemap"><mibp-resource-string key="Footer_LinkTexts_Sitemap"></mibp-resource-string></a></li>
    </ul>
  </section>


</footer>
