<ng-template #AvailabilityErrorTemplate>

  <div *ngIf="availabilityError?.errorType === 'stockroom_or_company'" class="text-error text-error--nocaps">
    <mibp-resource-string class="item-error" key="Carts_Error_StockroomOrCompanyNotSetup" ></mibp-resource-string>
    <a href="javascript:void(0)" class="contact-us" (click)="clickedAvailabilityLink()">
      <mibp-resource-string key="Global_Carts_RequestAvailability"></mibp-resource-string>
    </a>
  </div>
  <div *ngIf="availabilityError?.errorType === 'unknown'">
    <mibp-resource-string class="item-error" key="Carts_ErrorGettingAvailability" ></mibp-resource-string>
  </div>
</ng-template>

<div class="product-availability" *ngIf="userCanSeeAvailability && isAuroraCompany">
  <div *ngIf="!hasAvailabilityError; else AvailabilityErrorTemplate">
    <mibp-loader type='spinner' *ngIf="userCanSeeAvailability && isLoading && !isSubscription"></mibp-loader>

    <div *ngIf="!isSubscription && showAvailability && availability && !hasAvailabilityError && userCanSeeAvailability && !isLoading">
      <span class="availability" (click)="availabilityPopover.open($event.target)" [attr.title]="clickForDetailsText">
        <span class="local" [class]="localAvailabilityClass">L</span>
        <span class="national" [class]="nationalAvailabilityClass">N</span>
        <span class="global" [class]="globalAvailabilityClass">G</span>
      </span>
      <span translate="no" class="material-icon material-icon--blue" (click)="stockroomDetails?.length > 0 ? availabilityDescripionPopover.open($event.target) : availabilityPopover.open($event.target)">help</span>
    </div>
    <span>
      <a href="javascript:void(0)" class="contact-us" (click)="clickedAvailabilityLink()" *ngIf="!isSubscription && !isLoading && (!hasAvailabilityError && showContactInfo) || !userCanSeeAvailability">

        <mibp-resource-string key="Global_Carts_RequestAvailability"></mibp-resource-string>
      </a>
      <span *ngIf="isSubscription">
        <!-- not used at all today <span class="digital-product available"><fa-icon [icon]="faCheck"></fa-icon></span> -->
        <mibp-resource-string key="Global_DigitalProduct"></mibp-resource-string>
      </span>
    </span>
  </div>
</div>
<div *ngIf="userCanSeeAvailability && !isAuroraCompany">
  <a href="javascript:void(0)" class="contact-us" (click)="clickedAvailabilityLink()">
    <mibp-resource-string key="Global_Carts_RequestAvailability"></mibp-resource-string>
  </a>
</div>

<mibp-popover #availabilityPopover>
  <div class="availability-popover">
    @if (showStockroomDetails && stockroomDetails?.length > 0) {
      <div class="stockroom-details light-scroll">
        @for (a of stockroomDetails; track a.stockroomResourceStringKey) {
          <div class="stockroom-details__label">
            <mibp-resource-string [key]="a.stockroomResourceStringKey"></mibp-resource-string>: {{ a.availableQuantity }}
          </div>
          @for (room of a.stockrooms; track room.name) {
            <div class="stockroom-details__stockroom">
              {{room.name}}: {{room.availableQuantity}}
            </div>
          }
        }
      </div>

    } @else {
      <div class="availability-popover--element">
        <span class="availability-popover--header"><mibp-resource-string key="Carts_Availability_Local"></mibp-resource-string>: {{ availability?.local }}&nbsp;<mibp-resource-string key="Global_Items"></mibp-resource-string></span>
        <span [innerHTML]="localAvailabilityDescription"></span>
      </div>
      <div class="availability-popover--element">
        <span class="availability-popover--header"><mibp-resource-string key="Carts_Availability_National"></mibp-resource-string>: {{ availability?.national }}&nbsp;<mibp-resource-string key="Global_Items"></mibp-resource-string></span>
        <span [innerHTML]="nationalAvailabilityDescription"></span>
      </div>
      <div class="availability-popover--element">
        <span class="availability-popover--header"><mibp-resource-string key="Carts_Availability_Global"></mibp-resource-string>: {{ availability?.global }}&nbsp;<mibp-resource-string key="Global_Items"></mibp-resource-string></span>
        <span [innerHTML]="globalAvailabilityDescription"></span>
      </div>
    }
  </div>
</mibp-popover>

<mibp-popover #availabilityDescripionPopover>
  <div class="availability-popover">
      <div class="availability-popover--element">
        <span class="availability-popover--header"><mibp-resource-string key="Carts_Availability_Local"></mibp-resource-string></span>
        <span [innerHTML]="localAvailabilityDescription"></span>
      </div>
      <div class="availability-popover--element">
        <span class="availability-popover--header"><mibp-resource-string key="Carts_Availability_National"></mibp-resource-string></span>
        <span [innerHTML]="nationalAvailabilityDescription"></span>
      </div>
      <div class="availability-popover--element">
        <span class="availability-popover--header"><mibp-resource-string key="Carts_Availability_Global"></mibp-resource-string></span>
        <span [innerHTML]="globalAvailabilityDescription"></span>
      </div>
  </div>
</mibp-popover>
