import { OfflineComponent } from './components/offline-page/offline-page.component';
import { MibpOnboardingService } from './components/onboarding/onboarding.service';
import { QuillModule } from 'ngx-quill';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StartupService } from './services/startup/startup.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { GlobalSharedModule } from './modules/global-shared.module';
import { EcommerceAppComponent } from './ecommerce-app.component';
import { VariationRootPageComponent, ApplicationRootPageComponent, ErrorUnauthorizedPageComponent } from './pages';
import {
  AnimatedBarLoaderComponent,
  Error404Component,
  FooterComponent,
  RouterLoadingComponent,
  ResourceStringEditorToolbarComponent,
  MibpBrowserUpdateNoticeComponent,
  MibpToastComponent,
} from 'root/components';
import {SessionTimeoutComponent} from './components/session-timeout/session-timeout.component';
import {
  LocalizationService, ScrollToService, ClientSideCacheService, AuthService,
  SignalRService, ApiService, MibpErrorHandler,
  ResourceStringEditorService, FormattingService, FormValidationService, ContactUsService, ResponsibilityPickerService,
  LogService,
  PartsCatalogueService,
  LoaderService,
  MibpHttpApi,
  UrlHelperService,
  StartupLanguageService,
  StartupAuthenticationService,
  StartupBackendService,
  StartupLocalizationService,
  //StartupUserEventService,
  StartupUserStatusService,
  StartupUserPermissionsService,
  MibpGridService,
  ArrayHelperService,
  PermissionService,
  BroadcastService,
  InlineEditingOverlayService,
  GlobalConfigService,
  SignalR_GlobalConfig,
  ClientIdService,
  MibpResponsiveService,
  MibpDOMHelperService,
  TermsAndConditionsService,
  MibpHtmlHelperService,
  ScriptService,
  ToastService
} from 'root/services';
import { MibpOperationsService } from './services/operations/operations.service';
//import { TopbarComponent } from './components/topbar/topbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FrontendContextService } from './services/front-end-context/front-end-context.service';
import { ClientSideCacheMemoryStorageService } from './services/client-side-cache/memoryStorage';
import { StartupGuardService } from './guards';
import { APP_INITIALIZER } from '@angular/core';
import { ApplicationStateService } from './services/application-state/application-state.service';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'root/environment';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { AuthInterceptor } from './services/auth-service/auth.interceptor';
import { UnhandledExceptionDialogComponent } from './components/unhandled-exception-dialog/unhandled-exception-dialog.component';
import { PopupContainerInternalService } from './components/popup-container/popup-container-internal.service';
import { MibpInternalUserPhotoService } from './components/navigation';
import { SidebarService } from './services/sidebar-service/sidebar.service';
import { GoogleTagManagerService } from './services/google-tag-manager-service/google-tag-manager.service';
import { MibpFeatureTourService } from './components/feature-tour/feature-tour.service';
import { MyfleetEquipmentStatusDescriptionService } from 'src/app/modules/home/modules/cart/pages/myfleet/myfleet-equipment-status-desc/myfleet-equipment-status-desc.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MibpRestApiModule } from './mibp-openapi-gen/mibp-rest-api.module';
import { OperationSiteService } from './services/operation-sites/operation-sites.service';
import { ApiErrorHandlerService } from './services/api-error-handler/api-error-handler';
import { MibpSessionService } from './services/mibp-session/mibp-session.service';
import { MibpAppLoaderComponent } from './components/app-loader/app-loader.component';
import { DocumotoAuthService } from './services/auth-service/documoto-auth.service';
import { MibpDateTimeService } from './services/datetime/datetime.service';
import { ToastrModule } from 'ngx-toastr';
import { DownloadedManualsService } from './services/downloaded-manuals/downloaded-manuals.service';
import { EncryptionService } from './services/encryption/encryption-service';

export function loadGlobalConfig(appConfigService: GlobalConfigService) {
  return (): Promise<SignalR_GlobalConfig> => appConfigService.load();
}

export function loggerCallback(logLevel: LogLevel, message: string): void {
  if (!environment.production) {
    // eslint-disable-next-line no-console
    /// console.log("msal", message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.auth.b2c.clientId,
      knownAuthorities: environment.auth.b2c.knownAuthorities,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      authority: `${environment.auth.b2c.authorityBaseUrl}${environment.auth.b2c.policies.signUpSignIn}`
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    GlobalSharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {
    useHash: false
}),
    MsalModule,
    QuillModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MibpRestApiModule.forRoot({}),
    ToastrModule.forRoot({toastComponent: MibpToastComponent})
  ],
  declarations: [
    EcommerceAppComponent,
    ApplicationRootPageComponent,
    VariationRootPageComponent,
    ErrorUnauthorizedPageComponent,
    FooterComponent,
    Error404Component,
    AnimatedBarLoaderComponent,
    RouterLoadingComponent,
    //TopbarComponent,
    ResourceStringEditorToolbarComponent,
    MibpBrowserUpdateNoticeComponent,
    SessionTimeoutComponent,
    UnhandledExceptionDialogComponent,
    OfflineComponent,
    MibpAppLoaderComponent,
    MibpToastComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MibpToastComponent
  ],
  providers: [
    DownloadedManualsService,
    LocalizationService,
    ScrollToService,
    AuthService,
    DocumotoAuthService,
    GoogleTagManagerService,
    StartupGuardService,
    ClientSideCacheService,
    SignalRService,
    ApiService,
    FrontendContextService,
    ResourceStringEditorService,
    FormattingService,
    {provide: ErrorHandler, useClass: MibpErrorHandler},
    ClientSideCacheMemoryStorageService,
    FormValidationService,
    StartupService,
    StartupLanguageService,
    StartupAuthenticationService,
    StartupBackendService,
    StartupLocalizationService,
   // StartupUserEventService,
    StartupUserStatusService,
    StartupUserPermissionsService,
    ContactUsService,
    SidebarService,
    { provide: "windowObject", useValue: window},
    ResponsibilityPickerService,
    LogService,
    PartsCatalogueService,
    LoaderService,
    MibpHttpApi,
    UrlHelperService,
    MibpGridService,
    ArrayHelperService,
    PermissionService,
    BroadcastService,
    InlineEditingOverlayService,
    ApplicationStateService,
    PopupContainerInternalService,
    MibpInternalUserPhotoService,
    MibpResponsiveService,
    MibpOperationsService,
    ToastService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadGlobalConfig,
      multi: true,
      deps: [
        GlobalConfigService,
        // AuthService,
        // ClientIdService,
        // BroadcastService,
        // SessionApiController,
        // ApiConfiguration,
        // MibpSessionService,
        // TermsAndConditionsService,
        // LogService,
        // ApplicationStateService

      ]
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor, multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ClientIdService,
    MibpOnboardingService,
    MibpFeatureTourService,
    MyfleetEquipmentStatusDescriptionService,
    MibpDOMHelperService,
    OperationSiteService,
    ApiErrorHandlerService,
    MibpSessionService,
    MibpHtmlHelperService,
    MibpDateTimeService,
    EncryptionService,
    ScriptService
  ],
  bootstrap: [EcommerceAppComponent, MsalRedirectComponent]
})
export class AppModule { }
