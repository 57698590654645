/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getErrorSummary } from '../fn/mibp-log/get-error-summary';
import { GetErrorSummary$Params } from '../fn/mibp-log/get-error-summary';
import { getErrorSummary$Plain } from '../fn/mibp-log/get-error-summary-plain';
import { GetErrorSummary$Plain$Params } from '../fn/mibp-log/get-error-summary-plain';
import { getIntegratioLogStats } from '../fn/mibp-log/get-integratio-log-stats';
import { GetIntegratioLogStats$Params } from '../fn/mibp-log/get-integratio-log-stats';
import { getIntegratioLogStats$Plain } from '../fn/mibp-log/get-integratio-log-stats-plain';
import { GetIntegratioLogStats$Plain$Params } from '../fn/mibp-log/get-integratio-log-stats-plain';
import { getIntegrationLogDateStats } from '../fn/mibp-log/get-integration-log-date-stats';
import { GetIntegrationLogDateStats$Params } from '../fn/mibp-log/get-integration-log-date-stats';
import { getIntegrationLogDateStats$Plain } from '../fn/mibp-log/get-integration-log-date-stats-plain';
import { GetIntegrationLogDateStats$Plain$Params } from '../fn/mibp-log/get-integration-log-date-stats-plain';
import { getIntegrationlogErrors } from '../fn/mibp-log/get-integrationlog-errors';
import { GetIntegrationlogErrors$Params } from '../fn/mibp-log/get-integrationlog-errors';
import { getIntegrationlogErrors$Plain } from '../fn/mibp-log/get-integrationlog-errors-plain';
import { GetIntegrationlogErrors$Plain$Params } from '../fn/mibp-log/get-integrationlog-errors-plain';
import { getIntegrationlogMessageTypes } from '../fn/mibp-log/get-integrationlog-message-types';
import { GetIntegrationlogMessageTypes$Params } from '../fn/mibp-log/get-integrationlog-message-types';
import { getIntegrationlogMessageTypes$Plain } from '../fn/mibp-log/get-integrationlog-message-types-plain';
import { GetIntegrationlogMessageTypes$Plain$Params } from '../fn/mibp-log/get-integrationlog-message-types-plain';
import { getIntegrationlogTimings } from '../fn/mibp-log/get-integrationlog-timings';
import { GetIntegrationlogTimings$Params } from '../fn/mibp-log/get-integrationlog-timings';
import { getIntegrationlogTimings$Plain } from '../fn/mibp-log/get-integrationlog-timings-plain';
import { GetIntegrationlogTimings$Plain$Params } from '../fn/mibp-log/get-integrationlog-timings-plain';
import { getLoggedFieldValues } from '../fn/mibp-log/get-logged-field-values';
import { GetLoggedFieldValues$Params } from '../fn/mibp-log/get-logged-field-values';
import { getLoggedFieldValues$Plain } from '../fn/mibp-log/get-logged-field-values-plain';
import { GetLoggedFieldValues$Plain$Params } from '../fn/mibp-log/get-logged-field-values-plain';
import { getLogRowProperties } from '../fn/mibp-log/get-log-row-properties';
import { GetLogRowProperties$Params } from '../fn/mibp-log/get-log-row-properties';
import { getLogRowProperties$Plain } from '../fn/mibp-log/get-log-row-properties-plain';
import { GetLogRowProperties$Plain$Params } from '../fn/mibp-log/get-log-row-properties-plain';
import { getLogTags } from '../fn/mibp-log/get-log-tags';
import { GetLogTags$Params } from '../fn/mibp-log/get-log-tags';
import { getLogTags$Plain } from '../fn/mibp-log/get-log-tags-plain';
import { GetLogTags$Plain$Params } from '../fn/mibp-log/get-log-tags-plain';
import { getMibpLogAttachmentContent } from '../fn/mibp-log/get-mibp-log-attachment-content';
import { GetMibpLogAttachmentContent$Params } from '../fn/mibp-log/get-mibp-log-attachment-content';
import { getMibpLogAttachmentContent$Plain } from '../fn/mibp-log/get-mibp-log-attachment-content-plain';
import { GetMibpLogAttachmentContent$Plain$Params } from '../fn/mibp-log/get-mibp-log-attachment-content-plain';
import { IntegrationLogDateSummaryItemVm } from '../models/integration-log-date-summary-item-vm';
import { IntegrationLogErrorSummaryItemVm } from '../models/integration-log-error-summary-item-vm';
import { IntegrationLogMessageTypeVm } from '../models/integration-log-message-type-vm';
import { IntegrationLogSummaryItemVm } from '../models/integration-log-summary-item-vm';
import { IntegrationLogTimingSummaryItemVm } from '../models/integration-log-timing-summary-item-vm';
import { logFrontendError } from '../fn/mibp-log/log-frontend-error';
import { LogFrontendError$Params } from '../fn/mibp-log/log-frontend-error';
import { LookupResponseVm } from '../models/lookup-response-vm';
import { lookupUsers } from '../fn/mibp-log/lookup-users';
import { LookupUsers$Params } from '../fn/mibp-log/lookup-users';
import { lookupUsers$Plain } from '../fn/mibp-log/lookup-users-plain';
import { LookupUsers$Plain$Params } from '../fn/mibp-log/lookup-users-plain';
import { MibpLogErrorSummaryItemVm } from '../models/mibp-log-error-summary-item-vm';
import { MibpLogSearchResultVm } from '../models/mibp-log-search-result-vm';
import { prettifyAttachment } from '../fn/mibp-log/prettify-attachment';
import { PrettifyAttachment$Params } from '../fn/mibp-log/prettify-attachment';
import { prettifyAttachment$Plain } from '../fn/mibp-log/prettify-attachment-plain';
import { PrettifyAttachment$Plain$Params } from '../fn/mibp-log/prettify-attachment-plain';
import { PrettifyResult } from '../models/prettify-result';
import { searchLogs } from '../fn/mibp-log/search-logs';
import { SearchLogs$Params } from '../fn/mibp-log/search-logs';
import { searchLogs$Plain } from '../fn/mibp-log/search-logs-plain';
import { SearchLogs$Plain$Params } from '../fn/mibp-log/search-logs-plain';

@Injectable({ providedIn: 'root' })
export class MibpLogApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mibpLogControllerGetIntegratioLogStatsfromToCompanyCodeMessageType()` */
  static readonly MibpLogControllerGetIntegratioLogStatsfromToCompanyCodeMessageTypePath = '/mibplog/integrationlog/stats';

  /**
   * Get everything for given dates - log dates and companies etc etc.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegratioLogStats$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegratioLogStats$Plain$Response(params?: GetIntegratioLogStats$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogSummaryItemVm>>> {
    return getIntegratioLogStats$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get everything for given dates - log dates and companies etc etc.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegratioLogStats$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegratioLogStats$Plain(params?: GetIntegratioLogStats$Plain$Params, context?: HttpContext): Observable<Array<IntegrationLogSummaryItemVm>> {
    return this.getIntegratioLogStats$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogSummaryItemVm>>): Array<IntegrationLogSummaryItemVm> => r.body)
    );
  }

  /**
   * Get everything for given dates - log dates and companies etc etc.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegratioLogStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegratioLogStats$Response(params?: GetIntegratioLogStats$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogSummaryItemVm>>> {
    return getIntegratioLogStats(this.http, this.rootUrl, params, context);
  }

  /**
   * Get everything for given dates - log dates and companies etc etc.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegratioLogStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegratioLogStats(params?: GetIntegratioLogStats$Params, context?: HttpContext): Observable<Array<IntegrationLogSummaryItemVm>> {
    return this.getIntegratioLogStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogSummaryItemVm>>): Array<IntegrationLogSummaryItemVm> => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerGetIntegrationLogDateStatsfromToCompanyCodeMessageType()` */
  static readonly MibpLogControllerGetIntegrationLogDateStatsfromToCompanyCodeMessageTypePath = '/mibplog/integrationlog/stats/dates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationLogDateStats$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationLogDateStats$Plain$Response(params?: GetIntegrationLogDateStats$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogDateSummaryItemVm>>> {
    return getIntegrationLogDateStats$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationLogDateStats$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationLogDateStats$Plain(params?: GetIntegrationLogDateStats$Plain$Params, context?: HttpContext): Observable<Array<IntegrationLogDateSummaryItemVm>> {
    return this.getIntegrationLogDateStats$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogDateSummaryItemVm>>): Array<IntegrationLogDateSummaryItemVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationLogDateStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationLogDateStats$Response(params?: GetIntegrationLogDateStats$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogDateSummaryItemVm>>> {
    return getIntegrationLogDateStats(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationLogDateStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationLogDateStats(params?: GetIntegrationLogDateStats$Params, context?: HttpContext): Observable<Array<IntegrationLogDateSummaryItemVm>> {
    return this.getIntegrationLogDateStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogDateSummaryItemVm>>): Array<IntegrationLogDateSummaryItemVm> => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerGetIntegrationlogTimingsfromToCompanyCodeMessageType()` */
  static readonly MibpLogControllerGetIntegrationlogTimingsfromToCompanyCodeMessageTypePath = '/mibplog/integrationlog/timings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationlogTimings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogTimings$Plain$Response(params?: GetIntegrationlogTimings$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogTimingSummaryItemVm>>> {
    return getIntegrationlogTimings$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationlogTimings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogTimings$Plain(params?: GetIntegrationlogTimings$Plain$Params, context?: HttpContext): Observable<Array<IntegrationLogTimingSummaryItemVm>> {
    return this.getIntegrationlogTimings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogTimingSummaryItemVm>>): Array<IntegrationLogTimingSummaryItemVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationlogTimings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogTimings$Response(params?: GetIntegrationlogTimings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogTimingSummaryItemVm>>> {
    return getIntegrationlogTimings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationlogTimings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogTimings(params?: GetIntegrationlogTimings$Params, context?: HttpContext): Observable<Array<IntegrationLogTimingSummaryItemVm>> {
    return this.getIntegrationlogTimings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogTimingSummaryItemVm>>): Array<IntegrationLogTimingSummaryItemVm> => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerGetIntegrationlogMessageTypes()` */
  static readonly MibpLogControllerGetIntegrationlogMessageTypesPath = '/mibplog/integrationlog/messagetypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationlogMessageTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogMessageTypes$Plain$Response(params?: GetIntegrationlogMessageTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogMessageTypeVm>>> {
    return getIntegrationlogMessageTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationlogMessageTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogMessageTypes$Plain(params?: GetIntegrationlogMessageTypes$Plain$Params, context?: HttpContext): Observable<Array<IntegrationLogMessageTypeVm>> {
    return this.getIntegrationlogMessageTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogMessageTypeVm>>): Array<IntegrationLogMessageTypeVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationlogMessageTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogMessageTypes$Response(params?: GetIntegrationlogMessageTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogMessageTypeVm>>> {
    return getIntegrationlogMessageTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationlogMessageTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogMessageTypes(params?: GetIntegrationlogMessageTypes$Params, context?: HttpContext): Observable<Array<IntegrationLogMessageTypeVm>> {
    return this.getIntegrationlogMessageTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogMessageTypeVm>>): Array<IntegrationLogMessageTypeVm> => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerGetIntegrationlogErrorsfromToCompanyCodeMessageType()` */
  static readonly MibpLogControllerGetIntegrationlogErrorsfromToCompanyCodeMessageTypePath = '/mibplog/integrationlog/errors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationlogErrors$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogErrors$Plain$Response(params?: GetIntegrationlogErrors$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogErrorSummaryItemVm>>> {
    return getIntegrationlogErrors$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationlogErrors$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogErrors$Plain(params?: GetIntegrationlogErrors$Plain$Params, context?: HttpContext): Observable<Array<IntegrationLogErrorSummaryItemVm>> {
    return this.getIntegrationlogErrors$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogErrorSummaryItemVm>>): Array<IntegrationLogErrorSummaryItemVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationlogErrors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogErrors$Response(params?: GetIntegrationlogErrors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IntegrationLogErrorSummaryItemVm>>> {
    return getIntegrationlogErrors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationlogErrors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationlogErrors(params?: GetIntegrationlogErrors$Params, context?: HttpContext): Observable<Array<IntegrationLogErrorSummaryItemVm>> {
    return this.getIntegrationlogErrors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IntegrationLogErrorSummaryItemVm>>): Array<IntegrationLogErrorSummaryItemVm> => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerGetErrorSummaryfromTo()` */
  static readonly MibpLogControllerGetErrorSummaryfromToPath = '/mibplog/logs/errors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErrorSummary$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorSummary$Plain$Response(params?: GetErrorSummary$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MibpLogErrorSummaryItemVm>>> {
    return getErrorSummary$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getErrorSummary$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorSummary$Plain(params?: GetErrorSummary$Plain$Params, context?: HttpContext): Observable<Array<MibpLogErrorSummaryItemVm>> {
    return this.getErrorSummary$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MibpLogErrorSummaryItemVm>>): Array<MibpLogErrorSummaryItemVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErrorSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorSummary$Response(params?: GetErrorSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MibpLogErrorSummaryItemVm>>> {
    return getErrorSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getErrorSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorSummary(params?: GetErrorSummary$Params, context?: HttpContext): Observable<Array<MibpLogErrorSummaryItemVm>> {
    return this.getErrorSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MibpLogErrorSummaryItemVm>>): Array<MibpLogErrorSummaryItemVm> => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerGetLogTagstypeFromToQuerySkipTake()` */
  static readonly MibpLogControllerGetLogTagstypeFromToQuerySkipTakePath = '/mibplog/tags/{type}';

  /**
   * Get tags that have been logged for the specific MibpLogType.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogTags$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogTags$Plain$Response(params: GetLogTags$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupResponseVm>> {
    return getLogTags$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tags that have been logged for the specific MibpLogType.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLogTags$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogTags$Plain(params: GetLogTags$Plain$Params, context?: HttpContext): Observable<LookupResponseVm> {
    return this.getLogTags$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupResponseVm>): LookupResponseVm => r.body)
    );
  }

  /**
   * Get tags that have been logged for the specific MibpLogType.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogTags$Response(params: GetLogTags$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupResponseVm>> {
    return getLogTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tags that have been logged for the specific MibpLogType.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLogTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogTags(params: GetLogTags$Params, context?: HttpContext): Observable<LookupResponseVm> {
    return this.getLogTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupResponseVm>): LookupResponseVm => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerLookupUsersuserIds()` */
  static readonly MibpLogControllerLookupUsersuserIdsPath = '/mibplog/users/lookup';

  /**
   * Get tags that have been logged for the specific MibpLogType.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupUsers$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  lookupUsers$Plain$Response(params?: LookupUsers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return lookupUsers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tags that have been logged for the specific MibpLogType.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupUsers$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  lookupUsers$Plain(params?: LookupUsers$Plain$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.lookupUsers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /**
   * Get tags that have been logged for the specific MibpLogType.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupUsers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  lookupUsers$Response(params?: LookupUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return lookupUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get tags that have been logged for the specific MibpLogType.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupUsers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  lookupUsers(params?: LookupUsers$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.lookupUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerGetLoggedFieldValuestypeFieldNameFromToQuerySkipTake()` */
  static readonly MibpLogControllerGetLoggedFieldValuestypeFieldNameFromToQuerySkipTakePath = '/mibplog/fieldvalues/{type}';

  /**
   * Get logged field values for the given field over the specified time.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoggedFieldValues$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoggedFieldValues$Plain$Response(params?: GetLoggedFieldValues$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupResponseVm>> {
    return getLoggedFieldValues$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get logged field values for the given field over the specified time.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLoggedFieldValues$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoggedFieldValues$Plain(params?: GetLoggedFieldValues$Plain$Params, context?: HttpContext): Observable<LookupResponseVm> {
    return this.getLoggedFieldValues$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupResponseVm>): LookupResponseVm => r.body)
    );
  }

  /**
   * Get logged field values for the given field over the specified time.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoggedFieldValues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoggedFieldValues$Response(params?: GetLoggedFieldValues$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupResponseVm>> {
    return getLoggedFieldValues(this.http, this.rootUrl, params, context);
  }

  /**
   * Get logged field values for the given field over the specified time.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLoggedFieldValues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoggedFieldValues(params?: GetLoggedFieldValues$Params, context?: HttpContext): Observable<LookupResponseVm> {
    return this.getLoggedFieldValues$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupResponseVm>): LookupResponseVm => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerGetLogRowPropertieslogTypeFromToQuerySkipTake()` */
  static readonly MibpLogControllerGetLogRowPropertieslogTypeFromToQuerySkipTakePath = '/mibplog/properties/{logType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogRowProperties$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogRowProperties$Plain$Response(params: GetLogRowProperties$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupResponseVm>> {
    return getLogRowProperties$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLogRowProperties$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogRowProperties$Plain(params: GetLogRowProperties$Plain$Params, context?: HttpContext): Observable<LookupResponseVm> {
    return this.getLogRowProperties$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupResponseVm>): LookupResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogRowProperties()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogRowProperties$Response(params: GetLogRowProperties$Params, context?: HttpContext): Observable<StrictHttpResponse<LookupResponseVm>> {
    return getLogRowProperties(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLogRowProperties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogRowProperties(params: GetLogRowProperties$Params, context?: HttpContext): Observable<LookupResponseVm> {
    return this.getLogRowProperties$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupResponseVm>): LookupResponseVm => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerSearchLogslogTypeRequest()` */
  static readonly MibpLogControllerSearchLogslogTypeRequestPath = '/mibplog/search/{logType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchLogs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchLogs$Plain$Response(params: SearchLogs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpLogSearchResultVm>> {
    return searchLogs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchLogs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchLogs$Plain(params: SearchLogs$Plain$Params, context?: HttpContext): Observable<MibpLogSearchResultVm> {
    return this.searchLogs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpLogSearchResultVm>): MibpLogSearchResultVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchLogs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchLogs$Response(params: SearchLogs$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpLogSearchResultVm>> {
    return searchLogs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchLogs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchLogs(params: SearchLogs$Params, context?: HttpContext): Observable<MibpLogSearchResultVm> {
    return this.searchLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpLogSearchResultVm>): MibpLogSearchResultVm => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerGetMibpLogAttachmentContentid()` */
  static readonly MibpLogControllerGetMibpLogAttachmentContentidPath = '/mibplog/attachments/{id}/content';

  /**
   * Get the raw string content of an attachment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMibpLogAttachmentContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpLogAttachmentContent$Plain$Response(params: GetMibpLogAttachmentContent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getMibpLogAttachmentContent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the raw string content of an attachment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMibpLogAttachmentContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpLogAttachmentContent$Plain(params: GetMibpLogAttachmentContent$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getMibpLogAttachmentContent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Get the raw string content of an attachment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMibpLogAttachmentContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpLogAttachmentContent$Response(params: GetMibpLogAttachmentContent$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getMibpLogAttachmentContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the raw string content of an attachment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMibpLogAttachmentContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpLogAttachmentContent(params: GetMibpLogAttachmentContent$Params, context?: HttpContext): Observable<string> {
    return this.getMibpLogAttachmentContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerPrettifyAttachment()` */
  static readonly MibpLogControllerPrettifyAttachmentPath = '/mibplog/attachments/prettify';

  /**
   * Attempts to prettify a JSON or XML document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prettifyAttachment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  prettifyAttachment$Plain$Response(params?: PrettifyAttachment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PrettifyResult>> {
    return prettifyAttachment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Attempts to prettify a JSON or XML document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `prettifyAttachment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prettifyAttachment$Plain(params?: PrettifyAttachment$Plain$Params, context?: HttpContext): Observable<PrettifyResult> {
    return this.prettifyAttachment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrettifyResult>): PrettifyResult => r.body)
    );
  }

  /**
   * Attempts to prettify a JSON or XML document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prettifyAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  prettifyAttachment$Response(params?: PrettifyAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<PrettifyResult>> {
    return prettifyAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Attempts to prettify a JSON or XML document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `prettifyAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prettifyAttachment(params?: PrettifyAttachment$Params, context?: HttpContext): Observable<PrettifyResult> {
    return this.prettifyAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrettifyResult>): PrettifyResult => r.body)
    );
  }

  /** Path part for operation `mibpLogControllerLogFrontendErrorerror()` */
  static readonly MibpLogControllerLogFrontendErrorerrorPath = '/mibplog/error';

  /**
   * Log frontend error in MibpLog.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logFrontendError()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logFrontendError$Response(params?: LogFrontendError$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return logFrontendError(this.http, this.rootUrl, params, context);
  }

  /**
   * Log frontend error in MibpLog.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logFrontendError$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logFrontendError(params?: LogFrontendError$Params, context?: HttpContext): Observable<void> {
    return this.logFrontendError$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
