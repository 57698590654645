export enum SupportCaseTradingAccountTypeResourceString {
  QueryAboutInvoice = 'SupportCasesTradingAccount_Type_QueryAboutInvoice',
  QuestionAboutAccount = "SupportCasesTradingAccount_Type_QuestionAboutAccount",
  FollowUpOnCredit = "SupportCasesTradingAccount_Type_FollowUpOnCredit",
  AddNewAdress = "SupportCasesTradingAccount_Type_AddNewAdress",
  EditAddress = "SupportCasesTradingAccount_Type_EditAddress",

}
export enum SupportCaseTradingAccountTypeValue {
  QueryAboutInvoice = 'Query about an invoice',
  QuestionAboutAccount = "I have a question about my account",
  FollowUpOnCredit = "Following up on a credit for return",
  AddNewAdress = "Add a new delivery address to my account",
  EditAddress = "Edit an existing address",
 }
