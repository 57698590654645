import { Component, EmbeddedViewRef, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'mibp-offline-page',
  templateUrl: './offline-page.component.html',
  styleUrls: ['./offline-page.component.scss']
})
export class OfflineComponent {
  isConnected = true;
  status: string;
  private zIndexBase = 5003;
  @ViewChild('offlinePageTemplate', {static: true}) offlinePageTemplate: TemplateRef<any>;
  embeddedView: EmbeddedViewRef<any>;
  zIndex: number;
  active = false;
  constructor(private connectionService: ConnectionService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.connectionService.monitor().subscribe(connectionState => {
      this.isConnected = connectionState.hasNetworkConnection;
      if (!this.isConnected) {
        this.open();
      } else {
        this.close();
      }
    });
  }
  close() : void {
    if (this.active) {
      this.embeddedView?.destroy();
      this.active = false;
      this.tryAgain();
    }
  }
  open() : void {
    this.moveTemplateToBody();
    this.active = true;
    const openDialogCount = document.querySelectorAll('body > .modal').length;
    this.zIndex = this.zIndexBase + (openDialogCount  * 100);
  }
  private moveTemplateToBody(): void {
    const bodyElement = document.querySelector('body');
    this.embeddedView = this.viewContainerRef.createEmbeddedView(this.offlinePageTemplate);
    this.embeddedView.rootNodes.forEach(rootNode => bodyElement.appendChild(rootNode));
  }
  tryAgain() : void {
    window.location.reload();
  }
}
