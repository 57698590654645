<h4 class="edit-title" [class.hidden]="isEditing">
  <span class="edit-title__text ">{{ cart.title }}</span>
  <mibp-button *ngIf="allowEdit" #btn [icon]="btn.Enum.Icons.Edit" [isSmall]="true" (click)="startEditName($event)"></mibp-button>
</h4>
<form class="cart-name-form" [formGroup]="nameForm" [ngClass]="{'is-editing': isEditing}" >
  <input class="cart-name-input" autocomplete="off" formControlName="name"
  (keydown)="nameElementKeyDown($event)"
  (blur)="nameElementBlur()"
  (click)="clickedRenameInput($event)"
  maxlength="50"
  #cartNameElement  [class.hidden]="!isEditing" id="cartEditInput" type="text">
  <!-- <mibp-resource-string class="validation-message" *ngIf="nameForm?.controls?.name?.errors?.ERRBCRT000 || nameForm?.controls?.name?.errors?.required" key="Global_Error_ERRBCRT000"></mibp-resource-string>
  <mibp-resource-string class="validation-message" *ngIf="nameForm?.controls?.name?.errors?.ERRBCRT002" key="Global_Error_ERRBCRT002"></mibp-resource-string> -->
</form>
