import { DropdownInput } from './../../../../../../../components/dropdown/dropdown.interface';
import { Injectable } from "@angular/core";
import { EquipementStatuses } from './myfleet-equipment-status-desc.types';
@Injectable({
  providedIn: 'root'
})
export class MyfleetEquipmentStatusDescriptionService {
  public equipmentStatuses: EquipementStatuses[] = [
    { code: "A", description: "Status_description_A" },
    { code: "B", description: "Status_description_B" },
    { code: "C", description: "Status_description_C" },
    { code: "D", description: "Status_description_D" },
    { code: "E", description: "Status_description_E" },
    { code: "F", description: "Status_description_F" },
    { code: "M", description: "Status_description_M" },
    { code: "P", description: "Status_description_P" },
    { code: "R", description: "Status_description_R" },
    { code: "S", description: "Status_description_S" },
    { code: "T", description: "Status_description_T" },
    { code: "U", description: "Status_description_U" },
    { code: "W", description: "Status_description_W" },
    { code: "Z", description: "Status_description_Z" }
  ];
  public codeAndDescription: EquipementStatuses[];
  public codeDescription: DropdownInput[];

  getDescriptionForStatusCode(equipmentCode: string) : EquipementStatuses[] {
    const codeAndDescription = this.equipmentStatuses.filter(es => es.code == equipmentCode);
    return codeAndDescription;
  }

  /// method which will return the status from Globalconfig

  getDescriptionForStatus(status: string[]): DropdownInput[] {
    return this.equipmentStatuses.filter(s=>status.includes(s.code)).map(s=> {
      return <DropdownInput>{
        text: s.description,
        value: s.code
      };
    });
  }
}



