import { NavigationExtras } from '@angular/router';
import { Guid } from 'guid-typescript';
import { CartItemProductStatus, CartProductVm, ProductAvailability, ProductExpectedDate, ProductPrice, ProductSearchItemEquipment } from 'root/mibp-openapi-gen/models';
export interface ProductListItem {
  /**
  * The item ID of this item if it is an item residing in a Cart
  */
  itemId?: Guid;

  /*
   * The product details for this item
  */
  product: ProductListItemProduct;

  price?: ProductListItemPrice;

  productEquipment?: ProductSearchItemEquipment[];

  // START: New properties from Persistent cart
  cartItemProductStatus?: CartItemProductStatus;
  supersededFromDb?: CartProductVm;
  // END: New properties from Persistent cart

  machineLink: ProductListItemMachineLink;
  expectedDate?: string;
  documotoDeepUrl?: string;
  quantity: number;
  companyCode?: string;

  isKitItem?: boolean;
  kitItems?: KitItem[];
  isSparePart?: boolean;
  isSubscription?: boolean;

  isSuperseeded?: boolean;
  isPurchased?: boolean;
  isApproved?: boolean;
  isPending?: boolean;
  isRejected?: boolean;
  isDuplicate?: boolean;
  upsellCrossSellProductId?: number;
  crossSellProductIds?: number [];

  isJustAdded?: boolean;
  score?: number;

  documotoRoute?: { commands: string[], extras?: NavigationExtras };

  recommendedQuantity?: number;
  superseededByProductCode?: string;
  superseededByProductCodeDbValue?: string;
  superseededByProductName?: string;
  productPrice?: ProductPrice;
  productAvailability?: ProductAvailability;
  productExpectedDate?: ProductExpectedDate;
  isSuggestedKitNotInCart?: boolean;
  promotionProductQuantity?: PromotionProductQuantityDetails;
  showPromotionRestrictionNotice?: boolean;
  availablePromotionProductQuantity?: string;
}

export interface PromotionProductQuantityDetails {
maxOrderableQuantity: number;
orderedQuantity: number;
cumulativeDuplicateProductQuantity: number;
}

export interface ProductListItemProduct {
  name: string;
  code: string;
  id: Guid;
  aliases: any[];
  brandCode: string;
  brandDescription: string;
  type: string;
  weight?: number;
  unitOfMeasure: string;
  supersededProductCode: string;
  priceCommodityCode: string;
  PartsPictureImageUrl: string;
  majorProductItemGroupName?: string;
  hasKit?: boolean;
  productAlias:string;
  isRockToolItem?: boolean;
  productNotificationId?: number;
  productNotificationBusinessRelationIds: number[];
}

export interface ProductListItemPrice {
  basePrice?: number;
  basePriceString?: string;
  netPrice?: number;
  netPriceString?: string;
  taxValue?: number;
  vatPriceString?: string;
  auroraPrice?:number;
  hasPriceDiff?:boolean;

}
export interface ProductListItemMachineLink {
  equipmentId?: number;
  equipmentName: string;
}
export interface KitItem {
  name: string;
  position: number;
  quantity: number;
}

export const DECIMAL_EXCEPTION = 'DecimalValidationException';
