import { Injectable } from '@angular/core';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { ToastType } from '../toast-service/toast.enum';
import { ToastService } from '../toast-service/toast.service';

@Injectable({
  providedIn: 'root'
})
export class NoticebarService {

  constructor(private toastService: ToastService) {}


  show(resourceString: string, type: NoticeType, macros: { [key: string]: string } = null): void {
    this.toastService.show(resourceString, {
      type: type == NoticeType.Error ? ToastType.Error : type == NoticeType.Success ? ToastType.Success :
        type == NoticeType.Warning ? ToastType.Warning : ToastType.Info,
      bodyResourceMacros: macros
    });
  }



  showText(text: string, type: NoticeType, isHtml: boolean, callback?: (name: string, rect: ClientRect) => void): void {
    const regexToDetectHtmlTag = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    isHtml = isHtml || regexToDetectHtmlTag.test(text);

    this.toastService.showText(text, {
      type: type == NoticeType.Error ? ToastType.Error : type == NoticeType.Success ? ToastType.Success :
            type == NoticeType.Warning ? ToastType.Warning : ToastType.Info,
      isHtml: isHtml
    });
  }

  hide(): void {
  }

}
