/* tslint:disable */
/* eslint-disable */
export enum Rendition {
  Thumbnail = 0,
  Medium = 1,
  Large = 2,
  Original = 3,
  MediumLarge = 4,
  BillOfMaterialDrawingWeb = 10,
  BillOfMaterialDrawingThumbnail = 11,
  ClaimAttachmentThumbnail = 12,
  MyFleetListImage = 20,
  MyFleetDetailsImage = 21,
  ThumbnailProduct = 22
}
