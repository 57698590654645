import { TermsAndConditionsApiController } from './../../mibp-openapi-gen/services/terms-and-conditions-api-controller';
import { GlobalConfigService } from './../../services/global-config/global-config.service';
import { LocalizationService } from './../../services/localization/localization.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { TermsAndConditionsService } from 'root/services/terms-and-conditions/terms-and-conditions.service';
import { DialogButton } from '..';
import { FrontendContextService, SignalR_ResourceTranslation, ApiService, AuthService, SignalR_UserStatus, ClientSideCacheService, MibpHttpApi, SignalR_Rendition, MibpLogger, LogService } from 'root/services';
import { TermsAndConditionsTypes } from './terms-and-conditions.enum';
import { SafeHtml } from '@angular/platform-browser';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from 'root/services/dialog/dialog.service';
import { ButtonColors, ButtonIcons } from '../button/button.enum';
import { PdfRenderComponent } from '../pdf-render/pdf-render.component';
import { TermsAndConditionType, UserStatus } from 'root/mibp-openapi-gen/models';
import { Guid } from 'guid-typescript';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

@Component({
  selector: 'mibp-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {

  readonly GENERAL_TERMS_RESOURCE_KEY = `General-Terms-and-conditions`;
  readonly DIGITAL_TERMS_KEY_PREFIX = `Digital-service-terms-`;
  readonly COMPANY_TERMS_KEY_PREFIX = `Terms-and-Conditions-`;

  readonly INFORMATION_KEY = `Terms-and-Conditions-Info`;
  readonly UPDATED_KEY = `Terms-and-Conditions-Updated`;
  readonly DATAPRIVACY_NOTICE_KEY = `DataPrivacyDialogNotice`;


  @ViewChild('dialog', {static: true}) dialog: DialogComponent;
  @ViewChild('pdfviewer') pdfViewer : PdfRenderComponent;
  dialogButtons: DialogButton[];
  hasError = false;
  modifiedDate: Date;
  isTermsAccepted = false;
  isDialogLoading = false;
  languageCode: string;
  termsAndConditionsHtml: SafeHtml;
  termsType: TermsAndConditionsTypes;
  translation: SignalR_ResourceTranslation;
  showFromServiceSubscription: Subscription;
  butonPrintIcon = ButtonIcons.Print;
  generalTermsURL: string;
  showNewgeneralTerms = false;
  generalTermsId: Guid;
  private log: MibpLogger;

  constructor(private tacService: TermsAndConditionsService,
    public context: FrontendContextService,
    private api: ApiService,
    private dialogService: DialogService,
    private localizationService: LocalizationService,
    private authService: AuthService,
    private httpApi: MibpHttpApi,
    private sessionService: MibpSessionService,
    private globalConfig: GlobalConfigService,
    private termsAndConditionController: TermsAndConditionsApiController,
    logger: LogService) {
    this.languageCode = this.localizationService.getLang();
    this.log = logger.withPrefix(`TermsAndConditionsComponent`);
  }

  async ngOnInit(): Promise<void> {
    this.showNewgeneralTerms = this.globalConfig.enableNewTermsAndConditions;
    await this.showTermsAndConditionsIfNeeded();

    // Will trigger if user invokes service method to show terms
    this.showFromServiceSubscription = this.tacService.showTerms$.subscribe(e => {
      this.showTermsAndConditions(e.type, e.isReadOnly);
    });

    this.localizationService.currentLocale.subscribe(localizationDetails => {
      this.languageCode = localizationDetails.code;
    });

  }

  ngOnDestroy() {
    if (this.showFromServiceSubscription) {
      this.showFromServiceSubscription.unsubscribe();
    }
  }

  /**
   * Will determine if, and which T&C to show
   */
  private async showTermsAndConditionsIfNeeded() {
    const userIsActiveOrApproved = this.sessionService.hasActiveUser() || this.sessionService.hasStatus(UserStatus.Approved);

    // Skip terms and conditions if...
    if (!this.authService.isLoggedIn()
        || !userIsActiveOrApproved
        || !this.tacService.generalTermsAndConditionsPublishDate) {
      this.isTermsAccepted = true;

      if (!this.tacService.generalTermsAndConditionsPublishDate && this.sessionService.hasStatus(UserStatus.Approved) ) {
        this.dialogService.prompt('text://General terms and conditions are missing. This user can never be "Active". Please upload a general terms PDF with an admin user', 'b');
      }

      return;
    }

    // User inactive. No need to agree to terms then...
    if (!this.sessionService.current?.user
        || this.sessionService.current.user?.status === UserStatus.Inactivated
        || this.sessionService.current.user?.status === UserStatus.New
        || this.sessionService.current.user?.status === UserStatus.Suspended) {
      this.isTermsAccepted = true;
      return;
    }

    const dataPrivacyAccepted = this.sessionService.current.user?.acceptedDataPrivacyStatementDate !== null;
    const hasAcceptedTermsBefore = this.sessionService.current.user?.acceptedTermsAndConditionsDate !== null;
    const termsAndConditionsAccepted = dataPrivacyAccepted ? this.sessionService.current.user?.acceptedTermsAndConditionsDate ? new Date(this.sessionService.current.user?.acceptedTermsAndConditionsDate) : null :null;
    const termsAndConditionsModified = await this.generalTermsAcceptedDate();

    if (!dataPrivacyAccepted) {
      // Must accept terms if GDPR policy is not accepted
      return this.showDataPrivacyNotice();
    } else {
      if (termsAndConditionsAccepted < termsAndConditionsModified) {
        if (hasAcceptedTermsBefore) {
          return this.showTermsWasUpdatedNotice();
        }
        return this.showGeneralTerms();
      }
    }

    this.isTermsAccepted = true;
  }

  async generalTermsAcceptedDate(){
    try{
      const date = await firstValueFrom(this.termsAndConditionController.getGeneralTermsPublishDate());
      return new Date(date);
    }
    catch(error){
      this.log.error('error fetching general terms accepted date', error);
    }
  }

  /**
   * Show GDPR-notice with a link to terms and conditions
   */
  private showDataPrivacyNotice() {
    this.resetDialog();

    this.tacService.getTermsAndConditionsText(this.DATAPRIVACY_NOTICE_KEY, this.languageCode).then(safeHtml => {
      this.termsAndConditionsHtml = safeHtml;
      this.dialogButtons = [
        {
          resourceKey: 'dialog_ReadTermsAndConditionsButton',
          id: 'read',
          automatedTestId: 'button-close-privacy-notice'
        }
      ];
      this.isDialogLoading = false;
    }).catch(() => this.showErrorInDialog());

    // Preload general terms
    this.tacService.getTermsAndConditionsText(this.GENERAL_TERMS_RESOURCE_KEY, this.languageCode);
  }

  /**
   * Show notice that T&C was updated since last visit and link to terms and conditions
   */
  private showTermsWasUpdatedNotice() {
    this.resetDialog();

    this.tacService.getTermsAndConditionsText(this.UPDATED_KEY, this.languageCode).then(safeHtml => {
      this.termsAndConditionsHtml = safeHtml;
      this.dialogButtons = [
        {
          resourceKey: 'dialog_ReadTermsAndConditionsButton',
          id: 'read',
          automatedTestId: 'button-close-term-updated-notice'
        }
      ];

      this.isDialogLoading = false;
    }).catch(() => this.showErrorInDialog());

    // Preload general terms
    this.tacService.getTermsAndConditionsText(this.GENERAL_TERMS_RESOURCE_KEY, this.languageCode);
  }

  /**
   * Show the general terms and conditions that the user must accept to use the site
   */
  private showGeneralTerms(isReadOnly = false) {
    if(this.showNewgeneralTerms){
      this.termsAndConditionController.getUserFileId({termsAndConditionType :TermsAndConditionType.GeneralTerms}).toPromise().then(id => {
        this.generalTermsId = Guid.parse(id);
        this.generalTermsURL = this.httpApi.UserFile.getFileRenditionUrl(Guid.parse(id), SignalR_Rendition.Original);
        this.dialogButtons = isReadOnly ?
          [
            {
              resourceKey: 'Global_Dialog_Close',
              id: 'close',
              automatedTestId: 'button-close-terms-and-conditions'
            }
          ] :
          [
            {
              resourceKey: 'dialog_AcceptTermsAndConditionsButton',
              id: 'acceptGeneralTerms',
              automatedTestId: 'button-accept-terms-and-conditions',
              color: ButtonColors.Primary
            },
            {
              resourceKey: 'dialog_NotAcceptTermsAndConditionsButton',
              id: 'showMustAcceptInfo',
              color: ButtonColors.Secondary,
              automatedTestId: 'button-reject-terms-and-conditions'
            }
          ];

        this.pdfViewer.open();
      });
    }
    else{
      this.resetDialog();

      this.tacService.getTermsAndConditionsText(this.GENERAL_TERMS_RESOURCE_KEY, this.languageCode).then(safeHtml => {
        this.termsAndConditionsHtml = safeHtml;
        this.dialogButtons = isReadOnly ?

          [
            {
              resourceKey: 'Global_Dialog_Close',
              id: 'close',
              automatedTestId: 'button-close-terms-and-conditions'
            }
          ] :
          [
            {
              resourceKey: 'dialog_AcceptTermsAndConditionsButton',
              id: 'acceptGeneralTerms',
              automatedTestId: 'button-accept-terms-and-conditions'
            },
            {
              resourceKey: 'dialog_NotAcceptTermsAndConditionsButton',
              id: 'showMustAcceptInfo',
              color: ButtonColors.Orange,
              automatedTestId: 'button-reject-terms-and-conditions'
            }
          ];

        // ? this.dialogService.mapStringsToButtons(['close#Global_Dialog_Close'])
        // : this.dialogService.mapStringsToButtons(['acceptGeneralTerms#dialog_AcceptTermsAndConditionsButton',
        //   'orange showMustAcceptInfo#dialog_NotAcceptTermsAndConditionsButton'
        // ]);
        this.isDialogLoading = false;
      }).catch(() => this.showErrorInDialog());
    }
  }

  /**
   * Show company-specific terms and conditions
   */
  private showCompanyTerms() {
    const resourceStringKey = `${this.COMPANY_TERMS_KEY_PREFIX}${this.sessionService.current.activeDeliverySequence.companyCode}`;
    this.resetDialog();
    this.tacService.getTermsAndConditionsText(resourceStringKey, this.languageCode).then(safeHtml => {
      this.termsAndConditionsHtml = safeHtml;
      this.dialogButtons = [
        {
          resourceKey: 'Global_Dialog_Close',
          id: 'close',
          automatedTestId: 'button-close-company-terms'
        }
      ];
      this.isDialogLoading = false;
    }).catch(() => this.showErrorInDialog());
  }

  /**
   * Show company-specific digital services terms and conditions
   */
  private showCompanyDigitalServicesTerms() {
    const resourceStringKey = `${this.DIGITAL_TERMS_KEY_PREFIX}${this.sessionService.current.activeDeliverySequence.companyCode}`;
    this.resetDialog();
    this.tacService.getTermsAndConditionsText(resourceStringKey, this.languageCode).then(safeHtml => {
      this.termsAndConditionsHtml = safeHtml;
      this.dialogButtons = [
        {
          resourceKey: 'Global_Dialog_Close',
          id: 'close',
          automatedTestId: 'button-close-digital-services-terms'
        }
      ];
      this.isDialogLoading = false;
    }).catch(() => {
      this.showErrorInDialog();

    });
  }

  /**
   * Display a general error message in the dialog when things fail
   */
  private showErrorInDialog() {
    this.isDialogLoading = false;
    this.hasError = true;
    this.dialogButtons = this.dialogService.mapStringsToButtons([
      'close#Global_Dialog_Close'
    ]);
  }

  /**
   * Show information that you must accept terms and conditions or logout
   */
  private showMustAcceptInformation() {
    this.resetDialog();
    this.tacService.getTermsAndConditionsText(this.INFORMATION_KEY, this.languageCode).then(safeHtml => {
      this.termsAndConditionsHtml = safeHtml;

      this.dialogButtons = [
        {
          resourceKey: 'dialog_ReadTermsAndConditionsButton',
          id: 'read',
          automatedTestId: 'button-read-terms-and-condition'
        },
        {
          resourceKey: 'Global_Logout',
          id: 'signout',
          color: ButtonColors.Orange,
          automatedTestId: 'dialog-button-sign-out'
        }
      ];
      this.isDialogLoading = false;
    }).catch(err => this.showErrorInDialog());
  }

  /**
   * Remove buttons and show dialog with loader
   */
  private resetDialog() {
    this.hasError = false;
    this.termsAndConditionsHtml = null;
    this.isDialogLoading = true;
    this.dialogButtons = null;
    if (!this.dialog.active) {
      this.dialog.open();
    }
  }
  onNewTermsDialogButton(btn: DialogButton){
    this.pdfViewer.close();
    if(btn.id === 'acceptGeneralTerms'){
      this.acceptTermsAndConditions();
      this.pdfViewer.close();
      this.showNewgeneralTerms = false;
    }
    else if(btn.id === 'showMustAcceptInfo'){
      this.showNewgeneralTerms = false;
      this.showMustAcceptInformation();
    }
  }

  onDialogButton(btn: DialogButton) {
    switch (btn.id) {
    case 'showMustAcceptInfo':
      this.showMustAcceptInformation();
      break;
    case 'acceptGeneralTerms':
      this.acceptTermsAndConditions();
      this.dialog.close();
      break;
    case 'read':
      this.showNewgeneralTerms = this.globalConfig.enableNewTermsAndConditions;
      this.showGeneralTerms();
      break;
    case 'signout':
      this.dialog.close();
      this.authService.signout();
      break;
    case 'close':
      this.dialog.close();
      break;
    }
  }

  private acceptTermsAndConditions(): any {
    this.resetDialog();
    this.tacService.acceptTermsAndConditions().then(() => {
      this.isTermsAccepted = true;
      this.dialog.close();
      location.reload();
    });
  }

  public showTermsAndConditions(type: TermsAndConditionsTypes, isReadOnly = false) {
    switch (type) {
    case TermsAndConditionsTypes.General:
      this.showGeneralTerms(isReadOnly);
      break;
    case TermsAndConditionsTypes.Information:
      this.showMustAcceptInformation();
      break;
    case TermsAndConditionsTypes.Privacy:
      this.showDataPrivacyNotice();
      break;
    case TermsAndConditionsTypes.DigitalServiceTerms:
      this.showCompanyDigitalServicesTerms();
      break;
    case TermsAndConditionsTypes.Company:
      this.showCompanyTerms();
      break;
    }
  }

  print() {
    window.print();
  }
}
