import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownComponent, DropdownInput } from 'root/components/dropdown';
import { FrontendContextService, LocalizationService, BroadcastService, GlobalConfigService, ToastService } from 'root/services';
import { NewContactUsTopic } from '../new-contact-us-form/new-contact-us-form.types';
import { SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';
import { Subscription, skip } from 'rxjs';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MySandvikFeatures } from 'root/services/permission';
import { ToastButton } from 'root/components/toast/toast.component';
import { ToastType } from 'root/services/toast-service/toast.enum';
import { ActiveMibpToast } from 'root/services/toast-service/toast.service.interface';


@Component({
  selector: 'mibp-contact-us-main-page',
  templateUrl: './contact-us-main-page.component.html',
  styleUrls: ['./contact-us-main-page.component.scss']
})
export class ContactUsMainPageComponent implements OnInit, OnDestroy {

  categoryValues = [
    {resourceString:'SupportCases_Type_GeneralQuestionOrRequest', category: NewContactUsTopic.GeneralQuestionOrRequest, feature:  MySandvikFeatures.ContactUsForm},
    {resourceString:'SupportCases_Type_ItemEnquriy', category: NewContactUsTopic.ItemEnquiry, feature:  MySandvikFeatures.ContactUsFormItemEnquiry},
    {resourceString:'SupportCases_Type_ReturnsAndOrder', category: NewContactUsTopic.ReturnsAndOrderDiscrepancies, feature:  MySandvikFeatures.ContactUsFormReturnsAndOrder},
    {resourceString:'SupportCases_Type_ShippingAndTracking', category: NewContactUsTopic.ShippingAndTracking, feature:  MySandvikFeatures.ContactUsFormShippingAndTracking},
    {resourceString:'SupportCases_Type_TechnicalRequest', category: NewContactUsTopic.TechnicalRequest, feature:  MySandvikFeatures.ContactUsFormTechnicalRequest},
    {resourceString:'SupportCases_Type_TradingAccountQuery', category: NewContactUsTopic.TradingAccountQueryOrIssue, feature:  MySandvikFeatures.ContactUsFormTradingAccountQuery},
    {resourceString:'SupportCases_Type_WebsiteFeedBack', category: NewContactUsTopic.WebsiteFeedback, feature:  MySandvikFeatures.ContactUsFormWebsiteFeedback},

  ];

  categories: DropdownInput[] = [];
  newContactUsTopic = NewContactUsTopic;
  private stopUsingResources: () => void;
  selectedTopic: string;
  isActingAs = false;
  showLiveChat:boolean;
  actAsSub: Subscription;
  @ViewChild('topicDropdown') topicDropdown: DropdownComponent;
  activeToast: ActiveMibpToast;
  toasterSubscription: Subscription;

  constructor(private localizationService: LocalizationService,
    private frontEndContext: FrontendContextService,
    private router: Router,
    private globalConfig: GlobalConfigService,
    private broadcast: BroadcastService,
    private mibpSession:  MibpSessionService,
    private toastService: ToastService ) { }



  ngOnInit(): void {


    const hasPermission = this.frontEndContext.testPermission({ features: [MySandvikFeatures.GeneralSupportLiveChat] });
    if (this.globalConfig.enableSalesforceLiveChat && hasPermission) {
      this.showLiveChat = true;
    } else {
      this.showLiveChat = false;
    }

    this.isActingAs = !!this.broadcast.snapshot.deliverySequence;
    this.actAsSub = this.broadcast.deliverySequence.pipe(skip(1)).subscribe(ds => this.onUserActAs(ds));

    //Fill categories dropdown and check if user has permission to see each value
    this.setResourceStringSubscription(this.categoryValues.map(c=> c.resourceString), resourceStrings => {
      const categories = [];
      resourceStrings.forEach((rs, index) => {
        if (this.mibpSession.current.user.enabledFeatures.includes(this.categoryValues[index].feature)) {
          categories.push(<DropdownInput>{ value: this.categoryValues[index].category, text: rs });
        }
      });
      this.categories = categories;
    });
  }

  resetForm(success: boolean) {

    if (!success) {
      this.activeToast = this.toastService.showWithTitle(null,'ContactUs_Message_Sent_Error',{
        isHtml: false,
        type: ToastType.Error,
        disableTimeOut: false
      });
    }
    else {
      this.topicDropdown.writeValue(null);
      this.selectedTopic = null;

      this.activeToast = this.toastService.showWithTitle('SupportCase_SubmittedSuccessfullyMessage', 'SupportCase_SubmittedSuccessfully', {
        isHtml: true,
        type: ToastType.Success,
        disableTimeOut: false,
        buttons: [
          { id: 'redirect', textResourceString: 'Feature_general_view_supportcases_name' },

        ]
      });
    }
    if (this.activeToast.onButtonClick) {
     this.toasterSubscription =  this.activeToast.onButtonClick.subscribe(res => {
        if (res.id == 'redirect') {
          this.router.navigate([`/${this.frontEndContext.getLang()}/support-cases`]);
          this.toastService.removeToast(this.activeToast.toastId);
        }

      });
    }
  }

  onTopicChange(selecteditem: DropdownInput) {
    if(selecteditem){
      this.selectedTopic = selecteditem.value;
    }
  }

  ngOnDestroy(): void {
    if (this.stopUsingResources) {
      this.stopUsingResources();
    }
    if(this.actAsSub){
      this.actAsSub.unsubscribe();
    }

    if(this.toasterSubscription){
      this.toasterSubscription.unsubscribe();
    }
  }

  setResourceStringSubscription(resourceStringKeys: string[], onComplete: (translatedStrings: string[]) => void) {
    this.stopUsingResources = this.localizationService.using(
      resourceStringKeys,
      resourceStrings => {
        onComplete(resourceStrings);
      }
    );
  }

  startLiveChat() {
    const elements = document.getElementsByClassName("helpButton");
    const chatButton = elements[0].querySelector("Button") as HTMLElement;
    if(chatButton != null)
    {
      chatButton.click();
    }
  }

  gotoFAQ(e: Event){
    e.preventDefault();
    const url = this.frontEndContext.Navigation.ensureUrlLanguage('/help/faq');
    this.router.navigateByUrl(url);
  }
  gotoGeneral(e: Event){
    e.preventDefault();
    const url = this.frontEndContext.Navigation.ensureUrlLanguage('/help/topics');
    this.router.navigateByUrl(url);
  }

  protected onUserActAs(deliverySequence: SessionDeliverySequenceViewModel): void {
    if (deliverySequence) {
      this.isActingAs = true;
    } else {
      this.isActingAs = false;
    }

  }

}
