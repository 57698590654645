import { allPermissionPolicies } from "root/all-permission-policies";
import { MySandvikNavigationItem } from "../navigation-types";




export const NAVITEMS_WARRANTYPORTAL: MySandvikNavigationItem = {
  resourceKey: 'Warranty-Portal_Title',
  sandvikIconName: 'inbox_customize',
  showIfAnyChildren: true,
  route: {
    path: ['warranty-portal'],
    exactMatch: true
  },
  children: [
    {
      route: {path: ['warranty-claims']},
      resourceKey: 'Warranty-Claim_Title',
      permissionPolicy: allPermissionPolicies.viewWarrantyPortal,
      children:[
        {route: {path: ['create']}, resourceKey: 'WarrantyPortal_CreateClaim', uniqueId: 'warranty-portal/create', hidden: true, permissionPolicy: allPermissionPolicies.viewWarrantyPortal},
        {route: {path: [':id']}, resourceKey: 'WarrantyPortal_ClaimDetails', hidden: true, permissionPolicy: allPermissionPolicies.viewWarrantyPortal,}
      ]
    },
    {
      route: {path: ['warranty-applications']},
      resourceKey: 'Warranty-Applications_Title',
      permissionPolicy: allPermissionPolicies.viewWarrantyApplication,
      children:[
        { route: {path: ['create']}, resourceKey: 'Global_Add', uniqueId: 'warranty-applications/create', permissionPolicy: allPermissionPolicies.viewWarrantyApplication},
        {route: {path: ['view/:id']}, resourceKey: 'Global_View',uniqueId: 'warranty-applications/view'},
        {route: {path: ['edit/:id']}, resourceKey: 'Global_Edit',uniqueId: 'warranty-applications/edit'}
      ]
    },
    {
      route: {path: ['warranty-registrations']},
      resourceKey: 'Component-Warranty-Registrations',
      permissionPolicy: allPermissionPolicies.viewWarrantyRegistration,
      children:[
        { route: {path: ['create']}, resourceKey: 'Global_Add', uniqueId: 'warranty-registrations/create', permissionPolicy: allPermissionPolicies.viewWarrantyRegistration},
        {route: {path: ['edit/:id']}, resourceKey: 'Global_Edit',uniqueId: 'warranty-registrations/edit', permissionPolicy: allPermissionPolicies.viewWarrantyRegistration},
        {route: {path: ['view/:id']}, resourceKey: 'Global_View',uniqueId: 'warranty-registrations/view', permissionPolicy: allPermissionPolicies.viewWarrantyRegistration},
      ]
    }
  ]
};


