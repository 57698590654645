/* tslint:disable */
/* eslint-disable */
export enum OrderStatus {
  NotSet = 0,
  Open = 1,
  Converted = 2,
  Cancelled = 3,
  Completed = 4,
  ConvertedSent = 5,
  CancelledSent = 6,
  Expired = 7,
  Sent = 8,
  OnHold = 9,
  InProgress = 10,
  PartiallyDispatched = 11,
  Delivered = 12,
  PartiallyDelivered = 13,
  PartiallyCompleted = 14,
  MibpPaymentFailed = 30
}
