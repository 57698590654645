import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OnDestroy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { LocalizationService, FrontendContextService, GlobalConfigService } from 'root/services';
import { QuickAddRowItem } from '../quick-add/quick-add.component';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

@Component({
  selector: 'mibp-quick-add-row',
  templateUrl: './quick-add-row.component.html',
  styleUrls: ['./quick-add-row.component.scss', './../validation-messages/validation-messages.component.scss']
})
export class QuickAddRowComponent implements OnInit, OnDestroy {
  // Resource strings
  productNumberResourceString: string;
  quantityResourceString: string;
  resourceStringsUpdated: Subscription;
  formValidationUpdate: Subscription;
  productUpdate: Subscription;
  quantityUpdate: Subscription;
  multipleProductsData: string;

  @Input() skipFocus = false;
  @Input() hideQuantity: boolean;
  @Input() singelRow: boolean;
  @Input() index: number;
  @Input() showDeleteButton = false;
  @Input() showAddButton = false;
  @Input() isPromotion = false;
  @Output() addNewRow = new EventEmitter<number>();
  @Output() removeRow = new EventEmitter<number>();
  @Output() dataChanged = new EventEmitter<number>();
  @Output() addMultipleProducts = new EventEmitter<[string, number]>();

  public internalRow: QuickAddRowItem;

  @Input() set rowItem(value: QuickAddRowItem) {
    this.internalRow = value;
    if (this.form) {
      this.form.patchValue({
        product: value.product,
        quantity: value.quantity
      });
    }
  }
  get rowItem(): QuickAddRowItem {
    return this.internalRow;
  }

  @Input() set zeroMinQuantity(value: boolean){
    if(value){
      this.form.get('quantity').clearValidators();
      this.form.get('quantity').updateValueAndValidity();
      this.form.get('quantity').setValidators(Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(99999999.999)
      ]));
      this.form.get('quantity').updateValueAndValidity();
    }
  }

  quantity: number;
  productCode; string;
  form: UntypedFormGroup;
  lastValidationState: boolean;
  maxCartSize: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private localization: LocalizationService,
    private route: ActivatedRoute,
    private globalConfig: GlobalConfigService,
    private sessionService: MibpSessionService,
    private context: FrontendContextService,
    private elm: ElementRef
  ) {

    this.form = this.formBuilder.group({
      product: new UntypedFormControl(null, Validators.compose([])),
      quantity: new UntypedFormControl(1, Validators.compose([
        Validators.required,
        Validators.min(0.05),
        Validators.max(99999999.999),
      ]))
    });

  }

  addRow(): void {
    this.addNewRow.emit(this.index);
  }

  onPaste(event: ClipboardEvent): void {
    const pasteData = event.clipboardData.getData('text');
    setTimeout(()=>{
      if(pasteData.indexOf("\r\n") != -1 || pasteData.indexOf("\t") != -1){
        this.multipleProductsData = pasteData;
        this.addMultipleProducts.emit([pasteData, this.index]);
      }
    });
  }

  deleteRow(): void {
    this.removeRow.emit(this.index);
  }

  public isValid(): boolean {
    return !this.form.invalid;
  }

  public setFocus(): void {
    if (!this.skipFocus) {
      (this.elm.nativeElement as HTMLElement).querySelector('input').focus();
    }
  }

  ngOnInit(): void {
    this.maxCartSize = this.globalConfig.maxCartSize;


    this.resourceStringsUpdated = this.localization.loaded.subscribe(() => {
      // Required when using resource strings from code
      this.localization.getResourceString(this.singelRow ? 'Global_ProductNumber' : 'Global_ItemNumbers_Label').then(resourceStringValue => {
        this.productNumberResourceString = resourceStringValue;
      });
      this.localization.getResourceString('Global_Quantity').then(resourceStringValue => {
        this.quantityResourceString = resourceStringValue;
      });
    });

    this.localization.getResourceString(this.singelRow ? 'Global_ProductNumber' : 'Global_ItemNumbers_Label').then(resourceStringValue => {
      this.productNumberResourceString = resourceStringValue;
    });
    this.localization.getResourceString('Global_Quantity').then(resourceStringValue => {
      this.quantityResourceString = resourceStringValue;
    });

    this.productUpdate = this.form.get('product').valueChanges.subscribe(val => {
      this.internalRow.product = val;
    });

    this.quantityUpdate = this.form.get('quantity').valueChanges.subscribe(val => {
      this.internalRow.quantity = val;
    });

    this.formValidationUpdate = this.form.valueChanges.subscribe(
      () => {
        if (this.form.get('quantity').value < 0) {
          this.form.get('quantity').setValue(0);
        }

        if (this.form.invalid !== this.lastValidationState) {
          this.internalRow.product = this.form.get('product').value;
          this.internalRow.quantity = this.form.get('quantity').value;
          this.lastValidationState = this.form.invalid;
        }
        this.dataChanged.emit(this.index);
      });
  }

  ngOnDestroy(): void {
    this.resourceStringsUpdated.unsubscribe();
    this.formValidationUpdate.unsubscribe();
    this.productUpdate.unsubscribe();
    this.quantityUpdate.unsubscribe();
  }
}
