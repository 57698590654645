<span *ngIf="!isHyperlink" (click)="onClick($event)">
  <button [attr.tabindex]="tabindex" [attr.type]="type" [disabled]="isDisabled || !isConnected" [attr.class]="buttonClass" [style.minWidth]="minWidth ? minWidth + 'px' : null">
    <span *ngIf="iconName" translate="no" class="material-icon">{{iconName}}</span>
    <mibp-resource-string *ngIf="resourceStringKey || fallbackText" [defaultValue]="fallbackText" [key]="resourceStringKey" [macros]="resourceStringMacros"></mibp-resource-string>
    <ng-content></ng-content>
  </button>
</span>
<span *ngIf="isHyperlink" (click)="onClick($event)">
  <a [attr.tabindex]="tabindex" [href]="hyperlinkUrl" [class]="buttonClass" [attr.disabled]="isDisabled || !isConnected ? 'disabled' : null">
      <span *ngIf="iconName" translate="no" class="material-icon">{{iconName}}</span>
    <mibp-resource-string [key]="resourceStringKey" [defaultValue]="fallbackText" [macros]="resourceStringMacros" *ngIf="resourceStringKey"></mibp-resource-string></a>
</span>
