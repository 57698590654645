<ng-container *ngIf="isRockToolItem || (expectedDeliveryDates && expectedDeliveryDates?.length > 0) || isQuotation || productExpectedDate; else standardDateControl">
  <div [ngClass]="{'expected-delivery-date__info': productExpectedDate}" >
    <a #ava [ngClass]="{'expected-delivery-date__remove-underline': !isPopOverClosed}" href="javascript:void(0)" (click)="showPopover(ava)"><mibp-datetime [date]="date" [format]="'unchanged'"></mibp-datetime></a>
    <span *ngIf="isRockToolItem || isQuotation" translate="no" class="material-icon material-icon--fill">help</span>
  </div>
    <mibp-popover #expectedDeliveryDatePopover>
      <div class="expectedDeliveryDate-popover">
        <span class="popover-title"><mibp-resource-string key="Global_ExpectedDeliveryDate"></mibp-resource-string></span>
        <ul *ngFor="let edd of expectedDeliveryDates">
          <li>{{edd.supplyQuantity}} items - <mibp-datetime [date]="edd.deliveryDate" [format]="'unchanged'"></mibp-datetime></li>
        </ul>
        <p *ngIf="isQuotation" class="static-info"><mibp-resource-string key="OrderDetails_ExpectedDeliveryDateNote"></mibp-resource-string></p>
        <p *ngIf="isRockToolItem" class="static-info"><mibp-resource-string key="Global_RockToolDeliveryDateInfo"></mibp-resource-string></p>
      </div>
    </mibp-popover>
    <mibp-popover #availabilityPopover (closed)="closePopover($event)">
      <div class="availability-popover">
        <div *ngIf="productExpectedDate?.availabilityType != availabilityType.NotAvailable">
          <div class="availability-popover--popupHeader">
            <mibp-resource-string key="Global_ExpectedDeliveryDate"></mibp-resource-string>
          </div>
          <table class="availability-popover--table" *ngFor="let ava of productExpectedDate?.availabilities">
            <tbody>
              <tr class="availability-popover--popupValue" >
                <td>
                  <span>{{ava.quantity}}</span>&nbsp;
                  <span *ngIf="ava.quantity !== 1"><mibp-resource-string key="Global_Items"></mibp-resource-string></span>
                  <span *ngIf="ava.quantity === 1"><mibp-resource-string key="Global_Item"></mibp-resource-string></span>
                </td>
              </tr>

              <tr>
                <th><mibp-resource-string key="Global_ExpectedDeliveryDate"></mibp-resource-string>:</th>
                <td>
                  <mibp-datetime *ngIf="!productExpectedDate?.errorCode" [date]="ava.expectedDate" [format]="'unchanged'"></mibp-datetime>
                  <span *ngIf="productExpectedDate?.errorCode"><mibp-resource-string key="Carts_ErrorGettingAvailability"></mibp-resource-string></span>
                </td>
              </tr>

              <tr *ngIf="stockroomEnabled">
                <th><mibp-resource-string key="Global_StockRoom"></mibp-resource-string>:</th>
                <td class ="stockRoom">
                  <span class="prop__value prop__value--value" *ngIf="!productExpectedDate?.errorCode">{{ava.stockroomDescription}}</span>
                  <span *ngIf="productExpectedDate?.errorCode"><mibp-resource-string key="Carts_ErrorGettingAvailability"></mibp-resource-string></span>
                </td>
              </tr>
          </tbody>
        </table>
        <p *ngIf="isRockToolItem" class="static-info"><mibp-resource-string key="Global_RockToolDeliveryDateInfo"></mibp-resource-string></p>
        <p *ngIf="isQuotation" class="static-info"><mibp-resource-string key="OrderDetails_ExpectedDeliveryDateNote"></mibp-resource-string></p>
      </div>
    </div>
  </mibp-popover>
  </ng-container>
  <ng-template #standardDateControl>
    <mibp-datetime [date]="date" [format]="'unchanged'"></mibp-datetime>
  </ng-template>
