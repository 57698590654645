import { Component, Input, OnDestroy } from "@angular/core";
import { UntypedFormGroup, ValidationErrors } from "@angular/forms";
import { Subscription } from "rxjs";

export interface ErrorMessage {
  resourceStringKey: string;
  plainTextOverride?: string;
  macros: any;
  isRedIcon:boolean;
}
function nameof<T>(key: keyof T, instance?: T): keyof T {
  return key;
}
@Component({
  selector: 'mibp-validation-text',
  templateUrl: './validation-text.component.html',
  styleUrls: ['./validation-text.component.scss']
})
export class MibpValidationTextComponent implements OnDestroy {

  form: UntypedFormGroup;
  sub: Subscription;
  errors: ValidationErrors;
  statusSubscription: Subscription;
  valueSubscription: Subscription;
  errorMessages: ErrorMessage[] = [];
  isPending = false;
  pendingTimer;
  isAlwaysVisible = false;
  showErrors = false;

  resourceMappings = {
    email: 'Global_InvalidEmail',
    required: 'Global_FieldIsRequired',
    userExists: 'Settings_Users_Error_EmailExists',
    passwordsDoNotMatch: 'Global_Error_PasswordsMustMatch',
    roleExists: 'Settings_Roles_Error_RoleExists',
    weakPassword: 'Settings_Users_PasswordRequirements',
    guid: 'Global_Error_InvalidGuid',
    organizationWithScopeExists: 'Settings_Organizations_Error_ExistsWithScope',
    scopeConflict: 'Component_ResponsibilityPicker_ConflictingResponsibilities',
    organizationWithNameExists: 'Settings_Organizations_Error_NameExists',
    requireCheckboxesToBeChecked: 'Global_Validation_SelectAtLeastOneOption',
    greaterThanZero : 'Global_Validation_GreaterThanZero',
    anyANYANYFound : 'Any_Any_Bulletin_Error',
    externalEmail : 'Non_Sandvik_Email_Validator',
    scopeInactive: 'Component_ResponsibilityPicker_InactiveResponsibilities',
    postCodeInvalid : 'Validation_PostCode_Invalid'
};

  @Input() plainTextMappings: { [key: string]: string } = {};
  @Input() resourceKeyMappings: { [key: string]: string } = {};
  @Input() additionalInformationResourceKeys: { [key: string]: string } = {};

  @Input()
  set alwaysVisible(val: boolean) {
    this.isAlwaysVisible = val;
    this.onValueChanges();
  }
  @Input() controlName: string;
  @Input()
  set formGroup(val: UntypedFormGroup) {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.form = val;
    if (this.form) {
      if (this.valueSubscription) { this.valueSubscription.unsubscribe(); }
      if (this.statusSubscription) { this.statusSubscription.unsubscribe(); }
      this.valueSubscription = this.form.valueChanges.subscribe(() => this.onValueChanges());
      this.statusSubscription = this.form.statusChanges.subscribe(() => this.onStatusChanges());
    }

  }

  onStatusChanges() {
    this.onValueChanges();
  }


  onValueChanges() {
    let showErrors = false;
    clearTimeout(this.pendingTimer);
    if (this.form && this.form.controls[this.controlName]) {
      if ( this.form.controls[this.controlName].touched || this.form.controls[this.controlName].dirty || this.isAlwaysVisible) {
        showErrors = true;
        this.errors = this.form.controls[this.controlName].errors;
        this.errorMessages = this.errors ? Object.keys(this.errors).map<ErrorMessage>(errorKey => {
          const macros = this.errors[errorKey];
          return {
            errorKey: errorKey,
            plainTextOverride: this.plainTextMappings[errorKey] || null,
            resourceStringKey: this.resourceMappings[errorKey] || (this.resourceKeyMappings ? this.resourceKeyMappings[errorKey] : null) || 'Global_ValidationText_' + errorKey,
            macros,
            isRedIcon: nameof('scopeInactive',this.resourceMappings) == errorKey ? true : false
          };

        }) : [];

        if (this.form.pending) {
          this.isPending = true;
          this.pendingTimer = setTimeout(() => this.onValueChanges(), 150);
        } else {
          this.isPending = false;
        }
      }
    }
    this.showErrors = showErrors;
  }

  ngOnDestroy() {
    if (this.valueSubscription) { this.valueSubscription.unsubscribe(); }
    if (this.statusSubscription) { this.statusSubscription.unsubscribe(); }
  }



}
