import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MibpGridFrontendFilter } from '../../mibp-grid.types';

@Component({
  selector: 'mibp-grid-filter-text',
  templateUrl: './mibp-grid-filter-text.component.html',
  styleUrls: ['./mibp-grid-filter-text.component.scss']
})
export class MibpGridFilterTextComponent implements OnInit {

  private currentValue: string;
  private searchUpdateTimer: number;
  private updateMs = 1200;
  filterForm: UntypedFormGroup;
  @Input() filter: MibpGridFrontendFilter;
  @Output() update = new EventEmitter<string>();

  @Input()
  set value(val: string) {
    this.currentValue = val;
    if (this.filterForm) {
      this.filterForm.setValue({
        text: val
      });
    }
  }

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      text: [this.currentValue]
    });
  }

  /**
   * React to events of text field
   */
  onTextFieldUpdate(e?: Event): void {
    this.clearTimer();

    if ((e as KeyboardEvent)?.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      this.triggerUpdateIfNeeded(this.filterFormValue);
    } else {
      this.startTimer();
    }

  }

  /**
   * Get the current, trimmed value of the text field
   */
  private get filterFormValue(): string {
    return this.filterForm.value.text?.trim();
  }

  /**
   * Set the new text value and trigger an event is any change was detected
   * @param newValue The new text value
   */
  private triggerUpdateIfNeeded(newValue: string): void {
    if (this.currentValue != newValue) {
      this.currentValue = newValue;
      this.update.emit(this.currentValue);
    }
  }

  /**
   * Start the timer that will check if the search text was changed,
   */
  private startTimer(): void {
    this.clearTimer();
    this.searchUpdateTimer = window.setTimeout(() => {
      this.triggerUpdateIfNeeded(this.filterFormValue);
    }, this.updateMs);
  }

  /***
   * Clear the timer that will check for updates
   */
  clearTimer(): void {
    if (this.searchUpdateTimer) {
      clearTimeout(this.searchUpdateTimer);
    }
  }


}
