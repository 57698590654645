<div *ngIf="cart">
  <mibp-loader *ngIf="isLoading" type="fullscreen"></mibp-loader>
  <div class="inline-editing-element">

    <h3 class="edit-title my-header" [class.hidden]="isEditing">

      <span class="edit-title__text ">{{ cart?.name }}</span>
      <span>
        <mibp-button *ngIf="hasCreateCartPermission" #btn [isSmall]="true" [icon]="btn.Enum.Icons.Edit"
          (click)="startEditName()"></mibp-button>
      </span>

    </h3>
    <div>
      <form name="nameForm" [formGroup]="nameForm">
        <input autocomplete="off" formControlName="name" (keydown)="nameElementKeyDown($event)"
          (blur)="nameElementBlur($event)" (focus)="nameElementFocus($event)" maxlength="50" #cartNameElement
          [class.hidden]="!isEditing" id="cartEditInput" type="text">
        <mibp-resource-string class="validation-message"
          *ngIf="nameForm?.controls['name']?.errors?.ERRBCRT000 || nameForm?.controls['name']?.errors?.required"
          key="Global_Error_ERRBCRT000"></mibp-resource-string>
        <mibp-resource-string class="validation-message" *ngIf="nameForm?.controls['name']?.errors?.ERRBCRT002"
          key="Global_Error_ERRBCRT002"></mibp-resource-string>
      </form>
    </div>
  </div>



  <div class="properties">
    <div
      *ngIf="priceIsDone && availabilityIsDone && hasPriceEnquiryPermission && priceMissing && !disableShowErrorsButton">
      <mibp-button #btn [color]="btn.Enum.Colors.Orange" [disabled]="this.saveInProgress" (click)="onShowErrors()"
        displayBlock="true" resourceStringKey="Global_Show_Errors"></mibp-button>
    </div>
  </div>
  <div class="waitingForPrice" *ngIf="hasPriceEnquiryPermission && isLoadingTotalPrice">
    <mibp-loader type="spinner"></mibp-loader>
    &nbsp;
    <mibp-resource-string key="Carts_PriceAndAvailability_Loading"></mibp-resource-string>
  </div>

  <div class="properties">
    <div class="property dotted-border">
      <span class="label">
        <mibp-resource-string key="Global_NumberOfLines"></mibp-resource-string>
      </span>
      <span class="value">{{ itemCount }}</span>
    </div>

    @if (isActingAs || !isDeliverySesequenceRequired) {
    <div class="property dotted-border">
      <span class="label">
        <mibp-resource-string key="Global_TotalWeight"></mibp-resource-string>
      </span>
      <span *ngIf="!isLoadingTotalWeigth" class="value">{{ totalWeight | formatWeight }}
        <mibp-resource-string *ngIf="totalWeight" key="Global_UoM_KG"></mibp-resource-string></span>
      <span class="value value--no-transform" *ngIf="isLoadingTotalWeigth">
        <mibp-loader type="spinner"></mibp-loader>
      </span>
    </div>

    <div class="property dotted-border" *ngIf="isActingAs && hasPriceEnquiryPermission && isAuroraCompany">
      <span class="label">
        <mibp-resource-string key="product_price"></mibp-resource-string>
      </span>
      <span class="value" *ngIf="!isLoadingTotalPrice && !hasCartTotalError && !unableToRetrievePrice">
        {{ totalPrice }}
      </span>
      <span class="value" *ngIf="isLoadingTotalPrice">
        <mibp-loader type="spinner"></mibp-loader>
      </span>
      <!-- <span class="value my-text-red" *ngIf="unableToRetrievePrice && !hasCartTotalError">
        <mibp-resource-string key="Carts_UnableToRetrievePrice"></mibp-resource-string>
      </span> -->
      <!-- <span class="value my-text-red" *ngIf="hasCartTotalError">
        <mibp-resource-string key="Cart_ProductPrice_Error"></mibp-resource-string>
      </span> -->
    </div>

    <div class="property property--subtotal" [class.property--subtotal-bigamount]="totalPrice?.length > 6" *ngIf="isActingAs && hasPriceEnquiryPermission && isAuroraCompany">
      <span class=" label">
          <mibp-resource-string key="Carts_SubTotal"></mibp-resource-string>
      </span>
      <span class="value" *ngIf="!isLoadingTotalPrice && !hasCartTotalError && !unableToRetrievePrice">
        {{ totalPrice }}
      </span>
    </div>

    <div class="my-cart-actions">

      <div class="my-cart-actions__buttons">

      <div class="my-cart-actions--button" *ngIf="hasCreateCartPermission">
        <mibp-button automatedTestId="button-delete-cart" displayBlock="true" #btn [color]="btn.Enum.Colors.Secondary" (click)="onDeleteClick()"
          resourceStringKey="Carts_DeleteCart" [testid]="'deletecartbtn'"
          [disabled]="saveInProgress"></mibp-button>
      </div>

      <div class="my-cart-actions--button" *ngIf="canCheckout && isAuroraCompany ">
        <mibp-button automatedTestId="button-checkout-cart" [disabled]="hasCartTotalException || !hasPriceEnquiryPermission || saveInProgress || isLoadingTotalPrice || !isAuroraCompany || disableCheckoutButton || unableToRetrievePrice"
          displayBlock="true" resourceStringKey="Carts_CheckOut" (click)="onCheckoutClick()"
          [style]="btn.Enum.Styles.Fill" #btn></mibp-button>
      </div>

      <div class="my-cart-actions--button" *ngIf="hasPunchOutPermission">
        <mibp-button automatedTestId="button-punchout-cart" [disabled]="hasCartTotalException || !hasPriceEnquiryPermission || saveInProgress || isLoadingTotalPrice || punchoutTransaction || disableCheckoutButton || unableToRetrievePrice"
          displayBlock="true" (click)="onPunchOutClick($event)" resourceStringKey="Carts_PunchOut"
          [style]="btn.Enum.Styles.Fill" #btn></mibp-button>
        <div id="punchOutHtml" style="display: none;"></div>
      </div>
      <div *ngIf="!hasCartTotalError && unableToRetrievePrice" class="product-without-price-info">
        <mibp-resource-string [key]="'Price_ProductWithoutPrice_Info'" [isHtml]="'true'"></mibp-resource-string>
      </div>
      <div class="product-without-price-info" *ngIf="hasCartTotalError">
        <span>
          <mibp-resource-string class="my-text-red" key="Cart_ProductPrice_Error"></mibp-resource-string>
          <p><a href="javascript:void(0)" (click)="retryFetchingPrices()"><mibp-resource-string key="Global_Action_TryAgain"></mibp-resource-string></a></p>
        </span>
      </div>
    </div>
    </div>

    <div *ngIf="!isActingAs">
      <p>Act as a customer account to show prices and continue with check out.</p>
    </div>

  }

  </div>


</div>
