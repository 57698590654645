<div data-aut="quickadd-row">
  <form [formGroup]="form" class="quick-add-row-form">
    <div class="quick-add-row-form__parts-number" [class.quick-add-row-form__parts-number--wide]="singelRow">
      <label *ngIf="isPromotion && index === 0"><mibp-resource-string *ngIf="isPromotion"
          key="Global_ItemNumber_Label"></mibp-resource-string></label>
      <input data-aut="quickadd-product-code" name="productCode" [attr.tabindex]="0" formControlName="product"
        type="text" [mibpResourceStringEditor]="['Global_ProductNumber']"
        placeholder="{{ productNumberResourceString }}" autocomplete="off" autocorrect="off" autocapitalize="off"
        spellcheck="false" (paste)="onPaste($event)">
      <div class="validation-message" *ngIf="rowItem.invalidProduct">
        <mibp-resource-string key="Global_InvalidProductCode"></mibp-resource-string>
      </div>
      <div class="validation-message" *ngIf="rowItem.cartSizeExceeded">
        <mibp-resource-string key="Carts_TooManyItems_Error" [macros]="{ max: maxCartSize }"></mibp-resource-string>
      </div>
    </div>
    <div class="quick-add-row-form__quantity-and-add-delete">
      <div *ngIf="!hideQuantity">
        <label *ngIf="isPromotion && index === 0"><mibp-resource-string *ngIf="isPromotion"
            key="QuickAdd_MaxQty_Label"></mibp-resource-string>
          <mibp-inline-help>
            <div class="feature-help">
              <div class="my-body">
                <mibp-resource-string key="QuickAdd_MaxQty_Description" [isHtml]="true"></mibp-resource-string>
              </div>
            </div>
          </mibp-inline-help>
        </label>
        <mibp-numeric-input formControlName="quantity"></mibp-numeric-input>
        <mibp-validation-text [formGroup]="form" [alwaysVisible]="formSubmitted"
          controlName="quantity"></mibp-validation-text>
      </div>
      <div *ngIf="!singelRow" [ngStyle]="{'padding-top': isPromotion && index === 0 ? '32px': '0px'}"
        class="quick-add-item-action">
        <mibp-button automatedTestId="quickadd-add-row" *ngIf="showAddButton" [tabindex]="0" (click)="addRow()"
          [icon]="btn.Enum.Icons.Plus" #btn></mibp-button>
      </div>
      <div *ngIf="!singelRow" [ngStyle]="{'padding-top': isPromotion && index===0 ? '32px': '0px'}"
        class="quick-add-item-action">
        <mibp-button automatedTestId="quickadd-remove-row" *ngIf="showDeleteButton" [tabindex]="0" (click)="deleteRow()"
          [icon]="btn1.Enum.Icons.Trash" #btn1></mibp-button>
      </div>
    </div>
  </form>
</div>
