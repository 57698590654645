

import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, UntypedFormBuilder } from "@angular/forms";
import { OnInit, Component, forwardRef, Output, EventEmitter, Input } from "@angular/core";
import { ApiService} from "root/services";
import { PermissionsApiController } from './../../mibp-openapi-gen/services/permissions-api-controller';


@Component({
  selector: 'mibp-permission-picker',
  templateUrl: './permission-picker.component.html',
  styleUrls: ['./permission-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PermissionPickerComponent),
    }
  ]
})

export class PermissionPickerComponent implements OnInit, ControlValueAccessor {

  permissionData: any;
  permissionPickerForm: UntypedFormGroup;

  writeValueError = false;
  formGroup: UntypedFormGroup;
  currentValue = 0;
  invalidValue = false;
  permissionValues: PermissionValue[];
  filteredPermissionValues: PermissionValue[];
  isExpanded = false;
  isDisabled = false;

  @Output() valueChange = new EventEmitter();
  @Input() labelResourceString: string;
  @Input() permissionType: 'user' | 'roleEcom' | 'roleAdmin' | 'businessRelation' | 'accessgroup' | 'helptags' = 'user';
  @Input()
  set value(value: number) {
    if (!isNaN(value)) {
      if (this.permissionValues) {
        this.setPermission(value);

        this.onChange(value);
      } else {
        this.currentValue = value;
      }
    }
  }

  constructor(private formBuilder: UntypedFormBuilder,
    private api: ApiService,
    private permissionsApi: PermissionsApiController) {
  }

  expand() {
    if (!this.isExpanded) {
      this.isExpanded = true;
    }
  }

  toggleExpand(e: Event, value: boolean = null) {
    e.stopImmediatePropagation();
    if ((e.currentTarget as HTMLElement).classList.contains('should-expand-collapse')) {

      if (value !== null && this.isExpanded === value) {
        return;
      }

      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.filteredPermissionValues = this.permissionValues.slice(0);
      }
    }
  }

  inputClick(event: Event) {
    event.stopPropagation();
  }

  filterPermissions(e: Event) {
    if (e.currentTarget) {
      const tbx = e.currentTarget as HTMLInputElement;
      const val = tbx.value.toLowerCase();
      this.filteredPermissionValues = this.permissionValues.filter(f => f.name.toLowerCase().indexOf(val) !== -1).slice(0);
    }
    // this.filteredPermissionValues = this.permissionValues;
  }

  ngOnInit(): void {

    this.formGroup = this.formBuilder.group({
      permission: []
    });

    this.permissionsApi.getAllPermissions()
      .subscribe(permissions => {
        const key = this.permissionType !== 'helptags' ? this.permissionType + 'Permissions' : this.permissionType;
        const permissionValues = permissions[key] ? <PermissionValue[]>permissions[key].filter(v => v.name !== null && v.value !== 0) : [];

        this.formGroup.setValue({
          permission: 0 // form write value pls! isNaN(currentValue) ? 0 : currentValue
        });

        permissionValues.forEach(v => {
          if (this.bitwiseAndLarge(this.currentValue,  v.value)) {
            v.checked = true;
          }
        });

        permissionValues.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        this.permissionValues = permissionValues;
        this.filteredPermissionValues = permissionValues;
        this.onChange(this.currentValue);
      });
  }

  openRawPrompt(event: Event) {
    event.stopPropagation();
    const value = prompt('Change the bitwise permission value', this.currentValue.toString());
    if (value !== null) {
      this.setPermission(parseInt(value, 10));
    }
  }

  private bitwiseAndLarge(val1, val2) {
    let shift = 0, result = 0;
    const mask = ~((~0) << 30); // Gives us a bit mask like 01111..1 (30 ones)
    const divisor = 1 << 30; // To work with the bit mask, we need to clear bits at a time
    while ( (val1 !== 0) && (val2 !== 0) ) {
      let rs = (mask & val1) & (mask & val2);
      val1 = Math.floor(val1 / divisor); // val1 >>> 30
      val2 = Math.floor(val2 / divisor); // val2 >>> 30
      for (let i = shift++; i--;) {
        rs *= divisor; // rs << 30
      }
      result += rs;
    }
    return !!result;
  }


  selectAll(event: Event) {
    event.preventDefault();
    this.currentValue = this.permissionValues.reduce((sum, permission) => sum += permission.value, 0);
    this.permissionValues.forEach(permission => permission.checked = true);
    this.filteredPermissionValues.forEach(permission => permission.checked = true);
    this.onChange(this.currentValue);
    this.valueChange.emit(this.currentValue);
  }

  selectNone(event: Event) {
    event.preventDefault();
    this.currentValue = 0;
    this.permissionValues.forEach(permission => permission.checked = false);
    this.filteredPermissionValues.forEach(permission => permission.checked = false);
    this.onChange(this.currentValue);
    this.valueChange.emit(this.currentValue);
  }

  selectionToggle(event: Event) {
    event.preventDefault();
    const initialValue = this.currentValue + 0;
    let newValue = this.currentValue + 0;
    this.permissionValues.forEach(permission => {
      if (this.bitwiseAndLarge(initialValue, permission.value)) {
        newValue -= permission.value;
        permission.checked = false;
      } else {
        newValue += permission.value;
        permission.checked = true;
      }

      const filtered = this.filteredPermissionValues.find(p => p.value === permission.value);
      if (filtered) {
        filtered.checked = permission.checked;
      }

    });
    this.currentValue = newValue;
    this.onChange(this.currentValue);
    this.valueChange.emit(this.currentValue);
  }


  togglePermission(event: Event, permission: PermissionValue) {
    event.stopPropagation();

    if (permission.invalidFlag === true) {
      return;
    }

    permission.checked =  !permission.checked;
    const filtered = this.filteredPermissionValues.find(p => p.value === permission.value);
    if (filtered) {
      filtered.checked = permission.checked;
    }

    if (!this.bitwiseAndLarge(this.currentValue, permission.value)) {
      this.currentValue += permission.value;
    } else {
      this.currentValue -= permission.value;
    }

    this.onChange(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.invalidValue = false;
  }

  currentValueChange(event: Event) {
    const newValue = parseInt((<HTMLInputElement>event.target).value, 10);
    this.setPermission(newValue);
    this.onChange(newValue);
  }

  setPermission(value: number) {
    if (!this.isValidValue(value))  {
      value = 0;
      this.invalidValue = true;
    } else {
      this.invalidValue = false;
    }

    this.permissionValues.forEach(permission => {
      if ( value !== 0 && (this.bitwiseAndLarge(value, permission.value))) {
        permission.checked = true;
      } else {
        permission.checked = false;
      }
    });

    this.currentValue = value;
  }

  // toggleSelection(action: any) {

  //   if (action === true) {
  //     const all = 0;
  //     this.permissionValues.forEach(permission => {
  //       this.togglePermission(true, permission);
  //     });
  //     this.invalidValue = false;
  //     setTimeout( () => {
  //       this.currentValue = this.currentValue;
  //     }, 1);
  //   } else if (action === false) {
  //     this.permissionValues.forEach(permission => {
  //       this.togglePermission(false, permission);
  //     });
  //     this.invalidValue = false;
  //     setTimeout( () => {
  //       this.currentValue = this.currentValue;
  //     }, 1);
  //   } else {
  //     this.permissionValues.forEach(permission => {
  //       this.togglePermission(null, permission);
  //     });
  //     setTimeout( () => {
  //       this.currentValue = this.currentValue;
  //     }, 1);
  //   }
  //   this.formGroup.setValue({
  //     permission: this.currentValue
  //   });
  //   this.valueChange.emit(this.currentValue);
  //   this.onChange(this.currentValue);
  // }

  private isValidBitwiseFlag(num: number) {
    const binaryString = (num >>> 0).toString(2);
    return binaryString.match(/^1(0+|$)$/);
  }

  private isValidValue(num: number) {
    let invalidRemaining = num;
    this.permissionValues.forEach(flag => {
      if (this.bitwiseAndLarge(flag.value, num)) {
        invalidRemaining -= flag.value;
      }
    });

    return invalidRemaining === 0;
  }

  writeValue(obj: any): any {
    if (this.permissionValues) {
      this.setPermission(obj as number);
    } else {
      this.currentValue = obj as number;
      this.onChange(obj as number);
    }
  }


  triggerTouched(event: any, selectedItem: any = null) {
    this.onTouched();
    //   this.triggerChange(selectedItem ? selectedItem : null);
  }

  onChange = (o: any) => {
    // this.pendingChanges.push(rating);
  };

  onTouched = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}


interface PermissionValue {
  name: string;
  value: number;
  checked?: boolean;
  invalidFlag?: boolean;
}
