/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { enableDisableFeature } from '../fn/feature/enable-disable-feature';
import { EnableDisableFeature$Params } from '../fn/feature/enable-disable-feature';
import { enableDisableFeature$Plain } from '../fn/feature/enable-disable-feature-plain';
import { EnableDisableFeature$Plain$Params } from '../fn/feature/enable-disable-feature-plain';
import { FeatureForEditingVm } from '../models/feature-for-editing-vm';
import { FeatureTargetedViewModel } from '../models/feature-targeted-view-model';
import { getFeaturesForEdit } from '../fn/feature/get-features-for-edit';
import { GetFeaturesForEdit$Params } from '../fn/feature/get-features-for-edit';
import { getFeaturesForEdit$Plain } from '../fn/feature/get-features-for-edit-plain';
import { GetFeaturesForEdit$Plain$Params } from '../fn/feature/get-features-for-edit-plain';
import { getScopes } from '../fn/feature/get-scopes';
import { GetScopes$Params } from '../fn/feature/get-scopes';
import { getScopes$Plain } from '../fn/feature/get-scopes-plain';
import { GetScopes$Plain$Params } from '../fn/feature/get-scopes-plain';
import { getTargetedRoles } from '../fn/feature/get-targeted-roles';
import { GetTargetedRoles$Params } from '../fn/feature/get-targeted-roles';
import { getTargetedRoles$Plain } from '../fn/feature/get-targeted-roles-plain';
import { GetTargetedRoles$Plain$Params } from '../fn/feature/get-targeted-roles-plain';
import { getTargetedUsers } from '../fn/feature/get-targeted-users';
import { GetTargetedUsers$Params } from '../fn/feature/get-targeted-users';
import { getTargetedUsers$Plain } from '../fn/feature/get-targeted-users-plain';
import { GetTargetedUsers$Plain$Params } from '../fn/feature/get-targeted-users-plain';
import { ResolvedScope } from '../models/resolved-scope';
import { saveAsync } from '../fn/feature/save-async';
import { SaveAsync$Params } from '../fn/feature/save-async';

@Injectable({ providedIn: 'root' })
export class FeatureApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `featureControllerSaveAsyncresolvedScopesIdentifierChangeComment()` */
  static readonly FeatureControllerSaveAsyncresolvedScopesIdentifierChangeCommentPath = '/feature/save/{identifier}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAsync$Response(params: SaveAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAsync(params: SaveAsync$Params, context?: HttpContext): Observable<void> {
    return this.saveAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `featureControllerGetScopesidentifier()` */
  static readonly FeatureControllerGetScopesidentifierPath = '/feature/{identifier}/scopes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScopes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScopes$Plain$Response(params: GetScopes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResolvedScope>>> {
    return getScopes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScopes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScopes$Plain(params: GetScopes$Plain$Params, context?: HttpContext): Observable<Array<ResolvedScope>> {
    return this.getScopes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResolvedScope>>): Array<ResolvedScope> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScopes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScopes$Response(params: GetScopes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResolvedScope>>> {
    return getScopes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScopes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScopes(params: GetScopes$Params, context?: HttpContext): Observable<Array<ResolvedScope>> {
    return this.getScopes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResolvedScope>>): Array<ResolvedScope> => r.body)
    );
  }

  /** Path part for operation `featureControllerGetFeaturesForEditlevelIntRefIdGuidRefIdForInternalUserRoleIdOrgId()` */
  static readonly FeatureControllerGetFeaturesForEditlevelIntRefIdGuidRefIdForInternalUserRoleIdOrgIdPath = '/feature/{level}/foredit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeaturesForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeaturesForEdit$Plain$Response(params: GetFeaturesForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeatureForEditingVm>>> {
    return getFeaturesForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeaturesForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeaturesForEdit$Plain(params: GetFeaturesForEdit$Plain$Params, context?: HttpContext): Observable<Array<FeatureForEditingVm>> {
    return this.getFeaturesForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeatureForEditingVm>>): Array<FeatureForEditingVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeaturesForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeaturesForEdit$Response(params: GetFeaturesForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeatureForEditingVm>>> {
    return getFeaturesForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeaturesForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeaturesForEdit(params: GetFeaturesForEdit$Params, context?: HttpContext): Observable<Array<FeatureForEditingVm>> {
    return this.getFeaturesForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeatureForEditingVm>>): Array<FeatureForEditingVm> => r.body)
    );
  }

  /** Path part for operation `featureControllerEnableDisableFeaturerequest()` */
  static readonly FeatureControllerEnableDisableFeaturerequestPath = '/feature/enabledisable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableDisableFeature$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  enableDisableFeature$Plain$Response(params?: EnableDisableFeature$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeatureForEditingVm>>> {
    return enableDisableFeature$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enableDisableFeature$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  enableDisableFeature$Plain(params?: EnableDisableFeature$Plain$Params, context?: HttpContext): Observable<Array<FeatureForEditingVm>> {
    return this.enableDisableFeature$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeatureForEditingVm>>): Array<FeatureForEditingVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableDisableFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  enableDisableFeature$Response(params?: EnableDisableFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeatureForEditingVm>>> {
    return enableDisableFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enableDisableFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  enableDisableFeature(params?: EnableDisableFeature$Params, context?: HttpContext): Observable<Array<FeatureForEditingVm>> {
    return this.enableDisableFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeatureForEditingVm>>): Array<FeatureForEditingVm> => r.body)
    );
  }

  /** Path part for operation `featureControllerGetTargetedUsersrequest()` */
  static readonly FeatureControllerGetTargetedUsersrequestPath = '/feature/targetedusers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargetedUsers$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTargetedUsers$Plain$Response(params?: GetTargetedUsers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FeatureTargetedViewModel>> {
    return getTargetedUsers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTargetedUsers$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTargetedUsers$Plain(params?: GetTargetedUsers$Plain$Params, context?: HttpContext): Observable<FeatureTargetedViewModel> {
    return this.getTargetedUsers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FeatureTargetedViewModel>): FeatureTargetedViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargetedUsers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTargetedUsers$Response(params?: GetTargetedUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<FeatureTargetedViewModel>> {
    return getTargetedUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTargetedUsers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTargetedUsers(params?: GetTargetedUsers$Params, context?: HttpContext): Observable<FeatureTargetedViewModel> {
    return this.getTargetedUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<FeatureTargetedViewModel>): FeatureTargetedViewModel => r.body)
    );
  }

  /** Path part for operation `featureControllerGetTargetedRolesrequest()` */
  static readonly FeatureControllerGetTargetedRolesrequestPath = '/feature/targetedroles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargetedRoles$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTargetedRoles$Plain$Response(params?: GetTargetedRoles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FeatureTargetedViewModel>> {
    return getTargetedRoles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTargetedRoles$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTargetedRoles$Plain(params?: GetTargetedRoles$Plain$Params, context?: HttpContext): Observable<FeatureTargetedViewModel> {
    return this.getTargetedRoles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FeatureTargetedViewModel>): FeatureTargetedViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargetedRoles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTargetedRoles$Response(params?: GetTargetedRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<FeatureTargetedViewModel>> {
    return getTargetedRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTargetedRoles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTargetedRoles(params?: GetTargetedRoles$Params, context?: HttpContext): Observable<FeatureTargetedViewModel> {
    return this.getTargetedRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<FeatureTargetedViewModel>): FeatureTargetedViewModel => r.body)
    );
  }

}
