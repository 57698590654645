import { MachineFilterRequest } from './../../mibp-openapi-gen/models/machine-filter-request';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { DropdownData, DropdownInput} from '../../components/dropdown/dropdown.interface';
import { map } from 'rxjs/operators';
import { EquipmentsApiController } from 'root/mibp-openapi-gen/controllers';
import { ActivatedRoute } from '@angular/router';
import { MachineFilterSelection } from 'root/components/machine-filter/machine-filter.component';

@Injectable({
  providedIn: 'root'
})
export class MachineFilterService {

  constructor(private equipmentController: EquipmentsApiController, private route: ActivatedRoute) { }

  public anyActiveFilters(selection: MachineFilterSelection): boolean {
    if (selection) {
      return selection.group.length > 0 ||
        selection.model.length > 0 ||
        selection.machine.length > 0 ||
        selection.subgroup.length > 0;
    }
    return false;
  }


  getFiltersForEquipment(filterRequest: MachineFilterRequest): Observable<DropdownData> {
    return from(firstValueFrom(this.equipmentController.getMachineFilterForEquipments({body: filterRequest}))).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.total > filterRequest.skip + searchResult.values.length,
        items: searchResult.values.map(value => <DropdownInput>{
          text: value,
          value: value
        }),
        totalCount: searchResult.total
      }),
      catchError(error => {
        throw new Error(error);
      })
    );
  }

  getFiltersForElectronicManuals(filterRequest: MachineFilterRequest): Observable<DropdownData> {
    return from(firstValueFrom(this.equipmentController.getMachineFilterForElectronicManuals({body: filterRequest}))).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.total > filterRequest.skip + searchResult.values.length,
        items: searchResult.values.map(value => <DropdownInput>{
          text: value,
          value: value,
        }),
        totalCount: searchResult.total
      }),
      catchError(error => {
        throw new Error(error);
      })
    );
  }

  getFiltersForPartsManuals(filterRequest: MachineFilterRequest): Observable<DropdownData> {
    return from(firstValueFrom(this.equipmentController.getMachineFilterForPartsManuals({body: filterRequest}))).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.total > filterRequest.skip + searchResult.values.length,
        items: searchResult.values.map(value => <DropdownInput>{
          text: value,
          value: value
        }),
        totalCount: searchResult.total
      }),
      catchError(error => {
        throw new Error(error);
      })
    );
  }

  getFiltersForKits(filterRequest: MachineFilterRequest): Observable<DropdownData> {
    return from(firstValueFrom(this.equipmentController.getMachineFilterForKit({body: filterRequest}))).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.total > filterRequest.skip + searchResult.values.length,
        items: searchResult.values.map(value => <DropdownInput>{
          text: value,
          value: value
        }),
        totalCount: searchResult.total
      }),
      catchError(error => {
        throw new Error(error);
      })
    );
  }

  getFiltersForLocations(filterRequest: MachineFilterRequest): Observable<DropdownData> {
    return from(firstValueFrom(this.equipmentController.getMachineFilterForLocation({body: filterRequest}))).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.total > filterRequest.skip + searchResult.values.length,
        items: searchResult.values.map(value => <DropdownInput>{
          text: value,
          value: value
        }),
        totalCount: searchResult.total
      }),
      catchError(error => {
        throw new Error(error);
      })
    );
  }

  getFiltersForBulletins(filterRequest: MachineFilterRequest): Observable<DropdownData> {
    return from(firstValueFrom(this.equipmentController.getMachineFilterForBulletin({body: filterRequest}))).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.total > filterRequest.skip + searchResult.values.length,
        items: searchResult.values.map(value => <DropdownInput>{
          text: value,
          value: value
        }),
        totalCount: searchResult.total
      }),
      catchError(error => {
        throw new Error(error);
      })
    );
  }

  getFiltersForPromotions(filterRequest: MachineFilterRequest, promotionId : number): Observable<DropdownData> {
    return from(firstValueFrom(this.equipmentController.getMachineFilterForPromotions({body: filterRequest, promotionId: promotionId}))).pipe(
      map(searchResult => <DropdownData>{
        hasMoreResults: searchResult.total > filterRequest.skip + searchResult.values.length,
        items: searchResult.values.map(value => <DropdownInput>{
          text: value,
          value: value
        }),
        totalCount: searchResult.total
      }),
      catchError(error => {
        throw new Error(error);
      })
    );
  }

  getInitialMachineFiltersFromRoute(){
    if(this.route.snapshot.queryParams?.machinefilter){
      let filterParams: { [key: string]: string[] };
      try {
        filterParams = JSON.parse(this.route.snapshot.queryParams?.machinefilter);
      } catch(e) {
        filterParams = {};
      }
      return <MachineFilterSelection>{
        machine : filterParams?.machine ?? [],
        model : filterParams?.model ?? [],
        group : filterParams?.group ?? [],
        subgroup : filterParams?.subgroup ?? []
      };
    }
  }

}
