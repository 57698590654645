/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { actAsAll } from '../fn/session/act-as-all';
import { ActAsAll$Params } from '../fn/session/act-as-all';
import { actAsAll$Plain } from '../fn/session/act-as-all-plain';
import { ActAsAll$Plain$Params } from '../fn/session/act-as-all-plain';
import { actAsDeliverySequence } from '../fn/session/act-as-delivery-sequence';
import { ActAsDeliverySequence$Params } from '../fn/session/act-as-delivery-sequence';
import { actAsDeliverySequence$Plain } from '../fn/session/act-as-delivery-sequence-plain';
import { ActAsDeliverySequence$Plain$Params } from '../fn/session/act-as-delivery-sequence-plain';
import { actAsEncodedDeliverySequence } from '../fn/session/act-as-encoded-delivery-sequence';
import { ActAsEncodedDeliverySequence$Params } from '../fn/session/act-as-encoded-delivery-sequence';
import { actAsEncodedDeliverySequence$Plain } from '../fn/session/act-as-encoded-delivery-sequence-plain';
import { ActAsEncodedDeliverySequence$Plain$Params } from '../fn/session/act-as-encoded-delivery-sequence-plain';
import { changeConnectionAndUserLanguage } from '../fn/session/change-connection-and-user-language';
import { ChangeConnectionAndUserLanguage$Params } from '../fn/session/change-connection-and-user-language';
import { getCurrentSession } from '../fn/session/get-current-session';
import { GetCurrentSession$Params } from '../fn/session/get-current-session';
import { getCurrentSession$Plain } from '../fn/session/get-current-session-plain';
import { GetCurrentSession$Plain$Params } from '../fn/session/get-current-session-plain';
import { getPunchoutProfiles } from '../fn/session/get-punchout-profiles';
import { GetPunchoutProfiles$Params } from '../fn/session/get-punchout-profiles';
import { getPunchoutProfiles$Plain } from '../fn/session/get-punchout-profiles-plain';
import { GetPunchoutProfiles$Plain$Params } from '../fn/session/get-punchout-profiles-plain';
import { MibpGetUserSessionResponseViewModel } from '../models/mibp-get-user-session-response-view-model';
import { ping } from '../fn/session/ping';
import { Ping$Params } from '../fn/session/ping';
import { PunchoutProfileViewModel } from '../models/punchout-profile-view-model';
import { refreshSessionToken } from '../fn/session/refresh-session-token';
import { RefreshSessionToken$Params } from '../fn/session/refresh-session-token';

@Injectable({ providedIn: 'root' })
export class SessionApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sessionControllerGetCurrentSession()` */
  static readonly SessionControllerGetCurrentSessionPath = '/session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentSession$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSession$Plain$Response(params?: GetCurrentSession$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return getCurrentSession$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentSession$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSession$Plain(params?: GetCurrentSession$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.getCurrentSession$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSession$Response(params?: GetCurrentSession$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return getCurrentSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentSession(params?: GetCurrentSession$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.getCurrentSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /** Path part for operation `sessionControllerGetPunchoutProfiles()` */
  static readonly SessionControllerGetPunchoutProfilesPath = '/session/punchoutprofiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPunchoutProfiles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles$Plain$Response(params?: GetPunchoutProfiles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PunchoutProfileViewModel>>> {
    return getPunchoutProfiles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPunchoutProfiles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles$Plain(params?: GetPunchoutProfiles$Plain$Params, context?: HttpContext): Observable<Array<PunchoutProfileViewModel>> {
    return this.getPunchoutProfiles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PunchoutProfileViewModel>>): Array<PunchoutProfileViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPunchoutProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles$Response(params?: GetPunchoutProfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PunchoutProfileViewModel>>> {
    return getPunchoutProfiles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPunchoutProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPunchoutProfiles(params?: GetPunchoutProfiles$Params, context?: HttpContext): Observable<Array<PunchoutProfileViewModel>> {
    return this.getPunchoutProfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PunchoutProfileViewModel>>): Array<PunchoutProfileViewModel> => r.body)
    );
  }

  /** Path part for operation `sessionControllerRefreshSessionToken()` */
  static readonly SessionControllerRefreshSessionTokenPath = '/session/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshSessionToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshSessionToken$Response(params?: RefreshSessionToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return refreshSessionToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refreshSessionToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshSessionToken(params?: RefreshSessionToken$Params, context?: HttpContext): Observable<void> {
    return this.refreshSessionToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sessionControllerPing()` */
  static readonly SessionControllerPingPath = '/session/ping';

  /**
   * Will update LastSeen value in the connection so we'll have a second way to see if a user is still on the site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ping()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping$Response(params?: Ping$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ping(this.http, this.rootUrl, params, context);
  }

  /**
   * Will update LastSeen value in the connection so we'll have a second way to see if a user is still on the site.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping(params?: Ping$Params, context?: HttpContext): Observable<void> {
    return this.ping$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sessionControllerChangeConnectionAndUserLanguagelanguageCode()` */
  static readonly SessionControllerChangeConnectionAndUserLanguagelanguageCodePath = '/session/language/{languageCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeConnectionAndUserLanguage()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeConnectionAndUserLanguage$Response(params: ChangeConnectionAndUserLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeConnectionAndUserLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeConnectionAndUserLanguage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeConnectionAndUserLanguage(params: ChangeConnectionAndUserLanguage$Params, context?: HttpContext): Observable<void> {
    return this.changeConnectionAndUserLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sessionControllerActAsDeliverySequencedeliverySequenceId()` */
  static readonly SessionControllerActAsDeliverySequencedeliverySequenceIdPath = '/session/active-deliverysequence/{deliverySequenceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsDeliverySequence$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsDeliverySequence$Plain$Response(params: ActAsDeliverySequence$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsDeliverySequence$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsDeliverySequence$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsDeliverySequence$Plain(params: ActAsDeliverySequence$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsDeliverySequence$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsDeliverySequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsDeliverySequence$Response(params: ActAsDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsDeliverySequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsDeliverySequence(params: ActAsDeliverySequence$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /** Path part for operation `sessionControllerActAsEncodedDeliverySequenceencodeddeliverySequence()` */
  static readonly SessionControllerActAsEncodedDeliverySequenceencodeddeliverySequencePath = '/session/encoded-deliverysequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsEncodedDeliverySequence$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsEncodedDeliverySequence$Plain$Response(params?: ActAsEncodedDeliverySequence$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsEncodedDeliverySequence$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsEncodedDeliverySequence$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsEncodedDeliverySequence$Plain(params?: ActAsEncodedDeliverySequence$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsEncodedDeliverySequence$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsEncodedDeliverySequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsEncodedDeliverySequence$Response(params?: ActAsEncodedDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsEncodedDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsEncodedDeliverySequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsEncodedDeliverySequence(params?: ActAsEncodedDeliverySequence$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsEncodedDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /** Path part for operation `sessionControllerActAsAll()` */
  static readonly SessionControllerActAsAllPath = '/session/active-deliverysequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsAll$Plain$Response(params?: ActAsAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsAll$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsAll$Plain(params?: ActAsAll$Plain$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsAll$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actAsAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsAll$Response(params?: ActAsAll$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGetUserSessionResponseViewModel>> {
    return actAsAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `actAsAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actAsAll(params?: ActAsAll$Params, context?: HttpContext): Observable<MibpGetUserSessionResponseViewModel> {
    return this.actAsAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGetUserSessionResponseViewModel>): MibpGetUserSessionResponseViewModel => r.body)
    );
  }

}
