<mibp-dialog #dlg title="Grid_EditColumns" (opened)="onDialogOpen()" (closed)="onDialogClosed()" fullheight="true">
  <p><mibp-resource-string key="Grid_EditColumns_DescribeText"></mibp-resource-string></p>

  <form [formGroup]="editColumnsForm" class="edit-column-form checkbox">
      <div formArrayName="columns" *ngFor="let column of editColumnsFormArray.controls; let i = index">
        <input type="checkbox" [id]="'EditColumns' + i" [formControlName]="i" [name]="editableColumns[i].resourceStringKey" >
        <label [for]="'EditColumns' + i"><mibp-resource-string [key]="editableColumns[i].resourceStringKey"></mibp-resource-string></label>
      </div>

      <mibp-validation-messages [ngForm]="editColumnsForm" controlName="columns"
        [errors]="editColumnsForm.controls['columns'].errors" >
        <mibp-validation-message forError="required">
          <mibp-resource-string key="Grid_EditColumns_RequiredValidationMessage"></mibp-resource-string>
        </mibp-validation-message>
    </mibp-validation-messages>

    </form>
    <div class="buttons">
      <mibp-button resourceStringKey="Global_Save" class="left-button" [color]="btn.Enum.Colors.Orange" [style]="btn.Enum.Styles.Fill" (click)="submitForm()" [disabled]="editColumnsForm.invalid" #btn></mibp-button>
      <mibp-button [resourceStringKey]="'Global_CancelButton'"  [color]="btn.Enum.Colors.Orange" (click)="onDialogClosed()"></mibp-button>
    </div>

</mibp-dialog>
