import { Component, Input } from '@angular/core';
import { GlobalConfigService } from 'root/services';

@Component({
  selector: 'mibp-equipment-status',
  templateUrl: './equipment-status.component.html',
  styleUrls: ['./equipment-status.component.scss']
})
export class EquipmentStatusComponent {

  @Input() status: string;

  constructor(protected globalConfig: GlobalConfigService) {}

}
