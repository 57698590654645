<form class="my-actascomponent" [formGroup]="actAsForm">
  <ul class="actas" (mouseleave)="onMouseLeave()" (mouseenter)="onMouseEnter()" (window:resize)="hide()" #actAsElement [class.hidden]="!isVisible" [class.is-acting]="isActingAs" >
    <li class="container is-full">
        <ng-content></ng-content>
        <div class="columns">
            <div class="column is-12 actascolumn">
              <mibp-deliverysequence-picker
                [hideUnavailableElements]="true"
                formControlName="deliverySequence"
                (dropdownShow)="disableHideOnMouseout()"
                (dropdownHide)="enableHideOnMouseout()"
                [filterInactive]="true"
              ></mibp-deliverysequence-picker>
          </div>
          </div>
        <div class="columns buttonrow">
          <div class="column is-12 buttons actascolumn">
            <mibp-button type="submit" [displayBlock]="displayButtonsAsBlocks" class="my-actascomponent__actas" (click)="submitForm('actAs')" [disabled]="!actAsForm.valid || !actAsForm.value.deliverySequence || disableActAs" resourceStringKey="ActAs_ActAsAccount_Button" [color]="btn.Enum.Colors.Primary"></mibp-button>
            <mibp-button type="submit" [displayBlock]="displayButtonsAsBlocks" class="my-actascomponent__actasall" (click)="submitForm('actAsAll')" [disabled]="actAsForm.pending || disableActAs" resourceStringKey="ActAs_ActAsAllAccounts_Button" [color]="btn.Enum.Colors.Secondary" #btn></mibp-button>
          </div>
        </div>
    </li>
  </ul>
</form>
