
<div [ngClass]="classNames">

  <form [formGroup]="profileForm">
   <div class="my-profile-picker-card__image" style="position: relative;">
    <span class="my-status-label my-status-label--warning" style="position: absolute; top:0;left:0;">Logged in</span>
    <span translate="no" class="material-icon">{{profileIcon}}</span>
  </div>

  <div class="my-profile-picker-card__details" #profileDetails>
    <div class="my-profile-picker-card__name">
      <!-- Default name view -->
      <ng-container *ngIf="!isEditing && profile">
        <span>{{ profileForm.value.name }}</span>
        <button class="my-profile-picker-card__edit" [disabled]="profile.isLoggedIn || isLoading" (click)="editStart()"><span translate="no" class="material-icon">edit</span></button>
      </ng-container>

      <!-- Editing name of existing profile -->
      <ng-container *ngIf="isEditing">
        <input style="width: 100%;" (keydown.enter)="editSave( $event)" (keydown.escape)="editCancel()" formControlName="name" type="text">
      </ng-container>

      <!-- Name of new user form -->
      <ng-container *ngIf="!profile">
        <span>{{ profileForm.value.name || '?' }}</span>
      </ng-container>
    </div>
  </div>

  <div class="my-profile-picker-card__actions">

    <!-- Actions for existing users -->
    <ng-container *ngIf="profile" [ngSwitch]="isEditing">

      <ng-container  *ngSwitchCase="false">
        <mibp-button [color]="buttonColors.Blue" class="my-profile-picker-card__login" (click)="loginAsProfile()" [disabled]="loginDisabled || profile.isLoggedIn || isLoading" [resourceStringKey]="'Global_Login'" [displayBlock]="true" ></mibp-button>
        <mibp-button [color]="buttonColors.Orange" *ngIf="!profile?.isOriginalPunchoutUser" class="my-profile-picker-card__delete" [showElementLoader]="isLoading" (click)="deleteProfile()" [disabled]="profile.isLoggedIn || deleteDisabled" [resourceStringKey]="'Global_Delete'" [displayBlock]="true" ></mibp-button>
        <p *ngIf="profile?.isOriginalPunchoutUser" class="main-profile"><mibp-resource-string key="MainPunchoutProfile_CanNotBeRemoved"></mibp-resource-string></p>
      </ng-container>

      <ng-container *ngSwitchCase="true">
        <mibp-button [color]="buttonColors.Orange" class="my-profile-picker-card__save" [showElementLoader]="isLoading" (click)="editSave()" [disabled]="isLoading || profileForm.invalid || profile.isLoggedIn" [style]="buttonStyles.Fill" [resourceStringKey]="'Global_Save'" [displayBlock]="true" ></mibp-button>
        <mibp-button [color]="buttonColors.Orange" class="my-profile-picker-card__cancel" (click)="editCancel()" [disabled]="isLoading" [resourceStringKey]="'Global_CancelButton'" [displayBlock]="true" ></mibp-button>
      </ng-container>

    </ng-container>

    <!-- Actions for new users -->

    <ng-container *ngIf="!profile">
      <input type="text" formControlName="name" [disabled]="isLoading" (keydown.enter)="createNewProfile($event)" style="display: block; width:100%;">
        <mibp-button [color]="buttonColors.Blue"
        class="my-profile-picker-card__save"
        (click)="createNewProfile()"
        [showElementLoader]="isLoading"
        [style]="buttonStyles.Fill" [disabled]="profileForm.invalid"
        [resourceStringKey]="'Global_Add'" [displayBlock]="true" ></mibp-button>
    </ng-container>


  </div>

</form>

</div>
