// import { ApplicationStateService } from 'root/services/application-state/application-state.service';
// import { BroadcastService } from 'root/services/broadcast-service/broadcast.service';
import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { ActivationArgs } from "root/guards";
import { LogService } from "../logservice";
// import { SignalRService } from "../signalr-service/signalr.service";
// import { ApplicationStates } from '../application-state/application-state.types';

/**
 * Use from startup guard.
 * Will make sure a backend connection exists and connect if it does not
 */
@Injectable({
  providedIn: 'root'
})
export class StartupBackendService {


  constructor(
    logger: LogService,
    // private broadcastService: BroadcastService,
    // private appState: ApplicationStateService,
    // private signalrService: SignalRService
     ) {}

  public async testBackend(args: ActivationArgs): Promise<boolean | UrlTree> {

    //if (this.broadcastService.snapshot.signalR?.status === 'connected' || this.broadcastService.snapshot.signalR?.status === 'connecting' ) {
      return Promise.resolve(true);
    //}


    // this.appState.setState({ state: ApplicationStates.ConnectingToSignalR, resourceStringKey: 'AppLoading_Connection', textFallback: 'Connecting to Services' });

    // const connected = await this.signalrService.connect();


    // this.appState.setState({ state: ApplicationStates.ConnectingToSignalR, resourceStringKey: 'asd', textFallback: 'asdConnecting to Services' });

    // // null if not logged in
    // return connected == null || connected.status === 'connected';
  }

}
