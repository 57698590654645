import { NavigationService } from 'root/services';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { AuthService } from './../../../../services/auth-service/auth.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'mibp-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})
export class MibpHeaderSearchComponent implements OnInit, OnDestroy {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  showSearch = true;
  routerEventSub: Subscription;
  userToggled = false;
  searchHiddenByUser = false;

  constructor(private auth: AuthService,
              private navigationService: NavigationService,
              private route: ActivatedRoute,
              private router: Router) {}

  ngOnInit(): void {
    this.routerEventSub = this.router.events
      .pipe(filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent))
      .subscribe((e: RouterEvent) => this.onRouterEvent(e));
    this.hideSearchOnSearchResultPage();
  }

  ngOnDestroy(): void {
    this.routerEventSub?.unsubscribe();
  }

  public logout(e: UIEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.auth.signout();
  }

  triggerSearch(e: KeyboardEvent): void {
    e.preventDefault();
    e.stopPropagation();

    const searchText = this.searchInput.nativeElement.value.trim();
    if (searchText) {
      this.navigationService.navigateTo('shop/products', {
        queryParams: {
          query: searchText
        }
      });
      this.searchInput.nativeElement.value = '';
    }
  }

  userToggleSearch(): void {
    this.userToggled = true;
    if (this.showSearch) {
      this.searchHiddenByUser = true;
    } else {
      this.searchHiddenByUser = false;
    }
    if (this.showSearch === false) {
    }
    this.showSearch = !this.showSearch;
  }

  onRouterEvent(e: RouterEvent): void {
    if (e instanceof NavigationEnd) {
      this.hideSearchOnSearchResultPage();
    }
  }

  private hideSearchOnSearchResultPage(): void {
    if (this.navigationService.getByActivatedRoute(this.route)?.uniqueId === '#product-search') {
      if (this.showSearch) {
        this.showSearch = false;
      }
    } else {
      if (!this.showSearch && !this.searchHiddenByUser) {
        this.showSearch = true;
      }
    }
  }

}
