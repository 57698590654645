import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/ecommerce-app.module';
import { environment } from 'root/environment';
import { AppInjector } from 'root/services';

//if (environment.name !== 'lde') {
enableProdMode();
//}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((moduleRef) => {
    AppInjector.setInjector(moduleRef.injector);
  })
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));

