<ng-template [ngIf]="isAllowedToSeePrice && isAuroraCompany">
  <ng-template [ngIf]="isLoading && !hasPriceErrors">
    <mibp-loader type="spinner"></mibp-loader>
    <!-- <span class="debug-info" *ngIf="isDebugLogLevel && !hideOnAllErrors" #r (click)="showPopover(r)"><fa-icon [icon]="icons.debug"></fa-icon></span> -->
  </ng-template>

  <!--
    ## PRICE WITH UNIT OF MEASURE (ASSUMING QUANTITY 1)
    This is how the price is shown in most product listings (active cart, templates, saved, product search etc.)
  -->
  <ng-template [ngIf]="!isLoading && currentProductPrice?.netPrice && displayStyle === 'cartitem' && !quantityIsSet">

    <span class="base-price"  [class.base-price--block]="displayBlock" *ngIf="!isNetPriceOnly">
      <mibp-resource-string  *ngIf="currentProductPrice?.basePrice"
        key="Carts_List_Item_BasePrice_Text"
        [macros]="{ price : formatPrice(currentProductPrice?.basePrice), currency: currencyCode}"></mibp-resource-string>
        <em *ngIf="priceUnitOfMeasure && !currentProductPrice?.netPrice" class="price-unit">
          <mibp-resource-string [key]="'Global_UoM_' + priceUnitOfMeasure" [defaultValue]="priceUnitOfMeasure"></mibp-resource-string>
        </em>

    </span>

    <span class="net-price" [class.net-price--block]="displayBlock">
      <mibp-resource-string  *ngIf="currentProductPrice?.netPrice"
        key="Carts_List_Item_NetPrice_Text"
        [macros]="{ price : formatPrice(currentProductPrice?.netPrice), currency: currencyCode}"></mibp-resource-string> <!--  + currentProductPrice?.taxValue -->
        <em *ngIf="priceUnitOfMeasure" class="price-unit">
          <mibp-resource-string [key]="'Global_UoM_' + priceUnitOfMeasure" [defaultValue]="priceUnitOfMeasure"></mibp-resource-string>
        </em>
        <mibp-support-menu (action)="onSupportMenuAction($event)" [items]="supportMenuItems"></mibp-support-menu>
    </span>

    <!-- <span class="debug-info" *ngIf="isDebugLogLevel && !hideOnAllErrors" #r (click)="showPopover(r)"><fa-icon [icon]="icons.debug"></fa-icon></span> -->
  </ng-template>



  <!--
    ## TOTAL SUM - PRICE WITHOUT UNIT OF MEASURE (CALCUALTE SUM USING QUANTITY)
    # Total sum in most product listings (carts)
  -->
  <ng-template [ngIf]="!isLoading && currentProductPrice && displayStyle === 'cartitem' && quantityIsSet">

    <span class="net-price" [class.net-price--block]="displayBlock">
      <mibp-resource-string  *ngIf="currentProductPrice?.netPrice"
        key="Carts_List_Item_NetPrice_Text"
        [macros]="{ price : formatPrice( currentProductPrice?.netPrice * (quantity || 0)), currency: currencyCode}"></mibp-resource-string>
    </span>

    <!-- <span class="debug-info" *ngIf="isDebugLogLevel && !hideOnAllErrors" #r (click)="showPopover(r)"><fa-icon [icon]="icons.debug"></fa-icon></span> -->

  </ng-template>

  <!--
    ## CHECKOUT PAGE PRODUCT PRICE
  -->
  <ng-template [ngIf]="!isLoading && currentProductPrice && displayStyle === 'checkout' && !priceHasError">
    <mibp-resource-string
        key="Carts_List_Item_NetPrice_Text"
        [macros]="{ price : formatPrice(currentProductPrice?.netPrice), currency: currencyCode }"></mibp-resource-string>
        <mibp-support-menu (action)="onSupportMenuAction($event)" [items]="supportMenuItems"></mibp-support-menu>
  </ng-template>

  <!--
    ## IF ANY ERRORS
  -->
  <ng-template [ngIf]="priceHasError && !hideOnAllErrors && !isLoading">

    <ng-template [ngIf]="showRequestPriceDueToNotInResponse">
      <a href="javascript:void(0)" class="contact-us" (click)="onRequestPrice()">
        <mibp-resource-string key="Global_Carts_RequestPrice"></mibp-resource-string>
      </a>
      <mibp-support-menu (action)="onSupportMenuAction($event)" [items]="supportMenuItems"></mibp-support-menu>
    </ng-template>

    <ng-template [ngIf]="showRequestPriceDueToZeroPrice">

      <div class="text-error text-error--nocaps" *ngIf="!quantity && currentProductPrice?.errorCode == 'INVALID_STOCKROOM_OR_COMPANY_SETUP'; else zeroPriceRequestPriceLinkTemplate">
        <mibp-resource-string class="item-error" key="Carts_Error_StockroomOrCompanyNotSetup" ></mibp-resource-string>
        <mibp-support-menu (action)="onSupportMenuAction($event)" [items]="supportMenuItems"></mibp-support-menu>
      </div>

      <a href="javascript:void(0)" class="contact-us" (click)="onRequestPrice()">
        <mibp-resource-string key="Global_Carts_RequestPrice"></mibp-resource-string>
      </a>
      <mibp-support-menu (action)="onSupportMenuAction($event)" [items]="supportMenuItems"></mibp-support-menu>
    </ng-template>

    <span class="error"
    *ngIf="!showRequestPriceDueToNotInResponse && !showRequestPriceDueToZeroPrice"
    #r  title="{{ errorTooltip }}">
    <mibp-resource-string key="Carts_ErrorGettingPrice" ></mibp-resource-string>
    <!-- <span class="error--info">
      &nbsp;
      (<a href="javascript:void(0)" (click)="showPopover(r)"><mibp-resource-string key="Global_Info_Short_Text"></mibp-resource-string></a>)
      </span>-->
      <mibp-support-menu (action)="onSupportMenuAction($event)" [items]="supportMenuItems"></mibp-support-menu>
    </span>

    <!-- If error is now shown, always show debug icon for debug log level-->
    <span class="debug-info"
    *ngIf="isDebugLogLevel && (showRequestPriceDueToNotInResponse || showRequestPriceDueToZeroPrice)"
    #r (click)="showPopover(r)"><span translate="no" class="material-icon">bug_report</span></span>

  </ng-template>

</ng-template>

<ng-template [ngIf]="isAllowedToSeePrice && !isAuroraCompany">
  <a href="javascript:void(0)" class="contact-us" (click)="onRequestPrice()">
    <mibp-resource-string key="Global_Carts_RequestPrice"></mibp-resource-string>
  </a>
</ng-template>

 <!--
    ## POPOVER THAT CAN SHOW DEBUG INFO OR MESSAGES
  -->
<mibp-popover #pop>
  <h2>{{ productCode }}</h2>
  <div class="my-body my-body--nomargin">

    <div class="error-block" *ngIf="currentProductPrice?.errorCode || (priceIsZero && !showRequestPriceDueToZeroPrice)">
      <div><span translate="no" class="material-icon">error</span></div>
      <div>
        <p *ngIf="currentProductPrice?.errorCode === 'ERRDAUR002'">
          <mibp-resource-string key="Aurora_Error_ERRDAUR002"></mibp-resource-string>
        </p>
        <p *ngIf="currentProductPrice?.errorCode === 'ERRDAUR005'">
          <mibp-resource-string key="Aurora_Error_ERRDAUR005"></mibp-resource-string>
        </p>
        <p *ngIf="currentProductPrice?.errorCode === 'ERRDAUR004'">
          <mibp-resource-string key="Aurora_Error_ERRDAUR004"></mibp-resource-string>

        </p>
        <p *ngIf="currentProductPrice?.errorCode === 'ERRDAUR007'">
          <mibp-resource-string key="Aurora_Error_ERRDAUR007"></mibp-resource-string>
        </p>
        <p *ngIf="!currentProductPrice?.errorCode && priceIsZero">
          <mibp-resource-string key="Aurora_Error_ZeroPrice"></mibp-resource-string>
        </p>
      </div>
    </div>

    <div class="success-block" *ngIf="!isLoading && !currentProductPrice?.errorCode && isDebugLogLevel">
      <div><span translate="no" class="material-icon">done</span></div>
      <div>
        <p>
          Operation was successful
        </p>
      </div>
    </div>

  </div>

  <div *ngIf="isDebugLogLevel">
    <table class="my-table">
      <caption>
        Debug information
      </caption>
      <tr>
        <th>Source</th><td>{{ priceSourceInformation }}</td>
      </tr>
      <tr>
        <th>ProductCode</th><td>{{ productCode }}</td>
      </tr>
      <tr>
        <th>Loading</th><td>{{ isLoading ? 'Yes' : 'No' }}</td>
      </tr>
      <tr>
        <th>Update Triggered</th><td>{{ updateIsTriggered ? 'Yes' : 'No' }}</td>
      </tr>
      <tr>
        <th>Request Price (When not available in aurora response)</th><td>{{ showRequestPriceDueToNotInResponse ? 'Yes' : 'No' }}</td>
      </tr>
      <tr>
        <th>Request Price (When Aurora price is 0)</th><td>{{ showRequestPriceDueToZeroPrice ? 'Yes' : 'No' }}</td>
      </tr>
    </table>
    <pre>{{ currentProductPrice | json }}</pre>
  </div>
</mibp-popover>
