/* tslint:disable */
/* eslint-disable */
export enum DownloadedManualFileType {
  Unknown = 0,
  Pdf = 1,
  Zip = 2,
  MicrosoftExcel = 3,
  MicrosoftExcelOpenXml = 4,
  PlainText = 5
}
