import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { PaginationService } from 'root/services/pagination-service/pagination.service';

export interface PagingNode {
  type: 'page' | 'dropdown';
  text: string;
  pageNumber?: number;
  active?: boolean;
}

export interface PagingArgs {
  skip: number;
  pageNumber: number;
}

@Component({
  selector: 'mibp-grid-paging',
  templateUrl: './mibp-grid-paging.component.html',
  styleUrls: ['./mibp-grid-paging.component.scss']
})
export class MibpGridPagingComponent implements OnInit, OnChanges {

  @Input() currentIndex = 0;
  @Input() totalCount = 0;
  @Input() pageSize = 0;
  @Input() visiblePages = 5;
  @Input() disabled = false;
  @Output() goto = new EventEmitter<PagingArgs>();
  items: PagingNode[] = [];
  showNextPagingPage = false;
  showPrevPagingPage = false;
  isFirstPage = false;
  isLastPage = false;

  pageCount = 0;


  constructor(private paginationService: PaginationService) { }

  ngOnInit() {

  }

  ngOnChanges() {

    // Calculate items

    this.pageCount = Math.ceil(this.totalCount / this.pageSize);

    const currentPage = this.paginationService.getpageFromIndex(this.currentIndex, this.pageSize);
    const paginationInfo = this.paginationService.getPager(this.totalCount, currentPage, this.pageSize, false, this.visiblePages);
    const tva = this.paginationService.getFixedPager(this.totalCount, this.pageSize, currentPage - 1, this.visiblePages);
    this.items.splice(0, this.items.length);

    tva.forEach(pageNumber => {
      this.items.push({
        text: pageNumber.toString(), type: 'page', pageNumber: pageNumber - 1, active: currentPage === pageNumber
      });
    });

    if (tva.length > 0 && tva[tva.length - 1] < this.pageCount ) {
      this.showNextPagingPage = true;
    } else {
      this.showNextPagingPage = false;
    }

    this.showPrevPagingPage = tva.length > 0 && tva[0] > 1;

    this.isFirstPage = currentPage === 1;
    this.isLastPage = currentPage === this.pageCount;

  }

  triggerGotoPageEvent(pageNumber: number, e?: Event) {
    if (e) {
      e.preventDefault();
    }
    if (this.disabled) {
      return;
    }
    this.goto.emit({
      pageNumber: pageNumber,
      skip: this.paginationService.getIndexFromZeroBasedPageNumber(pageNumber, this.pageSize)
    } as PagingArgs);
  }

  goToNextPage(e: Event) {
    e.preventDefault();
    const newValue = (this.currentIndex + this.pageSize );
    if (newValue <= this.totalCount ) {
      this.triggerGotoPageEvent(this.paginationService.getpageFromIndex(newValue, this.pageSize) - 1);
    }
  }

  goToPrevPage(e: Event) {
    e.preventDefault();
    const newValue = (this.currentIndex - this.pageSize );
    if (newValue >= 0) {
      this.triggerGotoPageEvent(this.paginationService.getpageFromIndex(newValue, this.pageSize) - 1);
    }
  }

  goToPrevPageList(e: Event) {
    e.preventDefault();
    const firstPageOnPrevList = this.items[0].pageNumber - 1;
    this.triggerGotoPageEvent(firstPageOnPrevList);
  }

  goToNextPageList(e: Event) {
    e.preventDefault();
    const firstPageOnNextList = this.items[this.items.length - 1].pageNumber + 1;
    this.triggerGotoPageEvent(firstPageOnNextList);
  }

}
