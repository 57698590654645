
<div *ngIf="type === 'loader'">
  <div class="loading-new">
    <div>
      <span class="circle">
        <span class="left"><span class="anim"></span></span>
      </span>
    </div>
  </div>
  <span class="appLoaderProgress">{{resourceStringKey}}</span>
</div>

<div *ngIf="type === 'spinner'" class="loading-small" [class.disabled]="disabledBackgroundColor"><div><span class="circle"><span class="left"><span class="anim"></span></span></span></div></div>

<div *ngIf="type === 'progressbar'" class="progress-bar" style="width: 90%; margin-top: 8px;">
  <div [style.width]="percent ? percent + '%' : null"><span>{{percent}} %</span></div>
</div>

<div *ngIf="type === 'indeterminate-progressbar'" class="progress-bar indeterminate" [class.indeterminate--text]="resourceStringKey">
  <div class="progress indeterminate"></div>
  <mibp-resource-string *ngIf="resourceStringKey" [key]="resourceStringKey" class="appLoaderProgress"></mibp-resource-string>
</div>

<ng-template #fullscreenTemplate>
  <div class="fullscreen" *ngIf="isFullscreenLoaderVisible">
      <div class="loading-new">
        <div>
          <span class="circle">
            <span class="left"><span class="anim"></span></span>
          </span>
        </div>
      </div>
  </div>
</ng-template>
