import { ResourceStringEditorService } from 'root/services';
import { Component, OnInit, OnDestroy, Input, ElementRef, Renderer2, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { LocalizationService, FrontendContextService } from 'root/services/index';
import { Subscription } from 'rxjs';
import { ResourceStringEditorDirective } from '../resource-string-editor/resource-string-editor.directive';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

@Component({
  templateUrl: './resource-string.component.html',
  selector: 'mibp-resource-string',
  styleUrls: ['./resource-string.component.scss']
})
export class ResourceStringComponent implements OnInit, OnDestroy, OnChanges {

  // Dynamically add mibpResourceString editor directive since element has no child elements that can have the attribute
  @HostBinding('attr.mibpResourceStringEditor') editorDirective =
    new ResourceStringEditorDirective(this.elm, this.context, this.mibpSession, this.renderer, this.editorService, this.localizationService);

  resourceSubscription: Subscription;
  private rawValue: string;
  macroValues: { [f: string]: string};
  markedHtml?: SafeHtml;
  @Input() defaultValue: string;
  @Input() key: string;
  @Input() titleAttr = false;
  @Input() truncate = false;
  @Input() isHtml: boolean;
  @Input() markText?: string;
  @Input() formatFunction: (value: string, macros: { [key: string]: string} ) => string;
  @Input()
  set macros(macros: { [f: string]: string}) {
    this.macroValues = macros;
    if (this.rawValue) {
      if (this.isHtml) {
        this.resourceStringHtmlValue = this.sanitizer.bypassSecurityTrustHtml(this.replaceMacroValues(this.rawValue));
      } else {
        this.resourseStringTextValue = this.replaceMacroValues(this.rawValue);
      }
    }
  }

  public resourseStringTextValue = '';
  public resourceStringHtmlValue: SafeHtml;


  constructor(private localizationService: LocalizationService,
    private context: FrontendContextService,
    private editorService: ResourceStringEditorService,
    private sanitizer: DomSanitizer,
    private elm: ElementRef,
    private mibpSession: MibpSessionService,
    private renderer: Renderer2) {
  }

  markedText(text: string): SafeHtml {

    if (text?.toLowerCase().includes(this.markText?.toLowerCase())) {
      const re = new RegExp(`(${this.regExpEscape(this.markText)})`, 'gi');
      return this.sanitizer.bypassSecurityTrustHtml(text.replace(re, `<mark>$1</mark>`));
    }
    return this.sanitizer.bypassSecurityTrustHtml(`${text}`);

  }

  regExpEscape(text: string): string {
    return text.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, '\\$&');
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.key) {
      const key = changes.key.currentValue;

      this.editorDirective.setResourceStrings = [key];
      this.editorDirective.ngOnInit();

      if (this.defaultValue && key) {
        const defaultData = {translations: {}};
        defaultData.translations[key.toLowerCase()] = this.defaultValue;
        this.updateData(defaultData);
      } else {
        this.updateData(this.localizationService.Locale);
      }
    } else if (changes.markText) {
      this.updateData(this.localizationService.Locale);
    }


  }

  tryGetResourceString(key: string) {
    // Try to use the fallback function to get the resource string (index.html)
    return window['tryGetResourceString'] ? window['tryGetResourceString'](key, this.localizationService.getLanguageFromBrowser()) : null;
  }

  updateData(locale: any) {
    if (!locale && this.key) {
      let value = this.tryGetResourceString(this.key);
      if (!value) {
        value = this.defaultValue;
      }
      if (value) {
        if (this.formatFunction) {
          value = this.formatFunction(value, this.macroValues || {});
        }
        this.setValue(value);
      } else {
        this.setValue( `??:${this.key}` );
      }
    } else if (locale && this.key !== null && typeof this.key === 'string' ) {
      let value = locale.translations[this.key.toLowerCase()];
      if (typeof value !== 'undefined') {
        if (this.formatFunction) {
          value = this.formatFunction(value, this.macroValues || {});
        }
        this.setValue(value);
      } else {
        if (this.defaultValue) {
          this.setValue( this.defaultValue );
        } else {
          this.setValue( `${this.localizationService.isDebugEnabled ? this.localizationService.debugMissingPrefix : ''}${locale.code}:${this.key}` );
        }
      }
    }
  }

  ngOnInit() {

    this.resourceSubscription = this.localizationService.Locale$.subscribe(locale => {
      this.updateData(locale);
    });

  }

  private setValue(data: string) {
    this.rawValue = data;
    if (this.isHtml) {
      this.resourceStringHtmlValue = this.sanitizer.bypassSecurityTrustHtml(this.replaceMacroValues(this.rawValue));
    } else {
      this.resourseStringTextValue = this.replaceMacroValues(this.rawValue);

      if (this.markText) {
        this.markedHtml = this.markedText(this.resourseStringTextValue);
      } else {
        this.markedHtml = null;
      }

    }
  }

  private replaceMacroValues(rawValue: string): string {
    let newValue = rawValue;
    if (this.macroValues) {
      const re = /\{([a-z0-9-_]+)\}/ig;
      let m;
      while ((m = re.exec(rawValue))) {
        let macroValue: string;
        const keyWithUseResourceString = m[1] + '_useResourceString';
        if (keyWithUseResourceString in this.macroValues) {
          macroValue = this.localizationService.get(this.macroValues[keyWithUseResourceString]);
        } else if (m[1] in this.macroValues) {
          macroValue = this.macroValues[m[1]];
        }
        if (typeof macroValue !== 'undefined') {
          newValue = newValue.replace(m[0], macroValue);
        }
      }
    }
    return newValue;
  }

  ngOnDestroy() {
    if (this.resourceSubscription) {
      this.resourceSubscription.unsubscribe();
    }
    this.editorDirective.ngOnDestroy();
  }
}
