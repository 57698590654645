import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { BillOfMaterialApiController } from 'root/mibp-openapi-gen/controllers';
import { PartsCatalogueFolderType } from "root/mibp-openapi-gen/models";
import { MediaFolderVm } from './../../../mibp-openapi-gen/models/media-folder-vm';
import { ApiErrorHandlerService } from 'root/services/api-error-handler/api-error-handler';
import { NoticebarService } from "root/services";
import { NoticeType } from "root/components/noticebar/noticebar.enum";
import { firstValueFrom } from 'rxjs';
import { PartsManualsService } from "../parts-manual.service";


interface ExtendedMediaFolderVm extends MediaFolderVm {
  isExpanded?: boolean;
  children?: ExtendedMediaFolderVm[];
  childrenLoading?: boolean;
  hasLoadedChildren?: boolean;
}

@Component({
  selector: 'mibp-parts-manual-treeview',
  styleUrls: ['./parts-manual-treeview.component.scss'],
  templateUrl: './parts-manual-treeview.component.html',
  providers: [PartsManualsService]
})
export class MibpPartsManualTreeviewComponent implements OnChanges {

  @Input() mediaIdentifier?: string;
  @Input() selectedFolderId?: number;
  @Output() pageSelected = new EventEmitter<MediaFolderVm[]>();
  @Input() initialFolderId?: number;

  folderTypes = PartsCatalogueFolderType;

  tree?: ExtendedMediaFolderVm[];
  public selectedPage: ExtendedMediaFolderVm;
  loadingSelectedFolder = false;
  isLoadingTree = false;



  constructor(private bomApi: BillOfMaterialApiController,
    private errorHandler: ApiErrorHandlerService,
    private noticebarService: NoticebarService,
    private partsManualService: PartsManualsService) {}

  ngOnChanges(changes: SimpleChanges): void {



    if (changes.initialFolderId && !changes.initialFolderId.currentValue && changes.initialFolderId.previousValue) {
      setTimeout(() => {
        this.loadTree();
      }, 10);
      return;
    }

    const hasInitialFolderIdChanged = !!changes.initialFolderId;
    const isFirstInitialFolderIdChange = hasInitialFolderIdChanged && changes.initialFolderId.firstChange;


    if (isFirstInitialFolderIdChange) {
      if (!changes.initialFolderId.currentValue && changes.initialFolderId.previousValue) {
        this.selectedPage = null; // Had a value, but it was cleared
      } else {
        if (changes.initialFolderId.currentValue != changes.initialFolderId.previousValue) {
          this.expandInitialFolder(); // Value changed and is not empty
        }
      }
    }

    if (changes.mediaIdentifier) {
      if (changes.mediaIdentifier.currentValue != changes.mediaIdentifier.previousValue) {
        if (changes.mediaIdentifier) {

          // Tried to make this async bu
          this.loadTree();
        }
      }
    }
  }

  public async loadTree(): Promise<void> {
    this.isLoadingTree = true;

    try {
      const folders = await firstValueFrom(this.bomApi.getMediaFolders({
        mediaIdentifier: this.mediaIdentifier
      }));
      this.isLoadingTree = false;

      this.partsManualService.prepareFolders(folders);

      this.tree = folders;
      this.expandInitialFolder();
    } catch (err) {
      this.errorHandler
        .parseError(err)
        .else(() => this.noticebarService.showText('Could not load page', NoticeType.Error, false) );
    }
  }

  /***
   * Expand the initial folder so the treeview is in the correct state
   * when the page is first loading
   */
  private async expandInitialFolder(): Promise<void> {
    if (this.initialFolderId) {
      this.loadingSelectedFolder = true;

      const existsInTree = this.findFolderById(this.initialFolderId);

      const folderPath = existsInTree ? this.getFullFolderPathFromLoadedTree(existsInTree) : await this.getFolderPath();
      let foldersToLookIn = this.tree || [];

      for(const folder of folderPath) {
        const folderToExpand = foldersToLookIn.find(f => f.id == folder.id);
        if (folderToExpand) {
          if (folderToExpand.type == PartsCatalogueFolderType.Chapter && folder != folderPath[folderPath.length - 1]) {
            await this.toggleFolder(folderToExpand, true);
            foldersToLookIn = folderToExpand.children;
          } else {
            this.selectedPage = folderToExpand;
          }
        }
      }

      this.loadingSelectedFolder = false;

    }
  }


  private async getFolderPath(): Promise<MediaFolderVm[]> {
    return new Promise((resolve, reject) => {
      this.bomApi.getFolderPath({
        folderId: this.initialFolderId,
        mediaIdentifier: this.mediaIdentifier
      }).subscribe({
        next: folderPath => {
          resolve(folderPath);
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

  private getFullFolderPathFromLoadedTree(folder: ExtendedMediaFolderVm): ExtendedMediaFolderVm[] {

    if (!folder) {
      return [];
    }

    const folders: ExtendedMediaFolderVm[] = [];
    do {
      folders.push( Object.assign({}, folder));

      if (folder.parentFolderId != null) {
        folder = Object.assign({}, this.findFolderById(folder.parentFolderId));
        delete folder.children; // We don't need all the children for every node
      } else {
        folder = null;
      }

    } while (folder);
    folders.reverse();
    return folders;
  }

  private findFolderById(folderId: number, haystack: ExtendedMediaFolderVm[] = null): ExtendedMediaFolderVm {
    haystack = haystack || this.tree || [];

    for (const folder of haystack) {
      if (folder.id == folderId ) {
        return folder;
      }
    }

    for (const folder of haystack) {
      if (folder.children) {
        for (const child of folder.children) {
          const matchingChild = this.findFolderById(folderId, child.children || []);
          if (matchingChild) {
            return matchingChild;
          }
        }
      }
    }

    return null;
  }

  selectFolder(folder: ExtendedMediaFolderVm): void {
    this.selectedPage = folder;
    this.pageSelected.emit(this.getFullFolderPathFromLoadedTree(folder));
    this.toggleFolder(folder);
  }


  async toggleFolder(folder: ExtendedMediaFolderVm, doNotCollapse = false): Promise<ExtendedMediaFolderVm[]> {

    if (folder.isExpanded && !doNotCollapse) {
      folder.isExpanded = false;
    } else {
      folder.isExpanded = true;
    }

    if (folder.hasLoadedChildren) {
      return Promise.resolve(folder.children);
    }

    return new Promise((resolve, reject) => {
      folder.childrenLoading = true;
      this.bomApi.getMediaFolders({
        mediaIdentifier: this.mediaIdentifier,
        parentFolderId: folder.id
      }).subscribe({
        next: folders => {
          folder.childrenLoading = false;
          this.partsManualService.prepareFolders(folders);
          folder.children = folders;
          folder.hasLoadedChildren = true;
          resolve(folders);
        },
        error: err => {
          folder.childrenLoading = false;
          reject(err);
        }
      });
    });


  }


}
