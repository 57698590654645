/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DashboardNewsArticleViewModel } from '../models/dashboard-news-article-view-model';
import { DashboardNewsArticleViewModelSearchResponse } from '../models/dashboard-news-article-view-model-search-response';
import { delete$ } from '../fn/news-article/delete';
import { Delete$Params } from '../fn/news-article/delete';
import { getForEdit } from '../fn/news-article/get-for-edit';
import { GetForEdit$Params } from '../fn/news-article/get-for-edit';
import { getForEdit$Plain } from '../fn/news-article/get-for-edit-plain';
import { GetForEdit$Plain$Params } from '../fn/news-article/get-for-edit-plain';
import { getNewsArticle } from '../fn/news-article/get-news-article';
import { GetNewsArticle$Params } from '../fn/news-article/get-news-article';
import { getNewsArticle$Plain } from '../fn/news-article/get-news-article-plain';
import { GetNewsArticle$Plain$Params } from '../fn/news-article/get-news-article-plain';
import { getNewsArticlesForDashboard } from '../fn/news-article/get-news-articles-for-dashboard';
import { GetNewsArticlesForDashboard$Params } from '../fn/news-article/get-news-articles-for-dashboard';
import { getNewsArticlesForDashboard$Plain } from '../fn/news-article/get-news-articles-for-dashboard-plain';
import { GetNewsArticlesForDashboard$Plain$Params } from '../fn/news-article/get-news-articles-for-dashboard-plain';
import { getNewsArticlesForUser } from '../fn/news-article/get-news-articles-for-user';
import { GetNewsArticlesForUser$Params } from '../fn/news-article/get-news-articles-for-user';
import { getNewsArticlesForUser$Plain } from '../fn/news-article/get-news-articles-for-user-plain';
import { GetNewsArticlesForUser$Plain$Params } from '../fn/news-article/get-news-articles-for-user-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { NewsArticleEditViewModel } from '../models/news-article-edit-view-model';
import { NewsArticleViewModel } from '../models/news-article-view-model';
import { rescope } from '../fn/news-article/rescope';
import { Rescope$Params } from '../fn/news-article/rescope';
import { rescope$Plain } from '../fn/news-article/rescope-plain';
import { Rescope$Plain$Params } from '../fn/news-article/rescope-plain';
import { save } from '../fn/news-article/save';
import { Save$Params } from '../fn/news-article/save';
import { search } from '../fn/news-article/search';
import { Search$Params } from '../fn/news-article/search';
import { search$Plain } from '../fn/news-article/search-plain';
import { Search$Plain$Params } from '../fn/news-article/search-plain';

@Injectable({ providedIn: 'root' })
export class NewsArticleApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `newsArticleControllerGetNewsArticlesForDashboardlanguageCode()` */
  static readonly NewsArticleControllerGetNewsArticlesForDashboardlanguageCodePath = '/newsarticle/dashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewsArticlesForDashboard$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsArticlesForDashboard$Plain$Response(params?: GetNewsArticlesForDashboard$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DashboardNewsArticleViewModel>>> {
    return getNewsArticlesForDashboard$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewsArticlesForDashboard$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsArticlesForDashboard$Plain(params?: GetNewsArticlesForDashboard$Plain$Params, context?: HttpContext): Observable<Array<DashboardNewsArticleViewModel>> {
    return this.getNewsArticlesForDashboard$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DashboardNewsArticleViewModel>>): Array<DashboardNewsArticleViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewsArticlesForDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsArticlesForDashboard$Response(params?: GetNewsArticlesForDashboard$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DashboardNewsArticleViewModel>>> {
    return getNewsArticlesForDashboard(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewsArticlesForDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsArticlesForDashboard(params?: GetNewsArticlesForDashboard$Params, context?: HttpContext): Observable<Array<DashboardNewsArticleViewModel>> {
    return this.getNewsArticlesForDashboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DashboardNewsArticleViewModel>>): Array<DashboardNewsArticleViewModel> => r.body)
    );
  }

  /** Path part for operation `newsArticleControllerGetNewsArticlesForUserpagedOptionsLanguageCode()` */
  static readonly NewsArticleControllerGetNewsArticlesForUserpagedOptionsLanguageCodePath = '/newsarticle/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewsArticlesForUser$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getNewsArticlesForUser$Plain$Response(params?: GetNewsArticlesForUser$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DashboardNewsArticleViewModelSearchResponse>> {
    return getNewsArticlesForUser$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewsArticlesForUser$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getNewsArticlesForUser$Plain(params?: GetNewsArticlesForUser$Plain$Params, context?: HttpContext): Observable<DashboardNewsArticleViewModelSearchResponse> {
    return this.getNewsArticlesForUser$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DashboardNewsArticleViewModelSearchResponse>): DashboardNewsArticleViewModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewsArticlesForUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getNewsArticlesForUser$Response(params?: GetNewsArticlesForUser$Params, context?: HttpContext): Observable<StrictHttpResponse<DashboardNewsArticleViewModelSearchResponse>> {
    return getNewsArticlesForUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewsArticlesForUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getNewsArticlesForUser(params?: GetNewsArticlesForUser$Params, context?: HttpContext): Observable<DashboardNewsArticleViewModelSearchResponse> {
    return this.getNewsArticlesForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<DashboardNewsArticleViewModelSearchResponse>): DashboardNewsArticleViewModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `newsArticleControllerGetNewsArticlearticleIdLanguageCode()` */
  static readonly NewsArticleControllerGetNewsArticlearticleIdLanguageCodePath = '/newsarticle/{articleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewsArticle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsArticle$Plain$Response(params: GetNewsArticle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NewsArticleViewModel>> {
    return getNewsArticle$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewsArticle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsArticle$Plain(params: GetNewsArticle$Plain$Params, context?: HttpContext): Observable<NewsArticleViewModel> {
    return this.getNewsArticle$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewsArticleViewModel>): NewsArticleViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewsArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsArticle$Response(params: GetNewsArticle$Params, context?: HttpContext): Observable<StrictHttpResponse<NewsArticleViewModel>> {
    return getNewsArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewsArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewsArticle(params: GetNewsArticle$Params, context?: HttpContext): Observable<NewsArticleViewModel> {
    return this.getNewsArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewsArticleViewModel>): NewsArticleViewModel => r.body)
    );
  }

  /** Path part for operation `newsArticleControllerSearchoptions()` */
  static readonly NewsArticleControllerSearchoptionsPath = '/newsarticle/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain$Response(params?: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain(params?: Search$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `newsArticleControllerSavesaveNewsArticleViewModel()` */
  static readonly NewsArticleControllerSavesaveNewsArticleViewModelPath = '/newsarticle/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<void> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `newsArticleControllerDeleteid()` */
  static readonly NewsArticleControllerDeleteidPath = '/newsarticle/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params?: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params?: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `newsArticleControllerGetForEditid()` */
  static readonly NewsArticleControllerGetForEditidPath = '/newsarticle/getforedit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Plain$Response(params?: GetForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NewsArticleEditViewModel>> {
    return getForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Plain(params?: GetForEdit$Plain$Params, context?: HttpContext): Observable<NewsArticleEditViewModel> {
    return this.getForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewsArticleEditViewModel>): NewsArticleEditViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit$Response(params?: GetForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<NewsArticleEditViewModel>> {
    return getForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForEdit(params?: GetForEdit$Params, context?: HttpContext): Observable<NewsArticleEditViewModel> {
    return this.getForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewsArticleEditViewModel>): NewsArticleEditViewModel => r.body)
    );
  }

  /** Path part for operation `newsArticleControllerRescope()` */
  static readonly NewsArticleControllerRescopePath = '/newsarticle/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain$Response(params?: Rescope$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain(params?: Rescope$Plain$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Response(params?: Rescope$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope(params?: Rescope$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
