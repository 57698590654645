/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProductNotificationForReadViewModel } from '../../models/product-notification-for-read-view-model';

export interface GetProductNotification$Params {
  id: number;
  languageCode?: string;
}

export function getProductNotification(http: HttpClient, rootUrl: string, params: GetProductNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductNotificationForReadViewModel>> {
  const rb = new RequestBuilder(rootUrl, getProductNotification.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('languageCode', params.languageCode, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProductNotificationForReadViewModel>;
    })
  );
}

getProductNotification.PATH = '/productnotifications/{id}';
