import { ResolvedScopeSearchRequest } from './../../mibp-openapi-gen/models/resolved-scope-search-request';
import { UserIndex } from './../../mibp-openapi-gen/models/user-index';
import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ApiService} from 'root/services/mibp-api-services';
import { TableColumnDef } from 'root/components';
import { DialogComponent } from '../dialog/dialog.component';
import { ResolvedScope, PagedSearchOptions } from 'root/mibp-openapi-gen/models';
import { AccessGroupsApiController } from 'root/mibp-openapi-gen/controllers';
import { firstValueFrom } from 'rxjs';
import { LogService, MibpLogger } from 'root/services';
import { ApiErrorHandlerService } from 'root/services/api-error-handler/api-error-handler';



@Component({
  selector: 'mibp-targeted-users',
  templateUrl: './targeted-users.component.html',
  styleUrls: ['./targeted-users.component.scss']
})

export class TargetedUsersComponent {
  log: MibpLogger;
  @ViewChild(DialogComponent, {static: true}) dialog: DialogComponent;
  private isDialogOpen: boolean;
  @Input()
  get isOpen(): boolean {
    return this.isDialogOpen;
  }
  set isOpen(value: boolean) {
    this.isDialogOpen = value;
    if (this.isDialogOpen) {
      this.dialog.open();
    } else {
      this.dialog.close();
    }
  }

  @Input() scopes: ResolvedScope[] = [];
  @Output() closed = new EventEmitter();


  public columnDefs: TableColumnDef[] = [
    { title: 'Global_Username',          dataKey: 'email' },
    { title: 'Global_User_FullName',     dataKey: 'name' },
    { title: 'Global_CompanyCode',       dataKey: 'company',   dataCellClassList: ['text-uppercase'] },
    { title: 'Global_Organization',      dataKey: 'accessGroup',   dataCellClassList: ['text-uppercase'] },
    { title: 'Global_CustomerName',      dataKey: 'customer',  dataCellClassList: ['text-uppercase'] }
  ];


  isLoading = true;
  public totalItems: number;
  public pageSize = 10;
  public skip = 0;
  private query: string;
  users: UserIndex[];
  request: PagedSearchOptions;

  constructor(logger: LogService,
    private accessGroupApi: AccessGroupsApiController,
    private errorHandler: ApiErrorHandlerService) {
    this.log = logger.withPrefix('targeted-users.component');
  }


  getTargetedUsers() {
    this.users = [];
    this.request = {
      query: this.query,
      take: this.pageSize,
      skip: this.skip,
    };
    if (this.scopes.length > 0) {
      const request: ResolvedScopeSearchRequest = {
        scopes: this.scopes,
        pagedSearchOptions: this.request
      };
      firstValueFrom(this.accessGroupApi.searchTargetedUsers({body:request})).then(targetedUsers => {
        this.users = targetedUsers.items;
        this.totalItems = targetedUsers.count;
        this.isLoading = false;
      }).catch(error=> {
        this.log.error('Error search targeted users', error);
        this.errorHandler.parseError(error).showNotice();
      });
    }
  }
  onFilterChanged(value: string) {
    this.query = value;
    this.getTargetedUsers();
  }

  onChangePage(page: number) {
    this.skip = page;
    this.getTargetedUsers();
  }

  dialogOpen() {
    this.query = "";
    this.skip = 0;
    this.getTargetedUsers();
  }

  dialogClosed() {
    this.closed.emit();
  }

}
