<div class="my-grid-paging" [class.my-grid-paging--disabled]="disabled">
  <ul class="my-grid-paging__item-list">

    <li class="my-grid-paging__item my-grid-paging__item--prev" [class.my-grid-paging__item--disabled]="isFirstPage">
      <a href="#" (click)="goToPrevPage($event)"><mibp-resource-string
          key="Global_Dialog_Previous"></mibp-resource-string></a>
    </li>

    <ng-container *ngIf="showPrevPagingPage">
      <li class="my-grid-paging__item">
        <a href="#" (click)="triggerGotoPageEvent(0, $event)">1</a>
      </li>
      <li class="my-grid-paging__item">
        <a href="#" (click)="goToPrevPageList($event)">...</a>
      </li>
    </ng-container>

    <li class="my-grid-paging__item" *ngFor="let item of items" [class.my-grid-paging__item--active]="item.active">
      <a href="#" (click)="triggerGotoPageEvent(item.pageNumber, $event)">{{ item.text }}</a>
    </li>

    <ng-container *ngIf="showNextPagingPage">
      <li class="my-grid-paging__item">
        <a href="#" (click)="goToNextPageList($event)">...</a>
      </li>
      <li class="my-grid-paging__item">
        <a href="#" (click)="triggerGotoPageEvent(pageCount - 1, $event)">{{pageCount}}</a>
      </li>
    </ng-container>

    <li class="my-grid-paging__item my-grid-paging__item--next" [class.my-grid-paging__item--disabled]="isLastPage">
      <a href="#" (click)="goToNextPage($event)"><mibp-resource-string key="Global_Next"></mibp-resource-string></a>
    </li>
  </ul>
</div>