export enum ButtonIcons {
  None,
  ChevronCircleRight,
  ChevronDown,
  Plus,
  Trash,
  FolderOpen,
  Print,
  CheckCircle,
  Edit,
  Minus,
  AddToCart,
  Undo,
  Download,
  Copy,
  ScopePreview,
  Save,
  Eye,
  EyeSlash
}


export enum ButtonStyles {
  Normal,
  FatBorder,
  Fill,
  BeforeInput,
  AfterInput
}

export enum ButtonColors {
  Blue,
  Orange,
  Red,
  Primary,
  Secondary,
  Success
}
