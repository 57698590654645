/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BusinessRelationIndexSearchResponse } from '../models/business-relation-index-search-response';
import { CompanyIndexSearchResponse } from '../models/company-index-search-response';
import { DeliverySequenceIndexSearchResponse } from '../models/delivery-sequence-index-search-response';
import { DeliverySequencePickerDto } from '../models/delivery-sequence-picker-dto';
import { searchBusinessRelations } from '../fn/delivery-sequence-picker/search-business-relations';
import { SearchBusinessRelations$Params } from '../fn/delivery-sequence-picker/search-business-relations';
import { searchBusinessRelations$Plain } from '../fn/delivery-sequence-picker/search-business-relations-plain';
import { SearchBusinessRelations$Plain$Params } from '../fn/delivery-sequence-picker/search-business-relations-plain';
import { searchBusinessRelationsForOrganization } from '../fn/delivery-sequence-picker/search-business-relations-for-organization';
import { SearchBusinessRelationsForOrganization$Params } from '../fn/delivery-sequence-picker/search-business-relations-for-organization';
import { searchBusinessRelationsForOrganization$Plain } from '../fn/delivery-sequence-picker/search-business-relations-for-organization-plain';
import { SearchBusinessRelationsForOrganization$Plain$Params } from '../fn/delivery-sequence-picker/search-business-relations-for-organization-plain';
import { searchCompanies } from '../fn/delivery-sequence-picker/search-companies';
import { SearchCompanies$Params } from '../fn/delivery-sequence-picker/search-companies';
import { searchCompanies$Plain } from '../fn/delivery-sequence-picker/search-companies-plain';
import { SearchCompanies$Plain$Params } from '../fn/delivery-sequence-picker/search-companies-plain';
import { searchCompaniesForOrganization } from '../fn/delivery-sequence-picker/search-companies-for-organization';
import { SearchCompaniesForOrganization$Params } from '../fn/delivery-sequence-picker/search-companies-for-organization';
import { searchCompaniesForOrganization$Plain } from '../fn/delivery-sequence-picker/search-companies-for-organization-plain';
import { SearchCompaniesForOrganization$Plain$Params } from '../fn/delivery-sequence-picker/search-companies-for-organization-plain';
import { searchDeliverySequences } from '../fn/delivery-sequence-picker/search-delivery-sequences';
import { SearchDeliverySequences$Params } from '../fn/delivery-sequence-picker/search-delivery-sequences';
import { searchDeliverySequences$Plain } from '../fn/delivery-sequence-picker/search-delivery-sequences-plain';
import { SearchDeliverySequences$Plain$Params } from '../fn/delivery-sequence-picker/search-delivery-sequences-plain';
import { searchDeliverySequencesByOrganization } from '../fn/delivery-sequence-picker/search-delivery-sequences-by-organization';
import { SearchDeliverySequencesByOrganization$Params } from '../fn/delivery-sequence-picker/search-delivery-sequences-by-organization';
import { searchDeliverySequencesByOrganization$Plain } from '../fn/delivery-sequence-picker/search-delivery-sequences-by-organization-plain';
import { SearchDeliverySequencesByOrganization$Plain$Params } from '../fn/delivery-sequence-picker/search-delivery-sequences-by-organization-plain';
import { searchDeliverySequencesForOrganization } from '../fn/delivery-sequence-picker/search-delivery-sequences-for-organization';
import { SearchDeliverySequencesForOrganization$Params } from '../fn/delivery-sequence-picker/search-delivery-sequences-for-organization';
import { searchDeliverySequencesForOrganization$Plain } from '../fn/delivery-sequence-picker/search-delivery-sequences-for-organization-plain';
import { SearchDeliverySequencesForOrganization$Plain$Params } from '../fn/delivery-sequence-picker/search-delivery-sequences-for-organization-plain';
import { validate } from '../fn/delivery-sequence-picker/validate';
import { Validate$Params } from '../fn/delivery-sequence-picker/validate';
import { validate$Plain } from '../fn/delivery-sequence-picker/validate-plain';
import { Validate$Plain$Params } from '../fn/delivery-sequence-picker/validate-plain';
import { validateForOrganization } from '../fn/delivery-sequence-picker/validate-for-organization';
import { ValidateForOrganization$Params } from '../fn/delivery-sequence-picker/validate-for-organization';
import { validateForOrganization$Plain } from '../fn/delivery-sequence-picker/validate-for-organization-plain';
import { ValidateForOrganization$Plain$Params } from '../fn/delivery-sequence-picker/validate-for-organization-plain';

@Injectable({ providedIn: 'root' })
export class DeliverySequencePickerApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deliverySequencePickerControllerSearchCompaniesrequest()` */
  static readonly DeliverySequencePickerControllerSearchCompaniesrequestPath = '/deliverysequencepicker/searchcompanies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCompanies$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompanies$Plain$Response(params?: SearchCompanies$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyIndexSearchResponse>> {
    return searchCompanies$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCompanies$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompanies$Plain(params?: SearchCompanies$Plain$Params, context?: HttpContext): Observable<CompanyIndexSearchResponse> {
    return this.searchCompanies$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyIndexSearchResponse>): CompanyIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCompanies()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompanies$Response(params?: SearchCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyIndexSearchResponse>> {
    return searchCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCompanies$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompanies(params?: SearchCompanies$Params, context?: HttpContext): Observable<CompanyIndexSearchResponse> {
    return this.searchCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyIndexSearchResponse>): CompanyIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `deliverySequencePickerControllerSearchCompaniesForOrganizationrequest()` */
  static readonly DeliverySequencePickerControllerSearchCompaniesForOrganizationrequestPath = '/deliverysequencepicker/searchcompaniesfororganization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCompaniesForOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompaniesForOrganization$Plain$Response(params?: SearchCompaniesForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyIndexSearchResponse>> {
    return searchCompaniesForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCompaniesForOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompaniesForOrganization$Plain(params?: SearchCompaniesForOrganization$Plain$Params, context?: HttpContext): Observable<CompanyIndexSearchResponse> {
    return this.searchCompaniesForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyIndexSearchResponse>): CompanyIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCompaniesForOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompaniesForOrganization$Response(params?: SearchCompaniesForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyIndexSearchResponse>> {
    return searchCompaniesForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCompaniesForOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompaniesForOrganization(params?: SearchCompaniesForOrganization$Params, context?: HttpContext): Observable<CompanyIndexSearchResponse> {
    return this.searchCompaniesForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyIndexSearchResponse>): CompanyIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `deliverySequencePickerControllerSearchBusinessRelationsrequest()` */
  static readonly DeliverySequencePickerControllerSearchBusinessRelationsrequestPath = '/deliverysequencepicker/searchbusinessrelations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchBusinessRelations$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchBusinessRelations$Plain$Response(params?: SearchBusinessRelations$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRelationIndexSearchResponse>> {
    return searchBusinessRelations$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchBusinessRelations$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchBusinessRelations$Plain(params?: SearchBusinessRelations$Plain$Params, context?: HttpContext): Observable<BusinessRelationIndexSearchResponse> {
    return this.searchBusinessRelations$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessRelationIndexSearchResponse>): BusinessRelationIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchBusinessRelations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchBusinessRelations$Response(params?: SearchBusinessRelations$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRelationIndexSearchResponse>> {
    return searchBusinessRelations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchBusinessRelations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchBusinessRelations(params?: SearchBusinessRelations$Params, context?: HttpContext): Observable<BusinessRelationIndexSearchResponse> {
    return this.searchBusinessRelations$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessRelationIndexSearchResponse>): BusinessRelationIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `deliverySequencePickerControllerSearchBusinessRelationsForOrganizationrequest()` */
  static readonly DeliverySequencePickerControllerSearchBusinessRelationsForOrganizationrequestPath = '/deliverysequencepicker/searchbusinessrelationsfororganization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchBusinessRelationsForOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchBusinessRelationsForOrganization$Plain$Response(params?: SearchBusinessRelationsForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRelationIndexSearchResponse>> {
    return searchBusinessRelationsForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchBusinessRelationsForOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchBusinessRelationsForOrganization$Plain(params?: SearchBusinessRelationsForOrganization$Plain$Params, context?: HttpContext): Observable<BusinessRelationIndexSearchResponse> {
    return this.searchBusinessRelationsForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessRelationIndexSearchResponse>): BusinessRelationIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchBusinessRelationsForOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchBusinessRelationsForOrganization$Response(params?: SearchBusinessRelationsForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRelationIndexSearchResponse>> {
    return searchBusinessRelationsForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchBusinessRelationsForOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchBusinessRelationsForOrganization(params?: SearchBusinessRelationsForOrganization$Params, context?: HttpContext): Observable<BusinessRelationIndexSearchResponse> {
    return this.searchBusinessRelationsForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessRelationIndexSearchResponse>): BusinessRelationIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `deliverySequencePickerControllerSearchDeliverySequencesrequest()` */
  static readonly DeliverySequencePickerControllerSearchDeliverySequencesrequestPath = '/deliverysequencepicker/searchdeliverysequences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchDeliverySequences$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequences$Plain$Response(params?: SearchDeliverySequences$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceIndexSearchResponse>> {
    return searchDeliverySequences$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchDeliverySequences$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequences$Plain(params?: SearchDeliverySequences$Plain$Params, context?: HttpContext): Observable<DeliverySequenceIndexSearchResponse> {
    return this.searchDeliverySequences$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceIndexSearchResponse>): DeliverySequenceIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchDeliverySequences()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequences$Response(params?: SearchDeliverySequences$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceIndexSearchResponse>> {
    return searchDeliverySequences(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchDeliverySequences$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequences(params?: SearchDeliverySequences$Params, context?: HttpContext): Observable<DeliverySequenceIndexSearchResponse> {
    return this.searchDeliverySequences$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceIndexSearchResponse>): DeliverySequenceIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `deliverySequencePickerControllerSearchDeliverySequencesForOrganizationrequest()` */
  static readonly DeliverySequencePickerControllerSearchDeliverySequencesForOrganizationrequestPath = '/deliverysequencepicker/searchdeliverysequencesfororganization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchDeliverySequencesForOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequencesForOrganization$Plain$Response(params?: SearchDeliverySequencesForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceIndexSearchResponse>> {
    return searchDeliverySequencesForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchDeliverySequencesForOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequencesForOrganization$Plain(params?: SearchDeliverySequencesForOrganization$Plain$Params, context?: HttpContext): Observable<DeliverySequenceIndexSearchResponse> {
    return this.searchDeliverySequencesForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceIndexSearchResponse>): DeliverySequenceIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchDeliverySequencesForOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequencesForOrganization$Response(params?: SearchDeliverySequencesForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceIndexSearchResponse>> {
    return searchDeliverySequencesForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchDeliverySequencesForOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequencesForOrganization(params?: SearchDeliverySequencesForOrganization$Params, context?: HttpContext): Observable<DeliverySequenceIndexSearchResponse> {
    return this.searchDeliverySequencesForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceIndexSearchResponse>): DeliverySequenceIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `deliverySequencePickerControllerSearchDeliverySequencesByOrganizationrequest()` */
  static readonly DeliverySequencePickerControllerSearchDeliverySequencesByOrganizationrequestPath = '/deliverysequencepicker/searchdeliverysequencesbyorganization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchDeliverySequencesByOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequencesByOrganization$Plain$Response(params?: SearchDeliverySequencesByOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceIndexSearchResponse>> {
    return searchDeliverySequencesByOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchDeliverySequencesByOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequencesByOrganization$Plain(params?: SearchDeliverySequencesByOrganization$Plain$Params, context?: HttpContext): Observable<DeliverySequenceIndexSearchResponse> {
    return this.searchDeliverySequencesByOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceIndexSearchResponse>): DeliverySequenceIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchDeliverySequencesByOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequencesByOrganization$Response(params?: SearchDeliverySequencesByOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceIndexSearchResponse>> {
    return searchDeliverySequencesByOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchDeliverySequencesByOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchDeliverySequencesByOrganization(params?: SearchDeliverySequencesByOrganization$Params, context?: HttpContext): Observable<DeliverySequenceIndexSearchResponse> {
    return this.searchDeliverySequencesByOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceIndexSearchResponse>): DeliverySequenceIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `deliverySequencePickerControllerValidatecompanyCodeErpCustomerIdDeliverySequenceId()` */
  static readonly DeliverySequencePickerControllerValidatecompanyCodeErpCustomerIdDeliverySequenceIdPath = '/deliverysequencepicker/validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  validate$Plain$Response(params?: Validate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequencePickerDto>> {
    return validate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validate$Plain(params?: Validate$Plain$Params, context?: HttpContext): Observable<DeliverySequencePickerDto> {
    return this.validate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequencePickerDto>): DeliverySequencePickerDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validate()` instead.
   *
   * This method doesn't expect any request body.
   */
  validate$Response(params?: Validate$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequencePickerDto>> {
    return validate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validate(params?: Validate$Params, context?: HttpContext): Observable<DeliverySequencePickerDto> {
    return this.validate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequencePickerDto>): DeliverySequencePickerDto => r.body)
    );
  }

  /** Path part for operation `deliverySequencePickerControllerValidateForOrganizationorganizationIdCompanyCodeCustomerNumberDeliverySequenceId()` */
  static readonly DeliverySequencePickerControllerValidateForOrganizationorganizationIdCompanyCodeCustomerNumberDeliverySequenceIdPath = '/deliverysequencepicker/validatefororganization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateForOrganization$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateForOrganization$Plain$Response(params?: ValidateForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequencePickerDto>> {
    return validateForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateForOrganization$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateForOrganization$Plain(params?: ValidateForOrganization$Plain$Params, context?: HttpContext): Observable<DeliverySequencePickerDto> {
    return this.validateForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequencePickerDto>): DeliverySequencePickerDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateForOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateForOrganization$Response(params?: ValidateForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequencePickerDto>> {
    return validateForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateForOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateForOrganization(params?: ValidateForOrganization$Params, context?: HttpContext): Observable<DeliverySequencePickerDto> {
    return this.validateForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequencePickerDto>): DeliverySequencePickerDto => r.body)
    );
  }

}
