import { FormattingService } from 'root/services';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: 'mibp-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss']
})

export class MibpDateTimeComponent implements OnInit, OnChanges {

  @Input() date;
  @Input() format: 'date' | 'datetime' | 'utcdatetime' | 'time' | 'unchanged' | 'date-placeholder' = 'date';
  @Input() disableTooltip = true;

  utcDateString: string;
  localDateString: string;
  localDateTimeString: string;
  unchangedDateString: string;
  formatPlaceholder: string;
  tooltip: string;
  constructor(private formattingService: FormattingService) { }

  ngOnInit(): void {
    this.formatPlaceholder = this.formattingService.getDateFormatPlaceholder();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.format === 'unchanged') {
      this.unchangedDateString = this.formattingService.formatDate(this.date, null, true);
    } else {
      this.localDateString = this.formattingService.formatDate(this.date);
      this.localDateTimeString = this.formattingService.formatDateTime(this.date);
      this.utcDateString = this.formattingService.toServerUTCString(this.date);

      if (!this.disableTooltip) {
        if (this.format === 'utcdatetime') {
          this.tooltip = this.formattingService.toISOString(this.date);
        } else {
          this.tooltip = this.formattingService.getTooltipString(this.date);
        }
      }
    }
  }

}
