/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProductExpectedDate } from '../../models/product-expected-date';

export interface GetProductExpectedDatesForQuotation$Params {
  quotationId: string;
  userBrowserDate?: string;
}

export function getProductExpectedDatesForQuotation(http: HttpClient, rootUrl: string, params: GetProductExpectedDatesForQuotation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductExpectedDate>>> {
  const rb = new RequestBuilder(rootUrl, getProductExpectedDatesForQuotation.PATH, 'get');
  if (params) {
    rb.path('quotationId', params.quotationId, {});
    rb.query('userBrowserDate', params.userBrowserDate, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ProductExpectedDate>>;
    })
  );
}

getProductExpectedDatesForQuotation.PATH = '/priceandavailability/{quotationId}/expecteddatesquotation';
