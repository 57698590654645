import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { environment } from 'root/environment';
import { MibpPopoverComponent } from 'root/components/popover-component/popover.component';
import { LocalizationService } from '../../../services/localization/localization.service';
import { MibpLogger, LogService } from '../../../services/logservice/index';
import { BasicChartData } from '../chart.types';
import { Color, ChartDataset, ChartOptions, LinearScale, CartesianScaleOptions, LinearScaleOptions } from 'chart.js';

@Component({
  selector: 'mibp-chart-bar',
  templateUrl: './mibp-chart-bar.component.html',
  styleUrls: ['./mibp-chart-bar.component.scss']
})
export class MibpChartBarComponent implements OnInit, OnDestroy {

  log: MibpLogger;
  private releaseResourceStrings: () => void;
  private currentLegendValue: string;


  @Input() horizontal = false;
  @Input() width = '400px';
  @Input() height = '300px';
  @Input() aspectRatio = 1;
  @Input() title: string;
  @Input() loading = false;
  @Input() legendHtmlResourceStrings: string[] = [];
  @Input() useResourceStringLabels = true;
  @Input() stackColors: { [key: string]: Color};
  @Input()
  set colors(value: string[]) {
    if (value !== null && value.length > 0) {
      this.chartColors = [
        {
          backgroundColor: value,
        },
      ];
    }
  }

  @Input() legend : 'hidden' | 'top' | 'right' | 'left' = 'hidden';

  @ViewChild(MibpPopoverComponent)
  private popoverComponent: MibpPopoverComponent;

  isReady = false;
  public chartOptions: ChartOptions;
  public chartLabels: string[] = [];
  public chartData: number[] = [];
  public chartDataSets: ChartDataset[];
  public chartTooltipHtmlResourceStrings: string[] = [];
  public currentTooltipResourceString: string = null;
  public chartPlugins = [];
  public chartColors = [
    {
      backgroundColor: environment.chart.colors,
    },
  ];

  private configureStackBarChart(chartData: BasicChartData[]) {
    const labelData: ChartDataset[] = [];

    this.chartColors = null;

    for (const data of chartData) {
      if (data.stackValues) {
        const keys = Object.keys(data.stackValues);
        keys.forEach(key => {
          const existing = labelData.find(l => l.label === key) as ChartDataset;
          if (existing) {
            existing.data.push(data.stackValues[key]);
          } else {
            labelData.push({
              label: key,
              backgroundColor: this.stackColors[key],
              data: [data.stackValues[key]]
            });
          }
        });
      }
    }

    this.chartDataSets = labelData;
    this.chartLabels = chartData.map(c => c.labelText);
    this.isReady = true;

    this.chartOptions.scales.x = { stacked: true };
    this.chartOptions.scales.y = { ticks: { precision: 0 }, beginAtZero:true};
    this.chartOptions.plugins.legend.display = true;
    this.chartOptions.responsive = true;
    this.chartOptions.maintainAspectRatio = false;
  }

  @Input()
  set data(value: BasicChartData[]) {
    const resourceStrings = [];
    const values = [];
    let labels = [];
    this.chartTooltipHtmlResourceStrings = [];
    this.isReady = false;

    if (!value) {
      this.isReady = true;
      return;
    }

    if (value.length > 0 && value[0].stackValues) {
      this.configureStackBarChart(value);
      return;
    }

    value.forEach(val => {
      values.push(val.value);
      labels.push(val.labelResourceString);
      this.chartTooltipHtmlResourceStrings.push(val.tooltipHtmlResourceString);
      if (val.labelResourceString) { resourceStrings.push(val.labelResourceString); }
    });

    if (this.releaseResourceStrings) {
      this.releaseResourceStrings();
    }
    if (this.useResourceStringLabels) {
      this.releaseResourceStrings = this.localizationService.using(resourceStrings, (resourceStringValues) => {
        this.log.warn('rsv', resourceStrings, resourceStringValues);
        resourceStrings.forEach((rs, ix) => {
          labels = labels.map(v => rs === v ? resourceStringValues[ix] : v);
        });

        this.chartData = values;
        this.chartLabels = labels;
        this.legend = <any>this.currentLegendValue;
        this.isReady = true;
      });
    } else {
      this.chartData = values;
      this.chartLabels = labels.flatMap(l => {
        return this.formatLabel(l, 20);
      });
      this.isReady = true;
    }

  }

  constructor(private localizationService: LocalizationService, logger: LogService) {
    this.log = logger.withPrefix('mibp-chart-bar');
    this.initChart();
  }

  ngOnInit(): void {
    this.setupLegend();
  }

  setupLegend() {
    this.currentLegendValue = this.legend;
    if (this.legend === 'hidden') {
      this.chartOptions.plugins.legend = {
        display: false
      };
    } else {

      let clickCallback = null;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      if (this.chartTooltipHtmlResourceStrings && this.chartTooltipHtmlResourceStrings.findIndex(str => !!str) !== -1) {
        clickCallback = function(e: MouseEvent, legend: any) {
          if (self.chartTooltipHtmlResourceStrings[legend.index]) {
            self.currentTooltipResourceString = self.chartTooltipHtmlResourceStrings[legend.index];
            self.popoverComponent.openAt(e.clientX , e.clientY + window.scrollY);
          }
        };
      }

      this.chartOptions.plugins.legend = {
        display: true,
        position: this.legend
      };

      if (clickCallback) {
        this.chartOptions.plugins.legend.onClick = clickCallback;
      }
    }

  }

  initChart(): void {
    const showTitle = this.title !== (null || undefined);

    this.chartOptions  = {
      plugins: {
        title: {
          text: this.title,
          display: showTitle,
        },
        legend: {
          display: false
        }
      },
      responsive: true,
      aspectRatio: this.aspectRatio,
      scales: {
        x: {
          title: {
            display: false,
            text: 'x axis'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Item descr'
          },
          type: "linear",
          beginAtZero: true,
          ticks: {
            precision: 0
          }
        }
      }
    };
  }

  ngOnDestroy(): void {
    if (this.releaseResourceStrings) {
      this.releaseResourceStrings();
    }
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: any[] }): void {
    this.log.debug("chartClicked", event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: any[] }): void {
    this.log.debug("chartHovered", event, active);
  }

  formatLabel(str: string, maxwidth: number): string[] {
    const sections = [];
    const words = str.split(" ");
    let temp = "";

    words.forEach(function(item, index) {
      if (temp.length > 0) {
        const concat = temp + ' ' + item;

        if (concat.length > maxwidth) {
          sections.push(temp);
          temp = "";
        } else {
          if (index === (words.length - 1)) {
            sections.push(concat);
            return;
          } else {
            temp = concat;
            return;
          }
        }
      }

      if (index === (words.length - 1)) {
        sections.push(item);
        return;
      }

      if (item.length < maxwidth) {
        temp = item;
      } else {
        sections.push(item);
      }

    });

    return sections;
  }
}
