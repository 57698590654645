/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { delete$ } from '../fn/roles/delete';
import { Delete$Params } from '../fn/roles/delete';
import { delete$Plain } from '../fn/roles/delete-plain';
import { Delete$Plain$Params } from '../fn/roles/delete-plain';
import { exists } from '../fn/roles/exists';
import { Exists$Params } from '../fn/roles/exists';
import { exists$Plain } from '../fn/roles/exists-plain';
import { Exists$Plain$Params } from '../fn/roles/exists-plain';
import { get } from '../fn/roles/get';
import { Get$Params } from '../fn/roles/get';
import { get$Plain } from '../fn/roles/get-plain';
import { Get$Plain$Params } from '../fn/roles/get-plain';
import { getAllRoles } from '../fn/roles/get-all-roles';
import { GetAllRoles$Params } from '../fn/roles/get-all-roles';
import { getAllRoles$Plain } from '../fn/roles/get-all-roles-plain';
import { GetAllRoles$Plain$Params } from '../fn/roles/get-all-roles-plain';
import { getUserCount } from '../fn/roles/get-user-count';
import { GetUserCount$Params } from '../fn/roles/get-user-count';
import { getUserCount$Plain } from '../fn/roles/get-user-count-plain';
import { GetUserCount$Plain$Params } from '../fn/roles/get-user-count-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { RoleViewModel } from '../models/role-view-model';
import { save } from '../fn/roles/save';
import { Save$Params } from '../fn/roles/save';
import { searchGrid } from '../fn/roles/search-grid';
import { SearchGrid$Params } from '../fn/roles/search-grid';
import { searchGrid$Plain } from '../fn/roles/search-grid-plain';
import { SearchGrid$Plain$Params } from '../fn/roles/search-grid-plain';

@Injectable({ providedIn: 'root' })
export class RolesApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `rolesControllerGetAllRoles()` */
  static readonly RolesControllerGetAllRolesPath = '/roles/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRoles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRoles$Plain$Response(params?: GetAllRoles$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RoleViewModel>>> {
    return getAllRoles$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllRoles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRoles$Plain(params?: GetAllRoles$Plain$Params, context?: HttpContext): Observable<Array<RoleViewModel>> {
    return this.getAllRoles$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoleViewModel>>): Array<RoleViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRoles$Response(params?: GetAllRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RoleViewModel>>> {
    return getAllRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRoles(params?: GetAllRoles$Params, context?: HttpContext): Observable<Array<RoleViewModel>> {
    return this.getAllRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RoleViewModel>>): Array<RoleViewModel> => r.body)
    );
  }

  /** Path part for operation `rolesControllerGetid()` */
  static readonly RolesControllerGetidPath = '/roles/get/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain$Response(params: Get$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleViewModel>> {
    return get$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Plain(params: Get$Plain$Params, context?: HttpContext): Observable<RoleViewModel> {
    return this.get$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleViewModel>): RoleViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleViewModel>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: Get$Params, context?: HttpContext): Observable<RoleViewModel> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleViewModel>): RoleViewModel => r.body)
    );
  }

  /** Path part for operation `rolesControllerSaverole()` */
  static readonly RolesControllerSaverolePath = '/roles/saverole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<void> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `rolesControllerExistsnameIgnoreId()` */
  static readonly RolesControllerExistsnameIgnoreIdPath = '/roles/exists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists$Plain$Response(params?: Exists$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return exists$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists$Plain(params?: Exists$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.exists$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exists()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists$Response(params?: Exists$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return exists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists(params?: Exists$Params, context?: HttpContext): Observable<boolean> {
    return this.exists$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `rolesControllerGetUserCountid()` */
  static readonly RolesControllerGetUserCountidPath = '/roles/usercount/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount$Plain$Response(params: GetUserCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getUserCount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount$Plain(params: GetUserCount$Plain$Params, context?: HttpContext): Observable<number> {
    return this.getUserCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount$Response(params: GetUserCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getUserCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCount(params: GetUserCount$Params, context?: HttpContext): Observable<number> {
    return this.getUserCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `rolesControllerDeleteid()` */
  static readonly RolesControllerDeleteidPath = '/roles/delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Plain$Response(params: Delete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return delete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Plain(params: Delete$Plain$Params, context?: HttpContext): Observable<number> {
    return this.delete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<number> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `rolesControllerSearchGridoptions()` */
  static readonly RolesControllerSearchGridoptionsPath = '/roles/searchgrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain$Response(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Response(params?: SearchGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid(params?: SearchGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

}
