import { Component, ViewContainerRef, TemplateRef, ViewChild, OnDestroy, EmbeddedViewRef, Output, EventEmitter } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';


@Component({
  selector: 'mibp-popover',
  styleUrls: ['./popover.component.scss'],
  template: `
    <ng-template #popoverTemplate let-name>
      <div #popoverElement class="popover" [class.popover--arrow-up]="belowElement" [class.popover--arrow-down]="!belowElement" [style.opacity]="!isOpen ? '0' : 1">
        <ng-template [ngIf]="elementAvailable">
          <ng-content></ng-content>
        </ng-template>
      </div>
    </ng-template>
    `
})
export class MibpPopoverComponent implements OnDestroy {

  isOpen = false;
  elementAvailable = false;
  clickOutsideSub?: Subscription;
  belowElement = true;
  embeddedView: EmbeddedViewRef<any>;
  @Output() closed = new EventEmitter<boolean>();


  @ViewChild('popoverTemplate', { static: true }) popoverTemplate: TemplateRef<any>;
  @ViewChild('popoverElement') popoverElement;

  constructor(private viewContainerRef: ViewContainerRef) { }

  public close(): void {
    this.elementAvailable = false;
    this.belowElement = true;
    this.isOpen = false;
    this.embeddedView?.destroy();
    this.closed.emit(true);
  }

  public open(elmOrEvent: HTMLElement | MouseEvent): void {

    let inModal = false;

    if (elmOrEvent instanceof MouseEvent) {
      this.openAt(elmOrEvent.clientX, elmOrEvent.clientY + window.scrollY);
      return;
    }

    let  attachToElement: HTMLElement;
    if (document.querySelector('.modal-card-body')?.contains(elmOrEvent)) {
      attachToElement = document.querySelector('.modal-card-body');
      inModal = true;
    }

    const attachedToElement = this.moveTemplateToElement(attachToElement);

    setTimeout(() => {

      const elm = elmOrEvent as HTMLElement;
      const rect = elm.getBoundingClientRect();
      const scrollableRect = attachedToElement.getBoundingClientRect();
      const mainContainerLeft = inModal ? rect.left - scrollableRect.left : rect.left - document.body.getBoundingClientRect().left;
      const mainContainerTop = rect.top - scrollableRect.top;

      // if (document.getElementById('main-wrapper').contains(attachedToElement)) {
      //   mainContainerLeft -= scrollableRect.left;
      // }

      let topPosition = (attachedToElement.scrollTop + mainContainerTop + 20);
      let leftPosition = mainContainerLeft;

      this.elementAvailable = true;
      if (this.popoverElement?.nativeElement) {
        this.whenElementHasWidth(this.popoverElement.nativeElement as HTMLElement, (e: DOMRect) => {
          leftPosition = (mainContainerLeft - (e.width / 2)) + (rect.width / 2);

          if (leftPosition < 10) {
            leftPosition = 10;
          }


          if (topPosition + e.height + 10 > (scrollableRect.height + attachedToElement.scrollTop) ) {
            this.belowElement = false;
            topPosition = (attachedToElement.scrollTop + mainContainerTop) - e.height - 20 - rect.height;
          }

          this.popoverElement.nativeElement.style.left = `${leftPosition}px`;
          this.popoverElement.nativeElement.style.top = `${topPosition}px`;
          this.isOpen = true;

          let styleEle = document.getElementById('style-dynamicOverride');
          if(styleEle != null){
            styleEle.remove();
          }
          styleEle = document.createElement("style");
          styleEle.setAttribute('id', 'style-dynamicOverride');
          let styleText = "";
          let leftOverride = mainContainerLeft - leftPosition;
          if(scrollableRect.width < 400){
            styleText = styleText + ".popover { left: 10px !important; width:"+`${scrollableRect.width - 15}`+"px !important }";
            leftOverride = leftOverride + leftPosition;
          }
          styleText = styleText + " .popover--arrow-up:before { left:"+ `${leftOverride}px !important;` +" } .popover--arrow-up:after { left:"+ `${leftOverride}px !important;` +" }";
          styleEle.textContent = styleText;

          document.head.appendChild(styleEle);
        });
        this.closeOnClickOutside();
        this.closeOnWindowResize();
      } else {
        this.close();
      }

    });
  }



  whenElementHasWidth(element: HTMLElement, callback: (rect: DOMRect) => void): void {


    const tick = function() {
      const rect = element.getBoundingClientRect();
      if (rect.width > 0) {
        callback(rect);
      } else {
        window.setTimeout(() => tick(), 150);
      }
    };

    window.setTimeout(() => tick(), 150);

  }

  /**
   * Close popover if user click outside it
   */
  private closeOnClickOutside(): void {
    setTimeout(() => {
      this.clickOutsideSub = fromEvent<MouseEvent>(document, 'mousedown')
        .pipe(
          filter((event: Event) => {
            const clickTarget = event.target as HTMLElement;
            return this.embeddedView && !this.isElementChildOf(this.embeddedView.rootNodes[0], clickTarget);
          }),
          take(1)
        ).subscribe(() => this.close());
    });
  }

  /**
   * Close popover if window is resized
   */
  private closeOnWindowResize(): void {
    setTimeout(() => {
      this.clickOutsideSub = fromEvent<MouseEvent>(window, 'resize')
        .pipe(
          take(1)
        ).subscribe(() => this.close());
    });
  }

  private isElementChildOf(potentialParent: Element, potentialChild: Element): boolean {
    let current: Element | null = potentialChild;
    do {
      if (current === potentialParent || current.parentElement === potentialParent) {
        return true;
      }
      current = current.parentElement;
    } while (current);
    return false;
  }

  public openAt(x: number, y: number): void {
    this.popoverElement.nativeElement.style.left = x + 'px';
    this.popoverElement.nativeElement.style.top = y + 'px';
    setTimeout(() => { this.isOpen = true; }, 10);
  }

  /**
   * Move the 'dialogTemplate' element (see template) to the body
   * This is done by creating an EmbeddedView and moving its elements to the body element
   * Using the embeddedView, all angular bindings will remain and work as usual even if the
   * elements are moved somewhere else
   * https://angular.io/api/core/ViewContainerRef#createembeddedview
   */
  private moveTemplateToElement(element?: HTMLElement): HTMLElement  {
    const scrollableElement = element || document.querySelector('#main-wrapper') || document.body;

    scrollableElement.style.position = 'relative';

    this.embeddedView = this.viewContainerRef.createEmbeddedView(this.popoverTemplate);
    this.embeddedView.rootNodes.forEach(rootNode => scrollableElement.appendChild(rootNode));

    return scrollableElement;

  }

  ngOnDestroy(): void {
    this.close();
  }
}
