import { Injectable } from '@angular/core';
import { MibpLogger } from '../logservice';
import { BroadcastService, GlobalConfigService } from 'src/app/services/';
import { SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

@Injectable({
  providedIn: 'root'
})
export class StockroomService
{
  private _isStockroomEnable : boolean;
  private deliverySequence: SessionDeliverySequenceViewModel;

  public get isStockroomEnable()
  {
    return this._isStockroomEnable;
  }

  log: MibpLogger;

  constructor(
    private mibpSession: MibpSessionService,
    private globalConfigService : GlobalConfigService,
    private broadcastService : BroadcastService
  ) {
    this.mibpSession.activeDeliverySequence$.subscribe(ds => {

      if (!this.globalConfigService.enableStockroom) {
        return;
      }

      if(ds === this.deliverySequence){
        return;
      }

      this.deliverySequence = ds;

      const onlyEnabledForCompanies = (this.globalConfigService.enableStockroomCompanies || [])
        .filter(companyCode => companyCode)
        .map(companyCode => companyCode.trim().toUpperCase());

      const companyCode = this.deliverySequence?.companyCode?.toUpperCase();

      if (onlyEnabledForCompanies.length === 0) {
        this._isStockroomEnable = true;
      } else {
        if (onlyEnabledForCompanies.includes(companyCode)) {
          this._isStockroomEnable = true;
        } else {
          this._isStockroomEnable = false;
        }
      }

    });

  }

}
