/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomersGraphViewModel } from '../models/customers-graph-view-model';
import { getCustomerFilterData } from '../fn/internal-reports/get-customer-filter-data';
import { GetCustomerFilterData$Params } from '../fn/internal-reports/get-customer-filter-data';
import { getCustomerFilterData$Plain } from '../fn/internal-reports/get-customer-filter-data-plain';
import { GetCustomerFilterData$Plain$Params } from '../fn/internal-reports/get-customer-filter-data-plain';
import { getCustomerReportData } from '../fn/internal-reports/get-customer-report-data';
import { GetCustomerReportData$Params } from '../fn/internal-reports/get-customer-report-data';
import { getCustomerReportData$Plain } from '../fn/internal-reports/get-customer-report-data-plain';
import { GetCustomerReportData$Plain$Params } from '../fn/internal-reports/get-customer-report-data-plain';
import { getInternalReportData } from '../fn/internal-reports/get-internal-report-data';
import { GetInternalReportData$Params } from '../fn/internal-reports/get-internal-report-data';
import { getInternalReportData$Plain } from '../fn/internal-reports/get-internal-report-data-plain';
import { GetInternalReportData$Plain$Params } from '../fn/internal-reports/get-internal-report-data-plain';
import { getUserReportFilterData } from '../fn/internal-reports/get-user-report-filter-data';
import { GetUserReportFilterData$Params } from '../fn/internal-reports/get-user-report-filter-data';
import { getUserReportFilterData$Plain } from '../fn/internal-reports/get-user-report-filter-data-plain';
import { GetUserReportFilterData$Plain$Params } from '../fn/internal-reports/get-user-report-filter-data-plain';
import { InternalReportResponseViewModel } from '../models/internal-report-response-view-model';
import { ReportFilterResponseViewModel } from '../models/report-filter-response-view-model';

@Injectable({ providedIn: 'root' })
export class InternalReportsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `internalReportsControllerGetUserReportFilterDatarequest()` */
  static readonly InternalReportsControllerGetUserReportFilterDatarequestPath = '/internalreports/userreportfilter';

  /**
   * GetUserFilterData for getting List of Internal Reports for Users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserReportFilterData$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserReportFilterData$Plain$Response(params?: GetUserReportFilterData$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportFilterResponseViewModel>> {
    return getUserReportFilterData$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * GetUserFilterData for getting List of Internal Reports for Users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserReportFilterData$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserReportFilterData$Plain(params?: GetUserReportFilterData$Plain$Params, context?: HttpContext): Observable<ReportFilterResponseViewModel> {
    return this.getUserReportFilterData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportFilterResponseViewModel>): ReportFilterResponseViewModel => r.body)
    );
  }

  /**
   * GetUserFilterData for getting List of Internal Reports for Users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserReportFilterData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserReportFilterData$Response(params?: GetUserReportFilterData$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportFilterResponseViewModel>> {
    return getUserReportFilterData(this.http, this.rootUrl, params, context);
  }

  /**
   * GetUserFilterData for getting List of Internal Reports for Users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserReportFilterData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserReportFilterData(params?: GetUserReportFilterData$Params, context?: HttpContext): Observable<ReportFilterResponseViewModel> {
    return this.getUserReportFilterData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportFilterResponseViewModel>): ReportFilterResponseViewModel => r.body)
    );
  }

  /** Path part for operation `internalReportsControllerGetInternalReportDatarequest()` */
  static readonly InternalReportsControllerGetInternalReportDatarequestPath = '/internalreports/internalreportdata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalReportData$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInternalReportData$Plain$Response(params?: GetInternalReportData$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<InternalReportResponseViewModel>> {
    return getInternalReportData$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInternalReportData$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInternalReportData$Plain(params?: GetInternalReportData$Plain$Params, context?: HttpContext): Observable<InternalReportResponseViewModel> {
    return this.getInternalReportData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<InternalReportResponseViewModel>): InternalReportResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalReportData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInternalReportData$Response(params?: GetInternalReportData$Params, context?: HttpContext): Observable<StrictHttpResponse<InternalReportResponseViewModel>> {
    return getInternalReportData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInternalReportData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInternalReportData(params?: GetInternalReportData$Params, context?: HttpContext): Observable<InternalReportResponseViewModel> {
    return this.getInternalReportData$Response(params, context).pipe(
      map((r: StrictHttpResponse<InternalReportResponseViewModel>): InternalReportResponseViewModel => r.body)
    );
  }

  /** Path part for operation `internalReportsControllerGetCustomerFilterDatarequest()` */
  static readonly InternalReportsControllerGetCustomerFilterDatarequestPath = '/internalreports/customerreportfilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerFilterData$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFilterData$Plain$Response(params?: GetCustomerFilterData$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportFilterResponseViewModel>> {
    return getCustomerFilterData$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerFilterData$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFilterData$Plain(params?: GetCustomerFilterData$Plain$Params, context?: HttpContext): Observable<ReportFilterResponseViewModel> {
    return this.getCustomerFilterData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportFilterResponseViewModel>): ReportFilterResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerFilterData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFilterData$Response(params?: GetCustomerFilterData$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportFilterResponseViewModel>> {
    return getCustomerFilterData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerFilterData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFilterData(params?: GetCustomerFilterData$Params, context?: HttpContext): Observable<ReportFilterResponseViewModel> {
    return this.getCustomerFilterData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportFilterResponseViewModel>): ReportFilterResponseViewModel => r.body)
    );
  }

  /** Path part for operation `internalReportsControllerGetCustomerReportDatarequest()` */
  static readonly InternalReportsControllerGetCustomerReportDatarequestPath = '/internalreports/customerreportdata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerReportData$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCustomerReportData$Plain$Response(params?: GetCustomerReportData$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomersGraphViewModel>> {
    return getCustomerReportData$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerReportData$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCustomerReportData$Plain(params?: GetCustomerReportData$Plain$Params, context?: HttpContext): Observable<CustomersGraphViewModel> {
    return this.getCustomerReportData$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomersGraphViewModel>): CustomersGraphViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerReportData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCustomerReportData$Response(params?: GetCustomerReportData$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomersGraphViewModel>> {
    return getCustomerReportData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerReportData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCustomerReportData(params?: GetCustomerReportData$Params, context?: HttpContext): Observable<CustomersGraphViewModel> {
    return this.getCustomerReportData$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomersGraphViewModel>): CustomersGraphViewModel => r.body)
    );
  }

}
