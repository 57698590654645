import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { style } from '@angular/animations';
import { BroadcastService } from 'root/services';
import { Subscription } from "rxjs";
import { UserAction } from "../context-menu/context-menu.types";

@Component({
  selector: 'mibp-support-menu',
  templateUrl: './support-context-menu.component.html',
  styles: [`:host {
    display: inline-block;
    position: relative;

  }

  span.menu {
    cursor: pointer;
    font-weight: bold;
    font-family: arial;
    font-size: 0.9rem;
    letter-spacing: -1px;
    padding: 3px 6px;
  }

  span.menu:hover {
    background-color: #eee6;
  }

  .indicator {
      position: relative;
      width: 6px;
      height: 6px;
      background-color: #09f;
      border-radius: 3px;
      position: absolute;
      background-color: #09f;
      border-radius: 5px;
      position: absolute;
      top: 3px;
      right: 3px;

    }
  `]
})
export class MibpSupportTeamMemberContextMenuComponent implements OnInit, OnDestroy {

  toolbarsub: Subscription;
  isSupportToolbarOpen = false;
  @Input() items: UserAction[];
  @Input() contextMenuData?: unknown;
  @Input() beforeOpenCallbackFunction: (items: UserAction[]) => UserAction[];
  @Input() beforeOpenCallbackFunctionContext: unknown;
  @Output() action = new EventEmitter<UserAction>();


  constructor(private broadcast:BroadcastService) {}
  ngOnDestroy(): void {
    this.toolbarsub?.unsubscribe();
  }
  ngOnInit(): void {
    this.toolbarsub = this.broadcast.supportToolbarOpen.subscribe(isOpen => this.isSupportToolbarOpen = isOpen);
  }

  onAction(a: UserAction): void {
    this.action.emit(a);
  }

}
