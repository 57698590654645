import { Pipe, PipeTransform } from '@angular/core';
import { FormattingService } from 'root/services';

@Pipe({
  name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {

  constructor(private formattingService: FormattingService) {}

  transform(value: number): string {
    return this.formattingService.formatBytes(value);
  }

}
