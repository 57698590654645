/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LogDocumotoWidgetIntegrationMessageVm } from '../../models/log-documoto-widget-integration-message-vm';

export interface LogDocumotoWidgetIntegrationMessage$Params {
  mediaIdentifier: string;
      body?: LogDocumotoWidgetIntegrationMessageVm
}

export function logDocumotoWidgetIntegrationMessage(http: HttpClient, rootUrl: string, params: LogDocumotoWidgetIntegrationMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, logDocumotoWidgetIntegrationMessage.PATH, 'post');
  if (params) {
    rb.path('mediaIdentifier', params.mediaIdentifier, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

logDocumotoWidgetIntegrationMessage.PATH = '/media/documoto-widget/{mediaIdentifier}/log';
