import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { GlobalConfigService } from './../../services/global-config/global-config.service';
import { BroadcastService } from 'root/services';
import { MySandvikFeatures } from 'root/services/permission';
import { Subscription } from 'rxjs';
import { FrontendContextService } from 'root/services/index';

@Component({
  selector: 'mibp-salesfore-embedded-service',
  template: ``,
  styleUrls: ['./global-salesforce-embeddedservice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MibpSalesforceEmbeddedServiceComponent implements OnInit, OnDestroy {
  private deliverySequenceSub?: Subscription;
  scriptloaded: boolean;

  constructor(private globalConfig: GlobalConfigService, private broadcast: BroadcastService,
    private frontEndContextService: FrontendContextService) {

  }

  ngOnInit(): void {
    this.deliverySequenceSub = this.broadcast.deliverySequence.subscribe(() => setTimeout(() => this.onDeliverySequenceUpdate()));

  }
  onDeliverySequenceUpdate(): void {
    const hasPermission = this.frontEndContextService.testPermission({ features: [MySandvikFeatures.GeneralSupportLiveChat] });
    if (this.globalConfig.enableSalesforceLiveChat && hasPermission) {
      this.showChat();
    } else {
      this.hideChat();
    }
  }

  private get helpButton(): HTMLElement {
    const elements = document.getElementsByClassName("embeddedServiceHelpButton");
    if (elements.length > 0) {
      return elements[0] as HTMLElement;
    }
    return null;
  }

  showChat() {
    if(!this.scriptloaded){
      this.insertScript();
    } else {
      if (this.helpButton) {
        this.helpButton.style.display = 'block';
      }
    }
  }
  private hideChat() {
    if (!this.scriptloaded) {
      return;
    }
    if (this.helpButton) {
      this.helpButton.style.display = 'none';
    }
  }

  private insertScript() {
    const scriptElm = document.createElement('script');
    scriptElm.setAttribute('type', 'text/javascript');
    scriptElm.src = 'https://service.force.com/embeddedservice/5.0/esw.min.js';
    scriptElm.onload = () => {
      this.onEmbeddedScriptLoaded();
      this.scriptloaded = true;
    };

    document.head.appendChild(scriptElm);
  }
  ngOnDestroy(): void {

    this.deliverySequenceSub?.unsubscribe();
  }
  onEmbeddedScriptLoaded(): void {

    const languageCode = this.broadcast.snapshot.language;
    const currentUser = this.broadcast.snapshot.mibpSession?.user;
    const chatSettings = this.globalConfig.customerLiveChat;
    const initESW = function (gslbBaseURL) {

      window['embedded_svc'].settings.displayHelpButton = true; //Or false
      window['embedded_svc'].settings.language = languageCode; //For example, enter 'en' or 'en-US'

      //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
      //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)
      //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
      //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
      // Settings for Chat
      //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
      // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
      // Returns a valid button ID.
      //};
      //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
      //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
      //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

      window['embedded_svc'].settings.enabledFeatures = ['LiveAgent'];
      window['embedded_svc'].settings.entryFeature = 'LiveAgent';

      window['embedded_svc'].init(
        chatSettings.mySalesforceUrl,
        chatSettings.mySiteUrl,
        gslbBaseURL,
        chatSettings.organizationId,
        chatSettings.liveAgentName,
        {
          baseLiveAgentContentURL: chatSettings.liveAgentContentUrl,
          deploymentId: chatSettings.deploymentId,
          buttonId: chatSettings.buttonId,
          baseLiveAgentURL: chatSettings.liveAgentUrl,
          eswLiveAgentDevName: chatSettings.liveAgentDeveloperName,
          isOfflineSupportEnabled: true
        }
      );
      window['embedded_svc'].settings.prepopulatedPrechatFields = {
        FirstName: currentUser?.firstName,
        LastName: currentUser?.lastName,
        Email: currentUser?.email
      };


    };

    if (!window['embedded_svc']) {
      const s = document.createElement('script');
      s.setAttribute('src', this.globalConfig.customerLiveChat.scriptUrl);
      s.onload = function () {
        initESW(null);
      };
      document.body.appendChild(s);

    } else {

      initESW('https://service.force.com');

    }
  }

}
