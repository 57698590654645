import { MibpInternalUserPhotoService } from './user-photo.service';
import { GlobalConfigService } from './../../../services/global-config/global-config.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { BroadcastService } from './../../../services/broadcast-service/broadcast.service';
import { UserType } from 'root/mibp-openapi-gen/models';

@Component({
  selector: 'mibp-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss']
})
export class MibpUserPhotoComponent implements OnInit {

  @ViewChild('photoElement') photoElement: ElementRef<HTMLDivElement>;
  @Input() isSidebar = false;

  constructor(private globalConfig: GlobalConfigService,
              private userPhotoService: MibpInternalUserPhotoService,
              private broadcast: BroadcastService) {}

  ngOnInit(): void {
    this.loadUserPhoto();
  }

  private loadUserPhoto(): void {

    if (!this.broadcast.snapshot.mibpSession?.user) {
      return;
    }

    if (this.broadcast.snapshot.mibpSession?.user.type != UserType.Internal) {
      return;
    }

    this.userPhotoService.getUserPhoto().then(base64Image => {
      if (base64Image) {
        this.photoElement.nativeElement.classList.add('my-user-photo--loaded');
        this.photoElement.nativeElement.style.backgroundImage = `${base64Image}`;
      }
    });
  }

}
