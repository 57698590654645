import { Injectable } from "@angular/core";

@Injectable()
export class ArrayHelperService {

  public areEqual(array1: any[], array2: any[]) {

    if ((!array1 && !array2) || (!array1 && array2) || (!array2 && array1)) {
      return false;
    }

    // compare lengths - can save a lot of time
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0, l = array1.length; i < l; i++) {
      // Check if we have nested arrays
      if (array1[i] instanceof Array && array2[i] instanceof Array) {
        // recurse into the nested arrays
        if (!this.areEqual(array1[i], array2[i])) {
          return false;
        }
      } else if (array1[i] !== array2[i]) {
        // Warning - two different object instances will never be equal: {x:20} != {x:20}
        return false;
      }
    }
    return true;
  }

}
