import { SafeHtml } from '@angular/platform-browser';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExpandableListItem, HtmlViewLinkClick, HtmlViewConfig } from 'root/components';

@Component({
  selector: 'mibp-expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss']
})
export class ExpandableListComponent implements OnInit {

  @Input() listItems: ExpandableListItem[] = [];
  // @Input() loadItemBody: (item: ExpandableListItem) => void;
  @Output() loadItemBody = new EventEmitter<ExpandableListItem>();
  @Output() htmlViewLinkClick = new EventEmitter<HtmlViewLinkClick>();
  @Input() htmlViewConfig: HtmlViewConfig = {
    linkOptions: {
      openInNewTab: true
    }
  };

  @Input()
  set setItemBody(item: ExpandableListItem) {
    if (item) {
      const itemInList = this.listItems.filter(x => x.id === item.id)[0];
      itemInList.body = item.body;
      itemInList.bodyLoaded = true;
    }
  }

  constructor() { }

  ngOnInit() { }

  loadBody(item: ExpandableListItem) {
    if (item && !item.bodyLoaded) {
      this.loadItemBody.emit(item);
    }
  }

  isBodyHtml(body: string | SafeHtml): boolean {
    if (typeof body === 'string') {
      return false;
    }
    return true;
  }

  htmlLinkClick(event: HtmlViewLinkClick) {
    this.htmlViewLinkClick.emit(event);
  }
}
