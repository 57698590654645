import { ActivatedRoute } from '@angular/router';
import { OnDestroy, Input, ChangeDetectorRef, ViewChildren, QueryList, EventEmitter, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CartService, QuickOrderLine, LocalizationService } from 'root/services';
import { Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { QuickAddRowComponent } from '../quick-add-row/quick-add-row.component';
import { AddItemToCartSource, CartAddProductErrorReason, ProductCodeandQuantityDto } from 'root/mibp-openapi-gen/models';

export class QuickAddRowItem {
  product: string;
  quantity: number;
  possibleToAddNewRow: boolean;
  isValid: boolean;
  invalidProduct: boolean;
  cartSizeExceeded: boolean;
  decimalNotSupported: boolean;
}

@Component({
  selector: 'mibp-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.scss', './../validation-messages/validation-messages.component.scss']
})

export class QuickAddComponent implements OnInit, OnDestroy  {
  @Input() maxItems = 250;
  @Input() skipFocus = false;
  @Input() multiRowQuickAdd = false;
  @Input() hideAddToCartButton: boolean;
  @Input() hideQuantity: boolean;
  @Input() cartId: Guid = null;
  @Input() compactLayout = false;
  @Input() buttonTextKey = 'Carts_Active_AddToCartButton';
  @Input() zeroMinQuantity = false;
  @Output() addedToCart: EventEmitter<any> = new EventEmitter<any>();
  @Output() dataChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() isPromotion = false;
  @ViewChildren('cqr') quickAddRowChildren: QueryList<QuickAddRowComponent>;
  public rows: QuickAddRowItem[];

  // Resource strings
  productNumberResourceString: string;
  quantityResourceString: string;
  resourceStringsUpdated: Subscription;

  public working = false;
  public isValid = false;

  addStart = false;
  constructor(
    private cartService: CartService,
    private localization: LocalizationService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef
  ) { }

  removeRow(index: number, skipDefaultAdd = false) {
    this.working = true;
    this.rows.splice(index, 1);
    if (this.rows.length === 0 && !skipDefaultAdd) {
      this.addNewRow(0);
      this.isValid = false;
    }
    this.validateData();
    this.recalcAddButtonPosition();
    this.working = false;
    setTimeout(() => {
      if (this.quickAddRowChildren) {
        if (!this.skipFocus) {
          this.quickAddRowChildren.last.setFocus();
        }
      }
    }, 200);

    this.dataChanged.emit();
  }

  addNewRow(index: number, triggerChangedEvent = true) {
    this.addRow(null, this.isPromotion ? 0 : 1, false, triggerChangedEvent);
  }

  addMultipleRows(itemNumbersAndIndex: [string, number]) {
    if (!this.multiRowQuickAdd){
      return;
    }
    else {
      let count = 0;
      const itemNumberString = itemNumbersAndIndex[0];
      const index = itemNumbersAndIndex[1];
      itemNumberString.split('\r\n').forEach(itemNumber =>{
        const productQuantityData = itemNumber.split('\t');
        const product = productQuantityData[0];
        if (product){
          let quantity = 1;
          if(productQuantityData.length > 1 && productQuantityData[1]){
            const quantityVal = productQuantityData[1];
            // eslint-disable-next-line radix
            quantity = Number(quantityVal);

            quantity = isNaN(quantity) ? 1 : quantity == 0 ? 1 : quantity;
          }

          this.addRow(product, quantity, false);
          count++;
        }
      });

      if (count) {
        this.removeRow(index);
      }
    }
  }

  recalcAddButtonPosition() {
    if (this.isAddRowPossible(this.rows.length - 1)) {
      if (this.rows.length !== 1) {
        this.rows[this.rows.length - 2].possibleToAddNewRow = false;
      }
      this.rows[this.rows.length - 1].possibleToAddNewRow = true;
    }
  }

  addRow(product: string, quantity: number, skipValidation: boolean, triggerChangedEvent = true): any {
    const item = new QuickAddRowItem();
    item.product = product;
    item.quantity = quantity;
    item.invalidProduct = false;
    this.rows.push(item);
    if (!skipValidation) {
      this.validateData();
    }
    this.recalcAddButtonPosition();

    setTimeout(() => {
      if (this.quickAddRowChildren) {
        this.quickAddRowChildren.last.setFocus();
      }
    }, 200);

    if (triggerChangedEvent) {
      this.dataChanged.emit();
    }    
  }

  addFromImport(toAdd: ProductCodeandQuantityDto[]) {
    const quickAddItems = toAdd.map(excelItem => {
      const item = new QuickAddRowItem();
      item.product = excelItem.productCode;
      item.quantity = excelItem.quantity;
      item.invalidProduct = false;
      return item;
    });

    this.rows = quickAddItems;
    this.validateData();
    this.recalcAddButtonPosition();

    setTimeout(() => {
      if (this.quickAddRowChildren) {
        this.quickAddRowChildren.last.setFocus();
      }
    }, 200);

    this.dataChanged.emit();
  }

  trackByFn(index, item) {
    return index;
  }

  validateData(): boolean {
    if (this.quickAddRowChildren === undefined) {
      this.isValid = false;
      return false;
    }

    let temp = true;
    const childs = this.quickAddRowChildren.toArray();
    if (childs.length === 0) {
      temp = false;
    } else if (childs.length >= this.rows.length) {
      for (let index = 0; index < childs.length; index++) {
        if (this.rows[index] !== undefined && childs[index].isValid() === false) {
          temp = false;
          break;
        }
      }
    } else if (this.rows.length >= childs.length) {
      for (let index = 0; index < this.rows.length; index++) {
        if (this.rows[index] !== undefined && this.rows[index].product === null || this.rows[index].quantity === 0 ) {
          temp = false;
          break;
        }
      }
    }

    this.isValid = temp;
    this.addStart = !this.isValid;
    return temp;
  }

  onSubmit() {
    this.working = true;
    this.addStart = true;
    if (!this.validateData()) {
      this.working = false;
      return;
    }


    const lines: QuickOrderLine[] = this.rows.map(quickAddRow => {
      let product = quickAddRow.product;
      if (product) { product = product.trim(); }
      return {

        productCode: product,
        quantity: quickAddRow.quantity
      } as QuickOrderLine;
    });

    this.cartService.addToCart(lines, AddItemToCartSource.QuickAdd, this.cartId).then((result) => {
      this.ref.detach();
      for (let i = this.rows.length; i--;) {
        const line = this.rows[i];
        const err = result.productErrors.find(err => err.index === i);
        if (!err) {
          this.rows.splice(i, 1);
        } else {
          line.invalidProduct = err.reason === CartAddProductErrorReason.Missing || err.reason === CartAddProductErrorReason.Unknown;
          line.cartSizeExceeded = err.reason === CartAddProductErrorReason.CartSizeExceeded;
          line.decimalNotSupported  =  err.reason === CartAddProductErrorReason.DecimalNotSupported;
        }
      }
      this.ref.reattach();
      this.addedToCart.emit(result);
    }).finally(() => {
      this.working = false;
      this.addStart = false;
      if (this.rows.length < 1) {
        this.addRow(null, 1, false);
      }
    });
  }

  public isAddRowPossible(index: number): boolean {
    if (this.rows.length >= this.maxItems) {
      return false;
    }
    return this.multiRowQuickAdd && index + 1 === this.rows.length;
  }

  ngOnInit() {
    this.resourceStringsUpdated = this.localization.loaded.subscribe(locale => {
      // Required when using resource strings from code
      this.localization.getResourceString('Global_ProductNumber').then(resourceStringValue => {
        this.productNumberResourceString = resourceStringValue;
      });
      this.localization.getResourceString('Global_Quantity').then(resourceStringValue => {
        this.quantityResourceString = resourceStringValue;
      });
    });

    this.localization.getResourceString('Global_ProductNumber').then(resourceStringValue => {
      this.productNumberResourceString = resourceStringValue;
    });
    this.localization.getResourceString('Global_Quantity').then(resourceStringValue => {
      this.quantityResourceString = resourceStringValue;
    });

    this.rows = [];
    this.addNewRow(0, false);
  }

  ngOnDestroy() {
    this.resourceStringsUpdated.unsubscribe();
  }
}
