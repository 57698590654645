/* eslint-disable no-console */
import { MibpLogLevel } from "./loglevel.enum";
import { MibpLogEntry } from "./logentry.class";
import { BroadcastService } from "../broadcast-service/broadcast.service";

export class MibpLogger {

  private logWithDate = true;
  private timers: { [key: string]: Date } = {};

  constructor(private prefix: string, private level: MibpLogLevel, private broadcastService: BroadcastService) {}

  startTime(name): string {
    this.timers[name] = new Date();
    return name;
  }

  endTime(key: string) {
    if (this.timers[key]) {
      const diff = (new Date()).getTime() - this.timers[key].getTime();
      this.debug(key, diff / 1000, 's' );
      delete this.timers[key];
    }
  }

  debug(msg: string, ...params: any[]) {
    this.writeToLog(msg, MibpLogLevel.Debug, params);
  }

  warn(msg: string, ...params: any[]) {
    this.writeToLog(msg, MibpLogLevel.Warn, params);
  }

  error(msg: string, ...params: any[]) {
    this.writeToLog(msg, MibpLogLevel.Error, params);
  }

  info(msg: string, ...params: any[]) {
    this.writeToLog(msg, MibpLogLevel.Info, params);
  }

  private shouldLog(level: MibpLogLevel): boolean {
    let ret  = false;
    if ((level >= this.level &&
         level !== MibpLogLevel.Off) ||
         this.level === MibpLogLevel.All) {
      ret = true;
    }
    return ret;
  }

  private writeToLog(msg: string, level: MibpLogLevel, params: any) {
    if (this.shouldLog(level)) {
      const entry: MibpLogEntry = new MibpLogEntry();
      entry.message = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;
      entry.prefix = this.prefix;

      const logParameters: any[] = [entry.buildLogString()];
      if (params) {
        if (Array.isArray(params)) {
          if (params.length > 0) {
            params.forEach(param => logParameters.push(param));
          }
        } else {
          logParameters.push(params);
        }
      }

      if (level === MibpLogLevel.Error) {
        // tslint:disable-next-line
        console.error.apply(console, logParameters);
      } else if (level === MibpLogLevel.Warn) {
        // tslint:disable-next-line
        console.warn.apply(console, logParameters);
      } else {
        // tslint:disable-next-line
        console.log.apply(console, logParameters);
      }
    }
  }
}
