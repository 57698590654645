/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { MediaApiController } from "root/mibp-openapi-gen/controllers";
import { AddItemToCartSource } from "root/mibp-openapi-gen/models/add-item-to-cart-source";
import { CartService, GlobalConfigService, LogService, MibpLogger, ScriptService } from "root/services";
import { MibpSessionService } from "root/services/mibp-session/mibp-session.service";
import { Subscription, firstValueFrom } from "rxjs";
import { DocumotoWidgetAuthResponse, LogDocumotoWidgetIntegrationMessageTimingVm, LogDocumotoWidgetIntegrationMessageVm } from 'root/mibp-openapi-gen/models';
import { differenceInMilliseconds } from "date-fns";

declare const Widget: any;

type DocumotoCartItem =
{
  widgetType: string,
  partId: number,
  partDescription: string,
  pagePartId?: string //empty when part is added via Search Results Info tab
  partNumber: number,
  quantity: number,
  supplierKey: string,
  uom: string,
  itemText?: string //empty when part is added via Search Results Info tab
  mediaId?: number //present when adding part from book context
  mediaIdentifier?: string //present when adding part from book context
  mediaName?: string //present when adding part from book context c
  hapterId?: number //present when adding part from book context with chapter
  chapterName?: string //present when adding part from book context with chapter
  pageId?: string //present when adding part from Library page parts BOM
  pageHashkey?: string //present when adding part from Library page parts BOM
  pageName?: string //present when adding part from Library page parts BOM
};

interface DocumotoEventDetails {
  type: string;
  message: string;
  widgetType: string;
}

@Component({
  selector: 'mibp-documoto-widget',
  templateUrl: './documoto-widget.component.html',
  styleUrls: ['./documoto-widget.component.scss']
})
export class MibpDocumotoWidgetComponent implements OnInit, OnChanges {

  @Input() mediaIdentifier: string;
  @Input() widgetType: 'book' | 'library' = 'book';

  log: MibpLogger;
  deliverySequenceSubscription: Subscription;
  widgetScriptLoaded: boolean;
  currentUrlWithoutQuerystring: string;
  protected isLoading = false;
  private timingsForLog: LogDocumotoWidgetIntegrationMessageTimingVm[] = [];
  private timerDate!: Date;
  private authResponse?: DocumotoWidgetAuthResponse;


  private readonly documotoEventTypes = {
    widgetLogin: 'widgetLogin',
    widgetError: 'widgetError'
  };


  constructor(
    private globalConfig: GlobalConfigService,
    private scriptService: ScriptService,
    private mediaApi: MediaApiController,
    private cartService: CartService,
    private sessionService: MibpSessionService,
    private router: Router,
    private logger: LogService) {
    const queryStringIndex = this.router.url.indexOf('?');
    this.currentUrlWithoutQuerystring = queryStringIndex >= 0 ? this.router.url.substring(0, queryStringIndex) : this.router.url;
    this.log = logger.withPrefix(`documoto-${this.widgetType}-widget`);
  }

  ngOnInit(): void {
    this.deliverySequenceSubscription = this.sessionService.activeDeliverySequence$.subscribe(() => this.loadWidgetScript());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mediaIdentifier && !changes.mediaIdentifier.firstChange && changes.mediaIdentifier.previousValue != changes.mediaIdentifier.currentValue) {
      this.loadWidgetScript();
    }
  }

  private startLogTimer(name: string): void {
    this.endLogTimer();
    this.timerDate = new Date();
    this.timingsForLog.push({
      name: name,
      durationMs: -1
    });
  }

  private endLogTimer(): void {

    const logItem = this.timingsForLog.find(l => l.durationMs == -1);
    if (logItem) {
      logItem.durationMs = differenceInMilliseconds(new Date(), this.timerDate);
      this.log.info(logItem.name, (logItem.durationMs / 1000), 's');
    }

  }

  loadWidgetScript(): void {
    this.timingsForLog = [];

    if (this.widgetScriptLoaded) {
      const widgetContainer = document.getElementById(`documoto-container`);

      if (widgetContainer) {
        widgetContainer.innerHTML = '';
        this.initWidget();
      } else {
        this.log.error('Error loading the widget. Container not present in DOM');
      }
    } else {
      this.startLogTimer('Load widget script');
      this.isLoading = true;
      this.scriptService.loadScript(this.globalConfig.documotoWidgetScriptUrl).subscribe({
        next: () => {
          this.endLogTimer();
          this.widgetScriptLoaded = true;
          this.initWidget();
        },
        error: e => {
          this.endLogTimer();
          this.logToIntegrationLog(false, { errorMessage: 'Error when loadig script widget'});
          this.log.error('Error when loading widget script', e);
        }
      });
    }
  }

  initWidget(): void {
    this.isLoading = true;
    this.startLogTimer('Authenticate widget');

    if (this.widgetType == 'library') {
      this.mediaApi.authenticateDocumotoLibraryWidget().subscribe({
        next: config => {
          this.handleAuthResponse(config);
        },
        error: e => {
          this.endLogTimer();
          this.logToIntegrationLog(false, {errorMessage: 'Error when authenticating library widget'});
          this.log.error('Error when authenticating library widget', e);
        }
      });
    } else {
      this.mediaApi.authenticateDocumotoWidget({ mediaIdentifier: this.mediaIdentifier }).subscribe({
        next: config => {
          this.handleAuthResponse(config);
        },
        error: e => {
          this.endLogTimer();
          this.logToIntegrationLog(false, {errorMessage: 'Error when authenticating book widget'});
          this.log.error('Error when authenticating book widget', e);
        }
      });
    }
  }

  private handleAuthResponse(config: DocumotoWidgetAuthResponse) {
    this.authResponse = config;
    this.endLogTimer();
    if (this.sessionService.activeDeliverySequence) {
      Widget.addToCart = (item: DocumotoCartItem) => {
        this.cartService.addToCart([
          {
            productCode: item.partNumber?.toString(),
            quantity: item.quantity
          }], AddItemToCartSource.DocumotoWidget)
          .catch(error => {
            this.log.error("Could not add to active cart", error);
          });
      };
    } else {
      Widget.addToCart = undefined;
    }

    this.startLogTimer('Widget.init');
    Widget.init({...config, enablePartTags: true, enablePartComments: true, });
    this.listenForWidgetEvents(config.bindToElementById, this.mediaIdentifier);
  }

  private listenForWidgetEvents(widgetContanerId: string, mediaIdentifier: string): void {
    const iframe = document.querySelector(`#${widgetContanerId} iframe`);

    if (iframe) {
      Object.keys(this.documotoEventTypes).forEach(eventKeyName => {
        iframe.addEventListener(this.documotoEventTypes[eventKeyName], e => this.onDocumotoWidgetEvent(mediaIdentifier, e));
      });
    }
  }

  private onDocumotoWidgetEvent(mediaIdentifier: string, e: Event): void {
    this.endLogTimer();
    this.log.info('WidgetEvent received', mediaIdentifier, e.type, e);

    if (e.type == this.documotoEventTypes.widgetError) {
      this.logToIntegrationLog(false, {
        errorMessage: 'Documoto error: ' + (e as any).detail?.message,
        documotoDetails: (e as any).detail,
        stackTraceOrRawEvent: e
      });
    } else if (e.type == this.documotoEventTypes.widgetLogin) {
      this.logToIntegrationLog(true, {
        documotoDetails: (e as any).detail,
        stackTraceOrRawEvent: e
      });
    }

    this.isLoading = false;
  }


  private async logToIntegrationLog(isSuccessful: boolean, options?: { errorMessage?: string, documotoDetails?: DocumotoEventDetails, stackTraceOrRawEvent?: object }): Promise<void> {

    const timings = this.timingsForLog;
    const url = window.location.href;
    const userAgent = navigator.userAgent;
    const documotoDetails = options?.documotoDetails || null;
    const documotoWidgetAuthResponse = this.authResponse;
    const mediaIdentifier = this.mediaIdentifier;

    let errorMessage = options?.errorMessage;
    let stackTrace: string = null;

    if (!isSuccessful && !errorMessage) {
      errorMessage = 'An unknown error occured';
    }

    if (options?.stackTraceOrRawEvent) {
      try {
        stackTrace = JSON.stringify(options?.stackTraceOrRawEvent);
      } catch (e) {
        stackTrace = `ERROR serialzing stack trace in frontend`;
      }
    }

    const request: LogDocumotoWidgetIntegrationMessageVm = {
      url,
      errorMessage,
      isSuccessful,
      documotoDetails,
      timings,
      userAgent,
      stackTrace,
      documotoWidgetAuthResponse,
      mediaIdentifier
    };

    this.log.info('Logging Integration message', request);

    try {

      await firstValueFrom(this.mediaApi.logDocumotoWidgetIntegrationMessage({
        mediaIdentifier: this.mediaIdentifier,
        body: request
      }));

    } catch (e) {
      this.log.error('Error logging integration message', e);
    }
  }
}
