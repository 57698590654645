/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EquipmentSearchViewModel } from '../../models/equipment-search-view-model';
import { ProductAreaSearchResponse } from '../../models/product-area-search-response';

export interface ListEquipmentProductAreas$Plain$Params {
      body?: EquipmentSearchViewModel
}

export function listEquipmentProductAreas$Plain(http: HttpClient, rootUrl: string, params?: ListEquipmentProductAreas$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAreaSearchResponse>> {
  const rb = new RequestBuilder(rootUrl, listEquipmentProductAreas$Plain.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProductAreaSearchResponse>;
    })
  );
}

listEquipmentProductAreas$Plain.PATH = '/equipments/list/productareas';
