
<div *ngIf="!hasReceivedResponse && !errorData">
    <mibp-loader></mibp-loader>
</div>

<div *ngIf="hasReceivedResponse && !errorData">
  <ng-content select="[isSuccess]"></ng-content>
</div>

<div *ngIf="errorData">
  <mibp-system-message [messageType]="msg.Types.Error" #msg>
    <ng-content  select="[isError]"></ng-content>
    <pre>{{errorData | json}}</pre>
  </mibp-system-message>
</div>

<div *ngIf="debug">
  <hr/>
  {{ fullResponse | json }}
</div>
