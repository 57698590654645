import { Subscription } from 'rxjs/internal/Subscription';
import { OnDestroy } from '@angular/core';
import { ScrollToService } from './../../services/scroll-to/scroll-to.service';
import { Page } from './page';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { PaginationService } from 'root/services/pagination-service/pagination.service';
import { BroadcastService } from 'root/services';

@Component({
  selector: 'mibp-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges, OnDestroy {


  constructor(private pagerService: PaginationService, private scrollToService: ScrollToService,private broadcast: BroadcastService) { }

  startIx: number;
  pager: Page;
  currentPage: number;
  visiblePages = 5;
  responsiveSub: Subscription;

  @Input() scrollToElement: HTMLElement;
  @Input() totalItems: number;
  @Input() pageSize: number;
  @Output() changePage = new EventEmitter();

  @Input()
  set page(pageIndex: number) {
    this.currentPage = pageIndex;
    this.pager = this.pagerService.getPager(this.totalItems, this.currentPage, this.pageSize, false, this.visiblePages);
  }

  @Input()
  set startIndex(startIndex: number) {
    this.startIx = startIndex;
    const page = this.pagerService.getpageFromIndex(this.startIx, this.pageSize);
    this.setPage(page, false);
  }

  ngOnInit(): void {
    const page = this.pagerService.getpageFromIndex(this.startIx, this.pageSize);
    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe(br => {
      br.lteq('xxs') === true ? this.visiblePages = 3 : this.visiblePages = 5;
      this.pager = this.pagerService.getPager(this.totalItems, this.currentPage, this.pageSize, false, this.visiblePages);
    });
    this.setPage(page, false);
  }

  setPage(pageNumber: number, isUserEvent = true): void {
    this.pager = this.pagerService.getPager(this.totalItems, pageNumber, this.pageSize, isUserEvent, this.visiblePages);
    this.changePage.emit(this.pager);
    this.currentPage = pageNumber;
    if (this.scrollToElement) {
      this.scrollToService.scrollToElement(this.scrollToElement, () => { }, -300, 25);
    }
  }

  setIndex(skip: number, isUserEvent = true): void {
    const pageIndex = this.pagerService.getpageFromIndex(skip, this.pageSize);
    this.setPage(pageIndex, isUserEvent);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If total number of items or pageSize change we must create a new pager...
    if (changes.totalItems && changes.totalItems.previousValue !== changes.totalItems.currentValue ||
        changes.pageSize && changes.pageSize.previousValue !== changes.pageSize.currentValue
    ) {

      this.pager = this.pagerService.getPager(this.totalItems, this.currentPage, this.pageSize, false, this.visiblePages);
    }
  }

  ngOnDestroy(): void {
    if(this.responsiveSub){
      this.responsiveSub.unsubscribe();
    }
  }
}
