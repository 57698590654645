import { Component, OnInit, Input, EventEmitter, Output, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MibpGridFrontendFilter } from '../../mibp-grid.types';
import { ButtonColors, ButtonStyles } from 'root/components/button/button.enum';
import { MibpGridFilterCheckboxFacetComponent } from '../mibp-grid-filter-checkbox-facet/mibp-grid-filter-checkbox-facet.component';
import { MibpGridColumnDataType } from 'root/mibp-openapi-gen/models';


@Component({
  selector: 'mibp-grid-toolbar',
  templateUrl: './mibp-grid-toolbar.component.html',
  styleUrls: ['./mibp-grid-toolbar.component.scss']
})
export class MibpGridToolbarComponent implements OnInit {

  expanded = false;
  filterTypes = MibpGridColumnDataType;
  filterList: MibpGridFrontendFilter[];
  searchForm: UntypedFormGroup;
  anyFilterableColumns = false;
  anySearchableColumns = false;
  anyVisibleFilters = false;
  anyActiveFilters = false;
  buttonColors = ButtonColors;
  buttonStyles = ButtonStyles;
  @Input() tabName : string;
  @Input() showFilters = true;
  @Input() showSearch = true;
  @Input() showHelpIcon = false;
  @ViewChild(MibpGridFilterCheckboxFacetComponent) checkboxFacet: MibpGridFilterCheckboxFacetComponent;

  @Input() query: string;
  checkboxFacetFilter: MibpGridFrontendFilter;
  totalFilters: MibpGridFrontendFilter[];
  @Input()
  set filters(value: MibpGridFrontendFilter[]) {
    this.filterList = value ? value.filter(f => f.column.filterable) : [];
    this.totalFilters = value ? value.filter(f => f.column.filterable) : [];
    if(this.filterList){
      this.filterList.forEach(x=>{
        if(x.checkboxFacets && x.checkboxFacets.length!=0){
          this.checkboxFacetFilter = x;
        }
      });
    }
    this.anyVisibleFilters = this.filterList.length > 0;
    this.anySearchableColumns = value && value.filter(f => f.column.searchable).length > 0;
    this.anyActiveFilters = this.filters ? !!this.filterList.find(f => f.value && f.value.length > 0) : false;
    if(this.checkboxFacetFilter){
      this.filterList.splice(this.filterList.indexOf(this.checkboxFacetFilter),1);
    }
  }

  get filters(): MibpGridFrontendFilter[] {
    return this.totalFilters;
  }

  @Output() search = new EventEmitter<string>();
  @Input() searchPlaceholder: string;
  @Output() filter = new EventEmitter<MibpGridFrontendFilter>();
  @Output() reload = new EventEmitter();
  @Input() disabled = false;
  @Output() showUserFlow = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) { }


  trackByFilter(index: number): number {
    return index;
  }

  ngOnInit(): void {


    this.searchForm = this.fb.group({
      query: [this.query]
    });
  }

  public updateCheckboxFilterCount(colId: string, text: string, count: number): void {
    if (this.checkboxFacet) {
      this.checkboxFacet?.updateCount(text, count);
    } else {
      setTimeout(() => {
        this.checkboxFacet?.updateCount(text, count);
      }, 500);
    }
  }

  /**
   * When the user click to remove a single filter using the toolbar items
   */
  removeFilter(e: Event, filter: MibpGridFrontendFilter, singleFacetValue?: string): void {
    e.preventDefault();
    if (singleFacetValue) {
      const theColumn = this.filters.find(f => f.column.id === filter.column.id);
      if (theColumn) {
        if (Array.isArray(theColumn.value)) {
          const valueIndex = theColumn.value.indexOf(singleFacetValue);
          if (valueIndex !== -1) {
            const newValues = theColumn.value.slice(0) as string[];
            newValues.splice(valueIndex, 1);
            this.filter.emit(Object.assign({}, filter, {
              value: newValues
            }));

          }
        }
      }
    } else {
      this.filter.emit(Object.assign({}, filter, {
        value: ''
      }));
    }
  }

  resetFilters(): void {
    this.searchForm.setValue({
      query: null
    });
    this.filters = [];
  }

  filterUpdated(filter: MibpGridFrontendFilter, value: any): void {
    this.filter.emit(Object.assign({}, filter, {
      value: value
    }));
  }

  triggerReload(): void {
    if (!this.disabled) {
      this.reload.emit();
    }
  }

  triggerUserFlow(): void{
    this.showUserFlow.emit();
  }

  queueSearchUpdate(e?: Event): void {
    e?.stopPropagation();
    e?.preventDefault();
    this.search.emit(this.searchForm.value.query);
  }

}
