import { BroadcastService } from './../../services/broadcast-service/broadcast.service';
import { endOfDay, startOfDay } from 'date-fns';
import { ScrollToService } from './../../services/scroll-to/scroll-to.service';
import { FrontendContextService } from './../../services/front-end-context/front-end-context.service';
import { Subscription } from 'rxjs';
import { OnDestroy, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormattingService } from './../../services/formatting/formatting.service';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { MibpFilterBase, MibpFilterChangedArgs, MibpTextFilter, MibpFilterChangedReason, MibpFilterValues, MibpDateFilter, ValueUpdateFromClassEvent, MibpDropdownFilter } from './filter.component.types';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { LocalizationService } from 'root/services';
import { DropdownInput } from '../dropdown';

@Component({
  selector: 'mibp-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out'))
    ])
  ]
})
export class FilterComponent implements OnInit, OnDestroy, OnChanges {

  @Input() filters: MibpFilterBase[];
  @Input() textFilterDelayMs = 2000;
  @Input() filterQuerystringParam = 'filter';
  @Input() alwaysTriggerEvent = false;
  @Input() extraQueryParams: Params;
  @Input() showEmptyDropdowns = false;
  @Input() populateInitialUnknownValues = false; // Will add values from querystring as selected even if item list is not loaded
  @Output() filterChanged = new EventEmitter<MibpFilterChangedArgs>();

  textFilterDelayTimer: number;
  dateFormatPlaceholder: string;
  internalId: string;              // Internal id so element id:s can be unique
  isInitializing = true;

  queryParamSub: Subscription;
  pendingChanges: MibpFilterValues = {};
  filterSubscriptons: { [filterName: string]: Subscription } = {};
  collapsed = true;
  responsiveSub: Subscription;
  useMobileLayout = false;
  triggerChangeFromClassTimer: number;
  resourceSubscription: Subscription;

  constructor(private formatting: FormattingService,
    private scrollTo: ScrollToService,
    private broadcast: BroadcastService,private localizationService: LocalizationService,
    private router: Router, private route: ActivatedRoute, context: FrontendContextService) {

    this.internalId = `f${context.newRandomIdString()}`;
    this.resourceSubscription = this.localizationService.Locale$.subscribe(locale => {
      this.dateFormatPlaceholder = this.formatting.getDateFormatPlaceholder();
    });



  }

  ngOnInit(): void {
    // Listen for querystring changes. This will trigger immeidatly as well
    this.queryParamSub = this.route.queryParams.subscribe((params) => this.onQuerystringFilterUpdate(params));
    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe((screen) => {
      this.useMobileLayout = screen.lteq('s');
      if (this.collapsed && !this.useMobileLayout) {
        this.collapsed = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.resourceSubscription) {
      this.resourceSubscription.unsubscribe();
    }
    this.queryParamSub?.unsubscribe();
    this.responsiveSub?.unsubscribe();
    Object.keys(this.filterSubscriptons).forEach(filterName => this.filterSubscriptons[filterName].unsubscribe());
  }

  public refreshFiltersFromQuerystring(forceTriggerChange = false): void {
    this.onQuerystringFilterUpdate(this.route.snapshot.queryParams, forceTriggerChange);
  }

  /**
   * Update filters with values from querystring
   * This will trigger when page is first loaded and when querystring is changed
   */
  onQuerystringFilterUpdate(params: Params, forceTriggerChange = false): void {
    const filterParamString = params['filter'];
    let filterParams: { [key: string]: string | DropdownInput[]};

    try {
      filterParams = JSON.parse(filterParamString);
    } catch(e) {
      filterParams = {};
    }

    let anyChanges = false;

    // Go through available filters
    // Update filter values from parsed values in querystring
    this.filterArray.forEach(filter => {
      if (filter instanceof MibpTextFilter) {

        const value = filterParams[filter.name] ? filterParams[filter.name] : '';
        if (value !== filter.value) {
          filter.$_internalComponentSetValue(filterParams[filter.name] as string);
          anyChanges = true;
        }
      } else if (filter instanceof MibpDropdownFilter){
        const pendingDropdownListUpdate = filter.$_getPendingDropdownListUpdate();
        if (pendingDropdownListUpdate) {
          filter.$_internalComponentSetValue(pendingDropdownListUpdate);
          filter.$_setPendingDropdownListUpdate(null);
        }

        const value = (filterParams[filter.name] ? filterParams[filter.name] : []) as DropdownInput[];
        value.filter(v => v).forEach(v => v.checked = true);
        if (!this.areDropdownSelectionEqual(filter.items, value)) {
          filter.items.forEach(cbx => {
            if (!cbx.checked && value.find(c =>c.value == cbx.value)){
              cbx.checked = true;
              anyChanges = true;
            } else if (cbx.checked && !value.find(c =>c.value == cbx.value)) {
              cbx.checked = false;
              anyChanges = true;
            }
          });

          let itemAdded = false;

          // Fix to populate dropdown with initial values without having to execute a search first
          // Currently used on electronic manuals page
          if (this.populateInitialUnknownValues) {
            for (const valueItem of value) {
              if (!filter.items.find(va => va.value == valueItem.value)) {
                filter.items.push(valueItem);
                itemAdded = true;
              }
            }
          }

          filter.initialSelectedValues = filter.items.filter(i => i.checked);

          if (itemAdded) {
            anyChanges = true;
          }
        }
      }
      else if (filter instanceof MibpDateFilter) {
        const value = filterParams[filter.name] ? new Date(filterParams[filter.name] as string) : null;
        const isValidDate = this.isValidDate(value);

        if (isValidDate && filter.value?.getTime() !== value?.getTime()) {
          filter.$_internalComponentSetValue(value);
          anyChanges = true;
        } else if (!isValidDate && filter.value) {
          filter.$_internalComponentSetValue(null);
          anyChanges = true;
        } else if (filter.value?.getTime() !== value?.getTime()) {
          anyChanges = true;
        }
      }
    });

    this.pendingChanges = {};

    if (forceTriggerChange || (anyChanges || this.isInitializing || this.alwaysTriggerEvent)) {
      this.emitChangeEvent('user', anyChanges);
      if (this.isInitializing) {
        this.isInitializing = false;
      }
    }

  }

  private isValidDate(value: Date): boolean {
    return value && value instanceof Date && !isNaN(value.getTime());
  }

  /**
   * This will be invoked after querystring is updated and the new filter values
   * are set on the filters
   */
  private emitChangeEvent(reason: MibpFilterChangedReason, hasChanges = true): void {
    const values: MibpFilterValues = {};

    this.filterArray.forEach(filter => {
      if (filter instanceof MibpTextFilter) {
        values[filter.name] = this.pendingChanges[filter.name] ?? filter.value;
      } else if (filter instanceof MibpDropdownFilter) {
        const items = (this.pendingChanges[filter.name] ? this.pendingChanges[filter.name] : filter.items) as DropdownInput[];

        // Delete the "checked" option before sending the change-event. We only send checked items anyway
        values[filter.name] = items.filter(i => i.checked).map(item => {
          const copy = Object.assign({}, item);
          delete copy.checked;
          return copy;
        });

      }
      else if (filter instanceof MibpDateFilter) {
        const value = (this.pendingChanges[filter.name] ? this.pendingChanges[filter.name] : filter.value);
        values[filter.name] = value;
      }
    });

    this.filterChanged.emit({
      hasChanged: hasChanges,
      value: values
    });

  }

  /**
   * Compare two dropdown lists to see if the "checked" items are different
   */
  areDropdownSelectionEqual(first: DropdownInput[], second: DropdownInput[]): boolean {
    first = first || [];
    second = second || [];

    const currentSelection = first.filter(i => i.checked).map(i => i.value).join('|');
    const newSelection = second.filter(i => i.checked).map(i => i.value).join('|');

    return currentSelection == newSelection;
  }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes.filters?.currentValue) {
      Object.keys(this.filterSubscriptons).forEach(filterName => this.filterSubscriptons[filterName].unsubscribe());

      this.filters.forEach((filter) => {
        this.filterSubscriptons[filter.name] = filter.value$.subscribe(e => this.onValueChangedFromFilterClass(e));
      });
    }
  }

  onDateChanged(e: MibpDateFilter, v: Date): void {
    if (this.isValidDate(v)) {
      if (e.asEndOfDay) {
        v = endOfDay(v);
      } else {
        v = startOfDay(v);
      }
    } else {
      v = null;
    }

    this.checkForDateFilterChange(e, v);

  }

  /**
   * This is triggerd when the value is updated using the event class
   * For example: filterService.getTextFilter('query').setValue('hi')
   */
  onValueChangedFromFilterClass(e: ValueUpdateFromClassEvent): void {
    clearTimeout(this.triggerChangeFromClassTimer);
    const filter = this.filters.find(f => f.name === e.filterName);
    if (filter) {
      if (filter instanceof MibpTextFilter) {
        this.checkForTextFilterChange(filter, e.value as string);
      } else if (filter instanceof MibpDropdownFilter) {

        if (e.value) {
          const newItems = e.value as DropdownInput[];

          newItems.forEach(item => {
            if (typeof item.checked !== 'boolean') {
              const currentCheckedValue = filter.items?.find(c => c.value === item.value)?.checked;
              if (typeof currentCheckedValue === 'boolean') {
                item.checked = currentCheckedValue;
              }
            }

            if (typeof item.count === 'undefined') {
              item.count = filter.items?.find(c => c.value === item.value)?.count;
            }
          });

          filter.$_setPendingDropdownListUpdate(newItems);
          const newSelection = newItems.filter(i => i.checked === true);

          if ((newItems.length > 0 && !filter.items?.length) || !this.checkForDropdownFilterChange(filter, newSelection)) {
            this.pendingChanges[filter.name] = newSelection;
            filter.$_internalComponentSetValue(newItems);
          }

        } else {
          this.checkForDropdownFilterChange(filter, null);
        }

      } else if (filter instanceof MibpDateFilter) {

        if (e.value) {
          const theDate = e.value as Date;
          let date: Date;
          if (filter.asEndOfDay) {
            date = endOfDay(theDate);
          } else {
            date = startOfDay(theDate);
          }
          this.checkForDateFilterChange(filter, date);
        } else if (filter.value) {
          this.checkForDateFilterChange(filter, null);
        }

        // filter.items = e.value as MibpCheckboxItem[];
      }
    }

    this.triggerChangeFromClassTimer = window.setTimeout(() => {
      this.refreshFiltersFromQuerystring(true);
    });

  }

  isNumber(obj: any): boolean {
    if (obj === null || typeof obj === 'undefined') {
      return false;
    }
    return !isNaN(obj);
  }

  onDropdownChange(filter: MibpDropdownFilter, dropDowninputs: DropdownInput[]): void {
    const dropdownItems = filter.items.slice(0).map(copy => Object.assign({}, copy));    // Clone object so we don't modify current filters
    dropdownItems.forEach(dropdownItem => {
      let input = dropDowninputs.find(input => input.value == dropdownItem.value);
      if(input){
        dropdownItem.checked = true;
      }
      else{
        dropdownItem.checked = false;
      }
    });             // Update clone with new value
    this.checkForDropdownFilterChange(filter, dropdownItems.filter(i => i.checked));     // Trigger a change if any modification was detected
  }

  /**
   * Compare provided checkbox filter with current filter and trigger change if needed
   */
  checkForDropdownFilterChange(filter: MibpDropdownFilter, checkedItems: DropdownInput[]): boolean {

    const currentSelection = filter.items.filter(i => i.checked).map(i => i.value).join('|');  // Get string representation of current filter values
    const newSelection = checkedItems.filter(i => i.checked).map(i => i.value).join('|');      // Get string representation of new filter values
    const anyChange = currentSelection != newSelection;                                        // Check if the filter has changed

    if (anyChange) {
      this.pendingChanges[filter.name] = checkedItems;    // Set the new value as a pending change for this filter
      this.triggerFilterChangedEvent();                   // Trigger change. This will use pendingChanges for a filter if it exists, otherwise current filter value
    }

    return anyChange;
  }

  /**
   * Restart timer on textfield key up
   */
  onTextFilterKeyUp(filter: MibpTextFilter, e: KeyboardEvent): void {
    clearTimeout(this.textFilterDelayTimer);

    this.textFilterDelayTimer = window.setTimeout(() => {
      this.checkForTextFilterChange(filter, (e.target as HTMLInputElement).value);
    }, this.textFilterDelayMs);

  }

  /**
   * Stop timer on keydown, or search immediately if user press Enter
   */
  onTextFilterKeyDown(filter: MibpTextFilter, e: KeyboardEvent): void {
    clearTimeout(this.textFilterDelayTimer);
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.checkForTextFilterChange(filter, (e.target as HTMLInputElement).value);
    }
  }

  /**
   * Compare current filter with a given value
   * Trigger change if values differ
   */
  checkForTextFilterChange(filter: MibpTextFilter, newValue: string): void {
    if (filter.value !== newValue) {
      this.pendingChanges[filter.name] = newValue?.trim() || '';
      this.triggerFilterChangedEvent();
    }
  }

  /**
   * Compare current filter with a given value
   * Trigger change if values differ
   */
  checkForDateFilterChange(filter: MibpDateFilter, newValue: Date): void {
    if (filter.value !== newValue) {
      this.pendingChanges[filter.name] = newValue;
      this.triggerFilterChangedEvent();
    }
  }

  /**
   * Filter change event will be triggered
   * after the filter URL is updated
   */
  triggerFilterChangedEvent(): void {
    const filterQueryParams = this.createFilterQuerystringValue();
    this.scrollTo.stopNextScrollToTop();

    let params = { filter: filterQueryParams};
    if (this.extraQueryParams) {
      params = Object.assign({}, params, this.extraQueryParams);
    }

    this.router.navigate([], { queryParams: params, queryParamsHandling: 'merge' });
  }


  /**
   * This will create an object with the current filters values.
   * This object will then become the filter querystring
   */
  private createFilterQuerystringValue(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const querystringFilter:{[key: string]: any } = {};
    this.filterArray.forEach(filter => {
      if (filter instanceof MibpTextFilter) {

        const value = (typeof this.pendingChanges[filter.name] !== 'undefined' ? this.pendingChanges[filter.name] : filter.value) as string;

        if (value?.trim().length > 0) {
          querystringFilter[filter.name] = value;
        }

      } else if (filter instanceof MibpDropdownFilter) {

        const checkedCheckboxes = this.pendingChanges[filter.name] ? (this.pendingChanges[filter.name] as DropdownInput[]) : filter.items.filter(cbx => cbx.checked);
        if (checkedCheckboxes?.length > 0) {
          querystringFilter[filter.name] = checkedCheckboxes.map(cbc => {
            return {
              resourceStringKey: cbc.resourceStringKey,
              value: cbc.value,
              text: cbc.text
              // We dont need to send checked property
            };
          });
        }
      }
      else if (filter instanceof MibpDateFilter) {
        const dateValue = typeof this.pendingChanges[filter.name] !== 'undefined' ? this.pendingChanges[filter.name] : filter.value;
        if (dateValue) {
          querystringFilter[filter.name] = dateValue;
        }
      }
    });

    return Object.keys(querystringFilter).length > 0 ? JSON.stringify(querystringFilter) : null;
  }

  /**
   * Get an array representation of the filter dictionary
   */
  private get filterArray(): MibpFilterBase[] {
    if (!this.filters) {
      return [];
    }
    return Object.keys(this.filters).map(name => this.filters[name]);
  }

}
