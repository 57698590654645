import { Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import { MibpLogger, LogService, LoaderService } from 'root/services';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showElementLoader]'
})
export class ShowLoaderDirective implements OnDestroy {
  log: MibpLogger;
  id: string;

  constructor(logger: LogService, private loaderService: LoaderService, private elementRef: ElementRef) {
    this.log = logger.withPrefix('loader.directive');
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('showElementLoader')
  set showElementLoader(value: boolean) {
    if (value === true) {
      this.id = this.loaderService.createLoaderOverElement(this.elementRef.nativeElement as HTMLElement);
    } else {
      this.loaderService.destroyLoaderOverElement(this.id);
    }
  }

  ngOnDestroy() {
    // Delete DOM elements!
    this.loaderService.destroyLoaderOverElement(this.id);
  }

}
