export interface CreateUserFileViewModel {
    FileName: string ;
    Data: string;
    DisplayName: string;
    ExpireDate: Date;
    MimeType: string;
    FileSource:string;
}

export enum UserPropertyType {
  NotSet = 0,
  CompletedNewUXOnboarding = 100,
  CompleteSidebarToggleOnboarding = 200
}

export interface ImportFromExcelViewModel {
  Data: string;
  Name: string;
}
