/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumotoReprocessZipFileRequestVm } from '../../models/documoto-reprocess-zip-file-request-vm';
import { DocumotoReprocessZipFileResponseVm } from '../../models/documoto-reprocess-zip-file-response-vm';

export interface ReprocessZipFiles$Params {
      body?: DocumotoReprocessZipFileRequestVm
}

export function reprocessZipFiles(http: HttpClient, rootUrl: string, params?: ReprocessZipFiles$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoReprocessZipFileResponseVm>> {
  const rb = new RequestBuilder(rootUrl, reprocessZipFiles.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumotoReprocessZipFileResponseVm>;
    })
  );
}

reprocessZipFiles.PATH = '/operations/documoto/jobs/extractzip/reprocess';
