

<mibp-system-message *ngIf="messageWasSent"><mibp-resource-string key="ContactUs_Message_Sent"></mibp-resource-string></mibp-system-message>
<mibp-system-message *ngIf="!messageWasSent && isSendTrigger && showLogoutButton"><mibp-resource-string key="Global_Error_OperationFailed_message"></mibp-resource-string></mibp-system-message>
<mibp-button *ngIf="isSendTrigger && showLogoutButton" resourceStringKey="Global_Logout" (click)="onSignOut()"></mibp-button>

<form *ngIf="!messageWasSent && !(isSendTrigger && showLogoutButton)" [formGroup]="contactForm" class="contact-us-form" (ngSubmit)="onSubmit()">

  <div class="forms__row">
    <label for="topic"><mibp-resource-string key="ContactUs_Topic"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
    <mibp-dropdown formControlName="topic" id="topic" name="topic" placeholder="ContactUs_SelectTopic_Placeholder" [loading]="!topics" [items]="topics"></mibp-dropdown>

    <mibp-validation-messages [ngForm]="contactForm" controlName="topic"
      [errors]="contactForm.controls['topic'].errors" [formSubmitted]="formValidated">
      <mibp-validation-message forError="required">
        <mibp-resource-string key="Global_FieldIsRequired"></mibp-resource-string>
      </mibp-validation-message>
    </mibp-validation-messages>
  </div>

  <div class="forms__row">
    <label for="name"><mibp-resource-string key="ContactUs_Name"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
    <input formControlName="name" name="name" id="name" type="text">

    <mibp-validation-messages [ngForm]="contactForm" controlName="name"
      [errors]="contactForm.controls['name'].errors" [formSubmitted]="formValidated">
      <mibp-validation-message forError="required">
        <mibp-resource-string key="Global_FieldIsRequired"></mibp-resource-string>
      </mibp-validation-message>
    </mibp-validation-messages>
  </div>

  <div class="forms__row">
    <label for="organization"><mibp-resource-string key="Global_Customer"></mibp-resource-string></label>
    <input formControlName="organization" name="organization" id="organization" type="text" class="form-control">
  </div>

  <div class="forms__row">
    <label for="country"><mibp-resource-string key="ContactUs_Country"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
    <mibp-dropdown formControlName="country" name="country" id="country"
      [data]="countries$"
      (filter)="onFilterCountries($event)"
      placeholder="ContactUs_SelectCountry_Placeholder" [selectedOption]="contactForm.controls['country'].value">
    </mibp-dropdown>

    <mibp-validation-messages [ngForm]="contactForm" controlName="country"
      [errors]="contactForm.controls['country'].errors" [formSubmitted]="formValidated">
      <mibp-validation-message forError="required">
        <mibp-resource-string key="Global_FieldIsRequired"></mibp-resource-string>
      </mibp-validation-message>
    </mibp-validation-messages>
  </div>



  <div class="forms__row" formGroupName="contactPreferenceGroup">
    <label><mibp-resource-string key="ContactUs_PreferedContactMethod"></mibp-resource-string></label>

    <div class="checkbox">
      <input formControlName="byEmail" id="byEmail" name="byEmail" type="checkbox">
      <label for="byEmail"><mibp-resource-string key="ContactUs_ByEmail"></mibp-resource-string></label>
    </div>
    <div class="checkbox">
      <input formControlName="byPhone" id="byPhone" name="byPhone" type="checkbox">
      <label for="byPhone"><mibp-resource-string key="ContactUs_ByPhone"></mibp-resource-string></label>
    </div>

    <mibp-validation-messages [ngForm]="contactForm" controlName="contactPreferenceGroup"
      [errors]="contactForm.controls['contactPreferenceGroup'].errors" [formSubmitted]="formValidated">
      <mibp-validation-message forError="requireCheckboxesToBeChecked">
        <mibp-resource-string key="Global_MinOptionsRequired_1"></mibp-resource-string>
      </mibp-validation-message>
    </mibp-validation-messages>
  </div>

  <div class="forms__row" *ngIf="contactForm['controls'].contactPreferenceGroup['controls'].byEmail.value">
    <label for="email"><mibp-resource-string key="ContactUs_Email"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
    <input formControlName="email" name="email" id="email" type="email" />

    <mibp-validation-messages [ngForm]="contactForm" controlName="email"
      [errors]="contactForm.controls['email'].errors" [formSubmitted]="formValidated">
      <mibp-validation-message forError="required">
        <mibp-resource-string key="Global_FieldIsRequired"></mibp-resource-string>
      </mibp-validation-message>
      <mibp-validation-message forError="email">
        <mibp-resource-string key="Global_InvalidEmail"></mibp-resource-string>
      </mibp-validation-message>
    </mibp-validation-messages>
  </div>

  <div class="forms__row" *ngIf="contactForm['controls'].contactPreferenceGroup['controls'].byPhone.value">
    <label for="phone"><mibp-resource-string key="ContactUs_Phone"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
    <input formControlName="phone" name="phone" id="phone" type="text">

    <mibp-validation-messages [ngForm]="contactForm" controlName="phone"
      [errors]="contactForm.controls['phone'].errors" [formSubmitted]="formValidated">
      <mibp-validation-message forError="required">
        <mibp-resource-string key="Global_FieldIsRequired"></mibp-resource-string>
      </mibp-validation-message>
    </mibp-validation-messages>
  </div>

  <div class="forms__row">
    <label for="message"><mibp-resource-string key="ContactUs_Message"></mibp-resource-string><span class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
    <textarea formControlName="message" name="message" id="message"></textarea>

    <mibp-validation-messages [ngForm]="contactForm" controlName="message"
      [errors]="contactForm.controls['message'].errors" [formSubmitted]="formValidated">
      <mibp-validation-message forError="required">
        <mibp-resource-string key="Global_FieldIsRequired"></mibp-resource-string>
      </mibp-validation-message>
      <mibp-validation-message forError="maxlength">
        <mibp-resource-string key="ContactUs_Message_MaxLength"></mibp-resource-string>
      </mibp-validation-message>
    </mibp-validation-messages>
  </div>
  <div class="forms__row" *ngIf="showSend">
    <mibp-button class="contact-us-submit-button" resourceStringKey="ContactForm_Send" #submitBtn></mibp-button>
    <span class="contact-us-submit-extended-info">
      <span *ngIf="formValidated && contactForm.invalid">
        <mibp-resource-string key="Global_InvalidForm"></mibp-resource-string>
      </span>
      <mibp-loader *ngIf="formSubmitted && contactForm.valid" type="spinner"></mibp-loader>
    </span>
  </div>
  <div class="forms__row" *ngIf="!showSend">
    <span><h5><mibp-resource-string key="ActAs_None"></mibp-resource-string></h5></span>
  </div>
</form>
