<mibp-system-message class="mt-5" #msg [messageType]="msg.Types.Error" *ngIf="hasLoadingError">
  <h2><mibp-resource-string key="General_Error_InlineTitle"></mibp-resource-string></h2>
</mibp-system-message>
<mibp-loader *ngIf="isLoading"></mibp-loader>
<div *ngIf="!hasLoadingError && !isLoading">
<mibp-horizontal-scroll-container>
  <table class="my-table my-table--compact my-table--hover" *ngIf="!isLoading">
    <thead>
      <tr>
        <th></th>
        <th><mibp-resource-string key="Global_Name"></mibp-resource-string></th>
        <th><mibp-resource-string key="MyFleet_Machine"></mibp-resource-string></th>
        <th><mibp-resource-string key="MyFleet_Alias"></mibp-resource-string></th>
        <th><mibp-resource-string key="Global_Model"></mibp-resource-string></th>
        <th><mibp-resource-string key="UserReg_Language"></mibp-resource-string></th>
        <th><mibp-resource-string key="Global_Type"></mibp-resource-string></th>
      </tr>
    </thead>
    <tbody>

      <ng-template ngFor let-row [ngForOf]="whereUsedItems" let-ix="index">
        <tr *ngIf="row.folders.length < 2">
          <td> <span translate="no" class="material-icon">newsstand</span></td>
           <td><a (click)="emitSelectedEvent()" [routerLink]="row.extendedFolders[0].navigationRoute" [queryParams]="row.extendedFolders[0].navigationQueryParams" queryParamsHandling="merge">{{row.name}}</a></td>
           <td>{{row.equipmentName}}</td>
           <td>{{row.equipmentAlias}}</td>
           <td>{{row.equipmentModel}}</td>
           <td>{{row.language}}</td>
          <td>{{row.type}}</td>
        </tr>


        <tr *ngIf="row.folders.length > 1" (click)="row.expanded = !row.expanded">
          <td style="width: 70px;">
            <span *ngIf="!row.expanded" translate="no" class="material-icon">keyboard_arrow_right</span>
            <span *ngIf="row.expanded" translate="no" class="material-icon">keyboard_arrow_down</span>
          </td>
           <td>{{row.name}}</td>
           <td>{{row.equipmentName}}</td>
           <td>{{row.equipmentAlias}}</td>
           <td>{{row.equipmentModel}}</td>
           <td>{{row.language}}</td>
          <td>{{row.type}}</td>
         </tr>

        <tr *ngIf="row.expanded" aria-expanded="true">
          <td colspan="7">
            <table class="my-table my-table--compact my-table--hover">
              <tbody>
                <tr *ngFor="let detailRow of row.extendedFolders">
                   <td><a (click)="emitSelectedEvent()" [routerLink]="detailRow.navigationRoute" [queryParams]="detailRow.navigationQueryParams" queryParamsHandling="merge">{{detailRow.chapterName}}</a></td>
                  <td>{{detailRow.folderName}} {{detailRow.assemblyNumber}} {{detailRow.revision}}</td>
              </tbody>
            </table>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <div class="hide-print" style="text-align: center;">
    <mibp-paginator (changePage)="onPageChange($event)" [totalItems]="total" [pageSize]="take"
      [startIndex]="skip">
    </mibp-paginator>
  </div>
</mibp-horizontal-scroll-container>
</div>
