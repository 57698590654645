import { CartsApiController } from 'root/mibp-openapi-gen/controllers';
import { ApiService, CartService, FrontendContextService, SignalR_CartUpdatedEvent, BroadcastService, MibpLogger, LogService } from 'root/services';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { firstValueFrom, Subscription } from "rxjs";
import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mibp-header-cart',
  templateUrl: './header-cart.component.html',
  animations: [
    trigger('addedAnimation', [
      state('enlarged', style({
        transform: 'scale(1.2)',
      })),
      state('normal', style({
        transform: 'scale(1)',
      })),
      transition('* => *', animate('300ms ease')),
    ]),
  ]
})
export class MibpHeaderCartComponent implements OnInit, OnDestroy {

  itemsInCart: string;
  isCartQuickviewOpen = false;
  addedAnimationState: string;
  deliverySequenceSubscription: Subscription;
  cartSubscription: Subscription;
  cartQuickviewOpenSubscription: Subscription;
  log: MibpLogger;
  

  constructor(private api: ApiService,private cartService: CartService,
    private cartsController: CartsApiController, private frontendContext: FrontendContextService,
    logger: LogService,
    private sessionService: MibpSessionService,
    private broadcast:BroadcastService,
    private router: Router) {
    this.log = logger.withPrefix('header-cart');
  }
  onAnimationDone($event: AnimationEvent): void {
    if ($event.phaseName === 'done' && this.addedAnimationState === 'enlarged') {
      setTimeout(() => {
        // Hold for a short moment before shrinking
        this.addedAnimationState = 'normal';
      }, 350);
    }
  }

  ngOnInit(): void {
    this.deliverySequenceSubscription = this.sessionService.activeDeliverySequence$.subscribe(() => {
      this.getActiveCartCount();
    });
    this.cartSubscription = this.api.EventHub.CartUpdated.subscribe(e => this.onCartUpdate(e));

    this.broadcast.cartEvent.subscribe(event => {

      if(event && event.refreshActiveCartCount){
        this.getActiveCartCount();
      }
      else if(event && event.count){
        this.itemsInCart = `${event.count}`;
        if (event.productsAdded?.length > 0) {
          setTimeout(() => {
            this.addedAnimationState = 'enlarged';
          });
        }
      }
      else if (event && (!event.updatedItems || event.updatedItems.length === 0)){
        this.itemsInCart = ``;
      }
    });

    this.broadcast.cartQuickviewOpen.subscribe(isOpen => { this.isCartQuickviewOpen = isOpen; });
  }

  private onCartUpdate(cartEvent: SignalR_CartUpdatedEvent): void {
    if(cartEvent){

      // We must react to signalr events as well
      // This is only triggered when adding to cart from Documoto or My Rocktools POC api
      if (cartEvent.addProductsResponse?.productsAdded?.length > 0) {
        this.itemsInCart = `${cartEvent.count}`;
        setTimeout(() => {
          this.addedAnimationState = 'enlarged';
        });
      }

      this.cartService.handleAddToCart(this.cartService.mapToCartAddProductsResponseViewModel(cartEvent.addProductsResponse));
    }
  }

  getActiveCartCount(){
    firstValueFrom(this.cartsController.getActiveCartCount()).then(count => {
      this.broadcast.setCartEvent({count:count});
      if(count){
        this.itemsInCart = `${count}`;
      }
      else{
        this.itemsInCart = ``;
      }
    },err=>{
      this.log.error("Could not get active cart count", err);
    });
  }

  onHover() {
    if(this.router.url.toLocaleLowerCase().indexOf('/shop/cart') == -1){
      this.isCartQuickviewOpen = !this.isCartQuickviewOpen;
      this.broadcast.setCartQuickviewOpen(this.isCartQuickviewOpen);
    }
  }

  ngOnDestroy(): void {    
    this.deliverySequenceSubscription?.unsubscribe();
    this.cartSubscription?.unsubscribe();
    this.cartQuickviewOpenSubscription?.unsubscribe();
  }

}
