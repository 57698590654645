import { Subject, Observable } from 'rxjs';
import { DropdownInput } from '../dropdown';

export class MibpFilterBase {

  public hidden = false;

  constructor(private type: MibpFilterType, private filterName: string, public resourceStringKey: string) {

  }



  public get filterType(): MibpFilterType {
    return this.type;
  }

  public get name(): string {
    return this.filterName;
  }

  public get value$(): Observable<ValueUpdateFromClassEvent> {
    return this.valueSubject.asObservable();
  }

  private valueSubject = new Subject<ValueUpdateFromClassEvent>();

  /**
   * Let the filter component know that a value was set from code
   */
  protected triggerValueUpdate(value: ValueUpdateFromClassEvent): void {
    this.valueSubject.next(value);
  }

}

export interface MibpFilterInternalMethods {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $_internalComponentSetValue(value: any): void;
}

export class MibpTextFilter extends MibpFilterBase implements MibpFilterInternalMethods {
  constructor(name: string, resourceStringkey: string, private filterValue: string) {
    super('text', name, resourceStringkey);
  }

  public get value(): string {
    return this.filterValue;
  }

  public set value(value: string) {
    this.setValue(value);
  }

  setValue(val: string): void {
    this.triggerValueUpdate({
      filterName: this.name,
      value: val
    });
  }

  /**
   * Internal.
   * Write the current value from the component. Will not trigger changes.
   */
  $_internalComponentSetValue(value: string): void {
    this.filterValue = value;
  }

}



export class MibpDateFilter extends MibpFilterBase implements MibpFilterInternalMethods {
  constructor(name: string, resourceStringkey: string, private filterValue: Date, public asEndOfDay: boolean) {
    super('date', name, resourceStringkey);
  }
  setValue(val: Date): void {
    this.triggerValueUpdate({
      filterName: this.name,
      value: val
    });
  }

  public get value(): Date {
    return this.filterValue;
  }

  public set value(value: Date) {
    this.setValue(value);
  }

  /**
 * Set the value from the component. Will not trigger changes.
 */
  $_internalComponentSetValue(value: Date): void {
    this.filterValue = value;
  }
}

export class MibpDropdownFilter extends MibpFilterBase implements MibpFilterInternalMethods {

  public isExpanded = false;
  private pendingUpdate: DropdownInput[];

  constructor(
    name: string,
    resourceStringkey: string,
    private filterValue: DropdownInput[],
    public showMoreAfter: number = null,
    public initialSelectedValues: DropdownInput[],
    public scrollableOverflowHeight: number = 0 // Currently only implemented for Checkbox filters
    ) {
    super('dropdown', name, resourceStringkey);
  }
  public get items(): DropdownInput[] {
    return this.filterValue;
  }

  public set items(items: DropdownInput[]) {
    this.setItems(items);
  }
  $_internalComponentSetValue(value: DropdownInput[]): void {
    this.filterValue = value;
  }
  setItems(val: DropdownInput[]): void {
    this.triggerValueUpdate({
      filterName: this.name,
      value: val
    });
  }
  $_setPendingDropdownListUpdate(items: DropdownInput[]): void {
    this.pendingUpdate = items;
  }

  $_getPendingDropdownListUpdate(): DropdownInput[] {
    return this.pendingUpdate;
  }
}

export type MibpFilterChangedArgs = {
  hasChanged: boolean;
  value: MibpFilterValues;
};

export type MibpFilterChangedReason = 'load' | 'user' | 'code';
export type MibpFilterType = 'checkboxlist' | 'date' | 'text' | 'dropdown';
export type MibpFilterValues = { [name: string]: MibpFilterAllowedValueTypes};
export type MibpFilterAllowedValueTypes = string | Date | DropdownInput[];
export type ValueUpdateFromClassEvent = { filterName: string, value: MibpFilterAllowedValueTypes };
