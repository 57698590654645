<div class="my-cart-options" *ngIf="cart" >

  <h3 class="my-header"><mibp-resource-string key="Cart_Options"></mibp-resource-string></h3>

  <div class="my-cart-options__buttons">

    <div class="my-cart-options--button my-cart-options__templates" *ngIf="hasTemplatesPermission">
      <mibp-button automatedTestId="button-save-as-template" data-gtm="cart_save-as-template" displayBlock="true" resourceStringKey="Carts_SaveAsTemplate" (click)="saveAsTemplate()" #btn [color]="btn.Enum.Colors.Secondary"></mibp-button>
    </div>

    <div class="my-cart-options--button my-cart-options__save" *ngIf="hasCreateCartPermission">
      <mibp-button automatedTestId="button-save-for-later" data-gtm="cart_save-for-later"   displayBlock="true" resourceStringKey="Carts_SaveForLater"  (click)="saveForLater()" #btn [color]="btn.Enum.Colors.Secondary"></mibp-button>
    </div>

    <div automatedTestId="button-export-cart" class="my-cart-options--button my-cart-options__templates" *ngIf="hasExportCartPermission">
      <a href='{{exportUrl}}' (click)="exportClick($event)"><mibp-button [disabled]="disableExportCart" data-gtm="cart_export-cart" displayBlock="true"  #btn resourceStringKey="Carts_Export" [color]="btn.Enum.Colors.Secondary"></mibp-button></a>
    </div>
  </div>
</div>




