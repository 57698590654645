
import { allPermissionPolicies } from "root/all-permission-policies";
import { MySandvikNavigationItem } from "../navigation-types";


export const NAVITEMS_SUPPORTCASES: MySandvikNavigationItem = {
  permissionPolicy: allPermissionPolicies.canSeeSupportCases,
  resourceKey: 'SupportCases_Title',
  uniqueId: 'support-cases',
  sandvikIconName: 'newsstand',
  route: {path: ['support-cases']},
  children: [
    {
      hidden: true,
      resourceKey: 'Promotion_Details',
      uniqueId: 'support-cases/details',

    },
  ]


};


