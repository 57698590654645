import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService, ClientIdService, ClientSideCacheService, DialogService, LoaderService, LocalizationService, LogService, MibpLogger, PermissionService, SignalRService, UrlHelperService, neverEndingPromise } from "root/services";
import { filter, firstValueFrom, forkJoin, take } from "rxjs";
import { DialogComponent } from "../dialog/dialog.component";
import { MibpSessionService } from "root/services/mibp-session/mibp-session.service";
import { Router } from "@angular/router";
import { MibpGetUserSessionResponseViewModel, MibpSessionViewModel, UserStatus, UserType } from "root/mibp-openapi-gen/models";
import { ApplicationStateService } from "root/services/application-state/application-state.service";
import { DocumotoAuthService } from "root/services/auth-service/documoto-auth.service";
import { SessionApiController, UsersApiController } from "root/mibp-openapi-gen/controllers";
import { DialogButton } from "../dialog/dialog.types";
import { ButtonColors } from "../button/button.enum";
import { Guid } from "guid-typescript";
import { ContactUsFormData, ContactUsTopic } from "../contact-dialog/contact-us-form/contact-us-form.types";
import { allPermissionPolicies } from "root/all-permission-policies";
import { RejectedUserPageComponent } from "root/modules/user/pages/rejected/rejected-user-page.component";

/**
 * This component wraps the main router outlet
 * It will hide the main router outlet until showApplication == true
 */
@Component({
  selector: 'mibp-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss']
})
export class MibpAppLoaderComponent implements OnInit {

  /**
   * Set to true to show the main router outlet and let the application load
   */
  protected showApplication = false;

  /**
   * Hide/Show the main application loader
   * This will look like the loader in index.html and use the same CSS to make the loading experience "non jumpy"
   */
  protected showLoader = false;

  protected resourcesAreLoaded = false;

  protected translations = {
    'general-error-title': { value: null, resourceKey: 'General_Error_InlineTitle', fallbackText: 'Whoops! Something has gone wrong' },
    'load-session': { value: null, resourceKey: 'x', fallbackText: 'Could not load your account information' },
    'load-resources': { value: null, resourceKey: 'x', fallbackText: 'Could not load translations' },
    'what-to-do-now': { value: null, resourceKey: 'Global_Error_WhatToDoNow', fallbackText: 'Try the following to see if the problem resolves itself' },
    'reload-page': { value: null, resourceKey: 'Global_Error_ReloadSuggestion', fallbackText: 'Reload the page to try again' },
    'try-later': { value: null, resourceKey: 'Global_Error_TryLaterSuggestion', fallbackText: 'Wait and try later' },
    'login-again': { value: null, resourceKey: 'AppLoad_ClearCacheAndReload', fallbackText: '[Clear cache] and reload' },
    'contact-support': { value: null, resourceKey: 'Global_Error_ContactSupport', fallbackText: 'Contact support if the problem remains' }
  };


  /**
   * The name of the portal
   */
  protected portalNameText = 'My Sandvik Customer Portal';
  protected showActAsPrompt = false;
  protected name: string;
  protected clientId: string;
  protected error?: 'load-session' | 'load-resources';
  protected clearCacheAndreloadHtml: string;

  protected log: MibpLogger;
  dialogButtons: DialogButton[] = [
    { resourceKey: 'Global_Save', id: 'save', disabled: true, color: ButtonColors.Primary },
    { resourceKey: 'Global_ContactUs', id: 'contact_us', color: ButtonColors.Primary }
  ];
  concurrenSessionDialogButtons: DialogButton[] = [
    { resourceKey: 'Users_ConcurrentSession_LogOutButton', id: 'logout', color: ButtonColors.Red},
    { resourceKey: 'Users_ConcurrentSession_StayLoggedInButton', id: 'stayloggedin', color: ButtonColors.Primary}
  ];
  @ViewChild('actAsDialog') actAsDialog: DialogComponent;
  @ViewChild('contactDialog') contactUsDialog: DialogComponent;
  @ViewChild('concurrenSessionDialog') concurrenSessionDialog: DialogComponent;
  private resolveOpenPromise;
  private resolveConcurrentSessionPromise;
  private currentOrganizationId: Guid;
  private deliverySequenceId: number;
  public contactUsFormData: ContactUsFormData;
  public showContactUs = false;
  public message: string;
  public canActAs = false;

  constructor(private localization: LocalizationService,
    private stateService: ApplicationStateService,
    private auth: AuthService,
    private urlHelper: UrlHelperService,
    private router: Router,
    private cache: ClientSideCacheService,
    private documotoAuth: DocumotoAuthService,
    private clientIdService: ClientIdService,
    private signalrService: SignalRService,
    public session: MibpSessionService,
    public loaderService: LoaderService,
    logger: LogService,
    private usersApiRest: UsersApiController,
    private permissionService: PermissionService,
    private dialogService: DialogService) {
    this.log = logger.withPrefix(`app-loader`);

  }



  /**
   * When an error has occured and user wants to try to clear cache.
   */
  clearCacheClick(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    if ((e.target as HTMLElement).nodeName === 'A') {
      this.stateService.clearAllCacheAndReload();
    }
  }

  /**
   * Method to prepare texts
   */
  private tryGetTranslatedTexts(): void {
    this.translations = this.stateService.getTranslationsForApplicationLoad();

  }

  async ngOnInit(): Promise<void> {
    this.clientId = this.clientIdService.getClientId();

    // Language redirect
    const lang = this.localization.verifyLanguageFromUrl(window.location.href);
    if (typeof lang === 'string') {
      window.location.href = lang;
      return;
    }
    this.showLoader = true;

    this.tryGetTranslatedTexts();

    // Start by loading session data so we don't get duplicate connections!
    await this.loadSessionData();

    const localizationObservable = this.localization.loadOnApplicationStart().pipe(filter(f => f != null), take(1));

    localizationObservable.subscribe({
      next: () => {
        this.resourcesAreLoaded = true;
        this.tryGetTranslatedTexts();
      },
      error: e => {
        this.error = 'load-resources';
      }
    });

    forkJoin([localizationObservable]).subscribe(
      {
        next: async () => {
          this.tryGetTranslatedTexts();
          this.resourcesAreLoaded = true;

          // Hide the loader
          this.showLoader = false;

          // Check user status and redirect accordingly
          await this.redirectNonActiveUsers();

          await this.detectAccountInconsistencies();

          await this.displayContactUsForInactiveDeliverySequence();

          // Check if we need to redirect user to profile picker
          await this.checkPunchoutProfile();

          await this.actAsCorrectPunchoutCxmlDeliverySequence();

          this.showApplication = true;
          this.connectToSignalr();
          this.documotoAuth.ensureSignedout();
        },
        error: (error) => {
          this.tryGetTranslatedTexts();
          this.log.error(`AppLoad error`, error);
        }
      });
    }

  /**
   * When login for cxml users we receive an encoded 'ds' querystring parameter
   * We will here attempt to act as that encoded delivery sequence.
   * If it fails, the user will see a message and an option to sign out
   */
  private async actAsCorrectPunchoutCxmlDeliverySequence(): Promise<void> {
    const encodeddeliverySequence = this.urlHelper.getQuerystringValue('ds');
    if (encodeddeliverySequence && this.session.current?.user?.isPunchoutUser) {
      try {
        await this.session.actAsPunchoutEncodedDeliverySequence(encodeddeliverySequence);
      } catch (e) {

        // Could not act as the delivery sequence in the Address configuration
        // We must not let the user continue.

        // Show a message with a signout button
        this.dialogService.promptWithHtml('Punchout_CxmlActAsError', 'Global_Logout').finally(() => {
          this.auth.signout();
        });

        // But we do not wait for user to click sign out. We clear what we can at once.
        // Otherwise user will be logged if they manually reload the page
        this.cache.clearEverything();
        this.auth.clearAll();
        this.clientIdService.newClientId();

        // Never resolve this promise - we should should never continue to load the application
        await neverEndingPromise();
      }
    }
  }

  private connectToSignalr(): void {
    if (this.session.isLoggedIn()) {
      setTimeout(() => {
        this.signalrService.connect();
      }, 1500);
    }
  }

  private async redirectNonActiveUsers(): Promise<void> {
    if (this.session?.isRegistrationRequired() && this.router.url.indexOf('/user/newuserregistration') == -1) {
      this.router.navigate([this.localization.getLang(), 'user', 'newuserregistration']);
    } else if (this.session?.current?.user?.status == UserStatus.New && this.router.url.indexOf('/user/new') == -1) {
      this.router.navigate([this.localization.getLang(), 'user', 'new']);
    }else if(this.session?.hasUser() && this.session?.isUserDeactivated()){
      this.router.navigate([this.localization.getLang(), 'user', 'deactivated']);
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  private async checkPunchoutProfile(): Promise<void> {

    if (this.session.userMustPickPunchoutProfile() && !this.router.url.includes('profilepicker')) {
      this.router.navigate([this.localization.getLang(), 'user', 'profilepicker']);
    }

  }

  /**
   * If we have a B2C user - then we try to load session data
   */
  private async loadSessionData(): Promise<MibpSessionViewModel> {

    const b2cUser = this.auth.getB2CUser();

    if (b2cUser) {
      this.log.info(`B2CUser:`, `${b2cUser.email} (${b2cUser.type}). IdentityObjectId ${b2cUser.identityObjectId}. Nonce (ClientId) ${b2cUser.nonce}. Token expires ${b2cUser?.exp}`);
      this.cache.setUserKey(b2cUser.identityObjectId);
      try {
        const sessionData = await this.session.loadAtApplicationStart(b2cUser);
        this.log.info(`SessionData`, sessionData);
        if(this.session.current?.user?.isPunchoutUser == false){
          const self = this;
          await this.detectConcurrentSessions().then((isConcurrentSession) => {
            if(isConcurrentSession){
              return new Promise((resolve) => {
                setTimeout(() => {
                  self.showLoader = false;
                  self.resolveConcurrentSessionPromise = resolve;
                  this.concurrenSessionDialog.open();
                }, 1000);
              });
            }
          });
        }

        return sessionData.sessionData;
      } catch (e) {
        this.log.error("FAIL LOADING USER DATA", e);
        this.error = 'load-session';
        throw e;
      }
    } else {
      this.log.debug(`B2CUser:`, null);
    }

    return null;
  }

  private detectConcurrentSessions(): Promise<boolean> {
    return this.auth.detectConcurrentUserSessions();
  }

  private async detectAccountInconsistencies(): Promise<void> {

    // Detect if user is active without organization?! Should be rare, but at least we detect it
    if (this.session.current?.user?.status == UserStatus.Active && !this.session.current?.user?.accessGroupId) {
      await this.dialogService.promptWithText("Data inconsistency discovered - user is Active but have no Organization. Please contact support.", {id: 'logout', resourceKey: 'Global_Logout'});
      this.auth.signout();
      return neverEndingPromise();
    }
  }

  private async displayContactUsForInactiveDeliverySequence() {


    if (this.session.current?.user?.type == UserType.External && this.session.current?.user?.status == UserStatus.Active
      && !this.session?.current?.user?.isPunchoutUser) {//skip check for punchout user
      this.localization.getResourceString("Login_InactiveDeliverySequence_Message").then(r => {
        const deliverySequence = this.session.current?.user?.defaultDeliverySequence;
        let replaceString = "";
        if (deliverySequence) {
          replaceString = "\"" + deliverySequence.companyCode + "/" + deliverySequence.erpCustomerId + "/" + deliverySequence.deliverySequenceNumber + "\"";
        }
        this.message = r.replace('{deliverySequence}', replaceString);
      });

      this.canActAs = this.permissionService.test(allPermissionPolicies.canActAs);
      if (!this.canActAs) {
        this.dialogButtons = this.dialogButtons.filter((b) => { return b.resourceKey == 'Global_ContactUs'; });
      }
      const hasActiveDeliverySeq = await this.ensureActiveDeliverySequence();

      if (!hasActiveDeliverySeq) {
        const self = this;
        await new Promise((resolve) => {
          self.resolveOpenPromise = resolve;
          this.contactUsFormData =
          {
            topic: ContactUsTopic.TechnicalSupport,
            message: this.message,
            organization: this.session?.current?.user?.accessGroupName
          };
          this.showContactUs = true;
          this.contactUsDialog.open();
        });
      }
    }
    else {
      window.localStorage.removeItem('mibp.actasdialog');
    }
  }

  private async ensureActiveDeliverySequence(): Promise<boolean> {
    const self = this;

    this.currentOrganizationId = Guid.parse(this.session?.current?.user?.accessGroupId);
    return new Promise((resolve) => {
      self.resolveOpenPromise = resolve;
      if (window.localStorage.getItem('mibp.actasdialog')) {
        if (window.localStorage.getItem('mibp.actasdialog') == 'false') {
          this.showActAsPrompt = false;
          resolve(true);
        } else {
          this.showActAsPrompt = true;
        }

        if (this.showActAsPrompt && this.session.current?.user) {
          this.name = this.session.current?.user?.firstName;
          this.actAsDialog.open();
        }
      }
      else {
        firstValueFrom(this.usersApiRest.ensureActiveDeliverySequence()).then((userDelSeq) => {
          if (userDelSeq.isActiveDefaultDeliverySequence) {
            window.localStorage.setItem('mibp.actasdialog', 'false');
            this.showActAsPrompt = false;
            resolve(true);
          }
          else {
            window.localStorage.setItem('mibp.actasdialog', 'true');
            this.showActAsPrompt = true;
          }

          if (this.showActAsPrompt && this.session.current?.user) {
            this.name = this.session.current?.user?.firstName;
            this.actAsDialog.open();
          }
        }, (ex) => {
          this.log.error(ex.error.error);
          this.dialogService.prompt(`UserNotLoggedInInfo`, 'Global_Dialog_Close').then(button => {
            this.auth.signout();
          });
        });
      }
    });
  }

  async dialogClick(btn: DialogButton): Promise<void> {
    if (btn.id === 'save') {
      await firstValueFrom(this.usersApiRest.updateDefaultDeliverySequence({ deliverySequenceId: this.deliverySequenceId })).then(() => {
        this.dialogClosed(true);
        this.session.actAs(this.deliverySequenceId);
        window.localStorage.setItem('mibp.actasdialog', 'false');
      }).catch(error => {
        this.log.error('Error when updating user default delivery sequence', error);
      });
    }
    else if (btn.id === 'contact_us') {
      this.dialogClosed(false);
    }
  }

  async concurrenSessionDialogClick(btn: DialogButton): Promise<void> {
    if(btn.id == "logout"){
      this.auth.signoutAllSignIns();
    }
    else{
      this.concurrenSessionDialog.close();
      this.showLoader = true;
      this.log.info(`User continues with concurrent user session login`, this.session?.current?.user?.userId);
      return this.resolveConcurrentSessionPromise(true);
    }


  }

  async dialogClosed(isResolved: boolean): Promise<void> {
    this.actAsDialog.close();
    return this.resolveOpenPromise(isResolved);
  }

  onDeliverySequenceChanged(value) {
    this.dialogButtons[0].disabled = true;
    if (!value || value.active == false) {
      return;
    }
    this.deliverySequenceId = value.id;
    this.dialogButtons[0].disabled = false;
  }

  onContactUsFormClosed() {
    this.resolveOpenPromise();
  }
}
