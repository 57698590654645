import { map } from 'rxjs/operators';
import { SignalR_ApiResponseGeneric } from './../mibp-api-services';
import { MibpHttpApi } from './mibp-http-api.service';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
/**
 * Provides an API And for fetching large kits that are too big for SignalR
 */
export class OrderHttpService {
  constructor(private httpApi: MibpHttpApi) { }
  // public GetOrder(orderId: Guid): Observable<SignalR_Order> {
  //   return this.httpApi.get<SignalR_ApiResponseGeneric<SignalR_Order>>(`orders/${orderId}`)
  //     .pipe(map(o => {
  //       return o.data;
  //     }));
  // }
  // public GetListDespatches(listDespatchId: Guid): Observable<SignalR_Despatch[]> {
  //   return this.httpApi.get<SignalR_ApiResponseGeneric<SignalR_Despatch[]>>(`orders/Despatches/${listDespatchId}`)
  //     .pipe(map(o => {
  //       return o.data;
  //     }));
  // }
}
