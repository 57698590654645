/* tslint:disable */
/* eslint-disable */
export enum MibpGridColumnDataType {
  String = 0,
  Facet = 1,
  Boolean = 2,
  Number = 3,
  DateTime = 4,
  CheckboxFacet = 5,
  StringList = 6
}
