/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { DefaultApiController } from './services/default-api-controller';
import { OperationSitesApiController } from './services/operation-sites-api-controller';
import { AccessGroupsApiController } from './services/access-groups-api-controller';
import { ApplicationApiController } from './services/application-api-controller';
import { B2CUsersApiController } from './services/b-2-c-users-api-controller';
import { BiDashboardApiController } from './services/bi-dashboard-api-controller';
import { BillOfMaterialApiController } from './services/bill-of-material-api-controller';
import { BulletinApiController } from './services/bulletin-api-controller';
import { BusinessRelationsApiController } from './services/business-relations-api-controller';
import { CartsApiController } from './services/carts-api-controller';
import { ChangeLogsApiController } from './services/change-logs-api-controller';
import { ChangeTrackingApiController } from './services/change-tracking-api-controller';
import { CompaniesApiController } from './services/companies-api-controller';
import { ContactApiController } from './services/contact-api-controller';
import { CountriesApiController } from './services/countries-api-controller';
import { CrossSellsApiController } from './services/cross-sells-api-controller';
import { DeliverySequenceApiController } from './services/delivery-sequence-api-controller';
import { DeliverySequencePickerApiController } from './services/delivery-sequence-picker-api-controller';
import { DownloadApiController } from './services/download-api-controller';
import { EquipmentCalendarsApiController } from './services/equipment-calendars-api-controller';
import { EquipmentsApiController } from './services/equipments-api-controller';
import { FeatureApiController } from './services/feature-api-controller';
import { FleetDocumentsApiController } from './services/fleet-documents-api-controller';
import { HelpApiController } from './services/help-api-controller';
import { ImageApiController } from './services/image-api-controller';
import { InternalReportsApiController } from './services/internal-reports-api-controller';
import { KitApiController } from './services/kit-api-controller';
import { MapsApiController } from './services/maps-api-controller';
import { MediaApiController } from './services/media-api-controller';
import { MessageLogsApiController } from './services/message-logs-api-controller';
import { MibpLogApiController } from './services/mibp-log-api-controller';
import { MibpPaymentApiController } from './services/mibp-payment-api-controller';
import { NewsArticleApiController } from './services/news-article-api-controller';
import { NotificationApiController } from './services/notification-api-controller';
import { OperationsApiController } from './services/operations-api-controller';
import { OrdersApiController } from './services/orders-api-controller';
import { PermissionsApiController } from './services/permissions-api-controller';
import { PriceAndAvailabilityApiController } from './services/price-and-availability-api-controller';
import { ProductEnrichmentsApiController } from './services/product-enrichments-api-controller';
import { ProductNotificationsApiController } from './services/product-notifications-api-controller';
import { ProductsApiController } from './services/products-api-controller';
import { ProductUpgradesApiController } from './services/product-upgrades-api-controller';
import { PromotionsApiController } from './services/promotions-api-controller';
import { PunchoutApiController } from './services/punchout-api-controller';
import { RemoteSupportsApiController } from './services/remote-supports-api-controller';
import { ReportsApiController } from './services/reports-api-controller';
import { RescopeApiController } from './services/rescope-api-controller';
import { ResourceApiController } from './services/resource-api-controller';
import { RestrictedItemsApiController } from './services/restricted-items-api-controller';
import { RolesApiController } from './services/roles-api-controller';
import { SearchApiController } from './services/search-api-controller';
import { SessionApiController } from './services/session-api-controller';
import { SupportCaseApiController } from './services/support-case-api-controller';
import { SystemMessagesApiController } from './services/system-messages-api-controller';
import { TdmHostApiController } from './services/tdm-host-api-controller';
import { TemplatesApiController } from './services/templates-api-controller';
import { TermsAndConditionsApiController } from './services/terms-and-conditions-api-controller';
import { UpSellsApiController } from './services/up-sells-api-controller';
import { UserFileApiController } from './services/user-file-api-controller';
import { UsersApiController } from './services/users-api-controller';
import { WarrantyApiController } from './services/warranty-api-controller';
import { WarrantyApplicationApiController } from './services/warranty-application-api-controller';
import { WarrantyRegistrationApiController } from './services/warranty-registration-api-controller';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DefaultApiController,
    OperationSitesApiController,
    AccessGroupsApiController,
    ApplicationApiController,
    B2CUsersApiController,
    BiDashboardApiController,
    BillOfMaterialApiController,
    BulletinApiController,
    BusinessRelationsApiController,
    CartsApiController,
    ChangeLogsApiController,
    ChangeTrackingApiController,
    CompaniesApiController,
    ContactApiController,
    CountriesApiController,
    CrossSellsApiController,
    DeliverySequenceApiController,
    DeliverySequencePickerApiController,
    DownloadApiController,
    EquipmentCalendarsApiController,
    EquipmentsApiController,
    FeatureApiController,
    FleetDocumentsApiController,
    HelpApiController,
    ImageApiController,
    InternalReportsApiController,
    KitApiController,
    MapsApiController,
    MediaApiController,
    MessageLogsApiController,
    MibpLogApiController,
    MibpPaymentApiController,
    NewsArticleApiController,
    NotificationApiController,
    OperationsApiController,
    OrdersApiController,
    PermissionsApiController,
    PriceAndAvailabilityApiController,
    ProductEnrichmentsApiController,
    ProductNotificationsApiController,
    ProductsApiController,
    ProductUpgradesApiController,
    PromotionsApiController,
    PunchoutApiController,
    RemoteSupportsApiController,
    ReportsApiController,
    RescopeApiController,
    ResourceApiController,
    RestrictedItemsApiController,
    RolesApiController,
    SearchApiController,
    SessionApiController,
    SupportCaseApiController,
    SystemMessagesApiController,
    TdmHostApiController,
    TemplatesApiController,
    TermsAndConditionsApiController,
    UpSellsApiController,
    UserFileApiController,
    UsersApiController,
    WarrantyApiController,
    WarrantyApplicationApiController,
    WarrantyRegistrationApiController,
    ApiConfiguration
  ],
})
export class MibpRestApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<MibpRestApiModule> {
    return {
      ngModule: MibpRestApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: MibpRestApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('MibpRestApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
