import { Component, Input, Output ,EventEmitter} from "@angular/core";
import { NewContactUsTopic, SupportCaseFormData } from "./new-contact-us-form.types";


@Component({
  selector: 'mibp-new-contact-us-form',
  templateUrl: './new-contact-us-form.component.html'
})

export class NewContactUsFormComponent {
  protected newContactUsTopic = NewContactUsTopic;
  @Input() selectedTopic : string;
  @Input() formData: SupportCaseFormData = null;
  @Input() showHeader = true;
  @Output() successfullySubmitted = new EventEmitter<boolean>();

  formSuccessfullySubmitted($event: boolean){
    this.successfullySubmitted.emit($event);
  }
}
