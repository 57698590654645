import { CountriesApiController } from './../../../mibp-openapi-gen/services/countries-api-controller';
import { ApiService, FrontendContextService, LocalizationService, LogService, MibpLogger, ContactUsService, NoticebarService, SignalR_User, SignalR_Country, FormValidationService, AuthService } from 'root/services';
import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subscription, Observable, firstValueFrom } from 'rxjs';
import { DropdownInput, DropdownData, DropdownArgs } from 'root/components';
import { map } from 'rxjs/operators';
import { ContactUsFormData, ContactUsTopic } from './contact-us-form.types';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { ContactApiController, DeliverySequenceApiController } from 'root/mibp-openapi-gen/controllers';
import {ContactInfo, MibpSessionUserViewModel } from 'root/mibp-openapi-gen/models';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

@Component({
  selector: 'mibp-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})
export class ContactUsFormComponent implements OnInit, OnDestroy {

  private submitFormSubscription: Subscription;
  private readonly salesforceRemoteSupportEnquiryTopic = 'Remote Support Enquiry';
  public formValidated: boolean;
  public formSubmitted: boolean;
  @Input() formData: ContactUsFormData;
  @Input() helpPageForm = false;
  @Input() showLogoutButton: boolean;
  private stopUsingResources: () => void;
  selectedTopic: DropdownInput;
  contactForm: UntypedFormGroup;
  showSend: boolean;
  topics: DropdownInput[] = [];
  topicResourceStringKeys: string[] = [];
  hiddenTopicResourceStringKeys: string[] = [ContactUsTopic.RequestPrice];

  allCountries: SignalR_Country[];
  countries$: Observable<DropdownData>;
  messageWasSent = false;
  isSendTrigger = false;

  private phoneSubscription: Subscription;
  private emailSubscription: Subscription;

  // phonePattern = "^[/+-/(/)/#\s\./0-9 ]*$";

  log: MibpLogger;
  constructor(
    private fb: UntypedFormBuilder,
    private sessionService: MibpSessionService,
    private context: FrontendContextService,
    private localizationService: LocalizationService,
    private deliverySequenceApi: DeliverySequenceApiController,
    private logger: LogService,
    private contactUsService: ContactUsService,
    private noticebarService: NoticebarService,
    private contactApiController: ContactApiController,
    private countryController: CountriesApiController,
    private validation: FormValidationService,
    private element: ElementRef,
    private auth: AuthService
  ) {
    this.log = this.logger.withPrefix('ContactUsForm');
  }

  ngOnInit() {
    this.contactForm = this.fb.group({
      topic: [null, Validators.required],
      name: ['', Validators.required],
      organization: [''],
      country: [null, Validators.required],
      contactPreferenceGroup: new UntypedFormGroup({
        byPhone: new UntypedFormControl(false),
        byEmail: new UntypedFormControl(false),
      }),
      byPhone: [false],
      byEmail: [false],
      email: [''],
      phone: [''],
      message: ['', [Validators.required, Validators.maxLength(32000)]],
    });

    // If topic is set in @Input() formData, remove it from hidden topics
    if (this.formData && this.formData.topic) {
      this.hiddenTopicResourceStringKeys = this.hiddenTopicResourceStringKeys.filter(k => k !== this.formData.topic);
      this.formData.topic = null;
    }

    // Get resource string keys from enum values, add to topicResourceStringKeys if not in hiddenTopicResourceStringKeys
    // tslint:disable-next-line:forin
    for (const n in ContactUsTopic) {
      if (!this.hiddenTopicResourceStringKeys.includes(ContactUsTopic[n])) {
        this.topicResourceStringKeys.push(ContactUsTopic[n]);
      }
    }

    this.setTopicResourceStringSubscription(this.topicResourceStringKeys, resourceStrings => {
      const topics = [];
      resourceStrings.forEach((rs, index) => {
        topics.push(<DropdownInput>{ value: rs, text: rs });
      });
      this.topics = topics;
      // Make sure topics are set before setting form values
      this.setFormValues();
      this.setContactSubscriptions();
    });
    this.setFormValues();
    this.setContactSubscriptions();
  }

  async setFormValues(): Promise<void> {
    if (this.context && this.sessionService.current !== null) {

      this.formData = this.formData || {};

      const user = this.sessionService.current.user;

      if (user !== null) {
        this.formData.name = this.userFullName(user);
        this.formData.email = user.email;
        if (user.phone) {
          this.formData.phone = user.phone;
        } else {
          this.formData.phone = user.cell;
        }
      }
      const deliverySequence = this.sessionService.activeDeliverySequence;


      this.showSend = false;

      if (deliverySequence !== null) {
        this.showSend = true;
        this.formData.organization = deliverySequence.customerName || null;

        try {
          const countries = await firstValueFrom(this.deliverySequenceApi.getCountriesForActiveCompany());
          if (countries.length > 0) {
            const country = countries[0];
            this.formData.country = <DropdownInput>{ text: country.name, value: country.code };
          }
        } catch (e) {
          // Ignore
          this.log.error(`Error loading company countries`);
        }

      }
    }

    this.contactForm.patchValue({
      topic: this.formData.topic ? this.getSelectedTopicDropdownInput(this.formData.topic) : null,
      name: this.formData.name,
      organization: this.formData.organization,
      country: this.formData.country,
      email: this.formData.email,
      phone: this.formData.phone,
      message: this.formData.message,
    });

    // Disable name input if prefilled
    if (this.contactForm.controls['name'].value && this.contactForm.controls['name'].value !== '') {
      this.contactForm.controls['name'].disable();
    }

    // Disable organization input if prefilled
    if (this.formData.organization && this.formData.organization !== '') {
      this.contactForm.controls['organization'].disable();
    }

    this.contactForm.markAsPristine();
  }

  /***
   * Returns full name from user object
   * @param user User object
   */
  userFullName(user: MibpSessionUserViewModel): string {
    let fullName = '';
    const names: string[] = [];

    if (user.firstName && user.firstName !== '') {
      names.push(user.firstName);
    }
    if (user.lastName && user.lastName !== '') {
      names.push(user.lastName);
    }

    names.forEach((name) => {
      fullName += name + ' ';
    });

    return fullName.trim();
  }

  /***
   * Translates provided resourceStringKeys and returns result in callback function async upon completion
   * @param resourceStringKeys array of resource strings to be translated
   * @param onComplete the callback that handles the response
   * */

  setTopicResourceStringSubscription(resourceStringKeys: string[], onComplete: (translatedStrings: string[]) => void) {
    this.stopUsingResources = this.localizationService.using(
      this.topicResourceStringKeys,
      resourceStrings => {
        onComplete(resourceStrings);
      }
    );
  }

  getSelectedTopicDropdownInput(resourceStringKey: string): DropdownInput {
    const index = this.topicResourceStringKeys.indexOf(resourceStringKey);
    this.log.debug('Set selected topic', resourceStringKey, index);
    if (index !== null && this.topics.length >= index) {
      this.log.debug(
        'Selected topic is',
        this.topics[index], this.topicResourceStringKeys[index],
        this.topicResourceStringKeys, index, resourceStringKey
      );
      return this.topics[index];
    } else {
      return null;
    }
  }

  onFilterCountries(args: DropdownArgs) {
    this.log.debug("Countries", this.countries$);
    this.countries$ = this.countryController.countrySearch({
      query: args.query,
      index: args.index,
      take: args.take
    }).pipe(
      map(countryListResult => {
        return <DropdownData>{
          items: countryListResult.items.map(country => {
            return { text: country.name, value: country.code.toString() };
          }),
          totalCount: countryListResult.count,
          hasMoreResults: args.index + 10 < countryListResult.count
        };
      })
    );
  }

  /***
   * Set subscriptions for value changes in 'byEmail' and 'byPhone' checkboxes.
   * These are used to update validation of email and phone inputs depending on if they are visible or not.
   * */
  setContactSubscriptions() {
    const contactByEmail = this.contactForm['controls'].contactPreferenceGroup['controls'].byEmail;
    const contactByPhone = this.contactForm['controls'].contactPreferenceGroup['controls'].byPhone;

    const emailControl = this.contactForm.get('email');
    this.emailSubscription = contactByEmail.valueChanges
      .subscribe(value => {
        emailControl.setValidators(value ? [Validators.required, Validators.email] : null);
        emailControl.updateValueAndValidity();
      });

    const phoneControl = this.contactForm.get('phone');
    this.phoneSubscription = contactByPhone.valueChanges
      .subscribe(value => {
        phoneControl.setValidators(value ? [Validators.required] : null);
        phoneControl.updateValueAndValidity();
      });
  }

  onSubmit() {


    this.formValidated = true;

    let topic = this.contactForm.controls['topic']?.value?.text;
    let message = this.contactForm.controls['message']?.value;

    // change topic to salesforce + add selected topic to message body if we are requesting quote for insights or productivity
    const isInsightsTopicSelected = this.contactForm.controls['topic']?.value?.text == this.localizationService.get(ContactUsTopic.MySandvikInsightEnquiry);
    const isIProductivityTopicSelected = this.contactForm.controls['topic']?.value?.text == this.localizationService.get(ContactUsTopic.MySandvikProductivityEnquiry);

    if (isInsightsTopicSelected || isIProductivityTopicSelected) {
      topic = this.salesforceRemoteSupportEnquiryTopic;
      if (isInsightsTopicSelected) {
        message = `${this.localizationService.get(ContactUsTopic.MySandvikInsightEnquiry)}\n\n${message}`;
      } else if (isIProductivityTopicSelected) {
        message = `\n\n${this.localizationService.get(ContactUsTopic.MySandvikProductivityEnquiry)}\n\n${message}`;
      }
    }

    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.contactForm, this.element);
      return;
    }

    if (this.contactForm.valid) {
      this.formSubmitted = true;

      const contactInfo: ContactInfo = {
        country: this.contactForm.controls['country']?.value?.value,
        email: this.contactForm.controls['email']?.value,
        fullName: this.contactForm.controls['name']?.value,
        message: message,
        orderNumber: this.formData.orderNumber,
        organization: (this.contactForm.controls['organization']?.value != null) ? this.contactForm.controls['organization']?.value : null,
        partNumber: this.formData.partNumber,
        phoneNumber: this.contactForm.controls['phone']?.value,
        topic: topic,
        orderType: this.formData.orderType
      };

      this.contactForm.disable();

      this.submitFormSubscription = this.contactApiController.submitForm({ body: contactInfo }).subscribe(
        () => {
          this.onFormSubmitCompleted('ContactUs_Message_Sent', NoticeType.Success);
        },
        () => {
          this.onFormSubmitCompleted('ContactUs_Message_Sent_Error', NoticeType.Error);
        }
      );
    }
  }

  onFormSubmitCompleted(message: string, noticeType: NoticeType) {
    this.isSendTrigger = true;
    this.formValidated = false;
    if (this.helpPageForm && noticeType !== NoticeType.Error) {
      this.messageWasSent = true;
    }

    this.formSubmitted = false;
    this.contactUsService.closeContactUs();
    this.noticebarService.show(message, noticeType);
  }

  onSignOut(){
    this.auth.signout();
  }


  ngOnDestroy() {
    this.log.debug("Destroy contact form");

    this.formSubmitted = false;

    if (this.emailSubscription) {
      this.emailSubscription.unsubscribe();
    }

    if (this.phoneSubscription) {
      this.phoneSubscription.unsubscribe();
    }

    if (this.submitFormSubscription) {
      this.submitFormSubscription.unsubscribe();
    }

    if (this.stopUsingResources) {
      this.stopUsingResources();
    }
  }

}
