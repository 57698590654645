import { NoticebarService } from './../../../../../../services/noticebar-service/noticebar.service';
import { MibpHttpApi } from './../../../../../../services/mibp-http-api/mibp-http-api.service';
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {
  FrontendContextService,
  MibpLogger,
  LogService,
  ApiService,
  CartService,
  BroadcastService
} from 'root/services';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NavigationService } from './../../../../../../services/navigation/navigation.service';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { CartsApiController } from 'root/mibp-openapi-gen/controllers';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { Guid } from 'guid-typescript';
import { firstValueFrom } from 'rxjs';
import { Cart } from 'root/mibp-openapi-gen/models';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MySandvikFeatures } from 'root/services/permission';

@Component({
  selector: 'mibp-cart-option',
  templateUrl: './cart-option.component.html',
  styleUrls: ['./cart-option.component.scss']
})

export class CartOptionComponent implements OnInit, OnChanges {
  @Input() cart: Cart;
  @ViewChild("cartNameElement", { read: ElementRef }) cartNameElement: ElementRef;
  @Input() disableExportCart = false;
  nameForm: UntypedFormGroup;
  log: MibpLogger;
  hasCreateCartPermission: boolean;
  hasExportCartPermission: boolean;
  hasTemplatesPermission: boolean;
  exportUrl: string;
  constructor(private api: ApiService,
    private frontendContext: FrontendContextService,
    private session: MibpSessionService,
    private httpApi: MibpHttpApi,
    private navService: NavigationService,
    logger: LogService,
    private cartsController: CartsApiController,
    private noticeBarService: NoticebarService,
    private cartService: CartService,
    private broadcast: BroadcastService
  ) {
    this.log = logger.withPrefix('cart-options');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cart) {
      if (changes.cart.currentValue) {
        try {
          const todayDate = new Date().toLocaleDateString('en-ca');
          this.exportUrl = this.httpApi.resolveUriWithJwtToken(`download/cart/${encodeURIComponent(this.cart.id.toString())}/${todayDate}`);
        } catch(exception) {
          this.log.error("Failed to export Cart.", exception);
          this.exportUrl = null;
        }
      } else {
        this.exportUrl = null;
      }
    }
  }

  ngOnInit(): void {
    this.hasCreateCartPermission = this.frontendContext.testPermission(allPermissionPolicies.canAddToCart);
    this.hasExportCartPermission = this.frontendContext.testPermission(allPermissionPolicies.canExportCarts);
    this.hasTemplatesPermission = this.frontendContext.testPermission(allPermissionPolicies.canUseTemplates);
  }

  saveForLater(): void {

    if (this.session.hasFeature(MySandvikFeatures.ShopPersistentCart)) {
      return alert('Not implemented for persistent carts');
    }

    this.cartsController.saveActiveCart().toPromise().then(() => {
      this.log.debug("Cart saved");
      this.broadcast.setCartEvent({count:0});
      this.navService.navigateTo('shop/cart/saved');
    }).catch(error=>{
      this.log.error('Error saving cart', error);
      this.noticeBarService.showText("Error saving active cart", NoticeType.Error, false);
    });
  }

  exportClick(e: Event): void {

    if (this.session.hasFeature(MySandvikFeatures.ShopPersistentCart)) {
      return alert('Not implemented for persistent carts');
    }

    if (this.disableExportCart) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  saveAsTemplate(): void {

    if (this.session.hasFeature(MySandvikFeatures.ShopPersistentCart)) {
      return alert('Not implemented for persistent carts');
    }


    firstValueFrom(this.cartsController.saveActiveCartAsTemplate()).then(() => {
      this.log.debug("Cart saved as template");
      this.broadcast.setCartEvent({count:0});
      this.navService.navigateTo('shop/templates');
    }).catch(error=>{
      this.log.error('Error saving cart as template', error);
      this.noticeBarService.showText("Error saving cart as template", NoticeType.Error,false);
    });
  }

}
