import { ImportFromExcelViewModel } from './mibp-http-api.types';
import { SignalR_ApiResponseGeneric } from './../mibp-api-services';
import { MibpHttpApi } from './mibp-http-api.service';
/**
 * Provides an API And for fetching large kits that are too big for SignalR
 */
export class KitHttpService {

  constructor(private httpApi: MibpHttpApi) {}

  public async ImportMaintenanceKits(file: ImportFromExcelViewModel): Promise<SignalR_ApiResponseGeneric<number>> {
    return await this.httpApi.post<SignalR_ApiResponseGeneric<number>>('kit/import', file).toPromise();
  }

}
