import { Injectable } from "@angular/core";
import { UrlTree, Router } from "@angular/router";
import { ActivationArgs } from "root/guards";
import { AuthService } from "../auth-service/auth.service";
import { LogService, MibpLogger } from "../logservice";
import { FrontendContextService } from "../front-end-context/front-end-context.service";
import { ClientSideCacheService, CacheScope } from "../client-side-cache/client-side-cache.service";
import { BroadcastService } from "../broadcast-service/broadcast.service";
import { MibpSessionService } from "../mibp-session/mibp-session.service";
import { UserStatus } from "root/mibp-openapi-gen/models";


/**
 * Use from startup guard.
 * Will wait until the current user is resolved
 */
@Injectable({
  providedIn: 'root'
})
export class StartupUserStatusService {

  private log: MibpLogger;

  constructor(
    private authService: AuthService,
    private frontendContext: FrontendContextService,
    private router: Router,
    private cache: ClientSideCacheService,
    private broadcast: BroadcastService,
    private sessionService: MibpSessionService,
    logger: LogService) {
    this.log = logger.withPrefix('startup-user-status.service');
  }

  public async testUserStatus(args: ActivationArgs): Promise<boolean | UrlTree> {
    if (this.sessionService.hasUser() && this.authService.isLoggedIn() && !this.sessionService.userMustPickPunchoutProfile()) {
      return this.OnValidSessionNLogin(args);
    }
    //Incase User is not added in My Sandvik and try to access it or if we have a Punchout user.
    else if (!this.sessionService.hasUser() && this.authService.isLoggedIn()) {
      // We need to do this here as the statup guard is running in parallell with app-loader
      // which will also refresh the session, but guard will complete faster and will not have an updated session
      await this.sessionService.reloadSessionFromBackend();

      if (!this.sessionService.hasUser() && this.sessionService.userMustPickPunchoutProfile()) {
        const stateUrl = args.state.url.toString();
        const isPunchoutProfilePickerPage = stateUrl?.toLowerCase().indexOf('/user/profilepicker') !== -1;

        if (isPunchoutProfilePickerPage) {
          return Promise.resolve(true);
        }

        return this.resolveUrl('/user/profilepicker');
      }
      else if (!this.sessionService.hasUser()) {
        const stateUrl = args.state.url.toString();
        const isNewUserRegistrationPage = stateUrl?.toLowerCase().indexOf('/user/newuserregistration') !== -1;
        if (isNewUserRegistrationPage) {
          return Promise.resolve(true);
        }
        return this.resolveUrl('/user/newuserregistration');
      }
      else {
        return this.OnValidSessionNLogin(args);
      }
    }

    return Promise.resolve(true);
  }

  private OnValidSessionNLogin(args: ActivationArgs) {
    const stateUrl = args.state.url.toString();
    const isNewUserRegistrationPage = stateUrl.indexOf('/user/newuserregistration') !== -1;
    const isInactiveUserPage = stateUrl.indexOf('/user/inactive') !== -1;
    const isRejectedUserPage = stateUrl.indexOf('/user/rejected') !== -1;
    const isILockedUserPage = stateUrl.indexOf('/user/locked') !== -1;
    const isDeactivatedUserPage = stateUrl.indexOf('/user/deactivated') !== -1;
    const isNotLoggedInPage = stateUrl.indexOf('/user/notloggedin') !== -1;
    const isNewUserPage = !isNewUserRegistrationPage && stateUrl.indexOf('/user/new') !== -1;
    const isMultiProfile: boolean = this.cache.get("multiprofile");
    const isMyProfile = stateUrl.indexOf('/en/user/myprofile') !== -1;

    if (!this.sessionService.hasUser() && !isNewUserRegistrationPage) {
      return this.resolveUrl('/user/newuserregistration');
    } else if (this.sessionService.hasUser() && this.sessionService.isRegistrationRequired()) {
      if (isNewUserRegistrationPage) {
        return Promise.resolve(true);
      } else {
        return this.resolveUrl('/user/newuserregistration');
      }
    } else if (!this.sessionService.hasActiveUser() && isMyProfile) {
      return Promise.resolve(true);
    } else if (isNewUserRegistrationPage && !this.sessionService.hasStatus(UserStatus.Created)) {
      // Perhaps this logic could be moved to the newuserregistration page instead. This code does not have to run all the time
      if (!this.sessionService.hasStatus(UserStatus.Suspended)) {
        return this.resolveUrl(this.frontendContext.Navigation.ensureUrlLanguage('/'));
      }
    } else if (!this.sessionService.hasStatus(UserStatus.Created) && !this.sessionService.hasStatus(UserStatus.Suspended)) {
      // Registered user on registration page.
      if (isNewUserRegistrationPage || isNotLoggedInPage) {
        return this.resolveUrl(this.frontendContext.Navigation.ensureUrlLanguage('/'));
      }

      // Other redirects based on user status
      let userRedirectPath = '';
      if ((this.sessionService.hasStatus(UserStatus.New) || !this.sessionService.hasRole()) && !isNewUserPage) {
        // If multi profile for punchout
        if (isMultiProfile) {
          return Promise.resolve(true);
        } else {
          userRedirectPath = 'new';
        }
      } else if (this.sessionService.hasStatus(UserStatus.Rejected) && !isRejectedUserPage) {
        userRedirectPath = 'rejected';
      } else if (this.sessionService.hasStatus(UserStatus.Deactivated) && !isDeactivatedUserPage) {
        userRedirectPath = 'deactivated';
      } else if (this.sessionService.hasStatus(UserStatus.Inactivated) && !isInactiveUserPage) {
        userRedirectPath = 'inactive';
      } else if (this.sessionService.hasStatus(UserStatus.Active) || this.sessionService.hasStatus(UserStatus.Approved)) {
        if (isNewUserRegistrationPage || isInactiveUserPage) {
          return this.resolveUrl('/');
        }
        else {
          return Promise.resolve(true);
        }
      }

      if (userRedirectPath) {
        return this.resolveUrl(`/user/${userRedirectPath}`);
      }
    } else {
      return Promise.resolve(true);
    }
  }

  private resolveUrl(url: string): Promise<UrlTree> {
    return Promise.resolve(this.getUrl(url));
  }

  private getUrl(url: string): UrlTree {
    return this.router.parseUrl(this.frontendContext.Navigation.ensureUrlLanguage(url));
  }
}
