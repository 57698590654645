import { Injectable } from "@angular/core";
import { GlobalConfigService, MibpHttpApi } from "root/services";
import { firstValueFrom } from "rxjs";
import { UsersApiController } from './../../../mibp-openapi-gen/services/users-api-controller';
import { MibpSessionService } from "root/services/mibp-session/mibp-session.service";
import { UserType } from "root/mibp-openapi-gen/models";

@Injectable({
  providedIn: 'root'
})
export class MibpInternalUserPhotoService {

  constructor(private globalConfig: GlobalConfigService,
    private httpApi: MibpHttpApi,
    private sessionService: MibpSessionService,
    private usersApi: UsersApiController) {}

  private photoPromise: Promise<string>;


  public getUserPhoto(): Promise<string> {

    if (this.photoPromise) {
      return this.photoPromise;
    }

    if (this.sessionService.current?.user?.type == UserType.External) {
      return Promise.resolve(undefined);
    }

    if (!this.globalConfig.loadInternalUserPhoto) {
      return Promise.resolve(undefined);
    }

    this.photoPromise = new Promise((resolve) => {

      firstValueFrom(this.usersApi.getProfileBase64()).then( (userPhotoBase64) => {
        if (userPhotoBase64) {
          resolve(`url(data:image/jpg;base64,${userPhotoBase64})`);
          // document.querySelector('.mysandvik-sidebar__photo').classList.add('mysandvik-sidebar__photo--loaded');
          // document.querySelector<HTMLElement>('.mysandvik-sidebar__photo').style.backgroundImage = `url(data:image/jpg;base64,${userPhotoBase64})`;
        }
        this.photoPromise = undefined;
      }, () => {
        // Ignore errors.
        resolve(undefined);
        this.photoPromise = undefined;
      });

    });

    return this.photoPromise;
  }


}
