
import { Injectable } from "@angular/core";
import { MibpSessionViewModel, UserPermission, BusinessRelationPermission, UserStatus } from "root/mibp-openapi-gen/models";
import { SignalR_BusinessRelationPermission, SignalR_RoleAdministrationPermission, SignalR_RoleEcommercePermission, SignalR_UserPermission, SignalR_UserStatus } from "../mibp-api-services/_mibp-api-generated.service.types";
import { BroadcastService } from './../broadcast-service/broadcast.service';
import { AuthService } from "../auth-service/auth.service";
import { MibpSessionService } from "../mibp-session/mibp-session.service";


export interface PermissionRule {
  shouldBeloggedIn?: boolean;
  shouldBeRegistered?: boolean;

  businessRelationPermissions?: BusinessRelationPermission[];
  features?: string[];

  permissionMatch?: 'any' | 'all' | 'none';
  isFeatureAdministrator?: boolean;
  isSupportTeamMember?: boolean;
  isPunchoutUser?: boolean;
  isPunchoutMultiProfileUser?: boolean;
  shouldBeActive?: boolean;
  userStatus?: UserStatus[];
}

export type PermissionPolicyItem = PermissionRule | 'and' | 'or';
export type PermissionPolicy = PermissionPolicyItem | (PermissionPolicyItem | PermissionPolicyItem[])[];


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  //private userEvent: SignalR_UserApiEvent;
  private sessionVm: MibpSessionViewModel;

  constructor(private broadcast: BroadcastService, private s: MibpSessionService) {
    this.broadcast.mibpSession.subscribe(userEvent => {
      this.sessionVm = userEvent;
    });
  }



  public policyHasPermissionChecks(opts: PermissionPolicy): boolean {
    if (!opts) {
      return false;
    }
    if (Array.isArray(opts)) {
      for (let i = 0; i < opts.length; i++) {
        if (this.policyHasPermissionChecks(opts[i])) {
          return true;
        }
      }
    } else if (typeof opts !== 'string') {
      this.ruleHasPermissionChecks(opts);
    }
    return false;
  }

  private ruleHasPermissionChecks(rule: PermissionPolicyItem): boolean {
    if (rule !== 'and' && rule !== 'or') {
      if (rule.businessRelationPermissions && rule.businessRelationPermissions.length > 0) {
        return true;
      }
      if (rule.features && rule.features.length > 0) {
        return true;
      }
    }
    return false;
  }

  public testForUser(opts: PermissionPolicy,
    BusinessRelationPermission: BusinessRelationPermission = this.sessionVm.activeDeliverySequence?.businessRelationPermission,
    // ecommercePermission: SignalR_RoleEcommercePermission = this.userEvent.roleEcommercePermissions,
    // roleAdministrationPermission: SignalR_RoleAdministrationPermission = this.userEvent.roleAdminPermissions,
    // userPermission: SignalR_UserPermission | UserPermission = this.userEvent.userPermissions,
    feaures: string[] = [],
    isFeatureAdministrator: boolean = this.sessionVm?.user.isFeatureAdministrator,
    isSupportTeamMember: boolean = this.sessionVm?.user?.isSupportTeamMember,
    userStatus: UserStatus = this.sessionVm?.user?.status,
    isPunchoutUser: boolean = this.sessionVm?.user?.isPunchoutUser,
    isPunchoutMultiProfileUser: boolean = this.sessionVm?.user?.isPunchoutMultiProfileUser,
    isActive: boolean = this.sessionVm?.user?.status == UserStatus.Active): boolean {

    if (Array.isArray(opts)) {
      const result  = this.testArray(opts as PermissionPolicyItem[]);
      return result;
    } else if (typeof opts !== 'string') {
      // Single rule
      return this.testRule(opts as PermissionRule, BusinessRelationPermission, feaures, isFeatureAdministrator, isSupportTeamMember,
        isPunchoutUser,
        isPunchoutMultiProfileUser,
        isActive,
        userStatus);
    }
    return false;
  }


  public test(opts: PermissionPolicy ): boolean {

    if (Array.isArray(opts)) {
      const result  = this.testArray(opts as PermissionPolicyItem[]);
      return result;
    } else if (typeof opts !== 'string') {
      // Single rule
      return this.testRule(opts as PermissionRule);
    }
    return false;
  }

  private cleanupArray(originalArray: PermissionPolicyItem[]) {
    const items = originalArray.slice(0);

    /**
     * Make sure we have no "or"/"and" items after each other
     */
    for (let i = items.length - 1; i >= 0; i--) {
      if (i > 0) {
        if (items[i] === 'and' || items[i] === 'or') {
          if (items[i - 1] === 'and' || items[i - 1] === 'or') {
            items.splice(i, 1);
          }
        }
      }
    }

    // Make sure there's an "and" or an "or" between each policy statement
    const normalizedStatementArray: PermissionPolicyItem[] = [];
    for (let i = 0; i < items.length; i++) {
      normalizedStatementArray.push(items[i]);
      if (i < items.length - 1) {
        if (items[i + 1] === 'or' || items[i + 1] === 'and') {
          normalizedStatementArray.push(items[i + 1]);
          i++;
          continue;
        }
        normalizedStatementArray.push('and');
      }
    }
    return normalizedStatementArray;
  }

  /**
   * Tests an array of policys supporting and/or operators
   */
  private testArray(items: PermissionPolicyItem[]): boolean {

    const result: (boolean | 'and' | 'or')[] = [];

    if (items.length > 0) {
      items = this.cleanupArray(items);
    }

    // Push test results ito an array of [true, 'and', false, 'or', ...]
    for (let i = 0; i < items.length; i++) {
      if (items[i] === 'or' || items[i] === 'and') {
        result.push(items[i] as 'and' | 'or');
      } else {
        if (Array.isArray(items[i])) {
          result.push(this.testArray(items[i] as PermissionPolicyItem[]));
        } else {
          result.push(this.testRule(items[i] as PermissionRule));
        }
      }
    }

    // Group together all the "And":s
    let n = 0;
    do {
      const andIndex = result.findIndex(fa => fa === 'and');
      if (andIndex !== -1) {
        const first = result[andIndex - 1] as boolean;
        const second = result[andIndex + 1] as boolean;
        result.splice(andIndex - 1, 3, (first && second));
      } else {
        break;
      }
      n++;
    } while (n < 20);

    // We should only have OR:s remaining
    // So if any of them are true, the result is true
    return result.filter(r => r === true).length > 0;
  }

  /**
   * Test a single PermissionRule
   */
  private testRule(rule: PermissionRule,
    businessRelationPermission: BusinessRelationPermission = this.sessionVm?.activeDeliverySequence?.businessRelationPermission,
    features: string[] = this.sessionVm?.user?.enabledFeatures,
    isFeatureAdministrator: boolean = this.sessionVm?.user?.isFeatureAdministrator,
    isSupportTeamMember: boolean = this.sessionVm?.user?.isSupportTeamMember,
    isPunchoutUser: boolean = this.sessionVm?.user?.isPunchoutUser,
    isPunchoutMultiProfileUser: boolean = this.sessionVm?.user?.isPunchoutMultiProfileUser,
    isActive: boolean = this?.sessionVm?.user?.status == UserStatus.Active,
    userStatus: UserStatus = this.sessionVm?.user?.status) {
    let result = true;

    let loginShouldBeRequired = (rule.businessRelationPermissions && rule.businessRelationPermissions.length > 0) || rule.isFeatureAdministrator;

    if (typeof rule.userStatus !== 'undefined') {
      if (!rule.userStatus.includes(userStatus)) {
        return false;
      }
    }

    loginShouldBeRequired = typeof rule.features !== 'undefined';
    if (typeof rule.isFeatureAdministrator !== 'undefined' || typeof rule.isPunchoutUser !== 'undefined' || typeof rule.isSupportTeamMember !== 'undefined' || typeof rule.isPunchoutMultiProfileUser !== 'undefined' ) {
      loginShouldBeRequired = true;
    }

    rule.permissionMatch = rule.permissionMatch || 'all';
    rule.shouldBeloggedIn = loginShouldBeRequired || rule.shouldBeloggedIn;
    rule.shouldBeRegistered = loginShouldBeRequired || rule.shouldBeRegistered;

    if (rule.shouldBeloggedIn) {
      result = this.isLoggedIn();
    }

    if (rule.shouldBeRegistered) {
      result = this.isRegisteredUser();
    }

    if (rule.shouldBeActive) {
      result = isActive;
    }

    if (result) {
      // BusinessRelation permissions should still be used even when we start using features
      // This is for the reports
      result = this.testPermission<BusinessRelationPermission>(businessRelationPermission, rule.businessRelationPermissions, rule.permissionMatch);
    }

    if (result) {
      result = this.testFeatures(features, rule.features, rule.permissionMatch);
    }

    if (result && typeof rule.isFeatureAdministrator != 'undefined') {
      result = isFeatureAdministrator == rule.isFeatureAdministrator;
    }

    if (result && typeof rule.isSupportTeamMember != 'undefined') {
      result = isSupportTeamMember == rule.isSupportTeamMember;
    }

    if (result && typeof rule.isPunchoutUser != 'undefined') {
      result = isPunchoutUser == rule.isPunchoutUser;
    }

    if (result && typeof rule.isPunchoutMultiProfileUser != 'undefined') {
      result = isPunchoutMultiProfileUser == rule.isPunchoutMultiProfileUser;
    }

    return result;
  }

  private testFeatures(haystack: string[], features: string[], match: 'any' | 'all' | 'none'): boolean {
    let result = true;

    if (Array.isArray(features) && features.length=== 0) {
      // For now (maybe) - an empty feature array means there is no feature that will give you access to this.
      // Always return false
      return false;
    }

    if (features) {
      if (match === 'all') {
        if (!this.hasAllFeatures(haystack, features) ) {
          result = false;
        }
      } else if (match === 'any'  || match === 'none') {
        const hasAny = this.hasAnyFeature(haystack, features);
        if (!hasAny && match === 'any') {
          result = false;
        } else if (hasAny && match === 'none') {
          return false;
        }
      }
    }
    return result;
  }

  /**
   * Test a single permission toward a haystack
   */
  private testPermission<T>(haystack: T, permissions: T[], match: 'any' | 'all' | 'none'): boolean {
    let result = true;
    if (permissions) {
      if (match === 'all') {
        if (!this.hasAllPermissions(haystack, permissions) ) {
          result = false;
        }
      } else if (match === 'any'  || match === 'none') {
        const hasAny = this.hasAnyPermission(haystack, permissions);
        if (!hasAny && match === 'any') {
          result = false;
        } else if (hasAny && match === 'none') {
          return false;
        }
      }
    }
    return result;
  }

  /**
   * Returns true if the haystack contains any of the given permissions
   */
  private hasAnyPermission<T>(haystack: T, needles: T[]): boolean {
    for (let i = 0; i < needles.length; i++) {
      if (this.hasPermission(haystack, needles[i])) {
        return true;
      }
    }
    return false;
  }


  /**
   * Returns true if the haystack contains all of the given permissions
   */
  private hasAllPermissions<T>(haystack: T, needles: T[]): boolean {
    for (let i = 0; i < needles.length; i++) {
      if (!this.hasPermission(haystack, needles[i])) {
        return false;
      }
    }
    return true;
  }

  private hasAllFeatures(haystack: string[], needles: string[]): boolean {
    for (let i = 0; i < needles.length; i++) {
      if (!this.hasFeature(haystack, needles[i])) {
        return false;
      }
    }
    return true;
  }

  private hasFeature(haystack: string[], needle: string): boolean {
    if (this.isRegisteredUser()) {
      return haystack?.includes(needle);
    }
    return false;
  }

  private hasAnyFeature(haystack: string[], needles: string[]): boolean {
    for (let i = 0; i < needles.length; i++) {
      if (this.hasFeature(haystack, needles[i])) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns true if the haystack contains the given permission
   */
  private hasPermission<T>(haystack: T, needle: T): boolean {
    if (this.isRegisteredUser()) {
      return !!(this.bitwiseAndLarge(haystack, needle));
    }
    return false;
  }

  public isLoggedIn(): boolean {
    return true; //!!this.sessionVm.user;
  }

  public isRegisteredUser(): boolean {
    return this.isLoggedIn() && !!this.sessionVm?.user;
  }

  public isActiveUser(): boolean {
    return this.isLoggedIn() && this.isRegisteredUser() && this.sessionVm?.user.status == UserStatus.Active;
  }

  private bitwiseAndLarge(val1, val2) {
    let shift = 0, result = 0;
    const mask = ~((~0) << 30); // Gives us a bit mask like 01111..1 (30 ones)
    const divisor = 1 << 30; // To work with the bit mask, we need to clear bits at a time
    while ( (val1 !== 0) && (val2 !== 0) ) {
      let rs = (mask & val1) & (mask & val2);
      val1 = Math.floor(val1 / divisor); // val1 >>> 30
      val2 = Math.floor(val2 / divisor); // val2 >>> 30
      for (let i = shift++; i--;) {
        rs *= divisor; // rs << 30
      }
      result += rs;
    }
    return result;
  }

}
