import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  constructor() { }
  public  items: any[];
  getPager(totalItems: number, currentPage: number, pageSize: number, isUserEvent: boolean, visiblePages = 5) {

    if(totalItems > 100000 + pageSize){
      totalItems = 100000 + pageSize;
    }

    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;

    if (totalPages <= visiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - (visiblePages - 1);
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.ceil(visiblePages/2)+2;
        endPage = currentPage + Math.ceil(visiblePages/2)-2;
      }
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);


    if (isNaN(endPage)) {
      endPage = 1;
    }

    if (isNaN(startPage)) {
      startPage = 1;
    }

    const pages = Array.from(Array(endPage - startPage + 1), (_, i) => startPage + i);

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
      isUserEvent: isUserEvent
    };
  }

  public getPageCount(total: number, pageSize: number): number {
    return Math.ceil(total / pageSize);
  }

  public getFixedPager(total: number, pageSize: number, zeroBasedPage: number, visiblePages = 5 ) {


    const totalPages = Math.ceil(total / pageSize);
    let startPage  = 1;
    let endPage  = 1;
    pageSize = pageSize <= 0 ? 1 : pageSize;
    zeroBasedPage = zeroBasedPage < 0 ? 0 : zeroBasedPage;
    visiblePages = visiblePages < 1 ? 1 : visiblePages;

    if (totalPages <= visiblePages) {
      startPage = 1;
      endPage = totalPages;
    }

    const activePagerPage = zeroBasedPage;
    const pagerPage = this.getpageFromIndex(activePagerPage, visiblePages);

    startPage = pagerPage === 1 ? 1 : ((pagerPage - 1) * visiblePages) + 1;
    endPage = pagerPage === 1 ? startPage - 1 + visiblePages : startPage + visiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
    }

    if (startPage < 0 || endPage < 0) {
      return [];
    }

    let result = [];
    if (endPage >= startPage) {
      result = Array.from(Array(endPage - startPage + 1), (_, i) => startPage + i);
    }

    return result;
  }

  getpageFromIndex(ix, size) {
    if (ix < 0 || size <= 0) {
      return -1;
    }
    const pageCount = Math.ceil(ix/size)+2;
    let page = 1;
    do {
      if (ix < page * size) {
        return page;
      }
      page ++;
    } while (page < pageCount);
    return -1;
  }

  /**
   *
   *
   * @param page zero based page number
   */
  getIndexFromZeroBasedPageNumber(page: number, pageSize: number) {
    return (page * pageSize);

  }
}
