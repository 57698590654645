import { environment } from "root/environment";
import { MibpSessionService } from "../mibp-session/mibp-session.service";
import { filter, fromEvent, take, timeout } from "rxjs";
import { Injectable } from "@angular/core";
import { CacheScope, ClientSideCacheService } from "../client-side-cache/client-side-cache.service";
import { LogService, MibpLogger } from "../logservice";
import { ClientIdService } from "../clientid-service/clientid.service";

@Injectable({providedIn: 'root'})
export class DocumotoAuthService {

  private iframeId = 0;
  private log: MibpLogger;

  constructor(
    private sessionService: MibpSessionService,
    private cache: ClientSideCacheService,
    private clientId: ClientIdService,
    log: LogService) {
    this.log = log.withPrefix(`documoto-auth`);
  }

  public async ensureSignedout(): Promise<void> {

    this.log.info(`Making sure we've logged out from electronic manuals`);

    // Call this once per session to make sure we're logged in as the correct user
    const cacheKey = `documoto-signedout-${this.clientId.getClientId()}`;
    if (!this.cache.get(cacheKey)) {
      await this.signout();
      this.cache.add(cacheKey, 'true', null, CacheScope.UserSessionStorage);
      this.log.info(`OK Sign out completed`);
    } else {
      this.log.info(`OK this was already done for this user in this session`);
    }

  }

  public async signout(): Promise<void> {

    return new Promise(resolve => {
      const id = this.iframeId;
      this.iframeId++;

      const iframe = this.addNewIframe(id);
      const html = this.createIframeHtml(id);

      fromEvent<MessageEvent>(window, 'message')
        .pipe(
          filter(msg => msg.data === `DocumotoIframeSignOut${id}`),
          take(1),
          timeout(3000)
        ).subscribe({
          next: () => {
            this.log.info(`Got ok message`);
            setTimeout(() => {
              this.removeIframe(id);
              resolve();
            }, 1500);

          },
          error: (e) => {
            this.removeIframe(id);
            this.log.error(`Got err message`, e);
            resolve();
          }
        });

      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(html);
      iframe.contentWindow.document.close();

    });

  }

  private removeIframe(id: number): void {
    this.log.info(`Removing iframe ${id}`);
    const iframe = document.getElementById(`documoto-signout-${id}`);
    if (iframe) {
      iframe.parentElement.removeChild(iframe);
    }
  }

  /**
   * Create a new hidden iframe for logging out and add it to the DOM
   */
  private addNewIframe(id: number): HTMLIFrameElement {
    this.log.info(`Adding iframe ${id}`);
    let logoutIframe = document.createElement('iframe');
    logoutIframe.setAttribute('id', `documoto-signout-${id}`);
    logoutIframe.setAttribute('style', 'width: 0; height: 0; position: absolute; visibility: hidden');
    logoutIframe = document.body.appendChild(logoutIframe);
    return logoutIframe;
  }

  /**
   * Create the HTML for a form element that will peform a POST to the logout endpoint
   */
  private createIframeHtml(id: number): string {
    const signoutUrl = environment.documoto.signoutUrl
      .replace(`(userName)`, encodeURIComponent(`${this.sessionService.getIdentityObjectId()}@mibp.int`));

    const html = `
<form method="POST" onsubmit="onFormSubmit()" action="${signoutUrl}">
  <button id="signoutform"  type="submit">SIGN OUT</button>
</form>
<script type="text/javascript">
  function onFormSubmit() {
   window.parent.postMessage("DocumotoIframeSignOut${id}", "*");
  };
  document.getElementById("signoutform").click();
</script>
`;
    return html;
  }

}
