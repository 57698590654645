export interface ApplicationState {
  state: ApplicationStates;
  resourceStringKey?: string;
  textFallback?: string;
  error?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exception?: any;
  showReloadButton?: boolean,
  internalStatus?: string;
  stopStartupGuardProcessing?: boolean;

  /**
   * A stack track that can be shown to the user
   * And can also be sent to support for better help
   */
  stack?: string;
}


export enum ApplicationStates {
  /**
   * Before angular is loaded
   */
  Initializing,

  /**
   * Angular is initiated and is loading global configuration
   */
  LoadingGlobalConfig,

  SSO,

  /**
   * Check user status - but do not trigger login or anything
   */
  CheckB2CUserStatus,

  Authentication,

  /**
   *
   */
  // TODO: When we do not require connection to signalr, this should not be a step in the loading process it can be done silently
  ConnectingToSignalR,

  StartResetPasswordRedirect,

  LoadingTranslations,

  /**
   * When waiting for UserApiEvent
   */
  LoadingAccountInfo,


  Loaded,


  UnhandledException,

  Offline

}
