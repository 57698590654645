
<span *ngIf="showIcon" class="icon" (click)="open($event)">
  <div *ngIf="showIconIndicator" class="icon__indicator"></div>
  <fa-icon [icon]="menuIcon"></fa-icon>
  <span translate="no" class="material-icon">menu</span>
</span>

<ng-template #menuTemplate let-hejhej>
  <div class="my-context-menu">
    <div *ngFor="let item of hejhej">
      <div class="my-context-menu__separator" *ngIf="item.separator"></div>
      <div class="my-context-menu__item"
      [attr.title]="item.title"
      (click)="selectAction(item)"
      [class.my-context-menu__item--icon]="!!item.icon"
      [class.my-context-menu__item--bold]="!!item.bold"
      [class.my-context-menu__item--disabled]="!!item.disabled"
      [class.my-context-menu__item--danger]="!!item.danger"
      *ngIf="!item.separator && !item.hidden">
        <span>
          <span *ngIf="item.iconName" translate="no" class="material-icon">{{item.iconName}}</span>
        </span>
        <mibp-resource-string *ngIf="item.resourceStringKey && !item.textOverride" [key]="item.resourceStringKey" [macros]="item.resourceStringMacros"></mibp-resource-string>
        <span class="text" *ngIf="item.textOverride">{{ item.textOverride }}</span>
      </div>
    </div>

  </div>
</ng-template>
