import { SignalR_DeliverySequence, SignalR_UserStatus, SignalR_UserPermission } from 'root/services/mibp-api-services';
// import { SignalRService } from './../signalr-service/signalr.service';
// import {
//   SignalR_UserApiEvent
// } from '../mibp-api-services';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NavigationContext } from './navigation-context.class';
import { LogService, MibpLogger } from '../logservice';
import { PermissionService } from '../permission/permission.service';
import { BroadcastService } from '../broadcast-service/broadcast.service';
import { MySandvikFeatures } from 'root/services/permission';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { PermissionPolicy } from 'root/services/permission/permission.service';
import { UserPermission } from 'root/mibp-openapi-gen/models';


@Injectable()
export class FrontendContextService {


  contextReady = new BehaviorSubject<boolean>(false);
  private navigationContext: NavigationContext;
  private idAutoInc = 0;
  private buildNumber: string = null;
  // private userApiEventSubject = new BehaviorSubject<SignalR_UserApiEvent>(null);
  // private userApiEventInitialLoadSubject = new Subject<SignalR_UserApiEvent>();
  private userApiSubscription: Subscription;
  private deliverySequenceSubject = new BehaviorSubject<SignalR_DeliverySequence>(null);
  private isConnectedSubject = new BehaviorSubject<boolean>(false);
  private log: MibpLogger;
  private isAuthorized = false;
  private emailFromToken: string;
  public sidebarToggleSubject = new BehaviorSubject<boolean>(false);
  public isSidebarHiddenSubject = new BehaviorSubject<{isHidden:boolean,number:number}>(null);

  constructor(
    logger: LogService,
    private broadcastService: BroadcastService,
    private permissionService: PermissionService,
    router: Router) {
    this.log = logger.withPrefix('frontend-context');
    this.navigationContext = new NavigationContext(router, broadcastService);
  }

  public getLang(): string {
    return this.broadcastService.snapshot.language;
  }

  // public getUserDisplayName(): string {
  //   if (this.isLoggedIn()) {
  //     if (this.current.user) {
  //       if (this.testPermission(allPermissionPolicies.isPunchoutMultiProfileUser)) {
  //         return this.current.user.firstName;
  //       }
  //       return this.current.user.email;
  //     }
  //     return this.emailFromToken;
  //   }
  //   return null;
  // }

  // public getCurrentUserId(): Guid {
  //   if (this.isLoggedIn()) {
  //     if (this.current.user) {
  //       return this.current.user.id;
  //     }
  //     return null;
  //   }
  //   return null;
  // }

  /**
   * Returns true if the user is logged in
   */
  // public isLoggedIn(): boolean {
  //   return this.isAuthorized;
  // }

  // public userIsActive(): boolean {
  //   return this.isRegisteredUser() && this.current.user.status === SignalR_UserStatus.Active;
  // }

  // public userIsApproved(): boolean {
  //   return this.isRegisteredUser() && this.current.user.status === SignalR_UserStatus.Approved;
  // }

  /**
   * Returns true if the user is logged in and exists in the database
   */
  // public isRegisteredUser(): boolean {
  //   return this.isLoggedIn() && this.current && !!this.current.user;
  // }

  /**
   * Get the current UserApiEvent or wait for one to appear
   */
  // public async getUserApiEvent(timeoutMs: number): Promise<SignalR_UserApiEvent> {
  //   if (this.userApiEventSubject.value) {
  //     return Promise.resolve(this.userApiEventSubject.value);
  //   }
  //   const start = new Date();
  //   return new Promise(resolve => {
  //     const i = setInterval(() => {
  //       if ((new Date()).getTime() - start.getTime() >= timeoutMs) {
  //         clearInterval(i);
  //         resolve(null);
  //       }
  //       if (this.userApiEventSubject.value) {
  //         clearInterval(i);
  //         resolve(this.userApiEventSubject.value);
  //       }
  //     }, 50);
  //   });
  // }

  /**
   * Generate a new unique Id
   */
  newId(): number {
    this.idAutoInc++;
    return this.idAutoInc;
  }

  public newRandomIdString(length = 6): string {
    return Math.random().toString(20).substr(2, length);
  }

  // get ActiveDeliverySequence$(): Observable<SignalR_DeliverySequence> {
  //   return this.deliverySequenceSubject.asObservable();
  // }


  /**
   * Get the most recent active delivery sequence
   */
  // get ActiveDeliverySequence(): SignalR_DeliverySequence {
  //   return this.deliverySequenceSubject.value;
  // }

  get IsConnected(): boolean {
    return this.isConnectedSubject.value;
  }

  set IsConnected(value: boolean) {
    this.isConnectedSubject.next(value);
  }

  get IsConnected$(): Observable<boolean> {
    return this.isConnectedSubject.asObservable();
  }

  // get userApiEventInitialLoad$(): Observable<SignalR_UserApiEvent> {
  //   return this.userApiEventInitialLoadSubject.asObservable();
  // }

  /**
   * Get the client id for the current SignalR connection
   */
  get ClientId(): string {
    return this.broadcastService.snapshot.clientId;
  }

  get Navigation(): NavigationContext {
    return this.navigationContext;
  }

  /**
   * Get the current build number for the application
   */
  get BuildNumber(): string {
    if (this.buildNumber === null) {
      const meta = document.querySelector('meta[name="my-sandvik"]');
      if (meta) {
        this.buildNumber = meta.getAttribute('data-build');
      } {
        this.buildNumber = 'lde.' + (new Date()).getTime();
      }
    }
    return this.buildNumber;
  }

  // TODO - should be moved to some other service
  public isExpectedDeliveryDateEnabled(): boolean {
    return this.permissionService.test(allPermissionPolicies.canSeeExpectedDeliveryDate);
  }


  /**
   * Invoked by Startup Guard when the first UserApi events has been received
   */
  // public userApiFirstLoad(isAuthorized: boolean, emailFromToken: string, userApiEvent: SignalR_UserApiEvent): void {
  //   if (!this.current) {
  //     this.emailFromToken = emailFromToken;
  //     this.isAuthorized = isAuthorized;
  //     this.current = userApiEvent;
  //     this.permissionService.setup(userApiEvent, isAuthorized, this.broadcastService.snapshot.globalConfig.mySandvikFeaturesEnabled);
  //     this.userApiEventSubject.next(this.current);
  //     this.userApiEventInitialLoadSubject.next(this.current);
  //     this.deliverySequenceSubject.next(this.current.deliverySequence);
  //     this.contextReady.next(true);
  //     this.subscribeToUserApiEventChanges();
  //   }
  // }

  public canSeeBasePrice(): boolean {
    if (this.broadcastService.snapshot.globalConfig.mySandvikFeaturesEnabled) {
      return this.permissionService.test({features: [MySandvikFeatures.ShopShowBasePrice]});
    } else {
      return !this.permissionService.test(allPermissionPolicies.netPriceOnlyPermission);
    }
  }

  public testPermission(policy: PermissionPolicy): boolean {
    return this.permissionService.test(policy);
  }

  /**
   * Start subscription for updates of UserApi event
   */
  // private subscribeToUserApiEventChanges() {
  //   if (!this.userApiSubscription) {
  //     this.userApiSubscription = this.broadcastService.userEvent.subscribe(updatedUserApi => {
  //       if (this.current?.user?.status === SignalR_UserStatus.Approved && updatedUserApi?.user?.status === SignalR_UserStatus.Active) {
  //         // force page hard reload on new user that have changed status from approved to active
  //         window.location.reload();
  //       }
  //       if (updatedUserApi !== this.current) {
  //         if(updatedUserApi){

  //           const hasDeliverySequenceChanged = this.current?.deliverySequence?.id != updatedUserApi?.deliverySequence?.id;
  //           this.current = updatedUserApi;
  //           this.permissionService.setup(updatedUserApi, true, this.broadcastService.snapshot.globalConfig.mySandvikFeaturesEnabled);
  //           this.userApiEventSubject.next(this.current);
  //           this.log.debug("UserApiEvent was updated", this.current);
  //           if (hasDeliverySequenceChanged) {
  //             this.log.debug('Delivery Sequence Changed?', hasDeliverySequenceChanged);
  //             this.deliverySequenceSubject.next(this.current.deliverySequence);
  //           }
  //         }
  //         else{
  //           const hasDeliverySequenceChanged = this.current?.deliverySequence?.id != updatedUserApi?.deliverySequence?.id;
  //           this.permissionService.setup(this.current, true, this.broadcastService.snapshot.globalConfig.mySandvikFeaturesEnabled);
  //           this.userApiEventSubject.next(this.current);
  //           this.log.debug('Delivery Sequence Changed?', hasDeliverySequenceChanged);
  //           if (hasDeliverySequenceChanged) {
  //             this.deliverySequenceSubject.next(this.current.deliverySequence);
  //           }
  //         }
  //       } // else it's the same as before - do nothing
  //     });
  //   }
  // }

  // hasUserPermission(permission: UserPermission): boolean {
  //   return this.permissionService.test({
  //     userPermissions: [permission]
  //   });
  // }

  toggleSidebar(toggle:boolean) : void {
    this.sidebarToggleSubject.next(toggle);
  }

  isSidebarHidden(isHidden:boolean,sidebarsize:number) : void {
    this.isSidebarHiddenSubject.next({isHidden:isHidden,number:sidebarsize});
  }

}
