import { Injectable } from '@angular/core';
/**
 * Storage implementation to save values in memory (javascript variables)
 */
@Injectable({
  providedIn: 'root'
})
export class ClientSideCacheMemoryStorageService {

  private data = {};

  getItem(key: string) {
    if (typeof this[key] !== 'undefined') {
      return this[key];
    }
    return null;
  }

  removeItem(key: string) {
    if (typeof this[key] !== 'undefined') {
      delete this[key];
    }
  }

  setItem(key: string, value: any) {
    return this[key] = value;
  }
}
