/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EquipmentUpgrade } from '../models/equipment-upgrade';
import { getProductUpgradeForEdit } from '../fn/product-upgrades/get-product-upgrade-for-edit';
import { GetProductUpgradeForEdit$Params } from '../fn/product-upgrades/get-product-upgrade-for-edit';
import { getProductUpgradeForEdit$Plain } from '../fn/product-upgrades/get-product-upgrade-for-edit-plain';
import { GetProductUpgradeForEdit$Plain$Params } from '../fn/product-upgrades/get-product-upgrade-for-edit-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { saveProductUpgrades } from '../fn/product-upgrades/save-product-upgrades';
import { SaveProductUpgrades$Params } from '../fn/product-upgrades/save-product-upgrades';
import { saveProductUpgrades$Plain } from '../fn/product-upgrades/save-product-upgrades-plain';
import { SaveProductUpgrades$Plain$Params } from '../fn/product-upgrades/save-product-upgrades-plain';
import { searchEquipmentProductUpgradeDetails } from '../fn/product-upgrades/search-equipment-product-upgrade-details';
import { SearchEquipmentProductUpgradeDetails$Params } from '../fn/product-upgrades/search-equipment-product-upgrade-details';
import { searchEquipmentProductUpgradeDetails$Plain } from '../fn/product-upgrades/search-equipment-product-upgrade-details-plain';
import { SearchEquipmentProductUpgradeDetails$Plain$Params } from '../fn/product-upgrades/search-equipment-product-upgrade-details-plain';
import { searchEquipmentProductUpgrades } from '../fn/product-upgrades/search-equipment-product-upgrades';
import { SearchEquipmentProductUpgrades$Params } from '../fn/product-upgrades/search-equipment-product-upgrades';
import { searchEquipmentProductUpgrades$Plain } from '../fn/product-upgrades/search-equipment-product-upgrades-plain';
import { SearchEquipmentProductUpgrades$Plain$Params } from '../fn/product-upgrades/search-equipment-product-upgrades-plain';
import { SearchEquipmentUpgradeResultItemVm } from '../models/search-equipment-upgrade-result-item-vm';
import { SearchEquipmentUpgradeResultItemVmSearchResponse } from '../models/search-equipment-upgrade-result-item-vm-search-response';
import { searchProductUpgrades } from '../fn/product-upgrades/search-product-upgrades';
import { SearchProductUpgrades$Params } from '../fn/product-upgrades/search-product-upgrades';
import { searchProductUpgrades$Plain } from '../fn/product-upgrades/search-product-upgrades-plain';
import { SearchProductUpgrades$Plain$Params } from '../fn/product-upgrades/search-product-upgrades-plain';

@Injectable({ providedIn: 'root' })
export class ProductUpgradesApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `productUpgradesControllerSearchProductUpgradesoptions()` */
  static readonly ProductUpgradesControllerSearchProductUpgradesoptionsPath = '/productupgrades/get/productupgrades';

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchProductUpgrades$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProductUpgrades$Plain$Response(params?: SearchProductUpgrades$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchProductUpgrades$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchProductUpgrades$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProductUpgrades$Plain(params?: SearchProductUpgrades$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchProductUpgrades$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchProductUpgrades()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProductUpgrades$Response(params?: SearchProductUpgrades$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchProductUpgrades(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchProductUpgrades$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProductUpgrades(params?: SearchProductUpgrades$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchProductUpgrades$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `productUpgradesControllerSearchEquipmentProductUpgradesrequest()` */
  static readonly ProductUpgradesControllerSearchEquipmentProductUpgradesrequestPath = '/productupgrades/get/equipmentproductupgrades';

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentProductUpgrades$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentProductUpgrades$Plain$Response(params?: SearchEquipmentProductUpgrades$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEquipmentUpgradeResultItemVmSearchResponse>> {
    return searchEquipmentProductUpgrades$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentProductUpgrades$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentProductUpgrades$Plain(params?: SearchEquipmentProductUpgrades$Plain$Params, context?: HttpContext): Observable<SearchEquipmentUpgradeResultItemVmSearchResponse> {
    return this.searchEquipmentProductUpgrades$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchEquipmentUpgradeResultItemVmSearchResponse>): SearchEquipmentUpgradeResultItemVmSearchResponse => r.body)
    );
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentProductUpgrades()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentProductUpgrades$Response(params?: SearchEquipmentProductUpgrades$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEquipmentUpgradeResultItemVmSearchResponse>> {
    return searchEquipmentProductUpgrades(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentProductUpgrades$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentProductUpgrades(params?: SearchEquipmentProductUpgrades$Params, context?: HttpContext): Observable<SearchEquipmentUpgradeResultItemVmSearchResponse> {
    return this.searchEquipmentProductUpgrades$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchEquipmentUpgradeResultItemVmSearchResponse>): SearchEquipmentUpgradeResultItemVmSearchResponse => r.body)
    );
  }

  /** Path part for operation `productUpgradesControllerSearchEquipmentProductUpgradeDetailsrequest()` */
  static readonly ProductUpgradesControllerSearchEquipmentProductUpgradeDetailsrequestPath = '/productupgrades/get/upgradedetails';

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentProductUpgradeDetails$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentProductUpgradeDetails$Plain$Response(params?: SearchEquipmentProductUpgradeDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEquipmentUpgradeResultItemVm>> {
    return searchEquipmentProductUpgradeDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentProductUpgradeDetails$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentProductUpgradeDetails$Plain(params?: SearchEquipmentProductUpgradeDetails$Plain$Params, context?: HttpContext): Observable<SearchEquipmentUpgradeResultItemVm> {
    return this.searchEquipmentProductUpgradeDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchEquipmentUpgradeResultItemVm>): SearchEquipmentUpgradeResultItemVm => r.body)
    );
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentProductUpgradeDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentProductUpgradeDetails$Response(params?: SearchEquipmentProductUpgradeDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEquipmentUpgradeResultItemVm>> {
    return searchEquipmentProductUpgradeDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentProductUpgradeDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentProductUpgradeDetails(params?: SearchEquipmentProductUpgradeDetails$Params, context?: HttpContext): Observable<SearchEquipmentUpgradeResultItemVm> {
    return this.searchEquipmentProductUpgradeDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchEquipmentUpgradeResultItemVm>): SearchEquipmentUpgradeResultItemVm => r.body)
    );
  }

  /** Path part for operation `productUpgradesControllerGetProductUpgradeForEditupgradeId()` */
  static readonly ProductUpgradesControllerGetProductUpgradeForEditupgradeIdPath = '/productupgrades/get/{upgradeId}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductUpgradeForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductUpgradeForEdit$Plain$Response(params: GetProductUpgradeForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentUpgrade>> {
    return getProductUpgradeForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductUpgradeForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductUpgradeForEdit$Plain(params: GetProductUpgradeForEdit$Plain$Params, context?: HttpContext): Observable<EquipmentUpgrade> {
    return this.getProductUpgradeForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentUpgrade>): EquipmentUpgrade => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductUpgradeForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductUpgradeForEdit$Response(params: GetProductUpgradeForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentUpgrade>> {
    return getProductUpgradeForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductUpgradeForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductUpgradeForEdit(params: GetProductUpgradeForEdit$Params, context?: HttpContext): Observable<EquipmentUpgrade> {
    return this.getProductUpgradeForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentUpgrade>): EquipmentUpgrade => r.body)
    );
  }

  /** Path part for operation `productUpgradesControllerSaveProductUpgradesproductUpgrade()` */
  static readonly ProductUpgradesControllerSaveProductUpgradesproductUpgradePath = '/productupgrades/saveproductupgrades';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProductUpgrades$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductUpgrades$Plain$Response(params?: SaveProductUpgrades$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentUpgrade>>> {
    return saveProductUpgrades$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveProductUpgrades$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductUpgrades$Plain(params?: SaveProductUpgrades$Plain$Params, context?: HttpContext): Observable<Array<EquipmentUpgrade>> {
    return this.saveProductUpgrades$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentUpgrade>>): Array<EquipmentUpgrade> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProductUpgrades()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductUpgrades$Response(params?: SaveProductUpgrades$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentUpgrade>>> {
    return saveProductUpgrades(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveProductUpgrades$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveProductUpgrades(params?: SaveProductUpgrades$Params, context?: HttpContext): Observable<Array<EquipmentUpgrade>> {
    return this.saveProductUpgrades$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentUpgrade>>): Array<EquipmentUpgrade> => r.body)
    );
  }

}
