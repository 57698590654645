import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public sidebarScroll: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  public sidebarScrollTrigger(scroll: boolean): void {
    this.sidebarScroll.next(scroll);
  }

}
