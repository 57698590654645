import { allPermissionPolicies } from "root/all-permission-policies";
import { MySandvikNavigationItem } from "../navigation-types";

export const NAVITEMS_MYFLEET: MySandvikNavigationItem = {
  resourceKey: 'Fleet_Title',
  sandvikIconName: 'hub',
  showIfAnyChildren: true,
  automatedTestId: 'sidebar-myfleet',
  route: {
    path: ['myfleet'],
    exactMatch: true
  },
  children: [
    {
      resourceKey: 'MyFleet_Machines',
      route: { path: ['machines']},
      permissionPolicy: allPermissionPolicies.myfleet,
      children: [
        {
          route: { path: ['location'] },
          resourceKey: 'MyFleet_Details_Location_Title',
          uniqueId: 'myfleet/machine/location'
        },
        {
          route: { path: ['bulletins'] },
          resourceKey: 'MyFleet_Details_Bulletins_Title',
          uniqueId: 'myfleet/machine/bulletins',
          automatedTestId: 'sidebar-myfleet-machines',
        },
        {
          route: { path: ['manuals'] },
          resourceKey: 'MyFleet_Details_Manuals_Title',
          uniqueId: 'myfleet/machine/manuals'
        },
        {
          route: { path: ['reports'] },
          resourceKey: 'Reports_Reports',
          uniqueId: 'myfleet/machine/reports'
        },
        {
          route: { path: ['fleetdocuments'] },
          automatedTestId: 'sidebar-myfleet-documents',
          resourceKey: 'Global_Machine_Documents',
          uniqueId: 'myfleet/machine/fleetdocuments',
          permissionPolicy: allPermissionPolicies.canSeeFleetDocuments
        },
        {
          route: { path: ['kitfinder'] },
          resourceKey: 'KitFinder_Title',
          automatedTestId: 'sidebar-myfleet-kitfinder',
          uniqueId: 'myfleet/machine/kitfinder',
          permissionPolicy: allPermissionPolicies.canSeeShopMenu,
        },
        {
          route: { path: ['warranty'] },
          resourceKey: 'Warranty_Registration_Card_Title',
          uniqueId: 'myfleet/machine/warranty',
        },
        {
          route: { path: ['upgrades'] },
          resourceKey: 'MyFleet_ProductUpgrade_Title',
          uniqueId: 'myfleet/machine/upgrades',
          permissionPolicy: allPermissionPolicies.canViewMyFleetMachineUpgrade,
          children:[
            {
              route: { path: [':id'] },
              uniqueId: 'myfleet/machine/upgrades/:id',
              resourceKey: 'MyFleet_ProductUpgrade_Details',
              permissionPolicy: allPermissionPolicies.canViewMyFleetMachineUpgrade
            },
          ]
        }
      ]
    },
    {
      resourceKey: 'PartsCatalogue_Title',
      permissionPolicy: allPermissionPolicies.canSeeElectronicManuals,
      automatedTestId: 'sidebar-electronic-catalogue',
      route: {
        path: ['electronic-manuals']
      },
      children: [
        {
          resourceKey: 'Global_Details',
          permissionPolicy: allPermissionPolicies.canSeeElectronicManuals,
          automatedTestId: 'sidebar-electronic-catalogue-details',
          uniqueId: 'myfleet/electronic-manuals/details',
          route: {
            path: [':identifier']
          }
        },
        {
          resourceKey: 'ElectronicManuals_RelatedMedia',
          permissionPolicy: allPermissionPolicies.canSeeElectronicManuals,
          automatedTestId: 'sidebar-electronic-catalogue-related',
          uniqueId: 'myfleet/electronic-manuals/related',
          route: {
            path: [':mediaId']
          }
        },
        {
          resourceKey: 'ElectronicManuals_FilterCardRecentlyViewed_Title',
          permissionPolicy: allPermissionPolicies.canSeeElectronicManuals,
          automatedTestId: 'sidebar-electronic-catalogue-recentlyviewed',
          uniqueId: 'myfleet/electronic-manuals/recentlyviewed',
          route: {
            path: ['recentlyviewed']
          }
        },
        {
          resourceKey: 'ElectronicManuals_FilterCardCategory_Title',
          permissionPolicy: allPermissionPolicies.canSeeElectronicManuals,
          automatedTestId: 'sidebar-electronic-catalogue-category',
          uniqueId: 'myfleet/electronic-manuals/category',
          route: {
            path: ['category']
          }
        },
        {
          resourceKey: 'ElectronicManuals_FilterCardModelGroup_Title',
          permissionPolicy: allPermissionPolicies.canSeeElectronicManuals,
          automatedTestId: 'sidebar-electronic-catalogue-modelgroup',
          uniqueId: 'myfleet/electronic-manuals/modelgroup',
          route: {
            path: ['modelgroup']
          }
        },
      ]
    },

    {
      resourceKey: 'DownloadedManuals_PageTitle',
      permissionPolicy: allPermissionPolicies.canDownloadManuals,
      automatedTestId: 'sidebar-myfleet-downloaded-manuals',
      route: {
        path: ['downloaded-manuals']
      }
    },
    {
      resourceKey: 'Global_FleetDocuments',
      route: {path: ['fleetdocuments']},
      permissionPolicy: allPermissionPolicies.canSeeFleetDocuments,
      children: [
        {
          resourceKey: 'Campaign_Add_Title',
          route: {path: ['add']},
          permissionPolicy: allPermissionPolicies.canManageFleetDocuments
        },
        {
          resourceKey: 'Campaign_Campaigns_Edit',
          route: {path:['edit']}
        }
      ]
    },
    {
      route: { path: ['location'] },
      resourceKey: 'Location_Title',
      automatedTestId: 'sidebar-myfleet-location',
      uniqueId: 'myfleet/location',
      permissionPolicy: allPermissionPolicies.myfleet,
    },
    {
      route: { path: ['maintenance-planner'] },
      resourceKey: 'MaintenanceKitCalendar_Title',
      automatedTestId: 'sidebar-myfleet-maintenance-planner',
      uniqueId: 'myfleet/maintenance-planner',
      permissionPolicy: allPermissionPolicies.canViewMaintenancePlanner,
    },
    {
      route: { path: ['machine-orders'] },
      resourceKey: 'MachineOrders_Title',
      automatedTestId: 'sidebar-myfleet-machine-order',
      uniqueId: 'myfleet/machine-orders',
      permissionPolicy: allPermissionPolicies.machineOrder,
    },
    {
      route: { path: ['tdmodels'] },
      text: '3D Models (Preview)',
      uniqueId: 'myfleet/tdmodels',
      permissionPolicy: allPermissionPolicies.myFleetfleet3dmodels,
      children: [
        {
          route: {path: [':modelName'] },
          text: '3D Model',
          uniqueId: 'myfleet/tdmodels/view',
          permissionPolicy: allPermissionPolicies.myFleetfleet3dmodels
        }
      ]
    },
  ]
};


