import { FrontendContextService } from '../../services/front-end-context/front-end-context.service';
import { LocalizationService } from '../../services/localization/localization.service';
import { OnInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[ecommerceRouterLink]'
})

export class EcommerceRouterLinkDirective implements OnInit, OnDestroy {
  el: HTMLElement;
  mibpRouterLinkLang: string[];
  localizationSubscription: Subscription;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ecommerceRouterLink')
  set ecommerceRouterLink(route: string[]) {
    this.mibpRouterLinkLang = route;
    this.updateHrefAttr();
  }

  constructor(ref: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private frontendContext: FrontendContextService,
    private localizationService: LocalizationService) {
    this.el = ref.nativeElement;
  }

  private createRouteWithLanguage(languageCode: string) {
    const newRoute: string[] = [];
    if (this.mibpRouterLinkLang) {
      const oldRoute = this.mibpRouterLinkLang.slice(0);
      oldRoute.forEach(p => newRoute.push(p.replace(':lang', languageCode)));
    }
    return newRoute;
  }

  private updateHrefAttr() {
    if (this.localizationService.getLang()) {
      const anchorUrl = this.createRouteWithLanguage(this.localizationService.getLang()).join('/');
      this.el.setAttribute('href', anchorUrl);
    }
  }

  ngOnInit() {

    this.localizationService.Locale$.subscribe(() => {
      this.updateHrefAttr();
    });

    this.el.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();

      if (!this.mibpRouterLinkLang || this.mibpRouterLinkLang.filter((f: string) => f).length === 0) {
        return;
      }

      const langs = this.route.pathFromRoot.map(i => i.params['value'] && i.params['value']['lang']).filter(f => f);

      if (langs.length > 0) {
        const newRoute = this.createRouteWithLanguage(this.localizationService.getLang());
        if (newRoute[0].startsWith("http")) {
          window.open(newRoute[0]);
          return;
        }

        this.router.navigateByUrl(newRoute.toString());
      }

    });
  }
  ngOnDestroy() {
    if (this.localizationSubscription) {
      this.localizationSubscription.unsubscribe();
    }
  }

}
