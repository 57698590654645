
<div class="deliversequence-picker" [formGroup]="actAsForm">
  <div class="row">
    <div class="col-12" *ngIf="writeValueError">
      <mibp-system-message [messageType]="msg.Types.Error" #msg>
        <mibp-resource-string key="Global_Error_CouldNotLoadDeliverySequence"></mibp-resource-string>
      </mibp-system-message>
    </div>
    <div class="col-12" [testid]="'ds-picker-company'">
        <label><mibp-resource-string key="Global_Company"></mibp-resource-string><span *ngIf="isRequired" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
        <mibp-dropdown
          #companyDropdown
          [disabled]="isDisabled"
          [loading]="showCompanyLoader"
          formControlName="companyDropdown"
          (filter)="onFilterCompanies($event)"
          (valueChange)="onChangeCompany($event)"
          placeholder="ActAs_CompanyCode_Placeholder"
          (dropdownShow)="onDropdownShow()"
          (dropdownHide)="onDropdownHide()"
          [data]="companies$"
        ></mibp-dropdown>
    </div>
  </div>
  <div class="row" [hidden]="hideUnavailableElements && hideCustomerDropdown" [testid]="'ds-picker-customer'">
    <div class="col-12">
        <label><mibp-resource-string key="Global_Customer"></mibp-resource-string><span *ngIf="isRequired" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
        <mibp-dropdown
        [disabled]="isDisabled"
          [loading]="showCustomerLoader"
          formControlName="customerDropdown"
          (filter)="onFilterCustomers($event)"
          (valueChange)="onChangeCustomer()"
          placeholder="ActAs_CustomerNumber_Placeholder"
          (dropdownShow)="onDropdownShow()"
          (dropdownHide)="onDropdownHide()"
          [data]="customers$"
        ></mibp-dropdown>
    </div>
  </div>
  <div class="row mb-0" [hidden]="hideUnavailableElements && hideDeliverySequenceDropdown" [testid]="'ds-picker-dlv'">
    <div class="col-12">
        <label><mibp-resource-string key="Global_DeliverySequence"></mibp-resource-string><span *ngIf="isRequired" class="required">(<mibp-resource-string key="Global_Required"></mibp-resource-string>)</span></label>
        <mibp-dropdown
          #dlvDdl
          [disabled]="isDisabled"
          [loading]="showDeliverySequenceLoader"
          formControlName="deliverySequenceDropdown"
          (filter)="onFilterDeliverySequences($event)"
          (valueChange)="onChangeDeliverySequence($event)"
          placeholder="ActAs_DeliverySequence_Placeholder"
          (dropdownShow)="onDropdownShow()"
          (dropdownHide)="onDropdownHide()"
          [data]="deliverySequences$"
        ></mibp-dropdown>
    </div>
  </div>
  <!-- <div class="row buttonrow">
    <div title="Form debug details" style="margin: 10px;" class="col-md-12 col-xs-12">
      <p>FORM: <strong>{{actAsForm.status}}</strong></p>
      <p>COMPANY: {{actAsForm.get('companyDropdown').status}}: <strong>{{actAsForm.get('companyDropdown').value?.value}}</strong></p>
      <p>CUSTOMER: {{actAsForm.get('customerDropdown').status}}: <strong>{{actAsForm.get('customerDropdown').value?.value}}</strong></p>
      <p>DELIVERY SEQUENCE: {{actAsForm.get('deliverySequenceDropdown').status}}: <strong>{{actAsForm.get('deliverySequenceDropdown').value?.value}}</strong></p>
    </div>
  </div> -->
</div>
