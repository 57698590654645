/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MibpLogSearchRequestVm } from '../../models/mibp-log-search-request-vm';
import { MibpLogSearchResultVm } from '../../models/mibp-log-search-result-vm';
import { MibpLogType } from '../../models/mibp-log-type';

export interface SearchLogs$Plain$Params {
  logType: MibpLogType;
      body?: MibpLogSearchRequestVm
}

export function searchLogs$Plain(http: HttpClient, rootUrl: string, params: SearchLogs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpLogSearchResultVm>> {
  const rb = new RequestBuilder(rootUrl, searchLogs$Plain.PATH, 'post');
  if (params) {
    rb.path('logType', params.logType, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MibpLogSearchResultVm>;
    })
  );
}

searchLogs$Plain.PATH = '/mibplog/search/{logType}';
