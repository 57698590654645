<ng-template #offlinePageTemplate let-name>
  <div class="modal is-active" [style.zIndex]="zIndex">
    <div class="modal-background"></div>
    <div class="modal-card offline-card" [class.fullheight]="fullheight">
      <img style="margin-bottom: 30px;" src="assets/images/logo_black.svg" width="130" alt="[Sandvik Logotype]">
      <h3 class="my-header"><mibp-resource-string key="Internet_Connection_Error"></mibp-resource-string></h3>
      <p><mibp-resource-string key="Internet_Connection_Error_Description"></mibp-resource-string></p>
      <button style="margin-top: 50px;" class="my-button my-button--fill btn-min-widht" (click)="tryAgain()"><mibp-resource-string key="Global_Action_TryAgain"></mibp-resource-string></button>
    </div>
  </div>
</ng-template>

