<mibp-loader *ngIf="isLoading;else organizationList"></mibp-loader>

<ng-template #organizationList>
  <div *ngIf="totalItems!==0">
    <div class="columns orgrow" *ngFor="let ag of accessgroups">
      <div class="column">{{ag.name}}</div>
      <div class="column buttonrow">
        <mibp-button #btn [icon]="btn.Enum.Icons.Plus" (click)="selectedOrg(ag.id, ag.name)"></mibp-button>
      </div>
    </div>
  </div>
  <div *ngIf="totalItems===0">
    <div><mibp-resource-string key="Organization_Not_Match_Text"></mibp-resource-string></div>
  </div>
  <div class="columns">
    <div class="column pagerrow">
      <mibp-paginator [totalItems]="totalItems" [pageSize]="pageSize" (changePage)="pageChanged($event)"></mibp-paginator>
    </div>
  </div>
</ng-template>


