/* eslint-disable @angular-eslint/no-output-native */
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { SignalR_ApiResponseGeneric } from "root/services";

  @Component({
    selector: 'mibp-data-view',
    templateUrl: './data-view.component.html'
  })
export class MibpDataViewComponent implements OnInit, OnDestroy {

  hasReceivedResponse = false;

    @Input()
  set bind(obs: Observable<SignalR_ApiResponseGeneric<any>>) {
    this.data = null;
    this.errorData = null;
    this.hasReceivedResponse = false;
    if (!obs) {

      return;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = obs.subscribe({
      next: apiResponse => {
        this.fullResponse = apiResponse;
        if (apiResponse !== null && typeof apiResponse.data !== 'undefined') {
          this.data = apiResponse.data;
        } else {
          // If data has been mapped, we don't want to require it to have the ApiResponse structure
          this.data = apiResponse;
        }
        this.hasReceivedResponse = true;
        this.errorData = null;
        this.success.emit(this.data);
        this.ready.emit(this.data);
      },
      error: err => {
        err.silent = true;
        this.hasReceivedResponse = true;
        this.data = null;
        this.errorData = err.error;
        this.error.emit(err);
        this.ready.emit(err);

        // We re-throw this error but with the silent flag to hide the message
        throw err;
      }
    });
  }
    @Input() debug: boolean;

    // TODO: Rename events to non-native names
    @Output() success = new EventEmitter<any>();
    @Output() error = new EventEmitter<any>();
    @Output() ready = new EventEmitter<any>();

    data;
    errorData;
    fullResponse;

    subscription;

    ngOnInit() {

    }

    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

}
