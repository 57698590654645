import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit } from "@angular/core";

/**
 * Will try to resize the element to keep a specific aspect ratio
 */
@Directive({
  selector: '[mibpAspectRatio]'
})
export class MibpAspectRatioDirective implements AfterViewInit {

  private ratioWidth: number;
  private ratioHeight: number;
  private theRatio: number;

  constructor(private elementRef: ElementRef<HTMLElement>) {}
  ngAfterViewInit(): void {
    this.resize();
  }

  @Input()
  set mibpAspectRatio(value: number[]) {
    if (value?.length == 2) {
      this.ratioWidth = value[0];
      this.ratioHeight = value[1];
      this.theRatio = this.ratioHeight / this.ratioWidth;
    } else {
      this.theRatio = null;
    }
  }

  @HostListener('resize')
  onChange(): void {
    setTimeout(() => {
      this.resize();
    }, 100);
  }

  resize(doNotRepeat = false): void {

    if (this.theRatio && this.elementRef && this.elementRef.nativeElement) {
      const elementWidth = this.elementRef.nativeElement.getBoundingClientRect().width;
      if (elementWidth > 0) {
        if (this.elementRef.nativeElement.style.height != `${elementWidth * this.theRatio}px`) {
          this.elementRef.nativeElement.style.height = `${elementWidth * this.theRatio}px`;
        }
      }

      if (!doNotRepeat) {
        setTimeout( () => {
          this.resize(true);
        }, 50);
      }

    }

  }



}
