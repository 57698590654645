/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AddItemToCartSource } from '../../models/add-item-to-cart-source';
import { AddProductDto } from '../../models/add-product-dto';
import { CartAddProductsResponseViewModel } from '../../models/cart-add-products-response-view-model';

export interface AddProducts$Params {
  source?: AddItemToCartSource;
  cartId?: string;
  currentDatePattern?: string;
  dateTimeOffsetMinutes?: number;
      body?: Array<AddProductDto>
}

export function addProducts(http: HttpClient, rootUrl: string, params?: AddProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<CartAddProductsResponseViewModel>> {
  const rb = new RequestBuilder(rootUrl, addProducts.PATH, 'post');
  if (params) {
    rb.query('source', params.source, {});
    rb.query('cartId', params.cartId, {});
    rb.query('currentDatePattern', params.currentDatePattern, {});
    rb.query('dateTimeOffsetMinutes', params.dateTimeOffsetMinutes, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CartAddProductsResponseViewModel>;
    })
  );
}

addProducts.PATH = '/carts/addproducts';
