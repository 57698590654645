import { MibpHttpApi } from './mibp-http-api.service';
import { map } from 'rxjs/operators';

/**
 * Provides an API And for fetching large kits that are too big for SignalR
 */
export class BulletinsHttpService {

  constructor(private httpApi: MibpHttpApi) {}

}
