/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReportFilterResponseViewModel } from '../../models/report-filter-response-view-model';

export interface GetCustomerFilterData$Params {
  From?: string;
  To?: string;
  SalesAreas?: Array<number>;
  Status?: Array<number>;
  OnBoardedUsersOnly?: boolean;
  IncludeEndUsers?: boolean;
  IncludeDistributors?: boolean;
  Skip?: number;
  Take?: number;
  FetchRecent?: boolean;
}

export function getCustomerFilterData(http: HttpClient, rootUrl: string, params?: GetCustomerFilterData$Params, context?: HttpContext): Observable<StrictHttpResponse<ReportFilterResponseViewModel>> {
  const rb = new RequestBuilder(rootUrl, getCustomerFilterData.PATH, 'get');
  if (params) {
    rb.query('From', params.From, {});
    rb.query('To', params.To, {});
    rb.query('SalesAreas', params.SalesAreas, {});
    rb.query('Status', params.Status, {});
    rb.query('OnBoardedUsersOnly', params.OnBoardedUsersOnly, {});
    rb.query('IncludeEndUsers', params.IncludeEndUsers, {});
    rb.query('IncludeDistributors', params.IncludeDistributors, {});
    rb.query('Skip', params.Skip, {});
    rb.query('Take', params.Take, {});
    rb.query('FetchRecent', params.FetchRecent, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ReportFilterResponseViewModel>;
    })
  );
}

getCustomerFilterData.PATH = '/internalreports/customerreportfilter';
