import { Component, Input } from "@angular/core";

@Component({
  selector: 'mibp-progress',
  styleUrls: ['./progress.component.scss'],
  templateUrl: './progress.component.html'
})
export class ProgressComponent {
  @Input() oldLook: boolean;
  @Input() steps: string[];
  @Input() step = 0;
  @Input() hideTopBorder = false;

}
