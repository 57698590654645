import { OnboardingGuideStep } from './onboarding.types';
import { MibpOnboardingComponent } from './onboarding.component';
import { Injectable } from "@angular/core";
import { OnboardingGuideSettings, OnboardingResult } from '.';

@Injectable({
  providedIn: 'root'
})
export class MibpOnboardingService {

  private globalComponent: MibpOnboardingComponent;

  public registerGlobalComponent(component: MibpOnboardingComponent): void {
    this.globalComponent = component;
  }

  public startGuide(settings: OnboardingGuideSettings): Promise<OnboardingResult> {

    if (!this.globalComponent) {
      throw new Error("Onboarding Global Component is not registered");
    }

    settings.steps = this.prepareSteps(settings.steps);

    return this.globalComponent.startGuide(settings);
  }

  /**
   * Make sure we only include the steps that are relevant (visible on page)
   *
   * @private
   */
  private prepareSteps(steps: OnboardingGuideStep[]): OnboardingGuideStep[] {
    return steps.filter(s => {
      if (document.querySelector(s.elementSelector)) {
        return s;
      }
    });
  }

}
