import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BroadcastService } from 'root/services';
import { Subscription } from "rxjs";
import { ParsedApiError, ParsedErrorData } from './../../services/api-error-handler/api-error-handler';


@Component({
  selector: 'mibp-page-load-error',

  template: `
  <mibp-system-message [messageType]="msg.Types.Error" #msg>
    <h2><mibp-resource-string key="PageLoadError_Title"></mibp-resource-string></h2>
    <div class="my-body">
      <mibp-resource-string key="PageLoadError_Message" [macros]="{ CorrelationId: clientId }" [isHtml]="true"></mibp-resource-string>
      <ng-content></ng-content>
    </div>
</mibp-system-message>
  `
})
export class MibpGeneralErrorComponent implements OnInit, OnDestroy {

  clientSubscription?: Subscription;

  /**
   * ClientID is logged in all logrows when available.
   * This is used as correlation id so it's easier to find the error in the logs
   */
  clientId?: string;

  constructor(private broadcast: BroadcastService) {}

  ngOnDestroy(): void {
    this.clientSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.clientSubscription = this.broadcast.clientId.subscribe(clientId => this.onClientIdUpdate(clientId));
  }

  private onClientIdUpdate(clientId: string): void {
    this.clientId = clientId;
  }

}
