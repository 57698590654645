/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addTemplateToCart } from '../fn/templates/add-template-to-cart';
import { AddTemplateToCart$Params } from '../fn/templates/add-template-to-cart';
import { addTemplateToCart$Plain } from '../fn/templates/add-template-to-cart-plain';
import { AddTemplateToCart$Plain$Params } from '../fn/templates/add-template-to-cart-plain';
import { CartAddProductsResponseViewModel } from '../models/cart-add-products-response-view-model';
import { CartForListViewModelPagedResponse } from '../models/cart-for-list-view-model-paged-response';
import { getTemplates } from '../fn/templates/get-templates';
import { GetTemplates$Params } from '../fn/templates/get-templates';
import { getTemplates$Plain } from '../fn/templates/get-templates-plain';
import { GetTemplates$Plain$Params } from '../fn/templates/get-templates-plain';
import { getTotalTemplateCount } from '../fn/templates/get-total-template-count';
import { GetTotalTemplateCount$Params } from '../fn/templates/get-total-template-count';
import { getTotalTemplateCount$Plain } from '../fn/templates/get-total-template-count-plain';
import { GetTotalTemplateCount$Plain$Params } from '../fn/templates/get-total-template-count-plain';
import { TemplateCountViewModel } from '../models/template-count-view-model';

@Injectable({ providedIn: 'root' })
export class TemplatesApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `templatesControllerAddTemplateToCarttemplateCartId()` */
  static readonly TemplatesControllerAddTemplateToCarttemplateCartIdPath = '/templates/{templateCartId}/addtocart';

  /**
   * Add the specified template to the active cart.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTemplateToCart$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTemplateToCart$Plain$Response(params: AddTemplateToCart$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartAddProductsResponseViewModel>> {
    return addTemplateToCart$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Add the specified template to the active cart.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTemplateToCart$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTemplateToCart$Plain(params: AddTemplateToCart$Plain$Params, context?: HttpContext): Observable<CartAddProductsResponseViewModel> {
    return this.addTemplateToCart$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartAddProductsResponseViewModel>): CartAddProductsResponseViewModel => r.body)
    );
  }

  /**
   * Add the specified template to the active cart.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTemplateToCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTemplateToCart$Response(params: AddTemplateToCart$Params, context?: HttpContext): Observable<StrictHttpResponse<CartAddProductsResponseViewModel>> {
    return addTemplateToCart(this.http, this.rootUrl, params, context);
  }

  /**
   * Add the specified template to the active cart.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTemplateToCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addTemplateToCart(params: AddTemplateToCart$Params, context?: HttpContext): Observable<CartAddProductsResponseViewModel> {
    return this.addTemplateToCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartAddProductsResponseViewModel>): CartAddProductsResponseViewModel => r.body)
    );
  }

  /** Path part for operation `templatesControllerGetTotalTemplateCount()` */
  static readonly TemplatesControllerGetTotalTemplateCountPath = '/templates/templatecount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTotalTemplateCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalTemplateCount$Plain$Response(params?: GetTotalTemplateCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateCountViewModel>> {
    return getTotalTemplateCount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTotalTemplateCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalTemplateCount$Plain(params?: GetTotalTemplateCount$Plain$Params, context?: HttpContext): Observable<TemplateCountViewModel> {
    return this.getTotalTemplateCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateCountViewModel>): TemplateCountViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTotalTemplateCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalTemplateCount$Response(params?: GetTotalTemplateCount$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateCountViewModel>> {
    return getTotalTemplateCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTotalTemplateCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalTemplateCount(params?: GetTotalTemplateCount$Params, context?: HttpContext): Observable<TemplateCountViewModel> {
    return this.getTotalTemplateCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateCountViewModel>): TemplateCountViewModel => r.body)
    );
  }

  /** Path part for operation `templatesControllerGetTemplatesrequest()` */
  static readonly TemplatesControllerGetTemplatesrequestPath = '/templates/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplates$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTemplates$Plain$Response(params?: GetTemplates$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartForListViewModelPagedResponse>> {
    return getTemplates$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTemplates$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTemplates$Plain(params?: GetTemplates$Plain$Params, context?: HttpContext): Observable<CartForListViewModelPagedResponse> {
    return this.getTemplates$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartForListViewModelPagedResponse>): CartForListViewModelPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplates()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTemplates$Response(params?: GetTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<CartForListViewModelPagedResponse>> {
    return getTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTemplates$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTemplates(params?: GetTemplates$Params, context?: HttpContext): Observable<CartForListViewModelPagedResponse> {
    return this.getTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartForListViewModelPagedResponse>): CartForListViewModelPagedResponse => r.body)
    );
  }

}
