/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FeatureForEditingVm } from '../../models/feature-for-editing-vm';
import { FeatureLevel } from '../../models/feature-level';

export interface GetFeaturesForEdit$Params {
  level: FeatureLevel;
  intRefId?: number;
  guidRefId?: string;
  forInternalUser?: boolean;
  roleId?: number;
  orgId?: string;
}

export function getFeaturesForEdit(http: HttpClient, rootUrl: string, params: GetFeaturesForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeatureForEditingVm>>> {
  const rb = new RequestBuilder(rootUrl, getFeaturesForEdit.PATH, 'get');
  if (params) {
    rb.path('level', params.level, {});
    rb.query('intRefId', params.intRefId, {});
    rb.query('guidRefId', params.guidRefId, {});
    rb.query('forInternalUser', params.forInternalUser, {});
    rb.query('roleId', params.roleId, {});
    rb.query('orgId', params.orgId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<FeatureForEditingVm>>;
    })
  );
}

getFeaturesForEdit.PATH = '/feature/{level}/foredit';
