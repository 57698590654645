
<mibp-dialog #dialog  [buttons]="dialogButtons" disableEscButton="true" hideCloseButton="true" (buttonClick)="onDialogButton($event)">
    <mibp-loader *ngIf="!hasError && isDialogLoading"></mibp-loader>

    <mibp-system-message *ngIf="hasError" class="m-4" #msg [messageType]="msg.Types.Warning">
      <p><mibp-resource-string key="Global_Error_NotFound"></mibp-resource-string></p>
    </mibp-system-message>

    <div class="printButton" *ngIf="!hasError && !isDialogLoading">
      <mibp-button [icon]="butonPrintIcon" (click)="print()"></mibp-button>
    </div>

    <div *ngIf="!isDialogLoading && !hasError" [innerHTML]="termsAndConditionsHtml"></div>
</mibp-dialog>
<mibp-pdf-render *ngIf="showNewgeneralTerms" #pdfviewer [userFileId]="generalTermsId" [dialogButtons]="dialogButtons" (buttonClicked)="onNewTermsDialogButton($event)" [url]="generalTermsURL">
</mibp-pdf-render>
<ng-content *ngIf="isTermsAccepted"></ng-content>
<mibp-loader *ngIf="!isTermsAccepted"></mibp-loader>
