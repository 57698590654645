/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SearchIndexDeletedInfoVm } from '../../models/search-index-deleted-info-vm';

export interface DeleteEnvironmentInstanceDocuments$Plain$Params {
  indexName: string;
  take?: number;
  environmentInstance?: string;
}

export function deleteEnvironmentInstanceDocuments$Plain(http: HttpClient, rootUrl: string, params: DeleteEnvironmentInstanceDocuments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchIndexDeletedInfoVm>> {
  const rb = new RequestBuilder(rootUrl, deleteEnvironmentInstanceDocuments$Plain.PATH, 'delete');
  if (params) {
    rb.path('indexName', params.indexName, {});
    rb.query('take', params.take, {});
    rb.query('environmentInstance', params.environmentInstance, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SearchIndexDeletedInfoVm>;
    })
  );
}

deleteEnvironmentInstanceDocuments$Plain.PATH = '/search/indexes/{indexName}/my-environmentinstance-documents';
