/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MediaFolderVm } from '../../models/media-folder-vm';

export interface GetFolderInfo$Plain$Params {
  folderId: number;
  includeAttachments?: boolean;
}

export function getFolderInfo$Plain(http: HttpClient, rootUrl: string, params: GetFolderInfo$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaFolderVm>> {
  const rb = new RequestBuilder(rootUrl, getFolderInfo$Plain.PATH, 'get');
  if (params) {
    rb.path('folderId', params.folderId, {});
    rb.query('includeAttachments', params.includeAttachments, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MediaFolderVm>;
    })
  );
}

getFolderInfo$Plain.PATH = '/billofmaterial/folders/{folderId}';
