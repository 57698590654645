
<div  *ngIf="canEdit" class="product-availability__info" (click)="showPopover(ava)" #ava>
  <mibp-equipment-status [status]="status"></mibp-equipment-status>
</div>
<div  *ngIf="!canEdit">
  <mibp-resource-string [key]="description"></mibp-resource-string>
</div>
<mibp-popover #availabilityPopover>
 <table class="properties">
  <tbody>
    <tr>
      <th><mibp-resource-string key="Global_Status"></mibp-resource-string></th>
      <td><mibp-equipment-status [status]="status"></mibp-equipment-status></td>
    </tr>
    <tr>
      <th><mibp-resource-string key="Global_Description"></mibp-resource-string></th>
      <td><mibp-resource-string [key]="description"></mibp-resource-string></td>
    </tr>
  </tbody>
 </table>
</mibp-popover>
