/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateB2CUserRequestVm } from '../../models/create-b-2-c-user-request-vm';
import { CreateB2CUserResponseVm } from '../../models/create-b-2-c-user-response-vm';

export interface CreateB2cUser$Params {
      body?: CreateB2CUserRequestVm
}

export function createB2cUser(http: HttpClient, rootUrl: string, params?: CreateB2cUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateB2CUserResponseVm>> {
  const rb = new RequestBuilder(rootUrl, createB2cUser.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CreateB2CUserResponseVm>;
    })
  );
}

createB2cUser.PATH = '/b2cusers';
