/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BulletinRecentItemViewModel } from '../models/bulletin-recent-item-view-model';
import { BulletinSeverity } from '../models/bulletin-severity';
import { getIntegrationBulletin } from '../fn/bulletin/get-integration-bulletin';
import { GetIntegrationBulletin$Params } from '../fn/bulletin/get-integration-bulletin';
import { getIntegrationBulletin$Plain } from '../fn/bulletin/get-integration-bulletin-plain';
import { GetIntegrationBulletin$Plain$Params } from '../fn/bulletin/get-integration-bulletin-plain';
import { getRestrictedProductAreasBulletin } from '../fn/bulletin/get-restricted-product-areas-bulletin';
import { GetRestrictedProductAreasBulletin$Params } from '../fn/bulletin/get-restricted-product-areas-bulletin';
import { getRestrictedProductAreasBulletin$Plain } from '../fn/bulletin/get-restricted-product-areas-bulletin-plain';
import { GetRestrictedProductAreasBulletin$Plain$Params } from '../fn/bulletin/get-restricted-product-areas-bulletin-plain';
import { gridSearchForOrganization } from '../fn/bulletin/grid-search-for-organization';
import { GridSearchForOrganization$Params } from '../fn/bulletin/grid-search-for-organization';
import { gridSearchForOrganization$Plain } from '../fn/bulletin/grid-search-for-organization-plain';
import { GridSearchForOrganization$Plain$Params } from '../fn/bulletin/grid-search-for-organization-plain';
import { IntegrationBulletin } from '../models/integration-bulletin';
import { IntegrationBulletinIndexSearchResponse } from '../models/integration-bulletin-index-search-response';
import { integrationMarkAsRead } from '../fn/bulletin/integration-mark-as-read';
import { IntegrationMarkAsRead$Params } from '../fn/bulletin/integration-mark-as-read';
import { integrationReScope } from '../fn/bulletin/integration-re-scope';
import { IntegrationReScope$Params } from '../fn/bulletin/integration-re-scope';
import { integrationReScopeAll } from '../fn/bulletin/integration-re-scope-all';
import { IntegrationReScopeAll$Params } from '../fn/bulletin/integration-re-scope-all';
import { integrationReScopeAll$Plain } from '../fn/bulletin/integration-re-scope-all-plain';
import { IntegrationReScopeAll$Plain$Params } from '../fn/bulletin/integration-re-scope-all-plain';
import { listRecent } from '../fn/bulletin/list-recent';
import { ListRecent$Params } from '../fn/bulletin/list-recent';
import { listRecent$Plain } from '../fn/bulletin/list-recent-plain';
import { ListRecent$Plain$Params } from '../fn/bulletin/list-recent-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { SalesForceBulletinProductAreas } from '../models/sales-force-bulletin-product-areas';
import { searchIntegrationBulletins } from '../fn/bulletin/search-integration-bulletins';
import { SearchIntegrationBulletins$Params } from '../fn/bulletin/search-integration-bulletins';
import { searchIntegrationBulletins$Plain } from '../fn/bulletin/search-integration-bulletins-plain';
import { SearchIntegrationBulletins$Plain$Params } from '../fn/bulletin/search-integration-bulletins-plain';
import { severities } from '../fn/bulletin/severities';
import { Severities$Params } from '../fn/bulletin/severities';
import { severities$Plain } from '../fn/bulletin/severities-plain';
import { Severities$Plain$Params } from '../fn/bulletin/severities-plain';

@Injectable({ providedIn: 'root' })
export class BulletinApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bulletinControllerSearchIntegrationBulletinsrequest()` */
  static readonly BulletinControllerSearchIntegrationBulletinsrequestPath = '/bulletin/searchbulletin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchIntegrationBulletins$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchIntegrationBulletins$Plain$Response(params?: SearchIntegrationBulletins$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<IntegrationBulletinIndexSearchResponse>> {
    return searchIntegrationBulletins$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchIntegrationBulletins$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchIntegrationBulletins$Plain(params?: SearchIntegrationBulletins$Plain$Params, context?: HttpContext): Observable<IntegrationBulletinIndexSearchResponse> {
    return this.searchIntegrationBulletins$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<IntegrationBulletinIndexSearchResponse>): IntegrationBulletinIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchIntegrationBulletins()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchIntegrationBulletins$Response(params?: SearchIntegrationBulletins$Params, context?: HttpContext): Observable<StrictHttpResponse<IntegrationBulletinIndexSearchResponse>> {
    return searchIntegrationBulletins(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchIntegrationBulletins$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchIntegrationBulletins(params?: SearchIntegrationBulletins$Params, context?: HttpContext): Observable<IntegrationBulletinIndexSearchResponse> {
    return this.searchIntegrationBulletins$Response(params, context).pipe(
      map((r: StrictHttpResponse<IntegrationBulletinIndexSearchResponse>): IntegrationBulletinIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `bulletinControllerGridSearchForOrganizationoptionsOrganizationId()` */
  static readonly BulletinControllerGridSearchForOrganizationoptionsOrganizationIdPath = '/bulletin/gridsearch/organization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain$Response(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Response(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `bulletinControllerListRecenttake()` */
  static readonly BulletinControllerListRecenttakePath = '/bulletin/recent';

  /**
   * List the top N bulletins that the user can see. Defaults to 8.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRecent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRecent$Plain$Response(params?: ListRecent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BulletinRecentItemViewModel>>> {
    return listRecent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * List the top N bulletins that the user can see. Defaults to 8.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRecent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRecent$Plain(params?: ListRecent$Plain$Params, context?: HttpContext): Observable<Array<BulletinRecentItemViewModel>> {
    return this.listRecent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BulletinRecentItemViewModel>>): Array<BulletinRecentItemViewModel> => r.body)
    );
  }

  /**
   * List the top N bulletins that the user can see. Defaults to 8.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRecent()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRecent$Response(params?: ListRecent$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BulletinRecentItemViewModel>>> {
    return listRecent(this.http, this.rootUrl, params, context);
  }

  /**
   * List the top N bulletins that the user can see. Defaults to 8.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRecent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRecent(params?: ListRecent$Params, context?: HttpContext): Observable<Array<BulletinRecentItemViewModel>> {
    return this.listRecent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BulletinRecentItemViewModel>>): Array<BulletinRecentItemViewModel> => r.body)
    );
  }

  /** Path part for operation `bulletinControllerSeverities()` */
  static readonly BulletinControllerSeveritiesPath = '/bulletin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `severities$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  severities$Plain$Response(params?: Severities$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BulletinSeverity>>> {
    return severities$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `severities$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  severities$Plain(params?: Severities$Plain$Params, context?: HttpContext): Observable<Array<BulletinSeverity>> {
    return this.severities$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BulletinSeverity>>): Array<BulletinSeverity> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `severities()` instead.
   *
   * This method doesn't expect any request body.
   */
  severities$Response(params?: Severities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BulletinSeverity>>> {
    return severities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `severities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  severities(params?: Severities$Params, context?: HttpContext): Observable<Array<BulletinSeverity>> {
    return this.severities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BulletinSeverity>>): Array<BulletinSeverity> => r.body)
    );
  }

  /** Path part for operation `bulletinControllerIntegrationMarkAsReadbidOtherReadFlag()` */
  static readonly BulletinControllerIntegrationMarkAsReadbidOtherReadFlagPath = '/bulletin/{bid}/{otherReadFlag}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationMarkAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationMarkAsRead$Response(params: IntegrationMarkAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return integrationMarkAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationMarkAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationMarkAsRead(params: IntegrationMarkAsRead$Params, context?: HttpContext): Observable<void> {
    return this.integrationMarkAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `bulletinControllerGetIntegrationBulletiniId()` */
  static readonly BulletinControllerGetIntegrationBulletiniIdPath = '/bulletin/getintegrationbulletin/{iId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationBulletin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationBulletin$Plain$Response(params: GetIntegrationBulletin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<IntegrationBulletin>> {
    return getIntegrationBulletin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationBulletin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationBulletin$Plain(params: GetIntegrationBulletin$Plain$Params, context?: HttpContext): Observable<IntegrationBulletin> {
    return this.getIntegrationBulletin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<IntegrationBulletin>): IntegrationBulletin => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationBulletin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationBulletin$Response(params: GetIntegrationBulletin$Params, context?: HttpContext): Observable<StrictHttpResponse<IntegrationBulletin>> {
    return getIntegrationBulletin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrationBulletin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationBulletin(params: GetIntegrationBulletin$Params, context?: HttpContext): Observable<IntegrationBulletin> {
    return this.getIntegrationBulletin$Response(params, context).pipe(
      map((r: StrictHttpResponse<IntegrationBulletin>): IntegrationBulletin => r.body)
    );
  }

  /** Path part for operation `bulletinControllerGetRestrictedProductAreasBulletin()` */
  static readonly BulletinControllerGetRestrictedProductAreasBulletinPath = '/bulletin/getrestrictedproductareas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRestrictedProductAreasBulletin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestrictedProductAreasBulletin$Plain$Response(params?: GetRestrictedProductAreasBulletin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SalesForceBulletinProductAreas>>> {
    return getRestrictedProductAreasBulletin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRestrictedProductAreasBulletin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestrictedProductAreasBulletin$Plain(params?: GetRestrictedProductAreasBulletin$Plain$Params, context?: HttpContext): Observable<Array<SalesForceBulletinProductAreas>> {
    return this.getRestrictedProductAreasBulletin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SalesForceBulletinProductAreas>>): Array<SalesForceBulletinProductAreas> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRestrictedProductAreasBulletin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestrictedProductAreasBulletin$Response(params?: GetRestrictedProductAreasBulletin$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SalesForceBulletinProductAreas>>> {
    return getRestrictedProductAreasBulletin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRestrictedProductAreasBulletin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestrictedProductAreasBulletin(params?: GetRestrictedProductAreasBulletin$Params, context?: HttpContext): Observable<Array<SalesForceBulletinProductAreas>> {
    return this.getRestrictedProductAreasBulletin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SalesForceBulletinProductAreas>>): Array<SalesForceBulletinProductAreas> => r.body)
    );
  }

  /** Path part for operation `bulletinControllerIntegrationReScopeAll()` */
  static readonly BulletinControllerIntegrationReScopeAllPath = '/bulletin/integrationrescopeall';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationReScopeAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationReScopeAll$Plain$Response(params?: IntegrationReScopeAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return integrationReScopeAll$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationReScopeAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationReScopeAll$Plain(params?: IntegrationReScopeAll$Plain$Params, context?: HttpContext): Observable<number> {
    return this.integrationReScopeAll$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationReScopeAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationReScopeAll$Response(params?: IntegrationReScopeAll$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return integrationReScopeAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationReScopeAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationReScopeAll(params?: IntegrationReScopeAll$Params, context?: HttpContext): Observable<number> {
    return this.integrationReScopeAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `bulletinControllerIntegrationReScopebulletinId()` */
  static readonly BulletinControllerIntegrationReScopebulletinIdPath = '/bulletin/integrationrescope/{bulletinId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `integrationReScope()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationReScope$Response(params: IntegrationReScope$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return integrationReScope(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `integrationReScope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  integrationReScope(params: IntegrationReScope$Params, context?: HttpContext): Observable<void> {
    return this.integrationReScope$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
