import { Injectable } from "@angular/core";
import { LogService, MibpLogger } from "../logservice";

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateService {

  private log: MibpLogger;
  private navigationIsBlocked = false;
  public currentRouteHasGuard = false;

  constructor(logger: LogService) {
    this.log = logger.withPrefix('can-deactive.service');
  }

  /**
   * Will make can-deactivate.guard.ts block navigation
   * if the guard is used for the current route
   */
  public block(): void {

    if (!this.currentRouteHasGuard) {
      this.log.warn("MibpCanDeactivateGuard is missing for this route. Navigation can not be blocked.");
      return;
    }

    this.log.debug('CanDeactivate = false');
    this.navigationIsBlocked = true;
  }

  /**
   * Will make can-deactivate.guard.ts to allow navigation
   * if the guard is used for the current route
   */
  public unblock(): void {
    this.log.debug('CanDeactivate = true');
    this.navigationIsBlocked = false;
  }

  /**
   * True if navigation should be blocked
   */
  public get isBlocked() {
    return this.navigationIsBlocked;
  }


}
