/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CartAddProductsResponseViewModel } from '../../models/cart-add-products-response-view-model';

export interface AddTemplateToCart$Params {

/**
 * The [CartId] for the template whose items should be added to the cart.
 */
  templateCartId: string;
}

export function addTemplateToCart(http: HttpClient, rootUrl: string, params: AddTemplateToCart$Params, context?: HttpContext): Observable<StrictHttpResponse<CartAddProductsResponseViewModel>> {
  const rb = new RequestBuilder(rootUrl, addTemplateToCart.PATH, 'post');
  if (params) {
    rb.path('templateCartId', params.templateCartId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CartAddProductsResponseViewModel>;
    })
  );
}

addTemplateToCart.PATH = '/templates/{templateCartId}/addtocart';
