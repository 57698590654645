/* tslint:disable */
/* eslint-disable */
export enum RescopeEntityType {
  Company = 0,
  BusinessRelation = 1,
  AccessGroup = 2,
  DeliverySequence = 3,
  Equipment = 4,
  Order = 5,
  IntegrationBulletin = 6,
  User = 7,
  Claim = 8,
  Promotion = 9,
  NewsArticle = 10,
  SupportCase = 11,
  ComponentWarrantyRegistration = 12
}
