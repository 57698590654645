import { TermsAndConditionsService } from './../../../services/terms-and-conditions/terms-and-conditions.service';
import { AuthService } from './../../../services/auth-service/auth.service';
import { Subscription } from 'rxjs';
import { BroadcastService, FrontendContextService } from 'root/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TermsAndConditionsTypes } from 'root/components/terms-and-conditions/terms-and-conditions.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'mibp-footer-v2',
  templateUrl: './footer-v2.component.html',
  styleUrls: ['./footer-v2.component.scss']
})
export class MibpFooterV2Component implements OnInit, OnDestroy {
  lang: string;
  langSub: Subscription;
  isLoggedIn = false;

  constructor(private auth: AuthService,
              private broadcast: BroadcastService,
              private termsAndConditions: TermsAndConditionsService,
              private frontEndContext: FrontendContextService,
              private router: Router) {}

  ngOnInit(): void {
    this.lang = this.broadcast.snapshot.language;
    this.langSub = this.broadcast.language.subscribe(langCode => this.lang = langCode);
    this.broadcast.mibpSession.subscribe(() => this.isLoggedIn = this.auth.isLoggedIn());
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  logout(): void {
    this.auth.signout();
  }

  showTermsAndConditions(): void {
    this.termsAndConditions.showTermsAndConditions(TermsAndConditionsTypes.General, true);
  }

  gotoContactUs(e: Event){
    e.preventDefault();
    let url = this.frontEndContext.Navigation.ensureUrlLanguage('/help/contact');
    this.router.navigateByUrl(url);
  }
}
