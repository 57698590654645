import { Directive, ElementRef, Output, EventEmitter, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import { MibpLogger, LogService, LoaderService } from 'root/services';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[mibpFileDrop]'
})
export class MibpFiledropDirective implements OnInit, OnDestroy {
  log: MibpLogger;
  id: string;
  element: HTMLElement;

  /***
   * DragEnter will trigger for all child events.
   * This will be increased for each new event and decreased for DragLeave
   * to keep track on when to add/remove --hover class
   */
  dragEnterCounter = 0;

  // EventHandlers bound to the component context
  private onDragEnterBound: any;
  private onDragLeaveBound: any;
  private onDragDropBound: any;
  private onDragOverBound: any;

  @Output() fileDragDrop = new EventEmitter<FileList>();
  @Output() fileDragEnter = new EventEmitter<any>();
  @Output() fileDragLeave = new EventEmitter<any>();
  @Input() mibpFileDropClass = 'mibp-filedrop';

  constructor(logger: LogService, elementRef: ElementRef) {
    this.log = logger.withPrefix('filedrop.directive');
    this.element = elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.element.classList.add(this.mibpFileDropClass);
    this.atachEvents();
  }

  private atachEvents(): void {

    this.onDragEnterBound = this.onDragEnter.bind(this);
    this.onDragLeaveBound = this.onDragLeave.bind(this);
    this.onDragDropBound = this.onDragDrop.bind(this);
    this.onDragOverBound = this.onDragOver.bind(this);

    this.element.addEventListener('dragenter', this.onDragEnterBound, false);
    this.element.addEventListener('dragleave', this.onDragLeaveBound, false);
    this.element.addEventListener('drop', this.onDragDropBound, true);
    this.element.addEventListener('dragover', this.onDragOverBound, false);
  }

  onDragEnter(e: DragEvent): void {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';  // required to enable drop on DIV
    this.updateDOMOnEntering();

  }

  onDragLeave(e: DragEvent): void {
    e.preventDefault();
    this.updateDOMOnLeaving(false);
  }

  /**
   * We must set dropEffect or drop event will not trigger
   */
  onDragOver(e: DragEvent): void {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  }

  onDragDrop(e: DragEvent): void {
    e.preventDefault();
    this.updateDOMOnLeaving(true);
    const dt = e.dataTransfer;
    const f = dt.files;
    setTimeout( () => {
      this.fileDragDrop.emit(f);
    });
  }

  private detacthEvents(): void {
    this.element.classList.remove(this.mibpFileDropClass);
    this.element.removeEventListener('dragenter', this.onDragEnterBound);
    this.element.removeEventListener('dragleave', this.onDragLeaveBound);
    this.element.removeEventListener('drop', this.onDragDropBound);
    this.element.removeEventListener('dragover', this.onDragOverBound);

  }

  private updateDOMOnEntering(): void {
    if (this.dragEnterCounter === 0) {
      this.element.classList.add(`${this.mibpFileDropClass}--hover`);

      const overlay = document.createElement('div');
      overlay.classList.add(`${this.mibpFileDropClass}__overlay`);

      if (this.element.childNodes.length > 0) {
        this.element.insertBefore(overlay, this.element.firstChild);
      } else {
        this.element.appendChild(overlay);
      }
    }
    this.dragEnterCounter++;
  }

  private updateDOMOnLeaving(isFileDrop = false): void {
    if (isFileDrop) {
      this.dragEnterCounter = 0;
    } else {
      this.dragEnterCounter--;
    }

    if (this.dragEnterCounter === 0) {
      this.element.classList.remove(`${this.mibpFileDropClass}--hover`);
      const overlay = this.element.querySelector(`.${this.mibpFileDropClass}__overlay`);
      if (overlay) {
        overlay.parentElement.removeChild(overlay);
      }
    }
  }

  private removeClasses(): void {
    this.element.classList.forEach(className => {
      if (className.startsWith(this.mibpFileDropClass)) {
        this.element.classList.remove(className);
      }
    });
  }

  ngOnDestroy() {
    this.detacthEvents();
    this.removeClasses();
  }

}
