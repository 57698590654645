/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, OnInit, ElementRef, Renderer2, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[mibpInputAutosize]'
})
export class InputAutosizeDirective implements OnInit, OnDestroy  {

  private static autoincid = 0;

  private id: number;
  private inputElement: HTMLInputElement;
  private textSizeElement: HTMLDivElement;
  private watchTimer: any;
  isEnabled = true;


  @Input('mibpInputAutosize.disabled')
  set disabled(isDisabled: boolean) {
    if (this.inputElement) {
      if (isDisabled === true && this.isEnabled === true) {
        this.renderer.removeStyle(this.inputElement, 'width');
      } else {
        this.resizeInputField();
      }
    }
    this.isEnabled = !isDisabled;
  }
  @Input('mibpInputAutosize.minWidth') minWidth = 30;
  @Input('mibpInputAutosize.maxWidth') maxWidth = 0;
  @Input('mibpInputAutosize.debug') debug = false;

  constructor(private elm: ElementRef, private renderer: Renderer2) {}

  ngOnDestroy() {
    this.inputElement?.parentElement?.removeChild(this.inputElement);
    this.textSizeElement?.parentElement?.removeChild(this.textSizeElement);
  }

  ngOnInit() {

    this.inputElement = <HTMLInputElement><any>this.elm.nativeElement;

    if (!this.inputElement) {
      return;
    }

    const style = getComputedStyle(this.inputElement);

    this.inputElement.addEventListener('keyup', () => {  this.resizeInputField(); });
    this.inputElement.addEventListener('keypress', () => { this.resizeInputField(); });
    this.inputElement.addEventListener('focus', () => { this.resizeInputField(); this.startWatch(); });
    this.inputElement.addEventListener('blur', () => { this.endWatch(); this.resizeInputField(); });
    this.id = InputAutosizeDirective.autoincid;
    InputAutosizeDirective.autoincid ++;

    // Create hidden element that we'll use to measure the text
    this.textSizeElement = <HTMLDivElement>document.createElement(`DIV`);
    this.renderer.setAttribute(this.textSizeElement, 'id', `iaz_${this.id}`);
    this.renderer.setAttribute(this.textSizeElement, 'class', 'input-auzosize');


    // TIMER! INPUT!

    this.setStyles(this.textSizeElement, {
      'background-color': 'rgba(255,255,0,0.25)',
      'position': 'absolute',
      'top': ( this.inputElement.offsetTop + this.inputElement.offsetHeight + this.inputElement.getBoundingClientRect().height) + 'px',
      'left': (this.inputElement.offsetLeft) + 'px',
      'padding-left': `${this.inputElement.style.paddingLeft || 0}`,
      'padding-right': `${this.inputElement.style.paddingRight || 0}`,
      'fontSize': `${style.fontSize || 'inherit'}`,
      'visibility': this.debug ? 'visible' : 'hidden'
    });
    this.textSizeElement.innerText = '';

    document.body.appendChild(this.textSizeElement);
    this.resizeInputField();

    if (this.debug) {
      setTimeout( () => {
        this.textSizeElement.style.top = ( this.inputElement.offsetTop + this.inputElement.offsetHeight) + 'px';
      }, 150);
    }

  }

  private setStyles( el: HTMLElement, style: { [key: string]: string} ) {
    Object.keys(style)
      .forEach(propertyName => this.renderer.setStyle(el, propertyName, style[propertyName]));
  }

  private startWatch() {
    this.endWatch();
    if (this.isEnabled) {
      this.watchTimer = setInterval(() => {
        this.resizeInputField();
      }, 200);
    }
  }

  private endWatch() {
    if (this.watchTimer) {
      clearInterval(this.watchTimer);
    }
  }

  private resizeInputField() {
    if (!this.isEnabled) {
      return;
    }

    if (this.inputElement) {
      const textContent = this.inputElement.value.replace(' ', '_') + '__';
      this.textSizeElement.innerText = textContent;

      const size = this.textSizeElement.offsetWidth;
      const min = parseInt(this.minWidth ? this.minWidth.toString() : '0', 10);
      const max = parseInt(this.maxWidth ? this.maxWidth.toString() : '0', 10);
      let w = size < min ? min : size;
      if (max !== 0 && w > max) {
        w = max;
      }
      this.renderer.setStyle(this.inputElement, 'width', `${w}px`);
    }
  }

}
