/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteEnvironmentInstanceDocuments } from '../fn/search/delete-environment-instance-documents';
import { DeleteEnvironmentInstanceDocuments$Params } from '../fn/search/delete-environment-instance-documents';
import { deleteEnvironmentInstanceDocuments$Plain } from '../fn/search/delete-environment-instance-documents-plain';
import { DeleteEnvironmentInstanceDocuments$Plain$Params } from '../fn/search/delete-environment-instance-documents-plain';
import { deleteFromIndex } from '../fn/search/delete-from-index';
import { DeleteFromIndex$Params } from '../fn/search/delete-from-index';
import { deleteFromIndex$Plain } from '../fn/search/delete-from-index-plain';
import { DeleteFromIndex$Plain$Params } from '../fn/search/delete-from-index-plain';
import { deleteLdeIndex } from '../fn/search/delete-lde-index';
import { DeleteLdeIndex$Params } from '../fn/search/delete-lde-index';
import { deleteLdeIndex$Plain } from '../fn/search/delete-lde-index-plain';
import { DeleteLdeIndex$Plain$Params } from '../fn/search/delete-lde-index-plain';
import { FullCrawlJobVm } from '../models/full-crawl-job-vm';
import { listIndexes } from '../fn/search/list-indexes';
import { ListIndexes$Params } from '../fn/search/list-indexes';
import { listIndexes$Plain } from '../fn/search/list-indexes-plain';
import { ListIndexes$Plain$Params } from '../fn/search/list-indexes-plain';
import { listJobs } from '../fn/search/list-jobs';
import { ListJobs$Params } from '../fn/search/list-jobs';
import { listJobs$Plain } from '../fn/search/list-jobs-plain';
import { ListJobs$Plain$Params } from '../fn/search/list-jobs-plain';
import { resumeJob } from '../fn/search/resume-job';
import { ResumeJob$Params } from '../fn/search/resume-job';
import { SearchIndexDeletedInfoVm } from '../models/search-index-deleted-info-vm';
import { SearchIndexInfoVm } from '../models/search-index-info-vm';
import { sendUpdateIndexEvent } from '../fn/search/send-update-index-event';
import { SendUpdateIndexEvent$Params } from '../fn/search/send-update-index-event';
import { terminateJob } from '../fn/search/terminate-job';
import { TerminateJob$Params } from '../fn/search/terminate-job';
import { toBeTriggeredPartialCrawl } from '../fn/search/to-be-triggered-partial-crawl';
import { ToBeTriggeredPartialCrawl$Params } from '../fn/search/to-be-triggered-partial-crawl';
import { triggerFullCrawl } from '../fn/search/trigger-full-crawl';
import { TriggerFullCrawl$Params } from '../fn/search/trigger-full-crawl';

@Injectable({ providedIn: 'root' })
export class SearchApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchControllerTriggerFullCrawlindexType()` */
  static readonly SearchControllerTriggerFullCrawlindexTypePath = '/search/fullcrawl/{indexType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerFullCrawl()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerFullCrawl$Response(params: TriggerFullCrawl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return triggerFullCrawl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `triggerFullCrawl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerFullCrawl(params: TriggerFullCrawl$Params, context?: HttpContext): Observable<void> {
    return this.triggerFullCrawl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `searchControllerToBeTriggeredPartialCrawlindexType()` */
  static readonly SearchControllerToBeTriggeredPartialCrawlindexTypePath = '/search/partialcrawl/{indexType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toBeTriggeredPartialCrawl()` instead.
   *
   * This method doesn't expect any request body.
   */
  toBeTriggeredPartialCrawl$Response(params: ToBeTriggeredPartialCrawl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return toBeTriggeredPartialCrawl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toBeTriggeredPartialCrawl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toBeTriggeredPartialCrawl(params: ToBeTriggeredPartialCrawl$Params, context?: HttpContext): Observable<void> {
    return this.toBeTriggeredPartialCrawl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `searchControllerDeleteFromIndexindexTypeId()` */
  static readonly SearchControllerDeleteFromIndexindexTypeIdPath = '/search/{indexType}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFromIndex$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFromIndex$Plain$Response(params: DeleteFromIndex$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteFromIndex$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFromIndex$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFromIndex$Plain(params: DeleteFromIndex$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteFromIndex$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFromIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFromIndex$Response(params: DeleteFromIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return deleteFromIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFromIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFromIndex(params: DeleteFromIndex$Params, context?: HttpContext): Observable<boolean> {
    return this.deleteFromIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `searchControllerSendUpdateIndexEventindexIdSkipQueue()` */
  static readonly SearchControllerSendUpdateIndexEventindexIdSkipQueuePath = '/search/{index}/{id}/trigger-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendUpdateIndexEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendUpdateIndexEvent$Response(params: SendUpdateIndexEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendUpdateIndexEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendUpdateIndexEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendUpdateIndexEvent(params: SendUpdateIndexEvent$Params, context?: HttpContext): Observable<void> {
    return this.sendUpdateIndexEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `searchControllerListIndexesindexId()` */
  static readonly SearchControllerListIndexesindexIdPath = '/search/indexes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listIndexes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listIndexes$Plain$Response(params?: ListIndexes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SearchIndexInfoVm>>> {
    return listIndexes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listIndexes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listIndexes$Plain(params?: ListIndexes$Plain$Params, context?: HttpContext): Observable<Array<SearchIndexInfoVm>> {
    return this.listIndexes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SearchIndexInfoVm>>): Array<SearchIndexInfoVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listIndexes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listIndexes$Response(params?: ListIndexes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SearchIndexInfoVm>>> {
    return listIndexes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listIndexes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listIndexes(params?: ListIndexes$Params, context?: HttpContext): Observable<Array<SearchIndexInfoVm>> {
    return this.listIndexes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SearchIndexInfoVm>>): Array<SearchIndexInfoVm> => r.body)
    );
  }

  /** Path part for operation `searchControllerDeleteEnvironmentInstanceDocumentsindexNameTakeEnvironmentInstance()` */
  static readonly SearchControllerDeleteEnvironmentInstanceDocumentsindexNameTakeEnvironmentInstancePath = '/search/indexes/{indexName}/my-environmentinstance-documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEnvironmentInstanceDocuments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEnvironmentInstanceDocuments$Plain$Response(params: DeleteEnvironmentInstanceDocuments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchIndexDeletedInfoVm>> {
    return deleteEnvironmentInstanceDocuments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEnvironmentInstanceDocuments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEnvironmentInstanceDocuments$Plain(params: DeleteEnvironmentInstanceDocuments$Plain$Params, context?: HttpContext): Observable<SearchIndexDeletedInfoVm> {
    return this.deleteEnvironmentInstanceDocuments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchIndexDeletedInfoVm>): SearchIndexDeletedInfoVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEnvironmentInstanceDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEnvironmentInstanceDocuments$Response(params: DeleteEnvironmentInstanceDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchIndexDeletedInfoVm>> {
    return deleteEnvironmentInstanceDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEnvironmentInstanceDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEnvironmentInstanceDocuments(params: DeleteEnvironmentInstanceDocuments$Params, context?: HttpContext): Observable<SearchIndexDeletedInfoVm> {
    return this.deleteEnvironmentInstanceDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchIndexDeletedInfoVm>): SearchIndexDeletedInfoVm => r.body)
    );
  }

  /** Path part for operation `searchControllerDeleteLdeIndexindexName()` */
  static readonly SearchControllerDeleteLdeIndexindexNamePath = '/search/indexes/{indexName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLdeIndex$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLdeIndex$Plain$Response(params: DeleteLdeIndex$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchIndexDeletedInfoVm>> {
    return deleteLdeIndex$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteLdeIndex$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLdeIndex$Plain(params: DeleteLdeIndex$Plain$Params, context?: HttpContext): Observable<SearchIndexDeletedInfoVm> {
    return this.deleteLdeIndex$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchIndexDeletedInfoVm>): SearchIndexDeletedInfoVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLdeIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLdeIndex$Response(params: DeleteLdeIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchIndexDeletedInfoVm>> {
    return deleteLdeIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteLdeIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLdeIndex(params: DeleteLdeIndex$Params, context?: HttpContext): Observable<SearchIndexDeletedInfoVm> {
    return this.deleteLdeIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchIndexDeletedInfoVm>): SearchIndexDeletedInfoVm => r.body)
    );
  }

  /** Path part for operation `searchControllerListJobsskipTakeIndexType()` */
  static readonly SearchControllerListJobsskipTakeIndexTypePath = '/search/jobs/fullcrawl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listJobs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobs$Plain$Response(params?: ListJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FullCrawlJobVm>>> {
    return listJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listJobs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobs$Plain(params?: ListJobs$Plain$Params, context?: HttpContext): Observable<Array<FullCrawlJobVm>> {
    return this.listJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FullCrawlJobVm>>): Array<FullCrawlJobVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobs$Response(params?: ListJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FullCrawlJobVm>>> {
    return listJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listJobs(params?: ListJobs$Params, context?: HttpContext): Observable<Array<FullCrawlJobVm>> {
    return this.listJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FullCrawlJobVm>>): Array<FullCrawlJobVm> => r.body)
    );
  }

  /** Path part for operation `searchControllerTerminateJobjobId()` */
  static readonly SearchControllerTerminateJobjobIdPath = '/search/jobs/fullcrawl/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `terminateJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateJob$Response(params: TerminateJob$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return terminateJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `terminateJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  terminateJob(params: TerminateJob$Params, context?: HttpContext): Observable<void> {
    return this.terminateJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `searchControllerResumeJobjobIdFromIndex()` */
  static readonly SearchControllerResumeJobjobIdFromIndexPath = '/search/jobs/fullcrawl/{jobId}/resume';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resumeJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  resumeJob$Response(params: ResumeJob$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resumeJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resumeJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resumeJob(params: ResumeJob$Params, context?: HttpContext): Observable<void> {
    return this.resumeJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
