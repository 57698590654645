
<div>
  <span class="change-language-link" [class.active]="showPopup" (click)="togglePopup()">
    <mibp-resource-string key="Global_LanguageChange_Button"></mibp-resource-string>
    <img [src]="getFlagUrlFromCurrentLanguage()" title="">
  </span>
</div>
<div class="language" *ngIf="showPopup">
  <div class="popover popover-small">
    <mibp-loader type='loader' *ngIf="!languageCodes"></mibp-loader>
    <ul>
      <li *ngFor="let language of languageCodes; let i = index">
        <span class="country">
          <a class="lang-link" href="javascript:void(0)" (click)="switchLanguage(language)">
              <img width="24" height="24" alt="Flag icon" [src]="getFlagUrlFromIndex(i)" />
              <span>
                <mibp-resource-string [key]="getLanguageResoucenName(language)"></mibp-resource-string>
              </span>
          </a>
        </span>
      </li>
    </ul>
  </div>
</div>

