/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NewProduct } from '../../models/new-product';

export interface GetProductByCodeAndCompany$Params {

/**
 * code of product.
 */
  code: string;

/**
 * company code.
 */
  companyCode?: string;
}

export function getProductByCodeAndCompany(http: HttpClient, rootUrl: string, params: GetProductByCodeAndCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProduct>> {
  const rb = new RequestBuilder(rootUrl, getProductByCodeAndCompany.PATH, 'get');
  if (params) {
    rb.path('code', params.code, {});
    rb.query('companyCode', params.companyCode, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<NewProduct>;
    })
  );
}

getProductByCodeAndCompany.PATH = '/products/by-code/{code}';
