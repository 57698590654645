<div class="mibp-datepicker"
  [class.mibp-datepicker--clear]="!hideClearIcon"
  [class.mibp-datepicker--no-clear]="hideClearIcon"
  [class.block]="displayBlock" >
  <mat-form-field>
      <input autocomplete="off" #field matInput
      [contentEditable]="false"
      [disabled]="isDisabled"
      [className]="stylingName"
      [class.focus]="picker.opened"
      [matDatepicker]="picker"
      [min]="minDateValue"
      [max]="maxDateValue"
      (focus)="picker.open()"
      format='YYYY'
      [(ngModel)]="dateValue"
      (dateInput)="addEvent('input', $event)"
      (dateChange)="addEvent('change', $event);">

      <span [hidden]="!dateValue" translate="no" class="material-icon clear-icon"
        (click)="dateValue = null; addEvent('change', {target: null, targetElement: null, value: null}); $event.stopPropagation(); false">close</span>

       <img class="icon" (click)="picker.open();" src="../../../assets/images/calendar-black-41x24.svg" width="41" height="24" alt="" />
      <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>


