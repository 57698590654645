import { MySandvikNavigationItem } from "../navigation-types";
import { allPermissionPolicies } from '../../../all-permission-policies';

export const NAVITEMS_MYSANDVIK_DIGITAL_SERVICES: MySandvikNavigationItem =
{
  resourceKey: 'DigitalServices_Title',
  uniqueId: 'mysandvikdigitalservices',
  sandvikIconName: 'pie_chart',
  route: {path: ['digital-services']},
  permissionPolicy: allPermissionPolicies.mySandvikDigitalServices,
  children: [
    {
      hidden: true,
      resourceKey: 'dsvcoffering_title',
      uniqueId: 'digital-service-offering',
      route: {path: ['digital-service-offering'], exactMatch: true },
      permissionPolicy: allPermissionPolicies.mySandvikDigitalServices,
    }
  ]
};



