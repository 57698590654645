import { BiDashboardApiController } from 'root/mibp-openapi-gen/services/bi-dashboard-api-controller';
import { FrontendContextService } from './../../services/front-end-context/front-end-context.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService, LogService, MibpLogger } from 'root/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mibp-dashboard-product-search',
  templateUrl: './dashboard-product-search.component.html',
  styleUrls: ['./dashboard-product-search.component.scss']
})
export class DashboardProductSearchComponent implements OnInit, OnDestroy {
  log: MibpLogger;
  searchText =  "";
  lastChangeSearchText: Date;
  biDashboardThreshold: Date;
  localizationSubscription: Subscription;
  @ViewChild("input", {static: true}) inputElement: ElementRef;

  private updatePlaceholder() {
    if (this.inputElement) {
      this.inputElement.nativeElement.setAttribute('placeholder', this.localizationService.get('productsearch_placeholder'));
    }
  }

  constructor(private router: Router,
     private api: BiDashboardApiController,
      private localizationService: LocalizationService,
       private frontendContext: FrontendContextService,
       logger: LogService) {
        this.log = logger.withPrefix('dashboard_productsearch');
        }

  ngOnInit() {
    this.localizationSubscription = this.localizationService.Locale$.subscribe(() => this.updatePlaceholder());
    this.lastChangeSearchText = new Date();
    this.biDashboardThreshold = new Date(1500);
  }

  ngOnDestroy() {
    if (this.localizationSubscription) {
      this.localizationSubscription.unsubscribe();
    }
  }

  searchProducts() {
    if (this.searchText !== "") {
      const url = this.frontendContext.Navigation.ensureUrlLanguage(`/home/cart/search?query=${this.searchText}`);
      this.router.navigateByUrl(url);
    }
  }

  onChangeSearchText() {
    if (this.searchText === "") {
      return;
    }

    if ((new Date().getTime() - this.lastChangeSearchText.getTime()) > this.biDashboardThreshold.getTime()) {
      this.api.searchTyping(
        {
          searchText: this.searchText
        }
      ).subscribe(() => {},
        err => {
          this.log.debug("Error in searchTyping", err);
        });

      this.lastChangeSearchText = new Date();
    }
  }
}
