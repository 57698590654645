
<button *ngIf="
(downloadStatus == null ||downloadStatus == downloadedManualStatus.Unknown || downloadStatus == downloadedManualStatus.Error || downloadStatus == downloadedManualStatus.Expired)
||
(!userDownloadedManualId && !fileSize && (downloadStatus == downloadedManualStatus.InProgress || downloadStatus == downloadedManualStatus.Queued))"
class="my-button my-button--secondary"
[class.my-button--block]="displayBlock"
(click)="onDownloadManualClick(manual)">
  <span translate="no" class="material-icon">play_circle</span>
  <mibp-resource-string key="DownloadedManual_RequestDownloadButton"></mibp-resource-string>

</button>

<button *ngIf="fileSize"
(click)="onDownloadManualClick(manual)"
class="my-button" [class.my-button--block]="displayBlock" [class.my-button--secondary]="!userDownloadedManualId">
  <span translate="no" class="material-icon" [class.material-icon--white]="userDownloadedManualId">download</span>

  @if (fileType == downloadFileTypes.Pdf) {
    PDF
  } @else if (fileType == downloadFileTypes.MicrosoftExcel || fileType == downloadFileTypes.MicrosoftExcelOpenXml) {
    Excel
  } @else if (fileType == downloadFileTypes.PlainText) {
    Text
  } @else if (fileType == downloadFileTypes.Zip) {
    Zip
  }

  ({{fileSize | formatBytes}})
</button>

<mibp-inline-help
*ngIf="(fileSize && (downloadStatus == downloadedManualStatus.InProgress || downloadStatus == downloadedManualStatus.Queued))"
iconName="sync"
iconSize="large"
[applyContentStyles]="true" >
  <mibp-resource-string isHtml="true" key="DownloadedManuals_ManualUpdateInProgress"></mibp-resource-string>
</mibp-inline-help>

<!-- In progress / Queued -->
<button *ngIf="!fileSize && userDownloadedManualId && (downloadStatus == downloadedManualStatus.InProgress || downloadStatus == downloadedManualStatus.Queued)"
disabled
class="my-button loading"
[class.my-button--block]="displayBlock">

<span translate="no" class="material-icon"
  [class.material-icon--spin]="downloadStatus == downloadedManualStatus.InProgress">
  {{ downloadStatus == downloadedManualStatus.InProgress ? 'progress_activity'  : 'hourglass' }}</span>
<mibp-resource-string [key]="'DownloadedManualStatusCode_' + downloadStatus"></mibp-resource-string>
</button>
