import { ResponsiveBreakpoints, ResponsiveEvent } from './../../services/responsive/responsive.types';
import { Subscription } from 'rxjs';
import { GlobalConfigService } from './../../services/global-config/global-config.service';
import { SignalrConnectionStatusEvent } from './../../services/broadcast-service/broadcast.service.types';
import { AccessTokenEvent } from './../../services/broadcast-service/broadcast.service';
import { Component, ViewChild, TemplateRef, OnInit, EmbeddedViewRef, ViewContainerRef, OnDestroy, ElementRef } from "@angular/core";
import { BroadcastService, FrontendContextService, LoaderService, LogService, MibpLogLevel } from "root/services";
import { environment } from "root/environment";
import { formatDuration, intervalToDuration } from "date-fns";
import { LocalizationService } from './../../services/localization/localization.service';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
@Component({
  selector: 'mibp-devops-feedback',
  templateUrl: `./devops-feedback.component.html`,
  styleUrls: ['./devops-feedback.component.scss']
})
export class MibpDevopsFeedbackComponent implements OnInit, OnDestroy {
  @ViewChild('template', {static: true}) template: TemplateRef<any>;
  @ViewChild('translationDebugPrefix') translationDebugPrefixSelect: ElementRef<HTMLSelectElement>;
  @ViewChild('bugNameElement') bugNameElement: ElementRef<HTMLInputElement>;

  view: EmbeddedViewRef<any>;
  hasFocus = false;
  bugName: string;
  selectingloglevel = false;
  loglevel = environment.logLevel.toString();
  environmentLoglevel = environment.logLevel.toString();
  reloadRequired = false;
  tokenTime = '';
  accessToken: AccessTokenEvent;
  signalrStatus: SignalrConnectionStatusEvent;
  timer: number;
  responsiveSub: Subscription;
  breakpointName: ResponsiveBreakpoints;
  isTransalationDebugEnabled = false;
  isSalesforceChatEnabled = false;
  identityObjectId: string;
  accessTokenUrl: string;

  constructor(private viewContainerRef: ViewContainerRef,
    private context: FrontendContextService,
    protected broadcast: BroadcastService,
    private loader: LoaderService,
    private sessionService: MibpSessionService,
    private globalConfig: GlobalConfigService,
    private localization: LocalizationService) {
    this.loglevel = LogService.getLogLevel().toString();
    this.reloadRequired = false;

    broadcast.accessToken.subscribe(d => {
      this.accessToken = d;

      clearInterval(this.timer);
      if (d) {
        this.accessTokenUrl = `https://jwt.io?token=${encodeURIComponent(d.accessToken)}`;
        this.intervalTick();
        this.timer = window.setInterval(() => this.intervalTick(), 990);
      } else {
        this.tokenTime = '';
        this.accessTokenUrl = null;
      }
    });

    broadcast.signalR.subscribe(signalrStatus => {
      this.signalrStatus = signalrStatus;
    });



  }

  setTranslationDebugPrefix(e: Event): void {
    window.localStorage.setItem('MibpSelectedResourceDebugPrefix', (e.target as HTMLSelectElement).value);
  }


  throwAnError(): void {
    throw new Error(`Fake error for demo purposes`);
  }


  toggleLocalizationDebug(): void {
    this.localization.toggleLocalizationDebug(this.translationDebugPrefixSelect.nativeElement.value);
  }


  private intervalTick(): void {
    const now = new Date();
    const duration = intervalToDuration({start: new Date(), end: this.accessToken.expiresOn});
    const diff = this.accessToken.expiresOn.getTime() - now.getTime();

    if (diff < 0) {
      this.tokenTime = `Token expired`;
    } else {
      this.tokenTime = formatDuration(duration);
    }

  }

  reload(e: Event) {
    this.loader.showFullScreenLoader();
    e.preventDefault();
    e.stopPropagation();
    setTimeout( () => {
      window.location.reload();
    }, 250);
    return false;
  }

  logSelectClick(e: Event) {
    this.selectingloglevel = true;
  }

  loglevelChange(e: Event) {
    this.reloadRequired = this.loglevel !== LogService.getLogLevel().toString();
    LogService.setLogLevel(parseInt(this.loglevel, 10) as MibpLogLevel);
  }

  continueToDevops() {
    if (this.bugName) {
      window.open(this.createBugUrl(this.bugName), '_blank');
      this.bugName = '';
      this.hasFocus = false;
    }
  }

  reportBug(e: MouseEvent) {

    this.hasFocus = true;

    setTimeout( () => {

      if (this.bugNameElement) {
        this.bugNameElement.nativeElement.focus();
      }


      const preferedPrefix = window.localStorage.getItem('MibpSelectedResourceDebugPrefix');
      if (preferedPrefix) {

        // Old way of doing it because if IE11
        this.translationDebugPrefixSelect.nativeElement.querySelectorAll('option').forEach(i => {
          if (i.innerText == preferedPrefix) {
            i.setAttribute('selected', 'selected');
          }
        });
      }


    }, 150);
  }



  createBugUrl(name: string) {

    let build = "";
    const meta = document.querySelector('meta[name="my-sandvik"]');

    if (meta && meta.getAttribute('data-build')) {
      build = `<strong>Environment:</strong> ${environment.name.toUpperCase()} (Build ${meta.getAttribute('data-build')})<br>`;
    } else {
      build = `<strong>Environment:</strong> ${environment.name.toUpperCase()}<br>`;
    }

    let windowSize = "";
    if (window.innerWidth && window.innerHeight) {
      windowSize = `<br /><strong>Window size</strong>: ${window.innerWidth}x${window.innerHeight}`;
    }
    if (screen.width && screen.height) {
      windowSize += `<br /><strong>Screen</strong>: ${screen.width}x${screen.height}`;
      if (screen.orientation) {
        windowSize += `<br /><strong>Orientation</strong>: ${screen.orientation.type} (angle ${screen.orientation.angle})`;
      }
    }
    if (navigator.languages ) {
      windowSize += `<br /><strong>Browser Languages</strong>: ${navigator.languages.join(', ')}`;
    }

    let user = this.broadcast.snapshot.mibpSession.user ? `<li>Login as <strong>${this.broadcast.snapshot.mibpSession.user.email}</strong></li>` : '';

    if (this.sessionService.activeDeliverySequence) {
      user += `<li>Act as <strong>${this.sessionService.activeDeliverySequence.companyCode}</strong> / <strong>${this.sessionService.activeDeliverySequence.erpCustomerId}</strong> / <strong>${this.sessionService.activeDeliverySequence.deliverySequenceNumber}</strong></li>`;
    } else {
      user += '<li>Act as all accounts</li>';
    }

    const systemInfo = `${build}<strong>Browser:</strong> ${navigator.userAgent}${windowSize}`;
    const html = `<strong>Steps to reproduce:</strong><ol><li>Visit <a href="${window.location.href}">${window.location.href}</a></li>${user}<li>?</li></ol><strong>Expected Result:</strong><ul><li>?</li></ul><br><strong>Actual result</strong><ul><li>?</li></ul>`;


    const camelCaseEnvironment = environment.name[0].toUpperCase() + environment.name.toLowerCase().substr(1);

    const param = {
        "[System.Title]" : `[${environment.name.toUpperCase()}] ${name}`,
        "[System.AreaPath]" : `MIBP\\${environment.name.toUpperCase()} Environment`,
        "[Microsoft.VSTS.TCM.ReproSteps]" : html,
        "[Microsoft.VSTS.TCM.SystemInfo]": systemInfo,
        "[Tags]": `FoundIn${camelCaseEnvironment}`,
        "[System.IterationPath]": "MIBP\\Agile Team"
      },
      qs = [],
      keys = Object.keys(param);

    for (let i = 0; i < keys.length; i++) {
      qs.push( encodeURIComponent(keys[i]) + '=' + encodeURIComponent(param[keys[i]] ));
    }

    return 'https://dev.azure.com/sandvik/MIBP/_workitems/create/Bug?' + qs.join('&');
  }

  ngOnInit(): void {
    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe(e => this.onResponsiveBreakpoint(e));
    this.moveTemplateToBody();
    this.isTransalationDebugEnabled = this.localization.isDebugEnabled;
    this.isSalesforceChatEnabled = this.globalConfig.enableSalesforceLiveChat;
    this.identityObjectId = this.sessionService.getIdentityObjectId();

  }

  ngOnDestroy(): void {
    this.responsiveSub?.unsubscribe();
    if (this.view) {
      this.view.destroy();
    }
  }

  onResponsiveBreakpoint(e: ResponsiveEvent): void {
    this.breakpointName = e.breakpoint;
  }

  /**
   * Move the 'dialogTemplate' element (see template) to the body
   * This is done by creating an EmbeddedView and moving its elements to the body element
   * Using the embeddedView, all angular bindings will remain and work as usual even if the
   * elements are moved somewhere else
   * https://angular.io/api/core/ViewContainerRef#createembeddedview
   */
  private moveTemplateToBody(): void {
    const bodyElement = document.querySelector('body');
    this.view = this.viewContainerRef.createEmbeddedView(this.template);
    this.view.rootNodes.forEach(rootNode => bodyElement.appendChild(rootNode));
  }


}
