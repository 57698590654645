export enum SupportCaseTechnicalRequestTypeResourceString {
  FindItemInPartsManual = 'SupportCasesTechnicalRequest_FindItemInPartsManual',
  FindPart = "SupportCasesTechnicalRequest_FindPart",
  RequestForPartsBreakDown = "SupportCasesTechnicalRequest_RequestForPartsBreakDown",
  Replacement = "SupportCasesTechnicalRequest_Replacement",
  GeneralTechnicalRequest = "SupportCasesTechnicalRequest_GeneralTechnicalRequest",

}
export enum SupportCaseTechnicalRequestValue {
  FindItemInPartsManual = 'I need assistance finding an item in my parts manual',
  FindPart = "I need assistance finding what a part is for",
  RequestForPartsBreakDown = "Request for parts breakdown of an item",
  Replacement = "Replacement for out phased item",
  GeneralTechnicalRequest = "General technical request",

}
