import { BroadcastService } from 'root/services/broadcast-service/broadcast.service';
import { fromEvent, Subscription } from 'rxjs';
import { Injectable } from "@angular/core";
import { ResponsiveBreakpoints } from './responsive.types';


@Injectable({
  providedIn: 'root'
})
export class MibpResponsiveService {



  private resizeSub: Subscription;
  private current: ResponsiveBreakpoints;
  private breakpointList: ResponsiveBreakpoints[] = [
    'xxs',
    'xs',
    's',
    'm',
    'l',
    'xl'
  ];

  constructor(private broadcast: BroadcastService) {}

  public begin(): void {

    this.resizeSub = fromEvent(window, 'resize').subscribe(() => this.onResize());
    this.evaluateBreakpoint();
  }

  private onResize(): void {
    this.evaluateBreakpoint();
    this.broadcast.setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }


  private evaluateBreakpoint(): void {
    const w = window.innerWidth;
    const h = window.innerHeight;
    let breakpoint: ResponsiveBreakpoints;

    if (w <= 780) {
      breakpoint = 'xxs';
    } else if (w <= 1024) {
      breakpoint = 'xs';
    } else if (w <= 1280) {
      breakpoint = 's';
    } else if (w <= 1536) {
      breakpoint = 'm';
    } else if (w <= 1920) {
      breakpoint = 'l';
    } else {
      breakpoint = 'xl';
    }

    if (this.current !== breakpoint) {
      this.current = breakpoint;
      this.broadcast.setResponsiveBreakpoint({
        breakpoint: breakpoint,
        width: w,
        height: h,
        lteq: (b) => this.lessThanOrEqual(this.current, b),
        gteq: (b) => this.greaterThanOrEqual(this.current, b)
      });
    }

  }

  private lessThanOrEqual(current: ResponsiveBreakpoints, breakpoint: ResponsiveBreakpoints): boolean {

    const currentIndex = this.breakpointList.findIndex(c => c === current);
    const breapointIndex = this.breakpointList.findIndex(c => c === breakpoint);

    if (currentIndex <= breapointIndex) {
      return true;
    }

    return false;

  }

  private greaterThanOrEqual(current: ResponsiveBreakpoints, breakpoint: ResponsiveBreakpoints): boolean {
    const currentIndex = this.breakpointList.findIndex(c => c === current);
    const breapointIndex = this.breakpointList.findIndex(c => c === breakpoint);

    if (currentIndex >= breapointIndex) {
      return true;
    }

    return false;
  }

  /*

@media (min-width:320px)  { / * smartphones, iPhone, portrait 480x320 phones * / }
@media (min-widt  h:481px)  { /*  portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. * / }
@media (min-width:641px)  { / * portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones * / }
@media (min-width:961px)  { / * tablet, landscape iPad, lo-res laptops ands desktops  * / }
@media (min-width:1025px) { / * big landscape tablets, laptops, and desktops * / }
@media (min-width:1281px) { / * hi-res laptops and desktops * / }

  */



}
