import { FrontendContextService } from './../../services/front-end-context/front-end-context.service';
import { GlobalConfigService } from './../../services/global-config/global-config.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { PopupAnchorPosition, PopupAnchorTo, PopupClosedEvent, PopupPositionArgs, PopupPositionResult } from '../popup-container/popup-container.types';
import { CacheScope, ClientSideCacheService, DialogService, MibpDOMHelperService, MibpHttpApi } from 'root/services';
import { NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { MibpPopupContainerComponent } from '..';
import { firstValueFrom, Subscription } from 'rxjs';
import { MibpFeatureTourService } from './feature-tour.service';
import { FeatureTourGuideStep, FeatureTourResult } from './feature-tour.types';
import { DynatraceUserService } from 'root/services/dynatrace/dynatrace-user.service';
import { UsersApiController } from 'root/mibp-openapi-gen/controllers';
import { MibpLogger } from 'root/services/logservice/mibplogger.class';
import { LogService } from './../../services/logservice/log.service';


@Component({
  selector: 'mibp-feature-tour-component',
  templateUrl: './feature-tour.component.html',
  styleUrls: ['./feature-tour.component.scss']
})
export class MibpFeatureTourComponent implements OnInit {
  tourSkipped: boolean;
  navigationSub: Subscription;
  @ViewChild(MibpPopupContainerComponent) popupContainer: MibpPopupContainerComponent;
  currentStep: number;
  currentSteps: FeatureTourGuideStep[];
  currentPromise: Promise<FeatureTourResult>;
  currentResolve: (value: (FeatureTourResult | PromiseLike<FeatureTourResult>)) => void;
  currentReject: (value: FeatureTourResult | PromiseLike<FeatureTourResult>) => void;
  stepTitleResourceKey: string;
  stepBodyResourceKey: string;
  stepAnchorTo: PopupAnchorTo;
  stepAnchorPosition: PopupAnchorPosition;
  lastDeployDate: Date;
  disableFeatureTourAfter = 0;
  isPrevStep: boolean;
  log: MibpLogger;
  constructor(
    private cacheService: ClientSideCacheService,
    private httpService: MibpHttpApi,
    private onfeatureTourService: MibpFeatureTourService,
    private router: Router,
    private usersApi: UsersApiController,
    private domHelper: MibpDOMHelperService,
    private dialogService: DialogService,
    private globalConfig: GlobalConfigService,
    private context: FrontendContextService,
    private dynatraceUserService: DynatraceUserService,
    logService: LogService

  ) {
    this.onfeatureTourService.registerGlobalComponent(this);
    this.log = logService.withPrefix(`feature-tour-component`);
  }
  ngOnInit(): void {
    this.lastDeployDate = new Date(this.globalConfig.lastDeploymentDate);
    this.disableFeatureTourAfter = this.globalConfig.disableFeatureTourAfterDays;
  }
  testNewFeatureTour(): void {
    this.lastDeployDate = new Date(this.globalConfig.lastDeploymentDate);
    const isCompleted = this.cacheService.get(`HasCompletedNewFeatureTour`);
    const retakeTour = this.cacheService.get('ReTakingNewFeatureTour');
    const currentDate = new Date();
    this.lastDeployDate.setDate(this.disableFeatureTourAfter+this.lastDeployDate.getDate());
    if (isCompleted === true || (!retakeTour && currentDate>this.lastDeployDate ) || !this.onfeatureTourService.steps || this.onfeatureTourService.steps.length===0 || this.globalConfig.disableNewFeatureTour) {
      return;
    }

    if (this.dynatraceUserService.isDynatraceUser) {
      return;
    }

    if (isCompleted === false || retakeTour === true) {
      this.showOnboarding();
    } else {
      firstValueFrom(this.usersApi.getCompletedNewFeatureTour({tourId: this.onfeatureTourService.tourId}))
        .then(isCompletedNewFeatureTour => {
          if (isCompletedNewFeatureTour) {
            this.cacheService.add(`HasCompletedNewFeatureTour`, true, null, CacheScope.MemoryStorage);
          }
          else {
            this.showOnboarding();
          }
        });
    }

  }

  showOnboarding(): void {
    if (this.cacheService.get('ReTakingNewFeatureTour') === true) {
      this.cacheService.add(`HasCompletedNewFeatureTour`, true, null, CacheScope.MemoryStorage);
    }
    this.startGuide(this.onfeatureTourService.steps).then(() => {

      if (this.testIfTourStartedFromHelpPage()) {
        return;
      }

      firstValueFrom(this.usersApi.setCompletedNewFeatureTour({tourId: this.onfeatureTourService.tourId}))
        .then(() => {
          this.cacheService.add(`HasCompletedNewFeatureTour`, true, null, CacheScope.MemoryStorage);
        }, () => {
          this.log.error(`Unable to save that user completed tour`);
        });
    }, () => {
      this.testIfTourStartedFromHelpPage();
    });

  }

  private testIfTourStartedFromHelpPage(): boolean {
    if (this.cacheService.get('ReTakingNewFeatureTour') === true) {
      this.cacheService.remove('ReTakingNewFeatureTour');
      return true;
    }
  }
  public startGuide(steps: FeatureTourGuideStep[]): Promise<FeatureTourResult> {

    this.tourSkipped = false;

    this.navigationSub = this.router.events.pipe(
      filter((e: NavigationStart) => {
        return e instanceof NavigationStart;
      }),
      take(1)
    ).subscribe(() => {
      this.popupContainer.close({ reasonText: 'navigated', reason: 'code' });
    });
    this.currentStep = -1;
    this.currentSteps = steps;

    this.currentPromise = new Promise((resolve, reject) => {
      this.currentResolve = resolve;
      this.currentReject = reject;
      this.nextStep();
    });
    return this.currentPromise;
  }
  nextStep(): void {
    this.currentStep ++;
    if (this.currentStep < this.currentSteps.length) {
      const step = this.currentSteps[this.currentStep];

      this.domHelper.waitForElement(step.elementSelector, 5000, true ).then(element => {
        this.isPrevStep = false;
        this.showStepForElement(step, element);
      }, () => {
        this.nextStep();
      });

    }

  }

  prevStep(): void {
    this.currentStep --;

    if (this.currentStep >= 0) {
      const step = this.currentSteps[this.currentStep];

      this.domHelper.waitForElement(step.elementSelector, 5000, true).then(element => {
        this.isPrevStep = true;
        this.showStepForElement(step, element);
      }, () => {
        this.popupContainer.close({
          reason: 'code',
          reasonText: 'element-gone'
        });
      });

    }

  }

  done(): void {
    this.popupContainer.close({reason: 'code', reasonText: 'featuretour-completed'});
  }
  private showStepForElement(step: FeatureTourGuideStep, element: HTMLElement): void {
    // element.style.border = '10px solid lime';

    if (this.popupContainer.isOpen) {
      this.popupContainer.close();
      setTimeout(() => {
        this.stepTitleResourceKey = step.titleResourceString;
        this.stepBodyResourceKey = step.bodyResourceString;
        this.stepAnchorTo = step.anchorTo;
        this.stepAnchorPosition = step.anchorPosition;
        this.popupContainer.open(element, step.positionCallback);
        // this.grayscaleAllBut(element);
      }, 100);
    } else {
      this.stepTitleResourceKey = step.titleResourceString;
      this.stepBodyResourceKey = step.bodyResourceString;
      this.stepAnchorTo = step.anchorTo;
      this.stepAnchorPosition = step.anchorPosition;
      this.popupContainer.open(element, step.positionCallback);
      // this.grayscaleAllBut(element);
    }

  }

  close(): void {
    this.popupContainer.close({
      reason: 'code',
      reasonText: 'close-button'
    });
    this.navigationSub?.unsubscribe();
  }

  onPopupClosed(e: PopupClosedEvent): void {
    if (e.reason === 'code' && e.reasonText == 'close-button') {
      if (this.currentStep === this.currentSteps.length - 1) {
        this.resolveAsCompleted(e);
      } else {
        this.reject(e);
      }
    } else if (e.reason === 'code' && e.reasonText == 'featuretour-completed') {
      this.resolveAsCompleted(e);
    }
    else if (e.reason === 'code' && e.reasonText == 'skip') {
      this.resolveAsCompleted(e);}
    else if (e.reason === 'element-gone') {
      if (this.currentStep < this.currentSteps.length - 1) {
        if(this.isPrevStep === true){
          this.prevStep();
        }
        else{
          this.nextStep();
        }
      } else {
        this.resolveAsCompleted(e);
      }
    } else if (e.reasonText == 'navigated') {
      this.reject(e);
    }
  }

  private resolveAsCompleted(closeEvent: PopupClosedEvent): void {
    this.currentResolve({
      currentStep: this.currentSteps.length,
      stepCount: this.currentSteps.length,
      closeEvent: closeEvent
    });
  }

  private reject(closeEvent: PopupClosedEvent): void {
    this.currentReject({
      currentStep: this.currentStep + 1,
      stepCount: this.currentSteps.length,
      closeEvent: closeEvent
    });
  }
  skipTour() : void {
    this.dialogService.promptWithHtml('Onboarding_ConfirmSkipTourMessage', 'cancel#Global_CancelButton', 'confirm#Onboarding_SkipTour').then(buttonId => {
      if (buttonId === 'confirm') {
        this.tourSkipped = true;
        this.popupContainer.close({
          reason: 'code',
          reasonText: 'skip'
        });
        this.navigationSub?.unsubscribe();
      }
    });
  }
}
