/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { SignalR_ApiResponse } from 'root/services';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { MibpException } from './../error-handler/error-handler.service';
import { LogService } from 'root/services/logservice';
import { MibpLogger } from './../logservice/mibplogger.class';
import { NoticebarService } from './../noticebar-service/noticebar.service';
import { NoticeType } from "root/components/noticebar/noticebar.enum";
import { LocalizationService } from './../localization/localization.service';

@Injectable({
  providedIn: 'root'
})
export class  ApiErrorHandlerService {

  log: MibpLogger;
  constructor(logger: LogService, private noticeBarService: NoticebarService, private localizationService: LocalizationService) {
    this.log = logger.withPrefix('api-error-handler-servie');
  }

  /***
   * Parse the received error into something usable
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public parseError(e: any): ParsedApiErrorClass {
    return new ParsedApiErrorClass(e, this.log, this.noticeBarService, this.localizationService);
  }

}



export class ParsedApiErrorClass {

  private parsedData: ParsedErrorData = { type: 'unknown' };
  private anyErrorsCaught = false;

  constructor(rawError: any, private log: MibpLogger, private noticeBarService: NoticebarService, private loacalizationService: LocalizationService) {

    this.parsedData.raw = rawError;
    this.parsedData.parsedErrors = [];

    if (rawError instanceof HttpErrorResponse) {
      this.parsedData.httpErrorResponse = rawError;
      this.parsedData.type = 'rest';
      this.parseErrorArrayFromResponse(rawError.error);
    } else if (rawError instanceof MibpException && rawError.stack?.request && rawError?.message.includes('Method ')) {
      this.parsedData.type = 'signalr';
      this.parseErrorArrayFromResponse(rawError.stack);
    }

    this.writeErrorToConsole();
  }

  private writeErrorToConsole(): void {
    const handledError = this.parsedData.parsedErrors.find(e => e.exceptionType != "UNHANDLED");
    const unhandledError = this.parsedData.parsedErrors.find(e => e.exceptionType == "UNHANDLED");

    if (handledError) {
      this.log.error(`${handledError.exceptionType} occured`, handledError.description);
    } else if (unhandledError) {
      this.log.error(`Unhandled Exception occured`, unhandledError.exceptionType, unhandledError.description);
    } else {
      //Error could not be parsed and exceptionType is missing
      this.log.error(`Unhandled Exception occured, Error could not be parsed`, this.parsedData.raw);
    }
  }

  public getMessage(): string {
    const handledError = this.parsedData.parsedErrors.find(e => e.exceptionType != "UNHANDLED");
    const unhandledError = this.parsedData.parsedErrors.find(e => e.exceptionType == "UNHANDLED");

    if (handledError) {
      return handledError.description;
    } else if (unhandledError) {
      return unhandledError.description;
    } else {
      //Error could not be parsed and exceptionType is missing
      return `Unknown error`;
    }
  }

  private parseErrorArrayFromResponse(error: any): void {
    if(error) {
      if (Array.isArray(error.errors)) {
        this.parsedData.parsedErrors = error.errors;
        return;
      }

      try {
        // Try to manually parse response in case parse wasn´t done in API client
        let parsedMibpError = JSON.parse(error);

        if (Array.isArray(parsedMibpError.errors)){
          this.parsedData.parsedErrors = parsedMibpError.errors;
          return;
        }
      } catch {}

      this.parsedData.parsedErrors.push(error);
    }
  }

  /**
   *
   * Show an error notice. Will show name of any named exception with message from backend, otherwise just
   * general error notice
   */
  public showNotice(): ParsedApiErrorClass {

    if (this.anyErrorsCaught) {
      return this;
    }

    this.anyErrorsCaught = true;

    // First, try to find an error with a public message
    let err = this.parsedData.parsedErrors.find(e => e.exceptionType != "UNHANDLED" && e.publicMessage);

    // Else, try to find any MibpNamedException
    if (!err) {
      err = this.parsedData.parsedErrors.find(e => e.exceptionType != "UNHANDLED");
    }

    if (err) {
      this.noticeBarService.showText(`<em>${err.exceptionType}</em> occured<br>` + (err.publicMessage || this.loacalizationService.get('Global_GeneralErrorMessage') ), NoticeType.Error, true);
    } else {
      this.noticeBarService.show('Global_GeneralErrorMessage', NoticeType.Error);
    }

    return this;
  }

  /**
   *
   * @param cb Will always execute
   * @returns
   */
  public always(cb: (errorData: ParsedErrorData) => void): ParsedApiErrorClass {
    if (cb) {
      cb(this.parsedData);
    }
    return this;
  }

  /**
   * When an HttpError was identified with the specified response code
   */
  public catchHttpStatusCode(code: HttpStatusCode, cb: (errorData: ParsedErrorData) => void): ParsedApiErrorClass {

    if (this.anyErrorsCaught) {
      return this;
    }

    if (this.parsedData.httpErrorResponse?.status == code) {
      this.anyErrorsCaught = true;
      cb(this.parsedData);
    }
    return this;

  }

  /***
   * When an error with the specified code was identified
   */
  public catchMibpErrorCode(code: string, cb: (exception: ParsedApiError, errorData: ParsedErrorData) => void): ParsedApiErrorClass {

    if (this.anyErrorsCaught) {
      return this;
    }

    const err = this.parsedData.parsedErrors.find(e => e.code == code);
    if (err) {
      this.anyErrorsCaught = true;
      cb(err, this.parsedData);
    }
    return this;
  }

  /**
   * When a named exception was identified
   * @param exceptionName The name of the exception you want to catch
   * @param cb The callback to be called when this error was found
   */
  public catchNamedException(exceptionName: string, cb: (exception: ParsedApiError, errorData: ParsedErrorData) => void): ParsedApiErrorClass {

    if (this.anyErrorsCaught) {
      return this;
    }

    const namedExceptionToReturn = this.parsedData.parsedErrors.find(e => e.exceptionType == exceptionName);
    if (namedExceptionToReturn) {
      this.anyErrorsCaught = true;
      cb(namedExceptionToReturn, this.parsedData);
    }
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public else(cb: (data: ParsedErrorData) => void): void {
    if (!this.anyErrorsCaught && cb) {
      cb(this.parsedData);
    }
  }

}

export interface ParsedErrorData {
  type: 'signalr' | 'rest' | 'unknown';
  raw?: any;
  parsedErrors?: ParsedApiError[];
  httpErrorResponse?: HttpErrorResponse;
  signalRRequest?: { methodName: string; args: string[] }
  signalRResponse?: any;
}

export interface ParsedApiError {
  exceptionType: string;
  code?: string;
  description?: string;
  publicMessage?: string;
  resourceKey?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: { [key: string]: any};
}








