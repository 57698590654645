import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UserAction } from "../context-menu";
import { PermissionService } from 'root/services/permission';
import { BroadcastService } from "root/services";
import { Subscription } from "rxjs";

@Component({
  selector: 'mibp-page-support-menu',
  template: ``
})
export class MibpSupportTeamPageMenuComponent implements OnInit, OnDestroy {

  private pageMenuItems: UserAction[];
  private actionSubscription: Subscription;

  @Output() action = new EventEmitter<UserAction>();
  @Input()
  set items(items: UserAction[]) {
    if (!this.permissions.test({isSupportTeamMember: true})) {
      return;
    }
    this.pageMenuItems = items;
    this.broadcast.setSupportPageMenuItems(this.pageMenuItems);
  }

  constructor(private permissions: PermissionService, private broadcast: BroadcastService) {}

  ngOnInit(): void {

    if (!this.permissions.test({isSupportTeamMember: true})) {
      return;
    }

    this.broadcast.supportPageMenuAction.subscribe(action => {
      this.action.emit(action);
    });

  }
  ngOnDestroy(): void {
    this.actionSubscription?.unsubscribe();
    if (this.permissions.test({isSupportTeamMember: true})) {
      this.broadcast.setSupportPageMenuItems(null);
    }
  }




}
