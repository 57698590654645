import { FormattingService } from './../../services/formatting/formatting.service';
import { MibpPopoverComponent } from 'root/components/popover-component/popover.component';
import { FrontendContextService } from './../../services/front-end-context/front-end-context.service';
import { LogService } from './../../services/logservice/log.service';
import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { formatDistanceToNowStrict } from 'date-fns';
import { MibpLogger, MibpLogLevel } from '../../services/logservice';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { ProductPrice, SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';
import { UserAction } from '../context-menu';
import { ContactUsTopic } from '../contact-dialog/contact-us-form/contact-us-form.types';
import { ContactUsService, GlobalConfigService, LocalizationService } from 'root/services';
import { SupportCaseItemEnquiryType } from '../contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.interface';
import { MySandvikFeatures } from 'root/services/permission';


@Component({
  selector: 'mibp-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class MibpProductPriceComponent implements OnChanges, OnInit {

  supportMenuItems: UserAction[] = [
    {
      name: 'show-raw-data',
      textOverride: 'Show price data',
      iconName: 'attach_money'
    }
  ];

  @Input() displayStyle: 'checkout' | 'cartitem' = 'cartitem';

  @Input() productPrice: ProductPrice;
  @Input() productCode?: string;
  @Input() quantity?: number;
  @Input() quantityForContactUs?: number;
  @Input() priceUnitOfMeasure: string;
  @Input() forceNetPrice = false;
  @Input() triggerForFirstUpdate = false;
  @Input() hideOnAllErrors = false;
  @Input() displayBlock = true;

  @ViewChild(MibpPopoverComponent) popover: MibpPopoverComponent;

  /**
   * Show a Request Price link rather than an error when Aurora does not return the product at all
   *
   * @memberof MibpProductPriceComponent
   */
  @Input() requestPriceWhenNotInResponse = true;

  /**
   * Show a Request Price link when the price returned from Aurora is 0
   *
   * @memberof MibpProductPriceComponent
   */
  @Input() requestPriceWhenPriceIsZero = true;

  @Output() priceReceived = new EventEmitter<ProductPrice>();
  @Output() priceError = new EventEmitter<string>();

  showBasePrice = false;
  currentProductPrice: ProductPrice;
  /**
   * Subscription to price changes for the current ProductCode
   */
  priceSub?: Subscription;

  /**
   * Set to true if PriceEvent has "null" as price values
   */
  priceIsNull = false;

  /**
   * Set to true if PriceEvent has "0" as price values
   */
  priceIsZero = false;

  /**
   * Set to true if PriceEvent has null or 0 as price,
   * or if an errorcode was returned for the product
   */
  priceHasError = false;

  /**
   * The text to show to the user when hovering (unless in debug mode)
   */
  errorTooltip: string;

  /***
   * True if we received an event for this product that P&A update is in progress
   */
  receivedInProgressEvent: boolean;

  priceSourceInformation: string;

  /**
   * True if this component has triggerd an update for P&A for this product
   */
  updateIsTriggered = false;
  isDebugLogLevel = false;
  isAllowedToSeePrice = false;
  currencyCode: string;
  isNetPriceOnly: boolean;

  isLoading = false;
  log: MibpLogger;
  deliverySequence: SessionDeliverySequenceViewModel;
  stopUsingResources: () => void;
  contactUsMessageRequestPrice: string;
  isAuroraCompany: boolean;
  private contactUsItemEquiryFormEnabled: boolean;

  constructor(logger: LogService,
    private formattingService: FormattingService,
    private context: FrontendContextService,
    private contactUsService: ContactUsService,
    private sessionService: MibpSessionService,
    private localizationService: LocalizationService,
    private globalConfig: GlobalConfigService) {
    this.log = logger.withPrefix('price-component');
    this.isDebugLogLevel = LogService.getLogLevel() === MibpLogLevel.Debug;
    this.deliverySequence = this.sessionService.activeDeliverySequence;
  }

  get quantityIsSet(): boolean {
    return typeof this.quantity !== 'undefined';
  }

  onSupportMenuAction(action: UserAction): void {
    if (action.name == 'show-raw-data') {
      setTimeout(() => {
        alert(JSON.stringify(this.currentProductPrice, null, 2));
      });
    }
  }

  formatPrice(price: number): string {
    if (price) {
      return this.formattingService.formatPrice(price);
    }
    return null;
  }

  updateNetPriceOnly(): void {
    if (!this.showBasePrice || this.forceNetPrice) {
      this.isNetPriceOnly = true;
    } else if (this.currentProductPrice?.hasNetPriceOnly === true) {
      this.isNetPriceOnly =  true;
    } else {
      this.isNetPriceOnly =  false;
    }
  }

  ngOnInit(): void {
    this.contactUsItemEquiryFormEnabled = this.context.testPermission({ features: [MySandvikFeatures.ContactUsFormItemEnquiry] });
    this.sessionService.activeDeliverySequence$.subscribe(ds => this.onActAs(ds));
    this.showBasePrice = this.context.canSeeBasePrice();

    this.stopUsingResources = this.localizationService.using(['Carts_PriceForProductMessage'],
      values => {
        this.contactUsMessageRequestPrice = values[0];
      }
    );

    if (!this.productPrice) {
      this.isLoading = true;
    }

  }

  showPopover(elm: HTMLElement): void {
    this.priceSourceInformation = this.getSourceExact();
    this.popover.open(elm);
  }

  onActAs(activeDeliverySequence: SessionDeliverySequenceViewModel): void {
    this.isAuroraCompany = activeDeliverySequence?.isAuroraCompany;
    this.currencyCode = activeDeliverySequence?.currencyCode;
    this.isAllowedToSeePrice = activeDeliverySequence
                              && this.context.testPermission(allPermissionPolicies.canSeePrices);
    this.showBasePrice = this.context.canSeeBasePrice();

    this.updateNetPriceOnly();
    if( this.deliverySequence !== activeDeliverySequence ){
      this.deliverySequence = activeDeliverySequence;
      if(activeDeliverySequence){
        this.isLoading = true;
        this.priceSub?.unsubscribe();
        // this.priceSub = this.priceAndAvailability.getProductPrice(this.productCode)
        //   .subscribe(priceUpdateEvent => this.onPriceUpdate(priceUpdateEvent));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    // Let user of component set the product price, but only the initial value. Then we handle the rest...
    if (changes.productPrice && changes.productPrice.currentValue) {
      this.currentProductPrice = changes.productPrice.currentValue;
      const basePrice = this.currentProductPrice.basePrice;

      if (this.currentProductPrice.errorCode && (basePrice === null || basePrice === 0)) {
        this.priceHasError = true;
        this.priceError.emit(this.productCode);
      } else {
        this.priceHasError = false;
        if (!this.currentProductPrice.taxValue) {
          this.currentProductPrice.taxValue = 0;
        }
        if (basePrice === null) {
          this.priceIsNull = true;
          this.priceHasError = true;
          this.priceError.emit(this.productCode);
        } else if (basePrice === 0) {
          this.priceIsZero = true;
          this.priceHasError = true;
          this.priceError.emit(this.productCode);
        } else {
          this.priceIsNull = false;
          this.priceIsZero = false;
          this.priceReceived.emit(this.currentProductPrice);
        }
      }
      this.isLoading = false;
      this.updateNetPriceOnly();
    }
    else if(changes.quantityForContactUs){
      this.isLoading  = false;
    }
    else if(!changes.quantity && !changes.displayBlock && !changes.productCode){
      this.isLoading = true;
    }
  }

  getSourceExact(): string {
    // Note. This is only visible when logging is set to DISBLED. So no resource strings has been set for this.
    if (this.currentProductPrice) {
      if (this.currentProductPrice.cachedAt) {
        return `From Cache (cahed ${this.currentProductPrice.cachedAt} ago)`;
      } else {
        return `Fetched from origin`;
      }
    } else {
      return 'No data retrieved';
    }
  }

  get showRequestPriceDueToNotInResponse(): boolean {
    // ERRDAUR005 means the product was not in the response back from aurora
    // This should return true only when the result is missing from Aurora
    const hasZeroPrice = this.currentProductPrice?.netPrice === 0;
    const productMissingFromAuroraResponse = this.currentProductPrice?.errorCode === 'ERRDAUR005';
    return this.requestPriceWhenNotInResponse && hasZeroPrice && productMissingFromAuroraResponse;
  }

  get showRequestPriceDueToZeroPrice(): boolean {
    // ERRDAUR005 means the product was not in the response back from aurora
    // This should return true only when Aurora returns a zero value back
    const hasZeroPrice = this.currentProductPrice?.netPrice === 0;
    const productMissingFromAuroraResponse = this.currentProductPrice?.errorCode === 'ERRDAUR005';

    return this.requestPriceWhenPriceIsZero && hasZeroPrice && !productMissingFromAuroraResponse;
  }

  onRequestPrice(): void {
    if(this.globalConfig.enableNewContactUs && this.contactUsItemEquiryFormEnabled){
      this.contactUsService.openItemEnquiryContactUs(this.productCode, this.quantityForContactUs, SupportCaseItemEnquiryType.ItemPrice);
    }
    else{
      const message = `${this.contactUsMessageRequestPrice} ${this.productCode}`;
      this.contactUs(ContactUsTopic.RequestPrice, message);
    }
  }

  contactUs(topic: ContactUsTopic, message: string) {
    this.contactUsService.openContactUs(topic, message);
  }

}
