
  <ng-template #template>
    <div class="devops-feedback media@xs-and-larger" [class.devops-feedback--open]="hasFocus" [class.salesforce-chat-enabled]="isSalesforceChatEnabled">
      <div *ngIf="hasFocus" class="info" style="display: flex; flex-direction: column; height: 100%;">

        <div style="flex-grow: 1;">
          <mibp-tabs [noMargin]="true">
            <mibp-tab [text]="'Report Bug'">

              <div class="form__group my-mt-1">
                <label>BUG TITLE</label>

                <div style="display: flex;">
                  <div style="margin-right: 15px; flex-grow: 1">
                    <input #bugNameElement (keydown.enter)="continueToDevops($event)" max="100" [(ngModel)]="bugName" type="text" placeholder="Enter the bug name" />
                  </div>
                  <div>
                    <button *ngIf="hasFocus" [disabled]="!this.bugName" class="my-button my-button--fill send" type="button" (click)="continueToDevops($event)">Report!</button>
                  </div>
                </div>

                <p class="my-form-help-block">Enter a good name for the Bug and press Enter or Report! button. Keep the name short and descriptive!</p>
                <p class="my-form-help-block">The current environment and browser details will be added automatically.</p>

              </div>

              <div class="my-toolbar">
                <div class="my-toolbar__left">

                </div>
              </div>

            </mibp-tab>
            <mibp-tab [text]="'OPTIONS'">

              <table class="my-table my-table--compact">
                <tbody>
                  <tr>
                    <td>Log level</td>
                    <td>
                      <select [(ngModel)]="loglevel" (change)="loglevelChange()" (mousedown)="logSelectClick($event)">
                        <option value="0">All{{environmentLoglevel === '0' ? ' (Default)' : ''}}</option>
                        <option value="1">Debug{{environmentLoglevel === '1' ? ' (Default)' : ''}}</option>
                        <option value="2">Info{{environmentLoglevel === '2' ? ' (Default)' : ''}}</option>
                        <option value="3">Warn{{environmentLoglevel === '3' ? ' (Default)' : ''}}</option>
                        <option value="4">Error{{environmentLoglevel === '4' ? ' (Default)' : ''}}</option>
                        <option value="5">Off{{environmentLoglevel === '5' ? ' (Default)' : ''}}</option>
                        </select>
                        <div *ngIf="reloadRequired">
                          <strong style="color:red; padding: 4px;"><a href="javascript:void(0)" (click)="reload($event)">Reload</a> to enable new logging level</strong>
                        </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Translation Debugger
                      <select style="cursor: help; " title="The selected text will be visible in front of all translated resources" #translationDebugPrefix (change)="setTranslationDebugPrefix($event)" [disabled]="isTransalationDebugEnabled">
                        <option>😃</option>
                        <option>$</option>
                        <option>*</option>
                        <option>[OK]</option>
                        <option>✔</option>
                        <option>🟢</option>
                        <option>⁂</option>
                        <option>🔔</option>
                        <option>👓</option>
                        <option>👍</option>
                      </select>
                    </td>
                    <td>
                        <button class="my-button"
                        (click)="toggleLocalizationDebug()">
                        {{ isTransalationDebugEnabled ? 'Disable' : 'Enable' }}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Throw an error!</td>
                    <td>
                        <button class="my-button"
                        (click)="throwAnError()">
                        PLEASE CRASH!
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>ClientID</td>
                    <td>
                        <mibp-copy-to-clipboard [value]="broadcast.snapshot.clientId"></mibp-copy-to-clipboard>
                    </td>
                  </tr>
                  <tr>
                    <td *ngIf="accessTokenUrl"><a [href]="accessTokenUrl" target="_blank">AccessToken</a></td>
                    <td *ngIf="!accessTokenUrl" title="Show token on jwt.io">AccessToken</td>
                    <td>
                        <mibp-copy-to-clipboard [value]="accessToken?.accessToken"></mibp-copy-to-clipboard>
                    </td>
                  </tr>
                  <tr>
                    <td>IdentityObjectId</td>
                    <td>
                        <mibp-copy-to-clipboard [value]="identityObjectId"></mibp-copy-to-clipboard>
                    </td>
                  </tr>
                  <tr>
                    <td>User Id</td>
                    <td>
                        <mibp-copy-to-clipboard [value]="broadcast.snapshot.mibpSession?.user?.userId"></mibp-copy-to-clipboard>
                    </td>
                  </tr>
                </tbody>
              </table>


              <div>

              </div>

            </mibp-tab>
          </mibp-tabs>

        </div>


        <div>
          <div class="my-toolbar">
            <div class="my-toolbar__right">
              <button class="my-button my-button cancel" type="button" (click)="hasFocus = false">Close</button>
            </div>
          </div>
        </div>


      </div>
      <div>
        <div class="bug-button">
          <button *ngIf="!hasFocus" class="my-button hide-print" type="button" (click)="reportBug($event)"><span translate="no" class="material-icon material-icon--white">bug_report</span> Bug!</button>
          <div *ngIf="!hasFocus" style="margin-left: 10px;" class="bug-button__responsive" title="This is the My Sandvik reponsive breakpoint name">{{ breakpointName }}</div>
        </div>
      </div>
  </div>

  </ng-template>

