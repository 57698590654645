import { Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Subscription, skip } from 'rxjs';
import { FrontendContextService, ContactUsService, LocalizationService, GlobalConfigService, BroadcastService, ResponsiveEvent, ToastService } from 'root/services';
import { DialogComponent, ContactUsFormData } from 'root/components';
import { environment } from 'root/environment';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { MySandvikFeatures, PermissionService } from 'root/services/permission';
import { animate, style, transition, trigger } from '@angular/animations';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { RouterEventService } from 'root/services/router/router-event.service';
import { SupportCaseFormData } from 'root/components/contact-dialog/new-contact-us-form/new-contact-us-form.types';
import { ActiveMibpToast } from 'root/services/toast-service/toast.service.interface';
import { ToastType } from 'root/services/toast-service/toast.enum';
import { SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';

@Component({
  templateUrl: './application-root.component.html',
  styleUrls: ['./application-root.component.scss'],
  animations: [trigger('myToggleTrigger', [
    transition(':enter', [
      style({ transform: 'translateX(-100%)' }),
      animate('300ms', style({ transform: 'translateX(0)' })),
    ]),
    transition(':leave', [
      animate('300ms', style({ transform: 'translateX(-100%)' }))
    ])
  ])]
})

export class ApplicationRootPageComponent implements OnInit, OnDestroy {

  activeToast: ActiveMibpToast;
  toasterSubscription: Subscription;
  lang = '';
  sub: Subscription;
  enableResourceStringEditor = false;
  enableErrorDialog = environment.enableErrorDialog;
  enableBugReport = environment.enableBugReport;
  sidebarsize = 290;
  contactDialogSub: Subscription;
  @ViewChild('contactDialog', { static: true }) contactUsDialog: DialogComponent;
  @ViewChild('newContactDialog', { static: true }) newContactDialog: DialogComponent;
  public contactUsFormData: ContactUsFormData;
  public newContactUsFormData: SupportCaseFormData;
  private contactUsOpenValue: boolean;
  isTestingFeatureManagement = false;
  isSupportTeamMember = false;
  toggleSidebar = true;
  sessionSub?: Subscription;
  newContactDialogSub: Subscription;
  private showNewContactUsItemEnquiryForm: boolean;
  get contactUsOpen(): boolean {
    return this.contactUsOpenValue;
  }
  set contactUsOpen(value: boolean) {
    this.contactUsOpenValue = value;
    if (this.contactUsOpenValue && this.contactUsDialog && (!this.showNewContactUsItemEnquiryForm && !this.newContactUsFormData)) {
      this.contactUsDialog.open();
    } else if (!this.contactUsOpenValue && this.contactUsDialog && (!this.showNewContactUsItemEnquiryForm && !this.newContactUsFormData)) {
      this.contactUsDialog.close();
    }
    if (this.contactUsOpenValue && this.newContactDialog && (this.showNewContactUsItemEnquiryForm || this.newContactUsFormData)) {
      this.newContactDialog.open();
    } else if (!this.contactUsOpenValue && this.newContactDialog && (this.showNewContactUsItemEnquiryForm || this.newContactUsFormData)) {
      this.newContactDialog.close();
    }
  }

  sidebarToggleSub: Subscription;
  isLoggedIn = false;
  isSidebarHidden = true;
  layoutSub: Subscription;
  responsiveSub: Subscription;
  hasConfigurationErrors = false;
  headerSize = 75;
  isFocusContent = false;
  canSeeCart : boolean;
  actAsSub: Subscription;
  focusContentSub: Subscription;

  constructor(
    private router: Router,
    private globalConfig: GlobalConfigService,
    private localizationService: LocalizationService,
    private frontEndContextService: FrontendContextService,
    private contactService: ContactUsService,
    private broadcastService: BroadcastService,
    private permissionService: PermissionService,
    private sessionService: MibpSessionService,
    private routerEventSerice: RouterEventService,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {
    this.isLoggedIn = this.sessionService.hasActiveUser();
    this.responsiveSub = this.broadcastService.responsiveBreakpoint.subscribe(e => this.onResponsiveUpdate(e));
    this.isTestingFeatureManagement = broadcastService.snapshot.globalConfig.mySandvikFeaturesEnabled && broadcastService.snapshot.globalConfig.enableFeatureTestNotice;
    this.isSupportTeamMember = permissionService.test({ isSupportTeamMember: true });
    this.broadcastService.hasConfigurationErrors.subscribe(hasErrors => this.hasConfigurationErrors = hasErrors);
    this.showNewContactUsItemEnquiryForm = globalConfig.enableNewContactUs && this.sessionService.current?.user?.enabledFeatures?.includes(MySandvikFeatures.ContactUsFormItemEnquiry);
    this.testCartPermission();
  }

  private onResponsiveUpdate(e: ResponsiveEvent): void {

    if (e.lteq('xs')) {
      if (this.headerSize !== 105) {
        this.headerSize = 105;
      }
    } else {
      if (this.headerSize !== 75) {
        this.headerSize = 75;
      }
    }

  }

  showTabs(): boolean {
    if (this.router.url.indexOf('/dashboard') !== -1) {
      return false;
    } else {
      return true;
    }
  }

  private userChanged(): void {
    this.isSidebarHidden = false;

    if (this.isLoggedIn && !this.globalConfig.disableToggleSideBar) {
      const sidebarStatus = localStorage.getItem('mibp.mysandvik-sidebar.collapsed');
      if (sidebarStatus !== null) {
        this.isSidebarHidden = sidebarStatus === 'true' ? true : false;
        this.broadcastService.setSidebarCollapsed(this.isSidebarHidden);
      }
      //TODO: Analyse the above logic whether it's needed in any scenario
      // this.frontEndContextService.sidebarToggleSubject.subscribe(x=>{
      //   this.toggleSideBar(x);
      // });
    }
    else {
      this.isSidebarHidden = true;
    }

    // Hide sidebar if there is no user
    if (!this.sessionService.hasActiveUser()) {
      this.isSidebarHidden = true;
    }
  }


  ngOnInit(): void {
    this.actAsSub = this.broadcastService.deliverySequence.pipe(skip(1)).subscribe({
      next: () => this.onDeliverySequenceChanged()
    });    

    this.handleFocusContent();

    this.sessionSub = this.sessionService.current$.subscribe(() => this.userChanged());
    this.isLoggedIn = this.sessionService.isLoggedIn();

    this.lang = this.localizationService.getLang();

    if (this.sessionService.isLoggedIn()) {
      this.enableResourceStringEditor = this.frontEndContextService.testPermission(allPermissionPolicies.canManageTranslations);
      this.contactDialogSub = this.contactService.openContactDialog.subscribe(value => {
        this.contactUsFormData = value.formData;
        this.contactUsOpen = value.open;
      });
      this.newContactDialogSub = this.contactService.openNewContactUsDialog.subscribe(value => {
        this.newContactUsFormData = value.formData;
        this.contactUsOpen = value.open;
      });
    }

    if (this.isLoggedIn) {
      this.routerEventSerice.subscribeToRouterEvents();
    }

    if (this.isLoggedIn && !this.globalConfig.disableToggleSideBar) {
      this.sidebarToggleSub = this.frontEndContextService.sidebarToggleSubject.subscribe(x => {
        this.toggleSideBar(x);
      });
    }
  }

  handleFocusContent(): void {
    const focusQueryParam = this.route.snapshot.queryParams['focus'];

    if (focusQueryParam && focusQueryParam == 'content') {
      this.isFocusContent = true;
    }

    this.focusContentSub = this.broadcastService.focusContent.pipe(skip(1)).subscribe({
      next: (result) => this.isFocusContent = result
    });    
  }

  onDeliverySequenceChanged(): void {
   this.testCartPermission();
  }

  testCartPermission(){
    this.canSeeCart = this.permissionService.test(allPermissionPolicies.canSeeCart);
  }

  closeForm(success: any) {
    if (!success) {
      this.activeToast = this.toastService.showWithTitle(null, 'ContactUs_Message_Sent_Error', {
        isHtml: false,
        type: ToastType.Error,
        disableTimeOut: false
      });
    }
    else {

      this.newContactUsFormData = null;
      this.contactUsOpen = false;

      this.activeToast = this.toastService.showWithTitle('SupportCase_SubmittedSuccessfullyMessage', 'SupportCase_SubmittedSuccessfully', {
        isHtml: true,
        type: ToastType.Success,
        disableTimeOut: false,
        buttons: [
          { id: 'redirect', textResourceString: 'Feature_general_view_supportcases_name' },

        ]
      });
    }
    if (this.activeToast.onButtonClick) {
      this.toasterSubscription = this.activeToast.onButtonClick.subscribe(res => {
        if (res.id == 'redirect') {
          this.router.navigate([`/${this.frontEndContextService.getLang()}/support-cases`]);
          this.toastService.removeToast(this.activeToast.toastId);
        }
      });
    }
  }




  onContactUsFormClosed(): void {
    this.newContactUsFormData = null;
    this.contactUsOpen = false;
  }

  toggleSideBar(toggle: boolean): void {
    if (toggle) {
      this.isSidebarHidden = !this.isSidebarHidden;
      localStorage.setItem('mibp.mysandvik-sidebar.collapsed', `${this.isSidebarHidden}`);
      this.broadcastService.setSidebarCollapsed(this.isSidebarHidden);
      this.frontEndContextService.isSidebarHiddenSubject.next({ isHidden: this.isSidebarHidden, number: this.sidebarsize });
    }
  }

  ngOnDestroy(): void {
    this.contactDialogSub?.unsubscribe();
    this.layoutSub?.unsubscribe();
    this.responsiveSub?.unsubscribe();
    this.sessionSub?.unsubscribe();
    this.sidebarToggleSub?.unsubscribe();
    this.actAsSub?.unsubscribe();
    if( this.toasterSubscription){
      this.toasterSubscription.unsubscribe();
    }
  }
}
