<div [ngClass]="isHorizontal == true ? 'machine-filter-horizontal' : 'machine-filter-vertical'">
  <div class="machine-filter-dropdown" *ngIf="machineFilterEnabled">
    <label><mibp-resource-string [key]="'Global_MachineSerialNumber'"></mibp-resource-string></label>
    <mibp-dropdown
    #machineDropdown
    [data]="machines$"
    (filter)="filtermachines($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    (valueChange)="onDropdownValueChange($event,machineFilterType)"
    [take]="dropdownPageSize"
    [selectedOption]="initialMachineSelection"
    ></mibp-dropdown>
  </div>
  <div class="machine-filter-dropdown" *ngIf="machineModelFilterEnabled">
    <label><mibp-resource-string [key]="'Global_Machine_Model'"></mibp-resource-string></label>
    <mibp-dropdown
    #machineModelDropdown
    [data]="models$"
    (filter)="filterModels($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    (valueChange)="onDropdownValueChange($event,modelFilterType)"
    [selectedOption]="initialModelSelection"
    [take]="dropdownPageSize"></mibp-dropdown>
  </div>
  <div class="machine-filter-dropdown" *ngIf="machineSubGroupFilterEnabled">
    <label><mibp-resource-string [key]="'Global_Machine_ModelSubGroup'"></mibp-resource-string></label>
    <mibp-dropdown
    #machineSubGroupDropdown
    [data]="subgroups$"
    (filter)="filterSubGroups($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    (valueChange)="onDropdownValueChange($event,subgroupFilterType)"
    [selectedOption]="initialSubgroupSelection"
    [take]="dropdownPageSize"></mibp-dropdown>
  </div>
  <div class="machine-filter-dropdown" *ngIf="machineGroupFilterEnabled">
    <label><mibp-resource-string [key]="'Global_Machine_ModelGroup'"></mibp-resource-string></label>
    <mibp-dropdown
    #machineGroupDropdown
    [data]="groups$"
    (filter)="filterGroups($event)"
    [multiselectApply]="true"
    [newMultiSelectDesign]="true"
    [multiselect]="true"
    [selectedOption]="initialGroupSelection"
    (valueChange)="onDropdownValueChange($event,groupFilterType)"
    [take]="dropdownPageSize"></mibp-dropdown>
  </div>
</div>
