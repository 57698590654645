<nav *ngIf="!hideBreadCrumb" class="my-breadcrumbs hide--print" aria-label="Breadcrumb">
  <ul class="my-breadcrumbs__link-list">
    <ng-template ngFor let-crumb [ngForOf]="crumbs" let-i="index" let-isLast="last">
      <li *ngIf="!isLast">
        <a class="my-breadcrumbs__link" [routerLink]="crumb.route" [queryParams]="crumb.queryParams" [class.no-link]="!crumb.route">
          <span class="my-breadcrumbs__text">{{ crumb.text }}</span>
          <span class="my-breadcrumbs__separator" *ngIf="i < crumbs.length - 1">
            <svg focusable="false" fill="currentColor" width="20" height="20" aria-hidden="true">
              <use href="assets/images/icons.svg?v=23112101#chevron-right"></use>
            </svg>
          </span>
        </a>
      </li>
      <li *ngIf="isLast" class="no-link">
        <span class="my-breadcrumbs__text">{{ crumb.text }}</span>
      </li>
    </ng-template>
  </ul>
</nav>