<div>

  <div class="permission-wrapper" [class.collapsed]="!isExpanded" [class.expanded]="isExpanded" >
      <label style="    display: block;
      margin-bottom: 5px; text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;">
       <mibp-resource-string *ngIf="labelResourceString" [key]="labelResourceString"></mibp-resource-string>
       <span *ngIf="!labelResourceString">{{ permissionType }} permissions</span>
      </label>

      <div class="new-permission-list should-expand-collapse" style="display: flex;" (click)="toggleExpand($event, true)">
        <div style="flex: 0; padding-top: 6px;">

          <span translate="no" class="toolbarIcon material-icon should-expand-collapse" (click)="toggleExpand($event)" style="cursor: pointer;">{{isExpanded ? 'expand_more' : 'expand_less'}}</span>
          <span translate="no" class="material-icon toolbarIcon raw" (click)="openRawPrompt($event)" title="Click to set/read the raw permission value" *ngIf="isExpanded">content_copy</span>
          <span translate="no" class="material-icon toolbarIcon all" (click)="selectAll($event)" title="Click to Select All" *ngIf="isExpanded">done_all</span>
          <span translate="no" class="material-icon toolbarIcon none" (click)="selectNone($event)" title="Click to Select None" *ngIf="isExpanded">check_box</span>
          <span translate="no" class="material-icon toolbarIcon toggle" (click)="selectionToggle($event)" title="Click to Toggle selection" *ngIf="isExpanded">sync_alt</span>

        </div>
        <div style="flex: 1; " class="should-expand-collapse">
          <span (click)="toggleExpand($event)" *ngIf="permissionValues && currentValue === 0 && !isExpanded" class="no-permissions-text should-expand-collapse">No permission selected</span>
          <span (click)="toggleExpand($event)"  *ngIf="!permissionValues" class="no-permissions-text">Loading...</span>
          <div style="margin: 4px;">
            <input (click)="inputClick($event)" class="permission-filter-textbox" (keyup)="filterPermissions($event)" type="search" *ngIf="isExpanded" placeholder="Filter permissions" />
          </div>
          <div class="permissions">
            <span *ngIf="permissionValues?.length > 0 && filteredPermissionValues?.length ==0">No matches</span>
            <span (click)="togglePermission($event, permission)" *ngFor="let permission of filteredPermissionValues" class="permission" [class.active]="permission.checked">
              {{ permission.name }}
              <span translate="no" class="material-icon material-icon-large">{{permission.checked ? 'done' : 'add'}}</span>
            </span>
          </div>
        </div>
      </div>

      <!-- <div [style.display]="isExpanded ? 'block' : 'none'">
          <form [formGroup]="formGroup">
              <input type="text" formControlName="permission" [class.ng-invalid]="invalidValue" [class.ng-dirty]="invalidValue"
              (change)="currentValueChange($event)" />
            </form>

            <p style="margin-left: 28px; font-size: .85em; color: #f36a22; margin: 6px 0;" *ngIf="invalidValue">
                Invalid flag value
            </p>

      </div> -->
</div>

</div>
