
import { Component, ElementRef } from '@angular/core';
import { MibpLogger, LogService } from 'root/services';


@Component({
  selector: 'mibp-pdf-thumbnail-generator',
  styles: [`
    .hidden-pdf-generator {
      visibility: hidden;
      pointer-events: none;
      z-index: 0;
      height: 600px;
      position: absolute;
      left: -700px;
    }
  `],
  template: `
    <div class="hidden-pdf-generator" *ngFor="let pdf of pdfList; let index = index;" [attr.data-pdf-thumbnail]="index">
      <pdf-viewer *ngIf="pdf"
        [src]="pdf.data || pdf.url"
        [render-text]="true"
        [original-size]="false"
        [page]="1"
        [show-all]="false"
        (error)="onLoadError(index, $event)"
        (on-progress)="onProgress(index, $event)"
        (after-load-complete)="onLoadComplete(index, $event)"
        (page-rendered)="onPageRendered(index, $event)"
        ></pdf-viewer>
  </div>`
})
export class MibpPdfThumbnailGeneratorComponent {


  pdfList: {
    url: string;
    data: ArrayBuffer;
    resolve: (base64DataUrl: string) => void,
    reject: () => void;
    destroyTimer?: number;
  }[] = [];

  log: MibpLogger;

  constructor(private elm: ElementRef, private logService: LogService) {
    this.log = logService.withPrefix(`pdf-thumbnail-generator`);
  }

  onLoadComplete(pdfJobIndex: number, event:any): void {
    this.log.info(`[${pdfJobIndex}] PDF Loaded`, event);
  }
  onProgress(pdfJobIndex: number, event:any): void {
    this.log.info(`[${pdfJobIndex}] PDF Progress`, event);
  }

  onLoadError(pdfJobIndex: number, err: any): void {
    this.log.error(`[${pdfJobIndex}] Error loading PDF`, err);
    this.pdfList[pdfJobIndex].reject();
    this.pdfList[pdfJobIndex] = null;
  }

  /**
   * There's no event when PDF component is done working with the PDF data.
   * If we delete the data before it's done we will get a lot of errors.
   * This timer will delete the data after some time if no new page has recently been rendered.
   */
  startDestroyTimer(pdfJobIndex: number): void {
    if (this.pdfList[pdfJobIndex]) {
      this.pdfList[pdfJobIndex].destroyTimer = window.setTimeout(() => {
        this.pdfList[pdfJobIndex] = null;
        this.log.info(`[${this.pdfList.length}] PDF component was destroyed`);
      }, 1500);
    }
  }

  stopDestroyTimer(pdfJobIndex: number): void {
    if (this.pdfList[pdfJobIndex]) {
      window.clearTimeout(this.pdfList[pdfJobIndex].destroyTimer);
    }
  }

  onPageRendered(pdfJobIndex: number, renderEventArgs: {pageNumber: number}) {

    this.stopDestroyTimer(pdfJobIndex);

    this.log.info(`[${this.pdfList.length}] Page ${renderEventArgs.pageNumber} was rendered.`);

    if (renderEventArgs.pageNumber > 1) {
      this.startDestroyTimer(pdfJobIndex);
      return;
    }

    const pageCanvas = (this.elm.nativeElement as HTMLCanvasElement).querySelector(`div[data-pdf-thumbnail='${pdfJobIndex}'] canvas`);

    // Create an image element and use the canvas Data URL as a source
    const img = new Image();
    img.src = `${document.querySelector('canvas').toDataURL()}`;
    img.crossOrigin = 'anonymous';
    img.onload = () => {

      // Calculate a proper image size
      const originalWidth = pageCanvas.getBoundingClientRect().width;
      const originalHeight = pageCanvas.getBoundingClientRect().height;
      const newWidth = 250;
      const newHeight = ((newWidth / originalWidth)) * originalHeight;

      // When image is loaded caclate a proper ratio for the image
      const canvas = document.createElement('canvas');
      canvas.setAttribute('id', 'generatedimage');
      canvas.setAttribute('style', 'position: fixed; top: 100px; left:100px;z-index: 1000; border:1px solid orange;');
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      document.body.appendChild(canvas);

      this.log.info(`[${this.pdfList.length}] Thumbnail was created successfully`);
      this.pdfList[pdfJobIndex].resolve(canvas.toDataURL());
      // this.pdfList[pdfJobIndex] = null;
      canvas.parentNode.removeChild(canvas);
      this.startDestroyTimer(pdfJobIndex);
    };
  }

  public async createThumbnail(url: string): Promise<string> {

    return new Promise((resolve, reject) => {

      this.log.info(`[${this.pdfList.length}] PDF Thumbnail Creation from URL queued...`);

      this.pdfList.push({
        url: url,
        data: null,
        resolve: resolve,
        reject: reject
      });
    });

  }

  public async createThumbnailFromFile(file: File): Promise<string> {
    const data = await file.arrayBuffer();
    return new Promise((resolve, reject) => {
      this.log.info(`[${this.pdfList.length}] PDF Thumbnail Creation from File queued...`);
      this.pdfList.push({
        url: null,
        data: data,
        resolve: resolve,
        reject: reject
      });
    });
  }

}
