import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogButton } from '../dialog/dialog.types';
import { CartService, LoaderService, LocalizationService, NoticebarService } from 'root/services';
import { ButtonColors } from '../button/button.enum';
import { NoticeType } from '../noticebar/noticebar.enum';

@Component({
  selector: 'mibp-quick-add-import',
  templateUrl: './quick-add-import.component.html',
  styleUrls: ['./quick-add-import.component.scss']
})

export class QuickAddImportComponent implements OnInit {
  public fileName: string;
  @ViewChild('fileInputQuickAdd') fileInput;
  @ViewChild('dialogHelp') dialog: DialogComponent;
  @Output() importedFiled = new EventEmitter();
  dialogButtons: { resourceKey: string; id: string; color: any; disabled: any; }[];
  header: string;
  fileinvalid: string;
  fileTemplateInvalid: string;
  fileInputId: number;

  constructor(private cartService: CartService, private loaderService: LoaderService, private noticebar: NoticebarService, private localizationService: LocalizationService) { }

  ngOnInit() {
    this.fileInputId = this.generateId();
    this.dialogButtons = [{
      resourceKey: 'Global_Dialog_Close',
      id: 'ok',
      color: ButtonColors.Blue,
      disabled: false
    }];
    this.localizationService.using(['Global_Invalid_FileFormat', 'Global_Invalid_FileFormat_Message', 'Global_Invalid_FileTemplate'], resourceStrings => {
      this.header = resourceStrings[0];
      this.fileinvalid = resourceStrings[1];
      this.fileTemplateInvalid = resourceStrings[2];
    });
  }

  private generateId(): number {
    return Math.floor((Math.random() * 1000000) + 1);
  }

  public fileChangeEvent(fileInput: any) {
    if (fileInput.target.files.length === 0) {
      this.fileInput.nativeElement.value = "";
      this.fileName = "";
    }
    if (fileInput.target.files && fileInput.target.files[0]) {
      if (fileInput.target.files && fileInput.target.files.length > 0 ) {
        this.fileName = fileInput.target.value.split('\\').pop();
      }
    }
  }

  onSelectFile(event) {
    this.fileInput.nativeElement.value = "";
    this.fileName = "";
  }

  public upload() {
    const fileBrowser = this.fileInput.nativeElement;
    this.loaderService.showFullScreenLoader();
    const regImport = new RegExp('xlsx$');
    const regImport1 = new RegExp('csv$');
    const regImport2 = new RegExp('xls$');
    const file: File = fileBrowser.files[0];
    if(regImport.test(file.name)||regImport1.test(file.name)||regImport1.test(file.name)){
      this.cartService.uploadFile(fileBrowser).then((data) => {
        this.loaderService.hideFullScreenLoader();
        if(data.length!=0){
          this.importedFiled.emit(data);
          this.fileInput.nativeElement.value = "";
          this.fileName = "";
        }
        else{
          this.noticebar.showText(`<h2 class="text-error">${this.fileTemplateInvalid}</h2>`,NoticeType.Error,true);
        }
      }).catch(mibpException =>  {
        const importCartException = mibpException.stack?.errors.find(e => e.exceptionType === "ImportCartException");
        let htmlStringError =`<h2 class="text-error">${this.header}</h2><div class="text"><ul><li>${this.fileinvalid}</li></ul></div>`;

        if (importCartException ) {
          htmlStringError =
          `<h2 class="text-error">${this.header}</h2><div class="text">
            <ul>
              <li>${importCartException.description}</li>
              <li>${this.fileinvalid}</li>
            </ul></div>`;
        }
        this.noticebar.showText(htmlStringError, NoticeType.Error, true);
        this.loaderService.hideFullScreenLoader();
      });
    }
    else {
      this.loaderService.hideFullScreenLoader();
      const htmlString = `<h2 class="text-error">${this.header}</h2><div class="text"><ul><li>${this.fileinvalid}</li></ul></div>`;
      this.noticebar.showText(htmlString,NoticeType.Error, true);
    }
  }

  public onDialogButton(btn: DialogButton) {
    this.dialog.close();
  }
}
