/* tslint:disable */
/* eslint-disable */
export enum UserDateFormat {
  BrowserDefault = 0,
  YYMMDDDash = 1,
  YYMMDDSlash = 2,
  MMDDYYSlash = 3,
  MMDDYYDash = 4,
  DDMMYYSlash = 5,
  DDMMYYDash = 6
}
