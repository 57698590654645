/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InternalReportOptions } from '../../models/internal-report-options';
import { InternalReportResponseViewModel } from '../../models/internal-report-response-view-model';

export interface GetInternalReportData$Params {
      body?: InternalReportOptions
}

export function getInternalReportData(http: HttpClient, rootUrl: string, params?: GetInternalReportData$Params, context?: HttpContext): Observable<StrictHttpResponse<InternalReportResponseViewModel>> {
  const rb = new RequestBuilder(rootUrl, getInternalReportData.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InternalReportResponseViewModel>;
    })
  );
}

getInternalReportData.PATH = '/internalreports/internalreportdata';
