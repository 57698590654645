/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChangeTrackingDetailsDto } from '../models/change-tracking-details-dto';
import { ChangeTrackingHistoryDto } from '../models/change-tracking-history-dto';
import { getChangeDetails } from '../fn/change-tracking/get-change-details';
import { GetChangeDetails$Params } from '../fn/change-tracking/get-change-details';
import { getChangeDetails$Plain } from '../fn/change-tracking/get-change-details-plain';
import { GetChangeDetails$Plain$Params } from '../fn/change-tracking/get-change-details-plain';
import { getChangeTrackingId } from '../fn/change-tracking/get-change-tracking-id';
import { GetChangeTrackingId$Params } from '../fn/change-tracking/get-change-tracking-id';
import { getChangeTrackingId$Plain } from '../fn/change-tracking/get-change-tracking-id-plain';
import { GetChangeTrackingId$Plain$Params } from '../fn/change-tracking/get-change-tracking-id-plain';
import { listChangesByChangeTrackingId } from '../fn/change-tracking/list-changes-by-change-tracking-id';
import { ListChangesByChangeTrackingId$Params } from '../fn/change-tracking/list-changes-by-change-tracking-id';
import { listChangesByChangeTrackingId$Plain } from '../fn/change-tracking/list-changes-by-change-tracking-id-plain';
import { ListChangesByChangeTrackingId$Plain$Params } from '../fn/change-tracking/list-changes-by-change-tracking-id-plain';

@Injectable({ providedIn: 'root' })
export class ChangeTrackingApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `changeTrackingControllerListChangesByChangeTrackingIdchangeTrackingId()` */
  static readonly ChangeTrackingControllerListChangesByChangeTrackingIdchangeTrackingIdPath = '/changetracking/{changeTrackingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listChangesByChangeTrackingId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listChangesByChangeTrackingId$Plain$Response(params: ListChangesByChangeTrackingId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChangeTrackingHistoryDto>>> {
    return listChangesByChangeTrackingId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listChangesByChangeTrackingId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listChangesByChangeTrackingId$Plain(params: ListChangesByChangeTrackingId$Plain$Params, context?: HttpContext): Observable<Array<ChangeTrackingHistoryDto>> {
    return this.listChangesByChangeTrackingId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChangeTrackingHistoryDto>>): Array<ChangeTrackingHistoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listChangesByChangeTrackingId()` instead.
   *
   * This method doesn't expect any request body.
   */
  listChangesByChangeTrackingId$Response(params: ListChangesByChangeTrackingId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChangeTrackingHistoryDto>>> {
    return listChangesByChangeTrackingId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listChangesByChangeTrackingId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listChangesByChangeTrackingId(params: ListChangesByChangeTrackingId$Params, context?: HttpContext): Observable<Array<ChangeTrackingHistoryDto>> {
    return this.listChangesByChangeTrackingId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChangeTrackingHistoryDto>>): Array<ChangeTrackingHistoryDto> => r.body)
    );
  }

  /** Path part for operation `changeTrackingControllerGetChangeTrackingIdentityTypeEntityId()` */
  static readonly ChangeTrackingControllerGetChangeTrackingIdentityTypeEntityIdPath = '/changetracking/trackingid/{entityType}/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangeTrackingId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeTrackingId$Plain$Response(params: GetChangeTrackingId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getChangeTrackingId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangeTrackingId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeTrackingId$Plain(params: GetChangeTrackingId$Plain$Params, context?: HttpContext): Observable<number> {
    return this.getChangeTrackingId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangeTrackingId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeTrackingId$Response(params: GetChangeTrackingId$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getChangeTrackingId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangeTrackingId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeTrackingId(params: GetChangeTrackingId$Params, context?: HttpContext): Observable<number> {
    return this.getChangeTrackingId$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `changeTrackingControllerGetChangeDetailschangeId()` */
  static readonly ChangeTrackingControllerGetChangeDetailschangeIdPath = '/changetracking/change/{changeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangeDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeDetails$Plain$Response(params: GetChangeDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeTrackingDetailsDto>> {
    return getChangeDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangeDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeDetails$Plain(params: GetChangeDetails$Plain$Params, context?: HttpContext): Observable<ChangeTrackingDetailsDto> {
    return this.getChangeDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeTrackingDetailsDto>): ChangeTrackingDetailsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangeDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeDetails$Response(params: GetChangeDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeTrackingDetailsDto>> {
    return getChangeDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangeDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeDetails(params: GetChangeDetails$Params, context?: HttpContext): Observable<ChangeTrackingDetailsDto> {
    return this.getChangeDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeTrackingDetailsDto>): ChangeTrackingDetailsDto => r.body)
    );
  }

}
