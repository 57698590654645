import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  Refiner, DateRefiner, TextRefiner, RefinerChangeEvent,
  RefinerValue, CheckboxListRefiner, CheckboxListItem, ListRefiner, RadiobuttonListRefiner, DropdownMultiSelectRefiner, DropdownRefiner, NumberRefiner
} from './refiner.types';
import { DropdownInput } from '../dropdown';
import { MibpLogger, LogService, BroadcastService, FormattingService, ScrollToService } from 'root/services';
import { format, parseISO } from 'date-fns';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mibp-refiner',
  templateUrl: './refiner.component.html',
  styleUrls: ['./refiner.component.scss']
})
export class RefinerComponent implements OnInit, OnDestroy {
  log: MibpLogger;
  isCollapsed = true;
  isPending = false;
  expanded = {};
  dateFormatMacros = {};

  initialized: boolean;
  changeTimer;
  currentValues = {};
  prevValues = null;
  configuration: Refiner[] | any[];
  dateFormatSub: Subscription;
  queryParamSub: Subscription;

  objectKeys = Object.keys;
  responsiveSub: Subscription;
  useMobileLayout = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    logger: LogService,
    private broadcastService: BroadcastService,
    private formattingService: FormattingService,
    private broadcast: BroadcastService,
    private scrollTo: ScrollToService) {
    this.log = logger.withPrefix('refiner');

  }

  /**
   * Name of querystring parameter to use for this refiner
   */
  @Input() querystringParameter: string;

  /**
   * The time to wait in ms before after filter change before filter event is triggered
   */
  @Input() delayMs = 750;

  /***
   * The opacity of the ng-content when filter has changed but event is not yet triggered
   */
  @Input() pendingOpacity = 0.7;

  /***
   * The cursor of the ng-content when filter has chagned but event is not yet triggered
   */
  @Input() pendingCursor = 'progress';

  /**
   * Event that is triggered when refiner is first setup and when filter has changed
   */
  @Output() filter = new EventEmitter<RefinerChangeEvent>();

  /**
   * Event that is triggered when refiner has changed but filter event is not yet emitted
   */
  // TODO: Rename this output so it's not matching a native event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<RefinerChangeEvent>();

  /***
   * Refiner configuration
   */
  @Input()
  set config(refiners: Refiner[]) {
    refiners.forEach(ref => {
      if (ref instanceof DateRefiner) {
        if (typeof this.currentValues[ref.name] === 'undefined') {

          ref.refinerModel =  ref.dateValue ? ref.dateValue : null;
          this.currentValues[ref.name] = ref.refinerModel;
        }
      } else if (this.isCheckboxListRefiner(ref)) {
        if (typeof this.currentValues[ref.name] === 'undefined') {
          this.currentValues[ref.name] = ref.checkedValues instanceof Array ? ref.checkedValues.slice(0) : [];
        }
        if (ref.checkedValues !== null && ref.checkedValues.length > 0) {
          this.expanded[ref.name] = true;
        }
      } else if (this.isRadiobuttonListRefiner(ref)) {
        if (typeof this.currentValues[ref.name] === 'undefined') {
          this.currentValues[ref.name] = typeof ref.selectedValue === 'undefined' ? null : ref.selectedValue;
        }
        if (ref.selectedValue !== null) {
          this.expanded[ref.name] = true;
        }
      } else if (this.isDropdownMultiSelectRefiner(ref)) {
        if (typeof this.currentValues[ref.name] === 'undefined') {
          this.currentValues[ref.name] = ref.selectedValues && ref.selectedValues instanceof Array ? ref.selectedValues : [];
        }
      } else if (this.isDropdownRefiner(ref)) {
        if (typeof this.currentValues[ref.name] === 'undefined') {
          this.currentValues[ref.name] = typeof ref.selectedValues === 'undefined' ? null : ref.selectedValues;
        }
      } else if (this.isTextRefiner(ref)) {
        if (typeof this.currentValues[ref.name] === 'undefined') {
          this.currentValues[ref.name] = typeof ref.textValue === 'undefined' ? null : ref.textValue;
          ref.refinerModel = ref.textValue;
        }
      } else if (this.isNumberRefiner(ref)) {
        if (typeof this.currentValues[ref.name] === 'undefined') {
          this.currentValues[ref.name] = typeof ref.numberValue === 'undefined' ? null : ref.numberValue;
          ref.refinerModel = ref.numberValue;
        }
      } else if (this.isListRefiner(ref)) {
        if (typeof this.currentValues[ref.name] === 'undefined') {
          this.currentValues[ref.name] = ref.value;
          ref.refinerModel = ref.value;
        }
      } else {
        throw new Error(`Could not identify refiner ${JSON.stringify(ref)}`);
      }
    });
    const hasConfiguration = !!this.configuration;
    this.configuration = refiners;

    if (!hasConfiguration) {
      this.triggerChangeEvent(this.filter);
    }
  }

  setValue(refinerName: string, value: any): void {
    const refiner = this.configuration.find(f => f.name === refinerName);
    if (refiner) {
      this.log.error("SETVALUE NOT IMPLEMENTED", refinerName, refiner, value);
      // NOT IMPLEMENTED
    }
  }

  ngOnDestroy(): void {
    this.queryParamSub?.unsubscribe();
    this.dateFormatSub.unsubscribe();
  }

  ngOnInit(): void {

    this.dateFormatSub = this.broadcastService.dateFormatResourceStrings.subscribe(() => {
      this.dateFormatMacros = {
        dateFormat: this.formattingService.getDateFormatPlaceholder()
      };
    });

    this.queryParamSub = this.route.queryParams.subscribe(params => {
      if (this.querystringParameter) {
        if (typeof params[this.querystringParameter] !== 'undefined') {
          let valuesFromQuerystring: any;

          try {
            valuesFromQuerystring = JSON.parse(params[this.querystringParameter]);
          } catch {}

          if (valuesFromQuerystring) {
            this.configuration.forEach(refiner => {
              const refinerName = refiner.name;
              if (typeof valuesFromQuerystring[refinerName] !== 'undefined') {
                if (this.isDateRefiner(refiner)) {
                  this.currentValues[refinerName] = parseISO(valuesFromQuerystring[refinerName]);
                  refiner.refinerModel = this.currentValues[refinerName];
                  refiner['dateValue'] = valuesFromQuerystring[refinerName];
                } else if (this.isTextRefiner(refiner)) {
                  this.currentValues[refinerName] = valuesFromQuerystring[refinerName];
                  refiner.refinerModel = valuesFromQuerystring[refinerName];
                  refiner['_model'] = valuesFromQuerystring[refinerName];
                } else if (this.isNumberRefiner(refiner)) {
                  this.currentValues[refinerName] = valuesFromQuerystring[refinerName];
                  refiner.refinerModel = valuesFromQuerystring[refinerName];
                  refiner['_model'] = valuesFromQuerystring[refinerName];
                } else if (this.isListRefiner(refiner)) {
                  this.currentValues[refinerName] = valuesFromQuerystring[refinerName];
                  refiner.refinerModel = valuesFromQuerystring[refinerName];
                  refiner.value = refiner.refinerModel;
                } else if (this.isCheckboxListRefiner(refiner)) {
                  if (valuesFromQuerystring[refinerName] instanceof Array) {
                    this.currentValues[refinerName] = valuesFromQuerystring[refinerName].slice(0);
                    refiner.refinerModel = this.currentValues[refinerName];
                    if (this.currentValues[refinerName].length > 0) {
                      if (!this.initialized) {
                        this.expanded[refinerName] = true;
                      }
                    }
                  }
                } else if (this.isRadiobuttonListRefiner(refiner)) {
                  this.currentValues[refinerName] = valuesFromQuerystring[refinerName];
                  refiner.refinerModel = this.currentValues[refinerName];
                  if (this.currentValues[refinerName] !== null || this.currentValues[refinerName] !== 'undefined') {
                    if (!this.initialized) {
                      this.expanded[refinerName] = true;
                    }
                  }
                } else if (this.isDropdownMultiSelectRefiner(refiner)) {
                  if (valuesFromQuerystring[refinerName] instanceof Array) {
                    this.currentValues[refinerName] = valuesFromQuerystring[refinerName].slice(0);
                    refiner.refinerModel = this.currentValues[refinerName];
                    if (this.currentValues[refinerName].length > 0) {
                      if (!this.initialized) {
                        this.expanded[refinerName] = true;
                      }
                    }
                  }
                } else if (this.isDropdownRefiner(refiner)) {
                  this.currentValues[refinerName] = valuesFromQuerystring[refinerName];
                  refiner.refinerModel = this.currentValues[refinerName];
                  if (this.currentValues[refinerName] !== null || this.currentValues[refinerName] !== 'undefined') {
                    if (!this.initialized) {
                      this.expanded[refinerName] = true;
                    }
                  }
                }
              }
            });
          }

        }
        this.triggerChangeEvent(this.filter, true);
        this.initialized = true;
      }
    });

    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe((screen) => {
      this.useMobileLayout = screen.lteq('s');
      if (this.isCollapsed && !this.useMobileLayout) {
        this.isCollapsed = false;
      }
    });

  }

  /**
   * Test if a checkbox list item is checked
   */
  testChecked(refiner: CheckboxListRefiner, item: CheckboxListItem) {
    return this.currentValues[refiner.name].indexOf(item.value) !== -1;
  }

  /**
   * Test if a radio button list item is selected
   */
  testSelected(refiner: CheckboxListRefiner, item: CheckboxListItem) {
    return this.currentValues[refiner.name] === item.value;
  }

  hasValue(refiner: CheckboxListRefiner) {
    if (this.currentValues[refiner.name] instanceof Array) {
      if (this.currentValues[refiner.name].length > 0) {
        return true;
      }
    }
    return false;
  }

  private startChangeTimer() {
    this.isPending = true;
    if (this.changeTimer) {
      clearTimeout(this.changeTimer);
    }
    this.changeTimer = setTimeout(() => this.triggerChangeEvent(this.filter), this.delayMs);
  }

  onFilterDropdown(refiner: Refiner, value: any) {
    this.log.debug("On filter dropdown", value, refiner);
  }

  onDropdownValueChanged(refiner: Refiner, dropdownInput: DropdownInput | DropdownInput[]) {
    let newValue: any;
    if (dropdownInput instanceof Array) {
      const values: string[] = [];
      this.log.debug("Dropdown change", dropdownInput);
      dropdownInput.map( dr => {
        this.log.debug("Value", dr);
        values.push(dr.value);
      });
      newValue = values;
    } else {
      newValue = dropdownInput.value.toString();
    }
    this.currentValues[refiner.name] = newValue;
    this.log.debug("Dropdown change value:", refiner, this.currentValues[refiner.name]);
    this.startChangeTimer();
  }

  private triggerChangeEvent(emitType: EventEmitter<RefinerChangeEvent>, isFromNavigation = false) {
    const e = <RefinerChangeEvent> {
      after: [],
      before: [],
      filters: Object.assign({}, this.currentValues)
    };

    if (this.prevValues !== null) {
      this.configuration.forEach(refiner => {
        if (typeof this.prevValues[refiner.name] !== 'undefined') {
          e.before.push(<RefinerValue>{
            name: refiner.name,
            value: this.prevValues[refiner.name]
          });
        }
      });
    } else {
      e.before = null;
    }




    this.configuration.forEach(refiner => {
      if (typeof this.currentValues[refiner.name] !== 'undefined') {
        e.after.push(<RefinerValue>{
          name: refiner.name,
          value: this.currentValues[refiner.name] instanceof Array ?
            this.currentValues[refiner.name].slice(0) :
            (typeof this.currentValues[refiner.name] === 'undefined' ? null : this.currentValues[refiner.name])
        });
      }
    });

    this.prevValues = Object.assign({}, this.currentValues);

    if (this.querystringParameter && !isFromNavigation) {
      let url = this.router.url;
      if (url.indexOf('?') !== -1) {
        url = url.substr(0, url.indexOf('?'));
      }

      if (e.before !== null) {
        const qsParams = {};
        qsParams[this.querystringParameter] = this.buildQuerystringParameter(e.after);
        this.scrollTo.stopNextScrollToTop();
        this.router.navigate([url], {queryParams: qsParams, queryParamsHandling: 'merge'});
      }
    } else {
      emitType.emit(e);
    }
    this.isPending = false;
  }

  buildQuerystringParameter(values: RefinerValue[]) {
    const qs = {};
    let hasAny = false;

    values.forEach(val => {
      let qsValue = val.value;
      if (qsValue !== null) {
        if (qsValue instanceof Array && qsValue.length === 0) {
          return;
        }

        if (typeof qsValue === 'object' && qsValue instanceof Date) {
          qsValue = format(qsValue, 'yyyy-MM-dd');
        }
        qs[val.name] = qsValue;
        hasAny = true;
      }
    });
    return hasAny ? JSON.stringify(qs) : null;
  }

  updateCheckboxList(refinerName: string, items: CheckboxListItem[]) {
    if (this.configuration) {
      const refiner = this.configuration.find(ref => ref.name);
      this.log.debug("Found refiner to update", refinerName, refiner, items);
    }
  }

  dateChanged(refiner: Refiner, value: any) {
    if (value !== null && value instanceof Date) {
      this.currentValues[refiner.name] = value;
    } else {
      this.currentValues[refiner.name] = null;
    }
    this.startChangeTimer();
  }

  textKeyUp(refiner: Refiner, value: any) {
    this.currentValues[refiner.name] = value;
    this.startChangeTimer();
  }

  numberKeyUp(refiner: Refiner, value: any) {
    this.currentValues[refiner.name] = value;
    this.startChangeTimer();
  }

  chekboxCheck(refiner: Refiner, isChecked, itemValue: string) {
    const itemIndex = this.currentValues[refiner.name].indexOf(itemValue);
    if (!isChecked) {
      if (itemIndex !== -1) {
        this.currentValues[refiner.name].splice(itemIndex, 1);
      }
    } else {
      if (itemIndex === -1) {
        this.currentValues[refiner.name].push(itemValue);
      }
    }
    this.startChangeTimer();
  }

  radiobuttonCheck(refiner: Refiner, itemValue: string) {
    this.currentValues[refiner.name] = itemValue;
    this.startChangeTimer();
  }

  selectListItem(refiner: ListRefiner, activeItem: string) {
    this.currentValues[refiner.name] = activeItem;
    refiner.value = activeItem;
    this.startChangeTimer();
  }

  public isDateRefiner(object: any): object is DateRefiner {
    return object instanceof DateRefiner;
  }

  public isCheckboxListRefiner(object: any): object is CheckboxListRefiner {
    return object instanceof CheckboxListRefiner;
  }

  public isRadiobuttonListRefiner(object: any): object is RadiobuttonListRefiner {
    return object instanceof RadiobuttonListRefiner;
  }

  public isDropdownRefiner(object: any): object is DropdownRefiner {
    return object instanceof DropdownRefiner;
  }

  public isDropdownMultiSelectRefiner(object: any): object is DropdownMultiSelectRefiner {
    return object instanceof DropdownMultiSelectRefiner;
  }

  public isTextRefiner(object: any): object is TextRefiner {
    return object instanceof TextRefiner;
  }

  public isNumberRefiner(object: any): object is NumberRefiner {
    return object instanceof NumberRefiner;
  }

  public isListRefiner(object: any): object is ListRefiner {
    return object instanceof ListRefiner;
  }

}
