/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressVm } from '../models/address-vm';
import { CountryViewModel } from '../models/country-view-model';
import { DeliverySequence } from '../models/delivery-sequence';
import { DeliverySequenceDetailsViewModel } from '../models/delivery-sequence-details-view-model';
import { DeliverySequenceIndexSearchResponse } from '../models/delivery-sequence-index-search-response';
import { getActiveDeliverySequenceDetails } from '../fn/delivery-sequence/get-active-delivery-sequence-details';
import { GetActiveDeliverySequenceDetails$Params } from '../fn/delivery-sequence/get-active-delivery-sequence-details';
import { getActiveDeliverySequenceDetails$Plain } from '../fn/delivery-sequence/get-active-delivery-sequence-details-plain';
import { GetActiveDeliverySequenceDetails$Plain$Params } from '../fn/delivery-sequence/get-active-delivery-sequence-details-plain';
import { getAddressLinesForActiveDeliverySequence } from '../fn/delivery-sequence/get-address-lines-for-active-delivery-sequence';
import { GetAddressLinesForActiveDeliverySequence$Params } from '../fn/delivery-sequence/get-address-lines-for-active-delivery-sequence';
import { getAddressLinesForActiveDeliverySequence$Plain } from '../fn/delivery-sequence/get-address-lines-for-active-delivery-sequence-plain';
import { GetAddressLinesForActiveDeliverySequence$Plain$Params } from '../fn/delivery-sequence/get-address-lines-for-active-delivery-sequence-plain';
import { getAllDefaultDeliverySequencesforUsers } from '../fn/delivery-sequence/get-all-default-delivery-sequencesfor-users';
import { GetAllDefaultDeliverySequencesforUsers$Params } from '../fn/delivery-sequence/get-all-default-delivery-sequencesfor-users';
import { getAllDefaultDeliverySequencesforUsers$Plain } from '../fn/delivery-sequence/get-all-default-delivery-sequencesfor-users-plain';
import { GetAllDefaultDeliverySequencesforUsers$Plain$Params } from '../fn/delivery-sequence/get-all-default-delivery-sequencesfor-users-plain';
import { getAllWithout000 } from '../fn/delivery-sequence/get-all-without-000';
import { GetAllWithout000$Params } from '../fn/delivery-sequence/get-all-without-000';
import { getAllWithout000$Plain } from '../fn/delivery-sequence/get-all-without-000-plain';
import { GetAllWithout000$Plain$Params } from '../fn/delivery-sequence/get-all-without-000-plain';
import { getCountriesForActiveCompany } from '../fn/delivery-sequence/get-countries-for-active-company';
import { GetCountriesForActiveCompany$Params } from '../fn/delivery-sequence/get-countries-for-active-company';
import { getCountriesForActiveCompany$Plain } from '../fn/delivery-sequence/get-countries-for-active-company-plain';
import { GetCountriesForActiveCompany$Plain$Params } from '../fn/delivery-sequence/get-countries-for-active-company-plain';
import { gridSearchDeliverySequenceForOrganization } from '../fn/delivery-sequence/grid-search-delivery-sequence-for-organization';
import { GridSearchDeliverySequenceForOrganization$Params } from '../fn/delivery-sequence/grid-search-delivery-sequence-for-organization';
import { gridSearchDeliverySequenceForOrganization$Plain } from '../fn/delivery-sequence/grid-search-delivery-sequence-for-organization-plain';
import { GridSearchDeliverySequenceForOrganization$Plain$Params } from '../fn/delivery-sequence/grid-search-delivery-sequence-for-organization-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { reScopeAll } from '../fn/delivery-sequence/re-scope-all';
import { ReScopeAll$Params } from '../fn/delivery-sequence/re-scope-all';
import { reScopeAll$Plain } from '../fn/delivery-sequence/re-scope-all-plain';
import { ReScopeAll$Plain$Params } from '../fn/delivery-sequence/re-scope-all-plain';
import { search } from '../fn/delivery-sequence/search';
import { Search$Params } from '../fn/delivery-sequence/search';
import { search$Plain } from '../fn/delivery-sequence/search-plain';
import { Search$Plain$Params } from '../fn/delivery-sequence/search-plain';

@Injectable({ providedIn: 'root' })
export class DeliverySequenceApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deliverySequenceControllerReScopeAllcompanyCodeCustomerNumberDeliverySequenceNumber()` */
  static readonly DeliverySequenceControllerReScopeAllcompanyCodeCustomerNumberDeliverySequenceNumberPath = '/deliverysequence/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAll$Plain$Response(params?: ReScopeAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeAll$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAll$Plain(params?: ReScopeAll$Plain$Params, context?: HttpContext): Observable<number> {
    return this.reScopeAll$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAll$Response(params?: ReScopeAll$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAll(params?: ReScopeAll$Params, context?: HttpContext): Observable<number> {
    return this.reScopeAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `deliverySequenceControllerSearchcompanyCodeCustomerNumberQueryIndexTake()` */
  static readonly DeliverySequenceControllerSearchcompanyCodeCustomerNumberQueryIndexTakePath = '/deliverysequence/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  search$Plain$Response(params?: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceIndexSearchResponse>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  search$Plain(params?: Search$Plain$Params, context?: HttpContext): Observable<DeliverySequenceIndexSearchResponse> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceIndexSearchResponse>): DeliverySequenceIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method doesn't expect any request body.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceIndexSearchResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<DeliverySequenceIndexSearchResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceIndexSearchResponse>): DeliverySequenceIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `deliverySequenceControllerGetAllWithout000BusinessRelationId()` */
  static readonly DeliverySequenceControllerGetAllWithout000BusinessRelationIdPath = '/deliverysequence/businessrelations/{businessRelationId}/without000';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllWithout000$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWithout000$Plain$Response(params: GetAllWithout000$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliverySequence>>> {
    return getAllWithout000$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllWithout000$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWithout000$Plain(params: GetAllWithout000$Plain$Params, context?: HttpContext): Observable<Array<DeliverySequence>> {
    return this.getAllWithout000$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliverySequence>>): Array<DeliverySequence> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllWithout000()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWithout000$Response(params: GetAllWithout000$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliverySequence>>> {
    return getAllWithout000(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllWithout000$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWithout000(params: GetAllWithout000$Params, context?: HttpContext): Observable<Array<DeliverySequence>> {
    return this.getAllWithout000$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliverySequence>>): Array<DeliverySequence> => r.body)
    );
  }

  /** Path part for operation `deliverySequenceControllerGetAllDefaultDeliverySequencesforUsersbusinessRelationId()` */
  static readonly DeliverySequenceControllerGetAllDefaultDeliverySequencesforUsersbusinessRelationIdPath = '/deliverysequence/businessrelations/{businessRelationId}/defaultdeliverysequences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDefaultDeliverySequencesforUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDefaultDeliverySequencesforUsers$Plain$Response(params: GetAllDefaultDeliverySequencesforUsers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliverySequence>>> {
    return getAllDefaultDeliverySequencesforUsers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDefaultDeliverySequencesforUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDefaultDeliverySequencesforUsers$Plain(params: GetAllDefaultDeliverySequencesforUsers$Plain$Params, context?: HttpContext): Observable<Array<DeliverySequence>> {
    return this.getAllDefaultDeliverySequencesforUsers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliverySequence>>): Array<DeliverySequence> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDefaultDeliverySequencesforUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDefaultDeliverySequencesforUsers$Response(params: GetAllDefaultDeliverySequencesforUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliverySequence>>> {
    return getAllDefaultDeliverySequencesforUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDefaultDeliverySequencesforUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDefaultDeliverySequencesforUsers(params: GetAllDefaultDeliverySequencesforUsers$Params, context?: HttpContext): Observable<Array<DeliverySequence>> {
    return this.getAllDefaultDeliverySequencesforUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliverySequence>>): Array<DeliverySequence> => r.body)
    );
  }

  /** Path part for operation `deliverySequenceControllerGetAddressLinesForActiveDeliverySequence()` */
  static readonly DeliverySequenceControllerGetAddressLinesForActiveDeliverySequencePath = '/deliverysequence/active/addresslines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressLinesForActiveDeliverySequence$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForActiveDeliverySequence$Plain$Response(params?: GetAddressLinesForActiveDeliverySequence$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressVm>> {
    return getAddressLinesForActiveDeliverySequence$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddressLinesForActiveDeliverySequence$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForActiveDeliverySequence$Plain(params?: GetAddressLinesForActiveDeliverySequence$Plain$Params, context?: HttpContext): Observable<AddressVm> {
    return this.getAddressLinesForActiveDeliverySequence$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressVm>): AddressVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressLinesForActiveDeliverySequence()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForActiveDeliverySequence$Response(params?: GetAddressLinesForActiveDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressVm>> {
    return getAddressLinesForActiveDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddressLinesForActiveDeliverySequence$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressLinesForActiveDeliverySequence(params?: GetAddressLinesForActiveDeliverySequence$Params, context?: HttpContext): Observable<AddressVm> {
    return this.getAddressLinesForActiveDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressVm>): AddressVm => r.body)
    );
  }

  /** Path part for operation `deliverySequenceControllerGetCountriesForActiveCompany()` */
  static readonly DeliverySequenceControllerGetCountriesForActiveCompanyPath = '/deliverysequence/active/company/countries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountriesForActiveCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountriesForActiveCompany$Plain$Response(params?: GetCountriesForActiveCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryViewModel>>> {
    return getCountriesForActiveCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountriesForActiveCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountriesForActiveCompany$Plain(params?: GetCountriesForActiveCompany$Plain$Params, context?: HttpContext): Observable<Array<CountryViewModel>> {
    return this.getCountriesForActiveCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryViewModel>>): Array<CountryViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountriesForActiveCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountriesForActiveCompany$Response(params?: GetCountriesForActiveCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryViewModel>>> {
    return getCountriesForActiveCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountriesForActiveCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountriesForActiveCompany(params?: GetCountriesForActiveCompany$Params, context?: HttpContext): Observable<Array<CountryViewModel>> {
    return this.getCountriesForActiveCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryViewModel>>): Array<CountryViewModel> => r.body)
    );
  }

  /** Path part for operation `deliverySequenceControllerGetActiveDeliverySequenceDetails()` */
  static readonly DeliverySequenceControllerGetActiveDeliverySequenceDetailsPath = '/deliverysequence/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveDeliverySequenceDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveDeliverySequenceDetails$Plain$Response(params?: GetActiveDeliverySequenceDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceDetailsViewModel>> {
    return getActiveDeliverySequenceDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveDeliverySequenceDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveDeliverySequenceDetails$Plain(params?: GetActiveDeliverySequenceDetails$Plain$Params, context?: HttpContext): Observable<DeliverySequenceDetailsViewModel> {
    return this.getActiveDeliverySequenceDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceDetailsViewModel>): DeliverySequenceDetailsViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveDeliverySequenceDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveDeliverySequenceDetails$Response(params?: GetActiveDeliverySequenceDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliverySequenceDetailsViewModel>> {
    return getActiveDeliverySequenceDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveDeliverySequenceDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveDeliverySequenceDetails(params?: GetActiveDeliverySequenceDetails$Params, context?: HttpContext): Observable<DeliverySequenceDetailsViewModel> {
    return this.getActiveDeliverySequenceDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliverySequenceDetailsViewModel>): DeliverySequenceDetailsViewModel => r.body)
    );
  }

  /** Path part for operation `deliverySequenceControllerGridSearchDeliverySequenceForOrganizationoptionsOrganizationId()` */
  static readonly DeliverySequenceControllerGridSearchDeliverySequenceForOrganizationoptionsOrganizationIdPath = '/deliverysequence/searchgrid/organization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchDeliverySequenceForOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchDeliverySequenceForOrganization$Plain$Response(params?: GridSearchDeliverySequenceForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchDeliverySequenceForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchDeliverySequenceForOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchDeliverySequenceForOrganization$Plain(params?: GridSearchDeliverySequenceForOrganization$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchDeliverySequenceForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchDeliverySequenceForOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchDeliverySequenceForOrganization$Response(params?: GridSearchDeliverySequenceForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchDeliverySequenceForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchDeliverySequenceForOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchDeliverySequenceForOrganization(params?: GridSearchDeliverySequenceForOrganization$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchDeliverySequenceForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

}
