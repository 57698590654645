import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { ActivationArgs } from "root/guards";
import { AuthService, AuthenticationStatus } from "../auth-service";
import { LogService, MibpLogger } from "../logservice";
import { ClientSideCacheService, CacheScope } from "../client-side-cache/client-side-cache.service";
import { ApplicationStateService } from "../application-state/application-state.service";
import { ApplicationStates } from "../application-state/application-state.types";
import { environment } from "root/environment";


/**
 * Use from startup guard.
 * Will make sure we know who is visiting the page
 */
@Injectable({
  providedIn: 'root'
})
export class StartupAuthenticationService {

  private log: MibpLogger;
    /**
   * Logger with common prefix for logging MibpSession related things
   *  - Signing in, user events etc
   */
    private mibpSessionLog: MibpLogger;


  constructor(
    logger: LogService,
    private authService: AuthService,
    private cache: ClientSideCacheService,
    private appState: ApplicationStateService) {
    this.log = logger.withPrefix('startup-authentication.service');
    this.mibpSessionLog = logger.withPrefix(environment.mibpSessionLogPrefix + ':startup-authentication.service');
  }

  public async testAuthentication(args: ActivationArgs): Promise<boolean | UrlTree> {

    if (args.isFirst) {
      this.appState.setState({ state: ApplicationStates.CheckB2CUserStatus, resourceStringKey: 'AppLoading_Authorization_Permissions', textFallback: 'Checking permissions'  });
    }

    const authStatus = await this.authService.resolveAuthStatus();

    if (authStatus.status === AuthenticationStatus.NotLoggedIn) {
      if (args.routeConfig.allowAnonymous === true) {
        this.mibpSessionLog.info(`Not logged in. Anonymous access is ok`, args.state.url);
        return Promise.resolve(true);
      }
      this.appState.setState({ state: ApplicationStates.CheckB2CUserStatus, resourceStringKey: 'AppLoading_Authorization_RedirectingToLogin', textFallback: 'Redirecting to login page...', internalStatus: 'LOGINREQUIRED'   });
      this.mibpSessionLog.info(`Not logged in. Redirecting to login page`, JSON.stringify({ url: args.state.url, routeConfig: args.routeConfig }));
      this.redirectToB2CLoginPage(args.state.url.toString());
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise(() => {}); // Block Angular app while redirecting
    }

    switch (authStatus.status) {
    case AuthenticationStatus.LoggedIn:
      if (args.isFirst) {
        this.appState.setState({ state: ApplicationStates.CheckB2CUserStatus, internalStatus: 'OK' });
      }
      return Promise.resolve(true);
    }

  }

  private redirectToB2CLoginPage(returnUrl: string) {
    if (!this.cache.get('redirectInProgress')) {
      this.cache.add('redirectInProgress', true, null, CacheScope.MemoryStorage);
      this.authService.signinRedirect({returnUrl});
    }
  }

}
