/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPromotion } from '../fn/promotions/get-promotion';
import { GetPromotion$Params } from '../fn/promotions/get-promotion';
import { getPromotion$Plain } from '../fn/promotions/get-promotion-plain';
import { GetPromotion$Plain$Params } from '../fn/promotions/get-promotion-plain';
import { getPromotionForEdit } from '../fn/promotions/get-promotion-for-edit';
import { GetPromotionForEdit$Params } from '../fn/promotions/get-promotion-for-edit';
import { getPromotionForEdit$Plain } from '../fn/promotions/get-promotion-for-edit-plain';
import { GetPromotionForEdit$Plain$Params } from '../fn/promotions/get-promotion-for-edit-plain';
import { getPromotionProducts } from '../fn/promotions/get-promotion-products';
import { GetPromotionProducts$Params } from '../fn/promotions/get-promotion-products';
import { getPromotionProducts$Plain } from '../fn/promotions/get-promotion-products-plain';
import { GetPromotionProducts$Plain$Params } from '../fn/promotions/get-promotion-products-plain';
import { getPromotionsForDashboard } from '../fn/promotions/get-promotions-for-dashboard';
import { GetPromotionsForDashboard$Params } from '../fn/promotions/get-promotions-for-dashboard';
import { getPromotionsForDashboard$Plain } from '../fn/promotions/get-promotions-for-dashboard-plain';
import { GetPromotionsForDashboard$Plain$Params } from '../fn/promotions/get-promotions-for-dashboard-plain';
import { Int32ApiResponse } from '../models/int-32-api-response';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { promotion_Delete } from '../fn/promotions/promotion-delete';
import { Promotion_Delete$Params } from '../fn/promotions/promotion-delete';
import { promotion_SearchGrid } from '../fn/promotions/promotion-search-grid';
import { Promotion_SearchGrid$Params } from '../fn/promotions/promotion-search-grid';
import { promotion_SearchGrid$Plain } from '../fn/promotions/promotion-search-grid-plain';
import { Promotion_SearchGrid$Plain$Params } from '../fn/promotions/promotion-search-grid-plain';
import { PromotionDto } from '../models/promotion-dto';
import { PromotionForEditToReturnVmApiResponse } from '../models/promotion-for-edit-to-return-vm-api-response';
import { PromotionIndexSearchResponse } from '../models/promotion-index-search-response';
import { PromotionProductDtoPagedResponse } from '../models/promotion-product-dto-paged-response';
import { PromotionsForDashboardVmApiResponse } from '../models/promotions-for-dashboard-vm-api-response';
import { rescope } from '../fn/promotions/rescope';
import { Rescope$Params } from '../fn/promotions/rescope';
import { rescope$Plain } from '../fn/promotions/rescope-plain';
import { Rescope$Plain$Params } from '../fn/promotions/rescope-plain';
import { savePromotion } from '../fn/promotions/save-promotion';
import { SavePromotion$Params } from '../fn/promotions/save-promotion';
import { savePromotion$Plain } from '../fn/promotions/save-promotion-plain';
import { SavePromotion$Plain$Params } from '../fn/promotions/save-promotion-plain';
import { search } from '../fn/promotions/search';
import { Search$Params } from '../fn/promotions/search';
import { search$Plain } from '../fn/promotions/search-plain';
import { Search$Plain$Params } from '../fn/promotions/search-plain';

@Injectable({ providedIn: 'root' })
export class PromotionsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `promotionsControllerGetPromotionsForDashboard()` */
  static readonly PromotionsControllerGetPromotionsForDashboardPath = '/promotions/dashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotionsForDashboard$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionsForDashboard$Plain$Response(params?: GetPromotionsForDashboard$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionsForDashboardVmApiResponse>> {
    return getPromotionsForDashboard$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotionsForDashboard$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionsForDashboard$Plain(params?: GetPromotionsForDashboard$Plain$Params, context?: HttpContext): Observable<PromotionsForDashboardVmApiResponse> {
    return this.getPromotionsForDashboard$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionsForDashboardVmApiResponse>): PromotionsForDashboardVmApiResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotionsForDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionsForDashboard$Response(params?: GetPromotionsForDashboard$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionsForDashboardVmApiResponse>> {
    return getPromotionsForDashboard(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotionsForDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionsForDashboard(params?: GetPromotionsForDashboard$Params, context?: HttpContext): Observable<PromotionsForDashboardVmApiResponse> {
    return this.getPromotionsForDashboard$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionsForDashboardVmApiResponse>): PromotionsForDashboardVmApiResponse => r.body)
    );
  }

  /** Path part for operation `promotionsControllerSearchrequest()` */
  static readonly PromotionsControllerSearchrequestPath = '/promotions/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain$Response(params?: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionIndexSearchResponse>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain(params?: Search$Plain$Params, context?: HttpContext): Observable<PromotionIndexSearchResponse> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionIndexSearchResponse>): PromotionIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionIndexSearchResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<PromotionIndexSearchResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionIndexSearchResponse>): PromotionIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `promotionsControllerGetPromotionpromotionId()` */
  static readonly PromotionsControllerGetPromotionpromotionIdPath = '/promotions/{promotionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotion$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotion$Plain$Response(params: GetPromotion$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionDto>> {
    return getPromotion$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotion$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotion$Plain(params: GetPromotion$Plain$Params, context?: HttpContext): Observable<PromotionDto> {
    return this.getPromotion$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionDto>): PromotionDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotion$Response(params: GetPromotion$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionDto>> {
    return getPromotion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotion(params: GetPromotion$Params, context?: HttpContext): Observable<PromotionDto> {
    return this.getPromotion$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionDto>): PromotionDto => r.body)
    );
  }

  /** Path part for operation `promotionsControllerPromotionDeletepromotionId()` */
  static readonly PromotionsControllerPromotionDeletepromotionIdPath = '/promotions/{promotionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotion_Delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotion_Delete$Response(params: Promotion_Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return promotion_Delete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promotion_Delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promotion_Delete(params: Promotion_Delete$Params, context?: HttpContext): Observable<void> {
    return this.promotion_Delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `promotionsControllerGetPromotionProductsrequest()` */
  static readonly PromotionsControllerGetPromotionProductsrequestPath = '/promotions/promotionproducts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotionProducts$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPromotionProducts$Plain$Response(params?: GetPromotionProducts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionProductDtoPagedResponse>> {
    return getPromotionProducts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotionProducts$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPromotionProducts$Plain(params?: GetPromotionProducts$Plain$Params, context?: HttpContext): Observable<PromotionProductDtoPagedResponse> {
    return this.getPromotionProducts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionProductDtoPagedResponse>): PromotionProductDtoPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotionProducts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPromotionProducts$Response(params?: GetPromotionProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionProductDtoPagedResponse>> {
    return getPromotionProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotionProducts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPromotionProducts(params?: GetPromotionProducts$Params, context?: HttpContext): Observable<PromotionProductDtoPagedResponse> {
    return this.getPromotionProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionProductDtoPagedResponse>): PromotionProductDtoPagedResponse => r.body)
    );
  }

  /** Path part for operation `promotionsControllerRescope()` */
  static readonly PromotionsControllerRescopePath = '/promotions/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain$Response(params?: Rescope$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return rescope$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain(params?: Rescope$Plain$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.rescope$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Response(params?: Rescope$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return rescope(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope(params?: Rescope$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.rescope$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /** Path part for operation `promotionsControllerSavePromotionpromotionForSaveVm()` */
  static readonly PromotionsControllerSavePromotionpromotionForSaveVmPath = '/promotions/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePromotion$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  savePromotion$Plain$Response(params?: SavePromotion$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return savePromotion$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `savePromotion$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  savePromotion$Plain(params?: SavePromotion$Plain$Params, context?: HttpContext): Observable<number> {
    return this.savePromotion$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePromotion()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  savePromotion$Response(params?: SavePromotion$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return savePromotion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `savePromotion$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  savePromotion(params?: SavePromotion$Params, context?: HttpContext): Observable<number> {
    return this.savePromotion$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `promotionsControllerGetPromotionForEditpromotionId()` */
  static readonly PromotionsControllerGetPromotionForEditpromotionIdPath = '/promotions/{promotionId}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotionForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionForEdit$Plain$Response(params: GetPromotionForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionForEditToReturnVmApiResponse>> {
    return getPromotionForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotionForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionForEdit$Plain(params: GetPromotionForEdit$Plain$Params, context?: HttpContext): Observable<PromotionForEditToReturnVmApiResponse> {
    return this.getPromotionForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionForEditToReturnVmApiResponse>): PromotionForEditToReturnVmApiResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotionForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionForEdit$Response(params: GetPromotionForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionForEditToReturnVmApiResponse>> {
    return getPromotionForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotionForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionForEdit(params: GetPromotionForEdit$Params, context?: HttpContext): Observable<PromotionForEditToReturnVmApiResponse> {
    return this.getPromotionForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionForEditToReturnVmApiResponse>): PromotionForEditToReturnVmApiResponse => r.body)
    );
  }

  /** Path part for operation `promotionsControllerPromotionSearchGridoptions()` */
  static readonly PromotionsControllerPromotionSearchGridoptionsPath = '/promotions/promotiongrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotion_SearchGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotion_SearchGrid$Plain$Response(params?: Promotion_SearchGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return promotion_SearchGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promotion_SearchGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotion_SearchGrid$Plain(params?: Promotion_SearchGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.promotion_SearchGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promotion_SearchGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotion_SearchGrid$Response(params?: Promotion_SearchGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return promotion_SearchGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promotion_SearchGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  promotion_SearchGrid(params?: Promotion_SearchGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.promotion_SearchGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

}
