<quill-editor
[placeholder]="placeHolder"
[modules]="modules"
[styles]="styles"
[sanitize]="false"
[ngModel]="value"
[disabled]="isDisabled"
(onEditorCreated)="editorCreated($event)"
(onContentChanged)="editorChanged($event)"
(onBlur)="editorTouched()"
[ngClass]="{'disabled-state' : isDisabled}">
</quill-editor>
