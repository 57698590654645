import { Component, ViewChild, Input, ElementRef, forwardRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { DialogButton } from '../dialog/dialog.types';
import { DropdownArgs, DropdownData, DropdownInput, DropdownComponent } from '../dropdown';
import { Guid } from 'guid-typescript';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiService } from 'root/services/mibp-api-services';
import { SafeHtml } from '@angular/platform-browser';
import { ScopeInformation, ResponsibilityPickerService } from 'root/services/responsibility-picker/responsibility-picker.service';
import { SimpleScope, OrganizationPickerScope, ExtendedResolvedScope } from './organization-picker.types';
import { DialogComponent } from '../dialog/dialog.component';
import { MibpLogger, LogService, CanDeactivateService } from 'root/services';
import { ButtonColors } from '../button/button.enum';
import {PagedBulletinRefinementOptions, ResolvedScope} from 'root/mibp-openapi-gen/models';


@Component({
  selector: 'mibp-organization-picker',
  templateUrl: './organization-picker.component.html',
  styleUrls: ['./organization-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => OrganizationPickerComponent),
    }
  ]
})
export class OrganizationPickerComponent implements ControlValueAccessor {

  log: MibpLogger;
  isLoading = true;
  userScope: ScopeInformation;
  isFormValid = true;

  customerError: 'userscope' | '' = '';
  deliverySequenceError: 'userscope' | '' = '';
  productModelErrors: 'invalid-items' | '' = '';
  productAreaErrors: 'invalid-items' | '' = '';
  productGroupErrors: 'invalid-items' | '' = '';
  productSubgroupErrors: 'invalid-items' | '' = '';
  serialErrors: 'invalid-items' | '' = '';
  showFullScreenLoader = false;
  request: PagedBulletinRefinementOptions;
  dialogButtons: DialogButton[] = [
    {resourceKey: 'Global_Dialog_Close', id: 'close', color: ButtonColors.Orange}
  ];
  @Input() hideDeliverySequence = false;
  @Input() hideProductColumn = false;
  @Input() disabled = false;


  @Output() organizationSelected = new EventEmitter<DropdownInput>();

  // We should always validate the selected scopes.
  // We'll make it an option as we evaluate the validation
  @Input() validateScopes = false;

  @ViewChild(DialogComponent) dialog: DialogComponent;

  @ViewChild('companyDropdown') companyDropdown: DropdownComponent;
  @ViewChild('customerDropdown') customerDropdown: DropdownComponent;
  @ViewChild('deliverysequenceDropdown') deliverysequenceDropdown: DropdownComponent;

  @ViewChild('modelDropdown') modelDropdown: DropdownComponent;
  @ViewChild('productgroupDropdown') productgroupDropdown: DropdownComponent;
  @ViewChild('productsubgroupDropdown') productsubgroupDropdown: DropdownComponent;

  @ViewChild('serialDropdown') serialDropdown: DropdownComponent;

  @ViewChild('productAreaDropdown') productAreaDropdown: DropdownComponent;

  scopes: ExtendedResolvedScope[] = [];

  companies$: Observable<DropdownData>;
  customers$: Observable<DropdownData>;
  deliverySequences$: Observable<DropdownData>;
  productAreas$: Observable<DropdownData>;
  productGroups$: Observable<DropdownData>;
  productSubGroups$: Observable<DropdownData>;
  productModels$: Observable<DropdownData>;
  serials$: Observable<DropdownData>;

  searchForScopes: ResolvedScope[];
  selection: OrganizationPickerScope = {};
  isLoadingScopes = false;
  scopePreview: SimpleScope[];
  scopePreviewKeys: string[];
  scopeString: SafeHtml;
  dialogScopeList: ResolvedScope[];
  dropdownPageSize = 10;

  constructor(private elm: ElementRef, private rspSvc: ResponsibilityPickerService, private api: ApiService,
    logger: LogService, private canDeactivateService: CanDeactivateService) {
    this.log = logger.withPrefix('organization-picker');
    this.dropdownPageSize = rspSvc.pageSize;
  }

  // ControlValueAccessor

  writeValue(obj: any): void {
    this.scopes = null;
    this.triggerChange();

    if (obj instanceof Guid) {

      this.isLoadingScopes = true;
      this.rspSvc.getAccessGroupScopes(obj).subscribe(theGroups => {
        const scopes = theGroups as ExtendedResolvedScope[];
        this.rspSvc.validateScope(scopes).forEach(err => {
          scopes[err.itemIndex].invalid = this.validateScopes;
        });
        this.scopes = scopes;
        this.isLoadingScopes = false;
        this.triggerChange();
      });

    } else if (Array.isArray(obj)) {

      const scopes = obj as ExtendedResolvedScope[];
      scopes.forEach(s => s.invalid = false);
      this.rspSvc.validateScope(obj).forEach(err => scopes[err.itemIndex].invalid = this.validateScopes);
      this.scopes = scopes;
      this.triggerChange();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  triggerChange() {
    if (!this.scopes || this.scopes.length === 0) {
      this.onChange(null);
    } else {
      this.onChange(this.scopes);
    }
  }

  search() {
    this.rspSvc.selectionToScopes(this.selection).subscribe(result => {
      this.searchForScopes = result;
    });
  }

  onChange = (newValue: ResolvedScope[]) => {
    // this.pendingChanges.push(rating);
  };

  // Function to call when the input is touched (when value is changed).
  onTouched = () => {};

  setDisabledState?(isDisabled: boolean): void {    
  }

  // End ControlValueAccessor

  validationChanged(e: { isValid: boolean }) {

  }

  dialogClick(btn: DialogButton) {
    if (btn.id === 'save') {
      if (!this.scopes) {
        this.scopes = [];
      }
      const scopes = this.scopes.concat(this.dialogScopeList);
      this.rspSvc.validateScope(scopes).forEach(err => scopes[err.itemIndex].invalid = this.validateScopes);
      this.scopes = scopes;

      this.dialogScopeList = [];
      this.dialog.close();
      this.onChange(this.scopes);
    } else if (btn.id === 'close') {
      this.dialog.close();
    }
  }

  private updateDropdown(dropdown: DropdownComponent, data: { value?: DropdownInput[], disabled?: boolean, loading?: boolean }) {
    if (!dropdown) {
      return;
    }
    if (data.value !== undefined) {
      dropdown.writeValue(data.value);
    }

    if (data.disabled !== undefined) {
      if (data.disabled === true) {
        dropdown.disable();
      } else {
        dropdown.enable();
      }
    }

    if (data.loading !== undefined) {
      if (data.loading === true) {
        dropdown.loading = true;
        dropdown.enable();
      } else {
        dropdown.loading = false;
      }
    }


  }


  filterCompanies(a: DropdownArgs): void {
    this.companies$ = this.rspSvc.getCompanies(a.query, a.index, a.take);
    this.log.debug(`Get all companies`);
  }

  filterCustomers(a: DropdownArgs): void {
    if (this.selection.companies.length === 1) {
      const companyId = this.firstSelectedCompanyCode;
      this.log.debug(`Get customers for company ${companyId} ('${a.query}')`);
      this.customers$ = this.rspSvc.getCustomers(companyId, a.query, a.index, a.take);
    }
  }

  filterDeliverySequences(a: DropdownArgs): void {
    const companyId = this.firstSelectedCompanyCode;
    const customerNumber = this.firstSelectedCustomerNumber;
    this.log.debug(`Get delivery sequences for company ${companyId}, customer ${customerNumber} ('${a.query}')`);
    this.deliverySequences$ = this.rspSvc.getDeliverySequences(companyId, customerNumber, a.query, a.index, a.take);
  }

  filterProductAreas(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    this.log.debug(`Get product areas for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber} ('${args.query}')`);

    this.productAreas$ = this.rspSvc.getProductAreas({
      companyCodes: companyCodes,
      customerNumbers: customerNumber ? [customerNumber] : null,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });
  }

  filterProductGroups(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    const productArea = this.firstSelectedProductAreaCode;
    this.log.debug(`Get product groups for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productArea} ('${args.query}')`);

    this.productGroups$ = this.rspSvc.getProductGroups({
      companyCodes: companyCodes,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      productAreaCodes:  productArea ? [productArea] : null,
      customerNumbers: customerNumber ? [customerNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });

  }

  filterProductSubGroups(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    const productArea = this.firstSelectedProductAreaCode;
    const productGroup = this.firstSelectedProductGroupCode;
    this.log.debug(`Get product subgroups for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productArea}, productGroup#${productGroup} ('${args.query}')`);

    this.productSubGroups$ = this.rspSvc.getProductSubGroups({
      companyCodes:  companyCodes,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      productAreaCodes:  productArea ? [productArea] : null,
      productGroupCodes: productGroup ? [productGroup] : null,
      customerNumbers: customerNumber ? [customerNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });

  }

  filterProductModels(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    const productAreas = this.selection.productAreas ? this.selection.productAreas.map(c => c.value) : null;
    const productGroups = this.selection.productGroups ? this.selection.productGroups.map(c => c.value) : null;
    const productSubGroups = this.selection.productSubgroups ? this.selection.productSubgroups.map(c => c.value) : null;
    this.log.debug(`Get product models for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productAreas || ''}, productGroup#${productGroups}, productSubgroup#${productSubGroups} ('${args.query}')`);

    this.productModels$ = this.rspSvc.getProductModels({
      companyCodes:  companyCodes,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      productAreaCodes: productAreas,
      productGroupCodes: productGroups,
      productSubGroupCodes: productSubGroups,
      customerNumbers: customerNumber ? [customerNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });
  }

  filterSerialNumbers(args: DropdownArgs) {
    const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
    const customerNumber = this.firstSelectedCustomerNumber;
    const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
    const productAreas = this.selection.productAreas && this.selection.productAreas.length > 0 ? this.selection.productAreas.map(c => c.value) : null;
    const productGroups = this.selection.productGroups && this.selection.productGroups.length > 0 ? this.selection.productGroups.map(c => c.value) : null;
    const productSubGroups = this.selection.productSubgroups && this.selection.productSubgroups.length > 0 ? this.selection.productSubgroups.map(c => c.value) : null;
    const models = this.selection.models && this.selection.models.length > 0 ? this.selection.models.map(c => c.value) : null;
    this.log.debug(`Get product models for Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productAreas || ''}, productGroup#${productGroups}, productSubgroup#${productSubGroups} ('${args.query}')`);

    this.serials$ = this.rspSvc.getSerialNumbers({
      companyCodes:  companyCodes,
      deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
      productAreaCodes: productAreas,
      productGroupCodes: productGroups,
      productSubGroupCodes: productSubGroups,
      productModelNames: models,
      customerNumbers: customerNumber ? [customerNumber] : null,
      query: args.query,
      skip: args.index,
      take: args.take
    });
  }



  get firstSelectedCompanyCode(): string {
    if (!this.selection.companies || this.selection.companies.length === 0) {
      return null;
    }
    return this.selection.companies[0].value;
  }

  get firstSelectedCustomerNumber(): string {
    if (!this.selection.customers || this.selection.customers.length === 0) {
      return null;
    }
    return this.selection.customers[0].value;
  }

  get firstSelectedDeliverySequenceNumber(): string {
    if (!this.selection.deliverySequences || this.selection.deliverySequences.length === 0) {
      return null;
    }
    return this.selection.deliverySequences[0].value;
  }

  get firstSelectedProductAreaCode(): string {
    if (!this.selection.productAreas || this.selection.productAreas.length === 0) {
      return null;
    }
    return this.selection.productAreas[0].value;
  }

  get firstSelectedProductGroupCode(): string {
    if (!this.selection.productGroups || this.selection.productGroups.length === 0) {
      return null;
    }
    return this.selection.productGroups[0].value;
  }

  get firstSelectedProductSubGroupCode(): string {
    if (!this.selection.productSubgroups || this.selection.productSubgroups.length === 0) {
      return null;
    }
    return this.selection.productGroups[0].value;
  }




  onDropdownValueChanged(type:
    'company' | 'customer' | 'deliverysequence' |
    'model' | 'productgroup' | 'productsubgroup' | 'serial' | 'productarea' , e: DropdownInput[]) {

    e = e || [];

    if (type === 'company') {
      this.selection.companies = e;
      this.onCompanyChanged();
    } else if (type === 'customer') {
      this.selection.customers = e;
      this.onCustomerChanged();
    } else if (type === 'deliverysequence') {
      this.selection.deliverySequences = e;
      this.onDeliverySequenceChanged();
    } else if (type === 'productarea') {
      this.selection.productAreas = e;
      this.onProductAreaChanged();
    } else if (type === 'productgroup') {
      this.selection.productGroups = e;
      this.onProductGroupChanged();
    } else if (type === 'productsubgroup') {
      this.selection.productSubgroups = e;
      this.onProductSubGroupChanged();
    } else if (type === 'model') {
      this.selection.models = e;
      this.onProductModelChanged();
    } else if (type === 'serial') {
      this.selection.serials = e;
      // this.onProductModelChanged();
    } else {
      // this.log(`change $[type] not implemented`);
    }
    this.search();
  }

  orgSelected(id: DropdownInput) {
    this.organizationSelected.emit(id);
    this.dialog.close();
  }

  onProductModelChanged() {
    this.serials$ = null;
    this.validateSelectedSerialNumbers();
  }

  onProductGroupChanged() {
    this.productSubGroups$ = null;
    this.productModels$ = null;
    this.serials$ = null;

    if (this.selection?.productGroups?.length === 0 || this.selection?.productGroups?.length > 1) {
      this.updateDropdown(this.productsubgroupDropdown, { value: [], disabled: true });
    } else {
      this.updateDropdown(this.productsubgroupDropdown, {value: [], disabled: false});
    }

    this.validateSelectedProductModels();
    this.validateSelectedProductSubGroups();
    this.validateSelectedSerialNumbers();

  }

  onProductSubGroupChanged() {
    this.productModels$ = null;
    this.serials$ = null;
    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();
  }

  validateSelectedProductGroups(): Promise<void> {

    if (this.hideProductColumn) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      if (this.selection.productGroups && this.selection.productGroups.length > 0 && !this.productgroupDropdown.loading) {
        this.updateDropdown(this.productgroupDropdown, { loading: true });

        const companyCodes = this.selection.companies && this.selection.companies.length > 0 ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
        const productAreas = this.selection.productAreas && this.selection.productAreas.length > 0 ? this.selection.productAreas.map(pa => pa.value) : null;

        this.rspSvc.getProductGroups({
          companyCodes: companyCodes,
          customerNumbers: customerNumber ? [customerNumber] : null,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: productAreas,
          productGroupCodes:  this.selection.productGroups.map(pa => pa.value),
          query: null,
          skip: 0,
          take: this.selection.productGroups.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.productGroups.filter(selectedOption => {
            return searchResponse.items.findIndex( searchRow => searchRow.value === selectedOption.value ) === -1;
          }).map(selectedItem => selectedItem.value);

          this.productgroupDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.productgroupDropdown, { loading: false });
          resolve();
        });

      } else {
        this.productgroupDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }

  validateSelectedProductSubGroups(): Promise<void> {

    if (this.hideProductColumn) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      if (this.selection.productSubgroups && this.selection.productSubgroups.length > 0 && !this.productsubgroupDropdown.loading) {
        this.updateDropdown(this.productsubgroupDropdown, { loading: true });

        const companyCodes = this.selection.companies && this.selection.companies.length > 0 ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
        const productAreas = this.selection.productAreas && this.selection.productAreas.length > 0 ? this.selection.productAreas.map(pa => pa.value) : null;

        this.rspSvc.getProductSubGroups({
          companyCodes: companyCodes,
          customerNumbers: customerNumber ? [customerNumber] : null,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: productAreas,
          productGroupCodes:  this.selection.productGroups.map(pa => pa.value),
          productSubGroupCodes: this.selection.productSubgroups.map(pa => pa.value),
          query: null,
          skip: 0,
          take: this.selection.productGroups.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.productSubgroups.filter(selectedOption => {
            return searchResponse.items.findIndex( searchRow => searchRow.value === selectedOption.value ) === -1;
          }).map(selectedItem => selectedItem.value);

          this.productsubgroupDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.productsubgroupDropdown, { loading: false });
          resolve();
        });

      } else {
        this.productsubgroupDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }

  validateSelectedProductAreas(): Promise<void> {

    if (this.hideProductColumn) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      if (this.selection.productAreas && this.selection.productAreas.length > 0 && !this.productAreaDropdown.loading) {
        this.updateDropdown(this.productAreaDropdown, { loading: true });

        const companyCodes = this.selection.companies && this.selection.companies.length > 0 ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;

        this.rspSvc.getProductAreas({
          companyCodes: companyCodes,
          customerNumbers: customerNumber ? [customerNumber] : null,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: this.selection.productAreas.map(pa => pa.value),
          query: null,
          skip: 0,
          take: this.selection.productAreas.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.productAreas.filter(selectedOption => {
            return searchResponse.items.findIndex( searchRow => searchRow.value === selectedOption.value ) === -1;
          }).map(selectedItem => selectedItem.value);

          this.productAreaDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.productAreaDropdown, { loading: false });
          resolve();
        });

      } else {
        this.productAreaDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }

  validateSelectedProductModels(): Promise<void> {
    return new Promise(resolve => {

      if (this.selection.models && this.selection.models.length > 0 && !this.modelDropdown.loading) {
        this.updateDropdown(this.modelDropdown, { loading: true });

        const companyCodes = this.selection.companies ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
        const productAreas = this.selection.productAreas ? this.selection.productAreas.map(c => c.value) : null;
        const productGroups = this.selection.productGroups ? this.selection.productGroups.map(c => c.value) : null;
        const productSubGroups = this.selection.productSubgroups ? this.selection.productSubgroups.map(c => c.value) : null;
        const productModels = this.selection.models.map(c => c.value);
        this.log.debug(`Validate product models Company#${companyCodes.join(',')}, Customer#${customerNumber}, DeliverySequence#${deliverySequenceNumber}, productArea#${productAreas || ''}, productGroup#${productGroups}, productSubgroup#${productSubGroups}, models#${productModels.join(', ')})`);

        this.rspSvc.getProductModels({
          companyCodes:  companyCodes,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: productAreas,
          productGroupCodes: productGroups,
          productSubGroupCodes: productSubGroups,
          productModelNames: productModels,
          customerNumbers: customerNumber ? [customerNumber] : null,
          query: null,
          skip: 0,
          take: productModels.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.models.filter(selectedOption => {
            return searchResponse.items.findIndex( searchRow => searchRow.value === selectedOption.value ) === -1;
          }).map(selectedItem => selectedItem.value);

          this.modelDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.modelDropdown, { loading: false });
          resolve();
        });

      } else {
        this.modelDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }

  validateSelectedSerialNumbers(): Promise<void> {
    return new Promise(resolve => {

      if (this.selection.serials && this.selection.serials.length > 0 && !this.serialDropdown.loading) {
        this.updateDropdown(this.serialDropdown, { loading: true });

        const companyCodes = this.selection.companies && this.selection.companies.length > 0 ? this.selection.companies.map(c => c.value) : null;
        const customerNumber = this.firstSelectedCustomerNumber;
        const deliverySequenceNumber = this.firstSelectedDeliverySequenceNumber;
        const productAreas = this.selection.productAreas && this.selection.productAreas.length > 0 ? this.selection.productAreas.map(c => c.value) : null;
        const productGroups = this.selection.productGroups && this.selection.productGroups.length > 0 ? this.selection.productGroups.map(c => c.value) : null;
        const productSubGroups = this.selection.productSubgroups && this.selection.productSubgroups.length > 0 ? this.selection.productSubgroups.map(c => c.value) : null;
        const productModels = this.selection.models && this.selection.models.length > 0 ? this.selection.models.map(c => c.value) : null;
        const serialNumbers = this.selection.serials.map(c => c.value);

        this.rspSvc.getSerialNumbers({
          companyCodes: companyCodes,
          deliverySequenceNumbers: deliverySequenceNumber ? [deliverySequenceNumber] : null,
          productAreaCodes: productAreas,
          productGroupCodes: productGroups,
          productSubGroupCodes: productSubGroups,
          productModelNames: productModels,
          equipmentNames: serialNumbers,
          customerNumbers: customerNumber ? [customerNumber] : null,
          query: null,
          skip: 0,
          take: serialNumbers.length
        }).subscribe(searchResponse => {

          // If not in the search result, then mark those items as invalid
          const invalidValues = this.selection.serials.filter(selectedOption => {
            return searchResponse.items.findIndex( searchRow => searchRow.value === selectedOption.value ) === -1;
          }).map(selectedItem => selectedItem.value);

          this.serialDropdown.setInvalidValues(invalidValues);
          this.updateDropdown(this.serialDropdown, { loading: false });
          resolve();
        });

      } else {
        this.serialDropdown.setInvalidValues(null);
        resolve();
      }
    });
  }



  invalidValuesChanged(dropdown: 'productarea' | 'productgroup' | 'productsubgroup' | 'model' | 'serial', values: string[]) {

    let anyInvalid = false;

    if (dropdown === 'productarea') {
      if (values && values.length > 0) {
        this.productAreaErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.productAreaErrors = '';
      }
    }

    if (dropdown === 'productgroup') {
      if (values && values.length > 0) {
        this.productGroupErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.productGroupErrors = '';
      }
    }

    if (dropdown === 'productsubgroup') {
      if (values && values.length > 0) {
        this.productSubgroupErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.productSubgroupErrors = '';
      }
    }

    if (dropdown === 'model') {
      if (values && values.length > 0) {
        this.productModelErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.productModelErrors = '';
      }
    }

    if (dropdown === 'serial') {
      if (values && values.length > 0) {
        this.serialErrors = 'invalid-items';
        anyInvalid = true;
      } else {
        this.serialErrors = '';
      }
    }


    if (anyInvalid && this.isFormValid) {
      this.isFormValid = false;
    } else if (!anyInvalid && !this.isFormValid) {
      this.isFormValid = true;
    }

  }

  onProductAreaChanged() {
    if (this.hideProductColumn) {
      return;
    }
    this.productGroups$ = null;
    this.productModels$ = null;
    this.serials$ = null;

    if (this.selection.productAreas.length === 0 || this.selection.productAreas.length > 1) {
      this.updateDropdown(this.productgroupDropdown, { value: [], disabled: true });
    } else {
      this.updateDropdown(this.productgroupDropdown, {value: [], disabled: false});
    }

    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();


  }

  onCustomerChanged() {
    this.deliverySequenceError = '';
    this.deliverySequences$ = null;
    this.productAreas$ = null;
    this.productGroups$ = null;
    this.productSubGroups$ = null;
    this.productModels$ = null;
    this.serials$ = null;

    if (this.selection.customers.length === 0 || this.selection.customers.length > 1) {
      this.updateDropdown(this.deliverysequenceDropdown, { value: [], disabled: true });
    } else {

      if (!this.hideDeliverySequence) {
        if (this.userScope.hasAnyDeliverysequence(this.firstSelectedCompanyCode, this.firstSelectedCustomerNumber)) {
          this.log.debug(`Customer Changed - hasAnyDeliverySequence is true. Any is alright`);
          this.deliverysequenceDropdown.cannotRemoveLastItem = false;
          this.updateDropdown(this.deliverysequenceDropdown, { value: [], disabled: false });
        } else {
          const first = this.userScope.getFirstDeliverySequenceFromScope(this.firstSelectedCompanyCode, this.firstSelectedCustomerNumber);
          if (first) {
            this.deliverysequenceDropdown.cannotRemoveLastItem = true;
            this.updateDropdown(this.deliverysequenceDropdown, { value: [
              {text: first.number, value: first.number}
            ], disabled: false, loading: false });
          } else {
            this.deliverySequenceError = 'userscope';
          }
        }
      }
    }


    this.validateSelectedProductAreas();
    this.validateSelectedProductGroups();
    this.validateSelectedProductSubGroups();
    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();


  }

  private runValidation(fns: Promise<void>[]) {
    return new Promise((resolve, reject) => {
      Promise.all(fns).then(resolve, reject);
    });
  }

  onCompanyChanged() {
    this.updateDropdown(this.deliverysequenceDropdown, { value: [], disabled: true });
    this.deliverySequences$ = null;
    this.customers$ = null;
    this.productAreas$ = null;
    this.productGroups$ = null;
    this.productSubGroups$ = null;
    this.productModels$ = null;
    this.serials$ = null;
    this.customerError = '';

    if (this.selection.companies.length > 1 || this.selection.companies.length === 0) {
      // 0 or > 1 selected? Then we can't choose customers. "Any" only.
      this.updateDropdown(this.customerDropdown, { value: [], disabled: true });
      this.customers$ = null;
    } else {
      if (this.userScope.hasAnyCustomer(this.firstSelectedCompanyCode)) {
        this.log.debug(`Company Changed - hasAnyCustomer is true. Any is alright`);
        // The selected companies has "ANY" in scope for its customers
        // Let user choose freely from available customers
        this.customerDropdown.cannotRemoveLastItem = false;
        this.updateDropdown(this.customerDropdown, {
          value: [],
          disabled: false,
          loading: false
        });
      } else {
        this.log.debug(`Company Changed - hasAnyCustomer is false. Find first customer in scope`);
        // Customer scope for this company is not ANY.
        // Find the first customer for this company via db scopes and populate customer dropdown
        const first = this.userScope.getFirstCompanyCustomerFromScope(this.firstSelectedCompanyCode);
        if (first) {
          this.customerDropdown.cannotRemoveLastItem = true;

          this.updateDropdown(this.customerDropdown, {
            value: [{text: first.erpCustomerID, value: first.erpCustomerID}],
            disabled: false,
            loading: false
          });
        } else {
          this.customerError = 'userscope';
        }
      }
    }


    this.validateSelectedProductAreas();
    this.validateSelectedProductGroups();
    this.validateSelectedProductSubGroups();
    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();


  }

  onDeliverySequenceChanged() {
    this.productAreas$ = null;
    this.productModels$ = null;
    this.productGroups$ = null;
    this.productSubGroups$ = null;
    this.serials$ = null;

    this.validateSelectedProductAreas();
    this.validateSelectedProductGroups();
    this.validateSelectedProductSubGroups();
    this.validateSelectedProductModels();
    this.validateSelectedSerialNumbers();
  }


  addResponsibility() {

  }

  clearSelection() {
    this.dialogOpen();
  }

  resetDropdowns() {
    this.companies$ = null;
    this.companies$ = null;
    this.deliverySequences$ = null;
    this.productAreas$ = null;
    this.productGroups$ = null;
    this.productSubGroups$ = null;


    this.rspSvc.getUserScopes().subscribe(scopes => {
      this.log.warn("SCOPES", scopes);
      this.userScope = scopes;
      const companyValue: DropdownInput[] = [];
      let customerEnabled = false;


      if (!scopes.hasAnyCompany) {
        this.log.debug('User does not have *any* company in scopes. Selecting first company, disable any.');
        const firstCompanyInScope = scopes.scopes.find(scope => !!scope.company);
        if (firstCompanyInScope) {
          this.log.debug(`Selecting first company in scope: ${firstCompanyInScope.company.code}`);
          companyValue.push({ text: firstCompanyInScope.company.code, value: firstCompanyInScope.company.code });
          this.companyDropdown.cannotRemoveLastItem = true;
          customerEnabled = true;
        }



      } else {
        this.log.debug('Found user scope with *any* company');
        this.companyDropdown.cannotRemoveLastItem = false;
      }

      this.updateDropdown(this.companyDropdown, { disabled: false, value: companyValue, loading: false });
      //      this.updateDropdown(this.customerDropdown, { disabled: !customerEnabled, value: [], loading: false });
      this.onCompanyChanged();
      this.onCustomerChanged();
      // this.updateDropdown(this.deliverysequenceDropdown, { disabled: true, value: [], loading: false });

      this.updateDropdown(this.productAreaDropdown, { disabled: false, value: [], loading: false });
      this.updateDropdown(this.modelDropdown, { disabled: false, value: [], loading: false });
      this.updateDropdown(this.productgroupDropdown, { disabled: true, value: [], loading: false });
      this.updateDropdown(this.productsubgroupDropdown, { disabled: true, value: [], loading: false });

      this.updateDropdown(this.serialDropdown, { disabled: false, value: [], loading: false });

      this.onProductAreaChanged();
      this.onProductGroupChanged();
      this.onProductSubGroupChanged();

      this.isLoading = false;
    });
  }

  dialogOpen() {
    this.canDeactivateService.block();
    this.dialogScopeList = [];
    this.resetDropdowns();
  }

  dialogClosed() {
    this.canDeactivateService.unblock();
  }


  removeDialogScope(index: number) {
    const newList = this.dialogScopeList.slice(0);
    newList.splice(index, 1);
    this.dialogScopeList = newList;
  }

  removeScope(index: number) {
    const newList = this.scopes.slice(0);
    newList.splice(index, 1);
    newList.forEach(s => s.invalid = false);
    this.rspSvc.validateScope(newList).forEach(err => newList[err.itemIndex].invalid = this.validateScopes);
    this.scopes = newList;

    this.onChange(this.scopes);
  }

}
