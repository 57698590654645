/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getKitProducts } from '../fn/kit/get-kit-products';
import { GetKitProducts$Params } from '../fn/kit/get-kit-products';
import { getKitProducts$Plain } from '../fn/kit/get-kit-products-plain';
import { GetKitProducts$Plain$Params } from '../fn/kit/get-kit-products-plain';
import { importMaintenanceKits } from '../fn/kit/import-maintenance-kits';
import { ImportMaintenanceKits$Params } from '../fn/kit/import-maintenance-kits';
import { importMaintenanceKits$Plain } from '../fn/kit/import-maintenance-kits-plain';
import { ImportMaintenanceKits$Plain$Params } from '../fn/kit/import-maintenance-kits-plain';
import { KitIndexSearchResponse } from '../models/kit-index-search-response';
import { KitProductViewModel } from '../models/kit-product-view-model';
import { KitSearchItemSearchResponse } from '../models/kit-search-item-search-response';
import { searchKits } from '../fn/kit/search-kits';
import { SearchKits$Params } from '../fn/kit/search-kits';
import { searchKits$Plain } from '../fn/kit/search-kits-plain';
import { SearchKits$Plain$Params } from '../fn/kit/search-kits-plain';
import { searchKitsForEquipment } from '../fn/kit/search-kits-for-equipment';
import { SearchKitsForEquipment$Params } from '../fn/kit/search-kits-for-equipment';
import { searchKitsForEquipment$Plain } from '../fn/kit/search-kits-for-equipment-plain';
import { SearchKitsForEquipment$Plain$Params } from '../fn/kit/search-kits-for-equipment-plain';

@Injectable({ providedIn: 'root' })
export class KitApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `kitControllerSearchKitsrequest()` */
  static readonly KitControllerSearchKitsrequestPath = '/kit/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchKits$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKits$Plain$Response(params?: SearchKits$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<KitIndexSearchResponse>> {
    return searchKits$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchKits$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKits$Plain(params?: SearchKits$Plain$Params, context?: HttpContext): Observable<KitIndexSearchResponse> {
    return this.searchKits$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<KitIndexSearchResponse>): KitIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchKits()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKits$Response(params?: SearchKits$Params, context?: HttpContext): Observable<StrictHttpResponse<KitIndexSearchResponse>> {
    return searchKits(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchKits$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKits(params?: SearchKits$Params, context?: HttpContext): Observable<KitIndexSearchResponse> {
    return this.searchKits$Response(params, context).pipe(
      map((r: StrictHttpResponse<KitIndexSearchResponse>): KitIndexSearchResponse => r.body)
    );
  }

  /** Path part for operation `kitControllerSearchKitsForEquipmentrequestEquipmentId()` */
  static readonly KitControllerSearchKitsForEquipmentrequestEquipmentIdPath = '/kit/equipment/{equipmentId}/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchKitsForEquipment$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKitsForEquipment$Plain$Response(params: SearchKitsForEquipment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<KitSearchItemSearchResponse>> {
    return searchKitsForEquipment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchKitsForEquipment$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKitsForEquipment$Plain(params: SearchKitsForEquipment$Plain$Params, context?: HttpContext): Observable<KitSearchItemSearchResponse> {
    return this.searchKitsForEquipment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<KitSearchItemSearchResponse>): KitSearchItemSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchKitsForEquipment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKitsForEquipment$Response(params: SearchKitsForEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<KitSearchItemSearchResponse>> {
    return searchKitsForEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchKitsForEquipment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchKitsForEquipment(params: SearchKitsForEquipment$Params, context?: HttpContext): Observable<KitSearchItemSearchResponse> {
    return this.searchKitsForEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<KitSearchItemSearchResponse>): KitSearchItemSearchResponse => r.body)
    );
  }

  /** Path part for operation `kitControllerImportMaintenanceKitsrequest()` */
  static readonly KitControllerImportMaintenanceKitsrequestPath = '/kit/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importMaintenanceKits$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMaintenanceKits$Plain$Response(params?: ImportMaintenanceKits$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return importMaintenanceKits$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importMaintenanceKits$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMaintenanceKits$Plain(params?: ImportMaintenanceKits$Plain$Params, context?: HttpContext): Observable<number> {
    return this.importMaintenanceKits$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importMaintenanceKits()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMaintenanceKits$Response(params?: ImportMaintenanceKits$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return importMaintenanceKits(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importMaintenanceKits$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMaintenanceKits(params?: ImportMaintenanceKits$Params, context?: HttpContext): Observable<number> {
    return this.importMaintenanceKits$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `kitControllerGetKitProductskitProductId()` */
  static readonly KitControllerGetKitProductskitProductIdPath = '/kit/{kitProductId}/kitproducts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKitProducts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKitProducts$Plain$Response(params: GetKitProducts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KitProductViewModel>>> {
    return getKitProducts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKitProducts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKitProducts$Plain(params: GetKitProducts$Plain$Params, context?: HttpContext): Observable<Array<KitProductViewModel>> {
    return this.getKitProducts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KitProductViewModel>>): Array<KitProductViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKitProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKitProducts$Response(params: GetKitProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KitProductViewModel>>> {
    return getKitProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKitProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKitProducts(params: GetKitProducts$Params, context?: HttpContext): Observable<Array<KitProductViewModel>> {
    return this.getKitProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KitProductViewModel>>): Array<KitProductViewModel> => r.body)
    );
  }

}
