/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { cxmlPostLogin } from '../fn/punchout/cxml-post-login';
import { CxmlPostLogin$Params } from '../fn/punchout/cxml-post-login';
import { delete$ } from '../fn/punchout/delete';
import { Delete$Params } from '../fn/punchout/delete';
import { getById } from '../fn/punchout/get-by-id';
import { GetById$Params } from '../fn/punchout/get-by-id';
import { getById$Plain } from '../fn/punchout/get-by-id-plain';
import { GetById$Plain$Params } from '../fn/punchout/get-by-id-plain';
import { getOCI } from '../fn/punchout/get-oci';
import { GetOCI$Params } from '../fn/punchout/get-oci';
import { gridSearch } from '../fn/punchout/grid-search';
import { GridSearch$Params } from '../fn/punchout/grid-search';
import { gridSearch$Plain } from '../fn/punchout/grid-search-plain';
import { GridSearch$Plain$Params } from '../fn/punchout/grid-search-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { ociPostPunchout } from '../fn/punchout/oci-post-punchout';
import { OciPostPunchout$Params } from '../fn/punchout/oci-post-punchout';
import { PunchoutcXmlAddress } from '../models/punchoutc-xml-address';
import { save } from '../fn/punchout/save';
import { Save$Params } from '../fn/punchout/save';

@Injectable({ providedIn: 'root' })
export class PunchoutApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `punchoutControllerGetOci()` */
  static readonly PunchoutControllerGetOciPath = '/punchout/oci';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOCI()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOCI$Response(params?: GetOCI$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getOCI(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOCI$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOCI(params?: GetOCI$Params, context?: HttpContext): Observable<void> {
    return this.getOCI$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `punchoutControllerOciPostPunchoutpunchoutOciDto()` */
  static readonly PunchoutControllerOciPostPunchoutpunchoutOciDtoPath = '/punchout/oci';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ociPostPunchout()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ociPostPunchout$Response(params?: OciPostPunchout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ociPostPunchout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ociPostPunchout$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ociPostPunchout(params?: OciPostPunchout$Params, context?: HttpContext): Observable<void> {
    return this.ociPostPunchout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `punchoutControllerCxmlPostLogin()` */
  static readonly PunchoutControllerCxmlPostLoginPath = '/punchout/cxml';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cxmlPostLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  cxmlPostLogin$Response(params?: CxmlPostLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cxmlPostLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cxmlPostLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cxmlPostLogin(params?: CxmlPostLogin$Params, context?: HttpContext): Observable<void> {
    return this.cxmlPostLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `punchoutControllerGridSearchoptions()` */
  static readonly PunchoutControllerGridSearchoptionsPath = '/punchout/cxml/searchgrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain$Response(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Plain(params?: GridSearch$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch$Response(params?: GridSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearch(params?: GridSearch$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `punchoutControllerGetByIdid()` */
  static readonly PunchoutControllerGetByIdidPath = '/punchout/cxml/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain$Response(params: GetById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PunchoutcXmlAddress>> {
    return getById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Plain(params: GetById$Plain$Params, context?: HttpContext): Observable<PunchoutcXmlAddress> {
    return this.getById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PunchoutcXmlAddress>): PunchoutcXmlAddress => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById$Response(params: GetById$Params, context?: HttpContext): Observable<StrictHttpResponse<PunchoutcXmlAddress>> {
    return getById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getById(params: GetById$Params, context?: HttpContext): Observable<PunchoutcXmlAddress> {
    return this.getById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PunchoutcXmlAddress>): PunchoutcXmlAddress => r.body)
    );
  }

  /** Path part for operation `punchoutControllerDeleteid()` */
  static readonly PunchoutControllerDeleteidPath = '/punchout/cxml/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `punchoutControllerSavepunchoutcXmlAddress()` */
  static readonly PunchoutControllerSavepunchoutcXmlAddressPath = '/punchout/cxml/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `save()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save$Response(params?: Save$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return save(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `save$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  save(params?: Save$Params, context?: HttpContext): Observable<void> {
    return this.save$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
