import { Component,  Input, ViewEncapsulation, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import format from 'date-fns/format';

@Component({
  selector: 'mibp-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatepickerComponent),
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor {

  dateValue: Date = null;
  @Input() placeholder: string;
  @Input() displayBlock = false;
  @Input() hideClearIcon = false;
  // TODO: Rename event to non-native event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<Date>();
  @Output() utcChange = new EventEmitter<Date>();

  minDateValue: Date = null;
  maxDateValue: Date = null;
  public isDisabled = false;
  public stylingName = "datepicker";
  onChange: (value: any) => void = () => {};
  onTouch: () => void = () => {};

  @Input()
  set minDate(min: Date | string) {
    if (typeof min === 'string' || min instanceof Date) {
      this.minDateValue = new Date(min);
    } else if (typeof min === 'object') {
      this.minDateValue = min;
    } else {
      this.minDateValue = null;
    }
  }

  @Input()
  set maxDate(max: Date | string) {
    if (typeof max === 'string' || max instanceof Date) {
      this.maxDateValue = new Date(max);
    } else if (typeof max === 'object') {
      this.maxDateValue = max;
    } else {
      this.maxDateValue = null;
    }
  }

  @Input()
  public set date(value: Date | string) {

    if (value) {
      if (typeof value === 'string') {
        value = new Date(value);
      }
      this.dateValue = value;
    } else {
      this.dateValue = null;
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type === 'change') {
      if (event.value) {
        const momentUtcDate = format(event.value, 'yyyy-MM-dd HH:mm:ss');
        this.onChange(momentUtcDate);
        this.utcChange.emit(new Date(format(event.value, 'yyyy-MM-dd HH:mm:ss') + 'Z'));
        this.change.emit(event.value);
        this.onTouch();
      } else {
        this.onChange(null);
        this.change.emit(null);
        this.utcChange.emit(null);
        this.onTouch();
      }
    }
  }
  writeValue(obj: any): void {
    this.date = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  triggerChange(event: any) {
    if (event.value) {
      const momentUtcDate = format(event.value, 'yyyy-MM-dd HH:mm:ss');
      this.onChange(momentUtcDate);
      this.onTouch();
      this.change.emit(event.value);
    } else {
      this.onTouch();
      this.onChange(null);
      this.change.emit(null);
    }
  }
}
