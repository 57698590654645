/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActiveCartInformationVm } from '../models/active-cart-information-vm';
import { addProducts } from '../fn/carts/add-products';
import { AddProducts$Params } from '../fn/carts/add-products';
import { addProducts$Plain } from '../fn/carts/add-products-plain';
import { AddProducts$Plain$Params } from '../fn/carts/add-products-plain';
import { cancelQuotation } from '../fn/carts/cancel-quotation';
import { CancelQuotation$Params } from '../fn/carts/cancel-quotation';
import { cancelQuotation$Plain } from '../fn/carts/cancel-quotation-plain';
import { CancelQuotation$Plain$Params } from '../fn/carts/cancel-quotation-plain';
import { CartAddProductsResponseViewModel } from '../models/cart-add-products-response-view-model';
import { CartCheckoutViewModel } from '../models/cart-checkout-view-model';
import { CartForListViewModelPagedResponse } from '../models/cart-for-list-view-model-paged-response';
import { CartItemViewModelCartItemPagedResponse } from '../models/cart-item-view-model-cart-item-paged-response';
import { CartItemVmCartItemPagedResponse } from '../models/cart-item-vm-cart-item-paged-response';
import { CartValidationResult } from '../models/cart-validation-result';
import { checkCartHasDuplicates } from '../fn/carts/check-cart-has-duplicates';
import { CheckCartHasDuplicates$Params } from '../fn/carts/check-cart-has-duplicates';
import { checkCartHasDuplicates$Plain } from '../fn/carts/check-cart-has-duplicates-plain';
import { CheckCartHasDuplicates$Plain$Params } from '../fn/carts/check-cart-has-duplicates-plain';
import { convertQuotation } from '../fn/carts/convert-quotation';
import { ConvertQuotation$Params } from '../fn/carts/convert-quotation';
import { convertQuotation$Plain } from '../fn/carts/convert-quotation-plain';
import { ConvertQuotation$Plain$Params } from '../fn/carts/convert-quotation-plain';
import { ConvertQuotationValidationResult } from '../models/convert-quotation-validation-result';
import { deleteCart } from '../fn/carts/delete-cart';
import { DeleteCart$Params } from '../fn/carts/delete-cart';
import { deleteCartItem } from '../fn/carts/delete-cart-item';
import { DeleteCartItem$Params } from '../fn/carts/delete-cart-item';
import { deleteCartItem$Plain } from '../fn/carts/delete-cart-item-plain';
import { DeleteCartItem$Plain$Params } from '../fn/carts/delete-cart-item-plain';
import { deleteErrorProducts } from '../fn/carts/delete-error-products';
import { DeleteErrorProducts$Params } from '../fn/carts/delete-error-products';
import { ensureCartIsValid } from '../fn/carts/ensure-cart-is-valid';
import { EnsureCartIsValid$Params } from '../fn/carts/ensure-cart-is-valid';
import { ensureCartIsValidForMibpPayment } from '../fn/carts/ensure-cart-is-valid-for-mibp-payment';
import { EnsureCartIsValidForMibpPayment$Params } from '../fn/carts/ensure-cart-is-valid-for-mibp-payment';
import { getActive } from '../fn/carts/get-active';
import { GetActive$Params } from '../fn/carts/get-active';
import { getActive$Plain } from '../fn/carts/get-active-plain';
import { GetActive$Plain$Params } from '../fn/carts/get-active-plain';
import { getActiveCartCount } from '../fn/carts/get-active-cart-count';
import { GetActiveCartCount$Params } from '../fn/carts/get-active-cart-count';
import { getActiveCartCount$Plain } from '../fn/carts/get-active-cart-count-plain';
import { GetActiveCartCount$Plain$Params } from '../fn/carts/get-active-cart-count-plain';
import { getActiveForCheckout } from '../fn/carts/get-active-for-checkout';
import { GetActiveForCheckout$Params } from '../fn/carts/get-active-for-checkout';
import { getActiveForCheckout$Plain } from '../fn/carts/get-active-for-checkout-plain';
import { GetActiveForCheckout$Plain$Params } from '../fn/carts/get-active-for-checkout-plain';
import { getCartItems } from '../fn/carts/get-cart-items';
import { GetCartItems$Params } from '../fn/carts/get-cart-items';
import { getCartItems$Plain } from '../fn/carts/get-cart-items-plain';
import { GetCartItems$Plain$Params } from '../fn/carts/get-cart-items-plain';
import { getCartItemsV2 } from '../fn/carts/get-cart-items-v-2';
import { GetCartItemsV2$Params } from '../fn/carts/get-cart-items-v-2';
import { getCartItemsV2$Plain } from '../fn/carts/get-cart-items-v-2-plain';
import { GetCartItemsV2$Plain$Params } from '../fn/carts/get-cart-items-v-2-plain';
import { getItems } from '../fn/carts/get-items';
import { GetItems$Params } from '../fn/carts/get-items';
import { getItems$Plain } from '../fn/carts/get-items-plain';
import { GetItems$Plain$Params } from '../fn/carts/get-items-plain';
import { getItemsIncludingUpsellCrossell } from '../fn/carts/get-items-including-upsell-crossell';
import { GetItemsIncludingUpsellCrossell$Params } from '../fn/carts/get-items-including-upsell-crossell';
import { getItemsIncludingUpsellCrossell$Plain } from '../fn/carts/get-items-including-upsell-crossell-plain';
import { GetItemsIncludingUpsellCrossell$Plain$Params } from '../fn/carts/get-items-including-upsell-crossell-plain';
import { getMibpPaymentMethodResponseDto } from '../fn/carts/get-mibp-payment-method-response-dto';
import { GetMibpPaymentMethodResponseDto$Params } from '../fn/carts/get-mibp-payment-method-response-dto';
import { getMibpPaymentMethodResponseDto$Plain } from '../fn/carts/get-mibp-payment-method-response-dto-plain';
import { GetMibpPaymentMethodResponseDto$Plain$Params } from '../fn/carts/get-mibp-payment-method-response-dto-plain';
import { getPromotionProductForActiveCartExceedingQuantity } from '../fn/carts/get-promotion-product-for-active-cart-exceeding-quantity';
import { GetPromotionProductForActiveCartExceedingQuantity$Params } from '../fn/carts/get-promotion-product-for-active-cart-exceeding-quantity';
import { getPromotionProductForActiveCartExceedingQuantity$Plain } from '../fn/carts/get-promotion-product-for-active-cart-exceeding-quantity-plain';
import { GetPromotionProductForActiveCartExceedingQuantity$Plain$Params } from '../fn/carts/get-promotion-product-for-active-cart-exceeding-quantity-plain';
import { getQuickAddTemplates } from '../fn/carts/get-quick-add-templates';
import { GetQuickAddTemplates$Params } from '../fn/carts/get-quick-add-templates';
import { getQuickAddTemplates$Plain } from '../fn/carts/get-quick-add-templates-plain';
import { GetQuickAddTemplates$Plain$Params } from '../fn/carts/get-quick-add-templates-plain';
import { getSuggestedKitProductList } from '../fn/carts/get-suggested-kit-product-list';
import { GetSuggestedKitProductList$Params } from '../fn/carts/get-suggested-kit-product-list';
import { getSuggestedKitProductList$Plain } from '../fn/carts/get-suggested-kit-product-list-plain';
import { GetSuggestedKitProductList$Plain$Params } from '../fn/carts/get-suggested-kit-product-list-plain';
import { getSuggestedKitsForActiveCart } from '../fn/carts/get-suggested-kits-for-active-cart';
import { GetSuggestedKitsForActiveCart$Params } from '../fn/carts/get-suggested-kits-for-active-cart';
import { getSuggestedKitsForActiveCart$Plain } from '../fn/carts/get-suggested-kits-for-active-cart-plain';
import { GetSuggestedKitsForActiveCart$Plain$Params } from '../fn/carts/get-suggested-kits-for-active-cart-plain';
import { getSuggestedKitsForActiveCartExcludingExistingInCart } from '../fn/carts/get-suggested-kits-for-active-cart-excluding-existing-in-cart';
import { GetSuggestedKitsForActiveCartExcludingExistingInCart$Params } from '../fn/carts/get-suggested-kits-for-active-cart-excluding-existing-in-cart';
import { getSuggestedKitsForActiveCartExcludingExistingInCart$Plain } from '../fn/carts/get-suggested-kits-for-active-cart-excluding-existing-in-cart-plain';
import { GetSuggestedKitsForActiveCartExcludingExistingInCart$Plain$Params } from '../fn/carts/get-suggested-kits-for-active-cart-excluding-existing-in-cart-plain';
import { getTotalCartWeight } from '../fn/carts/get-total-cart-weight';
import { GetTotalCartWeight$Params } from '../fn/carts/get-total-cart-weight';
import { getTotalCartWeight$Plain } from '../fn/carts/get-total-cart-weight-plain';
import { GetTotalCartWeight$Plain$Params } from '../fn/carts/get-total-cart-weight-plain';
import { getUsersCartsWithStatus } from '../fn/carts/get-users-carts-with-status';
import { GetUsersCartsWithStatus$Params } from '../fn/carts/get-users-carts-with-status';
import { getUsersCartsWithStatus$Plain } from '../fn/carts/get-users-carts-with-status-plain';
import { GetUsersCartsWithStatus$Plain$Params } from '../fn/carts/get-users-carts-with-status-plain';
import { isInvoicePaymentAllowed } from '../fn/carts/is-invoice-payment-allowed';
import { IsInvoicePaymentAllowed$Params } from '../fn/carts/is-invoice-payment-allowed';
import { isInvoicePaymentAllowed$Plain } from '../fn/carts/is-invoice-payment-allowed-plain';
import { IsInvoicePaymentAllowed$Plain$Params } from '../fn/carts/is-invoice-payment-allowed-plain';
import { Item } from '../models/item';
import { KitAddToCartSuggetion } from '../models/kit-add-to-cart-suggetion';
import { KitSuggestionDto } from '../models/kit-suggestion-dto';
import { MibpMakePaymentResponseDto } from '../models/mibp-make-payment-response-dto';
import { MibpPaymentResultCodeDataVm } from '../models/mibp-payment-result-code-data-vm';
import { processResultCode } from '../fn/carts/process-result-code';
import { ProcessResultCode$Params } from '../fn/carts/process-result-code';
import { processResultCode$Plain } from '../fn/carts/process-result-code-plain';
import { ProcessResultCode$Plain$Params } from '../fn/carts/process-result-code-plain';
import { ProductCodeandQuantityDto } from '../models/product-codeand-quantity-dto';
import { productsWithoutPrice } from '../fn/carts/products-without-price';
import { ProductsWithoutPrice$Params } from '../fn/carts/products-without-price';
import { productsWithoutPrice$Plain } from '../fn/carts/products-without-price-plain';
import { ProductsWithoutPrice$Plain$Params } from '../fn/carts/products-without-price-plain';
import { punchout } from '../fn/carts/punchout';
import { Punchout$Params } from '../fn/carts/punchout';
import { punchout$Plain } from '../fn/carts/punchout-plain';
import { Punchout$Plain$Params } from '../fn/carts/punchout-plain';
import { QuickAddTemplateViewModelPagedResponse } from '../models/quick-add-template-view-model-paged-response';
import { removeSuggestedKitProductFromCart } from '../fn/carts/remove-suggested-kit-product-from-cart';
import { RemoveSuggestedKitProductFromCart$Params } from '../fn/carts/remove-suggested-kit-product-from-cart';
import { rename } from '../fn/carts/rename';
import { Rename$Params } from '../fn/carts/rename';
import { replaceProductsWithSuperseededProductsFromPandA } from '../fn/carts/replace-products-with-superseeded-products-from-pand-a';
import { ReplaceProductsWithSuperseededProductsFromPandA$Params } from '../fn/carts/replace-products-with-superseeded-products-from-pand-a';
import { replaceProductsWithUpsellProduct } from '../fn/carts/replace-products-with-upsell-product';
import { ReplaceProductsWithUpsellProduct$Params } from '../fn/carts/replace-products-with-upsell-product';
import { requestQuote } from '../fn/carts/request-quote';
import { RequestQuote$Params } from '../fn/carts/request-quote';
import { requestQuote$Plain } from '../fn/carts/request-quote-plain';
import { RequestQuote$Plain$Params } from '../fn/carts/request-quote-plain';
import { restoreSavedCart } from '../fn/carts/restore-saved-cart';
import { RestoreSavedCart$Params } from '../fn/carts/restore-saved-cart';
import { restoreSavedCart$Plain } from '../fn/carts/restore-saved-cart-plain';
import { RestoreSavedCart$Plain$Params } from '../fn/carts/restore-saved-cart-plain';
import { saveActiveCart } from '../fn/carts/save-active-cart';
import { SaveActiveCart$Params } from '../fn/carts/save-active-cart';
import { saveActiveCartAsTemplate } from '../fn/carts/save-active-cart-as-template';
import { SaveActiveCartAsTemplate$Params } from '../fn/carts/save-active-cart-as-template';
import { sendOrderForInvoice } from '../fn/carts/send-order-for-invoice';
import { SendOrderForInvoice$Params } from '../fn/carts/send-order-for-invoice';
import { sendOrderForInvoice$Plain } from '../fn/carts/send-order-for-invoice-plain';
import { SendOrderForInvoice$Plain$Params } from '../fn/carts/send-order-for-invoice-plain';
import { sendOrderForMibpPayment } from '../fn/carts/send-order-for-mibp-payment';
import { SendOrderForMibpPayment$Params } from '../fn/carts/send-order-for-mibp-payment';
import { sendOrderForMibpPayment$Plain } from '../fn/carts/send-order-for-mibp-payment-plain';
import { SendOrderForMibpPayment$Plain$Params } from '../fn/carts/send-order-for-mibp-payment-plain';
import { shareTemplate } from '../fn/carts/share-template';
import { ShareTemplate$Params } from '../fn/carts/share-template';
import { unShareTemplate } from '../fn/carts/un-share-template';
import { UnShareTemplate$Params } from '../fn/carts/un-share-template';
import { updatedKitSuggestedStatusForActiveCart } from '../fn/carts/updated-kit-suggested-status-for-active-cart';
import { UpdatedKitSuggestedStatusForActiveCart$Params } from '../fn/carts/updated-kit-suggested-status-for-active-cart';
import { updateQuantity } from '../fn/carts/update-quantity';
import { UpdateQuantity$Params } from '../fn/carts/update-quantity';
import { updateQuantity$Plain } from '../fn/carts/update-quantity-plain';
import { UpdateQuantity$Plain$Params } from '../fn/carts/update-quantity-plain';
import { uploadExcel } from '../fn/carts/upload-excel';
import { UploadExcel$Params } from '../fn/carts/upload-excel';
import { uploadExcel$Plain } from '../fn/carts/upload-excel-plain';
import { UploadExcel$Plain$Params } from '../fn/carts/upload-excel-plain';
import { validatePriceChanges } from '../fn/carts/validate-price-changes';
import { ValidatePriceChanges$Params } from '../fn/carts/validate-price-changes';
import { validatePriceChanges$Plain } from '../fn/carts/validate-price-changes-plain';
import { ValidatePriceChanges$Plain$Params } from '../fn/carts/validate-price-changes-plain';
import { ValidationResult } from '../models/validation-result';

@Injectable({ providedIn: 'root' })
export class CartsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `cartsControllerGetActiveCartCount()` */
  static readonly CartsControllerGetActiveCartCountPath = '/carts/active/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveCartCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveCartCount$Plain$Response(params?: GetActiveCartCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getActiveCartCount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveCartCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveCartCount$Plain(params?: GetActiveCartCount$Plain$Params, context?: HttpContext): Observable<number> {
    return this.getActiveCartCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveCartCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveCartCount$Response(params?: GetActiveCartCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getActiveCartCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveCartCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveCartCount(params?: GetActiveCartCount$Params, context?: HttpContext): Observable<number> {
    return this.getActiveCartCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetCartItemscartId()` */
  static readonly CartsControllerGetCartItemscartIdPath = '/carts/{cartId}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCartItems$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartItems$Plain$Response(params: GetCartItems$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartItemViewModelCartItemPagedResponse>> {
    return getCartItems$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCartItems$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartItems$Plain(params: GetCartItems$Plain$Params, context?: HttpContext): Observable<CartItemViewModelCartItemPagedResponse> {
    return this.getCartItems$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartItemViewModelCartItemPagedResponse>): CartItemViewModelCartItemPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCartItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartItems$Response(params: GetCartItems$Params, context?: HttpContext): Observable<StrictHttpResponse<CartItemViewModelCartItemPagedResponse>> {
    return getCartItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCartItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartItems(params: GetCartItems$Params, context?: HttpContext): Observable<CartItemViewModelCartItemPagedResponse> {
    return this.getCartItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartItemViewModelCartItemPagedResponse>): CartItemViewModelCartItemPagedResponse => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetSuggestedKitsForActiveCart()` */
  static readonly CartsControllerGetSuggestedKitsForActiveCartPath = '/carts/active/kits';

  /**
   * Get Kit Suggestions for active cart.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedKitsForActiveCart$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitsForActiveCart$Plain$Response(params?: GetSuggestedKitsForActiveCart$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<KitSuggestionDto>> {
    return getSuggestedKitsForActiveCart$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Kit Suggestions for active cart.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSuggestedKitsForActiveCart$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitsForActiveCart$Plain(params?: GetSuggestedKitsForActiveCart$Plain$Params, context?: HttpContext): Observable<KitSuggestionDto> {
    return this.getSuggestedKitsForActiveCart$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<KitSuggestionDto>): KitSuggestionDto => r.body)
    );
  }

  /**
   * Get Kit Suggestions for active cart.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedKitsForActiveCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitsForActiveCart$Response(params?: GetSuggestedKitsForActiveCart$Params, context?: HttpContext): Observable<StrictHttpResponse<KitSuggestionDto>> {
    return getSuggestedKitsForActiveCart(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Kit Suggestions for active cart.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSuggestedKitsForActiveCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitsForActiveCart(params?: GetSuggestedKitsForActiveCart$Params, context?: HttpContext): Observable<KitSuggestionDto> {
    return this.getSuggestedKitsForActiveCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<KitSuggestionDto>): KitSuggestionDto => r.body)
    );
  }

  /** Path part for operation `cartsControllerUpdatedKitSuggestedStatusForActiveCart()` */
  static readonly CartsControllerUpdatedKitSuggestedStatusForActiveCartPath = '/carts/active/kitsuggestedstatus';

  /**
   * Update Kit Suggested Status for active cart.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatedKitSuggestedStatusForActiveCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatedKitSuggestedStatusForActiveCart$Response(params?: UpdatedKitSuggestedStatusForActiveCart$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updatedKitSuggestedStatusForActiveCart(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Kit Suggested Status for active cart.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatedKitSuggestedStatusForActiveCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatedKitSuggestedStatusForActiveCart(params?: UpdatedKitSuggestedStatusForActiveCart$Params, context?: HttpContext): Observable<void> {
    return this.updatedKitSuggestedStatusForActiveCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetSuggestedKitProductListproductCode()` */
  static readonly CartsControllerGetSuggestedKitProductListproductCodePath = '/carts/active/suggestedkits/{productCode}/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedKitProductList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitProductList$Plain$Response(params: GetSuggestedKitProductList$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<KitAddToCartSuggetion>> {
    return getSuggestedKitProductList$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSuggestedKitProductList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitProductList$Plain(params: GetSuggestedKitProductList$Plain$Params, context?: HttpContext): Observable<KitAddToCartSuggetion> {
    return this.getSuggestedKitProductList$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<KitAddToCartSuggetion>): KitAddToCartSuggetion => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedKitProductList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitProductList$Response(params: GetSuggestedKitProductList$Params, context?: HttpContext): Observable<StrictHttpResponse<KitAddToCartSuggetion>> {
    return getSuggestedKitProductList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSuggestedKitProductList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitProductList(params: GetSuggestedKitProductList$Params, context?: HttpContext): Observable<KitAddToCartSuggetion> {
    return this.getSuggestedKitProductList$Response(params, context).pipe(
      map((r: StrictHttpResponse<KitAddToCartSuggetion>): KitAddToCartSuggetion => r.body)
    );
  }

  /** Path part for operation `cartsControllerRemoveSuggestedKitProductFromCartkitproductCode()` */
  static readonly CartsControllerRemoveSuggestedKitProductFromCartkitproductCodePath = '/carts/active/suggestedkits/{kitproductCode}/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeSuggestedKitProductFromCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSuggestedKitProductFromCart$Response(params: RemoveSuggestedKitProductFromCart$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeSuggestedKitProductFromCart(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeSuggestedKitProductFromCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSuggestedKitProductFromCart(params: RemoveSuggestedKitProductFromCart$Params, context?: HttpContext): Observable<void> {
    return this.removeSuggestedKitProductFromCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerAddProductsproductsDtoSourceCartIdCurrentDatePatternDateTimeOffsetMinutes()` */
  static readonly CartsControllerAddProductsproductsDtoSourceCartIdCurrentDatePatternDateTimeOffsetMinutesPath = '/carts/addproducts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addProducts$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addProducts$Plain$Response(params?: AddProducts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartAddProductsResponseViewModel>> {
    return addProducts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addProducts$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addProducts$Plain(params?: AddProducts$Plain$Params, context?: HttpContext): Observable<CartAddProductsResponseViewModel> {
    return this.addProducts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartAddProductsResponseViewModel>): CartAddProductsResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addProducts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addProducts$Response(params?: AddProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<CartAddProductsResponseViewModel>> {
    return addProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addProducts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addProducts(params?: AddProducts$Params, context?: HttpContext): Observable<CartAddProductsResponseViewModel> {
    return this.addProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartAddProductsResponseViewModel>): CartAddProductsResponseViewModel => r.body)
    );
  }

  /** Path part for operation `cartsControllerDeleteCartid()` */
  static readonly CartsControllerDeleteCartidPath = '/carts/id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCart$Response(params?: DeleteCart$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCart(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCart(params?: DeleteCart$Params, context?: HttpContext): Observable<void> {
    return this.deleteCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerRequestQuoterequest()` */
  static readonly CartsControllerRequestQuoterequestPath = '/carts/active/requestquote';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestQuote$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestQuote$Plain$Response(params?: RequestQuote$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return requestQuote$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestQuote$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestQuote$Plain(params?: RequestQuote$Plain$Params, context?: HttpContext): Observable<string> {
    return this.requestQuote$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestQuote()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestQuote$Response(params?: RequestQuote$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return requestQuote(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestQuote$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestQuote(params?: RequestQuote$Params, context?: HttpContext): Observable<string> {
    return this.requestQuote$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetActiveForCheckout()` */
  static readonly CartsControllerGetActiveForCheckoutPath = '/carts/active/forcheckout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveForCheckout$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveForCheckout$Plain$Response(params?: GetActiveForCheckout$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartCheckoutViewModel>> {
    return getActiveForCheckout$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveForCheckout$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveForCheckout$Plain(params?: GetActiveForCheckout$Plain$Params, context?: HttpContext): Observable<CartCheckoutViewModel> {
    return this.getActiveForCheckout$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartCheckoutViewModel>): CartCheckoutViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveForCheckout()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveForCheckout$Response(params?: GetActiveForCheckout$Params, context?: HttpContext): Observable<StrictHttpResponse<CartCheckoutViewModel>> {
    return getActiveForCheckout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveForCheckout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveForCheckout(params?: GetActiveForCheckout$Params, context?: HttpContext): Observable<CartCheckoutViewModel> {
    return this.getActiveForCheckout$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartCheckoutViewModel>): CartCheckoutViewModel => r.body)
    );
  }

  /** Path part for operation `cartsControllerIsInvoicePaymentAllowed()` */
  static readonly CartsControllerIsInvoicePaymentAllowedPath = '/carts/active/isinvoicepaymentallowed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isInvoicePaymentAllowed$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isInvoicePaymentAllowed$Plain$Response(params?: IsInvoicePaymentAllowed$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isInvoicePaymentAllowed$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isInvoicePaymentAllowed$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isInvoicePaymentAllowed$Plain(params?: IsInvoicePaymentAllowed$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.isInvoicePaymentAllowed$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isInvoicePaymentAllowed()` instead.
   *
   * This method doesn't expect any request body.
   */
  isInvoicePaymentAllowed$Response(params?: IsInvoicePaymentAllowed$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isInvoicePaymentAllowed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isInvoicePaymentAllowed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isInvoicePaymentAllowed(params?: IsInvoicePaymentAllowed$Params, context?: HttpContext): Observable<boolean> {
    return this.isInvoicePaymentAllowed$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `cartsControllerSendOrderForInvoicerequest()` */
  static readonly CartsControllerSendOrderForInvoicerequestPath = '/carts/active/sendorder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendOrderForInvoice$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendOrderForInvoice$Plain$Response(params?: SendOrderForInvoice$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return sendOrderForInvoice$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendOrderForInvoice$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendOrderForInvoice$Plain(params?: SendOrderForInvoice$Plain$Params, context?: HttpContext): Observable<string> {
    return this.sendOrderForInvoice$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendOrderForInvoice()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendOrderForInvoice$Response(params?: SendOrderForInvoice$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return sendOrderForInvoice(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendOrderForInvoice$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendOrderForInvoice(params?: SendOrderForInvoice$Params, context?: HttpContext): Observable<string> {
    return this.sendOrderForInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `cartsControllerEnsureCartIsValidrequest()` */
  static readonly CartsControllerEnsureCartIsValidrequestPath = '/carts/active/validate/ensure-cart-is-valid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ensureCartIsValid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ensureCartIsValid$Response(params?: EnsureCartIsValid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ensureCartIsValid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ensureCartIsValid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ensureCartIsValid(params?: EnsureCartIsValid$Params, context?: HttpContext): Observable<void> {
    return this.ensureCartIsValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerValidatePriceChanges()` */
  static readonly CartsControllerValidatePriceChangesPath = '/carts/active/validate/price-changes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validatePriceChanges$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  validatePriceChanges$Plain$Response(params?: ValidatePriceChanges$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartValidationResult>> {
    return validatePriceChanges$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validatePriceChanges$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validatePriceChanges$Plain(params?: ValidatePriceChanges$Plain$Params, context?: HttpContext): Observable<CartValidationResult> {
    return this.validatePriceChanges$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartValidationResult>): CartValidationResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validatePriceChanges()` instead.
   *
   * This method doesn't expect any request body.
   */
  validatePriceChanges$Response(params?: ValidatePriceChanges$Params, context?: HttpContext): Observable<StrictHttpResponse<CartValidationResult>> {
    return validatePriceChanges(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validatePriceChanges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validatePriceChanges(params?: ValidatePriceChanges$Params, context?: HttpContext): Observable<CartValidationResult> {
    return this.validatePriceChanges$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartValidationResult>): CartValidationResult => r.body)
    );
  }

  /** Path part for operation `cartsControllerEnsureCartIsValidForMibpPaymentrequest()` */
  static readonly CartsControllerEnsureCartIsValidForMibpPaymentrequestPath = '/carts/active/mibppayment/validate/ensure-cart-is-valid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ensureCartIsValidForMibpPayment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ensureCartIsValidForMibpPayment$Response(params?: EnsureCartIsValidForMibpPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ensureCartIsValidForMibpPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ensureCartIsValidForMibpPayment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ensureCartIsValidForMibpPayment(params?: EnsureCartIsValidForMibpPayment$Params, context?: HttpContext): Observable<void> {
    return this.ensureCartIsValidForMibpPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetMibpPaymentMethodResponseDto()` */
  static readonly CartsControllerGetMibpPaymentMethodResponseDtoPath = '/carts/active/mibppayment/payment-methods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMibpPaymentMethodResponseDto$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpPaymentMethodResponseDto$Plain$Response(params?: GetMibpPaymentMethodResponseDto$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return getMibpPaymentMethodResponseDto$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMibpPaymentMethodResponseDto$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpPaymentMethodResponseDto$Plain(params?: GetMibpPaymentMethodResponseDto$Plain$Params, context?: HttpContext): Observable<any> {
    return this.getMibpPaymentMethodResponseDto$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMibpPaymentMethodResponseDto()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpPaymentMethodResponseDto$Response(params?: GetMibpPaymentMethodResponseDto$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return getMibpPaymentMethodResponseDto(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMibpPaymentMethodResponseDto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpPaymentMethodResponseDto(params?: GetMibpPaymentMethodResponseDto$Params, context?: HttpContext): Observable<any> {
    return this.getMibpPaymentMethodResponseDto$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `cartsControllerSendOrderForMibpPaymentsendOrderForMibpPaymentRequest()` */
  static readonly CartsControllerSendOrderForMibpPaymentsendOrderForMibpPaymentRequestPath = '/carts/active/mibppayment/send-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendOrderForMibpPayment$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendOrderForMibpPayment$Plain$Response(params?: SendOrderForMibpPayment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpMakePaymentResponseDto>> {
    return sendOrderForMibpPayment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendOrderForMibpPayment$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendOrderForMibpPayment$Plain(params?: SendOrderForMibpPayment$Plain$Params, context?: HttpContext): Observable<MibpMakePaymentResponseDto> {
    return this.sendOrderForMibpPayment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpMakePaymentResponseDto>): MibpMakePaymentResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendOrderForMibpPayment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendOrderForMibpPayment$Response(params?: SendOrderForMibpPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpMakePaymentResponseDto>> {
    return sendOrderForMibpPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendOrderForMibpPayment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendOrderForMibpPayment(params?: SendOrderForMibpPayment$Params, context?: HttpContext): Observable<MibpMakePaymentResponseDto> {
    return this.sendOrderForMibpPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpMakePaymentResponseDto>): MibpMakePaymentResponseDto => r.body)
    );
  }

  /** Path part for operation `cartsControllerProcessResultCoderesultCodeMerchantReference()` */
  static readonly CartsControllerProcessResultCoderesultCodeMerchantReferencePath = '/carts/active/mibppayment/result-code/{merchantReference}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processResultCode$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  processResultCode$Plain$Response(params: ProcessResultCode$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpPaymentResultCodeDataVm>> {
    return processResultCode$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processResultCode$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processResultCode$Plain(params: ProcessResultCode$Plain$Params, context?: HttpContext): Observable<MibpPaymentResultCodeDataVm> {
    return this.processResultCode$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpPaymentResultCodeDataVm>): MibpPaymentResultCodeDataVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processResultCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  processResultCode$Response(params: ProcessResultCode$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpPaymentResultCodeDataVm>> {
    return processResultCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processResultCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processResultCode(params: ProcessResultCode$Params, context?: HttpContext): Observable<MibpPaymentResultCodeDataVm> {
    return this.processResultCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpPaymentResultCodeDataVm>): MibpPaymentResultCodeDataVm => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetUsersCartsWithStatusrequest()` */
  static readonly CartsControllerGetUsersCartsWithStatusrequestPath = '/carts/userscartswithstatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersCartsWithStatus$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersCartsWithStatus$Plain$Response(params?: GetUsersCartsWithStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartForListViewModelPagedResponse>> {
    return getUsersCartsWithStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersCartsWithStatus$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersCartsWithStatus$Plain(params?: GetUsersCartsWithStatus$Plain$Params, context?: HttpContext): Observable<CartForListViewModelPagedResponse> {
    return this.getUsersCartsWithStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartForListViewModelPagedResponse>): CartForListViewModelPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersCartsWithStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersCartsWithStatus$Response(params?: GetUsersCartsWithStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<CartForListViewModelPagedResponse>> {
    return getUsersCartsWithStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersCartsWithStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersCartsWithStatus(params?: GetUsersCartsWithStatus$Params, context?: HttpContext): Observable<CartForListViewModelPagedResponse> {
    return this.getUsersCartsWithStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartForListViewModelPagedResponse>): CartForListViewModelPagedResponse => r.body)
    );
  }

  /** Path part for operation `cartsControllerReplaceProductsWithSuperseededProductsFromPandAsuperseeddedProductsCartId()` */
  static readonly CartsControllerReplaceProductsWithSuperseededProductsFromPandAsuperseeddedProductsCartIdPath = '/carts/replaceproductswithsuperseededproducts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceProductsWithSuperseededProductsFromPandA()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  replaceProductsWithSuperseededProductsFromPandA$Response(params?: ReplaceProductsWithSuperseededProductsFromPandA$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return replaceProductsWithSuperseededProductsFromPandA(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceProductsWithSuperseededProductsFromPandA$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  replaceProductsWithSuperseededProductsFromPandA(params?: ReplaceProductsWithSuperseededProductsFromPandA$Params, context?: HttpContext): Observable<void> {
    return this.replaceProductsWithSuperseededProductsFromPandA$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerReplaceProductsWithUpsellProductproductIdToReplaceUpsellCrossSellProductId()` */
  static readonly CartsControllerReplaceProductsWithUpsellProductproductIdToReplaceUpsellCrossSellProductIdPath = '/carts/replaceproductswithupsellproducts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceProductsWithUpsellProduct()` instead.
   *
   * This method doesn't expect any request body.
   */
  replaceProductsWithUpsellProduct$Response(params?: ReplaceProductsWithUpsellProduct$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return replaceProductsWithUpsellProduct(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceProductsWithUpsellProduct$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  replaceProductsWithUpsellProduct(params?: ReplaceProductsWithUpsellProduct$Params, context?: HttpContext): Observable<void> {
    return this.replaceProductsWithUpsellProduct$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetQuickAddTemplatestypes()` */
  static readonly CartsControllerGetQuickAddTemplatestypesPath = '/carts/quickaddtemplates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuickAddTemplates$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getQuickAddTemplates$Plain$Response(params?: GetQuickAddTemplates$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<QuickAddTemplateViewModelPagedResponse>> {
    return getQuickAddTemplates$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuickAddTemplates$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getQuickAddTemplates$Plain(params?: GetQuickAddTemplates$Plain$Params, context?: HttpContext): Observable<QuickAddTemplateViewModelPagedResponse> {
    return this.getQuickAddTemplates$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuickAddTemplateViewModelPagedResponse>): QuickAddTemplateViewModelPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuickAddTemplates()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getQuickAddTemplates$Response(params?: GetQuickAddTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<QuickAddTemplateViewModelPagedResponse>> {
    return getQuickAddTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuickAddTemplates$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getQuickAddTemplates(params?: GetQuickAddTemplates$Params, context?: HttpContext): Observable<QuickAddTemplateViewModelPagedResponse> {
    return this.getQuickAddTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuickAddTemplateViewModelPagedResponse>): QuickAddTemplateViewModelPagedResponse => r.body)
    );
  }

  /** Path part for operation `cartsControllerSaveActiveCart()` */
  static readonly CartsControllerSaveActiveCartPath = '/carts/active/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveActiveCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveActiveCart$Response(params?: SaveActiveCart$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveActiveCart(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveActiveCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveActiveCart(params?: SaveActiveCart$Params, context?: HttpContext): Observable<void> {
    return this.saveActiveCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerCheckCartHasDuplicatescartId()` */
  static readonly CartsControllerCheckCartHasDuplicatescartIdPath = '/carts/{cartId}/hasduplicates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkCartHasDuplicates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCartHasDuplicates$Plain$Response(params: CheckCartHasDuplicates$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return checkCartHasDuplicates$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkCartHasDuplicates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCartHasDuplicates$Plain(params: CheckCartHasDuplicates$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.checkCartHasDuplicates$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkCartHasDuplicates()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCartHasDuplicates$Response(params: CheckCartHasDuplicates$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return checkCartHasDuplicates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkCartHasDuplicates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkCartHasDuplicates(params: CheckCartHasDuplicates$Params, context?: HttpContext): Observable<boolean> {
    return this.checkCartHasDuplicates$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `cartsControllerRestoreSavedCartcartId()` */
  static readonly CartsControllerRestoreSavedCartcartIdPath = '/carts/{cartId}/restoresavedcart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreSavedCart$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreSavedCart$Plain$Response(params: RestoreSavedCart$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return restoreSavedCart$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreSavedCart$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreSavedCart$Plain(params: RestoreSavedCart$Plain$Params, context?: HttpContext): Observable<number> {
    return this.restoreSavedCart$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreSavedCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreSavedCart$Response(params: RestoreSavedCart$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return restoreSavedCart(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreSavedCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreSavedCart(params: RestoreSavedCart$Params, context?: HttpContext): Observable<number> {
    return this.restoreSavedCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `cartsControllerSaveActiveCartAsTemplate()` */
  static readonly CartsControllerSaveActiveCartAsTemplatePath = '/carts/active/saveastemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveActiveCartAsTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveActiveCartAsTemplate$Response(params?: SaveActiveCartAsTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveActiveCartAsTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveActiveCartAsTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveActiveCartAsTemplate(params?: SaveActiveCartAsTemplate$Params, context?: HttpContext): Observable<void> {
    return this.saveActiveCartAsTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerShareTemplatecartId()` */
  static readonly CartsControllerShareTemplatecartIdPath = '/carts/{cartId}/sharetemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shareTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  shareTemplate$Response(params: ShareTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return shareTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shareTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shareTemplate(params: ShareTemplate$Params, context?: HttpContext): Observable<void> {
    return this.shareTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerUnShareTemplatecartId()` */
  static readonly CartsControllerUnShareTemplatecartIdPath = '/carts/{cartId}/unsharetemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unShareTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  unShareTemplate$Response(params: UnShareTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unShareTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unShareTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unShareTemplate(params: UnShareTemplate$Params, context?: HttpContext): Observable<void> {
    return this.unShareTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerDeleteCartItemcartIdItemId()` */
  static readonly CartsControllerDeleteCartItemcartIdItemIdPath = '/carts/{cartId}/deleteitem/{itemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCartItem$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCartItem$Plain$Response(params: DeleteCartItem$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return deleteCartItem$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCartItem$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCartItem$Plain(params: DeleteCartItem$Plain$Params, context?: HttpContext): Observable<number> {
    return this.deleteCartItem$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCartItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCartItem$Response(params: DeleteCartItem$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return deleteCartItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCartItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCartItem(params: DeleteCartItem$Params, context?: HttpContext): Observable<number> {
    return this.deleteCartItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetActive()` */
  static readonly CartsControllerGetActivePath = '/carts/active';

  /**
   * Returns basic info  (no items, prices or avaialability) about the active cart if one exists.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActive$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActive$Plain$Response(params?: GetActive$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveCartInformationVm>> {
    return getActive$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns basic info  (no items, prices or avaialability) about the active cart if one exists.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActive$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActive$Plain(params?: GetActive$Plain$Params, context?: HttpContext): Observable<ActiveCartInformationVm> {
    return this.getActive$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveCartInformationVm>): ActiveCartInformationVm => r.body)
    );
  }

  /**
   * Returns basic info  (no items, prices or avaialability) about the active cart if one exists.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActive()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActive$Response(params?: GetActive$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveCartInformationVm>> {
    return getActive(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns basic info  (no items, prices or avaialability) about the active cart if one exists.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActive(params?: GetActive$Params, context?: HttpContext): Observable<ActiveCartInformationVm> {
    return this.getActive$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveCartInformationVm>): ActiveCartInformationVm => r.body)
    );
  }

  /** Path part for operation `cartsControllerRenamecartIdName()` */
  static readonly CartsControllerRenamecartIdNamePath = '/carts/{cartId}/rename';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rename()` instead.
   *
   * This method doesn't expect any request body.
   */
  rename$Response(params: Rename$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rename(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rename$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rename(params: Rename$Params, context?: HttpContext): Observable<void> {
    return this.rename$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerUpdateQuantitycartIdItemIdQuantityProductCode()` */
  static readonly CartsControllerUpdateQuantitycartIdItemIdQuantityProductCodePath = '/carts/{cartId}/{itemId}/updatequantity/{quantity}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateQuantity$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateQuantity$Plain$Response(params: UpdateQuantity$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return updateQuantity$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateQuantity$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateQuantity$Plain(params: UpdateQuantity$Plain$Params, context?: HttpContext): Observable<number> {
    return this.updateQuantity$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateQuantity()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateQuantity$Response(params: UpdateQuantity$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return updateQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateQuantity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateQuantity(params: UpdateQuantity$Params, context?: HttpContext): Observable<number> {
    return this.updateQuantity$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `cartsControllerUploadExcelfilereq()` */
  static readonly CartsControllerUploadExcelfilereqPath = '/carts/uploadexcel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadExcel$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExcel$Plain$Response(params?: UploadExcel$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductCodeandQuantityDto>>> {
    return uploadExcel$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadExcel$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExcel$Plain(params?: UploadExcel$Plain$Params, context?: HttpContext): Observable<Array<ProductCodeandQuantityDto>> {
    return this.uploadExcel$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductCodeandQuantityDto>>): Array<ProductCodeandQuantityDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadExcel()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExcel$Response(params?: UploadExcel$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductCodeandQuantityDto>>> {
    return uploadExcel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadExcel$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExcel(params?: UploadExcel$Params, context?: HttpContext): Observable<Array<ProductCodeandQuantityDto>> {
    return this.uploadExcel$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductCodeandQuantityDto>>): Array<ProductCodeandQuantityDto> => r.body)
    );
  }

  /** Path part for operation `cartsControllerCancelQuotationorderId()` */
  static readonly CartsControllerCancelQuotationorderIdPath = '/carts/{orderId}/cancelquotation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelQuotation$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelQuotation$Plain$Response(params: CancelQuotation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ValidationResult>> {
    return cancelQuotation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelQuotation$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelQuotation$Plain(params: CancelQuotation$Plain$Params, context?: HttpContext): Observable<ValidationResult> {
    return this.cancelQuotation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ValidationResult>): ValidationResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelQuotation()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelQuotation$Response(params: CancelQuotation$Params, context?: HttpContext): Observable<StrictHttpResponse<ValidationResult>> {
    return cancelQuotation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelQuotation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelQuotation(params: CancelQuotation$Params, context?: HttpContext): Observable<ValidationResult> {
    return this.cancelQuotation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ValidationResult>): ValidationResult => r.body)
    );
  }

  /** Path part for operation `cartsControllerConvertQuotationrequest()` */
  static readonly CartsControllerConvertQuotationrequestPath = '/carts/convertquotation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `convertQuotation$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  convertQuotation$Plain$Response(params?: ConvertQuotation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConvertQuotationValidationResult>> {
    return convertQuotation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `convertQuotation$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  convertQuotation$Plain(params?: ConvertQuotation$Plain$Params, context?: HttpContext): Observable<ConvertQuotationValidationResult> {
    return this.convertQuotation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConvertQuotationValidationResult>): ConvertQuotationValidationResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `convertQuotation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  convertQuotation$Response(params?: ConvertQuotation$Params, context?: HttpContext): Observable<StrictHttpResponse<ConvertQuotationValidationResult>> {
    return convertQuotation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `convertQuotation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  convertQuotation(params?: ConvertQuotation$Params, context?: HttpContext): Observable<ConvertQuotationValidationResult> {
    return this.convertQuotation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConvertQuotationValidationResult>): ConvertQuotationValidationResult => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetItemspagedOptionsCartId()` */
  static readonly CartsControllerGetItemspagedOptionsCartIdPath = '/carts/{cartId}/cartitems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItems$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getItems$Plain$Response(params: GetItems$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartItemViewModelCartItemPagedResponse>> {
    return getItems$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItems$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getItems$Plain(params: GetItems$Plain$Params, context?: HttpContext): Observable<CartItemViewModelCartItemPagedResponse> {
    return this.getItems$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartItemViewModelCartItemPagedResponse>): CartItemViewModelCartItemPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getItems$Response(params: GetItems$Params, context?: HttpContext): Observable<StrictHttpResponse<CartItemViewModelCartItemPagedResponse>> {
    return getItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getItems(params: GetItems$Params, context?: HttpContext): Observable<CartItemViewModelCartItemPagedResponse> {
    return this.getItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartItemViewModelCartItemPagedResponse>): CartItemViewModelCartItemPagedResponse => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetCartItemsV2CartIdSkipTakeIncludeUpsellCrossell()` */
  static readonly CartsControllerGetCartItemsV2CartIdSkipTakeIncludeUpsellCrossellPath = '/carts/v2/{cartId}/items';

  /**
   * Endpoint for testing - just to return the new, smaller ViewModel for cart items.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCartItemsV2$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartItemsV2$Plain$Response(params: GetCartItemsV2$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartItemVmCartItemPagedResponse>> {
    return getCartItemsV2$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint for testing - just to return the new, smaller ViewModel for cart items.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCartItemsV2$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartItemsV2$Plain(params: GetCartItemsV2$Plain$Params, context?: HttpContext): Observable<CartItemVmCartItemPagedResponse> {
    return this.getCartItemsV2$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartItemVmCartItemPagedResponse>): CartItemVmCartItemPagedResponse => r.body)
    );
  }

  /**
   * Endpoint for testing - just to return the new, smaller ViewModel for cart items.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCartItemsV2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartItemsV2$Response(params: GetCartItemsV2$Params, context?: HttpContext): Observable<StrictHttpResponse<CartItemVmCartItemPagedResponse>> {
    return getCartItemsV2(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint for testing - just to return the new, smaller ViewModel for cart items.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCartItemsV2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartItemsV2(params: GetCartItemsV2$Params, context?: HttpContext): Observable<CartItemVmCartItemPagedResponse> {
    return this.getCartItemsV2$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartItemVmCartItemPagedResponse>): CartItemVmCartItemPagedResponse => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetItemsIncludingUpsellCrossellpagedOptionsCartId()` */
  static readonly CartsControllerGetItemsIncludingUpsellCrossellpagedOptionsCartIdPath = '/carts/{cartId}/itemsincludingupsellcrossell';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemsIncludingUpsellCrossell$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getItemsIncludingUpsellCrossell$Plain$Response(params: GetItemsIncludingUpsellCrossell$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CartItemViewModelCartItemPagedResponse>> {
    return getItemsIncludingUpsellCrossell$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItemsIncludingUpsellCrossell$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getItemsIncludingUpsellCrossell$Plain(params: GetItemsIncludingUpsellCrossell$Plain$Params, context?: HttpContext): Observable<CartItemViewModelCartItemPagedResponse> {
    return this.getItemsIncludingUpsellCrossell$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartItemViewModelCartItemPagedResponse>): CartItemViewModelCartItemPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemsIncludingUpsellCrossell()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getItemsIncludingUpsellCrossell$Response(params: GetItemsIncludingUpsellCrossell$Params, context?: HttpContext): Observable<StrictHttpResponse<CartItemViewModelCartItemPagedResponse>> {
    return getItemsIncludingUpsellCrossell(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItemsIncludingUpsellCrossell$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getItemsIncludingUpsellCrossell(params: GetItemsIncludingUpsellCrossell$Params, context?: HttpContext): Observable<CartItemViewModelCartItemPagedResponse> {
    return this.getItemsIncludingUpsellCrossell$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartItemViewModelCartItemPagedResponse>): CartItemViewModelCartItemPagedResponse => r.body)
    );
  }

  /** Path part for operation `cartsControllerPunchoutiscXmlAddress()` */
  static readonly CartsControllerPunchoutiscXmlAddressPath = '/carts/punchout/{iscXMLAddress}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `punchout$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  punchout$Plain$Response(params: Punchout$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return punchout$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `punchout$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  punchout$Plain(params: Punchout$Plain$Params, context?: HttpContext): Observable<string> {
    return this.punchout$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `punchout()` instead.
   *
   * This method doesn't expect any request body.
   */
  punchout$Response(params: Punchout$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return punchout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `punchout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  punchout(params: Punchout$Params, context?: HttpContext): Observable<string> {
    return this.punchout$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `cartsControllerProductsWithoutPricecartId()` */
  static readonly CartsControllerProductsWithoutPricecartIdPath = '/carts/{cartId}/productswithoutprice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsWithoutPrice$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsWithoutPrice$Plain$Response(params: ProductsWithoutPrice$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Item>>> {
    return productsWithoutPrice$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productsWithoutPrice$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsWithoutPrice$Plain(params: ProductsWithoutPrice$Plain$Params, context?: HttpContext): Observable<Array<Item>> {
    return this.productsWithoutPrice$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Item>>): Array<Item> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productsWithoutPrice()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsWithoutPrice$Response(params: ProductsWithoutPrice$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Item>>> {
    return productsWithoutPrice(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productsWithoutPrice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productsWithoutPrice(params: ProductsWithoutPrice$Params, context?: HttpContext): Observable<Array<Item>> {
    return this.productsWithoutPrice$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Item>>): Array<Item> => r.body)
    );
  }

  /** Path part for operation `cartsControllerDeleteErrorProductscartId()` */
  static readonly CartsControllerDeleteErrorProductscartIdPath = '/carts/{cartId}/deleteerrorproducts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteErrorProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErrorProducts$Response(params: DeleteErrorProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteErrorProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteErrorProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteErrorProducts(params: DeleteErrorProducts$Params, context?: HttpContext): Observable<void> {
    return this.deleteErrorProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetSuggestedKitsForActiveCartExcludingExistingInCart()` */
  static readonly CartsControllerGetSuggestedKitsForActiveCartExcludingExistingInCartPath = '/carts/active/kitsuggestionexcludexisting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedKitsForActiveCartExcludingExistingInCart$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitsForActiveCartExcludingExistingInCart$Plain$Response(params?: GetSuggestedKitsForActiveCartExcludingExistingInCart$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getSuggestedKitsForActiveCartExcludingExistingInCart$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSuggestedKitsForActiveCartExcludingExistingInCart$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitsForActiveCartExcludingExistingInCart$Plain(params?: GetSuggestedKitsForActiveCartExcludingExistingInCart$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getSuggestedKitsForActiveCartExcludingExistingInCart$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedKitsForActiveCartExcludingExistingInCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitsForActiveCartExcludingExistingInCart$Response(params?: GetSuggestedKitsForActiveCartExcludingExistingInCart$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getSuggestedKitsForActiveCartExcludingExistingInCart(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSuggestedKitsForActiveCartExcludingExistingInCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedKitsForActiveCartExcludingExistingInCart(params?: GetSuggestedKitsForActiveCartExcludingExistingInCart$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getSuggestedKitsForActiveCartExcludingExistingInCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetPromotionProductForActiveCartExceedingQuantity()` */
  static readonly CartsControllerGetPromotionProductForActiveCartExceedingQuantityPath = '/carts/active/promotionproducts-with-exceeding-quantity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotionProductForActiveCartExceedingQuantity$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionProductForActiveCartExceedingQuantity$Plain$Response(params?: GetPromotionProductForActiveCartExceedingQuantity$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getPromotionProductForActiveCartExceedingQuantity$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotionProductForActiveCartExceedingQuantity$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionProductForActiveCartExceedingQuantity$Plain(params?: GetPromotionProductForActiveCartExceedingQuantity$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getPromotionProductForActiveCartExceedingQuantity$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPromotionProductForActiveCartExceedingQuantity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionProductForActiveCartExceedingQuantity$Response(params?: GetPromotionProductForActiveCartExceedingQuantity$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getPromotionProductForActiveCartExceedingQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPromotionProductForActiveCartExceedingQuantity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPromotionProductForActiveCartExceedingQuantity(params?: GetPromotionProductForActiveCartExceedingQuantity$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getPromotionProductForActiveCartExceedingQuantity$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `cartsControllerGetTotalCartWeightcartId()` */
  static readonly CartsControllerGetTotalCartWeightcartIdPath = '/carts/{cartId}/weight';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTotalCartWeight$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCartWeight$Plain$Response(params: GetTotalCartWeight$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getTotalCartWeight$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTotalCartWeight$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCartWeight$Plain(params: GetTotalCartWeight$Plain$Params, context?: HttpContext): Observable<number> {
    return this.getTotalCartWeight$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTotalCartWeight()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCartWeight$Response(params: GetTotalCartWeight$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getTotalCartWeight(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTotalCartWeight$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTotalCartWeight(params: GetTotalCartWeight$Params, context?: HttpContext): Observable<number> {
    return this.getTotalCartWeight$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
