import { MibpHttpApi } from "./mibp-http-api.service";
import { SignalR_ApiResponseGeneric } from 'root/services';
import { map } from "rxjs/operators";

export class OperationsHttpService {
  constructor(private httpApi: MibpHttpApi) {}

  public async getQueuesAndTopics(): Promise<QueryCountResult> {
    return this.httpApi
      .get<SignalR_ApiResponseGeneric<QueryCountResult>>('operations/queues')
      .pipe(map(i => i.data)).toPromise();
  }

}

export interface QueryCountResult {
  topics: QueryCountTopic[];
}

export interface QueryCountTopic {
  name: string;
  source: string;
  subscriptions: QueryCountSubscription[];
}

export interface QueryCountSubscription {
  activeMessageCount: number;
  deadletterCount: number;
}
