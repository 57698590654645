<div class="header">
  <div class="title">
    <h3 class="my-header"><mibp-resource-string key="PartsManual_Illustration_Header"></mibp-resource-string></h3>
  </div>
  <div class="illustration-nav" *ngIf="illustrations?.length > 0">
    <div class="my-special-button-toolbar" [class.disabled]="!showImage">
      <div class="items">
        <button (click)="zoomIn()" [mibpResourceString.title]="'Global_Action_ZoomIn'" type="button" [disabled]="!showImage" class="my-special-button">
          <span translate="no" class="material-icon material-icon--large">zoom_in</span>
        </button>
        <button (click)="zoomOut()" [mibpResourceString.title]="'Global_Action_ZoomOut'" type="button" [disabled]="!showImage" class="my-special-button">
          <span translate="no" class="material-icon material-icon--large">zoom_out</span>
        </button>
        <button (click)="prevImage()" type="button" [disabled]="!showImage" class="my-special-button">
          <span translate="no" class="material-icon material-icon--large">chevron_left</span>
        </button>
        <div class="my-special-label" [class.disabled]="!showImage">
          {{currentIllustrationIndex + 1 }} of {{ illustrations?.length || 1 }}
        </div>
        <button (click)="nextImage()"  type="button" [disabled]="!showImage" class="my-special-button">
          <span translate="no" class="material-icon material-icon--large">chevron_right</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="image-container light-scroll" #imageContainer [style.overflow]="zoomValue ? 'auto' : null">

  <div class="image-notice" *ngIf="imageIsMissing">
    <span  translate="no" class="material-icon material-icon--xxlarge">image</span>
    <mibp-resource-string key="PartsManual_MissingIllustration"></mibp-resource-string>
  </div>
  <div class="image-notice" *ngIf="isLoadingImage" >
    <span class="loading-illustration"><mibp-resource-string key="PartsManual_LoadingIllustration"></mibp-resource-string></span>
  </div>


  <canvas #canvasHotpoints></canvas>
</div>


