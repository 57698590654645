import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AuthService, GlobalConfigService } from 'root/services';
import { DialogButton } from '..';
import { ButtonColors } from '../button/button.enum';
import { DialogComponent } from '../dialog/dialog.component';
import { differenceInSeconds } from 'date-fns';
import { Subscription } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'mibp-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit, OnDestroy {
  idleCountDown: number;
  dialogButtons: DialogButton[] = [
    {resourceKey: 'Global_Logout', id: 'sign_out', color: ButtonColors.Secondary},
    {resourceKey: 'Global_StayLoggedIn', id: 'stay', color: ButtonColors.Primary },
  ];
  @ViewChild('dialog') dialog: DialogComponent;

  countdownTimer?: number;
  countdownStart?: Date;

  onIdleStartSubscription?: Subscription;
  authSubscription?: Subscription;

  popupShowAfterSeconds: number;
  popupLogoutAfterSeconds: number;

  constructor(private idle: Idle, public authService: AuthService, globalConfig: GlobalConfigService,private connectionService: ConnectionService) {
    this.popupShowAfterSeconds = globalConfig.idleShowPopupAfterSeconds;
    this.popupLogoutAfterSeconds = globalConfig.idlePopupLogoutAfterSeconds;
  }

  ngOnInit(): void {
    this.connectionService.monitor().subscribe(isConnected => {
      if (!isConnected) {
        this.idle.stop();
      }
    });
    this.idle.setIdle(this.popupShowAfterSeconds);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.onIdleStartSubscription = this.idle.onIdleStart.subscribe(() => {
      // User is Idle. we turn of ng-idle and handle the countdown ourselves
      this.idle.stop();
      this.countdownTimer = window.setInterval(() => this.countdownTick(), 450);
      this.countdownStart = new Date();
      this.idleCountDown = this.popupLogoutAfterSeconds;
      this.dialog.open();
    });

    this.authSubscription = this.authService.isLoggedInSubject.subscribe(isLoggedIn=> {
      if (isLoggedIn) {
        this.idle.watch();
      } else {
        this.idle.stop();
      }
    });
  }

  /**
   * Is invoked while dialog is open in order to show the countdown
   */
  countdownTick(): void {
    const diff = differenceInSeconds(new Date(), this.countdownStart);
    const timeRemaining = this.popupLogoutAfterSeconds - diff;

    if (this.idleCountDown <= 0) {
      // Time is up. Log the user out!
      clearInterval(this.countdownTimer);
      this.logout(true);
    } else {
      this.idleCountDown = timeRemaining;
    }
  }

  dialogClick(e: DialogButton): void {
    if (e.id == 'stay') {
      this.stay();
    } else {
      this.logout();
    }
  }

  logout(automatic = false): void {
    this.dialog.close();
    this.authService.signout(null, automatic ? 'idle' : null);
  }

  stay(): void {
    clearInterval(this.countdownTimer);
    this.dialog.close();
    this.reset();
  }

  reset(): void {
    this.idle.watch();
  }

  ngOnDestroy(): void {
    this.authService?.isLoggedInSubject.unsubscribe();
    this.authSubscription?.unsubscribe();
  }

}
