/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllProductItemClasses } from '../fn/products/get-all-product-item-classes';
import { GetAllProductItemClasses$Params } from '../fn/products/get-all-product-item-classes';
import { getAllProductItemClasses$Plain } from '../fn/products/get-all-product-item-classes-plain';
import { GetAllProductItemClasses$Plain$Params } from '../fn/products/get-all-product-item-classes-plain';
import { getAllProductItemMajorGroups } from '../fn/products/get-all-product-item-major-groups';
import { GetAllProductItemMajorGroups$Params } from '../fn/products/get-all-product-item-major-groups';
import { getAllProductItemMajorGroups$Plain } from '../fn/products/get-all-product-item-major-groups-plain';
import { GetAllProductItemMajorGroups$Plain$Params } from '../fn/products/get-all-product-item-major-groups-plain';
import { getAllProductItemMinorGroups } from '../fn/products/get-all-product-item-minor-groups';
import { GetAllProductItemMinorGroups$Params } from '../fn/products/get-all-product-item-minor-groups';
import { getAllProductItemMinorGroups$Plain } from '../fn/products/get-all-product-item-minor-groups-plain';
import { GetAllProductItemMinorGroups$Plain$Params } from '../fn/products/get-all-product-item-minor-groups-plain';
import { getAllProductItemTypes } from '../fn/products/get-all-product-item-types';
import { GetAllProductItemTypes$Params } from '../fn/products/get-all-product-item-types';
import { getAllProductItemTypes$Plain } from '../fn/products/get-all-product-item-types-plain';
import { GetAllProductItemTypes$Plain$Params } from '../fn/products/get-all-product-item-types-plain';
import { getIdByCodeAndCompany } from '../fn/products/get-id-by-code-and-company';
import { GetIdByCodeAndCompany$Params } from '../fn/products/get-id-by-code-and-company';
import { getIdByCodeAndCompany$Plain } from '../fn/products/get-id-by-code-and-company-plain';
import { GetIdByCodeAndCompany$Plain$Params } from '../fn/products/get-id-by-code-and-company-plain';
import { getNewProductEntityById } from '../fn/products/get-new-product-entity-by-id';
import { GetNewProductEntityById$Params } from '../fn/products/get-new-product-entity-by-id';
import { getNewProductEntityById$Plain } from '../fn/products/get-new-product-entity-by-id-plain';
import { GetNewProductEntityById$Plain$Params } from '../fn/products/get-new-product-entity-by-id-plain';
import { getProductByCodeAndCompany } from '../fn/products/get-product-by-code-and-company';
import { GetProductByCodeAndCompany$Params } from '../fn/products/get-product-by-code-and-company';
import { getProductByCodeAndCompany$Plain } from '../fn/products/get-product-by-code-and-company-plain';
import { GetProductByCodeAndCompany$Plain$Params } from '../fn/products/get-product-by-code-and-company-plain';
import { getProductDetail } from '../fn/products/get-product-detail';
import { GetProductDetail$Params } from '../fn/products/get-product-detail';
import { getProductDetail$Plain } from '../fn/products/get-product-detail-plain';
import { GetProductDetail$Plain$Params } from '../fn/products/get-product-detail-plain';
import { getProductLocalThenGlobal } from '../fn/products/get-product-local-then-global';
import { GetProductLocalThenGlobal$Params } from '../fn/products/get-product-local-then-global';
import { getProductLocalThenGlobal$Plain } from '../fn/products/get-product-local-then-global-plain';
import { GetProductLocalThenGlobal$Plain$Params } from '../fn/products/get-product-local-then-global-plain';
import { getSimpleProductById } from '../fn/products/get-simple-product-by-id';
import { GetSimpleProductById$Params } from '../fn/products/get-simple-product-by-id';
import { getSimpleProductById$Plain } from '../fn/products/get-simple-product-by-id-plain';
import { GetSimpleProductById$Plain$Params } from '../fn/products/get-simple-product-by-id-plain';
import { getSimpleProductListByIds } from '../fn/products/get-simple-product-list-by-ids';
import { GetSimpleProductListByIds$Params } from '../fn/products/get-simple-product-list-by-ids';
import { getSimpleProductListByIds$Plain } from '../fn/products/get-simple-product-list-by-ids-plain';
import { GetSimpleProductListByIds$Plain$Params } from '../fn/products/get-simple-product-list-by-ids-plain';
import { getSimpleProductListByProductCodes } from '../fn/products/get-simple-product-list-by-product-codes';
import { GetSimpleProductListByProductCodes$Params } from '../fn/products/get-simple-product-list-by-product-codes';
import { getSimpleProductListByProductCodes$Plain } from '../fn/products/get-simple-product-list-by-product-codes-plain';
import { GetSimpleProductListByProductCodes$Plain$Params } from '../fn/products/get-simple-product-list-by-product-codes-plain';
import { getUnitsThatSupportsDecimals } from '../fn/products/get-units-that-supports-decimals';
import { GetUnitsThatSupportsDecimals$Params } from '../fn/products/get-units-that-supports-decimals';
import { getUnitsThatSupportsDecimals$Plain } from '../fn/products/get-units-that-supports-decimals-plain';
import { GetUnitsThatSupportsDecimals$Plain$Params } from '../fn/products/get-units-that-supports-decimals-plain';
import { NewProduct } from '../models/new-product';
import { NewProductSearchItemSearchResponse } from '../models/new-product-search-item-search-response';
import { ProductDetailViewModel } from '../models/product-detail-view-model';
import { ProductItemClass } from '../models/product-item-class';
import { ProductItemGroup } from '../models/product-item-group';
import { ProductItemType } from '../models/product-item-type';
import { searchProducts } from '../fn/products/search-products';
import { SearchProducts$Params } from '../fn/products/search-products';
import { searchProducts$Plain } from '../fn/products/search-products-plain';
import { SearchProducts$Plain$Params } from '../fn/products/search-products-plain';
import { searchProductsBasedOnCompanies } from '../fn/products/search-products-based-on-companies';
import { SearchProductsBasedOnCompanies$Params } from '../fn/products/search-products-based-on-companies';
import { searchProductsBasedOnCompanies$Plain } from '../fn/products/search-products-based-on-companies-plain';
import { SearchProductsBasedOnCompanies$Plain$Params } from '../fn/products/search-products-based-on-companies-plain';
import { SimpleProductViewModel } from '../models/simple-product-view-model';
import { StringSearchResponse } from '../models/string-search-response';
import { UnitsOfMeasure } from '../models/units-of-measure';

@Injectable({ providedIn: 'root' })
export class ProductsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `productsControllerSearchProductsrequest()` */
  static readonly ProductsControllerSearchProductsrequestPath = '/products/search';

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchProducts$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProducts$Plain$Response(params?: SearchProducts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProductSearchItemSearchResponse>> {
    return searchProducts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchProducts$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProducts$Plain(params?: SearchProducts$Plain$Params, context?: HttpContext): Observable<NewProductSearchItemSearchResponse> {
    return this.searchProducts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProductSearchItemSearchResponse>): NewProductSearchItemSearchResponse => r.body)
    );
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchProducts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProducts$Response(params?: SearchProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProductSearchItemSearchResponse>> {
    return searchProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchProducts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProducts(params?: SearchProducts$Params, context?: HttpContext): Observable<NewProductSearchItemSearchResponse> {
    return this.searchProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProductSearchItemSearchResponse>): NewProductSearchItemSearchResponse => r.body)
    );
  }

  /** Path part for operation `productsControllerSearchProductsBasedOnCompaniesrequest()` */
  static readonly ProductsControllerSearchProductsBasedOnCompaniesrequestPath = '/products/search/companies';

  /**
   * Search for products based on company codes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchProductsBasedOnCompanies$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProductsBasedOnCompanies$Plain$Response(params?: SearchProductsBasedOnCompanies$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StringSearchResponse>> {
    return searchProductsBasedOnCompanies$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products based on company codes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchProductsBasedOnCompanies$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProductsBasedOnCompanies$Plain(params?: SearchProductsBasedOnCompanies$Plain$Params, context?: HttpContext): Observable<StringSearchResponse> {
    return this.searchProductsBasedOnCompanies$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringSearchResponse>): StringSearchResponse => r.body)
    );
  }

  /**
   * Search for products based on company codes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchProductsBasedOnCompanies()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProductsBasedOnCompanies$Response(params?: SearchProductsBasedOnCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<StringSearchResponse>> {
    return searchProductsBasedOnCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products based on company codes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchProductsBasedOnCompanies$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchProductsBasedOnCompanies(params?: SearchProductsBasedOnCompanies$Params, context?: HttpContext): Observable<StringSearchResponse> {
    return this.searchProductsBasedOnCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringSearchResponse>): StringSearchResponse => r.body)
    );
  }

  /** Path part for operation `productsControllerGetSimpleProductListByIdsproductIds()` */
  static readonly ProductsControllerGetSimpleProductListByIdsproductIdsPath = '/products/search/ids';

  /**
   * Get simple  product data based on a list of ids.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleProductListByIds$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSimpleProductListByIds$Plain$Response(params?: GetSimpleProductListByIds$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SimpleProductViewModel>>> {
    return getSimpleProductListByIds$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get simple  product data based on a list of ids.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimpleProductListByIds$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSimpleProductListByIds$Plain(params?: GetSimpleProductListByIds$Plain$Params, context?: HttpContext): Observable<Array<SimpleProductViewModel>> {
    return this.getSimpleProductListByIds$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SimpleProductViewModel>>): Array<SimpleProductViewModel> => r.body)
    );
  }

  /**
   * Get simple  product data based on a list of ids.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleProductListByIds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSimpleProductListByIds$Response(params?: GetSimpleProductListByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SimpleProductViewModel>>> {
    return getSimpleProductListByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Get simple  product data based on a list of ids.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimpleProductListByIds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSimpleProductListByIds(params?: GetSimpleProductListByIds$Params, context?: HttpContext): Observable<Array<SimpleProductViewModel>> {
    return this.getSimpleProductListByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SimpleProductViewModel>>): Array<SimpleProductViewModel> => r.body)
    );
  }

  /** Path part for operation `productsControllerGetSimpleProductListByProductCodesproductCodes()` */
  static readonly ProductsControllerGetSimpleProductListByProductCodesproductCodesPath = '/products/search/codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleProductListByProductCodes$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSimpleProductListByProductCodes$Plain$Response(params?: GetSimpleProductListByProductCodes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SimpleProductViewModel>>> {
    return getSimpleProductListByProductCodes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimpleProductListByProductCodes$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSimpleProductListByProductCodes$Plain(params?: GetSimpleProductListByProductCodes$Plain$Params, context?: HttpContext): Observable<Array<SimpleProductViewModel>> {
    return this.getSimpleProductListByProductCodes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SimpleProductViewModel>>): Array<SimpleProductViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleProductListByProductCodes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSimpleProductListByProductCodes$Response(params?: GetSimpleProductListByProductCodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SimpleProductViewModel>>> {
    return getSimpleProductListByProductCodes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimpleProductListByProductCodes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSimpleProductListByProductCodes(params?: GetSimpleProductListByProductCodes$Params, context?: HttpContext): Observable<Array<SimpleProductViewModel>> {
    return this.getSimpleProductListByProductCodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SimpleProductViewModel>>): Array<SimpleProductViewModel> => r.body)
    );
  }

  /** Path part for operation `productsControllerGetNewProductEntityByIdid()` */
  static readonly ProductsControllerGetNewProductEntityByIdidPath = '/products/{id}/entity';

  /**
   * Get product based on Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewProductEntityById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getNewProductEntityById$Plain$Response(params: GetNewProductEntityById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProduct>> {
    return getNewProductEntityById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get product based on Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewProductEntityById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getNewProductEntityById$Plain(params: GetNewProductEntityById$Plain$Params, context?: HttpContext): Observable<NewProduct> {
    return this.getNewProductEntityById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProduct>): NewProduct => r.body)
    );
  }

  /**
   * Get product based on Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewProductEntityById()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getNewProductEntityById$Response(params: GetNewProductEntityById$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProduct>> {
    return getNewProductEntityById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get product based on Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewProductEntityById$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getNewProductEntityById(params: GetNewProductEntityById$Params, context?: HttpContext): Observable<NewProduct> {
    return this.getNewProductEntityById$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProduct>): NewProduct => r.body)
    );
  }

  /** Path part for operation `productsControllerGetSimpleProductByIdid()` */
  static readonly ProductsControllerGetSimpleProductByIdidPath = '/products/{id}/simple';

  /**
   * Get product based on Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleProductById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProductById$Plain$Response(params: GetSimpleProductById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SimpleProductViewModel>> {
    return getSimpleProductById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get product based on Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimpleProductById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProductById$Plain(params: GetSimpleProductById$Plain$Params, context?: HttpContext): Observable<SimpleProductViewModel> {
    return this.getSimpleProductById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SimpleProductViewModel>): SimpleProductViewModel => r.body)
    );
  }

  /**
   * Get product based on Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimpleProductById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProductById$Response(params: GetSimpleProductById$Params, context?: HttpContext): Observable<StrictHttpResponse<SimpleProductViewModel>> {
    return getSimpleProductById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get product based on Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSimpleProductById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimpleProductById(params: GetSimpleProductById$Params, context?: HttpContext): Observable<SimpleProductViewModel> {
    return this.getSimpleProductById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SimpleProductViewModel>): SimpleProductViewModel => r.body)
    );
  }

  /** Path part for operation `productsControllerGetProductByCodeAndCompanycodeCompanyCode()` */
  static readonly ProductsControllerGetProductByCodeAndCompanycodeCompanyCodePath = '/products/by-code/{code}';

  /**
   * Get product based on Code and Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductByCodeAndCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductByCodeAndCompany$Plain$Response(params: GetProductByCodeAndCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProduct>> {
    return getProductByCodeAndCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get product based on Code and Company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductByCodeAndCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductByCodeAndCompany$Plain(params: GetProductByCodeAndCompany$Plain$Params, context?: HttpContext): Observable<NewProduct> {
    return this.getProductByCodeAndCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProduct>): NewProduct => r.body)
    );
  }

  /**
   * Get product based on Code and Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductByCodeAndCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductByCodeAndCompany$Response(params: GetProductByCodeAndCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProduct>> {
    return getProductByCodeAndCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Get product based on Code and Company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductByCodeAndCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductByCodeAndCompany(params: GetProductByCodeAndCompany$Params, context?: HttpContext): Observable<NewProduct> {
    return this.getProductByCodeAndCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProduct>): NewProduct => r.body)
    );
  }

  /** Path part for operation `productsControllerGetIdByCodeAndCompanyproductCodeCompanyCode()` */
  static readonly ProductsControllerGetIdByCodeAndCompanyproductCodeCompanyCodePath = '/products/by-code/{productCode}/id';

  /**
   * Get product id based on Code and Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIdByCodeAndCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdByCodeAndCompany$Plain$Response(params: GetIdByCodeAndCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getIdByCodeAndCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get product id based on Code and Company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIdByCodeAndCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdByCodeAndCompany$Plain(params: GetIdByCodeAndCompany$Plain$Params, context?: HttpContext): Observable<string> {
    return this.getIdByCodeAndCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Get product id based on Code and Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIdByCodeAndCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdByCodeAndCompany$Response(params: GetIdByCodeAndCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getIdByCodeAndCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Get product id based on Code and Company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIdByCodeAndCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdByCodeAndCompany(params: GetIdByCodeAndCompany$Params, context?: HttpContext): Observable<string> {
    return this.getIdByCodeAndCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `productsControllerGetProductDetailproductIdLanguageCode()` */
  static readonly ProductsControllerGetProductDetailproductIdLanguageCodePath = '/products/{productId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductDetail$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductDetail$Plain$Response(params: GetProductDetail$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDetailViewModel>> {
    return getProductDetail$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductDetail$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductDetail$Plain(params: GetProductDetail$Plain$Params, context?: HttpContext): Observable<ProductDetailViewModel> {
    return this.getProductDetail$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDetailViewModel>): ProductDetailViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductDetail$Response(params: GetProductDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDetailViewModel>> {
    return getProductDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductDetail(params: GetProductDetail$Params, context?: HttpContext): Observable<ProductDetailViewModel> {
    return this.getProductDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDetailViewModel>): ProductDetailViewModel => r.body)
    );
  }

  /** Path part for operation `productsControllerGetProductLocalThenGlobalproductCode()` */
  static readonly ProductsControllerGetProductLocalThenGlobalproductCodePath = '/products/by-code/{productCode}/local-then-global';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductLocalThenGlobal$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductLocalThenGlobal$Plain$Response(params: GetProductLocalThenGlobal$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDetailViewModel>> {
    return getProductLocalThenGlobal$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductLocalThenGlobal$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductLocalThenGlobal$Plain(params: GetProductLocalThenGlobal$Plain$Params, context?: HttpContext): Observable<ProductDetailViewModel> {
    return this.getProductLocalThenGlobal$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDetailViewModel>): ProductDetailViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductLocalThenGlobal()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductLocalThenGlobal$Response(params: GetProductLocalThenGlobal$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDetailViewModel>> {
    return getProductLocalThenGlobal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductLocalThenGlobal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductLocalThenGlobal(params: GetProductLocalThenGlobal$Params, context?: HttpContext): Observable<ProductDetailViewModel> {
    return this.getProductLocalThenGlobal$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDetailViewModel>): ProductDetailViewModel => r.body)
    );
  }

  /** Path part for operation `productsControllerGetAllProductItemTypesrequest()` */
  static readonly ProductsControllerGetAllProductItemTypesrequestPath = '/products/get/itemtypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProductItemTypes$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemTypes$Plain$Response(params?: GetAllProductItemTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductItemType>>> {
    return getAllProductItemTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProductItemTypes$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemTypes$Plain(params?: GetAllProductItemTypes$Plain$Params, context?: HttpContext): Observable<Array<ProductItemType>> {
    return this.getAllProductItemTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductItemType>>): Array<ProductItemType> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProductItemTypes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemTypes$Response(params?: GetAllProductItemTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductItemType>>> {
    return getAllProductItemTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProductItemTypes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemTypes(params?: GetAllProductItemTypes$Params, context?: HttpContext): Observable<Array<ProductItemType>> {
    return this.getAllProductItemTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductItemType>>): Array<ProductItemType> => r.body)
    );
  }

  /** Path part for operation `productsControllerGetAllProductItemClassesrequest()` */
  static readonly ProductsControllerGetAllProductItemClassesrequestPath = '/products/get/itemclasses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProductItemClasses$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemClasses$Plain$Response(params?: GetAllProductItemClasses$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductItemClass>>> {
    return getAllProductItemClasses$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProductItemClasses$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemClasses$Plain(params?: GetAllProductItemClasses$Plain$Params, context?: HttpContext): Observable<Array<ProductItemClass>> {
    return this.getAllProductItemClasses$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductItemClass>>): Array<ProductItemClass> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProductItemClasses()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemClasses$Response(params?: GetAllProductItemClasses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductItemClass>>> {
    return getAllProductItemClasses(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProductItemClasses$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemClasses(params?: GetAllProductItemClasses$Params, context?: HttpContext): Observable<Array<ProductItemClass>> {
    return this.getAllProductItemClasses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductItemClass>>): Array<ProductItemClass> => r.body)
    );
  }

  /** Path part for operation `productsControllerGetAllProductItemMajorGroupsrequest()` */
  static readonly ProductsControllerGetAllProductItemMajorGroupsrequestPath = '/products/get/majorgroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProductItemMajorGroups$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemMajorGroups$Plain$Response(params?: GetAllProductItemMajorGroups$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductItemGroup>>> {
    return getAllProductItemMajorGroups$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProductItemMajorGroups$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemMajorGroups$Plain(params?: GetAllProductItemMajorGroups$Plain$Params, context?: HttpContext): Observable<Array<ProductItemGroup>> {
    return this.getAllProductItemMajorGroups$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductItemGroup>>): Array<ProductItemGroup> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProductItemMajorGroups()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemMajorGroups$Response(params?: GetAllProductItemMajorGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductItemGroup>>> {
    return getAllProductItemMajorGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProductItemMajorGroups$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemMajorGroups(params?: GetAllProductItemMajorGroups$Params, context?: HttpContext): Observable<Array<ProductItemGroup>> {
    return this.getAllProductItemMajorGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductItemGroup>>): Array<ProductItemGroup> => r.body)
    );
  }

  /** Path part for operation `productsControllerGetAllProductItemMinorGroupsrequest()` */
  static readonly ProductsControllerGetAllProductItemMinorGroupsrequestPath = '/products/get/minorgroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProductItemMinorGroups$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemMinorGroups$Plain$Response(params?: GetAllProductItemMinorGroups$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductItemGroup>>> {
    return getAllProductItemMinorGroups$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProductItemMinorGroups$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemMinorGroups$Plain(params?: GetAllProductItemMinorGroups$Plain$Params, context?: HttpContext): Observable<Array<ProductItemGroup>> {
    return this.getAllProductItemMinorGroups$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductItemGroup>>): Array<ProductItemGroup> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProductItemMinorGroups()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemMinorGroups$Response(params?: GetAllProductItemMinorGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductItemGroup>>> {
    return getAllProductItemMinorGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProductItemMinorGroups$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllProductItemMinorGroups(params?: GetAllProductItemMinorGroups$Params, context?: HttpContext): Observable<Array<ProductItemGroup>> {
    return this.getAllProductItemMinorGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductItemGroup>>): Array<ProductItemGroup> => r.body)
    );
  }

  /** Path part for operation `productsControllerGetUnitsThatSupportsDecimals()` */
  static readonly ProductsControllerGetUnitsThatSupportsDecimalsPath = '/products/units-that-supports-decimals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnitsThatSupportsDecimals$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnitsThatSupportsDecimals$Plain$Response(params?: GetUnitsThatSupportsDecimals$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UnitsOfMeasure>>> {
    return getUnitsThatSupportsDecimals$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnitsThatSupportsDecimals$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnitsThatSupportsDecimals$Plain(params?: GetUnitsThatSupportsDecimals$Plain$Params, context?: HttpContext): Observable<Array<UnitsOfMeasure>> {
    return this.getUnitsThatSupportsDecimals$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UnitsOfMeasure>>): Array<UnitsOfMeasure> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnitsThatSupportsDecimals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnitsThatSupportsDecimals$Response(params?: GetUnitsThatSupportsDecimals$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UnitsOfMeasure>>> {
    return getUnitsThatSupportsDecimals(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnitsThatSupportsDecimals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnitsThatSupportsDecimals(params?: GetUnitsThatSupportsDecimals$Params, context?: HttpContext): Observable<Array<UnitsOfMeasure>> {
    return this.getUnitsThatSupportsDecimals$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UnitsOfMeasure>>): Array<UnitsOfMeasure> => r.body)
    );
  }

}
