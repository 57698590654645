import { ValidatorFn, AbstractControl } from "@angular/forms";
import { UserFileReference } from "root/mibp-openapi-gen/models";

export function UserFileUploadValidator(): ValidatorFn {

  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value as UserFileReference[];

    // Return validation error if any uploaded file has an error
    if (value.filter(f => f.error).length > 0) {
      return {
        uploadErrors: true
      };
    }

    return;
  };
}
