<mibp-loader *ngIf="isLoadingScopes" ></mibp-loader>

 <div class="level-left">
  <div class="level-item">
    <mibp-button [disabled]="disabled" (click)="dlg.open()" resourceStringKey="Settings_InternalUser_Browse"></mibp-button>
  </div>
</div>


<mibp-dialog #dlg titleResourceStringKey="Component_OrganizationBrowser_Title" (opened)="dialogOpen()" (closed)="dialogClosed()"  [buttons]="dialogButtons" (buttonClick)="dialogClick($event)" fullheight="true">
  <div style="min-height: 250px">
    <mibp-loader *ngIf="isLoading"></mibp-loader>
    <div class="columns" [style.display]="isLoading ? 'none' : null" >
      <div class="column column-arrow column-arrow-1">
        <div class="field">
          <label><mibp-resource-string key="Global_Company"></mibp-resource-string></label>
          <mibp-dropdown
          #companyDropdown
          (filter)="filterCompanies($event)"
          [data]="companies$"
          placeholder="Component_ResponsibilityPicker_Any"
          [multiselect.tagPlaceholder]="true"
          [multiselect.hideOnClick]="true"
          [multiselect]="true"
          [take]="dropdownPageSize"
          (valueChange)="onDropdownValueChanged('company', $event)"
          ></mibp-dropdown>
        </div>

        <div class="field">
          <label><mibp-resource-string key="Global_Customer"></mibp-resource-string></label>
          <mibp-dropdown
          #customerDropdown
          (filter)="filterCustomers($event)"
          [data]="customers$"
          [multiselect.hideOnClick]="true"
          [multiselect.tagPlaceholder]="true"
          [disabled]="true"
          placeholder="Component_ResponsibilityPicker_Any"
          [multiselect]="true"
          [take]="dropdownPageSize"
          (valueChange)="onDropdownValueChanged('customer', $event)"></mibp-dropdown>
          <p *ngIf="customerError == 'userscope'" class="inline-error-text"><mibp-resource-string key="Component_ResponsibilityPicker_UserScopeLoadError"></mibp-resource-string></p>
        </div>

        <div class="field" *ngIf="!hideDeliverySequence">
          <label><mibp-resource-string key="Global_DeliverySequence"></mibp-resource-string></label>
          <mibp-dropdown
          #deliverysequenceDropdown
          [multiselect.hideOnClick]="true"
          [multiselect.tagPlaceholder]="true"
          [data]="deliverySequences$"
          (filter)="filterDeliverySequences($event)"
          placeholder="Component_ResponsibilityPicker_Any"
          [multiselect]="true"
          [take]="dropdownPageSize"
          (valueChange)="onDropdownValueChanged('deliverysequence', $event)"></mibp-dropdown>
          <p *ngIf="deliverySequenceError == 'userscope'" class="inline-error-text"><mibp-resource-string key="Component_ResponsibilityPicker_UserScopeLoadError"></mibp-resource-string></p>
        </div>
      </div>

      <div class="column" *ngIf="!hideProductColumn">
          <div class="field arrow-left">
              <label><mibp-resource-string key="Global_ProductArea"></mibp-resource-string></label>
              <mibp-dropdown
              #productAreaDropdown
              [data]="productAreas$"
              (filter)="filterProductAreas($event)"
              [multiselect.hideOnClick]="true"
              [multiselect.tagPlaceholder]="true"
              placeholder="Component_ResponsibilityPicker_Any"
              [multiselect]="true"
              [take]="dropdownPageSize"
              (valueChange)="onDropdownValueChanged('productarea', $event)"
              (invalidValuesChanged)="invalidValuesChanged('productarea', $event)"
              ></mibp-dropdown>
            </div>

        <div class="field">
            <label><mibp-resource-string key="Global_ProductGroup"></mibp-resource-string></label>
            <mibp-dropdown
            #productgroupDropdown
            [data]="productGroups$"
            (filter)="filterProductGroups($event)"
            [multiselect.hideOnClick]="true"
            [multiselect.tagPlaceholder]="true"
            [disabled]="true"
            placeholder="Component_ResponsibilityPicker_Any"
            [multiselect]="true"
            [take]="dropdownPageSize"
            (valueChange)="onDropdownValueChanged('productgroup', $event)"
            (invalidValuesChanged)="invalidValuesChanged('productgroup', $event)"></mibp-dropdown>
          </div>

          <div class="field">
            <label><mibp-resource-string key="GlobalModelSubGroup"></mibp-resource-string></label>
            <mibp-dropdown
            #productsubgroupDropdown
            [data]="productSubGroups$"
            (filter)="filterProductSubGroups($event)"
            [multiselect.hideOnClick]="true"
            [multiselect.tagPlaceholder]="true"
            [disabled]="true"
            placeholder="Component_ResponsibilityPicker_Any"
            [multiselect]="true"
            [take]="dropdownPageSize"
            (valueChange)="onDropdownValueChanged('productsubgroup', $event)"
            (invalidValuesChanged)="invalidValuesChanged('productsubgroup', $event)"></mibp-dropdown>
          </div>
      </div>

      <div class="column">
        <div class="field">
          <label><mibp-resource-string key="Global_Model"></mibp-resource-string></label>
          <mibp-dropdown
          #modelDropdown
          [data]="productModels$"
          (filter)="filterProductModels($event)"
          [multiselect.hideOnClick]="true"
          [multiselect.tagPlaceholder]="true"
          placeholder="Component_ResponsibilityPicker_Any"
          [multiselect]="true"
          [take]="dropdownPageSize"
          (valueChange)="onDropdownValueChanged('model', $event)"
          (invalidValuesChanged)="invalidValuesChanged('model', $event)"></mibp-dropdown>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label><mibp-resource-string key="Global_MachineSerialNumber"></mibp-resource-string></label>
          <mibp-dropdown
          #serialDropdown
          [data]="serials$"
          (filter)="filterSerialNumbers($event)"
          [multiselect.hideOnClick]="true"
          [multiselect.tagPlaceholder]="true"
          placeholder="Component_ResponsibilityPicker_Any"
          (valueChange)="onDropdownValueChanged('serial', $event)"
          [multiselect]="true"
          [take]="dropdownPageSize"
          (invalidValuesChanged)="invalidValuesChanged('serial', $event)"></mibp-dropdown>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <mibp-organization-list-selector (orgSelected)="orgSelected($event)" [scopes]="searchForScopes"></mibp-organization-list-selector>
</mibp-dialog>
<mibp-loader *ngIf="showFullScreenLoader" type='fullscreen'></mibp-loader>
