/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { pageView } from '../fn/bi-dashboard/page-view';
import { PageView$Params } from '../fn/bi-dashboard/page-view';
import { searchTyping } from '../fn/bi-dashboard/search-typing';
import { SearchTyping$Params } from '../fn/bi-dashboard/search-typing';
import { userRegistrationStarted } from '../fn/bi-dashboard/user-registration-started';
import { UserRegistrationStarted$Params } from '../fn/bi-dashboard/user-registration-started';

@Injectable({ providedIn: 'root' })
export class BiDashboardApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `biDashboardControllerPageViewurlReferrer()` */
  static readonly BiDashboardControllerPageViewurlReferrerPath = '/bidashboard/pageview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageView()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageView$Response(params?: PageView$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return pageView(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageView(params?: PageView$Params, context?: HttpContext): Observable<void> {
    return this.pageView$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `biDashboardControllerSearchTypingsearchText()` */
  static readonly BiDashboardControllerSearchTypingsearchTextPath = '/bidashboard/searchtyping';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTyping()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTyping$Response(params?: SearchTyping$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return searchTyping(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchTyping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTyping(params?: SearchTyping$Params, context?: HttpContext): Observable<void> {
    return this.searchTyping$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `biDashboardControllerUserRegistrationStarteduserEmail()` */
  static readonly BiDashboardControllerUserRegistrationStarteduserEmailPath = '/bidashboard/userregistrationstarted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userRegistrationStarted()` instead.
   *
   * This method doesn't expect any request body.
   */
  userRegistrationStarted$Response(params?: UserRegistrationStarted$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userRegistrationStarted(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userRegistrationStarted$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userRegistrationStarted(params?: UserRegistrationStarted$Params, context?: HttpContext): Observable<void> {
    return this.userRegistrationStarted$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
