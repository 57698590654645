import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";

export interface ScriptModel {
    src: string,
    loaded: boolean
}

@Injectable()
export class ScriptService {
    private scripts: ScriptModel[] = [];

    public loadScript(src: string): Observable<void> {
        return new Observable<void>((observer: Observer<void>) => {
            let existingScript = this.scripts.find(s => s.src == src);

            if (existingScript && existingScript.loaded) {
                observer.next();
                observer.complete();
            }
            else {
                let script = <ScriptModel>{ src: src, loaded: false };
                this.scripts = [...this.scripts, script];

                let scriptElement = document.createElement("script");
                scriptElement.type = "text/javascript";
                scriptElement.src = script.src;

                scriptElement.onload = () => {
                    script.loaded = true;
                    observer.next();
                    observer.complete();
                };

                scriptElement.onerror = (error: any) => {
                    observer.error("Error loading script " + script.src);
                };

                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        });
    }
}