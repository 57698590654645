import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { DialogComponent } from 'root/components';
import { MibpGridColumn, MibpGridColumnVisibility } from 'root/mibp-openapi-gen/models';
import { MibpGridComponent } from '../../mibp-grid.component';

@Component({
  selector: 'mibp-grid-edit-columns',
  templateUrl: './mibp-grid-edit-columns.component.html',
  styleUrls: ['./mibp-grid-edit-columns.component.scss']
})
export class MibpGridEditColumnsComponent implements OnInit {
  @Output() closed = new EventEmitter();
  @ViewChild(DialogComponent, {static: true}) dialog: DialogComponent;
  @Input() gridComponent: MibpGridComponent;

  editColumnsForm: UntypedFormGroup;

  hasDialogBeenOpendBefore = false;
  editableColumns: MibpGridColumn[];
  editColumnsFormArray: UntypedFormArray;

  //==== SETTERS =====
  private isDialogOpen: boolean;

  @Input()
  get isOpen(): boolean {
    return this.isDialogOpen;
  }
  set isOpen(value: boolean) {
    this.isDialogOpen = value;
    if (this.isDialogOpen) {
      this.dialog.open();
    } else {
      this.dialog.close();
    }
  }

  private _allColumns: MibpGridColumn[];
  @Input()
  set allColumns(value: MibpGridColumn[]) {
    this._allColumns = value;
    this.editableColumns = this._allColumns?.filter(c => c.visibility != MibpGridColumnVisibility.AlwaysHidden);
  }

  constructor(private formBuilder: UntypedFormBuilder) { }


  ngOnInit() {
    this.editColumnsFormArray = new UntypedFormArray([], this.minSelectedCheckboxes(1));
    this.editColumnsForm = this.formBuilder.group({
      columns: this.editColumnsFormArray
    });
  }



  onDialogOpen() {
    if (!this.hasDialogBeenOpendBefore) {
      this.hasDialogBeenOpendBefore = true;
      //Load data when dialog has been opened
      if (this._allColumns) {
        this.addCheckboxes();
      } else {
        //If columns not have been loaded yet we need to wait for it.
        setTimeout(() => {
          this.addCheckboxes();
        }, 2000);
      }
    } else {
      //UPDATE CHECKBOXES WITH CURRENT VALUES
      this.UpdateCheckBoxValues();
    }
  }

  onDialogClosed() {
    this.closed.emit();
  }

  submitForm() {
    const selectedColumns: string[] = this.editColumnsForm.value.columns
      .map((checked, i) => checked ? this.editableColumns[i].propertyName : null)
      .filter(v => v !== null);

    const firstVisibleColumn = this._allColumns.find(c => c.propertyName === selectedColumns[0]);
    const orderBy = firstVisibleColumn.sortable ? firstVisibleColumn.id + ' asc' : null;

    this.gridComponent.updateSelectedColumns(selectedColumns, orderBy);
    this.onDialogClosed();
  }

  private addCheckboxes() {
    this.editableColumns.forEach((column) => this.editColumnsFormArray.push(new UntypedFormControl(column.visibility == MibpGridColumnVisibility.Visible)));
  }

  UpdateCheckBoxValues() {
    //resets the form and pupulated with the current selected coumns
    const checboxValues = this.editableColumns.map(c => c.visibility == MibpGridColumnVisibility.Visible);
    this.editColumnsForm.reset({columns: checboxValues});
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

}
