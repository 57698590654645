import { EmbeddedViewRef } from '@angular/core';
import {
  Component, OnInit, Input, ElementRef,
  Output, EventEmitter,
  AfterViewInit, ViewChild, TemplateRef, ViewContainerRef, Injector, ChangeDetectorRef, Renderer2, OnDestroy
} from '@angular/core';
import { ButtonColors, ButtonStyles } from '../button/button.enum';
import { DialogService } from 'root/services/dialog/dialog.service';
import { DialogButton } from '../dialog/dialog.types';

@Component({
  selector: 'mibp-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightBoxComponent implements AfterViewInit, OnDestroy {

  @ViewChild('dialogTemplate', {static: true}) dialogTemplate: TemplateRef<any>;

  @Input() id: string;
  @Output() closed = new EventEmitter();
  @Output() opened = new EventEmitter();
  @Output() buttonClick = new EventEmitter<DialogButton>();
  @Input() fullheight = false;
  @Input() disableEscButton = "false";
  @Input() rawTitle : string;
  embeddedView: EmbeddedViewRef<any>;
  dialogButtons: DialogButton[];
  /**
   *
   *
   * "auto" = content width if a reource string is used. "default" if otherwise
   * "wide" = an extra wide dialog, but still not wider than the page container
   * "default" = ""
   */
  @Input() size: 'auto' | 'default' | 'narrow' | 'wide' = 'default';
  @Input() hideCloseButton = false;
  @Input() rawText;
  @Input() minHeight: number;
  marginTop;
  resolveOpenPromise: (button: DialogButton) => void;
  listenFn: any;
  zIndex = 0;
  element: Element;

  private zIndexBase = 5003;
  public showButtonsClass: string;
  public active = false;

  @Input() showbuttons: boolean;
  /**
   * Will register this component instance with the Dialog Service
   * so this instance of the component is used for general prompts etc
   */
  @Input() registerForService: boolean;
  @Input() resourceStringKey: string;

  /**
   * @param injector Required to invoke createEmbeddedView on viewContainerRef
   * @param viewContainerRef Required to move dialog template to body element
   */
  constructor(
    el: ElementRef,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private dialogService: DialogService,
    private changeRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.element = el.nativeElement;
    this.showbuttons = true;
    // this.viewContainerRef.createEmbeddedView(this.dialogTemplate, { static: false} );
  }

  trackButton(ix: number, btn: DialogButton) {
    return btn.id;
  }

  keyPressed(event: KeyboardEvent) {
    if (this.active && event && event.key && event.key.indexOf('Esc') === 0) {
      if (this.disableEscButton === 'true') {
        return;
      }
      // Find out if this dialog is the top level one...
      const topLevelDialogZIndex = Array.from(document.querySelectorAll('body > .modal.is-active'))
        .map((elm: HTMLElement) => parseInt(elm.style.zIndex, 10) )
        .reduce((highest: number, current: number): number => {
          return current > highest ? current : highest;
        }, 0);

      if (topLevelDialogZIndex === this.zIndex) {
        this.close();
      }
    }
  }

  public get modalClassName() {
    return `modal is-active modal-size-${this.size}`;
  }

  /**
   * Move the 'dialogTemplate' element (see template) to the body
   * This is done by creating an EmbeddedView and moving its elements to the body element
   * Using the embeddedView, all angular bindings will remain and work as usual even if the
   * elements are moved somewhere else
   * https://angular.io/api/core/ViewContainerRef#createembeddedview
   */
  private moveTemplateToBody(): void {
    const bodyElement = document.querySelector('body');
    this.embeddedView = this.viewContainerRef.createEmbeddedView(this.dialogTemplate);
    this.embeddedView.rootNodes.forEach(rootNode => bodyElement.appendChild(rootNode));
  }

  dialogButtonClick(button: DialogButton) {
    if (this.resolveOpenPromise ) {
      this.resolveOpenPromise(button);
      this.resolveOpenPromise = null;
    }
    this.buttonClick.emit(button);
  }

  ngAfterViewInit() {
    // this.viewContainerRef.createEmbeddedView(this.dialogTemplate, {} );
    this.close();
    this.showButtonGroup();
  }

  showButtonGroup() {
    if (!this.showbuttons) {
      this.showButtonsClass = 'modal-button-hide';
    }
  }

  open(ignoreOpen = false) {
    if (ignoreOpen) {
      return;
    }

    this.moveTemplateToBody();
    this.listenFn = this.renderer.listen('document', 'keydown', (event: KeyboardEvent) => {
      this.keyPressed(event);
    });

    const openDialogCount = document.querySelectorAll('body > .modal').length;
    this.zIndex = this.zIndexBase + (openDialogCount  * 100);

    const self = this;
    if (this.active) {
      this.close();
    }
    return new Promise<DialogButton>((resolve, reject) => {
      self.resolveOpenPromise = resolve;
      setTimeout( () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop === 0) {
          this.marginTop = '60px';
        } else {
          this.marginTop = `${scrollTop + 60}px`;
        }

        // this.element.style.display = 'block';
        this.dialogService.addDialogClass();

        this.active = true;
        this.opened.emit();
      }, 50);

    });
  }

  close(): void {
    // this.element.style.display = 'none';
    if (this.active) {
      this.embeddedView?.destroy();
      this.dialogService.removeDialogClass();
      this.active = false;
      if (this.resolveOpenPromise) {
        this.resolveOpenPromise(null);
        this.resolveOpenPromise = null;
      }
      // this.buttonClick.emit(null);
      this.closed.emit();
      this.listenFn();
    }
  }

  ngOnDestroy(): void {
    this.close();
  }

}
