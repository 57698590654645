/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AdyenConfigurationVm } from '../models/adyen-configuration-vm';
import { getMibpPaymentConfiguration } from '../fn/mibp-payment/get-mibp-payment-configuration';
import { GetMibpPaymentConfiguration$Params } from '../fn/mibp-payment/get-mibp-payment-configuration';
import { getMibpPaymentConfiguration$Plain } from '../fn/mibp-payment/get-mibp-payment-configuration-plain';
import { GetMibpPaymentConfiguration$Plain$Params } from '../fn/mibp-payment/get-mibp-payment-configuration-plain';
import { isCompanyAndCurrencyValidForMibpPayment } from '../fn/mibp-payment/is-company-and-currency-valid-for-mibp-payment';
import { IsCompanyAndCurrencyValidForMibpPayment$Params } from '../fn/mibp-payment/is-company-and-currency-valid-for-mibp-payment';
import { isCompanyAndCurrencyValidForMibpPayment$Plain } from '../fn/mibp-payment/is-company-and-currency-valid-for-mibp-payment-plain';
import { IsCompanyAndCurrencyValidForMibpPayment$Plain$Params } from '../fn/mibp-payment/is-company-and-currency-valid-for-mibp-payment-plain';

@Injectable({ providedIn: 'root' })
export class MibpPaymentApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mibpPaymentControllerGetMibpPaymentConfiguration()` */
  static readonly MibpPaymentControllerGetMibpPaymentConfigurationPath = '/mibppayment/configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMibpPaymentConfiguration$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpPaymentConfiguration$Plain$Response(params?: GetMibpPaymentConfiguration$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AdyenConfigurationVm>> {
    return getMibpPaymentConfiguration$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMibpPaymentConfiguration$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpPaymentConfiguration$Plain(params?: GetMibpPaymentConfiguration$Plain$Params, context?: HttpContext): Observable<AdyenConfigurationVm> {
    return this.getMibpPaymentConfiguration$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdyenConfigurationVm>): AdyenConfigurationVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMibpPaymentConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpPaymentConfiguration$Response(params?: GetMibpPaymentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<AdyenConfigurationVm>> {
    return getMibpPaymentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMibpPaymentConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMibpPaymentConfiguration(params?: GetMibpPaymentConfiguration$Params, context?: HttpContext): Observable<AdyenConfigurationVm> {
    return this.getMibpPaymentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdyenConfigurationVm>): AdyenConfigurationVm => r.body)
    );
  }

  /** Path part for operation `mibpPaymentControllerIsCompanyAndCurrencyValidForMibpPayment()` */
  static readonly MibpPaymentControllerIsCompanyAndCurrencyValidForMibpPaymentPath = '/mibppayment/validate/currency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isCompanyAndCurrencyValidForMibpPayment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCompanyAndCurrencyValidForMibpPayment$Plain$Response(params?: IsCompanyAndCurrencyValidForMibpPayment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isCompanyAndCurrencyValidForMibpPayment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isCompanyAndCurrencyValidForMibpPayment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCompanyAndCurrencyValidForMibpPayment$Plain(params?: IsCompanyAndCurrencyValidForMibpPayment$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.isCompanyAndCurrencyValidForMibpPayment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isCompanyAndCurrencyValidForMibpPayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCompanyAndCurrencyValidForMibpPayment$Response(params?: IsCompanyAndCurrencyValidForMibpPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isCompanyAndCurrencyValidForMibpPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isCompanyAndCurrencyValidForMibpPayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isCompanyAndCurrencyValidForMibpPayment(params?: IsCompanyAndCurrencyValidForMibpPayment$Params, context?: HttpContext): Observable<boolean> {
    return this.isCompanyAndCurrencyValidForMibpPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
