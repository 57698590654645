/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChangelogChangeForEditingViewModel } from '../models/changelog-change-for-editing-view-model';
import { ChangelogChangeListItemViewModelSearchResponse } from '../models/changelog-change-list-item-view-model-search-response';
import { ChangelogDeleteChangeResponseViewModel } from '../models/changelog-delete-change-response-view-model';
import { ChangelogReleaseForEditingViewModel } from '../models/changelog-release-for-editing-view-model';
import { ChangelogReleaseListItemViewModelSearchResponse } from '../models/changelog-release-list-item-view-model-search-response';
import { ChangeLogReleaseViewModelSearchResponse } from '../models/change-log-release-view-model-search-response';
import { clearUnseenChangelog } from '../fn/change-logs/clear-unseen-changelog';
import { ClearUnseenChangelog$Params } from '../fn/change-logs/clear-unseen-changelog';
import { createChange } from '../fn/change-logs/create-change';
import { CreateChange$Params } from '../fn/change-logs/create-change';
import { createChange$Plain } from '../fn/change-logs/create-change-plain';
import { CreateChange$Plain$Params } from '../fn/change-logs/create-change-plain';
import { createRelease } from '../fn/change-logs/create-release';
import { CreateRelease$Params } from '../fn/change-logs/create-release';
import { createRelease$Plain } from '../fn/change-logs/create-release-plain';
import { CreateRelease$Plain$Params } from '../fn/change-logs/create-release-plain';
import { deleteChange } from '../fn/change-logs/delete-change';
import { DeleteChange$Params } from '../fn/change-logs/delete-change';
import { deleteChange$Plain } from '../fn/change-logs/delete-change-plain';
import { DeleteChange$Plain$Params } from '../fn/change-logs/delete-change-plain';
import { deleteRelease } from '../fn/change-logs/delete-release';
import { DeleteRelease$Params } from '../fn/change-logs/delete-release';
import { deleteRelease$Plain } from '../fn/change-logs/delete-release-plain';
import { DeleteRelease$Plain$Params } from '../fn/change-logs/delete-release-plain';
import { getChangeDeleteStatus } from '../fn/change-logs/get-change-delete-status';
import { GetChangeDeleteStatus$Params } from '../fn/change-logs/get-change-delete-status';
import { getChangeDeleteStatus$Plain } from '../fn/change-logs/get-change-delete-status-plain';
import { GetChangeDeleteStatus$Plain$Params } from '../fn/change-logs/get-change-delete-status-plain';
import { getChangeForEditing } from '../fn/change-logs/get-change-for-editing';
import { GetChangeForEditing$Params } from '../fn/change-logs/get-change-for-editing';
import { getChangeForEditing$Plain } from '../fn/change-logs/get-change-for-editing-plain';
import { GetChangeForEditing$Plain$Params } from '../fn/change-logs/get-change-for-editing-plain';
import { getChangelogReleases } from '../fn/change-logs/get-changelog-releases';
import { GetChangelogReleases$Params } from '../fn/change-logs/get-changelog-releases';
import { getChangelogReleases$Plain } from '../fn/change-logs/get-changelog-releases-plain';
import { GetChangelogReleases$Plain$Params } from '../fn/change-logs/get-changelog-releases-plain';
import { getChanges } from '../fn/change-logs/get-changes';
import { GetChanges$Params } from '../fn/change-logs/get-changes';
import { getChanges$Plain } from '../fn/change-logs/get-changes-plain';
import { GetChanges$Plain$Params } from '../fn/change-logs/get-changes-plain';
import { getPendingChanges } from '../fn/change-logs/get-pending-changes';
import { GetPendingChanges$Params } from '../fn/change-logs/get-pending-changes';
import { getPendingChanges$Plain } from '../fn/change-logs/get-pending-changes-plain';
import { GetPendingChanges$Plain$Params } from '../fn/change-logs/get-pending-changes-plain';
import { getReleaseForEditing } from '../fn/change-logs/get-release-for-editing';
import { GetReleaseForEditing$Params } from '../fn/change-logs/get-release-for-editing';
import { getReleaseForEditing$Plain } from '../fn/change-logs/get-release-for-editing-plain';
import { GetReleaseForEditing$Plain$Params } from '../fn/change-logs/get-release-for-editing-plain';
import { getReleases } from '../fn/change-logs/get-releases';
import { GetReleases$Params } from '../fn/change-logs/get-releases';
import { getReleases$Plain } from '../fn/change-logs/get-releases-plain';
import { GetReleases$Plain$Params } from '../fn/change-logs/get-releases-plain';
import { hasUnseenChangelog } from '../fn/change-logs/has-unseen-changelog';
import { HasUnseenChangelog$Params } from '../fn/change-logs/has-unseen-changelog';
import { hasUnseenChangelog$Plain } from '../fn/change-logs/has-unseen-changelog-plain';
import { HasUnseenChangelog$Plain$Params } from '../fn/change-logs/has-unseen-changelog-plain';
import { updateChange } from '../fn/change-logs/update-change';
import { UpdateChange$Params } from '../fn/change-logs/update-change';
import { updateRelease } from '../fn/change-logs/update-release';
import { UpdateRelease$Params } from '../fn/change-logs/update-release';

@Injectable({ providedIn: 'root' })
export class ChangeLogsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `changeLogsControllerGetChangessearchQueryTypeSkipTake()` */
  static readonly ChangeLogsControllerGetChangessearchQueryTypeSkipTakePath = '/changelogs/changes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChanges$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChanges$Plain$Response(params?: GetChanges$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogChangeListItemViewModelSearchResponse>> {
    return getChanges$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChanges$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChanges$Plain(params?: GetChanges$Plain$Params, context?: HttpContext): Observable<ChangelogChangeListItemViewModelSearchResponse> {
    return this.getChanges$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogChangeListItemViewModelSearchResponse>): ChangelogChangeListItemViewModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChanges()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChanges$Response(params?: GetChanges$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogChangeListItemViewModelSearchResponse>> {
    return getChanges(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChanges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChanges(params?: GetChanges$Params, context?: HttpContext): Observable<ChangelogChangeListItemViewModelSearchResponse> {
    return this.getChanges$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogChangeListItemViewModelSearchResponse>): ChangelogChangeListItemViewModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerUpdateChangeupdatedChange()` */
  static readonly ChangeLogsControllerUpdateChangeupdatedChangePath = '/changelogs/changes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateChange()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateChange$Response(params?: UpdateChange$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateChange(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateChange$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateChange(params?: UpdateChange$Params, context?: HttpContext): Observable<void> {
    return this.updateChange$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerCreateChangenewChange()` */
  static readonly ChangeLogsControllerCreateChangenewChangePath = '/changelogs/changes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createChange$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createChange$Plain$Response(params?: CreateChange$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return createChange$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createChange$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createChange$Plain(params?: CreateChange$Plain$Params, context?: HttpContext): Observable<number> {
    return this.createChange$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createChange()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createChange$Response(params?: CreateChange$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return createChange(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createChange$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createChange(params?: CreateChange$Params, context?: HttpContext): Observable<number> {
    return this.createChange$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerGetPendingChangesskipTakeOnlyUnassignedChanges()` */
  static readonly ChangeLogsControllerGetPendingChangesskipTakeOnlyUnassignedChangesPath = '/changelogs/changes/pending';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPendingChanges$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPendingChanges$Plain$Response(params?: GetPendingChanges$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogChangeListItemViewModelSearchResponse>> {
    return getPendingChanges$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPendingChanges$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPendingChanges$Plain(params?: GetPendingChanges$Plain$Params, context?: HttpContext): Observable<ChangelogChangeListItemViewModelSearchResponse> {
    return this.getPendingChanges$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogChangeListItemViewModelSearchResponse>): ChangelogChangeListItemViewModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPendingChanges()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPendingChanges$Response(params?: GetPendingChanges$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogChangeListItemViewModelSearchResponse>> {
    return getPendingChanges(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPendingChanges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPendingChanges(params?: GetPendingChanges$Params, context?: HttpContext): Observable<ChangelogChangeListItemViewModelSearchResponse> {
    return this.getPendingChanges$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogChangeListItemViewModelSearchResponse>): ChangelogChangeListItemViewModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerGetChangeForEditingid()` */
  static readonly ChangeLogsControllerGetChangeForEditingidPath = '/changelogs/changes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangeForEditing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeForEditing$Plain$Response(params: GetChangeForEditing$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogChangeForEditingViewModel>> {
    return getChangeForEditing$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangeForEditing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeForEditing$Plain(params: GetChangeForEditing$Plain$Params, context?: HttpContext): Observable<ChangelogChangeForEditingViewModel> {
    return this.getChangeForEditing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogChangeForEditingViewModel>): ChangelogChangeForEditingViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangeForEditing()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeForEditing$Response(params: GetChangeForEditing$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogChangeForEditingViewModel>> {
    return getChangeForEditing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangeForEditing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeForEditing(params: GetChangeForEditing$Params, context?: HttpContext): Observable<ChangelogChangeForEditingViewModel> {
    return this.getChangeForEditing$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogChangeForEditingViewModel>): ChangelogChangeForEditingViewModel => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerDeleteChangeid()` */
  static readonly ChangeLogsControllerDeleteChangeidPath = '/changelogs/changes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChange$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChange$Plain$Response(params: DeleteChange$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>> {
    return deleteChange$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChange$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChange$Plain(params: DeleteChange$Plain$Params, context?: HttpContext): Observable<ChangelogDeleteChangeResponseViewModel> {
    return this.deleteChange$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>): ChangelogDeleteChangeResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChange()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChange$Response(params: DeleteChange$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>> {
    return deleteChange(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChange(params: DeleteChange$Params, context?: HttpContext): Observable<ChangelogDeleteChangeResponseViewModel> {
    return this.deleteChange$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>): ChangelogDeleteChangeResponseViewModel => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerGetChangeDeleteStatusid()` */
  static readonly ChangeLogsControllerGetChangeDeleteStatusidPath = '/changelogs/changes/{id}/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangeDeleteStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeDeleteStatus$Plain$Response(params: GetChangeDeleteStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>> {
    return getChangeDeleteStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangeDeleteStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeDeleteStatus$Plain(params: GetChangeDeleteStatus$Plain$Params, context?: HttpContext): Observable<ChangelogDeleteChangeResponseViewModel> {
    return this.getChangeDeleteStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>): ChangelogDeleteChangeResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangeDeleteStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeDeleteStatus$Response(params: GetChangeDeleteStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>> {
    return getChangeDeleteStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangeDeleteStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangeDeleteStatus(params: GetChangeDeleteStatus$Params, context?: HttpContext): Observable<ChangelogDeleteChangeResponseViewModel> {
    return this.getChangeDeleteStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>): ChangelogDeleteChangeResponseViewModel => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerGetReleasessearchQuerySkipTake()` */
  static readonly ChangeLogsControllerGetReleasessearchQuerySkipTakePath = '/changelogs/releases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReleases$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleases$Plain$Response(params?: GetReleases$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogReleaseListItemViewModelSearchResponse>> {
    return getReleases$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReleases$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleases$Plain(params?: GetReleases$Plain$Params, context?: HttpContext): Observable<ChangelogReleaseListItemViewModelSearchResponse> {
    return this.getReleases$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogReleaseListItemViewModelSearchResponse>): ChangelogReleaseListItemViewModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReleases()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleases$Response(params?: GetReleases$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogReleaseListItemViewModelSearchResponse>> {
    return getReleases(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReleases$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleases(params?: GetReleases$Params, context?: HttpContext): Observable<ChangelogReleaseListItemViewModelSearchResponse> {
    return this.getReleases$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogReleaseListItemViewModelSearchResponse>): ChangelogReleaseListItemViewModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerUpdateReleaserelease()` */
  static readonly ChangeLogsControllerUpdateReleasereleasePath = '/changelogs/releases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRelease()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateRelease$Response(params?: UpdateRelease$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateRelease(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRelease$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateRelease(params?: UpdateRelease$Params, context?: HttpContext): Observable<void> {
    return this.updateRelease$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerCreateReleaserelease()` */
  static readonly ChangeLogsControllerCreateReleasereleasePath = '/changelogs/releases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRelease$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRelease$Plain$Response(params?: CreateRelease$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return createRelease$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRelease$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRelease$Plain(params?: CreateRelease$Plain$Params, context?: HttpContext): Observable<number> {
    return this.createRelease$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRelease()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRelease$Response(params?: CreateRelease$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return createRelease(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRelease$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRelease(params?: CreateRelease$Params, context?: HttpContext): Observable<number> {
    return this.createRelease$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerGetReleaseForEditingid()` */
  static readonly ChangeLogsControllerGetReleaseForEditingidPath = '/changelogs/releases/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReleaseForEditing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleaseForEditing$Plain$Response(params: GetReleaseForEditing$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogReleaseForEditingViewModel>> {
    return getReleaseForEditing$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReleaseForEditing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleaseForEditing$Plain(params: GetReleaseForEditing$Plain$Params, context?: HttpContext): Observable<ChangelogReleaseForEditingViewModel> {
    return this.getReleaseForEditing$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogReleaseForEditingViewModel>): ChangelogReleaseForEditingViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReleaseForEditing()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleaseForEditing$Response(params: GetReleaseForEditing$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogReleaseForEditingViewModel>> {
    return getReleaseForEditing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReleaseForEditing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReleaseForEditing(params: GetReleaseForEditing$Params, context?: HttpContext): Observable<ChangelogReleaseForEditingViewModel> {
    return this.getReleaseForEditing$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogReleaseForEditingViewModel>): ChangelogReleaseForEditingViewModel => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerDeleteReleaseid()` */
  static readonly ChangeLogsControllerDeleteReleaseidPath = '/changelogs/releases/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRelease$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelease$Plain$Response(params: DeleteRelease$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>> {
    return deleteRelease$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRelease$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelease$Plain(params: DeleteRelease$Plain$Params, context?: HttpContext): Observable<ChangelogDeleteChangeResponseViewModel> {
    return this.deleteRelease$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>): ChangelogDeleteChangeResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRelease()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelease$Response(params: DeleteRelease$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>> {
    return deleteRelease(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRelease$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelease(params: DeleteRelease$Params, context?: HttpContext): Observable<ChangelogDeleteChangeResponseViewModel> {
    return this.deleteRelease$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangelogDeleteChangeResponseViewModel>): ChangelogDeleteChangeResponseViewModel => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerGetChangelogReleases()` */
  static readonly ChangeLogsControllerGetChangelogReleasesPath = '/changelogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangelogReleases$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangelogReleases$Plain$Response(params?: GetChangelogReleases$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeLogReleaseViewModelSearchResponse>> {
    return getChangelogReleases$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangelogReleases$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangelogReleases$Plain(params?: GetChangelogReleases$Plain$Params, context?: HttpContext): Observable<ChangeLogReleaseViewModelSearchResponse> {
    return this.getChangelogReleases$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeLogReleaseViewModelSearchResponse>): ChangeLogReleaseViewModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChangelogReleases()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangelogReleases$Response(params?: GetChangelogReleases$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeLogReleaseViewModelSearchResponse>> {
    return getChangelogReleases(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChangelogReleases$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChangelogReleases(params?: GetChangelogReleases$Params, context?: HttpContext): Observable<ChangeLogReleaseViewModelSearchResponse> {
    return this.getChangelogReleases$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeLogReleaseViewModelSearchResponse>): ChangeLogReleaseViewModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerHasUnseenChangelog()` */
  static readonly ChangeLogsControllerHasUnseenChangelogPath = '/changelogs/unseen';

  /**
   * Check if current user has any unseen changelog releases.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasUnseenChangelog$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasUnseenChangelog$Plain$Response(params?: HasUnseenChangelog$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return hasUnseenChangelog$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if current user has any unseen changelog releases.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hasUnseenChangelog$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasUnseenChangelog$Plain(params?: HasUnseenChangelog$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.hasUnseenChangelog$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * Check if current user has any unseen changelog releases.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasUnseenChangelog()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasUnseenChangelog$Response(params?: HasUnseenChangelog$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return hasUnseenChangelog(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if current user has any unseen changelog releases.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hasUnseenChangelog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasUnseenChangelog(params?: HasUnseenChangelog$Params, context?: HttpContext): Observable<boolean> {
    return this.hasUnseenChangelog$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `changeLogsControllerClearUnseenChangelog()` */
  static readonly ChangeLogsControllerClearUnseenChangelogPath = '/changelogs/unseen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearUnseenChangelog()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearUnseenChangelog$Response(params?: ClearUnseenChangelog$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clearUnseenChangelog(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearUnseenChangelog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearUnseenChangelog(params?: ClearUnseenChangelog$Params, context?: HttpContext): Observable<void> {
    return this.clearUnseenChangelog$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
