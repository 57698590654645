
<ng-template #popupContainerTemplate>
  <div [attr.id]="id" class="my-popup-container"
  [class.my-popup-container--above-header]="showAboveHeader"
  [class.my-popup-container--fitwidth]="fitWidth" [style.opacity]="isVisible ? 1 : 0"
  [class.my-popup-container--has-arrow]="useArrow"
  [class.my-popup-container--solid]="!closeOnRootClick"
  [class.my-popup-container--overlay]="closeOnRootClick"
  [class.my-popup-container--scrollable]="scrollable"
  >
  <div class="my-popup-content"
    [style.minWidth]="minWidth ? minWidth + 'px' : ''"
    [style.maxWidth]="maxWidth ? maxWidth + 'px' : ''"
    [class.my-popup-content--has-arrow]="useArrow"
    [class.my-popup-content--arrow-top-center]="arrowPosition == 'top-center'"
    [class.my-popup-content--arrow-top-left]="arrowPosition == 'top-left'"
    [class.my-popup-content--arrow-right-center]="arrowPosition == 'right-center'"
    [class.my-popup-content--arrow-left-center]="arrowPosition == 'left-center'"
    [class.my-popup-content--arrow-bottom-center]="arrowPosition == 'bottom-center'"
    [class.my-popup-content--absolute]="!attachToBody">
    <ng-content></ng-content>
  </div>
</div>
</ng-template>
