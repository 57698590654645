import { UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn, CanActivateChildFn } from "@angular/router";
import { Injectable, inject } from "@angular/core";
import { MibpLogger,
  StartupService,
  AuthService,
  FrontendContextService,
  LogService,
  RouteAuthConfig,
  ClientSideCacheService,
  CacheScope,
  StartupLanguageService,
  StartupAuthenticationService,
  StartupBackendService,
  StartupLocalizationService,
  StartupUserStatusService,
  StartupUserPermissionsService,
  BroadcastService
} from "root/services";
import { ApplicationStateService } from "root/services/application-state/application-state.service";
import { ApplicationStates } from "root/services/application-state/application-state.types";

export interface ActivationArgs {
  route: ActivatedRouteSnapshot;
  state: RouterStateSnapshot;
  isFirst: boolean;
  routeConfig: RouteAuthConfig;
  router: Router;
}

@Injectable({
  providedIn: 'root'
})
export class StartupGuardService  {

  private log: MibpLogger;

  constructor(
    private authService: AuthService,
    private cache: ClientSideCacheService,
    private appState: ApplicationStateService,
    private broadcastService: BroadcastService,
    // Different startup services that ensure different aspects during application load
    private appStartService: StartupService,
    private languageStartup: StartupLanguageService,
    private authenticationStartup: StartupAuthenticationService,
    //private backendStartup: StartupBackendService,
    private localizationStartup: StartupLocalizationService,
    private userStatusStartup: StartupUserStatusService,
    private userPermissionStartup: StartupUserPermissionsService,
    private frontendContext: FrontendContextService,
    private router: Router,
    logger: LogService) {
    this.log = logger.withPrefix('startup-guard');
  }

  public testActivation(isFirst: boolean, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // If b2c redict callback we need to stop processing our route guards and let auth redirect and handle if needed
    if (this.broadcastService.snapshot.applicationState.stopStartupGuardProcessing) {
      return new Promise( () => {
        this.log.debug(`ApplicationState set to stop processing guards and block all routes`);
      });
    }

    const routeConfig = this.authService.getRouteConfig(route);
    if (this.appStartService.hasStartupErrors) {
      return new Promise(() => {
        // console.log("blocking because of startup errors");
      });
    }

    if (isFirst && this.cache.get('firstIsLoaded')) {
      isFirst = false;
    } else if (isFirst) {
      this.cache.add('firstIsLoaded', true, null, CacheScope.MemoryStorage);
    }

    return this.startup({
      isFirst: isFirst,
      route: route,
      state: state,
      routeConfig: routeConfig,
      router: this.router
    });
  }

  /**
   * Main startup method that will block page load until we're sure we have everything
   */
  private async startup(args: ActivationArgs): Promise<boolean | UrlTree> {
    let canActivate: boolean | UrlTree;
    let stopwatch: string;

    stopwatch = this.log.startTime('testLang');
    canActivate = await this.languageStartup.testLanguage(args);
    this.log.endTime(stopwatch);

    // If language is ok - block until Auth is alright
    // If user is not loggedin, this will also redirect to login page if route requires any permissions
    if (canActivate === true) {
      stopwatch = this.log.startTime('testAuthentication');
      canActivate = await this.authenticationStartup.testAuthentication(args);
      this.log.endTime(stopwatch);
    }

    // For some routes like / and /[lang] that do not have a controller or are just container pages
    // we want to skip a few steps so we do not connect to SignalR before we're on a route
    // that cares anything about permissions or auth
    // if (args.routeConfig.postponeSignalrConnection === true) {
    //   this.log.debug("Postponing signalr connection for a deeper route", args.route.url);
    //   return true;
    // } else {
    //   this.log.debug("Not postponing any more");
    // }

    // If auth status is resolved - connect to backend
    // if (canActivate === true) {
    //   stopwatch = this.log.startTime('testBackend');
    //   canActivate = await this.backendStartup.testBackend(args);
    //   this.log.endTime(stopwatch);
    // }
   
    // If all is ok - keep blocking until resource strings are loaded
    if (canActivate === true) {
      stopwatch = this.log.startTime('testLocalization');
      // this.appState.setState({ state: ApplicationStates.LoadingTranslations, resourceStringKey: 'AppLoading_Translations', textFallback: 'Loading translations' });
      canActivate = await this.localizationStartup.testLocalization();
      this.log.endTime(stopwatch);
    }

    // If all is ok - keep blocking until current user is resolved
    // if (canActivate === true) {
    //   stopwatch = this.log.startTime('testUserEvent');
    //   const hasUserEvent = this.userEventStartup.hasUserEvent();
    //   canActivate = await this.userEventStartup.testUserEvent(args);
    //   if (!hasUserEvent) {
    //     this.appState.setState({ state: ApplicationStates.Loaded });
    //   }
    //   this.log.endTime(stopwatch);
    // }
   
    // If all is ok - check user status and redirect to appropriate page if needed
    if (canActivate === true) {
      stopwatch = this.log.startTime('testUserStatus');
      canActivate = await this.userStatusStartup.testUserStatus(args);
      this.log.endTime(stopwatch);
    }
   
    // If all is ok
    if (canActivate === true) {
      stopwatch = this.log.startTime('testUserPermissions');
      if (args.state.url.indexOf('/en/user/myprofile') !== -1 && this.broadcastService.snapshot?.mibpSession?.user !== null) {
        // special case for new inactive user reach myprofile page
      } else {
        canActivate = await this.userPermissionStartup.testUserPermissions(args);
      }
      this.log.endTime(stopwatch);
    }

    this.appStartService.hideNativeMessage();

    if (canActivate === true) {
      this.appStartService.applicationStarted = true;
      // this.appState.isLoaded = true;
      this.appState.setState({state: ApplicationStates.Loaded, internalStatus: 'LOADEDYES'});
    }


    if (canActivate !== null) {
      return canActivate;
    } else {
      this.log.debug('canActivate was null. This guard will never resolve');
      return new Promise(() => {
        // Block further route processing
      });
    }
  }

}

export const StartupCanActivateFn : CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean | UrlTree> => {
  return inject(StartupGuardService).testActivation(true, route, state);
};

export const StartupCanActivateChildFn : CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean | UrlTree> => {
  return inject(StartupGuardService).testActivation(false, route, state);
};
